import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps, Badge, styled } from "@mui/material";

declare type StyledAvatarProps = MuiAvatarProps & {
    alt: string;
}

const StyledAvatar = styled(MuiAvatar)<StyledAvatarProps>;


const textColor = (input: string) => {
    let hash = 0;

    /* eslint-disable no-bitwise */
    for (let i = 0; i < input.length; i += 1) {
        hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }

    let output = '#';
    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        output += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return output;
}

const Avatar = StyledAvatar(({ alt }) => ({
    backgroundColor: textColor(alt)
}));

const DotAvatar = StyledAvatar(({ theme, alt }) => ({
    backgroundColor: textColor(alt),
    width: 22,
    height: 22,
    padding: 1,
    fontSize: 14,
    textAlign: 'center',
    border: `2px solid ${theme.palette.background.paper}`,
}));


interface AvatarProps {
    Avatar: StyledAvatarProps;
    Organization?: StyledAvatarProps;
}

export function AvatarWithBadge(props: AvatarProps) {
    return (
        <Badge
            sx={{ maxHeight: 48 }}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={(props.Organization ? <DotAvatar {...props.Organization} /> : undefined)}>
            <Avatar {...props.Avatar} />
        </Badge>
    )
}