import { Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { MapPicker } from "components/Maps/MapPicker";
import { LatLng, LatLngBounds, Map } from "leaflet";
import { AutocompleteSearchPlace } from "components/Autocomplete/AutocompleteSearchPlace";
import { Nominatim, useLazyReverseQuery, useLazySearchQuery } from "services/api/nominatimApi";
import { AutocompleteLazyQuery } from "components/Autocomplete/AutocompleteLazyQuery";
import { Event } from "types/Event";
import { useCreateEventMutation, useLazyRetrieveCitiesInDepartmantQuery, useLazyRetrieveDepartmentsQuery, useLazyRetrieveTownsInCityQuery, useLazyRetrieveTypesQuery } from "services/api/admin";
import { City, Department, Settlement } from "types/Location";
import { Type } from "types/Category";

interface NewIncidentProps {
    onClose: () => void;
    onCreated?: (item: Event['id']) => void;
}

const defaultPoint: LatLng = new LatLng(11.003208381761434, -74.81138014822868);

declare type FormData = { [key: string]: FormDataEntryValue; };


export function NewEvent(props: NewIncidentProps) {
    //
    const [lazyReverseQuery, { data }] = useLazyReverseQuery();
    const [lazySearchQuery, searchResult] = useLazySearchQuery();
    const [execCreate, { isSuccess }] = useCreateEventMutation();

    //  FormModal
    const [open, setOpen] = useState<boolean>(true);
    //  GeoLocation
    const [map, setMap] = useState<Map | null>(null);
    const [bounds, setBounds] = useState<LatLngBounds>();
    const [point, setPoint] = useState<LatLng>(defaultPoint);
    const [place, setPlace] = useState<Nominatim | null>(null);
    //  DIVIPOLA
    const [department, setDepartment] = useState<Department | null>(null);
    const [city, setCity] = useState<City | null>(null);
    const [settler, setSettler] = useState<Settlement | null>(null);
    //  EVENT
    const [event, setEvent] = useState<Type | null>(null);


    useEffect(() => {
        const data = [...(searchResult.data || [])];
        const item = data?.shift();
        if (!item) return;
        const [min_lat, max_lat, min_lon, max_lon] = item.boundingbox;
        var southWest = new LatLng(Number.parseFloat(min_lat), Number.parseFloat(min_lon));
        var northEast = new LatLng(Number.parseFloat(max_lat), Number.parseFloat(max_lon));
        var bounds = new LatLngBounds(southWest, northEast);
        map?.fitBounds(bounds);
    }, [map, searchResult.data]);

    useEffect(() => {
        const locations = ['Colombia'];
        if (department) {
            locations.push(department.name);
        }
        if (city) {
            locations.push(city.name);
        }
        if (settler) {
            locations.push(settler.name);
        }

        const query = locations.reverse().join(",");
        lazySearchQuery({ query }).catch((err) => {
            console.error('lazySearchQuery', err);
        });

    }, [city, department, lazySearchQuery, settler]);

    //
    useEffect(() => {
        if (!open) props.onClose()
    }, [open, props]);

    useEffect(() => {
        if (!map) return;
        setBounds(map.getBounds());
        map.addEventListener("moveend", () => setBounds(map.getBounds()));
        return () => {
            map.removeEventListener("moveend");
        }
    }, [map]);

    useEffect(() => {
        //console.log("execQuery", { position: point });
        lazyReverseQuery({ position: point })
    }, [lazyReverseQuery, map, point]);

    useEffect(() => {
        setPlace(data || null);
    }, [data])

    useEffect(() => {
        if (!place) return;
        const latitude = Number.parseFloat(place.lat);
        const longitude = Number.parseFloat(place.lon);
        const point = new LatLng(latitude, longitude);
        setPoint(point);
    }, [place]);

    //
    const handleClose = () => setOpen(false);

    const getFormStringValue = (data: FormData, key: string): string | undefined => {
        const value = data[key];
        if (typeof value === "string") {
            return value;
        }
    }

    const handleSubmit = async (formData: FormData) => {
        const data = {
            incident: {
                address: getFormStringValue(formData, "address"),
                description: getFormStringValue(formData, "description"),
                latitude: point.lat,
                longitude: point.lng,
                poblado_id: settler?.code,
                event_id: event?.code,
            },
            patient: {
                first_name: getFormStringValue(formData, "first_name"),
                second_name: getFormStringValue(formData, "second_name"),
                lastname: getFormStringValue(formData, "lastname"),
                second_lastname: getFormStringValue(formData, "second_lastname"),
                age: 20,
                document_type: getFormStringValue(formData, "document_type"),
                document_number: getFormStringValue(formData, "document_number"),
            }
        };

        //await execCreate(data);
        if (isSuccess && props.onCreated) {
            props.onCreated(-1)
        }
        return true
    }


    return (
        <FormModal
            title="Nuevo incidente"
            buttonPositive="Crear"
            open={open}
            onClose={handleClose}
            onSubmit={handleSubmit}
            dialogProps={{ fullWidth: true, maxWidth: "lg" }}
        >
            <Grid container spacing={4} rowSpacing={1} width={'100%'}>
                <Grid xs={4}>
                    <Stack direction="column" spacing={4}>
                        <Divider textAlign="left">Ubicación geográfica</Divider>
                        <MapPicker
                            point={point}
                            geometry={place?.geojson}
                            onChangeMap={(value) => setMap(value)}
                            onChangePoint={(value) => setPoint(value)}
                        />
                        <Stack direction="row" spacing={4}>
                            <AutocompleteLazyQuery<Department, void>
                                query={useLazyRetrieveDepartmentsQuery}
                                queryArg={undefined}
                                valueField="id"
                                displayField="name"
                                TextField={{ label: "Departamento", name: "department", required: true }}
                                onChangeValue={(value) => setDepartment(value)}
                            />
                            <AutocompleteLazyQuery<City, string | null>
                                query={useLazyRetrieveCitiesInDepartmantQuery}
                                queryArg={department?.code.toString() || null}
                                valueField="id"
                                displayField="name"
                                TextField={{ label: "Municipio", name: "city", required: true }}
                                onChangeValue={(value) => setCity(value)}
                            />
                        </Stack>
                        <AutocompleteLazyQuery<Settlement, string | null>
                            query={useLazyRetrieveTownsInCityQuery}
                            queryArg={city?.code.toString() || null}
                            valueField="id"
                            displayField="name"
                            TextField={{ label: "Poblado", name: "settler", required: true }}
                            onChangeValue={(value) => setSettler(value)}
                        />
                    </Stack>
                </Grid>
                <Grid xs={4}>
                    <Stack direction="column" spacing={4}>
                        <Divider textAlign="left">Datos del evento</Divider>
                        <AutocompleteSearchPlace
                            displayField="display_name"
                            valueField="osm_id"
                            viewbox={bounds}
                            onChangeValue={(value) => setPlace(value)}
                        />
                        <TextField
                            margin="dense"
                            name="placeReference"
                            label="Lugar referencia"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                        <AutocompleteLazyQuery<Type, void>
                            query={useLazyRetrieveTypesQuery}
                            queryArg={undefined}
                            valueField="id"
                            displayField="name"
                            TextField={{ label: "Tipo de evento", name: "event", required: true }}
                            onChangeValue={(value) => setEvent(value)}
                        />
                        <TextField
                            required
                            margin="dense"
                            name="description"
                            label="Descripción de lo sucedido"
                            type="text"
                            fullWidth
                            multiline
                            minRows={6}
                            maxRows={6}
                            variant="outlined"
                        />
                    </Stack>
                </Grid>
                <Grid xs={4}>
                    <Stack direction="column" spacing={4}>
                        <Divider textAlign="left">Datos del reportante</Divider>
                        <Stack direction="row" spacing={4}>
                            <FormControl fullWidth>
                                <InputLabel>Tipo documento</InputLabel>
                                <Select label="Tipo documento" name="document_type">
                                    <MenuItem value="NUIP">NUIP</MenuItem>
                                    <MenuItem value="RC">Registro civil</MenuItem>
                                    <MenuItem value="TI">Tarjeta de identidad</MenuItem>
                                    <MenuItem value="CC">Cédula de ciudadanía</MenuItem>
                                    <MenuItem value="TE">Tarjeta de extranjería</MenuItem>
                                    <MenuItem value="CE">Cédula de extranjería</MenuItem>
                                    <MenuItem value="PP">Pasaporte</MenuItem>
                                    <MenuItem value="PEP">Permiso especial de permanencia</MenuItem>
                                    <MenuItem value="DIE">Documento de identificación extranjero</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                margin="dense"
                                name="document_number"
                                label="Número documento"
                                type="number"
                                fullWidth
                                variant="outlined"
                            />
                        </Stack>
                        <Stack direction="row" spacing={4}>
                            <TextField
                                margin="dense"
                                name="first_name"
                                label="Primer nombre"
                                type="text"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                margin="dense"
                                name="second_name"
                                label="Segundo nombre"
                                type="text"
                                fullWidth
                                variant="outlined"
                            />
                        </Stack>
                        <Stack direction="row" spacing={4}>
                            <TextField
                                margin="dense"
                                name="lastname"
                                label="Primer apellido"
                                type="text"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                name="second_lastname"
                                label="Segundo apellido"
                                type="text"
                                fullWidth
                                variant="outlined"
                            />
                        </Stack>
                        <Divider textAlign="left">Datos de contacto</Divider>
                        <TextField
                            name="phone"
                            label="Telefono"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                        <TextField
                            name="email"
                            label="Correo"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </FormModal >
    )
}