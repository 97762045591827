import { Add, DataObject, Delete, Edit } from "@mui/icons-material";
import { Button, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, CircularProgress } from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { UpdateToolFunction } from "./UpdateToolFunction";
import { CreateToolFunction } from "../../create/assistant/CreateToolFunction";
import { AssistantMessage } from "types/Message";

interface UpdateAssistantToolsProps {
    tools?: AssistantMessage.Tool[];
    onSubmit: (tools: AssistantMessage.Tool[]) => void;
}

export function UpdateAssistantTools(props: UpdateAssistantToolsProps) {
    const tools = useMemo(() => props.tools || [], [props.tools]);
    const onSubmit = useCallback(props.onSubmit, [props.onSubmit]);

    const [data, setData] = useState<AssistantMessage.Tool[]>([]);
    const [create, setCreate] = useState<boolean>(false);
    const [update, setUpdate] = useState<AssistantMessage.Tool | undefined>(undefined);
    const [hovered, setHovered] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Estado de carga

    useEffect(() => {
        setLoading(true);
        setData(tools);
        setLoading(false);
    }, [tools]);

    useEffect(() => {
        if (!loading) {
            onSubmit(data);
        }
    }, [data, onSubmit, loading]);

    const handleAddTool = useCallback((newTool: AssistantMessage.Tool) => {
        setData(prev => ([...prev, newTool]));
    }, []);

    const handleUpdateTool = useCallback((updatedTool: AssistantMessage.Tool) => {
        setData(prev => (prev.map(item => (item.id === updatedTool.id ? updatedTool : item))));
    }, []);

    const handleDeleteTool = useCallback((id: string) => {
        setData(prev => prev.filter(item => item.id !== id));
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Fragment>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper', mt: 0 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <>
                        <ListSubheader>Herramientas</ListSubheader>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            startIcon={<Add />}
                            onClick={() => setCreate(true)}
                            sx={{ mb: 4 }}
                        >
                            Agregar Función
                        </Button>
                    </>
                }
            >
                {data.map((it, index) => (
                    <ListItemButton
                        key={it.id}  // Añadido para mejorar el rendimiento
                        sx={{ pl: 4 }}
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                    >
                        <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 4 }}>
                            <DataObject />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary={it.function.name}
                            secondary={JSON.stringify(it.function.arguments)} />
                        {hovered === index &&
                            <Fragment>
                                <IconButton edge="start" aria-label="edit" color="default" onClick={() => setUpdate(it)}>
                                    <Edit />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" color="error" onClick={() => handleDeleteTool(it.id)}>
                                    <Delete />
                                </IconButton>
                            </Fragment>
                        }
                    </ListItemButton>
                ))}
            </List>

            <CreateToolFunction
                open={create}
                onClose={() => setCreate(false)}
                onSubmit={handleAddTool}
            />

            {update && <UpdateToolFunction
                open={!!update}
                defaultValue={update}
                onClose={() => setUpdate(undefined)}
                onSubmit={handleUpdateTool}
            />}
        </Fragment>
    );
}
