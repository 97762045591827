import {
    FormControl,
    InputLabel,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextFieldProps,
} from "@mui/material";
import { BaseQueryFn, TypedUseLazyQuery } from "@reduxjs/toolkit/dist/query/react";
import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";

interface Data {
    [key: string]: any;
}

interface SelectLazyQueryProps<D extends Data, QueryArg> {
    queryLazy: TypedUseLazyQuery<D[], QueryArg, BaseQueryFn<AxiosRequestConfig<D[]>>>;
    queryArg: QueryArg;

    valueField: keyof D;
    displayField: keyof D;
    captionField?: keyof D;
    defaultValue?: D[SelectLazyQueryProps<D, QueryArg>['valueField']];

    name?: TextFieldProps['name'];
    label?: TextFieldProps['label'];
    onChange?: (item: D | null) => void;
    disabled?: boolean;
}

export function SelectLazyQuery<D extends Data, QueryArg>({
    valueField,
    defaultValue,
    displayField,
    captionField,
    onChange,
    queryArg,
    ...props
}: SelectLazyQueryProps<D, QueryArg>) {
    const [trigger, result] = props.queryLazy();
    const [options, setOptions] = useState<D[]>([]);
    const [value, setValue] = useState<D[SelectLazyQueryProps<D, QueryArg>['valueField']] | ''>(
        defaultValue ?? ''
    );

    useEffect(() => {
        trigger(queryArg);
    }, [trigger, queryArg]);

    useEffect(() => {
        if (result.data) {
            setOptions(result.data);
            if (defaultValue !== undefined) {
                const selectedValue = result.data.find(
                    (it) => it[valueField] === defaultValue
                );
                setValue(selectedValue?.[valueField] ?? '');
            }
        }
    }, [defaultValue, result.data, valueField]);

    useEffect(() => {
        if (onChange) {
            const selectedItem = options.find((it) => it[valueField] === value) || null;
            onChange(selectedItem);
        }
    }, [onChange, value, options, valueField]);

    const handleChange = (event: SelectChangeEvent<D[SelectLazyQueryProps<D, QueryArg>['valueField']]>) => {
        setValue(event.target.value as D[SelectLazyQueryProps<D, QueryArg>['valueField']]);
    };

    return (
        <FormControl fullWidth margin="dense" variant="outlined" size="small" sx={{ p: 0 }} required>
            <InputLabel id="lazy-query-select-label">{props.label || "Seleccionar"}</InputLabel>
            <Select
                labelId="lazy-query-select-label"
                id="lazy-query-select"
                label={props.label || "Seleccionar"}
                value={value}
                onChange={handleChange}
                disabled={props.disabled}
                sx={{ "& #lazy-query-select": { p: 1, minHeight: 48 } }}
            >
                {options?.map((it) => (
                    <MenuItem key={it[valueField] as string} value={it[valueField] as string} sx={{ p: 1 }}>
                        <ListItem sx={{ p: 1 }}>
                            <ListItemText
                                sx={{ m: 0.5 }}
                                primary={it[displayField]}
                                secondary={captionField ? it[captionField] : null}
                            />
                        </ListItem>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
