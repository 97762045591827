import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { ReactNode, useMemo } from "react";

interface InfoModalProps {
    title: string;
    contentText?: string | undefined;
    buttonText?: string | undefined;
    children?: ReactNode;
    open: DialogProps['open'];
    onClose: DialogProps['onClose'];
}

export function InfoModal(props: InfoModalProps) {
    const onClose = useMemo(() => props.onClose ? props.onClose : () => { }, [props.onClose]);

    const handleClick = () => {
        onClose({}, "backdropClick")
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="xs" fullWidth>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.contentText &&
                    <DialogContentText>
                        {props.contentText}
                    </DialogContentText>
                }
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClick}>{props.buttonText || "Aceptar"}</Button>
            </DialogActions>
        </Dialog >
    )
}