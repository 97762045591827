import { Completion } from "types/Completion";
import { AssistantAPI } from ".";

export const injectCompletion = (api: AssistantAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveCompletions: build.query<Array<Completion>, void>({
            query: () => ({
                method: "GET",
                url: "/completions",
            }),
            providesTags: (result) => (
                result
                    ? [...result.map(({ id }) => ({ type: "Completion", id } as const)), { type: "Completion", id: 'LIST' }]
                    : [{ type: "Completion", id: 'LIST' }]
            ),
        }),
        retrieveCompletion: build.query<Completion, Completion['id']>({
            query: (id: Completion['id']) => ({
                method: "GET",
                url: `/completions/${id}`,
            }),
            providesTags: (result, err, id) => ([{ type: "Completion", id }]),
        }),
        retrieveCompletionMessages: build.query<Array<Completion.Message>, Completion['id']>({
            query: (id: Completion['id']) => ({
                method: "GET",
                url: `/completions/${id}/messages`,
            }),
            providesTags: (result, err, id) => ([{ type: "CompletionMessage", id: `Completion_${id}` }]),
        }),
    }),
    overrideExisting: "throw",
});