import { LatLngExpression } from "leaflet";
import { MapContainer, MapContainerProps, Marker, Popup, TileLayer } from "react-leaflet";

interface MapPreviewProps {
    container?: MapContainerProps;
    location: LatLngExpression;
    title: string;
}

export function MapPreview(props: MapPreviewProps) {

    return (
        <MapContainer
            style={{ minHeight: 256, minWidth: 256 }}
            zoom={16}
            {...props}
            center={props.container?.center || props.location}
            scrollWheelZoom={false}
        >
            <TileLayer
                maxZoom={20}
                subdomains={"0123"}
                url={`https://mt{s}.google.com/vt/lyrs=m@186112443&hl=x-local&src=web&x={x}&y={y}&z={z}&s=Galile&apistyle=s.e:l.i|p.v:off,s.t:3|s.e:g`}
            //attribution="Datos del mapa ©2024 Google"
            />
            <Marker position={props.location} draggable={false}>
                <Popup autoPan>
                    {props.title}
                </Popup>
            </Marker>
        </MapContainer>
    )
}