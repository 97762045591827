import { CreateNewFolder, ExpandLess, ExpandMore, Folder, FolderOpen } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { TrainCase, TrainChat, TrainSubType, } from "types/Train";
import { useLazyRetrieveTrainCasesQuery, } from "services/api/assistant";
import { Chats } from "../chats";
import { CaseCreate } from "./CaseCreate";
import { CaseMenu } from "./CaseMenu";

interface CasesProps {
    trainSubType?: TrainSubType;
    selected?: TrainCase;
    onChange?: (value?: TrainCase) => void;
    selectedChat?: TrainChat;
    onChangeChat?: (value?: TrainChat) => void;
}

export function Cases({
    trainSubType,
    selected, onChange,
    selectedChat, onChangeChat,
}: CasesProps) {
    const [hovered, setHovered] = useState<number | null>(null);
    const [create, setCreate] = useState<boolean>(false);

    const [trigger, { data }] = useLazyRetrieveTrainCasesQuery();

    useEffect(() => {
        if (trainSubType)
            trigger(trainSubType.id);
    }, [trigger, trainSubType]);


    return (
        <Fragment>
            <List component="div" disablePadding dense>
                <ListItemButton sx={{ pl: 12 }} onClick={() => setCreate(true)}>
                    <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 2 }}>
                        <CreateNewFolder />
                    </ListItemIcon>
                    <ListItemText primary={"Nuevo"} secondary={"Crear caso"} />
                </ListItemButton>
                {trainSubType && data?.map((it) => (
                    <Fragment key={it.id}>
                        <ListItem
                            disablePadding
                            secondaryAction={hovered === it.id && <CaseMenu value={it} />}
                            onMouseEnter={() => setHovered(it.id)}
                            onMouseLeave={() => setHovered(null)}
                        >
                            <ListItemButton
                                sx={{ pl: 12, }}
                                selected={selected?.id === it.id}
                                onClick={() => onChange?.(it)}
                            >
                                <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 2 }}>
                                    {selected?.id === it.id ? <FolderOpen /> : <Folder />}
                                </ListItemIcon>
                                <ListItemText primary={it.name} secondary={it.description} />
                                {(hovered !== it.id) && (selected && selected.id === it.id ? <ExpandLess /> : <ExpandMore />)}
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={selected?.id === it.id} timeout="auto" unmountOnExit>
                            <Chats
                                trainCase={selected}
                                selected={selectedChat}
                                onChange={onChangeChat}
                            />
                        </Collapse>
                    </Fragment>
                ))}
            </List>
            {(create && trainSubType) &&
                <CaseCreate
                    open={create}
                    onClose={() => setCreate(false)}
                    subTypeId={trainSubType?.id}
                />
            }
        </Fragment>
    );
}
