import { MapContainer, Marker, MarkerProps, Popup, TileLayer } from "react-leaflet";

interface MapMarkerProps {
    position: MarkerProps['position'];
}

export function MapMarker(props: MapMarkerProps) {
    return (
        <MapContainer
            style={{ minHeight: 256, width: '100%' }}
            zoom={16}
            dragging={false}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            zoomControl={false}
            center={props.position}
        >
            <TileLayer
                maxZoom={20}
                subdomains={"0123"}
                url={`https://mt{s}.google.com/vt/lyrs=m@186112443&hl=x-local&src=web&x={x}&y={y}&z={z}&s=Galile&apistyle=s.e:l.i|p.v:off,s.t:3|s.e:g`}
                attribution="Datos del mapa ©2024 Google"
            />
            <Marker position={props.position}>
                <Popup autoPan>
                    Marcador de Ubicación
                </Popup>
            </Marker>
        </MapContainer>
    )
}