import { Category } from "types/Category";
import { AdminAPI } from ".";

export const injectCategory = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveCategories: build.query<Category[], void>({
            query: () => ({
                method: "GET",
                url: "/categories",
            }),
            providesTags: (result) => ([
                { type: "Category", id: 'LIST' },
                ...(result || []).map(({ code: id }) => ({ type: "Category", id: id } as const))
            ]),
        }),
        createCategory: build.mutation<Category, Category.Create>({
            query: (body) => ({
                method: "POST",
                url: "/categories",
                data: body
            }),
            invalidatesTags: [{ type: "Category", id: "LIST" }]
        }),
        retrieveCategory: build.query<Category, number>({
            query: (code) => ({
                method: "GET",
                url: `/categories/${code}`,
            }),
            providesTags: (result, err, code) => ([{ type: "Category", id: code }]),
        }),
        updateCategory: build.mutation<Category, Category.Update>({
            query: (body) => {
                const { code, ...data } = body
                return ({
                    method: "PUT",
                    url: `/categories/${code}`,
                    data
                })
            },
            invalidatesTags: (result, error, { code }) => [{ type: "Category", id: "LIST" }, { type: 'Category', id: code }],
        }),
        deleteCategory: build.mutation<void, string>({
            query: (code) => ({
                method: "DELETE",
                url: `/categories/${code}`,
            }),
            invalidatesTags: (result, error, code) => [{ type: "Category", id: "LIST" }, { type: 'Category', id: code }],
        }),
        retrieveSubCategoriesInCategory: build.query<Category[], number>({
            query: (code) => ({
                method: "GET",
                url: `/categories/${code}/sub_categories`,
            }),
            providesTags: (result, err, code) => ([
                { type: "Category", id: `Category_${code}` },
                ...(result || []).map(({ code }) => ({ type: "SubCategory", id: code } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});