import { AddToDrive, FileOpen, InsertDriveFile, } from "@mui/icons-material";
import { Box, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { TrainCase, TrainChat, } from "types/Train";
import { useLazyRetrieveTrainChatsQuery, } from "services/api/assistant";
import { ChatCreate } from "./ChatCreate";
import { ChatMenu } from "./ChatMenu";


interface ChatsProps {
    trainCase?: TrainCase;
    selected?: TrainChat;
    onChange?: (value?: TrainChat) => void;
}

function RenderStatus({ state }: { state: TrainChat['state'] }) {
    //"created" | "processing" | "completed" | "rejected" | "approved" | "archived"
    switch (state) {
        case "created":
            return <Chip label="CREADO" color="default" size="small" />;
        case "processing":
            return <Chip label="EN PROGRESO" color="info" size="small" />;
        case "completed":
            return <Chip label="COMPLETADO" color="primary" size="small" />;
        case "rejected":
            return <Chip label="RECHAZADO" color="error" size="small" />;
        case "approved":
            return <Chip label="APROBADO" color="success" size="small" />;
        case "archived":
            return <Chip label="ARCHIVADO" color="warning" size="small" />;
    }
}

export function Chats({
    trainCase,
    selected, onChange
}: ChatsProps) {
    const [hovered, setHovered] = useState<number | null>(null);
    const [create, setCreate] = useState<boolean>(false);

    const [trigger, { data }] = useLazyRetrieveTrainChatsQuery();

    useEffect(() => {
        if (trainCase)
            trigger(trainCase.id);
    }, [trigger, trainCase]);


    return (
        <Fragment>
            <List component="div" disablePadding dense>
                <ListItemButton sx={{ pl: 16 }} onClick={() => setCreate(true)}>
                    <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 2 }}>
                        <AddToDrive />
                    </ListItemIcon>
                    <ListItemText primary={"Nuevo"} secondary={"Crear chat"} />
                </ListItemButton>

                {data?.map((it) => (
                    <ListItem
                        key={it.id}
                        disablePadding
                        secondaryAction={hovered === it.id && <ChatMenu value={it} />}
                        onMouseEnter={() => setHovered(it.id)}
                        onMouseLeave={() => setHovered(null)}
                    >
                        <ListItemButton
                            sx={{ pl: 16 }}
                            selected={selected?.id === it.id}
                            onClick={() => onChange?.(it)}
                        >
                            <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 2 }}>
                                {selected?.id === it.id ? <FileOpen /> : <InsertDriveFile />}
                            </ListItemIcon>
                            <ListItemText primary={it.name} secondary={<Box>
                                <Stack direction="column" spacing={2}>
                                    <Typography noWrap variant="body2" color="textSecondary">
                                        {it.description}
                                    </Typography>
                                    <Stack direction="row">
                                        <RenderStatus state={it.state} />
                                    </Stack>
                                </Stack>
                            </Box>} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {(create && trainCase) &&
                <ChatCreate
                    open={create}
                    onClose={() => setCreate(false)}
                    caseId={trainCase?.id}
                />
            }
        </Fragment>
    );
}
