import { AdminAPI } from ".";
import { User } from "types/User";

export const injectAuth = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        auth: build.mutation<User.LoginResponse, User.LoginRequest>({
            query: (credentials) => ({
                method: "POST",
                url: "/auth",
                data: { ...credentials, type: "admin" }
            }),
        }),
    }),
    overrideExisting: "throw",
});