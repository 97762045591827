import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useNotification } from "contexts/NotificationContext";
import { useEffect, useMemo, useState } from "react";
import { useUpdateTrainMessageMutation } from "services/api/assistant";
import { UserMessage } from "types/Message";


interface UpdateUserProps {
    open: boolean;
    onClose: () => void;
    message: UserMessage & {
        id: number;
        chat_id: number;
    };
}

export function UpdateUser(props: UpdateUserProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const message = useMemo(() => props.message, [props.message]);

    const [execUpdate] = useUpdateTrainMessageMutation();
    const notify = useNotification();
    const [data, setData] = useState<Partial<UserMessage>>({});

    useEffect(() => setData(message), [message]);

    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        try {
            if (!data.content)
                throw new Error("El contenido es requerido!");

            const body: UserMessage = {
                role: "user",
                name: data.name,
                content: data.content
            }

            await execUpdate({ ...body, id: message.id, chat_id: message.chat_id });
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    }

    return (
        <FormModal
            title="Nuevo mensaje del usuario"
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={handleClose}
            onSubmit={handleSubmit}
            open={open}>
            <Box>
                <TextField
                    type="text"
                    name="name"
                    label="Nombre (opcional)"
                    value={data?.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                />
                <TextField
                    type="text"
                    name="content"
                    label="Contenido"
                    value={data?.content}
                    onChange={(e) => setData({ ...data, content: e.target.value })}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    minRows={3}
                    maxRows={5}
                    multiline
                    fullWidth
                    required
                />
            </Box>
        </FormModal >
    )
}