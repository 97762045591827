import { AttachFile, SendRounded } from "@mui/icons-material"
import { Box, FormControl, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material"
import { useState } from "react"
import { useCreateFollowUpMutation } from "services/api/admin";
import { Event } from "types/Event";

interface ComposerProps {
    incidentId?: Event['id'];
}

export function Composer(props: ComposerProps) {
    const [input, setInput] = useState<string>("");

    const [execCreate, { isSuccess, isError }] = useCreateFollowUpMutation();

    const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setInput(value);
    }

    const handleSendClick = async () => {
        await execCreate({
            description: input,
            incident_id: props.incidentId!!,
            user_id: 4
        });
        if (isSuccess)
            setInput("");
    }

    return (
        <Box sx={{ px: 2, pb: 3 }}>
            <FormControl fullWidth>
                <TextField
                    value={input}
                    onChange={handleChange}
                    placeholder="Escribe algo aquí..."
                    error={isError}
                    multiline
                    InputProps={{
                        style: { paddingLeft: 0, paddingRight: 0 },
                        startAdornment: <InputAdornment position="start">
                            <Tooltip title="Adjuntar">
                                <IconButton disabled>
                                    <AttachFile />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>,
                        endAdornment: <InputAdornment position="end">
                            <Tooltip title="Enviar">
                                <IconButton
                                    onClick={handleSendClick}
                                    onMouseDown={(e) => e.preventDefault()}>
                                    <SendRounded />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>,
                    }}
                />
            </FormControl>
        </Box>
    )
}