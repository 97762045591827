import { Conversation } from "../../../types/Conversation"
import { AssistantAPI } from ".";

export const injectConversation = (api: AssistantAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveConversations: build.query<Array<Conversation>, void>({
            query: () => ({
                method: "GET",
                url: "/conversations",
            }),
            providesTags: (result) => (
                result
                    ? [...result.map(({ id }) => ({ type: "Conversation", id } as const)), { type: "Conversation", id: 'LIST' }]
                    : [{ type: "Conversation", id: 'LIST' }]
            ),
        }),
        retrieveConversation: build.query<Conversation, Conversation['id']>({
            query: (id: Conversation['id']) => ({
                method: "GET",
                url: `/conversations/${id}`,
            }),
            providesTags: (result, err, id) => ([{ type: "Conversation", id }]),
        }),
        retrieveConversationMessages: build.query<Array<Conversation.Message>, Conversation['id']>({
            query: (id: Conversation['id']) => ({
                method: "GET",
                url: `/conversations/${id}/messages`,
            }),
            providesTags: (result, err, id) => ([{ type: "ConversationMessage", id: `Conversation_${id}` }]),
        })
    }),
    overrideExisting: "throw",
});