import { Box, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FormSchemaUI } from "components/FormSchemaUI/FormSchemaUI";
import { JSONViewer } from "components/JSONViewer/JSONViewer";
import { FormModal } from "components/Modal";
import { SelectToolFunction } from "components/Select/SelectToolFunction";
import { useMemo, useRef, useState } from "react";
import { AssistantMessage } from "types/Message";
import { TrainTool } from "types/Train";


interface UpdateToolFunctionProps {
    open: boolean;
    defaultValue: AssistantMessage.Tool;
    onClose: () => void;
    onSubmit: (data: AssistantMessage.Tool) => void;
}

export function UpdateToolFunction({ open, defaultValue, onClose, onSubmit }: UpdateToolFunctionProps) {
    const callId = useMemo(() => defaultValue.id, [defaultValue.id]);
    const [toolFunction, setToolFunction] = useState<TrainTool.Function<any>>();
    const [formData, setFormData] = useState<any>(defaultValue.function.arguments);

    const formRef = useRef<any>(null);

    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        if (!formRef.current)
            return false;

        const isValid = formRef.current.validateForm();
        if (!isValid)
            return false;

        onSubmit({
            id: callId!!,
            type: "function",
            function: {
                name: defaultValue.function.name,
                arguments: formData
            }
        });
        return true;
    }

    return (
        <FormModal
            title="Editar función"
            buttonPositive="Guardar"
            open={open}
            dialogProps={{ maxWidth: "md", fullWidth: true }}
            onClose={onClose}
            onSubmit={handleSubmit}>
            <Grid container spacing={4}>
                <Grid sm={6}>
                    <Box sx={{ overflow: "auto", height: "65vh" }}>
                        <TextField
                            margin="dense"
                            name="id"
                            label="ID de llamada"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={callId}
                            InputProps={{
                                readOnly: true,
                            }}
                            required
                        />
                        <SelectToolFunction
                            readOnly={true}
                            defaultValue={defaultValue.function.name}
                            onChange={setToolFunction} />
                        {toolFunction &&
                            <JSONViewer src={formData} displayObjectSize={false} />
                        }
                    </Box>
                </Grid>
                <Grid sm={6}>
                    <Box sx={{ overflow: "auto", height: "65vh" }}>
                        <FormSchemaUI
                            ref={formRef}
                            schema={toolFunction?.JSONSchema}
                            uiSchema={toolFunction?.UISchema}
                            defaultFormData={defaultValue.function.arguments}
                            onChange={setFormData}
                        />
                    </Box>
                </Grid>
            </Grid>
        </FormModal>
    )
}