import { Breadcrumbs, Link, LinkProps, Stack, Typography } from "@mui/material";
import { Outlet, useLocation, Link as RouterLink } from "react-router-dom";


const breadcrumbNameMap: { [key: string]: string } = {
    '/assistant': 'Asistente',
    '/assistant/conversations': 'Conversaciones',
    '/assistant/completions': 'Agente virtual',
    '/assistant/trains': 'Entrenamiento',
    '/assistant/settings': 'Ajustes'
};

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}


function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
}


export function AssistantLayout() {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <Stack spacing={1}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return last ? (
                        <Typography variant="overline" color="text.primary" key={to}>
                            {breadcrumbNameMap[to]}
                        </Typography>
                    ) : (
                        <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                            <Typography variant="overline">
                                {breadcrumbNameMap[to]}
                            </Typography>
                        </LinkRouter>
                    );
                })}
            </Breadcrumbs>
            <Outlet />
        </Stack>
    )
}