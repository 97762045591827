import { Checkbox, Chip } from '@mui/material';
import { teal } from '@mui/material/colors';
import { ButtonLink } from 'components/Button/ButtonLink';
import { Content } from 'features/Content/Content';
import { EnhancedTable } from 'features/EnhancedTable/EnhancedTable';


declare type EmergencyType = {
    id: number;
    quien: string;
    afectado: boolean;
    date: string;
    direccion: string;
    descripcion: string;
    tipo: string;
    estado: string;
}

const data: EmergencyType[] = [{
    id: 30900,
    date: new Date().toISOString(),
    quien: 'Jaime Barrios',
    afectado: false,
    direccion: 'Calle x no. y',
    descripcion: 'Hay una persona de edad mayor con COVID',
    tipo: 'Enfermedad general',
    estado: 'Pendiente',
}, {
    id: 30901,
    quien: 'Pedro Perez',
    afectado: true,
    date: new Date().toISOString(),
    direccion: 'Calle 79 con 83 esquina en Barranquilla',
    descripcion: 'Están extorsionando, piden dinero a cambio de no atentar contra la vida ni la de mi familia.',
    tipo: 'Extorsión',
    estado: 'Pendiente',
}];

export function EmergenciesPage() {
    return (
        <Content title='Emergencias' hasFilter>
            <EnhancedTable
                size={data.length}
                columns={[
                    { label: 'Código', key: 'id' },
                    { label: '¿Quién reporta?', key: 'quien' },
                    {
                        label: '¿Está afectado?', key: 'afectado',
                        renderCell(value, params) {
                            return <Checkbox checked={params.afectado === true} disabled sx={{
                                color: teal[800],
                                '&.Mui-checked': {
                                    color: teal[600],
                                },
                            }} />
                        },
                    },
                    { label: 'Dirección', key: 'direccion' },
                    { label: 'Descripción', key: 'descripcion' },
                    { label: 'Tipo', key: 'tipo' },
                    {
                        label: 'Fecha', key: 'date',
                        renderCell(value, params) {
                            return value
                        },
                    },
                    {
                        label: 'Estado', key: 'estado', renderCell(value, params) {
                            return <Chip label={value} color="primary" size="small" variant="outlined" />
                        },
                    },
                    {
                        label: 'Acciones', renderCell(value, params) {
                            return (<ButtonLink to={`${value}`} >Detalle</ButtonLink>)
                        },
                    },
                ]}
                data={data}
                onPageChange={() => ({})}
            />
        </Content>
    );
}