import { Add, DataObject, Delete, Edit } from "@mui/icons-material";
import { Button, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { CreateToolFunction } from "./CreateToolFunction";
import { UpdateToolFunction } from "../../update/assistant/UpdateToolFunction";
import { AssistantMessage } from "types/Message";

interface CreateAssistantToolsProps {
    onChange: (tools: AssistantMessage.Tool[]) => void;
}

export function CreateAssistantTools(props: CreateAssistantToolsProps) {
    const onChange = useMemo(() => props.onChange, [props.onChange]);

    const [data, setData] = useState<Array<AssistantMessage.Tool>>([]);
    const [creating, setCreate] = useState<boolean>(false);
    const [updating, setUpdate] = useState<AssistantMessage.Tool>();
    const [hovered, setHovered] = useState<number | null>(null);

    useEffect(() => onChange(data), [data, onChange]);

    return (
        <Fragment>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper', mt: 0 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <>
                        <ListSubheader>Herramientas</ListSubheader>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            startIcon={<Add />}
                            onClick={() => setCreate(true)}
                            sx={{ mb: 4 }}
                        >
                            Agregar Función
                        </Button>
                    </>
                }
            >
                {data.map((it, index) => (
                    <ListItemButton
                        key={it.id}  // Añadido para mejorar el rendimiento
                        sx={{ pl: 4 }}
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                    >
                        <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 4 }}>
                            <DataObject />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary={it.function.name}
                            secondary={JSON.stringify(it.function.arguments)} />
                        {hovered === index &&
                            <Fragment>
                                <IconButton edge="start" aria-label="edit" color="default" onClick={() => setUpdate(it)}>
                                    <Edit />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" color="error" >
                                    <Delete />
                                </IconButton>
                            </Fragment>
                        }
                    </ListItemButton>
                ))}
            </List>

            <CreateToolFunction
                open={creating}
                onClose={(e) => {
                    setCreate(false)
                }}
                onSubmit={(it) => setData((prev) => ([...prev, it]))}
            />

            {updating && <UpdateToolFunction
                open={!!updating}
                defaultValue={updating}
                onClose={() => setUpdate(undefined)}
                onSubmit={(value) => setData((prev) => (prev.map((it) => (it.id === value.id ? value : it))))}
            />}

        </Fragment>
    )
}