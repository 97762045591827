import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { BaseQueryFn, TypedUseQuery } from "@reduxjs/toolkit/dist/query/react";
import { AxiosRequestConfig } from "axios";
import { SyntheticEvent, useEffect, useState } from "react";


declare type Data = { [key: string]: string | number | boolean | null | undefined; };

interface AutocompleteTypesProps<D extends Data, QueryArg> {
    query: TypedUseQuery<D[], QueryArg, BaseQueryFn<AxiosRequestConfig<D[]>>>;
    queryArg: QueryArg;
    dataKey: keyof D;
    dataValue: keyof D;
    dataGroup?: keyof D;
    defaultValue?: string | number | boolean;
    name?: TextFieldProps['name'];
    label?: TextFieldProps['label'];
    TextField?: TextFieldProps;
    onChangeValue?: (item: D | null) => void;
    disabled?: boolean;
}


export function AutocompleteQuery<D extends Data, QueryArg>(props: AutocompleteTypesProps<D, QueryArg>) {
    const { data, isLoading, isFetching } = props.query(props.queryArg);
    const [value, setValue] = useState<D | null>(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (props.defaultValue && !value) {
            const value = data?.find((item) => (item[props.dataKey] === props.defaultValue));
            if (value) {
                setValue(value);
            }
        }
    }, [data, props.dataKey, props.defaultValue, value]);

    useEffect(() => {
        if (props.onChangeValue) {
            props.onChangeValue(value);
        }
    }, [value, props, props.onChangeValue]);

    return (
        <Autocomplete
            disablePortal
            fullWidth
            loading={isLoading || isFetching}
            options={data || []}
            getOptionLabel={(item: D) => item[props.dataValue] as string}
            getOptionKey={(item: D) => item[props.dataKey] as string}
            value={value}
            onChange={(_: SyntheticEvent, newValue: D | null) => setValue(newValue)}
            inputValue={inputValue}
            disabled={props.disabled}
            onInputChange={(_: SyntheticEvent, newInputValue: string) => setInputValue(newInputValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={props.name}
                    label={props.label}
                    {...props.TextField}
                />
            )}
        />
    );
}