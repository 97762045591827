import { Autocomplete, TextField } from "@mui/material";
import { LatLngBounds } from "leaflet";
import { SyntheticEvent, useEffect, useState } from "react";
import { Nominatim, useLazySearchQuery } from "services/api/nominatimApi";

interface AutocompleteSearchPlaceProps {
    valueField: keyof Nominatim;
    displayField: keyof Nominatim;
    value?: Nominatim;
    viewbox?: LatLngBounds;
    onChangeValue?: (item: Nominatim | null) => void;
}


export function AutocompleteSearchPlace(props: AutocompleteSearchPlaceProps) {
    const [value, setValue] = useState<Nominatim | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [execQuery, { data, isLoading, isFetching }] = useLazySearchQuery();

    useEffect(() => {
        if (!props.value) {
            setValue(null);
            return;
        }
        const dataValue = props.value[props.displayField] as string;
        setValue(props.value);
        setInputValue(dataValue);
    }, [props.displayField, props.value]);

    useEffect(() => {
        if (inputValue) {
            execQuery({
                query: inputValue,
                viewbox: props.viewbox
            });
        }
    }, [inputValue, execQuery, props.viewbox]);

    useEffect(() => {
        if (props.onChangeValue) {
            props.onChangeValue(value);
        }
    }, [value, props, props.onChangeValue]);

    return (
        <Autocomplete
            disablePortal
            fullWidth
            loading={isLoading || isFetching}
            options={data || []}
            getOptionLabel={(item: Nominatim) => item[props.displayField] as string}
            getOptionKey={(item: Nominatim) => item[props.valueField] as string}
            value={value}
            onChange={(_: SyntheticEvent, newValue: Nominatim | null) => setValue(newValue)}
            inputValue={inputValue}
            onInputChange={(_: SyntheticEvent, newInputValue: string) => setInputValue(newInputValue)}
            renderInput={(params) => (<TextField label="Direccion" name="address" {...params} />)}
        />
    )
}