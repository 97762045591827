import { Box, Stack } from "@mui/material";
import { Message, MessageSource } from "./Message";
import { BubbleGroup } from "./BubbleGroup";

interface Props {
    messages: Message[];
}

export function ChatUI(props: Props) {
    const getPosition = (input: Message) => {
        switch (input.Source) {
            case MessageSource.USER:
                return "left";
            case MessageSource.ASSISTANT:
            case MessageSource.AGENT:
                return "right";
            case MessageSource.SYSTEM:
                return "center"
        }
    }

    const normalize = (input: Message[]) => {
        if (input.length === 0) {
            return [];
        }
        const output: Array<["left" | "center" | "right", Array<Message>]> = [];
        let group: Array<Message> = [];
        let last: Message | undefined = undefined;

        for (let i = 0; i < input.length; i++) {
            const element = input[i];
            if (!last) {
                last = element;
                group = [element];
                continue
            } else if (element.Source === last.Source) {
                last = element;
                group.push(element);
                continue;
            } else {
                output.push([getPosition(last), group]);
                last = element;
                group = [element];
            }
        }

        output.push([getPosition(last!!), group]);
        return output;
    }

    return (
        <Box display="flex" flexDirection="column">
            <Stack spacing={2} sx={{ width: '95%', maxWidth: '100%', marginBottom: 2 }}>
                {normalize(props.messages)
                    .map(([position, messages]) => (<BubbleGroup messages={messages} position={position} />))
                }
            </Stack>
        </Box>
    );
}