import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import { ChartMap } from "components/charts";
import atlantico from "data/atlantico";


interface Props {

}

export function WidgetMap(props: Props) {


    const mapSeries = (map: am5map.MapPolygonSeries) => {
        map.setAll({
            valueField: "value",
            calculateAggregates: true,
        });

        map.mapPolygons.template.setAll({
            tooltipText: "[bold]{name}[/]",
            toggleKey: "active",
            interactive: true,
            templateField: "polygonSettings",
        });

        map.mapPolygons.template.states.create("hover", {
            fill: am5.color(0x677935),
        });

        map.mapPolygons.template.states.create("active", {
            fill: am5.color(0x677935)
        });

        let previousPolygon: any;
        map.mapPolygons.template.on("active", function (active, target: am5map.MapPolygon | undefined) {
            if (previousPolygon && previousPolygon !== target) {
                previousPolygon.set("active", false);
            }
            if (target?.get("active")) {
                const dataItem: am5.DataItem<am5map.IMapPolygonSeriesDataItem> = target.dataItem as am5.DataItem<am5map.IMapPolygonSeriesDataItem>;
                map.zoomToDataItem(dataItem);
            } else {
                map.chart?.goHome();
            }
            previousPolygon = target;
        });

        /*
        map.set("heatRules", [{
            target: map.mapPolygons.template,
            dataField: "value",
            min: am5.color(0x009688),
            max: am5.color(0xF44336),
            minValue: 0,
            maxValue: 100,
            key: "fill"
        }]);
        */

        map.data.setAll([
            { id: "08001", value: 10, polygonSettings: { fill: am5.color(0x795dec) } },
            { id: "08078", value: 20, polygonSettings: { fill: am5.color(0xa7cceb) } },
            { id: "08137", value: 30, polygonSettings: { fill: am5.color(0x87da63) } },
            { id: "08141", value: 40, polygonSettings: { fill: am5.color(0xabebb8) } },
            { id: "08296", value: 50, polygonSettings: { fill: am5.color(0xea1ac4) } },
            { id: "08372", value: 60, polygonSettings: { fill: am5.color(0xea356b) } },
            { id: "08421", value: 70, polygonSettings: { fill: am5.color(0x6ee9a5) } },
            { id: "08433", value: 80, polygonSettings: { fill: am5.color(0xb1e275) } },
            { id: "08436", value: 90, polygonSettings: { fill: am5.color(0xe5795e) } },
            { id: "08520", value: 100, polygonSettings: { fill: am5.color(0x61d4ce) } },
            { id: "08549", value: 10, polygonSettings: { fill: am5.color(0x7277d8) } },
            { id: "08558", value: 20, polygonSettings: { fill: am5.color(0xcfe969) } },
            { id: "08560", value: 30, polygonSettings: { fill: am5.color(0xd5cf62) } },
            { id: "08573", value: 40, polygonSettings: { fill: am5.color(0xd69056) } },
            { id: "08606", value: 50, polygonSettings: { fill: am5.color(0xd05e9d) } },
            { id: "08634", value: 60, polygonSettings: { fill: am5.color(0xd73c44) } },
            { id: "08638", value: 70, polygonSettings: { fill: am5.color(0x1aca11) } },
            { id: "08675", value: 80, polygonSettings: { fill: am5.color(0x9458dd) } },
            { id: "08685", value: 90, polygonSettings: { fill: am5.color(0xcaa754) } },
            { id: "08758", value: 100, polygonSettings: { fill: am5.color(0x446cc8) } },
            { id: "08770", value: 10, polygonSettings: { fill: am5.color(0xe223f0) } },
            { id: "08832", value: 20, polygonSettings: { fill: am5.color(0x7ce1c3) } },
            { id: "08849", value: 30, polygonSettings: { fill: am5.color(0xb647e5) } },
        ]);
    }

    const mapPointsSeries = (series: am5map.MapPointSeries) => {

        // Create regular bullets
        series.bullets.push(function () {
            var circle = am5.Circle.new(series.root, {
                radius: 5,
                fill: am5.color(0xff0000),
                tooltipText: "{title}"
            });

            circle.events.on("click", function (ev) {
                //alert("Clicked on " + ev.target.dataItem?.dataContext?.name)
            });

            return am5.Bullet.new(series.root, {
                sprite: circle
            });
        });

        series.data.setAll([
            { title: "Evento 1", geometry: { type: "Point", coordinates: [-74.805852, 10.979952,] } },
            { title: "Evento 2", geometry: { type: "Point", coordinates: [-74.80539591501666, 10.93905915652754] } },
            { title: "Evento 3", geometry: { type: "Point", coordinates: [-74.89184794379804, 10.956148264730354] } },
            { title: "Evento 4", geometry: { type: "Point", coordinates: [-74.820647, 10.9916357] } },
            { title: "Evento 5", geometry: { type: "Point", coordinates: [-74.92508299612145, 10.627360112564938] } },
            { title: "Evento 6", geometry: { type: "Point", coordinates: [-74.95621486647345, 10.325742522199716] } },
            { title: "Evento 7", geometry: { type: "Point", coordinates: [-75.12539399925508, 10.495495872381296] } },
            { title: "Evento 8", geometry: { type: "Point", coordinates: [-75.10349764601563, 10.74815457607356] } },
            { title: "Evento 9", geometry: { type: "Point", coordinates: [-74.97955998844796, 10.740544273048538] } },
        ]);
    }

    return (
        <ChartMap
            id='map'
            geodata={atlantico}
            mapSeries={mapSeries}
            pointSeries={mapPointsSeries}
            zoomControl />
    )
}