import { useEffect, useState } from "react";
import { Chip, ListItem, ListItemText, MenuItem, MenuList, Paper, Stack, Toolbar, Typography } from "@mui/material";
import moment from "moment";

import { useNotification } from "contexts/NotificationContext";
import { Completion } from "types/Completion";
import { useRetrieveCompletionsQuery } from "services/api/assistant";
import { useSearchParams } from "react-router-dom";



interface CompletionsProps {
    onSelected?: (item?: Completion) => void;
}

export function Completions({ onSelected }: CompletionsProps) {
    const [selected, setSelected] = useState<Completion>();
    const [searchParams] = useSearchParams();
    const completionId = searchParams.get("id"); // Obtener el ID de la URL como string

    const { data, isError, error } = useRetrieveCompletionsQuery();
    const notify = useNotification();

    useEffect(() => {
        if (isError)
            notify.http(error);
    }, [error, isError, notify]);

    useEffect(() => {
        onSelected?.(selected);
    }, [onSelected, selected]);

    useEffect(() => {
        if (!data || data.length === 0) return;
        if (selected) return;
        const idNumber = completionId ? parseInt(completionId, 10) : null;

        const foundCompletion = data.find((it) => it.id === idNumber);
        if (foundCompletion) {
            setSelected(foundCompletion);
        } else if (!selected) {
            setSelected(data[0]); // Si no hay ninguno seleccionado, seleccionar el primero
        }
    }, [data, selected, completionId]);

    return (
        <Paper sx={{ height: "80vh", overflow: "auto" }}>
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, }}>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="contentTitle"
                    component="div">
                    {"Conversaciones"}
                </Typography>
            </Toolbar>
            <MenuList sx={{ paddingY: 0, backgroundColor: "background.paper", height: "85%" }}>
                {data?.map((it: Completion) => (
                    <MenuItem
                        key={it.id}
                        onClick={() => setSelected(it)}
                        selected={selected && selected.id === it.id}
                    >
                        <ListItem>
                            <ListItemText
                                primary={<Typography noWrap variant="body1">{it.id}</Typography>}
                                secondary={
                                    <Typography noWrap variant="body2" color="textSecondary">
                                        <Stack direction="row" spacing={2}>
                                            <Chip label={moment(it.created_at).locale("es").format('DD [de] MMMM, hh:mm a')} variant="outlined" size="small" />
                                        </Stack>
                                    </Typography>}
                            />
                        </ListItem>
                    </MenuItem>
                ))}
            </MenuList>
        </Paper>
    )
}