import { ArrowDropDown, Person2 } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Chip, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { TabPanel } from "components/Tabs/Tabs";


interface Props {
    value: any;
}

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

function Item(props: { title: string, value?: string }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
            <Typography variant="caption" color="primary" fontWeight="700">{props.title}: </Typography>
            <Typography variant="caption" color="text.primary" noWrap>{props.value}</Typography>
        </Box>
    )
}

export function TabEmergencyContact(props: Props) {
    return (
        <TabPanel value={props.value}>
            <Box className="Scrollbar" sx={{ width: '100%', p: 2, height: '100%', overflow: 'auto' }}>
                <Stack direction="column" >
                    <Stack direction="row" marginX={2}>
                        <Box sx={{ margin: 2, width: 64 }}>
                            <Avatar src="https://mui.com/static/images/avatar/2.jpg" alt="Photo" sx={{ width: 56, height: 56 }} />
                        </Box>
                        <Stack direction="column">
                            <Item title="Nombres" value="Pedro José" />
                            <Item title="Apellidos" value="Perez Paternina" />
                            <Item title="Identificación" value="1.034.098.567" />
                            <Item title="Telefono" value="300 913 58 33" />
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={4}>
                        <Item title="Sangre" value="RH O+" />
                        <Item title="Peso" value="80 KG" />
                        <Item title="Altura" value="170 cm" />
                        <Item title="Edad" value="35 AÑOS" />
                    </Stack>
                    <Chip label="DATOS RESIDENCIALES" color="primary" sx={{ marginTop: 2 }} />
                    <Stack direction="column" marginX={2}>
                        <Item title="Municipio" value="BARRANQUILLA - ATLÁNTICO" />
                        <Item title="Poblado" value="BARRANQUILLA. DISTRITO ESPECIAL. INDUSTRIAL Y PORTUARIO" />
                        <Item title="Dirección" value="CLL 30 # 43 - 23" />
                    </Stack>
                </Stack>
            </Box>
        </TabPanel>
    )
}