import { Folder, Settings } from "@mui/icons-material";
import { Box, List, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ReactNode, useState } from "react";

interface MenuOption {
    id: number;
    icon: ReactNode;
    title: string;
    subtitle?: string;
}

const data: Array<MenuOption> = [
    { id: 1, icon: <Settings />, title: "General", subtitle: "Configuracion general" }
];


export function SettingsPage() {
    const [selected, setSelected] = useState<MenuOption>(data[0]);

    return (
        <Grid container spacing={.5}>
            <Grid xs={3}>
                <Paper sx={{ height: "80vh", overflow: "auto" }}>
                    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, }}>
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="contentTitle"
                            component="div">
                            {"Configuración del asistente"}
                        </Typography>
                    </Toolbar>
                    <MenuList sx={{ paddingY: 0, backgroundColor: "background.paper", height: "85%" }}>
                        {data.map((it) => (
                            <MenuItem
                                key={it.id}
                                onClick={() => setSelected(it)}
                                selected={selected && selected.id === it.id}
                            >
                                <ListItem>
                                    <ListItemIcon>{it.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={<Typography noWrap variant="body1">{it.title}</Typography>}
                                        secondary={<Typography noWrap variant="body2" color="textSecondary">{it.subtitle}</Typography>}
                                    />
                                </ListItem>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Paper>
            </Grid>
            <Grid xs={6}>
                <Paper sx={{ height: "80vh", backgroundColor: 'background.default' }}>
                    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, backgroundColor: 'background.paper' }}>
                        <Typography
                            variant="h6"
                            id="contentTitle"
                            component="div">
                            {selected.title}
                        </Typography>
                    </Toolbar>
                    <Box sx={{ height: "70vh", p: 4, overflow: "auto" }}>
                        <List dense>
                            <ListItem>
                                <ListItemText
                                    primary="Modelo"
                                    secondary="ft:gpt-3.5-turbo-1106:viatrans:tured123:9eredD4g"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Instrucciones personalizadas"
                                    secondary="ALEX es un agente virtual diseñado para gestionar emergencias las 24 horas del día. Especializado en clasificar la urgencia de las solicitudes y proporcionar apoyo emocional cuando sea necesario. Opera bajo estrictos protocolos de seguridad y privacidad para garantizar la protección de los datos del usuario. Diseñado para mejorar la eficiencia en la gestión de recursos y asegurar una respuesta coordinada y efectiva en todo momento."
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Paper>
            </Grid>
            <Grid xs={3}>
            </Grid>
        </Grid>
    )
}