import { Event, FollowUp } from "types/Event";
import { AdminAPI } from ".";


export const injectEvent = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveEvents: build.query<Event[], void>({
            query: () => ({
                url: "/events",
                method: "GET"
            }),
            providesTags: (result) => ([
                { type: "Event", id: `LIST` },
                ...(result || []).map(({ id }) => ({ type: "Event", id } as const))
            ]),
        }),
        createEvent: build.mutation<Event, Event.Create>({
            query: (body) => ({
                url: "/events",
                method: "POST",
                data: body
            }),
            invalidatesTags: [{ type: "Event", id: "LIST" }]
        }),
        retrieveEvent: build.query<Event, string | number>({
            query: (id) => ({
                url: `/events/${id}`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([{ type: "Event", id }]),
        }),
        retrieveFollowUpsEvent: build.query<FollowUp[], string | number>({
            query: (id) => ({
                url: `/events/${id}/follow_ups`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([
                { type: "Event", id: `FollowUp_${id}` },
                ...(result || []).map(({ id }) => ({ type: "FollowUp", id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});