import { AdminAPI } from ".";
import { Type } from "types/Category";

export const injectType = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveTypes: build.query<Type[], void>({
            query: () => ({
                url: "/types",
                method: "GET"
            }),
            providesTags: (result) => ([
                { type: "Type", id: `LIST` },
                ...(result || []).map(({ code }) => ({ type: "Type", id: code } as const))
            ]),
        }),
        createType: build.mutation<Type, Type.Create>({
            query: (body) => ({
                url: "/types",
                method: "POST",
                data: body
            }),
            invalidatesTags: [{ type: "Type", id: "LIST" }]
        }),
        retrieveType: build.query<Type, Type['code']>({
            query: (code) => ({
                url: `/types/${code}`,
                method: "GET"
            }),
            providesTags: (result, err, code) => ([{ type: "Type", id: code }]),
        }),
        updateType: build.mutation<Type, Type.Update>({
            query: (body) => {
                const { code, ...data } = body
                return ({
                    url: `/types/${code}`,
                    method: "PUT",
                    data
                })
            },
            invalidatesTags: (result, error, { code }) => [{ type: "Type", id: "LIST" }, { type: "Type", id: code }],
        }),
        deleteType: build.mutation<void, Type['code']>({
            query: (code) => ({
                url: `/types/${code}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, code) => [{ type: "Type", id: "LIST" }, { type: "Type", id: code }],
        })
    }),
    overrideExisting: "throw",
});