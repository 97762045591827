import { Box, Paper, Stack, Typography } from "@mui/material";
import { AvatarWithBadge } from "components/Avatar/AvatarWithBadge";
import { RelativeTime } from "components/RelativeTime/RelativeTime";
import { Conversation } from "types/Conversation";


interface BubbleProps {
    content: Conversation.Message;
    variant?: 'sent' | 'received';
}

export function Bubble({ content, variant = 'received' }: BubbleProps) {

    return (
        <Stack
            direction="row"
            spacing={2}
            flexDirection={variant === 'sent' ? "row-reverse" : "row"}>
            <AvatarWithBadge
                Organization={(variant === 'received') ? { alt: `User ${content.name} from Organization` } : undefined}
                Avatar={{ alt: `User ${content.id} owner` }} />
            <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
                <Box sx={{ position: 'relative' }}>
                    <Paper
                        sx={{
                            p: 1.25,
                            borderRadius: '16px',
                            borderTopRightRadius: variant === "sent" ? 0 : '16px',
                            borderTopLeftRadius: variant === "sent" ? '16px' : 0,
                            bgcolor: variant === "sent" ? 'primary.main' : 'background.default',
                            mx: 1,
                            mt: 2,
                        }}>
                        <Typography
                            variant="body2"
                            sx={{ color: variant === "sent" ? 'common.white' : 'text.primary', }}>
                            {content.content}
                        </Typography>
                        <Typography
                            variant="body2"
                            align={variant === "sent" ? "left" : "right"}
                            color={variant === "sent" ? 'common.white' : 'text.primary'}
                            sx={{ fontSize: '.7em', fontWeight: 'normal' }}
                        >
                            <RelativeTime date={content.created_at} />
                        </Typography>
                    </Paper>
                </Box>
            </Box>
        </Stack>
    )
}