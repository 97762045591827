import { Map } from "leaflet";
import { useCallback, useMemo, useState } from "react";
import { MapContainerProps, Rectangle, useMap, useMapEvent } from "react-leaflet";

const BOUNDS_STYLE = { weight: 1 }

interface MinimapBoundsProps {
    parentMap: Map;
    zoom?: MapContainerProps['zoom'];
}

export function MinimapBounds({ parentMap, zoom }: MinimapBoundsProps) {
    const minimap = useMap();
    const onClick = useCallback((e: any) => parentMap.setView(e.latlng, parentMap.getZoom()), [parentMap])
    useMapEvent('click', onClick)

    const [bounds, setBounds] = useState(parentMap.getBounds())
    const onChange = useCallback(() => {
        setBounds(parentMap.getBounds())
        minimap.setView(parentMap.getCenter(), zoom)
    }, [minimap, parentMap, zoom]);

    const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [onChange])
    parentMap.addEventListener("move", handlers.move)
    parentMap.addEventListener("zoom", handlers.zoom)
    return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
}