import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container, CssBaseline, Theme, ThemeProvider, Toolbar, Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material';
import { Navbar } from 'features/Navbar/Navbar';
import { Sidebar } from 'features/Sidebar/Sidebar';

const drawerWidth: number = 240;

interface Props {
    theme: Theme;
}

export function AdminLayout(props: Props) {
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={props.theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline enableColorScheme />
                <Navbar open={open} drawerWidth={drawerWidth} toggleDrawer={toggleDrawer} />
                <Sidebar open={open} width={drawerWidth} toggleDrawer={toggleDrawer} />
                <Box component="main">
                    <Toolbar />
                    <Container maxWidth={open ? "lg" : "xl"} sx={{ mt: 2, mb: 2, width: open ? '82vw' : '95.5vw' }}>
                        <Outlet />
                        {/*<Copyright sx={{ pt: 4 }} />*/}
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}