import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { darkTheme, lightingTheme } from "theme/theme";
import { AuthLayout } from 'layout/AuthLayout';
import { RequireAuthLayout } from 'layout/RequireAuthLayout';
import { AdminLayout } from 'layout/AdminLayout';
import { HomePage } from 'pages/Home/HomePage';
import { EmergenciesPage } from 'pages/Emergencies/EmergenciesPage';
import { EmergencyPage } from 'pages/Emergencies/EmergencyPage';
import { NotFoundPage } from 'pages/HttpError/NotFoundPage';
import { DashboardLayout } from 'layout/DashboardLayout';
import { DashboardPage } from 'pages/Dashboard/DashboardPage';
import { InstitutionsPage } from 'pages/Institutions/InstitutionsPage';
import { SystemLayout } from 'layout/SystemLayout';
import { LoginPage } from 'pages/Login/LoginPage';
import { DepartamentsPage } from 'pages/System/Departaments/DepartamentsPage';
import { CitiesPage } from 'pages/System/Cities/CitiesPage';
import { EventPage } from 'pages/Events/EventPage';
import { usePaletteMode } from 'hooks/usePaletteMode';
import { useEffect, useState } from 'react';
import { Theme } from '@mui/material';
import { AssistantPage } from 'pages/Assistant/AssistantPage';

import { SettingsPage } from 'pages/Assistant/Settings/SettingsPage';
import { CompletionsPage } from 'pages/Assistant/Completions/CompletionsPage';
import { ConversationsPage } from 'pages/Assistant/Conversations/ConversationsPage';
import { AssistantLayout } from 'layout/AssistantLayout';
import { TrainsPage } from 'pages/Assistant/Trains/TrainsPage';
import { CategoriesPage } from 'pages/System/Categories/CategoriesPage';
import { SubCategoriesPage } from 'pages/System/SubCategories/SubCategoriesPage';
import { TypesPage } from 'pages/System/Types/TypesPage';
import { EventsPage } from 'pages/Events/EventsPage';
import { SettlementsPage } from 'pages/System/Settlements/SettlementsPage';


function App() {
  const { paletteMode } = usePaletteMode();

  const [theme, setTheme] = useState<Theme>(lightingTheme);
  useEffect(() => {
    setTheme((paletteMode === "light" ? lightingTheme : darkTheme))
  }, [paletteMode])

  return (
    <Routes>
      <Route element={<AuthLayout theme={lightingTheme} />}>
        <Route path="/login" index element={<LoginPage />} />
      </Route>
      <Route element={<RequireAuthLayout />}>
        <Route element={<AdminLayout theme={theme} />}>
          <Route index element={<HomePage />} />
          <Route path='system' element={<SystemLayout />} >
            <Route path='categories' element={<CategoriesPage />} />
            <Route path='sub_categories' element={<SubCategoriesPage />} />
            <Route path='types' element={<TypesPage />} />

            <Route path='departments' element={<DepartamentsPage />} />
            <Route path='cities' element={<CitiesPage />} />
            <Route path='settlements' element={<SettlementsPage />} />

            <Route element={<NotFoundPage />} path='*' />
          </Route>

          <Route path='events'>
            <Route index element={<EventsPage />} />
            <Route path=':eventId' element={<EventPage />} />

            <Route element={<NotFoundPage />} path='*' />
          </Route>

          <Route path='/emergencies'>
            <Route index element={<EmergenciesPage />} />
            <Route path=':emergencyId' element={<EmergencyPage />} />
          </Route>
          <Route path='/institutions'>
            <Route index element={<InstitutionsPage />} />
          </Route>

          <Route path='assistant' element={<AssistantLayout />}>
            <Route index element={<AssistantPage />} />
            <Route path='completions' element={<CompletionsPage />} />
            <Route path='conversations' element={<ConversationsPage />} />
            <Route path='trains' element={<TrainsPage />} />
            <Route path='settings' element={<SettingsPage />} />
          </Route>

          <Route element={<NotFoundPage />} path='*' />
        </Route>

        <Route path='/dashboard' element={<DashboardLayout />} >
          <Route index element={<DashboardPage />} />
          <Route element={<NotFoundPage />} path='*' />
        </Route>
      </Route>
      <Route element={<Navigate to="/" state={{ from: useLocation() }} replace />} path='*' />

    </Routes>
  );
}

export default App;
