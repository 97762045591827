import { AdminAPI } from ".";

export const injectAccount = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        me: build.query({
            query: () => ({
                method: "GET",
                url: "accounts/me"
            }),
        }),
    }),
    overrideExisting: "throw",
});