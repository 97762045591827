import { Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Message } from "./Message";

function hexToRGB(hex: string, alpha: number = 1) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

const SytledCenter = styled(Box)(({ theme }) => ({
    fontSize: '.8em',
    fontWeight: 500,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    boxShadow: "0px 0px 0px 0.3px rgba(0,0,0,0.2)"
}));

const StyledLeft = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.common.black : theme.palette.common.white,
    fontSize: '.9em',
    fontWeight: 500,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 0px 0px 0.3px rgba(0,0,0,0.2)"
}));

const StyledRight = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '.9em',
    fontWeight: 500,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    boxShadow: `0px 0px .5px 1px ${hexToRGB(theme.palette.primary.dark)}`
}));


interface Props {
    message: Message;
    position: "left" | "center" | "right";
    index: number;
}

export function Bubble(props: Props) {
    if (props.position === "center") {
        return (
            <SytledCenter>
                {props.message.Text}
            </SytledCenter>
        )
    }

    if (props.position === "left") {
        return (
            <StyledLeft>
                {props.message.Text}
                <Box>
                    <Typography sx={{ fontSize: '.8em' }} align="right">{"10:48"}</Typography>
                </Box>
            </StyledLeft>
        )
    }

    return (
        <StyledRight>
            {props.message.Text}
            <Box>
                <Typography sx={{ fontSize: '.8em' }} align="right">
                    {props.message.Source}{" • "}{"10:48"}
                </Typography>
            </Box>
        </StyledRight>
    )
}