import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Conversations } from "./Conversations";
import { useState } from "react";
import { Messages } from "./Messages";
import { ConversationDetails } from "./ConversationDetails";
import { Conversation } from "types/Conversation";


export function ConversationsPage() {
    const [conversation, setConversation] = useState<Conversation>();

    return (
        <Grid container spacing={.5}>
            <Grid xs={3}>
                <Conversations onSelected={setConversation} />
            </Grid>
            <Grid xs={6}>
                <Messages conversation={conversation} />
            </Grid>
            <Grid xs={3}>
                <ConversationDetails conversation={conversation} />
            </Grid>
        </Grid>
    )
}