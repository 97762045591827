import { TabPanel } from "components/Tabs/Tabs";

interface Props {
    value: any;
}

export function TabEmergencyChatAttachment(props: Props) {
    return (
        <TabPanel value={props.value}>
            <p>TabEmergencyChatAttachment</p>
        </TabPanel>
    )
}