import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, TextField, Typography } from "@mui/material";
import { ReactNode, useMemo, useState } from "react";

export interface ConfirmModalProps {
    title: string;
    contentText?: string | undefined;
    buttonPositive?: string | undefined;
    buttonNegative?: string | undefined;
    children?: ReactNode;
    open: DialogProps['open'];
    onClose: DialogProps['onClose'];
    onContinue: () => Promise<void>;
    confirmationText?: string; // Nuevo prop para el texto de confirmación requerido
}

export function ConfirmModal(props: ConfirmModalProps) {
    const onClose = useMemo(() => props.onClose ? props.onClose : () => { }, [props.onClose])
    const onContinue = useMemo(() => props.onContinue ? props.onContinue : async () => { }, [props.onContinue])

    const [disabled, setDisabled] = useState<boolean>(false);
    const [inputText, setInputText] = useState<string>(); // Estado para el texto ingresado por el usuario


    const handleNegativeClick = () => {
        onClose({}, "backdropClick")
    };

    const handlePositiveClick = async () => {
        setDisabled(true);
        await onContinue();
        setDisabled(false)
        onClose({}, "backdropClick")
    };

    const isConfirmDisabled: boolean = (props.confirmationText) ? !!props.confirmationText && inputText !== props.confirmationText : false;

    //props.confirmationText && inputText !== props.confirmationText;

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.contentText &&
                    <DialogContentText>
                        {props.contentText}
                    </DialogContentText>
                }
                <Box sx={{ my: 4 }}>
                    {props.children}
                </Box>
                {props.confirmationText && (
                    <>
                        <Typography variant="body2" color="textSecondary">
                            Por favor, escriba "<strong>{props.confirmationText}</strong>" para confirmar.
                        </Typography>
                        <TextField
                            label="Confirme el texto"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={handleNegativeClick} disabled={disabled}>{props.buttonNegative || "No"}</Button>
                <Button color="success" onClick={handlePositiveClick} disabled={disabled || isConfirmDisabled}>{props.buttonPositive || "Sí"}</Button>
            </DialogActions>
        </Dialog>
    )
}