import { Info } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { TrainChat } from "types/Train";
import { ChatUpdate } from "./ChatUpdate";
import { ChatDelete } from "./ChatDelete";

interface ChatMenuProps {
    value?: TrainChat;
}

export function ChatMenu(props: ChatMenuProps) {
    const [update, handleUpdate] = useState(false);
    const [remove, handleRemove] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        handleUpdate(false);
        handleRemove(false);
    };

    return (
        <Box>
            <IconButton
                id="chat-menu-button"
                edge="start"
                color="info"
                onClick={handleClick}
                aria-label="info"
                aria-controls={open ? 'chat-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <Info />
            </IconButton>
            <Menu
                id="chat-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'chat-menu-button',
                }}
            >
                <MenuItem onClick={() => handleUpdate(true)}>Editar</MenuItem>
                <MenuItem onClick={() => handleRemove(true)}>Eliminar</MenuItem>
            </Menu>

            {update && <ChatUpdate open={update} onClose={handleClose} value={props.value} />}
            {remove && <ChatDelete open={remove} onClose={handleClose} value={props.value} />}
        </Box>
    )
}