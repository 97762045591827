import React, { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import { Chart } from "./Chart";

interface Props {
    data: { [key: string]: any }[]
}


export function WidgetChart(props: Props) {
    const series1Ref = useRef<am5xy.ColumnSeries | null>(null);
    const series2Ref = useRef<am5xy.ColumnSeries | null>(null);
    const xAxisRef = useRef<am5xy.CategoryAxis<am5xy.AxisRenderer> | null>(null);

    const handler = (root: am5.Root) => {

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panY: false,
                layout: root.verticalLayout
            })
        );

        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "category"
            })
        );

        // Create series
        let series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Series A",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value1",
                categoryXField: "category"
            })
        );

        let series2 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Series B",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value2",
                categoryXField: "category"
            })
        );

        /*
        // Add legend
        let legend = chart.children.push(am5.Legend.new(root, {}));
        legend.data.setAll(chart.series.values);
        */

        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {}));

        xAxisRef.current = xAxis;
        series1Ref.current = series1;
        series2Ref.current = series2;
    }

    // This code will only run when props.data changes
    useLayoutEffect(() => {
        xAxisRef.current?.data.setAll(props.data);
        series1Ref.current?.data.setAll(props.data);
        series2Ref.current?.data.setAll(props.data);
    }, [props.data]);

    return (
        <Chart id="widget" handler={handler} container={{ height: "20vh" }} />
    )
}