import { LoadingButton } from "@mui/lab";
import { Box, Divider, Typography } from "@mui/material";
import { JSONViewer } from "components/JSONViewer/JSONViewer";
import { useEffect, useMemo } from "react";
import { useExecuteTrainToolFunctionMutation } from "services/api/assistant";

interface ExecuteFunctionProps {
    function?: string;
    arguments?: any;
    onResult?: (data: any) => void;
}

export function ExecuteFunction(props: ExecuteFunctionProps) {
    const functionName = useMemo(() => props.function, [props.function]);
    const functionArgs = useMemo(() => props.arguments, [props.arguments]);

    const [execCheck, { data, error, isError, isLoading, isSuccess }] = useExecuteTrainToolFunctionMutation();

    useEffect(() => {
        if (!props.onResult)
            return;
        props.onResult(data);
    }, [data, props]);

    const handleCheck = async () => {
        if (!functionName || !functionArgs)
            return;
        await execCheck({
            name: functionName,
            arguments: functionArgs
        });
    }

    return (
        <Box>
            <Typography variant="h5" fontSize="1.5rem" mt={1}>Ejecutar función</Typography>
            <Divider />
            <LoadingButton
                disabled={!functionName || !functionArgs}
                loading={isLoading}
                color={isSuccess ? "success" : (isError ? "error" : "primary")}
                variant="contained"
                sx={{ my: 2 }}
                onClick={handleCheck}
            >Ejecutar</LoadingButton>
            {
                (data) ? <JSONViewer src={data} displayObjectSize={false} /> :
                    (error !== null && typeof error === 'object') && <JSONViewer src={error} displayObjectSize={false} />
            }
        </Box>
    )
}