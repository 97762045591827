import { ChipProps, FormControl, InputLabel, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { TrainChat } from "types/Train";

interface StateInfo {
    key: TrainChat.State;
    title: string;
    description: string;
    color?: ChipProps['color'];
}

const data: StateInfo[] = [
    { key: "created", title: "Creado", description: "Chat creado." },
    { key: "processing", title: "En curso", description: "Trabajando en chat.", color: "info" },
    { key: "completed", title: "Completado", description: "Esperando aprobación.", color: "primary" },
    { key: "rejected", title: "Rechazado", description: "No cumple con los requisitos.", color: "error" },
    { key: "approved", title: "Aprobado", description: "Cumple con todos los objetivos.", color: "success" },
    { key: "archived", title: "Archivado", description: "Ya no se considera pero no se elimina." },
];

interface SelectTrainChatStateProps {
    value?: TrainChat.State;
    onChange?: (value?: TrainChat.State) => void;
}

export function SelectTrainChatState({ value, onChange }: SelectTrainChatStateProps) {
    const handleChange = (e: SelectChangeEvent<string>) => {
        const newState = e.target.value as TrainChat.State;
        onChange?.(newState);
    };

    return (
        <FormControl fullWidth margin="dense" variant="outlined" size="small" sx={{ p: 0 }} required>
            <InputLabel id="state-select-label">Estado</InputLabel>
            <Select<TrainChat.State>
                labelId="state-select-label"
                id="state-select"
                label="Estado"
                value={value}
                onChange={handleChange}
                sx={{ "& #state-select": { p: 1, minHeight: 48 } }}
            >
                {data.map(({ key, title, description }) => (
                    <MenuItem key={key} value={key} sx={{ p: 1 }}>
                        <ListItem sx={{ p: 1 }}>
                            <ListItemText sx={{ m: 0.5 }} primary={title} secondary={description} />
                        </ListItem>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
