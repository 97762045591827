import { TrainMessage } from "../../../types/Train";
import { AssistantAPI } from ".";

export const injectTrainMessage = (api: AssistantAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        createTrainMessage: build.mutation<TrainMessage, TrainMessage.Create>({
            query: (body) => ({
                url: "/trains/messages",
                method: "POST",
                data: body
            }),
            invalidatesTags: (result, err, { chat_id }) => ([{ type: "TrainChat", id: `TrainMessage_${chat_id}` }])
        }),
        retrieveTrainMessage: build.query<TrainMessage, TrainMessage['id']>({
            query: (id: TrainMessage['id']) => ({
                url: `/trains/messages/${id}`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([{ type: "TrainMessage", id }]),
        }),
        updateTrainMessage: build.mutation<{ affectedCount: number }, TrainMessage.Update>({
            query: (body) => {
                const { id, chat_id, ...data } = body;
                return ({
                    url: `/trains/messages/${id}`,
                    method: "PUT",
                    data
                })
            },
            invalidatesTags: (result, error, { id, chat_id }) => [
                { type: "TrainMessage", id },
                { type: "TrainChat", id: `TrainMessage_${chat_id}` },
            ],
        }),
        deleteTrainMessage: build.mutation<{ affectedCount: number }, TrainMessage.Delete>({
            query: ({ id }) => ({
                url: `/trains/messages/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, { id, chat_id }) => [
                { type: "TrainMessage", id },
                { type: "TrainChat", id: `TrainMessage_${chat_id}` },
            ],
        }),
        moveTrainMessage: build.mutation<TrainMessage.MoveResponse, TrainMessage.MoveRequest>({
            query: (body) => {
                const { id, chat_id, ...data } = body;
                return ({
                    url: `/trains/messages/${id}/reorder`,
                    method: "POST",
                    data
                })
            },
            invalidatesTags: (result, err, { chat_id }) => ([{ type: "TrainChat", id: `TrainMessage_${chat_id}` }])
        }),
    }),
    overrideExisting: "throw",
});
