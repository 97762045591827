import { TrainSubType, TrainType } from "../../../types/Train";
import { AssistantAPI } from ".";

export const injectTrainType = (api: AssistantAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveTrainTypes: build.query<TrainType[], void>({
            query: () => ({
                url: "/trains/types",
                method: "GET"
            }),
            providesTags: (result) => ([
                { type: "TrainType", id: 'LIST' },
                ...(result || []).map(({ id }) => ({ type: "TrainType", id } as const))
            ])
        }),
        createTrainType: build.mutation<TrainType, TrainType.Create>({
            query: (body) => ({
                url: "/trains/types",
                method: "POST",
                data: body
            }),
            invalidatesTags: [{ type: "TrainType", id: "LIST" }]
        }),
        retrieveTrainType: build.query<TrainType, TrainType['id']>({
            query: (id: TrainType['id']) => ({
                url: `/trains/types/${id}`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([{ type: "TrainType", id }]),
        }),
        updateTrainType: build.mutation<{ affectedCount: number }, TrainType.Update>({
            query: (body) => {
                const { id, ...data } = body;
                return ({
                    url: `/trains/types/${id}`,
                    method: "PUT",
                    data
                })
            },
            invalidatesTags: (result, error, { id }) => [
                { type: "TrainType", id },
                { type: "TrainType", id: "LIST" },
            ],
        }),
        deleteTrainType: build.mutation<{ affectedCount: number }, TrainSubType['id']>({
            query: (id) => ({
                url: `/trains/types/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, id) => [
                { type: "TrainType", id },
                { type: "TrainType", id: `TrainSubType_${id}` },
                { type: "TrainType", id: "LIST" },
            ],
        }),
        retrieveTrainSubTypes: build.query<TrainSubType[], TrainSubType['id']>({
            query: (id: TrainSubType['id']) => ({
                url: `/trains/types/${id}/sub_types`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([
                { type: "TrainType", id: `TrainSubType_${id}` },
                ...(result || []).map(({ id }) => ({ type: "TrainSubType", id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});