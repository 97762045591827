import { Content } from "features/Content/Content";
import { CellProps, EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { useState } from "react";
import { useRetrieveDepartmentsQuery } from "services/api/admin";
import { Department } from "types/Location";


export function DepartamentsPage() {
    const [hasCreate, setCreate] = useState<boolean>(false);
    const { data, isLoading, isFetching } = useRetrieveDepartmentsQuery();


    const columns: CellProps<Department>[] = [
        { label: 'Código', key: 'code' },
        { label: 'Nombre', key: 'name' },
        {
            label: 'Acciones', renderCell(_, item) {
                return (<></>)
            },
        },
    ];

    return (
        <Content
            title='Departamentos'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "65vh" }}>
            <EnhancedTable<Department>
                size={(data || []).length}
                columns={columns}
                data={data || []}
                onPageChange={() => ({})}
                isLoading={isLoading}
                isFetching={isFetching}
            />
        </Content>
    )
}