import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { TabPanel } from "components/Tabs/Tabs";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { InfoOutlined, } from "@mui/icons-material";
import React from "react";

interface Props {
    value: any;
}

function Item(props: { title: string, value?: string }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
            <Typography variant="caption" color="primary" fontWeight="700">{props.title}: </Typography>
            <Typography variant="caption" color="text.primary">{props.value}</Typography>
        </Box>
    )
}

function ModalBox(props: { open: boolean, onClose: () => void }) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function CardBox() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box boxShadow={1} borderRadius={2} p={2}>
            <Grid container spacing={1}>
                <Grid xs={6}>
                    <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
                        <Typography variant="caption" color="text.primary">{"25 Mar 2024 10:28AM"}</Typography>
                    </Box>
                </Grid>
                <Grid xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <Chip label="INFO" color="primary" size="small" icon={<InfoOutlined />} onClick={handleClickOpen} />
                    </Box>
                </Grid>
            </Grid>
            <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
                <Typography variant="caption" color="text.primary" fontSize={14} fontWeight="bold">{"Hurto en motocicleta"}</Typography>
            </Box>
            <Typography variant="caption" color="success.dark" fontWeight="bold" fontSize={14}>{"Confianza"}: {"80%"}</Typography>
            <Stack direction="column">
                <Item title="Descripción" value="Atraco en la vía por varios hombres en 2 motos." />
                <Item title="Dirección" value="Calle 38 con 72" />
                <Item title="Ubicación" value="BARRANQUILLA. DISTRITO ESPECIAL. INDUSTRIAL Y PORTUARIO" />
            </Stack>
            <ModalBox open={open} onClose={handleClose} />
        </Box>
    );
}

export function TabEmergencyContactHistory(props: Props) {
    return (
        <TabPanel value={props.value}>
            <Stack spacing={2}>
                <CardBox />
                <CardBox />
            </Stack>
        </TabPanel>
    )
}