import { Box, TextField } from "@mui/material";
import { AutocompleteQuery } from "components/Autocomplete/AutocompleteQuery";
import { FormModal } from "components/Modal";
import { useEffect, useState } from "react";
import { useCreateTypeMutation, useRetrieveCategoriesQuery, useRetrieveSubCategoriesQuery } from "services/api/admin";
import { SubCategory, Type } from "types/Category";

interface NewTypeProps {
    onClose: () => void;
    onCreated?: (item: Type['id']) => void;
}

export function NewType(props: NewTypeProps) {
    const [createType, { isSuccess }] = useCreateTypeMutation();
    const [open, setOpen] = useState<boolean>(true);
    const [subCategory, setSubCategory] = useState<SubCategory | null>(null);

    useEffect(() => {
        if (!open) {
            props.onClose()
        }
    }, [open, props]);

    const onClose = () => {
        setOpen(false)
    }

    const onSubmit = async (formData: { [key: string]: FormDataEntryValue; }) => {
        if (subCategory) {
            formData['sub_category_code'] = subCategory.code;
        }

        //await createType(formData);
        if (isSuccess && props.onCreated) {
            props.onCreated(-1)
        }
        return true
    }

    const onChangeSubCategory = (item: SubCategory | null) => setSubCategory(item);

    return (
        <FormModal
            title="Nuevo evento"
            buttonPositive="Guardar"
            open={open}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <Box>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="name"
                    label="Nombre"
                    type="text"
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    margin="dense"
                    name="description"
                    label="Descripcion"
                    type="text"
                    multiline
                    fullWidth
                    variant="outlined"
                    minRows={3}
                />
                <AutocompleteQuery<SubCategory, void>
                    query={useRetrieveSubCategoriesQuery}
                    queryArg={undefined}
                    onChangeValue={onChangeSubCategory}
                    dataKey="id"
                    dataValue="name"
                    label="Sub-Categoria"
                    TextField={{ margin: "dense" }}
                />
            </Box>
        </FormModal>
    )
}