import { Info } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { TypeUpdate } from "./TypeUpdate";
import { TypeDelete } from "./TypeDelete";
import { TrainType } from "types/Train";

interface TypeMenuProps {
    value?: TrainType;
}

export function TypeMenu(props: TypeMenuProps) {
    const [update, handleUpdate] = useState(false);
    const [remove, handleRemove] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        handleUpdate(false);
        handleRemove(false);
    };


    return (
        <Box>
            <IconButton
                id="basic-button"
                edge="end"
                color="info"
                onClick={handleClick}
                aria-label="info"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <Info />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleUpdate(true)}>Editar</MenuItem>
                <MenuItem onClick={() => handleRemove(true)}>Eliminar</MenuItem>
            </Menu>
            {update && <TypeUpdate open={update} onClose={handleClose} value={props.value} />}
            {remove && <TypeDelete open={remove} onClose={handleClose} value={props.value} />}
        </Box>
    );
}
