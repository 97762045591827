import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Completions } from "./Completions";
import { useState } from "react";
import { Messages } from "./Messages";
import { CompletionDetails } from "./CompletionDetails";
import { Completion } from "types/Completion";

export function CompletionsPage() {
    const [completion, setCompletion] = useState<Completion>();

    return (
        <Grid container spacing={1}>
            <Grid xs={3}>
                <Completions onSelected={setCompletion} />
            </Grid>
            <Grid xs={6}>
                <Messages completion={completion} />
            </Grid>
            <Grid xs={3}>
                <CompletionDetails completion={completion} />
            </Grid>
        </Grid>
    )
}