import { Box, Chip, Stack, Typography } from "@mui/material";
import { MapMarker } from "components/Maps/MapMarker";
import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { Event } from "types/Event";


function Item(props: { title: string, value?: string }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
            <Typography variant="caption" color="primary" fontWeight="700">{props.title}: </Typography>
            <Typography variant="caption" color="text.primary">{props.value}</Typography>
        </Box>
    )
}


interface Props {
    incident?: Event;
}


export function InfoTab(props: Props) {
    const [incident, setIncident] = useState<Event>();
    useEffect(() => setIncident(props.incident), [props.incident]);

    return (
        <Box sx={{ p: 2 }}>
            <Stack direction="column" spacing={2}>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                    }}>
                    <Box sx={{ color: 'text.secondary' }}>{incident?.id} • {`"Evento"`}</Box>
                    <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                        {`"Categoria"`}
                    </Box>
                    {/* Nivel de confianza
                    <Box
                        sx={{
                            color: 'success.dark',
                            display: 'inline',
                            fontWeight: 'bold',
                            mx: 0.5,
                            fontSize: 14,
                        }}
                    >
                        70%
                    </Box>
                    <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>
                        confianza
                    </Box>
                    */}
                </Box>
                <Stack direction="column">
                    <Item title="Afectado" value={`ID ${incident?.patient_id}`} />
                    <Item title="Ubicación" value={`Poblado ${incident?.poblado_id}`} />
                    <Item title="Dirección" value={incident?.address} />
                    <Item title="Referencia" value={undefined} />
                    <Item title="Descripción" value={incident?.description} />
                </Stack>
                <Chip label="MAPA" color="primary" />
                <MapMarker position={new LatLng(incident?.latitude || 0, incident?.longitude || 0)} />
            </Stack>
        </Box>
    )
}