import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { Messages } from "./message/Messages";
import { Setup } from "./setup/Setup";
import { TrainType, TrainChat, TrainSubType, TrainCase } from "types/Train";
import { Role } from "types/Message";
import { Types } from "./type";


export function TrainsPage() {
    const [trainType, setTrainType] = useState<TrainType>();
    const [trainSubtype, setTrainSubtype] = useState<TrainSubType>();
    const [trainCase, setTrainCase] = useState<TrainCase>();
    const [trainChat, setTrainChat] = useState<TrainChat>();

    const [sender, setSender] = useState<Role>('user');

    useEffect(() => setTrainSubtype(undefined), [trainType]);
    useEffect(() => setTrainCase(undefined), [trainSubtype]);
    useEffect(() => setTrainChat(undefined), [trainCase]);


    return (
        <Grid container spacing={2}>
            <Grid xs={4}>
                <Types
                    selected={trainType}
                    onChange={(it) => setTrainType((last) => (it?.id !== last?.id ? it : undefined))}
                    selectedSubType={trainSubtype}
                    onChangeSubtype={(it) => setTrainSubtype((last) => (it?.id !== last?.id ? it : undefined))}
                    selectedCase={trainCase}
                    onChangeCase={(it) => setTrainCase((last) => (it?.id !== last?.id ? it : undefined))}
                    selectedChat={trainChat}
                    onChangeChat={(it) => setTrainChat((last) => (it?.id !== last?.id ? it : undefined))}
                />
            </Grid>
            <Grid xs={5}>
                <Messages chat={trainChat} sender={sender} />
            </Grid>
            <Grid xs={3}>
                <Setup
                    trainChat={trainChat}
                    sender={sender}
                    onChangeSender={setSender}
                />
            </Grid>
        </Grid>
    )
}