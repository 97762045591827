import { LatLng } from "leaflet";
import { ReactNode, useEffect, useMemo, useRef, useState, useCallback } from "react";
import { Marker, Popup } from "react-leaflet";

export interface DraggableMarkerProps {
    point: LatLng;
    popup?: ReactNode;
    onChangePoint?: (point: LatLng) => void;
}

export function DraggableMarker({ point: initialPoint, popup, onChangePoint }: DraggableMarkerProps) {
    const [point, setPoint] = useState<LatLng>(initialPoint);

    const markerRef = useRef<L.Marker>(null);

    const handleDragEnd = useCallback(() => {
        const marker = markerRef.current;
        if (marker != null) {
            const newPoint = marker.getLatLng();
            setPoint(newPoint);
            onChangePoint?.(newPoint);
        }
    }, [onChangePoint]);

    useEffect(() => {
        setPoint(initialPoint);
    }, [initialPoint]);

    return (
        <Marker
            draggable
            eventHandlers={{ dragend: handleDragEnd }}
            position={point}
            ref={markerRef}
        >
            <Popup minWidth={90}>
                {popup}
            </Popup>
        </Marker>
    );
}
