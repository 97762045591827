import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Chip, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { TabPanel } from "components/Tabs/Tabs";

import { ArrowDropDown, Map } from '@mui/icons-material';
import React from 'react';
import { MapViewer } from 'components/MapViewer/MapViewer';

interface Props {
    value: any;
}

function Item(props: { title: string, value?: string }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
            <Typography variant="caption" color="primary" fontWeight="700">{props.title}: </Typography>
            <Typography variant="caption" color="text.primary">{props.value}</Typography>
        </Box>
    )
}

export function TabEmergencyInfo(props: Props) {
    return (
        <TabPanel value={props.value}>
            <Box sx={{ p: 2 }}>
                <Stack direction="column" spacing={2}>
                    <Box
                        sx={{
                            bgcolor: 'background.paper',
                            boxShadow: 1,
                            borderRadius: 2,
                            p: 2,
                        }}>
                        <Box sx={{ color: 'text.secondary' }}>10101 • Hurto en motocicleta</Box>
                        <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                            Hurto
                        </Box>
                        <Box
                            sx={{
                                color: 'success.dark',
                                display: 'inline',
                                fontWeight: 'bold',
                                mx: 0.5,
                                fontSize: 14,
                            }}
                        >
                            70%
                        </Box>
                        <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>
                            confianza
                        </Box>
                    </Box>
                    <Stack direction="column">
                        <Item title="Afectado" value="Quien reporta." />
                        <Item title="Ubicación" value="BARRANQUILLA. DISTRITO ESPECIAL. INDUSTRIAL Y PORTUARIO" />
                        <Item title="Dirección" value="Calle 38 con 72, esquina" />
                        <Item title="Referencia" value="Exito San Francisco" />
                        <Item title="Descripción" value="Atraco en la vía por varios hombres en 2 motos." />
                    </Stack>
                    <Chip label="MAPA" color="primary" />
                    <MapViewer />
                </Stack>
            </Box>
        </TabPanel>
    )
}