import { Breadcrumbs, Divider, Link, LinkProps, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Toolbar, Typography } from "@mui/material";
import { Outlet, useLocation, Link as RouterLink } from "react-router-dom";
import { Category, Event, EventAvailable, LocationCity, Place, TypeSpecimen } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ListCollapse, ListItemProps, SubListItemProps } from "components/ListCollapse/ListCollapse";



const breadcrumbNameMap: { [key: string]: string } = {
    '/system': 'Ajustes del sistema',
    '/system/types': 'Tipos',
    '/system/categories': 'Categorias',
    '/system/events': 'Eventos',
    '/system/divipola': 'Divipola'
};

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}


function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
}


export function SystemLayout() {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const menuList: (ListItemProps | SubListItemProps)[] = [
        {
            label: "Incidentes",
            icon: EventAvailable,
            children: [
                { label: "Categorias", href: "/system/categories", icon: TypeSpecimen },
                { label: "Sub-categorias", href: "/system/sub_categories", icon: Category },
                { label: "Tipos", href: "/system/types", icon: Event },
            ]
        },
        {
            label: "Lugares",
            icon: Place,
            children: [
                { label: "Departamentos", href: "/system/departments", icon: LocationCity },
                { label: "Municipios", href: "/system/cities", icon: LocationCity },
                { label: "Poblados", href: "/system/settlements", icon: LocationCity },
            ]
        }
    ]

    return (
        <>
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {pathnames.map((value, index) => {
                        const last = index === pathnames.length - 1;
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                        return last ? (
                            <Typography color="text.primary" key={to}>
                                {breadcrumbNameMap[to]}
                            </Typography>
                        ) : (
                            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                                {breadcrumbNameMap[to]}
                            </LinkRouter>
                        );
                    })}
                </Breadcrumbs>
            </Toolbar>
            <Grid container spacing={1} columns={14}>
                <Grid xs={3}>
                    <Paper sx={{ height: "75vh", overflow: "auto" }}>
                        <ListCollapse children={menuList} />
                    </Paper>
                </Grid>
                <Grid xs={11}>
                    <Paper sx={{ height: "75vh" }}>
                        <Outlet />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}