import { Divider, List, ListItemButton, ListItemText, } from "@mui/material";
import { JSONViewer } from "components/JSONViewer/JSONViewer";
import { Markdown } from "components/Markdown/Markdown";
import { useMemo } from "react";
import { Fragment } from "react/jsx-runtime";
import { AssistantMessage } from "types/Message";

interface ContentAssitantProps {
    message: AssistantMessage;
}

export function ContentAssitant(props: ContentAssitantProps) {
    const message = useMemo(() => props.message, [props.message]);

    return (
        <Fragment>
            {message.content && <Markdown>{message.content}</Markdown>}
            {message.tool_calls &&
                <List>
                    {message.tool_calls?.map((toolCall, index) => (
                        <Fragment>
                            <ListItemButton key={index}>
                                <ListItemText
                                    primary={`Function: ${toolCall.function.name}`}
                                    secondary={<JSONViewer src={toolCall.function.arguments} />}
                                />
                            </ListItemButton>
                            <Divider />
                        </Fragment>
                    ))}
                </List>
            }
        </Fragment>
    );
}