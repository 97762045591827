import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useEffect, useState } from "react";
import { useCreateCategoryMutation } from "services/api/admin";
import { Category } from "types/Category";

interface NewTypeProps {
    onClose: () => void;
    onCreated?: (item: Category['id']) => void;
}

export function NewCategory(props: NewTypeProps) {
    const [createCategory, { isSuccess }] = useCreateCategoryMutation();
    const [open, setOpen] = useState<boolean>(true);

    useEffect(() => {
        if (!open) {
            props.onClose()
        }
    }, [open, props]);

    const onClose = () => {
        setOpen(false)
    }

    const onSubmit = async (formData: { [key: string]: FormDataEntryValue; }) => {
        //await createType(formData);
        if (isSuccess && props.onCreated) {
            props.onCreated(-1)
        }
        return true
    }

    return (
        <FormModal
            title="Nuevo tipo"
            buttonPositive="Guardar"
            open={open}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <Box>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="name"
                    label="Nombre"
                    type="text"
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    margin="dense"
                    name="description"
                    label="Descripcion"
                    type="text"
                    multiline
                    fullWidth
                    variant="outlined"
                    minRows={3}
                />
            </Box>
        </FormModal>
    )
}