import { City, Department } from "types/Location";
import { AdminAPI } from ".";

export const injectDepartment = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveDepartments: build.query<Department[], void>({
            query: () => ({
                url: "/departments",
                method: "GET"
            }),
            providesTags: (result) => ([
                { type: "Department", id: `LIST` },
                ...(result || []).map(({ code: id }) => ({ type: "Department", id } as const))
            ]),
        }),
        retrieveCitiesInDepartmant: build.query<City[], string | null>({
            query: (code) => ({
                url: `/departments/${code}/cities`,
                method: "GET"
            }),
            providesTags: (result, err, code) => ([
                { type: "Department", id: `City_${code}` },
                ...(result || []).map(({ code: id }) => ({ type: "City", id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});