import { JSONViewer } from "components/JSONViewer/JSONViewer";
import { Markdown } from "components/Markdown/Markdown";
import { UserMessage } from "types/Message";


interface ContentUserProps {
    message: UserMessage;
}

export function ContentUser(props: ContentUserProps) {
    if (typeof props.message.content === "object")
        return <JSONViewer src={props.message.content} />;

    return <Markdown>{props.message.content}</Markdown>;
}