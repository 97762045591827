import { Box, Stack, Toolbar, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { WidgetChart } from 'features/dashboard/WidgetChart';
import { ChartRadar } from 'features/dashboard/ChartRadar';
import { WidgetLine, WidgetMap } from 'features/dashboard';
import { WidgetTotal } from 'features/dashboard/WidgetTotal';
import { Gauge } from '@mui/x-charts';
import { Widget } from 'features/dashboard/Widget';
import logo from 'assets/images/logo.png'
import { createAppTheme } from '@arwes/react';
import { Link } from 'react-router-dom';


//  https://augmented-ui.com/
//  https://arwes.dev/
// https://apexcharts.com/

//  https://commerce.nearform.com/blog/2021/future-ui/
//  https://i.pinimg.com/originals/2b/3a/96/2b3a96825f17276b7550c869da0b3fd3.gif


// https://github.com/flatlogic/light-blue-react-template


function WidgetBox(props: { children?: React.ReactNode; }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"} >
            {props.children}
        </Box>
    )
}
const theme = createAppTheme();

export function DashboardPage() {


    let data = [
        {
            category: "Research",
            value1: 1000,
            value2: 588
        },
        {
            category: "Marketing",
            value1: 1200,
            value2: 1800
        },
        {
            category: "Sales",
            value1: 850,
            value2: 1230
        }
    ];

    return (
        <Box height={"99vh"} >
            <Box mb={1} height={"10vh"} sx={{
                border: "1px solid rgba(61, 71, 81, 0.1)",
                backgroundColor: theme.colors.secondary.bg(0) //"rgba(7, 19, 15, 0.6)",
            }}>
                <Grid container spacing={1} columns={14} >
                    <Grid xs={4}>
                        <Toolbar disableGutters sx={{ ml: 4 }}>
                            <img src='https://www.atlantico.gov.co/images/botones/gov-co/logo-gov-co.png' alt='logo-gov-co' />
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textTransform: 'uppercase',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                            </Typography>
                        </Toolbar>
                    </Grid>
                    <Grid xs={6}>
                        <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
                            <Link to="/" className="logo-box"><img style={{ width: '100%' }} src={logo} alt="Logo" /></Link>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase'
                                }}
                            >
                            </Typography>
                        </Toolbar>
                    </Grid>
                    <Grid xs={4} visibility={"hidden"}>
                        <WidgetBox>FILTRO FECHA-HORA</WidgetBox>
                    </Grid>
                </Grid>
            </Box>
            <Box p={1} mb={1} height={"88vh"} overflow="auto" className='Scrollable'>
                <Grid container spacing={1} columns={14}>
                    <Grid xs={4}>
                        <Widget title="Instituciones" subtitle="ahora">
                            <Stack direction="row">
                                <Stack direction="column">
                                    <Gauge width={65} height={65} value={30} />
                                    <Gauge width={65} height={65} value={60} />
                                </Stack>
                                <Stack direction="column">
                                    <Gauge width={65} height={65} value={60} />
                                    <Gauge width={65} height={65} value={90} />
                                </Stack>
                            </Stack>
                        </Widget>
                        <Widget title="Progreso" subtitle="en el tiempo">
                            <WidgetLine />
                        </Widget>
                        <Widget title="Casos" subtitle="reportados">
                            <WidgetTotal />
                        </Widget>
                        {/*<WidgetBox><ChartPie /></WidgetBox>*/}
                    </Grid>
                    <Grid xs={6}>
                        <Grid container spacing={1} columns={14}>
                            <Grid xs={4}>
                                <WidgetBox>RECIBIDOS</WidgetBox>
                            </Grid>
                            <Grid xs={4}>
                                <WidgetBox>ACTIVOS</WidgetBox>
                            </Grid>
                            <Grid xs={4}>
                                <WidgetBox>ATENDIDOS</WidgetBox>
                            </Grid>
                        </Grid>
                        <WidgetBox>
                            <WidgetMap />
                        </WidgetBox>
                        <Grid container spacing={1} columns={14}>
                            <Grid xs={4}>
                                <WidgetBox>CIFRAS</WidgetBox>
                            </Grid>
                            <Grid xs={4}>
                                <WidgetBox>CIFRAS</WidgetBox>
                            </Grid>
                            <Grid xs={4}>
                                <WidgetBox>CIFRAS</WidgetBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={4}>
                        <Widget title="Ubicaciones" subtitle="hoy">
                            <Stack direction="row">
                                <Stack direction="column">
                                    <Gauge width={44} height={44} value={10} sx={{ fontSize: '10px' }} />
                                    <Gauge width={44} height={44} value={20} sx={{ fontSize: '10px' }} />
                                    <Gauge width={44} height={44} value={30} sx={{ fontSize: '10px' }} />
                                </Stack>
                                <Stack direction="column">
                                    <Gauge width={44} height={44} value={10} sx={{ fontSize: '10px' }} />
                                    <Gauge width={44} height={44} value={20} sx={{ fontSize: '10px' }} />
                                    <Gauge width={44} height={44} value={30} sx={{ fontSize: '10px' }} />
                                </Stack>
                            </Stack>
                        </Widget>
                        <Widget title="Eventos" subtitle="hoy">
                            <ChartRadar />
                        </Widget>
                        <Widget title="Comparativo" subtitle="mismo dia mes anterior">
                            <WidgetChart data={data} />
                        </Widget>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}