import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { FormEvent, ReactNode, useState } from "react";

export interface FormModalProps {
    title: string;
    contentText?: string | undefined;
    children?: ReactNode;
    open: DialogProps['open'];
    onClose: DialogProps['onClose'];
    buttonPositive?: string | undefined;
    onSubmit: (data: { [key: string]: FormDataEntryValue; }) => Promise<boolean>;
    dialogProps?: Omit<DialogProps, "open" | "onClose" | "PaperProps">;
}

export function FormModal(props: FormModalProps) {
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const handleCancelClick = () => {
        if (props.onClose) {
            props.onClose({}, "backdropClick")
        }
    };

    return (
        <Dialog
            {...props.dialogProps || {}}
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                component: 'form',
                onSubmit: async (event: FormEvent<HTMLFormElement>) => {
                    event.stopPropagation();
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData).entries());
                    setIsSubmit(true);
                    const close = await props.onSubmit(formJson);
                    setIsSubmit(false);
                    if (close && props.onClose) {
                        props.onClose({}, "backdropClick")
                    }
                },
            }}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.contentText &&
                    <DialogContentText>
                        {props.contentText}
                    </DialogContentText>
                }
                <Box>
                    {props.children}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick} disabled={isSubmit}>Cancelar</Button>
                <Button type="submit" disabled={isSubmit}>{props.buttonPositive ? props.buttonPositive : "Enviar"}</Button>
            </DialogActions>
        </Dialog>
    )
}