import { Alert, AlertProps, AlertTitle, Button } from "@mui/material";
import { SnackbarKey, SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { ReactNode, createContext, useContext } from "react";
import { Failure } from "services/utils/axiosBaseQuery";

interface INotificationContext {
    notify: (message: string, options?: { variant?: VariantType; title?: string }) => SnackbarKey;
    http: (error: unknown) => void;
    info: (message: string, title?: string) => SnackbarKey;
    success: (message: string, title?: string) => SnackbarKey;
    error: (message: string, title?: string) => SnackbarKey;
    warning: (message: string, title?: string) => SnackbarKey;
    destroy: (key: SnackbarKey) => void;
    deleted: (title: string, message: string, undo: (key: SnackbarKey) => void) => SnackbarKey;
}


const NotificationContext = createContext<INotificationContext | undefined>(undefined);


function Provider({ children }: { children: ReactNode; }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const onNotify = (message: string, options?: { variant?: VariantType; title?: string }): SnackbarKey => {
        const { variant = 'default', title } = options || {};

        return enqueueSnackbar(message, {
            variant,
            content: (key, message) => (
                <Alert
                    id={key.toString()}
                    onClose={() => closeSnackbar(key)}
                    severity={variant as AlertProps['severity']}
                    sx={{ maxWidth: '400px' }}
                >
                    {title && <AlertTitle>{title}</AlertTitle>}
                    {message}
                </Alert>
            ),
        });
    }

    const onHttp = (error: unknown) => {

        if (Array.isArray(error)) {
            (error as Array<Failure>).map(({ code, message }) => onError(message, code.toLocaleString()));
        } else if (error instanceof Error) {
            onError(error.message, error.name);
        }
    }

    const onDeleted = (title: string, message: string, undo: (key: SnackbarKey) => void) => {
        return enqueueSnackbar(message, {
            variant: "success",
            content: (key, message) => (
                <Alert
                    id={key.toString()}
                    onClose={() => closeSnackbar(key)}
                    severity="success"
                    sx={{ maxWidth: '400px' }}
                    action={
                        <Button color="inherit" size="small" onClick={() => undo(key)}>
                            Deshacer
                        </Button>
                    }
                >
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>
            ),
        })
    }

    const onInfo = (message: string, title: string = "Info") =>
        onNotify(message, { variant: 'info', title });

    const onSuccess = (message: string, title: string = "Success") =>
        onNotify(message, { variant: 'success', title });

    const onError = (message: string, title: string = "Error") =>
        onNotify(message, { variant: 'error', title });

    const onWarning = (message: string, title: string = "Warning") =>
        onNotify(message, { variant: 'warning', title });

    const onDestroy = (key: SnackbarKey) => closeSnackbar(key)

    const value: INotificationContext = {
        notify: onNotify,
        http: onHttp,
        info: onInfo,
        success: onSuccess,
        error: onError,
        warning: onWarning,
        destroy: onDestroy,
        deleted: onDeleted
    }


    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    )
}

export function NotificationProvider({ children }: { children: ReactNode; }) {

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            preventDuplicate
        >
            <Provider>{children}</Provider>
        </SnackbarProvider>
    )
}

export function useNotification() {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
}