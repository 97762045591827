import "dayjs/locale/es";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

// Extender el plugin relativeTime con las traducciones en español
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.locale(navigator.language || 'en');


interface RelativeTimeProps {
    date: string;
}

export function RelativeTime(props: RelativeTimeProps) {
    const now = dayjs();
    const givenDate = dayjs(props.date);

    let value: string;
    if (now.format('DD/MM/YYYY') === givenDate.format('DD/MM/YYYY')) {
        value = now.from(givenDate);
    } else if (now.format('MM/YYYY') === givenDate.format('MM/YYYY')) {
        value = givenDate.format('ddd DD [a las] hh:mm a');
    } else if (now.format('YYYY') === givenDate.format('YYYY')) {
        value = givenDate.format('DD MMM [a las] hh:mm a');
    } else {
        value = givenDate.format('DD-MMM-YY hh:mm a');
    }

    return <>{value}</>;
}