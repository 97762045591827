import { Box, Stack } from "@mui/material";
import { Composer } from "./Composer";
import { useEffect, useState } from "react";
import { Bubble } from "./Bubble";
import { Event } from "types/Event";
import { useLazyRetrieveConversationMessagesQuery } from "services/api/assistant";

interface ChatTabProps {
    incident?: Event;
}

export function ChatTab(props: ChatTabProps) {
    const [incident, setIncident] = useState<Event>();
    useEffect(() => setIncident(props.incident), [props.incident]);
    const [trigger, { data }] = useLazyRetrieveConversationMessagesQuery()

    useEffect(() => {
        if (incident?.conversation_id)
            trigger(incident.conversation_id)
    }, [incident?.conversation_id, trigger])

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}>
            <Box
                sx={{
                    height: { xs: "calc(100dvh - var(--Header-height))", lg: "100dvh" },
                    display: "flex",
                    flex: 1,
                    minHeight: '44dvh',
                    px: 2,
                    py: 3,
                    flexDirection: "column-reverse",
                    mb: 4
                }}>
                <Stack spacing={2} justifyContent="flex-end">
                    {
                        data?.map((item) => <Bubble content={item} />)
                    }
                </Stack>
            </Box>
            <Composer incidentId={incident?.id} />
        </Box>
    )
}