import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { MapContainer, MapContainerProps, Marker, Popup, TileLayer } from "react-leaflet";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

// https://github.com/nextgis/quickmapservices_contrib/tree/master
//  https://developers.google.com/maps/documentation/tile/policies?hl=es-419

// https://gis.stackexchange.com/questions/329407/requesting-styled-google-maps-xyz-tiles-with-qgis-seems-to-ignore-colors
export function MapViewer(props: MapContainerProps) {

    return (
        <MapContainer
            style={{ minHeight: 256, width: '100%' }}
            center={[11.015070, -74.821153]}
            zoom={16}
            {...props}
        >
            <TileLayer
                maxZoom={20}
                subdomains={"0123"}
                url={`https://mt{s}.google.com/vt/lyrs=m@186112443&hl=x-local&src=web&x={x}&y={y}&z={z}&s=Galile&apistyle=s.e:l.i|p.v:off,s.t:3|s.e:g`}
                attribution="Datos del mapa ©2024 Google"
            />
            <Marker position={[11.015070, -74.821153]} draggable >
                <Popup>
                    Marcador de ubicación
                </Popup>
            </Marker>
        </MapContainer>
    )
}