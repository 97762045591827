import { TabPanel } from "components/Tabs/Tabs";
import { ChatUI } from "features/ChatUI/ChatUI";
import { Message, MessageBodyType, MessageSource, MessageChannel } from "features/ChatUI/Message";


interface Props {
    value: any;
}

const messages: Message[] = [
    {
        Sid: "CM78a444b24ab193197630cf706b2924af",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.USER,
        Type: MessageBodyType.TEXT,
        Text: "Requiero reportar un atraco en la vía. En la 38 con 72. Varios hombres en 2 motos",
        Profile: {
            Name: "Pedro Perez",
            Picture: "https://mui.com/static/images/avatar/2.jpg"
        }
    },
    {
        Sid: "CM78a444b24ab193197630cf706b2924bf",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.ASSISTANT,
        Type: MessageBodyType.TEXT,
        Text: "Para continuar con el reporte del atraco, necesito saber en qué departamento de Colombia ocurrió. Por favor, indícame el nombre o el código del departamento.",
        Profile: {
            Name: "ALEX",
            Picture: "https://www.serviciopostal.gob.ec/chatbot/uploads/chatbot.png",
            Org: "https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED"
        }
    },
    {
        Sid: "CM78a444b24ab193197630cf706b2925bf",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.USER,
        Type: MessageBodyType.TEXT,
        Text: "Atlántico",
        Profile: {
            Name: "Pedro Perez",
            Picture: "https://mui.com/static/images/avatar/2.jpg"
        }
    },
    {
        Sid: "CM78a44193768e6cf2780e953570e024fc",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.USER,
        Type: MessageBodyType.TEXT,
        Text: "Barranquilla",
        Profile: {
            Name: "Pedro Perez",
            Picture: "https://mui.com/static/images/avatar/2.jpg"
        }
    },
    {
        Sid: "CM78a4451ba3083161b5c4e99e497966bf",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.ASSISTANT,
        Type: MessageBodyType.TEXT,
        Text: "El reporte del atraco ha sido registrado con éxito. Se está coordinando la asistencia necesaria. Mantente seguro y en un lugar seguro mientras llega la ayuda. Si hay novedades o necesitas más asistencia, no dudes en contactarnos.",
        Profile: {
            Name: "ALEX",
            Picture: "https://www.serviciopostal.gob.ec/chatbot/uploads/chatbot.png",
            Org: "https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED"
        }
    },
    {
        Sid: "CM78a423860dd3aa63e038fb427d234521",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.USER,
        Type: MessageBodyType.MEDIA,
        Media: [{
            ContentType: "image/jpeg",
            MediaUrl: "https://i.blogs.es/27c17a/jlacort_a_crazy_mad_man_is_driving_very_very_fast_in_a_highway._42699c48-70b1-4258-8f9b-00bebd2a22cb/650_1200.jpeg"
        }],
        Profile: {
            Name: "Pedro Perez",
            Picture: "https://mui.com/static/images/avatar/2.jpg"
        }
    },
    {
        Sid: "CM78a423860dd3aa63e038fb427d234521",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.SYSTEM,
        Type: MessageBodyType.TEXT,
        Text: "Parece una situación de robo en proceso. Por favor, dime tu ubicación para enviar ayuda inmediata.",
        Profile: {
            Name: "TURED123",
            Picture: "https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED"
        }
    },
    {
        Sid: "CM78a423860dd3aa63e038fb427d234522",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.ASSISTANT,
        Type: MessageBodyType.TEXT,
        Text: "La información de la foto ha sido recibida y es consistente con el reporte de atraco. Tu información adicional ha sido anotada. Mantente seguro y en contacto si puedes proporcionar más detalles o si tu situación cambia. La ayuda está en camino.",
        Profile: {
            Name: "ALEX",
            Picture: "https://www.serviciopostal.gob.ec/chatbot/uploads/chatbot.png",
            Org: "https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED"
        }
    },
    {
        Sid: "CM78a423860dd3aa63e038fb427d234521",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.USER,
        Type: MessageBodyType.MEDIA,
        Media: [{
            ContentType: "image/jpeg",
            MediaUrl: "https://previews.123rf.com/images/zhukovvvlad/zhukovvvlad1809/zhukovvvlad180900248/107984543-una-mujer-con-un-vestido-est%C3%A1-trepando-por-una-pared-de-ladrillos-la-ni%C3%B1a-se-sube-a-una-valla-de.jpg"
        }],
        Profile: {
            Name: "Pedro Perez",
            Picture: "https://mui.com/static/images/avatar/2.jpg"
        }
    },
    {
        Sid: "CM78a4451ba3083161b5c4e99e497966bf",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.SYSTEM,
        Type: MessageBodyType.TEXT,
        Text: "Observo a una persona que está escalando o trepando por una pared, y hay otra persona justo debajo, quizás brindando apoyo o mirando. Parece ser un lugar residencial con muros de ladrillo rojo. ¿Cómo puedo asistirte en relación a esta imagen?",
        Profile: {
            Name: "TURED123",
            Picture: "https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED"
        }
    },
    {
        Sid: "CM78a423860dd3aa63e038fb427d234521",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.USER,
        Type: MessageBodyType.TEXT,
        Text: "Están robando",
        Profile: {
            Name: "Pedro Perez",
            Picture: "https://mui.com/static/images/avatar/2.jpg"
        }
    },
    {
        Sid: "CM78a423860dd3aa63e038fb427d234522",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.AGENT,
        Type: MessageBodyType.TEXT,
        Text: "Gracias por la información. La foto ha sido recibida y añadida a tu reporte de atraco. Estamos coordinando la ayuda. Por favor, mantente en un lugar seguro. Si tienes más detalles, compártelos. La asistencia está en camino.",
        Profile: {
            Name: "Juan Rios",
            Picture: "https://cdn-icons-png.flaticon.com/512/6833/6833600.png",
            Org: "https://www.policia.gov.co/sites/default/files/escudo-policia.png"
        }
    },
    {
        Sid: "CM78a423860dd3aa63e038fb427d234522",
        Channel: MessageChannel.WHATSAPP,
        Source: MessageSource.AGENT,
        Type: MessageBodyType.TEXT,
        Text: "Soy el Agente Juan Rios, una patrulla va en camino",
        Profile: {
            Name: "Juan Rios",
            Picture: "https://cdn-icons-png.flaticon.com/512/6833/6833600.png",
            Org: "https://www.policia.gov.co/sites/default/files/escudo-policia.png"
        }
    }
];

export function TabEmergencyChat(props: Props) {
    return (
        <TabPanel value={props.value}>
            <ChatUI messages={messages} />
        </TabPanel>
    )
}