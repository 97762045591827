import React from 'react';
import { LinkProps, useHref, useLinkClickHandler } from 'react-router-dom';
import { Button, ButtonProps } from '@mui/material';

interface ButtonLinkProps extends ButtonProps, Pick<LinkProps, 'replace' | 'state' | 'to'> {
    children?: React.ReactNode;
}

export const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(({ onClick, replace = false, state, to, ...props }, ref) => {
    let href = useHref(to);
    let handleClick = useLinkClickHandler(to, { replace, state, });
    return (
        <Button
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event as any);
                }
            }}
            ref={ref}
        />
    );
}
);