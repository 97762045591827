import { useMemo, useState } from "react";
import { Box, Button, Paper, Stack, Toolbar, Typography } from "@mui/material";
import moment from "moment";
import { Completion } from "types/Completion";
import { ChatCreate } from "../Trains/type/chats/ChatCreate";
import { ButtonLink } from "components/Button/ButtonLink";


interface CompletionDetailsProps {
    completion?: Completion;
}


function Item(props: { title: string, value?: string }) {
    return (
        <Box flexGrow={1} overflow="hidden" textOverflow="ellipsis">
            <Typography variant="caption" color="primary" fontWeight="700" fontSize={14}>{props.title}: </Typography>
            <Typography variant="caption" color="text.primary" fontWeight="500" fontSize={14}>{props.value}</Typography>
        </Box>
    )
}

export function CompletionDetails(props: CompletionDetailsProps) {
    const completion = useMemo(() => props.completion, [props.completion]);
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Paper sx={{ height: "80vh", backgroundColor: 'background.paper' }}>
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="contentTitle"
                    component="div">
                    {"Detalles"}
                </Typography>
            </Toolbar>

            {completion && <Box
                sx={{
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    p: 2,
                }}>
                <Stack direction="column" spacing={1} mb={8}>
                    <Typography color="text.secondary" fontWeight="500" mb={1}>{moment(completion?.created_at).format('DD [de] MMMM, hh:mm a')}</Typography>

                    <Item title="Modelo" value={completion?.model} />
                    <Item title="ID" value={completion?.id.toString()} />
                </Stack>
                <Stack direction="column" spacing={1} mb={8}>
                    {!completion.train_id
                        ? <Button variant="contained" onClick={() => setOpen(true)}>Usar para entrenamiento</Button>
                        : <ButtonLink to={`/assistant/trains?id=${completion.train_id}`} variant="contained" color="info">Ver entrenamiento</ButtonLink>
                    }

                    <ButtonLink to={`/assistant/conversations?id=${completion.conversation_id}`} variant="contained">Ver conversación</ButtonLink>
                </Stack>
            </Box>}

            <ChatCreate
                open={open}
                onClose={() => setOpen(false)}
                completionId={completion?.id}
            />
        </Paper >
    )
}
