import { TrainCase, TrainChat, } from "../../../types/Train";
import { AssistantAPI } from ".";

export const injectTrainCase = (api: AssistantAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        createTrainCase: build.mutation<TrainCase, TrainCase.Create>({
            query: (body) => ({
                url: "/trains/cases",
                method: "POST",
                data: body
            }),
            invalidatesTags: (result, err, { sub_type_id }) => [{ type: "TrainSubType", id: `TrainCase_${sub_type_id}` }]
        }),
        retrieveTrainCase: build.query<TrainCase, TrainCase['id']>({
            query: (id: TrainCase['id']) => ({
                url: `/trains/cases/${id}`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([{ type: "TrainCase", id }]),
        }),
        updateTrainCase: build.mutation<{ affectedCount: number }, TrainCase.Update>({
            query: (body) => {
                const { id, sub_type_id, ...data } = body;
                return ({
                    url: `/trains/cases/${id}`,
                    method: "PUT",
                    data
                })
            },
            invalidatesTags: (result, error, { id, sub_type_id }) => [
                { type: "TrainCase", id },
                { type: "TrainSubType", id: `TrainCase_${sub_type_id}` },
            ],
        }),
        deleteTrainCase: build.mutation<{ affectedCount: number }, TrainCase.Delete>({
            query: ({ id }) => ({
                url: `/trains/cases/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, { id, sub_type_id }) => [
                { type: "TrainCase", id },
                { type: "TrainSubType", id: `TrainCase_${sub_type_id}` },
            ],
        }),
        retrieveTrainChats: build.query<TrainChat[], TrainCase['id']>({
            query: (id: TrainCase['id']) => ({
                url: `/trains/cases/${id}/chats`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([
                { type: "TrainCase", id: `TrainChat_${id}` },
                ...(result || []).map(({ id }) => ({ type: "TrainChat", id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});