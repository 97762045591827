import { ReactNode, createContext } from "react";
import { useLocalStorage } from "usehooks-ts";
import { PaletteMode } from "@mui/material";


interface IPaletteModeContext {
    paletteMode: PaletteMode;
    setPaletteMode: (mode: PaletteMode) => void;
}

export const PaletteModeContext = createContext<IPaletteModeContext>({
    paletteMode: "light",
    setPaletteMode(mode) {
    },
});

export function PaletteModeProvider({ children }: { children: ReactNode; }) {
    const [localPaletteMode, setLocalPaletteMode] = useLocalStorage<PaletteMode>("paletteMode", "light");

    let value: IPaletteModeContext = {
        paletteMode: localPaletteMode,
        setPaletteMode: setLocalPaletteMode
    };

    return (
        <PaletteModeContext.Provider value={value}>
            {children}
        </PaletteModeContext.Provider >
    );
}
