import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { grey, red, teal } from '@mui/material/colors';
import darkScrollbar from '@mui/material/darkScrollbar';


// A custom theme for this app
export const lightingTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            light: teal[100],
            main: teal[500],
            dark: teal[700],
        },
        secondary: {
            light: red[100],
            main: red[500],
            dark: red[700],
        },
        background: {
            default: grey["A200"],
            paper: grey["A100"]
        }
    },
    spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
}, locales["esES"]);

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: teal[100],
            main: teal[500],
            dark: teal[700],
        },
        secondary: {
            light: red[100],
            main: red[500],
            dark: red[700],
        },
        background: {
            default: grey["900"],
            paper: grey["800"]
        }
    },
    spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
}, locales["esES"]);

export default lightingTheme;