import { Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar } from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';


import DashboardIcon from '@mui/icons-material/Dashboard';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import BusinessIcon from '@mui/icons-material/Business';
import ContactsIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import logo from 'assets/images/logo.png'
import { Link } from "react-router-dom";
import { Engineering, Home } from "@mui/icons-material";

interface AppBarProps extends MuiDrawerProps {
    open: boolean;
    width: number;
}

const definition = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>;
const Drawer = definition(
    ({ theme, open, width }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: width,

            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(14),
                },
            }),
        },
    }),
);

interface Props {
    open: boolean;
    width: number;
    toggleDrawer: () => void;
}


export function Sidebar({ open, width, toggleDrawer }: Props) {
    return (
        <Drawer variant="permanent" open={open} width={width} sx={{ minHeight: '100vh' }}>
            <Toolbar disableGutters
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: [1],
                }}>
                <Link to="/" className="logo-box"><img style={{ width: '100%' }} src={logo} alt="Logo" /></Link>
            </Toolbar>
            <Divider />
            <List component="nav">
                <ListItemButton component="a" href="/">
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItemButton>
                <ListItemButton component="a" href="/dashboard">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton component="a" href="/events">
                    <ListItemIcon>
                        <EmergencyShareIcon />
                    </ListItemIcon>
                    <ListItemText primary="Eventos" />
                </ListItemButton>
                {/*
                <ListItemButton component="a" href="/institutions">
                    <ListItemIcon>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Instituciones" />
                </ListItemButton>
                */}
                <Divider sx={{ my: 1 }} />
                <ListSubheader component="div" inset>
                    Ajustes
                </ListSubheader>
                <ListItemButton component="a" href="/assistant">
                    <ListItemIcon>
                        <Engineering />
                    </ListItemIcon>
                    <ListItemText primary="Asistente" />
                </ListItemButton>
                <ListItemButton component="a" href="/system">
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sistema" />
                </ListItemButton>

                <ListItemButton onClick={toggleDrawer}>
                    <ListItemIcon>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </ListItemIcon>
                </ListItemButton>
            </List>
        </Drawer>)
}