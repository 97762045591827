import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useEffect, useState } from "react";
import { AutocompleteQuery } from "components/Autocomplete/AutocompleteQuery";
import { SubCategory, Category } from "types/Category";
import { useCreateCategoryMutation, useRetrieveCategoriesQuery, useRetrieveTypesQuery } from "services/api/admin";

interface NewSubCategoryProps {
    onClose: () => void;
    onCreated?: (item: SubCategory['code']) => void;
}

export function NewSubCategory(props: NewSubCategoryProps) {

    const [execCreate, { isSuccess }] = useCreateCategoryMutation();
    const [open, setOpen] = useState<boolean>(true);
    const [category, setCategory] = useState<Category | null>(null);

    useEffect(() => {
        if (!open) {
            props.onClose()
        }
    }, [open, props]);

    const onClose = () => {
        setOpen(false)
    }

    const onSubmit = async (formData: { [key: string]: FormDataEntryValue; }) => {
        if (category) {
            formData['category_code'] = category.code;
        }

        //await execCreate(formData);
        if (isSuccess && props.onCreated) {
            props.onCreated("")
        }
        return true
    }

    const onChangeCategory = (item: Category | null) => setCategory(item);

    return (
        <FormModal
            title="Nueva sub-categoria"
            buttonPositive="Guardar"
            open={open}
            onClose={onClose}
            onSubmit={onSubmit}>
            <Box>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="name"
                    label="Nombre"
                    type="text"
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    margin="dense"
                    name="description"
                    label="Descripcion"
                    type="text"
                    multiline
                    fullWidth
                    variant="outlined"
                    minRows={3}
                />
                <AutocompleteQuery<Category, void>
                    query={useRetrieveCategoriesQuery}
                    queryArg={undefined}
                    onChangeValue={onChangeCategory}
                    dataKey="id"
                    dataValue="name"
                    label="Categoria"
                    TextField={{ margin: "dense" }}
                />
            </Box>
        </FormModal>
    )
}