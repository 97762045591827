import { Fastfood } from "@mui/icons-material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineOppositeContentClasses } from "@mui/lab";
import { Avatar, Typography } from "@mui/material";
import { TabPanel } from "components/Tabs/Tabs";

interface Props {
    value: any;
}

function Item() {
    return (
        <TimelineItem>
            <TimelineOppositeContent align="right" variant="body2" color="text.secondary" sx={{ m: 'auto 0' }}>
                12:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined">
                    <Avatar src="https://mui.com/static/images/avatar/2.jpg" sx={{ width: 24, height: 24 }} />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2, m: 2 }} boxShadow={1}>
                <Typography variant="h6" component="span">
                    Eat
                </Typography>
                <Typography>Because you need strength</Typography>
            </TimelineContent>
        </TimelineItem>
    )
}

export function TabEmergencyFollowAttachment(props: Props) {
    return (
        <TabPanel value={props.value}>
            <Timeline position="right" sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.05,
                },
            }}>
                <Item />
                <Item />
                <Item />
                <Item />
                <Item />
            </Timeline>
        </TabPanel>
    )
}