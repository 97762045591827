import L, { LatLng, PathOptions } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Map, MapProps } from './Map';
import { MinimapControl } from './MinimapControl';
import { DraggableMarker, DraggableMarkerProps } from './DraggableMarker';
import { Circle, LayerGroup, Polygon, Polyline } from 'react-leaflet';
import { ReactNode, } from 'react';


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

export interface MapPickerProps extends MapProps, DraggableMarkerProps {
    point: LatLng;
    geometry?: GeoJSON.Geometry;
}

function geometryToLeaflet(geojson: GeoJSON.Geometry) {

    const pointToLatLng = (point: GeoJSON.Position): L.LatLng => {
        if (point.length === 3)
            return new LatLng(point[1], point[0], point[2])
        return new LatLng(point[1], point[0])
    }
    const { type } = geojson;

    if (type === "Point") {
        const { coordinates } = geojson;
        return { type, coordinates: pointToLatLng(coordinates) }
    }

    if (type === "MultiPoint" || type === "LineString") {
        const { coordinates } = geojson;
        return { type, coordinates: coordinates.map((it) => pointToLatLng(it)) }
    }

    if (type === "MultiLineString" || type === "Polygon") {
        const { coordinates } = geojson;
        return {
            type,
            coordinates: coordinates.map((points) => points.map((it) => pointToLatLng(it)))
        }
    }

    if (type === "MultiPolygon") {
        const { coordinates } = geojson;
        return {
            type,
            coordinates: coordinates.map((x) => x.map((points) => points.map((it) => pointToLatLng(it))))
        }
    }

    return {
        type,
        coordinates: {}
    }
}

function Geometry({ geometry }: { geometry: GeoJSON.Geometry }) {
    const renderGeometry = (geometry: GeoJSON.Geometry): ReactNode => {
        if (!geometry || geometry.type === "GeometryCollection")
            return null;

        const pathOptions: PathOptions = { color: 'green', fillColor: 'green' };
        const { type, coordinates } = geometryToLeaflet(geometry);

        switch (type) {
            case 'Point':
                return <Circle center={coordinates} pathOptions={pathOptions} radius={100} />;
            case 'MultiPoint':
                return coordinates.map((coordinate, idx) => (
                    <Circle key={idx} center={coordinate} pathOptions={pathOptions} radius={100} />
                ));
            case 'LineString':
            case 'MultiLineString':
                return <Polyline pathOptions={pathOptions} positions={coordinates} weight={10} />;
            case 'Polygon':
            case 'MultiPolygon':
                return <Polygon pathOptions={pathOptions} positions={coordinates} weight={10} />;
            default:
                return null;
        }
    }

    return (
        <LayerGroup>
            {renderGeometry(geometry)}
        </LayerGroup>
    );
}

export function MapPicker({ point, popup, onChangePoint, geometry, ...props }: MapPickerProps) {
    return (
        <Map
            center={point}
            zoom={15}
            tileLayer={{
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }}
            {...props}>
            <DraggableMarker point={point} popup={popup} onChangePoint={onChangePoint} />
            <MinimapControl
                zoom={10}
                position="topright"
                tileLayer={{
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                }}
            />
            {geometry && <Geometry geometry={geometry} />}
        </Map>
    )
}