import { Action } from "@reduxjs/toolkit"
import { createApi } from "@reduxjs/toolkit/query/react"
import { REHYDRATE } from "redux-persist"
import { RootState } from "services/store"
import { axiosBaseQuery } from "services/utils/axiosBaseQuery"
import { injectConversation } from "./injectConversation"
import { injectCompletion } from "./injectCompletion"
import { injectTrainType } from "./injectTrainType"
import { injectTrainChat } from "./injectTrainChat"
import { injectTrainMessage } from "./injectTrainMessage"
import { injectTrainTool } from "./injectTrainTool"
import { injectTrainSubType } from "./injectTrainSubType"
import { injectTrainCase } from "./injectTrainCase"


export const assistantAPI = createApi({
    reducerPath: 'assistant',
    tagTypes: [
        'Conversation', 'ConversationMessage',
        'Completion', 'CompletionMessage',
        'TrainType', 'TrainSubType', 'TrainCase', 'TrainChat', 'TrainMessage', 'TrainTool',
    ],
    baseQuery: axiosBaseQuery({ baseURL: process.env.REACT_APP_ASSISTANT_API_URL }),
    refetchOnMountOrArgChange: 10,
    refetchOnReconnect: true,
    extractRehydrationInfo: (action, { reducerPath, }): any => {
        const isHydrateAction = (action: Action):
            action is Action<typeof REHYDRATE> & { key: string; payload: RootState; err: unknown; } =>
            action.type === REHYDRATE;
        if (isHydrateAction(action)) {
            // when persisting the api reducer
            if (action.key === 'key used with redux-persist') {
                return action.payload
            }

            // When persisting the root reducer
            if (action.payload)
                return action.payload[reducerPath]
        }
        return undefined
    },
    endpoints: () => ({}), // Sin endpoints inicialmente
});

export type AssistantAPI = typeof assistantAPI;

export const {
    useRetrieveConversationsQuery,
    useRetrieveConversationQuery,
    useRetrieveConversationMessagesQuery,

    useLazyRetrieveConversationsQuery,
    useLazyRetrieveConversationQuery,
    useLazyRetrieveConversationMessagesQuery,
} = injectConversation(assistantAPI);

export const {
    useRetrieveCompletionsQuery,
    useRetrieveCompletionQuery,
    useRetrieveCompletionMessagesQuery,

    useLazyRetrieveCompletionsQuery,
    useLazyRetrieveCompletionQuery,
    useLazyRetrieveCompletionMessagesQuery,
} = injectCompletion(assistantAPI);


export const {
    useRetrieveTrainTypesQuery,
    useCreateTrainTypeMutation,
    useRetrieveTrainTypeQuery,
    useUpdateTrainTypeMutation,
    useDeleteTrainTypeMutation,
    useRetrieveTrainSubTypesQuery,

    useLazyRetrieveTrainTypesQuery,
    useLazyRetrieveTrainTypeQuery,
    useLazyRetrieveTrainSubTypesQuery,
} = injectTrainType(assistantAPI);

export const {
    useCreateTrainSubTypeMutation,
    useRetrieveTrainSubTypeQuery,
    useUpdateTrainSubTypeMutation,
    useDeleteTrainSubTypeMutation,
    useRetrieveTrainCasesQuery,

    useLazyRetrieveTrainSubTypeQuery,
    useLazyRetrieveTrainCasesQuery,
} = injectTrainSubType(assistantAPI);

export const {
    useCreateTrainCaseMutation,
    useRetrieveTrainCaseQuery,
    useUpdateTrainCaseMutation,
    useDeleteTrainCaseMutation,
    useRetrieveTrainChatsQuery,

    useLazyRetrieveTrainCaseQuery,
    useLazyRetrieveTrainChatsQuery,
} = injectTrainCase(assistantAPI);

export const {
    useCreateTrainChatMutation,
    useRetrieveTrainChatQuery,
    useUpdateTrainChatMutation,
    useUpdateTrainChatStateMutation,
    useDeleteTrainChatMutation,
    useRetrieveTrainMessagesQuery,

    useLazyRetrieveTrainChatQuery,
    useLazyRetrieveTrainMessagesQuery,
} = injectTrainChat(assistantAPI);

export const {
    useCreateTrainMessageMutation,
    useRetrieveTrainMessageQuery,
    useUpdateTrainMessageMutation,
    useDeleteTrainMessageMutation,
    useMoveTrainMessageMutation,

    useLazyRetrieveTrainMessageQuery,
} = injectTrainMessage(assistantAPI);

export const {
    useRetrieveTrainToolFunctionsQuery,
    useExecuteTrainToolFunctionMutation,

    useLazyRetrieveTrainToolFunctionsQuery,
} = injectTrainTool(assistantAPI);