import { Add, ArrowBack, FilterList } from "@mui/icons-material";
import { Box, BoxProps, Chip, Collapse, Paper, Toolbar, Typography, } from "@mui/material";
import { MouseEvent, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    title: ReactNode;
    children?: ReactNode | undefined;
    hasFilter?: boolean;
    hasReturn?: boolean;
    filterComponent?: ReactNode;
    boxComponent?: BoxProps;

    createText?: string;
    onCreateClick?: (e: MouseEvent<HTMLElement>) => void;
}

export function Content(props: Props) {
    const navigate = useNavigate();
    const [filter, setFilter] = useState<boolean>(false);

    const toggleFilter = () => {
        setFilter(!filter);
    }

    return (
        <Paper sx={{ width: '100%', height: '100%', mb: 2 }}>
            <Toolbar sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="contentTitle"
                    component="div">
                    {props.title}
                </Typography>
                {props.hasReturn &&
                    <Chip
                        icon={<ArrowBack />}
                        label="VOLVER"
                        sx={{ mr: 1, ml: 1 }}
                        onClick={() => navigate(-1)}
                    />
                }

                {props.onCreateClick &&
                    <Chip
                        icon={<Add />}
                        label={props.createText || "NUEVO"}
                        color="primary"
                        sx={{ mr: 1, ml: 1 }}
                        onClick={props.onCreateClick}
                    />
                }

                {props.hasFilter && <Chip
                    icon={<FilterList />}
                    label="FILTRAR"
                    color="primary"
                    sx={{ mr: 1, ml: 1 }}
                    onClick={toggleFilter} />}
            </Toolbar>
            {props.hasFilter &&
                <Collapse in={filter}>{props.filterComponent}</Collapse>
            }
            <Box {...props.boxComponent}>
                {props.children}
            </Box>
        </Paper>
    )
}