import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { Chart } from "components/charts";

interface Props {
    id?: string;
}


export function WidgetTotal(props: Props) {
    const handler = (root: am5.Root) => {

        var chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout,
                innerRadius: am5.percent(80)
            })
        );

        // Define data
        var data = [{
            country: "France",
            sales: 100000
        }, {
            country: "Spain",
            sales: 160000
        }, {
            country: "United Kingdom",
            sales: 80000
        }];


        // Create series
        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: "Series",
                valueField: "sales",
                categoryField: "country"
            })
        );
        // Hide labels and ticks
        //series.labels.template.set("forceHidden", true);
        //series.ticks.template.set("forceHidden", true);

        series.ticks.template.setAll({
            strokeWidth: 2,
            strokeOpacity: 1
        });

        series.ticks.template.adapters.add("stroke", function (stroke, target) {
            if (target.dataItem) {
                return am5.Color.fromHex(0xFF0000);
            }
            else {
                return stroke;
            }
        });

        series.labels.template.setAll({
            fontSize: 20
        });

        series.labels.template.adapters.add("fill", function (fill, target) {
            if (target.dataItem) {
                return am5.Color.fromHex(0xFF0000);
            }
            else {
                return fill;
            }
        });


        // Set data
        series.data.setAll(data);

        // Add legend
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            layout: root.horizontalLayout
        }));


        // Add label inside
        var label = series.children.push(am5.Label.new(root, {
            text: "[fontSize: 20px; #999]INCIDENTES[/]\n{valueSum.formatNumber()}",
            textAlign: "center",
            centerX: am5.p50,
            centerY: am5.p50,
            fontSize: 40,
            fontWeight: "500",
            populateText: true,
            fill: am5.color(0x555555),
            oversizedBehavior: "fit"
        }));

        // Resize label to fit series
        series.onPrivate("radius", function (radius) {
            label.set("maxWidth", (radius || 0) * 1.5)
        });
    }

    return (
        <Chart id={props.id || "chart"} handler={handler} container={{ height: "20vh" }} micro />
    )
}