import { useLayoutEffect } from "react";
import { Box, BoxProps } from "@mui/material";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Micro from "@amcharts/amcharts5/themes/Micro";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";

interface Props {
    id: string;
    container?: BoxProps;
    micro?: boolean;
    handler: (root: am5.Root) => void;
}

//https://www.amcharts.com/docs/v5/getting-started/integrations/react/
export function Chart(props: Props) {
    useLayoutEffect(() => {
        let root = am5.Root.new(props.id);

        //  themes
        const themes: Array<am5.Theme> = [
            am5themes_Animated.new(root),
            am5themes_Material.new(root),
            am5themes_Dark.new(root),
        ];
        if (props.micro) {
            themes.push(am5themes_Micro.new(root));
        }
        root.setThemes(themes);

        //  locale
        root.locale = am5locales_es_ES;

        //  setup
        props.handler(root);

        return () => {
            root.dispose();
        };
    }, [props]);

    return (
        <Box id={props.id} className="chartdiv" style={{ minWidth: "10vw", minHeight: "10vh" }} {...props.container} />
    )
}