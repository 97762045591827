import { Box, TextField } from "@mui/material";
import { ConfirmModal } from "components/Modal";
import { useNotification } from "contexts/NotificationContext";
import { useMemo } from "react";
import { useDeleteTrainSubTypeMutation, } from "services/api/assistant";
import { TrainSubType, } from "types/Train";


interface SubTypeDeleteProps {
    open: boolean;
    onClose: () => void;
    value?: TrainSubType;
}

export function SubTypeDelete(props: SubTypeDeleteProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const defaultValue = useMemo(() => props.value, [props.value]);

    const [execDelete] = useDeleteTrainSubTypeMutation();

    const notify = useNotification();

    const handleContinue = async () => {
        try {
            if (defaultValue) {
                const data: TrainSubType.Delete = {
                    id: defaultValue.id,
                    type_id: defaultValue.type_id,
                }

                await execDelete(data).unwrap();
                notify.success(defaultValue.name, "Sub-Tipo eliminado")
            }
        } catch (error) {
            notify.http(error);
        }
    }

    return (
        <ConfirmModal
            title="Desea eliminar?"
            buttonNegative="Cancelar"
            buttonPositive="Eliminar"
            confirmationText={`ELIMINAR`}
            open={open}
            onClose={handleClose}
            onContinue={handleContinue}>
            <Box>
                <TextField
                    type="text"
                    name="name"
                    label="Nombre"
                    value={defaultValue?.name}
                    inputProps={{ maxLength: 32 }}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    disabled
                />
                <TextField
                    type="text"
                    name="content"
                    label="Descripción (opcional)"
                    value={defaultValue?.description}
                    inputProps={{ maxLength: 128 }}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    minRows={3}
                    maxRows={5}
                    multiline
                    fullWidth
                    disabled
                />
            </Box>
        </ConfirmModal >
    )
}