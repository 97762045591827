import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store';

interface AuthState {
    token: string | null;
}

const initialState: AuthState = {
    token: null,
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = btoa(action.payload);
        },
        resetToken: (state) => {
            state.token = null;
        }
    }
});

export default slice;
export const {
    setToken,
    resetToken,
} = slice.actions;

export const selectCurrentToken = (state: RootState) => state.auth.token;