import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState, } from "react";
import { MapContainer, MapContainerProps, TileLayer, TileLayerProps, useMap } from "react-leaflet";

export interface MapProps extends MapContainerProps {
    tileLayer?: TileLayerProps;
    onChangeMap?: (map: L.Map) => void;
}


const ChangeMap = ({ onChangeMap }: { onChangeMap?: MapProps['onChangeMap'] }) => {
    const map = useMap();
    useEffect(() => {
        if (onChangeMap) {
            onChangeMap(map);
        }
    }, [map, onChangeMap]);
    return null;
};

export function Map({ onChangeMap, ...props }: MapProps) {

    return (
        <MapContainer
            style={{ minHeight: 256, width: '100%' }}
            center={[0, 0]}
            zoom={4}
            {...props}>
            <ChangeMap onChangeMap={onChangeMap} />

            {(props.tileLayer) ?
                <TileLayer {...props.tileLayer} /> :
                <TileLayer
                    maxZoom={20}
                    subdomains={"0123"}
                    url={`https://mt{s}.google.com/vt/lyrs=m@186112443&hl=x-local&src=web&x={x}&y={y}&z={z}&s=Galile&apistyle=s.e:l.i|p.v:off,s.t:3|s.e:g`}
                    attribution="Datos del mapa ©2024 Google"
                />
            }
            {props.children}
        </MapContainer>
    )
}