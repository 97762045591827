import ReactJson, { ReactJsonViewProps } from "react-json-view";

interface JSONViewerProps extends ReactJsonViewProps {

}

export function JSONViewer(props: JSONViewerProps) {

    return (
        <ReactJson
            theme="rjv-default"
            iconStyle="square"
            onEdit={false}
            onAdd={false}
            onDelete={false}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={true}
            indentWidth={4}
            collapsed={true}
            sortKeys
            name={false}
            {...props}
        />
    )
}