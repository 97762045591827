import { Box, Typography } from "@mui/material";
import { ConfirmModal } from "components/Modal";
import { useEffect, useState } from "react";
import { useDeleteTypeMutation } from "services/api/admin";
import { Type } from "types/Category";

interface DeleteTypeProps {
    item: Type;
    onClose: () => void;
    onDeleted?: (item: Type) => void;
}

function Item(props: { title: string, value?: string | number | boolean }) {
    return (
        <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
            <Typography variant="caption" fontSize={16} fontWeight="700" color="text.primary">{props.title}: </Typography>
            <Typography variant="caption" fontSize={16} fontWeight="700" color="text.secondary">{props.value}</Typography>
        </Box>
    )
}

export function DeleteType(props: DeleteTypeProps) {
    const [execDelete, { isSuccess }] = useDeleteTypeMutation();
    const [open, setOpen] = useState<boolean>(true);

    useEffect(() => {
        if (!open) {
            props.onClose()
        }
    }, [open, props])

    const onClose = () => {
        setOpen(false)
    }

    const onContinue = async () => {
        await execDelete(props.item.code);

        if (isSuccess && props.onDeleted)
            props.onDeleted(props.item);
    }

    return (
        <ConfirmModal
            title="Desea eliminar el typo?"
            buttonNegative="Cancelar"
            buttonPositive="Eliminar"
            open={open}
            onClose={onClose}
            onContinue={onContinue}>
            <Item title="Código" value={props.item.code} />
            <Item title="Nombre" value={props.item.name} />
            {props.item.description &&
                <Item title="Descripcion" value={props.item.description} />
            }
            <Item title="Categoria" value={props.item.sub_category_code} />
        </ConfirmModal>
    )
}