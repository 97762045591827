export enum MessageSource {
    SYSTEM = 'system',
    AGENT = 'agent'
}

export interface Message {
    Sid: string;
    Source: MessageSource;
    Text: string;
    Profile: {
        Picture: string;
        Org?: string;
        Name: string;
    }
}
