import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import { Chart } from "./Chart";
import { IComponentDataItem } from "@amcharts/amcharts5/.internal/core/render/Component";

interface Props {
    id?: string;
    geodata: GeoJSON.FeatureCollection;
    mapSeries?: (series: am5map.MapPolygonSeries) => void;
    pointSeries?: (series: am5map.MapPointSeries) => void;
    zoomControl?: boolean;
}

export function ChartMap(props: Props) {
    const handler = (root: am5.Root) => {

        let chart = root.container.children.push(
            am5map.MapChart.new(root, {
                projection: am5map.geoMercator(),
                panX: "translateX",
                panY: "translateY",
            })
        );

        // Add zoom control
        if (props.zoomControl)
            chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

        // Set clicking on "water" to zoom out
        chart.chartContainer.get("background")?.events.on("click", function () {
            chart.goHome();
        });

        // Create main polygon series for countries
        let mapSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: props.geodata,
                valueField: "value",
                calculateAggregates: true
            })
        );

        if (props.mapSeries) {
            props.mapSeries(mapSeries)
        }

        // Create series for labels
        var pointSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
                polygonIdField: "polygonId"
            })
        );

        pointSeries.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Label.new(root, {
                    fontSize: 10,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    text: "{name}",
                    populateText: true
                })
            });
        });

        // Add state labels
        mapSeries.events.on("datavalidated", function (ev) {
            var series = ev.target;
            var labelData: any[] = [];

            series.mapPolygons.each(function (polygon: am5map.MapPolygon) {
                const dataItem: am5.DataItem<IComponentDataItem> = polygon.dataItem as am5.DataItem<IComponentDataItem>;
                const dataContext: any = dataItem.dataContext as any;
                labelData.push({
                    polygonId: dataContext.id,
                    name: dataContext.iso
                });
            })
            pointSeries.data.setAll(labelData);
        });

        //

        if (props.pointSeries) {
            let pointSeries = chart.series.push(
                am5map.MapPointSeries.new(root, {
                    geoJSON: props.geodata,
                })
            );
            props.pointSeries(pointSeries)
        }

        // Make stuff animate on load
        chart.appear(1000, 100);
    }

    return (
        <Chart id={props.id || "chartMap"} handler={handler} container={{ height: "75vh" }} />
    )
}