import { BaseQueryApi, QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { BaseQueryFn } from "@reduxjs/toolkit/query";

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, CreateAxiosDefaults } from "axios";
import { selectCurrentToken } from "services/slices/authSlice";
import { RootState } from "services/store";

//  

interface Data {
    [key: string]: any
};

interface Metadata extends Data {
};

export interface Failure {
    readonly code: number | string;
    readonly message: string;
}

declare type ResponseError = Failure | { error: Failure; } | { errors: Array<Failure>; };

export type Response<D extends Data> = D | ResponseError;

//  
const cluster = [
    process.env.REACT_APP_API_URL,
    process.env.REACT_APP_ASSISTANT_API_URL,
]

export function axiosBaseQuery(config: CreateAxiosDefaults): BaseQueryFn<AxiosRequestConfig> {
    const instance: AxiosInstance = axios.create(config);

    const parseError = (error: any): Array<Failure> => {
        if (error instanceof AxiosError) {
            let { response } = error as AxiosError<ResponseError, any>;
            if (response) {
                const data: ResponseError = response.data;
                if ("code" in data && "message" in data) {
                    return [data];
                } else if ("error" in data) {
                    return [data.error];
                } else if ("errors" in data) {
                    return data.errors;
                }
                return [{ code: 1, message: "Error response unknown" }]
            } else {
                return [{ code: error.code || "CLI", message: error.message }]
            }
        } else if (error instanceof Error) {
            return [{ code: "ERR", message: error.message }]
        } else {
            return [{ code: "ERR", message: "Uncaught TypeError" }]
        }
    }

    return async <D extends Data, B = any>(config: AxiosRequestConfig, api: BaseQueryApi): Promise<QueryReturnValue<D, Array<Failure>, Metadata>> => {
        try {
            if (!config.headers) {
                config.headers = {} as AxiosRequestHeaders
            }

            if (cluster.includes(instance.defaults.baseURL)) {
                if (!config.headers.Authorization) {
                    const { getState } = api;
                    const token = selectCurrentToken(getState() as RootState);
                    if (token)
                        config.headers.Authorization = `Bearer ${atob(token)}`;
                }
            }

            const response = await instance.request<D, AxiosResponse<D, B>, B>(config);
            const data = response.data;
            return { data };
        } catch (error) {
            return {
                error: parseError(error)
            }
        }
    }
}
