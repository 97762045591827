import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { KeyboardArrowDown, Delete, Edit, Info } from '@mui/icons-material';
import { Box } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { ShowType } from './ShowType';
import { DeleteType } from './DeleteType';
import { EditType } from './EditType';
import { Type } from 'types/Category';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface MenuActionProps {
    item: Type;
}

export function MenuAction(props: MenuActionProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [action, setAction] = useState<"show" | "edit" | "delete" | "list" | null>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}>
                Detalle
            </Button>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => setAction("show")} disableRipple>
                    <Info />
                    Detalle
                </MenuItem>
                <MenuItem onClick={() => setAction("edit")} disableRipple>
                    <Edit />
                    Editar
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => setAction("delete")} disableRipple>
                    <Delete />
                    Eliminar
                </MenuItem>
            </StyledMenu>
            {(action === "show") &&
                <ShowType item={props.item} onClose={() => setAction(null)} />}

            {(action === "delete") &&
                <DeleteType item={props.item} onClose={() => setAction(null)} />}

            {(action === "edit") &&
                <EditType item={props.item} onClose={() => setAction(null)} />}
        </Box>
    );
}
