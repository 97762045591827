import { Markdown } from "components/Markdown/Markdown";
import { SystemMessage } from "types/Message";


interface ContentSystemProps {
    message: SystemMessage;
}

export function ContentSystem(props: ContentSystemProps) {
    return <Markdown>{props.message.content}</Markdown>;
}