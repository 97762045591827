import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { styled, useColorScheme } from '@mui/material/styles';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { DarkModeRounded, LightModeRounded, Menu as MenuIcon } from '@mui/icons-material';
import logo from 'assets/images/logo.png'
import { Link } from 'react-router-dom';
import { usePaletteMode } from 'hooks/usePaletteMode';

interface AppBarProps extends MuiAppBarProps {
    open: boolean;
    drawerWidth: number;
}

const definition = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open', })<AppBarProps>;

const AppBar = definition(({ theme, open, drawerWidth }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


interface Props {
    open: boolean;
    drawerWidth: number;
    toggleDrawer: () => void;
}

export function Navbar({ open, drawerWidth, toggleDrawer }: Props) {
    const { paletteMode, setPaletteMode } = usePaletteMode();

    const handleToggleMode = () => {
        if (paletteMode === "light") {
            setPaletteMode("dark")
        } else {
            setPaletteMode("light")
        }

    }

    return (
        <AppBar position="absolute" open={open} drawerWidth={drawerWidth} color='inherit'>
            <Toolbar sx={{ pr: '24px', }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{ marginRight: '36px' }}>
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}>
                    {!open && <Link to="/" className="logo-box"><img style={{ width: '10%' }} src={logo} alt="Logo" /></Link>}
                </Typography>
                <Tooltip title={paletteMode === 'light' ? 'Modo noche' : 'Modo dia'}>
                    <IconButton
                        id="toggle-mode"
                        sx={{ alignSelf: 'center' }}
                        onClick={handleToggleMode}
                    >
                        {paletteMode === 'light' ? <DarkModeRounded /> : <LightModeRounded color='warning' />}
                    </IconButton>
                </Tooltip>
                <ProfileMenu />
            </Toolbar>
        </AppBar>
    );
}
