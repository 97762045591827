import { Assistant, ModelTraining, QuestionAnswer, Settings } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";

interface Module {
    title: string;
    caption?: string;
    path: string;
    icon: React.ReactNode;
}

export function AssistantPage() {
    const navigate = useNavigate();
    const modules: Module[] = [
        { title: 'Conversaciones', caption: 'Todas las conversaciones.', path: 'conversations', icon: <QuestionAnswer style={{ fontSize: 40 }} /> },
        { title: 'Agente virtual', caption: 'Chats del agente virtual.', path: 'completions', icon: <Assistant style={{ fontSize: 40 }} /> },
        { title: 'Entrenamiento', caption: 'Datos de entrenamiento.', path: 'trains', icon: <ModelTraining style={{ fontSize: 40 }} /> },
        { title: 'Ajustes', caption: 'Ajustes del asistente', path: 'settings', icon: <Settings style={{ fontSize: 40 }} /> },
    ];

    return (
        <Grid container spacing={3} padding={3}>
            {modules.map((module) => (
                <Grid xs={12} sm={6} md={4} key={module.path}>
                    <Card style={{ height: '100%', minHeight: '20vh' }}>
                        <CardActionArea
                            style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                            onClick={() => navigate(module.path)}>
                            <CardContent style={{ textAlign: 'center', }}>
                                {module.icon}
                                <Typography variant="subtitle1" component="div">
                                    {module.title}
                                </Typography>
                                <Typography variant="caption" component="div">
                                    {module.caption}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    )
}