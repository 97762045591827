import { Box, Stack } from "@mui/material";
import { Composer } from "./Composer";
import { useEffect, useState } from "react";
import { Bubble } from "./Bubble";
import { FollowUp, Event } from "types/Event";
import { useRetrieveFollowUpsEventQuery } from "services/api/admin";

interface FollowTabProps {
    incident?: Event;
}

export function FollowUpsTab(props: FollowTabProps) {
    const [incident, setIncident] = useState<Event>();
    useEffect(() => setIncident(props.incident), [props.incident]);

    const [follows, setFollows] = useState<Array<FollowUp>>();
    const { data } = useRetrieveFollowUpsEventQuery(incident?.id!!);
    useEffect(() => setFollows(data), [data]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}>
            <Box
                sx={{
                    height: { xs: "calc(100dvh - var(--Header-height))", lg: "100dvh" },
                    display: "flex",
                    flex: 1,
                    minHeight: '44dvh',
                    px: 2,
                    py: 3,
                    flexDirection: "column-reverse",
                    mb: 4
                }}>
                <Stack spacing={2} justifyContent="flex-end">
                    {
                        follows?.map((item: FollowUp) => <Bubble content={item} />)
                    }
                </Stack>
            </Box>
            <Composer incidentId={incident?.id} />
        </Box>
    )
}