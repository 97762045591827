import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import 'moment/locale/es';


// CSS
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'leaflet/dist/leaflet.css';
import 'styles/main.css';


import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from 'services/store';
import { persistStore } from 'redux-persist';
import { PaletteModeProvider } from 'contexts/PaletteModeContext';
import { NotificationProvider } from 'contexts/NotificationContext';


const container: HTMLElement | null = document.getElementById("root");
if (!container) {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
let persistor = persistStore(store);

const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <NotificationProvider>
      <PaletteModeProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </ReduxProvider>
      </PaletteModeProvider>
    </NotificationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
