import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Stack } from "@mui/material";
import { ButtonLink } from "components/Button/ButtonLink";
import { SelectTrainChatState } from "components/Select/SelectTrainChatState";
import { useUpdateTrainChatStateMutation } from "services/api/assistant";
import { Role } from "types/Message";
import { TrainChat } from "types/Train";

interface SetupProps {
    trainChat?: TrainChat;
    sender?: Role;
    onChangeSender?: (sender: Role) => void;
}

export function Setup({ trainChat, sender = "user", onChangeSender }: SetupProps) {
    const [execUpdate] = useUpdateTrainChatStateMutation();

    const handleChangeState = async (value?: TrainChat.State) => {
        if (!value || !trainChat || trainChat.state === value) return;

        try {
            const data: TrainChat.UpdateState = {
                id: trainChat.id,
                case_id: trainChat.case_id,
                state: value,
            };
            await execUpdate(data).unwrap();
        } catch (error) {
            console.error("Error updating train chat state:", error);
        }
    };

    return (
        <Paper
            sx={{
                height: "79.5vh",
                overflow: "auto",
                px: 2,
            }}
        >
            <Divider textAlign="left">General</Divider>

            <Stack direction="column" spacing={4} mt={4}>
                <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="sender-select-label">Ver mensajes como</InputLabel>
                    <Select<Role>
                        labelId="sender-select-label"
                        id="sender-select"
                        label="Ver mensajes como"
                        value={sender}
                        onChange={(e) => onChangeSender?.(e.target.value as Role)}
                    >
                        <MenuItem value="system">Sistema</MenuItem>
                        <MenuItem value="user">Usuario</MenuItem>
                        <MenuItem value="assistant">Asistente</MenuItem>
                    </Select>
                </FormControl>

                {trainChat && (
                    <SelectTrainChatState
                        value={trainChat.state}
                        onChange={handleChangeState} />
                )}

                {trainChat?.completion_id && (
                    <ButtonLink
                        to={`/assistant/completions?id=${trainChat.completion_id}`}
                        variant="contained"
                    >
                        Ver chat del asistente
                    </ButtonLink>
                )}
            </Stack>
        </Paper>
    );
}
