import { ArrowBack, Attachment, Chat, ContactEmergency, History, Report } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Chip, Paper, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Tabs } from "components/Tabs/Tabs";
import { TabEmergencyChat } from "features/TabEmergency/TabEmergencyChat";
import { TabEmergencyChatAttachment } from "features/TabEmergency/TabEmergencyChatAttachment";
import { TabEmergencyContact } from "features/TabEmergency/TabEmergencyContact";
import { TabEmergencyContactHistory } from "features/TabEmergency/TabEmergencyContactHistory";
import { TabEmergencyFollow } from "features/TabEmergency/TabEmergencyFollow";
import { TabEmergencyFollowAttachment } from "features/TabEmergency/TabEmergencyFollowAttachment";
import { TabEmergencyInfo } from "features/TabEmergency/TabEmergencyInfo";
import { useNavigate } from "react-router-dom";


export function EmergencyPage() {
    const navigate = useNavigate();

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <Box sx={{ width: '100%', mb: 2 }}>
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Grid container spacing={1} columns={14}>
                        <Grid xs={2} xsOffset={1}>
                            <AvatarGroup max={4} total={5}>
                                <Avatar alt="TuRed123" src="https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED" />
                                <Avatar alt="Policia" src="https://www.policia.gov.co/sites/default/files/escudo-policia.png" />
                                <Avatar alt="Salud" src="https://as2.ftcdn.net/v2/jpg/06/72/71/65/1000_F_672716595_z4A4rrsrd1kFaYf5daOKrdBDAukOVoYN.jpg" />
                                <Avatar alt="Bomberos" src="https://www.shutterstock.com/image-vector/vector-fire-department-emblems-design-260nw-2340843179.jpg" />
                            </AvatarGroup>
                        </Grid>
                        <Grid xs={6} xsOffset={1}>
                            <Grid container spacing={2} columns={14}>
                                <Grid xs={3}>
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="contentTitle"
                                        component="div">
                                        #30900
                                    </Typography>
                                </Grid>
                                <Grid xs={7} sx={{ overflow: 'auto' }}>
                                    <Typography
                                        sx={{ height: 32 }}
                                        variant="h6"
                                        id="contentTitle"
                                        component="div"
                                        noWrap>
                                        Hurto en motocicleta
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Chip label="GESTIÓN" color="primary" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={4}>
                            <Box display="flex" justifyContent="flex-end">
                                <Chip icon={<ArrowBack />} label="Atrás" onClick={() => navigate(-1)} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1, p: 2 }}>
                <Grid container spacing={2} columns={14} >
                    <Grid xs={4}>
                        <Tabs
                            tabs={[
                                { icon: <Report />, label: "EMERGENCIA", value: 'emergencia' },
                                { icon: <ContactEmergency />, label: "CONTACTO", value: 'contacto' },
                                { icon: <History />, label: "HISTORIAL", value: 'historial' },
                            ]}>
                            <TabEmergencyInfo value="emergencia" />
                            <TabEmergencyContact value="contacto" />
                            <TabEmergencyContactHistory value="historial" />
                        </Tabs>
                    </Grid>
                    <Grid xs={6}>
                        <Tabs
                            tabs={[
                                { icon: <Chat />, label: "SEGUIMIENTO", value: 'seguimiento' },
                                { icon: <Attachment />, label: "ADJUNTOS", value: 'adjuntos' },
                            ]}>
                            <TabEmergencyFollow value="seguimiento" />
                            <TabEmergencyFollowAttachment value="adjuntos" />
                        </Tabs>
                    </Grid>
                    <Grid xs={4}>
                        <Tabs
                            tabs={[
                                { icon: <Chat />, label: "CHAT", value: 'chat' },
                                { icon: <Attachment />, label: "ADJUNTOS", value: 'adjuntos' },
                            ]}>
                            <TabEmergencyChat value="chat" />
                            <TabEmergencyChatAttachment value="adjuntos" />
                        </Tabs>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}