import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { SxProps, ThemeProvider } from '@mui/material/styles';
import { Container, CssBaseline, Theme, Toolbar } from "@mui/material";
import { Outlet } from 'react-router-dom';
import { Copyright } from 'components/Copyright/Copyright';
import logo from 'assets/images/logo.png'
import login_background from 'assets/images/login_background.png'; // Esta es la imagen de fondo

interface LogoProps {
    disabledLink?: boolean;
    sx?: SxProps<Theme>;
    [key: string]: any;
}

interface Props {
    theme: Theme;
}


const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
    return (
        <Link href="/" sx={{ display: 'contents' }}>
            <Box
                ref={ref}
                component="div"
                sx={{
                    display: 'inline-flex',
                    ...sx,
                }}
                {...other}
            >
                <img style={{ width: '100%' }} src={logo} alt="Logo" />
            </Box>
        </Link>
    );
});


export function AuthLayout(props: Props) {
    return (
        <ThemeProvider theme={props.theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    backgroundImage: `url(${login_background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Logo
                        sx={{
                            position: 'fixed',
                            top: { xs: 16, md: 24 },
                            left: { xs: 16, md: 24 },
                        }} />
                    <Toolbar />
                    <Outlet />
                </Container>
            </Box>
        </ThemeProvider>
    )
}