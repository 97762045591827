import { AdminAPI } from ".";
import { SubCategory } from "types/Category";

export const injectSubCategory = (api: AdminAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveSubCategories: build.query<Array<SubCategory>, void>({
            query: () => ({
                url: "sub_categories",
                method: "GET"
            }),
            providesTags: (result) => ([
                { type: "SubCategory", id: `LIST` },
                ...(result || []).map(({ code: id }) => ({ type: "SubCategory", id } as const))
            ]),
        }),
        createSubCategory: build.mutation<SubCategory, SubCategory.Create>({
            query: (body) => ({
                url: "sub_categories",
                method: "POST",
                data: body
            }),
            invalidatesTags: [{ type: "SubCategory", id: "LIST" }]
        }),
        retrieveSubCategory: build.query<SubCategory, SubCategory['code']>({
            query: (code) => ({
                url: `sub_categories/${code}`,
                method: "GET"
            }),
            providesTags: (result, err, code) => ([{ type: "SubCategory", id: code }]),
        }),
        updateSubCategory: build.mutation<SubCategory, SubCategory.Update>({
            query: (body) => {
                const { code, ...data } = body
                return ({
                    url: `sub_categories/${code}`,
                    method: "PUT",
                    data
                })
            },
            invalidatesTags: (result, error, { code }) => [{ type: "SubCategory", id: "LIST" }, { type: "SubCategory", id: code }],
        }),
        deleteSubCategory: build.mutation<void, SubCategory['code']>({
            query: (code) => ({
                url: `sub_categories/${code}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, id) => [{ type: "SubCategory", id: "LIST" }, { type: "SubCategory", id }],
        }),
        retrieveTypesInSubCategory: build.query<SubCategory[], SubCategory['code']>({
            query: (code) => ({
                method: "GET",
                url: `sub_categories/${code}/types`,
            }),
            providesTags: (result, err, code) => ([
                { type: "SubCategory", id: `Type_${code}` },
                ...(result || []).map(({ code }) => ({ type: "Type", id: code } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});