export enum MessageBodyType {
    TEXT = 'text',
    LOCATION = 'location',
    MEDIA = 'media',
}

export enum MessageSource {
    SYSTEM = 'system',
    ASSISTANT = 'assistant',
    AGENT = 'agent',
    USER = 'user',
}

export enum MessageStatus {
    CREATED = "created",
    QUEUED = "queued",
    SENDING = "sending",
    SENT = "sent",
    FAILED = "failed",
    DELIVERED = "delivered",
    UNDELIVERED = "undelivered",
    RECEIVING = "receiving",
    RECEIVED = "received",
    ACCEPTED = "accepted",
    SCHEDULED = "scheduled",
    READ = "read",
    PARTIALLY_DELIVERED = "partially_delivered",
    CANCELED = "canceled",
}

export enum MessageChannel {
    SMS = "sms",
    WHATSAPP = "whatsapp",
    MESSENGER = "messenger",
}

interface MessageBase {
    Sid: string;
    Channel: MessageChannel;
    Source: MessageSource;
    Profile: {
        Picture: string;
        Org?: string;
        Name: string;
    }
}

interface MessageText extends MessageBase {
    Type: MessageBodyType.TEXT;
    Text: string;
}

interface MessageLocation extends MessageBase {
    Type: MessageBodyType.LOCATION;
    Latitude: number;
    Longitude: number;
    Address?: string;
    Label?: string;
}

interface MessageMedia extends MessageBase {
    Type: MessageBodyType.MEDIA;
    Media: [{
        ContentType: string;
        MediaUrl: string;
    }];
}

export declare type Message = MessageText | MessageLocation | MessageMedia;
