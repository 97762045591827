import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { setToken } from 'services/slices/authSlice';
import { LoadingButton } from '@mui/lab';
import { useNotification } from 'contexts/NotificationContext';
import { Copyright } from 'components/Copyright/Copyright';
import { useAppDispatch } from 'hooks/useRedux';
import { User } from 'types/User';
import { useAuthMutation } from 'services/api/admin';


//  https://codesandbox.io/p/sandbox/github/reduxjs/redux-toolkit/tree/master/examples/query/react/authentication?file=%2Fsrc%2Ffeatures%2Fauth%2FLogin.tsx%3A103%2C20&from-embed=
export function LoginPage() {
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [formState, setFormState] = useState<User.LoginRequest>({ username: '', password: '' });

    const [login, { isLoading, isError, error }] = useAuthMutation();

    const notify = useNotification();
    useEffect(() => {
        if (isError)
            notify.http(error);
    }, [error, isError, notify]);

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) =>
        setFormState((prev) => ({ ...prev, [name]: value }));

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const { access_token } = await login(formState).unwrap();
            console.log('login', "setToken", { access_token });

            dispatch(setToken(access_token));
            navigate('/', { replace: true });
            notify.success("Inicio de sesión exitoso", "Iniciar sesión");
        } catch (error) {
            console.error("login", error);
        }
    };

    return (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <Card
                sx={{
                    p: 5,
                    width: 1,
                    maxWidth: 420,
                }}
            >
                <Typography variant="h4">Iniciar sesión en TuRed123</Typography>
                <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
                    ¿No tienes una cuenta?
                    <Link variant="subtitle2" sx={{ ml: 0.5 }}>
                        Empezar
                    </Link>
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Stack spacing={3}>
                        <TextField
                            onChange={handleChange}
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Usuario"
                            name="username"
                            autoComplete="email"
                            autoFocus
                        />

                        <TextField
                            onChange={handleChange}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3 }}>
                        <Link href="#" variant="subtitle2" underline="hover">
                            ¿Has olvidado tu contraseña?
                        </Link>
                    </Stack>

                    <LoadingButton
                        loading={isLoading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Iniciar sesión
                    </LoadingButton>
                </Box>

                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Card>
        </Stack>
    );
}