import { Checkbox, Chip } from "@mui/material";
import { teal } from "@mui/material/colors";
import { ButtonLink } from "components/Button/ButtonLink";
import { Content } from "features/Content/Content";
import { EnhancedTable } from "features/EnhancedTable/EnhancedTable";


declare type EmergencyType = {
    id: number;
    institucion: string;
    contacto: string;
    celular: string;
    estado: 'activo' | 'inactivo';
}

const data: EmergencyType[] = [{
    id: 12,
    institucion: "Policia Seccional Atlantico",
    contacto: "Pedro Perez",
    celular: "3116828692",
    estado: "activo"
}, {
    id: 15,
    institucion: "Seccional de Salud del Atlantico",
    contacto: "Juan Paternina",
    celular: "3239209892",
    estado: "inactivo"
}];

export function InstitutionsPage() {
    return (
        <Content title='Intituciones' hasFilter>
            <EnhancedTable
                size={data.length}
                columns={[
                    { label: 'Código', key: 'id' },
                    { label: 'Institución', key: 'institucion' },
                    { label: 'Contacto', key: 'contacto' },
                    { label: 'Celular', key: 'celular' },
                    {
                        label: 'Estado', key: 'estado', renderCell(value, params) {
                            return <Chip label={params.estado.toUpperCase()} color={params.estado === "activo" ? "primary" : 'default'} size="small" variant="outlined" />
                        },
                    },
                    {
                        label: 'Acciones', renderCell(value, params) {
                            return (<ButtonLink to={`${params.id}`} >Detalle</ButtonLink>)
                        },
                    },
                ]}
                data={data}
            />
        </Content>
    )
}