import { Autocomplete, Box, TextField } from "@mui/material";
import { AutocompleteQuery } from "components/Autocomplete/AutocompleteQuery";
import { FormModal } from "components/Modal";
import { useEffect, useState } from "react";
import { useRetrieveCategoriesQuery, useRetrieveSubCategoriesQuery, useUpdateTypeMutation } from "services/api/admin";
import { SubCategory, Type } from "types/Category";

interface EditTypeProps {
    item: Type;
    onClose: () => void;
    onChanged?: (id: Type['code'], item: Partial<Omit<Type, 'id' | 'created_at'>>) => void;
}

export function EditType(props: EditTypeProps) {
    const [execUpdate, { isSuccess }] = useUpdateTypeMutation();
    const [open, setOpen] = useState<boolean>(true);
    const [subCategory, setSubCategory] = useState<SubCategory | null>(null);

    useEffect(() => {
        if (!open) {
            props.onClose()
        }
    }, [open, props]);

    const onClose = () => {
        setOpen(false)
    }

    const onSubmit = async (formData: { [key: string]: FormDataEntryValue; }) => {
        if (subCategory) {
            formData['sub_category_code'] = subCategory.code;
        }

        /*
        await execUpdate({ id: props.item.id, ...formData });

        if (isSuccess && props.onChanged) {
            props.onChanged(props.item.id, formData);
        }
        */
        return true;
    }

    const onChangeSubCategory = (item: SubCategory | null) => setSubCategory(item);

    return (
        <FormModal
            title="Editar evento"
            buttonPositive="Guardar"
            open={open}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <Box>
                <TextField
                    disabled
                    margin="dense"
                    name="id"
                    label="Código"
                    type="text"
                    fullWidth
                    variant="outlined"
                    defaultValue={props.item.code}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="name"
                    label="Nombre"
                    type="text"
                    fullWidth
                    variant="outlined"
                    defaultValue={props.item.name}
                />
                <TextField
                    margin="dense"
                    name="description"
                    label="Descripcion"
                    type="text"
                    multiline
                    fullWidth
                    variant="outlined"
                    minRows={3}
                    defaultValue={props.item.description}
                />
                <AutocompleteQuery<SubCategory, void>
                    query={useRetrieveSubCategoriesQuery}
                    queryArg={undefined}
                    onChangeValue={onChangeSubCategory}
                    dataKey="id"
                    dataValue="name"
                    label="Categoria"
                    defaultValue={props.item.sub_category_code}
                    TextField={{ margin: "dense" }}
                />
            </Box>
        </FormModal>
    )
}