import { TrainTool } from "../../../types/Train";
import { AssistantAPI } from ".";

export const injectTrainTool = (api: AssistantAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        retrieveTrainToolFunctions: build.query<TrainTool.Function<unknown>[], void>({
            query: () => ({
                url: "/trains/tools/functions",
                method: "GET"
            }),
            providesTags: (result) => ([
                { type: "TrainTool", id: 'LIST' },
                ...(result || []).map(({ function: functionName }) => ({ type: "TrainTool", id: functionName } as const))
            ])
        }),
        executeTrainToolFunction: build.mutation<JSONType, TrainTool.Function.Execute>({
            query: (body) => ({
                url: "/trains/tools/functions/execute",
                method: "POST",
                data: body
            })
        }),
    }),
    overrideExisting: "throw",
});
