import {
    Tab as MUITab,
    Tabs as MUITabs,
    Paper,
    Box
} from "@mui/material";
import { ReactElement, ReactNode, SyntheticEvent, useState } from "react";

interface TabProps {
    icon?: string | ReactElement;
    label?: ReactNode;
    value?: any;
}

interface TabPanelProps {
    children?: ReactNode;
    value: any;
}

export function TabPanel(props: TabPanelProps) {
    return (
        <Box role="tabpanel"
            id={`full-width-tabpanel-${props.value}`}
            aria-labelledby={`full-width-tab-${props.value}`}
            aria-controls={`simple-tabpanel-${props.value}`}
            height={"60vh"}
            overflow={"auto"}
            className="Scrollable"
            p={2}>
            {props.children}
        </Box>
    );
}

interface Props {
    children: ReactElement<TabPanelProps>[] | ReactElement<TabPanelProps>;
    tabs: Array<TabProps>;
    value?: any;
}

export function Tabs(props: Props) {
    const [value, setValue] = useState<any>(props.value || props.tabs[0].value || 0);

    const handleChange = (_: SyntheticEvent, newValue: any) => {
        setValue(newValue);
    };

    return (
        <Paper sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <MUITabs
                    value={value}
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="fullWidth"
                    onChange={handleChange}
                    centered>
                    {props.tabs.map(({ ...tabProps }) => (<MUITab {...tabProps} />))}
                </MUITabs>
            </Box>
            {(
                Array.isArray(props.children)
                    ? props.children.find((it) => (it.props.value === value))
                    : props.children
            )}
        </Paper>
    );
}