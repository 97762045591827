import { Link, Typography } from "@mui/material";


export function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.primary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://viatransdelcaribe.com">
                Vitrans del Caribe
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}