import { Box } from "@mui/system";
import { Theme } from '@rjsf/mui';
import { withTheme } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { JSONSchemaType } from "ajv";
import { useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle, ForwardedRef } from "react";

const Form = withTheme(Theme);

interface FormSchemaUIProps<T = any> {
    schema?: JSONSchemaType<T>;
    uiSchema?: UiSchema<T>;
    defaultFormData?: T;
    onChange?: (data?: Partial<T>) => void;
}


function Component<T = any>(props: FormSchemaUIProps<T>, ref: ForwardedRef<any>) {
    const schema = useMemo(() => props.schema, [props.schema]);
    const uiSchema = useMemo(() => ({
        "ui:submitButtonOptions": {
            "norender": true, // Hide the submit button
        },
        ...props.uiSchema
    }), [props.uiSchema]);
    const defaultData = useMemo(() => props.defaultFormData, [props.defaultFormData]);
    const onChange = useMemo(() => props.onChange, [props.onChange]);

    // Reference to the form
    const formRef = useRef<any>(null);

    //
    const hasForm = useMemo(() => !!(schema && schema.properties), [schema]);
    const [formData, setFormData] = useState<Partial<T>>();

    useEffect(() => {
        setFormData(defaultData);
    }, [defaultData]);

    useEffect(() => {
        if (onChange) {
            onChange(formData);
        }
    }, [formData, onChange]);

    useImperativeHandle(ref, () => ({
        validateForm: () => formRef.current.validateForm(formData),
        reset: () => formRef.current.reset(),
        submit: () => formRef.current.submit(),
    }));

    return (
        <Box>
            {hasForm && (
                <Form
                    ref={formRef} // Set the form reference
                    schema={schema as RJSFSchema}
                    uiSchema={uiSchema as UiSchema}
                    validator={validator}
                    formData={formData}
                    showErrorList={"bottom"}
                    onChange={(e) => setFormData(e.formData)}
                />
            )}
        </Box>
    );
}


export const FormSchemaUI = forwardRef<any, FormSchemaUIProps>(Component);
