import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useEffect, useState } from "react";
import { useUpdateCategoryMutation, } from "services/api/admin";
import { Category } from "types/Category";

interface EditTypeProps {
    item: Category;
    onClose: () => void;
    onChanged?: (id: Category['id'], item: Partial<Omit<Category, 'id' | 'created_at'>>) => void;
}

export function EditCategory(props: EditTypeProps) {
    const [execUpdate, { isSuccess }] = useUpdateCategoryMutation();
    const [open, setOpen] = useState<boolean>(true);

    useEffect(() => {
        if (!open) {
            props.onClose()
        }
    }, [open, props]);


    const onClose = () => {
        setOpen(false)
    }

    const onSubmit = async (formData: { [key: string]: FormDataEntryValue; }) => {
        /*
        await execUpdate({ id: props.item.id, ...formData });

        if (isSuccess && props.onChanged) {
            props.onChanged(props.item.id, formData);
        }
        */
        return true;
    }

    return (
        <FormModal
            title="Editar Categoria"
            buttonPositive="Guardar"
            open={open}
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <Box>
                <TextField
                    disabled
                    margin="dense"
                    name="id"
                    label="Código"
                    type="text"
                    fullWidth
                    variant="outlined"
                    defaultValue={props.item.id}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="name"
                    label="Nombre"
                    type="text"
                    fullWidth
                    variant="outlined"
                    defaultValue={props.item.name}
                />
                <TextField
                    margin="dense"
                    name="description"
                    label="Descripcion"
                    type="text"
                    multiline
                    fullWidth
                    variant="outlined"
                    minRows={3}
                    defaultValue={props.item.description}
                />
            </Box>
        </FormModal>
    )
}