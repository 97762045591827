import { Content } from "features/Content/Content";
import { EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { useState } from "react";
import { NewType } from "./NewType";
import { MenuAction } from "./MenuAction";
import { Type } from "types/Category";
import { Chip } from "@mui/material";
import { useRetrieveTypesQuery } from "services/api/admin";

interface Props {

}


export function TypesPage(props: Props) {
    const [hasCreate, setCreate] = useState<boolean>(false);

    const { data, isLoading, isFetching } = useRetrieveTypesQuery();

    return (
        <Content
            title='Eventos'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "65vh" }}>
            <EnhancedTable<Type>
                size={(data || []).length}
                columns={[
                    { label: 'Código', key: 'code' },
                    { label: 'Nombre', key: 'name' },
                    { label: 'Descripción', key: 'description' },
                    {
                        label: 'Sub-categoria', key: 'sub_category_code', renderCell(value) {
                            return (<Chip label={`sub-categoria #${value}`} />)
                        },
                    },
                    {
                        label: 'Acciones', renderCell(_, item) {
                            return (
                                <MenuAction item={item} />
                            )
                        },
                    },
                ]}
                data={data || []}
                isLoading={isLoading}
                isFetching={isFetching}
                onPageChange={() => ({})} />
            {hasCreate && <NewType onClose={() => setCreate(false)} />}
        </Content>
    )
}