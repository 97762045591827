import { Content } from "features/Content/Content";
import { EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { useEffect, useState } from "react";
import { NewCategory } from "./NewCategory";
import { MenuAction } from "./MenuAction";
import { useNotification } from "contexts/NotificationContext";
import { Failure } from "services/utils/axiosBaseQuery"
import { useRetrieveCategoriesQuery } from "services/api/admin";
import { Category } from "types/Category";

interface Props {

}


export function CategoriesPage(props: Props) {
    const [hasCreate, setCreate] = useState<boolean>(false);

    const { data, isLoading, isFetching, isError, error } = useRetrieveCategoriesQuery();
    const notify = useNotification()
    useEffect(() => {
        if (isError) {
            if (Array.isArray(error)) {
                (error as Array<Failure>).map((it) => notify.error(it.message))
            } else
                console.log("isError", isError, (error as Array<any>)[0]);
        }
    }, [error, isError, notify]);

    return (
        <Content
            title='Tipos'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "65vh" }}>
            <EnhancedTable<Category>
                size={(data || []).length}
                columns={[
                    { label: 'Código', key: 'code' },
                    { label: 'Nombre', key: 'name' },
                    { label: 'Descripción', key: 'description' },
                    {
                        label: 'Acciones', renderCell(_, item) {
                            return (
                                <MenuAction item={item} />
                            )
                        },
                    },
                ]}
                isFetching={isFetching}
                isLoading={isLoading}
                data={data || []}
                onPageChange={() => ({})} />

            {hasCreate &&
                <NewCategory onClose={() => setCreate(false)} />}
        </Content>
    )
}