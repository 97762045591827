import { useMemo } from "react";
import { Box, Paper, Stack, Toolbar, Typography } from "@mui/material";
import moment from "moment";
import { Conversation } from "types/Conversation";
import { ButtonLink } from "components/Button/ButtonLink";


interface ConversationDetailsProps {
    conversation?: Conversation;
}


function Item(props: { title: string, value?: string }) {
    return (
        <Box flexGrow={1} overflow="hidden" textOverflow="ellipsis">
            <Typography variant="caption" color="primary" fontWeight="700" fontSize={14}>{props.title}: </Typography>
            <Typography variant="caption" color="text.primary" fontWeight="500" fontSize={14}>{props.value}</Typography>
        </Box>
    )
}

export function ConversationDetails(props: ConversationDetailsProps) {
    const conversation = useMemo(() => props.conversation, [props.conversation]);

    const getStatus = (status?: Conversation['status']) => {
        switch (status) {
            case "active":
                return "Conversación activa";
            case "inactive":
                return "Conversación inactiva";
            case "closed":
                return "Conversación finalizada";
            default:
                return status
        }
    }

    return (
        <Paper sx={{ height: "80vh", backgroundColor: 'background.paper' }}>
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="contentTitle"
                    component="div">
                    {conversation && moment(conversation?.created_at).format('DD [de] MMMM, hh:mm a')}
                </Typography>
            </Toolbar>
            {conversation &&
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        p: 2,
                    }}>
                    <Stack direction="column" spacing={1} mb={8}>
                        <Typography color="text.secondary" fontWeight="500" mb={1}>{conversation?.channel?.toUpperCase()}</Typography>

                        <Item title="Remitente (usuario)" value={conversation?.sender?.split(':')[1]} />
                        <Item title="Destinatario (tured123)" value={conversation?.recipient?.split(':')[1]} />
                        <Item title="Estado" value={getStatus(conversation?.status)} />

                        {conversation.completion_id &&
                            <ButtonLink to={`/assistant/completions?id=${conversation.completion_id}`} variant="contained">Inspeccionar asistente</ButtonLink>
                        }
                    </Stack>
                </Box>
            }
        </Paper>
    )
}
