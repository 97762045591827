import { Box, Typography } from "@mui/material";
import { WidgetTotal } from "./WidgetTotal";
import { ReactNode } from "react";
import { createAppTheme } from "@arwes/react";

interface Props {
    title: ReactNode;
    subtitle?: ReactNode;
    children?: ReactNode;
}

const theme = createAppTheme();

export function Widget(props: Props) {
    return (
        <Box component="div" sx={{
            m: 2,
            borderRadius: 1,
            border: "1px solid rgba(61, 71, 81, 0.1)",
            height: '27vh'
        }}>
            <Box component="div" sx={{
                height: '4vh',
                width: 'auto',
                pl: 1,
                pr: 1,
                fontSize: '.5rem',
                justifyContent: 'center',
                overflow: 'hidden',
                backgroundColor: theme.colors.secondary.bg(0), //"rgba(7, 19, 15, 0.6)",
                border: "1px solid rgba(61, 71, 81, 0.3)"
            }}>
                <Box flexGrow={1} overflow={"hidden"} textOverflow={"ellipsis"}>
                    <Typography variant="caption" typography="subtitle2" fontWeight="700" pr={1}>{props.title}</Typography>
                    <Typography variant="caption" typography="subtitle2" fontWeight="100" fontSize=".7rem">{props.subtitle}</Typography>
                </Box>
            </Box>
            <Box
                component="div"
                overflow="auto"
                className='Scrollable'
                justifyContent="center"
                borderRadius={1}
                height={'23vh'}
                sx={{ backgroundColor: theme.colors.primary.bg(1) }}
                p={2}>
                {props.children}
            </Box>
        </Box>
    )
}