import { ConfirmModal } from "components/Modal";
import { useNotification } from "contexts/NotificationContext";
import { useMemo } from "react";
import { useDeleteTrainMessageMutation } from "services/api/assistant";
import { AssistantMessage, SystemMessage, ToolMessage, UserMessage } from "types/Message";
import { TrainMessage } from "types/Train";

interface DeleteMessageProps {
    open: boolean;
    onClose: () => void;
    message: TrainMessage;
}

export function DeleteMessage(props: DeleteMessageProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const notify = useNotification();

    const message = useMemo(() => {
        const message = props.message;
        switch (message.role) {
            case "system":
                return {
                    role: "system",
                    name: message.name,
                    content: message.content,
                } as SystemMessage;
            case "user":
                return {
                    role: "user",
                    name: message.name,
                    content: message.content,
                } as UserMessage;
            case "assistant":
                return {
                    role: "assistant",
                    name: message.name,
                    content: message.content,
                    tool_calls: message.tool_calls,
                } as AssistantMessage;
            case "tool":
                return {
                    role: "tool",
                    tool_call_id: message.tool_call_id,
                    content: message.content,
                } as ToolMessage;
        }
    }, [props.message]);

    const [execDelete,] = useDeleteTrainMessageMutation();

    const onContinue = async () => {
        try {
            await execDelete({ id: props.message.id, chat_id: props.message.chat_id });
        } catch (error) {
            notify.http(error);
        }
    }

    return (
        <ConfirmModal
            title="Desea eliminar el mensaje?"
            buttonNegative="Cancelar"
            buttonPositive="Eliminar"
            confirmationText={`ELIMINAR_MENSAJE`}
            open={open}
            onClose={handleClose}
            onContinue={onContinue}>

        </ConfirmModal>
    )
}