import { Chip } from "@mui/material";
import { ButtonLink } from "components/Button/ButtonLink";
import { RelativeTime } from "components/RelativeTime/RelativeTime";
import { Content } from "features/Content/Content";
import { CellProps, EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { NewEvent } from "./NewEvent";
import { useState } from "react";
import { Event } from "types/Event";
import { useRetrieveEventsQuery } from "services/api/admin";


interface EventsPageProps {

}

export function EventsPage(props: EventsPageProps) {
    const [hasCreate, setCreate] = useState<boolean>(false);
    const { data } = useRetrieveEventsQuery();

    const columns: CellProps<Event>[] = [
        { label: 'Código', key: 'id' },
        { label: 'Dirección', key: 'address' },
        { label: 'Poblado', key: 'settlement_code' },
        { label: 'Descripción', key: 'description' },
        {
            label: 'Fecha', key: 'created_at', renderCell(value) {
                return <RelativeTime date={value as string} />
            },
        },
        {
            label: 'Estado', key: 'status', renderCell(value) {
                if (!value) return null;
                return <Chip
                    label={value === "reported" ? "REPORTADO" : value}
                    color={value === "reported" ? "error" : "default"}
                    size="small"
                    variant="outlined"
                />
            }
        },
        {
            label: 'Acciones', key: 'id', renderCell(value) {
                return (<ButtonLink to={`${value}`} >Detalle</ButtonLink>)
            }
        },
    ];

    return (
        <Content
            title='Eventos'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "75vh" }}>
            <EnhancedTable<Event>
                size={(data || []).length}
                columns={columns}
                data={data || []}
                onPageChange={() => ({})} />
            {hasCreate && <NewEvent onClose={() => setCreate(false)} />}
        </Content>
    )
}