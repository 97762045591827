import { MapViewer } from "components/MapViewer/MapViewer";

//  https://augmented-ui.com/
//  https://arwes.dev/

//  https://commerce.nearform.com/blog/2021/future-ui/
//  https://i.pinimg.com/originals/2b/3a/96/2b3a96825f17276b7550c869da0b3fd3.gif
export function HomePage() {

    return (
        <MapViewer style={{ minHeight: 545, }}
            center={[10.976, -74.806]}
            zoom={12}
            dragging={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            zoomControl={true} />
    )
}