import { Box } from "@mui/material";
import { MapContainer, MapContainerProps, TileLayer, TileLayerProps, useMap } from "react-leaflet";
import { MinimapBounds } from "./MinimapBounds";
import { useMemo } from "react";

const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

interface MinimapControlProps {
    position: keyof typeof POSITION_CLASSES;
    zoom?: MapContainerProps['zoom'];
    tileLayer?: TileLayerProps;
}

export function MinimapControl({ position, zoom, ...props }: MinimapControlProps) {
    const parentMap = useMap();
    const mapZoom = zoom || 0;

    const minimap = useMemo(
        () => (
            <MapContainer
                style={{ height: 80, width: 80 }}
                center={parentMap.getCenter()}
                zoom={mapZoom}
                dragging={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                attributionControl={false}
                zoomControl={false}>
                {(props.tileLayer) ?
                    <TileLayer {...props.tileLayer} /> :
                    <TileLayer
                        subdomains={"0123"}
                        url={`https://mt{s}.google.com/vt/lyrs=m@186112443&hl=x-local&src=web&x={x}&y={y}&z={z}&s=Galile&apistyle=s.t:0|s.e:l|p.v:off`}
                    />
                }
                <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
            </MapContainer>
        ), [mapZoom, parentMap, props.tileLayer])

    return (
        <Box component="div" className={(position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright}>
            <Box className="leaflet-control leaflet-bar">
                {minimap}
            </Box>
        </Box>
    )
}