import { Content } from "features/Content/Content";
import { CellProps, EnhancedTable } from "features/EnhancedTable/EnhancedTable";
import { useState } from "react";
import { Chip, } from "@mui/material";
import { useRetrieveCitiesQuery } from "services/api/admin";
import { City } from "types/Location";


export function CitiesPage() {
    const [hasCreate, setCreate] = useState<boolean>(false);
    const { data, isLoading, isFetching } = useRetrieveCitiesQuery();


    const columns: CellProps<City>[] = [
        { label: 'Id', key: 'code' },
        { label: 'Código', key: 'code' },
        { label: 'Nombre', key: 'name' },
        {
            label: 'Departamento', key: 'department_code', renderCell(value) {
                return (<Chip label={`${value}`} />)
            },
        },
        {
            label: 'Acciones', renderCell(_, item) {
                return (<></>)
            },
        },
    ];

    return (
        <Content
            title='Municipios'
            onCreateClick={() => setCreate(true)}
            boxComponent={{ overflow: "auto", height: "65vh" }}>
            <EnhancedTable<City>
                size={(data || []).length}
                columns={columns}
                data={data || []}
                onPageChange={() => ({})}
                isLoading={isLoading}
                isFetching={isFetching}
            />
        </Content>
    )
}