const value: GeoJSON.FeatureCollection = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "id": "08001",
      "properties": {"id": "08001", "iso": "BAQ", "name": "BARRANQUILLA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.84946230399993, 11.097782467000059],
              [-74.84938482899992, 11.097539763000043],
              [-74.84930883599992, 11.09729488000005 ],
              [-74.8492343129999 , 11.09704778300005 ],
              [-74.84916123999994, 11.096798407000051],
              [-74.84908971999994, 11.096547085000054],
              [-74.8490196109999 , 11.09629338600007 ],
              [-74.84895090999993, 11.096037251000041],
              [-74.84888358899991, 11.09577861200006 ],
              [-74.84881763499993, 11.095517406000056],
              [-74.84875302799992, 11.095253550000052],
              [-74.8486897439999 , 11.094986969000047],
              [-74.84862777099994, 11.094717565000053],
              [-74.84856709999991, 11.094445356000051],
              [-74.8485076899999 , 11.094170133000091],
              [-74.8484495209999 , 11.093891795000047],
              [-74.84839255799994, 11.093610217000048],
              [-74.8483367899999 , 11.09332528400006 ],
              [-74.84828217299992, 11.093036837000056],
              [-74.84817629999992, 11.09244876400004 ],
              [-74.84809488199994, 11.091968050000048],
              [-74.84801601299995, 11.091476141000044],
              [-74.84793952599995, 11.090972003000047],
              [-74.84786521299992, 11.090454263000083],
              [-74.84779293899993, 11.089922048000062],
              [-74.84772231099993, 11.089372513000058],
              [-74.84765299199995, 11.088803079000058],
              [-74.84758450999993, 11.088209807000055],
              [-74.84747438099993, 11.08719035200005 ],
              [-74.84736022699991, 11.086052401000074],
              [-74.84725617499993, 11.084956635000083],
              [-74.8470092309999 , 11.082286281000052],
              [-74.8468744729999 , 11.08090018300004 ],
              [-74.84680210999994, 11.080199185000083],
              [-74.84673083799993, 11.079542351000043],
              [-74.8466594379999 , 11.078918452000039],
              [-74.84658722799992, 11.078321588000051],
              [-74.84650502699992, 11.077681941000037],
              [-74.8464205699999 , 11.077065877000052],
              [-74.84633338799995, 11.076470020000045],
              [-74.84624315099995, 11.075892056000043],
              [-74.84614944399993, 11.07532943700005 ],
              [-74.84605210899991, 11.07478128300005 ],
              [-74.84595092799992, 11.074246304000042],
              [-74.84584569999993, 11.07372349700006 ],
              [-74.84573351099993, 11.073199570000043],
              [-74.84561672299992, 11.072686703000045],
              [-74.84549517799991, 11.072184200000038],
              [-74.84536873499991, 11.071691496000085],
              [-74.84530361899994, 11.071448605000057],
              [-74.84523723799992, 11.071207977000086],
              [-74.84516956399995, 11.07096955500009 ],
              [-74.84510058699993, 11.070733293000046],
              [-74.84503029399991, 11.07049912900004 ],
              [-74.84495866899994, 11.07026703300005 ],
              [-74.8448856999999 , 11.07003695900005 ],
              [-74.84481137499995, 11.069808862000059],
              [-74.84473560999993, 11.069582497000056],
              [-74.84465846099994, 11.069358041000044],
              [-74.84457991599993, 11.069135465000045],
              [-74.84449996399991, 11.068914721000056],
              [-74.84441859399993, 11.06869579000005 ],
              [-74.84433579299991, 11.068478643000049],
              [-74.84425155199995, 11.068263243000047],
              [-74.84416585999992, 11.068049569000038],
              [-74.84407869699993, 11.06783758000006 ],
              [-74.8439900699999 , 11.067627271000049],
              [-74.84389995899994, 11.06741861100005 ],
              [-74.8438083559999 , 11.067211579000059],
              [-74.84371525199992, 11.067006155000058],
              [-74.84362063899994, 11.066802323000047],
              [-74.84352450699993, 11.066600053000059],
              [-74.84342684699993, 11.066399339000043],
              [-74.8430788849999 , 11.065698618000056],
              [-74.84274031099994, 11.065029393000088],
              [-74.84240846999995, 11.064386416000048],
              [-74.84208172299992, 11.063766520000058],
              [-74.84175819399991, 11.063166181000042],
              [-74.84173846499993, 11.063130410000042],
              [-74.84143743699991, 11.062584604000051],
              [-74.84111861299994, 11.062020277000045],
              [-74.84080111299994, 11.06147210000006 ],
              [-74.84048288199995, 11.060936643000048],
              [-74.8403239189999 , 11.060674436000056],
              [-74.84030480099995, 11.060643325000058],
              [-74.84016498599993, 11.06041579400005 ],
              [-74.84000602899994, 11.060160630000041],
              [-74.83984702299995, 11.059908893000056],
              [-74.83968791599995, 11.05966051300004 ],
              [-74.83952867399995, 11.059415436000052],
              [-74.83936923099992, 11.05917355400004 ],
              [-74.83920958699991, 11.058934873000055],
              [-74.83904970599991, 11.058699349000051],
              [-74.8388895729999 , 11.058466936000059],
              [-74.83872914099993, 11.058237593000058],
              [-74.83856840499993, 11.058011287000056],
              [-74.83840732899995, 11.057787985000061],
              [-74.83824589499994, 11.05756765700005 ],
              [-74.83795159099992, 11.057173119000083],
              [-74.83765338199993, 11.05678126500004 ],
              [-74.83735137299993, 11.05639224500004 ],
              [-74.83704568199994, 11.056006206000063],
              [-74.83673642699995, 11.055623300000093],
              [-74.83642371899992, 11.055243659000041],
              [-74.83610767099992, 11.054867424000065],
              [-74.8357883939999 , 11.05449472500004 ],
              [-74.83546602699994, 11.054125731000056],
              [-74.83514066099991, 11.053760529000044],
              [-74.83481239199995, 11.053399239000044],
              [-74.83448132899991, 11.053041979000056],
              [-74.83414758299995, 11.05268886500005 ],
              [-74.8338112539999 , 11.052340008000044],
              [-74.83347244399994, 11.051995508000061],
              [-74.83313125399991, 11.05165547200005 ],
              [-74.83278805499992, 11.051320262000047],
              [-74.8324426769999 , 11.05098970000006 ],
              [-74.83209521299995, 11.050663880000059],
              [-74.83174575999993, 11.050342887000056],
              [-74.83139441199995, 11.050026813000045],
              [-74.83104125199992, 11.049715732000038],
              [-74.83068637899993, 11.049409725000089],
              [-74.83056131199993, 11.049304180000092],
              [-74.83032987199994, 11.04910886500005 ],
              [-74.82997184499993, 11.04881324900009 ],
              [-74.8296123579999 , 11.048522920000039],
              [-74.82925148599992, 11.04823794500004 ],
              [-74.82888931199994, 11.047958382000047],
              [-74.82852591499994, 11.04768430100006 ],
              [-74.8281613659999 , 11.047415753000053],
              [-74.8277957489999 , 11.047152790000041],
              [-74.82742912299994, 11.046895469000049],
              [-74.82723366999994, 11.046762775000047],
              [-74.82703151499993, 11.046631043000048],
              [-74.82682222099993, 11.046500003000062],
              [-74.8266052319999 , 11.04636928800005 ],
              [-74.82638016599992, 11.046238670000037],
              [-74.8261458309999 , 11.046107445000075],
              [-74.82590111499991, 11.045974985000043],
              [-74.82564432099991, 11.045840377000047],
              [-74.82542738899991, 11.045729692000066],
              [-74.82519935699992, 11.045615926000039],
              [-74.8249578839999 , 11.045497899000054],
              [-74.82483627899995, 11.045439545000079],
              [-74.8246983109999 , 11.045373338000047],
              [-74.82464413299994, 11.04534785800007 ],
              [-74.82421259499995, 11.045144908000054],
              [-74.82302074199993, 11.044591955000044],
              [-74.82269475399994, 11.044438034000052],
              [-74.82240221199993, 11.04429738400006 ],
              [-74.82209064599994, 11.044144088000053],
              [-74.82180033399993, 11.043997172000047],
              [-74.82152630899992, 11.043854120000049],
              [-74.82126597899992, 11.043713572000058],
              [-74.8209885309999 , 11.043557992000046],
              [-74.82072383199994, 11.043403137000041],
              [-74.82047042599993, 11.043248156000061],
              [-74.8202273309999 , 11.04309244600006 ],
              [-74.8199934779999 , 11.042935318000048],
              [-74.81976851699994, 11.042776513000092],
              [-74.81955191499992, 11.042615657000056],
              [-74.81934326299995, 11.042452455000046],
              [-74.81921760699993, 11.042349253000054],
              [-74.81914589899992, 11.042290359000049],
              [-74.81893023599991, 11.042106219000061],
              [-74.81871586299991, 11.041918033000059],
              [-74.81814846599991, 11.041413440000042],
              [-74.81797751999994, 11.041264697000088],
              [-74.81782255799993, 11.041133002000038],
              [-74.81761021199992, 11.04095748800006 ],
              [-74.81739345699992, 11.04078330100009 ],
              [-74.8171721889999 , 11.040610363000042],
              [-74.8169462859999 , 11.040438577000089],
              [-74.8167156369999 , 11.04026785900004 ],
              [-74.81648008199994, 11.040098087000047],
              [-74.81623944799992, 11.039929140000083],
              [-74.81599355799995, 11.039760887000057],
              [-74.8157425789999 , 11.039593444000047],
              [-74.81548590599994, 11.03942638600006 ],
              [-74.81522327499994, 11.03925954500005 ],
              [-74.8149543639999 , 11.039092720000042],
              [-74.81467890899995, 11.03892574300005 ],
              [-74.81439638299992, 11.038758293000058],
              [-74.81410628999993, 11.038590076000048],
              [-74.81380800399995, 11.038420723000058],
              [-74.81356424799992, 11.038284848000046],
              [-74.81331436499994, 11.038147731000038],
              [-74.81279392399995, 11.037868508000088],
              [-74.81224124699992, 11.037580129000048],
              [-74.81164549999994, 11.037276939000037],
              [-74.8111231929999 , 11.037016281000092],
              [-74.81053916899992, 11.03672903000006 ],
              [-74.80861168699994, 11.035793782000042],
              [-74.80790726399994, 11.035449395000057],
              [-74.80722192999991, 11.035108988000047],
              [-74.80660662399993, 11.034796708000044],
              [-74.80628918699995, 11.034632462000047],
              [-74.8059836999999 , 11.034472032000053],
              [-74.8056885389999 , 11.034314559000052],
              [-74.8054025859999 , 11.034159456000054],
              [-74.80512461599994, 11.034006046000059],
              [-74.80485420799994, 11.033854094000048],
              [-74.8045907259999 , 11.033703244000037],
              [-74.80433369399992, 11.033553225000048],
              [-74.80408777699995, 11.033406841000044],
              [-74.80384730199995, 11.033260815000062],
              [-74.80361197399992, 11.033114969000053],
              [-74.80338155499993, 11.032969149000053],
              [-74.80315577899995, 11.032823190000045],
              [-74.80293449599992, 11.032676993000052],
              [-74.80271752899995, 11.032530441000063],
              [-74.80250473199993, 11.032383441000093],
              [-74.8022958009999 , 11.032235760000049],
              [-74.80209078399992, 11.032087451000052],
              [-74.80188957099995, 11.031938422000053],
              [-74.80169206399995, 11.031788599000038],
              [-74.80149815999994, 11.031637913000054],
              [-74.80130778599994, 11.031486301000086],
              [-74.80112087399993, 11.031333706000055],
              [-74.80093734499991, 11.031180067000037],
              [-74.80070205599992, 11.030977110000038],
              [-74.80046526599995, 11.030765933000055],
              [-74.80022645899993, 11.030546077000054],
              [-74.79998498799995, 11.030316939000045],
              [-74.79974041799994, 11.030078124000056],
              [-74.7994914059999 , 11.029828336000037],
              [-74.79923675499992, 11.029566376000048],
              [-74.79897465499994, 11.02929038800005 ],
              [-74.79875885799993, 11.029058723000048],
              [-74.79853500099995, 11.028814599000043],
              [-74.79830074099993, 11.02855547900009 ],
              [-74.79805149999993, 11.028276277000089],
              [-74.79759419499993, 11.027756995000061],
              [-74.7965068609999 , 11.026511009000046],
              [-74.7962166229999 , 11.026182013000039],
              [-74.79595313999994, 11.025886641000056],
              [-74.79567878199992, 11.025583353000059],
              [-74.79541855899993, 11.02530050300004 ],
              [-74.7951682989999 , 11.025033576000055],
              [-74.79492575599994, 11.02478016300006 ],
              [-74.79468440699992, 11.02453362700004 ],
              [-74.79444789299993, 11.024297859000058],
              [-74.79421513999995, 11.024071785000046],
              [-74.7939853709999 , 11.023854649000043],
              [-74.79375772799995, 11.023645654000063],
              [-74.79353187299995, 11.02344449900005 ],
              [-74.79330734399991, 11.023250761000043],
              [-74.79308375599993, 11.023064121000061],
              [-74.79287631099993, 11.022897973000056],
              [-74.79266052199995, 11.022733179000056],
              [-74.79243594899992, 11.022569402000045],
              [-74.79220199499991, 11.02240620300006 ],
              [-74.7919582749999 , 11.02224330200005 ],
              [-74.79170354599995, 11.022079854000083],
              [-74.79143664399993, 11.02191511600006 ],
              [-74.79115581299993, 11.021747989000062],
              [-74.7909135829999 , 11.02160824300006 ],
              [-74.79065842799992, 11.021464816000048],
              [-74.79038777499994, 11.021316249000051],
              [-74.7900965579999 , 11.02115976400006 ],
              [-74.78954400099991, 11.020869775000051],
              [-74.78853973999992, 11.020352175000085],
              [-74.78815148799993, 11.020150490000049],
              [-74.78775974099995, 11.019943545000046],
              [-74.78740982499994, 11.019754315000057],
              [-74.78702812499995, 11.019541484000058],
              [-74.78667512699991, 11.019337040000039],
              [-74.7865072109999 , 11.019236702000057],
              [-74.78634444599993, 11.019137276000038],
              [-74.78618641999992, 11.019038514000044],
              [-74.78603280499993, 11.018940205000092],
              [-74.78585581999994, 11.018823867000037],
              [-74.7856842779999 , 11.018707679000045],
              [-74.78551786499992, 11.018591410000056],
              [-74.78535631599993, 11.01847488900006 ],
              [-74.7851993619999 , 11.01835791700006 ],
              [-74.7850468609999 , 11.018240387000048],
              [-74.78489864499994, 11.018122163000044],
              [-74.78475457399992, 11.018003142000055],
              [-74.78461434199994, 11.017883064000046],
              [-74.7844780289999 , 11.017761980000046],
              [-74.7843455389999 , 11.017639810000048],
              [-74.78421679199994, 11.017516474000047],
              [-74.78409169199995, 11.017391885000052],
              [-74.78397018599992, 11.017265992000091],
              [-74.7838522099999 , 11.017138731000045],
              [-74.78373771499992, 11.017010035000055],
              [-74.78363969499992, 11.016895046000059],
              [-74.78353252699992, 11.016764444000046],
              [-74.7832037039999 , 11.016349526000056],
              [-74.78309452099995, 11.016213780000044],
              [-74.78299860499993, 11.016097981000087],
              [-74.78290930499992, 11.015994059000093],
              [-74.78276960099993, 11.01583888500005 ],
              [-74.78262327999994, 11.015684343000089],
              [-74.78247003599995, 11.015530105000039],
              [-74.78230945899992, 11.015375764000055],
              [-74.78214128199994, 11.015221048000058],
              [-74.78196464299992, 11.015065140000047],
              [-74.78177871899993, 11.014907335000089],
              [-74.78158227099993, 11.014746554000055],
              [-74.78141300399994, 11.014612211000042],
              [-74.7812342229999 , 11.014473872000053],
              [-74.78104410999993, 11.014330137000059],
              [-74.78083908499991, 11.014178293000043],
              [-74.7804500499999 , 11.013896628000055],
              [-74.7794705689999 , 11.013198183000043],
              [-74.77919634299991, 11.01299844500005 ],
              [-74.77895171799992, 11.01281626900004 ],
              [-74.77868658599994, 11.012613004000059],
              [-74.77844198999992, 11.012418573000048],
              [-74.77821354799994, 11.01222948800006 ],
              [-74.77799902599992, 11.012043894000044],
              [-74.77775499199993, 11.011826495000037],
              [-74.77751103299994, 11.011606631000063],
              [-74.77726714099992, 11.011384293000049],
              [-74.77702330299991, 11.011159477000092],
              [-74.7767795069999 , 11.01093217500005 ],
              [-74.77653575699992, 11.010702369000057],
              [-74.77629202999992, 11.01047006400006 ],
              [-74.77604831999992, 11.010235239000053],
              [-74.77580461999992, 11.009997895000083],
              [-74.77556091799994, 11.009758014000056],
              [-74.77507345899994, 11.009270594000043],
              [-74.77470917499994, 11.00889877600008 ],
              [-74.7745858319999 , 11.008772883000063],
              [-74.77409793099991, 11.00826476900005 ],
              [-74.77360982399995, 11.00774632200006 ],
              [-74.77312118999993, 11.00721721800005 ],
              [-74.77263188699993, 11.006677289000038],
              [-74.77214174599993, 11.006126361000042],
              [-74.77165062699993, 11.00556427400005 ],
              [-74.7711582849999 , 11.004990744000054],
              [-74.77066449499995, 11.004405512000062],
              [-74.77016898499994, 11.003808263000053],
              [-74.7696167819999 , 11.003135246000056],
              [-74.76912610999995, 11.002533123000092],
              [-74.76867216799991, 11.001971451000088],
              [-74.76824665599992, 11.001439926000046],
              [-74.76819115199993, 11.001369852000039],
              [-74.7678393569999 , 11.000925709000057],
              [-74.76745102699994, 11.000429672000053],
              [-74.76707887999993, 10.99994825500005 ],
              [-74.76672115899993, 10.99947916800005 ],
              [-74.7663705619999 , 10.999012676000063],
              [-74.76603228199991, 10.998555517000057],
              [-74.76570539799991, 10.998106437000047],
              [-74.76538923699991, 10.997664496000084],
              [-74.76508305099992, 10.997228668000048],
              [-74.76478652999992, 10.996798497000043],
              [-74.76449926399994, 10.996373382000058],
              [-74.76422092199994, 10.995952848000059],
              [-74.76394507999993, 10.99552685000009 ],
              [-74.76367801999993, 10.995104789000038],
              [-74.76341951599994, 10.994686301000058],
              [-74.7631693749999 , 10.994271078000054],
              [-74.76292740899993, 10.993858791000036],
              [-74.76269349599994, 10.99344923600006 ],
              [-74.76246750199994, 10.99304218900005 ],
              [-74.76224931399992, 10.99263744600006 ],
              [-74.76203872199994, 10.992234582000037],
              [-74.76183575599993, 10.991833673000087],
              [-74.76164033799995, 10.991434556000058],
              [-74.76151791299992, 10.991175637000083],
              [-74.76145240299991, 10.991037088000041],
              [-74.76127187599991, 10.99064112800005 ],
              [-74.76109871499995, 10.99024656000006 ],
              [-74.7609328659999 , 10.989853279000044],
              [-74.76077428699995, 10.989461179000045],
              [-74.76047470299994, 10.988699199000052],
              [-74.76023270499991, 10.988071209000054],
              [-74.76001742799991, 10.987497104000056],
              [-74.75982366199992, 10.986962679000044],
              [-74.75973147499991, 10.986700837000058],
              [-74.75964344899995, 10.986445328000059],
              [-74.75955931699991, 10.98619536700005 ],
              [-74.75947887799992, 10.985950363000086],
              [-74.75940193199995, 10.98570968200005 ],
              [-74.75932838499995, 10.98547304400006 ],
              [-74.75925812899993, 10.985240094000062],
              [-74.75919107599992, 10.985010543000044],
              [-74.7591254479999 , 10.984777984000061],
              [-74.7590630489999 , 10.984548496000059],
              [-74.75900382799995, 10.984321869000041],
              [-74.75894773899995, 10.984097917000042],
              [-74.75889472699993, 10.98387645300005 ],
              [-74.75884476699991, 10.983657356000037],
              [-74.75879782599992, 10.98344049700006 ],
              [-74.75875388899993, 10.98322575800006 ],
              [-74.75871290299995, 10.983012901000052],
              [-74.75867487499994, 10.982801963000043],
              [-74.75863979299993, 10.982592862000047],
              [-74.75860764299995, 10.98238550900004 ],
              [-74.7585784119999 , 10.982179831000053],
              [-74.75855208999991, 10.981975765000072],
              [-74.75852867099991, 10.981773246000046],
              [-74.75850815299992, 10.981572223000057],
              [-74.75849026599991, 10.981358812000053],
              [-74.75847513799994, 10.981121967000092],
              [-74.75846307699993, 10.980877709000083],
              [-74.75843452999993, 10.980205867000052],
              [-74.75842324899992, 10.980001142000049],
              [-74.75841062899991, 10.979817751000041],
              [-74.75839177299991, 10.979599608000058],
              [-74.75836846199991, 10.979378705000045],
              [-74.75834067399995, 10.979154803000085],
              [-74.75830837099994, 10.978927619000046],
              [-74.75827152399995, 10.97869692100005 ],
              [-74.75823005899991, 10.978462237000087],
              [-74.75818389999995, 10.978223142000047],
              [-74.75813294599993, 10.977979083000037],
              [-74.75811396699993, 10.97789149600004 ],
              [-74.75809470699994, 10.977806533000091],
              [-74.75807449099995, 10.977717509000058],
              [-74.75805436699994, 10.977632493000044],
              [-74.7580325479999 , 10.977540453000074],
              [-74.75801126399995, 10.977454063000039],
              [-74.75798810099991, 10.97736019100006 ],
              [-74.75796531099991, 10.977271062000057],
              [-74.75794125099992, 10.977177102000041],
              [-74.75789913499995, 10.977019527000039],
              [-74.75785950699992, 10.976871449000043],
              [-74.75781396799994, 10.976709142000061],
              [-74.75777027399994, 10.976553572000057],
              [-74.75772002499991, 10.97638188700006 ],
              [-74.75767239299995, 10.976219277000041],
              [-74.75761574199993, 10.97603259400006 ],
              [-74.75756294899992, 10.975858765000055],
              [-74.7574630439999 , 10.97553860000005 ],
              [-74.75734941999991, 10.975181621000047],
              [-74.75694111099995, 10.973920773000089],
              [-74.7568363119999 , 10.973593795000056],
              [-74.75679031599992, 10.97344700900004 ],
              [-74.75669927199993, 10.973156077000056],
              [-74.75665118799992, 10.972997534000058],
              [-74.75658018299993, 10.972762764000038],
              [-74.75647290299992, 10.972393323000063],
              [-74.75645777499994, 10.972340229000054],
              [-74.75643238899994, 10.972249159000057],
              [-74.75640210299991, 10.972139660000039],
              [-74.7563778949999 , 10.97205013200005 ],
              [-74.75634966299992, 10.97194469100009 ],
              [-74.75632652399992, 10.971856238000044],
              [-74.75630012399995, 10.971754074000046],
              [-74.75627816999992, 10.971667094000054],
              [-74.7562534079999 , 10.971567520000065],
              [-74.75623264699993, 10.971482009000056],
              [-74.75620944999991, 10.971384709000063],
              [-74.75618986199993, 10.971300584000062],
              [-74.75616899199991, 10.971208973000046],
              [-74.7561484659999 , 10.971116494000057],
              [-74.75612513099992, 10.971008552000058],
              [-74.75610697699994, 10.970922203000043],
              [-74.75608536199991, 10.97081593200005 ],
              [-74.75606859299995, 10.97073129100005 ],
              [-74.75604853099992, 10.970625794000057],
              [-74.75603326899994, 10.970543573000043],
              [-74.7560145889999 , 10.970437738000044],
              [-74.75600092499991, 10.970358650000037],
              [-74.75598341199992, 10.97025085100006 ],
              [-74.75597150199991, 10.97017618600006 ],
              [-74.75595474099993, 10.970062907000056],
              [-74.75594498799995, 10.969996112000047],
              [-74.7559270129999 , 10.969861111000057],
              [-74.7559213639999 , 10.96981833700005 ],
              [-74.75590062299995, 10.969642443000055],
              [-74.75589614699993, 10.969599164000044],
              [-74.75588276099995, 10.969468385000084],
              [-74.75587707299991, 10.969404196000085],
              [-74.75586772299994, 10.969296043000043],
              [-74.7558625449999 , 10.969225604000087],
              [-74.75585550199992, 10.96912528300004 ],
              [-74.75585125399994, 10.969052240000053],
              [-74.7558460919999 , 10.968956017000039],
              [-74.75584294099991, 10.968881878000047],
              [-74.7558394909999 , 10.968788175000043],
              [-74.75583750999994, 10.968713612000045],
              [-74.7558357019999 , 10.96862148200006 ],
              [-74.75583492599992, 10.968547227000045],
              [-74.75583470399994, 10.968444822000038],
              [-74.75583490199995, 10.968399309000063],
              [-74.75583726699995, 10.968247207000047],
              [-74.75584361999995, 10.968072437000046],
              [-74.75585429999995, 10.967890196000042],
              [-74.75586941499995, 10.967698821000056],
              [-74.75588919199993, 10.967495214000053],
              [-74.75591117899995, 10.967299190000062],
              [-74.75593811899995, 10.967081936000056],
              [-74.75604888399994, 10.966264842000044],
              [-74.75609194899994, 10.965922885000055],
              [-74.75611257899993, 10.965737918000059],
              [-74.75612955699995, 10.965565111000046],
              [-74.75614321199993, 10.96540109600005 ],
              [-74.75615367599994, 10.965244167000037],
              [-74.75615688699992, 10.965187824000054],
              [-74.75615901099991, 10.965140852000047],
              [-74.7561613979999 , 10.965087310000058],
              [-74.75616289599992, 10.96504092500004 ],
              [-74.7561645369999 , 10.964988991000041],
              [-74.75616539099991, 10.964943707000089],
              [-74.75616631199995, 10.964892585000086],
              [-74.7561665309999 , 10.964847863000045],
              [-74.75616673999991, 10.964794644000051],
              [-74.7561658539999 , 10.964727633000052],
              [-74.75616520099993, 10.964705227000081],
              [-74.75616370799992, 10.96465917200004 ],
              [-74.75616247699992, 10.96463219800006 ],
              [-74.7561602859999 , 10.964589119000038],
              [-74.75615850399993, 10.964560056000039],
              [-74.7561555719999 , 10.964517157000046],
              [-74.75615327499992, 10.964487131000055],
              [-74.7561495189999 , 10.964442991000055],
              [-74.7561467779999 , 10.96441279600009 ],
              [-74.75614199199993, 10.964365311000051],
              [-74.75613898899991, 10.964336574000072],
              [-74.75613255399992, 10.964280864000045],
              [-74.7561298629999 , 10.964257960000054],
              [-74.75612854499991, 10.96424776400005 ],
              [-74.75611246699992, 10.964129271000047],
              [-74.75610241699991, 10.964060352000047],
              [-74.7560868939999 , 10.963961375000054],
              [-74.75607418499993, 10.963883598000052],
              [-74.75605409099995, 10.963767265000058],
              [-74.7560430929999 , 10.963704732000053],
              [-74.7560081929999 , 10.963512623000042],
              [-74.75597057699991, 10.963304995000044],
              [-74.75596018199991, 10.963246144000038],
              [-74.75593057499992, 10.963070733000052],
              [-74.75592366299992, 10.96302882200007 ],
              [-74.75591841399995, 10.962994291000086],
              [-74.7559030509999 , 10.962890519000041],
              [-74.75589434299991, 10.962826835000044],
              [-74.7558902049999 , 10.962795422000056],
              [-74.75587852399991, 10.962697763000051],
              [-74.75587836999995, 10.962696280000046],
              [-74.7558690809999 , 10.962603984000054],
              [-74.75586704599993, 10.962580453000044],
              [-74.75586000299995, 10.962489882000057],
              [-74.75585836199991, 10.962466264000057],
              [-74.75585721099992, 10.962446274000058],
              [-74.7558546489999 , 10.962396433000038],
              [-74.7558537299999 , 10.962375373000043],
              [-74.7558519239999 , 10.962319562000062],
              [-74.7558516119999 , 10.962308723000092],
              [-74.7558507999999 , 10.962244033000047],
              [-74.75585083699991, 10.962224849000052],
              [-74.75585114499995, 10.962183435000043],
              [-74.75585153799994, 10.96216171900005 ],
              [-74.7558526169999 , 10.962122351000062],
              [-74.7558533319999 , 10.962101725000082],
              [-74.75585548599992, 10.962056956000083],
              [-74.75585618499991, 10.962043387000051],
              [-74.75586016599993, 10.961986133000039],
              [-74.75586138599994, 10.961972167000056],
              [-74.75586529399993, 10.961929463000047],
              [-74.7558673719999 , 10.961909806000051],
              [-74.75587158999991, 10.961872873000061],
              [-74.75587409799994, 10.961852772000043],
              [-74.75587931399991, 10.961814898000057],
              [-74.75588181299992, 10.961797559000047],
              [-74.75589056099994, 10.961743432000048],
              [-74.75590540199994, 10.961664785000039],
              [-74.7559231539999 , 10.961582781000061],
              [-74.75594400199992, 10.961496551000039],
              [-74.75596835999994, 10.961404379000044],
              [-74.7560170289999 , 10.96123571000004 ],
              [-74.7561363239999 , 10.960844405000046],
              [-74.75618900799992, 10.960655606000046],
              [-74.75629346699992, 10.960243956000056],
              [-74.75632153799995, 10.960130236000055],
              [-74.7564034859999 , 10.959790775000045],
              [-74.75642323699992, 10.959707837000053],
              [-74.75651777699994, 10.959295329000042],
              [-74.75653964199995, 10.95919637500009 ],
              [-74.75661207599995, 10.958862713000087],
              [-74.75663549099994, 10.958752496000045],
              [-74.75672114499991, 10.958334853000053],
              [-74.75673110199995, 10.958283945000062],
              [-74.7568033359999 , 10.95791005600006 ],
              [-74.75682529499994, 10.957792742000038],
              [-74.75689219999992, 10.95742393100005 ],
              [-74.75690262799992, 10.957365639000045],
              [-74.75697428399991, 10.956943332000037],
              [-74.75699213399992, 10.956833406000044],
              [-74.7570458209999 , 10.956493670000043],
              [-74.75706170799992, 10.95639065100005 ],
              [-74.75712378299994, 10.955966781000086],
              [-74.75713486899991, 10.955886405000058],
              [-74.75718282699995, 10.955530991000046],
              [-74.75719810499993, 10.95541327300009 ],
              [-74.75725088799993, 10.954985718000046],
              [-74.75725113899995, 10.954983600000048],
              [-74.7572992819999 , 10.954554031000043],
              [-74.75731162699992, 10.954437065000093],
              [-74.75734695799991, 10.954086207000046],
              [-74.75735571299992, 10.953996421000056],
              [-74.7573939209999 , 10.953568835000056],
              [-74.75740198299991, 10.953469928000061],
              [-74.75742899599993, 10.953123781000045],
              [-74.75743742899994, 10.953009104000046],
              [-74.75746612599994, 10.952579099000047],
              [-74.75746862799991, 10.952535670000088],
              [-74.7574902909999 , 10.95214493900005 ],
              [-74.75749626899994, 10.952024522000045],
              [-74.75751258199995, 10.95165522700006 ],
              [-74.75751539999993, 10.951587562000043],
              [-74.75752950099991, 10.951158878000058],
              [-74.75753234299992, 10.951048153000045],
              [-74.75753960599991, 10.950712149000083],
              [-74.75753974299994, 10.950705826000046],
              [-74.7575416549999 , 10.95059783000005 ],
              [-74.75754605999992, 10.950170373000049],
              [-74.75754618899992, 10.950093063000054],
              [-74.75754580799992, 10.949715046000051],
              [-74.75754537199992, 10.949637290000055],
              [-74.75754020799991, 10.94922005300009 ],
              [-74.75753870299991, 10.949140587000045],
              [-74.75752854199993, 10.948712705000048],
              [-74.75752755499991, 10.948682168000062],
              [-74.75751297799991, 10.948267832000056],
              [-74.75751042799993, 10.948202493000053],
              [-74.75749098299991, 10.947785624000062],
              [-74.75748662199993, 10.947702629000048],
              [-74.75746233599995, 10.94728512000006 ],
              [-74.75745984399992, 10.947247425000057],
              [-74.75745850099992, 10.947227113000054],
              [-74.75743014299991, 10.94682057700004 ],
              [-74.75742706999995, 10.946778436000045],
              [-74.75739293099991, 10.946359052000048],
              [-74.75738595199994, 10.94627848500005 ],
              [-74.75734770899993, 10.945867847000045],
              [-74.75734046799994, 10.945795752000038],
              [-74.75729559299992, 10.945368105000057],
              [-74.75947371699993, 10.944442275000085],
              [-74.75960140699993, 10.94441977200006 ],
              [-74.75993624699993, 10.944298011000058],
              [-74.76002170399994, 10.944263515000046],
              [-74.76003752299994, 10.94425712900005 ],
              [-74.76026044899993, 10.944167141000037],
              [-74.76030216899994, 10.944162233000043],
              [-74.76051326599992, 10.944137398000066],
              [-74.76071254599992, 10.944170115000077],
              [-74.76086126299992, 10.944217705000085],
              [-74.7609980819999 , 10.944306935000043],
              [-74.76107041199992, 10.944367209000063],
              [-74.76107985099992, 10.944375075000039],
              [-74.7611051579999 , 10.944396164000068],
              [-74.76119457399994, 10.944489585000042],
              [-74.76130443699992, 10.944604367000068],
              [-74.76141032499993, 10.944727508000085],
              [-74.76146860299991, 10.94479528100004 ],
              [-74.7614970599999 , 10.944828375000043],
              [-74.76156817799995, 10.94491108200009 ],
              [-74.7615788679999 , 10.944923513000049],
              [-74.76169022599993, 10.945036776000052],
              [-74.76177022699994, 10.945110219000071],
              [-74.76180634399992, 10.94514337600009 ],
              [-74.76203762799992, 10.94524712000009 ],
              [-74.76212028599991, 10.945280377000074],
              [-74.76228128499991, 10.945345154000051],
              [-74.76243547399991, 10.945374659000038],
              [-74.76261155399993, 10.945367997000062],
              [-74.76288566799991, 10.94533087700006 ],
              [-74.76289459399993, 10.945329432000051],
              [-74.76321498499993, 10.94527757700007 ],
              [-74.76339867999991, 10.945251879000068],
              [-74.76363504599993, 10.945222105000084],
              [-74.7636779899999 , 10.945216696000045],
              [-74.76387076499992, 10.945159556000078],
              [-74.76396403899992, 10.945097690000068],
              [-74.76405729399994, 10.944974285000058],
              [-74.7641649439999 , 10.944838984000057],
              [-74.76418121599994, 10.944818533000046],
              [-74.76424005699994, 10.94474457800004 ],
              [-74.76433490399995, 10.944670166000037],
              [-74.76444703599992, 10.944582193000087],
              [-74.76458507899991, 10.944482837000066],
              [-74.76459767499995, 10.944442639000044],
              [-74.76461471099992, 10.944388274000062],
              [-74.76462552999993, 10.944353751000051],
              [-74.76467787799993, 10.944286532000092],
              [-74.76479328199991, 10.944223476000047],
              [-74.76492712599992, 10.944150902000047],
              [-74.76501219199992, 10.944061078000061],
              [-74.76510055199992, 10.943944161000047],
              [-74.76516864199994, 10.943854065000039],
              [-74.76527988099991, 10.943709512000055],
              [-74.7653500749999 , 10.943629800000053],
              [-74.76536230099993, 10.943614696000054],
              [-74.76536799699994, 10.943607659000065],
              [-74.76538485599991, 10.943586832000051],
              [-74.76552674999994, 10.943622662000053],
              [-74.76560136599994, 10.943638515000089],
              [-74.76563529399994, 10.943645724000078],
              [-74.7656353779999 , 10.943661783000039],
              [-74.7656354479999 , 10.943675298000073],
              [-74.76563546699992, 10.94367902600004 ],
              [-74.76563557999992, 10.943700800000045],
              [-74.76563584699994, 10.943752066000059],
              [-74.76563648399991, 10.943874152000092],
              [-74.76563792299993, 10.943873943000085],
              [-74.76567138399992, 10.943869092000057],
              [-74.76569582199994, 10.943865549000066],
              [-74.7657287369999 , 10.943872981000084],
              [-74.76576228699992, 10.943881547000046],
              [-74.76579548099994, 10.943898679000085],
              [-74.76580797299994, 10.94391010000004 ],
              [-74.76582046499993, 10.94392152200004 ],
              [-74.76583188699993, 10.943942937000088],
              [-74.76583759699992, 10.943966137000075],
              [-74.76583771199995, 10.943990214000053],
              [-74.76583795399995, 10.944041090000042],
              [-74.7658350989999 , 10.94414566800009 ],
              [-74.76583616999994, 10.944181359000083],
              [-74.7658422369999 , 10.944237753000039],
              [-74.76584255599994, 10.94424081200009 ],
              [-74.7658511599999 , 10.944323413000063],
              [-74.76585692899994, 10.944415569000057],
              [-74.7658949449999 , 10.944440497000073],
              [-74.76602170799993, 10.94449215700007 ],
              [-74.76611450699994, 10.94451476200004 ],
              [-74.76619190199995, 10.94451283300009 ],
              [-74.76627274099991, 10.944480259000045],
              [-74.7663357969999 , 10.944413634000057],
              [-74.7663743139999 , 10.944372746000056],
              [-74.76641312899994, 10.94433154300009 ],
              [-74.76648332399992, 10.94427681500008 ],
              [-74.76653073699993, 10.944263466000052],
              [-74.76658008499993, 10.944257815000071],
              [-74.76660130999994, 10.944255384000087],
              [-74.76666649499992, 10.944258625000089],
              [-74.76674204199992, 10.944285204000039],
              [-74.7667486329999 , 10.944287523000071],
              [-74.76675163999994, 10.94429023400005 ],
              [-74.76683769699991, 10.944367842000077],
              [-74.76692419799991, 10.944491030000052],
              [-74.76695088799994, 10.944529038000042],
              [-74.76710317299995, 10.944728913000063],
              [-74.7672173869999 , 10.944890716000089],
              [-74.76728758099995, 10.94499303300006 ],
              [-74.76737972899991, 10.945121975000063],
              [-74.76749102499991, 10.945197667000059],
              [-74.76757549599995, 10.945223841000086],
              [-74.76763317899992, 10.945235899000068],
              [-74.7676462849999 , 10.945238639000081],
              [-74.76778607799992, 10.945267861000048],
              [-74.76799991599995, 10.945298541000057],
              [-74.76818463799992, 10.945319019000067],
              [-74.7682585309999 , 10.94532543300005 ],
              [-74.76831554199993, 10.945328904000064],
              [-74.7683211339999 , 10.945329245000039],
              [-74.76838490999995, 10.94532835700005 ],
              [-74.76843756499994, 10.945311761000085],
              [-74.76847493199995, 10.945299984000087],
              [-74.76859152599991, 10.945238118000077],
              [-74.76868194499991, 10.945194098000059],
              [-74.76878426199994, 10.94517506200009 ],
              [-74.76888181999993, 10.94518577000008 ],
              [-74.76898231299992, 10.945210595000049],
              [-74.76915172999992, 10.94525628100007 ],
              [-74.76923834299993, 10.945274365000046],
              [-74.76932971399992, 10.945273413000052],
              [-74.76940871199992, 10.945258185000057],
              [-74.76946229599992, 10.945233500000086],
              [-74.76949342099994, 10.945219161000068],
              [-74.7695580749999 , 10.945178265000038],
              [-74.7696038279999 , 10.945111610000083],
              [-74.76963523699993, 10.94502880400006 ],
              [-74.76963904399992, 10.94495932400008 ],
              [-74.76963523699993, 10.944867001000091],
              [-74.76964570699994, 10.944758498000056],
              [-74.76966930599991, 10.944709824000086],
              [-74.76967616399992, 10.944695680000052],
              [-74.76970344499995, 10.944647703000044],
              [-74.76970376499992, 10.94464713900004 ],
              [-74.76970440199995, 10.94464657800006 ],
              [-74.7697275189999 , 10.944626222000068],
              [-74.76976753499991, 10.944590984000058],
              [-74.76985319599993, 10.944549105000078],
              [-74.77018251299995, 10.944438698000056],
              [-74.77054468099993, 10.944342626000036],
              [-74.77079612699993, 10.944275925000056],
              [-74.77083583099994, 10.944266286000072],
              [-74.77083589599994, 10.944266270000071],
              [-74.7708359749999 , 10.944266251000045],
              [-74.77103911899991, 10.944216932000074],
              [-74.77130656999992, 10.944148404000089],
              [-74.77144648299992, 10.94413127200005 ],
              [-74.77156259999992, 10.944124609000085],
              [-74.77160506399991, 10.94412944700008 ],
              [-74.77168613399994, 10.944138683000062],
              [-74.77171298199994, 10.944141741000067],
              [-74.77189001399995, 10.944167440000058],
              [-74.7719775779999 , 10.944187427000088],
              [-74.77207063799995, 10.944234312000049],
              [-74.7720806719999 , 10.944238167000037],
              [-74.77216412799993, 10.944270232000065],
              [-74.77225645099992, 10.944275943000036],
              [-74.77236971299993, 10.944253100000083],
              [-74.77254484199995, 10.944191234000073],
              [-74.77268951299993, 10.944147452000038],
              [-74.77276184899995, 10.944136982000089],
              [-74.77290494399995, 10.944135886000083],
              [-74.77310163599992, 10.944143645000054],
              [-74.77310520299994, 10.944143801000052],
              [-74.7733633759999 , 10.944155066000064],
              [-74.7735338519999 , 10.944150793000063],
              [-74.77362945899995, 10.944124933000069],
              [-74.77365176699993, 10.944118899000046],
              [-74.77378120999992, 10.94405798400004 ],
              [-74.77389161699995, 10.944000877000065],
              [-74.77401725199991, 10.943902844000093],
              [-74.77407143099992, 10.943862423000041],
              [-74.77411621399995, 10.943829013000084],
              [-74.77427945199992, 10.943678484000088],
              [-74.77435749199992, 10.943623904000049],
              [-74.77441711099993, 10.943598726000062],
              [-74.7745804029999 , 10.94352976600004 ],
              [-74.77483007599994, 10.94344122800004 ],
              [-74.77494878899995, 10.943399199000055],
              [-74.77498331299995, 10.943386976000056],
              [-74.77526123399991, 10.943228980000072],
              [-74.77544713899994, 10.943122372000062],
              [-74.77546206099993, 10.943113814000071],
              [-74.7755434629999 , 10.943070478000038],
              [-74.77559435899991, 10.943043382000042],
              [-74.77572875299995, 10.94293728200006 ],
              [-74.77574138599994, 10.942898644000081],
              [-74.77574718599993, 10.942889575000038],
              [-74.77575295799994, 10.94288055000004 ],
              [-74.77583325699993, 10.942754992000062],
              [-74.77593053299995, 10.94267805700008 ],
              [-74.77595948699991, 10.942664408000041],
              [-74.77617113999992, 10.942564635000053],
              [-74.77618080699995, 10.942557055000066],
              [-74.77625489699994, 10.942498962000059],
              [-74.77630273199992, 10.942446197000038],
              [-74.77632985999992, 10.942416273000049],
              [-74.77637577399992, 10.94232859200008 ],
              [-74.77641040599991, 10.94225689700005 ],
              [-74.7764178999999 , 10.942241382000077],
              [-74.77642526699992, 10.942106826000042],
              [-74.7764186039999 , 10.94204781600007 ],
              [-74.77639822099991, 10.941987940000047],
              [-74.77635246499995, 10.94185353000006 ],
              [-74.77634967199992, 10.941793011000073],
              [-74.77634889599994, 10.94177619800007 ],
              [-74.77634889599994, 10.941702342000042],
              [-74.77634889599994, 10.941676260000065],
              [-74.7763691369999 , 10.941590723000047],
              [-74.77638577699992, 10.941520406000052],
              [-74.77641989499995, 10.94142250200008 ],
              [-74.77644050499993, 10.941363361000072],
              [-74.77649189199991, 10.941288292000081],
              [-74.77657755199994, 10.94115884900009 ],
              [-74.77663409699994, 10.941087777000064],
              [-74.7766818849999 , 10.94102771200005 ],
              [-74.77673104199994, 10.940992557000072],
              [-74.77677933099994, 10.940958023000064],
              [-74.7769901609999 , 10.94088984800004 ],
              [-74.77721423499992, 10.940817440000046],
              [-74.77727353999995, 10.940790672000048],
              [-74.77754170999992, 10.940669632000038],
              [-74.7775423409999 , 10.940667911000048],
              [-74.77769826699995, 10.940242302000058],
              [-74.77763102099993, 10.940205973000047],
              [-74.77762070799992, 10.940200402000073],
              [-74.7777041839999 , 10.940008239000065],
              [-74.77775225599993, 10.939897577000067],
              [-74.77776252399991, 10.93987394000004 ],
              [-74.77783952999994, 10.939689675000068],
              [-74.7778424739999 , 10.93968263100004 ],
              [-74.7778922249999 , 10.939565642000048],
              [-74.7779740009999 , 10.93937334800006 ],
              [-74.77802306299992, 10.939257978000057],
              [-74.7780347339999 , 10.939230534000046],
              [-74.77810100599993, 10.939082928000062],
              [-74.7781544579999 , 10.938963877000049],
              [-74.77818333199991, 10.938899566000089],
              [-74.77822318799991, 10.938810797000087],
              [-74.77828931999994, 10.93865726200005 ],
              [-74.77830668299993, 10.938616951000085],
              [-74.7783104689999 , 10.938608162000037],
              [-74.77838594299993, 10.938432939000052],
              [-74.77842991799992, 10.938327295000079],
              [-74.7785105129999 , 10.938133675000074],
              [-74.77854679399991, 10.938046514000064],
              [-74.77867976599993, 10.937725482000076],
              [-74.77868004399994, 10.937724811000066],
              [-74.77861443299992, 10.93768810000006 ],
              [-74.7786000939999 , 10.937680077000039],
              [-74.77877427099992, 10.937265099000058],
              [-74.77902744499994, 10.93666547500004 ],
              [-74.77911405799995, 10.936453227000072],
              [-74.7792539699999 , 10.936112488000049],
              [-74.77928544299994, 10.936038512000039],
              [-74.77947668799993, 10.93558900700009 ],
              [-74.77966894799994, 10.93514833100005 ],
              [-74.7798440759999 , 10.934740015000045],
              [-74.7799449659999 , 10.934482082000045],
              [-74.77983931699993, 10.934274593000055],
              [-74.77975841599994, 10.934107078000068],
              [-74.77984693199994, 10.933578838000074],
              [-74.7798736229999 , 10.93343019300005 ],
              [-74.77989324899994, 10.93332090000007 ],
              [-74.77990213499993, 10.933271412000067],
              [-74.77996209799994, 10.932921155000088],
              [-74.78001444599994, 10.932737461000045],
              [-74.78005727599992, 10.932674643000041],
              [-74.78014483999993, 10.93261563200008 ],
              [-74.7801979379999 , 10.932609224000089],
              [-74.78028243099993, 10.93261155500005 ],
              [-74.78028333999993, 10.93261158000007 ],
              [-74.78028660899992, 10.932611670000085],
              [-74.7803014449999 , 10.93261207900008 ],
              [-74.7802749139999 , 10.93278949200004 ],
              [-74.78026977099995, 10.932821980000085],
              [-74.7802610519999 , 10.932877053000084],
              [-74.78025765899991, 10.932898489000081],
              [-74.78025483399995, 10.932916334000083],
              [-74.78023726299995, 10.93302731700004 ],
              [-74.78021780699993, 10.933150218000037],
              [-74.78021531299993, 10.933170171000086],
              [-74.7801950349999 , 10.933332397000072],
              [-74.78019138099995, 10.933361628000057],
              [-74.78018019099994, 10.933451147000085],
              [-74.78017783199994, 10.933470018000037],
              [-74.78017858899995, 10.933531418000086],
              [-74.7801788989999 , 10.933556509000084],
              [-74.78017905899992, 10.933569497000065],
              [-74.7801794849999 , 10.933603951000066],
              [-74.78017973499993, 10.933624207000037],
              [-74.7801868709999 , 10.93373997000009 ],
              [-74.78018830099995, 10.933763167000052],
              [-74.780195        , 10.93380972500006 ],
              [-74.78020733699992, 10.933895465000091],
              [-74.78022190099995, 10.933956452000075],
              [-74.78022232799992, 10.933958241000084],
              [-74.78022256499992, 10.933959235000088],
              [-74.7802492159999 , 10.934060600000066],
              [-74.78027798499994, 10.934142549000057],
              [-74.78028689599995, 10.934167931000047],
              [-74.78028948299993, 10.93417530000005 ],
              [-74.78029632899995, 10.934194801000046],
              [-74.78033805699994, 10.934289343000046],
              [-74.78035697899992, 10.934332213000062],
              [-74.78035945199991, 10.934337816000038],
              [-74.78038769999995, 10.934401815000058],
              [-74.78041125699991, 10.93443964800008 ],
              [-74.78044409299991, 10.934489617000054],
              [-74.78046138999991, 10.934510573000068],
              [-74.78046420899994, 10.934513988000049],
              [-74.78060405899993, 10.934683422000091],
              [-74.78064042299991, 10.93472747800007 ],
              [-74.7807039299999 , 10.934804419000045],
              [-74.78070433699992, 10.934804906000068],
              [-74.78072581099991, 10.934830593000072],
              [-74.7808034329999 , 10.93492344100008 ],
              [-74.78087203599995, 10.935005501000091],
              [-74.78088467999993, 10.93502062500005 ],
              [-74.78089320599992, 10.93503082300009 ],
              [-74.7810387209999 , 10.93520488300004 ],
              [-74.78107267699994, 10.935245785000063],
              [-74.7811441579999 , 10.935331890000043],
              [-74.78122291999995, 10.935426765000045],
              [-74.78127250299991, 10.935486492000052],
              [-74.78127534599992, 10.935490126000047],
              [-74.7812759599999 , 10.935490911000045],
              [-74.78136656899994, 10.935606749000044],
              [-74.78139171399994, 10.935638896000057],
              [-74.78142918999993, 10.93569814500006 ],
              [-74.78143503299992, 10.93570850000009 ],
              [-74.7814543639999 , 10.93574275800006 ],
              [-74.7814726659999 , 10.935775191000062],
              [-74.78148558299995, 10.935798082000076],
              [-74.78154695299992, 10.93591021800006 ],
              [-74.78155375499995, 10.93592264700004 ],
              [-74.78157980999993, 10.935976185000072],
              [-74.7815992109999 , 10.936040666000054],
              [-74.78160148599994, 10.936048226000082],
              [-74.78160169099993, 10.936048895000056],
              [-74.7816391369999 , 10.93617129900008 ],
              [-74.78165570199991, 10.936225444000058],
              [-74.78170312299994, 10.936380453000083],
              [-74.78173472999993, 10.936483769000063],
              [-74.78174141399995, 10.93650561700008 ],
              [-74.7817533619999 , 10.936544670000046],
              [-74.78175492199995, 10.936549771000045],
              [-74.78177336699991, 10.936610063000046],
              [-74.7817907729999 , 10.936666958000046],
              [-74.7818315209999 , 10.936800153000092],
              [-74.78183937599994, 10.936826840000037],
              [-74.78185528299991, 10.936880880000047],
              [-74.78186169299994, 10.936902658000065],
              [-74.78188207099993, 10.936971887000084],
              [-74.78188442999993, 10.936979901000086],
              [-74.78191234399992, 10.937074735000067],
              [-74.78193011799993, 10.937135119000061],
              [-74.78194482399994, 10.937185081000052],
              [-74.78196477099993, 10.937252845000046],
              [-74.78196980599995, 10.937269420000064],
              [-74.78200370399992, 10.93738101200006 ],
              [-74.7820107249999 , 10.937404124000068],
              [-74.7820777469999 , 10.937624758000084],
              [-74.78209516699991, 10.937608156000067],
              [-74.78218093599992, 10.937526414000047],
              [-74.78218915799994, 10.937518579000084],
              [-74.78219108199994, 10.937516745000039],
              [-74.7821919989999 , 10.93751632100009 ],
              [-74.7822825479999 , 10.937474492000092],
              [-74.78234268099993, 10.93744693900004 ],
              [-74.78240169299994, 10.937419899000076],
              [-74.78246332899994, 10.937385989000063],
              [-74.78253004099992, 10.937349287000075],
              [-74.78257235899991, 10.937326006000092],
              [-74.78278919599995, 10.937206711000044],
              [-74.78292834399991, 10.937130158000059],
              [-74.7831578659999 , 10.937003884000092],
              [-74.7833970879999 , 10.936841555000058],
              [-74.78363030399993, 10.936675477000051],
              [-74.78371117599994, 10.936617886000079],
              [-74.7838069739999 , 10.936537268000052],
              [-74.78398148299993, 10.936390409000069],
              [-74.78420420099991, 10.936205763000089],
              [-74.78421959399992, 10.936193352000089],
              [-74.78427482699993, 10.936148819000039],
              [-74.7842900149999 , 10.936136573000056],
              [-74.78434037399995, 10.936095969000064],
              [-74.7843859919999 , 10.936059188000058],
              [-74.78447335899995, 10.935980419000089],
              [-74.78451773099994, 10.935940413000083],
              [-74.78453845199994, 10.935921731000064],
              [-74.78469233899995, 10.935827566000057],
              [-74.78471780099994, 10.93581198600009 ],
              [-74.78483332999991, 10.935741292000046],
              [-74.78491939799994, 10.935694548000072],
              [-74.78497409899995, 10.935664840000072],
              [-74.78527495799995, 10.935501443000078],
              [-74.78534343099994, 10.935465425000075],
              [-74.78550412099992, 10.935380899000052],
              [-74.78564272799991, 10.93530798900008 ],
              [-74.7858012179999 , 10.935224620000042],
              [-74.7859516769999 , 10.93514547500007 ],
              [-74.7860654349999 , 10.935089276000042],
              [-74.7862061589999 , 10.935005303000082],
              [-74.78631848299995, 10.934938277000072],
              [-74.7863428899999 , 10.934923713000046],
              [-74.78636588699993, 10.934909990000051],
              [-74.78651037399993, 10.934823772000072],
              [-74.7866285899999 , 10.934748322000075],
              [-74.7868489949999 , 10.934607649000043],
              [-74.78687871499994, 10.934588681000037],
              [-74.78691966299994, 10.934561300000041],
              [-74.78703671099993, 10.934483033000049],
              [-74.78722899799993, 10.934345627000084],
              [-74.7872421909999 , 10.934336199000086],
              [-74.7873130829999 , 10.934285540000076],
              [-74.78745718599993, 10.934182565000071],
              [-74.78748024299995, 10.934166089000087],
              [-74.78751793299995, 10.934142317000067],
              [-74.78762099599993, 10.934077314000092],
              [-74.78773640999992, 10.934004520000087],
              [-74.78777148899991, 10.933982395000044],
              [-74.78794964399992, 10.933878701000083],
              [-74.78807891499991, 10.933803459000046],
              [-74.7882072999999 , 10.933742080000059],
              [-74.78831781299994, 10.93368924500004 ],
              [-74.78843678599992, 10.933664499000088],
              [-74.7885053139999 , 10.933664499000088],
              [-74.78850704399991, 10.933664968000073],
              [-74.78861762499992, 10.933694956000068],
              [-74.78880596099992, 10.933766763000051],
              [-74.7889022089999 , 10.933803459000046],
              [-74.78894010499994, 10.933819871000082],
              [-74.7890229109999 , 10.93385573200004 ],
              [-74.78902308499994, 10.933855807000043],
              [-74.78907455599995, 10.933874305000074],
              [-74.78908436799992, 10.933877831000075],
              [-74.7890973879999 , 10.933882510000046],
              [-74.7891449139999 , 10.933899589000077],
              [-74.78922676699995, 10.933908156000086],
              [-74.78926328099993, 10.933906069000045],
              [-74.78936001699992, 10.933900541000071],
              [-74.78943979199994, 10.933883154000057],
              [-74.7895192979999 , 10.933865826000044],
              [-74.78962366999991, 10.933843079000042],
              [-74.78969551299991, 10.933827421000046],
              [-74.78973121299992, 10.933819640000081],
              [-74.78999009799992, 10.93375491800009 ],
              [-74.79000134499995, 10.933752837000043],
              [-74.79019070999993, 10.93371780800004 ],
              [-74.79020353299995, 10.933715436000057],
              [-74.79022484299992, 10.933711494000079],
              [-74.79034511399993, 10.93368924500004 ],
              [-74.7904643089999 , 10.933661597000082],
              [-74.79048441299994, 10.933656934000055],
              [-74.79050650799991, 10.933651809000082],
              [-74.79052899699991, 10.93364659200006 ],
              [-74.79052975999991, 10.933646415000055],
              [-74.79056947599992, 10.933630451000056],
              [-74.79062684199994, 10.933607392000056],
              [-74.79063452299994, 10.933601383000052],
              [-74.79064062399993, 10.933596610000052],
              [-74.79070595999991, 10.933545500000037],
              [-74.7907400819999 , 10.933507809000048],
              [-74.79077056099993, 10.93347414200008 ],
              [-74.79083052399994, 10.933369446000086],
              [-74.7908940829999 , 10.933259784000086],
              [-74.79092486999991, 10.933206665000057],
              [-74.79106520399995, 10.933006556000066],
              [-74.79107703599993, 10.932989684000063],
              [-74.79117031099992, 10.932832639000083],
              [-74.7911827019999 , 10.93280933200009 ],
              [-74.79121028599991, 10.932757448000075],
              [-74.79121560399994, 10.932744393000064],
              [-74.79124169499994, 10.93268035400007 ],
              [-74.79126216199995, 10.932633131000046],
              [-74.7912998729999 , 10.93254612600009 ],
              [-74.79133794499995, 10.93239955200005 ],
              [-74.7913579879999 , 10.932321271000092],
              [-74.7913680929999 , 10.93228180300008 ],
              [-74.79137117599993, 10.932269764000068],
              [-74.79137622899992, 10.932250028000055],
              [-74.79137643799993, 10.93224921600006 ],
              [-74.79138350999995, 10.932221594000055],
              [-74.79138575399992, 10.932212366000044],
              [-74.79140778599992, 10.932121772000073],
              [-74.79141872399993, 10.932076793000078],
              [-74.79143919599994, 10.931992612000045],
              [-74.79149391699991, 10.931767592000085],
              [-74.7915329409999 , 10.931696209000052],
              [-74.79159338699992, 10.931653119000089],
              [-74.79182133099994, 10.931490623000059],
              [-74.79182686399992, 10.93148640000004 ],
              [-74.79198445599991, 10.931366104000062],
              [-74.79210686699992, 10.931272665000051],
              [-74.79213475199992, 10.931253316000038],
              [-74.79213515899994, 10.931253033000075],
              [-74.79214464499995, 10.93124645100005 ],
              [-74.79217288299992, 10.93122685700007 ],
              [-74.79219992399993, 10.931208094000056],
              [-74.79220014099991, 10.93120794300006 ],
              [-74.79221455099992, 10.931194382000058],
              [-74.7922170729999 , 10.931192008000039],
              [-74.79221780199993, 10.931191321000085],
              [-74.79224868199992, 10.931162258000086],
              [-74.79225754999993, 10.931156956000052],
              [-74.79236016099992, 10.931095607000088],
              [-74.79244665399995, 10.93107850000007 ],
              [-74.7925837109999 , 10.93105089900007 ],
              [-74.79266746799993, 10.931046140000092],
              [-74.7926898849999 , 10.93104978000008 ],
              [-74.7927788259999 , 10.931064224000067],
              [-74.79293587099994, 10.931133704000047],
              [-74.79305293999994, 10.931175582000037],
              [-74.79313842599993, 10.931178871000043],
              [-74.79315192599995, 10.931179390000068],
              [-74.79322330999992, 10.93115749900005 ],
              [-74.79335275199992, 10.931068983000046],
              [-74.79341292799995, 10.931028784000091],
              [-74.79341347199994, 10.931028421000065],
              [-74.7934212369999 , 10.931023234000065],
              [-74.7934248709999 , 10.931020806000049],
              [-74.7934307459999 , 10.931016882000051],
              [-74.79343775599995, 10.931012199000065],
              [-74.7934418019999 , 10.931009156000073],
              [-74.7934498589999 , 10.93100309600004 ],
              [-74.79345007199993, 10.931002936000084],
              [-74.7934698219999 , 10.930988081000066],
              [-74.79355643399992, 10.930956672000093],
              [-74.79356910799993, 10.930954862000078],
              [-74.7936896839999 , 10.930937636000067],
              [-74.79379004999993, 10.930916246000038],
              [-74.7938751719999 , 10.930893294000043],
              [-74.79387824499992, 10.930892465000056],
              [-74.79387881999992, 10.930892310000047],
              [-74.79389219599994, 10.93088870400004 ],
              [-74.79392083599993, 10.930880981000087],
              [-74.79394555399995, 10.930874316000086],
              [-74.7939464399999 , 10.930874077000055],
              [-74.79394779299992, 10.93087371200005 ],
              [-74.79395370199995, 10.930872119000071],
              [-74.79395427999992, 10.930871963000072],
              [-74.79401920799995, 10.930828873000053],
              [-74.79402837999993, 10.930819867000082],
              [-74.79406102999991, 10.930787811000073],
              [-74.79406666699992, 10.930782277000048],
              [-74.79407155599995, 10.93077747700005 ],
              [-74.79412262699992, 10.930727335000086],
              [-74.79412390399995, 10.930726081000046],
              [-74.79413350999994, 10.930716261000043],
              [-74.79416673399993, 10.930682299000068],
              [-74.79418558899994, 10.930672282000046],
              [-74.79418881399994, 10.930670569000085],
              [-74.7941971919999 , 10.93066611800009 ],
              [-74.7942048139999 , 10.930662307000091],
              [-74.79422193799991, 10.930653745000086],
              [-74.79426000899991, 10.930647083000053],
              [-74.7942613379999 , 10.930646810000042],
              [-74.79429712899992, 10.930639468000038],
              [-74.79433288699994, 10.930632867000043],
              [-74.79434342299993, 10.930630922000091],
              [-74.79435899499993, 10.930628047000084],
              [-74.79438548299993, 10.93062580600008 ],
              [-74.79447413199995, 10.930618304000063],
              [-74.79448272699995, 10.930617577000078],
              [-74.79450038699991, 10.930615811000052],
              [-74.7945588689999 , 10.930609963000052],
              [-74.79457531799994, 10.930601190000061],
              [-74.79460169999993, 10.930587120000041],
              [-74.79463025299992, 10.930564277000087],
              [-74.79465309599993, 10.930535724000038],
              [-74.79466546899994, 10.930507170000055],
              [-74.79466671999995, 10.930504340000084],
              [-74.79470544399993, 10.930416751000052],
              [-74.79476421599992, 10.930216463000079],
              [-74.79477777999995, 10.930170239000063],
              [-74.79481965799994, 10.930012242000089],
              [-74.79484535699993, 10.929969412000048],
              [-74.79487390999992, 10.929935148000084],
              [-74.79489178799992, 10.929902095000045],
              [-74.79491701699993, 10.929873156000042],
              [-74.7949833209999 , 10.929797101000077],
              [-74.79502122999992, 10.929753617000074],
              [-74.79504163399992, 10.929726272000039],
              [-74.79504533999994, 10.929721305000044],
              [-74.79508937199995, 10.929662294000082],
              [-74.79509194699995, 10.929658843000084],
              [-74.79510303399991, 10.929643983000062],
              [-74.79510644299995, 10.929639415000054],
              [-74.79510696699992, 10.929638712000042],
              [-74.79511287299994, 10.929630797000073],
              [-74.79511988399992, 10.929617489000066],
              [-74.7951268939999 , 10.929604182000048],
              [-74.79512747299992, 10.929603084000064],
              [-74.79516399799991, 10.92953375400009 ],
              [-74.79517067899991, 10.929520282000055],
              [-74.79522205699993, 10.929416685000092],
              [-74.79524212299992, 10.929382889000067],
              [-74.79525353899993, 10.929363662000071],
              [-74.79529013599995, 10.929302026000073],
              [-74.79538360099991, 10.929144611000083],
              [-74.79543906399994, 10.92905120000006 ],
              [-74.79551204899991, 10.928909174000069],
              [-74.79554471199992, 10.928845614000068],
              [-74.79564847099994, 10.928682996000077],
              [-74.79565507399991, 10.928672647000042],
              [-74.7957921759999 , 10.92857150000009 ],
              [-74.7961034409999 , 10.928452922000076],
              [-74.79613139299994, 10.928442274000076],
              [-74.79613482999991, 10.92844096500005 ],
              [-74.79615194999991, 10.928434443000071],
              [-74.79626836499995, 10.92839243800006 ],
              [-74.79637986199992, 10.928347391000045],
              [-74.79642220599993, 10.928330283000037],
              [-74.79643838899995, 10.928323745000057],
              [-74.79644203099991, 10.928322274000038],
              [-74.79646795499991, 10.928311800000074],
              [-74.7964742129999 , 10.928309469000055],
              [-74.7964858009999 , 10.928305152000064],
              [-74.79650201099992, 10.928299113000037],
              [-74.79651011499993, 10.928296094000075],
              [-74.79647936399994, 10.928168896000045],
              [-74.79662784299995, 10.92806991000009 ],
              [-74.79668019099995, 10.92803088700009 ],
              [-74.79672111699995, 10.927947130000064],
              [-74.79673333799991, 10.92790909100006 ],
              [-74.79678869399993, 10.927736785000093],
              [-74.79680582599991, 10.927667305000057],
              [-74.79681385999993, 10.927602191000062],
              [-74.79683318999992, 10.927445529000067],
              [-74.7967756029999 , 10.927109344000087],
              [-74.79694348699991, 10.927071703000081],
              [-74.79701236299991, 10.927056260000086],
              [-74.79702759199995, 10.926921106000066],
              [-74.79702521299993, 10.926864942000066],
              [-74.79701024699995, 10.926511632000086],
              [-74.79709701699994, 10.926495084000067],
              [-74.79712368299994, 10.92648999800008 ],
              [-74.79748825599995, 10.92642046800006 ],
              [-74.79770512099992, 10.926379447000045],
              [-74.7977273969999 , 10.926075911000055],
              [-74.7977377869999 , 10.925934343000051],
              [-74.79773827699995, 10.925927671000068],
              [-74.79780133299994, 10.925851528000067],
              [-74.79781233799991, 10.925734041000055],
              [-74.79781386299993, 10.925657780000051],
              [-74.7978138979999 , 10.925656031000074],
              [-74.79781420299992, 10.925640798000074],
              [-74.79781624399993, 10.925538739000046],
              [-74.7978169299999 , 10.925504471000067],
              [-74.7978194769999 , 10.925377122000043],
              [-74.79783940499993, 10.925098134000052],
              [-74.7978408919999 , 10.925077309000073],
              [-74.79783709199995, 10.924858163000067],
              [-74.79783660899994, 10.924830321000059],
              [-74.7978851499999 , 10.924454842000046],
              [-74.79789839499995, 10.924450755000066],
              [-74.7981349929999 , 10.92437774800004 ],
              [-74.79811111999993, 10.924318768000091],
              [-74.7980426709999 , 10.92414965800009 ],
              [-74.79798936999993, 10.924017973000048],
              [-74.79789514299995, 10.923880916000087],
              [-74.79780995899995, 10.923792638000066],
              [-74.79766290799995, 10.92370412300005 ],
              [-74.79753718199993, 10.923655757000063],
              [-74.79737432199994, 10.923639451000042],
              [-74.79733055499992, 10.923636629000043],
              [-74.79732590399993, 10.92363632900009 ],
              [-74.79732366299993, 10.923636185000078],
              [-74.79728842599991, 10.92363391300006 ],
              [-74.79724878899992, 10.923635453000088],
              [-74.79724735299993, 10.923609447000047],
              [-74.79724714399993, 10.923605652000049],
              [-74.79724631699992, 10.923590680000075],
              [-74.79724495799991, 10.923566066000092],
              [-74.79724473699991, 10.923562067000091],
              [-74.79723881599995, 10.92345485900006 ],
              [-74.79723867099995, 10.923452228000087],
              [-74.79728979399994, 10.92343040500009 ],
              [-74.79745136799994, 10.923352898000076],
              [-74.79760618199992, 10.923306203000038],
              [-74.79782675099995, 10.923263871000074],
              [-74.79793623199993, 10.923207700000091],
              [-74.7980282499999 , 10.923137968000049],
              [-74.79808930699994, 10.92305334000008 ],
              [-74.79811678999994, 10.922909669000092],
              [-74.7981173899999 , 10.922906242000067],
              [-74.79813023999992, 10.922832878000065],
              [-74.7981616429999 , 10.922653590000039],
              [-74.7981661309999 , 10.922633056000052],
              [-74.7982244609999 , 10.922366152000052],
              [-74.79822670499993, 10.922360673000071],
              [-74.7982891819999 , 10.922208155000078],
              [-74.79836894699991, 10.922114239000052],
              [-74.7984072029999 , 10.922069195000063],
              [-74.79855215799995, 10.92199170200007 ],
              [-74.79875835899992, 10.92189406700004 ],
              [-74.79886426899992, 10.921815064000043],
              [-74.79896366999992, 10.921708999000089],
              [-74.79900967099991, 10.921659914000088],
              [-74.7991132699999 , 10.92163234800006 ],
              [-74.79913018699995, 10.921627847000082],
              [-74.79914076199992, 10.921625033000055],
              [-74.79917828899994, 10.92162083800008 ],
              [-74.79917878399993, 10.921620783000037],
              [-74.79920104299993, 10.921618295000087],
              [-74.7992025129999 , 10.921384490000037],
              [-74.79920302599993, 10.92130289000005 ],
              [-74.79920310299991, 10.921290602000056],
              [-74.79922356999992, 10.921296152000082],
              [-74.79923150499991, 10.92129830500005 ],
              [-74.79939417999992, 10.921342421000077],
              [-74.79963139799992, 10.921438200000068],
              [-74.7996678419999 , 10.921452914000042],
              [-74.80008371999992, 10.921602991000043],
              [-74.80040191599994, 10.921706872000073],
              [-74.80062467999994, 10.921779597000068],
              [-74.80087660799995, 10.921819351000067],
              [-74.8010742429999 , 10.921831329000042],
              [-74.80123523999993, 10.921841086000086],
              [-74.80125351499993, 10.921842194000078],
              [-74.8013978649999 , 10.921836276000079],
              [-74.80156092599992, 10.921829590000073],
              [-74.80172606999992, 10.921797132000052],
              [-74.80173621299991, 10.92179513800005 ],
              [-74.80173892499994, 10.92179460500006 ],
              [-74.80196925699994, 10.921763196000086],
              [-74.80239375199994, 10.921739402000071],
              [-74.80242251599992, 10.921737951000068],
              [-74.80260124099993, 10.921728932000065],
              [-74.8026169449999 , 10.921730514000046],
              [-74.80278743699995, 10.92174768500007 ],
              [-74.80286583799995, 10.92175558200006 ],
              [-74.80315137299993, 10.921788894000088],
              [-74.80347331399992, 10.921842908000087],
              [-74.80408258799991, 10.921980592000068],
              [-74.80433240999992, 10.92203704700006 ],
              [-74.80457019199991, 10.92217712300004 ],
              [-74.80460936599991, 10.922200200000077],
              [-74.80470110899995, 10.92225424500009 ],
              [-74.80489075899993, 10.922325939000075],
              [-74.80520562499993, 10.922426985000072],
              [-74.80522293199994, 10.922432539000056],
              [-74.80551694499991, 10.922305750000078],
              [-74.80571350299994, 10.92222098600007 ],
              [-74.80571796099991, 10.922219064000046],
              [-74.80589584299992, 10.922138437000058],
              [-74.80604241799995, 10.92211654600004 ],
              [-74.80606393699992, 10.92210999300005 ],
              [-74.80612893199992, 10.922090199000081],
              [-74.8062431489999 , 10.922055415000045],
              [-74.80624367999991, 10.922055253000053],
              [-74.80625181099992, 10.92205277700009 ],
              [-74.80664204199991, 10.921929996000074],
              [-74.80686183499995, 10.921867988000088],
              [-74.80698278099993, 10.921833866000043],
              [-74.8070626889999 , 10.921812938000073],
              [-74.80719531699992, 10.921778202000041],
              [-74.80746247999991, 10.921708231000082],
              [-74.80748013399995, 10.92170727100006 ],
              [-74.80784971499992, 10.92168717900006 ],
              [-74.80804021299991, 10.921676822000052],
              [-74.80839903599991, 10.921670159000087],
              [-74.80863482599995, 10.921715973000062],
              [-74.80868373899995, 10.921725477000052],
              [-74.80876642499993, 10.921741543000053],
              [-74.8087803219999 , 10.921743985000091],
              [-74.80891272299993, 10.921767256000066],
              [-74.80893403499994, 10.921771002000071],
              [-74.80904067299991, 10.92178974400008 ],
              [-74.80914873699993, 10.921808737000049],
              [-74.80919215699993, 10.921816369000055],
              [-74.80924193699991, 10.921825118000072],
              [-74.80936753799995, 10.921847193000076],
              [-74.80941324999992, 10.921855227000037],
              [-74.80941366999991, 10.921866722000061],
              [-74.80941515199993, 10.921907322000038],
              [-74.80942903099992, 10.922287448000077],
              [-74.8100056479999 , 10.92224598900009 ],
              [-74.81057395099992, 10.92221928400005 ],
              [-74.81057989899995, 10.922305183000049],
              [-74.81085363799991, 10.922288198000047],
              [-74.81085375099991, 10.922288191000064],
              [-74.81086834399991, 10.922496893000073],
              [-74.8108696779999 , 10.922515978000092],
              [-74.81141790599992, 10.922493136000071],
              [-74.81142147599991, 10.922522491000052],
              [-74.81143159599992, 10.922605703000045],
              [-74.8114350379999 , 10.922634         ],
              [-74.81144133299995, 10.922721914000078],
              [-74.81144380299992, 10.922756400000083],
              [-74.81165351499993, 10.922747006000066],
              [-74.81173857399995, 10.922743195000066],
              [-74.81206919999994, 10.922728384000038],
              [-74.81214714499993, 10.922716155000046],
              [-74.81234290499992, 10.922711512000092],
              [-74.81236556499994, 10.92271097400004 ],
              [-74.81251759799994, 10.92270736800009 ],
              [-74.81260385699994, 10.922701904000064],
              [-74.8127692459999 , 10.922691428000064],
              [-74.81309508499993, 10.922670788000062],
              [-74.81326113999995, 10.922660270000051],
              [-74.81338034999993, 10.92265425700009 ],
              [-74.81363596799991, 10.922641364000071],
              [-74.81363815299994, 10.922641254000041],
              [-74.81382850099993, 10.922631652000064],
              [-74.81384969299995, 10.922630583000057],
              [-74.81406971199993, 10.922619485000041],
              [-74.81425599199991, 10.922610089000045],
              [-74.8144120899999 , 10.92260221500004 ],
              [-74.8144896259999 , 10.922597870000061],
              [-74.8145039119999 , 10.922597069000062],
              [-74.81458328199994, 10.922592620000046],
              [-74.81458396699992, 10.922549224000079],
              [-74.81458569299991, 10.922439939000071],
              [-74.81458665399992, 10.922379058000047],
              [-74.81458734799992, 10.92233513900004 ],
              [-74.81462453499995, 10.922332483000048],
              [-74.81477389799994, 10.922321814000043],
              [-74.81487668999995, 10.92228183900005 ],
              [-74.81513557599993, 10.922173336000071],
              [-74.81549344699994, 10.922041990000082],
              [-74.81562859999991, 10.921988690000092],
              [-74.81587796799994, 10.921745033000093],
              [-74.81634624599991, 10.921295791000091],
              [-74.81660839599994, 10.921060564000072],
              [-74.81669840599994, 10.920979798000076],
              [-74.81687186099992, 10.92089623600009 ],
              [-74.81695919499992, 10.920854163000058],
              [-74.8173632729999 , 10.920648328000084],
              [-74.81778572199994, 10.920455106000077],
              [-74.81786581499995, 10.920418472000051],
              [-74.81806056999994, 10.92032252000007 ],
              [-74.8181144589999 , 10.92029597000004 ],
              [-74.81815848899993, 10.92027427700009 ],
              [-74.81821092299992, 10.920246736000081],
              [-74.81849779999993, 10.920096055000045],
              [-74.81867240699995, 10.919988039000089],
              [-74.8187657819999 , 10.919904796000083],
              [-74.8187945279999 , 10.919879169000069],
              [-74.81892491299993, 10.919762931000037],
              [-74.81903418299993, 10.919639678000067],
              [-74.81920663299991, 10.919445160000066],
              [-74.81923167899993, 10.919417553000073],
              [-74.81928730999994, 10.919356235000066],
              [-74.81934681799993, 10.919290642000078],
              [-74.81942096799992, 10.919199898000045],
              [-74.81952572699993, 10.91907169700005 ],
              [-74.81952875299993, 10.91906801400006 ],
              [-74.81955222599993, 10.91903945200005 ],
              [-74.8196244749999 , 10.918951535000076],
              [-74.81988859499995, 10.918725486000085],
              [-74.81995277699991, 10.918690264000077],
              [-74.81999123599991, 10.918669158000057],
              [-74.82018126799994, 10.918564872000047],
              [-74.82039711399995, 10.918502104000083],
              [-74.82048497399995, 10.918476554000051],
              [-74.8205822079999 , 10.918448279000074],
              [-74.82076908399995, 10.918445637000048],
              [-74.82079562899992, 10.918454662000045],
              [-74.82093670499995, 10.918502628000056],
              [-74.8211454389999 , 10.91857359800008 ],
              [-74.8211736959999 , 10.918583205000061],
              [-74.8212365139999 , 10.918461377000085],
              [-74.82129742799992, 10.918303381000044],
              [-74.8213792809999 , 10.918078759000082],
              [-74.82143448499994, 10.917998810000086],
              [-74.82150110999993, 10.917955027000062],
              [-74.82154509999992, 10.917850678000093],
              [-74.82158296299991, 10.917760863000069],
              [-74.8215884729999 , 10.917746999000087],
              [-74.8216981189999 , 10.917471118000037],
              [-74.82170098499995, 10.91746390700007 ],
              [-74.82175048199991, 10.917373596000061],
              [-74.82180948799993, 10.917265936000092],
              [-74.82187808799995, 10.917157423000049],
              [-74.82191418399992, 10.917100325000092],
              [-74.82194535499991, 10.91705963000004 ],
              [-74.8219827129999 , 10.917010857000037],
              [-74.82200540899993, 10.916978606000043],
              [-74.8220188809999 , 10.91695946100009 ],
              [-74.82204172299993, 10.916892836000045],
              [-74.82206456599994, 10.916803368000046],
              [-74.8221254799999 , 10.91673484000006 ],
              [-74.82223398399992, 10.916677733000085],
              [-74.8222343459999 , 10.916677604000085],
              [-74.82240423499991, 10.916616929000043],
              [-74.82244327199993, 10.916594528000076],
              [-74.82252282199994, 10.91654887900006 ],
              [-74.82254997099994, 10.916533300000083],
              [-74.82255714399992, 10.91651704800006 ],
              [-74.82258337199994, 10.916457620000074],
              [-74.82261214899995, 10.916392417000054],
              [-74.82261314199991, 10.916390167000088],
              [-74.82262807099994, 10.91635634000005 ],
              [-74.82265979199991, 10.916284466000093],
              [-74.82266275799992, 10.916277746000048],
              [-74.82271516999992, 10.91625163100008 ],
              [-74.82273533599994, 10.916239674000053],
              [-74.82285668899993, 10.916176618000065],
              [-74.82312675799994, 10.916036230000088],
              [-74.82323985099993, 10.915983834000087],
              [-74.8233861189999 , 10.915916067000069],
              [-74.82352232399995, 10.915838342000086],
              [-74.8236050289999 , 10.91579114600006 ],
              [-74.8238548729999 , 10.915666224000063],
              [-74.82395566499991, 10.915608896000037],
              [-74.8241749099999 , 10.91548419500009 ],
              [-74.82432536099992, 10.915399475000072],
              [-74.82441999499991, 10.915346186000079],
              [-74.82472575499992, 10.915239111000062],
              [-74.82474509299993, 10.915234744000088],
              [-74.82502027399994, 10.915172607000045],
              [-74.82505768999994, 10.915164158000039],
              [-74.82528373899993, 10.915120138000077],
              [-74.82530494799994, 10.915109947000076],
              [-74.82537534799991, 10.915076118000059],
              [-74.8254092549999 , 10.915041616000053],
              [-74.82544316299993, 10.915007113000058],
              [-74.82556332599995, 10.914866725000081],
              [-74.82572296199993, 10.914699157000086],
              [-74.82575487199995, 10.914665661000072],
              [-74.82588303199992, 10.91456117100006 ],
              [-74.82596861799993, 10.91449139100007 ],
              [-74.82603473499995, 10.914437484000075],
              [-74.82609521799992, 10.914386173000082],
              [-74.82611366599991, 10.91437052200007 ],
              [-74.8262317999999 , 10.91427030200009 ],
              [-74.82625170999995, 10.914253411000061],
              [-74.82637113399994, 10.914154472000064],
              [-74.8264708509999 , 10.914071860000092],
              [-74.82649536199995, 10.914051553000093],
              [-74.82652171699993, 10.914031674000057],
              [-74.82653866299995, 10.914018892000058],
              [-74.82667494799995, 10.913916096000037],
              [-74.82681203899995, 10.913953736000053],
              [-74.82685283799992, 10.913986682000086],
              [-74.82761915599991, 10.914483464000057],
              [-74.82763858599992, 10.91449446200005 ],
              [-74.82782412099994, 10.914599475000045],
              [-74.8278381749999 , 10.914603990000046],
              [-74.82798826599992, 10.91465220100008 ],
              [-74.82825504099992, 10.914745762000052],
              [-74.82828837499994, 10.91475745300005 ],
              [-74.8284626329999 , 10.914800510000077],
              [-74.82857396499992, 10.91481486400005 ],
              [-74.82897572699994, 10.914834007000081],
              [-74.82921775199992, 10.91485792900005 ],
              [-74.82921909599992, 10.914853429000061],
              [-74.82925537699992, 10.914861084000052],
              [-74.82941112399993, 10.914893947000053],
              [-74.82975440999991, 10.915089187000092],
              [-74.83013557299995, 10.915243385000053],
              [-74.8305157499999 , 10.915397177000045],
              [-74.83069874599994, 10.915471203000038],
              [-74.8307015179999 , 10.915472325000053],
              [-74.8307042639999 , 10.91547343600007 ],
              [-74.8308420699999 , 10.915529186000072],
              [-74.8308984279999 , 10.915551988000061],
              [-74.83109786799992, 10.915632662000064],
              [-74.83113968899994, 10.915628131000062],
              [-74.83132058799993, 10.915608526000085],
              [-74.83160662499995, 10.915577519000067],
              [-74.83163933199995, 10.915573972000061],
              [-74.83181054199991, 10.915622511000038],
              [-74.83191005999993, 10.915707259000044],
              [-74.83202152099994, 10.915708076000044],
              [-74.83215559599995, 10.91571269700006 ],
              [-74.83215554499992, 10.915693476000058],
              [-74.83221069199993, 10.915733215000046],
              [-74.83239859199995, 10.915868613000043],
              [-74.8328788789999 , 10.916305421000061],
              [-74.8331779319999 , 10.916595010000037],
              [-74.83347268099993, 10.916923626000084],
              [-74.83359597399993, 10.917100985000047],
              [-74.8336393159999 , 10.917145958000049],
              [-74.83367513899992, 10.917183126000054],
              [-74.83367754099993, 10.917183529000056],
              [-74.83377783999993, 10.917200348000051],
              [-74.83379030399993, 10.917222460000062],
              [-74.8337913869999 , 10.917224385000054],
              [-74.83371982699992, 10.917549892000068],
              [-74.83363899099993, 10.91791760500007 ],
              [-74.83363768399994, 10.917923556000062],
              [-74.83385077899993, 10.918043528000055],
              [-74.83400124999991, 10.918128238000065],
              [-74.8340079539999 , 10.918132011000068],
              [-74.83442064999991, 10.918364342000075],
              [-74.8345505019999 , 10.91838894700004 ],
              [-74.83471923199994, 10.918420930000082],
              [-74.83478046599993, 10.91840822100005 ],
              [-74.83477497799993, 10.918403840000053],
              [-74.83497658199991, 10.918496660000073],
              [-74.83513953699992, 10.918569944000069],
              [-74.83540790399991, 10.918766898000058],
              [-74.83596846199993, 10.919187528000066],
              [-74.83662210099993, 10.919659137000053],
              [-74.8372911589999 , 10.920141802000046],
              [-74.83752386499992, 10.920310444000052],
              [-74.83759941299991, 10.920365185000037],
              [-74.83812528699991, 10.920752064000055],
              [-74.83856309499993, 10.921063058000072],
              [-74.83871678699995, 10.921166369000048],
              [-74.8388326779999 , 10.92121925400005 ],
              [-74.83903741799992, 10.92128521300009 ],
              [-74.83929418699995, 10.921377168000049],
              [-74.83931759799992, 10.92138555300005 ],
              [-74.83957174199992, 10.92151289800006 ],
              [-74.83957884699993, 10.921516594000082],
              [-74.83988709999994, 10.921676951000052],
              [-74.84001296399992, 10.921712873000047],
              [-74.84001574099995, 10.921713666000073],
              [-74.84001638599995, 10.92171384900007 ],
              [-74.84017526299994, 10.921737376000067],
              [-74.8403448869999 , 10.921758233000048],
              [-74.84015683799993, 10.922166463000053],
              [-74.83964099099995, 10.923284369000044],
              [-74.8394733909999 , 10.923632998000073],
              [-74.83928072299994, 10.924033772000087],
              [-74.83917669499994, 10.924250164000057],
              [-74.83898483699994, 10.924617503000093],
              [-74.8389835829999 , 10.924619905000043],
              [-74.83902661899992, 10.924619797000048],
              [-74.8387258809999 , 10.925451528000053],
              [-74.83852502399992, 10.926007018000064],
              [-74.83897773399991, 10.926278684000067],
              [-74.8393792469999 , 10.92651075200007 ],
              [-74.83955411399995, 10.92664549400007 ],
              [-74.83965330899991, 10.92670118400008 ],
              [-74.83979960599993, 10.926733450000086],
              [-74.84029824399994, 10.926831528000037],
              [-74.84051127099991, 10.926959779000072],
              [-74.84075397599992, 10.927083510000045],
              [-74.8410775829999 , 10.927164413000071],
              [-74.8415011269999 , 10.927202484000077],
              [-74.84171051899995, 10.92730718000007 ],
              [-74.84177650099991, 10.927355307000084],
              [-74.84211502799991, 10.927602234000062],
              [-74.84247194699992, 10.927740241000038],
              [-74.84266230399993, 10.927987706000067],
              [-74.84283362499991, 10.92809716100004 ],
              [-74.8430144639999 , 10.92817330400004 ],
              [-74.84369974899994, 10.928834794000068],
              [-74.84433863399994, 10.929570048000073],
              [-74.84491267899995, 10.930325527000036],
              [-74.84499300499994, 10.930437560000087],
              [-74.84542350899994, 10.93089402000004 ],
              [-74.84570547599992, 10.931200969000088],
              [-74.84624085399992, 10.931846994000068],
              [-74.84629491699991, 10.931918497000083],
              [-74.84648284799994, 10.932167051000079],
              [-74.84754396499994, 10.933594705000075],
              [-74.84821725799992, 10.934395855000048],
              [-74.848232        , 10.934413397000071],
              [-74.8482351099999 , 10.934410995000064],
              [-74.8482475319999 , 10.934407926000063],
              [-74.84827383599992, 10.934401426000079],
              [-74.84828334399992, 10.934408985000061],
              [-74.8484030919999 , 10.934504191000087],
              [-74.8484438559999 , 10.934557299000062],
              [-74.84862004699994, 10.934786844000087],
              [-74.84862397699993, 10.934791963000066],
              [-74.8486344499999 , 10.93481266100008 ],
              [-74.84866925799992, 10.934881449000045],
              [-74.84869616399993, 10.93491335300007 ],
              [-74.84871766899994, 10.934938852000073],
              [-74.84875090299994, 10.934957324000038],
              [-74.84881912499992, 10.935046205000049],
              [-74.84887310099992, 10.935116526000058],
              [-74.8488738289999 , 10.93511806500004 ],
              [-74.84892825999992, 10.935233048000043],
              [-74.84893930099992, 10.935252140000046],
              [-74.84896698999995, 10.935300020000057],
              [-74.84898934099994, 10.935353452000072],
              [-74.84895168499992, 10.935382798000091],
              [-74.84881065499991, 10.935492706000048],
              [-74.8488051779999 , 10.935496974000046],
              [-74.84895168499992, 10.935700735000069],
              [-74.84901721799991, 10.935791879000078],
              [-74.84901829199993, 10.935793372000091],
              [-74.8490760549999 , 10.935929717000079],
              [-74.84907965999992, 10.935938225000086],
              [-74.84918168999991, 10.936179060000086],
              [-74.8493187279999 , 10.936453856000071],
              [-74.84933409399991, 10.936484668000048],
              [-74.84934002699993, 10.936496566000073],
              [-74.84938744699991, 10.936591655000086],
              [-74.84944892499993, 10.936749038000073],
              [-74.8496224559999 , 10.937193275000084],
              [-74.84965372999994, 10.937273337000079],
              [-74.84968678799993, 10.937368061000086],
              [-74.84977007499992, 10.937606704000075],
              [-74.84978331999991, 10.937644655000042],
              [-74.8497956999999 , 10.937680129000057],
              [-74.84981727399992, 10.937728542000059],
              [-74.8499233579999 , 10.937966600000038],
              [-74.84993120999991, 10.93798422000009 ],
              [-74.8500129119999 , 10.938167563000093],
              [-74.85003554899993, 10.93821836300009 ],
              [-74.85007287999991, 10.938322061000065],
              [-74.85010552799992, 10.938412749000065],
              [-74.85011178999991, 10.938430143000062],
              [-74.85013495699991, 10.938494495000043],
              [-74.85016664299991, 10.93858251100005 ],
              [-74.85018215499991, 10.93862560100007 ],
              [-74.85018383599993, 10.93863027100008 ],
              [-74.8502025869999 , 10.938682358000051],
              [-74.85021484399994, 10.938722987000062],
              [-74.85022811099992, 10.93876696400008 ],
              [-74.85024735699994, 10.938830757000062],
              [-74.85033391299993, 10.938823238000055],
              [-74.85053001499995, 10.938806204000059],
              [-74.85070571999995, 10.938790941000093],
              [-74.85094112699994, 10.938780984000061],
              [-74.85100593999994, 10.938778243000058],
              [-74.85109952099992, 10.938774285000079],
              [-74.85112772499991, 10.93877159200008 ],
              [-74.8511996069999 , 10.938764727000091],
              [-74.85122782499991, 10.938762033000046],
              [-74.85143488599994, 10.938742260000083],
              [-74.85154804899992, 10.938731454000049],
              [-74.8516432639999 , 10.93872618000006 ],
              [-74.85192917099994, 10.938710342000093],
              [-74.85195612699994, 10.93870884900008 ],
              [-74.85234159899994, 10.938671968000051],
              [-74.85246811899992, 10.938656256000058],
              [-74.85252362799991, 10.938649363000081],
              [-74.85278298899993, 10.938600584000085],
              [-74.85281331099992, 10.938593968000077],
              [-74.8529056669999 , 10.938573818000066],
              [-74.85294757999992, 10.938564673000087],
              [-74.85311016499992, 10.938529200000062],
              [-74.85322097399995, 10.93849666300008 ],
              [-74.8534522779999 , 10.938428746000056],
              [-74.85353965799993, 10.938403089000076],
              [-74.8538657709999 , 10.938331405000042],
              [-74.85392394099995, 10.93831861800004 ],
              [-74.8540278559999 , 10.93829536700008 ],
              [-74.8544598979999 , 10.938198696000086],
              [-74.85454828099995, 10.938178920000041],
              [-74.85461517399995, 10.938163953000071],
              [-74.85481981999993, 10.938113098000088],
              [-74.85497012199994, 10.938075747000084],
              [-74.85501254399992, 10.938065205000044],
              [-74.8551490829999 , 10.93802893700007 ],
              [-74.85514970999992, 10.938028770000074],
              [-74.8552311219999 , 10.938007146000075],
              [-74.85524097299992, 10.938004529000068],
              [-74.85524325499995, 10.938003631000072],
              [-74.85557273699993, 10.937873934000038],
              [-74.85568651999995, 10.93782914400009 ],
              [-74.8558394069999 , 10.937768962000064],
              [-74.85612653999993, 10.93766953100004 ],
              [-74.8561761009999 , 10.93765236900009 ],
              [-74.85640928799995, 10.937560759000064],
              [-74.85654556999992, 10.93751569400007 ],
              [-74.85658106999995, 10.937503955000068],
              [-74.85670791099994, 10.937462012000083],
              [-74.85696784999993, 10.937367488000064],
              [-74.85698273799994, 10.937362074000077],
              [-74.8570521499999 , 10.937333988000091],
              [-74.85718856199992, 10.937278793000075],
              [-74.85724287999994, 10.937253420000047],
              [-74.85753239399992, 10.937118180000084],
              [-74.85766806499993, 10.93703555400009 ],
              [-74.85779104099993, 10.936960659000079],
              [-74.8580937079999 , 10.936743652000075],
              [-74.85824361399995, 10.936608161000038],
              [-74.85826522699995, 10.936628811000048],
              [-74.85826845899993, 10.936631899000076],
              [-74.85832516199991, 10.936686072000043],
              [-74.85833387299994, 10.936694395000075],
              [-74.85840827499993, 10.936744694000083],
              [-74.85843523799991, 10.93676292300006 ],
              [-74.85846113399992, 10.936776389000045],
              [-74.85847610399992, 10.936784174000081],
              [-74.85850662199994, 10.936800043000062],
              [-74.8585594459999 , 10.936810037000043],
              [-74.85859022799991, 10.936816469000064],
              [-74.85865509999991, 10.936830024000074],
              [-74.8588801969999 , 10.936887131000049],
              [-74.85913918199992, 10.936964827000054],
              [-74.85924043999995, 10.936995204000084],
              [-74.85937512499993, 10.937035610000066],
              [-74.8594435089999 , 10.937057143000061],
              [-74.85965060699994, 10.937122353000063],
              [-74.85970124799991, 10.937138299000082],
              [-74.85975878699992, 10.93715641700004 ],
              [-74.85992525599994, 10.937208834000046],
              [-74.85999288499994, 10.937227901000085],
              [-74.86033152399995, 10.937323373000083],
              [-74.86066767599993, 10.937418143000059],
              [-74.8607152379999 , 10.937431551000088],
              [-74.8610188689999 , 10.93749411300007 ],
              [-74.86111998799993, 10.937514948000057],
              [-74.86412756899995, 10.93813464100009 ],
              [-74.86468951799992, 10.93825042700007 ],
              [-74.86514884099995, 10.938345068000046],
              [-74.86916390599993, 10.939172347000067],
              [-74.86949576299992, 10.939240725000047],
              [-74.86983030599993, 10.93944554500007 ],
              [-74.87044129299994, 10.939850556000067],
              [-74.87131394999994, 10.940360122000072],
              [-74.87213340199992, 10.940884173000086],
              [-74.8731950259999 , 10.94146020800008 ],
              [-74.87334536399993, 10.94156505300009 ],
              [-74.8733473499999 , 10.941566697000042],
              [-74.87352971299993, 10.94171764400005 ],
              [-74.87395142799994, 10.941936585000064],
              [-74.87427627099993, 10.942131860000075],
              [-74.87453312299994, 10.942282539000075],
              [-74.8746253409999 , 10.94233663700004 ],
              [-74.87494525299991, 10.942498441000055],
              [-74.87503740299991, 10.942565168000044],
              [-74.8753187829999 , 10.94279882300009 ],
              [-74.87549003899994, 10.942911741000046],
              [-74.87568731899995, 10.94304181700005 ],
              [-74.87645515399993, 10.943578671000068],
              [-74.87645710299995, 10.943580034000092],
              [-74.87671564899995, 10.943760803000089],
              [-74.87724388899994, 10.944127240000057],
              [-74.87790416899992, 10.944485911000072],
              [-74.8776599649999 , 10.944686133000062],
              [-74.87790349099993, 10.944844371000045],
              [-74.87766243199991, 10.945139143000063],
              [-74.8776338269999 , 10.945174120000047],
              [-74.87775075099995, 10.945177587000046],
              [-74.87839738799994, 10.945196759000055],
              [-74.87846415299992, 10.945192815000041],
              [-74.87940564899992, 10.945137192000061],
              [-74.87945244699995, 10.945040086000063],
              [-74.87952597499992, 10.945048255000074],
              [-74.88075234399992, 10.945184517000087],
              [-74.88157486799992, 10.945220280000058],
              [-74.88156180399994, 10.945279493000044],
              [-74.8826791859999 , 10.945370782000055],
              [-74.88290492999994, 10.945384411000077],
              [-74.88372990499994, 10.94543421800006 ],
              [-74.88373015899992, 10.94542999500004 ],
              [-74.88373338499991, 10.945376061000047],
              [-74.88388926699992, 10.945389048000038],
              [-74.88441337799992, 10.945432724000057],
              [-74.88479918899992, 10.945432724000057],
              [-74.88588886399992, 10.94543273100004 ],
              [-74.88609496099991, 10.945502235000049],
              [-74.88775299499991, 10.945558119000054],
              [-74.8896114449999 , 10.94562675900005 ],
              [-74.89073017299995, 10.945665658000053],
              [-74.8916334299999 , 10.945696121000083],
              [-74.89207681499994, 10.945711074000087],
              [-74.89217940499992, 10.945701296000038],
              [-74.8923106609999 , 10.945624931000054],
              [-74.89227659499994, 10.945788764000042],
              [-74.89226208399992, 10.94590858500004 ],
              [-74.8923121549999 , 10.94591007300005 ],
              [-74.89251924299992, 10.945932429000038],
              [-74.89256342699991, 10.94593719900007 ],
              [-74.89257890099992, 10.945938279000075],
              [-74.89280898699991, 10.945954331000053],
              [-74.89277757799994, 10.946498276000057],
              [-74.89277043999994, 10.946752403000062],
              [-74.8929041419999 , 10.946732982000071],
              [-74.89302599399991, 10.94671528200007 ],
              [-74.8930402709999 , 10.947135020000076],
              [-74.89286181099993, 10.94720069200008 ],
              [-74.89291035199994, 10.947367731000043],
              [-74.89292320099992, 10.94747052300005 ],
              [-74.8929146349999 , 10.947543334000045],
              [-74.89285181699995, 10.947677536000072],
              [-74.89261767799991, 10.947721794000074],
              [-74.89262053399995, 10.947885978000045],
              [-74.8924049549999 , 10.948612665000041],
              [-74.8923863949999 , 10.948769710000079],
              [-74.89241494799995, 10.948896773000058],
              [-74.89240209899992, 10.94909664700009 ],
              [-74.8925477219999 , 10.949116635000053],
              [-74.89255718699991, 10.949607471000093],
              [-74.89255771599994, 10.949634882000055],
              [-74.8925585959999 , 10.949654173000056],
              [-74.89257484799992, 10.950010361000068],
              [-74.89260368999993, 10.95030599100005 ],
              [-74.89261821299993, 10.950454843000045],
              [-74.89306483999991, 10.950461819000054],
              [-74.89398639799992, 10.950503747000084],
              [-74.89414261999991, 10.950510854000072],
              [-74.89494359999992, 10.950261953000052],
              [-74.89594195699993, 10.950103421000051],
              [-74.8962879579999 , 10.950266885000076],
              [-74.89629725799995, 10.950271279000049],
              [-74.89654702199994, 10.950296585000046],
              [-74.89701999099992, 10.950243355000055],
              [-74.89753206199993, 10.950073151000083],
              [-74.8980835129999 , 10.949889837000057],
              [-74.89895052399993, 10.949757628000043],
              [-74.89935488299994, 10.949747936000051],
              [-74.90017264499994, 10.949728333000053],
              [-74.9005453289999 , 10.949849389000065],
              [-74.90065096899991, 10.949861871000053],
              [-74.90082228999995, 10.949882112000068],
              [-74.90091219199991, 10.94989273300007 ],
              [-74.90223712499994, 10.950049264000086],
              [-74.90238703099993, 10.950048437000078],
              [-74.9024976039999 , 10.950047828000038],
              [-74.90252731299995, 10.950047664000067],
              [-74.90255556799991, 10.950047509000058],
              [-74.90258382399992, 10.950047353000059],
              [-74.9036016899999 , 10.95004173600006 ],
              [-74.90462900799992, 10.950036066000052],
              [-74.90466394499992, 10.950035874000037],
              [-74.90525547199991, 10.950032608000072],
              [-74.90539394199993, 10.950053165000043],
              [-74.90547798599994, 10.950068319000081],
              [-74.90551463899993, 10.95007492700006 ],
              [-74.9056858429999 , 10.950105798000038],
              [-74.90571587699992, 10.95011121400006 ],
              [-74.90624451699995, 10.950206536000053],
              [-74.90676835699992, 10.95028852300004 ],
              [-74.90748413199992, 10.95042115800004 ],
              [-74.9077148049999 , 10.95046390400006 ],
              [-74.9090907719999 , 10.949826905000066],
              [-74.91051290999991, 10.949152005000087],
              [-74.91193462199993, 10.948455631000058],
              [-74.9154071609999 , 10.946797684000046],
              [-74.9163338219999 , 10.94634293200005 ],
              [-74.91821153399991, 10.945383057000072],
              [-74.9183999309999 , 10.945286751000083],
              [-74.91841916599992, 10.945440906000044],
              [-74.91840074699991, 10.945570907000047],
              [-74.9184008439999 , 10.945605219000072],
              [-74.91840147999994, 10.94583080600006 ],
              [-74.9185148759999 , 10.946071829000061],
              [-74.91870362899994, 10.946386906000043],
              [-74.91876050899992, 10.946572389000039],
              [-74.91885601299992, 10.947129055000062],
              [-74.91883806299995, 10.947426135000057],
              [-74.9188764239999 , 10.947704495000039],
              [-74.91895251199992, 10.948038443000087],
              [-74.91895335099991, 10.948335469000085],
              [-74.9189729229999 , 10.94861387900005 ],
              [-74.91910500299991, 10.948817724000037],
              [-74.9191403669999 , 10.94927767900009 ],
              [-74.91904514999993, 10.94976768400005 ],
              [-74.91871910299994, 10.950317659000063],
              [-74.91866887899994, 10.950454264000086],
              [-74.91856571399995, 10.950734857000043],
              [-74.91835480899994, 10.951341685000045],
              [-74.91816071199992, 10.951917623000043],
              [-74.91810556799993, 10.952081251000038],
              [-74.9180434889999 , 10.952195251000092],
              [-74.91783706399991, 10.952574325000057],
              [-74.91783709799995, 10.952695402000074],
              [-74.91783720599994, 10.953086284000051],
              [-74.91791408199992, 10.953522379000049],
              [-74.91795191699993, 10.953895210000042],
              [-74.91797181099992, 10.954091206000044],
              [-74.91770331999993, 10.954641165000055],
              [-74.91743475399994, 10.95490669700007 ],
              [-74.91695066699992, 10.955188258000078],
              [-74.91678243799993, 10.955286106000074],
              [-74.9167324739999 , 10.955343105000054],
              [-74.91670606399992, 10.955373234000092],
              [-74.91653306199993, 10.955570596000086],
              [-74.91616848899992, 10.955627580000055],
              [-74.91586156699992, 10.955987930000049],
              [-74.9156598749999 , 10.956099376000054],
              [-74.91520923999991, 10.956348371000047],
              [-74.91496435399995, 10.956566275000057],
              [-74.91497935099994, 10.95712541800009 ],
              [-74.91493176199992, 10.957258668000065],
              [-74.91486513699994, 10.95734432800009 ],
              [-74.91476519899993, 10.957387158000074],
              [-74.9142306729999 , 10.957387158000074],
              [-74.91404546099994, 10.957387158000074],
              [-74.9139752179999 , 10.957387158000074],
              [-74.91337083499991, 10.957401435000065],
              [-74.91328427699995, 10.957401435000065],
              [-74.9132239079999 , 10.957401435000065],
              [-74.9131512429999 , 10.957401435000065],
              [-74.91307028799991, 10.957401435000065],
              [-74.9127471349999 , 10.957401435000065],
              [-74.91258104899993, 10.957401435000065],
              [-74.91250946999992, 10.957401435000065],
              [-74.91238097899992, 10.957477578000066],
              [-74.91224945099992, 10.957672130000049],
              [-74.9122060819999 , 10.957736279000073],
              [-74.9121525509999 , 10.957815461000052],
              [-74.9118452699999 , 10.958282073000078],
              [-74.9118120409999 , 10.958332532000043],
              [-74.91176707799991, 10.958400809000068],
              [-74.9116965469999 , 10.958453708000093],
              [-74.9116909359999 , 10.958457916000043],
              [-74.91162431099991, 10.958462675000078],
              [-74.91145417999991, 10.958430302000068],
              [-74.91096358399994, 10.958336949000056],
              [-74.91071132999991, 10.958288949000064],
              [-74.91044885699995, 10.958239005000053],
              [-74.91038035399993, 10.95823228100005 ],
              [-74.9103780559999 , 10.958232055000053],
              [-74.91003404899993, 10.958198288000062],
              [-74.90982792499995, 10.958178055000076],
              [-74.9098120139999 , 10.958176493000053],
              [-74.90967315299991, 10.958162863000041],
              [-74.90868647099995, 10.95792917500006 ],
              [-74.90858811899994, 10.957905881000045],
              [-74.90852588799993, 10.957893224000088],
              [-74.90836685099993, 10.957860878000076],
              [-74.90830734199994, 10.95784877400007 ],
              [-74.90817885099995, 10.95784877400007 ],
              [-74.90799801199995, 10.957877327000062],
              [-74.90790283399991, 10.95792491700007 ],
              [-74.90784282899995, 10.957960920000062],
              [-74.90759350399992, 10.958110515000044],
              [-74.90743170099995, 10.958243764000088],
              [-74.90738734699994, 10.958275446000073],
              [-74.90716520099994, 10.958434121000039],
              [-74.90713512099995, 10.958460979000051],
              [-74.90703195099991, 10.95855309400008 ],
              [-74.90690345999991, 10.958748210000067],
              [-74.90689014399993, 10.958848084000067],
              [-74.90687490699992, 10.95896236200008 ],
              [-74.90687490699992, 10.958964295000044],
              [-74.90687490699992, 10.959046854000064],
              [-74.90687490699992, 10.959186031000058],
              [-74.90689965099995, 10.959336745000087],
              [-74.90692725499991, 10.959504879000065],
              [-74.90702415099992, 10.959813186000076],
              [-74.90703195099991, 10.959838003000073],
              [-74.90713512099995, 10.959999205000088],
              [-74.9071523849999 , 10.960026179000067],
              [-74.90718302199991, 10.96007405000006 ],
              [-74.90718423699991, 10.960075949000043],
              [-74.90728037299994, 10.96020750300005 ],
              [-74.9073650759999 , 10.960323413000083],
              [-74.90742811099994, 10.96042723700009 ],
              [-74.90744597699995, 10.960456663000059],
              [-74.90746025399994, 10.960556601000064],
              [-74.90745755699993, 10.960613231000082],
              [-74.90745311599994, 10.960706507000054],
              [-74.90737816299992, 10.960838567000053],
              [-74.90725324099992, 10.960917089000077],
              [-74.90713512099995, 10.960935740000082],
              [-74.90711761199992, 10.960938504000069],
              [-74.90689989099991, 10.960877828000037],
              [-74.90650727999991, 10.960667245000081],
              [-74.90637957399991, 10.960616163000054],
              [-74.90600759299991, 10.96046737100005 ],
              [-74.9059320579999 , 10.960431305000043],
              [-74.90572644399992, 10.960333129000048],
              [-74.90566048799991, 10.960301636000054],
              [-74.90561141299992, 10.960278204000076],
              [-74.90550043099995, 10.960278204000076],
              [-74.90545436799994, 10.960278204000076],
              [-74.9054126879999 , 10.960279480000054],
              [-74.90532145599991, 10.960282273000075],
              [-74.90510458799992, 10.960288911000077],
              [-74.90481514599992, 10.960287539000092],
              [-74.90465437799992, 10.960286777000078],
              [-74.90435148799992, 10.960285342000077],
              [-74.90384109399992, 10.960253219000037],
              [-74.90309156399991, 10.96032103400006 ],
              [-74.90299961499994, 10.96033942400004 ],
              [-74.90295803599992, 10.960347740000088],
              [-74.90287741199995, 10.960363864000044],
              [-74.90283026799995, 10.96036891500006 ],
              [-74.90267753699993, 10.960385279000093],
              [-74.90257225399995, 10.960389666000083],
              [-74.9024080499999 , 10.960396508000088],
              [-74.90233489499991, 10.960399556000084],
              [-74.90229335299995, 10.960395402000074],
              [-74.90213043099993, 10.960379110000076],
              [-74.9020850519999 , 10.960374572000092],
              [-74.90205911599992, 10.960378933000072],
              [-74.90194907699993, 10.960397435000061],
              [-74.9019122599999 , 10.960403625000083],
              [-74.90173801899994, 10.960432922000052],
              [-74.90168173299992, 10.960442386000068],
              [-74.90167741999994, 10.960442834000048],
              [-74.90163176699991, 10.960447571000088],
              [-74.9016218079999 , 10.960448604000078],
              [-74.90154699599992, 10.960456366000074],
              [-74.90147807099993, 10.960475054000085],
              [-74.90147794899991, 10.960600704000058],
              [-74.9012888119999 , 10.960612795000088],
              [-74.90108452999993, 10.960625853000067],
              [-74.90093902799993, 10.960635154000045],
              [-74.90050955599992, 10.960662607000074],
              [-74.90026786899995, 10.960678057000052],
              [-74.90022662699994, 10.96068069200004 ],
              [-74.90017002199994, 10.960772404000068],
              [-74.90014743899991, 10.960808993000057],
              [-74.90009210499994, 10.960898645000043],
              [-74.90008877899993, 10.960904034000066],
              [-74.89999620299994, 10.961054025000067],
              [-74.89999430199993, 10.961057106000055],
              [-74.89990004499992, 10.961209820000079],
              [-74.89988892999992, 10.961227829000052],
              [-74.89975094399995, 10.961451392000072],
              [-74.89970027899994, 10.961533479000082],
              [-74.89937276299992, 10.962064117000068],
              [-74.8991146969999 , 10.96248223300006 ],
              [-74.89906362099993, 10.962564987000064],
              [-74.8990339739999 , 10.96261301900006 ],
              [-74.89927375199994, 10.96281498400009 ],
              [-74.89977524999995, 10.963237394000089],
              [-74.89981303399992, 10.96326921900004 ],
              [-74.90035652299991, 10.963726995000059],
              [-74.90090187099992, 10.964186338000047],
              [-74.9021916339999 , 10.965278936000061],
              [-74.90401397699992, 10.966413926000087],
              [-74.90419863999995, 10.966659381000056],
              [-74.90472888099993, 10.967364183000086],
              [-74.90477107499993, 10.967420269000058],
              [-74.9050218399999 , 10.967854057000068],
              [-74.9057073699999 , 10.96903992600005 ],
              [-74.90630837299994, 10.969672699000057],
              [-74.90633039199992, 10.969695883000043],
              [-74.9068645509999 , 10.970308235000061],
              [-74.90757558599995, 10.970700889000057],
              [-74.90828576099995, 10.97078663600007 ],
              [-74.9087747019999 , 10.971092205000048],
              [-74.9093081339999 , 10.971441499000036],
              [-74.90873425299992, 10.972495301000038],
              [-74.90760120699991, 10.97220780500004 ],
              [-74.9075863889999 , 10.972204045000069],
              [-74.90746466099995, 10.97217315800009 ],
              [-74.90531523299995, 10.97162774900005 ],
              [-74.90531572299994, 10.971804654000039],
              [-74.90531596499994, 10.971890803000065],
              [-74.90443341499991, 10.973646934000044],
              [-74.90319320899994, 10.974439475000054],
              [-74.9008012779999 , 10.975936624000042],
              [-74.90079736699994, 10.975939098000083],
              [-74.90018104199993, 10.97632897200009 ],
              [-74.89891070099992, 10.977132564000044],
              [-74.89836506799992, 10.977477718000046],
              [-74.89761438099993, 10.978483167000093],
              [-74.8973597939999 , 10.978824156000087],
              [-74.89734826099993, 10.978839601000061],
              [-74.89727773499993, 10.978963358000044],
              [-74.89727108799991, 10.978975022000043],
              [-74.89659755799994, 10.98015692100006 ],
              [-74.8964715649999 , 10.980504047000068],
              [-74.89624530899994, 10.981127411000045],
              [-74.89592633899991, 10.982006212000044],
              [-74.89584889699995, 10.982219575000045],
              [-74.8947540229999 , 10.982655710000074],
              [-74.89419096799992, 10.98287999900009 ],
              [-74.89385429499993, 10.983014109000067],
              [-74.89203500699995, 10.983018984000068],
              [-74.8882568489999 , 10.980661525000073],
              [-74.88714550399993, 10.979919145000054],
              [-74.8868322699999 , 10.978955424000048],
              [-74.88602251499992, 10.97815961200007 ],
              [-74.8859234379999 , 10.978062240000042],
              [-74.88491913799993, 10.977075231000072],
              [-74.88315802599993, 10.974521252000045],
              [-74.88309643299993, 10.974478913000041],
              [-74.88299841499992, 10.974411553000039],
              [-74.88300252099992, 10.974440723000043],
              [-74.88302309599993, 10.974586913000053],
              [-74.88299758199992, 10.974569230000043],
              [-74.88296232999994, 10.974544804000061],
              [-74.88293542499991, 10.974585579000063],
              [-74.88286905899992, 10.974651178000045],
              [-74.88277040399993, 10.974754010000083],
              [-74.88273273199991, 10.974780604000046],
              [-74.88268250899995, 10.974821384000052],
              [-74.88263945799991, 10.974862164000058],
              [-74.88259999699994, 10.974910030000046],
              [-74.88256233299995, 10.974973853000051],
              [-74.8825515719999 , 10.975018171000045],
              [-74.88252647099995, 10.975101488000064],
              [-74.88249240199991, 10.975197217000073],
              [-74.8824744659999 , 10.975245083000061],
              [-74.88246012699994, 10.975303585000063],
              [-74.88244398799992, 10.975349677000054],
              [-74.8824350239999 , 10.975390448000041],
              [-74.88243323699993, 10.97543121800004 ],
              [-74.88243324099994, 10.97546667100005 ],
              [-74.88244465699995, 10.975554006000038],
              [-74.88245711299993, 10.975649312000087],
              [-74.88241805299992, 10.975672477000046],
              [-74.88241494399995, 10.975677093000058],
              [-74.8823868149999 , 10.975718794000045],
              [-74.88237119999991, 10.975776693000057],
              [-74.8823673039999 , 10.975834582000061],
              [-74.88236731399991, 10.975896336000062],
              [-74.88230093599992, 10.976056519000053],
              [-74.88230095299991, 10.976160725000057],
              [-74.88230097699994, 10.976311249000048],
              [-74.88230490099994, 10.97642703200006 ],
              [-74.88229725299993, 10.976578657000061],
              [-74.8822631299999 , 10.97671508700006 ],
              [-74.88224245299995, 10.976731948000065],
              [-74.88221512399991, 10.976813001000039],
              [-74.88219170299993, 10.976913351000064],
              [-74.88219171599991, 10.976994403000049],
              [-74.88218782699994, 10.977094751000038],
              [-74.88219956799992, 10.977237553000066],
              [-74.88219958499991, 10.977345620000051],
              [-74.88219569599994, 10.977461408000067],
              [-74.88221914199994, 10.97753087500007 ],
              [-74.88230899599995, 10.977604193000047],
              [-74.88239884899991, 10.97766978900006 ],
              [-74.8824808899999 , 10.977746971000045],
              [-74.88266058599993, 10.977843429000075],
              [-74.88275043499993, 10.97789358700004 ],
              [-74.88278559999992, 10.977951476000044],
              [-74.88279342999994, 10.978078838000044],
              [-74.8828364119999 , 10.978156023000054],
              [-74.88292627899995, 10.97831424900005 ],
              [-74.88298097299992, 10.978368272000068],
              [-74.88308254099991, 10.978422292000062],
              [-74.88321535599994, 10.978460863000066],
              [-74.88330130199995, 10.978534185000058],
              [-74.88337943999994, 10.97862679800005 ],
              [-74.88348100399992, 10.978680818000043],
              [-74.88359429099995, 10.97871553300007 ],
              [-74.88369584799995, 10.978715517000069],
              [-74.88378569799994, 10.978757959000063],
              [-74.88382760499991, 10.978807630000063],
              [-74.88384429899992, 10.978827421000062],
              [-74.88384758599994, 10.97889856200004 ],
              [-74.88384821799991, 10.97891233100006 ],
              [-74.8838130819999 , 10.979001107000045],
              [-74.88377012099994, 10.979047428000058],
              [-74.88369199599992, 10.979028142000061],
              [-74.88353965499994, 10.979032025000038],
              [-74.88343419199992, 10.979032041000039],
              [-74.88332091899991, 10.979055216000063],
              [-74.88320373399995, 10.979051379000055],
              [-74.88311389299992, 10.97904753000006 ],
              [-74.88309045199992, 10.979020518000084],
              [-74.88300921699994, 10.97900648700005 ],
              [-74.88293420199994, 10.978993527000057],
              [-74.88279747599995, 10.978920216000063],
              [-74.88272325899993, 10.978916370000093],
              [-74.88267248699992, 10.978966550000052],
              [-74.88264124799991, 10.979024450000054],
              [-74.88257875799991, 10.979074633000039],
              [-74.88249672899991, 10.979074646000072],
              [-74.88245836599992, 10.979062807000048],
              [-74.88243422799991, 10.97905535800004 ],
              [-74.8823756299999 , 10.979016774000058],
              [-74.88234209599995, 10.979054568000038],
              [-74.8822612269999 , 10.979124249000051],
              [-74.88219338999994, 10.97918270200006 ],
              [-74.88214001499995, 10.97926749000004 ],
              [-74.88214002899991, 10.97933531600006 ],
              [-74.88216481799992, 10.979371103000062],
              [-74.88220296399993, 10.97943327300004 ],
              [-74.88224301999992, 10.979510506000054],
              [-74.88227353499991, 10.979568906000054],
              [-74.8823288449999 , 10.979649908000056],
              [-74.88235218499995, 10.979691246000073],
              [-74.88238415599994, 10.97974786800006 ],
              [-74.88244517999993, 10.979800609000051],
              [-74.88250047999992, 10.979832629000043],
              [-74.88260691999994, 10.979905101000043],
              [-74.88264506099995, 10.979988812000045],
              [-74.88271285699994, 10.980064148000054],
              [-74.88276370199992, 10.980110186000047],
              [-74.88284190599995, 10.980159339000068],
              [-74.88282512099994, 10.980242314000066],
              [-74.88280834599993, 10.980358505000083],
              [-74.8828923559999 , 10.980482978000055],
              [-74.88304356399993, 10.98056594700006 ],
              [-74.88310323399992, 10.980590760000041],
              [-74.88320316399995, 10.980632315000037],
              [-74.88321157799993, 10.980723605000037],
              [-74.8831115129999 , 10.980822506000038],
              [-74.88311080199992, 10.980823213000065],
              [-74.88301000999991, 10.980839828000057],
              [-74.88294281599991, 10.980848137000066],
              [-74.88280844199994, 10.980947749000052],
              [-74.8826908559999 , 10.980989266000051],
              [-74.88259006299995, 10.980989282000053],
              [-74.88247247099991, 10.980989298000054],
              [-74.88241366699992, 10.980939515000046],
              [-74.88234647199994, 10.980947823000065],
              [-74.88228768999994, 10.981030825000062],
              [-74.88225410199993, 10.981105523000053],
              [-74.8822348519999 , 10.981186424000043],
              [-74.88221577199994, 10.981186473000037],
              [-74.8821449379999 , 10.98127982200009 ],
              [-74.88205255699995, 10.981354531000079],
              [-74.88197695999992, 10.981371142000057],
              [-74.88191815999994, 10.981329651000067],
              [-74.88185096099994, 10.98129646600006 ],
              [-74.88175019199991, 10.981454167000038],
              [-74.88157380399991, 10.981454195000083],
              [-74.88148140599992, 10.981437610000057],
              [-74.88138059499994, 10.981321440000045],
              [-74.88128819199994, 10.981271656000047],
              [-74.88119580699993, 10.981321466000054],
              [-74.88108662399992, 10.981412772000056],
              [-74.88096064899992, 10.98149578500005 ],
              [-74.88081787399994, 10.98159540100005 ],
              [-74.88075908199994, 10.981620303000057],
              [-74.8805575049999 , 10.981686728000057],
              [-74.8804734979999 , 10.981636946000037],
              [-74.88041469299992, 10.981553964000057],
              [-74.88032229299995, 10.981512480000049],
              [-74.88017951899991, 10.98162039500005 ],
              [-74.88013751499994, 10.981595502000062],
              [-74.8800367369999 , 10.981678510000052],
              [-74.87986876699995, 10.981827920000057],
              [-74.87979317599991, 10.981836230000056],
              [-74.87974278699994, 10.981886035000059],
              [-74.87974279999992, 10.98198562500005 ],
              [-74.8796840249999 , 10.982110122000051],
              [-74.87965883999993, 10.982201416000066],
              [-74.8797596419999 , 10.98225119600005 ],
              [-74.87982683699994, 10.982267782000065],
              [-74.87986045399992, 10.982383970000058],
              [-74.87981007399992, 10.982483564000063],
              [-74.87985208399994, 10.982566552000037],
              [-74.8799444839999 , 10.982599736000054],
              [-74.87991091099991, 10.982765722000067],
              [-74.87977652599994, 10.98283213700006 ],
              [-74.8796589339999 , 10.982815555000059],
              [-74.87955812899992, 10.982749177000073],
              [-74.87945733799995, 10.982765792000066],
              [-74.87930614999993, 10.982782412000063],
              [-74.87912136799991, 10.982832233000067],
              [-74.87900378299992, 10.982882050000057],
              [-74.87890298899993, 10.98288206300009 ],
              [-74.87872660599993, 10.982956779000062],
              [-74.87859222399993, 10.982998297000051],
              [-74.87851664099992, 10.983081299000048],
              [-74.87843265799995, 10.98316430400007 ],
              [-74.87842427999993, 10.983305391000044],
              [-74.87842428099992, 10.983338588000038],
              [-74.8784998829999 , 10.98338007600006 ],
              [-74.87847469899992, 10.983454770000037],
              [-74.87834871599995, 10.983521181000071],
              [-74.87819754199995, 10.983637394000084],
              [-74.87810515099994, 10.983662304000063],
              [-74.8779623769999 , 10.983786812000062],
              [-74.87786998199994, 10.983786827000074],
              [-74.87771879199994, 10.983795150000049],
              [-74.87764321199995, 10.983903049000048],
              [-74.87766001999995, 10.983986038000069],
              [-74.8776936459999 , 10.984152015000063],
              [-74.87760965299992, 10.984168626000042],
              [-74.87755086199991, 10.984210130000065],
              [-74.87755086899995, 10.984276525000041],
              [-74.87755088199992, 10.984367818000067],
              [-74.87751730399992, 10.984484012000053],
              [-74.87745010599991, 10.98447572300006 ],
              [-74.87736611299994, 10.984492332000059],
              [-74.87738291799991, 10.984550423000087],
              [-74.87740813499994, 10.984658309000054],
              [-74.87741654599995, 10.984741300000053],
              [-74.8774165609999 , 10.984840890000044],
              [-74.87732416799992, 10.984857504000047],
              [-74.8772317669999 , 10.98481601800006 ],
              [-74.87722462199991, 10.984811315000059],
              [-74.87711827499993, 10.984930164000048],
              [-74.87684871199991, 10.984966403000044],
              [-74.87659721699993, 10.985038122000049],
              [-74.87654377099994, 10.985215887000038],
              [-74.87647215099992, 10.98532265600005 ],
              [-74.87654434899991, 10.985429040000042],
              [-74.87659847399993, 10.985499945000072],
              [-74.8767606529999 , 10.985641617000056],
              [-74.87672532599993, 10.98587262500007 ],
              [-74.87661793799992, 10.98605053600005 ],
              [-74.87647459999994, 10.986228542000049],
              [-74.87620576199993, 10.986531219000085],
              [-74.87616133199992, 10.986555764000059],
              [-74.8761322279999 , 10.986682848000044],
              [-74.8760930059999 , 10.986788079000064],
              [-74.87600894499991, 10.98690993200006 ],
              [-74.87594168699991, 10.986970864000057],
              [-74.8758071769999 , 10.987053957000057],
              [-74.87564463399991, 10.987114898000073],
              [-74.87549890999992, 10.987203531000091],
              [-74.87534196999991, 10.987253395000039],
              [-74.87513458399991, 10.987308805000055],
              [-74.8748431109999 , 10.98731438100009 ],
              [-74.87445635599994, 10.98736427600005 ],
              [-74.87435545799991, 10.98732552100006 ],
              [-74.8741329579999 , 10.987356961000046],
              [-74.87412004299995, 10.987358782000058],
              [-74.87392948399992, 10.987502802000051],
              [-74.87387905399993, 10.98763572900009 ],
              [-74.87384543899992, 10.98775203200006 ],
              [-74.87382864599994, 10.987923719000037],
              [-74.87390152899991, 10.988051090000056],
              [-74.87399683299992, 10.988145230000043],
              [-74.87417620299993, 10.988161818000037],
              [-74.87428730699992, 10.98818619800005 ],
              [-74.87437799799994, 10.988206097000045],
              [-74.87447890599992, 10.988305771000057],
              [-74.87471433499991, 10.988366661000043],
              [-74.8748544849999 , 10.988499559000047],
              [-74.8749006139999 , 10.98855059400006 ],
              [-74.87499463699993, 10.988654612000062],
              [-74.87504509999991, 10.98878198400007 ],
              [-74.87505632099993, 10.988842902000044],
              [-74.87504512699991, 10.988986899000054],
              [-74.87500590099995, 10.989042285000039],
              [-74.87496667099992, 10.989092137000057],
              [-74.8749330469999 , 10.989158600000053],
              [-74.87489942899992, 10.989274906000048],
              [-74.87484338899992, 10.98934137200007 ],
              [-74.8747593189999 , 10.989407842000048],
              [-74.87468646499991, 10.989513079000062],
              [-74.87459119199991, 10.98966262500005 ],
              [-74.87453515699991, 10.98976785700006 ],
              [-74.87450876699995, 10.989849015000061],
              [-74.87448472599993, 10.98992293300006 ],
              [-74.87439506099992, 10.990039249000063],
              [-74.8743390269999 , 10.990177710000069],
              [-74.87433903699991, 10.990249709000068],
              [-74.87437267999991, 10.990338314000041],
              [-74.87446706899993, 10.990426373000048],
              [-74.87447358799994, 10.99043245100006 ],
              [-74.87454646299994, 10.990476749000038],
              [-74.87463616799994, 10.990626268000085],
              [-74.87466420299995, 10.99068718600006 ],
              [-74.87477631599995, 10.99074255100004 ],
              [-74.87486600199992, 10.990764693000074],
              [-74.87498371499993, 10.990759138000044],
              [-74.87512944399992, 10.990698200000054],
              [-74.87530879899992, 10.990592951000053],
              [-74.87547695199993, 10.990543082000045],
              [-74.87563949799994, 10.990498754000043],
              [-74.87574599099992, 10.99044335800005 ],
              [-74.87590852699992, 10.990310417000046],
              [-74.87603182599992, 10.99021625100005 ],
              [-74.87620558999993, 10.990188536000062],
              [-74.87628406899995, 10.99022175500005 ],
              [-74.87634013099995, 10.99028820600006 ],
              [-74.87635887099992, 10.990305397000043],
              [-74.87641861599991, 10.99036019500005 ],
              [-74.87647469099994, 10.990515254000059],
              [-74.87651395199993, 10.990686933000063],
              [-74.87646913499992, 10.990875239000047],
              [-74.87641870899995, 10.991019240000071],
              [-74.8763346529999 , 10.991202016000045],
              [-74.87627861899995, 10.991323861000069],
              [-74.8762113709999 , 10.991440173000058],
              [-74.87613291499991, 10.991562027000043],
              [-74.87609929099995, 10.991622952000057],
              [-74.87603204099992, 10.99171711100007 ],
              [-74.8759815969999 , 10.991772499000092],
              [-74.87595372599992, 10.991788242000041],
              [-74.87587915299991, 10.99205627300006 ],
              [-74.87584434799993, 10.992482667000047],
              [-74.87592436299991, 10.992650888000071],
              [-74.87592078599994, 10.99265973000007 ],
              [-74.87593403199992, 10.99276866300005 ],
              [-74.87595608899994, 10.992820952000045],
              [-74.87602226099995, 10.992929881000066],
              [-74.87606195799992, 10.99298652300007 ],
              [-74.87613694899994, 10.993091090000064],
              [-74.87617483799994, 10.993123841000056],
              [-74.87620752499993, 10.993152087000055],
              [-74.87626045999991, 10.99323051400006 ],
              [-74.87626046799994, 10.99328834000005 ],
              [-74.87626046899993, 10.99329152100006 ],
              [-74.87621637299992, 10.99334381600005 ],
              [-74.87623843699993, 10.993430963000037],
              [-74.87632664599994, 10.993452741000056],
              [-74.87641044399993, 10.99346580300005 ],
              [-74.8764698679999 , 10.993452947000037],
              [-74.87655156999995, 10.99343527700006 ],
              [-74.8766441869999 , 10.993435265000073],
              [-74.87671475799993, 10.993478830000072],
              [-74.87678533299993, 10.993557255000042],
              [-74.87683825699992, 10.993548535000059],
              [-74.87687355399993, 10.993648749000045],
              [-74.87690002699992, 10.99372718300009 ],
              [-74.8769882439999 , 10.993801250000047],
              [-74.87701471499992, 10.993866604000061],
              [-74.87700590799994, 10.993958115000055],
              [-74.87696622099992, 10.994001697000044],
              [-74.87688684499994, 10.99406706800005 ],
              [-74.8768030569999 , 10.994132444000059],
              [-74.87670604999994, 10.994267540000067],
              [-74.87662225799994, 10.994293695000067],
              [-74.87659579299992, 10.994271911000055],
              [-74.87652522199994, 10.99423706400006 ],
              [-74.8764326029999 , 10.994245787000068],
              [-74.87634440799991, 10.994302453000046],
              [-74.87626943899994, 10.99435475100006 ],
              [-74.87623416499991, 10.994415761000084],
              [-74.87609745299994, 10.994472427000062],
              [-74.87594309699995, 10.994542170000045],
              [-74.87588624499995, 10.994552389000091],
              [-74.87579756299994, 10.994568332000085],
              [-74.87554619499991, 10.994716521000043],
              [-74.8754271329999 , 10.994842905000041],
              [-74.87535217099992, 10.994943138000053],
              [-74.87539187599992, 10.995025923000071],
              [-74.87541103799992, 10.995039447000067],
              [-74.8754536269999 , 10.995069491000038],
              [-74.87546245599992, 10.995130495000069],
              [-74.87543343999994, 10.99521244300007 ],
              [-74.87543159499995, 10.995217650000086],
              [-74.87537426199992, 10.99523073000006 ],
              [-74.87529046899994, 10.995252528000037],
              [-74.87519345299995, 10.995339693000062],
              [-74.87522433299995, 10.995422481000048],
              [-74.87532136999994, 10.995461687000045],
              [-74.8754316369999 , 10.995522676000064],
              [-74.87553308299994, 10.995574951000037],
              [-74.87560365299993, 10.995609802000047],
              [-74.87566539599993, 10.99559236400006 ],
              [-74.87571831299994, 10.995540067000093],
              [-74.87575433099994, 10.995488034000061],
              [-74.87577563499991, 10.995457268000052],
              [-74.87587265799993, 10.995418036000046],
              [-74.87604024699993, 10.995400582000059],
              [-74.87623871999995, 10.995435414000042],
              [-74.87643278399992, 10.995500753000044],
              [-74.87652541199992, 10.995574815000055],
              [-74.8766004009999 , 10.995666314000061],
              [-74.87665774699991, 10.995744741000067],
              [-74.87669745799991, 10.99587110300007 ],
              [-74.87675480799993, 10.99600182000006 ],
              [-74.87675043299993, 10.99622405100007 ],
              [-74.87667547699994, 10.996372217000044],
              [-74.8765432049999 , 10.996638044000065],
              [-74.8763932729999 , 10.996781866000049],
              [-74.87630637799992, 10.99684131500004 ],
              [-74.87656071899994, 10.997027101000072],
              [-74.88333232299993, 11.00197348100005 ],
              [-74.87986246999992, 11.00556989000006 ],
              [-74.87963545399992, 11.005805186000089],
              [-74.87953143099992, 11.005913003000046],
              [-74.87934553899993, 11.006105675000072],
              [-74.8784164619999 , 11.007068640000057],
              [-74.87671497399992, 11.008832186000063],
              [-74.87502594699993, 11.010582747000058],
              [-74.8748909219999 , 11.010722692000058],
              [-74.87486739699995, 11.01074707400005 ],
              [-74.87461627899995, 11.011013392000052],
              [-74.87459427899995, 11.01099660400007 ],
              [-74.87459639399992, 11.010994382000092],
              [-74.87435803299991, 11.010816337000051],
              [-74.87435795599993, 11.010816280000085],
              [-74.87371086399992, 11.010332933000086],
              [-74.8735593959999 , 11.01021979300009 ],
              [-74.87164831199993, 11.009037749000072],
              [-74.87164632999992, 11.009036523000077],
              [-74.8716437459999 , 11.009034926000083],
              [-74.87162431399992, 11.009010661000048],
              [-74.87150368199991, 11.009163449000084],
              [-74.87144332199995, 11.009223400000053],
              [-74.87137696399992, 11.00930429400006 ],
              [-74.87126830799991, 11.009409216000051],
              [-74.87119594699993, 11.009508064000045],
              [-74.8709668969999 , 11.009855462000075],
              [-74.87094711399993, 11.009891756000059],
              [-74.87071394799995, 11.010319520000053],
              [-74.87069958399991, 11.010344304000057],
              [-74.87055177499991, 11.010599315000093],
              [-74.8704518909999 , 11.010771643000055],
              [-74.87040901899991, 11.010840667000082],
              [-74.87034960599993, 11.010936322000077],
              [-74.87030123599993, 11.011014198000055],
              [-74.87028776999995, 11.01103250400007 ],
              [-74.87024394899993, 11.011092079000036],
              [-74.87009609599994, 11.01125091700004 ],
              [-74.8700709549999 , 11.011270852000052],
              [-74.86990591199992, 11.01140171700007 ],
              [-74.86983113899993, 11.011461006000047],
              [-74.8698272339999 , 11.011464103000037],
              [-74.86975185099993, 11.01152387400009 ],
              [-74.8697232479999 , 11.011572555000043],
              [-74.86940118199993, 11.01212068700005 ],
              [-74.8693331309999 , 11.01223650500009 ],
              [-74.86932300699993, 11.012263225000083],
              [-74.86916341799991, 11.012684406000062],
              [-74.86915283699994, 11.012712331000046],
              [-74.86902465499992, 11.012991447000047],
              [-74.86899337999995, 11.013059547000069],
              [-74.86892993899994, 11.01313043500005 ],
              [-74.86883836599992, 11.013232757000083],
              [-74.86881627999992, 11.013257434000082],
              [-74.86874811199993, 11.013373322000064],
              [-74.86865673999995, 11.013474211000073],
              [-74.86859772999992, 11.013523704000079],
              [-74.8683864329999 , 11.013664568000081],
              [-74.86805067899991, 11.013850059000049],
              [-74.86779022399992, 11.013999654000088],
              [-74.8677352169999 , 11.014033104000077],
              [-74.8675789269999 , 11.014128145000086],
              [-74.86731766299994, 11.014318026000069],
              [-74.86718060599992, 11.014427957000066],
              [-74.86715465799995, 11.014454715000056],
              [-74.8670435489999 , 11.014569297000037],
              [-74.86693325199991, 11.014677409000058],
              [-74.86689935299995, 11.014710637000064],
              [-74.86682796999992, 11.01476774400004 ],
              [-74.86665807599991, 11.014883386000065],
              [-74.8666292119999 , 11.014907985000093],
              [-74.86638340199994, 11.015117468000085],
              [-74.86636825799991, 11.01513037400008 ],
              [-74.86629116299991, 11.015208896000047],
              [-74.86627117599994, 11.015251726000088],
              [-74.86626657199992, 11.015300067000055],
              [-74.86625975399994, 11.015371651000066],
              [-74.8662708629999 , 11.015485307000063],
              [-74.86627257299995, 11.015502801000082],
              [-74.86627831399994, 11.01556153200005 ],
              [-74.86628065199994, 11.015575096000077],
              [-74.86628545199994, 11.01560293500006 ],
              [-74.86395334299993, 11.015898464000088],
              [-74.86214733199995, 11.016123323000045],
              [-74.86054833399993, 11.016326767000066],
              [-74.8598111739999 , 11.016418441000042],
              [-74.85939535099993, 11.016470153000057],
              [-74.85845321799991, 11.016587318000063],
              [-74.85710066899992, 11.016757001000087],
              [-74.85663456399993, 11.01681547600009 ],
              [-74.85649016299993, 11.016833592000069],
              [-74.85450926199991, 11.017083435000075],
              [-74.85418446499995, 11.017115558000057],
              [-74.85388108399991, 11.01714054200005 ],
              [-74.85354557999995, 11.017158388000041],
              [-74.85327432199995, 11.017158388000041],
              [-74.85288171099995, 11.017149584000038],
              [-74.85267936299994, 11.017135532000054],
              [-74.85258140499991, 11.017128730000081],
              [-74.85233348299994, 11.017111513000089],
              [-74.85206135399994, 11.017076830000065],
              [-74.85187914499994, 11.017053607000037],
              [-74.8517509909999 , 11.017037274000074],
              [-74.85113554999992, 11.016924535000044],
              [-74.85111710199993, 11.01692115600008 ],
              [-74.85044773099992, 11.016743671000086],
              [-74.85036328899992, 11.01672128100006 ],
              [-74.85000205699993, 11.016593900000089],
              [-74.84986818399994, 11.016546692000077],
              [-74.84963993299993, 11.01646620300005 ],
              [-74.84912542199993, 11.016240541000059],
              [-74.84898891199992, 11.016180668000061],
              [-74.84888710999991, 11.01612932800009 ],
              [-74.84854728499994, 11.015957950000086],
              [-74.84762595699993, 11.015459215000078],
              [-74.84705094399993, 11.015150904000052],
              [-74.84602124899993, 11.01459880200008 ],
              [-74.84491337199995, 11.01400108200005 ],
              [-74.8443557299999 , 11.013705549000065],
              [-74.8437065089999 , 11.01336148200005 ],
              [-74.84281419899992, 11.012876805000076],
              [-74.84258951099991, 11.012754761000053],
              [-74.84249505799994, 11.012737635000065],
              [-74.84245864499991, 11.01273103300008 ],
              [-74.84229739599994, 11.012695546000089],
              [-74.84208887699992, 11.012649655000075],
              [-74.84177180499995, 11.012611027000048],
              [-74.84163922199991, 11.012620199000082],
              [-74.84158490699991, 11.012623957000073],
              [-74.84150069499992, 11.012652770000045],
              [-74.84133700899991, 11.012708774000089],
              [-74.84123963199994, 11.012747980000086],
              [-74.84026915299995, 11.01311250100008 ],
              [-74.84020914199994, 11.013132340000084],
              [-74.83983560099995, 11.013312888000087],
              [-74.83966970999995, 11.013433661000079],
              [-74.83963127399994, 11.013463353000077],
              [-74.83926447499994, 11.01385795400006 ],
              [-74.83926076599994, 11.013861944000041],
              [-74.83921135399993, 11.013915101000066],
              [-74.83899550099994, 11.01445526100008 ],
              [-74.83854828699992, 11.015589770000076],
              [-74.83854671499995, 11.015593759000069],
              [-74.83852257699994, 11.015654991000076],
              [-74.83850617199994, 11.015696607000052],
              [-74.83847750599995, 11.01576932800009 ],
              [-74.83839867599994, 11.015969305000056],
              [-74.83834355899995, 11.016106921000073],
              [-74.83813984499994, 11.01661555000004 ],
              [-74.83718033199995, 11.019011248000083],
              [-74.83711446599995, 11.019175699000073],
              [-74.8370679649999 , 11.01929179800004 ],
              [-74.8370600849999 , 11.019311473000073],
              [-74.8367940899999 , 11.019975599000077],
              [-74.83659714299995, 11.020469177000052],
              [-74.8355258759999 , 11.023153925000088],
              [-74.83550337699995, 11.023202069000092],
              [-74.83499023099995, 11.024438814000064],
              [-74.83449054399995, 11.025680893000072],
              [-74.83401465199995, 11.02685396700008 ],
              [-74.83365282099993, 11.027729737000072],
              [-74.83364107699992, 11.027758162000055],
              [-74.83331333199993, 11.028560872000071],
              [-74.83320653399994, 11.028822441000045],
              [-74.83319611699994, 11.02884795500006 ],
              [-74.83291772099994, 11.029518963000044],
              [-74.83266311799991, 11.030051962000073],
              [-74.8324176829999 , 11.030522533000067],
              [-74.83237763599993, 11.030599315000075],
              [-74.83234664999992, 11.03065872500008 ],
              [-74.83195641799995, 11.031353528000068],
              [-74.8315876019999 , 11.031965049000064],
              [-74.83113074499994, 11.032662231000074],
              [-74.83111642099993, 11.03268207800005 ],
              [-74.83073575499992, 11.033209507000038],
              [-74.83039787099995, 11.033644949000063],
              [-74.83007902399993, 11.03405897500005 ],
              [-74.82965072099995, 11.034534867000048],
              [-74.82912247999991, 11.035129732000087],
              [-74.8286608649999 , 11.035600866000038],
              [-74.8281231069999 , 11.036152901000037],
              [-74.82770249799995, 11.036551514000053],
              [-74.82726163199993, 11.037001957000086],
              [-74.82712373299995, 11.037259350000056],
              [-74.82702855499991, 11.037511573000074],
              [-74.82697573099995, 11.03778759000005 ],
              [-74.82694338699991, 11.038029495000046],
              [-74.82693997699994, 11.038054998000064],
              [-74.8269434739999 , 11.038063900000054],
              [-74.82694994499991, 11.038080372000081],
              [-74.82698861199992, 11.038229089000083],
              [-74.82710913999995, 11.038452927000037],
              [-74.82713435399995, 11.038499752000064],
              [-74.82729861999991, 11.038690759000076],
              [-74.82737917599991, 11.038784430000078],
              [-74.82747996699993, 11.038739694000071],
              [-74.82758164199993, 11.03869456600006 ],
              [-74.82777318599994, 11.038609549000057],
              [-74.82781092099992, 11.03859280000006 ],
              [-74.8280565199999 , 11.03844825300007 ],
              [-74.8280567889999 , 11.038448095000092],
              [-74.82828757999994, 11.03831226300008 ],
              [-74.82842875299991, 11.038232665000066],
              [-74.8285671669999 , 11.038154623000082],
              [-74.8289092139999 , 11.03797319000006 ],
              [-74.82938597099991, 11.037720475000071],
              [-74.8302854069999 , 11.037227926000071],
              [-74.83058941799993, 11.03707251500009 ],
              [-74.83085234799995, 11.036945213000081],
              [-74.83168820399993, 11.036484060000078],
              [-74.83171462899992, 11.036468960000093],
              [-74.83218780499993, 11.036211070000093],
              [-74.83223395099992, 11.036185920000037],
              [-74.83224266699995, 11.036181169000088],
              [-74.83224585799991, 11.036179384000093],
              [-74.8328927359999 , 11.035817587000054],
              [-74.83338786299993, 11.035543068000038],
              [-74.83370814699992, 11.035373991000085],
              [-74.83405332099994, 11.035191774000054],
              [-74.83410729799994, 11.035161766000044],
              [-74.83414009899991, 11.035143531000074],
              [-74.83488967399995, 11.034747336000066],
              [-74.83500115599992, 11.03470302900007 ],
              [-74.83508300999995, 11.034678282000073],
              [-74.83527146299991, 11.03462688600007 ],
              [-74.83541803799994, 11.034607850000043],
              [-74.83552998499994, 11.034600040000043],
              [-74.83567359199992, 11.03459928400008 ],
              [-74.83580468499991, 11.034596408000084],
              [-74.83582722599994, 11.034596825000051],
              [-74.83642542199993, 11.034591115000069],
              [-74.83656156299992, 11.034587873000078],
              [-74.8367170059999 , 11.03458788100005 ],
              [-74.83711294799991, 11.034585978000052],
              [-74.83727855899991, 11.034569798000064],
              [-74.83744797599991, 11.034528871000077],
              [-74.83751339299994, 11.034509127000092],
              [-74.83759657399992, 11.034484022000072],
              [-74.83766936399991, 11.034462052000038],
              [-74.83770971699994, 11.034449873000085],
              [-74.83783373599994, 11.034390883000071],
              [-74.8379610369999 , 11.034319499000048],
              [-74.83806335399993, 11.034248115000082],
              [-74.8380800999999 , 11.034234274000084],
              [-74.83817994799995, 11.034151747000067],
              [-74.83829523399993, 11.034041474000048],
              [-74.83834412999994, 11.033994703000076],
              [-74.83863323499992, 11.033692511000083],
              [-74.83882683099995, 11.03349372200006 ],
              [-74.83889278499993, 11.033420042000046],
              [-74.8390171019999 , 11.033293770000057],
              [-74.83918970099995, 11.033118458000047],
              [-74.83923642799994, 11.033076231000052],
              [-74.83944735799992, 11.032913285000063],
              [-74.83956093799992, 11.032836598000074],
              [-74.83979983599994, 11.032697638000059],
              [-74.83991761599992, 11.03263532200009 ],
              [-74.83994193799992, 11.03262413300007 ],
              [-74.84008946499995, 11.032552750000093],
              [-74.84025959599995, 11.032487314000036],
              [-74.8403744389999 , 11.032446438000079],
              [-74.8407992089999 , 11.032335960000069],
              [-74.8411762639999 , 11.032223293000072],
              [-74.84131674499992, 11.032188155000085],
              [-74.84133172799994, 11.03218368000006 ],
              [-74.84171196999995, 11.032086592000041],
              [-74.84197076099991, 11.032003602000088],
              [-74.84244769999992, 11.031877199000064],
              [-74.84285887099992, 11.03177535900005 ],
              [-74.84320485099994, 11.031673658000045],
              [-74.8435506429999 , 11.031581676000087],
              [-74.84358657999991, 11.031572117000053],
              [-74.84372542299991, 11.031533082000067],
              [-74.84406436699993, 11.031437791000087],
              [-74.84411469699995, 11.03142364100006 ],
              [-74.84431519899994, 11.031371579000051],
              [-74.8445049849999 , 11.031322300000056],
              [-74.84478439399993, 11.03125328300007 ],
              [-74.84483174799993, 11.031240196000056],
              [-74.8450565409999 , 11.031190011000092],
              [-74.84533263299994, 11.031101765000074],
              [-74.84604925199994, 11.030921608000085],
              [-74.84641479099992, 11.030820471000084],
              [-74.84641486699991, 11.030820450000078],
              [-74.84641492999992, 11.030820432000041],
              [-74.84703772899991, 11.03064513600009 ],
              [-74.84791527399994, 11.030412900000044],
              [-74.84897333599991, 11.030137075000084],
              [-74.84919812999993, 11.030079366000052],
              [-74.84963684599995, 11.029966740000077],
              [-74.84968910899994, 11.029954720000092],
              [-74.8500258599999 , 11.02987508700005 ],
              [-74.85026903699992, 11.02982374600009 ],
              [-74.8505106259999 , 11.02977999400008 ],
              [-74.85051079099992, 11.02977997000005 ],
              [-74.85051098499991, 11.029779949000044],
              [-74.85076228499992, 11.02976065200005 ],
              [-74.8510031749999 , 11.029750926000077],
              [-74.85103553199991, 11.029753136000068],
              [-74.85117564699993, 11.029749281000079],
              [-74.8514359589999 , 11.029759238000054],
              [-74.8514440639999 , 11.029759548000072],
              [-74.85152400099992, 11.029762606000077],
              [-74.85189330799994, 11.029786165000075],
              [-74.85262806999992, 11.02983303700006 ],
              [-74.85281455599994, 11.029854149000073],
              [-74.85303162699995, 11.029878723000081],
              [-74.85329051199994, 11.029924409000046],
              [-74.85377487499994, 11.030043589000059],
              [-74.85389394399994, 11.030072887000074],
              [-74.8541443869999 , 11.030133179000075],
              [-74.85440790699994, 11.030196619000037],
              [-74.85449108799992, 11.030217699000048],
              [-74.8546858279999 , 11.030267051000067],
              [-74.85518075599992, 11.030390783000087],
              [-74.8552178889999 , 11.030399602000045],
              [-74.85559200799992, 11.030488456000057],
              [-74.85616736799994, 11.03062510400008 ],
              [-74.85624675499992, 11.030643958000041],
              [-74.8573249019999 , 11.03090470300009 ],
              [-74.85764357199992, 11.030981772000075],
              [-74.85798919199993, 11.031070956000065],
              [-74.85827341399994, 11.031144296000036],
              [-74.85868577199994, 11.031243248000067],
              [-74.85930289899994, 11.03139133700006 ],
              [-74.85937270499994, 11.031404716000054],
              [-74.85937437099994, 11.031399686000043],
              [-74.8593749609999 , 11.031397904000073],
              [-74.85947192799995, 11.031105220000086],
              [-74.85956468299992, 11.030825248000042],
              [-74.85962806799995, 11.030633924000085],
              [-74.85967453699993, 11.030493662000083],
              [-74.85990986599995, 11.029840628000045],
              [-74.86005795299991, 11.02942968700006 ],
              [-74.8602588519999 , 11.029515053000068],
              [-74.86071570799993, 11.029308039000057],
              [-74.8608418199999 , 11.029234276000068],
              [-74.86097268999993, 11.029153374000089],
              [-74.86117970299995, 11.028896393000082],
              [-74.8612309799999 , 11.02886648100008 ],
              [-74.86128972299991, 11.028860689000055],
              [-74.86138429899995, 11.028837045000046],
              [-74.86151469299995, 11.02879992600009 ],
              [-74.86158389799994, 11.02876754600004 ],
              [-74.86182392699993, 11.028578551000066],
              [-74.86199390299993, 11.02842019700006 ],
              [-74.8623532819999 , 11.028103486000077],
              [-74.8631108909999 , 11.02744127200009 ],
              [-74.8633731409999 , 11.027234927000052],
              [-74.86355282399995, 11.02701899300007 ],
              [-74.86360623599995, 11.026884640000048],
              [-74.86366936299993, 11.026759882000079],
              [-74.86425680499991, 11.025688807000051],
              [-74.86472391799992, 11.024842125000077],
              [-74.86474594599991, 11.024874328000067],
              [-74.86480203399992, 11.024956322000037],
              [-74.86552924099993, 11.026019421000058],
              [-74.86604341799995, 11.025569329000064],
              [-74.86613409499995, 11.02548995400008 ],
              [-74.86661921199993, 11.02506530100004 ],
              [-74.86683437499994, 11.024899884000092],
              [-74.86690451299995, 11.025021265000078],
              [-74.86695929599995, 11.024924869000074],
              [-74.8669947859999 , 11.024869299000045],
              [-74.86703144099994, 11.024811904000046],
              [-74.86715833799991, 11.024696916000039],
              [-74.8671693419999 , 11.024692120000054],
              [-74.86725113699993, 11.024656466000067],
              [-74.86731062399991, 11.024601738000058],
              [-74.8673899449999 , 11.024529616000052],
              [-74.86740405099994, 11.024498011000048],
              [-74.86746069899993, 11.024371089000056],
              [-74.8674705489999 , 11.024349019000056],
              [-74.86748205399994, 11.024323242000037],
              [-74.86752321299991, 11.024251096000057],
              [-74.86758471799993, 11.024143287000072],
              [-74.86764640899992, 11.024010202000056],
              [-74.86765695399993, 11.023987453000075],
              [-74.86765895799994, 11.02398313000009 ],
              [-74.86766376599991, 11.02397275800007 ],
              [-74.86768632099995, 11.023942988000044],
              [-74.86771006299995, 11.023911652000038],
              [-74.8677512669999 , 11.023857263000082],
              [-74.86775682199993, 11.02384993600009 ],
              [-74.86775778799995, 11.023848662000091],
              [-74.86780309399995, 11.023791147000054],
              [-74.8678128489999 , 11.023778763000053],
              [-74.86781936699992, 11.023770489000071],
              [-74.86784305399993, 11.023740418000045],
              [-74.86791403099994, 11.023716057000058],
              [-74.86792911799995, 11.023702025000091],
              [-74.86807138099994, 11.023569716000054],
              [-74.86808030799995, 11.023561414000085],
              [-74.86808781099995, 11.023554434000062],
              [-74.86817232899995, 11.023475826000038],
              [-74.86817815299992, 11.023470410000073],
              [-74.86818974199991, 11.023459630000048],
              [-74.86821746599992, 11.023433839000063],
              [-74.86824652899992, 11.023414787000092],
              [-74.8682613869999 , 11.023400576000085],
              [-74.86826376399995, 11.02339830200009 ],
              [-74.86829149499994, 11.023371779000058],
              [-74.86831080499991, 11.023353310000061],
              [-74.86831237199993, 11.023351811000055],
              [-74.86831300499995, 11.023351206000086],
              [-74.86831558099993, 11.023348742000053],
              [-74.8683218999999 , 11.023343923000084],
              [-74.86833988899991, 11.023330205000093],
              [-74.86835893499995, 11.023315680000053],
              [-74.86836168399992, 11.02331313500008 ],
              [-74.86836289899992, 11.023312011000087],
              [-74.86836597899992, 11.023309159000064],
              [-74.86841615499992, 11.023262708000061],
              [-74.8684587379999 , 11.023223277000056],
              [-74.86846300899992, 11.023219322000045],
              [-74.86848461099993, 11.02319931900007 ],
              [-74.86849814399994, 11.023189038000055],
              [-74.86851166699995, 11.023178766000058],
              [-74.86854028099992, 11.023154544000079],
              [-74.86857962599993, 11.023121238000044],
              [-74.86859158999994, 11.02311162400008 ],
              [-74.86863937099992, 11.023071432000052],
              [-74.86864100899993, 11.023070054000073],
              [-74.8686565399999 , 11.023056734000079],
              [-74.8686660809999 , 11.023039788000062],
              [-74.86867176299995, 11.023029695000048],
              [-74.86867560399992, 11.023022873000059],
              [-74.86874423499995, 11.022969680000074],
              [-74.86874883399992, 11.022966101000065],
              [-74.8687553229999 , 11.022961050000049],
              [-74.86878843799991, 11.022935276000055],
              [-74.86879809499993, 11.022927664000065],
              [-74.86881347799994, 11.022915539000053],
              [-74.8688521919999 , 11.022884980000072],
              [-74.86887988599995, 11.022862364000048],
              [-74.86888437999994, 11.022860956000045],
              [-74.86889262599993, 11.02285837100004 ],
              [-74.86896552899992, 11.022910882000076],
              [-74.86897007399995, 11.02291415600007 ],
              [-74.86897243999994, 11.022910678000073],
              [-74.86897449799994, 11.022905627000057],
              [-74.86918516199995, 11.022612680000066],
              [-74.86917630799991, 11.022606507000091],
              [-74.86914186299992, 11.022582493000073],
              [-74.86913601499992, 11.022578416000044],
              [-74.86912811499991, 11.022572908000086],
              [-74.86912837299991, 11.022572604000061],
              [-74.86913794399993, 11.022561339000049],
              [-74.86914787099994, 11.022547735000046],
              [-74.86919194899991, 11.022487267000088],
              [-74.8692370139999 , 11.022425464000037],
              [-74.86924692499991, 11.022411866000084],
              [-74.86926058199992, 11.022398872000053],
              [-74.86931567799991, 11.022325785000078],
              [-74.86936934699992, 11.022254579000048],
              [-74.86937367999991, 11.022240637000039],
              [-74.86942917999994, 11.022166355000081],
              [-74.86943295399993, 11.022161305000054],
              [-74.86945413799992, 11.022132956000064],
              [-74.8694593699999 , 11.022125955000092],
              [-74.86948308599995, 11.022094219000053],
              [-74.86948741099991, 11.022092886000053],
              [-74.8694916959999 , 11.022091566000086],
              [-74.86949364399993, 11.022090966000064],
              [-74.86953957999992, 11.022031363000053],
              [-74.8695663009999 , 11.021996692000073],
              [-74.86957358799992, 11.021987237000076],
              [-74.86959231299994, 11.021962941000083],
              [-74.86961847999993, 11.02192898900006 ],
              [-74.8696607199999 , 11.021874181000044],
              [-74.86966166899992, 11.021872558000041],
              [-74.8696803119999 , 11.02184066500007 ],
              [-74.86969066699993, 11.021822949000068],
              [-74.86978269999992, 11.02166550700008 ],
              [-74.86980975499995, 11.021619224000062],
              [-74.86983603199991, 11.021574272000066],
              [-74.86989572499994, 11.021472153000047],
              [-74.86989656799994, 11.021470711000063],
              [-74.86993104599992, 11.021411729000079],
              [-74.86999735099994, 11.02129829900008 ],
              [-74.87000295399992, 11.021301823000044],
              [-74.87001540599994, 11.021309655000039],
              [-74.87001549799993, 11.02130971300005 ],
              [-74.87002090999994, 11.021313216000067],
              [-74.87002381999991, 11.021315099000049],
              [-74.8700288359999 , 11.021318290000067],
              [-74.87005487899995, 11.02133486200006 ],
              [-74.87007857399993, 11.02134993900006 ],
              [-74.87010500799994, 11.021366759000045],
              [-74.87012114399994, 11.021377026000039],
              [-74.87012137199991, 11.021377173000076],
              [-74.87013731999991, 11.021387320000088],
              [-74.87014290699994, 11.021390875000066],
              [-74.87014994999993, 11.021395356000085],
              [-74.87017507699994, 11.021411344000057],
              [-74.87018650399995, 11.021418615000073],
              [-74.87018778099991, 11.021419428000058],
              [-74.8701879539999 , 11.021419858000058],
              [-74.87019012499991, 11.021421284000041],
              [-74.87019249899993, 11.021422843000039],
              [-74.8701932589999 , 11.021423342000048],
              [-74.87021164399994, 11.021435416000088],
              [-74.870298        , 11.02149213000007 ],
              [-74.87029994199992, 11.021493406000047],
              [-74.87030476799993, 11.021496576000061],
              [-74.87031082099992, 11.021498369000085],
              [-74.87031401599995, 11.021500593000042],
              [-74.87031774699994, 11.021503191000079],
              [-74.8703516889999 , 11.021526819000087],
              [-74.87040501899992, 11.021563943000046],
              [-74.87041130599994, 11.021568320000085],
              [-74.87042240599993, 11.02157691900004 ],
              [-74.8704421779999 , 11.021592235000071],
              [-74.87046612099994, 11.021610905000045],
              [-74.87047517199994, 11.02161796300004 ],
              [-74.87047631899992, 11.021618857000078],
              [-74.87048277899993, 11.021620536000057],
              [-74.87048544399994, 11.021622390000061],
              [-74.87052034099992, 11.021646670000052],
              [-74.87055650899993, 11.021671833000084],
              [-74.87063677599991, 11.02172887000006 ],
              [-74.87063743999994, 11.02172936800008 ],
              [-74.87063864099991, 11.021730269000045],
              [-74.87064284299993, 11.021733419000043],
              [-74.87071970699992, 11.021791050000047],
              [-74.87075723799995, 11.021819748000041],
              [-74.8707813719999 , 11.021837820000087],
              [-74.87078949699992, 11.021843904000093],
              [-74.87079364899995, 11.02184701300007 ],
              [-74.87079817599994, 11.021850403000087],
              [-74.87080354699992, 11.021854425000072],
              [-74.87080634699993, 11.021856361000061],
              [-74.87081119599992, 11.021859714000072],
              [-74.87084419799993, 11.02187919000005 ],
              [-74.87086714499992, 11.021892733000072],
              [-74.87087796499992, 11.021899119000068],
              [-74.87089027999991, 11.021906387000058],
              [-74.87089137999993, 11.021907036000073],
              [-74.87090653299992, 11.021917079000048],
              [-74.8709270629999 , 11.021930687000065],
              [-74.87093738499993, 11.02193752800008 ],
              [-74.87093964799993, 11.02193916400006 ],
              [-74.87094092599995, 11.021940088000065],
              [-74.87094655099992, 11.021944156000075],
              [-74.87094689799994, 11.021944407000092],
              [-74.87094708899991, 11.021944545000053],
              [-74.87095632599994, 11.021951224000077],
              [-74.87097128399995, 11.021962040000062],
              [-74.87097195899992, 11.021962518000066],
              [-74.87098678399991, 11.021973023000044],
              [-74.87098715799993, 11.021973288000083],
              [-74.87099096199995, 11.021975983000061],
              [-74.87099333999993, 11.02197766800009 ],
              [-74.87099912899993, 11.021981770000082],
              [-74.87099995999995, 11.021982294000054],
              [-74.87101658599994, 11.021992786000055],
              [-74.87101844999995, 11.021993962000067],
              [-74.87102092099991, 11.021995521000065],
              [-74.87103780399991, 11.022002747000045],
              [-74.87103907999995, 11.022002575000045],
              [-74.87104785999992, 11.022008262000043],
              [-74.87104914199995, 11.022009092000076],
              [-74.87105341199992, 11.022011857000052],
              [-74.87105374399994, 11.022012072000052],
              [-74.87105504999994, 11.022012918000087],
              [-74.8710589989999 , 11.022015475000046],
              [-74.87106275199994, 11.022017906000087],
              [-74.87106475599995, 11.02201920400006 ],
              [-74.8710666579999 , 11.022020436000048],
              [-74.87106802599993, 11.022021322000057],
              [-74.87107077299993, 11.022023101000059],
              [-74.87108055899995, 11.02202943900005 ],
              [-74.87108014499995, 11.022030056000062],
              [-74.8710794719999 , 11.022031060000074],
              [-74.87110888199993, 11.022050318000083],
              [-74.87112079399992, 11.022058118000075],
              [-74.8711237999999 , 11.022060087000057],
              [-74.87112532899994, 11.022061088000044],
              [-74.87112614199992, 11.022061621000091],
              [-74.87114126999995, 11.022071662000087],
              [-74.87118469899991, 11.022100485000067],
              [-74.87119642099992, 11.022108003000085],
              [-74.8711999539999 , 11.022110269000052],
              [-74.87120198299993, 11.022111571000039],
              [-74.8712027599999 , 11.022112069000059],
              [-74.87122959099992, 11.02212927700009 ],
              [-74.87123028099995, 11.022129720000066],
              [-74.87123059699991, 11.02212998200008 ],
              [-74.87124653699993, 11.022143165000045],
              [-74.87124952199991, 11.022145634000083],
              [-74.87126710399991, 11.022160176000057],
              [-74.87129167699993, 11.022180500000047],
              [-74.87130066799995, 11.02218793600008 ],
              [-74.87130729199993, 11.022193414000071],
              [-74.8713139859999 , 11.022198951000064],
              [-74.87131934199994, 11.022203381000054],
              [-74.8713414689999 , 11.022221682000065],
              [-74.87135024899993, 11.022228944000062],
              [-74.87137919499992, 11.022252885000057],
              [-74.87138335199995, 11.022256217000063],
              [-74.87138685899993, 11.022259027000075],
              [-74.87139469399995, 11.022265307000055],
              [-74.87139820399995, 11.022268120000092],
              [-74.87141006399992, 11.022277625000072],
              [-74.8714112479999 , 11.02227857400004 ],
              [-74.87144453299993, 11.022305251000091],
              [-74.87145304699993, 11.022312075000059],
              [-74.87145953899994, 11.022317798000074],
              [-74.87153381399992, 11.022383281000089],
              [-74.87154324499994, 11.022391595000045],
              [-74.8715682799999 , 11.022406543000045],
              [-74.87159815399991, 11.022424380000075],
              [-74.8715979779999 , 11.02242459900009 ],
              [-74.8715861629999 , 11.022439447000068],
              [-74.8715558849999 , 11.022477498000057],
              [-74.87143412499995, 11.022630517000039],
              [-74.87113822799995, 11.023002377000068],
              [-74.87063713299995, 11.023619541000073],
              [-74.87011181799994, 11.024227146000044],
              [-74.86974801999992, 11.024705360000041],
              [-74.86843152099993, 11.026325068000062],
              [-74.86839319499995, 11.026372221000088],
              [-74.86818777299993, 11.026624954000056],
              [-74.86863713399993, 11.026696986000047],
              [-74.86894807899995, 11.026764918000083],
              [-74.8689541729999 , 11.026766064000071],
              [-74.86903802599994, 11.026781116000052],
              [-74.8691217029999 , 11.026800526000045],
              [-74.8695850659999 , 11.026908008000078],
              [-74.8697929569999 , 11.026952183000049],
              [-74.87018850399994, 11.027062237000052],
              [-74.87023182999991, 11.02707034000008 ],
              [-74.87029026499994, 11.027081269000064],
              [-74.8703653359999 , 11.027092421000077],
              [-74.87040077199993, 11.027097685000058],
              [-74.87045444799992, 11.027105658000039],
              [-74.87054308299992, 11.027125289000082],
              [-74.87058249299992, 11.02713783900009 ],
              [-74.87070935499992, 11.02717823800009 ],
              [-74.87071784499994, 11.027180294000061],
              [-74.87076950999995, 11.027191832000085],
              [-74.87085726599992, 11.027211431000069],
              [-74.87093336799995, 11.027240377000055],
              [-74.87093354299992, 11.02724048600004 ],
              [-74.87101837999995, 11.027293636000081],
              [-74.87108503099995, 11.027342972000042],
              [-74.87111282399991, 11.027363545000071],
              [-74.87122955699994, 11.027465076000055],
              [-74.87123618399994, 11.02750024800008 ],
              [-74.8712377139999 , 11.027508370000078],
              [-74.87124192999994, 11.02753074900005 ],
              [-74.87128452099995, 11.027571203000036],
              [-74.87148068599993, 11.027757525000084],
              [-74.87149927599995, 11.027775182000084],
              [-74.87158180499995, 11.027865229000042],
              [-74.87166477199992, 11.02794539100006 ],
              [-74.87167091499992, 11.02795132500006 ],
              [-74.87172443499992, 11.027997137000057],
              [-74.8719306189999 , 11.02821496200005 ],
              [-74.87194357499993, 11.028230199000063],
              [-74.87199325699993, 11.028288626000062],
              [-74.87203426899993, 11.028347718000077],
              [-74.87206979899992, 11.028398910000078],
              [-74.87210180999995, 11.028457702000082],
              [-74.87212692599991, 11.028510648000065],
              [-74.87215900799993, 11.02857828000009 ],
              [-74.87220630399992, 11.02866735400005 ],
              [-74.87222390399995, 11.028709720000052],
              [-74.87223559299991, 11.02873457800007 ],
              [-74.87223978999992, 11.028741349000086],
              [-74.87224623599991, 11.028751748000047],
              [-74.87215581599992, 11.028925449000042],
              [-74.87207503099995, 11.02913155300007 ],
              [-74.87207269599992, 11.029137511000044],
              [-74.8720495199999 , 11.029196679000052],
              [-74.87204945999991, 11.029196833000071],
              [-74.8720170819999 , 11.029253527000037],
              [-74.87193907799991, 11.029390112000044],
              [-74.8718607109999 , 11.02950472300006 ],
              [-74.87178635299995, 11.029581026000074],
              [-74.87173247599992, 11.029624487000092],
              [-74.87169163499993, 11.029662175000055],
              [-74.87196351799992, 11.03003726500009 ],
              [-74.87221160799993, 11.030379529000072],
              [-74.87226444299995, 11.030449307000083],
              [-74.87290168899995, 11.03129090200008 ],
              [-74.87290949999993, 11.031301218000067],
              [-74.8729504499999 , 11.031288264000068],
              [-74.87299470799991, 11.031285409000077],
              [-74.8730221539999 , 11.031290555000055],
              [-74.87304039299994, 11.03129397500004 ],
              [-74.87310892199991, 11.03131253500004 ],
              [-74.8732564899999 , 11.03140369600004 ],
              [-74.87328727399995, 11.031428026000071],
              [-74.8734215639999 , 11.03153416200007 ],
              [-74.87341972799993, 11.031561706000048],
              [-74.8734184349999 , 11.031581092000067],
              [-74.8734126409999 , 11.031668007000064],
              [-74.87315684899994, 11.032893430000058],
              [-74.87299028699994, 11.03369947200008 ],
              [-74.87298660999994, 11.033716670000047],
              [-74.87296942299992, 11.03379706000004 ],
              [-74.8729465099999 , 11.03390423400009 ],
              [-74.87294487499992, 11.033911881000051],
              [-74.87294252099991, 11.033913002000077],
              [-74.87280327499991, 11.03397932300004 ],
              [-74.87275165299991, 11.03400391000008 ],
              [-74.87275067199994, 11.034004377000088],
              [-74.87272644399991, 11.034020541000075],
              [-74.87272023399993, 11.034030647000066],
              [-74.87260509099991, 11.034218036000084],
              [-74.8725360869999 , 11.034365563000051],
              [-74.87247184199992, 11.034584473000052],
              [-74.87239807799995, 11.034796245000052],
              [-74.87233859199995, 11.034965187000068],
              [-74.87222437799994, 11.035195995000038],
              [-74.87209112799991, 11.035502945000076],
              [-74.87202450299992, 11.035652851000066],
              [-74.8719388419999 , 11.035864623000066],
              [-74.87190403799991, 11.03595868800005 ],
              [-74.87185080199993, 11.036102569000093],
              [-74.87177465999991, 11.036240578000047],
              [-74.8717413469999 , 11.036342895000075],
              [-74.8717127939999 , 11.036407140000051],
              [-74.87165092799995, 11.036590359000058],
              [-74.87161523599991, 11.036714091000078],
              [-74.87158906199994, 11.036794992000068],
              [-74.8715010219999 , 11.03699486700009 ],
              [-74.87146770899994, 11.037116220000087],
              [-74.87142963799994, 11.03718522400004 ],
              [-74.87140742199995, 11.03722965500009 ],
              [-74.8713558739999 , 11.037332751000065],
              [-74.8713035259999 , 11.037449344000038],
              [-74.87117979399994, 11.037758674000088],
              [-74.87103702699994, 11.038132250000046],
              [-74.87095136599993, 11.038336883000056],
              [-74.87085618799995, 11.038579588000061],
              [-74.87078718299995, 11.038765186000091],
              [-74.8707586299999 , 11.038898436000068],
              [-74.87073007599992, 11.039034065000067],
              [-74.87062775899994, 11.039512337000076],
              [-74.87060872399991, 11.039771698000038],
              [-74.87061348299994, 11.039866877000065],
              [-74.8706372769999 , 11.039962055000046],
              [-74.87066583099994, 11.040031059000057],
              [-74.87079908099992, 11.04019048300006 ],
              [-74.87094422799993, 11.040399876000038],
              [-74.87112030799994, 11.040611648000038],
              [-74.87117979399994, 11.04070206700004 ],
              [-74.8712583169999 , 11.040940013000068],
              [-74.87129876699993, 11.041113714000062],
              [-74.87138442799994, 11.041294553000057],
              [-74.87144391399994, 11.041415906000054],
              [-74.8715152979999 , 11.041539638000074],
              [-74.87154385199995, 11.041582468000058],
              [-74.87154623099991, 11.04163243700009 ],
              [-74.8715605079999 , 11.041738323000061],
              [-74.8715862059999 , 11.041871097000069],
              [-74.87161761499993, 11.041935342000045],
              [-74.8716376029999 , 11.041991021000058],
              [-74.87165616199991, 11.042056694000053],
              [-74.87166472799993, 11.042135217000066],
              [-74.87165473499994, 11.04221373900009 ],
              [-74.87162903699993, 11.042293689000076],
              [-74.87159191699993, 11.042379349000043],
              [-74.87151482299993, 11.042466438000076],
              [-74.87145200499992, 11.04252211700009 ],
              [-74.87136777199993, 11.042586363000055],
              [-74.87122072099993, 11.04267630600009 ],
              [-74.87090234899995, 11.042867615000091],
              [-74.87070532999991, 11.042973263000079],
              [-74.87064393999992, 11.043013238000071],
              [-74.87057826699993, 11.043067490000055],
              [-74.87049688899992, 11.04312888000004 ],
              [-74.87044834799991, 11.043184559000053],
              [-74.87039124099994, 11.043263081000077],
              [-74.87034983799992, 11.043324471000062],
              [-74.87032556799994, 11.043388717000084],
              [-74.87031271899991, 11.043442969000068],
              [-74.87029130399992, 11.043550044000085],
              [-74.87026274999994, 11.043699950000075],
              [-74.8702284869999 , 11.043780129000083],
              [-74.87027839899991, 11.043793638000068],
              [-74.87031134999995, 11.043802557000049],
              [-74.87031214099994, 11.043803208000043],
              [-74.87038986499994, 11.04386717500006 ],
              [-74.87057304899992, 11.043867155000044],
              [-74.87065811199994, 11.04397054900005 ],
              [-74.87062541799992, 11.044106270000043],
              [-74.87056689499991, 11.044151242000055],
              [-74.8705076679999 , 11.044196764000048],
              [-74.8704749609999 , 11.044242005000058],
              [-74.87053384699993, 11.044319552000047],
              [-74.87056002799994, 11.04438417700004 ],
              [-74.87054108699994, 11.044444077000037],
              [-74.87052732899991, 11.04448758500007 ],
              [-74.8705206699999 , 11.044497455000055],
              [-74.87042267099991, 11.044642706000047],
              [-74.87034437699992, 11.044773625000062],
              [-74.87033763499994, 11.044784899000092],
              [-74.8703545109999 , 11.044803649000073],
              [-74.87038997999991, 11.044843058000083],
              [-74.87047503399992, 11.04485597300004 ],
              [-74.8704078799999 , 11.04498869400004 ],
              [-74.87040308799993, 11.044998164000049],
              [-74.87037200899994, 11.045037247000039],
              [-74.8702591789999 , 11.045179139000084],
              [-74.87019377699994, 11.045353642000066],
              [-74.87012837099991, 11.045489369000052],
              [-74.87008884499994, 11.045542009000087],
              [-74.87004235899991, 11.04560391700005 ],
              [-74.86997792099993, 11.045689734000064],
              [-74.86996806899992, 11.045732435000048],
              [-74.86990018099993, 11.046026639000047],
              [-74.86989291599991, 11.04605812400007 ],
              [-74.86988115699995, 11.046148815000038],
              [-74.86986023499992, 11.046310178000056],
              [-74.86993874399991, 11.046355409000057],
              [-74.87009326499992, 11.046438642000055],
              [-74.87015465899992, 11.046471712000084],
              [-74.87028510899995, 11.046471698000062],
              [-74.87033784399995, 11.046471693000058],
              [-74.87053115399993, 11.046364244000074],
              [-74.87054293599994, 11.04635769500004 ],
              [-74.87054718599995, 11.046355333000065],
              [-74.87058421899991, 11.046357690000093],
              [-74.87074999899994, 11.04636824000005 ],
              [-74.87076311099992, 11.046371581000074],
              [-74.8708368529999 , 11.04639036900005 ],
              [-74.87089421399992, 11.046404984000048],
              [-74.87095281799992, 11.046419915000058],
              [-74.87095843799995, 11.04647536300007 ],
              [-74.87095936799994, 11.046484542000087],
              [-74.87097196399992, 11.046476577000078],
              [-74.8711229139999 , 11.046381119000046],
              [-74.87113120299995, 11.04638357400006 ],
              [-74.87119915099993, 11.046403700000042],
              [-74.8712537639999 , 11.046419877000062],
              [-74.87126552099994, 11.046535878000043],
              [-74.87126686399995, 11.046549132000052],
              [-74.87133850499993, 11.046632987000066],
              [-74.87141726699991, 11.046725177000042],
              [-74.87144353399992, 11.046755923000092],
              [-74.87152136599991, 11.046892567000043],
              [-74.87156132299992, 11.046962717000042],
              [-74.87157613199992, 11.04697636700007 ],
              [-74.87165946999994, 11.047053184000049],
              [-74.87177069599994, 11.047104871000045],
              [-74.8720127549999 , 11.047046675000047],
              [-74.87202590399994, 11.047032756000078],
              [-74.87206989299995, 11.046986189000052],
              [-74.87210433699994, 11.046949725000047],
              [-74.87211739899993, 11.046807539000042],
              [-74.87213694499991, 11.046708178000074],
              [-74.87215274499994, 11.046627856000043],
              [-74.87216316699994, 11.046574872000065],
              [-74.87218790299994, 11.046532657000057],
              [-74.87223511999991, 11.04645207100009 ],
              [-74.87226126599995, 11.04629049600004 ],
              [-74.87228372699991, 11.046262754000054],
              [-74.87231770299991, 11.046220791000053],
              [-74.8723397629999 , 11.046193544000062],
              [-74.87233974199995, 11.04605136300006 ],
              [-74.87230496399991, 11.045997388000046],
              [-74.87229393899992, 11.04598027700007 ],
              [-74.87230023299992, 11.045941385000049],
              [-74.87230195899991, 11.045930721000047],
              [-74.87232008799992, 11.045818703000066],
              [-74.87241820999992, 11.045721751000087],
              [-74.87249016499993, 11.045637725000063],
              [-74.87254248999994, 11.045540776000053],
              [-74.87256305799991, 11.045464546000062],
              [-74.87256864799991, 11.045443830000067],
              [-74.87261651299991, 11.045399023000073],
              [-74.87269293399993, 11.045327485000087],
              [-74.87271873599991, 11.04531686100006 ],
              [-74.87277143699993, 11.04529516100007 ],
              [-74.87282794499993, 11.045432510000069],
              [-74.87285463599994, 11.045497389000047],
              [-74.87285651699995, 11.04550196100007 ],
              [-74.87286183999993, 11.045503713000073],
              [-74.87294253399995, 11.045530273000054],
              [-74.87295465599993, 11.045534260000068],
              [-74.87303317399994, 11.045618269000045],
              [-74.87303318499994, 11.04569586100007 ],
              [-74.87303318799991, 11.045721674000049],
              [-74.87303320199993, 11.045844465000073],
              [-74.8730321939999 , 11.045877483000083],
              [-74.8730266899999 , 11.046057738000059],
              [-74.87293079599993, 11.046207353000057],
              [-74.8729024079999 , 11.046251639000047],
              [-74.8728869869999 , 11.046273227000086],
              [-74.87274542499995, 11.046471394000037],
              [-74.8726093379999 , 11.046596081000075],
              [-74.87243421199992, 11.046756539000057],
              [-74.87238563799991, 11.046801045000052],
              [-74.8723725779999 , 11.04699492800006 ],
              [-74.87240324099992, 11.047047922000047],
              [-74.8724390889999 , 11.047081166000055],
              [-74.8724344609999 , 11.047078199000055],
              [-74.87243347399993, 11.047079696000083],
              [-74.87214633799994, 11.047277191000092],
              [-74.87252317699995, 11.047516649000045],
              [-74.8728323229999 , 11.047275378000052],
              [-74.87311933899991, 11.047034161000056],
              [-74.87325234499991, 11.047121247000064],
              [-74.87325281999995, 11.047296126000049],
              [-74.87316472399993, 11.047449373000063],
              [-74.8730541999999 , 11.047493384000063],
              [-74.87294397199992, 11.047646692000058],
              [-74.87272286299992, 11.04771285600009 ],
              [-74.87272351899992, 11.047953309000093],
              [-74.87254690599991, 11.048106792000056],
              [-74.87243679699992, 11.048303820000058],
              [-74.87265808199993, 11.04830323300007 ],
              [-74.87283499199992, 11.048259047000045],
              [-74.87303396899995, 11.04819294300006 ],
              [-74.87327714499992, 11.048104858000045],
              [-74.87363916699991, 11.047935118000055],
              [-74.87374772299995, 11.04815254600004 ],
              [-74.87370238799991, 11.048273863000077],
              [-74.87348934699992, 11.048378658000047],
              [-74.87331534799995, 11.048430760000088],
              [-74.87322314799991, 11.048447679000049],
              [-74.87305267999994, 11.04849127500006 ],
              [-74.87292498699992, 11.048511577000056],
              [-74.8727156949999 , 11.048564141000043],
              [-74.87256939499991, 11.04859775500006 ],
              [-74.87240430499992, 11.04864757200005 ],
              [-74.87223669299993, 11.048691374000043],
              [-74.87202089199991, 11.048711395000055],
              [-74.87183991699993, 11.048736767000037],
              [-74.87158083499992, 11.048785906000091],
              [-74.87142820299994, 11.048824932000059],
              [-74.87124234099991, 11.048876269000061],
              [-74.87101678199991, 11.04894818300005 ],
              [-74.87069031199991, 11.04903355600004 ],
              [-74.87040680699994, 11.04913360800009 ],
              [-74.87012809299995, 11.049207723000052],
              [-74.8698986309999 , 11.049291097000037],
              [-74.86954753999993, 11.049389427000051],
              [-74.86942171899994, 11.049421600000073],
              [-74.8691565009999 , 11.04947040400009 ],
              [-74.8690074619999 , 11.04949804000006 ],
              [-74.86878606699992, 11.049549891000083],
              [-74.8686022679999 , 11.04961019700005 ],
              [-74.86839419199993, 11.049680523000063],
              [-74.86826117499993, 11.049729740000089],
              [-74.86790055199992, 11.049874242000044],
              [-74.86769549699994, 11.049982791000048],
              [-74.8675490139999 , 11.05005448500009 ],
              [-74.8673236539999 , 11.050158722000049],
              [-74.86705518299993, 11.050248287000045],
              [-74.86689066299994, 11.05032460700005 ],
              [-74.86662421999995, 11.05042602900005 ],
              [-74.86644502799993, 11.050501361000045],
              [-74.86631901999993, 11.050533579000046],
              [-74.86617165299992, 11.050576016000036],
              [-74.86585317899994, 11.050703146000046],
              [-74.865698        , 11.050771376000057],
              [-74.86555831899994, 11.050828977000037],
              [-74.86537801999992, 11.050877943000046],
              [-74.86524064999992, 11.050903571000049],
              [-74.8650044279999 , 11.050951612000063],
              [-74.86481587799994, 11.051032195000062],
              [-74.86465390699993, 11.05111168700006 ],
              [-74.86440962599994, 11.05118845000004 ],
              [-74.86425188399994, 11.051209761000052],
              [-74.86405953899992, 11.051260873000047],
              [-74.86381710899991, 11.051311446000057],
              [-74.86344751599995, 11.051326981000045],
              [-74.86315493799992, 11.05137705900006 ],
              [-74.86277861099995, 11.051482814000053],
              [-74.86236076499995, 11.051550648000045],
              [-74.86194764699991, 11.05163344500005 ],
              [-74.86164941399994, 11.051677337000058],
              [-74.86146227899991, 11.051734717000045],
              [-74.8612468309999 , 11.051775519000046],
              [-74.86104503799993, 11.05179092800006 ],
              [-74.86093302499995, 11.051789086000042],
              [-74.8607626559999 , 11.05181837300006 ],
              [-74.86051038999994, 11.051877150000053],
              [-74.86037014899995, 11.051937787000043],
              [-74.86019314799995, 11.05201637500005 ],
              [-74.86005080599995, 11.052065170000049],
              [-74.85986897099991, 11.052128807000088],
              [-74.8596285129999 , 11.052226509000093],
              [-74.85938653499994, 11.052344608000055],
              [-74.85925690199991, 11.052420623000046],
              [-74.85892535499994, 11.052591189000054],
              [-74.85871119099994, 11.052731731000051],
              [-74.85845311299994, 11.052866360000053],
              [-74.8582685689999 , 11.052965035000057],
              [-74.85806866299993, 11.053071450000061],
              [-74.8578812099999 , 11.053128926000056],
              [-74.85758250799995, 11.053252047000058],
              [-74.85737156699992, 11.053387047000058],
              [-74.85716189699991, 11.053504567000061],
              [-74.85689020599995, 11.053620804000047],
              [-74.85671334499995, 11.053693701000043],
              [-74.85637873199994, 11.053820322000092],
              [-74.85620117999991, 11.053901991000089],
              [-74.8559758799999 , 11.05398920500005 ],
              [-74.85575558499994, 11.054088493000052],
              [-74.8555809209999 , 11.054170382000052],
              [-74.85532375199995, 11.054287698000053],
              [-74.85493241299991, 11.054498503000048],
              [-74.85473233899995, 11.054643189000046],
              [-74.85462029699994, 11.054717656000037],
              [-74.8544600589999 , 11.054803510000056],
              [-74.85430081499993, 11.054915829000038],
              [-74.85413271999994, 11.055027548000055],
              [-74.85385661799995, 11.055158344000063],
              [-74.85364896499993, 11.055243959000052],
              [-74.85331068999994, 11.055373498000051],
              [-74.85307220899995, 11.05547464600005 ],
              [-74.85292744799995, 11.055549891000055],
              [-74.85265830599991, 11.055704710000043],
              [-74.85246822099992, 11.055791559000056],
              [-74.85234304399995, 11.055841752000049],
              [-74.85219656899994, 11.055899302000057],
              [-74.85210769299994, 11.055899158000045],
              [-74.85177450099991, 11.055996888000038],
              [-74.85159928699994, 11.056081867000046],
              [-74.85143586299995, 11.056167636000055],
              [-74.85119041299993, 11.056280131000051],
              [-74.85103558999992, 11.056369429000085],
              [-74.85078650199995, 11.056490537000059],
              [-74.85071878999992, 11.056524101000036],
              [-74.85038351199995, 11.05672744800006 ],
              [-74.8502035169999 , 11.056835621000062],
              [-74.84997857799993, 11.056949599000063],
              [-74.84970106799994, 11.057092318000059],
              [-74.84950055399992, 11.057196224000052],
              [-74.84924508699993, 11.05736102800006 ],
              [-74.84909537499993, 11.05745956000004 ],
              [-74.84871814599995, 11.057745389000047],
              [-74.84847287699995, 11.057931490000044],
              [-74.8482831359999 , 11.058089033000044],
              [-74.84804285799993, 11.058287260000043],
              [-74.84787893599992, 11.058376115000044],
              [-74.84773195499992, 11.058438563000038],
              [-74.84765209099993, 11.058472495000046],
              [-74.84748033699992, 11.058517399000038],
              [-74.84746436099994, 11.05852157600009 ],
              [-74.84722666499994, 11.058523295000043],
              [-74.8470761129999 , 11.058511125000052],
              [-74.84701409199994, 11.058506111000042],
              [-74.84696339599992, 11.058485505000078],
              [-74.84680647899995, 11.058421726000063],
              [-74.84666560999995, 11.05831829500005 ],
              [-74.84663782699994, 11.058294041000067],
              [-74.84661239099995, 11.058316542000057],
              [-74.84651721299991, 11.05842123800005 ],
              [-74.84648865899993, 11.058554488000084],
              [-74.84645058799993, 11.058611595000059],
              [-74.8463792039999 , 11.058578282000042],
              [-74.84628402599992, 11.058578282000042],
              [-74.84617932899994, 11.058568765000075],
              [-74.8461079459999 , 11.058635389000074],
              [-74.84608890999993, 11.058697255000084],
              [-74.84609842799995, 11.058759121000037],
              [-74.8461222219999 , 11.05883050500006 ],
              [-74.84615077599994, 11.058878094000079],
              [-74.8461602939999 , 11.058897130000048],
              [-74.84615553499992, 11.058978032000084],
              [-74.84612698099994, 11.059063693000041],
              [-74.84610318699993, 11.059144594000088],
              [-74.84606987399991, 11.059215978000054],
              [-74.84598897199993, 11.059258808000038],
              [-74.84596517799991, 11.059339710000074],
              [-74.84598897199993, 11.05939681700005 ],
              [-74.84604132099992, 11.059401576000084],
              [-74.84609842799995, 11.059363505000078],
              [-74.84615553499992, 11.059330192000061],
              [-74.8462126419999 , 11.059311156000092],
              [-74.84627450799991, 11.059273085000086],
              [-74.84630781999994, 11.059306397000057],
              [-74.84631456999995, 11.059322146000056],
              [-74.84604056699993, 11.059694436000086],
              [-74.84576656399992, 11.06006672600006 ],
              [-74.84562110599995, 11.060246881000069],
              [-74.8456165739999 , 11.060252494000054],
              [-74.84559781399992, 11.060275729000068],
              [-74.84544284899994, 11.060486222000065],
              [-74.84526586299995, 11.060716324000055],
              [-74.84508383399992, 11.060876938000092],
              [-74.84496248099992, 11.06096616800005 ],
              [-74.84490180499995, 11.061055398000065],
              [-74.84483398999993, 11.061244565000038],
              [-74.84471620699992, 11.061358779000045],
              [-74.84463411599995, 11.061423024000078],
              [-74.84453417799995, 11.061669298000083],
              [-74.84441639499994, 11.061979818000054],
              [-74.84429504299993, 11.06222609200006 ],
              [-74.84424067299994, 11.062362016000066],
              [-74.8442399349999 , 11.06236386200004 ],
              [-74.84417368999993, 11.062529474000087],
              [-74.84395596899992, 11.063082698000073],
              [-74.84400712999991, 11.063223115000085],
              [-74.84401401999992, 11.063242026000069],
              [-74.84407112699995, 11.063346722000063],
              [-74.84410443899992, 11.063426433000075],
              [-74.84419340999995, 11.06349315600005 ],
              [-74.84420515199992, 11.063501961000043],
              [-74.84430624399994, 11.063609230000054],
              [-74.84433353799994, 11.063712974000055],
              [-74.84436437999994, 11.063805179000042],
              [-74.84446931699995, 11.063953643000048],
              [-74.84453366299994, 11.064026970000043],
              [-74.84461291799994, 11.064215182000055],
              [-74.84527389399994, 11.065192611000043],
              [-74.84568714699992, 11.065804083000046],
              [-74.8458837519999 , 11.066132822000043],
              [-74.84601389499994, 11.066487647000088],
              [-74.84610978299992, 11.066700258000083],
              [-74.84614936699995, 11.06685449300005 ],
              [-74.84619501799995, 11.067064654000092],
              [-74.84622027699993, 11.067209393000041],
              [-74.84632560999995, 11.067703683000047],
              [-74.84644026099994, 11.068248242000038],
              [-74.8465853479999 , 11.068952529000057],
              [-74.84684277299993, 11.070072573000061],
              [-74.84696707199993, 11.07060314100005 ],
              [-74.84705605099992, 11.071070469000063],
              [-74.84730896699995, 11.07228143900005 ],
              [-74.8474517759999 , 11.072974247000047],
              [-74.84748815499995, 11.073134326000059],
              [-74.84761161699993, 11.073682660000088],
              [-74.8476538999999 , 11.073901638000052],
              [-74.8477373209999 , 11.07407          ],
              [-74.8477652709999 , 11.074220874000048],
              [-74.84780253499991, 11.074363431000052],
              [-74.84781791299991, 11.074469707000048],
              [-74.84783092299995, 11.074622734000059],
              [-74.84790859199995, 11.074670697000045],
              [-74.8480562499999 , 11.074742780000065],
              [-74.84817181499994, 11.074804417000053],
              [-74.84819621799994, 11.074966851000056],
              [-74.84817104199993, 11.075170685000046],
              [-74.8481220409999 , 11.075434810000047],
              [-74.8480891559999 , 11.075790601000051],
              [-74.84815836299992, 11.07623962200006 ],
              [-74.84831443999991, 11.076848509000058],
              [-74.84852673699993, 11.077870713000038],
              [-74.8486514679999 , 11.078454643000043],
              [-74.84873064299995, 11.078649340000084],
              [-74.84874928499994, 11.078808589000062],
              [-74.84876672899992, 11.078991225000038],
              [-74.84881542199992, 11.079201911000041],
              [-74.8488956459999 , 11.079376170000046],
              [-74.8489249559999 , 11.079442219000043],
              [-74.84892480699995, 11.079445133000092],
              [-74.8489228819999 , 11.079541755000037],
              [-74.84889310199992, 11.079601718000049],
              [-74.84896756199993, 11.079889999000045],
              [-74.84910742799991, 11.080469038000047],
              [-74.84947884099995, 11.082162837000055],
              [-74.8497145469999 , 11.083313101000044],
              [-74.84984294299994, 11.083827305000057],
              [-74.8499120969999 , 11.08416238600006 ],
              [-74.84993637699995, 11.084327901000051],
              [-74.84996615699993, 11.084385244000089],
              [-74.8500187539999 , 11.084402792000049],
              [-74.8500827599999 , 11.084429748000048],
              [-74.85004067299991, 11.084497807000048],
              [-74.8500245159999 , 11.084523311000055],
              [-74.85002643399991, 11.084602582000059],
              [-74.85005427499993, 11.08481521400006 ],
              [-74.85029970599993, 11.085951745000045],
              [-74.85054176599994, 11.087097079000046],
              [-74.85088745199994, 11.088661899000044],
              [-74.85115910899992, 11.089929581000092],
              [-74.8514207259999 , 11.091161679000038],
              [-74.85160248699992, 11.09197580600005 ],
              [-74.85168269799993, 11.092329491000044],
              [-74.8517805809999 , 11.092628467000054],
              [-74.8518591799999 , 11.09283834200005 ],
              [-74.85188943899993, 11.09300436600006 ],
              [-74.85193702599992, 11.093238876000044],
              [-74.85194458099994, 11.09338305600005 ],
              [-74.8520190129999 , 11.093850878000069],
              [-74.85237054499993, 11.09542631100004 ],
              [-74.85260937699991, 11.09652595700004 ],
              [-74.85274603699992, 11.09705924900004 ],
              [-74.85283582199992, 11.097284645000059],
              [-74.85284784099991, 11.097399796000047],
              [-74.8529965809999 , 11.09752013800005 ],
              [-74.85303978499991, 11.097549104000052],
              [-74.85313400799993, 11.097520435000092],
              [-74.85313659499991, 11.097519648000059],
              [-74.85319895999993, 11.097523341000056],
              [-74.85324113699994, 11.097514109000088],
              [-74.85334004499992, 11.097443673000043],
              [-74.8534064289999 , 11.097485769000059],
              [-74.85333186699995, 11.09754590700004 ],
              [-74.85322791499993, 11.097598427000037],
              [-74.85317564799993, 11.097630536000054],
              [-74.85317037999994, 11.097631690000071],
              [-74.85313347099992, 11.097639775000061],
              [-74.85308194299995, 11.097657255000058],
              [-74.85301229599992, 11.09773823100005 ],
              [-74.85294815499992, 11.09776961600005 ],
              [-74.85291251699994, 11.097826195000039],
              [-74.85289231199994, 11.09793062700004 ],
              [-74.85316405499992, 11.099088366000046],
              [-74.85345986599992, 11.100476659000037],
              [-74.85372309599995, 11.101628425000058],
              [-74.85393734899992, 11.10263063900004 ],
              [-74.85410678799991, 11.103401267000038],
              [-74.85423822299992, 11.10404046800005 ],
              [-74.85435885299995, 11.104482363000045],
              [-74.8544473849999 , 11.104793139000037],
              [-74.85451125599991, 11.10506130500005 ],
              [-74.85455106799992, 11.105275152000047],
              [-74.85457021099995, 11.10537038700005 ],
              [-74.85186053199993, 11.105367258000058],
              [-74.8518289669999 , 11.105089513000053],
              [-74.85179256099991, 11.104813418000049],
              [-74.85175130399995, 11.104538974000093],
              [-74.85170520399993, 11.10426618400004 ],
              [-74.8516542559999 , 11.103995048000058],
              [-74.85159845999993, 11.103725565000047],
              [-74.85153782099991, 11.10345773000006 ],
              [-74.85147232999992, 11.103191553000045],
              [-74.8514019989999 , 11.102927024000053],
              [-74.85132681899995, 11.102664152000045],
              [-74.85124679199993, 11.10240292800006 ],
              [-74.85116191799995, 11.10214336200005 ],
              [-74.85107219899993, 11.101885444000061],
              [-74.85097763099992, 11.101629179000042],
              [-74.85087821699994, 11.10137456700005 ],
              [-74.85077395999991, 11.101121611000053],
              [-74.85069609499993, 11.100943578000056],
              [-74.85051282099994, 11.100539869000045],
              [-74.85041649599992, 11.100320298000042],
              [-74.85032180199994, 11.100098968000054],
              [-74.85022873199995, 11.099875849000057],
              [-74.85013727599994, 11.099650913000062],
              [-74.85004742199993, 11.09942413500005 ],
              [-74.84995915799993, 11.099195484000063],
              [-74.84987247299995, 11.098964927000054],
              [-74.8497873529999 , 11.098732434000055],
              [-74.84970379499993, 11.098497992000091],
              [-74.84962177699992, 11.098261543000092],
              [-74.8495412819999 , 11.098023049000062],
              [-74.84946230399993, 11.097782467000059]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08078",
      "properties": {"id": "08078", "iso": "BAR", "name": "BARANOA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.94529786899994, 10.858543223000083],
              [-74.94526934299995, 10.858537991000048],
              [-74.94514074499995, 10.858544061000089],
              [-74.94481372799993, 10.858419993000041],
              [-74.94458328699994, 10.858365957000046],
              [-74.94433736899992, 10.85830829200006 ],
              [-74.94426872899993, 10.858322030000068],
              [-74.9441405789999 , 10.858338169000092],
              [-74.9440146959999 , 10.85834299000004 ],
              [-74.94390474699992, 10.858304788000055],
              [-74.94379027499991, 10.858291484000063],
              [-74.94364839999992, 10.858312180000041],
              [-74.94349282299993, 10.858348747000036],
              [-74.94342187299992, 10.858353444000045],
              [-74.94330722699993, 10.858254160000058],
              [-74.94317900599992, 10.85823410100005 ],
              [-74.94306461299993, 10.858263781000062],
              [-74.9429365389999 , 10.858316124000055],
              [-74.94276484499994, 10.858305215000087],
              [-74.94265733799995, 10.858341669000083],
              [-74.94247224599991, 10.858498219000069],
              [-74.94200335599993, 10.858441552000045],
              [-74.94177619899995, 10.85841714500009 ],
              [-74.94165460699992, 10.858410629000048],
              [-74.94152369599993, 10.858336111000085],
              [-74.94132870399994, 10.858332034000057],
              [-74.9411727019999 , 10.85832786800006 ],
              [-74.94101208399991, 10.858307836000051],
              [-74.94090195899992, 10.858301295000047],
              [-74.94073677299991, 10.85829261400005 ],
              [-74.94064736299993, 10.858320032000051],
              [-74.94056959199992, 10.858433585000057],
              [-74.94048013499992, 10.858438331000059],
              [-74.94037918099991, 10.858429500000057],
              [-74.94029422299991, 10.858388887000046],
              [-74.94018619099995, 10.858282573000054],
              [-74.94001377899991, 10.858097048000047],
              [-74.93985521099995, 10.857954576000054],
              [-74.93972659999991, 10.857880051000052],
              [-74.9396440029999 , 10.857875714000045],
              [-74.9395728909999 , 10.857875879000062],
              [-74.93948105599992, 10.857837549000067],
              [-74.93932707099992, 10.85769053100006 ],
              [-74.93915939899995, 10.85758435300005 ],
              [-74.93895045499994, 10.857487347000074],
              [-74.93881502799991, 10.857446850000088],
              [-74.93869115199993, 10.857447143000059],
              [-74.93849395099994, 10.857488419000049],
              [-74.93834715999992, 10.857500099000049],
              [-74.93816357499992, 10.857466516000045],
              [-74.93797317799994, 10.85746922800007 ],
              [-74.93779656499993, 10.857480979000059],
              [-74.93764516099992, 10.857481333000067],
              [-74.93751439699992, 10.857479373000047],
              [-74.93739742999992, 10.857490983000048],
              [-74.93726209899995, 10.857500372000061],
              [-74.93709002399993, 10.857487168000091],
              [-74.93695685499995, 10.857426261000057],
              [-74.93682349999995, 10.857272389000059],
              [-74.93667406799995, 10.857107220000046],
              [-74.93647658499992, 10.857005646000061],
              [-74.93625555299991, 10.856822573000045],
              [-74.93620589599993, 10.856781442000056],
              [-74.93618383499995, 10.856763170000079],
              [-74.93615719999991, 10.85674110900004 ],
              [-74.93609780699995, 10.856584028000043],
              [-74.9360688399999 , 10.856505514000048],
              [-74.93605617899993, 10.856471196000086],
              [-74.93599684799995, 10.856310386000075],
              [-74.93597444399995, 10.85624966000006 ],
              [-74.93590399699991, 10.856142433000059],
              [-74.93582858399992, 10.856027649000055],
              [-74.93574411899993, 10.855905159000088],
              [-74.93569869799995, 10.855839291000052],
              [-74.93550721499992, 10.855525295000064],
              [-74.93521800799994, 10.855202549000069],
              [-74.93504248699992, 10.85491772100005 ],
              [-74.93489225499991, 10.854781066000044],
              [-74.93476249299994, 10.85465558900006 ],
              [-74.93449193299995, 10.854566380000051],
              [-74.93424187099993, 10.85450183000006 ],
              [-74.93404861699992, 10.854439395000043],
              [-74.93384742199993, 10.854377640000052],
              [-74.93378716299992, 10.854359144000057],
              [-74.9335980319999 , 10.854252517000077],
              [-74.93344596599991, 10.854166784000085],
              [-74.93317288499992, 10.853951806000055],
              [-74.93294314299993, 10.853822070000092],
              [-74.93279988899991, 10.85376625400005 ],
              [-74.93268838299991, 10.85367892000005 ],
              [-74.93259039199995, 10.853533158000062],
              [-74.93250491599991, 10.853425937000054],
              [-74.93237066299992, 10.853424448000055],
              [-74.93196855899993, 10.853419991000067],
              [-74.93192497799993, 10.85341150000005 ],
              [-74.93185014999995, 10.853411709000056],
              [-74.93151835299994, 10.853412628000058],
              [-74.93121790099991, 10.85344841500006 ],
              [-74.93079354299994, 10.853432113000054],
              [-74.93033387899993, 10.853433388000042],
              [-74.92979295899994, 10.853421714000092],
              [-74.92939817199994, 10.853552427000068],
              [-74.9292205669999 , 10.853611233000038],
              [-74.92883206399995, 10.853769593000038],
              [-74.92839076899992, 10.854015484000058],
              [-74.92819708499991, 10.854295638000053],
              [-74.9281978759999 , 10.854575255000043],
              [-74.92832212499991, 10.85474967500005 ],
              [-74.92839311599994, 10.854893791000052],
              [-74.92843079999994, 10.85503377200007 ],
              [-74.92849111999993, 10.855262160000052],
              [-74.92847672499994, 10.855446520000044],
              [-74.92843253699994, 10.855645709000044],
              [-74.9283061989999 , 10.85580826100005 ],
              [-74.92813498499993, 10.855926693000072],
              [-74.92802325399992, 10.85597492100004 ],
              [-74.92791149899995, 10.856023161000053],
              [-74.92765815699994, 10.856112331000077],
              [-74.9273600219999 , 10.856186886000046],
              [-74.9270543849999 , 10.856246709000061],
              [-74.9267413739999 , 10.85633604900005 ],
              [-74.92627162899993, 10.856388956000046],
              [-74.92599578399995, 10.856433950000053],
              [-74.92567547599992, 10.856488752000075],
              [-74.9256453939999 , 10.856493899000043],
              [-74.92553282199992, 10.856494209000061],
              [-74.92530230099993, 10.856494845000043],
              [-74.92528082199993, 10.856492203000073],
              [-74.92497278899992, 10.856454313000086],
              [-74.92494241399993, 10.856450576000043],
              [-74.92489192699992, 10.856444366000062],
              [-74.92463063199995, 10.85635661300006 ],
              [-74.92443633399995, 10.856224435000058],
              [-74.9241748259999 , 10.856062954000038],
              [-74.92398794499991, 10.855916015000048],
              [-74.92377875099993, 10.855791250000038],
              [-74.92370387599993, 10.855688237000038],
              [-74.92356924499995, 10.855555897000045],
              [-74.92346414299993, 10.855312885000046],
              [-74.92344570599994, 10.855109877000075],
              [-74.9234333519999 , 10.85497382200009 ],
              [-74.92349200299992, 10.854612392000092],
              [-74.92348382299991, 10.854354366000052],
              [-74.92345559299991, 10.854149445000076],
              [-74.92345336599993, 10.854133269000044],
              [-74.92336319999993, 10.853897585000084],
              [-74.9232954929999 , 10.853691333000086],
              [-74.92309321999994, 10.853374858000052],
              [-74.92295840699995, 10.853176163000057],
              [-74.9227655759999 , 10.85294231000006 ],
              [-74.9225264449999 , 10.85278076800006 ],
              [-74.92237696399991, 10.852670585000055],
              [-74.92216035699994, 10.852560586000038],
              [-74.92202577499995, 10.852442991000089],
              [-74.92181662599995, 10.852332973000046],
              [-74.92172685499992, 10.852237373000037],
              [-74.92158476799995, 10.852105054000049],
              [-74.92143516499993, 10.851950634000048],
              [-74.9213005599999 , 10.851825665000092],
              [-74.92117349499995, 10.851730167000085],
              [-74.92106126499993, 10.851605137000092],
              [-74.92097895699993, 10.851509519000047],
              [-74.92076205899991, 10.851296300000058],
              [-74.92068714599992, 10.851178540000092],
              [-74.92058989599991, 10.851075589000061],
              [-74.92050002299993, 10.850943122000047],
              [-74.92042498299992, 10.850781127000062],
              [-74.92032754299993, 10.85061181900005 ],
              [-74.92025801099993, 10.850538905000064],
              [-74.9202153409999 , 10.85049416000004 ],
              [-74.92012544599993, 10.850354327000048],
              [-74.92012497199994, 10.850184751000086],
              [-74.92017678799994, 10.85004452600009 ],
              [-74.92022116699991, 10.84991169500006 ],
              [-74.92034756899994, 10.849771267000051],
              [-74.92051841099993, 10.849520123000048],
              [-74.92052880099993, 10.849506147000056],
              [-74.92064124699993, 10.849354885000082],
              [-74.92064472899995, 10.84935020100005 ],
              [-74.92081567399993, 10.849135924000052],
              [-74.92092709499991, 10.848973415000046],
              [-74.92097143299992, 10.84882583700005 ],
              [-74.9210008519999 , 10.848678303000042],
              [-74.92089812899991, 10.848445521000087],
              [-74.9206503719999 , 10.848358820000044],
              [-74.92013723899993, 10.848202938000043],
              [-74.9196949649999 , 10.84809929000005 ],
              [-74.91912859599995, 10.84787364500005 ],
              [-74.91861565199991, 10.847787666000045],
              [-74.91854152499991, 10.847773222000058],
              [-74.9181734309999 , 10.847701493000045],
              [-74.91730705399993, 10.847668903000056],
              [-74.91667060299994, 10.847670639000057],
              [-74.91612259699991, 10.847689608000053],
              [-74.91548619299994, 10.84770881500009 ],
              [-74.91530940099994, 10.847709295000072],
              [-74.91499117699993, 10.847710162000055],
              [-74.91474352099993, 10.84765840700004 ],
              [-74.91446046099992, 10.84758927200005 ],
              [-74.91426559699994, 10.84744998800005 ],
              [-74.91424738499995, 10.847257802000058],
              [-74.9142096139999 , 10.84698538300006 ],
              [-74.91425851699995, 10.846690336000052],
              [-74.91428780399991, 10.846493648000092],
              [-74.91426728599993, 10.846267604000047],
              [-74.91421707199993, 10.846090793000087],
              [-74.91409735499991, 10.84595349600005 ],
              [-74.91398654199992, 10.845864411000036],
              [-74.91392597399994, 10.84589661700005 ],
              [-74.91364263999992, 10.845946963000074],
              [-74.91341721799995, 10.845966995000083],
              [-74.91337786399993, 10.84597025100004 ],
              [-74.91337302199992, 10.845938484000044],
              [-74.91336062699992, 10.845929264000063],
              [-74.91324415499992, 10.845842657000048],
              [-74.91305073699993, 10.845688303000088],
              [-74.91304960699995, 10.845687399000042],
              [-74.91299582399995, 10.845633126000052],
              [-74.91291813799995, 10.845554726000046],
              [-74.91278641499991, 10.845387497000047],
              [-74.91271780499994, 10.845247455000049],
              [-74.91266089699991, 10.845114239000054],
              [-74.91250830399991, 10.844960985000057],
              [-74.91237974699993, 10.844906623000043],
              [-74.91224214099992, 10.84488919100005 ],
              [-74.91210708599993, 10.84490169500009 ],
              [-74.9118813309999 , 10.84493788900005 ],
              [-74.91168311099995, 10.844916291000061],
              [-74.91142662399994, 10.844892808000054],
              [-74.91120745099994, 10.844873662000055],
              [-74.91101502599992, 10.844894356000054],
              [-74.91086286599995, 10.844910724000044],
              [-74.91073740399992, 10.844960019000041],
              [-74.91057401499995, 10.845237674000089],
              [-74.91045846199995, 10.845464234000076],
              [-74.91044309499995, 10.845494363000057],
              [-74.91029089699992, 10.845709731000056],
              [-74.91015669299992, 10.845837420000066],
              [-74.91003411799994, 10.84596272500005 ],
              [-74.90986542599995, 10.846152870000083],
              [-74.90966377099994, 10.846275802000036],
              [-74.90950626199992, 10.846371823000084],
              [-74.90938840199993, 10.846443673000067],
              [-74.90910245599991, 10.846617990000084],
              [-74.90879105599993, 10.846807824000052],
              [-74.90877841199995, 10.84681553200005 ],
              [-74.9086372029999 , 10.846890091000091],
              [-74.90837339099994, 10.847041360000048],
              [-74.90807718199994, 10.847211250000043],
              [-74.90787421699991, 10.84737173600007 ],
              [-74.9077151159999 , 10.847540633000051],
              [-74.90761785799992, 10.847605412000064],
              [-74.90750235999991, 10.847682341000052],
              [-74.9073560779999 , 10.847699487000057],
              [-74.90722375699994, 10.84771882800004 ],
              [-74.9072065439999 , 10.847723793000057],
              [-74.9070521509999 , 10.84776832600005 ],
              [-74.90670279799991, 10.847979962000068],
              [-74.90642337899993, 10.848220962000084],
              [-74.90620629499995, 10.848406361000059],
              [-74.90619390899991, 10.84841571700008 ],
              [-74.9061232219999 , 10.848469109000064],
              [-74.90596363199995, 10.848589651000054],
              [-74.90540165899995, 10.849270097000044],
              [-74.90521617799993, 10.849494682000056],
              [-74.90507625299995, 10.849440512000058],
              [-74.9049507339999 , 10.84944831900009 ],
              [-74.90483229299991, 10.849469872000043],
              [-74.90475333499995, 10.849484239000049],
              [-74.90464874499992, 10.849491894000039],
              [-74.90460260999993, 10.84949838500006 ],
              [-74.90455186399993, 10.849505524000051],
              [-74.90444199099994, 10.849520982000058],
              [-74.90422573099994, 10.84952254700005 ],
              [-74.90403031999995, 10.849512464000043],
              [-74.9037836679999 , 10.849491258000057],
              [-74.90362293599992, 10.849453328000038],
              [-74.9034713819999 , 10.84939924300005 ],
              [-74.9033475729999 , 10.84932195500005 ],
              [-74.90320517599991, 10.849247108000043],
              [-74.9031072439999 , 10.849211024000056],
              [-74.90296288899992, 10.849186774000088],
              [-74.90286084199994, 10.849224302000039],
              [-74.90270991299991, 10.849255288000052],
              [-74.90261467499994, 10.84926977300006 ],
              [-74.9024751039999 , 10.84926388200006 ],
              [-74.9023469739999 , 10.849232617000041],
              [-74.90213260999991, 10.849174382000058],
              [-74.90192938099995, 10.849049385000058],
              [-74.90189393399993, 10.848971462000065],
              [-74.90189895899994, 10.848834473000068],
              [-74.90190358399991, 10.848708392000049],
              [-74.90190751799992, 10.848601159000054],
              [-74.90193021399995, 10.848461073000067],
              [-74.90195046799994, 10.848336065000069],
              [-74.90195141899994, 10.848330195000074],
              [-74.90197428099992, 10.848189085000058],
              [-74.90198265899994, 10.84808613000007 ],
              [-74.90198764999991, 10.848024806000069],
              [-74.90199079399991, 10.847986176000063],
              [-74.9019915369999 , 10.84797704600004 ],
              [-74.90199562599992, 10.84792679700007 ],
              [-74.9019978629999 , 10.847860737000076],
              [-74.9019991369999 , 10.847823109000046],
              [-74.90200218799993, 10.847733033000054],
              [-74.9020062749999 , 10.847612347000052],
              [-74.90200649299993, 10.847605942000087],
              [-74.90200723799995, 10.847583928000063],
              [-74.90200902399994, 10.847531204000063],
              [-74.90201438299994, 10.847451935000038],
              [-74.90201697899994, 10.847413528000061],
              [-74.90202662799993, 10.847270783000056],
              [-74.90203421699994, 10.847158519000061],
              [-74.90202628499992, 10.846707893000087],
              [-74.90202284399993, 10.846503358000064],
              [-74.90202218499991, 10.846464186000048],
              [-74.90200040799994, 10.846324964000075],
              [-74.90197583999992, 10.846167897000043],
              [-74.90188142699992, 10.845975431000056],
              [-74.90186106399995, 10.845934248000049],
              [-74.9018364879999 , 10.845884546000093],
              [-74.90179645899991, 10.84580359000006 ],
              [-74.90156481199995, 10.845607516000086],
              [-74.90138294699995, 10.845490748000088],
              [-74.90129691999994, 10.84543551300004 ],
              [-74.90122838099995, 10.845391506000055],
              [-74.90100842399994, 10.84520454200009 ],
              [-74.90083038499995, 10.84502417400006 ],
              [-74.90076731999994, 10.84495620000007 ],
              [-74.90073552599995, 10.844921930000055],
              [-74.90067569399991, 10.844857437000087],
              [-74.90064382299994, 10.844822564000083],
              [-74.90056921699994, 10.844740931000047],
              [-74.90056918199991, 10.844727954000064],
              [-74.9003571529999 , 10.844501198000046],
              [-74.90024766599993, 10.844384107000053],
              [-74.90024469999992, 10.84438093600005 ],
              [-74.90019259799993, 10.844325215000083],
              [-74.89986990699992, 10.843980111000064],
              [-74.89976442299991, 10.843867300000056],
              [-74.89973823199995, 10.843843789000061],
              [-74.8996375989999 , 10.84375345300009 ],
              [-74.89951462399995, 10.843643058000055],
              [-74.89897540999993, 10.844504886000038],
              [-74.89880119199995, 10.84478333800007 ],
              [-74.89846691499991, 10.845317610000052],
              [-74.89846469999992, 10.845321151000064],
              [-74.89822088299991, 10.845710841000084],
              [-74.89807791899995, 10.84593933800005 ],
              [-74.89797748199993, 10.846099865000042],
              [-74.8977993439999 , 10.846384579000073],
              [-74.89761494699991, 10.846679296000048],
              [-74.89722400099993, 10.847309697000071],
              [-74.89699413099993, 10.847680364000041],
              [-74.89603191299994, 10.84923193800006 ],
              [-74.8953951659999 , 10.850334868000061],
              [-74.8953762889999 , 10.850367565000056],
              [-74.89534495599992, 10.850475040000049],
              [-74.89532867499992, 10.850530881000054],
              [-74.89511749199994, 10.851255154000057],
              [-74.8951470909999 , 10.851426496000045],
              [-74.89533581099994, 10.852518933000056],
              [-74.89535980599993, 10.852657830000055],
              [-74.89533097099991, 10.852661750000038],
              [-74.8950435459999 , 10.852700836000054],
              [-74.8943493889999 , 10.852795232000062],
              [-74.89410058699991, 10.852362821000042],
              [-74.89405269799994, 10.852279597000063],
              [-74.89355273199993, 10.851731321000045],
              [-74.89346036099994, 10.851630028000045],
              [-74.8932425559999 , 10.851255580000043],
              [-74.8929583119999 , 10.850766910000061],
              [-74.89280995399992, 10.850321229000087],
              [-74.89278655699991, 10.850250943000049],
              [-74.89239580599991, 10.850085138000054],
              [-74.89236585999993, 10.850072431000058],
              [-74.89213523699993, 10.850041391000047],
              [-74.89195685499993, 10.850017381000043],
              [-74.89120677499994, 10.849828511000055],
              [-74.8906740999999 , 10.849924115000078],
              [-74.89000390399991, 10.850044400000058],
              [-74.88949268099992, 10.85013615400004 ],
              [-74.8891468349999 , 10.850216622000062],
              [-74.88900463399995, 10.850249707000046],
              [-74.88804019399993, 10.85058904400006 ],
              [-74.8875873309999 , 10.850738869000054],
              [-74.88602351799994, 10.851256239000065],
              [-74.8856800069999 , 10.851369885000054],
              [-74.88422383499994, 10.851760829000057],
              [-74.88386416399993, 10.851857392000056],
              [-74.88351739599995, 10.851973341000075],
              [-74.88211535799991, 10.852442129000053],
              [-74.88120173699991, 10.852782554000044],
              [-74.8807098229999 , 10.85296584300005 ],
              [-74.87899673599992, 10.852936603000046],
              [-74.87614400599995, 10.852887911000039],
              [-74.87529815799991, 10.852915063000069],
              [-74.87462225999991, 10.852936764000049],
              [-74.87354145299992, 10.853009058000055],
              [-74.87322556799995, 10.853030189000037],
              [-74.87183581499994, 10.853103691000058],
              [-74.87182400799992, 10.853104314000063],
              [-74.87187218899993, 10.85302875800005 ],
              [-74.87189479099993, 10.852896715000043],
              [-74.87192184799994, 10.852789054000084],
              [-74.87193221699994, 10.85274779100007 ],
              [-74.87195354099993, 10.852589442000067],
              [-74.87198415699993, 10.85243123500004 ],
              [-74.87200315899992, 10.85227285700006 ],
              [-74.8720148569999 , 10.852132758000039],
              [-74.8720311379999 , 10.851997332000053],
              [-74.87204307699994, 10.851843447000078],
              [-74.8720550999999 , 10.851684971000054],
              [-74.87205280899991, 10.851546961000054],
              [-74.87205123599995, 10.851367577000076],
              [-74.87203342499993, 10.851185666000049],
              [-74.87203029599993, 10.851147438000055],
              [-74.87202381199995, 10.851068249000036],
              [-74.87200947499991, 10.850955355000053],
              [-74.8719881099999 , 10.850844676000065],
              [-74.87197654999994, 10.850785066000071],
              [-74.87196888099993, 10.850745518000053],
              [-74.87194749399993, 10.850637136000046],
              [-74.8719103599999 , 10.850496313000065],
              [-74.8718909019999 , 10.850410942000053],
              [-74.8718567009999 , 10.850235682000061],
              [-74.8718176079999 , 10.850074146000054],
              [-74.8717732739999 , 10.849947020000059],
              [-74.87169860799992, 10.849826346000043],
              [-74.8716372049999 , 10.849744961000056],
              [-74.87158479399994, 10.849682100000052],
              [-74.87155728899995, 10.84965870600007 ],
              [-74.8714904549999 , 10.849623227000052],
              [-74.87145140899992, 10.84959505200004 ],
              [-74.87141932799994, 10.84956698700006 ],
              [-74.87139899699991, 10.849532201000045],
              [-74.87135375399993, 10.849457950000044],
              [-74.87131328299995, 10.84937687100006 ],
              [-74.87129202199992, 10.849299230000042],
              [-74.8712848319999 , 10.84927297300004 ],
              [-74.87126549299995, 10.849180710000041],
              [-74.87125307199994, 10.849090855000043],
              [-74.87124768099994, 10.84899878300007 ],
              [-74.8712457979999 , 10.84894451200006 ],
              [-74.87124456999993, 10.848909074000062],
              [-74.87124586899995, 10.848833218000038],
              [-74.87124516199992, 10.848738920000073],
              [-74.87125120999991, 10.848703984000053],
              [-74.87128129299992, 10.848530202000063],
              [-74.8713686239999 , 10.84804861300006 ],
              [-74.87135369399994, 10.847970205000081],
              [-74.87134144299995, 10.847871145000056],
              [-74.8713253599999 , 10.847723744000064],
              [-74.87132087599991, 10.847605538000039],
              [-74.8713205599999 , 10.847597201000042],
              [-74.87132060699992, 10.84750477700004 ],
              [-74.87132063199994, 10.847456944000044],
              [-74.87132488399993, 10.84741878400007 ],
              [-74.87133162999993, 10.847358231000044],
              [-74.8713504879999 , 10.847275739000054],
              [-74.87135677799995, 10.84724822600009 ],
              [-74.87137947299993, 10.847145082000054],
              [-74.87139453099991, 10.84708091400006 ],
              [-74.87142424399991, 10.846975581000038],
              [-74.87144922499994, 10.846874780000064],
              [-74.87148347099992, 10.846776403000092],
              [-74.87151838699992, 10.84663895600005 ],
              [-74.87158639799992, 10.845452729000044],
              [-74.8715880659999 , 10.845423573000062],
              [-74.87161755699992, 10.84424669200007 ],
              [-74.87160699599991, 10.844048789000055],
              [-74.87159931399992, 10.843954393000047],
              [-74.87158063099992, 10.843823050000083],
              [-74.87156400899994, 10.843707829000039],
              [-74.87153799899994, 10.843597074000058],
              [-74.87151857499993, 10.84350941200006 ],
              [-74.87148318599992, 10.843403117000037],
              [-74.87144997299993, 10.843306047000056],
              [-74.8714099789999 , 10.843197387000089],
              [-74.87136336299994, 10.84306792600006 ],
              [-74.87131417799992, 10.842952229000048],
              [-74.8711610979999 , 10.842657938000059],
              [-74.8710817299999 , 10.842539499000054],
              [-74.87103011399995, 10.842430662000083],
              [-74.87097624199993, 10.842317191000063],
              [-74.87093612899992, 10.842215421000049],
              [-74.87088711599995, 10.842090531000053],
              [-74.87085191599994, 10.841972737000049],
              [-74.87081926699994, 10.841843494000045],
              [-74.87078852499991, 10.84173725800008 ],
              [-74.87076244699995, 10.841631103000054],
              [-74.8707457349999 , 10.841520485000046],
              [-74.87073576799992, 10.841423761000044],
              [-74.8707239069999 , 10.841301712000075],
              [-74.87065735399995, 10.841250142000092],
              [-74.87063225199995, 10.841222174000052],
              [-74.87059356099991, 10.841173316000038],
              [-74.87054823499994, 10.841103665000048],
              [-74.8705256259999 , 10.841066538000064],
              [-74.87050784999991, 10.841017987000043],
              [-74.87048566399994, 10.84095558100006 ],
              [-74.87046577099994, 10.840895501000091],
              [-74.87044582999994, 10.840837719000092],
              [-74.87043338099994, 10.840750158000048],
              [-74.87041852599992, 10.840667160000066],
              [-74.8704014249999 , 10.84057953000007 ],
              [-74.8703824399999 , 10.840466574000061],
              [-74.87035654699991, 10.840348921000043],
              [-74.87032363999992, 10.840233462000072],
              [-74.87028798499995, 10.840143258000069],
              [-74.87024795199994, 10.84003688200005 ],
              [-74.8702031059999 , 10.839939653000044],
              [-74.87014467199992, 10.83982151300006 ],
              [-74.87009990599995, 10.839719682000066],
              [-74.87005045399991, 10.839620078000053],
              [-74.87001219899992, 10.83954592400005 ],
              [-74.86996262899993, 10.839453217000084],
              [-74.86991064599994, 10.839365071000088],
              [-74.86981804899995, 10.839205039000092],
              [-74.86976150999993, 10.839112231000058],
              [-74.86971138299992, 10.839051708000056],
              [-74.86966351299992, 10.838995813000054],
              [-74.8696132689999 , 10.838942185000064],
              [-74.86956070299993, 10.838888517000044],
              [-74.8695011989999 , 10.838832454000055],
              [-74.86946226999993, 10.838797382000052],
              [-74.86941652299993, 10.838753017000045],
              [-74.86937073399992, 10.838710956000057],
              [-74.86931560799991, 10.838671052000052],
              [-74.8692650829999 , 10.838633517000062],
              [-74.86920995799994, 10.838593607000064],
              [-74.8691594089999 , 10.838558375000048],
              [-74.86905337099995, 10.838503914000057],
              [-74.86890997899991, 10.83845811000009 ],
              [-74.86876677499993, 10.838400810000053],
              [-74.86863523599993, 10.838341379000042],
              [-74.86854768699993, 10.838294105000045],
              [-74.86847407999994, 10.838247025000044],
              [-74.86837498299991, 10.838194972000053],
              [-74.86830365799995, 10.838150229000064],
              [-74.86821850599995, 10.838098388000049],
              [-74.868138        , 10.838046610000049],
              [-74.86807821899993, 10.83800664000006 ],
              [-74.8680000839999 , 10.837952594000058],
              [-74.8679265159999 , 10.83790322100009 ],
              [-74.86663118299992, 10.836821745000066],
              [-74.86718497999993, 10.83606791200009 ],
              [-74.8672487199999 , 10.836205408000069],
              [-74.86732177099992, 10.83614900300006 ],
              [-74.86741362799995, 10.83608137400006 ],
              [-74.86752195299994, 10.836002496000049],
              [-74.86761857399995, 10.835928041000045],
              [-74.8677105509999 , 10.83585350800007 ],
              [-74.86779546899993, 10.835783494000054],
              [-74.86786872099992, 10.835715590000063],
              [-74.86793011399993, 10.835661308000056],
              [-74.86799379599995, 10.835609358000056],
              [-74.86806921899995, 10.83555068600009 ],
              [-74.86811406099991, 10.835512262000066],
              [-74.86816351899995, 10.835476200000073],
              [-74.86824149799992, 10.83540376700006 ],
              [-74.86830757999991, 10.83534725900006 ],
              [-74.8683737419999 , 10.835286151000048],
              [-74.86842580099994, 10.835234030000038],
              [-74.86854164699992, 10.835123071000055],
              [-74.86861730699991, 10.83505060300007 ],
              [-74.86867882099995, 10.83498942500006 ],
              [-74.8687451429999 , 10.834919125000056],
              [-74.86883305799995, 10.83481004500004 ],
              [-74.86892565499994, 10.834698746000072],
              [-74.86898744399991, 10.834621470000059],
              [-74.8690611369999 , 10.83452827900004 ],
              [-74.8691159519999 , 10.834450914000058],
              [-74.86917242899995, 10.834147348000045],
              [-74.86917377799995, 10.834144334000086],
              [-74.86946831699993, 10.833488257000056],
              [-74.86968469499993, 10.832973473000038],
              [-74.86976291399992, 10.832817812000087],
              [-74.86991955499991, 10.83258420900006 ],
              [-74.87003412699994, 10.832452371000045],
              [-74.87021466399995, 10.83210510300006 ],
              [-74.87029697999992, 10.831973427000037],
              [-74.87063024199995, 10.831440327000053],
              [-74.87112424199995, 10.830697611000062],
              [-74.87136533799992, 10.830380084000069],
              [-74.87164862799995, 10.830008631000055],
              [-74.87165574799991, 10.829999064000049],
              [-74.87166726099991, 10.829983492000054],
              [-74.87167871999992, 10.829967877000058],
              [-74.8716901219999 , 10.829952220000052],
              [-74.87170146099993, 10.82993652500005 ],
              [-74.87171274199994, 10.829920781000055],
              [-74.87172395899995, 10.829905001000043],
              [-74.87173511899994, 10.829889175000062],
              [-74.87174622099991, 10.82987330800006 ],
              [-74.87175725999992, 10.829857404000052],
              [-74.87176823499993, 10.829841456000054],
              [-74.87177915699993, 10.829825466000045],
              [-74.87179001299995, 10.82980943800004 ],
              [-74.87180080799993, 10.829793370000061],
              [-74.87181154799993, 10.82977726200005 ],
              [-74.8718222199999 , 10.829761115000053],
              [-74.8718328349999 , 10.829744925000057],
              [-74.87184338599991, 10.82972870000009 ],
              [-74.87185387799991, 10.829712433000054],
              [-74.87186430499992, 10.829696130000059],
              [-74.87187467099994, 10.829679786000042],
              [-74.87188497599993, 10.829663404000087],
              [-74.8718952179999 , 10.829646987000046],
              [-74.87190539799991, 10.829630529000042],
              [-74.8719155149999 , 10.829614033000041],
              [-74.87192556699995, 10.82959750000009 ],
              [-74.87193556099993, 10.829580933000045],
              [-74.87194548799994, 10.829564323000056],
              [-74.8719553549999 , 10.829547683000044],
              [-74.87196515699992, 10.829531003000056],
              [-74.87197489599993, 10.829514286000062],
              [-74.87198456999994, 10.829497533000051],
              [-74.8719941839999 , 10.829480747000048],
              [-74.87200373199994, 10.829463922000059],
              [-74.87201321099991, 10.829447066000057],
              [-74.87202263599994, 10.829430172000059],
              [-74.8720319919999 , 10.829413241000054],
              [-74.87204128199994, 10.829396279000036],
              [-74.87205051099994, 10.829379280000069],
              [-74.87205966999994, 10.829362248000052],
              [-74.87206876999994, 10.829345188000048],
              [-74.87207780099993, 10.829328084000053],
              [-74.87208677199993, 10.82931095300006 ],
              [-74.87209567399992, 10.829293788000086],
              [-74.87210451499993, 10.829276591000053],
              [-74.87211328599994, 10.829259358000058],
              [-74.87212199299995, 10.829242096000087],
              [-74.87213063399992, 10.829224803000045],
              [-74.87213921199992, 10.829207474000043],
              [-74.87214772099992, 10.829190113000038],
              [-74.87215616499992, 10.829172723000056],
              [-74.87216454399993, 10.82915530200006 ],
              [-74.8721728559999 , 10.829137848000073],
              [-74.87218110099991, 10.829120365000051],
              [-74.87218927999993, 10.829102852000062],
              [-74.8721973939999 , 10.82908530800006 ],
              [-74.87220543599994, 10.829067734000091],
              [-74.87221341799994, 10.829050128000063],
              [-74.8722213289999 , 10.82903249900005 ],
              [-74.87222917799994, 10.829014837000045],
              [-74.87223695699993, 10.828997143000038],
              [-74.87224466599992, 10.828979422000089],
              [-74.8722523109999 , 10.828961674000084],
              [-74.87225988199992, 10.828943896000055],
              [-74.87226739499994, 10.828926095000043],
              [-74.87227483899994, 10.828908261000038],
              [-74.87228220999992, 10.82889040100008 ],
              [-74.87228951799995, 10.828872515000057],
              [-74.87229675399993, 10.828854600000057],
              [-74.87230392499993, 10.828836659000046],
              [-74.87231102699991, 10.828818691000038],
              [-74.8723180579999 , 10.828800699000055],
              [-74.87232502699993, 10.82878267700005 ],
              [-74.87233192499991, 10.828764632000059],
              [-74.8723387519999 , 10.82874656100006 ],
              [-74.87234551299991, 10.82872846400005 ],
              [-74.87235220299993, 10.828710345000047],
              [-74.87235882199991, 10.828692200000091],
              [-74.87236537899992, 10.828674031000048],
              [-74.8723718629999 , 10.828655836000053],
              [-74.87237827799993, 10.828637617000084],
              [-74.87238462599993, 10.828619376000063],
              [-74.8723909009999 , 10.828601114000037],
              [-74.8723971039999 , 10.828582822000044],
              [-74.87240324599992, 10.82856451300006 ],
              [-74.8724093159999 , 10.828546180000046],
              [-74.8724153149999 , 10.828527825000037],
              [-74.8724212429999 , 10.82850944200004 ],
              [-74.87242710099991, 10.828491042000053],
              [-74.8724328909999 , 10.82847262100006 ],
              [-74.87243861199994, 10.828454178000072],
              [-74.87244426199993, 10.828435716000058],
              [-74.87244984199992, 10.82841723200005 ],
              [-74.8724553479999 , 10.828398724000067],
              [-74.87246079099992, 10.828380198000048],
              [-74.87246615599992, 10.828361653000059],
              [-74.8724714569999 , 10.828343089000043],
              [-74.87247281999993, 10.828338273000043],
              [-74.8724763539999 , 10.828320638000037],
              [-74.87248048499993, 10.828300415000058],
              [-74.87248468599995, 10.828280205000056],
              [-74.8724889579999 , 10.82826001300009 ],
              [-74.87249329899993, 10.828239832000065],
              [-74.87249771199993, 10.828219666000052],
              [-74.87250220099992, 10.828199517000087],
              [-74.87250675699994, 10.82817938100004 ],
              [-74.87251138799991, 10.828159266000057],
              [-74.87251608699995, 10.82813916300006 ],
              [-74.87252085999995, 10.828119077000053],
              [-74.8725257019999 , 10.828099009000084],
              [-74.87253061799993, 10.828078958000049],
              [-74.87253560099992, 10.828058921000093],
              [-74.87254065899992, 10.828038905000085],
              [-74.87254578499994, 10.828018908000047],
              [-74.8725509869999 , 10.827998922000063],
              [-74.8725562539999 , 10.827978960000053],
              [-74.87256159499992, 10.827959013000054],
              [-74.87256700699993, 10.827939087000061],
              [-74.8725724869999 , 10.827919181000084],
              [-74.87257804099994, 10.82789929200004 ],
              [-74.87258366099991, 10.82787942400006 ],
              [-74.87258935499995, 10.827859575000048],
              [-74.87259511899993, 10.827839746000052],
              [-74.87260095099992, 10.827819937000072],
              [-74.87260685799993, 10.82780014900004 ],
              [-74.87261282999992, 10.827780378000057],
              [-74.87261887699992, 10.827760632000093],
              [-74.87262108499993, 10.827753482000048],
              [-74.87262500399993, 10.827740867000045],
              [-74.8726311879999 , 10.827721161000056],
              [-74.87263744199993, 10.827701473000047],
              [-74.87264376799993, 10.827681811000048],
              [-74.8726501619999 , 10.827662170000053],
              [-74.87265662699991, 10.82764255300009 ],
              [-74.8726631589999 , 10.827622958000063],
              [-74.87266975799992, 10.827603389000046],
              [-74.8726764349999 , 10.827583836000088],
              [-74.87268317599995, 10.82756431200005 ],
              [-74.8726899859999 , 10.827544808000084],
              [-74.8726968659999 , 10.82752533100006 ],
              [-74.87270381499991, 10.827505874000053],
              [-74.87271083399992, 10.827486444000044],
              [-74.87271792099995, 10.827467033000062],
              [-74.87272507299991, 10.82744765700005 ],
              [-74.87273229999994, 10.82742829700004 ],
              [-74.87273959499993, 10.827408967000054],
              [-74.8727469559999 , 10.827389660000051],
              [-74.87275438699993, 10.827370381000037],
              [-74.87276188499993, 10.827351127000043],
              [-74.87276945199994, 10.827331894000054],
              [-74.8727770879999 , 10.827312695000046],
              [-74.87278478799993, 10.827293521000058],
              [-74.87279256099993, 10.827274371000044],
              [-74.8728003999999 , 10.827255248000085],
              [-74.87280830599991, 10.827236154000047],
              [-74.87281628099993, 10.827217088000054],
              [-74.87282432299992, 10.827198051000039],
              [-74.87283243299993, 10.827179039000043],
              [-74.8728406109999 , 10.827160053000057],
              [-74.87284885499992, 10.827141101000052],
              [-74.8728571659999 , 10.827122176000046],
              [-74.87286554499991, 10.827103279000085],
              [-74.8728739899999 , 10.827084411000044],
              [-74.87288250099994, 10.827065575000063],
              [-74.87289108399995, 10.827046764000045],
              [-74.87289972999992, 10.827027988000054],
              [-74.87290844199993, 10.827009240000052],
              [-74.87291722099991, 10.826990519000049],
              [-74.87292606699992, 10.826971832000083],
              [-74.87293497899992, 10.826953178000053],
              [-74.8729439569999 , 10.826934548000054],
              [-74.87295299899995, 10.82691595600005 ],
              [-74.87296210999995, 10.826897393000081],
              [-74.87297128299991, 10.826878862000058],
              [-74.87298052699992, 10.826860361000058],
              [-74.87298983399995, 10.82684189300005 ],
              [-74.87299920499993, 10.82682346100006 ],
              [-74.87300864299993, 10.82680505500008 ],
              [-74.8730181489999 , 10.826786687000038],
              [-74.8730277169999 , 10.826768352000045],
              [-74.87303734999995, 10.826750050000044],
              [-74.87304704799993, 10.826731781000092],
              [-74.87305681099991, 10.82671354200005 ],
              [-74.87306663899994, 10.826695341000061],
              [-74.87307652899995, 10.826677174000054],
              [-74.8730864879999 , 10.826659043000063],
              [-74.87309650899994, 10.826640947000044],
              [-74.87310659299993, 10.826622881000048],
              [-74.87311674299991, 10.82660485200006 ],
              [-74.87312141199993, 10.826596608000045],
              [-74.87312339499994, 10.826592017000053],
              [-74.87312895499991, 10.826579311000046],
              [-74.87313457899995, 10.826566634000073],
              [-74.87314026999991, 10.826553988000057],
              [-74.87314602799995, 10.826541373000055],
              [-74.87315185299991, 10.82652878500005 ],
              [-74.87315774399991, 10.826516224000045],
              [-74.87316369799993, 10.826503699000057],
              [-74.87316972399992, 10.826491202000057],
              [-74.87317581399992, 10.826478738000048],
              [-74.87318196399991, 10.826466306000043],
              [-74.8731881889999 , 10.82645390500005 ],
              [-74.87319447599992, 10.82644153700005 ],
              [-74.87320082699995, 10.826429202000043],
              [-74.87320724199992, 10.826416897000058],
              [-74.87321372399992, 10.826404628000091],
              [-74.8732202729999 , 10.826392393000049],
              [-74.87322688499995, 10.826380192000045],
              [-74.87323356099995, 10.826368023000043],
              [-74.87324030299993, 10.826355892000038],
              [-74.87324710699994, 10.82634379600006 ],
              [-74.87325397199993, 10.826331731000039],
              [-74.87326090699992, 10.82631970500006 ],
              [-74.87326789899993, 10.826307715000041],
              [-74.87327496099994, 10.82629576000005 ],
              [-74.87328208399992, 10.826283844000045],
              [-74.87328926899994, 10.826271964000057],
              [-74.8732965129999 , 10.82626011900004 ],
              [-74.8733038279999 , 10.826248314000054],
              [-74.87331119899994, 10.826236549000043],
              [-74.87331863599991, 10.82622481900006 ],
              [-74.8733261289999 , 10.826213129000053],
              [-74.87333369099991, 10.826201476000051],
              [-74.87334131099993, 10.826189861000046],
              [-74.87334899299992, 10.826178287000062],
              [-74.87335673599995, 10.826166758000056],
              [-74.8733645399999 , 10.826155262000043],
              [-74.87337240499994, 10.826143812000055],
              [-74.87338032999992, 10.826132401000052],
              [-74.87338831499994, 10.82612103200006 ],
              [-74.8733963599999 , 10.826109703000043],
              [-74.8734044659999 , 10.826098414000057],
              [-74.87341262899992, 10.82608717000005 ],
              [-74.87342085199992, 10.826075968000055],
              [-74.8734291369999 , 10.826064805000044],
              [-74.87343747699992, 10.826053690000037],
              [-74.8734458799999 , 10.826042615000063],
              [-74.8734543409999 , 10.826031585000067],
              [-74.87346285799993, 10.82602059900006 ],
              [-74.8734714329999 , 10.826009658000089],
              [-74.87348006799994, 10.82599876100005 ],
              [-74.87348875899994, 10.825987909000048],
              [-74.87349750699991, 10.825977101000092],
              [-74.8735063119999 , 10.825966339000047],
              [-74.87351517499991, 10.825955621000048],
              [-74.87352409499994, 10.825944951000054],
              [-74.87353306799992, 10.825934326000038],
              [-74.8735420989999 , 10.825923748000037],
              [-74.87355118499994, 10.82591321700005 ],
              [-74.87356033099991, 10.825902735000057],
              [-74.8735610949999 , 10.825901864000059],
              [-74.87356921799994, 10.825893567000037],
              [-74.87357918799995, 10.825883284000042],
              [-74.87358911299992, 10.825872952000054],
              [-74.87359897899995, 10.825862567000058],
              [-74.87360879099992, 10.825852134000058],
              [-74.87361855099994, 10.825841654000044],
              [-74.87362825699995, 10.825831125000093],
              [-74.87363790599994, 10.825820546000045],
              [-74.8736475039999 , 10.82580992000004 ],
              [-74.87365704599995, 10.825799246000088],
              [-74.87366653299995, 10.825788527000043],
              [-74.87367596699994, 10.825777754000057],
              [-74.87368534199993, 10.825766934000058],
              [-74.87369466399991, 10.825756072000047],
              [-74.87370392899993, 10.825745160000054],
              [-74.87371313799991, 10.82573420500006 ],
              [-74.87372228899994, 10.825723200000084],
              [-74.87373138599992, 10.825712151000062],
              [-74.87374042399995, 10.82570105700006 ],
              [-74.87374940299992, 10.825689916000044],
              [-74.87375832599992, 10.82567873700009 ],
              [-74.87376719499991, 10.82566750300009 ],
              [-74.8737760059999 , 10.82565623000005 ],
              [-74.87378475499992, 10.825644910000051],
              [-74.87379344899995, 10.825633548000042],
              [-74.87380208199994, 10.825622147000047],
              [-74.87381065799991, 10.825610698000048],
              [-74.87381917399995, 10.82559920500006 ],
              [-74.8738276329999 , 10.825587669000072],
              [-74.8738360289999 , 10.825576095000088],
              [-74.87384436699995, 10.825564477000057],
              [-74.87385264199992, 10.825552818000062],
              [-74.87386086499993, 10.825541113000043],
              [-74.87386902299994, 10.825529372000062],
              [-74.87387711899993, 10.82551759000006 ],
              [-74.87388515799995, 10.82550576500006 ],
              [-74.8738931339999 , 10.825493900000083],
              [-74.8739010509999 , 10.825481997000054],
              [-74.87390890299991, 10.82547005300006 ],
              [-74.87391669699991, 10.82545807200006 ],
              [-74.87392442599992, 10.82544604900005 ],
              [-74.87393209499993, 10.825433988000043],
              [-74.87393970099993, 10.82542188800005 ],
              [-74.87394724699993, 10.825409752000041],
              [-74.87395472799994, 10.825397576000057],
              [-74.87396214599994, 10.825385365000045],
              [-74.87396950599992, 10.825373118000073],
              [-74.87397679999992, 10.825360832000058],
              [-74.8739840309999 , 10.825348507000058],
              [-74.87399119799994, 10.825336151000045],
              [-74.87399829899994, 10.825323755000056],
              [-74.87400533699991, 10.825311325000087],
              [-74.87401231599995, 10.825298864000047],
              [-74.87401922899994, 10.825286362000043],
              [-74.87402607099995, 10.825273827000046],
              [-74.87403285799991, 10.825261260000048],
              [-74.8740395769999 , 10.825248655000053],
              [-74.87404622999992, 10.825236019000045],
              [-74.87405281799994, 10.82522335100009 ],
              [-74.8740593409999 , 10.825210646000073],
              [-74.87406579999993, 10.825197910000043],
              [-74.87406726799992, 10.825194990000057],
              [-74.87406997999994, 10.82518944900005 ],
              [-74.8740784549999 , 10.82517223900004 ],
              [-74.87408699499991, 10.82515505400005 ],
              [-74.87409559799994, 10.82513790300004 ],
              [-74.87410427099991, 10.82512078800005 ],
              [-74.87411300799994, 10.825103700000057],
              [-74.87412181099995, 10.825086645000056],
              [-74.87413067699993, 10.825069627000062],
              [-74.87413960999993, 10.82505264200006 ],
              [-74.87414860699994, 10.82503569000005 ],
              [-74.87415766599992, 10.825018771000089],
              [-74.87416679599994, 10.825001886000052],
              [-74.8741759859999 , 10.824985033000075],
              [-74.87418524199995, 10.824968217000048],
              [-74.8741945619999 , 10.824951436000049],
              [-74.87420394499992, 10.824934688000042],
              [-74.87421339199994, 10.824917975000062],
              [-74.8742229049999 , 10.824901302000058],
              [-74.87423248099992, 10.824884662000045],
              [-74.87424211899992, 10.82486805700006 ],
              [-74.8742518169999 , 10.824851487000046],
              [-74.87426158699992, 10.824834955000085],
              [-74.8742714139999 , 10.824818462000053],
              [-74.87428130699993, 10.824802003000059],
              [-74.87429126199993, 10.82478558300005 ],
              [-74.87430127499994, 10.824769199000059],
              [-74.87431135799994, 10.824752849000049],
              [-74.87432150099994, 10.82473654100005 ],
              [-74.87433170499992, 10.824720270000057],
              [-74.87434197099992, 10.82470403900004 ],
              [-74.87435230099993, 10.824687844000039],
              [-74.87436269199992, 10.82467169000006 ],
              [-74.87437314499994, 10.824655570000061],
              [-74.87438365699995, 10.824639492000074],
              [-74.87439423399991, 10.824623461000044],
              [-74.8744048659999 , 10.824607459000049],
              [-74.87441556499994, 10.824591501000043],
              [-74.87442632499994, 10.824575584000058],
              [-74.87443714099993, 10.82455970600006 ],
              [-74.87444802599992, 10.824543866000056],
              [-74.8744589659999 , 10.824528075000046],
              [-74.87446996699992, 10.824512317000085],
              [-74.87448102399992, 10.824496603000057],
              [-74.87449214299994, 10.82448093000005 ],
              [-74.87450332899994, 10.824465298000064],
              [-74.87451456999992, 10.824449707000042],
              [-74.87452586899991, 10.824434162000045],
              [-74.87453722899994, 10.82441865900006 ],
              [-74.87454864699993, 10.824403195000059],
              [-74.87456012399991, 10.824387775000048],
              [-74.87456107799994, 10.824386501000049],
              [-74.87456345999993, 10.824383571000055],
              [-74.87457603999991, 10.824368177000053],
              [-74.87458867499993, 10.824352831000056],
              [-74.87460136099992, 10.824337528000058],
              [-74.87461410799995, 10.824322275000043],
              [-74.87462691799993, 10.824307063000049],
              [-74.87463977999994, 10.824291900000048],
              [-74.87465269499995, 10.824276781000037],
              [-74.8746549409999 , 10.824274161000062],
              [-74.8746656749999 , 10.824261703000047],
              [-74.87467869999995, 10.824246684000059],
              [-74.87469178599991, 10.824231705000045],
              [-74.87470492299991, 10.82421677800005 ],
              [-74.87471811699993, 10.824201899000059],
              [-74.87473136499995, 10.824187066000093],
              [-74.87474466899994, 10.824172283000053],
              [-74.87475802599994, 10.824157544000059],
              [-74.87477143799993, 10.824142857000083],
              [-74.8747849049999 , 10.824128219000045],
              [-74.87479842699992, 10.824113626000042],
              [-74.874812        , 10.824099085000057],
              [-74.87482562799994, 10.824084595000045],
              [-74.87483930899992, 10.824070151000058],
              [-74.87485304599994, 10.824055758000043],
              [-74.87486683399993, 10.824041412000042],
              [-74.87488067499993, 10.82402712000004 ],
              [-74.87489456899993, 10.82401287600004 ],
              [-74.87490851399991, 10.82399868500005 ],
              [-74.87492251499992, 10.823984538000047],
              [-74.87493656499993, 10.823970450000047],
              [-74.87495066799994, 10.823956409000061],
              [-74.87496481999995, 10.823942418000058],
              [-74.8749790299999 , 10.823928478000084],
              [-74.87499328899992, 10.82391459300004 ],
              [-74.87500759699992, 10.823900756000057],
              [-74.87502195899992, 10.82388697500005 ],
              [-74.8750363659999 , 10.823873241000058],
              [-74.87505083299993, 10.823859562000052],
              [-74.87506534499994, 10.82384593200004 ],
              [-74.87507990799992, 10.82383235900005 ],
              [-74.87509452099994, 10.823818836000044],
              [-74.87510918499993, 10.82380537000006 ],
              [-74.87512389699992, 10.82379195100009 ],
              [-74.87513865599993, 10.823778588000039],
              [-74.87515347099992, 10.823765279000042],
              [-74.87516832599994, 10.823752024000044],
              [-74.87518324099995, 10.823738821000063],
              [-74.8751981989999 , 10.823725674000059],
              [-74.8752132059999 , 10.823712581000052],
              [-74.87522826299994, 10.823699542000043],
              [-74.87524336599995, 10.823686561000045],
              [-74.87525851799995, 10.823673628000051],
              [-74.87527371599992, 10.823660755000049],
              [-74.87528896299995, 10.82364793000005 ],
              [-74.87530425599994, 10.823635170000045],
              [-74.87531959699993, 10.823622456000066],
              [-74.87533498599993, 10.823609805000046],
              [-74.87535042099995, 10.823597205000056],
              [-74.87536590099995, 10.823584665000055],
              [-74.8753814289999 , 10.823572178000063],
              [-74.87539700199994, 10.823559746000058],
              [-74.87541262299993, 10.823547373000054],
              [-74.87542828699992, 10.823535058000061],
              [-74.87543579699991, 10.823529183000062],
              [-74.87544940099991, 10.82351585300006 ],
              [-74.87546613599994, 10.823499360000085],
              [-74.87548282599994, 10.823482819000048],
              [-74.87549946099995, 10.823466221000047],
              [-74.87551603999992, 10.823449573000062],
              [-74.87553257099995, 10.823432879000052],
              [-74.87554904699994, 10.823416131000045],
              [-74.87556547699995, 10.823399334000044],
              [-74.8755818489999 , 10.82338248700006 ],
              [-74.87559816999993, 10.823365589000048],
              [-74.87561443599992, 10.823348640000063],
              [-74.8756306489999 , 10.823331645000053],
              [-74.87564680699995, 10.823314597000092],
              [-74.87566291599995, 10.82329750200006 ],
              [-74.87567896899992, 10.82328035200004 ],
              [-74.87569496899994, 10.823263161000057],
              [-74.8757109149999 , 10.823245917000065],
              [-74.87572680199992, 10.823228627000049],
              [-74.87574264099993, 10.823211282000045],
              [-74.87575842399991, 10.823193895000088],
              [-74.87577415299995, 10.823176457000045],
              [-74.87578982499991, 10.82315897300009 ],
              [-74.87580544199994, 10.823141436000071],
              [-74.87582100799995, 10.823123856000052],
              [-74.87583651499995, 10.823106232000043],
              [-74.87585196899994, 10.823088554000037],
              [-74.8758673669999 , 10.823070832000042],
              [-74.87588270899994, 10.823053060000063],
              [-74.8758979939999 , 10.823035244000039],
              [-74.87591322299994, 10.823017378000088],
              [-74.87592839699994, 10.822999466000056],
              [-74.87594351699994, 10.82298151000009 ],
              [-74.87595857899993, 10.822963507000054],
              [-74.87597358199992, 10.82294545700006 ],
              [-74.87598853399993, 10.82292736700009 ],
              [-74.87600342499991, 10.822909225000046],
              [-74.8760182609999 , 10.82289103700009 ],
              [-74.87603303899994, 10.822872803000052],
              [-74.87604775999995, 10.82285452800005 ],
              [-74.87606242399994, 10.82283620600009 ],
              [-74.87607702899993, 10.822817839000038],
              [-74.87609157499992, 10.822799428000053],
              [-74.87610606999993, 10.822780976000047],
              [-74.8761204999999 , 10.822762473000068],
              [-74.87613487499993, 10.822743932000037],
              [-74.87614919299995, 10.822725343000059],
              [-74.87616345099991, 10.822706709000045],
              [-74.8761776529999 , 10.822688035000056],
              [-74.8761917889999 , 10.822669319000056],
              [-74.87620587599991, 10.822650554000063],
              [-74.87620762499995, 10.822648216000061],
              [-74.87621864499994, 10.822634816000061],
              [-74.87623159599991, 10.822618962000092],
              [-74.87624448699995, 10.822603063000088],
              [-74.8762573269999 , 10.822587119000048],
              [-74.87627010299991, 10.82257112600007 ],
              [-74.87628282899993, 10.822555095000041],
              [-74.87629548899992, 10.82253901200005 ],
              [-74.8763080999999 , 10.822522891000062],
              [-74.87632064399992, 10.82250672300006 ],
              [-74.87633313399994, 10.822490512000059],
              [-74.87634556899991, 10.822474252000063],
              [-74.87635794199991, 10.82245795600005 ],
              [-74.87637025899994, 10.822441612000091],
              [-74.87638251399994, 10.822425227000053],
              [-74.87639471199992, 10.822408798000083],
              [-74.87640684999991, 10.822392327000045],
              [-74.87641892799991, 10.822375814000054],
              [-74.87643094599991, 10.822359258000063],
              [-74.87644290599991, 10.82234266000006 ],
              [-74.87645480799995, 10.822326020000048],
              [-74.87646664699992, 10.822309337000092],
              [-74.8764784249999 , 10.822292613000059],
              [-74.8764901479999 , 10.822275847000071],
              [-74.87650180299994, 10.822259042000042],
              [-74.8765134059999 , 10.822242197000037],
              [-74.87652494399993, 10.822225313000047],
              [-74.8765364219999 , 10.822208381000053],
              [-74.87654783999994, 10.822191418000045],
              [-74.87655919299993, 10.82217441000006 ],
              [-74.8765704889999 , 10.822157361000052],
              [-74.8765817229999 , 10.822140273000059],
              [-74.8765928919999 , 10.82212314800006 ],
              [-74.87660400599992, 10.82210598000006 ],
              [-74.87661505599993, 10.822088776000044],
              [-74.87662604099995, 10.822071538000046],
              [-74.87663696399994, 10.82205425300009 ],
              [-74.87664782599995, 10.82203693200006 ],
              [-74.87665862999995, 10.82201957600006 ],
              [-74.87666936799991, 10.822002178000048],
              [-74.87668004199992, 10.821984746000055],
              [-74.87669065199992, 10.821967277000056],
              [-74.87670120199994, 10.82194977100005 ],
              [-74.87671169099991, 10.82193222300009 ],
              [-74.87672211299991, 10.821914640000045],
              [-74.87673247299995, 10.821897026000045],
              [-74.87674276999991, 10.821879368000054],
              [-74.87675300399991, 10.821861681000087],
              [-74.87676317499995, 10.821843953000041],
              [-74.87677328299992, 10.82182619300005 ],
              [-74.8767833249999 , 10.821808395000062],
              [-74.87679330199995, 10.821790563000093],
              [-74.87680321699992, 10.821772696000039],
              [-74.87681306499991, 10.82175479500006 ],
              [-74.87682285299991, 10.821736860000044],
              [-74.87683257299994, 10.821718889000067],
              [-74.87684222999991, 10.821700885000041],
              [-74.87685182199994, 10.821682845000055],
              [-74.87686134799992, 10.821664774000055],
              [-74.87687080699993, 10.821646671000053],
              [-74.8768802049999 , 10.821628531000044],
              [-74.87688953499992, 10.82161035900009 ],
              [-74.87689880399995, 10.821592157000055],
              [-74.87690800399992, 10.821573919000059],
              [-74.87691713999993, 10.82155564900006 ],
              [-74.87692620999991, 10.821537350000085],
              [-74.87693521299991, 10.82151901800006 ],
              [-74.87694415099992, 10.821500659000037],
              [-74.87695302299994, 10.82148225900005 ],
              [-74.87696182699995, 10.821463833000053],
              [-74.87697056799993, 10.821445380000057],
              [-74.87697923899992, 10.821426891000044],
              [-74.87698784699995, 10.821408374000043],
              [-74.87699056899993, 10.821402483000043],
              [-74.87699350299994, 10.821394335000036],
              [-74.87700186899991, 10.821371259000045],
              [-74.8770103029999 , 10.82134820400006 ],
              [-74.87701880899994, 10.821325175000084],
              [-74.8770273799999 , 10.821302174000039],
              [-74.87703602399995, 10.821279194000056],
              [-74.8770447359999 , 10.821256239000093],
              [-74.87705351199992, 10.821233309000093],
              [-74.8770623609999 , 10.82121040900006 ],
              [-74.87707127699991, 10.821187534000046],
              [-74.8770802599999 , 10.821164681000084],
              [-74.87708931199995, 10.821141857000043],
              [-74.87709843399995, 10.821119061000047],
              [-74.87710761999995, 10.821096290000071],
              [-74.87711687699993, 10.821073544000058],
              [-74.87712619799993, 10.821050830000047],
              [-74.87713559299993, 10.821028137000042],
              [-74.87714505399993, 10.821005477000085],
              [-74.87715457999991, 10.820982841000045],
              [-74.8771641749999 , 10.82096023300005 ],
              [-74.87717383699993, 10.820937657000059],
              [-74.87718356599993, 10.820915109000055],
              [-74.87719336399994, 10.820892582000056],
              [-74.87720322399991, 10.820870095000089],
              [-74.8772131579999 , 10.820847627000092],
              [-74.87722315499991, 10.820825192000086],
              [-74.87723321999994, 10.820802786000058],
              [-74.8772433499999 , 10.820780410000054],
              [-74.87725354799994, 10.820758065000064],
              [-74.87726381199991, 10.82073574900005 ],
              [-74.87727414699992, 10.820713463000061],
              [-74.87728454599994, 10.82069120500006 ],
              [-74.87729501199993, 10.820668979000061],
              [-74.87730553999995, 10.82064678200004 ],
              [-74.87731613699992, 10.820624619000057],
              [-74.87732680099992, 10.820602485000052],
              [-74.87733752999992, 10.820580383000049],
              [-74.87734832399991, 10.82055831100007 ],
              [-74.87735918599992, 10.820536274000062],
              [-74.87737011399992, 10.820514266000089],
              [-74.8773811069999 , 10.82049229100005 ],
              [-74.87739216499995, 10.820470344000057],
              [-74.87740328599995, 10.82044843500006 ],
              [-74.87741447399992, 10.820426556000086],
              [-74.87742572999991, 10.820404710000048],
              [-74.87743704899992, 10.820382897000059],
              [-74.8774484299999 , 10.82036111800005 ],
              [-74.87745988299991, 10.820339371000046],
              [-74.8774713979999 , 10.820317660000057],
              [-74.87748297699994, 10.820295981000072],
              [-74.87749461899995, 10.820274332000054],
              [-74.87750632899991, 10.820252724000056],
              [-74.8775181019999 , 10.820231143000058],
              [-74.87752993799995, 10.82020960500006 ],
              [-74.87754183699991, 10.820188095000049],
              [-74.87755380599992, 10.820166622000045],
              [-74.87756583599992, 10.820145187000037],
              [-74.87757792299993, 10.820123782000053],
              [-74.87759008499995, 10.82010241200004 ],
              [-74.87760230499993, 10.820081082000058],
              [-74.8776145889999 , 10.820059787000048],
              [-74.87762693899992, 10.82003852400004 ],
              [-74.87763934899994, 10.820017302000053],
              [-74.87765182599992, 10.819996113000059],
              [-74.87765211399994, 10.819995620000043],
              [-74.8776673559999 , 10.819973295000068],
              [-74.8776886679999 , 10.81994220200005 ],
              [-74.87771003199992, 10.819911149000063],
              [-74.8777314649999 , 10.819880141000056],
              [-74.87775295199992, 10.819849171000044],
              [-74.87777450099992, 10.819818239000085],
              [-74.87779611199994, 10.819787352000048],
              [-74.8778177829999 , 10.81975649900005 ],
              [-74.87783951099993, 10.81972569700008 ],
              [-74.87786130299992, 10.81969493300005 ],
              [-74.8778831489999 , 10.819664207000073],
              [-74.87790506099992, 10.819633525000086],
              [-74.87792703199995, 10.819602884000062],
              [-74.87794906099992, 10.819572287000085],
              [-74.87797114499995, 10.81954173400004 ],
              [-74.8779932999999 , 10.819511220000038],
              [-74.87801550299992, 10.819480747000057],
              [-74.87803776999993, 10.819450317000076],
              [-74.87806009999991, 10.819419929000048],
              [-74.87808247999993, 10.819389586000057],
              [-74.8781049239999 , 10.81935928200005 ],
              [-74.87812743099994, 10.819329025000059],
              [-74.87814999299991, 10.819298808000042],
              [-74.87817261499993, 10.81926863600006 ],
              [-74.87819529199993, 10.81923850600009 ],
              [-74.87821803199995, 10.819208421000042],
              [-74.87824082999992, 10.819178377000071],
              [-74.87826368499992, 10.819148379000069],
              [-74.87828659799993, 10.819118421000042],
              [-74.87830956999994, 10.81908850800005 ],
              [-74.8783326009999 , 10.819058638000058],
              [-74.87835568999992, 10.819028819000039],
              [-74.8783788369999 , 10.818999034000058],
              [-74.8784020409999 , 10.818969302000085],
              [-74.8784253039999 , 10.818939607000061],
              [-74.8784475779999 , 10.81891128600006 ],
              [-74.87845566699991, 10.818901337000057],
              [-74.87848045899995, 10.818870759000049],
              [-74.87850519299991, 10.818840137000052],
              [-74.87852987399992, 10.818809473000044],
              [-74.87855449199992, 10.818778759000054],
              [-74.87857905699991, 10.818747998000049],
              [-74.87860356399995, 10.818717198000058],
              [-74.87862801299991, 10.818686352000043],
              [-74.8786524009999 , 10.818655460000059],
              [-74.87867673699992, 10.818624528000043],
              [-74.87870101399994, 10.818593545000056],
              [-74.87872522999993, 10.818562521000047],
              [-74.8787493879999 , 10.818531453000048],
              [-74.87877349199994, 10.81850034100006 ],
              [-74.87879753699991, 10.818469183000047],
              [-74.87882151799994, 10.818437986000049],
              [-74.87884544899993, 10.818406740000057],
              [-74.87886931699995, 10.818375452000055],
              [-74.8788931279999 , 10.818344121000052],
              [-74.87891687799993, 10.81831274800004 ],
              [-74.87894056899995, 10.818281330000048],
              [-74.87896420499993, 10.818249870000045],
              [-74.87898778199991, 10.818218366000053],
              [-74.87901129799991, 10.818186819000061],
              [-74.87903475599995, 10.818155231000048],
              [-74.87905815499994, 10.818123598000057],
              [-74.87908149299994, 10.818091924000043],
              [-74.87910477499992, 10.818060204000062],
              [-74.87912799299994, 10.81802844300006 ],
              [-74.87915115499993, 10.817996644000061],
              [-74.87917426099995, 10.817964797000059],
              [-74.87919730199991, 10.81793291200006 ],
              [-74.8792202859999 , 10.817900980000047],
              [-74.8792432059999 , 10.817869010000038],
              [-74.87926607399993, 10.817837001000044],
              [-74.8792888779999 , 10.817804945000091],
              [-74.87931161999995, 10.81777284900005 ],
              [-74.87933430699991, 10.817740713000092],
              [-74.87935692699995, 10.817708537000044],
              [-74.87937949199994, 10.817676314000039],
              [-74.87940199899992, 10.817644054000084],
              [-74.8794244419999 , 10.817611752000062],
              [-74.87944682099993, 10.817579411000054],
              [-74.87946914899993, 10.817547026000057],
              [-74.87949140899991, 10.817514602000074],
              [-74.8795136089999 , 10.817482139000049],
              [-74.87953575299991, 10.81744963300008 ],
              [-74.87955782999995, 10.81741708900006 ],
              [-74.87957985299994, 10.81738450100005 ],
              [-74.87960181299991, 10.817351875000043],
              [-74.87960463799993, 10.817347667000092],
              [-74.8796084889999 , 10.817341920000047],
              [-74.87963217599992, 10.817306482000049],
              [-74.87965580399992, 10.817271007000045],
              [-74.8796793699999 , 10.817235488000051],
              [-74.87970287499991, 10.81719993900009 ],
              [-74.87972631899993, 10.817164338000055],
              [-74.87974969999993, 10.817128707000052],
              [-74.8797730199999 , 10.81709303200006 ],
              [-74.87979627999994, 10.81705732100005 ],
              [-74.87981947799994, 10.817021565000061],
              [-74.87984261299994, 10.816985774000045],
              [-74.87986568799994, 10.81694994500009 ],
              [-74.87988870099991, 10.816914077000092],
              [-74.87991165299991, 10.816878169000063],
              [-74.87993453699994, 10.816842223000037],
              [-74.87995736699992, 10.816806237000037],
              [-74.87998013299995, 10.816770218000045],
              [-74.8800028359999 , 10.816734155000063],
              [-74.88002547499991, 10.816698057000053],
              [-74.88004805399993, 10.81666191700009 ],
              [-74.88007057099992, 10.816625742000042],
              [-74.88009302199993, 10.816589529000055],
              [-74.8801154169999 , 10.816553279000061],
              [-74.88013774399991, 10.81651699300005 ],
              [-74.88016001199992, 10.816480665000086],
              [-74.88018221099992, 10.816444300000057],
              [-74.88020435599992, 10.816407900000058],
              [-74.8802264329999 , 10.816371462000063],
              [-74.8802484499999 , 10.81633498900004 ],
              [-74.88027040299994, 10.816298475000053],
              [-74.8802922939999 , 10.816261925000049],
              [-74.88031411799994, 10.816225337000049],
              [-74.88033588299993, 10.816188716000056],
              [-74.88035758599995, 10.816152059000046],
              [-74.88037922299992, 10.816115363000051],
              [-74.88040079699994, 10.81607863000005 ],
              [-74.8804223059999 , 10.816041860000041],
              [-74.88044375599992, 10.81600505700004 ],
              [-74.8804651399999 , 10.815968216000044],
              [-74.88047786299995, 10.815946231000055],
              [-74.88048012899992, 10.815942377000056],
              [-74.88048017699992, 10.81594229700005 ],
              [-74.8805163959999 , 10.815880768000056],
              [-74.88054220499993, 10.815836913000055],
              [-74.88054224699994, 10.815836838000052],
              [-74.88057843199994, 10.815775288000054],
              [-74.8806042189999 , 10.815731406000054],
              [-74.8806042519999 , 10.815731343000039],
              [-74.88064040299992, 10.815669770000056],
              [-74.88066616799995, 10.815625862000047],
              [-74.88066619699993, 10.815625812000064],
              [-74.88070230999995, 10.815564214000062],
              [-74.88072805299993, 10.815520290000052],
              [-74.88072808299995, 10.815520241000058],
              [-74.88076415699993, 10.81545862300004 ],
              [-74.88078989599995, 10.815414643000054],
              [-74.88082593899992, 10.815352997000048],
              [-74.88085165299992, 10.815309003000039],
              [-74.88088765599991, 10.815247339000052],
              [-74.88091334299992, 10.815203324000038],
              [-74.88094931199993, 10.815141639000046],
              [-74.88097497299992, 10.815097607000041],
              [-74.88101089999992, 10.815035900000055],
              [-74.88103653899992, 10.814991857000052],
              [-74.88107243199994, 10.81493013100004 ],
              [-74.8810980419999 , 10.814886071000046],
              [-74.88113389599994, 10.814824327000053],
              [-74.88115948299992, 10.814780246000055],
              [-74.88119529699992, 10.814718482000046],
              [-74.88120837999992, 10.814695913000037],
              [-74.8812208569999 , 10.81467438900006 ],
              [-74.88125663399995, 10.814612603000057],
              [-74.88128216899992, 10.814568490000056],
              [-74.8813179039999 , 10.814506691000076],
              [-74.88134341799991, 10.814462560000038],
              [-74.88137911699994, 10.814400741000043],
              [-74.88140459599992, 10.814356605000057],
              [-74.8814046249999 , 10.814356559000089],
              [-74.88144026299994, 10.81429475100009 ],
              [-74.88146520099991, 10.814251497000043],
              [-74.88147299699995, 10.814238860000046],
              [-74.88148424799994, 10.814220721000083],
              [-74.8814955599999 , 10.814202625000064],
              [-74.88150693599994, 10.81418456700004 ],
              [-74.8815183719999 , 10.81416654900005 ],
              [-74.88152987099994, 10.81414856400005 ],
              [-74.88154143199995, 10.814130619000082],
              [-74.88155305499993, 10.814112717000057],
              [-74.88156473799995, 10.814094856000054],
              [-74.88157648099991, 10.814077028000042],
              [-74.88158829199995, 10.814059246000056],
              [-74.8816001589999 , 10.814041497000062],
              [-74.8816120859999 , 10.814023792000057],
              [-74.88162407699991, 10.81400612400006 ],
              [-74.88163612699992, 10.81398850100004 ],
              [-74.88164823799991, 10.813970917000063],
              [-74.88166040899995, 10.81395337300006 ],
              [-74.88167263999992, 10.813935872000059],
              [-74.88168493199993, 10.813918409000053],
              [-74.8816972809999 , 10.813900988000057],
              [-74.88170969599992, 10.81388361200004 ],
              [-74.88172216499993, 10.813866275000066],
              [-74.88173469499992, 10.81384898400006 ],
              [-74.88174728999991, 10.81383173100005 ],
              [-74.88175993899995, 10.813814518000072],
              [-74.88177264699993, 10.813797350000073],
              [-74.88178541299993, 10.813780226000063],
              [-74.8817982409999 , 10.813763146000042],
              [-74.88181112399991, 10.813746111000057],
              [-74.88182406999994, 10.813729114000068],
              [-74.88183707299993, 10.813712163000048],
              [-74.88185013399993, 10.813695257000063],
              [-74.8818632519999 , 10.813678397000047],
              [-74.88187642899993, 10.813661578000051],
              [-74.8818896649999 , 10.813644802000056],
              [-74.88190295499993, 10.81362807100004 ],
              [-74.88191630199992, 10.813611386000048],
              [-74.88192971199993, 10.81359474900006 ],
              [-74.88194317499995, 10.813578151000058],
              [-74.88195669599992, 10.813561600000071],
              [-74.88197027399991, 10.813545100000056],
              [-74.8819839109999 , 10.813528637000047],
              [-74.88199759899993, 10.813512227000047],
              [-74.88201134799993, 10.813495863000071],
              [-74.88202515199993, 10.813479543000085],
              [-74.88203901199995, 10.813463270000057],
              [-74.8820529269999 , 10.813447044000043],
              [-74.88206689499992, 10.813430864000054],
              [-74.88208092499991, 10.813414733000059],
              [-74.88209500699992, 10.813398646000053],
              [-74.88210914599995, 10.813382606000062],
              [-74.8821233349999 , 10.813366618000089],
              [-74.88213758799992, 10.813350678000063],
              [-74.88215189199991, 10.813334780000048],
              [-74.88216624699993, 10.81331893500004 ],
              [-74.88218065699994, 10.813303136000059],
              [-74.8821951239999 , 10.81328738600007 ],
              [-74.88220964499993, 10.813271688000043],
              [-74.8822242199999 , 10.813256036000041],
              [-74.88223884599995, 10.813240437000047],
              [-74.88225352799992, 10.813224881000053],
              [-74.88226825999993, 10.813209381000092],
              [-74.88228305099994, 10.813193927000043],
              [-74.8822978899999 , 10.813178521000054],
              [-74.88230902399994, 10.81316703400006 ],
              [-74.88233915199993, 10.813125097000068],
              [-74.88152125199991, 10.813118054000086],
              [-74.8807424229999 , 10.813111348000064],
              [-74.87939013399995, 10.813043450000066],
              [-74.87942489399995, 10.812914094000064],
              [-74.87947417499993, 10.812713461000044],
              [-74.87953984299992, 10.812446120000061],
              [-74.87957736899995, 10.812293347000093],
              [-74.87958784999995, 10.812132475000055],
              [-74.87959622599993, 10.812003914000059],
              [-74.8796965709999 , 10.811575494000067],
              [-74.87939702699992, 10.811240096000063],
              [-74.87922564099995, 10.81104819300009 ],
              [-74.87920084199993, 10.811020425000038],
              [-74.87950088199995, 10.810719888000051],
              [-74.87972129299993, 10.810499112000059],
              [-74.87972628299991, 10.810494113000061],
              [-74.87996619299992, 10.81025380400007 ],
              [-74.8802310719999 , 10.809988476000058],
              [-74.88024500499995, 10.809974520000083],
              [-74.88024432499992, 10.809955737000053],
              [-74.88021766499992, 10.80921928400005 ],
              [-74.8802148499999 , 10.809141650000072],
              [-74.87732816699992, 10.80810630700006 ],
              [-74.87661062199993, 10.80784894900006 ],
              [-74.87658712799993, 10.807840523000039],
              [-74.87671779899995, 10.807545953000044],
              [-74.8768208489999 , 10.807370516000049],
              [-74.87693272699994, 10.807222868000053],
              [-74.87701369199993, 10.80711602000008 ],
              [-74.87710421399993, 10.80701126200006 ],
              [-74.87719330499993, 10.80695558900004 ],
              [-74.87721575099994, 10.806941560000041],
              [-74.87728033099995, 10.806901197000059],
              [-74.87737077499992, 10.806783628000062],
              [-74.8774826309999 , 10.806638225000086],
              [-74.87763331399992, 10.806399150000061],
              [-74.87775645099993, 10.80627462600006 ],
              [-74.87792015699995, 10.806228911000062],
              [-74.8780865459999 , 10.806214258000068],
              [-74.87818590199993, 10.806205508000062],
              [-74.87844438999991, 10.806198083000083],
              [-74.87871739799994, 10.806160941000087],
              [-74.87892053099995, 10.806120502000056],
              [-74.87917742999991, 10.806069360000038],
              [-74.87941353999992, 10.80600869500006 ],
              [-74.87972125199991, 10.805843379000066],
              [-74.87996471699995, 10.805770254000038],
              [-74.88016714499992, 10.805765312000062],
              [-74.88045355799994, 10.805758326000046],
              [-74.8807752539999 , 10.805728901000066],
              [-74.88107727199991, 10.805637816000058],
              [-74.8811012569999 , 10.80562679400009 ],
              [-74.88133040299994, 10.805521491000093],
              [-74.88138844799994, 10.805494813000053],
              [-74.88139324699995, 10.805492608000066],
              [-74.88148522699993, 10.805450351000047],
              [-74.88149761099993, 10.805443640000078],
              [-74.8816708029999 , 10.805349796000087],
              [-74.88191661399992, 10.805266285000073],
              [-74.88212308999994, 10.805168352000067],
              [-74.88247283299995, 10.805023580000068],
              [-74.88263032999993, 10.80497553300006 ],
              [-74.88290752199993, 10.804890969000041],
              [-74.88316203699992, 10.80481332100004 ],
              [-74.8831894519999 , 10.804804957000044],
              [-74.88353852099993, 10.804739934000054],
              [-74.8840292669999 , 10.804648519000068],
              [-74.8840848939999 , 10.804638158000046],
              [-74.88409219099992, 10.80467741700005 ],
              [-74.88411298599993, 10.804673638000054],
              [-74.8842828519999 , 10.804616764000059],
              [-74.88436195699995, 10.804598662000046],
              [-74.88436254399994, 10.804593655000076],
              [-74.8851193129999 , 10.798153906000039],
              [-74.88513802099993, 10.797994707000043],
              [-74.88508612499993, 10.797994524000046],
              [-74.88496186899994, 10.797994083000049],
              [-74.8807525929999 , 10.797979474000044],
              [-74.88042254999993, 10.797760654000058],
              [-74.87714592499992, 10.795588233000046],
              [-74.87686545999992, 10.795513595000045],
              [-74.87673432899993, 10.795419141000082],
              [-74.87666080999992, 10.79536618600008 ],
              [-74.87665931799995, 10.79536511100008 ],
              [-74.87690465599991, 10.794780653000089],
              [-74.87690478399992, 10.794780350000053],
              [-74.87767341299991, 10.792949284000088],
              [-74.87768236599993, 10.792927956000085],
              [-74.8790216619999 , 10.792040919000044],
              [-74.88157589799994, 10.793819466000059],
              [-74.88165168499995, 10.79362451500009 ],
              [-74.8816516999999 , 10.793624475000058],
              [-74.88281079399991, 10.79064285700008 ],
              [-74.88281965599992, 10.790620059000048],
              [-74.88293819099994, 10.79061052500009 ],
              [-74.88328518599991, 10.790582615000062],
              [-74.88380553699994, 10.790540753000073],
              [-74.88427040399995, 10.79072463500006 ],
              [-74.88428618299992, 10.790730877000044],
              [-74.88464605599995, 10.789623255000038],
              [-74.88467041099995, 10.78954830500004 ],
              [-74.88477175199995, 10.789236410000058],
              [-74.88494890099992, 10.788908441000046],
              [-74.88495160499991, 10.78885692600005 ],
              [-74.88496663299992, 10.788743323000062],
              [-74.88497530599994, 10.78867772700005 ],
              [-74.88498489799991, 10.788562846000048],
              [-74.88502474199993, 10.788434264000045],
              [-74.88503887099995, 10.788363055000048],
              [-74.88511825699993, 10.788220804000048],
              [-74.88517191699992, 10.788142823000044],
              [-74.88536215599993, 10.788283574000047],
              [-74.8853948439999 , 10.78822391500006 ],
              [-74.88541119299992, 10.788194090000047],
              [-74.8854811299999 , 10.788090155000077],
              [-74.88551151699994, 10.788044997000043],
              [-74.88553880299992, 10.78797858200005 ],
              [-74.88554186599993, 10.787949285000082],
              [-74.88558391399994, 10.787547109000059],
              [-74.8855919099999 , 10.787481575000072],
              [-74.88562174299994, 10.787359390000063],
              [-74.88562431999992, 10.787230045000058],
              [-74.88566529999991, 10.786944634000065],
              [-74.8857817199999 , 10.786169032000089],
              [-74.88580189299995, 10.786085244000049],
              [-74.88582791999994, 10.785999490000052],
              [-74.88585051399991, 10.785913483000058],
              [-74.88590882299991, 10.785789008000052],
              [-74.88591820799991, 10.78577269200008 ],
              [-74.88594082999992, 10.785733359000062],
              [-74.8860062469999 , 10.78561908000006 ],
              [-74.88607967899992, 10.78552039400006 ],
              [-74.88616155499994, 10.785415490000048],
              [-74.88621776399992, 10.78536464800004 ],
              [-74.88623130199994, 10.785344673000054],
              [-74.88626727699994, 10.785261569000056],
              [-74.88629637299994, 10.785184735000087],
              [-74.8863173009999 , 10.785120620000043],
              [-74.88635003899992, 10.78506548200005 ],
              [-74.88637005299995, 10.785004636000053],
              [-74.88638216499993, 10.784960383000055],
              [-74.88640542299993, 10.784875404000047],
              [-74.8865016229999 , 10.784598406000043],
              [-74.88655502599994, 10.78444464000006 ],
              [-74.8866198209999 , 10.784301507000066],
              [-74.88664395299992, 10.784221995000053],
              [-74.88667571799994, 10.784113059000049],
              [-74.88668154899995, 10.78408464000006 ],
              [-74.88669430999994, 10.783987766000052],
              [-74.88668308099994, 10.783838432000039],
              [-74.88668430199994, 10.78382904600005 ],
              [-74.88668925099995, 10.783791188000066],
              [-74.88669975399995, 10.783710833000043],
              [-74.88625738899992, 10.783686721000038],
              [-74.88625729799992, 10.783686721000038],
              [-74.8861697769999 , 10.783681947000048],
              [-74.88616971699992, 10.783681947000048],
              [-74.88616964199991, 10.783681926000042],
              [-74.88612666699993, 10.783669982000049],
              [-74.88610482899992, 10.783663918000059],
              [-74.88590377999992, 10.783608067000046],
              [-74.88554351099992, 10.78350798200006 ],
              [-74.88547844599992, 10.783505413000057],
              [-74.88536334199995, 10.78346883200004 ],
              [-74.88530490799991, 10.783453583000039],
              [-74.88459611099995, 10.78316161400005 ],
              [-74.8841527749999 , 10.782978997000043],
              [-74.8830449479999 , 10.782906537000088],
              [-74.8828260709999 , 10.782800016000067],
              [-74.8827446379999 , 10.782772915000066],
              [-74.88213015199995, 10.782409776000065],
              [-74.88138708299994, 10.781970649000073],
              [-74.88090320899994, 10.78168469700006 ],
              [-74.8808944939999 , 10.781679548000056],
              [-74.88054291599991, 10.781471774000067],
              [-74.88054928299994, 10.781463905000066],
              [-74.8806952939999 , 10.781283416000065],
              [-74.88069854499992, 10.781279398000038],
              [-74.88071659799994, 10.781243964000055],
              [-74.88075272899994, 10.781173052000042],
              [-74.88121718699995, 10.780221788000063],
              [-74.88143731999992, 10.779770935000045],
              [-74.8820671119999 , 10.778481033000048],
              [-74.88248002599994, 10.777635331000056],
              [-74.8827808449999 , 10.777019220000057],
              [-74.88304789199992, 10.777116546000059],
              [-74.88395656299991, 10.775340380000046],
              [-74.88391481499991, 10.775305777000085],
              [-74.88389623699993, 10.775298830000054],
              [-74.88387542799995, 10.775259694000056],
              [-74.88387323799992, 10.775204535000057],
              [-74.88390645999993, 10.774940341000047],
              [-74.8839228839999 , 10.774880624000048],
              [-74.88397661699992, 10.774772767000059],
              [-74.88402328099994, 10.774701657000037],
              [-74.88404426699992, 10.774669557000038],
              [-74.88410951899994, 10.774605389000044],
              [-74.88416307299991, 10.774568773000055],
              [-74.8842445869999 , 10.774506961000043],
              [-74.8843004979999 , 10.774454269000046],
              [-74.88437273599993, 10.77438094100006 ],
              [-74.88443332399993, 10.774316763000058],
              [-74.88447063299992, 10.77426631500009 ],
              [-74.88452665199992, 10.77417685100005 ],
              [-74.88457803199992, 10.77408276500006 ],
              [-74.88460607799993, 10.774018501000057],
              [-74.8846318759999 , 10.773931248000054],
              [-74.88466010299993, 10.77380032800005 ],
              [-74.88466031399992, 10.773715290000041],
              [-74.88467691999995, 10.773582049000083],
              [-74.8846865349999 , 10.773457965000091],
              [-74.88469372999992, 10.77337525200005 ],
              [-74.88470795199993, 10.773264978000043],
              [-74.88472673699994, 10.773189195000043],
              [-74.88473855399991, 10.773113394000063],
              [-74.88475749799994, 10.772973250000064],
              [-74.88483031599992, 10.77267699400005 ],
              [-74.88488418999992, 10.772516275000044],
              [-74.88489269599995, 10.772491250000087],
              [-74.88490760199994, 10.77244739300005 ],
              [-74.88491478999993, 10.772364681000056],
              [-74.8849056439999 , 10.772307194000064],
              [-74.88478292999991, 10.77212757500007 ],
              [-74.88434249599993, 10.771666628000048],
              [-74.8837723229999 , 10.771051318000048],
              [-74.88350364599995, 10.770694301000049],
              [-74.88346894299991, 10.770629848000056],
              [-74.88345509699991, 10.77059074500005 ],
              [-74.88345050499993, 10.770572340000058],
              [-74.88343900199993, 10.770524046000048],
              [-74.88343677699993, 10.770484964000048],
              [-74.88343691799992, 10.770429815000057],
              [-74.88343936799993, 10.770383854000045],
              [-74.88345819099993, 10.770291976000067],
              [-74.88351262399993, 10.769912932000068],
              [-74.88361120699994, 10.769538608000062],
              [-74.88366517199995, 10.76933652200006 ],
              [-74.8836958839999 , 10.769143557000064],
              [-74.88375705599992, 10.76885416500005 ],
              [-74.88379510899995, 10.768514135000089],
              [-74.88379866699995, 10.76803151200005 ],
              [-74.88383525099994, 10.767360537000059],
              [-74.8837494409999 , 10.767289038000058],
              [-74.8837567089999 , 10.76717645900004 ],
              [-74.8836810809999 , 10.766757945000052],
              [-74.88365348399992, 10.766632185000049],
              [-74.8833893289999 , 10.76542869900004 ],
              [-74.88317106699992, 10.764446717000055],
              [-74.8840204679999 , 10.764524639000058],
              [-74.88428406199995, 10.764548817000048],
              [-74.88547824299991, 10.76470861200005 ],
              [-74.88569175399994, 10.764833339000063],
              [-74.88648524199994, 10.765366569000037],
              [-74.88697943199992, 10.765698967000048],
              [-74.88826423299992, 10.76587509300009 ],
              [-74.88905443599992, 10.765877412000066],
              [-74.8904048089999 , 10.765858384000069],
              [-74.89090445399995, 10.76587593100004 ],
              [-74.89178026999991, 10.766025582000054],
              [-74.8921357129999 , 10.76608636900005 ],
              [-74.89233087999992, 10.76610991800004 ],
              [-74.8925236149999 , 10.766174836000062],
              [-74.8926676719999 , 10.76619363900005 ],
              [-74.89283283399993, 10.766238343000055],
              [-74.8928650819999 , 10.766247070000077],
              [-74.89289485799992, 10.766248321000091],
              [-74.89290254699995, 10.766248644000086],
              [-74.89294363899995, 10.766250368000044],
              [-74.89294834099991, 10.766250567000043],
              [-74.89295934799992, 10.766210031000071],
              [-74.89296399299991, 10.766193612000052],
              [-74.8929677239999 , 10.766180432000056],
              [-74.8929688959999 , 10.766176309000059],
              [-74.89296959399991, 10.766173853000055],
              [-74.89297487699992, 10.766155136000066],
              [-74.89298386799993, 10.766123371000049],
              [-74.89300667099991, 10.766042807000076],
              [-74.89304394699991, 10.76585870200006 ],
              [-74.89309164199994, 10.765656585000045],
              [-74.89309849099993, 10.765493425000045],
              [-74.89308084399994, 10.765261205000058],
              [-74.89306273099993, 10.764960202000054],
              [-74.8930659429999 , 10.764829553000084],
              [-74.89312898599991, 10.764711031000047],
              [-74.89320804899995, 10.76455111100006 ],
              [-74.89327713499995, 10.764415372000087],
              [-74.89337203299993, 10.764251586000057],
              [-74.89344396199994, 10.764096601000062],
              [-74.89353402099994, 10.763931255000045],
              [-74.89363148599995, 10.76375108500008 ],
              [-74.89370590099992, 10.763604887000042],
              [-74.89376180499994, 10.763497303000065],
              [-74.89383353699992, 10.763354022000044],
              [-74.89411976199995, 10.76289876900006 ],
              [-74.89438640299994, 10.762318619000041],
              [-74.89451536699994, 10.76193438200005 ],
              [-74.89456476599992, 10.76178720200005 ],
              [-74.89460736099994, 10.761646316000054],
              [-74.89462770299991, 10.761622651000039],
              [-74.89463309599995, 10.761616378000042],
              [-74.89466915999992, 10.76149864200005 ],
              [-74.89470102799993, 10.761373956000057],
              [-74.89476328799992, 10.761077330000091],
              [-74.89477714499992, 10.761011316000065],
              [-74.89481465199992, 10.760581564000063],
              [-74.89481521699992, 10.760361451000051],
              [-74.89480897099992, 10.760131990000048],
              [-74.89476768599991, 10.759753747000047],
              [-74.89470882199993, 10.759780123000041],
              [-74.89473367599993, 10.759442162000084],
              [-74.8947356619999 , 10.759415163000085],
              [-74.89475152299991, 10.759273011000062],
              [-74.89476580799993, 10.759144979000041],
              [-74.8947596289999 , 10.758775417000038],
              [-74.89476262099993, 10.758242887000051],
              [-74.89479449399994, 10.758091747000037],
              [-74.89481998299993, 10.757931673000087],
              [-74.89484919299991, 10.757723739000085],
              [-74.89488409499995, 10.75760574800006 ],
              [-74.89488678299995, 10.757596659000058],
              [-74.89492478099993, 10.757468190000054],
              [-74.89495093699992, 10.757400522000069],
              [-74.89496573299994, 10.757354923000037],
              [-74.89500481899995, 10.757204072000093],
              [-74.89500921099994, 10.75718712400004 ],
              [-74.89502887599991, 10.757143430000042],
              [-74.89505654699991, 10.757081959000061],
              [-74.89507131699992, 10.75704810500008 ],
              [-74.89511521599991, 10.756947474000071],
              [-74.89520570299993, 10.756805154000062],
              [-74.89526587099994, 10.756743614000072],
              [-74.89534193899993, 10.756638447000057],
              [-74.89542329599993, 10.756525967000073],
              [-74.89551763399993, 10.75640009600005 ],
              [-74.89559988799994, 10.756246927000063],
              [-74.89579564399992, 10.75569351300004 ],
              [-74.8958437039999 , 10.755525456000044],
              [-74.89588250799994, 10.755369159000054],
              [-74.89594294199992, 10.755029427000068],
              [-74.89595658199994, 10.754928005000068],
              [-74.89597061599994, 10.754753376000053],
              [-74.8959756029999 , 10.75459039000009 ],
              [-74.89598067499992, 10.754387726000061],
              [-74.8959721789999 , 10.754252302000054],
              [-74.89594840699993, 10.75407585000005 ],
              [-74.8959182989999 , 10.753972330000067],
              [-74.89591796999991, 10.753971197000055],
              [-74.89591759099994, 10.75396989300009 ],
              [-74.89590941199992, 10.753941771000086],
              [-74.89586104599994, 10.753801463000059],
              [-74.89581310999995, 10.753701256000056],
              [-74.89578982699993, 10.753608086000042],
              [-74.89579019799993, 10.75345459500005 ],
              [-74.89581907799993, 10.75339549000006 ],
              [-74.8958283959999 , 10.753182026000047],
              [-74.89583087099993, 10.753125356000055],
              [-74.89585580999994, 10.75298852800006 ],
              [-74.89589485099992, 10.752774332000058],
              [-74.89593280299994, 10.752334928000039],
              [-74.89597307399993, 10.751886415000058],
              [-74.89597147199993, 10.75162797900009 ],
              [-74.8959564139999 , 10.751267233000078],
              [-74.89590933599993, 10.75098114800005 ],
              [-74.8958978519999 , 10.750916609000058],
              [-74.89585808399994, 10.750669783000092],
              [-74.89585236499994, 10.750563345000046],
              [-74.89585141999993, 10.750545440000053],
              [-74.89584865299992, 10.750494006000054],
              [-74.89584858599994, 10.750492511000061],
              [-74.89584883799995, 10.750489909000066],
              [-74.89584917699995, 10.750486762000037],
              [-74.89585830399994, 10.750394175000054],
              [-74.89585924199991, 10.750382780000052],
              [-74.89586083699993, 10.750363715000049],
              [-74.8958665209999 , 10.75029562900005 ],
              [-74.89591554899994, 10.750110893000056],
              [-74.89600115799993, 10.749796054000058],
              [-74.89605658199991, 10.749450060000072],
              [-74.89608493299994, 10.74913458900005 ],
              [-74.89610398999992, 10.74886863000006 ],
              [-74.89610906799993, 10.74866385200005 ],
              [-74.89610458699991, 10.748488787000042],
              [-74.89612603199993, 10.74840025800006 ],
              [-74.89612616099993, 10.74834654600005 ],
              [-74.89611737999991, 10.748173103000056],
              [-74.89611305499994, 10.748004202000061],
              [-74.89611815299992, 10.747808089000046],
              [-74.89610286499993, 10.747551311000052],
              [-74.8960876729999 , 10.74738577000005 ],
              [-74.89605273599994, 10.747224888000062],
              [-74.89604391199993, 10.747011950000058],
              [-74.89600230499991, 10.746784650000052],
              [-74.89600266899993, 10.746639260000052],
              [-74.8960198449999 , 10.746517794000056],
              [-74.89603186799991, 10.746397221000052],
              [-74.8960594699999 , 10.746231523000063],
              [-74.89605979499993, 10.746105296000053],
              [-74.89606671799993, 10.745987446000072],
              [-74.89604740599992, 10.745846975000063],
              [-74.8960305519999 , 10.74573035700007 ],
              [-74.89599162499991, 10.745626207000043],
              [-74.89594342999993, 10.745528057000058],
              [-74.89591103299995, 10.745467632000043],
              [-74.89581760399994, 10.745350384000062],
              [-74.89570940499993, 10.745201592000058],
              [-74.89566711499992, 10.745151135000071],
              [-74.89561318299991, 10.745095269000046],
              [-74.89555131199995, 10.745029011000042],
              [-74.89543352899994, 10.744893542000057],
              [-74.89538899599995, 10.744847151000045],
              [-74.8953676239999 , 10.744828259000087],
              [-74.8952762099999 , 10.744730178000054],
              [-74.89521243399992, 10.744660201000045],
              [-74.89508339799994, 10.744549592000055],
              [-74.89486157399995, 10.74437264100004 ],
              [-74.8947649779999 , 10.744293621000054],
              [-74.89475580699991, 10.744286106000061],
              [-74.8947151989999 , 10.744252884000048],
              [-74.89464312299992, 10.744186165000087],
              [-74.89477062199995, 10.74407330300005 ],
              [-74.89493707899993, 10.744012341000087],
              [-74.89505927399995, 10.743931319000069],
              [-74.89520169999992, 10.743786359000069],
              [-74.89520448699994, 10.743679891000056],
              [-74.89520460199992, 10.743679709000048],
              [-74.89443515399995, 10.74334239600006 ],
              [-74.89456413999994, 10.742911597000045],
              [-74.89456391599992, 10.74291119500009 ],
              [-74.89435670499995, 10.74253937800006 ],
              [-74.89426204299991, 10.74244157000004 ],
              [-74.89411976199995, 10.742464708000057],
              [-74.89400524899992, 10.742474449000042],
              [-74.89385084399993, 10.742449696000051],
              [-74.89371501599993, 10.742406384000049],
              [-74.89345752499992, 10.74232427800007 ],
              [-74.89334538499992, 10.74234094600007 ],
              [-74.89322162299993, 10.74237383700006 ],
              [-74.8930790849999 , 10.742388323000057],
              [-74.89297375799993, 10.742361045000052],
              [-74.89280989699995, 10.74231321800005 ],
              [-74.89265054999993, 10.74223070100004 ],
              [-74.89241879199994, 10.742160055000056],
              [-74.89229464099992, 10.742105123000044],
              [-74.8922263259999 , 10.741989855000043],
              [-74.89219954299995, 10.741751913000087],
              [-74.89219746899994, 10.741620025000088],
              [-74.89219627199992, 10.741543912000054],
              [-74.8922768679999 , 10.741270838000048],
              [-74.89239411299991, 10.74105635400008 ],
              [-74.89244150299993, 10.74096965800004 ],
              [-74.89263202599994, 10.740707660000055],
              [-74.89311611199992, 10.740157464000049],
              [-74.89360556999992, 10.739808595000056],
              [-74.89363236699995, 10.739789494000092],
              [-74.89365551299994, 10.739772996000056],
              [-74.89367509999994, 10.739763691000064],
              [-74.89398511099995, 10.739616401000092],
              [-74.89427028199992, 10.73947852200007 ],
              [-74.89454108599995, 10.739271230000043],
              [-74.89480727699993, 10.739080168000044],
              [-74.89499362999993, 10.738847766000049],
              [-74.8950372619999 , 10.738653036000073],
              [-74.8950341659999 , 10.738623748000066],
              [-74.89502595399995, 10.738546075000045],
              [-74.89501523699994, 10.738444696000045],
              [-74.8949207629999 , 10.738273725000056],
              [-74.8947844349999 , 10.738172417000044],
              [-74.89468130999995, 10.738161283000068],
              [-74.89467032499994, 10.73816411900009 ],
              [-74.89459005799995, 10.738184837000063],
              [-74.89449913899995, 10.73828250300005 ],
              [-74.89438783799994, 10.738535420000062],
              [-74.89425479399995, 10.738642536000043],
              [-74.8941447169999 , 10.738647640000067],
              [-74.89400158199993, 10.738594995000085],
              [-74.89377854999992, 10.73848711100004 ],
              [-74.89356490999995, 10.738383818000045],
              [-74.8934453359999 , 10.738361178000048],
              [-74.89325344799994, 10.738406011000052],
              [-74.89311782899995, 10.738462180000056],
              [-74.89285579299991, 10.738535108000065],
              [-74.89268010399991, 10.738535873000046],
              [-74.8925535219999 , 10.738517892000061],
              [-74.8924080779999 , 10.738472201000093],
              [-74.89228827199992, 10.738398608000068],
              [-74.89219134599995, 10.73833501200005 ],
              [-74.89205331699992, 10.738244457000064],
              [-74.89189079099992, 10.738045989000057],
              [-74.89174674799995, 10.737791856000058],
              [-74.89164022799991, 10.737546824000049],
              [-74.89154825399993, 10.737410581000063],
              [-74.8913708739999 , 10.737036165000063],
              [-74.89134889399992, 10.73683709100004 ],
              [-74.89139675199993, 10.736540440000056],
              [-74.89144009099994, 10.736280864000037],
              [-74.89161555999993, 10.735712697000054],
              [-74.89161630599995, 10.735710674000075],
              [-74.89163354699991, 10.735663898000041],
              [-74.89171044499994, 10.735455200000047],
              [-74.89167933699991, 10.735309423000047],
              [-74.89155471999993, 10.735208054000054],
              [-74.89137169599991, 10.735141677000058],
              [-74.89133936299993, 10.73513596500004 ],
              [-74.89106456799993, 10.735087419000081],
              [-74.89071487599995, 10.734945330000073],
              [-74.89033003499992, 10.734801080000068],
              [-74.89004850899994, 10.73470965100006 ],
              [-74.88988874499995, 10.734606116000066],
              [-74.88984809599992, 10.734423322000055],
              [-74.88981675799994, 10.734150989000057],
              [-74.88981639999992, 10.734147845000052],
              [-74.88981321799992, 10.73396256600006 ],
              [-74.88978621899992, 10.733689385000048],
              [-74.88967943799992, 10.73338644000006 ],
              [-74.8895451809999 , 10.733224889000041],
              [-74.88940382999994, 10.733049478000055],
              [-74.88928593299994, 10.732878595000045],
              [-74.88925872999994, 10.732617742000059],
              [-74.88925165099994, 10.732549856000048],
              [-74.88932367899992, 10.732417527000052],
              [-74.88942900099994, 10.732262927000079],
              [-74.8894819269999 , 10.732185239000046],
              [-74.88963335999995, 10.731999292000069],
              [-74.88966668899991, 10.731960068000092],
              [-74.88984027099991, 10.731755776000057],
              [-74.89006367299993, 10.731542231000049],
              [-74.89011680499993, 10.731495195000036],
              [-74.89024990399992, 10.73137736600006 ],
              [-74.8902681969999 , 10.731354856000053],
              [-74.89018269799993, 10.731286886000078],
              [-74.89015959799991, 10.731268521000061],
              [-74.8899976109999 , 10.731169689000069],
              [-74.88987278899992, 10.731005881000044],
              [-74.88983016799995, 10.730899583000053],
              [-74.88974072199994, 10.730793490000053],
              [-74.8896725379999 , 10.73072896900004 ],
              [-74.88955514799994, 10.73065540500005 ],
              [-74.8894399699999 , 10.730554052000059],
              [-74.88926825499993, 10.73037424000006 ],
              [-74.88917194499993, 10.730302898000048],
              [-74.88903775599994, 10.730139130000055],
              [-74.88903384699995, 10.730126662000089],
              [-74.88899499899992, 10.730002744000046],
              [-74.88893143799993, 10.729924315000062],
              [-74.8886499269999 , 10.729798215000073],
              [-74.88859676999994, 10.729778491000047],
              [-74.88848342499995, 10.729736435000063],
              [-74.88838696899995, 10.729632687000048],
              [-74.88822695399995, 10.729452826000056],
              [-74.88819594799992, 10.729418561000045],
              [-74.88813267899991, 10.729348641000058],
              [-74.8881163719999 , 10.729330619000052],
              [-74.88797753699993, 10.729173811000067],
              [-74.88792753699994, 10.729137757000046],
              [-74.88787447899995, 10.729099493000092],
              [-74.88780842299991, 10.729051858000048],
              [-74.88766740299991, 10.728929784000059],
              [-74.88742756499994, 10.728699340000048],
              [-74.88724133499994, 10.728415425000037],
              [-74.88716085399994, 10.728256399000088],
              [-74.88701456199993, 10.727967337000052],
              [-74.88689671899994, 10.727791921000062],
              [-74.88678611299991, 10.72766508500007 ],
              [-74.8866687339999 , 10.72759383500005 ],
              [-74.88653748599995, 10.727561992000062],
              [-74.8863809529999 , 10.727634427000055],
              [-74.88635425899992, 10.72765725000005 ],
              [-74.88631564999991, 10.727690258000052],
              [-74.8860127559999 , 10.728015631000062],
              [-74.8858384959999 , 10.728310357000055],
              [-74.88580371699993, 10.728350836000061],
              [-74.88571272999991, 10.728456731000051],
              [-74.88555444699995, 10.728661112000054],
              [-74.88540508199992, 10.728765920000058],
              [-74.88517380899992, 10.72887802200006 ],
              [-74.88497769299994, 10.72899923500006 ],
              [-74.88482951499992, 10.729104613000061],
              [-74.8847746589999 , 10.729143622000038],
              [-74.88467199699994, 10.729222769000046],
              [-74.88457891599995, 10.72934816500009 ],
              [-74.88451172999993, 10.72950585700005 ],
              [-74.88442361699992, 10.729696047000061],
              [-74.8843494919999 , 10.72987229100005 ],
              [-74.88416360199994, 10.73015779700006 ],
              [-74.8841379019999 , 10.730197267000051],
              [-74.88393027399991, 10.730362507000052],
              [-74.8837202279999 , 10.730509243000085],
              [-74.88360335699991, 10.730551408000053],
              [-74.88335734899994, 10.730510799000058],
              [-74.88322347799993, 10.73041647100007 ],
              [-74.8831317449999 , 10.730321959000037],
              [-74.88305619699992, 10.730181082000058],
              [-74.8830669379999 , 10.729965762000063],
              [-74.88329077299994, 10.729761102000055],
              [-74.88337715599994, 10.729707491000056],
              [-74.88343295099992, 10.729619289000084],
              [-74.8834765819999 , 10.729429291000088],
              [-74.88355394699994, 10.728931285000044],
              [-74.88360402799992, 10.728613943000084],
              [-74.88360455299994, 10.728309387000081],
              [-74.88360467899992, 10.728236632000062],
              [-74.88352953599991, 10.728125395000063],
              [-74.88350333499994, 10.728086609000059],
              [-74.88339519099992, 10.727987535000068],
              [-74.8832637189999 , 10.727904772000045],
              [-74.8831646509999 , 10.727847651000047],
              [-74.88316153799991, 10.727845856000044],
              [-74.88309236999993, 10.727805971000066],
              [-74.88292849099992, 10.727806676000057],
              [-74.88287587699995, 10.727809164000064],
              [-74.88271314799994, 10.72781685800004 ],
              [-74.88264574699991, 10.727796350000062],
              [-74.88263812799994, 10.727794034000055],
              [-74.8825510289999 , 10.727687926000044],
              [-74.88250832699993, 10.727563113000087],
              [-74.88243356599992, 10.72738164000009 ],
              [-74.88242075099993, 10.72735053100007 ],
              [-74.88227021199992, 10.727193770000042],
              [-74.88214248999992, 10.727084994000052],
              [-74.88207280299991, 10.727025642000058],
              [-74.88192972399992, 10.72696607000006 ],
              [-74.8816790269999 , 10.726923164000084],
              [-74.88138732399995, 10.726940442000057],
              [-74.88138411799991, 10.726940633000083],
              [-74.88138147899991, 10.726942306000069],
              [-74.88123703999992, 10.727033856000048],
              [-74.88101737999995, 10.72712507500006 ],
              [-74.8809717019999 , 10.727146732000051],
              [-74.8807907659999 , 10.727232524000044],
              [-74.88073483599993, 10.727281007000045],
              [-74.8805948669999 , 10.727402341000072],
              [-74.88042754999992, 10.727680834000068],
              [-74.88021860899994, 10.728075241000056],
              [-74.8801055209999 , 10.728232350000042],
              [-74.88009994499993, 10.728240098000072],
              [-74.88000466499994, 10.728327823000086],
              [-74.87994839299995, 10.728379635000067],
              [-74.87977745899991, 10.728373422000061],
              [-74.87955930899994, 10.728279451000049],
              [-74.87937390299993, 10.728178395000043],
              [-74.87902614299992, 10.727895167000042],
              [-74.87853679299991, 10.727505565000058],
              [-74.87819115099995, 10.727272949000053],
              [-74.87789765899993, 10.72716758900009 ],
              [-74.87789730499992, 10.727167340000051],
              [-74.87789588299995, 10.72716633400006 ],
              [-74.8776436789999 , 10.726987892000068],
              [-74.87747632199995, 10.726784646000056],
              [-74.87737983599993, 10.726701621000075],
              [-74.87719443499992, 10.726653743000043],
              [-74.87704664499995, 10.726628878000042],
              [-74.87686347099992, 10.726555491000056],
              [-74.87668732899994, 10.72648207800006 ],
              [-74.87650391599993, 10.726355384000044],
              [-74.87644494299991, 10.726274515000057],
              [-74.87640929899993, 10.726168051000059],
              [-74.87639461899994, 10.726033685000061],
              [-74.87644302299992, 10.72585269600006 ],
              [-74.87653849599991, 10.725713219000056],
              [-74.87668551199994, 10.725568893000059],
              [-74.8767831909999 , 10.72539928000009 ],
              [-74.87688819199991, 10.72511090100005 ],
              [-74.87688968899994, 10.725106788000062],
              [-74.8769217489999 , 10.724909301000082],
              [-74.87693072999991, 10.724853977000066],
              [-74.87691600099993, 10.724708024000051],
              [-74.8768755509999 , 10.72457608700006 ],
              [-74.87674159399995, 10.72450017500006 ],
              [-74.87661267199991, 10.724500724000052],
              [-74.8764560759999 , 10.72460105600004 ],
              [-74.87630416799993, 10.724701370000048],
              [-74.87616625099992, 10.724785397000062],
              [-74.87606333499991, 10.72483451000005 ],
              [-74.87593068599995, 10.725045992000048],
              [-74.87588029699992, 10.725305795000054],
              [-74.87586298499991, 10.725487185000077],
              [-74.8758601589999 , 10.725516794000043],
              [-74.87572964599991, 10.725681909000059],
              [-74.8755839929999 , 10.725771044000055],
              [-74.87558543999995, 10.725648431000081],
              [-74.8755857829999 , 10.725619377000044],
              [-74.87595473199991, 10.724206493000054],
              [-74.87603548699991, 10.724150081000062],
              [-74.87614828599993, 10.72407129800007 ],
              [-74.87624092999994, 10.723978068000065],
              [-74.87633919299992, 10.723901700000056],
              [-74.87643273099991, 10.723829024000054],
              [-74.87654363099995, 10.723712830000068],
              [-74.87671679699992, 10.723531412000057],
              [-74.87683626699993, 10.72341054800006 ],
              [-74.87683516499993, 10.72340741700009 ],
              [-74.87703813099995, 10.72319477800005 ],
              [-74.8771572469999 , 10.723015675000056],
              [-74.87727338099995, 10.722860281000067],
              [-74.87741101599994, 10.722676088000071],
              [-74.87751102099992, 10.722521233000066],
              [-74.87790628099992, 10.722060382000052],
              [-74.87806390899993, 10.72191027100007 ],
              [-74.87818160299992, 10.721843767000053],
              [-74.87834810699991, 10.721749694000039],
              [-74.87854671399992, 10.721685992000062],
              [-74.87872023199992, 10.721641982000051],
              [-74.8787521239999 , 10.721633891000067],
              [-74.87893395099991, 10.721599711000067],
              [-74.87912569799994, 10.721531427000059],
              [-74.87937349799995, 10.721443162000071],
              [-74.87940800299992, 10.72142524600008 ],
              [-74.87961019999995, 10.721320251000066],
              [-74.87971597699993, 10.721297612000058],
              [-74.87989450299995, 10.721278526000049],
              [-74.88008314299992, 10.721258367000075],
              [-74.88015465099994, 10.721234551000066],
              [-74.88027476399992, 10.721194557000047],
              [-74.88056636099992, 10.721132258000068],
              [-74.88083247999992, 10.72106266700007 ],
              [-74.8810711239999 , 10.720965068000055],
              [-74.88138761099992, 10.720799878000037],
              [-74.88142808499992, 10.720778748000043],
              [-74.88156329899994, 10.720715144000053],
              [-74.88172008599992, 10.720641391000072],
              [-74.88177654099991, 10.720614830000045],
              [-74.8818945829999 , 10.720559299000058],
              [-74.88205849799994, 10.720520193000084],
              [-74.88230408699991, 10.720461600000078],
              [-74.88262145099992, 10.720385880000038],
              [-74.88279386099993, 10.720363176000092],
              [-74.88300883699992, 10.720292813000071],
              [-74.8833737029999 , 10.72015112400004 ],
              [-74.88371021899991, 10.720038896000062],
              [-74.88400316999991, 10.719922289000067],
              [-74.88426695399994, 10.719817293000062],
              [-74.88459934699995, 10.719670527000062],
              [-74.8849496279999 , 10.719570003000058],
              [-74.88508665899991, 10.71953897700007 ],
              [-74.88521418099992, 10.719498219000059],
              [-74.88538635299994, 10.719443187000081],
              [-74.88573681799994, 10.719331167000064],
              [-74.88582580699995, 10.719288808000044],
              [-74.8859443959999 , 10.719158328000049],
              [-74.88601542399994, 10.71905021100008 ],
              [-74.8860416409999 , 10.719010293000053],
              [-74.88604841299991, 10.719018591000065],
              [-74.8860867169999 , 10.718918919000089],
              [-74.88608721499992, 10.718917563000048],
              [-74.88613323099992, 10.718792138000083],
              [-74.88616637299992, 10.718613464000043],
              [-74.88617994099991, 10.718316850000065],
              [-74.88619033499992, 10.718089360000079],
              [-74.8861597419999 , 10.717652004000058],
              [-74.8861405369999 , 10.717487119000054],
              [-74.88605656599992, 10.717091703000051],
              [-74.88597624199991, 10.716713380000044],
              [-74.88595659799995, 10.716473935000067],
              [-74.88600194799994, 10.71624237800006 ],
              [-74.88604093399994, 10.716175038000074],
              [-74.8861441809999 , 10.71607694100004 ],
              [-74.88632557699992, 10.715904607000084],
              [-74.8865614739999 , 10.715680496000061],
              [-74.88671697599995, 10.715544866000073],
              [-74.88699155499995, 10.715376517000038],
              [-74.88715651199993, 10.715321006000067],
              [-74.88726364199994, 10.715219637000075],
              [-74.88743346399991, 10.715058920000047],
              [-74.88759229399994, 10.714856659000077],
              [-74.88775966199995, 10.714551035000056],
              [-74.88823959199993, 10.714762695000047],
              [-74.88936265699994, 10.71525799300008 ],
              [-74.88936306599993, 10.715346256000089],
              [-74.88936319999993, 10.715375280000046],
              [-74.8916084409999 , 10.716512650000084],
              [-74.89318256199994, 10.716667291000078],
              [-74.8939336119999 , 10.717235362000054],
              [-74.89493536499992, 10.717993053000043],
              [-74.8952662399999 , 10.718364355000062],
              [-74.89589245499991, 10.719067086000052],
              [-74.89594841899992, 10.719129892000069],
              [-74.8961188259999 , 10.719321127000057],
              [-74.8961521139999 , 10.719365381000046],
              [-74.8961539849999 , 10.71936738700009 ],
              [-74.8961872719999 , 10.71940306600004 ],
              [-74.89619656899993, 10.719413030000055],
              [-74.89619719599995, 10.719413565000082],
              [-74.89624535199994, 10.719454608000092],
              [-74.89624622699995, 10.719455352000068],
              [-74.89625945299991, 10.719464246000086],
              [-74.89629785199992, 10.719490065000059],
              [-74.8963004389999 , 10.719491805000075],
              [-74.89631913799991, 10.719501499000046],
              [-74.89635399399992, 10.719519571000092],
              [-74.89635849899992, 10.719521908000047],
              [-74.89637854599994, 10.719529565000073],
              [-74.89641312299995, 10.719542774000047],
              [-74.89641965099992, 10.719545267000058],
              [-74.89643963499992, 10.719550405000064],
              [-74.89647446999993, 10.719559363000087],
              [-74.89648309599994, 10.719561580000061],
              [-74.89650229199992, 10.719564259000038],
              [-74.89653730999993, 10.719569144000047],
              [-74.8965480089999 , 10.71957063700006 ],
              [-74.8965658649999 , 10.71957109300007 ],
              [-74.89660090199993, 10.719571991000066],
              [-74.89661354299994, 10.71957231500005 ],
              [-74.89662966899994, 10.719570902000044],
              [-74.89666444099993, 10.719567856000083],
              [-74.89667884199991, 10.71956659500006 ],
              [-74.89669297599994, 10.719563723000078],
              [-74.89672719599992, 10.719556773000079],
              [-74.89674305699992, 10.719553550000057],
              [-74.89675505799994, 10.719549661000087],
              [-74.89678830199995, 10.719538883000041],
              [-74.89680534999991, 10.719533357000046],
              [-74.89681518599991, 10.719528883000066],
              [-74.89684719599995, 10.719514323000055],
              [-74.89686490799994, 10.719506267000042],
              [-74.89687253999995, 10.719501687000047],
              [-74.89690325499993, 10.719483259000071],
              [-74.89692095599992, 10.71947263900006 ],
              [-74.89693280599994, 10.719464362000053],
              [-74.8989951019999 , 10.718389175000084],
              [-74.89991346799991, 10.716692114000068],
              [-74.90020967399994, 10.716966788000093],
              [-74.9002220509999 , 10.71697806800006 ],
              [-74.90101611599994, 10.71770181700009 ],
              [-74.90232680999992, 10.718896441000084],
              [-74.90243797999995, 10.71878784100005 ],
              [-74.90244183199991, 10.718746688000067],
              [-74.90248228099995, 10.718571916000087],
              [-74.90251167699995, 10.71844487800007 ],
              [-74.90254400999993, 10.71830516500006 ],
              [-74.90270834799992, 10.718043213000044],
              [-74.90280341199991, 10.717920112000058],
              [-74.90290187799991, 10.71785770200006 ],
              [-74.90302996199995, 10.717776520000086],
              [-74.90315050099991, 10.717700121000064],
              [-74.90326066199992, 10.717630304000068],
              [-74.90335764199995, 10.717528368000046],
              [-74.9034716139999 , 10.717408590000048],
              [-74.9034846749999 , 10.717387933000055],
              [-74.90357851999994, 10.71723957000006 ],
              [-74.90366503699994, 10.717102780000062],
              [-74.90376546899995, 10.716943984000068],
              [-74.90384362799995, 10.716820385000062],
              [-74.9038979469999 , 10.716734526000039],
              [-74.90394724599992, 10.716656575000059],
              [-74.90399817799994, 10.716576058000044],
              [-74.9040889019999 , 10.716511489000084],
              [-74.90416173799991, 10.716459651000037],
              [-74.90421208899994, 10.71637989800007 ],
              [-74.90428810399993, 10.716340616000082],
              [-74.90441555299992, 10.716274775000045],
              [-74.90450828999991, 10.71622687200005 ],
              [-74.9045850249999 , 10.71611149100005 ],
              [-74.90463933599995, 10.716029846000083],
              [-74.90470905799992, 10.715925019000053],
              [-74.9047927499999 , 10.715799182000069],
              [-74.90483825899992, 10.715680957000075],
              [-74.90489631899993, 10.715530123000065],
              [-74.90498967999991, 10.71538659500004 ],
              [-74.90504256499992, 10.715235755000037],
              [-74.90512271199992, 10.715007103000062],
              [-74.90514937299992, 10.71462834600004 ],
              [-74.90511780199995, 10.714461153000059],
              [-74.90517037299992, 10.713854315000049],
              [-74.90522310099993, 10.713245748000077],
              [-74.9052274149999 , 10.71319595600005 ],
              [-74.90522226199994, 10.712883112000043],
              [-74.90521378299991, 10.71236831400006 ],
              [-74.9052065859999 , 10.712335151000048],
              [-74.90520148399992, 10.712315513000078],
              [-74.90518252599992, 10.712242546000084],
              [-74.90517725399991, 10.71222225300005 ],
              [-74.90517338599994, 10.712210463000076],
              [-74.90514401399992, 10.71212093400004 ],
              [-74.90514087899993, 10.712111378000088],
              [-74.90513952599991, 10.712107667000055],
              [-74.9051021699999 , 10.712014360000069],
              [-74.90509761699991, 10.712002989000041],
              [-74.90508527899993, 10.711975303000088],
              [-74.90504760899995, 10.711897433000047],
              [-74.90504473899995, 10.71189224200009 ],
              [-74.90499196399992, 10.711796784000057],
              [-74.9049910629999 , 10.711795155000061],
              [-74.9049401289999 , 10.71171425600005 ],
              [-74.9049069859999 , 10.711678861000053],
              [-74.90488391899993, 10.711652007000055],
              [-74.90484471799994, 10.711606371000073],
              [-74.90482878099994, 10.711587818000055],
              [-74.90480753599991, 10.711559786000066],
              [-74.90477121799995, 10.711511865000091],
              [-74.90475633599993, 10.711492228000054],
              [-74.90473703499993, 10.711463231000039],
              [-74.90470368099994, 10.711413125000092],
              [-74.90468991499995, 10.711392445000058],
              [-74.90467255899995, 10.711362549000057],
              [-74.90464236699995, 10.71131054400007 ],
              [-74.90462976899994, 10.711288846000059],
              [-74.90461437699992, 10.711258136000083],
              [-74.9045874759999 , 10.711204468000062],
              [-74.90457612099993, 10.711181813000053],
              [-74.90456272599994, 10.711150411000062],
              [-74.9045392239999 , 10.711095318000048],
              [-74.9045291679999 , 10.711071743000048],
              [-74.90451780299992, 10.711039783000047],
              [-74.90449774099994, 10.71098336700004 ],
              [-74.90448909399993, 10.710959049000053],
              [-74.90447980099992, 10.710926749000066],
              [-74.90446324599992, 10.710869209000066],
              [-74.90445603499995, 10.710844146000056],
              [-74.90444064999991, 10.71077904000009 ],
              [-74.90442004399995, 10.71060411700006 ],
              [-74.90441370699995, 10.710408734000055],
              [-74.90436127699991, 10.710187426000061],
              [-74.90435979099993, 10.709824363000052],
              [-74.90438664399994, 10.709677226000053],
              [-74.90437766499991, 10.70956497800006 ],
              [-74.90437073699991, 10.709478371000046],
              [-74.90436793599991, 10.709443314000055],
              [-74.90440142199992, 10.70932444500005 ],
              [-74.9044685909999 , 10.70908601900004 ],
              [-74.90462332599992, 10.708536725000044],
              [-74.90469748499993, 10.708243654000057],
              [-74.90472352799992, 10.707977233000065],
              [-74.90478861899993, 10.707750917000055],
              [-74.90484259099992, 10.70756325700006 ],
              [-74.90491969899995, 10.707382811000059],
              [-74.9049613599999 , 10.70726546700007 ],
              [-74.90502270999991, 10.707148518000054],
              [-74.90508338599994, 10.707032858000048],
              [-74.90514524499991, 10.70691492700007 ],
              [-74.9052170409999 , 10.706778064000048],
              [-74.90527304599993, 10.70637105500009 ],
              [-74.90531174599994, 10.70619679300006 ],
              [-74.90535626699995, 10.705996305000042],
              [-74.90539018599992, 10.705843554000069],
              [-74.90539101799993, 10.705839806000085],
              [-74.90541249099994, 10.705743128000051],
              [-74.9054518669999 , 10.705565838000041],
              [-74.90551137699993, 10.705297846000065],
              [-74.90557598799995, 10.705006869000044],
              [-74.90558266499994, 10.704976799000065],
              [-74.9055886889999 , 10.704949671000065],
              [-74.90560310299992, 10.705379544000039],
              [-74.9057970099999 , 10.706130384000062],
              [-74.90591795799992, 10.706329492000066],
              [-74.90599171399992, 10.706450904000064],
              [-74.90631558599995, 10.706984058000046],
              [-74.90723735599994, 10.708501449000039],
              [-74.90730807699993, 10.708461723000084],
              [-74.9075424639999 , 10.708330060000037],
              [-74.90755136599995, 10.708258087000047],
              [-74.90757163399991, 10.708094219000088],
              [-74.90758336099992, 10.707999434000044],
              [-74.9076240839999 , 10.707670223000036],
              [-74.90762688099994, 10.707616804000054],
              [-74.90762906899994, 10.707597127000042],
              [-74.90763475199992, 10.707546017000084],
              [-74.90763776199992, 10.707518952000044],
              [-74.90764108199994, 10.707501339000089],
              [-74.90765063799995, 10.707450644000062],
              [-74.90765600399993, 10.707422178000058],
              [-74.9076601729999 , 10.707406621000075],
              [-74.90767355299994, 10.707356692000076],
              [-74.90768150399992, 10.707327020000037],
              [-74.90768621799992, 10.707313583000087],
              [-74.90770349499991, 10.707264333000069],
              [-74.90771413099992, 10.70723401500004 ],
              [-74.90771906999993, 10.707222735000073],
              [-74.90774020899994, 10.707174462000069],
              [-74.9077536879999 , 10.707143680000058],
              [-74.90775858899991, 10.70713444900008 ],
              [-74.9077837339999 , 10.707087091000062],
              [-74.90779996399993, 10.707056524000052],
              [-74.90780447999992, 10.707049373000075],
              [-74.90783390299993, 10.707002784000053],
              [-74.90785269499992, 10.70697302800005 ],
              [-74.90785661099994, 10.706967751000093],
              [-74.90789067299994, 10.706921850000072],
              [-74.90791158799993, 10.706893665000052],
              [-74.90791761199995, 10.706886205000046],
              [-74.90797615699995, 10.706819047000067],
              [-74.90797632999994, 10.706818849000058],
              [-74.9079793009999 , 10.70681589500009 ],
              [-74.9080429409999 , 10.706752615000084],
              [-74.9080465209999 , 10.706749054000056],
              [-74.90806678299992, 10.706731716000093],
              [-74.9081144459999 , 10.706690933000061],
              [-74.9081217929999 , 10.706684646000042],
              [-74.90814379699992, 10.706668495000088],
              [-74.90819032299993, 10.706634346000044],
              [-74.90820171799993, 10.706625982000048],
              [-74.9082227689999 , 10.706612821000078],
              [-74.90827012999995, 10.706583211000066],
              [-74.90828585199995, 10.70657338300009 ],
              [-74.90832790799993, 10.706550213000071],
              [-74.90901264599995, 10.706138476000092],
              [-74.90939979299992, 10.705905682000036],
              [-74.90940577099991, 10.705902086000037],
              [-74.9095331119999 , 10.705825512000047],
              [-74.90966688599991, 10.705764644000055],
              [-74.90973690499993, 10.705744939000056],
              [-74.90978864899995, 10.705704293000053],
              [-74.90989175599992, 10.705623320000086],
              [-74.90996060399993, 10.705552745000091],
              [-74.91001980999994, 10.705480034000061],
              [-74.91008777499991, 10.705396581000059],
              [-74.91015308699991, 10.705316374000063],
              [-74.91021111299995, 10.705245106000064],
              [-74.91027056399992, 10.705189415000063],
              [-74.91035761799992, 10.70511939700009 ],
              [-74.91043313099993, 10.705058664000092],
              [-74.91050584399994, 10.70500018100006 ],
              [-74.91058866799995, 10.704902034000042],
              [-74.91066226599992, 10.704799661000038],
              [-74.91249185399994, 10.70489636900004 ],
              [-74.91290328699995, 10.704918116000044],
              [-74.91347830699993, 10.705008820000046],
              [-74.91593424299992, 10.705670203000068],
              [-74.91608327599994, 10.705710331000091],
              [-74.91629034999994, 10.705790024000066],
              [-74.91633979099993, 10.705806411000083],
              [-74.91651467299994, 10.705864372000065],
              [-74.91654538699993, 10.705872125000042],
              [-74.91675546599993, 10.705925150000041],
              [-74.91712503199994, 10.706077333000053],
              [-74.91733548299993, 10.70614689100006 ],
              [-74.91751335999993, 10.706218290000038],
              [-74.9177583899999 , 10.706306702000063],
              [-74.91830282399991, 10.706576192000057],
              [-74.91863480399991, 10.706776319000085],
              [-74.91868515899995, 10.706806674000063],
              [-74.91833199399991, 10.70710736600006 ],
              [-74.91827094399991, 10.707159347000072],
              [-74.91822345499992, 10.707199784000068],
              [-74.91817363699994, 10.707282088000056],
              [-74.9181240719999 , 10.707331949000093],
              [-74.91806181099992, 10.707394599000054],
              [-74.91798992799994, 10.707442675000038],
              [-74.9178955029999 , 10.707505838000088],
              [-74.91777883599991, 10.707949840000083],
              [-74.9174521939999 , 10.709192949000055],
              [-74.9167409559999 , 10.710946230000047],
              [-74.91707667199995, 10.711088626000048],
              [-74.91715472399994, 10.711097979000044],
              [-74.92268703699995, 10.711760833000085],
              [-74.92271728499992, 10.711646827000038],
              [-74.9227293269999 , 10.711596615000076],
              [-74.92275707399995, 10.711480917000074],
              [-74.92277112999994, 10.711422309000056],
              [-74.92278093699991, 10.711374823000085],
              [-74.92280499499992, 10.711258324000084],
              [-74.92281781799994, 10.711196229000052],
              [-74.92282556099991, 10.711151628000039],
              [-74.92284594299991, 10.711034230000053],
              [-74.9228573019999 , 10.71096880600004 ],
              [-74.92286317499992, 10.710927175000052],
              [-74.92287984299992, 10.710809034000079],
              [-74.92288954399993, 10.710740273000056],
              [-74.92289374599994, 10.710701661000087],
              [-74.92290668299995, 10.710582782000074],
              [-74.92291451099993, 10.710510851000038],
              [-74.92291723299991, 10.710475403000089],
              [-74.92292642199993, 10.710355737000043],
              [-74.92293217899993, 10.71028076500005 ],
              [-74.92293363199991, 10.710248419000038],
              [-74.92293902599994, 10.71012829700004 ],
              [-74.92294253099993, 10.710050244000058],
              [-74.92294291199994, 10.710021144000052],
              [-74.92294449699995, 10.709900258000062],
              [-74.92294555599995, 10.709819516000039],
              [-74.92294507299994, 10.70979361600007 ],
              [-74.92294280499993, 10.709672065000063],
              [-74.92294125199993, 10.709588809000081],
              [-74.92294011399991, 10.709566255000084],
              [-74.9229339229999 , 10.709443578000048],
              [-74.92292962199991, 10.709358350000059],
              [-74.9229280369999 , 10.709339106000073],
              [-74.9229177929999 , 10.709214746000043],
              [-74.92291067699995, 10.709128362000058],
              [-74.92355758299993, 10.709162677000052],
              [-74.92457866399991, 10.709201972000074],
              [-74.92559071599993, 10.709265938000044],
              [-74.92771935399992, 10.710594430000071],
              [-74.9295759389999 , 10.71112308100004 ],
              [-74.93038608899991, 10.711354363000055],
              [-74.93119567699995, 10.711385502000041],
              [-74.93147296899991, 10.711444757000038],
              [-74.9321448529999 , 10.711565366000059],
              [-74.93218166699995, 10.711515615000053],
              [-74.9322154429999 , 10.711476685000036],
              [-74.93250847299993, 10.711446203000037],
              [-74.93281787699993, 10.711454656000058],
              [-74.93348392699994, 10.711385936000056],
              [-74.93425013099994, 10.711324059000049],
              [-74.93457187599995, 10.71128719300009 ],
              [-74.93486593499995, 10.711253499000065],
              [-74.93518903499995, 10.71121647700005 ],
              [-74.93521076699994, 10.71121751000004 ],
              [-74.93568024199993, 10.711239852000062],
              [-74.9361511489999 , 10.71126558700007 ],
              [-74.93671862199994, 10.711356056000056],
              [-74.93708931399993, 10.711486557000057],
              [-74.9375312809999 , 10.71171670700005 ],
              [-74.93796563799992, 10.711931280000044],
              [-74.93800973399993, 10.711953064000056],
              [-74.93828694099994, 10.712112988000058],
              [-74.93889441199991, 10.712430396000059],
              [-74.93933974999993, 10.712630109000088],
              [-74.93938910199995, 10.712658071000078],
              [-74.93987689099993, 10.712934443000051],
              [-74.94024261899995, 10.713141981000092],
              [-74.9405048129999 , 10.71322768500005 ],
              [-74.94089740299995, 10.713343819000045],
              [-74.94126844099992, 10.713381411000057],
              [-74.94175023499992, 10.713711596000053],
              [-74.94211282799995, 10.714144547000046],
              [-74.9421022759999 , 10.71497419700006 ],
              [-74.94195845699994, 10.715546991000053],
              [-74.94192865899993, 10.715815650000081],
              [-74.94191150899991, 10.715970275000075],
              [-74.94184298599993, 10.716209247000052],
              [-74.94171550699991, 10.716635087000043],
              [-74.94151244099993, 10.717029394000065],
              [-74.94135784799994, 10.717227510000043],
              [-74.94110477599992, 10.717499364000048],
              [-74.94009133199995, 10.71836029800005 ],
              [-74.9399267149999 , 10.718500143000085],
              [-74.93986861699995, 10.718554641000082],
              [-74.93972951199993, 10.71868512900005 ],
              [-74.93966073799993, 10.718793220000066],
              [-74.93956146399995, 10.718957482000064],
              [-74.93952175999993, 10.719061260000046],
              [-74.93945947599991, 10.719213830000058],
              [-74.93943636599994, 10.719275780000089],
              [-74.93938945899993, 10.719401516000062],
              [-74.93931942499995, 10.719544399000085],
              [-74.93906886699995, 10.719830796000053],
              [-74.9389222559999 , 10.720139832000086],
              [-74.93875035499991, 10.720459178000056],
              [-74.93832062299992, 10.720792373000052],
              [-74.93803237499992, 10.721062534000055],
              [-74.93797939899991, 10.721111954000037],
              [-74.93764505199994, 10.721423857000048],
              [-74.9374765579999 , 10.72162884700009 ],
              [-74.93736670699991, 10.721774536000055],
              [-74.93728150299995, 10.721968612000069],
              [-74.93724809799994, 10.722054668000055],
              [-74.93723285399994, 10.722093939000047],
              [-74.9372141579999 , 10.722246320000067],
              [-74.93722592899991, 10.722534151000048],
              [-74.93724735799992, 10.72299914100006 ],
              [-74.93725297799995, 10.723155788000042],
              [-74.93721904299991, 10.723264800000038],
              [-74.93714082299994, 10.723380002000056],
              [-74.93697396399995, 10.723528973000043],
              [-74.9368242999999 , 10.723667376000037],
              [-74.93681780199995, 10.723673384000051],
              [-74.93681153599994, 10.723704656000052],
              [-74.93675742799991, 10.72397469300006 ],
              [-74.9367184919999 , 10.724446139000065],
              [-74.93670213199994, 10.724599324000053],
              [-74.93653648599991, 10.725206609000054],
              [-74.93644086899991, 10.725393205000046],
              [-74.93623240599993, 10.725727927000037],
              [-74.9361190919999 , 10.725901406000048],
              [-74.93578387399992, 10.726289157000053],
              [-74.93552316699993, 10.726519618000054],
              [-74.93518301199992, 10.726893884000049],
              [-74.93511410799994, 10.726969121000081],
              [-74.93499801999991, 10.727095878000057],
              [-74.9349381799999 , 10.727231291000066],
              [-74.93475912299994, 10.727636480000058],
              [-74.93475413199991, 10.727647774000047],
              [-74.93475027999995, 10.727675839000085],
              [-74.93466426499992, 10.72830248300005 ],
              [-74.93463952499991, 10.728425300000083],
              [-74.93461232699991, 10.728560325000046],
              [-74.93456484299992, 10.729112422000071],
              [-74.93456870199992, 10.729528653000045],
              [-74.93462711799992, 10.730136917000038],
              [-74.93468558099994, 10.730714714000044],
              [-74.93484555299995, 10.73196727800007 ],
              [-74.9348791999999 , 10.732033845000046],
              [-74.93496246099994, 10.732198569000047],
              [-74.9357968029999 , 10.732965332000049],
              [-74.93590973699992, 10.73306911800006 ],
              [-74.93599904899992, 10.733129783000038],
              [-74.9360886799999 , 10.733190665000052],
              [-74.93629534799993, 10.733331043000078],
              [-74.9363265209999 , 10.733352216000071],
              [-74.93660343399995, 10.73358341700009 ],
              [-74.93662216999991, 10.733721174000038],
              [-74.93660365799991, 10.733814584000072],
              [-74.9365315469999 , 10.733925828000054],
              [-74.93631077799995, 10.734177883000086],
              [-74.9360034689999 , 10.734645409000052],
              [-74.93547974499995, 10.73539504200005 ],
              [-74.93528313199994, 10.735765186000037],
              [-74.93509453099995, 10.735914524000066],
              [-74.93488965599994, 10.736148978000074],
              [-74.93478119999992, 10.73629574000006 ],
              [-74.93461181999993, 10.736497329000088],
              [-74.9345630059999 , 10.736562323000044],
              [-74.93439721199991, 10.736669990000053],
              [-74.9340205119999 , 10.736883677000037],
              [-74.93380409699995, 10.73702434900008 ],
              [-74.9334769969999 , 10.737236968000047],
              [-74.93308597799995, 10.737487377000036],
              [-74.93262882399995, 10.737780141000087],
              [-74.93244102199992, 10.73786778200008 ],
              [-74.93236046299995, 10.737905377000061],
              [-74.9319089569999 , 10.738415449000058],
              [-74.93135194899992, 10.738508505000084],
              [-74.93139622799993, 10.738698914000054],
              [-74.93140060799993, 10.739072540000052],
              [-74.93135550399995, 10.739234285000066],
              [-74.9313262949999 , 10.739419216000044],
              [-74.93133370899994, 10.739562160000048],
              [-74.93137102599991, 10.739692976000072],
              [-74.9314825379999 , 10.739890592000052],
              [-74.93210109299991, 10.74030446100005 ],
              [-74.93214351799992, 10.740365431000043],
              [-74.93214276999993, 10.740782078000052],
              [-74.93208601299995, 10.741222011000048],
              [-74.93216898399993, 10.741549423000038],
              [-74.9322370939999 , 10.741673676000062],
              [-74.93232841299994, 10.741871850000052],
              [-74.93242828599995, 10.741986724000071],
              [-74.93301079199995, 10.741954159000045],
              [-74.93333736299991, 10.742224265000061],
              [-74.93362196499993, 10.742453222000051],
              [-74.93380007999991, 10.742660125000043],
              [-74.9343007789999 , 10.742900945000088],
              [-74.93432653299993, 10.742913333000047],
              [-74.93450317499992, 10.74282363900005 ],
              [-74.9357314479999 , 10.74357062300004 ],
              [-74.93562066899995, 10.744059074000063],
              [-74.93540012799991, 10.744535085000052],
              [-74.93492143899994, 10.74526779100006 ],
              [-74.9344604449999 , 10.745783127000038],
              [-74.9339995919999 , 10.746221134000052],
              [-74.93374175399993, 10.746587541000054],
              [-74.9332994209999 , 10.747100726000042],
              [-74.93274613999995, 10.74761422000006 ],
              [-74.93215639399995, 10.748310661000062],
              [-74.9316038309999 , 10.749080135000042],
              [-74.93136037499994, 10.749322219000078],
              [-74.9311981649999 , 10.749483516000055],
              [-74.93079300999995, 10.750069736000057],
              [-74.93073756799993, 10.750158211000041],
              [-74.93043764599992, 10.750636826000061],
              [-74.93040672699993, 10.750659085000052],
              [-74.93038819799995, 10.75067692400006 ],
              [-74.9303102049999 , 10.750752013000067],
              [-74.93019232299991, 10.75085262500005 ],
              [-74.93011203099991, 10.750962926000057],
              [-74.9300097009999 , 10.751168497000037],
              [-74.92993466799993, 10.751264897000055],
              [-74.9298851019999 , 10.751328578000084],
              [-74.92971311299993, 10.751470761000064],
              [-74.9295246659999 , 10.75158986300005 ],
              [-74.92937501199992, 10.751675762000048],
              [-74.92906086399995, 10.75184741600009 ],
              [-74.92879371499993, 10.751976936000062],
              [-74.92865468899993, 10.75204235700005 ],
              [-74.92819548799991, 10.75216110200006 ],
              [-74.92797124799995, 10.752264025000045],
              [-74.92780662299992, 10.752352758000086],
              [-74.92761879199992, 10.752491932000055],
              [-74.92755644599993, 10.752542113000061],
              [-74.9276191589999 , 10.752532299000052],
              [-74.92743258799993, 10.752764224000089],
              [-74.92719924799991, 10.753011706000052],
              [-74.92696617299993, 10.75335175300006 ],
              [-74.92677951199994, 10.753552823000064],
              [-74.92660836199991, 10.753722998000057],
              [-74.92635903799993, 10.753831671000057],
              [-74.92625034199995, 10.754017101000045],
              [-74.92614242299993, 10.754480227000045],
              [-74.92609703699992, 10.754989465000051],
              [-74.92602288699993, 10.755257013000062],
              [-74.92605099799994, 10.755534295000075],
              [-74.92622869899992, 10.755695030000084],
              [-74.92625746899995, 10.755851651000057],
              [-74.92627098899993, 10.756139077000057],
              [-74.9263410019999 , 10.756281258000058],
              [-74.9265945969999 , 10.756504432000042],
              [-74.9268057939999 , 10.756707624000057],
              [-74.92706315099991, 10.756879493000042],
              [-74.9271819089999 , 10.756904588000054],
              [-74.92743503799994, 10.756898497000066],
              [-74.92763652999992, 10.756917606000059],
              [-74.92792462299991, 10.757062873000052],
              [-74.9279436679999 , 10.757079803000067],
              [-74.92809188699994, 10.75721158500005 ],
              [-74.9282730889999 , 10.757326842000055],
              [-74.92842738399992, 10.757417390000057],
              [-74.92861724399995, 10.757493109000052],
              [-74.9286780779999 , 10.757506230000047],
              [-74.92880960399992, 10.757534599000053],
              [-74.92900853299994, 10.757565059000058],
              [-74.92924304699994, 10.757580695000058],
              [-74.92949622099991, 10.757597429000043],
              [-74.92969965999993, 10.757645395000054],
              [-74.9299214749999 , 10.75775767500005 ],
              [-74.93003825399995, 10.757891638000046],
              [-74.93022124799995, 10.758064440000055],
              [-74.93046484399991, 10.758115116000056],
              [-74.9306986709999 , 10.758142207000049],
              [-74.93095332899992, 10.758279685000048],
              [-74.93116547499994, 10.758448767000061],
              [-74.93117555299995, 10.758707641000058],
              [-74.93122287999995, 10.758928863000051],
              [-74.9314899179999 , 10.759055788000069],
              [-74.93153369399994, 10.75908587300006 ],
              [-74.93163795599992, 10.759157535000043],
              [-74.93171645199993, 10.759266110000056],
              [-74.93172966199995, 10.75941574300009 ],
              [-74.93166774799994, 10.759715750000055],
              [-74.9315764239999 , 10.75994492700005 ],
              [-74.93154932499993, 10.760161202000063],
              [-74.93162533799995, 10.760304078000047],
              [-74.93187240299994, 10.76038959400006 ],
              [-74.93208265999994, 10.760507087000065],
              [-74.93221027099992, 10.760653478000052],
              [-74.9323722819999 , 10.760802201000047],
              [-74.93249251799995, 10.76105147800007 ],
              [-74.93241511799994, 10.761166850000052],
              [-74.93229685299991, 10.761301548000063],
              [-74.93238269699992, 10.76154850000006 ],
              [-74.93247556099993, 10.761708859000066],
              [-74.9324627869999 , 10.761966352000059],
              [-74.93237025499991, 10.762132329000053],
              [-74.93230515599993, 10.762237004000042],
              [-74.93222530999992, 10.762386613000047],
              [-74.93218062699992, 10.762607519000085],
              [-74.9322215649999 , 10.76276990200006 ],
              [-74.9322902259999 , 10.762935326000047],
              [-74.9324067739999 , 10.763075318000062],
              [-74.93259328999994, 10.76328324900004 ],
              [-74.93264966899994, 10.763539772000058],
              [-74.93262670599995, 10.763699015000043],
              [-74.9324122129999 , 10.76387965500004 ],
              [-74.93219371799995, 10.763876236000044],
              [-74.93192081199993, 10.76390929200005 ],
              [-74.93168556199993, 10.763979372000051],
              [-74.9314674769999 , 10.764131019000047],
              [-74.93129845499993, 10.764320446000056],
              [-74.93119810399992, 10.76459497500008 ],
              [-74.93127245799991, 10.76476086200006 ],
              [-74.93132853199995, 10.764881034000041],
              [-74.9313630179999 , 10.764954947000092],
              [-74.93148646199995, 10.765078259000063],
              [-74.93149335099992, 10.765222306000055],
              [-74.9314945029999 , 10.765365967000037],
              [-74.93142040399994, 10.76551594700004 ],
              [-74.93141174199991, 10.765635966000048],
              [-74.93140758899995, 10.765773530000047],
              [-74.93139280299994, 10.765898876000051],
              [-74.93138717599993, 10.765909         ],
              [-74.93126056499995, 10.766136777000042],
              [-74.93112882699995, 10.766208116000087],
              [-74.93098606399991, 10.766272959000048],
              [-74.93091194999994, 10.766357803000062],
              [-74.93086280999995, 10.766480758000057],
              [-74.93092486099994, 10.766657330000044],
              [-74.93106013199991, 10.766775791000043],
              [-74.93124246199994, 10.766880287000049],
              [-74.93133444199992, 10.766961306000042],
              [-74.93146852699994, 10.767096956000046],
              [-74.93157724399993, 10.767265326000086],
              [-74.9316941379999 , 10.767480257000045],
              [-74.93191743699992, 10.767656603000091],
              [-74.93212070699991, 10.767871805000084],
              [-74.93223806899994, 10.768081092000045],
              [-74.93232594099993, 10.76855037300004 ],
              [-74.9323316359999 , 10.768631234000054],
              [-74.93253130699992, 10.768737075000047],
              [-74.93277686399995, 10.76876562700005 ],
              [-74.93296215099991, 10.768715586000042],
              [-74.93306464099993, 10.76868790900005 ],
              [-74.93318499399993, 10.768696263000038],
              [-74.93325744899994, 10.768730044000051],
              [-74.93326913399994, 10.768745004000039],
              [-74.93330492199993, 10.768790831000047],
              [-74.9333413519999 , 10.768845103000046],
              [-74.9333703829999 , 10.76900235000005 ],
              [-74.93340596599995, 10.769148561000065],
              [-74.93342477099992, 10.76928786600007 ],
              [-74.93347019399994, 10.769457783000064],
              [-74.93358728399994, 10.769592448000083],
              [-74.93382602999992, 10.76963784700007 ],
              [-74.9340836579999 , 10.769742076000057],
              [-74.93429460499993, 10.76993509500005 ],
              [-74.93433186899995, 10.770139030000053],
              [-74.93425728799991, 10.770375431000048],
              [-74.93415770599995, 10.770558316000063],
              [-74.93419250799991, 10.770796632000042],
              [-74.93436335699994, 10.770906405000062],
              [-74.9344101339999 , 10.770905112000037],
              [-74.93465798599993, 10.770898258000045],
              [-74.93498832799992, 10.770875354000054],
              [-74.93524329799993, 10.77093923700005 ],
              [-74.93545195599995, 10.77092507000009 ],
              [-74.93548343399993, 10.770926466000049],
              [-74.93567623399991, 10.770935017000056],
              [-74.9358681409999 , 10.77099449800005 ],
              [-74.93609122599992, 10.770941037000057],
              [-74.93639836099993, 10.770680470000059],
              [-74.93669613299994, 10.770551621000038],
              [-74.9369102629999 , 10.770543556000064],
              [-74.93711748599992, 10.770632873000068],
              [-74.93718100699994, 10.77070251200007 ],
              [-74.9372352879999 , 10.770762021000053],
              [-74.93724310999994, 10.770780087000048],
              [-74.93727002399993, 10.770842254000058],
              [-74.93728945499993, 10.770887136000056],
              [-74.93730469299993, 10.770922332000055],
              [-74.93742788499992, 10.771138244000042],
              [-74.93767709799994, 10.771368780000046],
              [-74.93767731499992, 10.771368948000088],
              [-74.93785776899995, 10.771508144000052],
              [-74.93797482799994, 10.77155969100005 ],
              [-74.93813781599994, 10.771631464000052],
              [-74.9384137479999 , 10.77165080900005 ],
              [-74.93868604999994, 10.77172175600009 ],
              [-74.93888531599993, 10.771845208000059],
              [-74.93900079199994, 10.771928216000049],
              [-74.93920833499993, 10.772098357000061],
              [-74.93934844099994, 10.772393243000067],
              [-74.93940005299993, 10.772806001000049],
              [-74.9394150469999 , 10.772890947000064],
              [-74.93945303499993, 10.773106161000044],
              [-74.93944812899991, 10.773175265000077],
              [-74.9394330909999 , 10.773387099000047],
              [-74.93943141799991, 10.773666950000063],
              [-74.93942144899995, 10.773777259000042],
              [-74.93940449999991, 10.773964798000065],
              [-74.93945478299992, 10.774155342000086],
              [-74.9394903729999 , 10.77429021000006 ],
              [-74.93962481499995, 10.774432309000076],
              [-74.93990214099995, 10.774524092000092],
              [-74.93991292399994, 10.774527661000093],
              [-74.94008519099992, 10.774530560000073],
              [-74.94026881899993, 10.774468636000051],
              [-74.94041033799994, 10.774507421000067],
              [-74.94049571099993, 10.774530819000063],
              [-74.94052957999992, 10.77456634400005 ],
              [-74.94061951999993, 10.774660654000058],
              [-74.94073820599993, 10.77502651900005 ],
              [-74.94082236399993, 10.775389990000065],
              [-74.94086448399992, 10.775612117000037],
              [-74.9410525529999 , 10.775815808000061],
              [-74.94105729599994, 10.775818805000085],
              [-74.9414125639999 , 10.77604331300006 ],
              [-74.94151060299993, 10.776211812000042],
              [-74.94152781899993, 10.77640114400009 ],
              [-74.9415301379999 , 10.776426651000065],
              [-74.94150328199993, 10.77649789800006 ],
              [-74.94137047999993, 10.776574977000053],
              [-74.94118470299992, 10.776628818000063],
              [-74.94106835699995, 10.776637811000057],
              [-74.94103795399991, 10.776658714000064],
              [-74.94099256999993, 10.776753935000045],
              [-74.94097680999994, 10.77678700100006 ],
              [-74.94096259899993, 10.776987863000045],
              [-74.9409753889999 , 10.777019713000072],
              [-74.94104129699991, 10.777183843000046],
              [-74.94118065999993, 10.777271976000065],
              [-74.9411852849999 , 10.777274903000091],
              [-74.94132888699994, 10.777371713000036],
              [-74.94138701299994, 10.777531640000063],
              [-74.94151823699991, 10.777558351000039],
              [-74.94162161899993, 10.777550062000046],
              [-74.9416432349999 , 10.77749315400007 ],
              [-74.94166428099993, 10.777437745000043],
              [-74.94170815099994, 10.777308211000047],
              [-74.9417840939999 , 10.777271876000043],
              [-74.94186241099993, 10.777234405000058],
              [-74.94209259799993, 10.777170125000055],
              [-74.94231474799994, 10.777077304000045],
              [-74.94243088899992, 10.777028776000066],
              [-74.94266927399991, 10.776930478000054],
              [-74.94271783599993, 10.776933945000053],
              [-74.9427666059999 , 10.776937429000043],
              [-74.94278327799992, 10.776938619000077],
              [-74.94281322299992, 10.776940760000059],
              [-74.9429592859999 , 10.777003094000065],
              [-74.94314082499994, 10.777137176000053],
              [-74.94330714099993, 10.777241354000068],
              [-74.94357674699995, 10.77735868600007 ],
              [-74.9438035359999 , 10.777426824000088],
              [-74.94393281799995, 10.777482228000054],
              [-74.94410072399995, 10.777482697000039],
              [-74.94410312799994, 10.777482871000075],
              [-74.9442735959999 , 10.777495058000056],
              [-74.94439339799993, 10.777520938000066],
              [-74.94442181799991, 10.777528744000051],
              [-74.94454457099994, 10.777595227000063],
              [-74.9446901039999 , 10.777749910000068],
              [-74.94468596899992, 10.777887288000045],
              [-74.94468576899993, 10.777893873000039],
              [-74.94459164299991, 10.777996777000055],
              [-74.94450356599992, 10.778013547000057],
              [-74.94448102799993, 10.778086960000053],
              [-74.9445584959999 , 10.778138684000055],
              [-74.94469107099991, 10.778148183000042],
              [-74.94477350699992, 10.778211807000048],
              [-74.94478070499991, 10.778356623000093],
              [-74.9447163079999 , 10.778530936000038],
              [-74.94465021299993, 10.778700787000048],
              [-74.94464043499994, 10.778811131000055],
              [-74.94471678299993, 10.778960874000063],
              [-74.9448180409999 , 10.779250923000063],
              [-74.9449265689999 , 10.779437647000066],
              [-74.94499907399995, 10.779477498000062],
              [-74.94518046799993, 10.779369359000043],
              [-74.94539582099992, 10.779107817000067],
              [-74.94544770599992, 10.779111550000039],
              [-74.94550661199992, 10.77917919500004 ],
              [-74.94560857599993, 10.77929627800006 ],
              [-74.94560467499991, 10.779434537000043],
              [-74.94554605099995, 10.77960925800005 ],
              [-74.94546840499993, 10.779724892000047],
              [-74.94541260199992, 10.77985940900004 ],
              [-74.9454074759999 , 10.780014913000059],
              [-74.94551151299993, 10.780184078000048],
              [-74.94558619399993, 10.78027607200005 ],
              [-74.94567052899993, 10.780478475000052],
              [-74.94582487899993, 10.780674417000057],
              [-74.94595591699994, 10.780707001000053],
              [-74.94604821199994, 10.780713673000037],
              [-74.94613956899991, 10.780650983000044],
              [-74.94621026399994, 10.78055213500005 ],
              [-74.94630082299994, 10.780500933000042],
              [-74.9463077929999 , 10.780484110000089],
              [-74.94637256099992, 10.780488791000039],
              [-74.94649817399994, 10.780497869000044],
              [-74.94651010699994, 10.78049948000006 ],
              [-74.946682        , 10.780522696000048],
              [-74.94682785999993, 10.780510138000068],
              [-74.94707786899994, 10.780488830000081],
              [-74.94708458199995, 10.780488258000048],
              [-74.94716734399992, 10.780486409000048],
              [-74.94725626399992, 10.780484422000086],
              [-74.94740294399992, 10.78046037100006 ],
              [-74.94764879299993, 10.780345286000056],
              [-74.94777868199992, 10.78014674700006 ],
              [-74.94799650899995, 10.780018074000054],
              [-74.94809303199992, 10.780048137000051],
              [-74.94823403999993, 10.780272013000058],
              [-74.9483942459999 , 10.78038755600005 ],
              [-74.9484794899999 , 10.780330161000052],
              [-74.94851782299992, 10.780113423000046],
              [-74.94862048099992, 10.779889776000061],
              [-74.94884574199995, 10.77973849700004 ],
              [-74.94901320499991, 10.779750553000042],
              [-74.94915180699991, 10.779760532000068],
              [-74.9493464059999 , 10.779716775000054],
              [-74.9495617259999 , 10.779709173000072],
              [-74.9496752629999 , 10.779746231000047],
              [-74.94981848599991, 10.77977387900006 ],
              [-74.95002230199992, 10.779765440000062],
              [-74.95021250199994, 10.77973998400006 ],
              [-74.95045995499993, 10.779688475000057],
              [-74.9507756189999 , 10.779491645000064],
              [-74.9508800289999 , 10.779493373000037],
              [-74.95093004099994, 10.779525857000067],
              [-74.95092085999994, 10.779658085000051],
              [-74.95087619599991, 10.779799309000055],
              [-74.95075600099995, 10.779940883000052],
              [-74.95066172399993, 10.780043875000047],
              [-74.95060110999992, 10.780247514000052],
              [-74.95062796799994, 10.780445925000038],
              [-74.95067198399994, 10.780564674000061],
              [-74.95078456099992, 10.780699944000048],
              [-74.95086305099994, 10.780907894000052],
              [-74.95093739599992, 10.780924822000088],
              [-74.95105739699994, 10.78086992100009 ],
              [-74.95117143699991, 10.780733661000056],
              [-74.9513018159999 , 10.78069683800004 ],
              [-74.9514405729999 , 10.780706863000091],
              [-74.95158551299994, 10.780711543000052],
              [-74.9518295709999 , 10.780711817000054],
              [-74.9520545179999 , 10.780820566000045],
              [-74.9521923229999 , 10.780928803000052],
              [-74.9521952529999 , 10.780934281000043],
              [-74.95224378699993, 10.781025028000045],
              [-74.95230290899991, 10.781209563000061],
              [-74.95244191799992, 10.781300552000062],
              [-74.95270640099994, 10.781342817000052],
              [-74.95278280199994, 10.781331003000048],
              [-74.95308820199995, 10.781202755000038],
              [-74.9531749919999 , 10.781209036000064],
              [-74.9533306319999 , 10.781312803000048],
              [-74.95349070499992, 10.781521001000044],
              [-74.95350781699995, 10.781568613000047],
              [-74.95355899399993, 10.781711008000059],
              [-74.95356164099991, 10.781868272000054],
              [-74.95355090099991, 10.782023619000086],
              [-74.95355213199991, 10.782174069000064],
              [-74.9536483359999 , 10.78229671300005 ],
              [-74.95379363199993, 10.782382440000049],
              [-74.95394415999993, 10.782561107000049],
              [-74.95395038999993, 10.782723521000037],
              [-74.95397818499993, 10.782910652000055],
              [-74.95414531299991, 10.783102150000047],
              [-74.9543020829999 , 10.78327556700009 ],
              [-74.95436371599993, 10.783425067000053],
              [-74.95439823499993, 10.783508801000039],
              [-74.95442025099993, 10.783695540000053],
              [-74.9544145189999 , 10.783862905000092],
              [-74.95437325699993, 10.784039256000085],
              [-74.95428425699993, 10.784148481000045],
              [-74.95415831399993, 10.78420292900006 ],
              [-74.95406353699991, 10.784311715000058],
              [-74.95406795399992, 10.784416192000037],
              [-74.95415722399991, 10.78455580700006 ],
              [-74.9543683149999 , 10.784785365000062],
              [-74.95438811899993, 10.78491992700009 ],
              [-74.95433027699994, 10.785083532000044],
              [-74.95420891299995, 10.785155658000065],
              [-74.95419057899994, 10.785252706000051],
              [-74.95424171699995, 10.785354853000058],
              [-74.9543064369999 , 10.785429057000044],
              [-74.95446184999992, 10.785562174000063],
              [-74.95458071599995, 10.785779258000048],
              [-74.95467104799991, 10.78598848100006 ],
              [-74.9547761419999 , 10.786152496000057],
              [-74.95488152299993, 10.78616607300006 ],
              [-74.95500868199991, 10.786094390000073],
              [-74.95509324199992, 10.785880675000044],
              [-74.95510594799993, 10.78569641100006 ],
              [-74.95525603399994, 10.78554538800006 ],
              [-74.9553734349999 , 10.785530855000047],
              [-74.95552543899993, 10.785605667000084],
              [-74.95560796399991, 10.78567539100004 ],
              [-74.9556545339999 , 10.78575984300005 ],
              [-74.95585196999991, 10.78593643000005 ],
              [-74.95599545699991, 10.786051168000085],
              [-74.95605677899994, 10.78607177300006 ],
              [-74.95617189699993, 10.786110454000038],
              [-74.95637351599993, 10.786142641000083],
              [-74.95649703099991, 10.78620962900004 ],
              [-74.95657899899993, 10.786281728000063],
              [-74.95677034999994, 10.786209005000046],
              [-74.95702147599991, 10.786030564000043],
              [-74.95722899899994, 10.78580837900006 ],
              [-74.95748449999991, 10.785566535000044],
              [-74.95776202499991, 10.785343663000049],
              [-74.95794455099991, 10.785316498000043],
              [-74.95825434799991, 10.785316292000061],
              [-74.95848630899991, 10.78533328900005 ],
              [-74.95869653599993, 10.78517067000007 ],
              [-74.95875869199995, 10.785122590000071],
              [-74.95878968699992, 10.785098614000049],
              [-74.95914733199993, 10.785157373000061],
              [-74.95948419299992, 10.785129398000038],
              [-74.95977127899994, 10.78506014900006 ],
              [-74.95981303899993, 10.785050074000083],
              [-74.95986400099991, 10.785037780000039],
              [-74.96053643499994, 10.784750633000044],
              [-74.96069694599993, 10.78466781800006 ],
              [-74.96092823399994, 10.784672301000057],
              [-74.96126328899993, 10.784733845000062],
              [-74.96156068399995, 10.784857030000069],
              [-74.96214500299993, 10.785256357000037],
              [-74.96237438799994, 10.785371806000057],
              [-74.96249708599993, 10.785461474000044],
              [-74.9626588399999 , 10.78555749800006 ],
              [-74.9628832759999 , 10.78567273300007 ],
              [-74.96306287999994, 10.785707463000051],
              [-74.96317923399994, 10.785729957000058],
              [-74.96335405499991, 10.785731775000045],
              [-74.9635518739999 , 10.785701448000054],
              [-74.96372173399993, 10.785653848000038],
              [-74.96375900999993, 10.78564405800006 ],
              [-74.9639152439999 , 10.78560301400006 ],
              [-74.96412971399991, 10.78569439100005 ],
              [-74.96422228099993, 10.785816818000058],
              [-74.96422679599993, 10.78592021500009 ],
              [-74.96422859699993, 10.785961485000087],
              [-74.96436950299994, 10.786061635000067],
              [-74.9643801069999 , 10.786059858000044],
              [-74.96451105499995, 10.786037885000042],
              [-74.96453485799992, 10.786033889000066],
              [-74.96474610799993, 10.786015416000055],
              [-74.96486180599993, 10.78610210000005 ],
              [-74.96489595499992, 10.786225813000044],
              [-74.96499852099993, 10.786369149000052],
              [-74.9651469229999 , 10.78637182600005 ],
              [-74.9653251279999 , 10.786252795000053],
              [-74.96546213399995, 10.78621423900006 ],
              [-74.9656657729999 , 10.786218245000043],
              [-74.96591732999991, 10.786318169000083],
              [-74.96608442199994, 10.786342457000046],
              [-74.96616134099992, 10.78634530700009 ],
              [-74.96628105199994, 10.786349740000048],
              [-74.9662875809999 , 10.786349983000036],
              [-74.9664775789999 , 10.786378899000056],
              [-74.96671413199994, 10.786483411000063],
              [-74.96682260099993, 10.78654933100006 ],
              [-74.96696826099992, 10.786623852000048],
              [-74.9670811709999 , 10.786669937000056],
              [-74.96728513799991, 10.786668201000055],
              [-74.9673784339999 , 10.786666669000056],
              [-74.96753416899992, 10.786644615000057],
              [-74.96767517899991, 10.786699243000044],
              [-74.9678265039999 , 10.786805540000046],
              [-74.96784081099992, 10.786815592000039],
              [-74.9678060999999 , 10.786887854000042],
              [-74.96779787199995, 10.78695638000005 ],
              [-74.96772318399991, 10.78705212500006 ],
              [-74.96770494499992, 10.787137160000043],
              [-74.96771305199991, 10.787216145000059],
              [-74.96793025799991, 10.78730470000005 ],
              [-74.96813968899994, 10.787413399000059],
              [-74.96834216499991, 10.787461070000063],
              [-74.96839722099992, 10.787495439000054],
              [-74.9685890959999 , 10.787615206000055],
              [-74.96878261699993, 10.787782532000051],
              [-74.96897117899994, 10.787932721000061],
              [-74.96910721099994, 10.788009807000037],
              [-74.96932866399993, 10.788003637000088],
              [-74.9695263129999 , 10.788079116000063],
              [-74.96963213499993, 10.788213790000043],
              [-74.96967971099991, 10.788237562000063],
              [-74.96975906499995, 10.788338557000088],
              [-74.96978174399993, 10.788420859000041],
              [-74.96978737199993, 10.788565640000058],
              [-74.9698367119999 , 10.788753537000048],
              [-74.96997051099993, 10.788864398000044],
              [-74.97015386099991, 10.788898475000053],
              [-74.9703241069999 , 10.788876883000057],
              [-74.9703841029999 , 10.788890079000055],
              [-74.97038939099991, 10.788904908000063],
              [-74.97040745499993, 10.788955575000045],
              [-74.97040322399994, 10.789009907000093],
              [-74.9703903269999 , 10.789063587000044],
              [-74.97038166199991, 10.789137715000038],
              [-74.97033587699991, 10.789240662000054],
              [-74.9703151999999 , 10.789357024000083],
              [-74.97029540799991, 10.789536723000083],
              [-74.97022762499995, 10.789698392000048],
              [-74.97012593199992, 10.789990623000051],
              [-74.97008916199991, 10.790096292000044],
              [-74.97006914899993, 10.790316217000054],
              [-74.9700658029999 , 10.790508641000088],
              [-74.97007384499994, 10.790532600000063],
              [-74.97011534099994, 10.790656176000084],
              [-74.9701216869999 , 10.790661769000053],
              [-74.9702295319999 , 10.79075683800005 ],
              [-74.9703923859999 , 10.790754803000084],
              [-74.9705550189999 , 10.790718239000057],
              [-74.97067452899995, 10.79070454300006 ],
              [-74.97079647399994, 10.790705151000054],
              [-74.97089752799991, 10.790787561000059],
              [-74.9708901649999 , 10.790836114000058],
              [-74.97084329699993, 10.790878555000063],
              [-74.97077664299991, 10.79090536700005 ],
              [-74.97067870399991, 10.790969802000063],
              [-74.97067068399991, 10.791110039000046],
              [-74.97068044799994, 10.791142939000054],
              [-74.97069598799993, 10.791195309000045],
              [-74.97077947499992, 10.79127922300006 ],
              [-74.97090422499991, 10.791383693000057],
              [-74.97100279999995, 10.791460118000089],
              [-74.9710898219999 , 10.791498311000055],
              [-74.97110008599992, 10.79150616600009 ],
              [-74.97145515799991, 10.791513193000071],
              [-74.97162962399995, 10.791509154000039],
              [-74.97180364499991, 10.79151083100004 ],
              [-74.97194762499993, 10.791504847000056],
              [-74.9720532369999 , 10.791564538000046],
              [-74.9721308689999 , 10.791685298000061],
              [-74.97221069199992, 10.791852169000038],
              [-74.97241700599994, 10.791959673000065],
              [-74.97255588299993, 10.791964427000039],
              [-74.97272541399991, 10.791873858000088],
              [-74.97274323399995, 10.791645454000047],
              [-74.97257597999993, 10.791558124000062],
              [-74.97235767199993, 10.791530120000061],
              [-74.9722925179999 , 10.791249452000045],
              [-74.97247317999995, 10.791090785000051],
              [-74.97270704499994, 10.791068280000047],
              [-74.97291327399995, 10.791055671000038],
              [-74.97291692599993, 10.791055450000044],
              [-74.9729742159999 , 10.790916166000045],
              [-74.97296053299993, 10.790719804000048],
              [-74.97311142299992, 10.790645063000056],
              [-74.97329733099991, 10.790641922000077],
              [-74.97349811599992, 10.79072086900004 ],
              [-74.97353221399993, 10.790878528000064],
              [-74.97352418899993, 10.790981295000051],
              [-74.97359450599993, 10.791021571000044],
              [-74.9737497019999 , 10.791039063000085],
              [-74.97385631999992, 10.791059105000045],
              [-74.97395676999992, 10.791061958000057],
              [-74.97397778899995, 10.791062556000043],
              [-74.9741827119999 , 10.790814028000057],
              [-74.9742346509999 , 10.79066871300006 ],
              [-74.97442042799992, 10.790590941000062],
              [-74.97456448499992, 10.790601859000049],
              [-74.9746309059999 , 10.790620334000039],
              [-74.9747028729999 , 10.79050088100007 ],
              [-74.97473292399991, 10.790401562000056],
              [-74.97477788899994, 10.790254023000045],
              [-74.97485651199992, 10.79012928800006 ],
              [-74.9749404559999 , 10.790081360000045],
              [-74.97495096099993, 10.790075363000085],
              [-74.97504642299992, 10.790029981000089],
              [-74.97515821299993, 10.790003217000049],
              [-74.9753031969999 , 10.789938533000054],
              [-74.97538735499995, 10.78990705700005 ],
              [-74.97547328299993, 10.78989312400006 ],
              [-74.97550828899995, 10.789895716000046],
              [-74.9756105809999 , 10.78990039200005 ],
              [-74.9756891479999 , 10.789932100000044],
              [-74.97572357599995, 10.789943354000059],
              [-74.97589073899991, 10.789976322000086],
              [-74.9759535469999 , 10.78997952900005 ],
              [-74.97605781599992, 10.790020611000045],
              [-74.97613077299991, 10.79004777700004 ],
              [-74.97626678299991, 10.790030297000044],
              [-74.9763886689999 , 10.79003776400009 ],
              [-74.97646701499991, 10.79006085800006 ],
              [-74.97655270099995, 10.790128010000046],
              [-74.97664769399995, 10.790198863000057],
              [-74.97672878599991, 10.79024682000005 ],
              [-74.97682261599994, 10.790301513000088],
              [-74.97687585499995, 10.790338819000056],
              [-74.97689364399992, 10.790352658000074],
              [-74.97692325999992, 10.790375698000048],
              [-74.97693185299994, 10.79038392700005 ],
              [-74.97696251199994, 10.790413285000056],
              [-74.97697591699995, 10.790451131000054],
              [-74.97712086299993, 10.79045923600006 ],
              [-74.97717920199995, 10.790463569000053],
              [-74.9772128269999 , 10.790442859000052],
              [-74.97724528999993, 10.790396030000068],
              [-74.9773452949999 , 10.790301958000043],
              [-74.97743636899992, 10.790230180000037],
              [-74.9775469679999 , 10.790197790000093],
              [-74.97765449499991, 10.790231609000045],
              [-74.97779122899993, 10.790311079000048],
              [-74.97791552199993, 10.790401213000052],
              [-74.97803637999993, 10.789973423000049],
              [-74.9787264169999 , 10.790178132000051],
              [-74.9796567919999 , 10.790455135000059],
              [-74.98050622099993, 10.790692409000087],
              [-74.98099167799995, 10.790850826000053],
              [-74.98187945799992, 10.790524790000063],
              [-74.98208008299991, 10.790451110000049],
              [-74.98342679599995, 10.790290235000043],
              [-74.98373062699994, 10.790555052000059],
              [-74.9846578399999 , 10.790254893000053],
              [-74.98487069899994, 10.790391156000055],
              [-74.98493845399992, 10.79028769200005 ],
              [-74.9849463889999 , 10.790269027000079],
              [-74.9851302269999 , 10.790276554000059],
              [-74.98524196299991, 10.790281131000086],
              [-74.9855239069999 , 10.790354562000061],
              [-74.98574169699992, 10.790423272000055],
              [-74.98598607899993, 10.790543980000052],
              [-74.98599132599992, 10.79054657000006 ],
              [-74.9862233099999 , 10.790581578000058],
              [-74.9864621669999 , 10.79051264800006 ],
              [-74.98669265799992, 10.790483677000054],
              [-74.98690740899991, 10.790528596000058],
              [-74.98717882499994, 10.790688456000055],
              [-74.98728533899992, 10.790951877000055],
              [-74.98728032199995, 10.791201779000062],
              [-74.98724098199995, 10.791426587000046],
              [-74.98722724499993, 10.791513442000053],
              [-74.98722478799993, 10.791529002000061],
              [-74.9872000069999 , 10.791685722000068],
              [-74.98729089299991, 10.792001313000071],
              [-74.98754695499991, 10.792498072000058],
              [-74.98790457299992, 10.792788228000063],
              [-74.98800524299992, 10.792964552000058],
              [-74.98808345899994, 10.793018712000048],
              [-74.98819709799994, 10.793080487000054],
              [-74.98841225299992, 10.793131505000076],
              [-74.98861391799994, 10.793117425000048],
              [-74.98879471999993, 10.79306099300004 ],
              [-74.98899455299994, 10.793075909000038],
              [-74.9891231169999 , 10.793097150000051],
              [-74.98929134299993, 10.793144665000057],
              [-74.98949710999995, 10.793160021000062],
              [-74.9896545659999 , 10.793008590000056],
              [-74.9897806109999 , 10.792884765000053],
              [-74.98996555199994, 10.792857745000049],
              [-74.99014163499993, 10.792876698000043],
              [-74.99029406599993, 10.792987982000056],
              [-74.99046403699992, 10.79328726700004 ],
              [-74.9905741099999 , 10.79359880000004 ],
              [-74.99073721999991, 10.793915352000056],
              [-74.9909277289999 , 10.79408133000004 ],
              [-74.99134510399995, 10.794132122000065],
              [-74.9916784909999 , 10.794104506000053],
              [-74.99196850999994, 10.794015243000047],
              [-74.99219922599991, 10.793927375000067],
              [-74.99236728599993, 10.79389321800005 ],
              [-74.99255672999993, 10.793902501000048],
              [-74.99285300199995, 10.794088215000045],
              [-74.99311902699992, 10.794376871000054],
              [-74.99347331399991, 10.794697778000057],
              [-74.9938328579999 , 10.795001434000085],
              [-74.99371080899994, 10.795266783000045],
              [-74.99366840099992, 10.795639055000038],
              [-74.99367067999992, 10.795971862000044],
              [-74.99357647199992, 10.796219359000077],
              [-74.9935595469999 , 10.796460340000067],
              [-74.99368072499993, 10.796707154000046],
              [-74.99375876899995, 10.796791633000055],
              [-74.99387446199995, 10.79704477100006 ],
              [-74.99378849199991, 10.797168946000056],
              [-74.99365631499995, 10.797238618000051],
              [-74.99349530199993, 10.797246460000054],
              [-74.9933827609999 , 10.797185889000048],
              [-74.99318368799993, 10.797129673000086],
              [-74.99297201699994, 10.797224326000048],
              [-74.99291994299995, 10.797270697000044],
              [-74.99276429299994, 10.79740928700005 ],
              [-74.99254352699995, 10.797650342000054],
              [-74.9924423519999 , 10.797668418000057],
              [-74.99232280399991, 10.797682216000055],
              [-74.99222748699992, 10.797699808000061],
              [-74.99204224599993, 10.797861946000069],
              [-74.9919881109999 , 10.798089029000039],
              [-74.99200231099991, 10.798269325000092],
              [-74.99207794699993, 10.798467782000046],
              [-74.99207870499993, 10.79850891700005 ],
              [-74.99208159999995, 10.798666530000048],
              [-74.99201918599994, 10.79902706900009 ],
              [-74.99189028399991, 10.79914354300007 ],
              [-74.99166549799992, 10.799186249000059],
              [-74.99138973599992, 10.799116170000048],
              [-74.99127165199991, 10.799116948000062],
              [-74.99116761199991, 10.799117639000087],
              [-74.99093625299992, 10.799155048000046],
              [-74.99080263799993, 10.799213377000058],
              [-74.99079044399991, 10.79928994100004 ],
              [-74.99078655199992, 10.799314377000087],
              [-74.99083106599994, 10.799498063000044],
              [-74.99091873899994, 10.799619448000044],
              [-74.9910036359999 , 10.799782070000049],
              [-74.99100314799995, 10.800004775000048],
              [-74.9909489449999 , 10.800155932000052],
              [-74.99070417199994, 10.80048176400004 ],
              [-74.99054799299995, 10.800712106000049],
              [-74.99043221499994, 10.800921396000092],
              [-74.9904372279999 , 10.80113784100007 ],
              [-74.99060161199992, 10.801393217000054],
              [-74.9907558939999 , 10.801520505000042],
              [-74.99086602799991, 10.801724913000044],
              [-74.99090370699992, 10.801794847000053],
              [-74.99104322699992, 10.802192951000052],
              [-74.99107621999991, 10.802612039000053],
              [-74.99096599099994, 10.802815072000044],
              [-74.99086588499995, 10.802917606000051],
              [-74.99070989499995, 10.803088341000091],
              [-74.99071110299991, 10.803140412000062],
              [-74.99071491299992, 10.803304838000088],
              [-74.99082762699993, 10.803452182000058],
              [-74.9909695579999 , 10.803574611000045],
              [-74.99112156899992, 10.80374891100007 ],
              [-74.99122211499991, 10.804033254000046],
              [-74.99117248099992, 10.80424284500009 ],
              [-74.9910994299999 , 10.804384137000056],
              [-74.9911040099999 , 10.804518615000063],
              [-74.99117931799992, 10.804635120000057],
              [-74.99128678499994, 10.804625634000047],
              [-74.99146724999991, 10.804703534000055],
              [-74.99160269899994, 10.804668133000064],
              [-74.9916619139999 , 10.80473326300006 ],
              [-74.99170091799994, 10.80492329100008 ],
              [-74.9917858359999 , 10.805009590000054],
              [-74.9919299629999 , 10.805008580000049],
              [-74.99207224399993, 10.804995255000051],
              [-74.99224665899993, 10.805073640000046],
              [-74.99230178699992, 10.805110699000068],
              [-74.9924615029999 , 10.805218083000057],
              [-74.99257118199995, 10.805313910000052],
              [-74.99272444299993, 10.805505535000066],
              [-74.99285565199995, 10.805722545000037],
              [-74.99298503099993, 10.805840036000063],
              [-74.993091        , 10.805889251000053],
              [-74.9931899529999 , 10.805884500000047],
              [-74.99323457499992, 10.805882360000055],
              [-74.99338682899992, 10.806062264000047],
              [-74.99338912099995, 10.806319966000046],
              [-74.99346661499993, 10.806541651000089],
              [-74.99361977199993, 10.806580755000084],
              [-74.99381234099991, 10.806510840000044],
              [-74.9939810379999 , 10.806366886000092],
              [-74.99419003799994, 10.806049443000063],
              [-74.9943027679999 , 10.805805063000037],
              [-74.99442098399993, 10.805630522000058],
              [-74.99459049899991, 10.805498323000052],
              [-74.99486394899992, 10.805392059000042],
              [-74.99507873199991, 10.805378874000041],
              [-74.99519228999992, 10.805450799000084],
              [-74.99530721899993, 10.805616284000052],
              [-74.99546515999992, 10.80564325000006 ],
              [-74.99558739199995, 10.805825628000036],
              [-74.9955530279999 , 10.806040887000051],
              [-74.99553523499992, 10.806152345000044],
              [-74.9954565189999 , 10.80644630100005 ],
              [-74.9954103039999 , 10.806772530000046],
              [-74.99524778599994, 10.807068172000072],
              [-74.9950725619999 , 10.807206618000066],
              [-74.99489907499992, 10.807216036000057],
              [-74.99460990099993, 10.80712468300004 ],
              [-74.99432753899993, 10.80742544900005 ],
              [-74.99413727099994, 10.807600773000047],
              [-74.99397028399994, 10.80762142900005 ],
              [-74.99350217599994, 10.807633850000059],
              [-74.99319048699994, 10.807644175000064],
              [-74.99286172599994, 10.807744050000053],
              [-74.9925888969999 , 10.807944577000058],
              [-74.9923590009999 , 10.808086764000052],
              [-74.9922447699999 , 10.80815741400005 ],
              [-74.99212436399995, 10.808309120000047],
              [-74.99200899499994, 10.808488631000046],
              [-74.99200026199992, 10.80850221500009 ],
              [-74.99199609899995, 10.808755096000084],
              [-74.99203102399991, 10.808892889000049],
              [-74.99220889399993, 10.809088256000052],
              [-74.99231734099993, 10.809242881000046],
              [-74.99230863499992, 10.809277029000043],
              [-74.9922483819999 , 10.809513343000049],
              [-74.99203280099994, 10.809603300000049],
              [-74.99191876999993, 10.809619656000052],
              [-74.99184288399994, 10.809726383000054],
              [-74.99185802999995, 10.80985447200004 ],
              [-74.9918249079999 , 10.809892726000044],
              [-74.9917471629999 , 10.809899761000054],
              [-74.99167495099994, 10.809824037000055],
              [-74.9916542709999 , 10.809637867000049],
              [-74.99162760799993, 10.809452240000041],
              [-74.99146741599992, 10.809343411000043],
              [-74.99132796799995, 10.809332488000052],
              [-74.99116869699992, 10.809376257000054],
              [-74.99103792699992, 10.809629089000055],
              [-74.99098398799993, 10.81012884100005 ],
              [-74.99107708699995, 10.810324067000067],
              [-74.9912339949999 , 10.810536506000062],
              [-74.99140394699992, 10.810658032000049],
              [-74.9915287099999 , 10.810764759000051],
              [-74.99161616299995, 10.810903194000048],
              [-74.99161189099993, 10.811029159000043],
              [-74.99151390999992, 10.811240122000072],
              [-74.99148561299995, 10.811277260000054],
              [-74.9913830129999 , 10.811411907000092],
              [-74.99129896199992, 10.811648735000063],
              [-74.99134746899995, 10.811767107000037],
              [-74.99143830699995, 10.811790965000057],
              [-74.99160359399991, 10.811826010000061],
              [-74.9917241359999 , 10.811840551000046],
              [-74.99180920499992, 10.811863991000052],
              [-74.99189575499992, 10.812013833000037],
              [-74.99189450199992, 10.812174477000042],
              [-74.99183501099992, 10.812411232000045],
              [-74.9917279469999 , 10.812518232000059],
              [-74.99153262599992, 10.812641745000064],
              [-74.9915056669999 , 10.812764094000045],
              [-74.99151310199994, 10.812856184000054],
              [-74.99151732099995, 10.81290847300005 ],
              [-74.99157113099994, 10.813032959000054],
              [-74.99168029399993, 10.813190174000056],
              [-74.99186025699993, 10.81340420400005 ],
              [-74.99186539599992, 10.813413660000037],
              [-74.9919511999999 , 10.813571541000044],
              [-74.99201755199994, 10.813828934000071],
              [-74.99219873099992, 10.813882289000048],
              [-74.99230864899994, 10.813884550000068],
              [-74.99252335299991, 10.813808355000049],
              [-74.99269926599993, 10.813637491000065],
              [-74.99272402899993, 10.81347284800006 ],
              [-74.9927402909999 , 10.813342018000071],
              [-74.99283281099991, 10.813199527000052],
              [-74.99291061999992, 10.81309613600007 ],
              [-74.99303040599995, 10.813047466000057],
              [-74.99325943999992, 10.813016298000036],
              [-74.99342761199995, 10.812942436000071],
              [-74.99358956099991, 10.812873869000043],
              [-74.99378772799992, 10.812859625000044],
              [-74.99397115199992, 10.812884478000058],
              [-74.99413774599992, 10.812902361000056],
              [-74.99427095399994, 10.812975227000038],
              [-74.99435999499991, 10.81316540000006 ],
              [-74.99441178399991, 10.813396881000074],
              [-74.99451929899993, 10.813645825000037],
              [-74.99456527599995, 10.813799926000058],
              [-74.9946243359999 , 10.813997892000089],
              [-74.9946606929999 , 10.814339191000045],
              [-74.99461706699992, 10.814594294000074],
              [-74.99450707299991, 10.814810139000087],
              [-74.99434587199994, 10.814941905000069],
              [-74.99430655299994, 10.815071044000092],
              [-74.99429511999995, 10.815213692000043],
              [-74.9942962049999 , 10.815218511000069],
              [-74.99431220699995, 10.815289532000065],
              [-74.9943788409999 , 10.81536563700007 ],
              [-74.99435205999993, 10.815513673000055],
              [-74.99422314799995, 10.815594670000053],
              [-74.99419646199993, 10.815611437000086],
              [-74.99405756999994, 10.81567668300005 ],
              [-74.99391519599993, 10.815718006000054],
              [-74.9937624769999 , 10.81573918500004 ],
              [-74.99371608099995, 10.81577599600007 ],
              [-74.99367972999994, 10.815868046000048],
              [-74.99368010899991, 10.81589762100009 ],
              [-74.99368088199992, 10.815957750000052],
              [-74.9936572009999 , 10.81603636200009 ],
              [-74.99363493699991, 10.816050356000062],
              [-74.99348943699994, 10.816141826000091],
              [-74.99336403399991, 10.816178677000039],
              [-74.9932936379999 , 10.816199364000056],
              [-74.99321057899994, 10.81621980500006 ],
              [-74.99303794899993, 10.816273911000053],
              [-74.9929423719999 , 10.816310009000063],
              [-74.99282502999995, 10.81632508000007 ],
              [-74.99270757499994, 10.816345254000055],
              [-74.99259222799992, 10.816371936000053],
              [-74.99245269999994, 10.816450731000089],
              [-74.99231946899994, 10.81648696600007 ],
              [-74.99220215199995, 10.816541568000048],
              [-74.99212550699991, 10.81663065500004 ],
              [-74.99209563399995, 10.81667169800005 ],
              [-74.99209742499994, 10.816698745000053],
              [-74.99210062399993, 10.816747072000055],
              [-74.99212969399991, 10.816759561000083],
              [-74.99233968099992, 10.816849775000037],
              [-74.9923937499999 , 10.81688093300005 ],
              [-74.9924421209999 , 10.816908807000061],
              [-74.99256090699993, 10.81698048000004 ],
              [-74.99268580199993, 10.817153504000089],
              [-74.9926848099999 , 10.817202167000062],
              [-74.9926591819999 , 10.817269124000063],
              [-74.99258643999991, 10.817309738000063],
              [-74.99251551399993, 10.817327547000048],
              [-74.99246786299994, 10.81734416800009 ],
              [-74.99240964799992, 10.817420236000089],
              [-74.99237227599991, 10.817489207000051],
              [-74.99235831399994, 10.81759141300006 ],
              [-74.9924156909999 , 10.817779036000047],
              [-74.9925222999999 , 10.817935177000038],
              [-74.99258443899993, 10.817946479000057],
              [-74.99271389899991, 10.817913401000055],
              [-74.99286189499992, 10.81787251500009 ],
              [-74.99300138899991, 10.817865392000044],
              [-74.9930845689999 , 10.817975473000047],
              [-74.99308466499991, 10.818118729000048],
              [-74.99308175699991, 10.818227382000089],
              [-74.99308185299992, 10.818370637000044],
              [-74.99308269999995, 10.81857695900004 ],
              [-74.99297905499992, 10.818787197000063],
              [-74.99283201299994, 10.81889269900006 ],
              [-74.9928194279999 , 10.81890172900006 ],
              [-74.9926831169999 , 10.818927669000061],
              [-74.99257384099991, 10.818919794000067],
              [-74.99236466799994, 10.818858891000048],
              [-74.99223436299991, 10.818752136000057],
              [-74.99216688099995, 10.818697195000084],
              [-74.99208770199994, 10.818632734000062],
              [-74.99193556199992, 10.818581666000057],
              [-74.99175056599995, 10.818579776000092],
              [-74.99157249899991, 10.81863566900006 ],
              [-74.9913980739999 , 10.818646         ],
              [-74.99124363599992, 10.818623409000054],
              [-74.99110947699995, 10.818636643000048],
              [-74.9910328599999 , 10.81869379200009 ],
              [-74.99103250399992, 10.818696602000045],
              [-74.99102318399991, 10.818770690000065],
              [-74.9910553279999 , 10.818947703000049],
              [-74.9911173019999 , 10.819149769000092],
              [-74.99114998499994, 10.819283845000086],
              [-74.99119961699995, 10.819459236000057],
              [-74.99133715099993, 10.819512089000057],
              [-74.99152866799994, 10.819468611000048],
              [-74.99166726099992, 10.819435640000052],
              [-74.99170502499993, 10.819469859000037],
              [-74.99171212099992, 10.819561996000061],
              [-74.99169188699994, 10.819706560000043],
              [-74.9916468369999 , 10.819763444000046],
              [-74.99153302899992, 10.819809899000063],
              [-74.99146620199991, 10.819813679000049],
              [-74.9913855019999 , 10.819808111000043],
              [-74.99125253199992, 10.819807131000061],
              [-74.99107276099994, 10.819791819000045],
              [-74.99105418499994, 10.819790239000042],
              [-74.99073838699991, 10.819724565000058],
              [-74.9902964929999 , 10.819570188000057],
              [-74.99011138399993, 10.819533963000083],
              [-74.9899814449999 , 10.819567538000058],
              [-74.9899259629999 , 10.819646557000056],
              [-74.9899647869999 , 10.819812496000054],
              [-74.99003084799995, 10.819963212000062],
              [-74.99005668299992, 10.820074033000083],
              [-74.99006553599992, 10.820289287000037],
              [-74.99001210799992, 10.820451424000055],
              [-74.9899813799999 , 10.82065523700004 ],
              [-74.98997516899993, 10.820769240000061],
              [-74.98993287299993, 10.820900682000058],
              [-74.9899273659999 , 10.820905951000043],
              [-74.98985773099992, 10.82097254200005 ],
              [-74.98980539999991, 10.820950956000047],
              [-74.98973174799994, 10.820920576000049],
              [-74.98956882699991, 10.82075150500009 ],
              [-74.9894623539999 , 10.82067231800005 ],
              [-74.98928321999995, 10.820524956000042],
              [-74.98908363899994, 10.820416175000048],
              [-74.98892068599991, 10.82035581200006 ],
              [-74.9887581669999 , 10.820289754000044],
              [-74.98861283499991, 10.82026213000006 ],
              [-74.9884211719999 , 10.820271258000048],
              [-74.9883380249999 , 10.82029121700009 ],
              [-74.98833274999993, 10.82029248200007 ],
              [-74.9882560019999 , 10.820310903000063],
              [-74.98808742099993, 10.820393233000061],
              [-74.98794185499992, 10.820477237000091],
              [-74.98783963699992, 10.820564477000062],
              [-74.98762830499993, 10.820675217000087],
              [-74.98743506299991, 10.820641963000071],
              [-74.9874285059999 , 10.820640832000038],
              [-74.9874157669999 , 10.820626404000052],
              [-74.98727215499991, 10.820463740000037],
              [-74.9870416839999 , 10.820312984000054],
              [-74.98688202599993, 10.82024713900006 ],
              [-74.98669519799995, 10.820199539000043],
              [-74.98658831299991, 10.820197582000048],
              [-74.98638228099992, 10.820205655000052],
              [-74.98626226499994, 10.820191475000058],
              [-74.98612995299993, 10.820004747000041],
              [-74.9860973939999 , 10.819916257000045],
              [-74.98607047899992, 10.819843107000054],
              [-74.9859551529999 , 10.819697685000051],
              [-74.98586045999991, 10.819688015000054],
              [-74.9857428439999 , 10.819608244000051],
              [-74.98568769799994, 10.819501287000037],
              [-74.98563543199992, 10.819394543000044],
              [-74.98560639499993, 10.819286597000087],
              [-74.98558091899991, 10.819170317000044],
              [-74.98554740799995, 10.819088064000084],
              [-74.9854663779999 , 10.81897923200006 ],
              [-74.98540383999995, 10.818928949000053],
              [-74.98529178599995, 10.818852507000088],
              [-74.98525530599994, 10.818838443000061],
              [-74.98521622699991, 10.81878412900005 ],
              [-74.98512058299991, 10.818677102000038],
              [-74.9850632969999 , 10.818524207000053],
              [-74.98507520699991, 10.818264704000057],
              [-74.98508870199993, 10.818166714000085],
              [-74.98508886299993, 10.818165546000046],
              [-74.98512835399993, 10.817959521000091],
              [-74.98517618999995, 10.817794158000083],
              [-74.98527483499993, 10.817700098000046],
              [-74.98530451699992, 10.817671793000045],
              [-74.98548539699993, 10.817470244000049],
              [-74.98555580499993, 10.817312227000059],
              [-74.98553326299992, 10.817158928000083],
              [-74.98545904399992, 10.817073435000054],
              [-74.98531104599994, 10.817042701000048],
              [-74.98519976499995, 10.817023215000063],
              [-74.98507762299994, 10.816891818000045],
              [-74.98505817499995, 10.816735870000059],
              [-74.98502976499992, 10.816510591000053],
              [-74.98495817399993, 10.81632565600006 ],
              [-74.98486675899994, 10.816201703000047],
              [-74.98474001799991, 10.816158180000059],
              [-74.98456076499991, 10.81615664800006 ],
              [-74.98444636499994, 10.81613978100006 ],
              [-74.98439743699993, 10.816127227000038],
              [-74.98435621499993, 10.816116652000062],
              [-74.98426444499995, 10.816099291000057],
              [-74.98423364999991, 10.816093464000062],
              [-74.98410853899992, 10.816138783000042],
              [-74.98399482699995, 10.816259333000062],
              [-74.98386723099992, 10.816335955000056],
              [-74.98374397599991, 10.816439179000042],
              [-74.9836683119999 , 10.816551146000052],
              [-74.98365548399994, 10.81657012900007 ],
              [-74.98351149599995, 10.816671306000046],
              [-74.98330865399993, 10.816748201000053],
              [-74.98322162099993, 10.816787696000063],
              [-74.98312680399994, 10.816861439000093],
              [-74.98296560599994, 10.816924175000054],
              [-74.9828076039999 , 10.816909894000048],
              [-74.9826012879999 , 10.816821065000056],
              [-74.98246157299991, 10.816768529000058],
              [-74.98232115499991, 10.816761727000085],
              [-74.98213278199995, 10.816802459000087],
              [-74.98203042299991, 10.81682700500005 ],
              [-74.9819422889999 , 10.816818240000089],
              [-74.98179389599994, 10.816756032000058],
              [-74.98170832699992, 10.81670407900009 ],
              [-74.98155102099992, 10.816681265000057],
              [-74.98141061299992, 10.816591019000043],
              [-74.98138868299992, 10.816429239000058],
              [-74.98135231399993, 10.816303609000045],
              [-74.9812952769999 , 10.816256571000054],
              [-74.98117490399994, 10.816262085000062],
              [-74.98115008799994, 10.816263225000057],
              [-74.98107186999994, 10.816337665000049],
              [-74.9810128549999 , 10.816462130000048],
              [-74.98098126399992, 10.816605735000053],
              [-74.98098821999992, 10.816807285000039],
              [-74.9809891829999 , 10.816835148000052],
              [-74.98092563299991, 10.81705366600005 ],
              [-74.98091249299995, 10.81722007400009 ],
              [-74.98091238199993, 10.817221458000063],
              [-74.9808687769999 , 10.817341025000076],
              [-74.9807068309999 , 10.817403165000087],
              [-74.98062569899992, 10.817417713000054],
              [-74.98053105299994, 10.817408017000048],
              [-74.98048181399992, 10.81740446300006 ],
              [-74.9804534349999 , 10.81740241500006 ],
              [-74.98037230199992, 10.81736797800005 ],
              [-74.98030132299994, 10.817351417000054],
              [-74.98025850699992, 10.817357299000037],
              [-74.980141        , 10.817417401000057],
              [-74.98004430099991, 10.81747043900009 ],
              [-74.97988277499991, 10.817538797000054],
              [-74.97972307599991, 10.817547284000057],
              [-74.97956681399995, 10.817438822000042],
              [-74.97950960299994, 10.817357529000049],
              [-74.9794190689999 , 10.81720150600006 ],
              [-74.9793797669999 , 10.817133781000052],
              [-74.97931657399994, 10.817057871000088],
              [-74.97919747199995, 10.816914801000053],
              [-74.97904203699994, 10.816832101000045],
              [-74.97889562299991, 10.816781500000047],
              [-74.97872417899993, 10.816754809000088],
              [-74.9785708789999 , 10.816717998000058],
              [-74.97845527299995, 10.816658647000054],
              [-74.97835672399992, 10.816514303000076],
              [-74.9782236229999 , 10.816404616000057],
              [-74.97800016799994, 10.81624600300006 ],
              [-74.97791348999993, 10.81613903400006 ],
              [-74.97788891599993, 10.816108708000058],
              [-74.97780401599994, 10.81622273000005 ],
              [-74.97774865699995, 10.816264473000047],
              [-74.97769223299991, 10.816287382000041],
              [-74.97781131599993, 10.816370452000058],
              [-74.9778398709999 , 10.81630763600009 ],
              [-74.97795237399993, 10.816379228000073],
              [-74.97796550499993, 10.81638758400004 ],
              [-74.97793335299991, 10.816579688000047],
              [-74.97788946399993, 10.816779095000072],
              [-74.97783069699994, 10.817046103000052],
              [-74.97782140999993, 10.81708830000008 ],
              [-74.97781111799992, 10.817135060000055],
              [-74.97765738099991, 10.817120170000067],
              [-74.97763709999992, 10.817120488000057],
              [-74.97761681799994, 10.817120807000038],
              [-74.97761784699992, 10.817132341000047],
              [-74.97761887699994, 10.817143876000046],
              [-74.9776374029999 , 10.817364784000063],
              [-74.97667483599992, 10.817394522000086],
              [-74.97661492299994, 10.817214130000082],
              [-74.97659142099991, 10.817172508000056],
              [-74.97636192099992, 10.817212877000088],
              [-74.9762992229999 , 10.817223905000048],
              [-74.97619344199995, 10.81724448500006 ],
              [-74.97605461599994, 10.817271494000067],
              [-74.97590899199992, 10.817315277000091],
              [-74.97574718899995, 10.817487549000077],
              [-74.97570903999991, 10.817532360000087],
              [-74.97571691299993, 10.81754023500008 ],
              [-74.9757331699999 , 10.817556491000062],
              [-74.97573499899994, 10.817558319000057],
              [-74.97588728499994, 10.817636365000055],
              [-74.97593106599993, 10.817702990000043],
              [-74.97579971999994, 10.817860986000085],
              [-74.97573119099991, 10.817919997000047],
              [-74.97567027699995, 10.81789905800008 ],
              [-74.97553880499993, 10.817746839000051],
              [-74.9754608849999 , 10.81781720400005 ],
              [-74.97538854899994, 10.817727737000041],
              [-74.97537522499994, 10.817596390000062],
              [-74.97537532099994, 10.81759622800007 ],
              [-74.97545898099992, 10.81745552600006 ],
              [-74.97547421099995, 10.817385094000088],
              [-74.97543804299994, 10.817299434000063],
              [-74.9754415999999 , 10.817212271000074],
              [-74.97544184899994, 10.81720615900008 ],
              [-74.97547801699994, 10.81711288400004 ],
              [-74.97568360199995, 10.81705197000008 ],
              [-74.97580148799995, 10.81699302700008 ],
              [-74.97578872899993, 10.816973878000056],
              [-74.97567036999993, 10.816894724000065],
              [-74.97555096499991, 10.816814871000076],
              [-74.9756330429999 , 10.81669641700006 ],
              [-74.97561366699995, 10.816681044000063],
              [-74.97559429099994, 10.816665672000056],
              [-74.97549149699995, 10.816617131000044],
              [-74.97536764599994, 10.816533416000084],
              [-74.97515313799994, 10.81642867800008 ],
              [-74.97499029499994, 10.81658241000008 ],
              [-74.97494897999991, 10.816621414000053],
              [-74.97505605599991, 10.816754188000061],
              [-74.9749375589999 , 10.816864119000059],
              [-74.97508608699991, 10.81697457100006 ],
              [-74.97496062899995, 10.817165807000038],
              [-74.9749458419999 , 10.81718834700007 ],
              [-74.97493105599995, 10.817210886000055],
              [-74.97497031699993, 10.817285839000078],
              [-74.97505478699992, 10.817352464000066],
              [-74.9750880979999 , 10.817376258000081],
              [-74.97500982399993, 10.817466959000058],
              [-74.97478365899991, 10.817445380000038],
              [-74.97469012199991, 10.817436455000063],
              [-74.97461761499994, 10.817429537000066],
              [-74.97460194599995, 10.817428042000074],
              [-74.9743895929999 , 10.817297162000045],
              [-74.9743730799999 , 10.817316420000054],
              [-74.97428586299992, 10.817198032000078],
              [-74.97413778499993, 10.817365693000056],
              [-74.97406843099992, 10.817296971000076],
              [-74.97396195699991, 10.817358468000066],
              [-74.97388010399993, 10.817394636000074],
              [-74.97376398599994, 10.817442225000093],
              [-74.97371925199991, 10.817462213000056],
              [-74.97367451699995, 10.817482200000086],
              [-74.97359030899992, 10.817408540000088],
              [-74.97351709999992, 10.817457164000075],
              [-74.97348802999994, 10.817473779000068],
              [-74.97352600799991, 10.817542277000086],
              [-74.97352303399992, 10.817711814000063],
              [-74.97347800999995, 10.817780626000058],
              [-74.97344060299991, 10.81783779400007 ],
              [-74.97343875099995, 10.817840625000088],
              [-74.97336539499992, 10.817952734000073],
              [-74.97327616599995, 10.818169860000069],
              [-74.9729237979999 , 10.817967055000054],
              [-74.97285336599992, 10.818022259000088],
              [-74.97263826199992, 10.818037487000083],
              [-74.97260035999994, 10.818042590000061],
              [-74.97256514399993, 10.817941701000052],
              [-74.97253032399993, 10.817784974000062],
              [-74.97284560299994, 10.81757439100005 ],
              [-74.97306317999994, 10.817419426000072],
              [-74.97300550499995, 10.817270776000043],
              [-74.97290149899993, 10.817120344000045],
              [-74.97279192099995, 10.81704097100004 ],
              [-74.97265686599991, 10.817028355000048],
              [-74.97259065199995, 10.817023576000054],
              [-74.97256877299992, 10.817020573000093],
              [-74.9724206329999 , 10.817000248000056],
              [-74.9723542829999 , 10.816835772000047],
              [-74.97228257299992, 10.816753421000044],
              [-74.97214598999994, 10.816649230000053],
              [-74.9719659989999 , 10.816544749000059],
              [-74.97184864499991, 10.816527689000054],
              [-74.97168633099994, 10.816530253000053],
              [-74.9716081439999 , 10.816567489000079],
              [-74.97153195099992, 10.816653457000086],
              [-74.9714504179999 , 10.816844813000046],
              [-74.9713770859999 , 10.817005306000055],
              [-74.9712886989999 , 10.817136135000055],
              [-74.9712519009999 , 10.817310707000047],
              [-74.97126064199995, 10.81759461300004 ],
              [-74.9713115269999 , 10.817795463000039],
              [-74.97124615999991, 10.818076518000055],
              [-74.97118149699992, 10.818126152000048],
              [-74.97102584899994, 10.818080647000045],
              [-74.97091161399993, 10.817949533000046],
              [-74.97082881199992, 10.81782353400007 ],
              [-74.97074600099995, 10.817734692000045],
              [-74.97068177599994, 10.817741496000053],
              [-74.97065126399991, 10.817793044000041],
              [-74.97062174899992, 10.81784291300005 ],
              [-74.97065783399995, 10.817974112000059],
              [-74.97068262499994, 10.81806449000004 ],
              [-74.97075170099993, 10.818180919000042],
              [-74.97084202499991, 10.81832173500004 ],
              [-74.97092038799991, 10.818467398000053],
              [-74.97098922799995, 10.818623796000054],
              [-74.97104831799993, 10.818689930000062],
              [-74.97111405399994, 10.818700379000063],
              [-74.97117846799995, 10.81869073200005 ],
              [-74.9712429029999 , 10.818606811000052],
              [-74.97137794899993, 10.81843371900004 ],
              [-74.97154574799993, 10.818322971000043],
              [-74.97161523599993, 10.818359396000062],
              [-74.9716267359999 , 10.818394461000082],
              [-74.9716689839999 , 10.81852322800006 ],
              [-74.97168089399992, 10.818704037000089],
              [-74.97168152899991, 10.818844039000055],
              [-74.9716387759999 , 10.819020901000044],
              [-74.9715378169999 , 10.819202148000045],
              [-74.97138154699991, 10.81927659300004 ],
              [-74.97127641499992, 10.819326155000056],
              [-74.97113253599991, 10.819390076000047],
              [-74.97098224399991, 10.819424966000042],
              [-74.97088330399993, 10.81943214000006 ],
              [-74.97080372699992, 10.819493497000053],
              [-74.97080363099991, 10.819612022000058],
              [-74.97080180299992, 10.819647003000057],
              [-74.97082079499995, 10.819711290000043],
              [-74.97081758799993, 10.819805669000061],
              [-74.97076753099992, 10.81990849400006 ],
              [-74.97070859999991, 10.819924745000037],
              [-74.97064575899992, 10.819912074000058],
              [-74.97058620999991, 10.819888511000045],
              [-74.97051703999995, 10.819884028000047],
              [-74.97044854399991, 10.819913993000057],
              [-74.97036387399993, 10.819971623000072],
              [-74.97036347699992, 10.819971893000059],
              [-74.9702036349999 , 10.820014170000093],
              [-74.9700651249999 , 10.820082077000052],
              [-74.96993984699992, 10.820138880000059],
              [-74.96988111099995, 10.820152276000044],
              [-74.96976705299994, 10.820170695000058],
              [-74.96966636899992, 10.820161322000047],
              [-74.96956438799992, 10.820171931000061],
              [-74.96949311599991, 10.82024470400006 ],
              [-74.96940045899993, 10.820310835000043],
              [-74.96920501599993, 10.820395819000055],
              [-74.96911307999994, 10.820430009000063],
              [-74.96887925499993, 10.820509491000053],
              [-74.96882510199993, 10.820505998000044],
              [-74.96877546399992, 10.820502794000049],
              [-74.96862313499992, 10.820530228000052],
              [-74.96851042899993, 10.820571681000047],
              [-74.96844673499993, 10.820616305000044],
              [-74.96841077199991, 10.820679919000042],
              [-74.96834746399992, 10.820772741000042],
              [-74.96833379999993, 10.82079277300005 ],
              [-74.96825746099995, 10.82085378100004 ],
              [-74.96821198399994, 10.82088267100005 ],
              [-74.96818448199991, 10.820947102000048],
              [-74.96816753699994, 10.821074985000052],
              [-74.9680356529999 , 10.821278593000045],
              [-74.96795588099991, 10.821347984000056],
              [-74.96787432699995, 10.821400069000049],
              [-74.96779349699995, 10.821440737000046],
              [-74.96768888899993, 10.821488730000056],
              [-74.96751164599993, 10.821618223000087],
              [-74.96733608999995, 10.821784433000062],
              [-74.96726966499995, 10.821877740000048],
              [-74.96718907099995, 10.822004424000056],
              [-74.96713940399991, 10.822055702000057],
              [-74.96707304799992, 10.822094667000044],
              [-74.96702297099995, 10.822142589000066],
              [-74.96701129199994, 10.822153765000053],
              [-74.96696703099991, 10.822211118000041],
              [-74.96695088099995, 10.822281745000055],
              [-74.96692804399993, 10.822366268000053],
              [-74.96685346099991, 10.822444621000045],
              [-74.96678359499992, 10.822494612000071],
              [-74.96672834799995, 10.82258586000006 ],
              [-74.96672484599992, 10.822640100000058],
              [-74.96675995399994, 10.822679601000061],
              [-74.96677290699995, 10.822746537000057],
              [-74.96672024499992, 10.82279780400006 ],
              [-74.96661381799993, 10.822831136000048],
              [-74.96649480199994, 10.822861489000047],
              [-74.96633902999992, 10.823129941000047],
              [-74.96633442599995, 10.823201273000052],
              [-74.96629691099992, 10.82333354800005 ],
              [-74.9662511759999 , 10.823419716000046],
              [-74.96616256199991, 10.823586671000044],
              [-74.96615481499992, 10.823691629000052],
              [-74.96611393499995, 10.823786428000062],
              [-74.96604219699992, 10.823910775000058],
              [-74.9659326929999 , 10.824035709000043],
              [-74.96582430799992, 10.82418924700005 ],
              [-74.96578786399994, 10.82440149900009 ],
              [-74.96589507599992, 10.824569345000043],
              [-74.96595077899991, 10.82469315800006 ],
              [-74.9660014449999 , 10.824805193000088],
              [-74.96607596199993, 10.824996054000053],
              [-74.96607018499992, 10.825130280000053],
              [-74.96600619399993, 10.825269419000051],
              [-74.96595452699995, 10.825352071000054],
              [-74.96585516499994, 10.825516495000045],
              [-74.9657951019999 , 10.825579675000085],
              [-74.9657373629999 , 10.825621856000055],
              [-74.96566072699994, 10.825642798000047],
              [-74.96555997199994, 10.825633576000087],
              [-74.96548399199992, 10.82562649600004 ],
              [-74.9654649769999 , 10.825624722000043],
              [-74.96539556199991, 10.825623200000052],
              [-74.96536507499991, 10.825634927000067],
              [-74.96531240299993, 10.825655187000052],
              [-74.96525354099992, 10.825714497000092],
              [-74.96519775199994, 10.825771122000049],
              [-74.9651170109999 , 10.825854843000059],
              [-74.96509472899993, 10.825930776000064],
              [-74.9651554269999 , 10.825977562000048],
              [-74.96525349599995, 10.825983733000044],
              [-74.9653807329999 , 10.826029770000048],
              [-74.9654811719999 , 10.826132692000044],
              [-74.96553883299993, 10.82627089400006 ],
              [-74.96553522499994, 10.826329913000052],
              [-74.96546574099995, 10.82641516600006 ],
              [-74.9654751779999 , 10.82649305700005 ],
              [-74.96549523399995, 10.82658593800005 ],
              [-74.9654843049999 , 10.826665414000047],
              [-74.9654387409999 , 10.826742679000063],
              [-74.96537011399994, 10.826818501000048],
              [-74.96531036599993, 10.826892048000047],
              [-74.9652691359999 , 10.82699251300005 ],
              [-74.96525674799994, 10.827094794000061],
              [-74.9652558809999 , 10.827197802000057],
              [-74.96524467099994, 10.827325858000052],
              [-74.96523224599991, 10.827428059000056],
              [-74.96521202899993, 10.827561260000039],
              [-74.96529628999991, 10.827735389000054],
              [-74.96537872099992, 10.827803508000045],
              [-74.96541557299992, 10.827860187000056],
              [-74.96542482599995, 10.827940905000048],
              [-74.96541056799992, 10.82806708000004 ],
              [-74.96528682799993, 10.828195599000082],
              [-74.96521386199993, 10.828293966000047],
              [-74.96517279499994, 10.828391449000037],
              [-74.96517647899992, 10.828468952000037],
              [-74.9652364399999 , 10.828569733000052],
              [-74.96538546799991, 10.828501636000055],
              [-74.9655021989999 , 10.82848602100006 ],
              [-74.96558121899994, 10.828516702000059],
              [-74.96559047199992, 10.828597379000087],
              [-74.9655508809999 , 10.828672031000053],
              [-74.96552776599992, 10.828716365000048],
              [-74.9655113309999 , 10.828746811000087],
              [-74.9654978989999 , 10.828820361000055],
              [-74.96548704499992, 10.828988473000038],
              [-74.96548391199991, 10.829036906000056],
              [-74.96551762399992, 10.82914199000004 ],
              [-74.96555127399995, 10.829292835000047],
              [-74.96555359699994, 10.829436016000045],
              [-74.96556271399993, 10.829608217000043],
              [-74.96565581499993, 10.829780084000049],
              [-74.96575596799994, 10.829932375000055],
              [-74.96579218699992, 10.830043321000062],
              [-74.9658485089999 , 10.830201278000061],
              [-74.96591130799993, 10.830436637000048],
              [-74.96593377099992, 10.830632824000077],
              [-74.96593898599991, 10.830678375000048],
              [-74.96604780599995, 10.830827844000055],
              [-74.96614096999991, 10.830950595000047],
              [-74.9662066489999 , 10.831094248000056],
              [-74.96622651199993, 10.831186441000057],
              [-74.96625982099994, 10.831297125000049],
              [-74.9662590069999 , 10.83140246000005 ],
              [-74.96617880499991, 10.83147114600007 ],
              [-74.96616262599991, 10.831587346000049],
              [-74.96616696799992, 10.831699079000089],
              [-74.96616693699991, 10.831787384000052],
              [-74.96616078499994, 10.831881022000061],
              [-74.96615581799995, 10.83195788200004 ],
              [-74.9661529309999 , 10.832092017000036],
              [-74.96620264499995, 10.832172229000037],
              [-74.96628892299992, 10.832223242000055],
              [-74.96632748899992, 10.832297047000054],
              [-74.96630972299994, 10.832393116000048],
              [-74.96629396799995, 10.83259219200005 ],
              [-74.96631942999994, 10.832775943000058],
              [-74.96630744399994, 10.832826475000047],
              [-74.96627564199991, 10.83286989000004 ],
              [-74.96621821499991, 10.832909235000045],
              [-74.96616727399993, 10.83293754600004 ],
              [-74.96612702099992, 10.832977590000041],
              [-74.96609022699994, 10.833055337000076],
              [-74.96608905799991, 10.833118120000051],
              [-74.96612707499992, 10.833200439000052],
              [-74.96613549199992, 10.833291993000046],
              [-74.9661278339999 , 10.833365808000053],
              [-74.9660746269999 , 10.833473970000057],
              [-74.96602328699993, 10.83355006000005 ],
              [-74.96597871199992, 10.83361265800005 ],
              [-74.96593597999993, 10.833692891000055],
              [-74.96594295499995, 10.833885207000037],
              [-74.96596617999995, 10.83393219900006 ],
              [-74.96602730699993, 10.83404342600005 ],
              [-74.9660500359999 , 10.834121338000045],
              [-74.96616540899993, 10.834290353000085],
              [-74.9662604849999 , 10.834459417000062],
              [-74.96625716299991, 10.83455232600005 ],
              [-74.9662562659999 , 10.834577491000061],
              [-74.9661347309999 , 10.834657978000052],
              [-74.96600887099993, 10.834814214000062],
              [-74.96590078499992, 10.83486348300005 ],
              [-74.96578799199995, 10.83482811400006 ],
              [-74.96573317299993, 10.834821121000061],
              [-74.9657023069999 , 10.834817184000087],
              [-74.9656301309999 , 10.834857190000037],
              [-74.9655852439999 , 10.834882070000049],
              [-74.96556587999993, 10.834902236000062],
              [-74.96549305099995, 10.834978078000063],
              [-74.96540973399993, 10.835016151000048],
              [-74.96533077499993, 10.834989608000058],
              [-74.96526812899992, 10.834958190000066],
              [-74.96522469699994, 10.834936406000054],
              [-74.96516383599993, 10.834936551000055],
              [-74.96501999599991, 10.835004820000051],
              [-74.9649656929999 , 10.835030593000056],
              [-74.96481725799993, 10.835173517000044],
              [-74.9644862259999 , 10.835314655000047],
              [-74.96424971199991, 10.835388739000052],
              [-74.96407177399993, 10.835449313000083],
              [-74.96393206099992, 10.835469699000043],
              [-74.96379698099992, 10.835541311000043],
              [-74.96370688199994, 10.835570486000051],
              [-74.96359677399994, 10.835717775000091],
              [-74.96358110599994, 10.835782601000062],
              [-74.9635655269999 , 10.835847048000062],
              [-74.96357475099995, 10.835933899000054],
              [-74.96355092199991, 10.83613917200006 ],
              [-74.96354603799995, 10.836181233000048],
              [-74.96353296899991, 10.836372833000041],
              [-74.9635381089999 , 10.83663567800005 ],
              [-74.9635614469999 , 10.836969755000041],
              [-74.96357304999992, 10.837107836000087],
              [-74.96350811299993, 10.83729065500006 ],
              [-74.9633440209999 , 10.837484851000056],
              [-74.96329458199995, 10.837549573000047],
              [-74.9632611529999 , 10.837710037000079],
              [-74.96328626399992, 10.837841401000048],
              [-74.96329326599994, 10.837941626000088],
              [-74.96328002799993, 10.838061946000039],
              [-74.96321275199995, 10.838209129000063],
              [-74.96308707599991, 10.838445558000046],
              [-74.96286884199992, 10.838622064000049],
              [-74.96265503699993, 10.838767372000063],
              [-74.9623891679999 , 10.838823705000038],
              [-74.96224039299995, 10.838824063000061],
              [-74.96206005999994, 10.838826729000061],
              [-74.96179377399994, 10.839114580000057],
              [-74.96121782599994, 10.839267060000054],
              [-74.96034064899993, 10.839486514000043],
              [-74.9601222849999 , 10.839859037000053],
              [-74.95987456799992, 10.84091346200006 ],
              [-74.95946836999991, 10.841379501000063],
              [-74.95944440399995, 10.841416821000053],
              [-74.95875056799991, 10.84249725300009 ],
              [-74.95843231999993, 10.842673223000077],
              [-74.95835215199992, 10.842717550000089],
              [-74.95818715799993, 10.842808780000041],
              [-74.9575922169999 , 10.843058413000051],
              [-74.95732110699993, 10.84322325700009 ],
              [-74.95702889599994, 10.843400931000076],
              [-74.95659706099991, 10.843605722000063],
              [-74.95637142999993, 10.843712725000046],
              [-74.95580837699993, 10.84414821200005 ],
              [-74.95524532299993, 10.844583699000054],
              [-74.95493797099994, 10.844802044000062],
              [-74.95480731099991, 10.844894866000061],
              [-74.95477661399991, 10.844974444000059],
              [-74.9544961439999 , 10.845701534000057],
              [-74.95393308599995, 10.846137021000061],
              [-74.95308786499993, 10.846573306000039],
              [-74.95130236699993, 10.84710523700005 ],
              [-74.95064488399993, 10.847417018000044],
              [-74.95008307299992, 10.848286381000037],
              [-74.94977162799995, 10.849000070000045],
              [-74.94921034999993, 10.850055376000057],
              [-74.94880466299992, 10.85070735000005 ],
              [-74.9479625589999 , 10.852228325000056],
              [-74.9477324799999 , 10.852994376000083],
              [-74.9476830779999 , 10.853158867000047],
              [-74.94767183199991, 10.853182385000082],
              [-74.9473714419999 , 10.85381057300009 ],
              [-74.94665401499992, 10.855083261000061],
              [-74.9464995159999 , 10.85541221200009 ],
              [-74.9457503459999 , 10.857007297000052],
              [-74.94563517499995, 10.857539970000062],
              [-74.94541366399994, 10.858564457000057],
              [-74.94529786899994, 10.858543223000083]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08137",
      "properties": {"id": "08137", "iso": "CAM", "name": "CAMPO DE LA CRUZ"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.80921048499994, 10.486242382000057],
              [-74.8082069699999 , 10.484792953000067],
              [-74.80653309099995, 10.482935797000039],
              [-74.8068720249999 , 10.482232768000074],
              [-74.80842313799991, 10.479015394000044],
              [-74.8099710649999 , 10.475273211000058],
              [-74.81025640999991, 10.473243470000057],
              [-74.81095612299993, 10.468266073000052],
              [-74.81128156999995, 10.464696807000053],
              [-74.81147301499993, 10.460929595000039],
              [-74.81166616599995, 10.457889299000044],
              [-74.8118585389999 , 10.454518583000038],
              [-74.81191955799994, 10.452073349000045],
              [-74.8119815309999 , 10.450920802000041],
              [-74.81198782999991, 10.450803651000058],
              [-74.81204749499994, 10.44969403500005 ],
              [-74.81202587899992, 10.449255968000045],
              [-74.81190729299993, 10.446852757000045],
              [-74.81193883599991, 10.446180608000077],
              [-74.81203445799991, 10.444143033000046],
              [-74.81223024199994, 10.442226150000067],
              [-74.81269182599993, 10.439779988000055],
              [-74.81315480099994, 10.437928577000037],
              [-74.81401428899994, 10.434358074000045],
              [-74.81487593899993, 10.43171273300004 ],
              [-74.8161379689999 , 10.429000384000062],
              [-74.81719986099995, 10.426354581000055],
              [-74.81806411999992, 10.424832654000056],
              [-74.81926277999992, 10.42357428300005 ],
              [-74.8203157499999 , 10.42236162100005 ],
              [-74.82052771899993, 10.422117506000063],
              [-74.82225868599994, 10.420130971000049],
              [-74.82472000899992, 10.416490630000055],
              [-74.82531797299993, 10.415299727000047],
              [-74.82717732999993, 10.41119818900006 ],
              [-74.8281788029999 , 10.411261928000044],
              [-74.8286727169999 , 10.411309652000057],
              [-74.82951420599994, 10.411390960000062],
              [-74.83121592899994, 10.411463369000046],
              [-74.83230441199993, 10.411274946000049],
              [-74.83339271299991, 10.411012175000053],
              [-74.83414307099991, 10.410750201000042],
              [-74.83496870499994, 10.410562385000048],
              [-74.83529174099993, 10.410466800000052],
              [-74.83598181299993, 10.410262611000064],
              [-74.83714457299993, 10.409739449000085],
              [-74.83823187699994, 10.40906777400005 ],
              [-74.8396190229999 , 10.408172347000061],
              [-74.8406310339999 , 10.407426499000053],
              [-74.84081000099991, 10.407302831000038],
              [-74.84198052599993, 10.406493982000086],
              [-74.8425923449999 , 10.406025745000079],
              [-74.84295497699992, 10.405748216000063],
              [-74.84422981199992, 10.405001729000048],
              [-74.8461419599999 , 10.40384481700005 ],
              [-74.84726658599993, 10.403098680000085],
              [-74.84824073999994, 10.402241383000046],
              [-74.84910261099992, 10.401533047000044],
              [-74.85093834299994, 10.399855883000043],
              [-74.85213748799993, 10.398886522000055],
              [-74.8535611719999 , 10.397619233000057],
              [-74.85509694199993, 10.39612863700006 ],
              [-74.85633286899991, 10.394861794000064],
              [-74.85801778299992, 10.392961932000048],
              [-74.85876622399991, 10.39195647300005 ],
              [-74.85973873799992, 10.390467227000045],
              [-74.86071069399992, 10.388754953000046],
              [-74.86190661299992, 10.38652171700005 ],
              [-74.8634747289999 , 10.382986557000038],
              [-74.86470360099992, 10.378931815000044],
              [-74.86492478299994, 10.37729571500006 ],
              [-74.86510897399995, 10.375882731000047],
              [-74.86551973199994, 10.37498959900006 ],
              [-74.86553120199994, 10.374945623000087],
              [-74.86574286199993, 10.374134101000038],
              [-74.86589043599992, 10.373092926000083],
              [-74.86581264599994, 10.372015133000048],
              [-74.86566051999995, 10.371234900000047],
              [-74.86513174299995, 10.369972350000069],
              [-74.86456570099995, 10.368821412000045],
              [-74.86384884499995, 10.367410631000041],
              [-74.86351255299991, 10.366748804000053],
              [-74.86351196999993, 10.36674766100009 ],
              [-74.86350935299993, 10.366742505000047],
              [-74.86324517999992, 10.366222607000054],
              [-74.86260728699995, 10.363851361000059],
              [-74.86214961499991, 10.36097784900005 ],
              [-74.86174225499991, 10.358203340000046],
              [-74.8616556849999 , 10.357366984000066],
              [-74.86158838999995, 10.356716839000057],
              [-74.86138545499995, 10.355626955000048],
              [-74.86123306999991, 10.35473520000005 ],
              [-74.8610306299999 , 10.353843569000048],
              [-74.86122790099995, 10.352653583000063],
              [-74.8618125399999 , 10.351082679000058],
              [-74.86707473899992, 10.342962661000058],
              [-74.86740330899994, 10.342455649000044],
              [-74.8676768599999 , 10.341961419000086],
              [-74.86819349399991, 10.34102800800008 ],
              [-74.86841791899991, 10.34062253500008 ],
              [-74.87091183799993, 10.34261607700006 ],
              [-74.87119459199994, 10.342842100000041],
              [-74.87183997699992, 10.343190723000077],
              [-74.87305514299993, 10.34384712700006 ],
              [-74.87308475999993, 10.343863125000041],
              [-74.87385188299993, 10.344277499000043],
              [-74.87392646799992, 10.344317789000058],
              [-74.87397406499991, 10.344343501000083],
              [-74.87418159799995, 10.34448926400006 ],
              [-74.87462293699991, 10.344799245000047],
              [-74.8748388169999 , 10.34467776400004 ],
              [-74.87569368699991, 10.344196706000048],
              [-74.87611483199993, 10.344346030000054],
              [-74.87618532599993, 10.344371025000044],
              [-74.87619854299993, 10.344374439000092],
              [-74.87623174599992, 10.344383016000052],
              [-74.87625886799992, 10.344390022000084],
              [-74.87626502199993, 10.344339718000072],
              [-74.8762682819999 , 10.34431307200009 ],
              [-74.87627807699994, 10.344232999000042],
              [-74.87633922499992, 10.344243163000044],
              [-74.87643046099993, 10.34425832900007 ],
              [-74.87650815699993, 10.344271244000083],
              [-74.87667854199992, 10.344299567000064],
              [-74.87734227499993, 10.344409897000048],
              [-74.87755404199993, 10.344445096000072],
              [-74.87762987299993, 10.344464797000057],
              [-74.8776662169999 , 10.344474239000078],
              [-74.87773730299995, 10.344492708000075],
              [-74.87785498099993, 10.344523279000043],
              [-74.87788816199992, 10.34452262700006 ],
              [-74.87789067299991, 10.344523279000043],
              [-74.87806924299991, 10.344519769000044],
              [-74.87814503499993, 10.344554231000075],
              [-74.87828263099993, 10.344616795000093],
              [-74.87840766599993, 10.344673647000093],
              [-74.87869399699991, 10.344803839000065],
              [-74.87980425699993, 10.345308661000047],
              [-74.88120173999994, 10.345944079000049],
              [-74.8812804069999 , 10.345979840000041],
              [-74.88129277099995, 10.345985460000065],
              [-74.88138195499994, 10.346040706000053],
              [-74.8814022169999 , 10.346106631000055],
              [-74.88140772999992, 10.34612456800005 ],
              [-74.88143419999994, 10.346210668000083],
              [-74.88146044899992, 10.346296044000042],
              [-74.8816564629999 , 10.346995114000038],
              [-74.8817485159999 , 10.347257149000086],
              [-74.88186849899995, 10.347598685000037],
              [-74.88236053499992, 10.348221987000045],
              [-74.88257600899993, 10.348330935000092],
              [-74.8829521859999 , 10.348529107000047],
              [-74.88344741399993, 10.348782810000046],
              [-74.88428547199993, 10.349214282000048],
              [-74.88524744499995, 10.349678883000081],
              [-74.88524748499992, 10.349678904000086],
              [-74.88597893499991, 10.350032169000087],
              [-74.88604671199994, 10.350069846000054],
              [-74.88621450599993, 10.350163118000069],
              [-74.88672049299993, 10.350444375000052],
              [-74.8868414449999 , 10.350511607000044],
              [-74.8860045209999 , 10.35235825500007 ],
              [-74.88600438399993, 10.35235855500008 ],
              [-74.88563751499993, 10.35316804200005 ],
              [-74.88765295099995, 10.353586215000064],
              [-74.88811548299992, 10.35273692900006 ],
              [-74.88822122599993, 10.352542766000056],
              [-74.88837099699992, 10.352179483000043],
              [-74.88876369099995, 10.351476848000061],
              [-74.88892633199993, 10.351473569000063],
              [-74.88950052799993, 10.35146198600006 ],
              [-74.89023631599991, 10.351373983000087],
              [-74.89121802099993, 10.351305384000057],
              [-74.89161089499993, 10.35130128700007 ],
              [-74.89203594599991, 10.351296854000054],
              [-74.89369358999994, 10.351279569000042],
              [-74.89380439399991, 10.351278412000056],
              [-74.89385556299993, 10.35047390200009 ],
              [-74.89391660299992, 10.34951415800009 ],
              [-74.89393724899992, 10.34918953500005 ],
              [-74.89392872499991, 10.348627352000051],
              [-74.8939551549999 , 10.348231517000045],
              [-74.89403390799993, 10.347770954000055],
              [-74.89411151099995, 10.34738582500006 ],
              [-74.8941933079999 , 10.346970990000045],
              [-74.89430405799993, 10.346633377000046],
              [-74.89459188599994, 10.346717419000072],
              [-74.8946099129999 , 10.346722683000053],
              [-74.8946732739999 , 10.346741183000063],
              [-74.89468093099993, 10.346743418000074],
              [-74.8954844619999 , 10.346978037000042],
              [-74.89601008099993, 10.347133075000045],
              [-74.8960245959999 , 10.347137355000086],
              [-74.89601548499991, 10.347197700000038],
              [-74.89601330399995, 10.347212132000038],
              [-74.89594044199993, 10.34769465100004 ],
              [-74.89593879799992, 10.347705545000053],
              [-74.89587778899994, 10.348109544000067],
              [-74.8958557059999 , 10.348255786000038],
              [-74.89576298499992, 10.348869783000055],
              [-74.89575925999992, 10.348894444000052],
              [-74.89571892899994, 10.349161519000063],
              [-74.89557302799994, 10.35012768200005 ],
              [-74.89553892299995, 10.350353529000074],
              [-74.89535696999991, 10.35155842100005 ],
              [-74.89552779599995, 10.351665790000084],
              [-74.89565852699991, 10.351792580000051],
              [-74.89571364799991, 10.351846037000087],
              [-74.89578598399993, 10.351952348000054],
              [-74.8958677299999 , 10.352072487000044],
              [-74.89592050499994, 10.35234375500005 ],
              [-74.89591413499994, 10.352686722000044],
              [-74.8959972529999 , 10.352971299000046],
              [-74.89612472199991, 10.353273548000061],
              [-74.89637669299992, 10.35353742600006 ],
              [-74.89670257599994, 10.353779778000046],
              [-74.8969309169999 , 10.353877035000039],
              [-74.89700267199993, 10.353907596000056],
              [-74.89715008399992, 10.35394242700005 ],
              [-74.89724315399991, 10.353964202000043],
              [-74.8974963149999 , 10.354001827000047],
              [-74.89764930899992, 10.35399970900005 ],
              [-74.89778955999992, 10.353926887000057],
              [-74.8978342019999 , 10.353814236000062],
              [-74.89780886799991, 10.353670586000078],
              [-74.8977210569999 , 10.35353464800005 ],
              [-74.89762093099995, 10.353350902000045],
              [-74.89759500299994, 10.353172954000058],
              [-74.89768548599994, 10.353025398000057],
              [-74.8978214469999 , 10.352973218000045],
              [-74.89800452899993, 10.352970631000062],
              [-74.89822007499993, 10.352967637000063],
              [-74.89834971599993, 10.352956672000062],
              [-74.8987647379999 , 10.352845948000038],
              [-74.89921772499991, 10.35272509400005 ],
              [-74.8992365499999 , 10.352721594000059],
              [-74.89943941899992, 10.35268386100006 ],
              [-74.89985358099995, 10.352606827000045],
              [-74.90048178099994, 10.352588154000046],
              [-74.90128798799992, 10.352591056000051],
              [-74.90206449499993, 10.352635422000048],
              [-74.90270055899992, 10.352733673000046],
              [-74.90334418299994, 10.35291212900006 ],
              [-74.90392825899994, 10.353146112000047],
              [-74.9041301339999 , 10.35206799000008 ],
              [-74.90423930399993, 10.351484954000057],
              [-74.9043105209999 , 10.351104623000083],
              [-74.9045742799999 , 10.349695996000037],
              [-74.90424070699993, 10.34902426900004 ],
              [-74.90757536799993, 10.34890411400005 ],
              [-74.9079013189999 , 10.348929120000037],
              [-74.90817851799994, 10.348940686000049],
              [-74.90848826399991, 10.34895888200009 ],
              [-74.90897085199992, 10.34893216100005 ],
              [-74.90951948899993, 10.34879711800005 ],
              [-74.9098282189999 , 10.348700659000087],
              [-74.9103507069999 , 10.348469523000063],
              [-74.91066810899991, 10.348311072000058],
              [-74.91070841599992, 10.348289996000062],
              [-74.91112531499994, 10.348072         ],
              [-74.91119444999993, 10.348035847000062],
              [-74.9112892629999 , 10.347957038000061],
              [-74.91130984499995, 10.347969688000092],
              [-74.91232136699995, 10.348591419000059],
              [-74.9126561569999 , 10.348225195000055],
              [-74.91336865599993, 10.347445793000077],
              [-74.91381003399994, 10.34696296900006 ],
              [-74.91404530699992, 10.34718820200004 ],
              [-74.91435211599992, 10.347441839000055],
              [-74.9144410799999 , 10.347515384000076],
              [-74.91446854799995, 10.347538092000093],
              [-74.91479157599991, 10.347774085000083],
              [-74.91492962799992, 10.347848917000078],
              [-74.91510022199992, 10.347941389000084],
              [-74.91532483299994, 10.34761639800007 ],
              [-74.91532825599995, 10.347611444000052],
              [-74.91551910099992, 10.347335313000087],
              [-74.9155888219999 , 10.34723443100006 ],
              [-74.91575384099991, 10.346995667000044],
              [-74.91632980499992, 10.34616230100005 ],
              [-74.91693459899994, 10.345287210000038],
              [-74.91768863199991, 10.34419618000004 ],
              [-74.91735439799993, 10.344006123000042],
              [-74.91701330399991, 10.343811534000054],
              [-74.91669962299994, 10.343598412000063],
              [-74.9163544889999 , 10.34320202300006 ],
              [-74.91614341099995, 10.342911970000046],
              [-74.9160624129999 , 10.342800665000084],
              [-74.9158890029999 , 10.342499240000052],
              [-74.9157407969999 , 10.342181580000045],
              [-74.91559029099994, 10.341854776000048],
              [-74.91553627199994, 10.341661762000058],
              [-74.91550010199995, 10.34153249700006 ],
              [-74.91546412199995, 10.341403932000048],
              [-74.9154411689999 , 10.341140322000058],
              [-74.91543398899995, 10.340971105000051],
              [-74.91542959499992, 10.340867506000052],
              [-74.91542047799993, 10.340800655000066],
              [-74.9154131869999 , 10.34074718700009 ],
              [-74.91542150799995, 10.340748327000085],
              [-74.91557033499993, 10.34070710800006 ],
              [-74.91569564699995, 10.340677037000091],
              [-74.91590119499995, 10.340566270000068],
              [-74.91600930099992, 10.340508012000043],
              [-74.91634019799994, 10.340387749000058],
              [-74.91655441499995, 10.340311263000046],
              [-74.91673462999995, 10.340209949000041],
              [-74.91689597399994, 10.340130241000054],
              [-74.91701868299992, 10.339932824000073],
              [-74.91703058599995, 10.339882547000059],
              [-74.91708209299992, 10.339664990000074],
              [-74.91721131199995, 10.339402980000045],
              [-74.91724257399994, 10.339175562000037],
              [-74.91724957399992, 10.339124637000054],
              [-74.91727058099991, 10.33882727300005 ],
              [-74.91732324299994, 10.338566135000065],
              [-74.91737685099991, 10.338573220000058],
              [-74.91752749099993, 10.338595683000051],
              [-74.91764147499993, 10.338612678000061],
              [-74.91765918899995, 10.338615320000088],
              [-74.91774894399992, 10.33862870300004 ],
              [-74.91784277699992, 10.33864269500009 ],
              [-74.91920173599993, 10.338845335000087],
              [-74.92053963099994, 10.339059728000052],
              [-74.92086173599995, 10.339104727000063],
              [-74.92204284399992, 10.339269727000044],
              [-74.9231320639999 , 10.33942188900005 ],
              [-74.9235262489999 , 10.33946951300004 ],
              [-74.92378131999993, 10.339500328000042],
              [-74.92398443699994, 10.339530041000046],
              [-74.92510382099994, 10.339693792000048],
              [-74.9264677729999 , 10.339893318000065],
              [-74.9265870779999 , 10.339910772000053],
              [-74.92760686499992, 10.340040170000066],
              [-74.92833679299991, 10.340190104000044],
              [-74.92834555099995, 10.340201257000047],
              [-74.9299140299999 , 10.340574850000053],
              [-74.93034141299995, 10.340676643000052],
              [-74.93108985899994, 10.340854913000044],
              [-74.93161618199991, 10.341020573000037],
              [-74.93328633099992, 10.341612198000064],
              [-74.93395999699993, 10.341850830000055],
              [-74.93445370599994, 10.342115192000051],
              [-74.93457056099993, 10.342198178000046],
              [-74.93515133999995, 10.342610611000055],
              [-74.93562127899992, 10.342970837000053],
              [-74.93599249199991, 10.343305437000083],
              [-74.93635886399994, 10.343721265000056],
              [-74.93645417199991, 10.343903270000055],
              [-74.9363327289999 , 10.344083646000058],
              [-74.93628148599993, 10.344159755000078],
              [-74.93617755699995, 10.344321518000072],
              [-74.93590340599991, 10.34474823000005 ],
              [-74.93582666599991, 10.344867673000067],
              [-74.93546160799991, 10.345435878000046],
              [-74.9352707299999 , 10.345776079000075],
              [-74.93504901699993, 10.346171233000064],
              [-74.93490642699993, 10.346425371000066],
              [-74.93488921699992, 10.346456044000092],
              [-74.93463221999991, 10.346914077000065],
              [-74.93440743799994, 10.34728901300008 ],
              [-74.93440588699991, 10.347291601000052],
              [-74.93419244299992, 10.347647603000041],
              [-74.93389661399993, 10.348076855000045],
              [-74.93381785899993, 10.34828929200006 ],
              [-74.9338163459999 , 10.348293371000068],
              [-74.93379338799991, 10.348355299000048],
              [-74.9337576499999 , 10.348405409000065],
              [-74.93364822799992, 10.348558836000052],
              [-74.9335345639999 , 10.348700061000045],
              [-74.93346965099994, 10.348757320000061],
              [-74.93338064699992, 10.348835828000063],
              [-74.93322589499991, 10.348972331000084],
              [-74.93308651099994, 10.349097912000047],
              [-74.93284346599995, 10.349266254000042],
              [-74.9326969359999 , 10.349382794000064],
              [-74.93236140599993, 10.349563914000043],
              [-74.93207550999995, 10.349659595000048],
              [-74.93176161899993, 10.349742007000089],
              [-74.93150521299992, 10.349798375000091],
              [-74.9313583899999 , 10.349812425000039],
              [-74.93105391099994, 10.349841562000051],
              [-74.93066470899993, 10.349849560000052],
              [-74.93044698999995, 10.349861864000047],
              [-74.93020387899992, 10.34987688800004 ],
              [-74.92983155299993, 10.349928052000053],
              [-74.9297783799999 , 10.349940308000043],
              [-74.92948795299992, 10.349891893000063],
              [-74.92939504899994, 10.349865851000061],
              [-74.92911549199994, 10.34978748900005 ],
              [-74.92881310199994, 10.349718659000075],
              [-74.9286338309999 , 10.349654179000083],
              [-74.92853807999995, 10.349619739000048],
              [-74.92817032199991, 10.349500502000069],
              [-74.9281152129999 , 10.349482634000083],
              [-74.9274268389999 , 10.349259445000087],
              [-74.92733158699991, 10.349228563000054],
              [-74.92714425799994, 10.349167823000073],
              [-74.92692378299995, 10.349096338000038],
              [-74.92684749999995, 10.349447161000057],
              [-74.92681346899991, 10.349603667000054],
              [-74.9268206289999 , 10.349605775000043],
              [-74.92678707199991, 10.349691500000063],
              [-74.9265934039999 , 10.34975563100005 ],
              [-74.92644202399993, 10.349858838000046],
              [-74.9263688339999 , 10.349908740000046],
              [-74.92628054799991, 10.349968929000056],
              [-74.92620582199993, 10.35001987000004 ],
              [-74.92591106399993, 10.350294208000037],
              [-74.92572161899994, 10.35047776600004 ],
              [-74.9254974449999 , 10.35081972100005 ],
              [-74.92529852699994, 10.351140693000048],
              [-74.92519732499994, 10.351487679000059],
              [-74.92516043199993, 10.351797187000045],
              [-74.9251309679999 , 10.352138583000055],
              [-74.92516124999992, 10.352442495000048],
              [-74.92510922499991, 10.352825404000043],
              [-74.9251085699999 , 10.352990484000088],
              [-74.92510828999991, 10.353061116000049],
              [-74.92493501599995, 10.353393145000041],
              [-74.92478807799995, 10.353633307000052],
              [-74.92463299799994, 10.353802904000077],
              [-74.92457387999991, 10.353867557000058],
              [-74.92434882599991, 10.354150012000048],
              [-74.92405937399991, 10.354465441000059],
              [-74.92369389399994, 10.354807161000053],
              [-74.92345234899994, 10.355101958000091],
              [-74.92341869199993, 10.355143032000058],
              [-74.92317701099995, 10.35539826300004 ],
              [-74.92298869199993, 10.355603371000086],
              [-74.9229491559999 , 10.35564643400005 ],
              [-74.92263583399995, 10.355918734000056],
              [-74.92245214399992, 10.356116091000047],
              [-74.92234865699993, 10.356227268000055],
              [-74.92205228799992, 10.356545079000057],
              [-74.92170562899992, 10.356911712000056],
              [-74.92148072499992, 10.357201051000061],
              [-74.92130667599992, 10.357485059000055],
              [-74.92108413699992, 10.357778942000039],
              [-74.92091044699993, 10.357937074000063],
              [-74.92071156899993, 10.358116167000048],
              [-74.9205350869999 , 10.358242308000058],
              [-74.92036315999991, 10.358363810000071],
              [-74.92029177899991, 10.358396903000084],
              [-74.9200511869999 , 10.358906106000063],
              [-74.9198108889999 , 10.359414686000036],
              [-74.91978649299995, 10.359466328000053],
              [-74.91975195399993, 10.35953943000004 ],
              [-74.91974558499993, 10.359552908000069],
              [-74.91970505299992, 10.359638695000058],
              [-74.91964010399994, 10.359776157000056],
              [-74.91951771599992, 10.360035202000063],
              [-74.91982412499993, 10.360328725000045],
              [-74.9200657589999 , 10.360541485000056],
              [-74.9202747029999 , 10.360717539000063],
              [-74.92071317299991, 10.361040684000045],
              [-74.9209731389999 , 10.361215395000045],
              [-74.92118752899995, 10.36128777600004 ],
              [-74.92143378899993, 10.36132536200006 ],
              [-74.9217214329999 , 10.361344067000061],
              [-74.92196462399994, 10.36133138300005 ],
              [-74.92223766099994, 10.361302229000046],
              [-74.92253494399995, 10.361190321000038],
              [-74.92298608899995, 10.360877059000074],
              [-74.92301187599992, 10.360858437000047],
              [-74.9232408549999 , 10.360678910000047],
              [-74.9233960599999 , 10.360569357000088],
              [-74.92341137199992, 10.36055749500008 ],
              [-74.9234287669999 , 10.360544018000041],
              [-74.9235748719999 , 10.36101261400006 ],
              [-74.92361526499991, 10.361142159000053],
              [-74.92362314299993, 10.361167424000087],
              [-74.92370889299991, 10.361442436000061],
              [-74.92396555699992, 10.362265608000087],
              [-74.92363480299991, 10.362606819000064],
              [-74.9234679029999 , 10.362940438000066],
              [-74.92358523299993, 10.362845547000063],
              [-74.92378331099991, 10.36276483100005 ],
              [-74.92393317699992, 10.362716903000091],
              [-74.92408767499995, 10.362662010000065],
              [-74.9241266219999 , 10.36265977100004 ],
              [-74.9243539759999 , 10.362646687000051],
              [-74.92456516599992, 10.362668799000062],
              [-74.92488357399992, 10.362721340000064],
              [-74.92514610499995, 10.362763266000059],
              [-74.92541462499992, 10.362788969000064],
              [-74.92549899099993, 10.362797044000047],
              [-74.92580719599994, 10.362794841000039],
              [-74.92613367899992, 10.362776317000055],
              [-74.92642298999993, 10.362746948000051],
              [-74.92651091099992, 10.362720093000064],
              [-74.92669053399993, 10.36266523200004 ],
              [-74.92704537899994, 10.362529615000085],
              [-74.9271997059999 , 10.362455641000054],
              [-74.92728966399994, 10.362412523000046],
              [-74.92736053199991, 10.362378553000042],
              [-74.92742199799994, 10.362340147000054],
              [-74.9274268389999 , 10.362337120000063],
              [-74.92765668099992, 10.362193483000055],
              [-74.92791618799993, 10.362043250000056],
              [-74.92809737999994, 10.361919313000044],
              [-74.92816102899991, 10.361877425000046],
              [-74.92836517799992, 10.36175224100009 ],
              [-74.92847456999993, 10.361698672000045],
              [-74.9284779219999 , 10.361697032000052],
              [-74.92851315299993, 10.361679780000088],
              [-74.92873109899995, 10.361537042000066],
              [-74.92888988499993, 10.361461471000041],
              [-74.92905691199991, 10.361321762000046],
              [-74.92906772599991, 10.361314900000082],
              [-74.92908410699994, 10.361304507000057],
              [-74.92919872899995, 10.361231789000044],
              [-74.92929323499993, 10.361171841000044],
              [-74.92958544399994, 10.361032562000048],
              [-74.92984792099992, 10.360921168000061],
              [-74.93001790599993, 10.360822584000061],
              [-74.93019709299995, 10.36072157700005 ],
              [-74.9303463409999 , 10.360627841000053],
              [-74.93054171199992, 10.360524324000039],
              [-74.93068860999995, 10.36043292700009 ],
              [-74.93086359099993, 10.360357142000055],
              [-74.9309599739999 , 10.360315201000049],
              [-74.93122272199992, 10.360200852000048],
              [-74.9312842839999 , 10.360160712000038],
              [-74.93136270499991, 10.360109582000064],
              [-74.93156490799993, 10.360001345000057],
              [-74.93179203599993, 10.359851580000054],
              [-74.93194374299992, 10.359769283000048],
              [-74.93210192499993, 10.359657104000064],
              [-74.93240917399993, 10.359593148000044],
              [-74.9326197069999 , 10.35953788800009 ],
              [-74.93261974699993, 10.359537878000083],
              [-74.93264684699994, 10.359530764000056],
              [-74.93282001199992, 10.35968241800009 ],
              [-74.9329468489999 , 10.359770904000072],
              [-74.93294968799995, 10.359772883000062],
              [-74.93299945099994, 10.359843093000052],
              [-74.93308760899993, 10.359997412000041],
              [-74.93327381199992, 10.360193773000049],
              [-74.9334166939999 , 10.360346311000058],
              [-74.93350008199991, 10.36043533500009 ],
              [-74.93371077799992, 10.360614867000038],
              [-74.9337984249999 , 10.360689551000064],
              [-74.93399837099992, 10.360876551000047],
              [-74.93443962099991, 10.361375835000047],
              [-74.93461182499993, 10.36156784800005 ],
              [-74.93471708499993, 10.361676782000075],
              [-74.93474977199992, 10.361710612000081],
              [-74.93481676699992, 10.361779947000059],
              [-74.93489828499992, 10.361936497000045],
              [-74.9348994849999 , 10.36193880400009 ],
              [-74.93493622699992, 10.362009362000038],
              [-74.93500844899995, 10.362179928000046],
              [-74.93506475099991, 10.362371349000057],
              [-74.93508835299991, 10.362549469000044],
              [-74.93509678299995, 10.362789628000087],
              [-74.93509254199995, 10.363114643000074],
              [-74.93509755399992, 10.363434939000058],
              [-74.93509250099993, 10.363654968000048],
              [-74.93509066999991, 10.363734821000037],
              [-74.93512266199991, 10.36387917400009 ],
              [-74.93512505699994, 10.363889974000074],
              [-74.9351280809999 , 10.363903613000048],
              [-74.93513971999994, 10.36394649500005 ],
              [-74.93522643699993, 10.364266027000042],
              [-74.93529503899993, 10.364647172000048],
              [-74.93529086899991, 10.364976744000046],
              [-74.93542925299994, 10.365082272000052],
              [-74.93550760599993, 10.36519784300009 ],
              [-74.93557442499991, 10.365322736000053],
              [-74.9356770089999 , 10.365508875000046],
              [-74.9357048679999 , 10.365657205000048],
              [-74.93581717599994, 10.36587527200004 ],
              [-74.93596943599994, 10.366127016000064],
              [-74.93619971699991, 10.366473795000047],
              [-74.93636833199992, 10.366732206000052],
              [-74.93644324999991, 10.36698787000006 ],
              [-74.93644912099995, 10.367007908000062],
              [-74.93653454399993, 10.36728357000004 ],
              [-74.93663834599994, 10.367746469000053],
              [-74.93666872699993, 10.36785824900005 ],
              [-74.93669877299993, 10.368150722000053],
              [-74.93675375099991, 10.36830892000006 ],
              [-74.93684199099994, 10.368562843000063],
              [-74.93693529399991, 10.368897873000037],
              [-74.93708674299995, 10.369245772000056],
              [-74.93717137199991, 10.369440502000089],
              [-74.9372478279999 , 10.36961642600005 ],
              [-74.9373854609999 , 10.369966800000043],
              [-74.93762850799993, 10.370391182000049],
              [-74.93774832199995, 10.370643446000088],
              [-74.9378572949999 , 10.37079060800005 ],
              [-74.93799286399991, 10.371015146000047],
              [-74.93808614099993, 10.37134332000005 ],
              [-74.93814314199994, 10.371630795000044],
              [-74.93818929199995, 10.371863566000059],
              [-74.93820571799995, 10.37192022000005 ],
              [-74.9382537059999 , 10.372085739000056],
              [-74.93834850999991, 10.372412721000046],
              [-74.93853396799994, 10.372404613000072],
              [-74.93921478899995, 10.37237485500009 ],
              [-74.93922447699993, 10.372990303000051],
              [-74.9392655289999 , 10.373392458000069],
              [-74.93938105099994, 10.373665347000042],
              [-74.93945162099993, 10.37388175600006 ],
              [-74.9395478159999 , 10.37410230100005 ],
              [-74.93964446899992, 10.374203873000056],
              [-74.93973664999993, 10.374315653000053],
              [-74.93978617099992, 10.374375703000055],
              [-74.93987467199992, 10.374550609000039],
              [-74.93997331399993, 10.374780284000053],
              [-74.9399773959999 , 10.374836633000086],
              [-74.9399910379999 , 10.375024913000061],
              [-74.94001172799994, 10.375310625000054],
              [-74.9400206329999 , 10.375434086000041],
              [-74.94009188899992, 10.375693887000068],
              [-74.94012420299993, 10.375977181000053],
              [-74.94017920199991, 10.376234974000056],
              [-74.94018387799991, 10.37653238900009 ],
              [-74.9401887489999 , 10.376697068000055],
              [-74.94019508299994, 10.376951011000074],
              [-74.94055940499993, 10.376841303000049],
              [-74.9406264939999 , 10.37682109900004 ],
              [-74.94114802099995, 10.376673886000049],
              [-74.94153332399992, 10.376560686000062],
              [-74.94178696599994, 10.37647914200005 ],
              [-74.94213577099993, 10.376398538000046],
              [-74.9423911959999 , 10.376329321000071],
              [-74.94253734699993, 10.376289717000077],
              [-74.94292729499995, 10.376178702000061],
              [-74.94325332099993, 10.376135455000053],
              [-74.94333444699993, 10.376124695000044],
              [-74.94351712399992, 10.376096836000045],
              [-74.9437325099999 , 10.376086823000037],
              [-74.94401350399994, 10.376119320000043],
              [-74.94430641199995, 10.376174562000074],
              [-74.94451426099994, 10.376204746000042],
              [-74.94475012399994, 10.376239001000044],
              [-74.94507696799991, 10.376238750000084],
              [-74.9452181769999 , 10.37619767600006 ],
              [-74.94525920099994, 10.37618574000004 ],
              [-74.94536690799993, 10.376182321000044],
              [-74.94549640399993, 10.376178209000045],
              [-74.94565770699995, 10.376173076000043],
              [-74.94594702099994, 10.37614824700006 ],
              [-74.94661564299992, 10.376351745000079],
              [-74.94670161199991, 10.376377910000087],
              [-74.94693686399995, 10.376449509000054],
              [-74.94720665599993, 10.376507347000086],
              [-74.9474493159999 , 10.376590311000086],
              [-74.94764940599993, 10.376658722000059],
              [-74.94799172199993, 10.376758986000084],
              [-74.94881335899993, 10.37697803900005 ],
              [-74.94928549299993, 10.377080988000046],
              [-74.9496249199999 , 10.377144649000059],
              [-74.95024133399994, 10.377284374000055],
              [-74.95029317099994, 10.377291114000059],
              [-74.95074768699993, 10.37735019300004 ],
              [-74.9511934969999 , 10.377398509000045],
              [-74.95124483299992, 10.377415228000075],
              [-74.95153923399994, 10.377511110000057],
              [-74.95154990599991, 10.377514589000043],
              [-74.95205012499991, 10.377630808000049],
              [-74.9523206209999 , 10.377736704000085],
              [-74.95242662599992, 10.377774833000046],
              [-74.95254675799993, 10.377818037000054],
              [-74.95271177099994, 10.37788865400006 ],
              [-74.95281043299991, 10.377930875000061],
              [-74.95306973099991, 10.378062086000057],
              [-74.95345945899993, 10.378230294000048],
              [-74.95346683699995, 10.378234236000083],
              [-74.95375831299992, 10.378389974000072],
              [-74.9538054379999 , 10.37841515100007 ],
              [-74.95428315899994, 10.378723944000058],
              [-74.95446983499994, 10.378803555000047],
              [-74.9547454879999 , 10.378939095000078],
              [-74.9548089999999 , 10.378999969000063],
              [-74.9549693589999 , 10.379027393000058],
              [-74.95513854799992, 10.379024874000038],
              [-74.95548648999994, 10.379042670000047],
              [-74.95554881099991, 10.379045751000092],
              [-74.95563229699991, 10.379049878000046],
              [-74.95734223099993, 10.379134406000048],
              [-74.95824419299993, 10.379150880000054],
              [-74.9588468199999 , 10.379224089000047],
              [-74.95930318499995, 10.379279527000051],
              [-74.95944370899991, 10.379284226000038],
              [-74.96011882399995, 10.379306800000052],
              [-74.96025947899994, 10.37931150500009 ],
              [-74.96039544899992, 10.37931605600005 ],
              [-74.96048040799991, 10.379328143000066],
              [-74.96050655399995, 10.379331863000061],
              [-74.96113880699994, 10.379421833000038],
              [-74.96181389599991, 10.37937310400008 ],
              [-74.96181392299991, 10.379373102000045],
              [-74.96241217699992, 10.379329920000089],
              [-74.96254248499991, 10.379326139000057],
              [-74.96278769499992, 10.381832425000084],
              [-74.96266104199992, 10.382397304000051],
              [-74.9626469289999 , 10.382460249000076],
              [-74.96264633599992, 10.382462897000039],
              [-74.96262857099993, 10.382542125000043],
              [-74.96257711999993, 10.383135282000069],
              [-74.96251615199992, 10.383134477000056],
              [-74.96239783999994, 10.383142127000042],
              [-74.9621725419999 , 10.383241601000066],
              [-74.96203523199995, 10.383314465000069],
              [-74.96181541899995, 10.383431107000092],
              [-74.9617094159999 , 10.383487357000092],
              [-74.96165929799992, 10.383513951000054],
              [-74.96132333599991, 10.38370892000006 ],
              [-74.9611609449999 , 10.383822867000049],
              [-74.96115047599994, 10.383828441000048],
              [-74.96089139899993, 10.383966397000052],
              [-74.9608668269999 , 10.383976293000046],
              [-74.96068085699994, 10.384051189000047],
              [-74.96049235299995, 10.384084659000052],
              [-74.96045112099995, 10.384096446000058],
              [-74.96031129699992, 10.384136417000093],
              [-74.95996757199993, 10.384203270000057],
              [-74.95976419899995, 10.384207490000051],
              [-74.9595755009999 , 10.38417140200005 ],
              [-74.95936464399995, 10.384146359000056],
              [-74.9591685499999 , 10.38411029200006 ],
              [-74.9590834739999 , 10.384103204000041],
              [-74.9591014799999 , 10.383931078000046],
              [-74.95910795999993, 10.383605222000085],
              [-74.9590849839999 , 10.383327041000086],
              [-74.95905496799992, 10.383173355000054],
              [-74.95899536899992, 10.383019753000042],
              [-74.95898306899994, 10.383010245000037],
              [-74.9588915999999 , 10.382939494000084],
              [-74.95875466199993, 10.382899603000055],
              [-74.95868873899991, 10.382889998000053],
              [-74.95853269199995, 10.382867263000037],
              [-74.95826282399992, 10.38289730400004 ],
              [-74.9579894069999 , 10.382978602000037],
              [-74.95776781599994, 10.383081722000043],
              [-74.95757594399993, 10.38323235900009 ],
              [-74.9572919119999 , 10.383485759000052],
              [-74.9572034549999 , 10.383588519000057],
              [-74.95703723399993, 10.383654877000083],
              [-74.95646107599993, 10.383912745000089],
              [-74.95609915099993, 10.384082154000055],
              [-74.95559329199995, 10.384354472000041],
              [-74.95533492099992, 10.384527257000059],
              [-74.95529980499992, 10.384551867000084],
              [-74.95513561899992, 10.384666928000058],
              [-74.9550914319999 , 10.38470553600007 ],
              [-74.9549548629999 , 10.384824853000055],
              [-74.95479615999994, 10.384935127000062],
              [-74.95456728399995, 10.385078536000037],
              [-74.95430503799992, 10.385189095000044],
              [-74.95416841999992, 10.385262692000083],
              [-74.9541631049999 , 10.385395043000074],
              [-74.95415596399994, 10.38557283700004 ],
              [-74.95415459599991, 10.385606877000043],
              [-74.95411820999993, 10.385819321000042],
              [-74.9540595659999 , 10.386006201000043],
              [-74.9539270109999 , 10.386211589000084],
              [-74.95376486899994, 10.386413398000059],
              [-74.9536248199999 , 10.386582197000052],
              [-74.95354816199995, 10.386941197000056],
              [-74.95342029699992, 10.387498039000093],
              [-74.9532849599999 , 10.388029276000054],
              [-74.95327116199991, 10.38808776500008 ],
              [-74.9532677869999 , 10.388102070000059],
              [-74.95324971499991, 10.388178665000055],
              [-74.95321804899993, 10.388312906000067],
              [-74.95321178599994, 10.388339449000057],
              [-74.95315704899991, 10.388571475000049],
              [-74.95310957399994, 10.388783949000072],
              [-74.95288116899991, 10.389095775000044],
              [-74.95279280699992, 10.38923513800006 ],
              [-74.95247498499992, 10.389557040000057],
              [-74.95236873599993, 10.389664655000047],
              [-74.9518414339999 , 10.39020795500005 ],
              [-74.9517300839999 , 10.390320650000092],
              [-74.95139891999992, 10.390655826000057],
              [-74.9511002289999 , 10.390960516000064],
              [-74.95060981599994, 10.391470759000072],
              [-74.95046963699991, 10.391595624000047],
              [-74.95023843899992, 10.39223329300006 ],
              [-74.94998879499991, 10.39288565600009 ],
              [-74.9498750219999 , 10.39319716500006 ],
              [-74.94966046299993, 10.393172123000056],
              [-74.94933490299991, 10.393125419000057],
              [-74.94903146699994, 10.393056691000083],
              [-74.94894510199993, 10.393385288000047],
              [-74.94875702599995, 10.394100864000052],
              [-74.94853858899995, 10.394942344000071],
              [-74.9484460299999 , 10.395298905000061],
              [-74.94834350399992, 10.39566529700005 ],
              [-74.94853329599994, 10.396089474000064],
              [-74.94899839499993, 10.397105994000071],
              [-74.94916527499993, 10.397471351000092],
              [-74.9492923489999 , 10.39774955100006 ],
              [-74.94971270499991, 10.398630728000057],
              [-74.95018882999995, 10.399617933000059],
              [-74.95039349799993, 10.400071349000086],
              [-74.95005556699994, 10.400225935000037],
              [-74.94981243799992, 10.40034305000006 ],
              [-74.94943525799994, 10.400504572000045],
              [-74.94926391699994, 10.400577947000045],
              [-74.94855472699993, 10.400887427000043],
              [-74.94803944299991, 10.401105753000081],
              [-74.9474327659999 , 10.401373761000059],
              [-74.94701444299994, 10.401553384000067],
              [-74.94699522399992, 10.40156279200005 ],
              [-74.94686210099991, 10.401627939000036],
              [-74.94679581199995, 10.401726972000063],
              [-74.9466935989999 , 10.401875529000051],
              [-74.94661080399993, 10.402024027000039],
              [-74.94653622299995, 10.402134064000052],
              [-74.94646711799993, 10.402219377000051],
              [-74.94636199599995, 10.402318514000058],
              [-74.9461654989999 , 10.402472815000067],
              [-74.94600782099991, 10.402624267000078],
              [-74.9460048979999 , 10.402626929000064],
              [-74.94578372099994, 10.402828075000059],
              [-74.94542990399992, 10.40326288600005 ],
              [-74.94515894299991, 10.403571162000048],
              [-74.94513783199994, 10.403605067000058],
              [-74.94493803699993, 10.403925976000039],
              [-74.94483886599994, 10.404170627000042],
              [-74.94475921199995, 10.40445092400006 ],
              [-74.94470428599993, 10.404648769000062],
              [-74.94468237199993, 10.40474768000007 ],
              [-74.94467715199994, 10.404865767000047],
              [-74.94464694499993, 10.404975685000068],
              [-74.9446250659999 , 10.405085579000058],
              [-74.94458096199992, 10.405184546000044],
              [-74.94454516199994, 10.40527800700005 ],
              [-74.94453156199995, 10.405374151000046],
              [-74.94448786299995, 10.40561864600005 ],
              [-74.94439701899995, 10.405866022000055],
              [-74.94429221999991, 10.406083227000067],
              [-74.94417338699992, 10.406240068000045],
              [-74.94407656999994, 10.406333689000064],
              [-74.94392992899992, 10.40646588900006 ],
              [-74.94371682499991, 10.406633967000062],
              [-74.94349813499991, 10.406788328000061],
              [-74.94333749799995, 10.40687388400005 ],
              [-74.94317118599992, 10.406912782000063],
              [-74.94299942399994, 10.40698738900005 ],
              [-74.94277506199995, 10.407097836000048],
              [-74.94257011799994, 10.407210970000051],
              [-74.9424511279999 , 10.407310145000054],
              [-74.9423543979999 , 10.407436715000074],
              [-74.94223580999994, 10.40768141600006 ],
              [-74.94215884399995, 10.40793424800006 ],
              [-74.9420791469999 , 10.408200813000064],
              [-74.94199850899992, 10.408520130000056],
              [-74.94196689799992, 10.408731064000051],
              [-74.94191466099994, 10.408898707000048],
              [-74.94187357899995, 10.409088281000038],
              [-74.94180753799992, 10.40927792900004 ],
              [-74.94172207799994, 10.409467618000065],
              [-74.94165589699992, 10.409607839000046],
              [-74.94164651699992, 10.409679263000044],
              [-74.9415901719999 , 10.40992443600004 ],
              [-74.94155637799992, 10.410098431000051],
              [-74.9415345299999 , 10.410210936000055],
              [-74.94147522299994, 10.410448556000063],
              [-74.94140853599993, 10.410570957000061],
              [-74.94136796899994, 10.410638920000054],
              [-74.94133129199992, 10.410700374000044],
              [-74.9413283259999 , 10.410703779000073],
              [-74.94122707899993, 10.41082031700006 ],
              [-74.94121877399994, 10.410829880000051],
              [-74.94103932699994, 10.410994491000054],
              [-74.94090557599992, 10.411099615000069],
              [-74.94070836599991, 10.411218879000046],
              [-74.94046874399993, 10.411306830000058],
              [-74.94027837599992, 10.411349251000047],
              [-74.93992564099995, 10.411353701000053],
              [-74.93968227199991, 10.41136483200006 ],
              [-74.93898738799993, 10.411373702000049],
              [-74.93869394799992, 10.411368939000056],
              [-74.93825011199993, 10.411361732000046],
              [-74.93735402499993, 10.411322250000069],
              [-74.93692485199995, 10.411304943000061],
              [-74.93686717899993, 10.411302616000057],
              [-74.93623930099994, 10.411307804000046],
              [-74.9359006869999 , 10.41131570400006 ],
              [-74.93547736399995, 10.411306368000055],
              [-74.9354641889999 , 10.411306227000068],
              [-74.93496939199991, 10.411300757000049],
              [-74.93441215099995, 10.411337182000068],
              [-74.93426300499993, 10.41136298400005 ],
              [-74.93385859699993, 10.411432955000066],
              [-74.93355188399994, 10.411496640000053],
              [-74.93311489099995, 10.41164797600004 ],
              [-74.93286829599992, 10.41176736500006 ],
              [-74.93285116899995, 10.41174895000006 ],
              [-74.93263832799994, 10.411520048000057],
              [-74.9322456299999 , 10.411102061000065],
              [-74.93213021899993, 10.410979908000058],
              [-74.93196260999991, 10.410802511000043],
              [-74.93156639599994, 10.410388023000053],
              [-74.9312302159999 , 10.409997821000047],
              [-74.93094023099991, 10.409729713000047],
              [-74.93075276099995, 10.409541647000083],
              [-74.9306325629999 , 10.40944419400006 ],
              [-74.93056170799991, 10.40933264000006 ],
              [-74.93051551999991, 10.409214035000048],
              [-74.93049045999993, 10.409077911000054],
              [-74.93048448999991, 10.408944722000058],
              [-74.93048294699992, 10.408910313000092],
              [-74.93047212599993, 10.408823042000051],
              [-74.93040480999991, 10.408714971000052],
              [-74.93031990499992, 10.408627900000056],
              [-74.93004762999993, 10.408384186000092],
              [-74.9298319689999 , 10.408206669000037],
              [-74.9298314269999 , 10.408206129000064],
              [-74.92960559499994, 10.407980297000051],
              [-74.92930853599995, 10.407705221000072],
              [-74.92906450599992, 10.407471907000058],
              [-74.9288169969999 , 10.407256067000048],
              [-74.92858003799995, 10.407029718000047],
              [-74.92832181299991, 10.40676501200005 ],
              [-74.9279646299999 , 10.406430736000061],
              [-74.92779125499993, 10.406235644000049],
              [-74.92772743599994, 10.406117090000066],
              [-74.92767737499992, 10.405869290000055],
              [-74.92760630299995, 10.40567741700005 ],
              [-74.92758830699995, 10.405544771000052],
              [-74.92758055699994, 10.405289871000093],
              [-74.92761479199993, 10.404909149000048],
              [-74.92766294599994, 10.404458546000058],
              [-74.9277186249999 , 10.404179036000073],
              [-74.92773896099993, 10.403875175000053],
              [-74.92776614199994, 10.403494470000055],
              [-74.9277870009999 , 10.403382675000046],
              [-74.92774782399994, 10.403246588000059],
              [-74.92767313099995, 10.403019803000063],
              [-74.92754549199992, 10.40277919500005 ],
              [-74.92741088299994, 10.402570034000064],
              [-74.92725513199991, 10.402371404000064],
              [-74.92703929099991, 10.402124049000065],
              [-74.92685175999992, 10.401911536000057],
              [-74.92659000599991, 10.40164334700006 ],
              [-74.92630691099993, 10.401312359000087],
              [-74.9259954979999 , 10.400943032000043],
              [-74.92616747199992, 10.40062479900007 ],
              [-74.9262201279999 , 10.400550631000044],
              [-74.92645561299992, 10.40021895500007 ],
              [-74.92662082399994, 10.40000899200004 ],
              [-74.92679671899992, 10.399833921000038],
              [-74.92694791799994, 10.399658918000057],
              [-74.92704264499991, 10.399470098000052],
              [-74.9269050179999 , 10.399449512000047],
              [-74.92677435699994, 10.399393987000053],
              [-74.92653431999992, 10.399328277000052],
              [-74.92648284799992, 10.39931013100005 ],
              [-74.92617065299993, 10.399200045000043],
              [-74.9257151509999 , 10.399026655000057],
              [-74.9255033579999 , 10.398971351000057],
              [-74.92534688599994, 10.398895420000088],
              [-74.9252313959999 , 10.398839376000069],
              [-74.92503008199992, 10.39874562600005 ],
              [-74.9247723339999 , 10.39865203000005 ],
              [-74.92456397199993, 10.398561789000041],
              [-74.92423543899992, 10.398381079000046],
              [-74.92413302299991, 10.39833595600004 ],
              [-74.92381049199992, 10.39906664700004 ],
              [-74.9236528479999 , 10.399468653000042],
              [-74.92314895299995, 10.399658559000045],
              [-74.92241598699991, 10.399925901000074],
              [-74.92185919799994, 10.400122932000045],
              [-74.92179986899993, 10.400357058000054],
              [-74.9216633659999 , 10.400752018000048],
              [-74.92148432199991, 10.401271130000055],
              [-74.92144637399991, 10.401381159000039],
              [-74.92125755799992, 10.401996258000054],
              [-74.9211656789999 , 10.402257727000062],
              [-74.92104753399991, 10.402593953000064],
              [-74.92115076199991, 10.402623077000044],
              [-74.9212805169999 , 10.40265968600005 ],
              [-74.92168650699995, 10.402784322000059],
              [-74.92214549199991, 10.402940243000046],
              [-74.92259032099992, 10.403078747000052],
              [-74.92279372799993, 10.403218209000045],
              [-74.92310274699992, 10.403430075000074],
              [-74.92319726999995, 10.403490652000073],
              [-74.92371051399994, 10.403819570000053],
              [-74.9243713489999 , 10.404264792000049],
              [-74.92439858499995, 10.404282470000055],
              [-74.92469290899993, 10.404473456000062],
              [-74.92447158199991, 10.404805786000054],
              [-74.92432051499992, 10.405029683000066],
              [-74.92403576099991, 10.405386624000073],
              [-74.9238670819999 , 10.405621039000039],
              [-74.92376601299992, 10.405769036000038],
              [-74.92362816699995, 10.405970879000051],
              [-74.92343131799993, 10.406222828000068],
              [-74.92324857099993, 10.406474742000057],
              [-74.92305870099995, 10.406698738000046],
              [-74.9229993379999 , 10.40692238400004 ],
              [-74.9228943899999 , 10.407243931000039],
              [-74.92277563899995, 10.40768074500005 ],
              [-74.92263579899992, 10.408145558000058],
              [-74.92252057199994, 10.40857887900006 ],
              [-74.92240561499995, 10.409113467000054],
              [-74.92226235999993, 10.409620191000045],
              [-74.92212286099993, 10.410210713000083],
              [-74.92202505999995, 10.410567163000053],
              [-74.92195099899993, 10.410837851000053],
              [-74.92185390899994, 10.411192691000053],
              [-74.92182244799994, 10.411297533000038],
              [-74.92162443899991, 10.411123460000056],
              [-74.9214303089999 , 10.411082071000067],
              [-74.92095403999991, 10.41105888800007 ],
              [-74.92049179899993, 10.411007734000066],
              [-74.91996610299992, 10.41097071200005 ],
              [-74.91954276099995, 10.410954377000053],
              [-74.91889381199991, 10.410994508000044],
              [-74.91855345599993, 10.411115322000057],
              [-74.9184180549999 , 10.411163387000045],
              [-74.91787191999993, 10.411395302000074],
              [-74.91764290099991, 10.411493687000075],
              [-74.91757588399992, 10.41149735600004 ],
              [-74.91746674399991, 10.411574471000051],
              [-74.9174068129999 , 10.411588595000069],
              [-74.91733285799995, 10.411634187000061],
              [-74.91710635899994, 10.41174863100008 ],
              [-74.9168326329999 , 10.411886937000077],
              [-74.9168063759999 , 10.411889894000069],
              [-74.91673407099995, 10.41189804000004 ],
              [-74.91654699499992, 10.41191911900006 ],
              [-74.9165896909999 , 10.411955984000087],
              [-74.91677681599992, 10.412117557000045],
              [-74.9167783549999 , 10.412213828000063],
              [-74.9167783769999 , 10.412214283000083],
              [-74.91677796199991, 10.412214258000063],
              [-74.91657801399992, 10.412202377000085],
              [-74.91626360799995, 10.412224251000055],
              [-74.91585685099994, 10.412259296000059],
              [-74.91542885799993, 10.412409228000058],
              [-74.91501462299993, 10.412554323000052],
              [-74.91500687799993, 10.412558225000055],
              [-74.91444787299992, 10.412843014000089],
              [-74.91432136999993, 10.412908481000045],
              [-74.9140157519999 , 10.413044669000044],
              [-74.91361972099992, 10.413171067000064],
              [-74.9124590859999 , 10.41367589500004 ],
              [-74.91113939099995, 10.414217997000037],
              [-74.91000545999992, 10.414655864000053],
              [-74.90891884799993, 10.41515694800006 ],
              [-74.90864310199993, 10.41528345000006 ],
              [-74.9085516849999 , 10.415351558000054],
              [-74.9084778909999 , 10.41536897800006 ],
              [-74.9083513089999 , 10.415412539000044],
              [-74.90827093899992, 10.41545293300004 ],
              [-74.90818305099992, 10.415454571000055],
              [-74.90808177899993, 10.415490857000066],
              [-74.90802620599993, 10.41549184300004 ],
              [-74.90787422699992, 10.415535895000062],
              [-74.90775808799992, 10.415510563000055],
              [-74.90767930699991, 10.415502856000046],
              [-74.9075893179999 , 10.415522861000056],
              [-74.9074903099999 , 10.415552149000064],
              [-74.90743944299993, 10.415553130000092],
              [-74.9073723169999 , 10.415554364000059],
              [-74.90728417699995, 10.415542308000056],
              [-74.90724487299991, 10.415542969000057],
              [-74.90715708599993, 10.415553794000061],
              [-74.9070807459999 , 10.41555519700006 ],
              [-74.90703909199993, 10.415556011000092],
              [-74.9069718529999 , 10.41554579700005 ],
              [-74.90692327799991, 10.415546703000075],
              [-74.90675727899992, 10.416754362000063],
              [-74.90565136099991, 10.416332877000059],
              [-74.90561416799994, 10.416322175000062],
              [-74.9055838939999 , 10.416311231000066],
              [-74.90554651499991, 10.416286754000055],
              [-74.90552567999993, 10.416287158000046],
              [-74.90550021299993, 10.416287651000061],
              [-74.9054516349999 , 10.416288555000051],
              [-74.90541950199992, 10.416302831000053],
              [-74.90535532699994, 10.416340638000065],
              [-74.90534604499993, 10.41634080700004 ],
              [-74.90515320799994, 10.416378878000046],
              [-74.90479927799993, 10.41644874900004 ],
              [-74.90433421699993, 10.416540559000055],
              [-74.90404984299994, 10.416652124000052],
              [-74.90271585499994, 10.416991936000045],
              [-74.90253377999994, 10.416743403000055],
              [-74.90248485299992, 10.416723751000063],
              [-74.9023496449999 , 10.416664357000059],
              [-74.90223956599993, 10.416581731000065],
              [-74.90206991899993, 10.416392471000052],
              [-74.90200399299994, 10.416303466000045],
              [-74.90105996999995, 10.415437747000055],
              [-74.90102233099992, 10.415417989000048],
              [-74.90086585699993, 10.415327011000045],
              [-74.90070693699994, 10.41522917900005 ],
              [-74.90053882399991, 10.415136117000088],
              [-74.9003982019999 , 10.415024189000064],
              [-74.90027351799995, 10.414895978000061],
              [-74.90014402499992, 10.414758663000043],
              [-74.9000652009999 , 10.414599834000057],
              [-74.90003244399992, 10.414433245000055],
              [-74.90001192199992, 10.414307662000056],
              [-74.89999553199993, 10.414152258000058],
              [-74.90004500299995, 10.414057429000081],
              [-74.90005257699994, 10.413951969000038],
              [-74.90003677899995, 10.413835410000047],
              [-74.89998098899991, 10.413671573000045],
              [-74.89997971299994, 10.413591420000046],
              [-74.89991467999994, 10.413452237000058],
              [-74.89990545599994, 10.413432495000052],
              [-74.89983931499995, 10.413349047000054],
              [-74.89978497999994, 10.413279057000068],
              [-74.89972837699992, 10.413211408000052],
              [-74.89964086799995, 10.413171614000078],
              [-74.89960251999992, 10.413154175000045],
              [-74.89946655099993, 10.413046755000039],
              [-74.89945497899993, 10.41304241000006 ],
              [-74.8993653359999 , 10.412866576000056],
              [-74.89929315099994, 10.41278681500006 ],
              [-74.89917682399994, 10.412666         ],
              [-74.89902566199993, 10.41253172000006 ],
              [-74.8988166709999 , 10.412384091000092],
              [-74.89854980699994, 10.412229970000055],
              [-74.89830368799994, 10.412068805000047],
              [-74.89803193999995, 10.411873391000086],
              [-74.8978208449999 , 10.411767087000044],
              [-74.8976326529999 , 10.411630700000046],
              [-74.8974886179999 , 10.411521612000058],
              [-74.8973444259999 , 10.411382716000048],
              [-74.89717251299993, 10.411250813000038],
              [-74.89710521299992, 10.411216939000042],
              [-74.89702609799991, 10.41113253900005 ],
              [-74.89687525699992, 10.411044159000085],
              [-74.89665503399993, 10.410963154000058],
              [-74.89653672799994, 10.41089975400007 ],
              [-74.8964440279999 , 10.41086370000005 ],
              [-74.89630708599992, 10.410791239000048],
              [-74.8961517329999 , 10.410718966000047],
              [-74.89586415299993, 10.410581002000072],
              [-74.89546763999994, 10.410414083000092],
              [-74.89529582799992, 10.410298280000063],
              [-74.89517050299992, 10.41022345500005 ],
              [-74.89500338599993, 10.410125931000039],
              [-74.89486196599995, 10.41006273700009 ],
              [-74.89472037199994, 10.40998570100004 ],
              [-74.89452546899992, 10.409879273000058],
              [-74.89431874499991, 10.409722456000054],
              [-74.89413769499993, 10.409613595000053],
              [-74.89397530699995, 10.409527574000037],
              [-74.89380818299992, 10.409430052000062],
              [-74.8936968189999 , 10.40937122400004 ],
              [-74.8935298959999 , 10.409294393000039],
              [-74.89336291299992, 10.409224484000049],
              [-74.89320995799994, 10.409165899000072],
              [-74.89303832099995, 10.409089150000057],
              [-74.89282735599994, 10.409001129000046],
              [-74.8925053669999 , 10.40890710900004 ],
              [-74.89247018599991, 10.408895061000067],
              [-74.89226895699994, 10.40882614800006 ],
              [-74.89205823999993, 10.408781772000054],
              [-74.89193792799995, 10.408768871000063],
              [-74.8919217369999 , 10.408768985000052],
              [-74.89189690299992, 10.408874696000055],
              [-74.8918789519999 , 10.408966620000058],
              [-74.89179498399994, 10.409240287000046],
              [-74.8916814769999 , 10.409592219000046],
              [-74.89161607299991, 10.409870358000092],
              [-74.8915823179999 , 10.410028921000048],
              [-74.8915096639999 , 10.410265766000066],
              [-74.89140719799991, 10.41053037100005 ],
              [-74.89130476699995, 10.410808750000058],
              [-74.89115877799992, 10.411154045000046],
              [-74.8911000249999 , 10.411383931000046],
              [-74.89099530199991, 10.411655462000056],
              [-74.89090869899991, 10.411876307000057],
              [-74.89079093999993, 10.41229710400006 ],
              [-74.89070669999995, 10.412520348000044],
              [-74.89064082899995, 10.412727300000086],
              [-74.8905934949999 , 10.412936465000087],
              [-74.8905863889999 , 10.413299059000053],
              [-74.8905675659999 , 10.413634211000044],
              [-74.89052952799995, 10.413845611000056],
              [-74.89048384199992, 10.41432780200006 ],
              [-74.89045915899993, 10.414463399000056],
              [-74.89045136899995, 10.414704379000057],
              [-74.89041874299994, 10.415051085000073],
              [-74.89041953199995, 10.415188791000048],
              [-74.8904298249999 , 10.415360803000056],
              [-74.89044478099993, 10.41554198700004 ],
              [-74.89043650999992, 10.41570726000009 ],
              [-74.89042625999991, 10.41593215100005 ],
              [-74.89041721699994, 10.415966713000046],
              [-74.89041808099995, 10.41610894300004 ],
              [-74.89034515499992, 10.416295295000054],
              [-74.89031590399992, 10.416430966000064],
              [-74.89032622399992, 10.416612160000057],
              [-74.89032772899992, 10.416859972000054],
              [-74.89031203999991, 10.416944967000063],
              [-74.89028053699991, 10.417094349000081],
              [-74.89025795499992, 10.417188620000047],
              [-74.89018940699992, 10.417326785000057],
              [-74.89018052499995, 10.417397925000046],
              [-74.8897701759999 , 10.417618899000047],
              [-74.8896049029999 , 10.41743421600006 ],
              [-74.88946321799995, 10.41732737600006 ],
              [-74.88931656699992, 10.417172376000053],
              [-74.88910762699993, 10.41704310700004 ],
              [-74.88896620399993, 10.416979841000057],
              [-74.88883195599993, 10.41695786300005 ],
              [-74.88865376799993, 10.416940798000041],
              [-74.88847787899994, 10.416928257000052],
              [-74.88832528199993, 10.416929352000068],
              [-74.88806840099994, 10.41691059100009 ],
              [-74.88795271999993, 10.416895274000069],
              [-74.88781183999993, 10.416928477000056],
              [-74.88768950899993, 10.41696607700004 ],
              [-74.88742438199995, 10.41712169100009 ],
              [-74.88724933899994, 10.41724227800006 ],
              [-74.8868804299999 , 10.41743305700004 ],
              [-74.88665013499991, 10.417597621000084],
              [-74.88647783099992, 10.417784724000057],
              [-74.88627999899995, 10.41797428700005 ],
              [-74.88598764999995, 10.418196711000064],
              [-74.88584011299992, 10.418280353000057],
              [-74.88563484299993, 10.41838277700009 ],
              [-74.8854111789999 , 10.41848766600009 ],
              [-74.88524486799992, 10.418539292000048],
              [-74.8851757679999 , 10.418578798000055],
              [-74.88502800299995, 10.41862578200005 ],
              [-74.88472085699993, 10.418703693000054],
              [-74.88439022299991, 10.41871752500009 ],
              [-74.8841936099999 , 10.418718942000055],
              [-74.88410820099995, 10.418740205000063],
              [-74.88407352899992, 10.418740426000056],
              [-74.88405962299993, 10.418740533000062],
              [-74.8838604629999 , 10.418698393000057],
              [-74.88373527599992, 10.418651105000038],
              [-74.8834526999999 , 10.41857280000005 ],
              [-74.88330894199993, 10.41851411500005 ],
              [-74.88314659299994, 10.418441858000051],
              [-74.88300766399993, 10.418403863000037],
              [-74.8828662709999 , 10.418352105000054],
              [-74.8827478899999 , 10.418286373000058],
              [-74.88256918799993, 10.418179829000053],
              [-74.88243698299993, 10.418121128000053],
              [-74.88235117099993, 10.418071200000043],
              [-74.88227685699991, 10.418025838000062],
              [-74.88189903799991, 10.418664170000056],
              [-74.88175426099991, 10.418818988000055],
              [-74.88153229199992, 10.419224435000046],
              [-74.88139920099991, 10.419404364000059],
              [-74.8812523759999 , 10.419595837000088],
              [-74.88108929499992, 10.41979208500004 ],
              [-74.88098634099993, 10.419983248000051],
              [-74.88087422899991, 10.420190597000044],
              [-74.88065385699991, 10.420469831000048],
              [-74.8804952619999 , 10.420633873000043],
              [-74.88036895099992, 10.42078624000004 ],
              [-74.88022455399994, 10.421007510000038],
              [-74.88001323799995, 10.421256854000092],
              [-74.87972647299995, 10.421655892000047],
              [-74.87950884199995, 10.422001649000038],
              [-74.87934386799992, 10.422269020000044],
              [-74.8792266339999 , 10.422400612000047],
              [-74.87917599499991, 10.42243082400006 ],
              [-74.87902594799993, 10.422484662000045],
              [-74.8787700389999 , 10.422619559000054],
              [-74.87859714099994, 10.422717171000045],
              [-74.8782623699999 , 10.422818232000054],
              [-74.87804714099991, 10.422794556000042],
              [-74.87771136899994, 10.422725835000051],
              [-74.8774515689999 , 10.422599156000047],
              [-74.87724736299992, 10.42247898000005 ],
              [-74.87711050799993, 10.422418011000047],
              [-74.87706175099993, 10.42237704200005 ],
              [-74.87696416999995, 10.422299739000039],
              [-74.87672524499993, 10.422191302000044],
              [-74.87654629699995, 10.422045707000052],
              [-74.87639057599995, 10.421911413000089],
              [-74.87620484899992, 10.421793448000074],
              [-74.87595894799995, 10.42166207300005 ],
              [-74.87581521299995, 10.421612639000045],
              [-74.87566703999994, 10.42158844700009 ],
              [-74.87556278399995, 10.421550143000047],
              [-74.87521081799991, 10.42148386200006 ],
              [-74.87509514599992, 10.421473204000051],
              [-74.87487779899993, 10.42148392100006 ],
              [-74.87464923199991, 10.421547493000048],
              [-74.8744549999999 , 10.42156267100006 ],
              [-74.87437187199993, 10.421583848000068],
              [-74.87420816199995, 10.42167459600006 ],
              [-74.8740924789999 , 10.42166160700009 ],
              [-74.87391479999991, 10.421731686000044],
              [-74.87366788899993, 10.421816069000045],
              [-74.87346698599993, 10.421872573000087],
              [-74.8732823009999 , 10.42193582100009 ],
              [-74.87321322999992, 10.421986839000056],
              [-74.87308243599995, 10.422173611000062],
              [-74.87288788299992, 10.422509980000086],
              [-74.87273217699993, 10.422775075000061],
              [-74.87256255999995, 10.423040121000042],
              [-74.87255361799993, 10.423059351000063],
              [-74.87196163899995, 10.423819540000068],
              [-74.87188988899993, 10.423889357000064],
              [-74.87187851099992, 10.423928441000044],
              [-74.87170126399991, 10.42405819600009 ],
              [-74.8714894339999 , 10.424220312000045],
              [-74.87142024999991, 10.424257566000051],
              [-74.87136250299994, 10.424271687000044],
              [-74.87126317999991, 10.424295384000061],
              [-74.87107632099992, 10.424374739000086],
              [-74.87104633599995, 10.424393319000046],
              [-74.87097950599991, 10.424428164000062],
              [-74.87085732699995, 10.424500219000038],
              [-74.87053421099995, 10.424610352000059],
              [-74.87038181699995, 10.424664195000048],
              [-74.87025951099992, 10.424710963000052],
              [-74.87007477199995, 10.424753596000073],
              [-74.86983420999991, 10.424757587000045],
              [-74.86963562499994, 10.424800307000055],
              [-74.86939984499992, 10.424831831000063],
              [-74.86903456899995, 10.424861916000054],
              [-74.86863005399994, 10.424892301000057],
              [-74.86832492399992, 10.424924346000068],
              [-74.8682440739999 , 10.424943263000046],
              [-74.8681194909999 , 10.42499230900006 ],
              [-74.86793500999994, 10.42508309100009 ],
              [-74.86780603299991, 10.425171272000057],
              [-74.8676493339999 , 10.425273361000052],
              [-74.8675041759999 , 10.425368421000087],
              [-74.86740058499993, 10.425451807000059],
              [-74.86732704499991, 10.425541821000081],
              [-74.86724699399991, 10.425682343000062],
              [-74.86719942999991, 10.425857120000046],
              [-74.86714492899995, 10.426029572000061],
              [-74.86711557899991, 10.426158255000075],
              [-74.86708215399995, 10.426369642000054],
              [-74.86697754499994, 10.426670974000046],
              [-74.86688861599993, 10.426882707000061],
              [-74.86683856299993, 10.42703453100006 ],
              [-74.86653211299995, 10.427362421000055],
              [-74.86652829799993, 10.427489063000053],
              [-74.86645559099992, 10.427682957000059],
              [-74.86635201099995, 10.427912741000057],
              [-74.8662116239999 , 10.428201587000046],
              [-74.86614151499992, 10.428294204000053],
              [-74.86595232699995, 10.428525406000063],
              [-74.86528423399994, 10.429305968000051],
              [-74.86471074899993, 10.429869308000093],
              [-74.86422440699994, 10.430347047000055],
              [-74.86409012199994, 10.430453951000061],
              [-74.8638863409999 , 10.430616822000047],
              [-74.86369593599994, 10.430820408000045],
              [-74.86352332999991, 10.43100720600006 ],
              [-74.86323848199993, 10.431329813000048],
              [-74.8630871499999 , 10.431524831000047],
              [-74.86292981799994, 10.43169025900005 ],
              [-74.86280530099992, 10.431801233000044],
              [-74.86271187699992, 10.431884491000062],
              [-74.8625774649999 , 10.432004280000058],
              [-74.86244262899993, 10.43220780200005 ],
              [-74.86236679899991, 10.432331977000047],
              [-74.86226787299995, 10.43228603600005 ],
              [-74.86226914299993, 10.432283488000053],
              [-74.86201727799994, 10.432049168000049],
              [-74.86170117199993, 10.431755076000059],
              [-74.85929444499993, 10.42951596100005 ],
              [-74.85888685799995, 10.429136750000055],
              [-74.85826681899994, 10.428559883000048],
              [-74.85814517099993, 10.428585748000046],
              [-74.85810722999992, 10.428619594000054],
              [-74.85803948599994, 10.428659311000047],
              [-74.85794824699991, 10.428690820000043],
              [-74.8578645309999 , 10.428738060000057],
              [-74.85779639999993, 10.428768602000048],
              [-74.85769991899991, 10.428786566000042],
              [-74.85760852299995, 10.42881581800009 ],
              [-74.85750254399994, 10.428829567000037],
              [-74.85739716299992, 10.428857081000046],
              [-74.85728197799995, 10.428871213000093],
              [-74.8571890689999 , 10.428863741000043],
              [-74.85709616399993, 10.428858536000064],
              [-74.85697041399993, 10.428843305000044],
              [-74.85682390699992, 10.428828904000056],
              [-74.85671861399993, 10.428803524000045],
              [-74.8565984949999 , 10.428758125000058],
              [-74.85647814599992, 10.428705807000085],
              [-74.85638799199995, 10.42865680500006 ],
              [-74.85627752399995, 10.428617881000037],
              [-74.85624902199993, 10.428603045000045],
              [-74.85615725699995, 10.428567887000042],
              [-74.85601350899992, 10.428459132000057],
              [-74.85592631199995, 10.428423818000056],
              [-74.85582124299992, 10.428350200000068],
              [-74.8557286649999 , 10.428190965000056],
              [-74.85567477599994, 10.42812202400006 ],
              [-74.85557186299991, 10.427937885000063],
              [-74.85550429499995, 10.427821237000046],
              [-74.85538725299995, 10.427578011000037],
              [-74.85537675899991, 10.42754859200005 ],
              [-74.85535586599991, 10.427494332000038],
              [-74.8553298779999 , 10.427428786000064],
              [-74.8552656729999 , 10.427150827000048],
              [-74.85522351199995, 10.427063922000059],
              [-74.85516535999994, 10.427004672000066],
              [-74.85509543799992, 10.426917979000052],
              [-74.85504656699993, 10.426860960000056],
              [-74.8549838049999 , 10.42681090800005 ],
              [-74.85494421399994, 10.42675612000005 ],
              [-74.85488383699993, 10.426719828000046],
              [-74.85480962199995, 10.426683642000057],
              [-74.85477258799995, 10.42668387000009 ],
              [-74.85468919399995, 10.426656955000055],
              [-74.8545943769999 , 10.426657611000053],
              [-74.85452043799995, 10.426674182000056],
              [-74.8544141889999 , 10.426697883000088],
              [-74.8542871219999 , 10.42672404800004 ],
              [-74.85415335999994, 10.426784618000056],
              [-74.85406106499994, 10.426821988000086],
              [-74.85393641299993, 10.426873361000048],
              [-74.85381420399995, 10.426931567000054],
              [-74.85357863799993, 10.426995203000047],
              [-74.85354180099995, 10.427013872000089],
              [-74.8534726179999 , 10.427051049000056],
              [-74.85330423999994, 10.42713486200006 ],
              [-74.85326758999992, 10.427199358000053],
              [-74.8531779029999 , 10.427284898000039],
              [-74.85315492399991, 10.427307978000044],
              [-74.8531048179999 , 10.427444052000055],
              [-74.85304071599995, 10.427515821000043],
              [-74.85295242699993, 10.427616183000055],
              [-74.85285508699991, 10.427721507000058],
              [-74.8526997319999 , 10.42793283800006 ],
              [-74.85257880899991, 10.42813575200006 ],
              [-74.85246411499992, 10.428377496000053],
              [-74.85235254999992, 10.428582296000059],
              [-74.85227853499993, 10.42879928800005 ],
              [-74.8522550209999 , 10.428951963000088],
              [-74.85223012299991, 10.429127802000039],
              [-74.85210790499991, 10.42935374700005 ],
              [-74.85205069599994, 10.429530941000053],
              [-74.85201226799995, 10.429714233000084],
              [-74.85191893699994, 10.430021669000041],
              [-74.85185494899991, 10.430256654000061],
              [-74.85181386699992, 10.430430844000057],
              [-74.85178627499994, 10.430571203000056],
              [-74.85176828199991, 10.430662736000045],
              [-74.85171948499993, 10.43103489400005 ],
              [-74.85164948599993, 10.431346027000075],
              [-74.85166174699992, 10.431630537000046],
              [-74.8516302189999 , 10.43191925800005 ],
              [-74.85156704899993, 10.432763426000065],
              [-74.85151918299994, 10.432942506000074],
              [-74.85151511999993, 10.433170284000084],
              [-74.8515577089999 , 10.43351326800007 ],
              [-74.85162536399991, 10.43400930000007 ],
              [-74.85163911299992, 10.43407429000007 ],
              [-74.85169525499992, 10.434339653000052],
              [-74.8517336729999 , 10.434639140000058],
              [-74.85173984499994, 10.434997545000044],
              [-74.85172344099993, 10.435315458000048],
              [-74.85167624499991, 10.43567151600007 ],
              [-74.85160161899995, 10.435874743000056],
              [-74.85147513699991, 10.436110085000053],
              [-74.85134156299995, 10.436504351000053],
              [-74.85127461399992, 10.436723375000042],
              [-74.85118902799991, 10.436873498000068],
              [-74.85110668399994, 10.43701793300005 ],
              [-74.8510138069999 , 10.437281724000059],
              [-74.85093880199992, 10.437475774000063],
              [-74.85090524399993, 10.437665757000048],
              [-74.85089802899995, 10.437712798000064],
              [-74.85078885899992, 10.438278083000057],
              [-74.85077561599991, 10.438471393000043],
              [-74.85081578899991, 10.43875930300004 ],
              [-74.85083082799991, 10.438839142000063],
              [-74.85089207599992, 10.439025014000038],
              [-74.85096624699992, 10.439240235000057],
              [-74.85108383999994, 10.439389237000057],
              [-74.85123174799992, 10.439649551000059],
              [-74.85133428099994, 10.439826708000055],
              [-74.85145025299994, 10.440097617000049],
              [-74.85152200899995, 10.44025776300009 ],
              [-74.85156987199991, 10.440455671000052],
              [-74.8515564349999 , 10.440787359000069],
              [-74.85154383699995, 10.441086762000054],
              [-74.85152969799992, 10.441510319000088],
              [-74.85153382399994, 10.441820564000068],
              [-74.85154919299993, 10.44217625300007 ],
              [-74.85156017699995, 10.442430899000044],
              [-74.85157767899994, 10.442784184000061],
              [-74.85160950499994, 10.442985168000064],
              [-74.85165995599993, 10.443187590000036],
              [-74.85170751399994, 10.443376399000044],
              [-74.85181103499991, 10.443629391000059],
              [-74.85188339099994, 10.443750417000047],
              [-74.85190411699995, 10.443789263000042],
              [-74.8520068979999 , 10.443981893000057],
              [-74.85211339099993, 10.44419569900009 ],
              [-74.85220544799995, 10.444407488000081],
              [-74.8522085429999 , 10.444414607000056],
              [-74.8523463919999 , 10.444817889000092],
              [-74.8524379669999 , 10.445115129000044],
              [-74.85249045699993, 10.445365783000057],
              [-74.85254819499994, 10.445685144000038],
              [-74.85259593899991, 10.446059124000044],
              [-74.85260073299992, 10.44609667800006 ],
              [-74.8526022179999 , 10.446107151000092],
              [-74.85262238099995, 10.446249266000052],
              [-74.85264060499992, 10.446377726000037],
              [-74.85254258299994, 10.446364716000062],
              [-74.85223698499993, 10.446324158000039],
              [-74.8520464959999 , 10.446298876000071],
              [-74.85201953399991, 10.446295298000052],
              [-74.85181396099995, 10.446268015000044],
              [-74.85181326199995, 10.446260588000086],
              [-74.85145623099993, 10.446135895000054],
              [-74.85068098799991, 10.44578739800005 ],
              [-74.84985439399992, 10.445490903000064],
              [-74.84890237499991, 10.445381212000086],
              [-74.84832326299994, 10.44535514100005 ],
              [-74.84823749799995, 10.445732734000046],
              [-74.84810776799992, 10.44664410200005 ],
              [-74.84797787899993, 10.447158844000057],
              [-74.84784267899994, 10.447694631000047],
              [-74.84756469699994, 10.448499778000041],
              [-74.84735268499992, 10.449115779000067],
              [-74.84744413999994, 10.449571652000088],
              [-74.84745124699992, 10.449672201000055],
              [-74.84747083399992, 10.45001896000008 ],
              [-74.84750253899995, 10.45058024900004 ],
              [-74.84751966199991, 10.45110872500004 ],
              [-74.8474966149999 , 10.451969546000043],
              [-74.84735722499994, 10.452927327000054],
              [-74.84729474999995, 10.453196692000063],
              [-74.84715343199991, 10.453411946000074],
              [-74.84683972699992, 10.454140488000064],
              [-74.84662166999993, 10.454508470000064],
              [-74.84656327099992, 10.454927129000055],
              [-74.84641406599991, 10.455441629000063],
              [-74.84627765899995, 10.455877354000052],
              [-74.84621208599992, 10.45602271100006 ],
              [-74.84616991999991, 10.456279743000039],
              [-74.84608422699995, 10.45665580900004 ],
              [-74.84582927799994, 10.457082926000055],
              [-74.84527339199991, 10.457780416000048],
              [-74.84465815099992, 10.458393152000042],
              [-74.84431729999994, 10.458653803000061],
              [-74.8441230549999 , 10.458928856000057],
              [-74.8438451529999 , 10.459628211000052],
              [-74.84374068799991, 10.460000515000047],
              [-74.84351392399992, 10.460488500000054],
              [-74.8431472279999 , 10.461115756000083],
              [-74.84290710999994, 10.461457167000049],
              [-74.84262764599993, 10.46163701100005 ],
              [-74.8419851829999 , 10.46177960500006 ],
              [-74.84152085399995, 10.461969713000087],
              [-74.8411363699999 , 10.462183928000059],
              [-74.8407550469999 , 10.462526808000064],
              [-74.84050998399994, 10.462914340000054],
              [-74.84015664899994, 10.46320772100006 ],
              [-74.8401302549999 , 10.46325173300005 ],
              [-74.83857267199994, 10.46273326700009 ],
              [-74.8382021459999 , 10.463379169000063],
              [-74.83787480299992, 10.464115074000063],
              [-74.83769158899992, 10.46464710400005 ],
              [-74.83745602099992, 10.465038924000055],
              [-74.83722448599991, 10.465419017000045],
              [-74.83704900399994, 10.46570010000005 ],
              [-74.83677769399992, 10.466118599000083],
              [-74.8367623179999 , 10.466142318000038],
              [-74.83644984599994, 10.466631716000052],
              [-74.83615166999994, 10.46740784800005 ],
              [-74.83526890399992, 10.469705626000064],
              [-74.83514132699992, 10.469970833000048],
              [-74.83493043499993, 10.470129402000055],
              [-74.83445914899994, 10.470427777000054],
              [-74.83381183599994, 10.471101607000037],
              [-74.83337873699992, 10.471695028000056],
              [-74.83285451799992, 10.472377382000047],
              [-74.83281413999993, 10.47243551300005 ],
              [-74.8326144159999 , 10.47272305100006 ],
              [-74.83225575099993, 10.473239423000052],
              [-74.83221081399995, 10.473396815000058],
              [-74.83112906899993, 10.474805162000052],
              [-74.83107204799995, 10.47486094900006 ],
              [-74.83104798699992, 10.474884474000078],
              [-74.83094206699991, 10.474988032000056],
              [-74.83081207399994, 10.475115039000059],
              [-74.83078240299994, 10.47514915100004 ],
              [-74.83066572299992, 10.475288136000074],
              [-74.83057058299994, 10.475397369000063],
              [-74.8302505979999 , 10.475739703000045],
              [-74.83020070899994, 10.475821393000047],
              [-74.8301151149999 , 10.47598170200007 ],
              [-74.83001927899994, 10.47615524300005 ],
              [-74.82998527699993, 10.476227290000054],
              [-74.82991160599994, 10.476392793000059],
              [-74.82988249899995, 10.476453667000044],
              [-74.8298086129999 , 10.476618891000044],
              [-74.82979282899993, 10.476670909000063],
              [-74.8297520239999 , 10.476847492000047],
              [-74.82972788399991, 10.47695602400006 ],
              [-74.8296928499999 , 10.477133380000055],
              [-74.82968389099995, 10.477205088000062],
              [-74.82966391399992, 10.477384939000046],
              [-74.82965858199992, 10.477419350000048],
              [-74.82961669399992, 10.47759529700005 ],
              [-74.82957913699994, 10.477674062000062],
              [-74.82949046899995, 10.47783275100005 ],
              [-74.82945533299994, 10.477908119000062],
              [-74.82941053399992, 10.477990633000047],
              [-74.82935013399992, 10.47810449800005 ],
              [-74.82933342399991, 10.478218800000093],
              [-74.82932664799995, 10.478250527000057],
              [-74.82927691299994, 10.478424589000042],
              [-74.82927147799995, 10.478451163000045],
              [-74.82924594699995, 10.47863039300006 ],
              [-74.8292290739999 , 10.478734041000052],
              [-74.82918505999993, 10.478916072000061],
              [-74.82909565999995, 10.479069112000047],
              [-74.82905385899994, 10.479105441000058],
              [-74.82900207499995, 10.479256422000049],
              [-74.82899490999995, 10.479304458000058],
              [-74.82891575499991, 10.479386503000057],
              [-74.82883654099993, 10.479468549000046],
              [-74.82880848799994, 10.47952334200005 ],
              [-74.8287372129999 , 10.479616811000085],
              [-74.8286081629999 , 10.47974677700006 ],
              [-74.82857023499992, 10.479789951000043],
              [-74.82845669199992, 10.479931471000043],
              [-74.82839267299994, 10.480014181000058],
              [-74.8281635969999 , 10.480328364000059],
              [-74.82805319999994, 10.48047220500007 ],
              [-74.82802152699992, 10.480508160000056],
              [-74.8278948279999 , 10.480638544000044],
              [-74.8278239839999 , 10.480703194000057],
              [-74.82769921399995, 10.480834567000045],
              [-74.82763129499995, 10.48098755600006 ],
              [-74.82761794299995, 10.481071633000056],
              [-74.82760696699995, 10.481252956000048],
              [-74.8275964629999 , 10.48133666800004 ],
              [-74.82758499099992, 10.481516010000064],
              [-74.82758846099995, 10.481534293000038],
              [-74.82760568599991, 10.481585033000044],
              [-74.82764681299994, 10.481706186000054],
              [-74.8276948059999 , 10.481871194000064],
              [-74.8277446649999 , 10.481978497000057],
              [-74.82782348399991, 10.48214173200006 ],
              [-74.82785314199992, 10.482249434000039],
              [-74.82785949999993, 10.482398225000054],
              [-74.82781470399993, 10.482581097000093],
              [-74.8277560599999 , 10.482734209000057],
              [-74.8277247979999 , 10.482820942000046],
              [-74.8276668499999 , 10.482992411000055],
              [-74.82764693699994, 10.483049527000048],
              [-74.82758240899994, 10.483218605000047],
              [-74.82749355499993, 10.483387608000044],
              [-74.82739097599995, 10.483522184000037],
              [-74.82736876299992, 10.483540382000058],
              [-74.82720734399993, 10.48362671700005 ],
              [-74.82704013799992, 10.483678602000055],
              [-74.82690551199994, 10.483719250000092],
              [-74.82685337999993, 10.483731380000052],
              [-74.82667122999993, 10.483741130000055],
              [-74.8266295219999 , 10.483729505000042],
              [-74.82653219399992, 10.483729106000055],
              [-74.8264533919999 , 10.483719609000048],
              [-74.82627930899991, 10.483760067000048],
              [-74.8261887939999 , 10.483775681000054],
              [-74.8260077679999 , 10.48381156000005 ],
              [-74.82591718299994, 10.483854647000044],
              [-74.8258520789999 , 10.483904736000056],
              [-74.82578441099992, 10.483986813000058],
              [-74.82573546999993, 10.484050707000051],
              [-74.82569078699993, 10.484190082000055],
              [-74.82566512999995, 10.484233507000056],
              [-74.82558813999992, 10.48434986500007 ],
              [-74.82556696299991, 10.484416162000059],
              [-74.82559193999992, 10.484535237000046],
              [-74.8254640909999 , 10.48460567300009 ],
              [-74.8254107809999 , 10.484626048000052],
              [-74.82535272599995, 10.484637271000054],
              [-74.82529242399994, 10.484636987000044],
              [-74.82524375499992, 10.484636821000038],
              [-74.82524162099992, 10.484636811000087],
              [-74.82513479099993, 10.484636304000048],
              [-74.82504666699992, 10.48463595000004 ],
              [-74.82496086899994, 10.484626473000048],
              [-74.82486137599994, 10.484589418000041],
              [-74.8246761389999 , 10.484622916000092],
              [-74.82456990399993, 10.484815013000059],
              [-74.82442903099991, 10.485237107000046],
              [-74.8243552699999 , 10.485533665000048],
              [-74.8243234549999 , 10.485661580000055],
              [-74.82430924899995, 10.485801033000087],
              [-74.82421536999993, 10.485876334000068],
              [-74.8242085629999 , 10.485881794000079],
              [-74.82418072199994, 10.485904125000047],
              [-74.82418107199993, 10.485901979000062],
              [-74.82417984999995, 10.485902577000047],
              [-74.82412564899994, 10.485995217000038],
              [-74.82386142599995, 10.486446833000059],
              [-74.82377656599994, 10.48661474100004 ],
              [-74.82363492099995, 10.486991251000063],
              [-74.82339594099994, 10.487601993000055],
              [-74.82306977499991, 10.48840806000004 ],
              [-74.82303472699994, 10.488494674000037],
              [-74.82289019399991, 10.488851852000039],
              [-74.82289020499991, 10.488851879000038],
              [-74.82289023299995, 10.488851939000085],
              [-74.82275580399994, 10.489128253000047],
              [-74.82265933799994, 10.489291767000054],
              [-74.82248134999992, 10.489593458000058],
              [-74.82167502599992, 10.490960172000086],
              [-74.8207966409999 , 10.49317850500006 ],
              [-74.82043381599993, 10.494094810000092],
              [-74.82043362699994, 10.49409528600006 ],
              [-74.82028377699993, 10.494095645000073],
              [-74.82020523299991, 10.494095834000063],
              [-74.81912300499994, 10.494098429000076],
              [-74.81723668399991, 10.494102952000048],
              [-74.81586268799992, 10.493881612000052],
              [-74.8153285809999 , 10.493585904000042],
              [-74.81459351199993, 10.49289473600004 ],
              [-74.81355785999995, 10.491973303000066],
              [-74.81238824099995, 10.490788227000053],
              [-74.81068235799995, 10.488383604000092],
              [-74.80921048499994, 10.486242382000057]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08141",
      "properties": {"id": "08141", "iso": "CAN", "name": "CANDELARIA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.85869873199994, 10.56139317800006 ],
              [-74.85810048199994, 10.560673572000042],
              [-74.85805971199994, 10.560782171000085],
              [-74.85798164099992, 10.56095315500005 ],
              [-74.8579496829999 , 10.561023145000092],
              [-74.85766547299994, 10.56164558200004 ],
              [-74.85735516099993, 10.56237980800006 ],
              [-74.85708600999993, 10.562981713000056],
              [-74.85706958499992, 10.56302088700005 ],
              [-74.85704953099992, 10.563068718000068],
              [-74.85703748599991, 10.563097454000058],
              [-74.85658884299994, 10.564167490000045],
              [-74.8564904449999 , 10.564414845000044],
              [-74.85638943499993, 10.564668754000081],
              [-74.85636828999992, 10.564721907000092],
              [-74.85636723899995, 10.564724556000044],
              [-74.85636714899994, 10.564724770000055],
              [-74.85635060899995, 10.564645537000047],
              [-74.85633618299994, 10.56457643400006 ],
              [-74.85633340699991, 10.56456313700005 ],
              [-74.8561950749999 , 10.564277323000056],
              [-74.85603847199991, 10.564114428000039],
              [-74.85587001999994, 10.563936598000055],
              [-74.85569876499994, 10.56379097200005 ],
              [-74.85553901799994, 10.563619609000057],
              [-74.85537079599993, 10.563482167000075],
              [-74.8552603249999 , 10.563376233000042],
              [-74.85507467899993, 10.563233190000062],
              [-74.85494133899994, 10.563119400000062],
              [-74.8547976289999 , 10.562964665000038],
              [-74.85400844899993, 10.562381579000089],
              [-74.8538985319999 , 10.562267716000065],
              [-74.8537646119999 , 10.562152798000056],
              [-74.85347458199993, 10.561821867000049],
              [-74.85324797099992, 10.561517902000048],
              [-74.85314947199993, 10.56140401500005 ],
              [-74.8529576279999 , 10.561180767000053],
              [-74.85271950299995, 10.560951971000065],
              [-74.85255734599991, 10.56084050000004 ],
              [-74.85232822199993, 10.560663102000092],
              [-74.85197729499993, 10.560534572000051],
              [-74.85174536099993, 10.560489961000087],
              [-74.85157417799991, 10.56042427400007 ],
              [-74.85133945699994, 10.56029546700006 ],
              [-74.85114204299992, 10.560124213000051],
              [-74.85095346599991, 10.559955184000046],
              [-74.85080265599993, 10.559818264000057],
              [-74.85058690199992, 10.55962274600006 ],
              [-74.8502833519999 , 10.559484506000047],
              [-74.85012108899991, 10.55944422400006 ],
              [-74.84992977299993, 10.559433399000056],
              [-74.84959923999992, 10.559462457000052],
              [-74.8493267899999 , 10.559428106000041],
              [-74.84901418599992, 10.559320399000057],
              [-74.84876413899991, 10.559114228000055],
              [-74.8486021459999 , 10.558954164000056],
              [-74.84846817199991, 10.558816644000046],
              [-74.84835201999994, 10.55871974900009 ],
              [-74.84776544699992, 10.558199149000075],
              [-74.8474806179999 , 10.558010008000053],
              [-74.84717253199995, 10.557881378000047],
              [-74.84681875699994, 10.55775174200005 ],
              [-74.84665330099995, 10.557692265000071],
              [-74.84640665499995, 10.557586652000055],
              [-74.8462154899999 , 10.557521011000063],
              [-74.84599513699993, 10.557421557000055],
              [-74.84562087199993, 10.557090824000056],
              [-74.84550396499992, 10.556921053000053],
              [-74.84532990699995, 10.556731642000045],
              [-74.84519026399994, 10.556605429000058],
              [-74.84501349899995, 10.556357269000046],
              [-74.84491747299995, 10.55620495100004 ],
              [-74.84483020999994, 10.556016467000063],
              [-74.8447486149999 , 10.555809882000062],
              [-74.84467286099994, 10.55565525500009 ],
              [-74.8445479959999 , 10.55538606500005 ],
              [-74.84445823599992, 10.555226390000087],
              [-74.8443705869999 , 10.55511135100005 ],
              [-74.8442548299999 , 10.554945541000052],
              [-74.8441210169999 , 10.554865624000058],
              [-74.84396494399994, 10.554785783000057],
              [-74.84370352299993, 10.554650541000058],
              [-74.84348926799993, 10.554540606000046],
              [-74.84329188999993, 10.554386286000067],
              [-74.84317542399992, 10.55416284000006 ],
              [-74.84307966299991, 10.55388509700009 ],
              [-74.84290777099994, 10.55353296900006 ],
              [-74.84285545299991, 10.553395239000054],
              [-74.8427644599999 , 10.553194147000056],
              [-74.8426492449999 , 10.553012114000069],
              [-74.84250641199992, 10.552634491000049],
              [-74.84226851199992, 10.552371230000062],
              [-74.84212878599993, 10.55209641600004 ],
              [-74.84202993699995, 10.551837889000069],
              [-74.84197759199992, 10.551683212000057],
              [-74.84196787799993, 10.551619266000046],
              [-74.84193378099991, 10.551394845000061],
              [-74.84193057399995, 10.551373712000043],
              [-74.84191866099991, 10.551173737000056],
              [-74.84191362299993, 10.551127759000053],
              [-74.84185485299992, 10.551118942000073],
              [-74.84183623799993, 10.551116148000062],
              [-74.8416535149999 , 10.551020694000044],
              [-74.84159668599995, 10.550897689000067],
              [-74.8415899929999 , 10.55070429300008 ],
              [-74.8415875789999 , 10.550634555000045],
              [-74.84158570099993, 10.550351401000057],
              [-74.8414871889999 , 10.549966170000062],
              [-74.84134818399991, 10.549742605000063],
              [-74.84092043299995, 10.549319153000056],
              [-74.84066319399994, 10.549140196000053],
              [-74.84050223899993, 10.549017982000066],
              [-74.84011225599994, 10.548916347000045],
              [-74.83987681299993, 10.548896351000053],
              [-74.83959662599995, 10.548970068000074],
              [-74.83941596799991, 10.548970904000043],
              [-74.83902381399992, 10.548900563000075],
              [-74.83881853399993, 10.548780087000068],
              [-74.8386170039999 , 10.548691974000064],
              [-74.83841292599993, 10.548631317000059],
              [-74.83832264199992, 10.548652190000041],
              [-74.83827786099994, 10.548567639000055],
              [-74.83821567199993, 10.548566816000061],
              [-74.8380577719999 , 10.548668411000051],
              [-74.8379893579999 , 10.548582421000049],
              [-74.83805118899994, 10.548454573000072],
              [-74.83805140899995, 10.54831716700005 ],
              [-74.83801558399995, 10.54832084800006 ],
              [-74.8377057049999 , 10.548433750000072],
              [-74.83751474199994, 10.54845148700008 ],
              [-74.83728954999992, 10.54835894200005 ],
              [-74.83709280999994, 10.548373412000046],
              [-74.83706248599992, 10.548517823000054],
              [-74.83707550699995, 10.548679477000064],
              [-74.8369995729999 , 10.548750960000064],
              [-74.83683070699993, 10.548568216000092],
              [-74.83674231299995, 10.548469288000092],
              [-74.83655838899995, 10.548401162000062],
              [-74.83635198799993, 10.548201478000067],
              [-74.8358840379999 , 10.54791571900006 ],
              [-74.83557359199995, 10.547311574000048],
              [-74.83550066899994, 10.546797821000041],
              [-74.8355868399999 , 10.546123927000053],
              [-74.83586054599994, 10.54557860400007 ],
              [-74.83603474599994, 10.545187307000049],
              [-74.83616752599994, 10.544889045000048],
              [-74.8363525659999 , 10.544426231000045],
              [-74.83674844599994, 10.543379352000045],
              [-74.83679721699991, 10.54273250600005 ],
              [-74.83678543499991, 10.542193087000044],
              [-74.83672337299993, 10.541785146000052],
              [-74.83645919299994, 10.541260787000056],
              [-74.83639194499995, 10.541178339000055],
              [-74.83633415799994, 10.541101746000038],
              [-74.83623543599992, 10.541022290000058],
              [-74.83617164999993, 10.540949589000093],
              [-74.8361023619999 , 10.540870617000053],
              [-74.83598817299992, 10.540755001000036],
              [-74.83597046499995, 10.540676948000055],
              [-74.83594905599995, 10.540582585000038],
              [-74.83594352999995, 10.54030679400006 ],
              [-74.83601945799995, 10.540147500000046],
              [-74.83612388099993, 10.540051559000062],
              [-74.83624816999992, 10.539924648000067],
              [-74.83634327099992, 10.539872306000063],
              [-74.83645454299995, 10.539896953000039],
              [-74.83655848399991, 10.539897689000043],
              [-74.83660647399995, 10.53980111800007 ],
              [-74.83657849999992, 10.539685066000061],
              [-74.83657795599993, 10.53968189200009 ],
              [-74.83655921299993, 10.539572504000091],
              [-74.83654134499994, 10.539483459000053],
              [-74.83654412599992, 10.539216991000046],
              [-74.83661386099993, 10.53901631900004 ],
              [-74.83676408799994, 10.538894218000053],
              [-74.8369929619999 , 10.53884729500004 ],
              [-74.83714657199994, 10.538860043000057],
              [-74.8372788879999 , 10.538918465000052],
              [-74.83738335999993, 10.538910488000056],
              [-74.83750885299992, 10.538854050000054],
              [-74.83760253499992, 10.538778171000047],
              [-74.83771079099995, 10.538667900000064],
              [-74.83771211399994, 10.538665526000045],
              [-74.83775968499992, 10.538579976000051],
              [-74.83778157899991, 10.53854059400004 ],
              [-74.83774694999994, 10.538435832000062],
              [-74.83771591699991, 10.538366461000066],
              [-74.8377970439999 , 10.538307209000038],
              [-74.8379777049999 , 10.538268990000063],
              [-74.8380955049999 , 10.53823846000006 ],
              [-74.83808144299991, 10.538130916000057],
              [-74.83807030799994, 10.538059773000043],
              [-74.83804029899994, 10.537906809000049],
              [-74.83795259199991, 10.537751760000049],
              [-74.83794670599991, 10.537616609000054],
              [-74.83798527599993, 10.537572211000054],
              [-74.83801569099995, 10.537562123000043],
              [-74.8396034349999 , 10.53702041400004 ],
              [-74.8396316059999 , 10.536907757000051],
              [-74.83967174999992, 10.536683187000051],
              [-74.8397146609999 , 10.536443133000091],
              [-74.83970820499991, 10.536139182000056],
              [-74.83975452199991, 10.53593907700008 ],
              [-74.8399218699999 , 10.535731871000053],
              [-74.84003311799995, 10.535460791000048],
              [-74.84007226599994, 10.53536540500005 ],
              [-74.83994109899993, 10.53496401700005 ],
              [-74.83971789799995, 10.534752681000043],
              [-74.83959596999995, 10.534626420000052],
              [-74.83931683999992, 10.534192063000091],
              [-74.83923817999994, 10.533899589000043],
              [-74.8391712099999 , 10.533716698000092],
              [-74.83906680599995, 10.533515808000061],
              [-74.8389965739999 , 10.533284347000063],
              [-74.8388682719999 , 10.532656381000038],
              [-74.83884446299993, 10.532495987000061],
              [-74.83877465799992, 10.532204617000048],
              [-74.83873337099993, 10.531900764000056],
              [-74.83876244699991, 10.531654357000093],
              [-74.8387506009999 , 10.531482622000055],
              [-74.8387445109999 , 10.53132783600006 ],
              [-74.83868002999992, 10.53110990500005 ],
              [-74.83862756799994, 10.530908895000039],
              [-74.83854022499992, 10.530679727000063],
              [-74.83828810299991, 10.530548700000054],
              [-74.83799209099993, 10.530447252000045],
              [-74.83780398099992, 10.53033234700007 ],
              [-74.83758339299993, 10.530017618000045],
              [-74.83737959199993, 10.52956498900005 ],
              [-74.8373615189999 , 10.529477516000043],
              [-74.83734188299991, 10.52938245200005 ],
              [-74.83728020299992, 10.529083872000058],
              [-74.83723347699993, 10.528894139000045],
              [-74.83716397799992, 10.528722551000044],
              [-74.83693752199991, 10.52851829900004 ],
              [-74.83665874799993, 10.528283085000055],
              [-74.83662258399994, 10.528233399000044],
              [-74.8364433559999 , 10.528213848000064],
              [-74.83641344699993, 10.527888261000044],
              [-74.83643252099995, 10.527697527000043],
              [-74.83644106399993, 10.527482452000072],
              [-74.8364731819999 , 10.527272181000058],
              [-74.83650961299992, 10.527126169000042],
              [-74.83643997899992, 10.526900313000056],
              [-74.83631264999991, 10.526790049000056],
              [-74.83617801799994, 10.526708876000043],
              [-74.83609485499994, 10.52660187400005 ],
              [-74.83591931099994, 10.52647115800005 ],
              [-74.83573298599993, 10.526378050000062],
              [-74.83553155299995, 10.526292728000044],
              [-74.83523732299994, 10.526064518000055],
              [-74.83505377799992, 10.525840379000044],
              [-74.83496473799994, 10.525567454000054],
              [-74.8349385379999 , 10.525188054000068],
              [-74.83495483099995, 10.524997527000039],
              [-74.8349662679999 , 10.524863779000043],
              [-74.83498845499992, 10.524586194000051],
              [-74.83482139099993, 10.524218484000073],
              [-74.83460816599995, 10.523970030000044],
              [-74.83434663299994, 10.523788373000059],
              [-74.8341307199999 , 10.523580527000092],
              [-74.83395716099994, 10.523099463000051],
              [-74.83369515999993, 10.52262409900004 ],
              [-74.8333858499999 , 10.52236948500007 ],
              [-74.83300751699994, 10.522030169000061],
              [-74.83284736199994, 10.521695480000062],
              [-74.83266772099995, 10.521432204000064],
              [-74.83255559899993, 10.521226070000068],
              [-74.83248675399994, 10.521099511000045],
              [-74.8324193439999 , 10.520975572000054],
              [-74.83229764899994, 10.520675294000057],
              [-74.83220232999992, 10.52036137400006 ],
              [-74.83204143899991, 10.519997955000065],
              [-74.83197957899995, 10.519746972000064],
              [-74.83188726499992, 10.51947388900004 ],
              [-74.83180484499991, 10.519226550000042],
              [-74.83171896499994, 10.51897907600005 ],
              [-74.8316567469999 , 10.51881654500005 ],
              [-74.8314924039999 , 10.518630020000046],
              [-74.83132866499994, 10.518518938000057],
              [-74.83115292499991, 10.51828832800004 ],
              [-74.83112076399993, 10.518124140000054],
              [-74.83112284399994, 10.51792718300004 ],
              [-74.83114774499995, 10.517777928000044],
              [-74.83111976499993, 10.517637189000084],
              [-74.83097028799995, 10.51748937700006 ],
              [-74.83091556999995, 10.517367311000044],
              [-74.83089427699991, 10.517319805000056],
              [-74.83081692499991, 10.517147237000074],
              [-74.83079486499992, 10.516963427000064],
              [-74.83077517399994, 10.516829015000042],
              [-74.83068595799995, 10.516734047000057],
              [-74.83057941599992, 10.51663807600005 ],
              [-74.83043151599992, 10.51651077400004 ],
              [-74.83023628099994, 10.516438682000057],
              [-74.83004228999994, 10.51634931700005 ],
              [-74.82986587599993, 10.516258070000049],
              [-74.82975512999991, 10.516182196000045],
              [-74.82966958799994, 10.516105314000072],
              [-74.82953406599995, 10.515939398000057],
              [-74.82940658599995, 10.515870774000064],
              [-74.8291458679999 , 10.515716588000089],
              [-74.82897775499993, 10.515608511000039],
              [-74.82863487899994, 10.515242088000036],
              [-74.82848785699991, 10.51503028600007 ],
              [-74.82843206699994, 10.514949914000056],
              [-74.8283098839999 , 10.514731521000044],
              [-74.82818205699994, 10.514503036000065],
              [-74.8279379569999 , 10.51413750200004 ],
              [-74.8278337079999 , 10.513999899000055],
              [-74.82763314099992, 10.513690763000056],
              [-74.82746204799992, 10.513530711000044],
              [-74.82735158199995, 10.513415716000054],
              [-74.8272182899999 , 10.513307548000057],
              [-74.82697464499995, 10.513130735000061],
              [-74.82684706099991, 10.513015774000053],
              [-74.82670753499991, 10.51292458100005 ],
              [-74.82647543699994, 10.512798573000055],
              [-74.82618552399992, 10.512610554000048],
              [-74.82586629899993, 10.512341258000049],
              [-74.8255350689999 , 10.512067462000061],
              [-74.82521016499993, 10.511809464000066],
              [-74.82505392999991, 10.511655594000047],
              [-74.82476941999994, 10.511334789000045],
              [-74.82465327599994, 10.511232233000044],
              [-74.82435161299992, 10.51090639000006 ],
              [-74.82406708999991, 10.510586151000041],
              [-74.8238233319999 , 10.51035735000005 ],
              [-74.8235504239999 , 10.510116175000064],
              [-74.82330674699995, 10.509922394000057],
              [-74.82312720099992, 10.50980756100006 ],
              [-74.82299962799993, 10.50969938500009 ],
              [-74.82278981499991, 10.50953627000007 ],
              [-74.82248884599994, 10.509302285000047],
              [-74.8226317299999 , 10.508898903000045],
              [-74.82266380199991, 10.508774646000063],
              [-74.82268822599991, 10.508680023000068],
              [-74.82279569799994, 10.508263644000067],
              [-74.82280999499994, 10.508208256000046],
              [-74.82283916099993, 10.50809526100005 ],
              [-74.82284607999992, 10.508067434000054],
              [-74.82287171699994, 10.508072078000055],
              [-74.8228724999999 , 10.508072220000088],
              [-74.82287254399995, 10.50807222800006 ],
              [-74.82288767799992, 10.508074970000052],
              [-74.82289309099991, 10.50807595100008 ],
              [-74.82289575399994, 10.508076434000088],
              [-74.8229322279999 , 10.508083043000056],
              [-74.82309652499993, 10.508112813000082],
              [-74.8232463459999 , 10.507635107000056],
              [-74.82325436499991, 10.507609537000064],
              [-74.8232686209999 , 10.507571100000064],
              [-74.8233457629999 , 10.507363097000052],
              [-74.82344602799992, 10.507109469000056],
              [-74.82358373199992, 10.506761133000055],
              [-74.82359114099995, 10.506742392000092],
              [-74.82361495899994, 10.506682140000066],
              [-74.82361688899994, 10.506677259000071],
              [-74.82363175599994, 10.506639651000057],
              [-74.8236346999999 , 10.506632204000084],
              [-74.8236916919999 , 10.506504401000086],
              [-74.82370012799993, 10.506467805000057],
              [-74.82370637799994, 10.506440694000048],
              [-74.82376173299991, 10.506200555000078],
              [-74.82377236799994, 10.506154420000087],
              [-74.82377372999991, 10.506148511000049],
              [-74.82379651299993, 10.506049676000089],
              [-74.8237992729999 , 10.50603770400005 ],
              [-74.82381850799993, 10.505954257000042],
              [-74.82387805099995, 10.505695955000078],
              [-74.82389915499994, 10.50560439700007 ],
              [-74.82390469199993, 10.505580377000058],
              [-74.82379335899992, 10.505520372000092],
              [-74.8237818959999 , 10.505514194000057],
              [-74.82370321299993, 10.505471786000044],
              [-74.82368440999994, 10.505461652000065],
              [-74.82365751599991, 10.505447157000049],
              [-74.8236275189999 , 10.505430990000093],
              [-74.8236187309999 , 10.505426254000042],
              [-74.82357257299992, 10.505401377000055],
              [-74.82356627399992, 10.505400104000046],
              [-74.8235645389999 , 10.505399753000063],
              [-74.8235225059999 , 10.505391259000078],
              [-74.82352171499991, 10.505391099000065],
              [-74.82345822999991, 10.505378270000051],
              [-74.82345666799995, 10.50537741100004 ],
              [-74.82345093499993, 10.505374259000064],
              [-74.82342352799992, 10.505359190000092],
              [-74.82340056899994, 10.50534656700006 ],
              [-74.82329080499994, 10.505293349000056],
              [-74.82329043699991, 10.505293170000073],
              [-74.8232645569999 , 10.505280622000043],
              [-74.8231805719999 , 10.505239902000085],
              [-74.82301215199993, 10.50578056200004 ],
              [-74.82300413799993, 10.505806292000045],
              [-74.82299767399991, 10.505827043000068],
              [-74.82299608699992, 10.505830881000065],
              [-74.8229944119999 , 10.505834932000084],
              [-74.82297298199995, 10.505886773000043],
              [-74.82296644099995, 10.505881320000071],
              [-74.82289443899992, 10.505821292000064],
              [-74.82284924699991, 10.505783615000041],
              [-74.82282549399991, 10.505763813000044],
              [-74.82274859899991, 10.505699706000087],
              [-74.8224606309999 , 10.505410361000088],
              [-74.82245173899992, 10.505401427000038],
              [-74.8223898299999 , 10.505339222000089],
              [-74.82146154599991, 10.504467770000076],
              [-74.82144629099992, 10.504453449000039],
              [-74.8213784479999 , 10.504389760000038],
              [-74.82071635299991, 10.503768200000081],
              [-74.82044163199993, 10.503519459000074],
              [-74.81982793799995, 10.502963795000085],
              [-74.81974556599994, 10.50288720900005 ],
              [-74.81890618599994, 10.502106772000047],
              [-74.81851167099995, 10.501717289000055],
              [-74.8183740739999 , 10.50158293000004 ],
              [-74.81819364799992, 10.501406750000058],
              [-74.8180808759999 , 10.50129663100006 ],
              [-74.81784022499994, 10.50104540500007 ],
              [-74.81770355899994, 10.50075036000004 ],
              [-74.8176826799999 , 10.50067886000005 ],
              [-74.81761819099995, 10.500449142000093],
              [-74.8175807529999 , 10.500315783000076],
              [-74.81755933799991, 10.500142082000082],
              [-74.81755706899992, 10.500073998000062],
              [-74.81755695999993, 10.500070698000059],
              [-74.81759027099991, 10.49990889500009 ],
              [-74.81761408699992, 10.499838701000044],
              [-74.8176163899999 , 10.499831915000073],
              [-74.8176806919999 , 10.49964239600007 ],
              [-74.81774500699993, 10.499422844000037],
              [-74.81776559999992, 10.499363695000056],
              [-74.81776695099995, 10.499359814000059],
              [-74.8177999689999 , 10.499264984000092],
              [-74.81780457299993, 10.49925176000005 ],
              [-74.81824428099992, 10.49823224000005 ],
              [-74.81834092699995, 10.498032046000048],
              [-74.81834375299991, 10.498026191000065],
              [-74.81857818599991, 10.497540582000056],
              [-74.81863120699995, 10.49743075200007 ],
              [-74.81880048399995, 10.497025191000091],
              [-74.81882898299995, 10.49695691100004 ],
              [-74.8188496329999 , 10.496907437000061],
              [-74.81885485899994, 10.496894917000077],
              [-74.81887309899992, 10.496851217000085],
              [-74.81890012899993, 10.496786460000067],
              [-74.81890548199993, 10.496773636000057],
              [-74.81890563699994, 10.496773264000069],
              [-74.81896485499993, 10.49679245200008 ],
              [-74.8189653359999 , 10.496792608000078],
              [-74.81897261299991, 10.496794966000039],
              [-74.81899748399991, 10.496829801000047],
              [-74.81900328699993, 10.496837929000037],
              [-74.81902683399994, 10.496870909000052],
              [-74.81904328999991, 10.49687792800006 ],
              [-74.81921042699992, 10.49694921400004 ],
              [-74.81928973799995, 10.496983041000078],
              [-74.82018353899991, 10.49736426000004 ],
              [-74.82019109899994, 10.497367484000051],
              [-74.82045200499994, 10.496562621000066],
              [-74.82010807299991, 10.496421166000061],
              [-74.81937213399993, 10.496118485000068],
              [-74.81929509899993, 10.496086801000047],
              [-74.81926977699993, 10.496076386000084],
              [-74.81926708899994, 10.49607528100006 ],
              [-74.8192597019999 , 10.496072014000049],
              [-74.81921009899992, 10.496050077000064],
              [-74.81926102599994, 10.49592922100004 ],
              [-74.81930112899994, 10.495834049000052],
              [-74.81932163399995, 10.49578538500009 ],
              [-74.81936673799993, 10.495678344000055],
              [-74.81951667599992, 10.49537758300005 ],
              [-74.8196064739999 , 10.495197457000074],
              [-74.81960779099995, 10.495194815000048],
              [-74.81965191099994, 10.495106314000054],
              [-74.8196622609999 , 10.49510928300009 ],
              [-74.81977806399993, 10.495142499000053],
              [-74.81982006899995, 10.49508400700006 ],
              [-74.8198943189999 , 10.494980613000052],
              [-74.81999741999994, 10.494837042000086],
              [-74.82001768299995, 10.494808824000074],
              [-74.8201356319999 , 10.494638173000055],
              [-74.82043362699994, 10.49409528600006 ],
              [-74.82043381599993, 10.494094810000092],
              [-74.8207966409999 , 10.49317850500006 ],
              [-74.82167502599992, 10.490960172000086],
              [-74.82248134999992, 10.489593458000058],
              [-74.82265933799994, 10.489291767000054],
              [-74.82275580399994, 10.489128253000047],
              [-74.82289023299995, 10.488851939000085],
              [-74.82289020499991, 10.488851879000038],
              [-74.82289019399991, 10.488851852000039],
              [-74.82303472699994, 10.488494674000037],
              [-74.82306977499991, 10.48840806000004 ],
              [-74.82339594099994, 10.487601993000055],
              [-74.82363492099995, 10.486991251000063],
              [-74.82377656599994, 10.48661474100004 ],
              [-74.82386142599995, 10.486446833000059],
              [-74.82412564899994, 10.485995217000038],
              [-74.82417984999995, 10.485902577000047],
              [-74.82418107199993, 10.485901979000062],
              [-74.82418072199994, 10.485904125000047],
              [-74.8242085629999 , 10.485881794000079],
              [-74.82421536999993, 10.485876334000068],
              [-74.82430924899995, 10.485801033000087],
              [-74.8243234549999 , 10.485661580000055],
              [-74.8243552699999 , 10.485533665000048],
              [-74.82442903099991, 10.485237107000046],
              [-74.82456990399993, 10.484815013000059],
              [-74.8246761389999 , 10.484622916000092],
              [-74.82486137599994, 10.484589418000041],
              [-74.82496086899994, 10.484626473000048],
              [-74.82504666699992, 10.48463595000004 ],
              [-74.82513479099993, 10.484636304000048],
              [-74.82524162099992, 10.484636811000087],
              [-74.82524375499992, 10.484636821000038],
              [-74.82529242399994, 10.484636987000044],
              [-74.82535272599995, 10.484637271000054],
              [-74.8254107809999 , 10.484626048000052],
              [-74.8254640909999 , 10.48460567300009 ],
              [-74.82559193999992, 10.484535237000046],
              [-74.82556696299991, 10.484416162000059],
              [-74.82558813999992, 10.48434986500007 ],
              [-74.82566512999995, 10.484233507000056],
              [-74.82569078699993, 10.484190082000055],
              [-74.82573546999993, 10.484050707000051],
              [-74.82578441099992, 10.483986813000058],
              [-74.8258520789999 , 10.483904736000056],
              [-74.82591718299994, 10.483854647000044],
              [-74.8260077679999 , 10.48381156000005 ],
              [-74.8261887939999 , 10.483775681000054],
              [-74.82627930899991, 10.483760067000048],
              [-74.8264533919999 , 10.483719609000048],
              [-74.82653219399992, 10.483729106000055],
              [-74.8266295219999 , 10.483729505000042],
              [-74.82667122999993, 10.483741130000055],
              [-74.82685337999993, 10.483731380000052],
              [-74.82690551199994, 10.483719250000092],
              [-74.82704013799992, 10.483678602000055],
              [-74.82720734399993, 10.48362671700005 ],
              [-74.82736876299992, 10.483540382000058],
              [-74.82739097599995, 10.483522184000037],
              [-74.82749355499993, 10.483387608000044],
              [-74.82758240899994, 10.483218605000047],
              [-74.82764693699994, 10.483049527000048],
              [-74.8276668499999 , 10.482992411000055],
              [-74.8277247979999 , 10.482820942000046],
              [-74.8277560599999 , 10.482734209000057],
              [-74.82781470399993, 10.482581097000093],
              [-74.82785949999993, 10.482398225000054],
              [-74.82785314199992, 10.482249434000039],
              [-74.82782348399991, 10.48214173200006 ],
              [-74.8277446649999 , 10.481978497000057],
              [-74.8276948059999 , 10.481871194000064],
              [-74.82764681299994, 10.481706186000054],
              [-74.82760568599991, 10.481585033000044],
              [-74.82758846099995, 10.481534293000038],
              [-74.82758499099992, 10.481516010000064],
              [-74.8275964629999 , 10.48133666800004 ],
              [-74.82760696699995, 10.481252956000048],
              [-74.82761794299995, 10.481071633000056],
              [-74.82763129499995, 10.48098755600006 ],
              [-74.82769921399995, 10.480834567000045],
              [-74.8278239839999 , 10.480703194000057],
              [-74.8278948279999 , 10.480638544000044],
              [-74.82802152699992, 10.480508160000056],
              [-74.82805319999994, 10.48047220500007 ],
              [-74.8281635969999 , 10.480328364000059],
              [-74.82839267299994, 10.480014181000058],
              [-74.82845669199992, 10.479931471000043],
              [-74.82857023499992, 10.479789951000043],
              [-74.8286081629999 , 10.47974677700006 ],
              [-74.8287372129999 , 10.479616811000085],
              [-74.82880848799994, 10.47952334200005 ],
              [-74.82883654099993, 10.479468549000046],
              [-74.82891575499991, 10.479386503000057],
              [-74.82899490999995, 10.479304458000058],
              [-74.82900207499995, 10.479256422000049],
              [-74.82905385899994, 10.479105441000058],
              [-74.82909565999995, 10.479069112000047],
              [-74.82918505999993, 10.478916072000061],
              [-74.8292290739999 , 10.478734041000052],
              [-74.82924594699995, 10.47863039300006 ],
              [-74.82927147799995, 10.478451163000045],
              [-74.82927691299994, 10.478424589000042],
              [-74.82932664799995, 10.478250527000057],
              [-74.82933342399991, 10.478218800000093],
              [-74.82935013399992, 10.47810449800005 ],
              [-74.82941053399992, 10.477990633000047],
              [-74.82945533299994, 10.477908119000062],
              [-74.82949046899995, 10.47783275100005 ],
              [-74.82957913699994, 10.477674062000062],
              [-74.82961669399992, 10.47759529700005 ],
              [-74.82965858199992, 10.477419350000048],
              [-74.82966391399992, 10.477384939000046],
              [-74.82968389099995, 10.477205088000062],
              [-74.8296928499999 , 10.477133380000055],
              [-74.82972788399991, 10.47695602400006 ],
              [-74.8297520239999 , 10.476847492000047],
              [-74.82979282899993, 10.476670909000063],
              [-74.8298086129999 , 10.476618891000044],
              [-74.82988249899995, 10.476453667000044],
              [-74.82991160599994, 10.476392793000059],
              [-74.82998527699993, 10.476227290000054],
              [-74.83001927899994, 10.47615524300005 ],
              [-74.8301151149999 , 10.47598170200007 ],
              [-74.83020070899994, 10.475821393000047],
              [-74.8302505979999 , 10.475739703000045],
              [-74.83057058299994, 10.475397369000063],
              [-74.83066572299992, 10.475288136000074],
              [-74.83078240299994, 10.47514915100004 ],
              [-74.83081207399994, 10.475115039000059],
              [-74.83094206699991, 10.474988032000056],
              [-74.83104798699992, 10.474884474000078],
              [-74.83107204799995, 10.47486094900006 ],
              [-74.83112906899993, 10.474805162000052],
              [-74.83221081399995, 10.473396815000058],
              [-74.83225575099993, 10.473239423000052],
              [-74.8326144159999 , 10.47272305100006 ],
              [-74.83281413999993, 10.47243551300005 ],
              [-74.83285451799992, 10.472377382000047],
              [-74.83337873699992, 10.471695028000056],
              [-74.83381183599994, 10.471101607000037],
              [-74.83445914899994, 10.470427777000054],
              [-74.83493043499993, 10.470129402000055],
              [-74.83514132699992, 10.469970833000048],
              [-74.83526890399992, 10.469705626000064],
              [-74.83615166999994, 10.46740784800005 ],
              [-74.83644984599994, 10.466631716000052],
              [-74.8367623179999 , 10.466142318000038],
              [-74.83677769399992, 10.466118599000083],
              [-74.83704900399994, 10.46570010000005 ],
              [-74.83722448599991, 10.465419017000045],
              [-74.83745602099992, 10.465038924000055],
              [-74.83769158899992, 10.46464710400005 ],
              [-74.83787480299992, 10.464115074000063],
              [-74.8382021459999 , 10.463379169000063],
              [-74.83857267199994, 10.46273326700009 ],
              [-74.8401302549999 , 10.46325173300005 ],
              [-74.84015664899994, 10.46320772100006 ],
              [-74.84050998399994, 10.462914340000054],
              [-74.8407550469999 , 10.462526808000064],
              [-74.8411363699999 , 10.462183928000059],
              [-74.84152085399995, 10.461969713000087],
              [-74.8419851829999 , 10.46177960500006 ],
              [-74.84262764599993, 10.46163701100005 ],
              [-74.84290710999994, 10.461457167000049],
              [-74.8431472279999 , 10.461115756000083],
              [-74.84351392399992, 10.460488500000054],
              [-74.84374068799991, 10.460000515000047],
              [-74.8438451529999 , 10.459628211000052],
              [-74.8441230549999 , 10.458928856000057],
              [-74.84431729999994, 10.458653803000061],
              [-74.84465815099992, 10.458393152000042],
              [-74.84527339199991, 10.457780416000048],
              [-74.84582927799994, 10.457082926000055],
              [-74.84608422699995, 10.45665580900004 ],
              [-74.84616991999991, 10.456279743000039],
              [-74.84621208599992, 10.45602271100006 ],
              [-74.84627765899995, 10.455877354000052],
              [-74.84641406599991, 10.455441629000063],
              [-74.84656327099992, 10.454927129000055],
              [-74.84662166999993, 10.454508470000064],
              [-74.84683972699992, 10.454140488000064],
              [-74.84715343199991, 10.453411946000074],
              [-74.84729474999995, 10.453196692000063],
              [-74.84735722499994, 10.452927327000054],
              [-74.8474966149999 , 10.451969546000043],
              [-74.84751966199991, 10.45110872500004 ],
              [-74.84750253899995, 10.45058024900004 ],
              [-74.84747083399992, 10.45001896000008 ],
              [-74.84745124699992, 10.449672201000055],
              [-74.84744413999994, 10.449571652000088],
              [-74.84735268499992, 10.449115779000067],
              [-74.84756469699994, 10.448499778000041],
              [-74.84784267899994, 10.447694631000047],
              [-74.84797787899993, 10.447158844000057],
              [-74.84810776799992, 10.44664410200005 ],
              [-74.84823749799995, 10.445732734000046],
              [-74.84832326299994, 10.44535514100005 ],
              [-74.84890237499991, 10.445381212000086],
              [-74.84985439399992, 10.445490903000064],
              [-74.85068098799991, 10.44578739800005 ],
              [-74.85145623099993, 10.446135895000054],
              [-74.85181326199995, 10.446260588000086],
              [-74.85181396099995, 10.446268015000044],
              [-74.85201953399991, 10.446295298000052],
              [-74.8520464959999 , 10.446298876000071],
              [-74.85223698499993, 10.446324158000039],
              [-74.85254258299994, 10.446364716000062],
              [-74.85264060499992, 10.446377726000037],
              [-74.85262238099995, 10.446249266000052],
              [-74.8526022179999 , 10.446107151000092],
              [-74.85260073299992, 10.44609667800006 ],
              [-74.85259593899991, 10.446059124000044],
              [-74.85254819499994, 10.445685144000038],
              [-74.85249045699993, 10.445365783000057],
              [-74.8524379669999 , 10.445115129000044],
              [-74.8523463919999 , 10.444817889000092],
              [-74.8522085429999 , 10.444414607000056],
              [-74.85220544799995, 10.444407488000081],
              [-74.85211339099993, 10.44419569900009 ],
              [-74.8520068979999 , 10.443981893000057],
              [-74.85190411699995, 10.443789263000042],
              [-74.85188339099994, 10.443750417000047],
              [-74.85181103499991, 10.443629391000059],
              [-74.85170751399994, 10.443376399000044],
              [-74.85165995599993, 10.443187590000036],
              [-74.85160950499994, 10.442985168000064],
              [-74.85157767899994, 10.442784184000061],
              [-74.85156017699995, 10.442430899000044],
              [-74.85154919299993, 10.44217625300007 ],
              [-74.85153382399994, 10.441820564000068],
              [-74.85152969799992, 10.441510319000088],
              [-74.85154383699995, 10.441086762000054],
              [-74.8515564349999 , 10.440787359000069],
              [-74.85156987199991, 10.440455671000052],
              [-74.85152200899995, 10.44025776300009 ],
              [-74.85145025299994, 10.440097617000049],
              [-74.85133428099994, 10.439826708000055],
              [-74.85123174799992, 10.439649551000059],
              [-74.85108383999994, 10.439389237000057],
              [-74.85096624699992, 10.439240235000057],
              [-74.85089207599992, 10.439025014000038],
              [-74.85083082799991, 10.438839142000063],
              [-74.85081578899991, 10.43875930300004 ],
              [-74.85077561599991, 10.438471393000043],
              [-74.85078885899992, 10.438278083000057],
              [-74.85089802899995, 10.437712798000064],
              [-74.85090524399993, 10.437665757000048],
              [-74.85093880199992, 10.437475774000063],
              [-74.8510138069999 , 10.437281724000059],
              [-74.85110668399994, 10.43701793300005 ],
              [-74.85118902799991, 10.436873498000068],
              [-74.85127461399992, 10.436723375000042],
              [-74.85134156299995, 10.436504351000053],
              [-74.85147513699991, 10.436110085000053],
              [-74.85160161899995, 10.435874743000056],
              [-74.85167624499991, 10.43567151600007 ],
              [-74.85172344099993, 10.435315458000048],
              [-74.85173984499994, 10.434997545000044],
              [-74.8517336729999 , 10.434639140000058],
              [-74.85169525499992, 10.434339653000052],
              [-74.85163911299992, 10.43407429000007 ],
              [-74.85162536399991, 10.43400930000007 ],
              [-74.8515577089999 , 10.43351326800007 ],
              [-74.85151511999993, 10.433170284000084],
              [-74.85151918299994, 10.432942506000074],
              [-74.85156704899993, 10.432763426000065],
              [-74.8516302189999 , 10.43191925800005 ],
              [-74.85166174699992, 10.431630537000046],
              [-74.85164948599993, 10.431346027000075],
              [-74.85171948499993, 10.43103489400005 ],
              [-74.85176828199991, 10.430662736000045],
              [-74.85178627499994, 10.430571203000056],
              [-74.85181386699992, 10.430430844000057],
              [-74.85185494899991, 10.430256654000061],
              [-74.85191893699994, 10.430021669000041],
              [-74.85201226799995, 10.429714233000084],
              [-74.85205069599994, 10.429530941000053],
              [-74.85210790499991, 10.42935374700005 ],
              [-74.85223012299991, 10.429127802000039],
              [-74.8522550209999 , 10.428951963000088],
              [-74.85227853499993, 10.42879928800005 ],
              [-74.85235254999992, 10.428582296000059],
              [-74.85246411499992, 10.428377496000053],
              [-74.85257880899991, 10.42813575200006 ],
              [-74.8526997319999 , 10.42793283800006 ],
              [-74.85285508699991, 10.427721507000058],
              [-74.85295242699993, 10.427616183000055],
              [-74.85304071599995, 10.427515821000043],
              [-74.8531048179999 , 10.427444052000055],
              [-74.85315492399991, 10.427307978000044],
              [-74.8531779029999 , 10.427284898000039],
              [-74.85326758999992, 10.427199358000053],
              [-74.85330423999994, 10.42713486200006 ],
              [-74.8534726179999 , 10.427051049000056],
              [-74.85354180099995, 10.427013872000089],
              [-74.85357863799993, 10.426995203000047],
              [-74.85381420399995, 10.426931567000054],
              [-74.85393641299993, 10.426873361000048],
              [-74.85406106499994, 10.426821988000086],
              [-74.85415335999994, 10.426784618000056],
              [-74.8542871219999 , 10.42672404800004 ],
              [-74.8544141889999 , 10.426697883000088],
              [-74.85452043799995, 10.426674182000056],
              [-74.8545943769999 , 10.426657611000053],
              [-74.85468919399995, 10.426656955000055],
              [-74.85477258799995, 10.42668387000009 ],
              [-74.85480962199995, 10.426683642000057],
              [-74.85488383699993, 10.426719828000046],
              [-74.85494421399994, 10.42675612000005 ],
              [-74.8549838049999 , 10.42681090800005 ],
              [-74.85504656699993, 10.426860960000056],
              [-74.85509543799992, 10.426917979000052],
              [-74.85516535999994, 10.427004672000066],
              [-74.85522351199995, 10.427063922000059],
              [-74.8552656729999 , 10.427150827000048],
              [-74.8553298779999 , 10.427428786000064],
              [-74.85535586599991, 10.427494332000038],
              [-74.85537675899991, 10.42754859200005 ],
              [-74.85538725299995, 10.427578011000037],
              [-74.85550429499995, 10.427821237000046],
              [-74.85557186299991, 10.427937885000063],
              [-74.85567477599994, 10.42812202400006 ],
              [-74.8557286649999 , 10.428190965000056],
              [-74.85582124299992, 10.428350200000068],
              [-74.85592631199995, 10.428423818000056],
              [-74.85601350899992, 10.428459132000057],
              [-74.85615725699995, 10.428567887000042],
              [-74.85624902199993, 10.428603045000045],
              [-74.85627752399995, 10.428617881000037],
              [-74.85638799199995, 10.42865680500006 ],
              [-74.85647814599992, 10.428705807000085],
              [-74.8565984949999 , 10.428758125000058],
              [-74.85671861399993, 10.428803524000045],
              [-74.85682390699992, 10.428828904000056],
              [-74.85697041399993, 10.428843305000044],
              [-74.85709616399993, 10.428858536000064],
              [-74.8571890689999 , 10.428863741000043],
              [-74.85728197799995, 10.428871213000093],
              [-74.85739716299992, 10.428857081000046],
              [-74.85750254399994, 10.428829567000037],
              [-74.85760852299995, 10.42881581800009 ],
              [-74.85769991899991, 10.428786566000042],
              [-74.85779639999993, 10.428768602000048],
              [-74.8578645309999 , 10.428738060000057],
              [-74.85794824699991, 10.428690820000043],
              [-74.85803948599994, 10.428659311000047],
              [-74.85810722999992, 10.428619594000054],
              [-74.85814517099993, 10.428585748000046],
              [-74.85826681899994, 10.428559883000048],
              [-74.85888685799995, 10.429136750000055],
              [-74.85929444499993, 10.42951596100005 ],
              [-74.86170117199993, 10.431755076000059],
              [-74.86201727799994, 10.432049168000049],
              [-74.86226914299993, 10.432283488000053],
              [-74.86226787299995, 10.43228603600005 ],
              [-74.86236679899991, 10.432331977000047],
              [-74.86244262899993, 10.43220780200005 ],
              [-74.8625774649999 , 10.432004280000058],
              [-74.86271187699992, 10.431884491000062],
              [-74.86280530099992, 10.431801233000044],
              [-74.86292981799994, 10.43169025900005 ],
              [-74.8630871499999 , 10.431524831000047],
              [-74.86323848199993, 10.431329813000048],
              [-74.86352332999991, 10.43100720600006 ],
              [-74.86369593599994, 10.430820408000045],
              [-74.8638863409999 , 10.430616822000047],
              [-74.86409012199994, 10.430453951000061],
              [-74.86422440699994, 10.430347047000055],
              [-74.86471074899993, 10.429869308000093],
              [-74.86528423399994, 10.429305968000051],
              [-74.86595232699995, 10.428525406000063],
              [-74.86614151499992, 10.428294204000053],
              [-74.8662116239999 , 10.428201587000046],
              [-74.86635201099995, 10.427912741000057],
              [-74.86645559099992, 10.427682957000059],
              [-74.86652829799993, 10.427489063000053],
              [-74.86653211299995, 10.427362421000055],
              [-74.86683856299993, 10.42703453100006 ],
              [-74.86688861599993, 10.426882707000061],
              [-74.86697754499994, 10.426670974000046],
              [-74.86708215399995, 10.426369642000054],
              [-74.86711557899991, 10.426158255000075],
              [-74.86714492899995, 10.426029572000061],
              [-74.86719942999991, 10.425857120000046],
              [-74.86724699399991, 10.425682343000062],
              [-74.86732704499991, 10.425541821000081],
              [-74.86740058499993, 10.425451807000059],
              [-74.8675041759999 , 10.425368421000087],
              [-74.8676493339999 , 10.425273361000052],
              [-74.86780603299991, 10.425171272000057],
              [-74.86793500999994, 10.42508309100009 ],
              [-74.8681194909999 , 10.42499230900006 ],
              [-74.8682440739999 , 10.424943263000046],
              [-74.86832492399992, 10.424924346000068],
              [-74.86863005399994, 10.424892301000057],
              [-74.86903456899995, 10.424861916000054],
              [-74.86939984499992, 10.424831831000063],
              [-74.86963562499994, 10.424800307000055],
              [-74.86983420999991, 10.424757587000045],
              [-74.87007477199995, 10.424753596000073],
              [-74.87025951099992, 10.424710963000052],
              [-74.87038181699995, 10.424664195000048],
              [-74.87053421099995, 10.424610352000059],
              [-74.87085732699995, 10.424500219000038],
              [-74.87097950599991, 10.424428164000062],
              [-74.87104633599995, 10.424393319000046],
              [-74.87107632099992, 10.424374739000086],
              [-74.87126317999991, 10.424295384000061],
              [-74.87136250299994, 10.424271687000044],
              [-74.87142024999991, 10.424257566000051],
              [-74.8714894339999 , 10.424220312000045],
              [-74.87170126399991, 10.42405819600009 ],
              [-74.87187851099992, 10.423928441000044],
              [-74.87188988899993, 10.423889357000064],
              [-74.87196163899995, 10.423819540000068],
              [-74.87255361799993, 10.423059351000063],
              [-74.87256255999995, 10.423040121000042],
              [-74.87273217699993, 10.422775075000061],
              [-74.87288788299992, 10.422509980000086],
              [-74.87308243599995, 10.422173611000062],
              [-74.87321322999992, 10.421986839000056],
              [-74.8732823009999 , 10.42193582100009 ],
              [-74.87346698599993, 10.421872573000087],
              [-74.87366788899993, 10.421816069000045],
              [-74.87391479999991, 10.421731686000044],
              [-74.8740924789999 , 10.42166160700009 ],
              [-74.87420816199995, 10.42167459600006 ],
              [-74.87437187199993, 10.421583848000068],
              [-74.8744549999999 , 10.42156267100006 ],
              [-74.87464923199991, 10.421547493000048],
              [-74.87487779899993, 10.42148392100006 ],
              [-74.87509514599992, 10.421473204000051],
              [-74.87521081799991, 10.42148386200006 ],
              [-74.87556278399995, 10.421550143000047],
              [-74.87566703999994, 10.42158844700009 ],
              [-74.87581521299995, 10.421612639000045],
              [-74.87595894799995, 10.42166207300005 ],
              [-74.87620484899992, 10.421793448000074],
              [-74.87639057599995, 10.421911413000089],
              [-74.87654629699995, 10.422045707000052],
              [-74.87672524499993, 10.422191302000044],
              [-74.87696416999995, 10.422299739000039],
              [-74.87706175099993, 10.42237704200005 ],
              [-74.87711050799993, 10.422418011000047],
              [-74.87724736299992, 10.42247898000005 ],
              [-74.8774515689999 , 10.422599156000047],
              [-74.87771136899994, 10.422725835000051],
              [-74.87804714099991, 10.422794556000042],
              [-74.8782623699999 , 10.422818232000054],
              [-74.87859714099994, 10.422717171000045],
              [-74.8787700389999 , 10.422619559000054],
              [-74.87902594799993, 10.422484662000045],
              [-74.87917599499991, 10.42243082400006 ],
              [-74.8792266339999 , 10.422400612000047],
              [-74.87934386799992, 10.422269020000044],
              [-74.87950884199995, 10.422001649000038],
              [-74.87972647299995, 10.421655892000047],
              [-74.88001323799995, 10.421256854000092],
              [-74.88022455399994, 10.421007510000038],
              [-74.88036895099992, 10.42078624000004 ],
              [-74.8804952619999 , 10.420633873000043],
              [-74.88065385699991, 10.420469831000048],
              [-74.88087422899991, 10.420190597000044],
              [-74.88098634099993, 10.419983248000051],
              [-74.88108929499992, 10.41979208500004 ],
              [-74.8812523759999 , 10.419595837000088],
              [-74.88139920099991, 10.419404364000059],
              [-74.88153229199992, 10.419224435000046],
              [-74.88175426099991, 10.418818988000055],
              [-74.88189903799991, 10.418664170000056],
              [-74.88227685699991, 10.418025838000062],
              [-74.88235117099993, 10.418071200000043],
              [-74.88243698299993, 10.418121128000053],
              [-74.88256918799993, 10.418179829000053],
              [-74.8827478899999 , 10.418286373000058],
              [-74.8828662709999 , 10.418352105000054],
              [-74.88300766399993, 10.418403863000037],
              [-74.88314659299994, 10.418441858000051],
              [-74.88330894199993, 10.41851411500005 ],
              [-74.8834526999999 , 10.41857280000005 ],
              [-74.88373527599992, 10.418651105000038],
              [-74.8838604629999 , 10.418698393000057],
              [-74.88405962299993, 10.418740533000062],
              [-74.88407352899992, 10.418740426000056],
              [-74.88410820099995, 10.418740205000063],
              [-74.8841936099999 , 10.418718942000055],
              [-74.88439022299991, 10.41871752500009 ],
              [-74.88472085699993, 10.418703693000054],
              [-74.88502800299995, 10.41862578200005 ],
              [-74.8851757679999 , 10.418578798000055],
              [-74.88524486799992, 10.418539292000048],
              [-74.8854111789999 , 10.41848766600009 ],
              [-74.88563484299993, 10.41838277700009 ],
              [-74.88584011299992, 10.418280353000057],
              [-74.88598764999995, 10.418196711000064],
              [-74.88627999899995, 10.41797428700005 ],
              [-74.88647783099992, 10.417784724000057],
              [-74.88665013499991, 10.417597621000084],
              [-74.8868804299999 , 10.41743305700004 ],
              [-74.88724933899994, 10.41724227800006 ],
              [-74.88742438199995, 10.41712169100009 ],
              [-74.88768950899993, 10.41696607700004 ],
              [-74.88781183999993, 10.416928477000056],
              [-74.88795271999993, 10.416895274000069],
              [-74.88806840099994, 10.41691059100009 ],
              [-74.88832528199993, 10.416929352000068],
              [-74.88847787899994, 10.416928257000052],
              [-74.88865376799993, 10.416940798000041],
              [-74.88883195599993, 10.41695786300005 ],
              [-74.88896620399993, 10.416979841000057],
              [-74.88910762699993, 10.41704310700004 ],
              [-74.88931656699992, 10.417172376000053],
              [-74.88946321799995, 10.41732737600006 ],
              [-74.8896049029999 , 10.41743421600006 ],
              [-74.8897701759999 , 10.417618899000047],
              [-74.89018052499995, 10.417397925000046],
              [-74.89018940699992, 10.417326785000057],
              [-74.89025795499992, 10.417188620000047],
              [-74.89028053699991, 10.417094349000081],
              [-74.89031203999991, 10.416944967000063],
              [-74.89032772899992, 10.416859972000054],
              [-74.89032622399992, 10.416612160000057],
              [-74.89031590399992, 10.416430966000064],
              [-74.89034515499992, 10.416295295000054],
              [-74.89041808099995, 10.41610894300004 ],
              [-74.89041721699994, 10.415966713000046],
              [-74.89042625999991, 10.41593215100005 ],
              [-74.89043650999992, 10.41570726000009 ],
              [-74.89044478099993, 10.41554198700004 ],
              [-74.8904298249999 , 10.415360803000056],
              [-74.89041953199995, 10.415188791000048],
              [-74.89041874299994, 10.415051085000073],
              [-74.89045136899995, 10.414704379000057],
              [-74.89045915899993, 10.414463399000056],
              [-74.89048384199992, 10.41432780200006 ],
              [-74.89052952799995, 10.413845611000056],
              [-74.8905675659999 , 10.413634211000044],
              [-74.8905863889999 , 10.413299059000053],
              [-74.8905934949999 , 10.412936465000087],
              [-74.89064082899995, 10.412727300000086],
              [-74.89070669999995, 10.412520348000044],
              [-74.89079093999993, 10.41229710400006 ],
              [-74.89090869899991, 10.411876307000057],
              [-74.89099530199991, 10.411655462000056],
              [-74.8911000249999 , 10.411383931000046],
              [-74.89115877799992, 10.411154045000046],
              [-74.89130476699995, 10.410808750000058],
              [-74.89140719799991, 10.41053037100005 ],
              [-74.8915096639999 , 10.410265766000066],
              [-74.8915823179999 , 10.410028921000048],
              [-74.89161607299991, 10.409870358000092],
              [-74.8916814769999 , 10.409592219000046],
              [-74.89179498399994, 10.409240287000046],
              [-74.8918789519999 , 10.408966620000058],
              [-74.89189690299992, 10.408874696000055],
              [-74.8919217369999 , 10.408768985000052],
              [-74.89193792799995, 10.408768871000063],
              [-74.89205823999993, 10.408781772000054],
              [-74.89226895699994, 10.40882614800006 ],
              [-74.89247018599991, 10.408895061000067],
              [-74.8925053669999 , 10.40890710900004 ],
              [-74.89282735599994, 10.409001129000046],
              [-74.89303832099995, 10.409089150000057],
              [-74.89320995799994, 10.409165899000072],
              [-74.89336291299992, 10.409224484000049],
              [-74.8935298959999 , 10.409294393000039],
              [-74.8936968189999 , 10.40937122400004 ],
              [-74.89380818299992, 10.409430052000062],
              [-74.89397530699995, 10.409527574000037],
              [-74.89413769499993, 10.409613595000053],
              [-74.89431874499991, 10.409722456000054],
              [-74.89452546899992, 10.409879273000058],
              [-74.89472037199994, 10.40998570100004 ],
              [-74.89486196599995, 10.41006273700009 ],
              [-74.89500338599993, 10.410125931000039],
              [-74.89517050299992, 10.41022345500005 ],
              [-74.89529582799992, 10.410298280000063],
              [-74.89546763999994, 10.410414083000092],
              [-74.89586415299993, 10.410581002000072],
              [-74.8961517329999 , 10.410718966000047],
              [-74.89630708599992, 10.410791239000048],
              [-74.8964440279999 , 10.41086370000005 ],
              [-74.89653672799994, 10.41089975400007 ],
              [-74.89665503399993, 10.410963154000058],
              [-74.89687525699992, 10.411044159000085],
              [-74.89702609799991, 10.41113253900005 ],
              [-74.89710521299992, 10.411216939000042],
              [-74.89717251299993, 10.411250813000038],
              [-74.8973444259999 , 10.411382716000048],
              [-74.8974886179999 , 10.411521612000058],
              [-74.8976326529999 , 10.411630700000046],
              [-74.8978208449999 , 10.411767087000044],
              [-74.89803193999995, 10.411873391000086],
              [-74.89830368799994, 10.412068805000047],
              [-74.89854980699994, 10.412229970000055],
              [-74.8988166709999 , 10.412384091000092],
              [-74.89902566199993, 10.41253172000006 ],
              [-74.89917682399994, 10.412666         ],
              [-74.89929315099994, 10.41278681500006 ],
              [-74.8993653359999 , 10.412866576000056],
              [-74.89945497899993, 10.41304241000006 ],
              [-74.89946655099993, 10.413046755000039],
              [-74.89960251999992, 10.413154175000045],
              [-74.89964086799995, 10.413171614000078],
              [-74.89972837699992, 10.413211408000052],
              [-74.89978497999994, 10.413279057000068],
              [-74.89983931499995, 10.413349047000054],
              [-74.89990545599994, 10.413432495000052],
              [-74.89991467999994, 10.413452237000058],
              [-74.89997971299994, 10.413591420000046],
              [-74.89998098899991, 10.413671573000045],
              [-74.90003677899995, 10.413835410000047],
              [-74.90005257699994, 10.413951969000038],
              [-74.90004500299995, 10.414057429000081],
              [-74.89999553199993, 10.414152258000058],
              [-74.90001192199992, 10.414307662000056],
              [-74.90003244399992, 10.414433245000055],
              [-74.9000652009999 , 10.414599834000057],
              [-74.90014402499992, 10.414758663000043],
              [-74.90027351799995, 10.414895978000061],
              [-74.9003982019999 , 10.415024189000064],
              [-74.90053882399991, 10.415136117000088],
              [-74.90070693699994, 10.41522917900005 ],
              [-74.90086585699993, 10.415327011000045],
              [-74.90102233099992, 10.415417989000048],
              [-74.90105996999995, 10.415437747000055],
              [-74.90200399299994, 10.416303466000045],
              [-74.90206991899993, 10.416392471000052],
              [-74.90223956599993, 10.416581731000065],
              [-74.9023496449999 , 10.416664357000059],
              [-74.90248485299992, 10.416723751000063],
              [-74.90253377999994, 10.416743403000055],
              [-74.90271585499994, 10.416991936000045],
              [-74.90404984299994, 10.416652124000052],
              [-74.90433421699993, 10.416540559000055],
              [-74.90479927799993, 10.41644874900004 ],
              [-74.90515320799994, 10.416378878000046],
              [-74.90534604499993, 10.41634080700004 ],
              [-74.90535532699994, 10.416340638000065],
              [-74.90541950199992, 10.416302831000053],
              [-74.9054516349999 , 10.416288555000051],
              [-74.90550021299993, 10.416287651000061],
              [-74.90552567999993, 10.416287158000046],
              [-74.90554651499991, 10.416286754000055],
              [-74.9055838939999 , 10.416311231000066],
              [-74.90561416799994, 10.416322175000062],
              [-74.90565136099991, 10.416332877000059],
              [-74.90675727899992, 10.416754362000063],
              [-74.90692327799991, 10.415546703000075],
              [-74.9069718529999 , 10.41554579700005 ],
              [-74.90703909199993, 10.415556011000092],
              [-74.9070807459999 , 10.41555519700006 ],
              [-74.90715708599993, 10.415553794000061],
              [-74.90724487299991, 10.415542969000057],
              [-74.90728417699995, 10.415542308000056],
              [-74.9073723169999 , 10.415554364000059],
              [-74.90743944299993, 10.415553130000092],
              [-74.9074903099999 , 10.415552149000064],
              [-74.9075893179999 , 10.415522861000056],
              [-74.90767930699991, 10.415502856000046],
              [-74.90775808799992, 10.415510563000055],
              [-74.90787422699992, 10.415535895000062],
              [-74.90802620599993, 10.41549184300004 ],
              [-74.90808177899993, 10.415490857000066],
              [-74.90818305099992, 10.415454571000055],
              [-74.90827093899992, 10.41545293300004 ],
              [-74.9083513089999 , 10.415412539000044],
              [-74.9084778909999 , 10.41536897800006 ],
              [-74.9085516849999 , 10.415351558000054],
              [-74.90864310199993, 10.41528345000006 ],
              [-74.90891884799993, 10.41515694800006 ],
              [-74.91000545999992, 10.414655864000053],
              [-74.91113939099995, 10.414217997000037],
              [-74.9124590859999 , 10.41367589500004 ],
              [-74.91361972099992, 10.413171067000064],
              [-74.9140157519999 , 10.413044669000044],
              [-74.91432136999993, 10.412908481000045],
              [-74.91444787299992, 10.412843014000089],
              [-74.91500687799993, 10.412558225000055],
              [-74.91501462299993, 10.412554323000052],
              [-74.91542885799993, 10.412409228000058],
              [-74.91585685099994, 10.412259296000059],
              [-74.91626360799995, 10.412224251000055],
              [-74.91657801399992, 10.412202377000085],
              [-74.91677796199991, 10.412214258000063],
              [-74.9167783769999 , 10.412214283000083],
              [-74.91684209699991, 10.412218070000051],
              [-74.91684690799991, 10.412747980000063],
              [-74.91685308399991, 10.412907173000065],
              [-74.9168849109999 , 10.413600531000043],
              [-74.91692673999995, 10.414483699000073],
              [-74.91694859199993, 10.41483597000007 ],
              [-74.91699382599995, 10.414915311000073],
              [-74.91699000599993, 10.415114610000046],
              [-74.9168922739999 , 10.41580351400006 ],
              [-74.91678067799995, 10.416352897000081],
              [-74.91675173999994, 10.41657331500005 ],
              [-74.91663468799993, 10.417216716000041],
              [-74.9166055099999 , 10.417851613000039],
              [-74.9165356769999 , 10.418118542000059],
              [-74.91649861099995, 10.418303190000074],
              [-74.9164282559999 , 10.418653669000037],
              [-74.9164042569999 , 10.418773219000059],
              [-74.91640276999993, 10.418780628000093],
              [-74.9163983819999 , 10.418816225000057],
              [-74.91632941299991, 10.419375818000049],
              [-74.91643368799993, 10.419267772000069],
              [-74.91703220799991, 10.418780309000056],
              [-74.91715299499992, 10.418667312000082],
              [-74.91724199499993, 10.418584054000064],
              [-74.91735659699992, 10.418515539000055],
              [-74.91742016799992, 10.418438740000056],
              [-74.91745870299991, 10.418387646000042],
              [-74.91753207399995, 10.418345084000066],
              [-74.9175985089999 , 10.418300344000045],
              [-74.9178288789999 , 10.418180334000056],
              [-74.91789573499995, 10.418145507000077],
              [-74.9179405459999 , 10.418126835000066],
              [-74.9180116359999 , 10.418086535000043],
              [-74.91805287699992, 10.418060585000092],
              [-74.91814066699993, 10.418049817000053],
              [-74.91825156899995, 10.418038589000048],
              [-74.91833945699995, 10.418036937000068],
              [-74.91853832899994, 10.418024052000078],
              [-74.91858429099995, 10.418004864000068],
              [-74.9186326169999 , 10.417987998000058],
              [-74.91872487899991, 10.417970169000057],
              [-74.9187665369999 , 10.417969428000049],
              [-74.91887262999995, 10.417946828000083],
              [-74.91896490499994, 10.417929135000065],
              [-74.91904552599993, 10.417904695000061],
              [-74.91911257499993, 10.417903461000037],
              [-74.91918417699992, 10.417892966000068],
              [-74.91924187399991, 10.41788278000007 ],
              [-74.91931776099995, 10.417851579000057],
              [-74.91937983999992, 10.417827470000077],
              [-74.91940758699991, 10.417826972000057],
              [-74.91948717699995, 10.417738488000055],
              [-74.91950984599993, 10.417706013000043],
              [-74.91958008199992, 10.417613097000071],
              [-74.91960057099993, 10.41759206900008 ],
              [-74.91963673499993, 10.417538722000074],
              [-74.91965713199994, 10.417510915000037],
              [-74.91970158899994, 10.417539826000052],
              [-74.91973247699991, 10.41759193100006 ],
              [-74.91975846299994, 10.417625832000056],
              [-74.91979432499994, 10.417696146000083],
              [-74.91984149699994, 10.417752517000054],
              [-74.91987237399991, 10.417802289000065],
              [-74.91988461499994, 10.417843299000083],
              [-74.91991593299991, 10.417922873000066],
              [-74.91994268699995, 10.41800479200009 ],
              [-74.91996148399994, 10.418053967000048],
              [-74.91998616299992, 10.41811852300009 ],
              [-74.92002726299995, 10.418225410000048],
              [-74.92002898499993, 10.418335290000073],
              [-74.92004487699995, 10.41846096100005 ],
              [-74.92004600599995, 10.41853419000006 ],
              [-74.92005977699995, 10.41866904300008 ],
              [-74.92006160099993, 10.418785851000052],
              [-74.92006253099993, 10.418843049000088],
              [-74.9200408879999 , 10.418937391000043],
              [-74.9200217689999 , 10.41904308900007 ],
              [-74.92002297499994, 10.419120916000054],
              [-74.9200137549999 , 10.419269952000093],
              [-74.9200164959999 , 10.419439365000073],
              [-74.9200063959999 , 10.419531136000046],
              [-74.91998264499995, 10.419639250000046],
              [-74.91995223499993, 10.419763483000054],
              [-74.9199380089999 , 10.419885132000047],
              [-74.91991452099995, 10.420009209000057],
              [-74.91988630799995, 10.420128841000064],
              [-74.91986178299993, 10.420188868000082],
              [-74.91981492399992, 10.420297333000065],
              [-74.91978609999995, 10.420378062000054],
              [-74.91978001399991, 10.420394344000044],
              [-74.91975507899991, 10.42046104800005 ],
              [-74.91972221499992, 10.420576181000058],
              [-74.91968574999993, 10.420757777000063],
              [-74.91967278299995, 10.420815231000063],
              [-74.91965813799993, 10.420914         ],
              [-74.91964175999993, 10.421026414000039],
              [-74.91963103499995, 10.42110001900005 ],
              [-74.91960762399992, 10.42122867900008 ],
              [-74.91958589699993, 10.421318430000042],
              [-74.91955986099993, 10.42142648500004 ],
              [-74.91953567899992, 10.421507130000066],
              [-74.9195248069999 , 10.421548615000063],
              [-74.91949388599994, 10.421643044000064],
              [-74.91944912699995, 10.421740058000069],
              [-74.9194134359999 , 10.421805895000091],
              [-74.91939518099991, 10.421839566000074],
              [-74.91935303399993, 10.421952532000091],
              [-74.91931754699993, 10.422047053000085],
              [-74.91927331099993, 10.422178453000072],
              [-74.9192297539999 , 10.422348768000063],
              [-74.91915510199993, 10.422602004000055],
              [-74.91912156399991, 10.422675964000064],
              [-74.91911270999992, 10.42270127200004 ],
              [-74.91907572999992, 10.422850879000066],
              [-74.9190335909999 , 10.422963849000041],
              [-74.9189799049999 , 10.423081643000046],
              [-74.91893101799991, 10.423208471000066],
              [-74.91888896299992, 10.423326100000054],
              [-74.91880829999991, 10.423494823000055],
              [-74.9187685149999 , 10.42361003700006 ],
              [-74.91875227699995, 10.423752315000058],
              [-74.91872539699995, 10.423810011000057],
              [-74.91865881299992, 10.423987594000039],
              [-74.91863192499994, 10.424045363000062],
              [-74.9186003559999 , 10.424240652000037],
              [-74.91860104699992, 10.424286407000068],
              [-74.91853245499993, 10.424484686000085],
              [-74.9185034599999 , 10.424551646000054],
              [-74.91844631799995, 10.424740428000064],
              [-74.91841143999994, 10.424873928000068],
              [-74.91837385999992, 10.424986879000073],
              [-74.91834031299993, 10.425060770000073],
              [-74.91831191699993, 10.42516663400005 ],
              [-74.9182918599999 , 10.42521514200007 ],
              [-74.91827353999992, 10.425271330000044],
              [-74.91823498399992, 10.425408436000055],
              [-74.91822637599995, 10.425439044000086],
              [-74.91813774099995, 10.42574144200006 ],
              [-74.91800502199993, 10.426092352000069],
              [-74.9180374739999 , 10.426094643000056],
              [-74.91801624599992, 10.42612212000006 ],
              [-74.91799439599993, 10.426150405000044],
              [-74.91797713999995, 10.42623321900004 ],
              [-74.91797730299993, 10.426242330000036],
              [-74.91796389899991, 10.42627004600007 ],
              [-74.91794873799995, 10.426336751000065],
              [-74.9179495919999 , 10.426389434000043],
              [-74.9179210389999 , 10.426488457000062],
              [-74.91790814599995, 10.426550496000061],
              [-74.91790900799992, 10.426605436000045],
              [-74.91790058999993, 10.426656026000046],
              [-74.91790186399993, 10.426733844000069],
              [-74.91791244899991, 10.426820692000092],
              [-74.91791477099991, 10.426967226000045],
              [-74.91793656199991, 10.427240898000036],
              [-74.91773252699994, 10.427481453000041],
              [-74.9177135939999 , 10.427503774000058],
              [-74.91769690799993, 10.42805022400006 ],
              [-74.91769043799991, 10.428262078000046],
              [-74.91768633399994, 10.42839652400005 ],
              [-74.91803717699992, 10.42912029300004 ],
              [-74.91894390999994, 10.430990833000067],
              [-74.91904486099992, 10.431199083000081],
              [-74.91925797099992, 10.43163871300004 ],
              [-74.92002650699993, 10.433224142000086],
              [-74.92006241099995, 10.43329034900006 ],
              [-74.92037728199995, 10.433870984000066],
              [-74.92039692599991, 10.43390720800005 ],
              [-74.92067120099995, 10.43447682100009 ],
              [-74.92095247799995, 10.435060975000056],
              [-74.92110566499991, 10.435157829000048],
              [-74.92126329999991, 10.435257495000087],
              [-74.92141930499992, 10.43535613000006 ],
              [-74.92149004599992, 10.435400856000058],
              [-74.92180673099995, 10.435601082000062],
              [-74.92247088699992, 10.435872201000052],
              [-74.92284870399993, 10.436026432000062],
              [-74.92290461599993, 10.436049256000047],
              [-74.92293202399992, 10.436060444000077],
              [-74.92296646599993, 10.436071320000053],
              [-74.9234066699999 , 10.43621031500004 ],
              [-74.92364336599991, 10.43629609900006 ],
              [-74.9238581539999 , 10.436373940000067],
              [-74.9239457569999 , 10.436389594000048],
              [-74.92406665599992, 10.436411198000087],
              [-74.92432583899995, 10.43645751200006 ],
              [-74.92437284499994, 10.436466554000049],
              [-74.92513624599991, 10.436613393000073],
              [-74.92526385999992, 10.436578628000063],
              [-74.92572896799993, 10.436451926000075],
              [-74.92610669999993, 10.43629113500009 ],
              [-74.92629628599991, 10.436210431000063],
              [-74.9262945669999 , 10.436227621000057],
              [-74.92626148999994, 10.436558127000069],
              [-74.92625918399995, 10.436940135000043],
              [-74.92631007799991, 10.437262529000066],
              [-74.9264072659999 , 10.43752992800006 ],
              [-74.9264188489999 , 10.437641978000045],
              [-74.92635165299993, 10.43782740000006 ],
              [-74.92633484999993, 10.437854590000086],
              [-74.92619878399995, 10.43807477100006 ],
              [-74.92574023399993, 10.438890257000082],
              [-74.92564717199991, 10.439055761000077],
              [-74.92559837099992, 10.439142548000063],
              [-74.9255983509999 , 10.439142583000091],
              [-74.92559831999995, 10.439142640000057],
              [-74.9254967679999 , 10.439323237000053],
              [-74.92516593699992, 10.439911596000059],
              [-74.92544837699995, 10.440244885000084],
              [-74.92573547699993, 10.440482115000066],
              [-74.92604802299991, 10.44084046000006 ],
              [-74.9262008199999 , 10.44098652100007 ],
              [-74.92641841299991, 10.441322250000042],
              [-74.92650869699992, 10.441516424000042],
              [-74.92656949699995, 10.441616147000047],
              [-74.92658915699991, 10.441648386000054],
              [-74.9266869349999 , 10.441808750000064],
              [-74.9266949279999 , 10.441833617000043],
              [-74.92678789399992, 10.442122960000063],
              [-74.92682948499993, 10.44215762400006 ],
              [-74.92690242299994, 10.442070069000067],
              [-74.92694890199994, 10.442014276000066],
              [-74.92702427199993, 10.441934226000058],
              [-74.92709076199992, 10.441863605000037],
              [-74.9271794899999 , 10.441769389000058],
              [-74.92725283899995, 10.441676791000077],
              [-74.92731768399995, 10.441585367000073],
              [-74.92747954899994, 10.441456619000064],
              [-74.92761682199995, 10.441358918000049],
              [-74.92772104199992, 10.441309346000082],
              [-74.92783812399995, 10.44125811300006 ],
              [-74.92796773999993, 10.44124293200008 ],
              [-74.92810265599991, 10.441229577000058],
              [-74.92816185499993, 10.441240070000049],
              [-74.92824663899995, 10.44125035500008 ],
              [-74.9283925599999 , 10.441296031000093],
              [-74.92857511499994, 10.441362233000063],
              [-74.92863603199993, 10.441387834000068],
              [-74.92874131199994, 10.44145020500008 ],
              [-74.92892225899993, 10.441534691000072],
              [-74.92912014399991, 10.441680069000085],
              [-74.92927254299991, 10.441772841000045],
              [-74.92954403599992, 10.441910935000067],
              [-74.92959050899992, 10.441934575000062],
              [-74.92967576699994, 10.44196076500009 ],
              [-74.92993782899993, 10.442041263000078],
              [-74.93022260299995, 10.442129765000061],
              [-74.93024568499993, 10.442133295000076],
              [-74.93034066099995, 10.44214781900007 ],
              [-74.93046799799993, 10.442245855000067],
              [-74.93054180299993, 10.442328511000085],
              [-74.93056990899993, 10.442359985000053],
              [-74.93063482199994, 10.442398938000053],
              [-74.93066481099993, 10.442416933000061],
              [-74.9308732259999 , 10.442471314000045],
              [-74.93116036599992, 10.44246150400005 ],
              [-74.9315308059999 , 10.442437762000054],
              [-74.93170891099993, 10.442437354000049],
              [-74.93171138199995, 10.442437348000055],
              [-74.93172000699991, 10.442435214000056],
              [-74.9320101269999 , 10.442363447000048],
              [-74.93212124599995, 10.442326563000051],
              [-74.93229029399993, 10.442282682000041],
              [-74.93240835699993, 10.442252694000047],
              [-74.93240380999993, 10.44228786900004 ],
              [-74.9323944539999 , 10.442360221000058],
              [-74.93239447099995, 10.44237658500009 ],
              [-74.93239449499993, 10.442399068000043],
              [-74.93238979699993, 10.442425035000042],
              [-74.93238289999994, 10.442463153000062],
              [-74.9323412139999 , 10.442534174000059],
              [-74.93227408799993, 10.442616695000083],
              [-74.9321952919999 , 10.44271062100006 ],
              [-74.93206792199993, 10.442859629000054],
              [-74.93203887499993, 10.442893471000048],
              [-74.93158395899991, 10.443423437000092],
              [-74.9311268699999 , 10.443978389000051],
              [-74.93084248299994, 10.444323667000049],
              [-74.93038903399992, 10.444874200000072],
              [-74.9303497059999 , 10.444920001000071],
              [-74.93031960699994, 10.444977211000037],
              [-74.9303195999999 , 10.445050441000092],
              [-74.9303195729999 , 10.44509387700009 ],
              [-74.9303403429999 , 10.445161469000084],
              [-74.93034275499991, 10.44516931600009 ],
              [-74.93043802099993, 10.445897117000072],
              [-74.93050394099993, 10.446400709000045],
              [-74.93065631099995, 10.447564753000051],
              [-74.93070255999993, 10.44791807200005 ],
              [-74.93071053999995, 10.447979040000064],
              [-74.93074402299993, 10.448234820000039],
              [-74.93074532999992, 10.448244807000037],
              [-74.93073230399995, 10.448292129000038],
              [-74.93072689299993, 10.448311784000055],
              [-74.93072775199994, 10.44837962300005 ],
              [-74.93073013499992, 10.448567636000064],
              [-74.93073417499994, 10.448886168000058],
              [-74.9307365599999 , 10.449074266000082],
              [-74.93076359899993, 10.45120680000008 ],
              [-74.93051349399991, 10.451143351000042],
              [-74.9303745389999 , 10.451804682000045],
              [-74.93035827799991, 10.452118146000089],
              [-74.93032582799992, 10.452669434000086],
              [-74.93024005299992, 10.453207172000077],
              [-74.93021258399995, 10.453642872000046],
              [-74.93018207499995, 10.454126758000086],
              [-74.9301079409999 , 10.454879436000056],
              [-74.93011254099991, 10.455410078000057],
              [-74.93013098999995, 10.455842386000086],
              [-74.93017725899995, 10.456242546000055],
              [-74.93016793399994, 10.456537625000067],
              [-74.93007992199995, 10.457198882000057],
              [-74.93002665099993, 10.457516895000083],
              [-74.92993630299992, 10.457954019000056],
              [-74.92992232899991, 10.458297136000056],
              [-74.92992234899992, 10.458459559000062],
              [-74.92988296199991, 10.458743284000093],
              [-74.92987827999991, 10.459013148000054],
              [-74.9298643809999 , 10.459225900000092],
              [-74.92977409699995, 10.459450296000057],
              [-74.9296837359999 , 10.459619755000062],
              [-74.9296558929999 , 10.459823364000044],
              [-74.92959566199994, 10.46028560600007 ],
              [-74.9295516709999 , 10.460532688000058],
              [-74.92938487099991, 10.460979119000058],
              [-74.92932465399991, 10.461422998000046],
              [-74.92927593299993, 10.462022429000058],
              [-74.92926667299992, 10.462418146000061],
              [-74.92925271999991, 10.462871017000055],
              [-74.9292225609999 , 10.463452104000055],
              [-74.92921783899993, 10.46391413200007 ],
              [-74.92925854899994, 10.464201109000044],
              [-74.92931041799994, 10.46457497700004 ],
              [-74.92935439299993, 10.465155793000065],
              [-74.9293496969999 , 10.465654467000093],
              [-74.92939826999992, 10.466166701000077],
              [-74.92942371999993, 10.466611825000086],
              [-74.92942376499991, 10.466612613000052],
              [-74.92942224799992, 10.466611794000073],
              [-74.92914121199993, 10.466460055000084],
              [-74.92753887499993, 10.466075026000055],
              [-74.92722788799995, 10.466005132000078],
              [-74.92667283899993, 10.465880388000073],
              [-74.92589165999993, 10.465478137000048],
              [-74.92530201699992, 10.465174515000058],
              [-74.92470699299992, 10.46485117800006 ],
              [-74.92421380299993, 10.464641903000086],
              [-74.92350287499994, 10.46428221800005 ],
              [-74.92303034699995, 10.464131420000058],
              [-74.92291935299994, 10.464095998000062],
              [-74.9227197539999 , 10.463985557000058],
              [-74.92228957599991, 10.463747528000056],
              [-74.92179406799994, 10.463494756000046],
              [-74.92139579799993, 10.463262416000077],
              [-74.92129627199995, 10.46321465300008 ],
              [-74.9203028899999 , 10.462917374000085],
              [-74.9198629569999 , 10.462767412000062],
              [-74.91938821999992, 10.462674744000083],
              [-74.91892749399994, 10.462538540000082],
              [-74.91881168099991, 10.462509115000046],
              [-74.91815178299993, 10.462416874000041],
              [-74.91793213999995, 10.462383355000043],
              [-74.91788167099992, 10.462375654000084],
              [-74.91782058099994, 10.462366333000091],
              [-74.91779744799993, 10.462362802000087],
              [-74.9175230649999 , 10.462312645000054],
              [-74.91739453999992, 10.46228915000006 ],
              [-74.91734268399995, 10.462278509000043],
              [-74.9173422639999 , 10.462279589000048],
              [-74.9173050519999 , 10.462374990000058],
              [-74.9172076989999 , 10.462525084000049],
              [-74.91713562399991, 10.462661174000061],
              [-74.91690365099993, 10.463034424000057],
              [-74.91686924699991, 10.46308977800004 ],
              [-74.9167763289999 , 10.463239283000064],
              [-74.91671259099991, 10.463344040000038],
              [-74.91665450499994, 10.46348938500006 ],
              [-74.91656772999994, 10.46364599900005 ],
              [-74.9165089089999 , 10.463732889000084],
              [-74.9164482619999 , 10.463876403000086],
              [-74.91638655799994, 10.463980098000093],
              [-74.9163522419999 , 10.464067538000052],
              [-74.91630048799993, 10.46424313600005 ],
              [-74.91626741399995, 10.464516250000088],
              [-74.91622788899991, 10.46477184400004 ],
              [-74.91617516999992, 10.464991256000076],
              [-74.91612869099993, 10.465255825000042],
              [-74.91607306599991, 10.46545195400006 ],
              [-74.9160307009999 , 10.465647158000081],
              [-74.91597139899994, 10.465891566000039],
              [-74.91590928799991, 10.466274093000038],
              [-74.91588940799994, 10.466508488000045],
              [-74.91583140299991, 10.466828466000038],
              [-74.91581180299994, 10.466916170000047],
              [-74.91568782299993, 10.467345936000072],
              [-74.9155922619999 , 10.468083979000085],
              [-74.91543700199992, 10.46850740800005 ],
              [-74.91545782399993, 10.468717816000037],
              [-74.91541514799991, 10.468884389000038],
              [-74.91517518799992, 10.469820981000055],
              [-74.91496666199993, 10.470471040000064],
              [-74.91475358299994, 10.470963281000081],
              [-74.91470719999995, 10.47130648700005 ],
              [-74.91473495799994, 10.471516866000059],
              [-74.91473592299991, 10.47170286100004 ],
              [-74.91473718799995, 10.471946888000048],
              [-74.91477265899994, 10.472266473000047],
              [-74.91478888899991, 10.472624716000041],
              [-74.9147806229999 , 10.472865210000066],
              [-74.9147553219999 , 10.47293277700004 ],
              [-74.91463243099992, 10.473260954000068],
              [-74.9144355329999 , 10.473885346000088],
              [-74.91428966699993, 10.474429667000038],
              [-74.9141599429999 , 10.474932840000065],
              [-74.91398357999992, 10.475598257000058],
              [-74.91521901699991, 10.475816420000058],
              [-74.91540443499991, 10.476488387000074],
              [-74.91555513599991, 10.477123804000087],
              [-74.91572910299993, 10.478017562000048],
              [-74.91587978299992, 10.47872162300007 ],
              [-74.91594943099994, 10.479384580000044],
              [-74.91595850199991, 10.479700181000055],
              [-74.91596144999994, 10.479802732000053],
              [-74.91596246499995, 10.479838034000068],
              [-74.9159647649999 , 10.479918016000056],
              [-74.91596488999994, 10.47992236400006 ],
              [-74.91596802899994, 10.48003157100004 ],
              [-74.91600281599995, 10.480360793000045],
              [-74.91602562399993, 10.480417170000067],
              [-74.9160337369999 , 10.480437216000041],
              [-74.91603419499995, 10.48048686900006 ],
              [-74.91603756299992, 10.480853419000084],
              [-74.91603763699993, 10.480861490000052],
              [-74.91604845899991, 10.480951695000044],
              [-74.9160724649999 , 10.481151795000073],
              [-74.91614432099993, 10.481469534000041],
              [-74.91618376399992, 10.481805528000052],
              [-74.91621874799995, 10.482178503000057],
              [-74.91622726799994, 10.48236307600007 ],
              [-74.91622726999992, 10.482363142000054],
              [-74.91623484599995, 10.482619457000055],
              [-74.91626736099994, 10.482898327000044],
              [-74.91626741699991, 10.483176828000069],
              [-74.9162674239999 , 10.483211543000039],
              [-74.91627032199995, 10.48322620600004 ],
              [-74.91632074699993, 10.483481315000063],
              [-74.91628370399991, 10.483742009000082],
              [-74.91625708399994, 10.48381256500005 ],
              [-74.9161447749999 , 10.484110243000089],
              [-74.91604511299994, 10.484357338000052],
              [-74.91596636799994, 10.484606635000091],
              [-74.91596418499995, 10.485063922000052],
              [-74.9159822279999 , 10.485265433000052],
              [-74.91599427699992, 10.485399999000037],
              [-74.91600089899993, 10.485487015000047],
              [-74.91602772499994, 10.485839547000069],
              [-74.9160315009999 , 10.485889170000064],
              [-74.91603247599994, 10.485922194000068],
              [-74.91603777499995, 10.48610167000004 ],
              [-74.91604061499993, 10.48619786800009 ],
              [-74.91604540999992, 10.486360190000084],
              [-74.91610198699993, 10.486615382000082],
              [-74.91614979799994, 10.48683103400009 ],
              [-74.91615460699995, 10.486864607000086],
              [-74.91623329799995, 10.487413941000057],
              [-74.91639324999994, 10.487957940000058],
              [-74.91639692199993, 10.487970439000037],
              [-74.91652080299991, 10.488392144000045],
              [-74.91652658999993, 10.488437142000066],
              [-74.91659093899995, 10.488937491000058],
              [-74.91659960299995, 10.48900486000008 ],
              [-74.91658814599992, 10.489411818000065],
              [-74.91654184099991, 10.48983256400004 ],
              [-74.9165232979999 , 10.490125198000044],
              [-74.91654188599995, 10.490296699000055],
              [-74.9166323259999 , 10.490490943000054],
              [-74.91683857099991, 10.490815337000072],
              [-74.91704488499994, 10.49105744700006 ],
              [-74.91725345899994, 10.49129042900006 ],
              [-74.91755004999993, 10.491484420000063],
              [-74.91777484999994, 10.491543573000058],
              [-74.9179482369999 , 10.491642959000046],
              [-74.91811565499995, 10.491708767000091],
              [-74.91815495799995, 10.491723780000086],
              [-74.91832175799993, 10.491867615000046],
              [-74.91847009899993, 10.492098292000037],
              [-74.91861613899994, 10.49245708500007 ],
              [-74.91863473899991, 10.492733738000084],
              [-74.9185954479999 , 10.493088152000041],
              [-74.9185490779999 , 10.493353463000062],
              [-74.9184448879999 , 10.493611939000061],
              [-74.9182317179999 , 10.494051136000053],
              [-74.91809278599993, 10.494394313000043],
              [-74.9179329019999 , 10.494691697000064],
              [-74.9177730639999 , 10.495009715000037],
              [-74.91761095399994, 10.495304856000075],
              [-74.91742558099992, 10.495574839000085],
              [-74.9172611109999 , 10.49584025200005 ],
              [-74.91707868299994, 10.49620739900007 ],
              [-74.91701782099994, 10.496329893000052],
              [-74.91680937099994, 10.496645899000043],
              [-74.9165522479999 , 10.497036086000037],
              [-74.91654991899992, 10.497039622000045],
              [-74.91632286099991, 10.497384718000092],
              [-74.9159730149999 , 10.497922448000054],
              [-74.91548418199994, 10.498622621000038],
              [-74.91497589599993, 10.499167500000055],
              [-74.9149397249999 , 10.499206275000063],
              [-74.91490932699992, 10.499315828000078],
              [-74.91480771099992, 10.499682057000086],
              [-74.91478458299991, 10.500077605000058],
              [-74.91480316099995, 10.500557712000045],
              [-74.91487043299992, 10.501049211000065],
              [-74.9148627969999 , 10.501247191000061],
              [-74.91485489599995, 10.50145199900004 ],
              [-74.91485429499994, 10.50146761700006 ],
              [-74.91483818799992, 10.50197522600007 ],
              [-74.91490540799992, 10.502578724000045],
              [-74.91487076199991, 10.50309089500007 ],
              [-74.91481286499993, 10.503529970000045],
              [-74.9147341339999 , 10.504023896000092],
              [-74.91460473699993, 10.504491100000052],
              [-74.91459286799994, 10.504533954000067],
              [-74.9145701889999 , 10.50466281200005 ],
              [-74.9145003079999 , 10.505059911000046],
              [-74.91447712999991, 10.505457788000058],
              [-74.91447719699994, 10.505825944000037],
              [-74.91441703099991, 10.506164314000046],
              [-74.91428265099995, 10.506422862000079],
              [-74.91417401399991, 10.506571110000039],
              [-74.91404040499992, 10.506753436000054],
              [-74.9139606409999 , 10.506862282000043],
              [-74.91382155399992, 10.507047613000054],
              [-74.91368725099994, 10.50728328300005 ],
              [-74.91353195399995, 10.507514348000086],
              [-74.91337673899994, 10.507802608000077],
              [-74.91319141199995, 10.508173237000051],
              [-74.9129759299999 , 10.50847529400005 ],
              [-74.91287403899992, 10.508690327000068],
              [-74.91276055799995, 10.509001436000062],
              [-74.9125913769999 , 10.509246307000069],
              [-74.91241965699993, 10.50949096700009 ],
              [-74.91240832799991, 10.509507109000083],
              [-74.91229719699993, 10.50973586200007 ],
              [-74.9122422339999 , 10.509930351000037],
              [-74.9122299679999 , 10.509973754000043],
              [-74.91212110799995, 10.51032828700005 ],
              [-74.9119983619999 , 10.510698818000037],
              [-74.91188254899993, 10.511069324000061],
              [-74.91183793899995, 10.511268223000059],
              [-74.91177635699995, 10.511514384000066],
              [-74.91166042799995, 10.51197778900007 ],
              [-74.91166041399993, 10.511977849000061],
              [-74.91164393699995, 10.512055068000052],
              [-74.91164172599991, 10.512267715000064],
              [-74.91152812699994, 10.51251017900006 ],
              [-74.9114331429999 , 10.512748074000058],
              [-74.9113498079999 , 10.512990529000092],
              [-74.91127798299993, 10.513216991000093],
              [-74.9112015529999 , 10.513374826000074],
              [-74.91117439499993, 10.513405118000037],
              [-74.91107640299992, 10.51351442300006 ],
              [-74.9109698549999 , 10.513750088000052],
              [-74.91089337199992, 10.513889629000062],
              [-74.91084707499994, 10.514051967000057],
              [-74.91081928499995, 10.514280642000074],
              [-74.91081005299992, 10.514509280000084],
              [-74.91082399499993, 10.51475854000006 ],
              [-74.91087503799992, 10.515186016000087],
              [-74.91088622299992, 10.515296552000052],
              [-74.91094234999991, 10.515851247000057],
              [-74.91097785799991, 10.516190605000077],
              [-74.91101890199991, 10.516582830000061],
              [-74.91102262499993, 10.516900294000038],
              [-74.91102466199993, 10.517073956000047],
              [-74.91102524299993, 10.517123557000048],
              [-74.91102595299992, 10.517184082000085],
              [-74.91103986599995, 10.517607075000058],
              [-74.91109091299995, 10.518064295000045],
              [-74.91105855199993, 10.51850096600009 ],
              [-74.91089411299993, 10.519063025000037],
              [-74.91085277099995, 10.519204332000072],
              [-74.91095673699994, 10.51937913900008 ],
              [-74.91072261099993, 10.519255887000043],
              [-74.91067169299993, 10.519452633000071],
              [-74.9104236849999 , 10.519288317000075],
              [-74.9103615809999 , 10.519582293000042],
              [-74.91028938199992, 10.51992406000005 ],
              [-74.91060689599993, 10.520033395000041],
              [-74.91070538999992, 10.520079250000038],
              [-74.91100409599994, 10.520218318000047],
              [-74.91120947399992, 10.520313934000058],
              [-74.91183522599994, 10.52058756200006 ],
              [-74.91249574299991, 10.520838276000063],
              [-74.91266027599994, 10.520900189000088],
              [-74.9132466229999 , 10.521120825000082],
              [-74.91384251199992, 10.52134715200009 ],
              [-74.91418297099995, 10.521476462000066],
              [-74.91498717799993, 10.521843538000041],
              [-74.91564066399991, 10.521920539000064],
              [-74.91641239799992, 10.521969891000083],
              [-74.91717255599991, 10.52206730100005 ],
              [-74.91780289699994, 10.52226541300007 ],
              [-74.91780456399994, 10.522266349000063],
              [-74.91790979699994, 10.522325392000084],
              [-74.91828270399992, 10.52253461500004 ],
              [-74.91831097399995, 10.522554013000047],
              [-74.91877175099995, 10.522870168000054],
              [-74.91912785699992, 10.523149213000067],
              [-74.91919121399991, 10.523198858000057],
              [-74.91954840199992, 10.523529627000073],
              [-74.91955632199995, 10.523536962000037],
              [-74.91957834699991, 10.523557358000062],
              [-74.91930264299992, 10.52401954000004 ],
              [-74.91894353899994, 10.524545858000067],
              [-74.91880450899993, 10.524907253000038],
              [-74.91876285299992, 10.525334839000038],
              [-74.91867026999995, 10.525849424000057],
              [-74.91861699099991, 10.526279302000091],
              [-74.91851977699991, 10.526821307000091],
              [-74.9184062999999 , 10.527370136000059],
              [-74.9183669869999 , 10.527818332000038],
              [-74.91831605999994, 10.52827334500006 ],
              [-74.91827676699995, 10.528673524000055],
              [-74.91820030499991, 10.528899937000062],
              [-74.91808214199995, 10.529039589000092],
              [-74.91794310099993, 10.529160929000057],
              [-74.9176580489999 , 10.52928931300005 ],
              [-74.91708104999992, 10.529443179000054],
              [-74.91680291999995, 10.529571537000038],
              [-74.9165341659999 , 10.529734259000065],
              [-74.91636034599992, 10.52997907300005 ],
              [-74.91622137999991, 10.530290181000055],
              [-74.91614263199995, 10.53066984800006 ],
              [-74.91609607099991, 10.53086421100005 ],
              [-74.91606732899993, 10.53098417600006 ],
              [-74.9160407359999 , 10.531095191000077],
              [-74.91601089699992, 10.531198776000053],
              [-74.91610178699995, 10.531248899000047],
              [-74.91615076799991, 10.53127591000009 ],
              [-74.91630794699995, 10.531385742000055],
              [-74.91665703299992, 10.531485602000089],
              [-74.91701379799991, 10.531597537000039],
              [-74.91731552699991, 10.531683859000054],
              [-74.9176575649999 , 10.531694237000067],
              [-74.91791996099994, 10.531662987000061],
              [-74.91822958599994, 10.531544773000064],
              [-74.91856148199992, 10.53143969100006 ],
              [-74.91878461999994, 10.531387915000039],
              [-74.91897021999995, 10.531391274000043],
              [-74.91899199199992, 10.531397190000064],
              [-74.9191590719999 , 10.53144254600005 ],
              [-74.9193833569999 , 10.531553073000055],
              [-74.91963448599995, 10.531746596000062],
              [-74.91980386299991, 10.531932591000043],
              [-74.92002327599994, 10.532180048000043],
              [-74.9203251429999 , 10.532536118000053],
              [-74.92053153599994, 10.532757667000055],
              [-74.9207736539999 , 10.532996878000063],
              [-74.92105849599994, 10.533254290000059],
              [-74.92130456699994, 10.53355874400006 ],
              [-74.92149776699995, 10.533730135000042],
              [-74.9216992339999 , 10.533914752000044],
              [-74.92186789899995, 10.533999086000051],
              [-74.92200353499993, 10.534034546000044],
              [-74.92217106999993, 10.534065113000054],
              [-74.92229043999993, 10.534086892000062],
              [-74.92255680099993, 10.534122609000065],
              [-74.92260271799995, 10.534129818000054],
              [-74.92260740099994, 10.534075778000044],
              [-74.92260776099994, 10.534073426000077],
              [-74.9226134779999 , 10.534036068000091],
              [-74.92272554099992, 10.534062885000083],
              [-74.92270314399991, 10.534321324000075],
              [-74.92270026199992, 10.53441858900004 ],
              [-74.92270022999992, 10.534419675000038],
              [-74.9226936629999 , 10.534641308000062],
              [-74.92269199499992, 10.534697597000047],
              [-74.92269196699993, 10.534698548000051],
              [-74.9226883039999 , 10.534822158000054],
              [-74.92268410799994, 10.534963779000066],
              [-74.92280650799995, 10.534980745000041],
              [-74.92292443399992, 10.534997091000037],
              [-74.92295386299992, 10.535002513000052],
              [-74.92294290299992, 10.535072553000077],
              [-74.92294306799994, 10.535072582000055],
              [-74.9229414049999 , 10.535082128000056],
              [-74.92285232499995, 10.535173369000063],
              [-74.92258463299993, 10.535281529000088],
              [-74.92244553199993, 10.535439031000067],
              [-74.92235924999994, 10.535536727000078],
              [-74.92224691599995, 10.53573659600005 ],
              [-74.92212945199992, 10.535977087000049],
              [-74.92212468699995, 10.53598835400004 ],
              [-74.92208470999992, 10.53609937400006 ],
              [-74.92208922999993, 10.536155592000057],
              [-74.92209813999995, 10.536216119000073],
              [-74.92211991199991, 10.536274930000047],
              [-74.9222551599999 , 10.536453511000047],
              [-74.92240054599995, 10.536604819000047],
              [-74.92256393699995, 10.53668127900005 ],
              [-74.92268849499993, 10.536765527000057],
              [-74.9227866629999 , 10.536958928000047],
              [-74.92304774999991, 10.537307679000037],
              [-74.92315650799992, 10.537510671000064],
              [-74.92327437299991, 10.537731077000046],
              [-74.92333765299992, 10.53796611000007 ],
              [-74.9233476839999 , 10.538215967000042],
              [-74.92326906199992, 10.538596191000067],
              [-74.92324328399991, 10.538738480000063],
              [-74.92327575599995, 10.538900179000052],
              [-74.92324128799993, 10.539054003000047],
              [-74.92316601399995, 10.539182031000053],
              [-74.92302618799994, 10.539323488000036],
              [-74.92290073399994, 10.53940330100005 ],
              [-74.92281954799995, 10.53945264500004 ],
              [-74.9226887129999 , 10.53953726900005 ],
              [-74.9226028029999 , 10.539597592000064],
              [-74.92249727199993, 10.539725134000037],
              [-74.92253991599995, 10.539759699000058],
              [-74.92255390499992, 10.539771041000051],
              [-74.92249547799992, 10.539871383000047],
              [-74.92241199099993, 10.540055812000048],
              [-74.92237644199992, 10.540275898000061],
              [-74.92236722199993, 10.540519578000044],
              [-74.92234975499991, 10.540737300000046],
              [-74.92227144399993, 10.540897459000064],
              [-74.92216845299993, 10.540999956000064],
              [-74.9220001079999 , 10.541101471000047],
              [-74.92165672999994, 10.541205214000058],
              [-74.92141178599991, 10.54125036000005 ],
              [-74.92132157599991, 10.541331442000057],
              [-74.92123566299995, 10.541487577000055],
              [-74.9210745069999 , 10.541857569000058],
              [-74.92092731799994, 10.542213659000083],
              [-74.92079308299992, 10.542610120000063],
              [-74.92072793999995, 10.542887452000059],
              [-74.92064046699994, 10.543164535000074],
              [-74.92062905499995, 10.543200681000087],
              [-74.92052637899991, 10.543411135000042],
              [-74.92031870799991, 10.543839570000046],
              [-74.92017709999993, 10.544106146000047],
              [-74.92007869699995, 10.544180908000044],
              [-74.91986601299993, 10.544284860000062],
              [-74.91969355499992, 10.544376563000071],
              [-74.91944898399993, 10.544552785000064],
              [-74.91920675499995, 10.54473071800004 ],
              [-74.91899246699995, 10.544887214000084],
              [-74.9187403709999 , 10.545085394000068],
              [-74.91844351899994, 10.545343168000045],
              [-74.91811627499993, 10.545586012000058],
              [-74.91790487499992, 10.545777581000038],
              [-74.91786813199991, 10.545909811000058],
              [-74.91780633399992, 10.546367433000057],
              [-74.91780628799995, 10.54637802700006 ],
              [-74.91790965399991, 10.54663166000006 ],
              [-74.91804324199995, 10.546846544000061],
              [-74.91812276499991, 10.546950091000042],
              [-74.91806163399991, 10.547079731000053],
              [-74.9179972419999 , 10.547238395000079],
              [-74.91797399399991, 10.547295680000047],
              [-74.9178924119999 , 10.54742649700006 ],
              [-74.91783645499993, 10.547434830000043],
              [-74.91774793299993, 10.54751830300006 ],
              [-74.91771433199995, 10.547549986000092],
              [-74.91761850499995, 10.54768353500009 ],
              [-74.91753067099995, 10.547759364000058],
              [-74.91742804899991, 10.547760224000058],
              [-74.91732181399993, 10.54771955700005 ],
              [-74.91720600399992, 10.54759236600006 ],
              [-74.91714723199993, 10.547433867000052],
              [-74.91708071399995, 10.547415016000059],
              [-74.91702478399992, 10.547431325000048],
              [-74.91689966199993, 10.54751533800004 ],
              [-74.91680122699995, 10.54755427400005 ],
              [-74.91644786499995, 10.54765855100004 ],
              [-74.91630694499992, 10.54766733300005 ],
              [-74.91621338999994, 10.547675121000054],
              [-74.91606682299994, 10.547656437000057],
              [-74.91601352499993, 10.54762938500005 ],
              [-74.91588452899992, 10.547615561000043],
              [-74.9157380179999 , 10.547614818000056],
              [-74.91571716699991, 10.547612915000059],
              [-74.91546773699991, 10.547590109000055],
              [-74.9153371459999 , 10.54763532600009 ],
              [-74.91522327599995, 10.547693221000088],
              [-74.91510989999995, 10.54772594700006 ],
              [-74.91499500099991, 10.547735818000092],
              [-74.91489631999991, 10.547689214000059],
              [-74.91477339399995, 10.547649627000055],
              [-74.91449081499991, 10.547539847000053],
              [-74.91440478299995, 10.547535455000059],
              [-74.91424790999992, 10.547519679000061],
              [-74.91405503399994, 10.547531219000064],
              [-74.91377728299994, 10.547605537000038],
              [-74.91361782199994, 10.547609159000046],
              [-74.91346520299993, 10.547548500000062],
              [-74.91330728699995, 10.547478287000047],
              [-74.9132710049999 , 10.547480997000036],
              [-74.91319010199993, 10.547531021000054],
              [-74.91312199399994, 10.547557847000064],
              [-74.91304285799993, 10.547615108000059],
              [-74.9130040949999 , 10.547667914000044],
              [-74.91295931799993, 10.547804946000042],
              [-74.91280426899993, 10.547966004000045],
              [-74.91248181899994, 10.54821335400004 ],
              [-74.91238183399992, 10.548231332000057],
              [-74.9122577359999 , 10.548244946000068],
              [-74.91210324199994, 10.548229226000046],
              [-74.9119715619999 , 10.54823824500005 ],
              [-74.91183993899995, 10.548269749000042],
              [-74.91167563399995, 10.548282896000046],
              [-74.91155901399992, 10.548264759000062],
              [-74.9114219679999 , 10.54823342900005 ],
              [-74.91125331399991, 10.548151433000044],
              [-74.91105093399995, 10.547934883000039],
              [-74.9110060349999 , 10.547886847000086],
              [-74.91089244999995, 10.54770205200009 ],
              [-74.91083910899994, 10.547593194000058],
              [-74.91082176799995, 10.54743985400006 ],
              [-74.91072159499993, 10.547255591000066],
              [-74.91063529399992, 10.54722954500005 ],
              [-74.91061367899994, 10.547223024000061],
              [-74.91051187599993, 10.54728904700005 ],
              [-74.91038862399995, 10.547393184000043],
              [-74.91025406599994, 10.547417205000045],
              [-74.91013098899992, 10.547378913000045],
              [-74.9100123739999 , 10.547270831000048],
              [-74.90991470099993, 10.547150568000063],
              [-74.90979259799991, 10.547043073000054],
              [-74.90965743099991, 10.547057865000056],
              [-74.90955750599994, 10.54717000900007 ],
              [-74.90945867699992, 10.547258517000046],
              [-74.90930449099994, 10.547350065000046],
              [-74.90914953099991, 10.547372977000066],
              [-74.9089966109999 , 10.547290369000052],
              [-74.90896196999995, 10.547206351000057],
              [-74.90892677999994, 10.547121011000058],
              [-74.9089029669999 , 10.54694519800006 ],
              [-74.90887738799995, 10.546761882000055],
              [-74.90878517899995, 10.54660423200005 ],
              [-74.9087315729999 , 10.546512582000048],
              [-74.90861014199993, 10.546437369000046],
              [-74.90847486699994, 10.546416410000063],
              [-74.90834356999994, 10.546357374000081],
              [-74.90824209499993, 10.546332283000083],
              [-74.90815826799991, 10.546365961000049],
              [-74.90811297499994, 10.546424330000093],
              [-74.90811193399992, 10.546468737000055],
              [-74.90804343399992, 10.546563499000058],
              [-74.9079292959999 , 10.54659495900006 ],
              [-74.90781442699995, 10.546575093000058],
              [-74.90770454199992, 10.546666512000058],
              [-74.90766913499994, 10.546718510000062],
              [-74.9075298809999 , 10.546732732000066],
              [-74.90744797999992, 10.54682753800006 ],
              [-74.9073329279999 , 10.546947231000047],
              [-74.90719201299993, 10.546993177000047],
              [-74.9069740889999 , 10.54721292000005 ],
              [-74.90689400499991, 10.547335402000044],
              [-74.9067783239999 , 10.547438946000057],
              [-74.90667180499992, 10.547487680000074],
              [-74.90655585399992, 10.547497222000061],
              [-74.90647228499995, 10.54741499000005 ],
              [-74.90648622099991, 10.54718947400005 ],
              [-74.9064275479999 , 10.547052959000041],
              [-74.9062844099999 , 10.546924756000067],
              [-74.90614916699991, 10.546874990000049],
              [-74.90614439699993, 10.546873236000067],
              [-74.90599514599995, 10.54685519800006 ],
              [-74.90572877799991, 10.546844407000037],
              [-74.90553587699992, 10.546846663000053],
              [-74.90541068999994, 10.546887380000044],
              [-74.90531889599993, 10.546990275000041],
              [-74.9052453139999 , 10.547142146000056],
              [-74.90513866899994, 10.547352355000044],
              [-74.90503579199992, 10.547451250000051],
              [-74.90490135899995, 10.547519669000053],
              [-74.9047615739999 , 10.547550048000062],
              [-74.90459910899995, 10.547598356000037],
              [-74.90449557499994, 10.547670150000044],
              [-74.9044178609999 , 10.54766746900009 ],
              [-74.90432538099992, 10.547664276000091],
              [-74.90411485599992, 10.547618723000085],
              [-74.90393881799992, 10.54760709900006 ],
              [-74.90378282199993, 10.547469124000088],
              [-74.90369374699992, 10.547292330000062],
              [-74.90354766299993, 10.54715778700006 ],
              [-74.9034258659999 , 10.547159846000056],
              [-74.90331635399991, 10.547179180000057],
              [-74.90319691199994, 10.547189317000061],
              [-74.90304882599992, 10.547168960000079],
              [-74.9029063669999 , 10.547077087000048],
              [-74.90273652199994, 10.546989324000037],
              [-74.90256480099993, 10.547062457000038],
              [-74.90243202099992, 10.547095114000058],
              [-74.90234984799991, 10.547095916000046],
              [-74.90223970199992, 10.547113412000044],
              [-74.90223687299994, 10.54709964600005 ],
              [-74.90217270399995, 10.546999481000057],
              [-74.90209190799993, 10.546971564000046],
              [-74.90206945099993, 10.546963808000044],
              [-74.9019844789999 , 10.546970999000052],
              [-74.9018704049999 , 10.54704903000004 ],
              [-74.90176447899995, 10.54710790200005 ],
              [-74.90169006499991, 10.547116798000047],
              [-74.90159171199991, 10.547158250000052],
              [-74.9014980849999 , 10.54721128500006 ],
              [-74.90145319299995, 10.547298412000089],
              [-74.90144239599994, 10.547417909000046],
              [-74.90141561599995, 10.547482359000071],
              [-74.90137933799991, 10.54750566100006 ],
              [-74.90132050099993, 10.547426950000045],
              [-74.90123281299992, 10.547296689000063],
              [-74.90113469999994, 10.547215768000058],
              [-74.90103909999993, 10.547193409000045],
              [-74.9009306129999 , 10.547177465000061],
              [-74.9008859029999 , 10.547182104000058],
              [-74.90088594199995, 10.547458392000067],
              [-74.90093692699992, 10.54778983600005 ],
              [-74.90094859499993, 10.548121370000047],
              [-74.90097807299992, 10.548254729000064],
              [-74.90100251299992, 10.548365323000041],
              [-74.90101578899993, 10.54842536600006 ],
              [-74.90104583699991, 10.54850755800004 ],
              [-74.90104693799992, 10.548571547000051],
              [-74.90102957899995, 10.54864510300007 ],
              [-74.90102132699991, 10.548709251000048],
              [-74.9010131579999 , 10.54877805600006 ],
              [-74.90098600699991, 10.54881739600006 ],
              [-74.90095631799994, 10.548845370000038],
              [-74.90088792499995, 10.548919700000056],
              [-74.9008352269999 , 10.548961721000069],
              [-74.90074042299995, 10.548981531000038],
              [-74.90061003099993, 10.548956237000084],
              [-74.9004773659999 , 10.54893320900004 ],
              [-74.90030985299995, 10.54890617600006 ],
              [-74.90009578899992, 10.54887072200006 ],
              [-74.89994169699992, 10.548818299000061],
              [-74.8998017639999 , 10.548772553000049],
              [-74.89971268099993, 10.548716776000049],
              [-74.89934694499993, 10.548493867000047],
              [-74.89876944899993, 10.54835984500005 ],
              [-74.8984989619999 , 10.548297071000093],
              [-74.89819844599992, 10.548228720000054],
              [-74.89792812499991, 10.548159860000055],
              [-74.89767634099991, 10.548088416000041],
              [-74.89715829999994, 10.547916035000071],
              [-74.89663654299994, 10.547796275000053],
              [-74.89593209899994, 10.547578902000055],
              [-74.89547529099991, 10.547460388000047],
              [-74.89509938899994, 10.547355783000057],
              [-74.89509919599993, 10.54735572800007 ],
              [-74.89470586899995, 10.547241553000049],
              [-74.89204294299992, 10.546451535000074],
              [-74.8919598249999 , 10.546426876000055],
              [-74.89195988299991, 10.546425637000084],
              [-74.89186806899994, 10.546412676000045],
              [-74.89186700199991, 10.546434889000068],
              [-74.89186693699992, 10.546436164000056],
              [-74.89185932999993, 10.54659442400009 ],
              [-74.89186581199993, 10.546636336000063],
              [-74.89187892199993, 10.546721063000064],
              [-74.89189570999991, 10.54682957700004 ],
              [-74.89193350699992, 10.547007444000087],
              [-74.8919845499999 , 10.547146163000093],
              [-74.89202109299993, 10.54725312000005 ],
              [-74.89205955999995, 10.54733494900006 ],
              [-74.89215471199992, 10.547475245000044],
              [-74.89226143099995, 10.547615432000043],
              [-74.89232207899994, 10.54777460300005 ],
              [-74.89234768099993, 10.54780598700006 ],
              [-74.89245469799994, 10.547937176000062],
              [-74.8925861219999 , 10.548161625000091],
              [-74.89267731899992, 10.548347768000042],
              [-74.8927302269999 , 10.548461332000045],
              [-74.8928420009999 , 10.548624316000087],
              [-74.89290889699993, 10.548739968000064],
              [-74.89304412599995, 10.548920834000057],
              [-74.89316802799993, 10.549111052000057],
              [-74.89332189399994, 10.549296170000048],
              [-74.89343822299992, 10.54945215500004 ],
              [-74.89346398999993, 10.549506738000048],
              [-74.89350791699991, 10.549599790000059],
              [-74.89355398499993, 10.549720300000047],
              [-74.89361778099993, 10.549929809000048],
              [-74.89366409999991, 10.550064079000038],
              [-74.89369155899993, 10.550186817000053],
              [-74.8937736979999 , 10.550482740000064],
              [-74.89380020899995, 10.550576727000077],
              [-74.8938645689999 , 10.550678678000054],
              [-74.89393307799992, 10.550755412000058],
              [-74.89401389199992, 10.550868477000051],
              [-74.89414725799992, 10.551074558000039],
              [-74.89441179699992, 10.551354028000048],
              [-74.89464081699992, 10.551590589000057],
              [-74.89490029099994, 10.551847253000062],
              [-74.89504799499991, 10.552076043000056],
              [-74.89524725699994, 10.552338234000047],
              [-74.89537364499995, 10.55268402200005 ],
              [-74.89543946499992, 10.552877429000091],
              [-74.89555035399991, 10.553269232000048],
              [-74.89570329499992, 10.553534519000038],
              [-74.8958073259999 , 10.553791380000064],
              [-74.89586833999994, 10.553975756000057],
              [-74.89553866299991, 10.554106923000063],
              [-74.89535692399994, 10.554203722000068],
              [-74.89510364799992, 10.554319993000092],
              [-74.89482027799994, 10.554448063000052],
              [-74.89445365899991, 10.554591319000053],
              [-74.89406088899995, 10.554696092000086],
              [-74.89370404399995, 10.554731622000077],
              [-74.8932857229999 , 10.55483673200007 ],
              [-74.89314224599991, 10.554857378000065],
              [-74.89264202699991, 10.55492266400006 ],
              [-74.89231326999993, 10.554971464000062],
              [-74.89190806699992, 10.55488424300006 ],
              [-74.89149672199994, 10.554849714000056],
              [-74.89108099099991, 10.554828969000084],
              [-74.89079110799992, 10.55484053500004 ],
              [-74.89040827299993, 10.554846676000068],
              [-74.89013626399992, 10.554816728000048],
              [-74.89007399299993, 10.554815329000064],
              [-74.88989943599995, 10.55481141100006 ],
              [-74.88974592299991, 10.554788788000053],
              [-74.88948621199995, 10.554662481000037],
              [-74.88933000299994, 10.554616980000048],
              [-74.88922161199991, 10.55459724800005 ],
              [-74.88901318899991, 10.554560886000047],
              [-74.8886950669999 , 10.554533450000065],
              [-74.88840737599992, 10.554538079000054],
              [-74.88814034099994, 10.55452867200006 ],
              [-74.88810874199993, 10.554531022000049],
              [-74.88790392699991, 10.554546231000074],
              [-74.8877187189999 , 10.554574394000042],
              [-74.88752894899994, 10.554604900000072],
              [-74.88725778799994, 10.55462755700006 ],
              [-74.88719495399994, 10.554634155000088],
              [-74.88705183199994, 10.554649184000084],
              [-74.88705179199991, 10.554649190000077],
              [-74.8870498249999 , 10.554649532000042],
              [-74.8869474899999 , 10.554667949000077],
              [-74.88683417599992, 10.554688342000077],
              [-74.88638757699994, 10.554768718000048],
              [-74.88596099099993, 10.554851879000068],
              [-74.88553392299991, 10.554907873000047],
              [-74.88499263099993, 10.554968804000055],
              [-74.8849925689999 , 10.554968811000037],
              [-74.88479734899994, 10.55498999200006 ],
              [-74.88408944299994, 10.55517351800006 ],
              [-74.88352076199993, 10.555327379000062],
              [-74.88301250399991, 10.555429373000038],
              [-74.88296037399994, 10.555430373000092],
              [-74.8828364229999 , 10.55543275000008 ],
              [-74.8826598089999 , 10.555436138000061],
              [-74.88201373499993, 10.555464168000071],
              [-74.88149381499994, 10.555509498000049],
              [-74.8815076699999 , 10.555539111000087],
              [-74.8815951599999 , 10.555726119000042],
              [-74.8816555539999 , 10.555841108000038],
              [-74.88169693599991, 10.55600572800006 ],
              [-74.88177682899993, 10.556205815000055],
              [-74.88191349499994, 10.556471284000054],
              [-74.88192801899993, 10.556490497000084],
              [-74.88201403399995, 10.55660427500004 ],
              [-74.88211518199995, 10.556738070000051],
              [-74.88223438499995, 10.55693296800007 ],
              [-74.88235636399992, 10.55715299700006 ],
              [-74.88250459199992, 10.55755558900006 ],
              [-74.88252620899993, 10.557740566000064],
              [-74.88254683299994, 10.557913628000051],
              [-74.8825276899999 , 10.557914827000047],
              [-74.88244983099992, 10.557919712000057],
              [-74.88236159599995, 10.557925243000057],
              [-74.88224177399991, 10.55793275700006 ],
              [-74.88190736299993, 10.557970838000074],
              [-74.88164728999993, 10.55800045300009 ],
              [-74.88132024099991, 10.558037695000053],
              [-74.88032009099993, 10.55816986800005 ],
              [-74.88022899299995, 10.558184986000072],
              [-74.87965643799993, 10.55828000400004 ],
              [-74.87854029199991, 10.55845611300009 ],
              [-74.87846165199994, 10.558469871000057],
              [-74.8776945059999 , 10.558594213000049],
              [-74.87742619799991, 10.558599632000039],
              [-74.8771042379999 , 10.558577014000036],
              [-74.87634212499995, 10.558438272000046],
              [-74.87577266399995, 10.558293190000086],
              [-74.8748392299999 , 10.558039289000078],
              [-74.87438536499991, 10.557893901000057],
              [-74.87422059699992, 10.557817937000038],
              [-74.87388443499992, 10.557662961000062],
              [-74.87380372099994, 10.557625749000067],
              [-74.87363196599995, 10.557684799000072],
              [-74.87346097499994, 10.557729284000061],
              [-74.8725629349999 , 10.557749152000042],
              [-74.87203513899993, 10.557674287000054],
              [-74.87156961999995, 10.557605126000055],
              [-74.87088832599994, 10.55754145900005 ],
              [-74.87008189799991, 10.557452646000058],
              [-74.86951262799994, 10.557383749000053],
              [-74.8685141489999 , 10.55717784300009 ],
              [-74.8680960719999 , 10.557117356000049],
              [-74.86794780999992, 10.557094281000047],
              [-74.8674735429999 , 10.557083757000044],
              [-74.86679243899994, 10.557084706000069],
              [-74.8663923819999 , 10.557117948000041],
              [-74.86605437199995, 10.557074838000062],
              [-74.86592083999994, 10.557013622000056],
              [-74.86567744299992, 10.556879423000055],
              [-74.86549035099995, 10.556736279000063],
              [-74.86537167299991, 10.556692618000056],
              [-74.86535104599994, 10.556739568000069],
              [-74.86531361599992, 10.557170477000057],
              [-74.86531403899994, 10.557334601000036],
              [-74.86541213899994, 10.557445725000036],
              [-74.86591148999992, 10.557988453000064],
              [-74.86612727699992, 10.55820906100007 ],
              [-74.86651774799992, 10.558608253000045],
              [-74.86692129799991, 10.559004451000078],
              [-74.86702696099991, 10.559108189000085],
              [-74.86743417899993, 10.55955263900006 ],
              [-74.8675919609999 , 10.559821879000083],
              [-74.86772345799994, 10.560066332000076],
              [-74.86808403499992, 10.560736642000052],
              [-74.86834906199994, 10.561213686000087],
              [-74.86855369199992, 10.56153999500009 ],
              [-74.86874002999991, 10.561796176000087],
              [-74.8687544629999 , 10.561816018000059],
              [-74.86838497799994, 10.562212602000045],
              [-74.86835548699992, 10.562241750000055],
              [-74.86784394099993, 10.562747362000039],
              [-74.86765709399992, 10.562962169000059],
              [-74.86763574499992, 10.562962221000078],
              [-74.86706971799993, 10.563635070000089],
              [-74.8668460689999 , 10.563900175000072],
              [-74.86612312699992, 10.56475712200006 ],
              [-74.86582557899993, 10.565107482000087],
              [-74.86502877599992, 10.565884725000046],
              [-74.86481387099991, 10.566081560000043],
              [-74.86433386099992, 10.56579356800006 ],
              [-74.8639517599999 , 10.565560282000092],
              [-74.86361433299993, 10.565354271000047],
              [-74.86279882799994, 10.564868559000047],
              [-74.86226660999995, 10.564551577000088],
              [-74.86102954499995, 10.563802340000052],
              [-74.8606892979999 , 10.563532732000056],
              [-74.85997898699992, 10.56285037300006 ],
              [-74.85931455499991, 10.562160383000048],
              [-74.85869873199994, 10.56139317800006 ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08296",
      "properties": {"id": "08296", "iso": "GAL", "name": "GALAPA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.89260368999993, 10.95030599100005 ],
              [-74.89257484799992, 10.950010361000068],
              [-74.8925585959999 , 10.949654173000056],
              [-74.89255771599994, 10.949634882000055],
              [-74.89255718699991, 10.949607471000093],
              [-74.8925477219999 , 10.949116635000053],
              [-74.89240209899992, 10.94909664700009 ],
              [-74.89241494799995, 10.948896773000058],
              [-74.8923863949999 , 10.948769710000079],
              [-74.8924049549999 , 10.948612665000041],
              [-74.89262053399995, 10.947885978000045],
              [-74.89261767799991, 10.947721794000074],
              [-74.89285181699995, 10.947677536000072],
              [-74.8929146349999 , 10.947543334000045],
              [-74.89292320099992, 10.94747052300005 ],
              [-74.89291035199994, 10.947367731000043],
              [-74.89286181099993, 10.94720069200008 ],
              [-74.8930402709999 , 10.947135020000076],
              [-74.89302599399991, 10.94671528200007 ],
              [-74.8929041419999 , 10.946732982000071],
              [-74.89277043999994, 10.946752403000062],
              [-74.89277757799994, 10.946498276000057],
              [-74.89280898699991, 10.945954331000053],
              [-74.89257890099992, 10.945938279000075],
              [-74.89256342699991, 10.94593719900007 ],
              [-74.89251924299992, 10.945932429000038],
              [-74.8923121549999 , 10.94591007300005 ],
              [-74.89226208399992, 10.94590858500004 ],
              [-74.89227659499994, 10.945788764000042],
              [-74.8923106609999 , 10.945624931000054],
              [-74.89217940499992, 10.945701296000038],
              [-74.89207681499994, 10.945711074000087],
              [-74.8916334299999 , 10.945696121000083],
              [-74.89073017299995, 10.945665658000053],
              [-74.8896114449999 , 10.94562675900005 ],
              [-74.88775299499991, 10.945558119000054],
              [-74.88609496099991, 10.945502235000049],
              [-74.88588886399992, 10.94543273100004 ],
              [-74.88479918899992, 10.945432724000057],
              [-74.88441337799992, 10.945432724000057],
              [-74.88388926699992, 10.945389048000038],
              [-74.88373338499991, 10.945376061000047],
              [-74.88373015899992, 10.94542999500004 ],
              [-74.88372990499994, 10.94543421800006 ],
              [-74.88290492999994, 10.945384411000077],
              [-74.8826791859999 , 10.945370782000055],
              [-74.88156180399994, 10.945279493000044],
              [-74.88157486799992, 10.945220280000058],
              [-74.88075234399992, 10.945184517000087],
              [-74.87952597499992, 10.945048255000074],
              [-74.87945244699995, 10.945040086000063],
              [-74.87940564899992, 10.945137192000061],
              [-74.87846415299992, 10.945192815000041],
              [-74.87839738799994, 10.945196759000055],
              [-74.87775075099995, 10.945177587000046],
              [-74.8776338269999 , 10.945174120000047],
              [-74.87766243199991, 10.945139143000063],
              [-74.87790349099993, 10.944844371000045],
              [-74.8776599649999 , 10.944686133000062],
              [-74.87790416899992, 10.944485911000072],
              [-74.87724388899994, 10.944127240000057],
              [-74.87671564899995, 10.943760803000089],
              [-74.87645710299995, 10.943580034000092],
              [-74.87645515399993, 10.943578671000068],
              [-74.87568731899995, 10.94304181700005 ],
              [-74.87549003899994, 10.942911741000046],
              [-74.8753187829999 , 10.94279882300009 ],
              [-74.87503740299991, 10.942565168000044],
              [-74.87494525299991, 10.942498441000055],
              [-74.8746253409999 , 10.94233663700004 ],
              [-74.87453312299994, 10.942282539000075],
              [-74.87427627099993, 10.942131860000075],
              [-74.87395142799994, 10.941936585000064],
              [-74.87352971299993, 10.94171764400005 ],
              [-74.8733473499999 , 10.941566697000042],
              [-74.87334536399993, 10.94156505300009 ],
              [-74.8731950259999 , 10.94146020800008 ],
              [-74.87213340199992, 10.940884173000086],
              [-74.87131394999994, 10.940360122000072],
              [-74.87044129299994, 10.939850556000067],
              [-74.86983030599993, 10.93944554500007 ],
              [-74.86949576299992, 10.939240725000047],
              [-74.86916390599993, 10.939172347000067],
              [-74.86514884099995, 10.938345068000046],
              [-74.86468951799992, 10.93825042700007 ],
              [-74.86412756899995, 10.93813464100009 ],
              [-74.86111998799993, 10.937514948000057],
              [-74.8610188689999 , 10.93749411300007 ],
              [-74.8607152379999 , 10.937431551000088],
              [-74.86066767599993, 10.937418143000059],
              [-74.86033152399995, 10.937323373000083],
              [-74.85999288499994, 10.937227901000085],
              [-74.85992525599994, 10.937208834000046],
              [-74.85975878699992, 10.93715641700004 ],
              [-74.85970124799991, 10.937138299000082],
              [-74.85965060699994, 10.937122353000063],
              [-74.8594435089999 , 10.937057143000061],
              [-74.85937512499993, 10.937035610000066],
              [-74.85924043999995, 10.936995204000084],
              [-74.85913918199992, 10.936964827000054],
              [-74.8588801969999 , 10.936887131000049],
              [-74.85865509999991, 10.936830024000074],
              [-74.85859022799991, 10.936816469000064],
              [-74.8585594459999 , 10.936810037000043],
              [-74.85850662199994, 10.936800043000062],
              [-74.85847610399992, 10.936784174000081],
              [-74.85846113399992, 10.936776389000045],
              [-74.85843523799991, 10.93676292300006 ],
              [-74.85840827499993, 10.936744694000083],
              [-74.85833387299994, 10.936694395000075],
              [-74.85832516199991, 10.936686072000043],
              [-74.85826845899993, 10.936631899000076],
              [-74.85826522699995, 10.936628811000048],
              [-74.85824361399995, 10.936608161000038],
              [-74.8580937079999 , 10.936743652000075],
              [-74.85779104099993, 10.936960659000079],
              [-74.85766806499993, 10.93703555400009 ],
              [-74.85753239399992, 10.937118180000084],
              [-74.85724287999994, 10.937253420000047],
              [-74.85718856199992, 10.937278793000075],
              [-74.8570521499999 , 10.937333988000091],
              [-74.85698273799994, 10.937362074000077],
              [-74.85696784999993, 10.937367488000064],
              [-74.85670791099994, 10.937462012000083],
              [-74.85658106999995, 10.937503955000068],
              [-74.85654556999992, 10.93751569400007 ],
              [-74.85640928799995, 10.937560759000064],
              [-74.8561761009999 , 10.93765236900009 ],
              [-74.85612653999993, 10.93766953100004 ],
              [-74.8558394069999 , 10.937768962000064],
              [-74.85568651999995, 10.93782914400009 ],
              [-74.85557273699993, 10.937873934000038],
              [-74.85524325499995, 10.938003631000072],
              [-74.85524097299992, 10.938004529000068],
              [-74.8552311219999 , 10.938007146000075],
              [-74.85514970999992, 10.938028770000074],
              [-74.8551490829999 , 10.93802893700007 ],
              [-74.85501254399992, 10.938065205000044],
              [-74.85497012199994, 10.938075747000084],
              [-74.85481981999993, 10.938113098000088],
              [-74.85461517399995, 10.938163953000071],
              [-74.85454828099995, 10.938178920000041],
              [-74.8544598979999 , 10.938198696000086],
              [-74.8540278559999 , 10.93829536700008 ],
              [-74.85392394099995, 10.93831861800004 ],
              [-74.8538657709999 , 10.938331405000042],
              [-74.85353965799993, 10.938403089000076],
              [-74.8534522779999 , 10.938428746000056],
              [-74.85322097399995, 10.93849666300008 ],
              [-74.85311016499992, 10.938529200000062],
              [-74.85294757999992, 10.938564673000087],
              [-74.8529056669999 , 10.938573818000066],
              [-74.85281331099992, 10.938593968000077],
              [-74.85278298899993, 10.938600584000085],
              [-74.85252362799991, 10.938649363000081],
              [-74.85246811899992, 10.938656256000058],
              [-74.85234159899994, 10.938671968000051],
              [-74.85195612699994, 10.93870884900008 ],
              [-74.85192917099994, 10.938710342000093],
              [-74.8516432639999 , 10.93872618000006 ],
              [-74.85154804899992, 10.938731454000049],
              [-74.85143488599994, 10.938742260000083],
              [-74.85122782499991, 10.938762033000046],
              [-74.8511996069999 , 10.938764727000091],
              [-74.85112772499991, 10.93877159200008 ],
              [-74.85109952099992, 10.938774285000079],
              [-74.85100593999994, 10.938778243000058],
              [-74.85094112699994, 10.938780984000061],
              [-74.85070571999995, 10.938790941000093],
              [-74.85053001499995, 10.938806204000059],
              [-74.85033391299993, 10.938823238000055],
              [-74.85024735699994, 10.938830757000062],
              [-74.85022811099992, 10.93876696400008 ],
              [-74.85021484399994, 10.938722987000062],
              [-74.8502025869999 , 10.938682358000051],
              [-74.85018383599993, 10.93863027100008 ],
              [-74.85018215499991, 10.93862560100007 ],
              [-74.85016664299991, 10.93858251100005 ],
              [-74.85013495699991, 10.938494495000043],
              [-74.85011178999991, 10.938430143000062],
              [-74.85010552799992, 10.938412749000065],
              [-74.85007287999991, 10.938322061000065],
              [-74.85003554899993, 10.93821836300009 ],
              [-74.8500129119999 , 10.938167563000093],
              [-74.84993120999991, 10.93798422000009 ],
              [-74.8499233579999 , 10.937966600000038],
              [-74.84981727399992, 10.937728542000059],
              [-74.8497956999999 , 10.937680129000057],
              [-74.84978331999991, 10.937644655000042],
              [-74.84977007499992, 10.937606704000075],
              [-74.84968678799993, 10.937368061000086],
              [-74.84965372999994, 10.937273337000079],
              [-74.8496224559999 , 10.937193275000084],
              [-74.84944892499993, 10.936749038000073],
              [-74.84938744699991, 10.936591655000086],
              [-74.84934002699993, 10.936496566000073],
              [-74.84933409399991, 10.936484668000048],
              [-74.8493187279999 , 10.936453856000071],
              [-74.84918168999991, 10.936179060000086],
              [-74.84907965999992, 10.935938225000086],
              [-74.8490760549999 , 10.935929717000079],
              [-74.84901829199993, 10.935793372000091],
              [-74.84901721799991, 10.935791879000078],
              [-74.84895168499992, 10.935700735000069],
              [-74.8488051779999 , 10.935496974000046],
              [-74.84881065499991, 10.935492706000048],
              [-74.84895168499992, 10.935382798000091],
              [-74.84898934099994, 10.935353452000072],
              [-74.84896698999995, 10.935300020000057],
              [-74.84893930099992, 10.935252140000046],
              [-74.84892825999992, 10.935233048000043],
              [-74.8488738289999 , 10.93511806500004 ],
              [-74.84887310099992, 10.935116526000058],
              [-74.84881912499992, 10.935046205000049],
              [-74.84875090299994, 10.934957324000038],
              [-74.84871766899994, 10.934938852000073],
              [-74.84869616399993, 10.93491335300007 ],
              [-74.84866925799992, 10.934881449000045],
              [-74.8486344499999 , 10.93481266100008 ],
              [-74.84862397699993, 10.934791963000066],
              [-74.84862004699994, 10.934786844000087],
              [-74.8484438559999 , 10.934557299000062],
              [-74.8484030919999 , 10.934504191000087],
              [-74.84828334399992, 10.934408985000061],
              [-74.84827383599992, 10.934401426000079],
              [-74.8482475319999 , 10.934407926000063],
              [-74.8482351099999 , 10.934410995000064],
              [-74.848232        , 10.934413397000071],
              [-74.84821725799992, 10.934395855000048],
              [-74.84754396499994, 10.933594705000075],
              [-74.84648284799994, 10.932167051000079],
              [-74.84629491699991, 10.931918497000083],
              [-74.84624085399992, 10.931846994000068],
              [-74.84570547599992, 10.931200969000088],
              [-74.84542350899994, 10.93089402000004 ],
              [-74.84499300499994, 10.930437560000087],
              [-74.84491267899995, 10.930325527000036],
              [-74.84433863399994, 10.929570048000073],
              [-74.84369974899994, 10.928834794000068],
              [-74.8430144639999 , 10.92817330400004 ],
              [-74.84283362499991, 10.92809716100004 ],
              [-74.84266230399993, 10.927987706000067],
              [-74.84247194699992, 10.927740241000038],
              [-74.84211502799991, 10.927602234000062],
              [-74.84177650099991, 10.927355307000084],
              [-74.84171051899995, 10.92730718000007 ],
              [-74.8415011269999 , 10.927202484000077],
              [-74.8410775829999 , 10.927164413000071],
              [-74.84075397599992, 10.927083510000045],
              [-74.84051127099991, 10.926959779000072],
              [-74.84029824399994, 10.926831528000037],
              [-74.83979960599993, 10.926733450000086],
              [-74.83965330899991, 10.92670118400008 ],
              [-74.83955411399995, 10.92664549400007 ],
              [-74.8393792469999 , 10.92651075200007 ],
              [-74.83897773399991, 10.926278684000067],
              [-74.83852502399992, 10.926007018000064],
              [-74.8387258809999 , 10.925451528000053],
              [-74.83902661899992, 10.924619797000048],
              [-74.8389835829999 , 10.924619905000043],
              [-74.83898483699994, 10.924617503000093],
              [-74.83917669499994, 10.924250164000057],
              [-74.83928072299994, 10.924033772000087],
              [-74.8394733909999 , 10.923632998000073],
              [-74.83964099099995, 10.923284369000044],
              [-74.84015683799993, 10.922166463000053],
              [-74.8403448869999 , 10.921758233000048],
              [-74.84017526299994, 10.921737376000067],
              [-74.84001638599995, 10.92171384900007 ],
              [-74.84001574099995, 10.921713666000073],
              [-74.84001296399992, 10.921712873000047],
              [-74.83988709999994, 10.921676951000052],
              [-74.83957884699993, 10.921516594000082],
              [-74.83957174199992, 10.92151289800006 ],
              [-74.83931759799992, 10.92138555300005 ],
              [-74.83929418699995, 10.921377168000049],
              [-74.83903741799992, 10.92128521300009 ],
              [-74.8388326779999 , 10.92121925400005 ],
              [-74.83871678699995, 10.921166369000048],
              [-74.83856309499993, 10.921063058000072],
              [-74.83812528699991, 10.920752064000055],
              [-74.83759941299991, 10.920365185000037],
              [-74.83752386499992, 10.920310444000052],
              [-74.8372911589999 , 10.920141802000046],
              [-74.83662210099993, 10.919659137000053],
              [-74.83596846199993, 10.919187528000066],
              [-74.83540790399991, 10.918766898000058],
              [-74.83513953699992, 10.918569944000069],
              [-74.83497658199991, 10.918496660000073],
              [-74.83477497799993, 10.918403840000053],
              [-74.83478046599993, 10.91840822100005 ],
              [-74.83471923199994, 10.918420930000082],
              [-74.8345505019999 , 10.91838894700004 ],
              [-74.83442064999991, 10.918364342000075],
              [-74.8340079539999 , 10.918132011000068],
              [-74.83400124999991, 10.918128238000065],
              [-74.83385077899993, 10.918043528000055],
              [-74.83363768399994, 10.917923556000062],
              [-74.83363899099993, 10.91791760500007 ],
              [-74.83371982699992, 10.917549892000068],
              [-74.8337913869999 , 10.917224385000054],
              [-74.83379030399993, 10.917222460000062],
              [-74.83377783999993, 10.917200348000051],
              [-74.83367754099993, 10.917183529000056],
              [-74.83367513899992, 10.917183126000054],
              [-74.8336393159999 , 10.917145958000049],
              [-74.83359597399993, 10.917100985000047],
              [-74.83347268099993, 10.916923626000084],
              [-74.8331779319999 , 10.916595010000037],
              [-74.8328788789999 , 10.916305421000061],
              [-74.83239859199995, 10.915868613000043],
              [-74.83221069199993, 10.915733215000046],
              [-74.83215554499992, 10.915693476000058],
              [-74.83215559599995, 10.91571269700006 ],
              [-74.83202152099994, 10.915708076000044],
              [-74.83191005999993, 10.915707259000044],
              [-74.83181054199991, 10.915622511000038],
              [-74.83163933199995, 10.915573972000061],
              [-74.83160662499995, 10.915577519000067],
              [-74.83132058799993, 10.915608526000085],
              [-74.83113968899994, 10.915628131000062],
              [-74.83109786799992, 10.915632662000064],
              [-74.8308984279999 , 10.915551988000061],
              [-74.8308420699999 , 10.915529186000072],
              [-74.8307042639999 , 10.91547343600007 ],
              [-74.8307015179999 , 10.915472325000053],
              [-74.83069874599994, 10.915471203000038],
              [-74.8305157499999 , 10.915397177000045],
              [-74.83013557299995, 10.915243385000053],
              [-74.82975440999991, 10.915089187000092],
              [-74.82941112399993, 10.914893947000053],
              [-74.82925537699992, 10.914861084000052],
              [-74.82921909599992, 10.914853429000061],
              [-74.82921775199992, 10.91485792900005 ],
              [-74.82897572699994, 10.914834007000081],
              [-74.82857396499992, 10.91481486400005 ],
              [-74.8284626329999 , 10.914800510000077],
              [-74.82828837499994, 10.91475745300005 ],
              [-74.82825504099992, 10.914745762000052],
              [-74.82798826599992, 10.91465220100008 ],
              [-74.8278381749999 , 10.914603990000046],
              [-74.82782412099994, 10.914599475000045],
              [-74.82763858599992, 10.91449446200005 ],
              [-74.82761915599991, 10.914483464000057],
              [-74.82685283799992, 10.913986682000086],
              [-74.82681203899995, 10.913953736000053],
              [-74.82667494799995, 10.913916096000037],
              [-74.82673154699995, 10.913840417000074],
              [-74.82683832399994, 10.913737365000088],
              [-74.82688890199995, 10.913688551000064],
              [-74.8269361799999 , 10.913642921000076],
              [-74.82704801499995, 10.913526328000046],
              [-74.82711939899991, 10.913395457000092],
              [-74.82716291699995, 10.913256198000056],
              [-74.82719078299993, 10.913167030000068],
              [-74.82728120199994, 10.912857700000075],
              [-74.82743348799994, 10.912424637000072],
              [-74.82757387599992, 10.912098651000065],
              [-74.82765715699992, 10.91197253900009 ],
              [-74.82774757699991, 10.911879741000064],
              [-74.82788558499993, 10.91177504500007 ],
              [-74.82812115199994, 10.911629898000058],
              [-74.82816237999992, 10.911589433000074],
              [-74.82826126399993, 10.911440171000038],
              [-74.82827328999991, 10.911422019000042],
              [-74.82834601099995, 10.91129090000004 ],
              [-74.82839566199993, 10.911201378000044],
              [-74.82842254799993, 10.911033419000091],
              [-74.82839459099995, 10.910781672000041],
              [-74.82820202199991, 10.910251535000043],
              [-74.82811260199992, 10.910004964000052],
              [-74.82805734099992, 10.909755525000037],
              [-74.8280417549999 , 10.909075342000051],
              [-74.8281019399999 , 10.908569312000054],
              [-74.82813128799995, 10.908470310000041],
              [-74.82825682099991, 10.908275226000058],
              [-74.82843839899994, 10.908061180000061],
              [-74.82874305599995, 10.907900789000053],
              [-74.82914564299995, 10.907758150000063],
              [-74.82957220799995, 10.907596668000053],
              [-74.8297563719999 , 10.907501786000068],
              [-74.82975802399994, 10.907500634000087],
              [-74.82992101899993, 10.907386966000047],
              [-74.82994725899994, 10.907368658000053],
              [-74.83023850299992, 10.907134779000046],
              [-74.83032913699992, 10.906965931000059],
              [-74.83049420799995, 10.906524839000042],
              [-74.83060070699992, 10.90632897100005 ],
              [-74.83077171699995, 10.906144644000051],
              [-74.83148752999995, 10.905634735000092],
              [-74.8319374969999 , 10.905313364000051],
              [-74.83212880999992, 10.905176726000093],
              [-74.83212891999995, 10.90522          ],
              [-74.8324241439999 , 10.905006491000051],
              [-74.83282409999993, 10.904759477000084],
              [-74.83331199799994, 10.904427814000087],
              [-74.83331986199994, 10.904418256000042],
              [-74.83341603699995, 10.904301219000047],
              [-74.83342706899992, 10.904267608000055],
              [-74.83347282799991, 10.904128195000055],
              [-74.83350516699994, 10.904077249000068],
              [-74.8335700579999 , 10.903975022000054],
              [-74.83382567799993, 10.903647207000063],
              [-74.83405000399995, 10.90317454600006 ],
              [-74.83419055699994, 10.902848384000038],
              [-74.83434634999992, 10.902510447000054],
              [-74.83437855499994, 10.90244060300006 ],
              [-74.83441917099992, 10.902332410000042],
              [-74.83449223799994, 10.90213777200006 ],
              [-74.83451098899991, 10.90211026500009 ],
              [-74.8346722579999 , 10.901873692000038],
              [-74.83474085799992, 10.90177305800006 ],
              [-74.8349993839999 , 10.901506441000038],
              [-74.83516413599995, 10.90143289200006 ],
              [-74.83544308799992, 10.90125931600005 ],
              [-74.83565148199995, 10.901135786000054],
              [-74.83591365599995, 10.900982189000047],
              [-74.83602871399995, 10.900868630000048],
              [-74.83605505299994, 10.90084263500006 ],
              [-74.83607630099993, 10.90082166700006 ],
              [-74.83611956099992, 10.900673553000047],
              [-74.83618163199992, 10.900461033000056],
              [-74.8363071409999 , 10.89997737400006 ],
              [-74.83634270399995, 10.89984032700005 ],
              [-74.83645860699994, 10.899228858000072],
              [-74.83646162699995, 10.899197087000061],
              [-74.83651073499993, 10.898678915000062],
              [-74.83651386999992, 10.89864577700007 ],
              [-74.8365203539999 , 10.898227245000044],
              [-74.8365180049999 , 10.89821277100009 ],
              [-74.83648576999991, 10.89801433200006 ],
              [-74.83639008199992, 10.897620157000063],
              [-74.83637909799995, 10.897495130000038],
              [-74.83635891099993, 10.89726524200006 ],
              [-74.83636031799995, 10.89721686900009 ],
              [-74.83636456099993, 10.897070984000038],
              [-74.83636632999992, 10.897010115000057],
              [-74.83636791399994, 10.896955672000047],
              [-74.83637629799995, 10.896667310000055],
              [-74.83641639399991, 10.896089954000047],
              [-74.83645317299994, 10.895682556000054],
              [-74.83655377999992, 10.895037991000038],
              [-74.83659088299993, 10.89475764100007 ],
              [-74.8366595899999 , 10.89443589700005 ],
              [-74.83667311699992, 10.894372541000052],
              [-74.83667477299991, 10.894348688000036],
              [-74.83668408499994, 10.894214526000042],
              [-74.83674765599994, 10.893930373000046],
              [-74.83681893399995, 10.893702254000061],
              [-74.8369277889999 , 10.893373139000062],
              [-74.8371236349999 , 10.892926430000045],
              [-74.83734896999994, 10.892294350000043],
              [-74.83750692399991, 10.891686957000047],
              [-74.83750922999991, 10.891673050000065],
              [-74.83753099199993, 10.891542031000085],
              [-74.8375367239999 , 10.891507519000072],
              [-74.83757362999995, 10.89114495800004 ],
              [-74.83757329899993, 10.891016324000077],
              [-74.83757321699994, 10.890984280000055],
              [-74.8375800579999 , 10.890704008000057],
              [-74.83762477499994, 10.890434843000037],
              [-74.8376727289999 , 10.890273986000068],
              [-74.83770727199993, 10.890158116000066],
              [-74.8378689189999 , 10.889765350000062],
              [-74.8379352689999 , 10.889606211000057],
              [-74.83796681199993, 10.889530558000047],
              [-74.83809482799995, 10.889298559000054],
              [-74.83836228699994, 10.888868157000047],
              [-74.83841495199994, 10.888774971000089],
              [-74.83851666599992, 10.888594996000052],
              [-74.83867829699994, 10.888107268000056],
              [-74.83870484899995, 10.888003944000047],
              [-74.83875189199995, 10.88782087800007 ],
              [-74.83878206599991, 10.88770345200004 ],
              [-74.83896616899995, 10.887009171000045],
              [-74.83901686699994, 10.886670989000038],
              [-74.83906024299995, 10.886381637000056],
              [-74.83911101399991, 10.88583157000005 ],
              [-74.83911565699992, 10.885671865000063],
              [-74.83911786099992, 10.885596056000054],
              [-74.83911713999993, 10.885568111000055],
              [-74.83911521999994, 10.885511343000076],
              [-74.83911392899995, 10.885473192000063],
              [-74.8388464869999 , 10.885460141000067],
              [-74.83880917699992, 10.885458319000065],
              [-74.83882019499993, 10.885428338000054],
              [-74.83882068999992, 10.885428311000055],
              [-74.83891089699995, 10.885218806000069],
              [-74.83901914699993, 10.884967402000086],
              [-74.83916355499991, 10.88466210200005 ],
              [-74.83932616999994, 10.884380663000059],
              [-74.83951313999995, 10.884158958000057],
              [-74.83973638999993, 10.883931190000055],
              [-74.8398813039999 , 10.883823200000052],
              [-74.84005644399991, 10.883709151000062],
              [-74.84021956799995, 10.883625031000065],
              [-74.84039469599992, 10.883505007000053],
              [-74.84055786699992, 10.883438824000052],
              [-74.84068474799994, 10.883378715000049],
              [-74.8408902189999 , 10.883294487000057],
              [-74.84114787099992, 10.882956859000046],
              [-74.84123703599994, 10.883023329000082],
              [-74.84133696899994, 10.882940593000058],
              [-74.84151778299992, 10.882677047000072],
              [-74.84161401699993, 10.882461546000059],
              [-74.8417523629999 , 10.88215027700005 ],
              [-74.84187267099992, 10.881886887000064],
              [-74.84199906799995, 10.881635436000067],
              [-74.84224609199993, 10.881246159000057],
              [-74.8424328879999 , 10.880958685000053],
              [-74.84254139599994, 10.880808930000057],
              [-74.8426499759999 , 10.880689071000063],
              [-74.84292160299992, 10.88045519600007 ],
              [-74.84322938299994, 10.880167415000074],
              [-74.84356128399992, 10.879849676000049],
              [-74.84402598299994, 10.879417998000065],
              [-74.84403438899994, 10.87940932400005 ],
              [-74.84406737599994, 10.879375293000066],
              [-74.8441828519999 , 10.879256160000068],
              [-74.84432752499993, 10.879058482000062],
              [-74.8445325319999 , 10.87879487300006 ],
              [-74.84467706899994, 10.878543376000039],
              [-74.8448276069999 , 10.878273928000056],
              [-74.8449538129999 , 10.877950730000066],
              [-74.84504365799995, 10.877603701000055],
              [-74.84510352199993, 10.877358400000048],
              [-74.84502475499994, 10.877310764000072],
              [-74.84486111599995, 10.877191589000063],
              [-74.84472758299995, 10.87701852300006 ],
              [-74.84470715299994, 10.876978392000069],
              [-74.84466070099995, 10.876887147000048],
              [-74.84463023899991, 10.876803514000073],
              [-74.84461668499995, 10.876702833000081],
              [-74.84461173299991, 10.876666037000064],
              [-74.84461144199992, 10.876552433000086],
              [-74.8445990379999 , 10.876432879000049],
              [-74.84455042299993, 10.87634928600005 ],
              [-74.84440506099992, 10.876277899000058],
              [-74.84430820799992, 10.87625422800005 ],
              [-74.84418118999992, 10.876260527000056],
              [-74.84404200699993, 10.876242939000065],
              [-74.84388472299992, 10.876243333000048],
              [-74.8437394959999 , 10.876225760000068],
              [-74.84358821199993, 10.876208202000043],
              [-74.84340051499993, 10.876142901000037],
              [-74.8431824939999 , 10.876047784000093],
              [-74.84298869899993, 10.875964559000067],
              [-74.84282509099995, 10.87585733900005 ],
              [-74.84273415199993, 10.875779838000085],
              [-74.84263691599995, 10.875606683000058],
              [-74.84260640899993, 10.875505109000073],
              [-74.84256972999992, 10.875355717000048],
              [-74.84256923799995, 10.875164379000068],
              [-74.84256882699992, 10.875003826000068],
              [-74.84256873299995, 10.874967062000053],
              [-74.84257740599992, 10.87474304300008 ],
              [-74.84258007699992, 10.874674052000046],
              [-74.8426339209999 , 10.874440723000077],
              [-74.84271781299992, 10.874129584000059],
              [-74.84278369299994, 10.87387231300005 ],
              [-74.84284952899992, 10.873597094000047],
              [-74.8428370769999 , 10.873459599000057],
              [-74.84281248199994, 10.873304201000053],
              [-74.84275778999995, 10.873208670000054],
              [-74.84264256499995, 10.873095353000053],
              [-74.84250923499991, 10.87300001500006 ],
              [-74.84238197199994, 10.872910646000037],
              [-74.84221234699993, 10.872815403000061],
              [-74.84202456999992, 10.872720206000054],
              [-74.84183081399993, 10.872648938000054],
              [-74.84158259399993, 10.872571828000048],
              [-74.84140097699992, 10.872518471000092],
              [-74.84115876299995, 10.872423406000053],
              [-74.8410740729999 , 10.872423620000063],
              [-74.8408985989999 , 10.872406119000061],
              [-74.84071707399994, 10.87238863600004 ],
              [-74.8404689919999 , 10.87236534200008 ],
              [-74.84032376399995, 10.872347767000065],
              [-74.8401058849999 , 10.872306459000072],
              [-74.83978506099993, 10.872223550000058],
              [-74.83959131199992, 10.872158264000063],
              [-74.83924016799995, 10.872045531000083],
              [-74.83890944199993, 10.87189126800007 ],
              [-74.83885378699995, 10.87180591300006 ],
              [-74.83894074199992, 10.871749470000054],
              [-74.83921243499992, 10.871545498000046],
              [-74.8395807199999 , 10.871263549000048],
              [-74.83971350099995, 10.871143630000063],
              [-74.83989451799994, 10.87096379400009 ],
              [-74.84002727899991, 10.870837893000044],
              [-74.84017343699992, 10.870677669000088],
              [-74.84020221299994, 10.870646125000064],
              [-74.84047982099992, 10.870363437000037],
              [-74.8406848969999 , 10.870154608000064],
              [-74.84096253299992, 10.869908759000054],
              [-74.84116169599992, 10.86972888200006 ],
              [-74.84121874599992, 10.869677873000057],
              [-74.84134275899993, 10.869566990000067],
              [-74.84152387199993, 10.869423032000043],
              [-74.84201892199991, 10.869039112000053],
              [-74.84226648099991, 10.868859111000063],
              [-74.84246581299993, 10.868745004000061],
              [-74.84249785799994, 10.868720804000077],
              [-74.84261674399994, 10.868631022000045],
              [-74.8428825439999 , 10.868486850000068],
              [-74.84320273499992, 10.868324603000076],
              [-74.84359465499995, 10.868139148000068],
              [-74.8436981669999 , 10.868090167000048],
              [-74.84386131299993, 10.868018001000053],
              [-74.84407281199992, 10.867933768000057],
              [-74.84421784199992, 10.867873605000057],
              [-74.84438096899993, 10.86779546200006 ],
              [-74.84447760199993, 10.86773542800006 ],
              [-74.84459233799993, 10.867657406000092],
              [-74.8448884359999 , 10.867537073000051],
              [-74.8451302659999 , 10.86748265400007 ],
              [-74.84534192699994, 10.86745820300007 ],
              [-74.84548842399994, 10.867433690000041],
              [-74.84548845099994, 10.867433684000048],
              [-74.84561942099992, 10.867397851000078],
              [-74.84569556299994, 10.86737596000006 ],
              [-74.8457289509999 , 10.867361177000078],
              [-74.84573490799994, 10.86737022200009 ],
              [-74.84575160299994, 10.867395571000088],
              [-74.84575707499994, 10.867409691000091],
              [-74.84583321699995, 10.867387800000074],
              [-74.8459579009999 , 10.86733259600004 ],
              [-74.84599990899994, 10.867315071000064],
              [-74.8461130419999 , 10.867267875000039],
              [-74.84627008599995, 10.867207912000083],
              [-74.8463576229999 , 10.867173731000037],
              [-74.84637002399995, 10.867168889000084],
              [-74.84652992299993, 10.867071807000059],
              [-74.84675689799991, 10.866948452000088],
              [-74.84676340999994, 10.866944913000054],
              [-74.84677375299992, 10.86693929200004 ],
              [-74.8467979589999 , 10.866927522000083],
              [-74.84680254799991, 10.86692529000004 ],
              [-74.84680269499995, 10.866925219000052],
              [-74.84680519199992, 10.866924005000044],
              [-74.84680871899991, 10.866922290000048],
              [-74.84690739799993, 10.866874309000082],
              [-74.8470406479999 , 10.866872405000038],
              [-74.84714915199993, 10.866880020000053],
              [-74.84724432999991, 10.866891441000064],
              [-74.84735473699993, 10.866914284000075],
              [-74.8475749829999 , 10.866964256000074],
              [-74.84763470699994, 10.866974249000066],
              [-74.84781278299994, 10.866979719000085],
              [-74.84781450299994, 10.866979798000045],
              [-74.8479365149999 , 10.866985430000057],
              [-74.84799013899993, 10.866984696000088],
              [-74.84799566599992, 10.86698462000004 ],
              [-74.8480754759999 , 10.86698352600007 ],
              [-74.84813414199994, 10.866976935000082],
              [-74.84830654399991, 10.866957565000064],
              [-74.8483187089999 , 10.86695473800006 ],
              [-74.84836068099992, 10.866944982000064],
              [-74.8484197329999 , 10.866932172000077],
              [-74.84867923199994, 10.86688674800007 ],
              [-74.84871521999992, 10.866883116000054],
              [-74.84873375199993, 10.866878535000069],
              [-74.84873450799995, 10.866878348000057],
              [-74.84875219499992, 10.866873976000079],
              [-74.84877070399995, 10.866869400000041],
              [-74.84877139699995, 10.866869229000088],
              [-74.84882110599995, 10.866856942000084],
              [-74.84891747499995, 10.866820060000066],
              [-74.84904834499991, 10.866811732000087],
              [-74.84908522699993, 10.866796266000051],
              [-74.8491254189999 , 10.86676327400005 ],
              [-74.84915390099991, 10.866754750000041],
              [-74.84915477499993, 10.866754488000083],
              [-74.84925384699994, 10.86672483700005 ],
              [-74.84984603399994, 10.866478185000062],
              [-74.85014813599992, 10.866339896000056],
              [-74.85041395499991, 10.866207681000049],
              [-74.85076428899993, 10.866009475000055],
              [-74.8511690659999 , 10.865811131000044],
              [-74.85142889399992, 10.865702844000054],
              [-74.85188213599992, 10.865528294000057],
              [-74.85219041699992, 10.865443804000051],
              [-74.85228288199994, 10.86542070300004 ],
              [-74.85248058999991, 10.865371309000068],
              [-74.8527648399999 , 10.86534667400008 ],
              [-74.8530671179999 , 10.865280133000056],
              [-74.8533088659999 , 10.865195809000056],
              [-74.85350223599994, 10.865117586000054],
              [-74.8537439179999 , 10.86500933900004 ],
              [-74.85392529399991, 10.86497300600007 ],
              [-74.8541067239999 , 10.864954605000037],
              [-74.85431840899992, 10.864942105000068],
              [-74.8546207569999 , 10.864899482000055],
              [-74.85479611599993, 10.864875118000043],
              [-74.8550560569999 , 10.864808684000081],
              [-74.85557594999995, 10.864681791000066],
              [-74.85589074599994, 10.864591012000062],
              [-74.85591709199991, 10.864629949000062],
              [-74.85593733999991, 10.86465987300005 ],
              [-74.8561464849999 , 10.864588685000058],
              [-74.85630968499993, 10.864540438000063],
              [-74.85657553599992, 10.864420173000042],
              [-74.85697431699992, 10.864245757000049],
              [-74.85751817199991, 10.864029108000068],
              [-74.8577597929999 , 10.863896949000036],
              [-74.8580497719999 , 10.863752702000056],
              [-74.85844230099991, 10.863500564000049],
              [-74.8587262129999 , 10.863350358000048],
              [-74.8590524519999 , 10.863194062000048],
              [-74.85920350599991, 10.863127905000056],
              [-74.85943923399992, 10.863055546000055],
              [-74.85960849999992, 10.86301325900007 ],
              [-74.85977175799991, 10.862988923000046],
              [-74.86006816399993, 10.862988165000047],
              [-74.86009082699991, 10.862988104000067],
              [-74.86018309399992, 10.862987868000062],
              [-74.8603403699999 , 10.86298746500006 ],
              [-74.86036343899991, 10.862992471000041],
              [-74.8604493119999 , 10.863011104000066],
              [-74.86055216499994, 10.863016814000048],
              [-74.86077605699995, 10.863046139000062],
              [-74.8609152009999 , 10.863051763000044],
              [-74.86104223199993, 10.863051435000045],
              [-74.86105264799994, 10.863047591000054],
              [-74.86120539599995, 10.862991225000087],
              [-74.86132622299993, 10.862931120000042],
              [-74.86141291799993, 10.862880586000074],
              [-74.86150137999994, 10.862829021000039],
              [-74.86164016099991, 10.862697121000053],
              [-74.86173068799991, 10.86261915700004 ],
              [-74.86182717799994, 10.862505302000045],
              [-74.86189938399991, 10.862361615000054],
              [-74.86197156899993, 10.862205969000058],
              [-74.86208591399992, 10.861984438000093],
              [-74.86219387199992, 10.861631382000041],
              [-74.86230791799994, 10.861296252000045],
              [-74.86240992199993, 10.860979085000054],
              [-74.86283334599995, 10.860977999000056],
              [-74.86408547999991, 10.860968791000062],
              [-74.86483556799993, 10.860972840000045],
              [-74.86535370099995, 10.860987758000078],
              [-74.86550046999992, 10.860991984000066],
              [-74.86559780399995, 10.860994787000038],
              [-74.86614822999991, 10.860981407000054],
              [-74.86640892899993, 10.860992273000079],
              [-74.86655355499994, 10.860998299000073],
              [-74.8667652609999 , 10.860991777000038],
              [-74.86684993299991, 10.86098557400004 ],
              [-74.86698886999994, 10.860913463000088],
              [-74.86716398199991, 10.860799403000044],
              [-74.86723641499992, 10.860739422000051],
              [-74.8673811939999 , 10.86058956900007 ],
              [-74.86745954799994, 10.860481741000058],
              [-74.86752581199994, 10.86037992200005 ],
              [-74.86759800999994, 10.860230247000061],
              [-74.86765211599993, 10.860104541000055],
              [-74.86774238999993, 10.859930906000045],
              [-74.86791120999993, 10.859721196000066],
              [-74.8679775089999 , 10.859631333000038],
              [-74.86812832399994, 10.85947548200005 ],
              [-74.86827316199992, 10.859349544000054],
              [-74.86843011499991, 10.859229551000055],
              [-74.86859312599995, 10.859109541000066],
              [-74.86873804899994, 10.85901350100005 ],
              [-74.86886482099993, 10.858917507000058],
              [-74.86904599099995, 10.858803427000055],
              [-74.86916676499993, 10.858725383000092],
              [-74.8692997579999 , 10.85869514300009 ],
              [-74.86943266199995, 10.858629023000049],
              [-74.86953538499995, 10.85858690200007 ],
              [-74.86961383999994, 10.858520927000086],
              [-74.8696982269999 , 10.858407103000047],
              [-74.86980069399993, 10.85826931400004 ],
              [-74.8699029839999 , 10.858065750000037],
              [-74.8699509889999 , 10.857922123000037],
              [-74.86999901999991, 10.85778447000007 ],
              [-74.87004712999993, 10.857676720000086],
              [-74.87011326899994, 10.85752707100005 ],
              [-74.87016134499993, 10.857407357000056],
              [-74.87021539699992, 10.857263714000055],
              [-74.87023918499995, 10.857194723000077],
              [-74.8702814259999 , 10.857072206000055],
              [-74.87033539499993, 10.856892686000037],
              [-74.87047388099995, 10.85665315700004 ],
              [-74.87054605199995, 10.85649750600004 ],
              [-74.87063031199995, 10.856335844000057],
              [-74.87078701199994, 10.856120186000055],
              [-74.87089704399995, 10.85599869400005 ],
              [-74.87094985199991, 10.855940385000054],
              [-74.8710462219999 , 10.855784673000073],
              [-74.87105186399992, 10.85577517900009 ],
              [-74.8711245099999 , 10.855652925000072],
              [-74.87117256699992, 10.855527231000053],
              [-74.87121441699992, 10.855341767000084],
              [-74.87125035999992, 10.855210129000056],
              [-74.87124999399992, 10.855072606000078],
              [-74.87124969199994, 10.854958999000075],
              [-74.87126739299993, 10.85479153600005 ],
              [-74.87127918699991, 10.85467790100006 ],
              [-74.87132734199992, 10.854588084000056],
              [-74.87136337599992, 10.854492320000077],
              [-74.87147790799992, 10.854342541000051],
              [-74.87156823999993, 10.854192823000062],
              [-74.8715705919999 , 10.854187136000064],
              [-74.8716103189999 , 10.854091064000045],
              [-74.87166431299994, 10.853923508000037],
              [-74.87166402699995, 10.85381588100006 ],
              [-74.8716938259999 , 10.853648381000085],
              [-74.8716933149999 , 10.853457047000063],
              [-74.87171115699994, 10.853343392000056],
              [-74.87171683299994, 10.853272386000071],
              [-74.87175077699993, 10.85321915600008 ],
              [-74.87175408999991, 10.853213960000062],
              [-74.87182400799992, 10.853104314000063],
              [-74.87183581499994, 10.853103691000058],
              [-74.87322556799995, 10.853030189000037],
              [-74.87354145299992, 10.853009058000055],
              [-74.87462225999991, 10.852936764000049],
              [-74.87529815799991, 10.852915063000069],
              [-74.87614400599995, 10.852887911000039],
              [-74.87899673599992, 10.852936603000046],
              [-74.8807098229999 , 10.85296584300005 ],
              [-74.88120173699991, 10.852782554000044],
              [-74.88211535799991, 10.852442129000053],
              [-74.88351739599995, 10.851973341000075],
              [-74.88386416399993, 10.851857392000056],
              [-74.88422383499994, 10.851760829000057],
              [-74.8856800069999 , 10.851369885000054],
              [-74.88602351799994, 10.851256239000065],
              [-74.8875873309999 , 10.850738869000054],
              [-74.88804019399993, 10.85058904400006 ],
              [-74.88900463399995, 10.850249707000046],
              [-74.8891468349999 , 10.850216622000062],
              [-74.88949268099992, 10.85013615400004 ],
              [-74.89000390399991, 10.850044400000058],
              [-74.8906740999999 , 10.849924115000078],
              [-74.89120677499994, 10.849828511000055],
              [-74.89195685499993, 10.850017381000043],
              [-74.89213523699993, 10.850041391000047],
              [-74.89236585999993, 10.850072431000058],
              [-74.89239580599991, 10.850085138000054],
              [-74.89278655699991, 10.850250943000049],
              [-74.89280995399992, 10.850321229000087],
              [-74.8929583119999 , 10.850766910000061],
              [-74.8932425559999 , 10.851255580000043],
              [-74.89346036099994, 10.851630028000045],
              [-74.89355273199993, 10.851731321000045],
              [-74.89405269799994, 10.852279597000063],
              [-74.89410058699991, 10.852362821000042],
              [-74.8943493889999 , 10.852795232000062],
              [-74.8950435459999 , 10.852700836000054],
              [-74.89533097099991, 10.852661750000038],
              [-74.89535980599993, 10.852657830000055],
              [-74.89533581099994, 10.852518933000056],
              [-74.8951470909999 , 10.851426496000045],
              [-74.89511749199994, 10.851255154000057],
              [-74.89532867499992, 10.850530881000054],
              [-74.89534495599992, 10.850475040000049],
              [-74.8953762889999 , 10.850367565000056],
              [-74.8953951659999 , 10.850334868000061],
              [-74.89603191299994, 10.84923193800006 ],
              [-74.89699413099993, 10.847680364000041],
              [-74.89722400099993, 10.847309697000071],
              [-74.89761494699991, 10.846679296000048],
              [-74.8977993439999 , 10.846384579000073],
              [-74.89797748199993, 10.846099865000042],
              [-74.89807791899995, 10.84593933800005 ],
              [-74.89822088299991, 10.845710841000084],
              [-74.89846469999992, 10.845321151000064],
              [-74.89846691499991, 10.845317610000052],
              [-74.89880119199995, 10.84478333800007 ],
              [-74.89897540999993, 10.844504886000038],
              [-74.89951462399995, 10.843643058000055],
              [-74.8996375989999 , 10.84375345300009 ],
              [-74.89973823199995, 10.843843789000061],
              [-74.89976442299991, 10.843867300000056],
              [-74.89986990699992, 10.843980111000064],
              [-74.90019259799993, 10.844325215000083],
              [-74.90024469999992, 10.84438093600005 ],
              [-74.90024766599993, 10.844384107000053],
              [-74.9003571529999 , 10.844501198000046],
              [-74.90056918199991, 10.844727954000064],
              [-74.90056921699994, 10.844740931000047],
              [-74.90064382299994, 10.844822564000083],
              [-74.90067569399991, 10.844857437000087],
              [-74.90073552599995, 10.844921930000055],
              [-74.90076731999994, 10.84495620000007 ],
              [-74.90083038499995, 10.84502417400006 ],
              [-74.90100842399994, 10.84520454200009 ],
              [-74.90122838099995, 10.845391506000055],
              [-74.90129691999994, 10.84543551300004 ],
              [-74.90138294699995, 10.845490748000088],
              [-74.90156481199995, 10.845607516000086],
              [-74.90179645899991, 10.84580359000006 ],
              [-74.9018364879999 , 10.845884546000093],
              [-74.90186106399995, 10.845934248000049],
              [-74.90188142699992, 10.845975431000056],
              [-74.90197583999992, 10.846167897000043],
              [-74.90200040799994, 10.846324964000075],
              [-74.90202218499991, 10.846464186000048],
              [-74.90202284399993, 10.846503358000064],
              [-74.90202628499992, 10.846707893000087],
              [-74.90203421699994, 10.847158519000061],
              [-74.90202662799993, 10.847270783000056],
              [-74.90201697899994, 10.847413528000061],
              [-74.90201438299994, 10.847451935000038],
              [-74.90200902399994, 10.847531204000063],
              [-74.90200723799995, 10.847583928000063],
              [-74.90200649299993, 10.847605942000087],
              [-74.9020062749999 , 10.847612347000052],
              [-74.90200218799993, 10.847733033000054],
              [-74.9019991369999 , 10.847823109000046],
              [-74.9019978629999 , 10.847860737000076],
              [-74.90199562599992, 10.84792679700007 ],
              [-74.9019915369999 , 10.84797704600004 ],
              [-74.90199079399991, 10.847986176000063],
              [-74.90198764999991, 10.848024806000069],
              [-74.90198265899994, 10.84808613000007 ],
              [-74.90197428099992, 10.848189085000058],
              [-74.90195141899994, 10.848330195000074],
              [-74.90195046799994, 10.848336065000069],
              [-74.90193021399995, 10.848461073000067],
              [-74.90190751799992, 10.848601159000054],
              [-74.90190358399991, 10.848708392000049],
              [-74.90189895899994, 10.848834473000068],
              [-74.90189393399993, 10.848971462000065],
              [-74.90192938099995, 10.849049385000058],
              [-74.90213260999991, 10.849174382000058],
              [-74.9023469739999 , 10.849232617000041],
              [-74.9024751039999 , 10.84926388200006 ],
              [-74.90261467499994, 10.84926977300006 ],
              [-74.90270991299991, 10.849255288000052],
              [-74.90286084199994, 10.849224302000039],
              [-74.90296288899992, 10.849186774000088],
              [-74.9031072439999 , 10.849211024000056],
              [-74.90320517599991, 10.849247108000043],
              [-74.9033475729999 , 10.84932195500005 ],
              [-74.9034713819999 , 10.84939924300005 ],
              [-74.90362293599992, 10.849453328000038],
              [-74.9037836679999 , 10.849491258000057],
              [-74.90403031999995, 10.849512464000043],
              [-74.90422573099994, 10.84952254700005 ],
              [-74.90444199099994, 10.849520982000058],
              [-74.90455186399993, 10.849505524000051],
              [-74.90460260999993, 10.84949838500006 ],
              [-74.90464874499992, 10.849491894000039],
              [-74.90475333499995, 10.849484239000049],
              [-74.90483229299991, 10.849469872000043],
              [-74.9049507339999 , 10.84944831900009 ],
              [-74.90507625299995, 10.849440512000058],
              [-74.90521617799993, 10.849494682000056],
              [-74.90540165899995, 10.849270097000044],
              [-74.90596363199995, 10.848589651000054],
              [-74.9061232219999 , 10.848469109000064],
              [-74.90619390899991, 10.84841571700008 ],
              [-74.90620629499995, 10.848406361000059],
              [-74.90642337899993, 10.848220962000084],
              [-74.90670279799991, 10.847979962000068],
              [-74.9070521509999 , 10.84776832600005 ],
              [-74.9072065439999 , 10.847723793000057],
              [-74.90722375699994, 10.84771882800004 ],
              [-74.9073560779999 , 10.847699487000057],
              [-74.90750235999991, 10.847682341000052],
              [-74.90761785799992, 10.847605412000064],
              [-74.9077151159999 , 10.847540633000051],
              [-74.90787421699991, 10.84737173600007 ],
              [-74.90807718199994, 10.847211250000043],
              [-74.90837339099994, 10.847041360000048],
              [-74.9086372029999 , 10.846890091000091],
              [-74.90877841199995, 10.84681553200005 ],
              [-74.90879105599993, 10.846807824000052],
              [-74.90910245599991, 10.846617990000084],
              [-74.90938840199993, 10.846443673000067],
              [-74.90950626199992, 10.846371823000084],
              [-74.90966377099994, 10.846275802000036],
              [-74.90986542599995, 10.846152870000083],
              [-74.91003411799994, 10.84596272500005 ],
              [-74.91015669299992, 10.845837420000066],
              [-74.91029089699992, 10.845709731000056],
              [-74.91044309499995, 10.845494363000057],
              [-74.91045846199995, 10.845464234000076],
              [-74.91057401499995, 10.845237674000089],
              [-74.91073740399992, 10.844960019000041],
              [-74.91086286599995, 10.844910724000044],
              [-74.91101502599992, 10.844894356000054],
              [-74.91120745099994, 10.844873662000055],
              [-74.91142662399994, 10.844892808000054],
              [-74.91168311099995, 10.844916291000061],
              [-74.9118813309999 , 10.84493788900005 ],
              [-74.91210708599993, 10.84490169500009 ],
              [-74.91224214099992, 10.84488919100005 ],
              [-74.91237974699993, 10.844906623000043],
              [-74.91250830399991, 10.844960985000057],
              [-74.91266089699991, 10.845114239000054],
              [-74.91271780499994, 10.845247455000049],
              [-74.91278641499991, 10.845387497000047],
              [-74.91291813799995, 10.845554726000046],
              [-74.91299582399995, 10.845633126000052],
              [-74.91304960699995, 10.845687399000042],
              [-74.91305073699993, 10.845688303000088],
              [-74.91324415499992, 10.845842657000048],
              [-74.91336062699992, 10.845929264000063],
              [-74.91337302199992, 10.845938484000044],
              [-74.91337786399993, 10.84597025100004 ],
              [-74.91341721799995, 10.845966995000083],
              [-74.91364263999992, 10.845946963000074],
              [-74.91392597399994, 10.84589661700005 ],
              [-74.91398654199992, 10.845864411000036],
              [-74.91409735499991, 10.84595349600005 ],
              [-74.91421707199993, 10.846090793000087],
              [-74.91426728599993, 10.846267604000047],
              [-74.91428780399991, 10.846493648000092],
              [-74.91425851699995, 10.846690336000052],
              [-74.9142096139999 , 10.84698538300006 ],
              [-74.91424738499995, 10.847257802000058],
              [-74.91426559699994, 10.84744998800005 ],
              [-74.91446046099992, 10.84758927200005 ],
              [-74.91474352099993, 10.84765840700004 ],
              [-74.91499117699993, 10.847710162000055],
              [-74.91530940099994, 10.847709295000072],
              [-74.91548619299994, 10.84770881500009 ],
              [-74.91612259699991, 10.847689608000053],
              [-74.91667060299994, 10.847670639000057],
              [-74.91730705399993, 10.847668903000056],
              [-74.9181734309999 , 10.847701493000045],
              [-74.91854152499991, 10.847773222000058],
              [-74.91861565199991, 10.847787666000045],
              [-74.91912859599995, 10.84787364500005 ],
              [-74.9196949649999 , 10.84809929000005 ],
              [-74.92013723899993, 10.848202938000043],
              [-74.9206503719999 , 10.848358820000044],
              [-74.92089812899991, 10.848445521000087],
              [-74.9210008519999 , 10.848678303000042],
              [-74.92097143299992, 10.84882583700005 ],
              [-74.92092709499991, 10.848973415000046],
              [-74.92081567399993, 10.849135924000052],
              [-74.92064472899995, 10.84935020100005 ],
              [-74.92064124699993, 10.849354885000082],
              [-74.92052880099993, 10.849506147000056],
              [-74.92051841099993, 10.849520123000048],
              [-74.92034756899994, 10.849771267000051],
              [-74.92022116699991, 10.84991169500006 ],
              [-74.92017678799994, 10.85004452600009 ],
              [-74.92012497199994, 10.850184751000086],
              [-74.92012544599993, 10.850354327000048],
              [-74.9202153409999 , 10.85049416000004 ],
              [-74.92025801099993, 10.850538905000064],
              [-74.92032754299993, 10.85061181900005 ],
              [-74.92042498299992, 10.850781127000062],
              [-74.92050002299993, 10.850943122000047],
              [-74.92058989599991, 10.851075589000061],
              [-74.92068714599992, 10.851178540000092],
              [-74.92076205899991, 10.851296300000058],
              [-74.92097895699993, 10.851509519000047],
              [-74.92106126499993, 10.851605137000092],
              [-74.92117349499995, 10.851730167000085],
              [-74.9213005599999 , 10.851825665000092],
              [-74.92143516499993, 10.851950634000048],
              [-74.92158476799995, 10.852105054000049],
              [-74.92172685499992, 10.852237373000037],
              [-74.92181662599995, 10.852332973000046],
              [-74.92202577499995, 10.852442991000089],
              [-74.92216035699994, 10.852560586000038],
              [-74.92237696399991, 10.852670585000055],
              [-74.9225264449999 , 10.85278076800006 ],
              [-74.9227655759999 , 10.85294231000006 ],
              [-74.92295840699995, 10.853176163000057],
              [-74.92309321999994, 10.853374858000052],
              [-74.9232954929999 , 10.853691333000086],
              [-74.92336319999993, 10.853897585000084],
              [-74.92345336599993, 10.854133269000044],
              [-74.92345559299991, 10.854149445000076],
              [-74.92348382299991, 10.854354366000052],
              [-74.92349200299992, 10.854612392000092],
              [-74.9234333519999 , 10.85497382200009 ],
              [-74.92344570599994, 10.855109877000075],
              [-74.92346414299993, 10.855312885000046],
              [-74.92356924499995, 10.855555897000045],
              [-74.92370387599993, 10.855688237000038],
              [-74.92377875099993, 10.855791250000038],
              [-74.92398794499991, 10.855916015000048],
              [-74.9241748259999 , 10.856062954000038],
              [-74.92443633399995, 10.856224435000058],
              [-74.92463063199995, 10.85635661300006 ],
              [-74.92489192699992, 10.856444366000062],
              [-74.92494241399993, 10.856450576000043],
              [-74.92497278899992, 10.856454313000086],
              [-74.92528082199993, 10.856492203000073],
              [-74.92530230099993, 10.856494845000043],
              [-74.92553282199992, 10.856494209000061],
              [-74.9256453939999 , 10.856493899000043],
              [-74.92567547599992, 10.856488752000075],
              [-74.92599578399995, 10.856433950000053],
              [-74.92627162899993, 10.856388956000046],
              [-74.9267413739999 , 10.85633604900005 ],
              [-74.9270543849999 , 10.856246709000061],
              [-74.9273600219999 , 10.856186886000046],
              [-74.92765815699994, 10.856112331000077],
              [-74.92791149899995, 10.856023161000053],
              [-74.92802325399992, 10.85597492100004 ],
              [-74.92813498499993, 10.855926693000072],
              [-74.9283061989999 , 10.85580826100005 ],
              [-74.92843253699994, 10.855645709000044],
              [-74.92847672499994, 10.855446520000044],
              [-74.92849111999993, 10.855262160000052],
              [-74.92843079999994, 10.85503377200007 ],
              [-74.92839311599994, 10.854893791000052],
              [-74.92832212499991, 10.85474967500005 ],
              [-74.9281978759999 , 10.854575255000043],
              [-74.92819708499991, 10.854295638000053],
              [-74.92839076899992, 10.854015484000058],
              [-74.92883206399995, 10.853769593000038],
              [-74.9292205669999 , 10.853611233000038],
              [-74.92939817199994, 10.853552427000068],
              [-74.92979295899994, 10.853421714000092],
              [-74.93033387899993, 10.853433388000042],
              [-74.93079354299994, 10.853432113000054],
              [-74.93121790099991, 10.85344841500006 ],
              [-74.93151835299994, 10.853412628000058],
              [-74.93185014999995, 10.853411709000056],
              [-74.93192497799993, 10.85341150000005 ],
              [-74.93196855899993, 10.853419991000067],
              [-74.93237066299992, 10.853424448000055],
              [-74.93250491599991, 10.853425937000054],
              [-74.93259039199995, 10.853533158000062],
              [-74.93268838299991, 10.85367892000005 ],
              [-74.93279988899991, 10.85376625400005 ],
              [-74.93294314299993, 10.853822070000092],
              [-74.93317288499992, 10.853951806000055],
              [-74.93344596599991, 10.854166784000085],
              [-74.9335980319999 , 10.854252517000077],
              [-74.93378716299992, 10.854359144000057],
              [-74.93384742199993, 10.854377640000052],
              [-74.93404861699992, 10.854439395000043],
              [-74.93424187099993, 10.85450183000006 ],
              [-74.93449193299995, 10.854566380000051],
              [-74.93476249299994, 10.85465558900006 ],
              [-74.93489225499991, 10.854781066000044],
              [-74.93504248699992, 10.85491772100005 ],
              [-74.93521800799994, 10.855202549000069],
              [-74.93550721499992, 10.855525295000064],
              [-74.93569869799995, 10.855839291000052],
              [-74.93574411899993, 10.855905159000088],
              [-74.93582858399992, 10.856027649000055],
              [-74.93590399699991, 10.856142433000059],
              [-74.93597444399995, 10.85624966000006 ],
              [-74.93599684799995, 10.856310386000075],
              [-74.93605617899993, 10.856471196000086],
              [-74.9360688399999 , 10.856505514000048],
              [-74.93609780699995, 10.856584028000043],
              [-74.93615719999991, 10.85674110900004 ],
              [-74.93618383499995, 10.856763170000079],
              [-74.93620589599993, 10.856781442000056],
              [-74.93625555299991, 10.856822573000045],
              [-74.93647658499992, 10.857005646000061],
              [-74.93667406799995, 10.857107220000046],
              [-74.93682349999995, 10.857272389000059],
              [-74.93695685499995, 10.857426261000057],
              [-74.93709002399993, 10.857487168000091],
              [-74.93726209899995, 10.857500372000061],
              [-74.93739742999992, 10.857490983000048],
              [-74.93751439699992, 10.857479373000047],
              [-74.93764516099992, 10.857481333000067],
              [-74.93779656499993, 10.857480979000059],
              [-74.93797317799994, 10.85746922800007 ],
              [-74.93816357499992, 10.857466516000045],
              [-74.93834715999992, 10.857500099000049],
              [-74.93849395099994, 10.857488419000049],
              [-74.93869115199993, 10.857447143000059],
              [-74.93881502799991, 10.857446850000088],
              [-74.93895045499994, 10.857487347000074],
              [-74.93915939899995, 10.85758435300005 ],
              [-74.93932707099992, 10.85769053100006 ],
              [-74.93948105599992, 10.857837549000067],
              [-74.9395728909999 , 10.857875879000062],
              [-74.9396440029999 , 10.857875714000045],
              [-74.93972659999991, 10.857880051000052],
              [-74.93985521099995, 10.857954576000054],
              [-74.94001377899991, 10.858097048000047],
              [-74.94018619099995, 10.858282573000054],
              [-74.94029422299991, 10.858388887000046],
              [-74.94037918099991, 10.858429500000057],
              [-74.94048013499992, 10.858438331000059],
              [-74.94056959199992, 10.858433585000057],
              [-74.94064736299993, 10.858320032000051],
              [-74.94073677299991, 10.85829261400005 ],
              [-74.94090195899992, 10.858301295000047],
              [-74.94101208399991, 10.858307836000051],
              [-74.9411727019999 , 10.85832786800006 ],
              [-74.94132870399994, 10.858332034000057],
              [-74.94152369599993, 10.858336111000085],
              [-74.94165460699992, 10.858410629000048],
              [-74.94177619899995, 10.85841714500009 ],
              [-74.94200335599993, 10.858441552000045],
              [-74.94247224599991, 10.858498219000069],
              [-74.94265733799995, 10.858341669000083],
              [-74.94276484499994, 10.858305215000087],
              [-74.9429365389999 , 10.858316124000055],
              [-74.94306461299993, 10.858263781000062],
              [-74.94317900599992, 10.85823410100005 ],
              [-74.94330722699993, 10.858254160000058],
              [-74.94342187299992, 10.858353444000045],
              [-74.94349282299993, 10.858348747000036],
              [-74.94364839999992, 10.858312180000041],
              [-74.94379027499991, 10.858291484000063],
              [-74.94390474699992, 10.858304788000055],
              [-74.9440146959999 , 10.85834299000004 ],
              [-74.9441405789999 , 10.858338169000092],
              [-74.94426872899993, 10.858322030000068],
              [-74.94433736899992, 10.85830829200006 ],
              [-74.94458328699994, 10.858365957000046],
              [-74.94481372799993, 10.858419993000041],
              [-74.94481582499992, 10.858510503000048],
              [-74.94482470699995, 10.858574472000043],
              [-74.94483089099992, 10.858594765000078],
              [-74.94487299699995, 10.85873295500005 ],
              [-74.94499632199995, 10.859545787000059],
              [-74.94473106299995, 10.85985745700009 ],
              [-74.94408956599995, 10.860633968000059],
              [-74.94373487699994, 10.86070937900007 ],
              [-74.94343435899992, 10.860922842000036],
              [-74.9429684939999 , 10.86115394300009 ],
              [-74.94275197399992, 10.86158505700007 ],
              [-74.94263223299993, 10.862015903000042],
              [-74.94243900999993, 10.86213603300007 ],
              [-74.94239730699991, 10.862156792000064],
              [-74.94224571599995, 10.86223224300005 ],
              [-74.94210129899994, 10.862495738000064],
              [-74.9419568799999 , 10.862759231000041],
              [-74.94198210799993, 10.863117918000057],
              [-74.94195921599993, 10.86357240700005 ],
              [-74.94210214499992, 10.863807507000047],
              [-74.94232204399992, 10.864035641000044],
              [-74.94242021299993, 10.864288199000043],
              [-74.94243312099991, 10.86454099100007 ],
              [-74.94234139799994, 10.864795106000088],
              [-74.94232436599992, 10.86484229000007 ],
              [-74.9422272299999 , 10.864888105000091],
              [-74.9420932569999 , 10.864951297000061],
              [-74.94169342399994, 10.865126890000056],
              [-74.94087655699991, 10.865412222000089],
              [-74.94058450799992, 10.865509358000054],
              [-74.94032048199995, 10.865873047000036],
              [-74.94005486899994, 10.866065129000049],
              [-74.93966848699995, 10.866329301000064],
              [-74.93921335999994, 10.866632787000071],
              [-74.9388804159999 , 10.866793185000063],
              [-74.93841213099995, 10.866978583000048],
              [-74.93821903999992, 10.867146544000093],
              [-74.93802608599992, 10.867362341000046],
              [-74.93780886699994, 10.867554282000071],
              [-74.9375999209999 , 10.86778507300005 ],
              [-74.93759178499994, 10.867794060000051],
              [-74.9373744909999 , 10.867962090000049],
              [-74.9370125069999 , 10.868297940000048],
              [-74.93672297099994, 10.868585753000048],
              [-74.93655887199992, 10.868690679000053],
              [-74.9363848349999 , 10.868801954000048],
              [-74.93602229899994, 10.868946468000047],
              [-74.9358149279999 , 10.868994949000069],
              [-74.93552248899994, 10.868957240000043],
              [-74.93524752999991, 10.868781211000055],
              [-74.93486031999993, 10.868758375000084],
              [-74.9344491139999 , 10.868807354000069],
              [-74.93411077199994, 10.868951802000083],
              [-74.93384576999995, 10.869359133000046],
              [-74.93362909199993, 10.86974241200005 ],
              [-74.93343029899995, 10.869955143000084],
              [-74.93363707299994, 10.870107098000062],
              [-74.93401807799995, 10.87038708600005 ],
              [-74.9343091209999 , 10.870625447000066],
              [-74.93452274299995, 10.870835414000055],
              [-74.93469735999992, 10.871007040000052],
              [-74.93494021399994, 10.871317284000042],
              [-74.93511068499993, 10.871699482000054],
              [-74.93525848299993, 10.872049765000042],
              [-74.93525777899993, 10.872368748000042],
              [-74.93511556199991, 10.872598632000063],
              [-74.93506509299993, 10.872680211000045],
              [-74.93484806799995, 10.872943906000046],
              [-74.93463179999992, 10.87347068300005 ],
              [-74.93441532799994, 10.873925713000062],
              [-74.93422243599991, 10.874165420000054],
              [-74.93398107999991, 10.874381347000053],
              [-74.93354587599993, 10.874502147000044],
              [-74.93308606499994, 10.874479511000061],
              [-74.93272310999993, 10.874480522000056],
              [-74.93238405099993, 10.874372264000044],
              [-74.93207887499995, 10.874447295000039],
              [-74.93197421399992, 10.87456336400004 ],
              [-74.93185263099991, 10.874698197000043],
              [-74.93158190299994, 10.874914746000059],
              [-74.93161827299991, 10.874940896000055],
              [-74.93193142699994, 10.874785042000042],
              [-74.93206361999995, 10.87464213000004 ],
              [-74.93208199199995, 10.874658898000064],
              [-74.9321102589999 , 10.874696265000068],
              [-74.93218222899992, 10.874791401000039],
              [-74.93223036799992, 10.874855034000063],
              [-74.93223151899991, 10.874856555000065],
              [-74.93224521099995, 10.874874654000052],
              [-74.93224259599992, 10.874877471000048],
              [-74.93227045999993, 10.874914304000072],
              [-74.93216524899992, 10.875027647000081],
              [-74.93215093299995, 10.875043069000071],
              [-74.93199581599993, 10.875210175000063],
              [-74.9319018569999 , 10.875311397000075],
              [-74.93189870499992, 10.875312267000083],
              [-74.93174718299991, 10.875354104000053],
              [-74.93160784899993, 10.875392576000081],
              [-74.93139060599992, 10.875556284000083],
              [-74.93130458199994, 10.875621110000054],
              [-74.93124397299994, 10.875668046000044],
              [-74.93112108699995, 10.87576320900007 ],
              [-74.9311018539999 , 10.875807270000053],
              [-74.93102842299993, 10.875907400000074],
              [-74.93101131399993, 10.87593072900006 ],
              [-74.9310107689999 , 10.875931472000048],
              [-74.93098332999995, 10.875968889000092],
              [-74.93097889599994, 10.875974935000045],
              [-74.93096687299993, 10.875991329000044],
              [-74.93096419199992, 10.875994159000072],
              [-74.9309437789999 , 10.876015710000047],
              [-74.93099847799994, 10.876037390000079],
              [-74.93103486399991, 10.876051811000082],
              [-74.93102389899991, 10.876076674000046],
              [-74.93098117199992, 10.876167214000077],
              [-74.93098106299993, 10.876167444000089],
              [-74.93091705499995, 10.876303078000092],
              [-74.93090890299993, 10.87632035300004 ],
              [-74.9305020619999 , 10.877182451000067],
              [-74.93046107099991, 10.877269310000088],
              [-74.93000033299995, 10.87706047000006 ],
              [-74.9299550209999 , 10.87703993100007 ],
              [-74.92982314499994, 10.877389139000059],
              [-74.92982906499992, 10.877510346000065],
              [-74.92983163899993, 10.87756304800007 ],
              [-74.92984060799995, 10.877746667000054],
              [-74.92990211499995, 10.878028094000058],
              [-74.9298302709999 , 10.878291381000054],
              [-74.92973402399991, 10.878482986000051],
              [-74.92956406099995, 10.878803916000038],
              [-74.92953505199995, 10.87898195300005 ],
              [-74.9295101859999 , 10.879134556000054],
              [-74.92947888299994, 10.879692680000062],
              [-74.92947226899992, 10.880038325000044],
              [-74.9295700049999 , 10.88037289500005 ],
              [-74.9295855979999 , 10.880597169000055],
              [-74.92955306399995, 10.880721871000048],
              [-74.92959342199993, 10.88087377000005 ],
              [-74.92964457199992, 10.881066284000042],
              [-74.92957252399992, 10.881257822000066],
              [-74.92947647599993, 10.881521175000046],
              [-74.92933197199994, 10.88176074800009 ],
              [-74.92939776599991, 10.88201175100005 ],
              [-74.92947509099992, 10.882217411000056],
              [-74.92956907599995, 10.88249888100006 ],
              [-74.92981945699995, 10.883003085000041],
              [-74.92996546299992, 10.883289688000048],
              [-74.93003893799994, 10.88360040300006 ],
              [-74.9300444779999 , 10.883756633000075],
              [-74.9300478259999 , 10.883851049000043],
              [-74.9299981719999 , 10.88403719300004 ],
              [-74.92995277299991, 10.884191340000086],
              [-74.92993603899993, 10.884248168000056],
              [-74.92983655699993, 10.884558450000043],
              [-74.92977829099993, 10.88475212800006 ],
              [-74.92982538299992, 10.88504209100006 ],
              [-74.92983962099993, 10.88508218600009 ],
              [-74.92992656099995, 10.885327019000044],
              [-74.93006513099994, 10.885525035000057],
              [-74.93008811699991, 10.885758987000088],
              [-74.93005575199993, 10.885943286000042],
              [-74.93000703599995, 10.886160132000043],
              [-74.92998848899992, 10.886276802000054],
              [-74.92998549499993, 10.88629564100006 ],
              [-74.92999094499993, 10.886284791000037],
              [-74.92999534099994, 10.886281877000044],
              [-74.93015508099995, 10.886175976000061],
              [-74.93031456099993, 10.886163475000046],
              [-74.93057178099991, 10.886212743000044],
              [-74.93080769999995, 10.886287938000066],
              [-74.93091389499995, 10.886326273000066],
              [-74.93101621199992, 10.886363205000066],
              [-74.93132321699994, 10.886378604000072],
              [-74.93176175299993, 10.88638280300006 ],
              [-74.93196241899994, 10.886538508000058],
              [-74.93218960399992, 10.886793684000054],
              [-74.93261245799994, 10.887591907000058],
              [-74.93284202099994, 10.888678450000043],
              [-74.9328433899999 , 10.889158087000055],
              [-74.93274733499993, 10.889510089000055],
              [-74.93203723499994, 10.890087633000064],
              [-74.9314883479999 , 10.890472875000057],
              [-74.93129560499995, 10.890953051000054],
              [-74.93128654499992, 10.891031060000046],
              [-74.9312729749999 , 10.891147883000087],
              [-74.93123244999992, 10.891496820000043],
              [-74.9311690159999 , 10.891944659000046],
              [-74.93077021599993, 10.892458866000084],
              [-74.93013920699991, 10.893012902000066],
              [-74.9296340329999 , 10.89332691900006 ],
              [-74.92951971899993, 10.893910781000045],
              [-74.9295101539999 , 10.89425467500007 ],
              [-74.92941591399995, 10.89448419100006 ],
              [-74.92940478799994, 10.894499108000048],
              [-74.9290307409999 , 10.894726459000083],
              [-74.9285795699999 , 10.895131175000074],
              [-74.92837040199993, 10.89531880900006 ],
              [-74.92824356799991, 10.895684765000055],
              [-74.92812708899993, 10.896479492000083],
              [-74.92806498399995, 10.897125117000087],
              [-74.9279858939999 , 10.89780371900008 ],
              [-74.92795478599993, 10.898285264000037],
              [-74.92806372799993, 10.898749662000057],
              [-74.92814649399992, 10.899406852000084],
              [-74.92812985099994, 10.899755054000082],
              [-74.9281244199999 , 10.899868669000057],
              [-74.92812440699993, 10.899868950000041],
              [-74.92812210099993, 10.899917196000047],
              [-74.92812045499994, 10.899933323000084],
              [-74.92770237799994, 10.904030293000062],
              [-74.92455352699994, 10.93488764600005 ],
              [-74.92453166899992, 10.935101848000045],
              [-74.92375868999994, 10.936248919000093],
              [-74.9238022049999 , 10.936277415000063],
              [-74.92363892599991, 10.936520894000068],
              [-74.92331191499994, 10.936945524000066],
              [-74.9231567249999 , 10.93714155300006 ],
              [-74.92293065199993, 10.937541217000046],
              [-74.92282375799994, 10.93778726000005 ],
              [-74.92276916899993, 10.937936591000039],
              [-74.92275355999993, 10.93811297700006 ],
              [-74.92275204899994, 10.938370751000036],
              [-74.92279614199992, 10.939053791000049],
              [-74.92280724299991, 10.939225685000054],
              [-74.9228170799999 , 10.939864877000048],
              [-74.9228244279999 , 10.940341831000069],
              [-74.92286275299995, 10.941238737000049],
              [-74.92288728499994, 10.941813600000046],
              [-74.92287556799994, 10.942001443000038],
              [-74.92287166999995, 10.942063937000057],
              [-74.92284467699994, 10.942210767000063],
              [-74.92278039399991, 10.942330862000063],
              [-74.92262598099995, 10.942552225000043],
              [-74.92173235999991, 10.943533394000042],
              [-74.92123753499993, 10.944031322000058],
              [-74.92110233299991, 10.944127885000057],
              [-74.92070430299992, 10.944314202000044],
              [-74.92036366299993, 10.944468477000044],
              [-74.92033874999993, 10.944479759000046],
              [-74.91964765199992, 10.944812969000054],
              [-74.91929158399995, 10.944984609000073],
              [-74.91918799399991, 10.945034545000055],
              [-74.9183999309999 , 10.945286751000083],
              [-74.91821153399991, 10.945383057000072],
              [-74.9163338219999 , 10.94634293200005 ],
              [-74.9154071609999 , 10.946797684000046],
              [-74.91193462199993, 10.948455631000058],
              [-74.91051290999991, 10.949152005000087],
              [-74.9090907719999 , 10.949826905000066],
              [-74.9077148049999 , 10.95046390400006 ],
              [-74.90748413199992, 10.95042115800004 ],
              [-74.90676835699992, 10.95028852300004 ],
              [-74.90624451699995, 10.950206536000053],
              [-74.90571587699992, 10.95011121400006 ],
              [-74.9056858429999 , 10.950105798000038],
              [-74.90551463899993, 10.95007492700006 ],
              [-74.90547798599994, 10.950068319000081],
              [-74.90539394199993, 10.950053165000043],
              [-74.90525547199991, 10.950032608000072],
              [-74.90466394499992, 10.950035874000037],
              [-74.90462900799992, 10.950036066000052],
              [-74.9036016899999 , 10.95004173600006 ],
              [-74.90258382399992, 10.950047353000059],
              [-74.90255556799991, 10.950047509000058],
              [-74.90252731299995, 10.950047664000067],
              [-74.9024976039999 , 10.950047828000038],
              [-74.90238703099993, 10.950048437000078],
              [-74.90223712499994, 10.950049264000086],
              [-74.90091219199991, 10.94989273300007 ],
              [-74.90082228999995, 10.949882112000068],
              [-74.90065096899991, 10.949861871000053],
              [-74.9005453289999 , 10.949849389000065],
              [-74.90017264499994, 10.949728333000053],
              [-74.89935488299994, 10.949747936000051],
              [-74.89895052399993, 10.949757628000043],
              [-74.8980835129999 , 10.949889837000057],
              [-74.89753206199993, 10.950073151000083],
              [-74.89701999099992, 10.950243355000055],
              [-74.89654702199994, 10.950296585000046],
              [-74.89629725799995, 10.950271279000049],
              [-74.8962879579999 , 10.950266885000076],
              [-74.89594195699993, 10.950103421000051],
              [-74.89494359999992, 10.950261953000052],
              [-74.89414261999991, 10.950510854000072],
              [-74.89398639799992, 10.950503747000084],
              [-74.89306483999991, 10.950461819000054],
              [-74.89261821299993, 10.950454843000045],
              [-74.89260368999993, 10.95030599100005 ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08372",
      "properties": {"id": "08372", "iso": "JUA", "name": "JUAN DE ACOSTA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-75.07509840499995, 10.886346314000093],
              [-75.0750652779999 , 10.886298280000062],
              [-75.07500917499993, 10.886302294000075],
              [-75.07466727799994, 10.886306664000074],
              [-75.07463678899995, 10.886307053000053],
              [-75.07456030399993, 10.88635832500006 ],
              [-75.07445893899995, 10.88634690300006 ],
              [-75.0743486149999 , 10.886306196000078],
              [-75.07429962499992, 10.886293232000071],
              [-75.07418184099993, 10.886253971000087],
              [-75.07411873599995, 10.886252196000044],
              [-75.07408078199995, 10.88633505200005 ],
              [-75.07400442999995, 10.88629844500008 ],
              [-75.0738273039999 , 10.886241277000067],
              [-75.07377957099993, 10.886225871000079],
              [-75.07350474299994, 10.886049791000062],
              [-75.07350257299993, 10.886055395000085],
              [-75.07348636199993, 10.88604334900009 ],
              [-75.07346758199992, 10.886029395000037],
              [-75.0732975009999 , 10.885903015000054],
              [-75.07320052599994, 10.885830957000053],
              [-75.07320050399994, 10.885830941000052],
              [-75.07305975499992, 10.885726356000077],
              [-75.0729584529999 , 10.885651083000084],
              [-75.07295696799991, 10.88564997900005 ],
              [-75.07283817099994, 10.885561707000079],
              [-75.0726940909999 , 10.885454648000064],
              [-75.0725222019999 , 10.885342850000086],
              [-75.0722502779999 , 10.885165989000086],
              [-75.0721033239999 , 10.885070409000036],
              [-75.07209730999995, 10.885066498000072],
              [-75.07196240699994, 10.884978756000066],
              [-75.0716784139999 , 10.884816081000054],
              [-75.0715566579999 , 10.884746337000081],
              [-75.07153023799992, 10.884731203000058],
              [-75.0714036249999 , 10.884658677000061],
              [-75.07135416799991, 10.884628208000038],
              [-75.07131931099991, 10.884606733000055],
              [-75.0712154979999 , 10.884542777000092],
              [-75.07110678599992, 10.884475802000054],
              [-75.0710990369999 , 10.884471028000064],
              [-75.0709868909999 , 10.884401937000064],
              [-75.07098503399993, 10.884400793000054],
              [-75.07088216699992, 10.88433930900004 ],
              [-75.07077802099991, 10.884277061000091],
              [-75.07068284199994, 10.88420805700008 ],
              [-75.0706550999999 , 10.884179565000068],
              [-75.0705948019999 , 10.884117637000088],
              [-75.0705509579999 , 10.884073793000084],
              [-75.07054567599994, 10.884068511000066],
              [-75.07052341799994, 10.884046253000065],
              [-75.07051799399994, 10.884038925000084],
              [-75.0705164279999 , 10.884036808000076],
              [-75.07046045499993, 10.883961168000042],
              [-75.0704353779999 , 10.88392728000008 ],
              [-75.07041482699992, 10.883900064000045],
              [-75.07041276099994, 10.883897328000046],
              [-75.07034733799992, 10.88381068700005 ],
              [-75.07031436299991, 10.883759743000041],
              [-75.07031258299992, 10.883756992000087],
              [-75.07020814899994, 10.88359564600006 ],
              [-75.07016695499993, 10.883532003000084],
              [-75.070146        , 10.883498748000079],
              [-75.07012364899992, 10.883463278000079],
              [-75.07007807799994, 10.883394366000061],
              [-75.07007698699994, 10.88339271600006 ],
              [-75.07004560299993, 10.883345256000041],
              [-75.06998088099994, 10.883274824000068],
              [-75.06994825499993, 10.88323968800006 ],
              [-75.06990664199992, 10.883194874000083],
              [-75.06990254899995, 10.883191489000069],
              [-75.06986220899995, 10.883158131000073],
              [-75.0698076569999 , 10.883113021000042],
              [-75.06978193599991, 10.883097589000045],
              [-75.06971247799993, 10.883055914000067],
              [-75.0696708129999 , 10.883029804000046],
              [-75.0696505809999 , 10.883017125000038],
              [-75.06962369399992, 10.883000276000075],
              [-75.0696199859999 , 10.88299795200004 ],
              [-75.06958866299993, 10.882978323000088],
              [-75.06956970999994, 10.882966446000069],
              [-75.06955208399995, 10.882953227000087],
              [-75.06953445699992, 10.882940007000059],
              [-75.06953163899993, 10.882937893000076],
              [-75.06952777799995, 10.882935240000052],
              [-75.0695142429999 , 10.882925941000053],
              [-75.06947919899994, 10.88297497700006 ],
              [-75.06941733299993, 10.883053499000084],
              [-75.0694005009999 , 10.88307558300005 ],
              [-75.06934297499993, 10.883151057000077],
              [-75.06933136999993, 10.883167204000074],
              [-75.06931185199994, 10.883154019000074],
              [-75.06924792899991, 10.883093336000059],
              [-75.06924228299994, 10.88308797600007 ],
              [-75.06922122799995, 10.88306798800005 ],
              [-75.06915077299993, 10.883001105000062],
              [-75.06906792399991, 10.882960809000053],
              [-75.06895539699991, 10.882869261000053],
              [-75.0688204779999 , 10.882793636000088],
              [-75.06871894999995, 10.882736729000044],
              [-75.06856801799995, 10.88265212500005 ],
              [-75.06843384699994, 10.882576923000045],
              [-75.06827075699994, 10.882485503000055],
              [-75.06816782199991, 10.882427806000067],
              [-75.06804234599991, 10.88235747300007 ],
              [-75.06794060499993, 10.882300445000055],
              [-75.06778876999994, 10.882215336000058],
              [-75.06759634399992, 10.882107480000059],
              [-75.06742520699993, 10.882011552000051],
              [-75.0673068989999 , 10.881945235000046],
              [-75.06720389999992, 10.881887499000072],
              [-75.06708227199994, 10.881819323000059],
              [-75.06694888299995, 10.881744556000058],
              [-75.06681576099993, 10.881617963000053],
              [-75.06668793999995, 10.881496416000061],
              [-75.06654285499991, 10.88135845100004 ],
              [-75.06637914899994, 10.881202779000091],
              [-75.06628729999994, 10.881115437000062],
              [-75.0662107469999 , 10.881042644000047],
              [-75.0661237999999 , 10.880959961000087],
              [-75.06600799199992, 10.880849835000049],
              [-75.06594396799994, 10.880755561000058],
              [-75.06581523099993, 10.880610188000048],
              [-75.06571849499994, 10.880500958000084],
              [-75.06562200999991, 10.880357269000058],
              [-75.06555770499995, 10.880303599000058],
              [-75.06549358699993, 10.880250086000046],
              [-75.0654218489999 , 10.88019021100007 ],
              [-75.0653489959999 , 10.880129404000058],
              [-75.06531962699995, 10.880060330000049],
              [-75.06528572099995, 10.879980580000051],
              [-75.06528046099993, 10.87985128300005 ],
              [-75.06524804299994, 10.879743632000043],
              [-75.06521273099992, 10.879626381000037],
              [-75.06514790999995, 10.87952210800006 ],
              [-75.06509854599994, 10.879442686000061],
              [-75.06506697899994, 10.879287842000053],
              [-75.06500871299994, 10.879120055000044],
              [-75.06496323899995, 10.87898911700006 ],
              [-75.06493028599994, 10.87889423200005 ],
              [-75.0649026559999 , 10.878814661000092],
              [-75.06488204699991, 10.87875531900005 ],
              [-75.06486316599995, 10.878700949000063],
              [-75.06482127099991, 10.87867379100004 ],
              [-75.06478631099992, 10.878651128000058],
              [-75.06479206999995, 10.878569298000059],
              [-75.0647403399999 , 10.878439524000044],
              [-75.06469407999992, 10.87832347400007 ],
              [-75.06464385399994, 10.878197477000072],
              [-75.06453058999995, 10.878034532000072],
              [-75.06443729199992, 10.877939757000092],
              [-75.06436640699991, 10.87783539500009 ],
              [-75.06429041199993, 10.877723511000056],
              [-75.06419902899995, 10.877639438000074],
              [-75.06411892099993, 10.877565737000054],
              [-75.06407576399994, 10.877526033000038],
              [-75.06404446599993, 10.877440273000047],
              [-75.0639803169999 , 10.877434140000048],
              [-75.06387779599993, 10.877459822000048],
              [-75.06379076899992, 10.877481622000062],
              [-75.06369508499995, 10.877476253000054],
              [-75.06357970699992, 10.87749247100004 ],
              [-75.06346190599993, 10.877509027000087],
              [-75.06339070699994, 10.87754695600006 ],
              [-75.06332657899992, 10.877547161000052],
              [-75.06325655099994, 10.87754738500007 ],
              [-75.06320486099992, 10.877585581000062],
              [-75.06313119799995, 10.877611172000059],
              [-75.06306883199994, 10.877632839000057],
              [-75.0629901659999 , 10.877627472000086],
              [-75.06292611299995, 10.877623106000044],
              [-75.0628331389999 , 10.877653328000065],
              [-75.06272143099994, 10.877689647000068],
              [-75.06271304799992, 10.87772390300006 ],
              [-75.0626455009999 , 10.877999903000045],
              [-75.06263299499994, 10.878051004000042],
              [-75.06255422599992, 10.878372862000049],
              [-75.06251135699995, 10.878349206000053],
              [-75.06213148099994, 10.87902185200005 ],
              [-75.06179741699992, 10.880060688000071],
              [-75.06105706199992, 10.882635848000064],
              [-75.06102639599993, 10.882742505000067],
              [-75.0610188469999 , 10.882768768000062],
              [-75.06053744699994, 10.884623460000057],
              [-75.05959877599992, 10.885724120000077],
              [-75.0595082989999 , 10.885830211000041],
              [-75.05949152199992, 10.885849882000059],
              [-75.05937331199993, 10.885904868000068],
              [-75.05836827799993, 10.88637235400006 ],
              [-75.05687762399992, 10.886450798000055],
              [-75.05686859199994, 10.886451272000045],
              [-75.05684488499992, 10.886445672000093],
              [-75.05647594399994, 10.886358516000087],
              [-75.05641660899994, 10.886344499000074],
              [-75.05626213599993, 10.886308007000082],
              [-75.05563019099992, 10.886158717000058],
              [-75.05545385699992, 10.886023221000073],
              [-75.05548926599994, 10.885957659000042],
              [-75.05543977999992, 10.885930771000062],
              [-75.05507703399991, 10.885733671000082],
              [-75.0547655659999 , 10.885494337000068],
              [-75.05466552299993, 10.884912624000037],
              [-75.05453622499994, 10.884160798000039],
              [-75.05449366499994, 10.88260428600006 ],
              [-75.05460241499992, 10.881454992000045],
              [-75.05493541999994, 10.880082610000045],
              [-75.0552669779999 , 10.878265462000059],
              [-75.05514616399995, 10.877707164000071],
              [-75.05486510199995, 10.877456950000067],
              [-75.0547980849999 , 10.877530498000056],
              [-75.05474632099993, 10.87760226000006 ],
              [-75.0546711529999 , 10.877643240000054],
              [-75.0546097109999 , 10.877650652000057],
              [-75.05460850499992, 10.877650797000058],
              [-75.05453884299993, 10.877605688000074],
              [-75.0545161359999 , 10.877576781000073],
              [-75.05444886599992, 10.877491158000055],
              [-75.05438628299993, 10.87738052800006 ],
              [-75.05424925999995, 10.877222116000041],
              [-75.05410171999995, 10.877064444000041],
              [-75.05391271799994, 10.877033934000053],
              [-75.0538050049999 , 10.877041857000052],
              [-75.05370194999995, 10.877052338000055],
              [-75.05362162499995, 10.877064162000067],
              [-75.0535266899999 , 10.87712134900005 ],
              [-75.05345238299992, 10.87721246700005 ],
              [-75.05338287399991, 10.877604427000051],
              [-75.05338956799994, 10.87797011500004 ],
              [-75.05342226899995, 10.87818317500006 ],
              [-75.05346800799992, 10.878389476000052],
              [-75.0534890159999 , 10.87854739100004 ],
              [-75.05348673499992, 10.878636153000059],
              [-75.05345434899994, 10.878803878000042],
              [-75.0533667489999 , 10.879136250000045],
              [-75.05326290399995, 10.87927374800006 ],
              [-75.0531867389999 , 10.879379707000055],
              [-75.05307540599995, 10.879576789000055],
              [-75.05298710199992, 10.879760376000092],
              [-75.05292317399994, 10.879948072000047],
              [-75.05290466699995, 10.880179687000066],
              [-75.05289456499992, 10.88036346000007 ],
              [-75.05283152599992, 10.880562897000061],
              [-75.05273458499994, 10.880711646000066],
              [-75.05263006699994, 10.880760581000061],
              [-75.05246461399992, 10.880719489000057],
              [-75.05239064199992, 10.880695357000093],
              [-75.05227161699992, 10.880591386000049],
              [-75.05217163599991, 10.880421130000059],
              [-75.05215781899994, 10.880239170000038],
              [-75.0521909439999 , 10.880041990000052],
              [-75.05218596399993, 10.879818071000045],
              [-75.05220133599994, 10.879545441000062],
              [-75.05230395099994, 10.879313658000058],
              [-75.05242540099994, 10.879092289000084],
              [-75.0524405999999 , 10.878896387000054],
              [-75.05238322699995, 10.878853372000037],
              [-75.0522508329999 , 10.878851263000058],
              [-75.05209632099991, 10.878874375000066],
              [-75.05196330299992, 10.878943133000064],
              [-75.05182743699993, 10.87905341000004 ],
              [-75.0516256649999 , 10.879245223000055],
              [-75.05142194899992, 10.879490272000055],
              [-75.05128204099992, 10.879700372000059],
              [-75.0512659069999 , 10.879725153000038],
              [-75.05114402599992, 10.879781251000054],
              [-75.05092810999992, 10.879785214000037],
              [-75.0507710569999 , 10.87977309300004 ],
              [-75.05062372399993, 10.87973075900004 ],
              [-75.0504308589999 , 10.879721256000039],
              [-75.05025217699995, 10.879740208000044],
              [-75.05000275599991, 10.879776115000084],
              [-75.0497623789999 , 10.879693417000055],
              [-75.04940035499993, 10.87943090500005 ],
              [-75.04919178199992, 10.879297227000052],
              [-75.04911833199992, 10.879204239000046],
              [-75.04906029599994, 10.879077619000043],
              [-75.0490446199999 , 10.878992681000057],
              [-75.04906414099992, 10.878857972000048],
              [-75.0491165869999 , 10.878798162000066],
              [-75.04920332699993, 10.878715340000042],
              [-75.04928155399995, 10.878639001000067],
              [-75.04934509599991, 10.878466937000042],
              [-75.04934020999991, 10.87832792000006 ],
              [-75.04926766099993, 10.878169692000085],
              [-75.04917371599993, 10.87800696000005 ],
              [-75.04911431999994, 10.87793976000006 ],
              [-75.04902868599993, 10.877844665000055],
              [-75.04892809899991, 10.877737723000052],
              [-75.04892372299992, 10.877733074000048],
              [-75.04884547199993, 10.87765528600005 ],
              [-75.04876192799992, 10.877586742000062],
              [-75.0486843729999 , 10.87751780800005 ],
              [-75.04863268299994, 10.877409817000057],
              [-75.04862338499993, 10.87737411900008 ],
              [-75.04859399299994, 10.877261262000047],
              [-75.04859810199991, 10.877160226000058],
              [-75.04862646799995, 10.87703805700005 ],
              [-75.04869645699995, 10.876871638000068],
              [-75.0487054059999 , 10.87679399700005 ],
              [-75.04878884899995, 10.87663139600005 ],
              [-75.04887609399992, 10.876531999000065],
              [-75.0490126919999 , 10.87639547300006 ],
              [-75.04909097699993, 10.876373710000053],
              [-75.0492349459999 , 10.876254470000049],
              [-75.04931772099991, 10.87616005500007 ],
              [-75.04932459399993, 10.87605590000004 ],
              [-75.0492831649999 , 10.875987560000056],
              [-75.04920845999993, 10.875931607000041],
              [-75.04914542699993, 10.875932858000056],
              [-75.04907021399993, 10.875931965000063],
              [-75.04894391599993, 10.875854501000049],
              [-75.04887811899994, 10.875781888000063],
              [-75.04887302899994, 10.875678548000053],
              [-75.04896059399994, 10.875491990000057],
              [-75.04903263999995, 10.87531037000008 ],
              [-75.04903490399994, 10.875304663000065],
              [-75.04908198899994, 10.875100090000046],
              [-75.0491014669999 , 10.87488849500005 ],
              [-75.04907614899992, 10.874833910000063],
              [-75.04899862199994, 10.874765073000049],
              [-75.04896931399992, 10.87476517500005 ],
              [-75.04890690799994, 10.874765396000043],
              [-75.04890549299995, 10.874765403000083],
              [-75.04868620999991, 10.874742956000091],
              [-75.04859861799991, 10.874736993000056],
              [-75.04856274899993, 10.874728308000044],
              [-75.0485220509999 , 10.87471845500005 ],
              [-75.04845729099992, 10.87465846300006 ],
              [-75.04844754099992, 10.87464943100008 ],
              [-75.04844073499993, 10.874647518000074],
              [-75.04833695899993, 10.874618361000046],
              [-75.04825579899995, 10.874579401000062],
              [-75.04822700499994, 10.874506698000062],
              [-75.04818840599995, 10.87440923400004 ],
              [-75.04821017599994, 10.87415018300004 ],
              [-75.04823197099995, 10.87400659800005 ],
              [-75.04827528299995, 10.873922151000045],
              [-75.04836710699993, 10.87388181700004 ],
              [-75.04847743499994, 10.873871425000061],
              [-75.04859007999994, 10.873890476000042],
              [-75.04864731299995, 10.873917813000048],
              [-75.04873676599993, 10.873960539000052],
              [-75.04891770699993, 10.874007567000092],
              [-75.04904043199991, 10.874010800000065],
              [-75.0490441689999 , 10.874010898000051],
              [-75.04913727699994, 10.873972062000064],
              [-75.0491727289999 , 10.873864445000038],
              [-75.04914858599994, 10.873813189000089],
              [-75.04911865899993, 10.87374965500004 ],
              [-75.04906828999992, 10.873681994000037],
              [-75.0490457439999 , 10.873628302000043],
              [-75.04900179499992, 10.873523638000052],
              [-75.04893645599992, 10.87338002100006 ],
              [-75.0489211599999 , 10.873357614000042],
              [-75.04889100899993, 10.873313436000046],
              [-75.04880156799993, 10.873245465000082],
              [-75.04871856299991, 10.873182984000039],
              [-75.04864232799991, 10.873068183000044],
              [-75.04863724899991, 10.872964934000038],
              [-75.04866480499993, 10.872895004000043],
              [-75.04876855599991, 10.872763926000061],
              [-75.04877597399991, 10.87275455400004 ],
              [-75.04879599499992, 10.87272925800005 ],
              [-75.04890906199995, 10.872575750000067],
              [-75.04892795399991, 10.87255009900008 ],
              [-75.04904678899993, 10.872280102000047],
              [-75.0490386649999 , 10.872061648000056],
              [-75.04898300999992, 10.871926337000048],
              [-75.04891010999995, 10.871801073000086],
              [-75.04882000499993, 10.871724335000067],
              [-75.04881214699992, 10.871724751000045],
              [-75.0485197889999 , 10.87174026200006 ],
              [-75.04845201199993, 10.871756694000055],
              [-75.04837597599993, 10.871821028000056],
              [-75.0482969979999 , 10.872000942000057],
              [-75.04825314199991, 10.872092678000058],
              [-75.04825723699992, 10.872164411000085],
              [-75.04826121699995, 10.87223415200009 ],
              [-75.04830284499991, 10.872282373000075],
              [-75.04832395699992, 10.872306828000092],
              [-75.04841107899995, 10.872460709000052],
              [-75.04835665599995, 10.87257091500004 ],
              [-75.04834679099991, 10.872571582000091],
              [-75.04827302199993, 10.872576577000075],
              [-75.04816850299994, 10.872583648000045],
              [-75.04806971099993, 10.872587369000087],
              [-75.0479383949999 , 10.87248622800007 ],
              [-75.04793553099995, 10.872484021000048],
              [-75.04783375699992, 10.872411034000038],
              [-75.04777402999991, 10.872376614000075],
              [-75.04762326899993, 10.872305444000062],
              [-75.04746799799995, 10.872200598000063],
              [-75.0472700329999 , 10.872086824000064],
              [-75.04720297799992, 10.872035166000046],
              [-75.04714557299991, 10.871991734000062],
              [-75.04708699199995, 10.871890791000055],
              [-75.04707621499995, 10.871872221000046],
              [-75.04705598699991, 10.87176712300004 ],
              [-75.04701412799994, 10.871591050000063],
              [-75.04697569399991, 10.871537409000041],
              [-75.0469535069999 , 10.87150644400009 ],
              [-75.04684031999994, 10.871401765000087],
              [-75.0465772579999 , 10.871298408000087],
              [-75.0464789859999 , 10.871278208000092],
              [-75.0464583779999 , 10.871273970000061],
              [-75.04629862399992, 10.871200596000051],
              [-75.04615887499995, 10.871190912000088],
              [-75.04601203699991, 10.871192035000092],
              [-75.04588723699993, 10.871206438000058],
              [-75.04581624799994, 10.871237717000042],
              [-75.04573651099992, 10.871272849000093],
              [-75.0454929149999 , 10.87141650500007 ],
              [-75.04535688899995, 10.871478962000083],
              [-75.0452894419999 , 10.871519197000055],
              [-75.04523946599994, 10.871549009000091],
              [-75.04512066299992, 10.871639836000043],
              [-75.04503604299993, 10.87170765400009 ],
              [-75.0449600309999 , 10.871718357000077],
              [-75.0449144989999 , 10.871724769000082],
              [-75.04489362999993, 10.871722636000072],
              [-75.04484501899992, 10.871717666000052],
              [-75.04480488799993, 10.87170265900005 ],
              [-75.04477943499995, 10.871645276000038],
              [-75.04477949799991, 10.871587796000085],
              [-75.0447795039999 , 10.871581750000075],
              [-75.04478527599991, 10.871570164000047],
              [-75.04483132299993, 10.87147775200009 ],
              [-75.04488038799991, 10.871376894000036],
              [-75.04486823799994, 10.87129793400004 ],
              [-75.04482031399994, 10.871259824000049],
              [-75.04472950999991, 10.871248268000045],
              [-75.04463849599995, 10.871272181000052],
              [-75.04457902599995, 10.871370846000048],
              [-75.04456420499992, 10.871395432000043],
              [-75.04456104899992, 10.871398561000092],
              [-75.04455222799993, 10.871407312000088],
              [-75.0445002379999 , 10.87145888100008 ],
              [-75.04439822699993, 10.871495369000058],
              [-75.04429598099995, 10.871502320000047],
              [-75.04425517499993, 10.871505092000064],
              [-75.04422649399993, 10.871493876000045],
              [-75.04420447299992, 10.871485262000078],
              [-75.04417793099992, 10.871474882000086],
              [-75.04403329299993, 10.87138720200005 ],
              [-75.04378464299992, 10.871117541000046],
              [-75.04348861199992, 10.87070049600004 ],
              [-75.04338507599994, 10.870428812000057],
              [-75.0433669809999 , 10.870386893000045],
              [-75.04336329299991, 10.870378351000056],
              [-75.04331202199995, 10.870259580000038],
              [-75.04320783399993, 10.870074757000054],
              [-75.04311782899993, 10.869995275000065],
              [-75.04306843099994, 10.869995294000091],
              [-75.04303103099994, 10.869995308000057],
              [-75.04296298899993, 10.870010980000075],
              [-75.04293987999995, 10.870016303000057],
              [-75.04277178799992, 10.870048448000091],
              [-75.04268383899995, 10.870066803000043],
              [-75.04262455299994, 10.870079175000058],
              [-75.04248718799994, 10.87000590000008 ],
              [-75.04236970199992, 10.869968267000047],
              [-75.0422657869999 , 10.869863205000058],
              [-75.0422195509999 , 10.86980733200005 ],
              [-75.04224521799995, 10.869714075000047],
              [-75.0423250099999 , 10.869636816000082],
              [-75.04247455299992, 10.869492019000063],
              [-75.0425877639999 , 10.869406431000073],
              [-75.0426977809999 , 10.869323253000061],
              [-75.04289904199993, 10.869217972000058],
              [-75.04294012999992, 10.869160044000068],
              [-75.04304975499991, 10.869005490000063],
              [-75.04309049199992, 10.868948057000068],
              [-75.04312627399992, 10.868867736000084],
              [-75.04319493299994, 10.868713610000043],
              [-75.04318533299994, 10.868551926000066],
              [-75.04313874699994, 10.86848548200004 ],
              [-75.04310352699991, 10.868435245000057],
              [-75.04307238899992, 10.868421768000076],
              [-75.04304469199991, 10.868409780000093],
              [-75.04287246399991, 10.868438014000048],
              [-75.04277312599993, 10.868430083000078],
              [-75.04268751799992, 10.868406459000084],
              [-75.04266426299995, 10.868379337000079],
              [-75.04263390999995, 10.868343932000073],
              [-75.04262201999995, 10.868300410000074],
              [-75.04260463799994, 10.868236773000092],
              [-75.04260260199993, 10.868095290000042],
              [-75.04265390199993, 10.867908828000054],
              [-75.0427294559999 , 10.867726603000051],
              [-75.04277216899993, 10.867666392000046],
              [-75.0428372799999 , 10.867574603000037],
              [-75.04303026799994, 10.867401980000068],
              [-75.04318320399994, 10.867214841000077],
              [-75.04318869399992, 10.867208123000069],
              [-75.04322191099993, 10.86705831100005 ],
              [-75.04319994399992, 10.86693000200006 ],
              [-75.04316667499995, 10.866898624000044],
              [-75.04314045299992, 10.866886074000092],
              [-75.04308588299995, 10.866859955000052],
              [-75.04294512799993, 10.866857874000061],
              [-75.04285425899991, 10.866881842000055],
              [-75.0427846849999 , 10.866910246000089],
              [-75.04263455199992, 10.866941262000068],
              [-75.04262928799994, 10.866942289000065],
              [-75.04252763999995, 10.86696217900004 ],
              [-75.0423365879999 , 10.866928176000044],
              [-75.04201287599994, 10.866813654000055],
              [-75.04195820699994, 10.866802678000056],
              [-75.04191842799992, 10.866714011000056],
              [-75.04190197799994, 10.866617828000074],
              [-75.04190077499993, 10.866616528000066],
              [-75.04180730999991, 10.866515269000047],
              [-75.04168748399991, 10.86643508800006 ],
              [-75.04159028799995, 10.866425881000055],
              [-75.04149061599992, 10.866425585000059],
              [-75.04149058999991, 10.86642558400007 ],
              [-75.0414677199999 , 10.86642551500006 ],
              [-75.04133965699992, 10.866469748000043],
              [-75.04125183699995, 10.866531846000044],
              [-75.04111258099994, 10.866585692000058],
              [-75.04103026699994, 10.866603178000048],
              [-75.04090177799992, 10.866641540000046],
              [-75.04073350099992, 10.866706235000038],
              [-75.04060000599992, 10.866718414000047],
              [-75.04048343399995, 10.866717622000067],
              [-75.04013864299992, 10.866672467000058],
              [-75.03990260299992, 10.86667109800004 ],
              [-75.03989428599994, 10.866662775000066],
              [-75.03977309699991, 10.866541538000092],
              [-75.0397007059999 , 10.86645519600006 ],
              [-75.0396594799999 , 10.866394979000063],
              [-75.03954581099993, 10.866161224000052],
              [-75.03945153899991, 10.865985066000064],
              [-75.03929904599994, 10.865250254000046],
              [-75.03927696099993, 10.86483901400004 ],
              [-75.03926470999994, 10.864642529000037],
              [-75.03921960499991, 10.864504602000068],
              [-75.0392138599999 , 10.864487031000067],
              [-75.0391262409999 , 10.864357655000049],
              [-75.03902352299991, 10.864302973000065],
              [-75.0389449729999 , 10.86432906400006 ],
              [-75.0388686849999 , 10.86442272100004 ],
              [-75.0388403369999 , 10.864480187000083],
              [-75.03883257799993, 10.864495917000056],
              [-75.03878641099993, 10.864593372000058],
              [-75.03869141099995, 10.864676547000045],
              [-75.03851925899994, 10.864765091000038],
              [-75.03845079399991, 10.864728636000052],
              [-75.03840870099992, 10.864600134000057],
              [-75.03833971199992, 10.864393995000057],
              [-75.03832655799994, 10.86422411500007 ],
              [-75.0382917789999 , 10.863929128000052],
              [-75.03817752999993, 10.86361807500009 ],
              [-75.03816272799992, 10.86358652000007 ],
              [-75.03797156199994, 10.86317901700005 ],
              [-75.03779246399995, 10.862755202000073],
              [-75.0377233239999 , 10.862755414000048],
              [-75.03769366699993, 10.862727672000062],
              [-75.0376488739999 , 10.862685769000052],
              [-75.03756917399994, 10.862611214000083],
              [-75.03736814599995, 10.862423159000059],
              [-75.03730508699994, 10.862426472000038],
              [-75.03697229399995, 10.86244395400007 ],
              [-75.03655222099991, 10.862676357000055],
              [-75.0364628179999 , 10.862775297000042],
              [-75.03644929399991, 10.862836866000066],
              [-75.03649428099993, 10.863253866000036],
              [-75.03649994799991, 10.863306399000066],
              [-75.03652797799992, 10.86350135400005 ],
              [-75.03654554699995, 10.863623571000062],
              [-75.0365812309999 , 10.863683295000044],
              [-75.03659519299993, 10.863706654000055],
              [-75.03663429499994, 10.863909482000054],
              [-75.03659342799995, 10.86407932700007 ],
              [-75.03652222799991, 10.864183813000068],
              [-75.03634013299995, 10.864315745000056],
              [-75.03628732499993, 10.864322722000054],
              [-75.03622185599994, 10.864331371000048],
              [-75.03547312199993, 10.86411187400006 ],
              [-75.03514679199992, 10.863639719000048],
              [-75.03511873699995, 10.863448276000042],
              [-75.03512770799995, 10.863299509000058],
              [-75.0351417629999 , 10.863066382000056],
              [-75.03517358499994, 10.862817299000085],
              [-75.03522002299991, 10.862687685000083],
              [-75.03524523199991, 10.862617313000044],
              [-75.03528012399994, 10.862562275000073],
              [-75.03531884599994, 10.86250119400006 ],
              [-75.03541367699995, 10.862382842000045],
              [-75.03541452799993, 10.862379200000078],
              [-75.03544212899993, 10.86226128800007 ],
              [-75.03544740399991, 10.862253035000037],
              [-75.03543743599994, 10.862249701000053],
              [-75.03529133199993, 10.862200804000054],
              [-75.03525085399991, 10.862187258000063],
              [-75.03517995099992, 10.862163528000053],
              [-75.03469435599993, 10.86200100700006 ],
              [-75.03445841299992, 10.862168703000066],
              [-75.0344569419999 , 10.862169745000074],
              [-75.03427958499992, 10.862295804000041],
              [-75.03430218299991, 10.86237525000007 ],
              [-75.03437968499992, 10.862647714000047],
              [-75.03426110999993, 10.862868323000043],
              [-75.03425215999994, 10.862866335000092],
              [-75.0340699699999 , 10.862718849000089],
              [-75.0339094229999 , 10.862532483000052],
              [-75.03373437999994, 10.862360540000054],
              [-75.03354460199995, 10.862131143000056],
              [-75.03350275999992, 10.862049074000083],
              [-75.0334129069999 , 10.861872823000056],
              [-75.03336827899994, 10.861556732000054],
              [-75.03335254799993, 10.861183052000058],
              [-75.03320640299995, 10.86095352700005 ],
              [-75.03314610199993, 10.860917105000055],
              [-75.03306513899992, 10.860868203000052],
              [-75.03301699399992, 10.860839123000062],
              [-75.03278455299994, 10.860911719000057],
              [-75.03274925099993, 10.860966936000068],
              [-75.03258198099991, 10.861228570000037],
              [-75.03240821299994, 10.861459094000054],
              [-75.0320772739999 , 10.861510470000042],
              [-75.03194275099992, 10.861417415000062],
              [-75.0318260979999 , 10.861317160000056],
              [-75.03165100299992, 10.861130844000058],
              [-75.03156303399993, 10.860901125000055],
              [-75.03135893799993, 10.860743652000053],
              [-75.03106783899995, 10.860658303000037],
              [-75.0306316349999 , 10.860674034000056],
              [-75.03042809499993, 10.860689032000039],
              [-75.03000625199991, 10.860647234000055],
              [-75.02961322399995, 10.860519072000045],
              [-75.02939492399992, 10.860462252000048],
              [-75.02940859699993, 10.860189103000039],
              [-75.02940773099994, 10.859915997000087],
              [-75.02933420399995, 10.859657488000039],
              [-75.02891236699992, 10.859615674000054],
              [-75.02846184999993, 10.859703313000068],
              [-75.02844812599994, 10.859962081000049],
              [-75.0282020599999 , 10.860083778000046],
              [-75.02812465199992, 10.859967228000073],
              [-75.02807049799992, 10.85985869600006 ],
              [-75.02803055399994, 10.859741068000062],
              [-75.02800324999993, 10.859618001000058],
              [-75.02798515299992, 10.85949105900005 ],
              [-75.02797365499993, 10.859361413000045],
              [-75.02796679199992, 10.859229926000069],
              [-75.0279616439999 , 10.85896370000006 ],
              [-75.0279638419999 , 10.858561049000059],
              [-75.0279775169999 , 10.857486236000057],
              [-75.02797798499995, 10.857218824000086],
              [-75.02797335799994, 10.856953649000047],
              [-75.02796744699992, 10.856822692000037],
              [-75.02795769199992, 10.856693542000073],
              [-75.02794245599995, 10.856566979000092],
              [-75.02791958499995, 10.856444124000063],
              [-75.02788613899992, 10.856326418000037],
              [-75.02784104499995, 10.856217400000048],
              [-75.02778711699995, 10.856113633000064],
              [-75.0276860589999 , 10.855945875000089],
              [-75.0276160169999 , 10.85585031000005 ],
              [-75.0275346279999 , 10.855764299000043],
              [-75.02721339999994, 10.85549672600007 ],
              [-75.02711256099991, 10.855408728000043],
              [-75.02690499199991, 10.855221868000058],
              [-75.02668505699995, 10.855016251000052],
              [-75.02653670099994, 10.854877552000062],
              [-75.02653601799994, 10.854878317000043],
              [-75.0261972269999 , 10.855147957000042],
              [-75.02551891799993, 10.85546339800004 ],
              [-75.02474891799994, 10.855421009000054],
              [-75.02433195499992, 10.855277823000051],
              [-75.02405535199995, 10.855332730000043],
              [-75.02370726799995, 10.855401825000058],
              [-75.02289156199993, 10.855225271000052],
              [-75.02191791099995, 10.855228264000061],
              [-75.02139558699992, 10.855436650000058],
              [-75.02090774499993, 10.85563127800009 ],
              [-75.02056101499994, 10.85576960800006 ],
              [-75.01797920999991, 10.855804030000058],
              [-75.01792356999994, 10.855796467000062],
              [-75.01774836399994, 10.855696439000042],
              [-75.01618421799992, 10.854827840000041],
              [-75.01603673199992, 10.854673484000045],
              [-75.01583329099992, 10.854460565000068],
              [-75.0148642069999 , 10.853440977000048],
              [-75.0146787139999 , 10.853328566000073],
              [-75.01385237699992, 10.852827798000078],
              [-75.01378081899992, 10.852784433000068],
              [-75.0135054669999 , 10.852846680000084],
              [-75.01314744599995, 10.852927614000066],
              [-75.0127356669999 , 10.853019270000061],
              [-75.01223226799993, 10.853055832000052],
              [-75.01133435399993, 10.85311080100007 ],
              [-75.01054805399991, 10.853158937000046],
              [-75.01036906599995, 10.853005701000086],
              [-75.01024841299994, 10.85290240900008 ],
              [-75.01002808599992, 10.85271378400006 ],
              [-75.00986789599995, 10.852551162000054],
              [-75.00961321299991, 10.852369252000074],
              [-75.00957448899993, 10.852341594000052],
              [-75.00957406099991, 10.852204795000091],
              [-75.00960009699992, 10.852020559000039],
              [-75.00965827199991, 10.851899367000044],
              [-75.00976962099992, 10.851762222000048],
              [-75.00987617599992, 10.851682717000074],
              [-75.0099130989999 , 10.851655166000057],
              [-75.00998203799992, 10.851603727000054],
              [-75.01011266899991, 10.851505278000047],
              [-75.01015728199991, 10.851471657000047],
              [-75.01014332599993, 10.851423934000081],
              [-75.01010348099993, 10.85128766300005 ],
              [-75.01007635399992, 10.851124635000076],
              [-75.01009701999993, 10.850924631000055],
              [-75.01017527399995, 10.850414016000059],
              [-75.01016950299993, 10.850271970000051],
              [-75.01021159599992, 10.850113996000061],
              [-75.01030150399993, 10.84992956800005 ],
              [-75.01036452599993, 10.849655768000048],
              [-75.01042777599991, 10.849455637000062],
              [-75.01042487599995, 10.84938180100005 ],
              [-75.0104239819999 , 10.849254182000038],
              [-75.01042001299993, 10.848687718000065],
              [-75.0104514009999 , 10.848497953000049],
              [-75.0105091289999 , 10.848234698000056],
              [-75.01050793099995, 10.847850605000076],
              [-75.0106175219999 , 10.84715047800006 ],
              [-75.01070697299991, 10.846818725000048],
              [-75.01077030099992, 10.846644901000047],
              [-75.0107706579999 , 10.846637372000089],
              [-75.01077413399992, 10.846564123000064],
              [-75.01078029099995, 10.846434404000092],
              [-75.01073744399991, 10.84635034900009 ],
              [-75.0106256709999 , 10.846350692000044],
              [-75.01047138099995, 10.846372207000059],
              [-75.01016269999991, 10.846383672000059],
              [-75.00981132099992, 10.846358432000045],
              [-75.00975209899991, 10.846288134000076],
              [-75.00968310399992, 10.846206237000047],
              [-75.00952237399991, 10.845869984000046],
              [-75.00930239299993, 10.845307667000043],
              [-75.00917423899995, 10.845176514000059],
              [-75.0088649029999 , 10.84497751300006 ],
              [-75.00850243999992, 10.84481024300004 ],
              [-75.00833432899992, 10.84473829600006 ],
              [-75.0081240259999 , 10.844648285000062],
              [-75.00774580399991, 10.844549465000057],
              [-75.00760847299995, 10.84455446000004 ],
              [-75.00753124399995, 10.844557269000063],
              [-75.00741254699994, 10.844561586000054],
              [-75.00731110599992, 10.84457583200009 ],
              [-75.00711550199992, 10.844603300000074],
              [-75.00704584899995, 10.844613081000091],
              [-75.0069955319999 , 10.844620147000057],
              [-75.00700570299995, 10.844467527000063],
              [-75.00703997599993, 10.844277864000048],
              [-75.00704756399995, 10.84423589100004 ],
              [-75.00709015199993, 10.843763926000065],
              [-75.00710880999992, 10.843557226000087],
              [-75.00710930999992, 10.843551693000052],
              [-75.00726217599993, 10.84307242400007 ],
              [-75.00734107999995, 10.842772274000083],
              [-75.00737764099995, 10.842603155000063],
              [-75.00755621899992, 10.84177717700004 ],
              [-75.00770712099995, 10.840666522000049],
              [-75.00767975799994, 10.840308509000067],
              [-75.0076793799999 , 10.840303554000059],
              [-75.00754025799995, 10.840067202000057],
              [-75.00732653799992, 10.839804772000036],
              [-75.00694747699993, 10.83943234700007 ],
              [-75.00676981299995, 10.839223873000037],
              [-75.00675516399991, 10.839206683000043],
              [-75.00663728299992, 10.838954484000055],
              [-75.0065874519999 , 10.838333767000051],
              [-75.00670820399995, 10.83779671700006 ],
              [-75.00689729899995, 10.836985852000055],
              [-75.00701300399993, 10.836538266000048],
              [-75.00702831399991, 10.83632775600006 ],
              [-75.00711275799995, 10.836095989000057],
              [-75.00729827799995, 10.835848130000045],
              [-75.00755292199995, 10.835579016000054],
              [-75.00774905199995, 10.835320600000045],
              [-75.0080036139999 , 10.835025176000045],
              [-75.00811469899992, 10.83480385200005 ],
              [-75.0081236769999 , 10.834767871000054],
              [-75.00783151699994, 10.834098071000085],
              [-75.00780597399995, 10.834039511000071],
              [-75.00768681599993, 10.833945868000058],
              [-75.00767555899995, 10.833937021000054],
              [-75.00752846499995, 10.833758054000043],
              [-75.00752824799991, 10.83375778900006 ],
              [-75.00751475099992, 10.833741370000041],
              [-75.0070932079999 , 10.833228470000051],
              [-75.00650602799993, 10.832677784000055],
              [-75.00579117299992, 10.832148529000051],
              [-75.00475647699994, 10.831457129000057],
              [-75.00370075699993, 10.830849973000056],
              [-75.0033596639999 , 10.830703680000056],
              [-75.00281116899993, 10.830610628000045],
              [-75.00215641699992, 10.830581033000044],
              [-75.00198067699995, 10.830549996000059],
              [-75.00183167099993, 10.830555704000062],
              [-75.0014698409999 , 10.830588369000054],
              [-75.00083111099991, 10.830574506000062],
              [-75.00037864099994, 10.830554824000046],
              [-75.00013366399992, 10.830508207000037],
              [-74.99963310299995, 10.830425526000056],
              [-74.99943617399992, 10.830426120000084],
              [-74.99934591699991, 10.830500054000083],
              [-74.99921857299995, 10.83062671500005 ],
              [-74.99911262499995, 10.830790144000048],
              [-74.99896432899993, 10.831027362000043],
              [-74.99818078399994, 10.830656143000056],
              [-74.99752515799992, 10.830342417000054],
              [-74.99719400999993, 10.82996457300004 ],
              [-74.99684201899993, 10.829734117000044],
              [-74.99622344599993, 10.829351874000054],
              [-74.99601305199991, 10.829153093000059],
              [-74.99594587799993, 10.829089625000051],
              [-74.99595568399991, 10.829053040000076],
              [-74.99600376199993, 10.82887372500005 ],
              [-74.9960717749999 , 10.828489425000043],
              [-74.99606591799994, 10.82831581000005 ],
              [-74.99590492399994, 10.827884839000092],
              [-74.99589371799993, 10.827700715000049],
              [-74.99599458499995, 10.827616225000043],
              [-74.99636114899994, 10.827394138000045],
              [-74.99619450999995, 10.826857954000047],
              [-74.99615680199992, 10.826710737000042],
              [-74.99608599699991, 10.82618479000007 ],
              [-74.99602006399994, 10.825511498000083],
              [-74.99606856199995, 10.824925344000064],
              [-74.9960919159999 , 10.824643116000061],
              [-74.99626136499995, 10.824178059000076],
              [-74.99668303999994, 10.823020763000045],
              [-74.99769122299995, 10.820292215000052],
              [-74.99803971099993, 10.81938616900004 ],
              [-74.9980922709999 , 10.819170284000052],
              [-74.99805982099991, 10.819002013000045],
              [-74.99787813899991, 10.81876578300006 ],
              [-74.99769092299994, 10.818596742000068],
              [-74.99768066999991, 10.818587482000055],
              [-74.9976323489999 , 10.818450821000056],
              [-74.9976317199999 , 10.818245622000063],
              [-74.99769988899993, 10.817913932000067],
              [-74.99786437699993, 10.817750333000049],
              [-74.9980187189999 , 10.817749871000046],
              [-74.99813041999994, 10.817728489000046],
              [-74.99830586999991, 10.817670083000053],
              [-74.99846535799992, 10.817611727000042],
              [-74.99861401099992, 10.817490260000056],
              [-74.99864299799992, 10.81744304700004 ],
              [-74.99867226499993, 10.817395379000061],
              [-74.99864524799995, 10.817263919000084],
              [-74.99855972299991, 10.817143160000057],
              [-74.9984537169999 , 10.816925736000087],
              [-74.99844188999992, 10.816901476000055],
              [-74.99841455399991, 10.816664786000047],
              [-74.99843935299992, 10.816075411000043],
              [-74.9984448909999 , 10.815894692000086],
              [-74.99844902899991, 10.815759680000042],
              [-74.99830434499995, 10.81543915800006 ],
              [-74.9980694759999 , 10.815213612000036],
              [-74.9977124269999 , 10.81506209400004 ],
              [-74.99730778599991, 10.815010692000044],
              [-74.99680766599994, 10.815064808000045],
              [-74.9965523329999 , 10.815107664000038],
              [-74.9963820079999 , 10.815102916000058],
              [-74.99584461899991, 10.815151877000062],
              [-74.99539235299994, 10.815190063000045],
              [-74.99525939599994, 10.815222034000044],
              [-74.99497242399991, 10.81525500500004 ],
              [-74.99495936999995, 10.815256945000044],
              [-74.99466231899993, 10.81516044500006 ],
              [-74.99450526399994, 10.815274127000066],
              [-74.9943788409999 , 10.81536563700007 ],
              [-74.99431220699995, 10.815289532000065],
              [-74.9942962049999 , 10.815218511000069],
              [-74.99429511999995, 10.815213692000043],
              [-74.99430655299994, 10.815071044000092],
              [-74.99434587199994, 10.814941905000069],
              [-74.99450707299991, 10.814810139000087],
              [-74.99461706699992, 10.814594294000074],
              [-74.9946606929999 , 10.814339191000045],
              [-74.9946243359999 , 10.813997892000089],
              [-74.99456527599995, 10.813799926000058],
              [-74.99451929899993, 10.813645825000037],
              [-74.99441178399991, 10.813396881000074],
              [-74.99435999499991, 10.81316540000006 ],
              [-74.99427095399994, 10.812975227000038],
              [-74.99413774599992, 10.812902361000056],
              [-74.99397115199992, 10.812884478000058],
              [-74.99378772799992, 10.812859625000044],
              [-74.99358956099991, 10.812873869000043],
              [-74.99342761199995, 10.812942436000071],
              [-74.99325943999992, 10.813016298000036],
              [-74.99303040599995, 10.813047466000057],
              [-74.99291061999992, 10.81309613600007 ],
              [-74.99283281099991, 10.813199527000052],
              [-74.9927402909999 , 10.813342018000071],
              [-74.99272402899993, 10.81347284800006 ],
              [-74.99269926599993, 10.813637491000065],
              [-74.99252335299991, 10.813808355000049],
              [-74.99230864899994, 10.813884550000068],
              [-74.99219873099992, 10.813882289000048],
              [-74.99201755199994, 10.813828934000071],
              [-74.9919511999999 , 10.813571541000044],
              [-74.99186539599992, 10.813413660000037],
              [-74.99186025699993, 10.81340420400005 ],
              [-74.99168029399993, 10.813190174000056],
              [-74.99157113099994, 10.813032959000054],
              [-74.99151732099995, 10.81290847300005 ],
              [-74.99151310199994, 10.812856184000054],
              [-74.9915056669999 , 10.812764094000045],
              [-74.99153262599992, 10.812641745000064],
              [-74.9917279469999 , 10.812518232000059],
              [-74.99183501099992, 10.812411232000045],
              [-74.99189450199992, 10.812174477000042],
              [-74.99189575499992, 10.812013833000037],
              [-74.99180920499992, 10.811863991000052],
              [-74.9917241359999 , 10.811840551000046],
              [-74.99160359399991, 10.811826010000061],
              [-74.99143830699995, 10.811790965000057],
              [-74.99134746899995, 10.811767107000037],
              [-74.99129896199992, 10.811648735000063],
              [-74.9913830129999 , 10.811411907000092],
              [-74.99148561299995, 10.811277260000054],
              [-74.99151390999992, 10.811240122000072],
              [-74.99161189099993, 10.811029159000043],
              [-74.99161616299995, 10.810903194000048],
              [-74.9915287099999 , 10.810764759000051],
              [-74.99140394699992, 10.810658032000049],
              [-74.9912339949999 , 10.810536506000062],
              [-74.99107708699995, 10.810324067000067],
              [-74.99098398799993, 10.81012884100005 ],
              [-74.99103792699992, 10.809629089000055],
              [-74.99116869699992, 10.809376257000054],
              [-74.99132796799995, 10.809332488000052],
              [-74.99146741599992, 10.809343411000043],
              [-74.99162760799993, 10.809452240000041],
              [-74.9916542709999 , 10.809637867000049],
              [-74.99167495099994, 10.809824037000055],
              [-74.9917471629999 , 10.809899761000054],
              [-74.9918249079999 , 10.809892726000044],
              [-74.99185802999995, 10.80985447200004 ],
              [-74.99184288399994, 10.809726383000054],
              [-74.99191876999993, 10.809619656000052],
              [-74.99203280099994, 10.809603300000049],
              [-74.9922483819999 , 10.809513343000049],
              [-74.99230863499992, 10.809277029000043],
              [-74.99231734099993, 10.809242881000046],
              [-74.99220889399993, 10.809088256000052],
              [-74.99203102399991, 10.808892889000049],
              [-74.99199609899995, 10.808755096000084],
              [-74.99200026199992, 10.80850221500009 ],
              [-74.99200899499994, 10.808488631000046],
              [-74.99212436399995, 10.808309120000047],
              [-74.9922447699999 , 10.80815741400005 ],
              [-74.9923590009999 , 10.808086764000052],
              [-74.9925888969999 , 10.807944577000058],
              [-74.99286172599994, 10.807744050000053],
              [-74.99319048699994, 10.807644175000064],
              [-74.99350217599994, 10.807633850000059],
              [-74.99397028399994, 10.80762142900005 ],
              [-74.99413727099994, 10.807600773000047],
              [-74.99432753899993, 10.80742544900005 ],
              [-74.99460990099993, 10.80712468300004 ],
              [-74.99489907499992, 10.807216036000057],
              [-74.9950725619999 , 10.807206618000066],
              [-74.99524778599994, 10.807068172000072],
              [-74.9954103039999 , 10.806772530000046],
              [-74.9954565189999 , 10.80644630100005 ],
              [-74.99553523499992, 10.806152345000044],
              [-74.9955530279999 , 10.806040887000051],
              [-74.99558739199995, 10.805825628000036],
              [-74.99546515999992, 10.80564325000006 ],
              [-74.99530721899993, 10.805616284000052],
              [-74.99519228999992, 10.805450799000084],
              [-74.99507873199991, 10.805378874000041],
              [-74.99486394899992, 10.805392059000042],
              [-74.99459049899991, 10.805498323000052],
              [-74.99442098399993, 10.805630522000058],
              [-74.9943027679999 , 10.805805063000037],
              [-74.99419003799994, 10.806049443000063],
              [-74.9939810379999 , 10.806366886000092],
              [-74.99381234099991, 10.806510840000044],
              [-74.99361977199993, 10.806580755000084],
              [-74.99346661499993, 10.806541651000089],
              [-74.99338912099995, 10.806319966000046],
              [-74.99338682899992, 10.806062264000047],
              [-74.99323457499992, 10.805882360000055],
              [-74.9931899529999 , 10.805884500000047],
              [-74.993091        , 10.805889251000053],
              [-74.99298503099993, 10.805840036000063],
              [-74.99285565199995, 10.805722545000037],
              [-74.99272444299993, 10.805505535000066],
              [-74.99257118199995, 10.805313910000052],
              [-74.9924615029999 , 10.805218083000057],
              [-74.99230178699992, 10.805110699000068],
              [-74.99224665899993, 10.805073640000046],
              [-74.99207224399993, 10.804995255000051],
              [-74.9919299629999 , 10.805008580000049],
              [-74.9917858359999 , 10.805009590000054],
              [-74.99170091799994, 10.80492329100008 ],
              [-74.9916619139999 , 10.80473326300006 ],
              [-74.99160269899994, 10.804668133000064],
              [-74.99146724999991, 10.804703534000055],
              [-74.99128678499994, 10.804625634000047],
              [-74.99117931799992, 10.804635120000057],
              [-74.9911040099999 , 10.804518615000063],
              [-74.9910994299999 , 10.804384137000056],
              [-74.99117248099992, 10.80424284500009 ],
              [-74.99122211499991, 10.804033254000046],
              [-74.99112156899992, 10.80374891100007 ],
              [-74.9909695579999 , 10.803574611000045],
              [-74.99082762699993, 10.803452182000058],
              [-74.99071491299992, 10.803304838000088],
              [-74.99071110299991, 10.803140412000062],
              [-74.99070989499995, 10.803088341000091],
              [-74.99086588499995, 10.802917606000051],
              [-74.99096599099994, 10.802815072000044],
              [-74.99107621999991, 10.802612039000053],
              [-74.99104322699992, 10.802192951000052],
              [-74.99090370699992, 10.801794847000053],
              [-74.99086602799991, 10.801724913000044],
              [-74.9907558939999 , 10.801520505000042],
              [-74.99060161199992, 10.801393217000054],
              [-74.9904372279999 , 10.80113784100007 ],
              [-74.99043221499994, 10.800921396000092],
              [-74.99054799299995, 10.800712106000049],
              [-74.99070417199994, 10.80048176400004 ],
              [-74.9909489449999 , 10.800155932000052],
              [-74.99100314799995, 10.800004775000048],
              [-74.9910036359999 , 10.799782070000049],
              [-74.99091873899994, 10.799619448000044],
              [-74.99083106599994, 10.799498063000044],
              [-74.99078655199992, 10.799314377000087],
              [-74.99079044399991, 10.79928994100004 ],
              [-74.99080263799993, 10.799213377000058],
              [-74.99093625299992, 10.799155048000046],
              [-74.99116761199991, 10.799117639000087],
              [-74.99127165199991, 10.799116948000062],
              [-74.99138973599992, 10.799116170000048],
              [-74.99166549799992, 10.799186249000059],
              [-74.99189028399991, 10.79914354300007 ],
              [-74.99201918599994, 10.79902706900009 ],
              [-74.99208159999995, 10.798666530000048],
              [-74.99207870499993, 10.79850891700005 ],
              [-74.99207794699993, 10.798467782000046],
              [-74.99200231099991, 10.798269325000092],
              [-74.9919881109999 , 10.798089029000039],
              [-74.99204224599993, 10.797861946000069],
              [-74.99222748699992, 10.797699808000061],
              [-74.99232280399991, 10.797682216000055],
              [-74.9924423519999 , 10.797668418000057],
              [-74.99254352699995, 10.797650342000054],
              [-74.99276429299994, 10.79740928700005 ],
              [-74.99291994299995, 10.797270697000044],
              [-74.99297201699994, 10.797224326000048],
              [-74.99318368799993, 10.797129673000086],
              [-74.9933827609999 , 10.797185889000048],
              [-74.99349530199993, 10.797246460000054],
              [-74.99365631499995, 10.797238618000051],
              [-74.99378849199991, 10.797168946000056],
              [-74.99387446199995, 10.79704477100006 ],
              [-74.99375876899995, 10.796791633000055],
              [-74.99368072499993, 10.796707154000046],
              [-74.9935595469999 , 10.796460340000067],
              [-74.99357647199992, 10.796219359000077],
              [-74.99367067999992, 10.795971862000044],
              [-74.99366840099992, 10.795639055000038],
              [-74.99371080899994, 10.795266783000045],
              [-74.9938328579999 , 10.795001434000085],
              [-74.99347331399991, 10.794697778000057],
              [-74.99311902699992, 10.794376871000054],
              [-74.99285300199995, 10.794088215000045],
              [-74.99255672999993, 10.793902501000048],
              [-74.99236728599993, 10.79389321800005 ],
              [-74.99219922599991, 10.793927375000067],
              [-74.99196850999994, 10.794015243000047],
              [-74.9916784909999 , 10.794104506000053],
              [-74.99134510399995, 10.794132122000065],
              [-74.9909277289999 , 10.79408133000004 ],
              [-74.99073721999991, 10.793915352000056],
              [-74.9905741099999 , 10.79359880000004 ],
              [-74.99046403699992, 10.79328726700004 ],
              [-74.99029406599993, 10.792987982000056],
              [-74.99014163499993, 10.792876698000043],
              [-74.98996555199994, 10.792857745000049],
              [-74.9897806109999 , 10.792884765000053],
              [-74.9896545659999 , 10.793008590000056],
              [-74.98949710999995, 10.793160021000062],
              [-74.98929134299993, 10.793144665000057],
              [-74.9891231169999 , 10.793097150000051],
              [-74.98899455299994, 10.793075909000038],
              [-74.98879471999993, 10.79306099300004 ],
              [-74.98861391799994, 10.793117425000048],
              [-74.98841225299992, 10.793131505000076],
              [-74.98819709799994, 10.793080487000054],
              [-74.98808345899994, 10.793018712000048],
              [-74.98800524299992, 10.792964552000058],
              [-74.98790457299992, 10.792788228000063],
              [-74.98754695499991, 10.792498072000058],
              [-74.98729089299991, 10.792001313000071],
              [-74.9872000069999 , 10.791685722000068],
              [-74.98722478799993, 10.791529002000061],
              [-74.98722724499993, 10.791513442000053],
              [-74.98724098199995, 10.791426587000046],
              [-74.98728032199995, 10.791201779000062],
              [-74.98728533899992, 10.790951877000055],
              [-74.98717882499994, 10.790688456000055],
              [-74.98690740899991, 10.790528596000058],
              [-74.98669265799992, 10.790483677000054],
              [-74.9864621669999 , 10.79051264800006 ],
              [-74.9862233099999 , 10.790581578000058],
              [-74.98599132599992, 10.79054657000006 ],
              [-74.98598607899993, 10.790543980000052],
              [-74.98574169699992, 10.790423272000055],
              [-74.9855239069999 , 10.790354562000061],
              [-74.98524196299991, 10.790281131000086],
              [-74.9851302269999 , 10.790276554000059],
              [-74.9849463889999 , 10.790269027000079],
              [-74.98501441099995, 10.790109009000048],
              [-74.98513491799991, 10.789875463000044],
              [-74.98529217799995, 10.789681295000037],
              [-74.9855521149999 , 10.78942587000006 ],
              [-74.98579859799992, 10.789115193000043],
              [-74.98606277699992, 10.788896547000093],
              [-74.98623178499992, 10.788686380000058],
              [-74.98637403799995, 10.788607012000057],
              [-74.98651490499992, 10.78855984900008 ],
              [-74.9865485659999 , 10.788548580000054],
              [-74.98684873499991, 10.78844282700004 ],
              [-74.98751771799994, 10.78834911000007 ],
              [-74.98797790399993, 10.788251494000065],
              [-74.98822685999994, 10.788168418000055],
              [-74.98871645999992, 10.787903200000073],
              [-74.98908265599994, 10.787689973000056],
              [-74.9894241639999 , 10.787391512000056],
              [-74.98979247799991, 10.786957589000053],
              [-74.99018678099992, 10.78671010000005 ],
              [-74.9906615829999 , 10.786300015000052],
              [-74.99112201899993, 10.785931145000063],
              [-74.99154044199992, 10.785587319000058],
              [-74.99203557399994, 10.785237129000052],
              [-74.99250523899991, 10.785039279000046],
              [-74.99258748199992, 10.785004632000039],
              [-74.99326157799993, 10.786995794000063],
              [-74.99384192899993, 10.788710052000056],
              [-74.99385493199992, 10.788806691000048],
              [-74.99385315699993, 10.78899510900004 ],
              [-74.99387284699992, 10.789363053000045],
              [-74.99387093699994, 10.789558399000043],
              [-74.9938819489999 , 10.78986646900006 ],
              [-74.99394221299991, 10.790117419000069],
              [-74.99408426999992, 10.790302373000088],
              [-74.99433324699993, 10.790460586000052],
              [-74.99481639699991, 10.790632093000056],
              [-74.99523056899994, 10.790738628000042],
              [-74.99538237299993, 10.790729849000058],
              [-74.9954765889999 , 10.79072440300007 ],
              [-74.99594132099992, 10.790642940000055],
              [-74.99643083299992, 10.790628272000049],
              [-74.9969061079999 , 10.79065262000006 ],
              [-74.997349        , 10.79066972600009 ],
              [-74.99766486099992, 10.790626196000062],
              [-74.99838434599991, 10.790583368000057],
              [-74.99880434999994, 10.790565937000054],
              [-74.99922184499991, 10.79056907100005 ],
              [-74.99959803999991, 10.790521400000046],
              [-74.99997391599993, 10.790508195000086],
              [-75.00043525799992, 10.790532375000055],
              [-75.00084271599991, 10.790615893000052],
              [-75.00126168799994, 10.79070411400005 ],
              [-75.00150865699993, 10.790727364000077],
              [-75.0016069909999 , 10.790736622000054],
              [-75.0016369949999 , 10.79050701500006 ],
              [-75.00170328699994, 10.790369597000051],
              [-75.00172056399992, 10.790018084000053],
              [-75.00173717899992, 10.789730889000055],
              [-75.00171420299995, 10.789459572000055],
              [-75.00161624299994, 10.789028946000087],
              [-75.00153249099992, 10.788807735000091],
              [-75.00151981699992, 10.788681222000037],
              [-75.0014032229999 , 10.788252771000089],
              [-75.00090446799993, 10.787768620000065],
              [-75.00051688699995, 10.787549659000092],
              [-75.0004357329999 , 10.78751434500009 ],
              [-75.00018889299992, 10.787406937000071],
              [-74.99989108799991, 10.78725987100006 ],
              [-74.99986985399994, 10.787248488000046],
              [-74.99966487599994, 10.787138594000055],
              [-74.99928102799993, 10.787018492000072],
              [-74.99925659199994, 10.786411520000058],
              [-74.9992093969999 , 10.785763021000037],
              [-74.99921251699993, 10.785429761000046],
              [-74.99919914199995, 10.784397693000074],
              [-74.99928245099994, 10.78368574700005 ],
              [-74.99960017799992, 10.782950423000045],
              [-74.99983119799992, 10.782563725000045],
              [-75.0001505159999 , 10.781906495000044],
              [-75.00048537799995, 10.781332130000067],
              [-75.00084556099995, 10.78076948200004 ],
              [-75.00100448099994, 10.780477760000053],
              [-75.00107538299994, 10.780509205000044],
              [-75.00116318199991, 10.780537467000045],
              [-75.00130238499992, 10.780538530000058],
              [-75.00163412699993, 10.78053181200005 ],
              [-75.00164116499991, 10.780531260000089],
              [-75.00180396299993, 10.780518503000053],
              [-75.00180493699992, 10.780527453000047],
              [-75.00207095999991, 10.78051219100007 ],
              [-75.00221260099994, 10.78050406300008 ],
              [-75.00268643899994, 10.780459054000062],
              [-75.00295690299993, 10.778981275000092],
              [-75.00305891199991, 10.775835137000058],
              [-75.00313763999992, 10.775829194000039],
              [-75.00344414299991, 10.775792469000066],
              [-75.00371105099993, 10.775771534000057],
              [-75.00373037199995, 10.775771689000067],
              [-75.00380147299995, 10.775772261000043],
              [-75.0038759169999 , 10.775754383000049],
              [-75.00413977699992, 10.775809232000086],
              [-75.00420625099991, 10.77576939000005 ],
              [-75.00427765899991, 10.775704584000039],
              [-75.00437977699994, 10.775667930000054],
              [-75.00447110399995, 10.775643892000062],
              [-75.00454881099995, 10.775629804000062],
              [-75.00462870399991, 10.775615324000057],
              [-75.0048933779999 , 10.77558748100006 ],
              [-75.00520415799991, 10.775589787000058],
              [-75.00558865699992, 10.775647882000044],
              [-75.00589446499993, 10.775686997000037],
              [-75.00625776999993, 10.77577248600005 ],
              [-75.00655827799994, 10.77587588700004 ],
              [-75.00687005899994, 10.776020766000045],
              [-75.00721010799992, 10.776115295000068],
              [-75.00758702799993, 10.77623999900004 ],
              [-75.00788295199993, 10.776338749000047],
              [-75.00820230099993, 10.776414762000059],
              [-75.00855250399991, 10.776419631000067],
              [-75.0088240529999 , 10.77639649200006 ],
              [-75.0091515279999 , 10.776350660000048],
              [-75.00940010199992, 10.776313469000058],
              [-75.00960179299994, 10.776282618000039],
              [-75.00967187999993, 10.776271898000061],
              [-75.00995515999995, 10.776232694000043],
              [-75.01025478699995, 10.776188993000062],
              [-75.01052187499994, 10.776154218000045],
              [-75.01093038399995, 10.776122821000058],
              [-75.01101863399992, 10.776114291000056],
              [-75.01126229699992, 10.776102327000046],
              [-75.01162176499992, 10.776102784000045],
              [-75.01193958399995, 10.776098292000086],
              [-75.01222505299995, 10.776075182000056],
              [-75.01264311699993, 10.776014025000052],
              [-75.01329747599993, 10.77598675900009 ],
              [-75.01384649399995, 10.77605528500004 ],
              [-75.01433860399993, 10.776050400000088],
              [-75.01450299699991, 10.776048770000045],
              [-75.01497846899991, 10.776052328000048],
              [-75.01566663299991, 10.776124177000042],
              [-75.01630869199994, 10.776163527000051],
              [-75.01698790799992, 10.776203125000052],
              [-75.01780930399991, 10.776170320000062],
              [-75.01855300499994, 10.776256327000056],
              [-75.01907904699993, 10.776306266000063],
              [-75.0195731419999 , 10.776303128000052],
              [-75.02004679499993, 10.776251539000043],
              [-75.02036423399994, 10.776220438000053],
              [-75.02033444799991, 10.776242188000083],
              [-75.02029560099993, 10.776425776000053],
              [-75.02023530199995, 10.776671287000056],
              [-75.02017309399992, 10.77687762000005 ],
              [-75.0200693669999 , 10.777051589000052],
              [-75.0198690329999 , 10.777144284000087],
              [-75.01964521699995, 10.777266720000057],
              [-75.01963514899995, 10.777276111000049],
              [-75.01964788299995, 10.777289458000041],
              [-75.01988925899991, 10.777261765000048],
              [-75.02006219799995, 10.777234373000056],
              [-75.02019983299994, 10.777122488000089],
              [-75.02030437099995, 10.776980025000057],
              [-75.02032344299994, 10.77687682000004 ],
              [-75.02032826899995, 10.776850718000048],
              [-75.0204705459999 , 10.776265719000037],
              [-75.02054340899991, 10.776230178000048],
              [-75.02064192499995, 10.776221185000054],
              [-75.02075717699995, 10.77612315500005 ],
              [-75.0207651579999 , 10.775893672000052],
              [-75.02076586899994, 10.775873198000056],
              [-75.02064269199991, 10.775595699000064],
              [-75.0204735289999 , 10.775258866000058],
              [-75.02033556499993, 10.775080875000072],
              [-75.02017520899994, 10.77504927700005 ],
              [-75.02004012999993, 10.775107779000052],
              [-75.01992178499995, 10.775089674000071],
              [-75.01983062799991, 10.775033844000063],
              [-75.01977561099994, 10.774802114000067],
              [-75.0196191469999 , 10.774544968000043],
              [-75.01952767099993, 10.774432027000046],
              [-75.01954262899994, 10.774375245000044],
              [-75.01961659799991, 10.77409442600009 ],
              [-75.01985130199995, 10.773905705000061],
              [-75.0201136689999 , 10.773564428000043],
              [-75.02059971199992, 10.77303167100007 ],
              [-75.02072229399994, 10.77289730700005 ],
              [-75.0211421219999 , 10.772547622000047],
              [-75.02139544799991, 10.772172883000053],
              [-75.0216406319999 , 10.771839298000089],
              [-75.0217985999999 , 10.77130605700006 ],
              [-75.0219177749999 , 10.771133847000044],
              [-75.02203590399995, 10.771152014000052],
              [-75.02205106999992, 10.77126019700006 ],
              [-75.02212237299995, 10.771478624000054],
              [-75.0221585459999 , 10.771492652000063],
              [-75.02230092199994, 10.771270481000045],
              [-75.02233886399995, 10.771211275000041],
              [-75.02245507599991, 10.770803064000063],
              [-75.02245512299993, 10.770782248000046],
              [-75.02245582499995, 10.770474456000045],
              [-75.02247935499992, 10.770207206000066],
              [-75.02255919499993, 10.769917615000054],
              [-75.02252751099991, 10.769678844000055],
              [-75.02248124099992, 10.769597563000048],
              [-75.02245886599991, 10.769558251000092],
              [-75.02236640799993, 10.769566569000062],
              [-75.02234881699991, 10.769573782000066],
              [-75.02220862699994, 10.769437957000036],
              [-75.02219251999992, 10.76942101600008 ],
              [-75.02214850899992, 10.769374730000038],
              [-75.02208679599994, 10.76928890000005 ],
              [-75.02205711199991, 10.769139516000052],
              [-75.02206381099995, 10.769063620000054],
              [-75.02208758199993, 10.769065669000042],
              [-75.02228708599995, 10.768821270000046],
              [-75.02263454299992, 10.768750645000068],
              [-75.02342035499993, 10.768623783000066],
              [-75.02361729899991, 10.768586320000054],
              [-75.0237383779999 , 10.768647655000052],
              [-75.02398142999994, 10.768719327000042],
              [-75.0244428069999 , 10.768994776000056],
              [-75.02501066799994, 10.769041457000071],
              [-75.02549669899992, 10.769122880000054],
              [-75.02567439099994, 10.769149131000063],
              [-75.02600713699991, 10.769235896000055],
              [-75.02643847299993, 10.76937021000009 ],
              [-75.02684601599992, 10.769505374000062],
              [-75.02709530499993, 10.769571638000059],
              [-75.02734074599994, 10.769629524000038],
              [-75.02768683499994, 10.769682774000046],
              [-75.02797456899992, 10.769727033000038],
              [-75.02832133299995, 10.76972898300005 ],
              [-75.02879387999991, 10.769736788000046],
              [-75.02894190699993, 10.76969947200007 ],
              [-75.0291169059999 , 10.769633962000057],
              [-75.02928698399995, 10.76957595600004 ],
              [-75.02945498899993, 10.769512146000068],
              [-75.02967091599993, 10.769475398000054],
              [-75.02979342799995, 10.769464356000071],
              [-75.02994049499995, 10.769446991000052],
              [-75.03010731899991, 10.76941772500004 ],
              [-75.03028997899992, 10.769378611000093],
              [-75.03040318399991, 10.769375788000048],
              [-75.03050557299991, 10.769378148000044],
              [-75.0306004869999 , 10.769377159000044],
              [-75.03069968299991, 10.769378437000057],
              [-75.0307762619999 , 10.769447158000048],
              [-75.0308166289999 , 10.769483384000068],
              [-75.03100805799994, 10.769648750000044],
              [-75.03121795699991, 10.76978143100007 ],
              [-75.03148120599991, 10.769914504000042],
              [-75.03181881599994, 10.77002744300006 ],
              [-75.03210171699993, 10.770029604000058],
              [-75.03227371699995, 10.769991785000059],
              [-75.0324388809999 , 10.769935557000053],
              [-75.03276717999995, 10.769793226000047],
              [-75.03319034099991, 10.769679204000056],
              [-75.03333668599993, 10.769657284000061],
              [-75.03362358699991, 10.769728499000053],
              [-75.03383198799992, 10.769771495000043],
              [-75.03413287699993, 10.76983580600006 ],
              [-75.03445203299992, 10.769941739000046],
              [-75.0349930619999 , 10.770127476000084],
              [-75.0352916359999 , 10.77020556900004 ],
              [-75.03562652099993, 10.770362219000049],
              [-75.03581475699991, 10.770575184000052],
              [-75.03585552399994, 10.770683529000053],
              [-75.03593551499995, 10.770817529000055],
              [-75.0360363559999 , 10.770951600000046],
              [-75.03618591899993, 10.771088421000059],
              [-75.03638156999995, 10.771255469000039],
              [-75.03655976499994, 10.771300457000052],
              [-75.03677467499995, 10.77139180000006 ],
              [-75.03696892999994, 10.77145531900004 ],
              [-75.03706627499992, 10.77145607500006 ],
              [-75.03712465199993, 10.771417478000046],
              [-75.03718743999991, 10.77139948200005 ],
              [-75.03725490999994, 10.771370183000045],
              [-75.03745497299991, 10.77130493900006 ],
              [-75.03750143499991, 10.771279015000061],
              [-75.03750146499993, 10.771288493000043],
              [-75.03750457199993, 10.77130423400007 ],
              [-75.03763563699994, 10.771278945000063],
              [-75.03782656299995, 10.77119377200006 ],
              [-75.0380856889999 , 10.771036869000056],
              [-75.0382569009999 , 10.770936002000042],
              [-75.03837342399993, 10.770814776000066],
              [-75.03888654499991, 10.771130262000042],
              [-75.03930074399995, 10.771427155000083],
              [-75.04006219299993, 10.772043231000055],
              [-75.04008846499994, 10.772059524000042],
              [-75.04070008399992, 10.772438820000048],
              [-75.04079323399992, 10.772545471000058],
              [-75.0409344059999 , 10.772707109000066],
              [-75.04078375499995, 10.772927461000052],
              [-75.04072610999992, 10.773076227000047],
              [-75.04071260499995, 10.773176903000092],
              [-75.04071775799991, 10.773278322000067],
              [-75.04073619499991, 10.773353179000082],
              [-75.0407446829999 , 10.77338764500007 ],
              [-75.04077986599992, 10.773450239000056],
              [-75.04087952299994, 10.773520485000063],
              [-75.04091746799992, 10.773522764000063],
              [-75.04099200399992, 10.773592491000045],
              [-75.04106548399994, 10.77351569700005 ],
              [-75.04107915999992, 10.77351397600006 ],
              [-75.04124944999995, 10.773438404000046],
              [-75.04153585499995, 10.773264591000043],
              [-75.04163766499994, 10.773217265000085],
              [-75.0417694219999 , 10.773183238000058],
              [-75.0419657839999 , 10.77318774300005 ],
              [-75.04250068999994, 10.773246571000072],
              [-75.04297047699993, 10.77335654300009 ],
              [-75.04342397799991, 10.77344256200007 ],
              [-75.0439285139999 , 10.773478443000045],
              [-75.04443023699991, 10.773542152000061],
              [-75.04454657499991, 10.773574058000065],
              [-75.0446538139999 , 10.773628891000044],
              [-75.04510599399993, 10.773303941000052],
              [-75.04540097299991, 10.773145219000071],
              [-75.04567212299992, 10.773023224000042],
              [-75.04576893199993, 10.773017107000044],
              [-75.04587431599992, 10.773051185000043],
              [-75.0460354789999 , 10.773123231000056],
              [-75.04618605099995, 10.773214966000069],
              [-75.04691143399992, 10.773733274000051],
              [-75.04711460199991, 10.773826807000091],
              [-75.04725874799993, 10.773856890000047],
              [-75.0484794809999 , 10.773986548000039],
              [-75.04874373799993, 10.773992390000046],
              [-75.0488163089999 , 10.773956387000055],
              [-75.04901662999993, 10.773716080000042],
              [-75.04924798899992, 10.773519605000047],
              [-75.04957838099995, 10.773393217000091],
              [-75.04994745199991, 10.77317867100004 ],
              [-75.05106153399993, 10.772337435000054],
              [-75.05154667399995, 10.771947269000066],
              [-75.05189185899991, 10.771714901000053],
              [-75.05242907599995, 10.771395812000037],
              [-75.05257992499992, 10.771292366000068],
              [-75.05276268599994, 10.771141404000048],
              [-75.0527726389999 , 10.771385978000069],
              [-75.05287399399992, 10.771351976000062],
              [-75.05298090299993, 10.77135710400006 ],
              [-75.05332409999994, 10.771510096000043],
              [-75.05365102299993, 10.771595059000049],
              [-75.0538009789999 , 10.771602783000048],
              [-75.05402656899992, 10.77153964900009 ],
              [-75.05426496799993, 10.771534831000054],
              [-75.05448077199992, 10.771569156000055],
              [-75.05494383699994, 10.771684021000056],
              [-75.05507359799992, 10.771697590000088],
              [-75.0551983869999 , 10.771687495000037],
              [-75.05555793499991, 10.771613801000058],
              [-75.0556252479999 , 10.771563513000046],
              [-75.05566029599993, 10.77148763200006 ],
              [-75.05572668199994, 10.770838476000051],
              [-75.0559611889999 , 10.76969441500006 ],
              [-75.0560897869999 , 10.76849652900006 ],
              [-75.05612063099994, 10.768382298000063],
              [-75.0561746429999 , 10.768276813000057],
              [-75.05626070599993, 10.768217661000051],
              [-75.05698323299993, 10.767855695000037],
              [-75.05875204699993, 10.767732983000087],
              [-75.05943721599994, 10.767685449000055],
              [-75.06005287799991, 10.767650624000055],
              [-75.06070098499993, 10.767797553000037],
              [-75.06096529899992, 10.767822245000048],
              [-75.06123078399992, 10.76782042800005 ],
              [-75.06193702999991, 10.76750953900006 ],
              [-75.06309887999993, 10.767040232000056],
              [-75.06326782299993, 10.76697198900007 ],
              [-75.06327845399994, 10.767049025000063],
              [-75.06320789199992, 10.767370318000076],
              [-75.06300032299993, 10.768315449000056],
              [-75.06342183999993, 10.768462206000038],
              [-75.06349021599993, 10.768482131000042],
              [-75.06385049199991, 10.768587121000053],
              [-75.0642479139999 , 10.768662943000038],
              [-75.06463816599995, 10.768769115000055],
              [-75.06512064799995, 10.768843501000049],
              [-75.06523446499995, 10.768890275000047],
              [-75.06539155699994, 10.768992899000068],
              [-75.0657755869999 , 10.769162398000049],
              [-75.0661741749999 , 10.76928752300006 ],
              [-75.06634860799994, 10.769292721000056],
              [-75.06676152299991, 10.769224084000086],
              [-75.06700145099995, 10.769160979000048],
              [-75.06722523499991, 10.76906123200007 ],
              [-75.0678438779999 , 10.769297768000058],
              [-75.06858869799993, 10.76963726300005 ],
              [-75.06927368299995, 10.769898238000053],
              [-75.06952040599992, 10.770033641000055],
              [-75.06972295299994, 10.770189689000063],
              [-75.07001234699993, 10.770495483000047],
              [-75.0702932669999 , 10.770837303000064],
              [-75.07045411799993, 10.771007096000062],
              [-75.07127304999995, 10.771660994000058],
              [-75.07160924699991, 10.771907881000061],
              [-75.07183916299994, 10.77212393700006 ],
              [-75.07196354699994, 10.772273488000053],
              [-75.0720340769999 , 10.772382015000062],
              [-75.07205695699992, 10.772467899000048],
              [-75.0720771739999 , 10.772773610000058],
              [-75.0721756669999 , 10.77289630000007 ],
              [-75.07247373599995, 10.772862388000078],
              [-75.07254509599994, 10.77285426900005 ],
              [-75.07312823299992, 10.772787923000067],
              [-75.0735363309999 , 10.772719219000066],
              [-75.07364094199994, 10.772718263000058],
              [-75.0737820409999 , 10.772716972000069],
              [-75.07469811899995, 10.77267699400005 ],
              [-75.0751762619999 , 10.772697338000057],
              [-75.07567364699992, 10.772698025000068],
              [-75.0758807659999 , 10.77272767900007 ],
              [-75.07603163899995, 10.772746705000088],
              [-75.07622342899992, 10.772743048000052],
              [-75.07669792299993, 10.772684986000058],
              [-75.07708970599992, 10.772575303000053],
              [-75.0771915979999 , 10.772532091000073],
              [-75.0771871959999 , 10.772539342000073],
              [-75.07708595199995, 10.772706088000064],
              [-75.0770165749999 , 10.772888074000036],
              [-75.07675038999992, 10.773952812000061],
              [-75.0767499509999 , 10.77409928500009 ],
              [-75.07676285199994, 10.77459168100006 ],
              [-75.07679789999992, 10.775083022000047],
              [-75.07680510099993, 10.775604907000059],
              [-75.0768270069999 , 10.775786864000054],
              [-75.07687520399992, 10.775993498000048],
              [-75.07697283899995, 10.776279585000054],
              [-75.07707720299993, 10.776509358000055],
              [-75.07720266599995, 10.776728569000056],
              [-75.07748683199992, 10.776744201000042],
              [-75.07777132599995, 10.776736308000068],
              [-75.07805415999991, 10.776704958000039],
              [-75.07833335599992, 10.776650362000055],
              [-75.07858715899994, 10.776579343000037],
              [-75.07883464199995, 10.77648911700004 ],
              [-75.07943273199993, 10.77618506500005 ],
              [-75.08001601099994, 10.775962580000055],
              [-75.08041201099991, 10.775856013000066],
              [-75.08055934599992, 10.775857754000072],
              [-75.08070241699994, 10.77589258100005 ],
              [-75.08086811699991, 10.775982730000067],
              [-75.08132922999994, 10.776296855000055],
              [-75.08169386299994, 10.776570512000092],
              [-75.08204674199993, 10.776858839000056],
              [-75.08232977299991, 10.777260691000038],
              [-75.08266016999994, 10.77779143400005 ],
              [-75.08279016799992, 10.77794807500004 ],
              [-75.08293934099993, 10.778087004000042],
              [-75.08337364099992, 10.778391850000048],
              [-75.08377542599993, 10.778657484000064],
              [-75.0849543999999 , 10.779426450000074],
              [-75.0854137419999 , 10.779748525000059],
              [-75.08550009099991, 10.779851931000053],
              [-75.08652698499992, 10.781414665000057],
              [-75.0878657099999 , 10.78354218000004 ],
              [-75.0880560629999 , 10.783772860000056],
              [-75.08826494099992, 10.783976042000063],
              [-75.08839628699991, 10.784071400000073],
              [-75.08876914399991, 10.784293688000048],
              [-75.08944955399994, 10.784771449000061],
              [-75.09051025199994, 10.785435895000091],
              [-75.09074343899994, 10.785019229000056],
              [-75.09087872799995, 10.784815300000048],
              [-75.09094099299995, 10.784748835000073],
              [-75.09108869799991, 10.784656848000054],
              [-75.09124937899992, 10.784589392000044],
              [-75.09141890299992, 10.784548207000057],
              [-75.09159290899993, 10.784534345000054],
              [-75.09180578099995, 10.784561305000068],
              [-75.09224281799993, 10.784720779000054],
              [-75.09259958199993, 10.784884471000055],
              [-75.09294322099993, 10.785073632000092],
              [-75.09350332499992, 10.78530879400006 ],
              [-75.09377457999994, 10.785392011000056],
              [-75.09409017599995, 10.785543620000055],
              [-75.09447769699995, 10.785765240000046],
              [-75.09493297999995, 10.785995669000044],
              [-75.09550708299992, 10.786205446000054],
              [-75.09635798599993, 10.786503450000055],
              [-75.09692838099994, 10.786750580000046],
              [-75.09706276999992, 10.786849006000068],
              [-75.0972499639999 , 10.787025723000056],
              [-75.0975569769999 , 10.787277093000057],
              [-75.09836022899992, 10.787858096000036],
              [-75.09867685499995, 10.788038332000042],
              [-75.09919712899995, 10.788260256000058],
              [-75.09959119899992, 10.788259309000068],
              [-75.09998691399994, 10.788258359000054],
              [-75.10393362699995, 10.788248851000048],
              [-75.10414509899994, 10.788248341000042],
              [-75.10445990999995, 10.78824758400009 ],
              [-75.10446940599991, 10.788231711000037],
              [-75.10450180999993, 10.788176307000072],
              [-75.1045976119999 , 10.788098490000039],
              [-75.1045982089999 , 10.788098004000062],
              [-75.1046914129999 , 10.788022297000055],
              [-75.1047314939999 , 10.787954399000057],
              [-75.10478117199995, 10.787870242000054],
              [-75.10486826599993, 10.787649204000047],
              [-75.10492476399992, 10.78737551000006 ],
              [-75.10502015199995, 10.78740711000006 ],
              [-75.10523405599992, 10.787467832000061],
              [-75.10552686299991, 10.787502866000068],
              [-75.10582905399991, 10.787565418000042],
              [-75.10611003199995, 10.787563765000073],
              [-75.10617774499991, 10.788166813000089],
              [-75.10841979399993, 10.788350550000075],
              [-75.10852757499993, 10.788359383000056],
              [-75.10876134199992, 10.788353670000049],
              [-75.1091746319999 , 10.788374308000073],
              [-75.10958487599993, 10.788427941000066],
              [-75.10988504099993, 10.78850058800009 ],
              [-75.11023057999995, 10.788606007000055],
              [-75.11059945699992, 10.78874174300006 ],
              [-75.11095953499995, 10.78889889800007 ],
              [-75.11133350199992, 10.789119980000066],
              [-75.11169137299993, 10.789365735000047],
              [-75.11180536099994, 10.789423990000046],
              [-75.11326027399991, 10.789860298000065],
              [-75.11606220499993, 10.790327397000055],
              [-75.11607215299995, 10.790329056000076],
              [-75.11644676999992, 10.790391501000045],
              [-75.11644853899992, 10.790391795000062],
              [-75.11647515999994, 10.790396509000061],
              [-75.11651863599991, 10.790570905000038],
              [-75.11666883099991, 10.791173383000057],
              [-75.11672035399994, 10.79141299400004 ],
              [-75.11709980499995, 10.793177650000075],
              [-75.11725146799995, 10.793973290000054],
              [-75.11719147699995, 10.79405673000008 ],
              [-75.11687312499993, 10.794499520000045],
              [-75.11654181099993, 10.79505589200005 ],
              [-75.11644715699993, 10.795508338000047],
              [-75.1163672429999 , 10.795779740000057],
              [-75.11613298999993, 10.796226344000047],
              [-75.1161472739999 , 10.796232279000037],
              [-75.11670153599994, 10.796462554000072],
              [-75.11736610499992, 10.796738656000059],
              [-75.11771091399993, 10.79688191200006 ],
              [-75.11796533699993, 10.796944777000078],
              [-75.11869759899992, 10.79712571500005 ],
              [-75.11896196899994, 10.797207564000075],
              [-75.12011950899995, 10.797565938000048],
              [-75.12024200299993, 10.797622343000057],
              [-75.12035501899993, 10.797695574000045],
              [-75.12045621699991, 10.79778411600006 ],
              [-75.12054347499992, 10.797886125000048],
              [-75.12059636099991, 10.797966228000064],
              [-75.12084976999995, 10.79860556400007 ],
              [-75.1218829039999 , 10.801399230000072],
              [-75.12210459199991, 10.801998690000062],
              [-75.12213469499994, 10.802441611000063],
              [-75.12213869399994, 10.802972486000044],
              [-75.12215677899991, 10.803283901000043],
              [-75.12223210999991, 10.803823897000086],
              [-75.12228521399993, 10.804004792000057],
              [-75.12236387299993, 10.804166923000082],
              [-75.12262344099992, 10.804701944000044],
              [-75.1229312079999 , 10.805197750000048],
              [-75.12305158799995, 10.805361046000087],
              [-75.12339736399991, 10.805664497000066],
              [-75.12359333199993, 10.80587016600009 ],
              [-75.12385936399994, 10.80621969300006 ],
              [-75.12407180799994, 10.80653557100004 ],
              [-75.12414894599993, 10.806666857000039],
              [-75.12425282799995, 10.806843658000048],
              [-75.12441527699991, 10.807161704000066],
              [-75.12453916499993, 10.807469019000052],
              [-75.12468025899994, 10.807819012000039],
              [-75.1248943459999 , 10.808216471000037],
              [-75.12490696899994, 10.808239903000072],
              [-75.1256555949999 , 10.807906930000058],
              [-75.12586327299994, 10.807814561000043],
              [-75.12659429699994, 10.807965187000093],
              [-75.12675148099993, 10.807997574000069],
              [-75.1267778589999 , 10.807941286000073],
              [-75.12684654599991, 10.807794726000054],
              [-75.12691508299991, 10.807706274000054],
              [-75.12694028499993, 10.807685248000041],
              [-75.12703654699993, 10.807604939000043],
              [-75.12710006299994, 10.807574774000045],
              [-75.12717980999992, 10.807536904000074],
              [-75.12727556999994, 10.807513450000044],
              [-75.12834372999993, 10.807521713000085],
              [-75.12839414699994, 10.80752280900009 ],
              [-75.12856828299994, 10.80752659600006 ],
              [-75.13026804799995, 10.807570221000049],
              [-75.13151752699991, 10.807581089000053],
              [-75.13174650699995, 10.807619289000058],
              [-75.13196382699994, 10.80770020600005 ],
              [-75.13218481599995, 10.80783886100005 ],
              [-75.13458557399991, 10.810243659000037],
              [-75.13475610899991, 10.810359677000065],
              [-75.13495058899991, 10.810422716000062],
              [-75.13633148699995, 10.810705103000089],
              [-75.1377444499999 , 10.811044606000053],
              [-75.14012103199991, 10.811577201000091],
              [-75.14141514499994, 10.811801119000052],
              [-75.14219862999994, 10.811930629000074],
              [-75.14379261499994, 10.811927556000057],
              [-75.14504292299995, 10.811916989000053],
              [-75.14631957699993, 10.81188164200006 ],
              [-75.14641663999993, 10.81186189300007 ],
              [-75.14655098899993, 10.811834557000054],
              [-75.14677430199993, 10.811758295000061],
              [-75.1469857749999 , 10.811654137000062],
              [-75.14718185499993, 10.811523831000045],
              [-75.14734850799994, 10.811380107000048],
              [-75.1474963419999 , 10.811217500000055],
              [-75.14892918399994, 10.809358681000049],
              [-75.14974410199994, 10.808336353000072],
              [-75.15016212599994, 10.807595265000089],
              [-75.1501746369999 , 10.807573085000058],
              [-75.15067774499994, 10.80668115900005 ],
              [-75.1511121769999 , 10.805901834000053],
              [-75.15156651399991, 10.805008156000042],
              [-75.15158703799995, 10.804976513000042],
              [-75.15159143199992, 10.804967929000043],
              [-75.15201385699993, 10.80431059600005 ],
              [-75.15270239999995, 10.804310505000046],
              [-75.15523896499991, 10.804310157000089],
              [-75.15523863699991, 10.80454555600005 ],
              [-75.15523755399994, 10.805322817000047],
              [-75.15525854799995, 10.805513627000039],
              [-75.15533254999991, 10.805821412000057],
              [-75.15534291999995, 10.806062655000062],
              [-75.1556915189999 , 10.806063779000056],
              [-75.15575793499994, 10.806066309000073],
              [-75.15604471299991, 10.806077233000053],
              [-75.15665188199995, 10.806101205000061],
              [-75.15665194399992, 10.80610120700004 ],
              [-75.15763560999994, 10.806140045000063],
              [-75.15884829999993, 10.806143922000047],
              [-75.1602145089999 , 10.806148285000063],
              [-75.16208869099995, 10.806154269000046],
              [-75.16250597099992, 10.806231834000073],
              [-75.16387868699991, 10.806432050000069],
              [-75.1676456699999 , 10.80655531900004 ],
              [-75.16764575399992, 10.806555321000076],
              [-75.1688854169999 , 10.806595883000057],
              [-75.1690222169999 , 10.805148399000075],
              [-75.16908773699993, 10.804455139000083],
              [-75.16909140399991, 10.804416330000038],
              [-75.16993002099991, 10.80435680800008 ],
              [-75.16993010699991, 10.804356802000086],
              [-75.1707071109999 , 10.80430165200005 ],
              [-75.1715467649999 , 10.80416807000006 ],
              [-75.17193096199992, 10.804106947000037],
              [-75.17232069199991, 10.804044944000054],
              [-75.17263845699995, 10.803961523000055],
              [-75.17316335299995, 10.803788196000085],
              [-75.17356374799994, 10.803655983000056],
              [-75.17442865599992, 10.80339123500005 ],
              [-75.17466600099993, 10.80321198200005 ],
              [-75.17471438799993, 10.803172330000052],
              [-75.17551012399991, 10.803659417000063],
              [-75.1756494949999 , 10.803567826000062],
              [-75.17581459199994, 10.803441823000071],
              [-75.1759191029999 , 10.803386856000088],
              [-75.17600276199994, 10.80333422800004 ],
              [-75.1760608429999 , 10.803315947000044],
              [-75.17615608099993, 10.80326560900005 ],
              [-75.17632114299994, 10.803167220000091],
              [-75.17668107499992, 10.803034727000068],
              [-75.17714311799995, 10.80289784300004 ],
              [-75.17752855599991, 10.80278603000005 ],
              [-75.17786521599993, 10.802658066000049],
              [-75.1781461459999 , 10.802566814000045],
              [-75.17835059799995, 10.802495960000044],
              [-75.17865710299992, 10.802404755000055],
              [-75.17889860899993, 10.802313364000042],
              [-75.17919111099991, 10.802217472000052],
              [-75.17943028199994, 10.802151302000084],
              [-75.17971587799991, 10.802082977000055],
              [-75.18004774499991, 10.802012451000053],
              [-75.18028695299995, 10.801957772000037],
              [-75.18055846699991, 10.801905471000055],
              [-75.18081846399991, 10.801869232000058],
              [-75.18109916499992, 10.801830719000066],
              [-75.18131729099991, 10.801828880000073],
              [-75.18147979399993, 10.801810853000063],
              [-75.18170023499994, 10.801779066000051],
              [-75.18185566099993, 10.80177941900007 ],
              [-75.18212954099994, 10.80175237800006 ],
              [-75.18246594699991, 10.80175068200009 ],
              [-75.1826028349999 , 10.801760142000091],
              [-75.18276511199991, 10.801760481000088],
              [-75.1828996989999 , 10.801765358000068],
              [-75.18303197999995, 10.801765596000052],
              [-75.18313634499992, 10.801788731000045],
              [-75.18328948099992, 10.801789031000055],
              [-75.18339375999994, 10.801789289000055],
              [-75.18351666599995, 10.80180325300006 ],
              [-75.1836026599999 , 10.801803440000072],
              [-75.18373941699991, 10.801817493000044],
              [-75.18392949899993, 10.80187987100004 ],
              [-75.18402924899993, 10.801891516000069],
              [-75.18420774999993, 10.801914882000062],
              [-75.18454864499995, 10.802007360000061],
              [-75.18475026599992, 10.80206058500005 ],
              [-75.1849288059999 , 10.80213210900007 ],
              [-75.18518839099994, 10.802217541000061],
              [-75.18548516599992, 10.802275576000056],
              [-75.18594878699992, 10.802411953000046],
              [-75.18628269499993, 10.802509048000047],
              [-75.18700128099994, 10.802714774000037],
              [-75.18762253999995, 10.802908917000082],
              [-75.18820444699992, 10.80308455700009 ],
              [-75.18845708899994, 10.803183774000047],
              [-75.18874211399992, 10.803273906000072],
              [-75.18955816999994, 10.803528067000059],
              [-75.18972035099995, 10.803581223000037],
              [-75.18990119799992, 10.803618282000059],
              [-75.19027205599991, 10.80377744000009 ],
              [-75.19048055199994, 10.80387427800008 ],
              [-75.19060563999994, 10.803938868000046],
              [-75.19100229599991, 10.803967162000049],
              [-75.19141881599995, 10.804195175000075],
              [-75.19183840199992, 10.804424869000059],
              [-75.19243073699994, 10.804454553000085],
              [-75.19272420899995, 10.80446925800004 ],
              [-75.19273903699991, 10.80418009500005 ],
              [-75.19275611599994, 10.803846949000047],
              [-75.19277064199991, 10.803563564000058],
              [-75.19278249399991, 10.803332442000055],
              [-75.19279816399995, 10.803026793000072],
              [-75.19281448399994, 10.802708370000062],
              [-75.19282633599994, 10.80247726500005 ],
              [-75.19283071599995, 10.802391790000058],
              [-75.19297244799992, 10.802398897000046],
              [-75.1931496119999 , 10.802457082000046],
              [-75.19334530099991, 10.802450136000061],
              [-75.19357874099995, 10.802441847000068],
              [-75.19372463099995, 10.802436666000062],
              [-75.19392620799994, 10.802473004000092],
              [-75.19421663799994, 10.802478195000049],
              [-75.19443765499994, 10.80248215000006 ],
              [-75.19467773899993, 10.802486439000063],
              [-75.19492079199995, 10.802490789000046],
              [-75.19514744799994, 10.80249483600005 ],
              [-75.19541160499995, 10.802499561000047],
              [-75.19566615399992, 10.80250410900004 ],
              [-75.19584332099993, 10.802507279000054],
              [-75.1960713329999 , 10.802511354000046],
              [-75.19631932499993, 10.802576704000046],
              [-75.1964960709999 , 10.802569824000045],
              [-75.1966727009999 , 10.80256294700007 ],
              [-75.1969264089999 , 10.802553068000066],
              [-75.19722849199991, 10.802592140000058],
              [-75.1975626709999 , 10.802588378000053],
              [-75.19777417899991, 10.802585993000037],
              [-75.19793484599995, 10.802584186000047],
              [-75.1982008839999 , 10.802632309000046],
              [-75.19848500499995, 10.80263751700005 ],
              [-75.19865648199993, 10.802640666000059],
              [-75.19886488199995, 10.802644488000055],
              [-75.19914474099994, 10.802649620000068],
              [-75.19949066099991, 10.802655963000063],
              [-75.19966698299993, 10.802659198000072],
              [-75.19966364999993, 10.802836657000057],
              [-75.19971150299995, 10.803101197000046],
              [-75.1997459719999 , 10.803291769000055],
              [-75.19975125899992, 10.803606635000051],
              [-75.19975629699991, 10.803906778000055],
              [-75.19976060199991, 10.804162958000063],
              [-75.19976484099993, 10.804415389000042],
              [-75.19983749999994, 10.804729829000053],
              [-75.19987695999993, 10.805154125000058],
              [-75.19991976699993, 10.80561444600005 ],
              [-75.1999523689999 , 10.805965029000049],
              [-75.19996675699991, 10.806119754000065],
              [-75.20012804899994, 10.806370002000051],
              [-75.20039803399993, 10.80653546700006 ],
              [-75.20070131799991, 10.806600942000046],
              [-75.20103786099992, 10.806555333000063],
              [-75.20122824899994, 10.80644367800005 ],
              [-75.2015134639999 , 10.806276408000087],
              [-75.20176926599993, 10.806126387000063],
              [-75.20212464899993, 10.806040946000053],
              [-75.20230368499995, 10.80589604100004 ],
              [-75.20261745199991, 10.805761743000062],
              [-75.20284544799995, 10.805664154000056],
              [-75.20314375999993, 10.805438031000051],
              [-75.20340947999995, 10.805236612000044],
              [-75.2035910059999 , 10.80498145100006 ],
              [-75.20369690699994, 10.804832591000093],
              [-75.20374656399991, 10.804559224000059],
              [-75.2038807909999 , 10.804436673000055],
              [-75.20403570899992, 10.804295233000062],
              [-75.2043288989999 , 10.80421311300006 ],
              [-75.20448046799993, 10.804170661000057],
              [-75.2047664939999 , 10.804178229000058],
              [-75.20508037999991, 10.804077216000053],
              [-75.20548438399993, 10.80406464400005 ],
              [-75.20577626699992, 10.804055567000091],
              [-75.20601176299994, 10.80404823400005 ],
              [-75.20638433899995, 10.80403664000005 ],
              [-75.2067984059999 , 10.804023757000039],
              [-75.20710091699993, 10.804014343000063],
              [-75.20730263399992, 10.804008064000072],
              [-75.20752739099993, 10.804001069000037],
              [-75.2077679919999 , 10.80402934500006 ],
              [-75.20805682399993, 10.804028287000051],
              [-75.2082483399999 , 10.804027588000054],
              [-75.20852103899995, 10.804078083000093],
              [-75.2087544819999 , 10.804121309000038],
              [-75.20897578199992, 10.804127913000059],
              [-75.20929847999992, 10.804137541000046],
              [-75.20944897899994, 10.804142027000069],
              [-75.20961993899994, 10.804181730000039],
              [-75.2099066099999 , 10.80424829900005 ],
              [-75.21017751699992, 10.804311208000058],
              [-75.2106780499999 , 10.80429670600006 ],
              [-75.21086725899994, 10.804291224000053],
              [-75.21126211399991, 10.804412927000044],
              [-75.21156752599995, 10.804507060000049],
              [-75.21217534199991, 10.80451313900005 ],
              [-75.21260976299993, 10.80462990600006 ],
              [-75.21303471899995, 10.80474412500007 ],
              [-75.21361261299995, 10.804685405000043],
              [-75.21433119699992, 10.804756736000058],
              [-75.21500443799994, 10.804709872000046],
              [-75.21555696399992, 10.804671412000062],
              [-75.21611198699992, 10.804750180000042],
              [-75.2166056499999 , 10.804703977000088],
              [-75.21662244799995, 10.804697357000066],
              [-75.2166385079999 , 10.804691027000047],
              [-75.21666818599994, 10.804741037000042],
              [-75.21669340199992, 10.80478352700004 ],
              [-75.21671521799993, 10.80485075100006 ],
              [-75.21674725199995, 10.804949459000056],
              [-75.21675060199993, 10.805838531000063],
              [-75.21675289199993, 10.80644531300004 ],
              [-75.21675327299994, 10.806546206000064],
              [-75.21675381199992, 10.806688947000055],
              [-75.2162656129999 , 10.807199540000056],
              [-75.2159748919999 , 10.80750359700005 ],
              [-75.21523608799993, 10.808588679000081],
              [-75.21499419499992, 10.808950309000068],
              [-75.21469175599992, 10.809402455000054],
              [-75.21407052199993, 10.810564415000044],
              [-75.21391659999995, 10.811222134000047],
              [-75.21352807499994, 10.811880719000044],
              [-75.2131758889999 , 10.812359236000077],
              [-75.21310016299992, 10.812462125000081],
              [-75.21302871599994, 10.81282226400009 ],
              [-75.21298504499993, 10.813042393000046],
              [-75.21273243499991, 10.813673531000063],
              [-75.21267521499993, 10.813816493000047],
              [-75.2126751529999 , 10.813816649000046],
              [-75.21228472899992, 10.813972704000037],
              [-75.21216372599991, 10.813929708000046],
              [-75.21185404499994, 10.813819670000044],
              [-75.21150359899991, 10.814207517000057],
              [-75.21123178699992, 10.814711039000088],
              [-75.21092116799991, 10.815292017000047],
              [-75.21055165899992, 10.815703358000064],
              [-75.21025921699993, 10.816028907000089],
              [-75.20983128599994, 10.816610319000063],
              [-75.20956213399995, 10.81685402800008 ],
              [-75.20948162499991, 10.816926927000054],
              [-75.20940263799991, 10.81699844800005 ],
              [-75.20921083299993, 10.817213371000037],
              [-75.20909113599993, 10.817347495000092],
              [-75.20898127799995, 10.817398670000046],
              [-75.20850564299991, 10.81762023500005 ],
              [-75.20840929099995, 10.817652377000059],
              [-75.20815417699993, 10.817737481000052],
              [-75.20799413099991, 10.81784381400007 ],
              [-75.20796945199993, 10.817860210000049],
              [-75.20769387099995, 10.81804330400007 ],
              [-75.20768612599994, 10.818048450000049],
              [-75.20701194299994, 10.818024261000062],
              [-75.20688075699991, 10.817929236000055],
              [-75.20663107199994, 10.817844788000059],
              [-75.20663041299991, 10.817844565000087],
              [-75.20662169399992, 10.817841616000067],
              [-75.2065190699999 , 10.81780690700009 ],
              [-75.2064869319999 , 10.817807839000068],
              [-75.20643697999992, 10.817809288000092],
              [-75.2063892509999 , 10.817799561000072],
              [-75.20638559299994, 10.81780234100006 ],
              [-75.20629588799994, 10.81778898500005 ],
              [-75.20613905499994, 10.817765635000058],
              [-75.20613762099993, 10.817765421000047],
              [-75.20599251599992, 10.817743817000064],
              [-75.20578835799995, 10.81768099900006 ],
              [-75.20560147499992, 10.817577906000054],
              [-75.2054193269999 , 10.81749172900004 ],
              [-75.2053737249999 , 10.817470154000091],
              [-75.20534006799994, 10.817459043000042],
              [-75.20532916999991, 10.817455455000072],
              [-75.20523705099993, 10.817577649000043],
              [-75.20518755899991, 10.817634756000075],
              [-75.2051256929999 , 10.817662358000064],
              [-75.20503241799992, 10.81767282700008 ],
              [-75.20494503099991, 10.817720363000092],
              [-75.20494296399994, 10.81772056200009 ],
              [-75.20490536799991, 10.81772417500008 ],
              [-75.20489974599991, 10.817724715000054],
              [-75.20488132799994, 10.817726485000037],
              [-75.20483679299991, 10.817730765000078],
              [-75.20477513999992, 10.817736006000075],
              [-75.20473222899994, 10.817739653000046],
              [-75.20436090099992, 10.81777121500005 ],
              [-75.20418006199992, 10.817743853000081],
              [-75.20413394199994, 10.817735109000068],
              [-75.20410594399993, 10.817729801000041],
              [-75.2040688429999 , 10.817722767000078],
              [-75.20405627199995, 10.81772038400004 ],
              [-75.20404040099993, 10.817717375000086],
              [-75.20392902899994, 10.817696262000084],
              [-75.20392934499995, 10.817679571000042],
              [-75.20388494199995, 10.817671651000069],
              [-75.20387791399992, 10.817670398000075],
              [-75.2036648639999 , 10.817632402000072],
              [-75.20365942899991, 10.817631433000088],
              [-75.20360811299992, 10.81762228100007 ],
              [-75.20355850199991, 10.817613433000076],
              [-75.20354264199995, 10.817610604000038],
              [-75.20354169399991, 10.817610435000063],
              [-75.20344996299991, 10.817594076000091],
              [-75.20341965299991, 10.817583973000069],
              [-75.2033168239999 , 10.817549696000071],
              [-75.20328934999992, 10.81754053800006 ],
              [-75.20328321399995, 10.817556947000071],
              [-75.20316944299992, 10.817527880000057],
              [-75.20313304899992, 10.817518582000048],
              [-75.2031225529999 , 10.817515900000046],
              [-75.20312052999992, 10.817515383000057],
              [-75.20305488899993, 10.817522231000055],
              [-75.20304723899994, 10.817523029000085],
              [-75.20186857499993, 10.817646001000071],
              [-75.20111591299991, 10.817724527000053],
              [-75.2001285099999 , 10.817691965000051],
              [-75.20006005099992, 10.817689707000056],
              [-75.19916073799993, 10.81769298000006 ],
              [-75.19830080799994, 10.817773413000054],
              [-75.19801598099991, 10.817794565000042],
              [-75.19775354599994, 10.817814055000042],
              [-75.19654185299993, 10.81793441800005 ],
              [-75.19581396699994, 10.818088668000087],
              [-75.19579951199995, 10.818091731000038],
              [-75.19579313699995, 10.818096494000088],
              [-75.1956435379999 , 10.818208260000063],
              [-75.19511891499991, 10.81833994200008 ],
              [-75.19496059099993, 10.818379682000057],
              [-75.19470597499992, 10.818443591000062],
              [-75.19465928499994, 10.818449532000045],
              [-75.19408064399994, 10.818523165000045],
              [-75.19357902299993, 10.81858010500008 ],
              [-75.1931089439999 , 10.818633465000062],
              [-75.19302535299994, 10.81864295400004 ],
              [-75.1924394049999 , 10.818799694000063],
              [-75.19212774699992, 10.819110072000058],
              [-75.19211737099994, 10.81912817600005 ],
              [-75.19185355099995, 10.81958848700009 ],
              [-75.19181693599995, 10.819652373000054],
              [-75.19181753299995, 10.819813698000075],
              [-75.19181836699994, 10.820038927000041],
              [-75.19172203599993, 10.820203561000085],
              [-75.19154636699994, 10.820503788000053],
              [-75.19113684299992, 10.820754972000088],
              [-75.19103919499992, 10.820814865000045],
              [-75.19060965599994, 10.820971038000039],
              [-75.18990725599991, 10.821360138000045],
              [-75.18978208199991, 10.821491949000063],
              [-75.1891852139999 , 10.822120461000054],
              [-75.18917797499995, 10.822128085000088],
              [-75.18917555099995, 10.82213063800009 ],
              [-75.18917282599995, 10.82213439900005 ],
              [-75.18877816299994, 10.82267851000006 ],
              [-75.1886435799999 , 10.82293579800006 ],
              [-75.18831221599993, 10.823569280000072],
              [-75.18784528099991, 10.82418946100006 ],
              [-75.1870640969999 , 10.82442420600006 ],
              [-75.18612665099994, 10.824698174000048],
              [-75.18511141999994, 10.825088386000061],
              [-75.18490252399994, 10.825273296000091],
              [-75.1847606419999 , 10.825398887000063],
              [-75.18456743299993, 10.82557379900004 ],
              [-75.1843319429999 , 10.825786988000061],
              [-75.1839813009999 , 10.82613615400004 ],
              [-75.18390869199993, 10.82631020100007 ],
              [-75.18378748999993, 10.826600725000048],
              [-75.18366448599994, 10.826810935000083],
              [-75.1835154819999 , 10.827065577000042],
              [-75.1834315879999 , 10.827132421000044],
              [-75.18312559499992, 10.82737622400009 ],
              [-75.1825797159999 , 10.82780340000005 ],
              [-75.18238924999991, 10.827929917000063],
              [-75.18222865399991, 10.828036592000046],
              [-75.18172173499994, 10.82842497100006 ],
              [-75.1815615889999 , 10.828569242000071],
              [-75.1813529559999 , 10.828757195000037],
              [-75.18134956299991, 10.828760055000089],
              [-75.18134470199993, 10.828764144000047],
              [-75.1813370079999 , 10.828770616000043],
              [-75.1808252159999 , 10.829201304000037],
              [-75.18059243599993, 10.829704667000044],
              [-75.17984962799994, 10.829745981000087],
              [-75.17863787199991, 10.829866283000058],
              [-75.17860469899995, 10.82987296500005 ],
              [-75.17794525699992, 10.830005793000055],
              [-75.17785639199991, 10.830023693000044],
              [-75.1772698549999 , 10.830025788000057],
              [-75.17683079099993, 10.830052890000047],
              [-75.17660524299993, 10.830066812000041],
              [-75.17589949699993, 10.830091454000069],
              [-75.17588559599994, 10.830091940000045],
              [-75.17580048099995, 10.83009491200005 ],
              [-75.1753024649999 , 10.83023565700006 ],
              [-75.1751982589999 , 10.83026510700006 ],
              [-75.17496836999993, 10.830494015000056],
              [-75.17486667299994, 10.830595283000036],
              [-75.17484527499994, 10.830616582000061],
              [-75.17477255699993, 10.830607247000046],
              [-75.17432795299993, 10.830608829000084],
              [-75.17413741199994, 10.830609504000051],
              [-75.17394685999994, 10.830610187000048],
              [-75.17375608599991, 10.83054807700006 ],
              [-75.17356554299994, 10.830548750000048],
              [-75.17324773799993, 10.830487093000045],
              [-75.1729934519999 , 10.83042520500004 ],
              [-75.17280267699994, 10.830363085000045],
              [-75.1725483909999 , 10.830301201000054],
              [-75.17223058499991, 10.830239541000083],
              [-75.17191277699993, 10.830177881000054],
              [-75.1717222339999 , 10.830178554000042],
              [-75.17146817199995, 10.830179453000085],
              [-75.17127739799992, 10.83011734300004 ],
              [-75.17095959399995, 10.83005567300006 ],
              [-75.17076881899993, 10.829993565000052],
              [-75.17045124499992, 10.829994689000046],
              [-75.17026047199994, 10.829932579000058],
              [-75.16981564399993, 10.829871358000048],
              [-75.16962509299992, 10.829872031000093],
              [-75.16937103099991, 10.82987293900004 ],
              [-75.16918048699995, 10.829873612000085],
              [-75.16898993899991, 10.829874286000063],
              [-75.16879939799992, 10.829874959000051],
              [-75.1684818249999 , 10.829876083000045],
              [-75.16829127499994, 10.82987675600009 ],
              [-75.16803721299993, 10.829877657000054],
              [-75.16784667099995, 10.829878330000042],
              [-75.16765612099994, 10.829879001000052],
              [-75.1674020669999 , 10.829879899000048],
              [-75.16721151799993, 10.829880575000061],
              [-75.16689394199994, 10.829881699000055],
              [-75.1665763609999 , 10.829882823000048],
              [-75.16638581799992, 10.82988348400005 ],
              [-75.16619527199992, 10.829884157000038],
              [-75.16594120999991, 10.829885058000059],
              [-75.16575066799993, 10.829885731000047],
              [-75.1654330959999 , 10.829886853000062],
              [-75.16517903399995, 10.82988774200004 ],
              [-75.16492497299993, 10.82988864300006 ],
              [-75.16460739099995, 10.829889764000086],
              [-75.16441684999995, 10.829890440000042],
              [-75.16403598199992, 10.829954568000062],
              [-75.16378192099995, 10.82995545700004 ],
              [-75.16359137899991, 10.829956133000053],
              [-75.16321050999994, 10.830020263000051],
              [-75.16302041499995, 10.830146513000045],
              [-75.16283008799991, 10.830209977000038],
              [-75.16244967999995, 10.830399683000053],
              [-75.16225936599994, 10.830463140000063],
              [-75.16206903999995, 10.83052660900006 ],
              [-75.16181520299995, 10.830590288000053],
              [-75.1616246609999 , 10.830590955000048],
              [-75.16143433499991, 10.830654418000051],
              [-75.16105347699994, 10.830718542000056],
              [-75.16086292599994, 10.830719215000045],
              [-75.1605453439999 , 10.830720327000051],
              [-75.16029150799994, 10.830784011000048],
              [-75.1600376799999 , 10.830847691000088],
              [-75.15978360799994, 10.830848582000044],
              [-75.15933922999994, 10.830912936000061],
              [-75.15902186999995, 10.830976836000048],
              [-75.15870430099994, 10.830977948000054],
              [-75.1585141999999 , 10.831104197000059],
              [-75.1583238799999 , 10.831167657000037],
              [-75.1581335539999 , 10.831231109000043],
              [-75.15781620899992, 10.831295018000048],
              [-75.15762588299992, 10.831358475000059],
              [-75.1574355649999 , 10.831421934000048],
              [-75.15718171999993, 10.831485612000051],
              [-75.15699162399994, 10.831611852000037],
              [-75.15680152599992, 10.831738102000088],
              [-75.15661120899995, 10.831801559000041],
              [-75.1563575959999 , 10.831928022000056],
              [-75.15616749199995, 10.83205427200005 ],
              [-75.15597739299994, 10.832180522000044],
              [-75.15566048599993, 10.832370003000051],
              [-75.1554706149999 , 10.832559039000046],
              [-75.15528051599995, 10.83268528900004 ],
              [-75.15502690399995, 10.832811752000055],
              [-75.1547735069999 , 10.833001008000053],
              [-75.15458341199991, 10.83312724600006 ],
              [-75.1543915389999 , 10.833280095000077],
              [-75.15427622999994, 10.83337195300004 ],
              [-75.15426673199994, 10.833379520000051],
              [-75.15407685499991, 10.833568556000046],
              [-75.1540140059999 , 10.833757146000039],
              [-75.1538236799999 , 10.83382060300005 ],
              [-75.15376839399994, 10.833875643000056],
              [-75.1536338059999 , 10.83400963400004 ],
              [-75.15346089699995, 10.834124459000066],
              [-75.15344370399993, 10.834135877000051],
              [-75.15325360999992, 10.834262126000056],
              [-75.15312724599994, 10.834450932000038],
              [-75.15304052699992, 10.834537269000066],
              [-75.15300365099995, 10.834573982000052],
              [-75.15293736899991, 10.834639972000048],
              [-75.15274726699994, 10.834766215000059],
              [-75.1526975079999 , 10.834791026000062],
              [-75.15249365499994, 10.834892678000074],
              [-75.1521765139999 , 10.835019371000044],
              [-75.15198641199993, 10.835145609000051],
              [-75.15179608599993, 10.83520906700005 ],
              [-75.15160575999994, 10.83527252500005 ],
              [-75.15141543399994, 10.835335971000063],
              [-75.15122510799995, 10.835399430000052],
              [-75.1510347819999 , 10.835462877000055],
              [-75.15084467799994, 10.835589127000048],
              [-75.15065479999993, 10.83577815800004 ],
              [-75.15046470199991, 10.83590440100005 ],
              [-75.15014823399991, 10.836219454000059],
              [-75.14995812399991, 10.836345695000091],
              [-75.14976847199995, 10.836597521000044],
              [-75.14970584999992, 10.836848897000039],
              [-75.14964322899993, 10.837100282000051],
              [-75.1496438929999 , 10.837288647000037],
              [-75.14964478899992, 10.837539807000041],
              [-75.14958194499991, 10.83772840100005 ],
              [-75.14958260999992, 10.837916771000039],
              [-75.1495832839999 , 10.83810513800006 ],
              [-75.14952043699992, 10.838293728000053],
              [-75.14945781299991, 10.838545115000045],
              [-75.14939496999995, 10.838733705000038],
              [-75.1493321239999 , 10.838922288000049],
              [-75.14920598299994, 10.839173889000051],
              [-75.14920664699991, 10.83936226500009 ],
              [-75.1492075459999 , 10.839613425000039],
              [-75.14914469899992, 10.83980200800005 ],
              [-75.1491455879999 , 10.840053168000054],
              [-75.14914648299992, 10.840304328000059],
              [-75.1490836289999 , 10.840492923000056],
              [-75.1490843059999 , 10.840681288000042],
              [-75.14895793999995, 10.840870107000057],
              [-75.14883157399993, 10.84105891300004 ],
              [-75.14870520799991, 10.841247733000046],
              [-75.14851510499994, 10.841373971000053],
              [-75.14832521999995, 10.841563002000044],
              [-75.1481353339999 , 10.84175203600006 ],
              [-75.1479454489999 , 10.84194106600006 ],
              [-75.1478190819999 , 10.842129877000048],
              [-75.1477562359999 , 10.842318467000041],
              [-75.1476933809999 , 10.842507050000052],
              [-75.14763053199994, 10.84269564400006 ],
              [-75.14763120899994, 10.842884014000049],
              [-75.14763209799992, 10.843135171000085],
              [-75.1476962829999 , 10.84332332300005 ],
              [-75.14769695299992, 10.84351169200005 ],
              [-75.14769762999993, 10.84370006100005 ],
              [-75.1475714799999 , 10.843951667000056],
              [-75.1474453379999 , 10.844203268000058],
              [-75.1472554529999 , 10.844392301000084],
              [-75.1471925969999 , 10.844580884000038],
              [-75.1470022709999 , 10.844644341000048],
              [-75.14668890899992, 10.845838453000056],
              [-75.14637555299993, 10.847032556000045],
              [-75.1461890089999 , 10.84816343600005 ],
              [-75.14599867399994, 10.84822689300006 ],
              [-75.14587230299992, 10.848415703000057],
              [-75.1458094489999 , 10.84860429300005 ],
              [-75.14555626799995, 10.84885633500005 ],
              [-75.14536637599991, 10.849045367000087],
              [-75.14524022599994, 10.849296967000043],
              [-75.1450503399999 , 10.849485991000051],
              [-75.14479692499992, 10.84967524800004 ],
              [-75.14460681499992, 10.849801490000061],
              [-75.14454395999991, 10.849990071000093],
              [-75.14435362499995, 10.850053528000046],
              [-75.14429076999994, 10.850242111000057],
              [-75.1442279229999 , 10.850430707000044],
              [-75.14422858799992, 10.850619072000086],
              [-75.14416573299991, 10.850807665000048],
              [-75.14410311199993, 10.85105905000006 ],
              [-75.14410377599995, 10.851247415000046],
              [-75.14391344099994, 10.851310864000084],
              [-75.14372331899995, 10.851437105000059],
              [-75.14353342699991, 10.85162613600005 ],
              [-75.1433435319999 , 10.851815160000058],
              [-75.1431534219999 , 10.851941400000044],
              [-75.14309056699994, 10.852129992000073],
              [-75.14302771099995, 10.852318589000049],
              [-75.14290133599991, 10.852507393000053],
              [-75.14271100099995, 10.852570840000055],
              [-75.14252065699992, 10.852634288000047],
              [-75.14249319999993, 10.852654792000067],
              [-75.14243263599991, 10.85270002100009 ],
              [-75.14226723899992, 10.852823537000063],
              [-75.14218959399994, 10.852823809000085],
              [-75.14207668299991, 10.852824205000047],
              [-75.14193913999992, 10.852915531000065],
              [-75.14188655999993, 10.852950443000054],
              [-75.1416683139999 , 10.852994377000073],
              [-75.14156918899994, 10.853014332000043],
              [-75.1413786199999 , 10.853014987000051],
              [-75.14118828499994, 10.853078434000054],
              [-75.14111008999993, 10.853078703000051],
              [-75.14110406499992, 10.853078724000056],
              [-75.14099772499992, 10.853079089000062],
              [-75.14080715699993, 10.853079757000046],
              [-75.14072924599992, 10.85310572800006 ],
              [-75.14061681899994, 10.853143205000038],
              [-75.1404267019999 , 10.853269433000037],
              [-75.14023635699994, 10.853332885000043],
              [-75.14004602299991, 10.853396331000056],
              [-75.13928510499994, 10.853775709000047],
              [-75.1389677219999 , 10.85383958500006 ],
              [-75.13877760199995, 10.853965826000092],
              [-75.13852373799995, 10.854029491000063],
              [-75.13826987399995, 10.854093162000083],
              [-75.13807953399993, 10.854156602000046],
              [-75.1378891949999 , 10.854220049000048],
              [-75.13769863599993, 10.854220704000056],
              [-75.13750829199995, 10.854284152000048],
              [-75.13725442599991, 10.854347820000044],
              [-75.1370003369999 , 10.854348697000091],
              [-75.1367464839999 , 10.854412363000051],
              [-75.1364926199999 , 10.854476026000043],
              [-75.13623875699994, 10.854539687000056],
              [-75.13604840799991, 10.854603134000058],
              [-75.13585806799995, 10.854666577000046],
              [-75.13566772399992, 10.854730025000038],
              [-75.13547716499994, 10.854730679000056],
              [-75.13528681799994, 10.854794127000048],
              [-75.13509647699993, 10.854857569000046],
              [-75.1349061329999 , 10.854921015000059],
              [-75.13471579799995, 10.854984462000061],
              [-75.13446193399994, 10.855048125000053],
              [-75.13427158699994, 10.855111566000062],
              [-75.13408146099994, 10.855237806000048],
              [-75.13389134099992, 10.855364035000036],
              [-75.13370099799994, 10.855427487000043],
              [-75.13351087799992, 10.855553715000042],
              [-75.13332053399995, 10.85561715600005 ],
              [-75.13319414999995, 10.85580596200009 ],
              [-75.13300402899995, 10.855932205000045],
              [-75.1329411669999 , 10.856120788000055],
              [-75.13287831199995, 10.85630937700006 ],
              [-75.13268840799992, 10.856498403000046],
              [-75.1324982779999 , 10.85662463500006 ],
              [-75.13237189199992, 10.856813440000053],
              [-75.13218155099992, 10.856876881000062],
              [-75.1319916459999 , 10.857065902000045],
              [-75.13180130399991, 10.857129350000037],
              [-75.13167491899992, 10.857318158000055],
              [-75.1315485319999 , 10.857506957000055],
              [-75.1313584159999 , 10.857633197000041],
              [-75.13116806999994, 10.85769663700006 ],
              [-75.13097771699995, 10.857760075000044],
              [-75.1307875999999 , 10.857886318000055],
              [-75.13059725399995, 10.857949756000039],
              [-75.13040734299994, 10.858138780000047],
              [-75.13021743899992, 10.858327804000055],
              [-75.13009105499992, 10.858516603000055],
              [-75.12990092699994, 10.858642841000062],
              [-75.1299015909999 , 10.858831211000052],
              [-75.12983872699994, 10.859019795000052],
              [-75.12977586099993, 10.859208385000045],
              [-75.1297765189999 , 10.859396753000055],
              [-75.12977718399992, 10.859585129000038],
              [-75.12977783899993, 10.85977349600006 ],
              [-75.12977850399994, 10.85996186500006 ],
              [-75.12977915699992, 10.860150242000088],
              [-75.12977981599994, 10.860338609000053],
              [-75.12978047999991, 10.860526979000042],
              [-75.1297811359999 , 10.860715346000063],
              [-75.12978180099992, 10.860903722000046],
              [-75.12984597699995, 10.861091875000056],
              [-75.12997368099991, 10.861279809000052],
              [-75.13016491499991, 10.861467533000052],
              [-75.13029261899993, 10.861655461000055],
              [-75.13054692999992, 10.861717381000062],
              [-75.13073771599994, 10.861779525000088],
              [-75.13092872099992, 10.86190444400006 ],
              [-75.13099290999992, 10.86209259800006 ],
              [-75.13099356499993, 10.862280974000043],
              [-75.13099422999994, 10.862469343000043],
              [-75.13099488499995, 10.862657711000054],
              [-75.1308047579999 , 10.862783949000061],
              [-75.13061441399992, 10.86284739100006 ],
              [-75.13042384499994, 10.862848044000089],
              [-75.13016975599993, 10.862848919000044],
              [-75.12991565899995, 10.862849778000054],
              [-75.12972508999991, 10.862850434000052],
              [-75.12953451899995, 10.862851080000041],
              [-75.12928020899994, 10.862789159000044],
              [-75.1290896399999 , 10.862789816000088],
              [-75.12883532299992, 10.862727894000045],
              [-75.12858079799992, 10.86260318300009 ],
              [-75.12839022999992, 10.862603837000051],
              [-75.12819944299991, 10.862541692000093],
              [-75.12788138699995, 10.862417195000091],
              [-75.12756333999994, 10.862292707000051],
              [-75.12730902499993, 10.862230775000057],
              [-75.12711823299992, 10.86216863900006 ],
              [-75.12692744899994, 10.862106505000042],
              [-75.12673666299992, 10.862044357000059],
              [-75.12648235199993, 10.861982437000052],
              [-75.12629178299994, 10.861983084000087],
              [-75.1261009989999 , 10.861920946000055],
              [-75.12584419799992, 10.861921819000088],
              [-75.12584411599994, 10.861898091000057],
              [-75.12566089699993, 10.861766063000061],
              [-75.12547229299992, 10.861755147000054],
              [-75.12520460699994, 10.86173965200004 ],
              [-75.12493072299992, 10.861732541000038],
              [-75.12466495599995, 10.86179813800004 ],
              [-75.12437549299995, 10.861813450000056],
              [-75.12413001499993, 10.86181347300004 ],
              [-75.12371503799994, 10.861854721000043],
              [-75.1236185329999 , 10.86188390600006 ],
              [-75.12352456499991, 10.861912324000059],
              [-75.12298920599994, 10.862176610000063],
              [-75.1227443539999 , 10.86231377100006 ],
              [-75.12269834499995, 10.86233954700009 ],
              [-75.12257938199991, 10.86247293300005 ],
              [-75.12250435099992, 10.86259390400005 ],
              [-75.12250289899993, 10.862603075000038],
              [-75.12249616299994, 10.862645633000056],
              [-75.12247753399993, 10.862687858000072],
              [-75.12241857099991, 10.862821506000046],
              [-75.12229779499995, 10.862807176000047],
              [-75.12221110099995, 10.862806025000054],
              [-75.12211989899993, 10.86283941000005 ],
              [-75.12207762199995, 10.862863649000076],
              [-75.12205452699993, 10.862876890000052],
              [-75.12194311299993, 10.862909328000057],
              [-75.12181291699994, 10.862887328000056],
              [-75.1217592189999 , 10.862878255000055],
              [-75.12175369699992, 10.862877322000088],
              [-75.12160595899991, 10.862882009000089],
              [-75.12149793399993, 10.862903029000051],
              [-75.1213981599999 , 10.862933124000051],
              [-75.12128124999992, 10.863020350000056],
              [-75.12113410099994, 10.863135140000054],
              [-75.12096921199992, 10.86325780900006 ],
              [-75.12084358999994, 10.863344656000038],
              [-75.12084297099995, 10.86334546200004 ],
              [-75.12077271399994, 10.863436961000048],
              [-75.12067429599995, 10.863499015000059],
              [-75.12057734899992, 10.863529283000048],
              [-75.12043083899994, 10.863505084000053],
              [-75.12034069999993, 10.86345164000005 ],
              [-75.12032521799995, 10.863447603000054],
              [-75.12019195899995, 10.863412854000046],
              [-75.12003385499992, 10.863388106000059],
              [-75.1199173089999 , 10.86338268600008 ],
              [-75.11986914399995, 10.863380446000065],
              [-75.11974460399995, 10.863366301000042],
              [-75.11969628899993, 10.863360813000043],
              [-75.11953484799994, 10.863344611000059],
              [-75.11935124799993, 10.863367951000043],
              [-75.11916954599991, 10.863350805000039],
              [-75.11893837499991, 10.863337151000053],
              [-75.11881129299991, 10.863328342000045],
              [-75.11868762199992, 10.863308098000061],
              [-75.11865811899992, 10.863303359000042],
              [-75.1185739149999 , 10.863289835000046],
              [-75.11853516399992, 10.863283611000043],
              [-75.11834150899995, 10.863271705000045],
              [-75.11818652399995, 10.863249136000093],
              [-75.11816587699991, 10.86324612900006 ],
              [-75.11799338899993, 10.86321491700005 ],
              [-75.11785438299995, 10.863211342000056],
              [-75.11770767599995, 10.86324800500006 ],
              [-75.11769152699992, 10.863252290000048],
              [-75.1175960779999 , 10.863277617000051],
              [-75.1173600379999 , 10.863301429000046],
              [-75.11719101599994, 10.86331094500008 ],
              [-75.11707790999992, 10.863317313000039],
              [-75.11704068899991, 10.863325030000055],
              [-75.11696365699993, 10.863341001000038],
              [-75.1168240099999 , 10.863349012000072],
              [-75.11667485599992, 10.863373986000056],
              [-75.11661915799993, 10.863393531000042],
              [-75.1165361699999 , 10.863422653000043],
              [-75.11639019299992, 10.863502896000057],
              [-75.1163753269999 , 10.863512098000058],
              [-75.1163220709999 , 10.86354506400005 ],
              [-75.11628556499994, 10.863567661000047],
              [-75.1162073939999 , 10.863624965000042],
              [-75.11607767299995, 10.863729186000057],
              [-75.11600764499991, 10.863798472000042],
              [-75.11591224999995, 10.863913013000058],
              [-75.1158339619999 , 10.86403415600006 ],
              [-75.1157764649999 , 10.86408373100005 ],
              [-75.11570543099992, 10.86411236400005 ],
              [-75.11563729099993, 10.864141131000054],
              [-75.11556529299992, 10.86419003800006 ],
              [-75.11549922799992, 10.864236319000042],
              [-75.1154441679999 , 10.864233772000091],
              [-75.11539118899992, 10.864248739000061],
              [-75.11533504099992, 10.864269365000041],
              [-75.11530081299992, 10.864256176000083],
              [-75.11522570299991, 10.86418595300006 ],
              [-75.1151648309999 , 10.864183138000044],
              [-75.11507098999994, 10.86420202100004 ],
              [-75.11491007099994, 10.864226509000048],
              [-75.11477644799993, 10.864226148000057],
              [-75.11469224199993, 10.864225155000042],
              [-75.11458097799994, 10.864243238000086],
              [-75.1144339569999 , 10.864279990000057],
              [-75.11427252399994, 10.86425221400009 ],
              [-75.11413138499995, 10.864225375000046],
              [-75.11401564599993, 10.864214220000065],
              [-75.11389976699991, 10.864205963000074],
              [-75.1137246269999 , 10.864221106000059],
              [-75.11361012299994, 10.864244854000049],
              [-75.11336865599992, 10.86430920500004 ],
              [-75.11314716999993, 10.864319309000052],
              [-75.11314104099995, 10.864321432000054],
              [-75.11306995299992, 10.864346082000054],
              [-75.11302889099994, 10.86436032000006 ],
              [-75.11280112899993, 10.864382510000041],
              [-75.11276589399995, 10.864385943000059],
              [-75.11215129099992, 10.864462178000053],
              [-75.1114505619999 , 10.864569353000093],
              [-75.11088554399993, 10.864633422000054],
              [-75.11047755899995, 10.864698934000046],
              [-75.11008617499994, 10.864787341000067],
              [-75.10998572199992, 10.864810031000047],
              [-75.10979727499995, 10.864850800000056],
              [-75.10970201399994, 10.864892936000047],
              [-75.10961602299994, 10.864923875000045],
              [-75.10953112199991, 10.864931605000038],
              [-75.1094920669999 , 10.864955980000047],
              [-75.10945817299995, 10.864959233000093],
              [-75.10945107299995, 10.864959914000053],
              [-75.10939193399992, 10.864977561000046],
              [-75.10932364999991, 10.865003495000053],
              [-75.10926519899994, 10.86500662800006 ],
              [-75.10915840699994, 10.864992198000039],
              [-75.10913484499991, 10.86498901400006 ],
              [-75.10898481099991, 10.865017025000043],
              [-75.10895982799991, 10.86502333900006 ],
              [-75.1088874159999 , 10.865041639000083],
              [-75.1086769389999 , 10.865113420000057],
              [-75.10858474299994, 10.865139433000081],
              [-75.1085705189999 , 10.865143446000047],
              [-75.1084940259999 , 10.865157383000053],
              [-75.10849111499994, 10.86515724800006 ],
              [-75.10848806399991, 10.865160016000061],
              [-75.10848224599994, 10.865159758000061],
              [-75.10847933799994, 10.865159621000089],
              [-75.10847642899995, 10.86515948500005 ],
              [-75.10847351299992, 10.865159350000056],
              [-75.10847337599995, 10.865162252000061],
              [-75.1084704669999 , 10.865162118000057],
              [-75.10846464199994, 10.865161860000057],
              [-75.10846172899994, 10.865161723000085],
              [-75.10845868799993, 10.865164489000051],
              [-75.10845285999994, 10.865164220000054],
              [-75.10844995399992, 10.865164096000058],
              [-75.1084438549999 , 10.865169627000057],
              [-75.10843803799992, 10.86516935800006 ],
              [-75.10843512899993, 10.865169224000056],
              [-75.1084290309999 , 10.865174767000042],
              [-75.10842320299992, 10.865174497000055],
              [-75.10841738499994, 10.865174239000055],
              [-75.1084115619999 , 10.86517396700009 ],
              [-75.10840851599994, 10.865176733000055],
              [-75.10840269899995, 10.865176464000058],
              [-75.10839687399994, 10.865176206000058],
              [-75.10839077499992, 10.865181737000057],
              [-75.1083878629999 , 10.865181603000053],
              [-75.10838204599992, 10.865181345000053],
              [-75.10837622399993, 10.865181073000088],
              [-75.10837331199991, 10.865180937000048],
              [-75.10836748899993, 10.865180667000061],
              [-75.10836139999992, 10.865186211000037],
              [-75.10835557299993, 10.86518594100005 ],
              [-75.10834947199993, 10.865191487000061],
              [-75.10834073899991, 10.865191081000091],
              [-75.1083378319999 , 10.865190945000052],
              [-75.10833478599994, 10.865193714000043],
              [-75.10832896099993, 10.865193451000039],
              [-75.10829625899993, 10.865206497000088],
              [-75.10829015699994, 10.865212031000056],
              [-75.1082013059999 , 10.865239960000054],
              [-75.1081777469999 , 10.865244695000058],
              [-75.10815709699995, 10.865249572000039],
              [-75.10815126699993, 10.865249304000088],
              [-75.10814517199992, 10.865254839000045],
              [-75.10814226299993, 10.86525471400006 ],
              [-75.10813643799992, 10.865254442000037],
              [-75.1081333919999 , 10.865257210000038],
              [-75.10812756299993, 10.865256941000041],
              [-75.10812465799995, 10.865256816000056],
              [-75.10811855899993, 10.865262348000044],
              [-75.10811565099993, 10.86526221400004 ],
              [-75.10811273299993, 10.865262078000057],
              [-75.10810982499993, 10.865261954000061],
              [-75.10810677099994, 10.865264720000084],
              [-75.10810385899993, 10.865264584000045],
              [-75.10809804099995, 10.865264314000058],
              [-75.10809498999993, 10.865267094000046],
              [-75.10808917199995, 10.86526682400006 ],
              [-75.10808625699991, 10.86526668600004 ],
              [-75.10808043899993, 10.865266416000054],
              [-75.10807433699995, 10.865271964000044],
              [-75.10807142099992, 10.865271828000061],
              [-75.10806560399993, 10.865271556000039],
              [-75.1080597799999 , 10.86527129600006 ],
              [-75.10805396399991, 10.865271024000037],
              [-75.10804813699991, 10.865270756000086],
              [-75.10803649399992, 10.865270228000043],
              [-75.1080306689999 , 10.865269954000041],
              [-75.10802485199991, 10.865269694000062],
              [-75.10801611699992, 10.86526929200005 ],
              [-75.10801028899994, 10.865269029000046],
              [-75.10800446499991, 10.865268760000049],
              [-75.10799282199991, 10.865268229000037],
              [-75.1079813149999 , 10.865264788000047],
              [-75.10797257599995, 10.865264396000043],
              [-75.10796676399991, 10.865264122000042],
              [-75.10796107399995, 10.86526095000005 ],
              [-75.10795233999994, 10.865260558000045],
              [-75.10794652299995, 10.865260288000059],
              [-75.1079377879999 , 10.865259890000061],
              [-75.10793196199995, 10.865259622000053],
              [-75.10792613599995, 10.865259355000092],
              [-75.10792322799995, 10.865259216000084],
              [-75.10791739699994, 10.865258956000048],
              [-75.10791449299995, 10.865258822000044],
              [-75.10790881299994, 10.865255650000051],
              [-75.10790589499993, 10.865255517000037],
              [-75.10790007799994, 10.865255252000054],
              [-75.10789716099993, 10.86525511800005 ],
              [-75.10789134399994, 10.865254848000063],
              [-75.10788842599993, 10.86525471400006 ],
              [-75.10788551599995, 10.865254587000038],
              [-75.10787969099994, 10.86525431800004 ],
              [-75.10787678299994, 10.865254182000058],
              [-75.10787387399995, 10.865254048000054],
              [-75.10785640599994, 10.865253248000045],
              [-75.10785348499991, 10.865253112000062],
              [-75.10781273299995, 10.865251254000043],
              [-75.10780690399991, 10.865250984000056],
              [-75.10780080799992, 10.865256527000042],
              [-75.10779498199992, 10.865256258000045],
              [-75.10778915299994, 10.865255988000058],
              [-75.10778333499991, 10.865255725000054],
              [-75.1077772399999 , 10.865261262000047],
              [-75.1077714139999 , 10.86526099200006 ],
              [-75.10776849599995, 10.86526086300006 ],
              [-75.10775685099992, 10.865260326000055],
              [-75.10775075499993, 10.865265867000062],
              [-75.10774492899992, 10.865265600000043],
              [-75.10773909999995, 10.865265330000057],
              [-75.1077330039999 , 10.865270873000043],
              [-75.10772718699991, 10.865270604000045],
              [-75.1077213609999 , 10.865270341000041],
              [-75.10771234399994, 10.865275741000062],
              [-75.10770652699995, 10.865275479000047],
              [-75.10770070099994, 10.86527520900006 ],
              [-75.10769487599993, 10.865274941000052],
              [-75.10768877499993, 10.86528048300005 ],
              [-75.10768294999991, 10.865280213000062],
              [-75.10767712499995, 10.865279955000062],
              [-75.10767421599991, 10.865279821000058],
              [-75.10766811699995, 10.865285353000047],
              [-75.10766229099994, 10.865285095000047],
              [-75.10765938299994, 10.865284957000085],
              [-75.10765328399992, 10.865290491000053],
              [-75.10764745599994, 10.865290233000053],
              [-75.10764453999991, 10.865290099000049],
              [-75.10763872199993, 10.865289824000058],
              [-75.10763261499994, 10.865295373000038],
              [-75.10762970499991, 10.865295237000055],
              [-75.1076267979999 , 10.865295101000072],
              [-75.10762068699995, 10.865300644000058],
              [-75.10761487399992, 10.86530037700004 ],
              [-75.10761195299995, 10.865300238000088],
              [-75.10760585699995, 10.865305784000043],
              [-75.1076029489999 , 10.86530564800006 ],
              [-75.10759711799994, 10.865305376000038],
              [-75.10759102399993, 10.865310922000049],
              [-75.10758810599992, 10.865310788000045],
              [-75.10758228999993, 10.865310518000058],
              [-75.10757617999991, 10.865316060000055],
              [-75.1075732729999 , 10.86531592600005 ],
              [-75.10756744699995, 10.865315663000047],
              [-75.10756134399992, 10.86532119800006 ],
              [-75.1075523319999 , 10.865326607000043],
              [-75.10754650599995, 10.865326337000056],
              [-75.10754345799995, 10.865329113000087],
              [-75.10753763499991, 10.865328843000043],
              [-75.10753167199994, 10.865331475000062],
              [-75.1075315359999 , 10.865334387000075],
              [-75.1075228009999 , 10.865333981000049],
              [-75.10751669399991, 10.86533952700006 ],
              [-75.1075108679999 , 10.865339255000038],
              [-75.10750476899995, 10.865344801000049],
              [-75.10749894299994, 10.865344528000037],
              [-75.10749589799991, 10.865347297000085],
              [-75.10749006899994, 10.86534703600006 ],
              [-75.10748397299994, 10.865352570000084],
              [-75.10748105099992, 10.865352434000044],
              [-75.10747509299995, 10.86535507800005 ],
              [-75.10746899399993, 10.865360619000057],
              [-75.10746316399991, 10.86536035000006 ],
              [-75.10745720599994, 10.865362984000058],
              [-75.10745415199995, 10.865365757000063],
              [-75.10744818899991, 10.86536839200005 ],
              [-75.10744209099994, 10.865373933000058],
              [-75.10743918199995, 10.865373799000054],
              [-75.10743320799992, 10.865376439000045],
              [-75.10743016599992, 10.865379207000046],
              [-75.10742420299994, 10.865381841000044],
              [-75.1074180949999 , 10.86538738300004 ],
              [-75.10741518599991, 10.865387249000037],
              [-75.10740894199995, 10.86539569200005 ],
              [-75.1074031249999 , 10.865395422000063],
              [-75.1074000719999 , 10.865398202000051],
              [-75.10739119099992, 10.865400699000077],
              [-75.10738509299995, 10.865406242000063],
              [-75.10737926299993, 10.86540597000004 ],
              [-75.10737316799992, 10.865411516000052],
              [-75.10737024999992, 10.865411382000048],
              [-75.10736428799993, 10.86541402100005 ],
              [-75.10735873999994, 10.865419056000064],
              [-75.10735818899991, 10.865419556000063],
              [-75.10735596599994, 10.86541945600004 ],
              [-75.10735235899995, 10.865419293000059],
              [-75.1073500679999 , 10.86542030600009 ],
              [-75.10734639999993, 10.865421927000057],
              [-75.1073403019999 , 10.865427469000053],
              [-75.10733447199993, 10.865427201000045],
              [-75.10732559599995, 10.86542970700009 ],
              [-75.10731963299992, 10.865432339000051],
              [-75.1073135339999 , 10.865437883000084],
              [-75.10730770799995, 10.86543761300004 ],
              [-75.1073017459999 , 10.865440257000046],
              [-75.10728981199992, 10.865445531000091],
              [-75.10728399499993, 10.865445261000048],
              [-75.10727788199995, 10.865450802000055],
              [-75.10727206099995, 10.865450535000036],
              [-75.10726609799991, 10.86545317200006 ],
              [-75.10725999599993, 10.865458715000045],
              [-75.10725417399993, 10.865458445000058],
              [-75.10724542999992, 10.865458053000054],
              [-75.1072394649999 , 10.865460683000038],
              [-75.10723044699995, 10.865466095000045],
              [-75.10722754299991, 10.865465957000083],
              [-75.10722144199991, 10.865471501000059],
              [-75.10721852699993, 10.865471367000055],
              [-75.10721269599992, 10.865471095000089],
              [-75.10720964399991, 10.865473873000042],
              [-75.10720382699992, 10.865473603000055],
              [-75.10720091099995, 10.865473469000051],
              [-75.10719800399994, 10.865473340000051],
              [-75.10719189299994, 10.865478877000044],
              [-75.10718898699992, 10.865478740000071],
              [-75.1071860699999 , 10.86547861400004 ],
              [-75.1071830109999 , 10.865481382000041],
              [-75.10718010699992, 10.865481246000058],
              [-75.10717719899992, 10.865481112000055],
              [-75.1071741419999 , 10.865483887000039],
              [-75.10717122699992, 10.86548375600006 ],
              [-75.10716831899992, 10.865483618000042],
              [-75.10716526499994, 10.865486386000043],
              [-75.10716235599995, 10.865486262000047],
              [-75.10715943799994, 10.865486128000043],
              [-75.10715652699992, 10.86548599200006 ],
              [-75.10715361099994, 10.865485854000042],
              [-75.10715070399993, 10.865485720000038],
              [-75.10714764999994, 10.865488497000058],
              [-75.10714474199995, 10.865488364000043],
              [-75.10714182399994, 10.86548822800006 ],
              [-75.10713891099994, 10.865488094000057],
              [-75.10713585999991, 10.865490867000062],
              [-75.10713294399994, 10.865490733000058],
              [-75.1071300349999 , 10.865490599000054],
              [-75.10712711799994, 10.865490464000061],
              [-75.10712420899995, 10.865490330000057],
              [-75.10711837999992, 10.865490067000053],
              [-75.10711547499994, 10.865489933000049],
              [-75.10711255699994, 10.865489799000045],
              [-75.10710950399994, 10.865492566000057],
              [-75.10710659499995, 10.865492439000093],
              [-75.10710645799992, 10.865495341000042],
              [-75.10710062899994, 10.865495071000055],
              [-75.10709757799992, 10.865497851000043],
              [-75.1070946609999 , 10.86549771500006 ],
              [-75.10708855999991, 10.865503258000047],
              [-75.10708564399994, 10.865503122000064],
              [-75.10708273599994, 10.86550298900005 ],
              [-75.10707967699994, 10.865505755000072],
              [-75.10707385599994, 10.865505494000047],
              [-75.10707080199995, 10.865508262000048],
              [-75.10706789299991, 10.865508126000066],
              [-75.10706497599995, 10.865507990000083],
              [-75.10706193099992, 10.865510766000057],
              [-75.10706179099992, 10.865513668000062],
              [-75.10705887499995, 10.865513534000058],
              [-75.10705596899993, 10.865513400000054],
              [-75.10705582299994, 10.865516309000043],
              [-75.10705291499994, 10.865516175000039],
              [-75.10704999299992, 10.865516039000056],
              [-75.10698969499992, 10.865541276000044],
              [-75.10694499099992, 10.86555998700004 ],
              [-75.10681950899993, 10.865589173000046],
              [-75.10672724499995, 10.865625701000056],
              [-75.10665648499992, 10.865639929000054],
              [-75.10651627899995, 10.86567136800005 ],
              [-75.1064343409999 , 10.865705465000076],
              [-75.10628647499993, 10.865743835000046],
              [-75.1061803639999 , 10.865763730000083],
              [-75.1060678039999 , 10.865796431000092],
              [-75.10595758699992, 10.865840892000051],
              [-75.10587943599995, 10.86588682200005 ],
              [-75.10579919699995, 10.865884619000042],
              [-75.10561794299991, 10.865947686000084],
              [-75.10547799099993, 10.866002451000043],
              [-75.10538363299992, 10.866019995000045],
              [-75.10526690099994, 10.866054734000045],
              [-75.10526059699993, 10.866056609000054],
              [-75.10520553099991, 10.866077401000041],
              [-75.10509969599991, 10.86612062800009 ],
              [-75.1049809459999 , 10.866158908000045],
              [-75.1048929399999 , 10.866195675000085],
              [-75.10481633499995, 10.866208207000057],
              [-75.10468693799993, 10.866224167000041],
              [-75.10455752199994, 10.866240119000054],
              [-75.10442864499993, 10.866275047000045],
              [-75.10439106199993, 10.866296627000054],
              [-75.10432977799991, 10.866324428000041],
              [-75.10426217699995, 10.866331555000045],
              [-75.10415245699994, 10.866362977000051],
              [-75.10407455699993, 10.86640168300005 ],
              [-75.10396580999992, 10.866412766000053],
              [-75.10389624099992, 10.866429999000047],
              [-75.10383826899994, 10.866479810000044],
              [-75.10375963199994, 10.866545836000057],
              [-75.10375597299992, 10.866548906000048],
              [-75.10365031599991, 10.866586355000038],
              [-75.10362931899994, 10.86659301900005 ],
              [-75.10328485699995, 10.86670235300005 ],
              [-75.10310761699992, 10.866767176000053],
              [-75.10299544799994, 10.86681746700009 ],
              [-75.1026057439999 , 10.867008205000047],
              [-75.10235669299993, 10.867164545000037],
              [-75.10200787399992, 10.86735432000006 ],
              [-75.10182092499991, 10.867496023000058],
              [-75.10168408299995, 10.867600654000057],
              [-75.10159129099992, 10.867672282000058],
              [-75.10149570599992, 10.867740874000049],
              [-75.10136772899995, 10.867873625000072],
              [-75.10116053999991, 10.868129690000046],
              [-75.10106485499995, 10.86823038500006 ],
              [-75.10091787499994, 10.868422114000055],
              [-75.10078925399995, 10.868658447000087],
              [-75.10069796699992, 10.868819169000062],
              [-75.10064708399995, 10.868962744000044],
              [-75.1005927949999 , 10.869055131000039],
              [-75.1005543949999 , 10.869121969000048],
              [-75.10046524899991, 10.86929886200005 ],
              [-75.1004667759999 , 10.869510435000052],
              [-75.10045487499991, 10.86966598500004 ],
              [-75.10041181399993, 10.86992080400006 ],
              [-75.10036466799994, 10.870077730000048],
              [-75.10036832799994, 10.87015374300006 ],
              [-75.10036703899993, 10.870271852000087],
              [-75.10035627399992, 10.87037349700006 ],
              [-75.10034125599992, 10.87047203800006 ],
              [-75.1003207569999 , 10.870532405000063],
              [-75.1002730649999 , 10.870639725000046],
              [-75.10025087699995, 10.870735031000038],
              [-75.10026970099995, 10.870891971000049],
              [-75.10024535299993, 10.870971147000091],
              [-75.10019525099995, 10.871006869000041],
              [-75.10013155699994, 10.871020117000057],
              [-75.10012512999992, 10.871062152000093],
              [-75.10015526199993, 10.87116706800009 ],
              [-75.10015989999994, 10.871253358000047],
              [-75.10016145999992, 10.871373067000093],
              [-75.1002040589999 , 10.871493128000054],
              [-75.10026470999992, 10.871603754000091],
              [-75.10026181599994, 10.871694091000052],
              [-75.10024351599992, 10.871715083000083],
              [-75.10021525499991, 10.871747497000058],
              [-75.10016318899994, 10.87179337300006 ],
              [-75.10010583499991, 10.871839625000064],
              [-75.10010538599994, 10.871839987000044],
              [-75.10010450699991, 10.87184069600005 ],
              [-75.10009520499995, 10.871848196000087],
              [-75.10009453499993, 10.87184873700005 ],
              [-75.10006628399992, 10.871866493000084],
              [-75.10007420399995, 10.87189223300004 ],
              [-75.10008741899992, 10.871935182000072],
              [-75.10010455199995, 10.871990864000054],
              [-75.10013744299994, 10.872028689000047],
              [-75.1002628039999 , 10.872172854000041],
              [-75.10026584199994, 10.872176348000039],
              [-75.1002949089999 , 10.872209774000055],
              [-75.10031296099993, 10.872219019000056],
              [-75.10050963299994, 10.872319754000046],
              [-75.10068513899995, 10.872409648000087],
              [-75.10080207499993, 10.87252658400007 ],
              [-75.10083742599994, 10.872561934000089],
              [-75.10090365399992, 10.872674522000068],
              [-75.10093260399992, 10.872723737000058],
              [-75.10091903499995, 10.872761733000061],
              [-75.10090365399992, 10.872804797000072],
              [-75.10088501399991, 10.872856987000091],
              [-75.10070417599991, 10.87289505800004 ],
              [-75.1005518899999 , 10.872952166000061],
              [-75.10054985599993, 10.872953004000067],
              [-75.10039008699994, 10.87301879100005 ],
              [-75.10022185299994, 10.873108165000076],
              [-75.10021801099992, 10.873110206000092],
              [-75.10018131899994, 10.87312969900006 ],
              [-75.10008551599992, 10.873180594000075],
              [-75.09995226599995, 10.873247218000074],
              [-75.09989573999991, 10.873198229000081],
              [-75.09959279499992, 10.873248876000048],
              [-75.09955536899992, 10.873255133000043],
              [-75.0994706589999 , 10.873269294000067],
              [-75.09942573699993, 10.873276186000055],
              [-75.09868837399995, 10.873389305000046],
              [-75.09851436899993, 10.873415998000041],
              [-75.09829052799995, 10.87347401900007 ],
              [-75.09812777099995, 10.873516205000044],
              [-75.09792535499992, 10.873568671000044],
              [-75.09789398799995, 10.87357680200006 ],
              [-75.0978919229999 , 10.873577337000086],
              [-75.09782051899992, 10.873595845000068],
              [-75.0977917159999 , 10.873637451000093],
              [-75.09775364299992, 10.873656486000073],
              [-75.09766798399994, 10.873599380000087],
              [-75.09757280499991, 10.873608898000043],
              [-75.0974585909999 , 10.873656486000073],
              [-75.09729678799994, 10.873666004000086],
              [-75.09723404699992, 10.873666004000086],
              [-75.09718257399993, 10.873666004000086],
              [-75.09702076999992, 10.87374214600004 ],
              [-75.09695414499993, 10.873780219000082],
              [-75.09687295399993, 10.873739623000063],
              [-75.09681716599994, 10.873749841000063],
              [-75.09674316299993, 10.873763396000072],
              [-75.09666127799994, 10.873778395000045],
              [-75.09657171899994, 10.873794799000052],
              [-75.09653116599992, 10.873689151000065],
              [-75.09602164099994, 10.87373593400008 ],
              [-75.09596060399991, 10.873787142000083],
              [-75.09513480299995, 10.873933845000067],
              [-75.09507718299994, 10.873919686000079],
              [-75.09435079399992, 10.874106776000076],
              [-75.09264108899993, 10.87444663000008 ],
              [-75.09257335099994, 10.874355445000049],
              [-75.09250800899991, 10.874267485000075],
              [-75.09232331699991, 10.874332312000092],
              [-75.09218217699993, 10.874631729000043],
              [-75.09206448499992, 10.87470984500004 ],
              [-75.09210028699994, 10.874761293000063],
              [-75.09186460299992, 10.874833546000048],
              [-75.09167624799994, 10.874633435000078],
              [-75.0912819699999 , 10.87495756900006 ],
              [-75.09123317399991, 10.874838149000084],
              [-75.09102426299995, 10.874887784000066],
              [-75.09098355099991, 10.874894685000072],
              [-75.09102345499991, 10.875067946000058],
              [-75.0909910389999 , 10.875084038000068],
              [-75.0904961409999 , 10.875252616000068],
              [-75.08940660299993, 10.875623744000052],
              [-75.08931949099991, 10.87565341700008 ],
              [-75.08927582899992, 10.875686446000088],
              [-75.0892416069999 , 10.87571233400007 ],
              [-75.08885301399994, 10.875826456000084],
              [-75.08836321799993, 10.875948053000059],
              [-75.08771682199995, 10.87611318100005 ],
              [-75.08766328399992, 10.876126268000064],
              [-75.08759070999992, 10.876177427000073],
              [-75.08755977699991, 10.87622263600008 ],
              [-75.08750728399991, 10.876268862000074],
              [-75.08696445499993, 10.87647402500005 ],
              [-75.08697235499994, 10.87652932800006 ],
              [-75.08642673599991, 10.876736038000047],
              [-75.08636747999992, 10.876742260000071],
              [-75.08630663299994, 10.876781881000056],
              [-75.0862423879999 , 10.876846126000089],
              [-75.0860611409999 , 10.876927071000068],
              [-75.0858747609999 , 10.87701030900007 ],
              [-75.08534883499993, 10.877333724000039],
              [-75.08525372199995, 10.877392213000064],
              [-75.08493701999993, 10.87763627600009 ],
              [-75.08487403899994, 10.877684813000087],
              [-75.08486467999995, 10.877692025000044],
              [-75.08466480499993, 10.877813377000052],
              [-75.08453820099993, 10.877976429000057],
              [-75.08450776099994, 10.878015632000086],
              [-75.08450145599994, 10.878033938000044],
              [-75.08449755099991, 10.878045278000059],
              [-75.08448420399992, 10.878066076000039],
              [-75.0844118679999 , 10.878187905000061],
              [-75.08450576299992, 10.878265703000068],
              [-75.08436237599994, 10.878524836000054],
              [-75.08411937699992, 10.87896027700009 ],
              [-75.08407874399995, 10.879033089000075],
              [-75.08376846199991, 10.87964032800005 ],
              [-75.08359523699994, 10.87999058400004 ],
              [-75.08352405399995, 10.880167785000083],
              [-75.08351073699993, 10.880224731000055],
              [-75.08340606999991, 10.880672300000072],
              [-75.08336561899995, 10.880867416000058],
              [-75.0833751369999 , 10.881086326000059],
              [-75.08340214399993, 10.881432371000074],
              [-75.08342963599995, 10.881592148000038],
              [-75.08346011099991, 10.88176926300008 ],
              [-75.08346645099994, 10.88190269000006 ],
              [-75.08346519799994, 10.881910103000052],
              [-75.08345661299995, 10.881949551000048],
              [-75.08344751499993, 10.881991355000082],
              [-75.08343878699992, 10.88203145500006 ],
              [-75.08343164799993, 10.882063578000043],
              [-75.08342450999993, 10.882109977000084],
              [-75.08342094099993, 10.882156377000058],
              [-75.08340666399994, 10.882202776000042],
              [-75.08338524899995, 10.882249175000084],
              [-75.08337566799992, 10.882281753000086],
              [-75.08337210899992, 10.882293852000089],
              [-75.0833674029999 , 10.882309852000049],
              [-75.08336319799992, 10.882343493000064],
              [-75.0833608449999 , 10.882356598000058],
              [-75.08333765399993, 10.882485787000064],
              [-75.08332677899995, 10.882546363000074],
              [-75.0832698879999 , 10.882584273000077],
              [-75.08324675299991, 10.882599690000063],
              [-75.08321978599992, 10.882617659000061],
              [-75.08317527799994, 10.882647318000068],
              [-75.08300531399993, 10.882756232000077],
              [-75.08269265299992, 10.88297752200009 ],
              [-75.08249790699995, 10.883156167000038],
              [-75.08234001699992, 10.883378699000048],
              [-75.0823392559999 , 10.883489308000037],
              [-75.08233510799994, 10.883494254000084],
              [-75.08227738999994, 10.883563071000083],
              [-75.08226255099993, 10.88358265800008 ],
              [-75.08218836099991, 10.88353752900008 ],
              [-75.08192769199991, 10.88392001200009 ],
              [-75.08196781799995, 10.883937672000059],
              [-75.08195364999995, 10.883957098000053],
              [-75.08196434999991, 10.883967296000037],
              [-75.08196629899993, 10.883969153000066],
              [-75.08198169399992, 10.883983826000076],
              [-75.08200765599992, 10.883996288000048],
              [-75.08201075699992, 10.88400944600005 ],
              [-75.0820234059999 , 10.884021501000063],
              [-75.08203880099995, 10.884036174000073],
              [-75.08209946499994, 10.884072744000036],
              [-75.08211830999994, 10.884176944000046],
              [-75.08211355199995, 10.884260225000048],
              [-75.08197435299991, 10.884579073000054],
              [-75.08195769699995, 10.88462428300005 ],
              [-75.08196602499993, 10.884745635000058],
              [-75.08186489799994, 10.884842003000074],
              [-75.08179589299993, 10.884943130000067],
              [-75.08162837899994, 10.88524603600007 ],
              [-75.08155985099995, 10.88535834600009 ],
              [-75.08152177999995, 10.885426875000064],
              [-75.08147228699994, 10.885657207000065],
              [-75.0814684799999 , 10.885828528000047],
              [-75.08145134799992, 10.885872310000082],
              [-75.08120769099992, 10.886085510000044],
              [-75.08077367699991, 10.886374852000074],
              [-75.0806994379999 , 10.886382466000043],
              [-75.07982033499991, 10.886365263000073],
              [-75.07950591799994, 10.886339491000058],
              [-75.07948670899992, 10.886338371000079],
              [-75.07948448099995, 10.886338609000063],
              [-75.07946955799991, 10.886334972000043],
              [-75.07944821399991, 10.886341040000048],
              [-75.07944701299994, 10.886342614000057],
              [-75.0791835469999 , 10.886355217000073],
              [-75.0790062769999 , 10.886374253000042],
              [-75.07892775499994, 10.88639209900009 ],
              [-75.07887778599991, 10.88640756600006 ],
              [-75.07867935799993, 10.886474110000051],
              [-75.0786694979999 , 10.886476282000046],
              [-75.07855544799992, 10.886375839000038],
              [-75.0783896129999 , 10.886406111000042],
              [-75.07815603599994, 10.886396665000063],
              [-75.07777246699993, 10.88636525600009 ],
              [-75.07767919199995, 10.886355738000077],
              [-75.07743172799991, 10.88635669000007 ],
              [-75.07741364399993, 10.886299583000039],
              [-75.07712144599992, 10.886317667000071],
              [-75.07681914499994, 10.886320074000082],
              [-75.07677065999991, 10.886317988000087],
              [-75.07668138499992, 10.88631278400004 ],
              [-75.0765833509999 , 10.88631278400004 ],
              [-75.07638713099993, 10.886307939000062],
              [-75.07636671999995, 10.886311548000037],
              [-75.07633717199991, 10.88630489600007 ],
              [-75.07630259899992, 10.886297113000069],
              [-75.07630194099994, 10.886304177000056],
              [-75.0762982629999 , 10.886310378000076],
              [-75.07626140899993, 10.886325259000046],
              [-75.07612944899995, 10.886303147000092],
              [-75.07612294899991, 10.88630205800007 ],
              [-75.07588385499992, 10.886261993000062],
              [-75.07587767799993, 10.886304674000087],
              [-75.07582057099995, 10.886539051000057],
              [-75.07581671399993, 10.886538458000075],
              [-75.07572743999992, 10.886524741000073],
              [-75.07566373299994, 10.886514953000074],
              [-75.07545222399995, 10.886482455000078],
              [-75.07544937499995, 10.886415319000037],
              [-75.07510283399995, 10.886348423000072],
              [-75.07509840499995, 10.886346314000093]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08421",
      "properties": {"id": "08421", "iso": "LUR", "name": "LURUACO"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-75.2214078909999 , 10.694946255000048],
              [-75.21954054699995, 10.692314243000055],
              [-75.21829020899992, 10.690157076000048],
              [-75.21630084299994, 10.687526644000059],
              [-75.21456223699994, 10.685375933000046],
              [-75.21331196999995, 10.68321872200005 ],
              [-75.21292519799994, 10.682170986000074],
              [-75.21242469599991, 10.680815161000055],
              [-75.21140227799992, 10.677447241000039],
              [-75.2099128349999 , 10.675655469000048],
              [-75.20961191799995, 10.675339377000057],
              [-75.20855037899992, 10.674224302000084],
              [-75.20655259799992, 10.673732466000047],
              [-75.20622274999994, 10.673651260000042],
              [-75.20487990299995, 10.673669007000058],
              [-75.20306668499995, 10.675021422000043],
              [-75.20174828799992, 10.676850356000045],
              [-75.20091634399995, 10.678121107000038],
              [-75.2008113139999 , 10.678255172000092],
              [-75.20072723499993, 10.678362494000055],
              [-75.20071461299995, 10.678403882000055],
              [-75.20032700899992, 10.679046923000044],
              [-75.20032134999991, 10.679056312000057],
              [-75.20010112199992, 10.679502076000063],
              [-75.20008473999991, 10.67951925500006 ],
              [-75.19983315999991, 10.679782999000054],
              [-75.19949393199994, 10.68025635400005 ],
              [-75.19918257099994, 10.68089148900009 ],
              [-75.19882989699994, 10.68141885500006 ],
              [-75.19850390099992, 10.681784249000088],
              [-75.19815117899992, 10.682298119000052],
              [-75.19782562799992, 10.682784917000049],
              [-75.1977574949999 , 10.682914415000084],
              [-75.19754119499993, 10.683325524000054],
              [-75.19732430999994, 10.683690524000042],
              [-75.19691687699992, 10.684164124000063],
              [-75.19640038199992, 10.684651605000056],
              [-75.19584266899994, 10.685058292000065],
              [-75.19513519399993, 10.685402602000067],
              [-75.19473955599994, 10.685507400000063],
              [-75.19471209199992, 10.685512088000053],
              [-75.19369000699993, 10.685686519000058],
              [-75.19338995099992, 10.685687594000058],
              [-75.19283090499994, 10.685730056000068],
              [-75.19217658299993, 10.68582682400006 ],
              [-75.19169966899995, 10.685949931000039],
              [-75.1916644609999 , 10.685967347000087],
              [-75.19133776399991, 10.68612895800004 ],
              [-75.19049220199992, 10.686403102000043],
              [-75.18994358899994, 10.686482614000056],
              [-75.1898155099999 , 10.686346365000077],
              [-75.18975466499995, 10.686281639000072],
              [-75.18956585199993, 10.686111692000054],
              [-75.18922055099995, 10.686035362000041],
              [-75.18880636199992, 10.686020453000083],
              [-75.18867695799992, 10.686015793000081],
              [-75.18843630299995, 10.686007130000064],
              [-75.1873311359999 , 10.686001031000046],
              [-75.18662164599994, 10.686170480000044],
              [-75.18614751899992, 10.68625673300005 ],
              [-75.18596353599992, 10.686283367000044],
              [-75.1857292919999 , 10.68630154400006 ],
              [-75.18535637399992, 10.686294531000044],
              [-75.18509955199994, 10.68633630000005 ],
              [-75.18502804399992, 10.686330360000056],
              [-75.18477837799992, 10.686285145000056],
              [-75.18463811899994, 10.686266241000055],
              [-75.18456711399995, 10.686266532000047],
              [-75.18442403299991, 10.686282469000048],
              [-75.1843717029999 , 10.68628099600005 ],
              [-75.18432200199993, 10.686267132000069],
              [-75.18417624499995, 10.686201569000048],
              [-75.18410933899992, 10.686178424000047],
              [-75.18389677099992, 10.686148210000056],
              [-75.18384410599992, 10.68614517900005 ],
              [-75.18379361899991, 10.686150338000061],
              [-75.18374637099993, 10.686170812000057],
              [-75.18367162599992, 10.686220477000063],
              [-75.18355538199995, 10.68630739100007 ],
              [-75.18350578799993, 10.686356896000063],
              [-75.18347430999995, 10.686416932000043],
              [-75.18340386599994, 10.686613875000091],
              [-75.18336998899991, 10.686669709000057],
              [-75.18331425199995, 10.68670701600007 ],
              [-75.18313472499995, 10.68678613500009 ],
              [-75.18310625499993, 10.68680547300005 ],
              [-75.18307201699992, 10.686841921000052],
              [-75.18300526799993, 10.686942195000086],
              [-75.18294528899992, 10.687047395000093],
              [-75.18292077399991, 10.687111179000055],
              [-75.18286869699995, 10.68729144100007 ],
              [-75.18284783299993, 10.687334298000053],
              [-75.18282680499993, 10.687356778000037],
              [-75.18275259099994, 10.687394295000047],
              [-75.18255155499992, 10.687460585000053],
              [-75.1824822289999 , 10.68747174300006 ],
              [-75.18228687499993, 10.687479119000045],
              [-75.18223520899994, 10.687491513000055],
              [-75.1821689379999 , 10.687519137000038],
              [-75.18212241799995, 10.687545967000062],
              [-75.18208082599995, 10.687579589000052],
              [-75.1818438439999 , 10.68781696700006 ],
              [-75.18177918399994, 10.687869196000065],
              [-75.18174935099995, 10.68788244700005 ],
              [-75.18169940599995, 10.68789249200006 ],
              [-75.1814367959999 , 10.687915583000063],
              [-75.18137136699994, 10.687930667000046],
              [-75.18132567899994, 10.687948633000076],
              [-75.18128392599994, 10.687977983000053],
              [-75.18123325999994, 10.688026053000044],
              [-75.1811746759999 , 10.68809202400007 ],
              [-75.1811005209999 , 10.68818890700004 ],
              [-75.18106424599995, 10.688215372000059],
              [-75.18101868999992, 10.688217744000042],
              [-75.1809211719999 , 10.688195619000055],
              [-75.18090356899995, 10.688171383000054],
              [-75.18085594999991, 10.688105822000068],
              [-75.1808086289999 , 10.68806064000006 ],
              [-75.18062912999994, 10.687972313000046],
              [-75.18057061799993, 10.687934064000046],
              [-75.18052236699992, 10.687885203000064],
              [-75.18045709699993, 10.687804791000076],
              [-75.18043032599991, 10.687761977000036],
              [-75.18041416599993, 10.687713750000057],
              [-75.18039593399993, 10.687518909000062],
              [-75.18038494299992, 10.687468993000039],
              [-75.18037005699995, 10.687439854000047],
              [-75.18033657899991, 10.687402271000053],
              [-75.18025517899991, 10.687335180000048],
              [-75.18018100199993, 10.687287751000042],
              [-75.18006733899995, 10.687240681000048],
              [-75.1799011999999 , 10.687181701000043],
              [-75.17974695799995, 10.687155158000053],
              [-75.17969603499995, 10.68715228700006 ],
              [-75.17964755499992, 10.687157975000048],
              [-75.17958949199993, 10.68718791300006 ],
              [-75.1793833719999 , 10.687345033000042],
              [-75.17916265199995, 10.687450244000047],
              [-75.17908159599995, 10.687482337000063],
              [-75.17903148699992, 10.68749531800006 ],
              [-75.1789114419999 , 10.687512594000054],
              [-75.17882620699993, 10.687517497000044],
              [-75.17876082399994, 10.687504559000047],
              [-75.17852691799993, 10.68738518300006 ],
              [-75.17834721999992, 10.687323516000049],
              [-75.17826166399993, 10.687309283000047],
              [-75.17806471699993, 10.687306057000058],
              [-75.17792177599995, 10.687283960000059],
              [-75.17778151999994, 10.68725051000007 ],
              [-75.17748280399991, 10.68713404400006 ],
              [-75.17741046599991, 10.68712254400009 ],
              [-75.17725105499994, 10.687111776000052],
              [-75.17705666799992, 10.68725390800006 ],
              [-75.17687569499992, 10.687292927000044],
              [-75.1766301749999 , 10.687370563000059],
              [-75.17642337399991, 10.687422470000058],
              [-75.1761520259999 , 10.687512989000084],
              [-75.17594591499994, 10.68775682100005 ],
              [-75.17576540399995, 10.68792379100006 ],
              [-75.17558544199994, 10.688244301000054],
              [-75.17541823199991, 10.688513586000056],
              [-75.17522418099992, 10.688514268000063],
              [-75.17503013399994, 10.688514951000059],
              [-75.17479727299991, 10.688515768000059],
              [-75.1744610579999 , 10.688555335000046],
              [-75.1743305299999 , 10.688582195000038],
              [-75.1743290579999 , 10.688582340000039],
              [-75.17418987699995, 10.688606656000047],
              [-75.17403192699993, 10.688649790000056],
              [-75.17389075499995, 10.688727848000042],
              [-75.1737354249999 , 10.688808344000051],
              [-75.17362488499992, 10.688876849000053],
              [-75.17349328699993, 10.68897353500006 ],
              [-75.17335701099995, 10.689077263000058],
              [-75.1731995589999 , 10.68919281300009 ],
              [-75.17307052799993, 10.68931985100005 ],
              [-75.17297668399993, 10.689411605000089],
              [-75.1727887369999 , 10.68955773500005 ],
              [-75.17262641399992, 10.689652293000051],
              [-75.17239319599992, 10.689740330000063],
              [-75.17219974299991, 10.689772022000056],
              [-75.17185537299991, 10.689839795000069],
              [-75.1716903109999 , 10.689878303000057],
              [-75.1714612689999 , 10.68988687600006 ],
              [-75.17127234599991, 10.68989050500005 ],
              [-75.17098670399992, 10.689911149000068],
              [-75.1707979869999 , 10.68994514700006 ],
              [-75.17069439099993, 10.689992578000044],
              [-75.1705373019999 , 10.690161859000057],
              [-75.17041259599995, 10.690230461000056],
              [-75.17023555899993, 10.69024568900005 ],
              [-75.17010315999994, 10.690225569000063],
              [-75.16992119199995, 10.69021046100005 ],
              [-75.16983854799992, 10.690213358000051],
              [-75.16961716499992, 10.690265899000053],
              [-75.16945956899991, 10.690332310000088],
              [-75.1692757909999 , 10.69036389900009 ],
              [-75.16911015999995, 10.690290363000088],
              [-75.16899871599992, 10.69020712300005 ],
              [-75.16891781099991, 10.690105005000078],
              [-75.16886739299991, 10.689977016000057],
              [-75.16872294199993, 10.68989633700005 ],
              [-75.1686215769999 , 10.689906360000066],
              [-75.16851117699991, 10.689974784000071],
              [-75.1684243329999 , 10.690038377000064],
              [-75.16820942299995, 10.690002510000056],
              [-75.16800868199994, 10.689968885000042],
              [-75.16785046399991, 10.689944294000043],
              [-75.16770867699995, 10.68990793100005 ],
              [-75.16760196699994, 10.68982698900004 ],
              [-75.16747625799991, 10.689727511000058],
              [-75.16745207899993, 10.689641344000052],
              [-75.16750560999992, 10.689535976000059],
              [-75.16758306299994, 10.689479447000053],
              [-75.16763924499992, 10.689416064000056],
              [-75.16761979799992, 10.689332193000041],
              [-75.16755315699993, 10.689246315000048],
              [-75.16738237599992, 10.689109808000069],
              [-75.16729259599992, 10.689089422000052],
              [-75.16718654399995, 10.689104151000038],
              [-75.16711133399991, 10.689144328000054],
              [-75.16698912499993, 10.689210495000054],
              [-75.16690919699994, 10.689250712000046],
              [-75.16681256299995, 10.689263036000057],
              [-75.16675316099992, 10.689200439000047],
              [-75.1667456209999 , 10.689132825000058],
              [-75.16676174699995, 10.689076712000087],
              [-75.1667612629999 , 10.689006716000051],
              [-75.16673952499991, 10.688932196000053],
              [-75.1666374049999 , 10.688832559000048],
              [-75.1665238459999 , 10.688784333000058],
              [-75.16642475899994, 10.688782672000059],
              [-75.16632109799991, 10.68880204900006 ],
              [-75.16610233599994, 10.688892208000084],
              [-75.16590189899995, 10.688902909000092],
              [-75.16581205799991, 10.688873189000049],
              [-75.16562237099993, 10.688732148000042],
              [-75.16544923399994, 10.688595658000054],
              [-75.16534093599995, 10.688626730000067],
              [-75.16528710099993, 10.68868776700009 ],
              [-75.16527127699993, 10.688788207000073],
              [-75.16526927199993, 10.688839557000051],
              [-75.1653383499999 , 10.688937086000067],
              [-75.1653889079999 , 10.689086079000049],
              [-75.16537752999994, 10.68914682400009 ],
              [-75.16534017599992, 10.689203079000038],
              [-75.1652719789999 , 10.689233879000085],
              [-75.16516570199991, 10.68921593500005 ],
              [-75.1650661189999 , 10.689141947000053],
              [-75.16492595499994, 10.688998234000053],
              [-75.16482598899995, 10.688868249000052],
              [-75.16474489099994, 10.688738130000047],
              [-75.16460029899991, 10.688636449000057],
              [-75.1645033449999 , 10.688602109000044],
              [-75.16431175399993, 10.688526412000044],
              [-75.1641462039999 , 10.68846454000004 ],
              [-75.16396409399994, 10.688395782000043],
              [-75.16380558599991, 10.688329195000051],
              [-75.16369652199995, 10.688248268000052],
              [-75.16341871499992, 10.687984155000038],
              [-75.1633192409999 , 10.68792649900007 ],
              [-75.16318946199993, 10.687920384000051],
              [-75.16305025999992, 10.687916664000056],
              [-75.16298532799993, 10.687869547000048],
              [-75.16294831899995, 10.68784269200006 ],
              [-75.16289159399992, 10.687835662000055],
              [-75.16276203899992, 10.687814769000056],
              [-75.16267569099995, 10.687804396000047],
              [-75.1625458769999 , 10.687708806000046],
              [-75.16241602199995, 10.68760254600005 ],
              [-75.16231850299994, 10.687485498000058],
              [-75.16223187899993, 10.687400427000057],
              [-75.16214537999991, 10.687347370000055],
              [-75.16199391099991, 10.687230510000063],
              [-75.16188575199993, 10.687156185000049],
              [-75.16180999799991, 10.687092420000056],
              [-75.16175597599994, 10.687071265000043],
              [-75.16162642699993, 10.687050372000044],
              [-75.16147514499994, 10.686986870000055],
              [-75.16139950899992, 10.68695511900006 ],
              [-75.16124845599995, 10.686955640000065],
              [-75.16106465799993, 10.686849564000056],
              [-75.16067487799995, 10.686466747000054],
              [-75.16010190599991, 10.686148592000052],
              [-75.1600486769999 , 10.68635153200006 ],
              [-75.15966188399995, 10.686811742000089],
              [-75.15940316399991, 10.686876667000092],
              [-75.1590577899999 , 10.686845850000054],
              [-75.15889628799994, 10.68694245000006 ],
              [-75.15837673499993, 10.686474707000059],
              [-75.15818275299995, 10.686539407000055],
              [-75.15783666599992, 10.687124595000057],
              [-75.15783000399995, 10.687135857000044],
              [-75.15779374399995, 10.687093122000078],
              [-75.15777583199991, 10.68707201300009 ],
              [-75.15772912999995, 10.687045745000091],
              [-75.15696500299993, 10.686615949000043],
              [-75.1565656439999 , 10.68657464000006 ],
              [-75.1559605679999 , 10.68633128600004 ],
              [-75.15557109699995, 10.686033835000046],
              [-75.15534255999995, 10.685479709000049],
              [-75.15502995199995, 10.685177985000053],
              [-75.15494286799992, 10.685228076000044],
              [-75.1548199799999 , 10.68529876100007 ],
              [-75.1547283619999 , 10.685306049000076],
              [-75.15472869299992, 10.685310110000046],
              [-75.15468408699991, 10.685317049000048],
              [-75.15463574599994, 10.685324569000045],
              [-75.15338957099993, 10.685518425000055],
              [-75.14534803899994, 10.68676938100009 ],
              [-75.14365237899995, 10.687033161000045],
              [-75.14306763799993, 10.687124125000082],
              [-75.1330189439999 , 10.688687318000063],
              [-75.11337124799991, 10.691743751000047],
              [-75.1129600399999 , 10.691807719000053],
              [-75.10316947599995, 10.691389765000054],
              [-75.10268327799992, 10.690912083000057],
              [-75.10234520999995, 10.690280573000052],
              [-75.10215378399994, 10.689624682000044],
              [-75.10215275799993, 10.689319245000092],
              [-75.1021505679999 , 10.688665895000042],
              [-75.10185610699995, 10.688316665000059],
              [-75.10164104599994, 10.688092100000063],
              [-75.1015085379999 , 10.687953737000043],
              [-75.10049637899994, 10.686896843000056],
              [-75.09963738199991, 10.68661686200005 ],
              [-75.0989967989999 , 10.686408069000038],
              [-75.09811603999992, 10.686120987000038],
              [-75.0972104139999 , 10.686050309000052],
              [-75.0964456129999 , 10.685990624000056],
              [-75.0954269689999 , 10.68591112100006 ],
              [-75.09529183699993, 10.685900573000083],
              [-75.0945934529999 , 10.685846062000053],
              [-75.0944473159999 , 10.68582654100004 ],
              [-75.09417888099995, 10.685817537000048],
              [-75.09392480299994, 10.685831678000056],
              [-75.09368479599993, 10.685841025000059],
              [-75.09351540699993, 10.685849674000053],
              [-75.09335034499992, 10.685821021000038],
              [-75.09311737899992, 10.685827969000059],
              [-75.09289594699993, 10.685811517000047],
              [-75.0926933639999 , 10.68579720300005 ],
              [-75.0924580699999 , 10.685806502000048],
              [-75.0921871189999 , 10.685781218000045],
              [-75.09192807199992, 10.685769791000041],
              [-75.09173504799992, 10.685769358000073],
              [-75.09149017699991, 10.685762448000048],
              [-75.09126417499994, 10.68576001300005 ],
              [-75.09093452499991, 10.68574927800006 ],
              [-75.09062341899994, 10.68571041100006 ],
              [-75.09045849799992, 10.685695733000045],
              [-75.09026773399995, 10.685685961000047],
              [-75.09004860899995, 10.685664821000046],
              [-75.08985087799994, 10.685664435000092],
              [-75.08955664599995, 10.685664993000046],
              [-75.08937764699994, 10.68565510700006 ],
              [-75.08915885399995, 10.68566657100007 ],
              [-75.08887862199992, 10.685655349000058],
              [-75.08865255799992, 10.685645922000049],
              [-75.0884687379999 , 10.685624437000058],
              [-75.08820058099991, 10.685643370000037],
              [-75.08789912399993, 10.685627701000044],
              [-75.0874045299999 , 10.68559994800006 ],
              [-75.08693836299994, 10.685590546000071],
              [-75.08635688999993, 10.685584604000042],
              [-75.08580587499995, 10.68556439100007 ],
              [-75.0852879279999 , 10.685555496000063],
              [-75.08472982299992, 10.685533020000037],
              [-75.08408711299995, 10.685523018000083],
              [-75.08354553499993, 10.685505036000052],
              [-75.08270925799991, 10.685441035000053],
              [-75.0819909679999 , 10.685406149000073],
              [-75.08145615599994, 10.685375311000087],
              [-75.08133009099993, 10.68536804100006 ],
              [-75.0805754509999 , 10.685324526000045],
              [-75.0794406359999 , 10.68530069700006 ],
              [-75.07835265499995, 10.685253122000063],
              [-75.07679201899992, 10.685165503000064],
              [-75.07673954599994, 10.685162558000059],
              [-75.0766861219999 , 10.685180929000069],
              [-75.07589044399992, 10.685142375000055],
              [-75.07438982999992, 10.685098789000051],
              [-75.0726464359999 , 10.685034263000091],
              [-75.07213707799991, 10.685017716000061],
              [-75.07143317599991, 10.684994844000073],
              [-75.06984776699994, 10.684949733000053],
              [-75.06804509799991, 10.684846150000055],
              [-75.06747857899995, 10.684832921000066],
              [-75.06677777699991, 10.684816561000048],
              [-75.06562303699991, 10.684741599000063],
              [-75.0642022049999 , 10.684685989000059],
              [-75.06420256399991, 10.684677110000052],
              [-75.06332236199995, 10.684645754000087],
              [-75.06319545799994, 10.68435773400006 ],
              [-75.06317919599991, 10.684262021000052],
              [-75.06313188199994, 10.684159438000052],
              [-75.06300433599995, 10.684035957000049],
              [-75.06290517999992, 10.68397542200006 ],
              [-75.0627988629999 , 10.683851916000037],
              [-75.06274438799994, 10.683684028000073],
              [-75.06268513399993, 10.683471824000037],
              [-75.06261415099993, 10.683310953000046],
              [-75.06251737299993, 10.683262083000045],
              [-75.06234506899995, 10.683185305000052],
              [-75.0621727589999 , 10.683103861000063],
              [-75.06200268499992, 10.682929109000042],
              [-75.06194114099992, 10.682770558000072],
              [-75.06194078899995, 10.68250230600006 ],
              [-75.06195918799995, 10.682140720000064],
              [-75.06191152399992, 10.681774546000042],
              [-75.0618403979999 , 10.681499374000055],
              [-75.06172664499991, 10.681100624000067],
              [-75.06178523799991, 10.680811302000052],
              [-75.06192410499995, 10.680575547000046],
              [-75.06219968399995, 10.680260315000055],
              [-75.06246583699993, 10.679954426000052],
              [-75.06257180099993, 10.679812008000056],
              [-75.0626493279999 , 10.679571653000039],
              [-75.06265377199992, 10.679361708000044],
              [-75.06252360199994, 10.679035290000058],
              [-75.06246204999991, 10.678867408000087],
              [-75.0624805089999 , 10.678554809000047],
              [-75.0624777669999 , 10.678263227000059],
              [-75.0624703119999 , 10.677976318000049],
              [-75.0624369759999 , 10.677738428000055],
              [-75.06247427099993, 10.67740014800006 ],
              [-75.06253295399995, 10.67717847700004 ],
              [-75.0625389889999 , 10.677032215000054],
              [-75.06254209799994, 10.676956861000065],
              [-75.06249936499995, 10.676749305000044],
              [-75.06240935299991, 10.67646482500004 ],
              [-75.06244901499991, 10.676131210000051],
              [-75.06246295999995, 10.675972570000056],
              [-75.06237779999992, 10.675793055000042],
              [-75.06229509199994, 10.675678850000054],
              [-75.0622238389999 , 10.675308040000061],
              [-75.06218341899995, 10.675065492000044],
              [-75.0621431429999 , 10.674934908000068],
              [-75.06210506199994, 10.674673691000066],
              [-75.06204349799992, 10.674496482000052],
              [-75.06191107099994, 10.67424470700007 ],
              [-75.06189176599992, 10.673915824000062],
              [-75.06199757399992, 10.673654443000089],
              [-75.0620844849999 , 10.673374421000062],
              [-75.06202764599993, 10.67320187200005 ],
              [-75.0619000929999 , 10.673071393000043],
              [-75.06167816399994, 10.672920025000053],
              [-75.06141381299994, 10.672799032000057],
              [-75.06117073099995, 10.672708341000089],
              [-75.06092986999994, 10.672515009000051],
              [-75.0608575469999 , 10.67242213500009 ],
              [-75.06080464399992, 10.67235419800005 ],
              [-75.06058025099992, 10.672123523000039],
              [-75.06041495099993, 10.67198608800004 ],
              [-75.06010562099993, 10.67183943300006 ],
              [-75.05987880599992, 10.67174394600005 ],
              [-75.05966859999995, 10.671709158000056],
              [-75.05957888499995, 10.671709260000057],
              [-75.05945371899992, 10.671709405000058],
              [-75.05929359299995, 10.671730063000041],
              [-75.05902451399993, 10.671718699000053],
              [-75.05883097899994, 10.671725924000043],
              [-75.05863032999991, 10.67170281600005 ],
              [-75.05828799699992, 10.67163084200007 ],
              [-75.05808965799991, 10.671572716000071],
              [-75.05798800399992, 10.671449118000055],
              [-75.05784599599991, 10.671152827000071],
              [-75.0577726649999 , 10.67103153000005 ],
              [-75.05772526999993, 10.67088919400004 ],
              [-75.05771320699995, 10.670688461000054],
              [-75.05769415799995, 10.67056243400009 ],
              [-75.05757363099991, 10.67044585900004 ],
              [-75.05742260599993, 10.670471708000036],
              [-75.05731174599993, 10.670523188000061],
              [-75.05719604399991, 10.670485974000087],
              [-75.05718404399994, 10.670336595000038],
              [-75.05727119199992, 10.670198774000085],
              [-75.05733943799993, 10.670046970000044],
              [-75.05737237699992, 10.669969901000059],
              [-75.0573509219999 , 10.669806527000048],
              [-75.0572752409999 , 10.66969223700005 ],
              [-75.05717829999992, 10.669561627000064],
              [-75.05705064099993, 10.669405376000043],
              [-75.05702210099992, 10.669244344000049],
              [-75.05688976899995, 10.66912078200005 ],
              [-75.05679524199991, 10.66903452300005 ],
              [-75.05672659699991, 10.668882873000086],
              [-75.05666272499991, 10.668775569000047],
              [-75.05660124099995, 10.668684603000088],
              [-75.05646645999991, 10.668498017000047],
              [-75.0563762459999 , 10.668096629000047],
              [-75.05635100099994, 10.66804568200007 ],
              [-75.05635543699992, 10.66783325700004 ],
              [-75.0563999439999 , 10.667576437000037],
              [-75.05636654299991, 10.667303367000045],
              [-75.0563238599999 , 10.667151687000057],
              [-75.05623140499995, 10.666843670000048],
              [-75.05618855799992, 10.666570612000044],
              [-75.05609376199993, 10.666274268000052],
              [-75.05612640499993, 10.665970776000052],
              [-75.05612387899993, 10.66584472900007 ],
              [-75.05604105599991, 10.66568142500006 ],
              [-75.05592052799994, 10.665564850000067],
              [-75.05575987199995, 10.665438983000058],
              [-75.05566054499991, 10.66533865200006 ],
              [-75.05563202699994, 10.66520786600006 ],
              [-75.05564600199995, 10.665060681000057],
              [-75.0557141399999 , 10.664794293000057],
              [-75.0558083119999 , 10.664567594000061],
              [-75.05596395599991, 10.664387541000053],
              [-75.0561266599999 , 10.664188792000061],
              [-75.0562492649999 , 10.664029805000041],
              [-75.05631032999992, 10.663772771000083],
              [-75.05640439199993, 10.663464308000073],
              [-75.0565364599999 , 10.663316989000066],
              [-75.05674409799991, 10.663162577000037],
              [-75.05693510799995, 10.662938097000051],
              [-75.05721310099995, 10.662409835000062],
              [-75.05728605899992, 10.66221586100005 ],
              [-75.05727630399991, 10.661986943000045],
              [-75.05725469599992, 10.661727671000051],
              [-75.05732068299994, 10.66161780100009 ],
              [-75.05741985299994, 10.661598997000056],
              [-75.05760411099993, 10.661619814000062],
              [-75.0577409739999 , 10.661528550000071],
              [-75.0578729579999 , 10.66131815600005 ],
              [-75.05793893499992, 10.661203618000059],
              [-75.05802152599995, 10.661147460000052],
              [-75.05819642199992, 10.66123602600004 ],
              [-75.0582791619999 , 10.661291997000092],
              [-75.05838542399994, 10.66127552000006 ],
              [-75.05844673299993, 10.661203034000039],
              [-75.05848420099994, 10.660962380000058],
              [-75.05851935899994, 10.660761444000059],
              [-75.0585023829999 , 10.660432083000046],
              [-75.0584690899999 , 10.660263929000052],
              [-75.05841692599995, 10.660112144000038],
              [-75.05834108099992, 10.65991600600006 ],
              [-75.0583161159999 , 10.659794331000057],
              [-75.05832284799993, 10.65952801900005 ],
              [-75.05833439299994, 10.659331776000045],
              [-75.05828223799995, 10.659187005000092],
              [-75.05826545199994, 10.659000138000067],
              [-75.05823465299994, 10.658927757000072],
              [-75.05813290799995, 10.65879005000005 ],
              [-75.05808789899993, 10.658689654000057],
              [-75.0580995979999 , 10.658607878000055],
              [-75.05820336199992, 10.658490957000083],
              [-75.05824807699992, 10.658371769000041],
              [-75.0582408219999 , 10.658245631000057],
              [-75.05829717599994, 10.65800028700005 ],
              [-75.05839145899995, 10.657862351000063],
              [-75.05845979999992, 10.657745472000045],
              [-75.0585115909999 , 10.65761926500005 ],
              [-75.05859184799993, 10.657586472000048],
              [-75.0587076249999 , 10.657621378000044],
              [-75.05891307299993, 10.657597782000039],
              [-75.0589814999999 , 10.657548648000045],
              [-75.05921274399992, 10.657391866000069],
              [-75.0592810359999 , 10.657242284000063],
              [-75.05948396499991, 10.657099555000059],
              [-75.05966094299993, 10.656984884000053],
              [-75.05986779799991, 10.65680099900004 ],
              [-75.05993458599994, 10.656741624000063],
              [-75.0602107659999 , 10.656626839000069],
              [-75.0603027759999 , 10.656551981000064],
              [-75.0603899429999 , 10.65638602100006 ],
              [-75.06034964999992, 10.656280948000074],
              [-75.0603259589999 , 10.65622724700006 ],
              [-75.06018897399991, 10.656229741000061],
              [-75.06011586799991, 10.65630924900006 ],
              [-75.06004038799995, 10.656384088000038],
              [-75.05991995399995, 10.65639824300007 ],
              [-75.05986798099991, 10.65638662400005 ],
              [-75.05980875599994, 10.656253538000044],
              [-75.05983455699993, 10.656120354000052],
              [-75.05990051399993, 10.65599179700007 ],
              [-75.06006569899995, 10.655886486000043],
              [-75.06024509899993, 10.655813863000049],
              [-75.06032987599991, 10.655629219000048],
              [-75.0603250229999 , 10.65553344600005 ],
              [-75.06030594499993, 10.655397984000047],
              [-75.06033172699995, 10.655250782000053],
              [-75.06044488499992, 10.65509647400006 ],
              [-75.06059105199995, 10.654902414000048],
              [-75.0607655199999 , 10.654673284000069],
              [-75.06093070399993, 10.654567971000063],
              [-75.06110302799993, 10.654504702000054],
              [-75.06126843499993, 10.654562910000038],
              [-75.06150717699995, 10.654709805000039],
              [-75.06164418599991, 10.654730670000049],
              [-75.06170154599994, 10.654679891000058],
              [-75.0617102409999 , 10.654672193000067],
              [-75.06169124899992, 10.654599799000039],
              [-75.06166261499993, 10.654387254000085],
              [-75.06168844899992, 10.654279767000048],
              [-75.06176384399993, 10.654141852000066],
              [-75.06185829399993, 10.654125394000062],
              [-75.06202365199994, 10.654148560000067],
              [-75.06217000099991, 10.654087656000058],
              [-75.06234229699993, 10.654003359000058],
              [-75.06251457399992, 10.65390504800007 ],
              [-75.06250260899992, 10.653788260000056],
              [-75.06247417199995, 10.653718212000058],
              [-75.06242683199991, 10.653641177000054],
              [-75.0623888849999 , 10.653524419000064],
              [-75.06232500699991, 10.653445068000053],
              [-75.06225638699993, 10.653349371000047],
              [-75.06223974699992, 10.653269966000039],
              [-75.0621992849999 , 10.653041084000051],
              [-75.06224399199994, 10.652917221000052],
              [-75.06234569599991, 10.65281787400005 ],
              [-75.06236023399993, 10.652803672000061],
              [-75.06236192799992, 10.652802016000066],
              [-75.06248877899992, 10.652737517000048],
              [-75.06262589499994, 10.652661384000055],
              [-75.0627368989999 , 10.652574948000051],
              [-75.0627388389999 , 10.652526942000065],
              [-75.06274708799992, 10.65245722900005 ],
              [-75.06282365899995, 10.65242201600006 ],
              [-75.06285606799992, 10.652342136000073],
              [-75.0627724279999 , 10.652246328000047],
              [-75.06266584499991, 10.652164180000057],
              [-75.06250931099993, 10.652050884000062],
              [-75.06248244499994, 10.651982900000064],
              [-75.06248863699994, 10.651911977000054],
              [-75.0625043899999 , 10.651731560000087],
              [-75.0625336949999 , 10.651568608000048],
              [-75.06260018899991, 10.651397131000067],
              [-75.06260682499993, 10.65121156300006 ],
              [-75.06258389699991, 10.651093554000056],
              [-75.06255396599994, 10.650918844000046],
              [-75.06252043199993, 10.650805030000072],
              [-75.06252913799995, 10.650652797000077],
              [-75.0625251649999 , 10.650471418000052],
              [-75.0625295669999 , 10.650300495000067],
              [-75.06256467499992, 10.650124619000053],
              [-75.06257247899993, 10.649937868000052],
              [-75.06262188699992, 10.649640138000052],
              [-75.06265295399993, 10.649408701000084],
              [-75.06272890899993, 10.649237724000045],
              [-75.0627391079999 , 10.649187304000066],
              [-75.06272168899994, 10.649157032000062],
              [-75.0626869319999 , 10.649105260000056],
              [-75.06265263099993, 10.649038265000058],
              [-75.06256050099995, 10.648918538000089],
              [-75.06249467999993, 10.648698502000059],
              [-75.06241811099994, 10.64859911800005 ],
              [-75.06227108299993, 10.648385577000056],
              [-75.0623281039999 , 10.648342393000064],
              [-75.06238915599994, 10.648296157000061],
              [-75.06245301299992, 10.64816157000007 ],
              [-75.06248612299993, 10.648027261000038],
              [-75.06245248599993, 10.647968455000068],
              [-75.06240418199991, 10.64795014200007 ],
              [-75.0623960719999 , 10.647947069000054],
              [-75.06238009799995, 10.64794101700005 ],
              [-75.06230013699991, 10.647985199000061],
              [-75.06228355199994, 10.647867685000051],
              [-75.06266360799992, 10.647139069000048],
              [-75.06289914199994, 10.64567863700006 ],
              [-75.06221618099994, 10.644697955000083],
              [-75.06158501999994, 10.643561925000085],
              [-75.06121615199993, 10.642683711000075],
              [-75.06079648499991, 10.642271202000074],
              [-75.0594339129999 , 10.641344372000049],
              [-75.0594287589999 , 10.641340717000048],
              [-75.05931199499992, 10.641026071000056],
              [-75.0592415879999 , 10.640924595000058],
              [-75.05900474799995, 10.640632293000067],
              [-75.05883895999995, 10.640548319000061],
              [-75.0586493109999 , 10.640676122000059],
              [-75.05868601399993, 10.640813612000045],
              [-75.05849050599994, 10.640674862000083],
              [-75.05801574299994, 10.63938315200005 ],
              [-75.05769110699993, 10.638703083000053],
              [-75.0572625879999 , 10.638471681000055],
              [-75.05713202499993, 10.638732023000046],
              [-75.05705223899992, 10.63867321400005 ],
              [-75.05701989599993, 10.638515828000038],
              [-75.05688889299995, 10.638428514000054],
              [-75.05681617999994, 10.638288109000086],
              [-75.05667763499991, 10.638174487000072],
              [-75.05648261099992, 10.638097582000057],
              [-75.0562942439999 , 10.637948768000058],
              [-75.05608287799993, 10.637259722000067],
              [-75.0555231269999 , 10.636640731000057],
              [-75.05496490999991, 10.63650453300005 ],
              [-75.05468630799993, 10.635957641000061],
              [-75.0546908689999 , 10.635957254000061],
              [-75.0548907239999 , 10.635541585000055],
              [-75.05506850099994, 10.635114397000052],
              [-75.0550200479999 , 10.635074172000088],
              [-75.0550300239999 , 10.635055949000048],
              [-75.05481734799992, 10.633953079000037],
              [-75.05432622899991, 10.63298902300005 ],
              [-75.05432344199994, 10.632112774000063],
              [-75.0543572819999 , 10.631882276000056],
              [-75.05444115899991, 10.631209205000062],
              [-75.05442737299995, 10.631221193000044],
              [-75.05543275899993, 10.630157781000037],
              [-75.05554694599994, 10.629466639000043],
              [-75.05555915699995, 10.62945972700004 ],
              [-75.05554496099995, 10.629403541000045],
              [-75.05557083199994, 10.629008147000093],
              [-75.0555901319999 , 10.628586992000066],
              [-75.05570595499995, 10.628168606000088],
              [-75.05568972999993, 10.627743997000039],
              [-75.05570187799992, 10.627416407000055],
              [-75.05567340899995, 10.627420894000068],
              [-75.05556916699993, 10.627405259000057],
              [-75.05502453699995, 10.62745361900005 ],
              [-75.05410151899991, 10.62748739500006 ],
              [-75.05415746699992, 10.627392212000075],
              [-75.05431918199992, 10.626522170000044],
              [-75.05443501199994, 10.625897054000063],
              [-75.05443577599993, 10.625892928000042],
              [-75.05431832999994, 10.625510116000044],
              [-75.05433186399995, 10.625494888000048],
              [-75.05441169599993, 10.625405091000061],
              [-75.05448200399991, 10.62500250100004 ],
              [-75.05448761899993, 10.624524448000045],
              [-75.05460415699991, 10.624144037000065],
              [-75.05469951099991, 10.624036751000062],
              [-75.05443650599994, 10.62381558900006 ],
              [-75.05366806599994, 10.623335183000052],
              [-75.05373274599992, 10.621748659000048],
              [-75.05484517299993, 10.620779610000056],
              [-75.05511767799993, 10.61907410200007 ],
              [-75.05515304099993, 10.61885277500005 ],
              [-75.05518696699994, 10.618640455000047],
              [-75.05637132499993, 10.615968795000072],
              [-75.05638394699992, 10.615958454000065],
              [-75.05639302799995, 10.615902935000065],
              [-75.0564770869999 , 10.615554595000049],
              [-75.05666809599995, 10.61536434900006 ],
              [-75.05682717399992, 10.615205221000053],
              [-75.05701772099991, 10.615083557000048],
              [-75.05690837999992, 10.614608934000046],
              [-75.05681155499991, 10.614479546000041],
              [-75.05680528799991, 10.614465375000066],
              [-75.05684416299994, 10.613600442000063],
              [-75.0564201599999 , 10.611808525000072],
              [-75.05745228899991, 10.60752913400006 ],
              [-75.05745119099993, 10.607184283000038],
              [-75.05956972799993, 10.597575708000079],
              [-75.06015192299992, 10.594935173000067],
              [-75.06232684499992, 10.597024868000062],
              [-75.06381185499993, 10.598451658000045],
              [-75.06511115399991, 10.599699993000058],
              [-75.0672544279999 , 10.601176749000047],
              [-75.06772409099995, 10.601399014000037],
              [-75.06772511299994, 10.601399498000092],
              [-75.06916812299994, 10.602060830000084],
              [-75.06940141399991, 10.602167749000046],
              [-75.0705225879999 , 10.602681586000074],
              [-75.07134654299995, 10.602993808000065],
              [-75.07171994799995, 10.603135302000055],
              [-75.0719923719999 , 10.603238530000056],
              [-75.0735147449999 , 10.603815401000077],
              [-75.0756661879999 , 10.604621375000079],
              [-75.0766426749999 , 10.60504700100006 ],
              [-75.0772663759999 , 10.605268314000057],
              [-75.07804379399994, 10.605560593000064],
              [-75.0792099759999 , 10.606012405000058],
              [-75.0808100829999 , 10.60663252900008 ],
              [-75.0832959629999 , 10.607540913000037],
              [-75.08344944999993, 10.607540421000067],
              [-75.0860099919999 , 10.603703901000074],
              [-75.08708819999993, 10.602119421000054],
              [-75.0882919949999 , 10.600293367000063],
              [-75.0892354959999 , 10.598932623000053],
              [-75.08924219199992, 10.598907109000038],
              [-75.08932465899994, 10.598592908000057],
              [-75.0895914859999 , 10.598322563000067],
              [-75.08982395899994, 10.598118022000051],
              [-75.08997943499992, 10.597981226000059],
              [-75.09028023099995, 10.597712388000048],
              [-75.09064288099995, 10.59743187500004 ],
              [-75.0908973409999 , 10.597186147000059],
              [-75.09111332899994, 10.597001772000056],
              [-75.0914990039999 , 10.596667609000065],
              [-75.09175739499995, 10.596441004000042],
              [-75.09205811599992, 10.596149209000089],
              [-75.09233970299994, 10.595918700000084],
              [-75.0926211609999 , 10.595649926000078],
              [-75.09295677999995, 10.595384803000059],
              [-75.09331550199994, 10.595088991000068],
              [-75.09360086499993, 10.594831685000088],
              [-75.09383609299994, 10.594620458000065],
              [-75.0940905679999 , 10.594378558000074],
              [-75.09427178899995, 10.594209599000067],
              [-75.09458049599994, 10.593994308000049],
              [-75.09492015899991, 10.593782741000041],
              [-75.0951786359999 , 10.593582919000085],
              [-75.09548342199992, 10.593352332000052],
              [-75.09556792699993, 10.593285880000053],
              [-75.0959231949999 , 10.593006510000066],
              [-75.09622103599992, 10.59308599600007 ],
              [-75.09649969799995, 10.593142488000069],
              [-75.09689460299995, 10.593259827000054],
              [-75.09713070899994, 10.59331263100006 ],
              [-75.09737850099992, 10.593388357000038],
              [-75.09763787799994, 10.593460218000075],
              [-75.09789338999991, 10.593532089000064],
              [-75.09808308599992, 10.593585045000054],
              [-75.09829220799992, 10.593664721000039],
              [-75.09848989199992, 10.593719902000089],
              [-75.09851216699991, 10.593726120000042],
              [-75.09856320499995, 10.593740367000066],
              [-75.09881867599995, 10.593800760000079],
              [-75.09909724699992, 10.593830461000039],
              [-75.09938344799991, 10.593825701000071],
              [-75.09964639099991, 10.593805711000073],
              [-75.09989382299995, 10.593774292000091],
              [-75.10007940799994, 10.593754552000064],
              [-75.10033831499993, 10.593684827000061],
              [-75.10063977699991, 10.59361879100004 ],
              [-75.1007950579999 , 10.593581870000037],
              [-75.10093349599993, 10.593548953000038],
              [-75.1009828629999 , 10.593538238000065],
              [-75.10127360899992, 10.593475136000052],
              [-75.10151906099992, 10.593417325000075],
              [-75.10158664799991, 10.593401406000055],
              [-75.10190360999991, 10.593342971000084],
              [-75.10223991099991, 10.59328447100006 ],
              [-75.10264187799993, 10.59320279700006 ],
              [-75.10296720699995, 10.593175526000039],
              [-75.1029744139999 , 10.59317492100007 ],
              [-75.10345005199991, 10.593146578000074],
              [-75.10387178199994, 10.59319111700006 ],
              [-75.1039898969999 , 10.593208898000057],
              [-75.10422005099991, 10.593243545000064],
              [-75.10461496999994, 10.593364701000041],
              [-75.10516873299991, 10.593565694000063],
              [-75.1054506609999 , 10.593656605000092],
              [-75.10545098099993, 10.59375226900005 ],
              [-75.10567270099995, 10.593912257000056],
              [-75.10595564499994, 10.594091177000053],
              [-75.10622306699992, 10.594254843000044],
              [-75.10648667599992, 10.594433823000088],
              [-75.10670383599995, 10.594601480000051],
              [-75.10704879499991, 10.594818459000066],
              [-75.10734358499991, 10.595070045000057],
              [-75.10763041799993, 10.595252774000073],
              [-75.1079289889999 , 10.595477561000052],
              [-75.10801887299993, 10.595547345000057],
              [-75.10824309999992, 10.595721427000058],
              [-75.10848339899991, 10.595869870000058],
              [-75.10867736699993, 10.596041427000046],
              [-75.1089720089999 , 10.59624709500008 ],
              [-75.10931717199992, 10.596525295000049],
              [-75.10962740899993, 10.596765347000087],
              [-75.10984848599992, 10.596948293000082],
              [-75.11005799199995, 10.597138932000064],
              [-75.11033725399994, 10.597371432000045],
              [-75.11059337499995, 10.597623139000063],
              [-75.11079905299994, 10.597825268000065],
              [-75.1110357469999 , 10.59805025500009 ],
              [-75.11119097999995, 10.59820663000005 ],
              [-75.11140446799993, 10.598431693000066],
              [-75.1116489289999 , 10.598664308000082],
              [-75.11198659999991, 10.599011405000056],
              [-75.1120636519999 , 10.599086047000071],
              [-75.11225823599995, 10.599274541000057],
              [-75.11242520499991, 10.599469143000078],
              [-75.11248669899993, 10.599529577000055],
              [-75.11248671899995, 10.59952959800006 ],
              [-75.1126075979999 , 10.599648392000063],
              [-75.11275132899993, 10.599831591000054],
              [-75.11298427499992, 10.600091028000065],
              [-75.11314732999995, 10.600270334000072],
              [-75.11332211599995, 10.600487872000087],
              [-75.11353556499995, 10.600701452000067],
              [-75.11371037799995, 10.600926640000068],
              [-75.11403259699995, 10.601277616000061],
              [-75.11423448799991, 10.601502715000038],
              [-75.11440528899993, 10.601685824000072],
              [-75.11470027099995, 10.601987144000077],
              [-75.11500699399994, 10.602330521000056],
              [-75.11527872499994, 10.602620437000041],
              [-75.11570180599995, 10.603059089000055],
              [-75.11602397599995, 10.603394756000057],
              [-75.11629572099991, 10.603690751000045],
              [-75.11634619599994, 10.603745730000071],
              [-75.11632786199993, 10.603756363000059],
              [-75.11640005499993, 10.603775511000038],
              [-75.1165252099999 , 10.603713127000049],
              [-75.11653652199993, 10.60370804300004 ],
              [-75.1166406289999 , 10.603661277000072],
              [-75.11696364799991, 10.60351617300006 ],
              [-75.11711742699993, 10.603446722000058],
              [-75.11749270999991, 10.60327723800009 ],
              [-75.11811879699991, 10.602981113000055],
              [-75.11819416499992, 10.602949969000065],
              [-75.11860029999991, 10.602782150000053],
              [-75.11903089599991, 10.60265529700007 ],
              [-75.11931112099995, 10.602623764000043],
              [-75.11934815199993, 10.602619597000057],
              [-75.11988726599992, 10.602581434000058],
              [-75.12004197599992, 10.602581125000086],
              [-75.12011811299993, 10.602580973000045],
              [-75.12022701099994, 10.602451443000064],
              [-75.12033478599994, 10.602258567000092],
              [-75.12070282299993, 10.602017858000067],
              [-75.1209072929999 , 10.601864115000069],
              [-75.12110023899993, 10.60173337200007 ],
              [-75.12129318399991, 10.601602626000044],
              [-75.12149771999992, 10.601468012000055],
              [-75.12159803199995, 10.601394970000058],
              [-75.12182595799993, 10.601313854000068],
              [-75.12204254199992, 10.601309307000065],
              [-75.12219326899992, 10.601274363000073],
              [-75.12240573499992, 10.601197121000041],
              [-75.12258338799995, 10.601119999000048],
              [-75.1228112629999 , 10.601023574000067],
              [-75.12294646599992, 10.60097337500008 ],
              [-75.12308536499995, 10.600873422000063],
              [-75.12320889499995, 10.600804133000054],
              [-75.12337112199992, 10.600738536000051],
              [-75.1234792379999 , 10.600684602000058],
              [-75.1235524999999 , 10.60061931100006 ],
              [-75.1236371469999 , 10.600488922000068],
              [-75.1236910099999 , 10.600404557000047],
              [-75.12380262699992, 10.600243469000077],
              [-75.12392988399995, 10.60013207500009 ],
              [-75.12393979199993, 10.600125361000039],
              [-75.12395253999995, 10.59990318000007 ],
              [-75.1240149919999 , 10.598814726000057],
              [-75.12383609799991, 10.596099885000058],
              [-75.1238458979999 , 10.596094969000092],
              [-75.12414296899993, 10.595945914000083],
              [-75.12423243699993, 10.595856801000082],
              [-75.12431339599993, 10.595779999000058],
              [-75.12441738199993, 10.595649549000086],
              [-75.12450604999992, 10.595565068000042],
              [-75.12462168699994, 10.59544988500005 ],
              [-75.12475657599992, 10.59530785000004 ],
              [-75.12487606399992, 10.59518883000004 ],
              [-75.1249570359999 , 10.595115853000038],
              [-75.12506498199991, 10.595012176000068],
              [-75.1251574289999 , 10.59490089600007 ],
              [-75.12525371599992, 10.594781950000083],
              [-75.12551577599993, 10.594505561000062],
              [-75.12558896999991, 10.594440750000047],
              [-75.12568929299994, 10.59435191600005 ],
              [-75.12592467799993, 10.59419041700005 ],
              [-75.12596517399993, 10.59414795400005 ],
              [-75.12599789699993, 10.594113640000046],
              [-75.12605541699992, 10.59396803900006 ],
              [-75.12610525399992, 10.593837768000071],
              [-75.12615894399994, 10.593703658000038],
              [-75.12623186199994, 10.593538873000057],
              [-75.12632037299994, 10.593408473000068],
              [-75.12640497899991, 10.593266611000047],
              [-75.12645091999991, 10.593128695000075],
              [-75.12650458299993, 10.592986934000066],
              [-75.12658522899994, 10.592818298000054],
              [-75.12667359699992, 10.592645805000075],
              [-75.12679661499993, 10.592427281000084],
              [-75.12691971299995, 10.592231713000047],
              [-75.12700806299995, 10.59205539800007 ],
              [-75.12712358599993, 10.591905775000043],
              [-75.12721587099992, 10.59174857700009 ],
              [-75.12731986499995, 10.591621950000047],
              [-75.1274200819999 , 10.59152212500004 ],
              [-75.12753191299993, 10.59142608500008 ],
              [-75.12766694299995, 10.591326145000039],
              [-75.12783292099994, 10.59122992400006 ],
              [-75.12797571099992, 10.591137606000075],
              [-75.12814167999994, 10.591037558000039],
              [-75.12828831999991, 10.59094140500008 ],
              [-75.12841178699995, 10.59085297900009 ],
              [-75.1285236459999 , 10.590764594000063],
              [-75.1286585659999 , 10.590634040000054],
              [-75.12877413599995, 10.590499722000061],
              [-75.12888194199991, 10.590357777000065],
              [-75.1290091429999 , 10.59023107300004 ],
              [-75.12912856199995, 10.59009291700005 ],
              [-75.12922094399994, 10.589962505000074],
              [-75.12930940899992, 10.589820625000073],
              [-75.12940559399993, 10.58967106600005 ],
              [-75.12947836599994, 10.589464188000079],
              [-75.12956273399993, 10.589253444000065],
              [-75.1296509789999 , 10.589046513000085],
              [-75.12970845699994, 10.588889435000056],
              [-75.12971181099994, 10.588740185000063],
              [-75.12971134499992, 10.58860465400005 ],
              [-75.12971132199993, 10.58859860800004 ],
              [-75.12970694499995, 10.588449383000068],
              [-75.12968287399991, 10.588196913000047],
              [-75.12967040599995, 10.587944401000073],
              [-75.12966963899993, 10.58772246600006 ],
              [-75.12966894599992, 10.587519660000055],
              [-75.12971071399994, 10.587293756000065],
              [-75.12977213699992, 10.587159621000069],
              [-75.12989141299994, 10.586979369000062],
              [-75.13001448299991, 10.586776150000048],
              [-75.13014129599992, 10.58653847700009 ],
              [-75.13027586299995, 10.586304606000056],
              [-75.1303871419999 , 10.586047854000071],
              [-75.13046764899991, 10.585840948000055],
              [-75.13079246199993, 10.58582072300004 ],
              [-75.13102643699995, 10.585253608000073],
              [-75.13119898199994, 10.584816800000056],
              [-75.13127927599993, 10.584625003000042],
              [-75.13137544199992, 10.584395287000063],
              [-75.13152884199991, 10.58401594500009 ],
              [-75.13170115999992, 10.583514087000083],
              [-75.1318366289999 , 10.583120626000039],
              [-75.13193858199992, 10.582824512000059],
              [-75.13215671299992, 10.58215030200006 ],
              [-75.1323405249999 , 10.581617795000057],
              [-75.1326810509999 , 10.58054521400004 ],
              [-75.13291336799995, 10.580620965000037],
              [-75.13305206199993, 10.580636326000047],
              [-75.13311455799993, 10.58064324600008 ],
              [-75.1332421429999 , 10.580631338000046],
              [-75.13343931799994, 10.580611541000053],
              [-75.13360427999993, 10.580588572000067],
              [-75.13363647099993, 10.58058408900007 ],
              [-75.13377176699993, 10.580564501000083],
              [-75.13383960499993, 10.580556813000044],
              [-75.13398052999992, 10.580540837000058],
              [-75.13418166999992, 10.580547814000056],
              [-75.13430553699993, 10.580578010000067],
              [-75.13442555599994, 10.580615868000052],
              [-75.13457278599992, 10.58069190200007 ],
              [-75.1347162799999 , 10.580806215000052],
              [-75.13480549899992, 10.580882448000068],
              [-75.13493350399995, 10.58099298600007 ],
              [-75.13508472099994, 10.581103444000064],
              [-75.13510416499992, 10.581115264000061],
              [-75.13511577299994, 10.581122318000041],
              [-75.13524749999993, 10.58120238400005 ],
              [-75.13548377399991, 10.581304904000092],
              [-75.13567751799991, 10.581411392000064],
              [-75.1358867859999 , 10.581533137000065],
              [-75.13604194999994, 10.58166654200005 ],
              [-75.1361932179999 , 10.581792308000047],
              [-75.13628635599991, 10.581883830000038],
              [-75.13637559799992, 10.58196771300004 ],
              [-75.1364918729999 , 10.582040025000083],
              [-75.13665072099991, 10.582119846000069],
              [-75.1367825719999 , 10.582222714000068],
              [-75.1369299829999 , 10.582352321000087],
              [-75.1369976389999 , 10.582414369000048],
              [-75.13704638299993, 10.582459072000063],
              [-75.13721705199993, 10.582603902000074],
              [-75.13736832299992, 10.582729665000045],
              [-75.13742677699992, 10.58279666900006 ],
              [-75.1375081079999 , 10.582889908000084],
              [-75.13762072999992, 10.583023456000092],
              [-75.13770000699992, 10.583145782000088],
              [-75.13770238799992, 10.583149455000068],
              [-75.13777623999994, 10.583256350000056],
              [-75.13784238199992, 10.58337092000005 ],
              [-75.1378774719999 , 10.583451160000038],
              [-75.1379241329999 , 10.58352370700004 ],
              [-75.13802112299993, 10.583611388000065],
              [-75.13812958699992, 10.583660766000037],
              [-75.13829235599991, 10.583755881000059],
              [-75.13844537799991, 10.583859719000088],
              [-75.13850557399991, 10.583900567000057],
              [-75.13864515599994, 10.584003409000047],
              [-75.13875766399991, 10.584102519000055],
              [-75.13888181999994, 10.584216896000044],
              [-75.13902931799993, 10.584369458000083],
              [-75.13918061699991, 10.584502875000055],
              [-75.13935903799995, 10.584651505000068],
              [-75.13958393999991, 10.584819112000048],
              [-75.13970802099993, 10.584910524000065],
              [-75.13988624299992, 10.585001761000058],
              [-75.14010705299995, 10.585104328000057],
              [-75.14024170699992, 10.585163561000059],
              [-75.14043126899992, 10.58524400400006 ],
              [-75.14065639499995, 10.585339540000064],
              [-75.14074080199993, 10.585246091000045],
              [-75.14119002099994, 10.584738298000048],
              [-75.14157250399995, 10.584279929000047],
              [-75.14187823299994, 10.583847819000084],
              [-75.1421217969999 , 10.583407716000067],
              [-75.1423156919999 , 10.58304948600005 ],
              [-75.14247578299995, 10.582783538000058],
              [-75.14264062199993, 10.582546579000052],
              [-75.14281967399995, 10.58237686800004 ],
              [-75.14298460099991, 10.582181666000054],
              [-75.14309673699995, 10.581976133000069],
              [-75.14321672099993, 10.581599494000045],
              [-75.14331261599995, 10.581199707000053],
              [-75.14330163199992, 10.58099558300006 ],
              [-75.14327823299993, 10.580743927000071],
              [-75.1433556849999 , 10.580494968000039],
              [-75.14347605099994, 10.580322556000056],
              [-75.14354098099994, 10.580229551000059],
              [-75.14358107699991, 10.580200236000053],
              [-75.14378041099991, 10.58005449500007 ],
              [-75.14391589299993, 10.579798456000049],
              [-75.14401719599994, 10.579462455000055],
              [-75.14410461699993, 10.579213472000049],
              [-75.14409877799994, 10.578947281000069],
              [-75.14408659199995, 10.578453181000043],
              [-75.14407366299992, 10.577885428000059],
              [-75.1440888919999 , 10.577601217000051],
              [-75.1442092659999 , 10.577132362000043],
              [-75.14424823699994, 10.577032530000054],
              [-75.14423477199995, 10.576957701000083],
              [-75.14421446199992, 10.576844828000048],
              [-75.14430093199991, 10.576844534000088],
              [-75.14438645699994, 10.576844245000075],
              [-75.14590048199994, 10.576839120000045],
              [-75.14719507599995, 10.577028708000057],
              [-75.14778470199991, 10.577453452000043],
              [-75.14888284599994, 10.577527316000044],
              [-75.14986389899991, 10.577756750000049],
              [-75.15080493199991, 10.577753552000047],
              [-75.15158845699995, 10.577556911000045],
              [-75.15288494499993, 10.578289596000047],
              [-75.15367157899993, 10.57898522000005 ],
              [-75.15429879699991, 10.57894428800006 ],
              [-75.1550834059999 , 10.57905799200006 ],
              [-75.1558689599999 , 10.579443256000047],
              [-75.15641803499994, 10.579480172000046],
              [-75.15681135599993, 10.579827980000061],
              [-75.15838124899994, 10.580249347000063],
              [-75.1592828069999 , 10.580168668000056],
              [-75.16081172299994, 10.58008582900004 ],
              [-75.16147952099993, 10.580432686000051],
              [-75.16257876499992, 10.580816857000059],
              [-75.16367759899993, 10.581084636000071],
              [-75.16516512399994, 10.580381204000048],
              [-75.16602473499995, 10.579524752000054],
              [-75.16633485599993, 10.578515014000061],
              [-75.16719048699991, 10.576533525000059],
              [-75.1687104689999 , 10.573929021000083],
              [-75.16870909999994, 10.573541079000051],
              [-75.16862780299994, 10.572726671000055],
              [-75.16940883099994, 10.571831688000088],
              [-75.16999545199991, 10.571402917000057],
              [-75.17077825699994, 10.571012257000064],
              [-75.17132484399991, 10.570350851000057],
              [-75.17234357699994, 10.570153345000051],
              [-75.17304878199991, 10.569995720000065],
              [-75.17391223999994, 10.569851916000061],
              [-75.1740292959999 , 10.569818387000055],
              [-75.1754367609999 , 10.570194437000055],
              [-75.17637305399995, 10.569876486000055],
              [-75.1770418609999 , 10.569658840000045],
              [-75.17772799799991, 10.569606761000045],
              [-75.1777958959999 , 10.56956090600005 ],
              [-75.1782456819999 , 10.569257140000047],
              [-75.1788134599999 , 10.56887421500005 ],
              [-75.17921611499992, 10.568539488000056],
              [-75.1794591229999 , 10.568492721000041],
              [-75.17981896799995, 10.568423468000049],
              [-75.1805544469999 , 10.568281925000065],
              [-75.18367815999994, 10.567680765000091],
              [-75.2020516849999 , 10.564144772000077],
              [-75.21002815299994, 10.562609696000038],
              [-75.21053081499991, 10.56251295900006 ],
              [-75.21616622999994, 10.561428421000073],
              [-75.22228891099991, 10.560250107000059],
              [-75.22568862299994, 10.560301251000055],
              [-75.22580241199995, 10.559890849000055],
              [-75.22591419999992, 10.559686722000038],
              [-75.22607944599991, 10.559550640000054],
              [-75.22632925999994, 10.559458173000053],
              [-75.22677644499993, 10.559506512000041],
              [-75.2271712779999 , 10.559286479000093],
              [-75.22728873199992, 10.559300060000055],
              [-75.2275605449999 , 10.559407933000045],
              [-75.22769085199991, 10.559388454000043],
              [-75.22788734799991, 10.559144957000058],
              [-75.22821098799994, 10.559025168000062],
              [-75.22842500199994, 10.559035392000055],
              [-75.22891156599991, 10.559169618000055],
              [-75.22899126399994, 10.559166840000046],
              [-75.23098276699994, 10.561589067000057],
              [-75.23354852599994, 10.564589459000047],
              [-75.23666021799994, 10.567939573000046],
              [-75.23704498399991, 10.56971930900005 ],
              [-75.23616966299994, 10.571694154000056],
              [-75.23489292499994, 10.574219583000058],
              [-75.23256308599991, 10.577388165000059],
              [-75.23080315599992, 10.579303392000043],
              [-75.22939449799992, 10.580836331000057],
              [-75.22818298499993, 10.583538945000043],
              [-75.22743743599995, 10.585402818000091],
              [-75.2271516319999 , 10.586546032000058],
              [-75.2268782729999 , 10.587639465000052],
              [-75.22678508099995, 10.589876115000038],
              [-75.2273442419999 , 10.590714855000044],
              [-75.22808979099995, 10.592546578000054],
              [-75.22808979099995, 10.593552828000043],
              [-75.22808979099995, 10.593790245000037],
              [-75.22827617799993, 10.596586054000056],
              [-75.22827617799993, 10.599102284000082],
              [-75.22771701699992, 10.60105935300004 ],
              [-75.22678508099995, 10.602364063000039],
              [-75.22678508099995, 10.603332179000063],
              [-75.22678508099995, 10.603855158000044],
              [-75.22678508099995, 10.605253062000088],
              [-75.22725104699992, 10.606930547000047],
              [-75.22697146399992, 10.608235257000047],
              [-75.22641230599993, 10.60935358200004 ],
              [-75.2265986889999 , 10.610565099000041],
              [-75.22715785499992, 10.611963004000074],
              [-75.22780039499992, 10.612789125000063],
              [-75.2278102109999 , 10.61280174600006 ],
              [-75.22790340299991, 10.613826878000054],
              [-75.22790340299991, 10.614658621000046],
              [-75.22790340299991, 10.61466561800006 ],
              [-75.22788901599995, 10.614701589000049],
              [-75.22765507799994, 10.615400230000091],
              [-75.2284449089999 , 10.616467758000056],
              [-75.22883533899994, 10.616995460000055],
              [-75.22848857999992, 10.617837587000054],
              [-75.2287747819999 , 10.618106237000063],
              [-75.22922837599992, 10.61853201200006 ],
              [-75.22945952999993, 10.618901790000052],
              [-75.22906776899993, 10.62084008000005 ],
              [-75.22892236099995, 10.621206961000041],
              [-75.22891575999995, 10.62122361900009 ],
              [-75.2285046319999 , 10.622260947000086],
              [-75.22807316799992, 10.623290738000037],
              [-75.22724465999994, 10.624860003000038],
              [-75.22677612799993, 10.625716475000047],
              [-75.22651314099994, 10.626530437000042],
              [-75.22631003399994, 10.627188760000081],
              [-75.22618263899994, 10.627575531000048],
              [-75.2261750699999 , 10.627598506000083],
              [-75.22614427199994, 10.627756461000047],
              [-75.22549702999993, 10.628204005000043],
              [-75.22538220199993, 10.628278636000061],
              [-75.22529359999993, 10.628398828000059],
              [-75.22521266599995, 10.62850861700008 ],
              [-75.22506667099992, 10.62870666300006 ],
              [-75.22480099599994, 10.62906705800009 ],
              [-75.22481344699992, 10.629081243000087],
              [-75.22473857799991, 10.62919921100007 ],
              [-75.22466252099991, 10.629384925000068],
              [-75.22456863499991, 10.629547278000075],
              [-75.22457576799991, 10.629591661000063],
              [-75.2246149849999 , 10.629624367000076],
              [-75.22458486099993, 10.629679507000048],
              [-75.2245659909999 , 10.629714042000046],
              [-75.22448544899993, 10.629861472000073],
              [-75.22447237599994, 10.629890671000055],
              [-75.2243832449999 , 10.630076385000052],
              [-75.22431039199995, 10.630162771000073],
              [-75.22419904499992, 10.630294801000048],
              [-75.22410753899993, 10.630402257000071],
              [-75.22403385799993, 10.63044313800009 ],
              [-75.22399807199992, 10.63042197100009 ],
              [-75.2239151579999 , 10.630372925000074],
              [-75.22390550999995, 10.630367217000071],
              [-75.2238905989999 , 10.630360780000046],
              [-75.22388364299991, 10.630357779000065],
              [-75.22385432399994, 10.630416258000082],
              [-75.22383590299995, 10.630453         ],
              [-75.2238264209999 , 10.630471912000075],
              [-75.22382238099993, 10.630479970000067],
              [-75.22381899299995, 10.630486727000061],
              [-75.22378845899993, 10.630532993000088],
              [-75.22372772599994, 10.630625019000092],
              [-75.22367578899991, 10.630679709000049],
              [-75.22367083899991, 10.630684922000057],
              [-75.2236596219999 , 10.630716029000041],
              [-75.22365103399994, 10.630746910000084],
              [-75.22364790799992, 10.630769963000091],
              [-75.22362393199995, 10.630810392000058],
              [-75.22362754999995, 10.630821326000046],
              [-75.22363149699993, 10.630833254000038],
              [-75.22364061199994, 10.630860805000054],
              [-75.22367502899993, 10.630964831000085],
              [-75.22367935599993, 10.630977899000072],
              [-75.22368389399992, 10.630991613000049],
              [-75.22373173499994, 10.63113620200005 ],
              [-75.22377729199991, 10.63119349100009 ],
              [-75.22392142899992, 10.631374743000038],
              [-75.22420497099995, 10.631568568000091],
              [-75.22432683199992, 10.63166336200004 ],
              [-75.22445180799991, 10.63176057600009 ],
              [-75.22459544299994, 10.63192932000004 ],
              [-75.22483808499993, 10.632233858000063],
              [-75.22496334899995, 10.632421899000065],
              [-75.22505258899992, 10.63255586300005 ],
              [-75.2251188649999 , 10.632656588000089],
              [-75.22511892099993, 10.632656677000057],
              [-75.2251992539999 , 10.632778761000054],
              [-75.22537171999994, 10.633026212000061],
              [-75.22550149699993, 10.63322443900006 ],
              [-75.22563378799993, 10.633482646000061],
              [-75.22584416399991, 10.633978357000046],
              [-75.22591100599993, 10.634197289000042],
              [-75.22603445599992, 10.634544317000064],
              [-75.22613228599994, 10.634819510000057],
              [-75.22618538199993, 10.635061739000037],
              [-75.22628447099993, 10.635360382000044],
              [-75.22633216299994, 10.635590413000045],
              [-75.22653923899992, 10.636133632000053],
              [-75.22678603199995, 10.63654865300009 ],
              [-75.2270274899999 , 10.636951581000062],
              [-75.22731339099994, 10.63733925300005 ],
              [-75.22765644799995, 10.637754046000055],
              [-75.2277744669999 , 10.637952639000048],
              [-75.22788115999992, 10.638228010000091],
              [-75.22797733099992, 10.638581392000049],
              [-75.2279863309999 , 10.638620023000044],
              [-75.22798967299991, 10.638634367000066],
              [-75.22805098599991, 10.638897555000085],
              [-75.22812649299993, 10.639183883000044],
              [-75.22819937899993, 10.639614076000043],
              [-75.2282347869999 , 10.640048112000045],
              [-75.22831749199992, 10.640521019000062],
              [-75.22842760499992, 10.640851645000055],
              [-75.22853081999995, 10.641193932000078],
              [-75.22853180999994, 10.641380046000052],
              [-75.2284969879999 , 10.641744045000053],
              [-75.22847100599995, 10.641964536000046],
              [-75.22849288399993, 10.642518069000062],
              [-75.22852004099991, 10.642783850000058],
              [-75.22857521999993, 10.643099410000048],
              [-75.22858663199992, 10.643520347000049],
              [-75.22858681499991, 10.643820533000053],
              [-75.2285855849999 , 10.643831238000075],
              [-75.22856008499991, 10.644052994000049],
              [-75.22862109599993, 10.64437504500006 ],
              [-75.22870219399994, 10.644460793000064],
              [-75.22879624899991, 10.644560241000079],
              [-75.22930164099995, 10.644839249000086],
              [-75.22981948099994, 10.645281330000046],
              [-75.23019556299994, 10.645654171000047],
              [-75.23040855599993, 10.645842292000054],
              [-75.23057961399991, 10.645946625000079],
              [-75.23060704499994, 10.645963356000038],
              [-75.2309354649999 , 10.646104870000045],
              [-75.23095562999993, 10.646112926000058],
              [-75.23127689699993, 10.646241266000061],
              [-75.2316969229999 , 10.646424971000044],
              [-75.23194459799993, 10.646567505000064],
              [-75.23204718099993, 10.646624468000084],
              [-75.23217768599994, 10.64669693500008 ],
              [-75.23278276499991, 10.64703292200005 ],
              [-75.23316187099994, 10.647243432000039],
              [-75.2334579589999 , 10.647425644000066],
              [-75.23375444899995, 10.647674877000043],
              [-75.23404362399992, 10.64791795900004 ],
              [-75.23410736599993, 10.647957947000066],
              [-75.23423718499993, 10.64803938600005 ],
              [-75.23440539899991, 10.648165217000042],
              [-75.23475302199995, 10.648441897000055],
              [-75.2349434919999 , 10.64861766100006 ],
              [-75.23508385799994, 10.648786757000039],
              [-75.2351133809999 , 10.648822322000058],
              [-75.23533199099995, 10.64905447700005 ],
              [-75.23557468899992, 10.649203678000049],
              [-75.23588504799994, 10.649381662000053],
              [-75.2361723059999 , 10.649527935000037],
              [-75.23646284499995, 10.649634161000051],
              [-75.2366082069999 , 10.649684738000076],
              [-75.23669855399993, 10.649725598000089],
              [-75.23677699799993, 10.64976107700005 ],
              [-75.23702779199994, 10.64994209300005 ],
              [-75.23736349599994, 10.650299172000075],
              [-75.23881444299991, 10.651589223000087],
              [-75.23949594099992, 10.65219514800009 ],
              [-75.24175282899995, 10.654131989000064],
              [-75.2418047139999 , 10.654250182000055],
              [-75.2419382299999 , 10.654415999000037],
              [-75.24195902399993, 10.65444182400006 ],
              [-75.24226155699995, 10.655696436000085],
              [-75.24225390299995, 10.655707437000046],
              [-75.24230350899995, 10.65591539400009 ],
              [-75.24231382199991, 10.655962946000045],
              [-75.24233785099995, 10.656073741000057],
              [-75.24237600499993, 10.656209199000045],
              [-75.2423882139999 , 10.656243209000081],
              [-75.24240271699995, 10.656283605000056],
              [-75.24240644899993, 10.656297322000057],
              [-75.24246962099994, 10.656529310000053],
              [-75.2425133719999 , 10.656689976000052],
              [-75.24257633099995, 10.656905562000077],
              [-75.24259762699995, 10.656979185000068],
              [-75.24259763899994, 10.65697922600009 ],
              [-75.24264310599995, 10.657136412000057],
              [-75.24270113999995, 10.657482636000054],
              [-75.2427537609999 , 10.657796524000048],
              [-75.24277439299993, 10.657944820000068],
              [-75.2427842859999 , 10.658015928000054],
              [-75.24281724499991, 10.658167536000065],
              [-75.24283198199993, 10.658235328000046],
              [-75.24284198299995, 10.658286045000068],
              [-75.24284365699992, 10.65829453400005 ],
              [-75.24287297799992, 10.658434531000069],
              [-75.2428731359999 , 10.658435283000074],
              [-75.24289660499994, 10.658547341000087],
              [-75.24290856599993, 10.658603714000037],
              [-75.24291608499993, 10.658638143000076],
              [-75.24292479399992, 10.658678017000057],
              [-75.24294614299993, 10.658768550000048],
              [-75.24297777699991, 10.658902022000063],
              [-75.24297780299992, 10.658902130000058],
              [-75.24298116299991, 10.658916305000048],
              [-75.2429815779999 , 10.658918277000055],
              [-75.24300090799994, 10.659010094000053],
              [-75.24300507599992, 10.65902989600005 ],
              [-75.24301020199994, 10.659057042000086],
              [-75.24302813699995, 10.65915202900004 ],
              [-75.2430426549999 , 10.659221205000051],
              [-75.24305546599993, 10.659286117000079],
              [-75.24306571599993, 10.659338214000059],
              [-75.2430853589999 , 10.659421061000046],
              [-75.24310500399991, 10.659506467000085],
              [-75.24311876199994, 10.659562522000044],
              [-75.24312806099994, 10.659600416000046],
              [-75.24313793699991, 10.659644356000058],
              [-75.24314514499991, 10.659676430000047],
              [-75.2431680709999 , 10.659772969000073],
              [-75.24316810099992, 10.659773090000044],
              [-75.24317759899992, 10.659813082000085],
              [-75.24320322099993, 10.65992069400005 ],
              [-75.24321895899993, 10.659990144000062],
              [-75.2432329419999 , 10.660051843000076],
              [-75.24324154999994, 10.660089826000046],
              [-75.24325702499993, 10.660158127000045],
              [-75.2432844249999 , 10.66028519200006 ],
              [-75.2432939759999 , 10.660329484000044],
              [-75.24330656199993, 10.660387873000047],
              [-75.24331828599992, 10.660440614000038],
              [-75.24337147499995, 10.660679966000089],
              [-75.24340990799993, 10.660844804000078],
              [-75.24344321399991, 10.660994266000046],
              [-75.24348249999991, 10.66117276700004 ],
              [-75.24351044699995, 10.66129814900006 ],
              [-75.24351581399992, 10.661322229000064],
              [-75.2435602239999 , 10.661522941000044],
              [-75.24358755599991, 10.661647634000076],
              [-75.2436109599999 , 10.661748838000051],
              [-75.24361658899994, 10.661773181000058],
              [-75.2436397269999 , 10.66186085000004 ],
              [-75.24364904899994, 10.661896169000045],
              [-75.24369089899994, 10.662059297000042],
              [-75.24369516799993, 10.662075524000045],
              [-75.24371405199992, 10.662182035000058],
              [-75.24371651899992, 10.662195951000058],
              [-75.24372300199991, 10.662230711000063],
              [-75.24372302799992, 10.66223085100006 ],
              [-75.24372305299994, 10.662230985000065],
              [-75.24372307099992, 10.662231081000073],
              [-75.24372310599995, 10.662231270000063],
              [-75.2437231429999 , 10.662231475000056],
              [-75.24373531099991, 10.662296730000037],
              [-75.24374380499995, 10.662343844000077],
              [-75.24375409699991, 10.662400930000047],
              [-75.24377288999995, 10.662502562000043],
              [-75.24377801499992, 10.662532454000086],
              [-75.24379504299992, 10.66259774000008 ],
              [-75.24380363499995, 10.662630676000049],
              [-75.24383311399993, 10.662754091000068],
              [-75.24386769199992, 10.662898854000048],
              [-75.24389344199994, 10.663001853000083],
              [-75.24390527199995, 10.663049173000047],
              [-75.2439280609999 , 10.663144033000037],
              [-75.24393686899992, 10.663180697000087],
              [-75.24395139199993, 10.663242190000062],
              [-75.2439757329999 , 10.663361637000037],
              [-75.24398811499992, 10.663422400000059],
              [-75.24401544699992, 10.66356161400006 ],
              [-75.2440203079999 , 10.663588073000085],
              [-75.24402249799994, 10.663599988000044],
              [-75.24402595099991, 10.663616732000037],
              [-75.24403047399994, 10.663638677000051],
              [-75.24406787499993, 10.663819157000091],
              [-75.24416183699992, 10.664215438000042],
              [-75.2441787809999 , 10.664286893000053],
              [-75.24424146899992, 10.664552103000062],
              [-75.24424476799993, 10.664604956000062],
              [-75.24424628699995, 10.664629259000037],
              [-75.2442501079999 , 10.66471499000005 ],
              [-75.24424156599991, 10.664808945000061],
              [-75.24422448099995, 10.665088676000039],
              [-75.24422404199993, 10.665092318000063],
              [-75.2441817749999 , 10.66544314500004 ],
              [-75.24416422299993, 10.66560828200005 ],
              [-75.24414757199992, 10.66576494900005 ],
              [-75.24414635699992, 10.665776383000093],
              [-75.24403022099995, 10.666952568000056],
              [-75.24399420599991, 10.667370495000057],
              [-75.2439903209999 , 10.66741557100005 ],
              [-75.24395169199994, 10.667809075000037],
              [-75.24394996699993, 10.66781663900008 ],
              [-75.24393686299993, 10.66787396400008 ],
              [-75.24393238099992, 10.667893571000093],
              [-75.24392201099994, 10.667991050000069],
              [-75.2439203109999 , 10.668007038000042],
              [-75.24391065399993, 10.668137400000091],
              [-75.24389375499993, 10.66833536200005 ],
              [-75.24388651299995, 10.66844158500004 ],
              [-75.2438822439999 , 10.668533362000062],
              [-75.24388168399992, 10.668545392000055],
              [-75.24385995299991, 10.668772322000052],
              [-75.24385770799995, 10.668791730000066],
              [-75.24384560199991, 10.668896449000044],
              [-75.24383198499993, 10.669014191000088],
              [-75.24381891699994, 10.669127199000059],
              [-75.24379477499991, 10.669337231000043],
              [-75.24377526799992, 10.669517127000063],
              [-75.24377304799992, 10.669537607000052],
              [-75.24377261799992, 10.66954439300008 ],
              [-75.24375976599993, 10.66974699900004 ],
              [-75.24375528999991, 10.669817503000047],
              [-75.24372648399992, 10.670076807000044],
              [-75.24369165199994, 10.670390294000072],
              [-75.24366913799992, 10.670592919000057],
              [-75.24366658299994, 10.670623574000047],
              [-75.24364420499995, 10.670892110000068],
              [-75.2435973389999 , 10.671454491000077],
              [-75.24353272199994, 10.672043235000046],
              [-75.24346810399993, 10.672653513000057],
              [-75.24325271099991, 10.674843343000077],
              [-75.24314501299995, 10.67596338900006 ],
              [-75.24297987999995, 10.677428062000047],
              [-75.24297630899991, 10.677456303000042],
              [-75.2428866429999 , 10.678165482000054],
              [-75.24282192499993, 10.678677342000071],
              [-75.24281731099995, 10.678760447000059],
              [-75.24282018899993, 10.679840012000057],
              [-75.2428224599999 , 10.679882667000072],
              [-75.24284165699993, 10.680243368000049],
              [-75.24284829699991, 10.680368102000045],
              [-75.24244536499992, 10.684282284000062],
              [-75.2423115169999 , 10.68558252300005 ],
              [-75.2421790599999 , 10.68689237500007 ],
              [-75.24217434199994, 10.68693902800004 ],
              [-75.24196644599994, 10.688994897000043],
              [-75.24197650699995, 10.689004469000054],
              [-75.24202402899994, 10.68904968000004 ],
              [-75.2421375209999 , 10.689158233000057],
              [-75.24218456599993, 10.689203234000047],
              [-75.24229025199992, 10.689291873000059],
              [-75.24240094299995, 10.689384713000038],
              [-75.24241701199992, 10.689402793000056],
              [-75.24251262599995, 10.68951035300006 ],
              [-75.24253994199995, 10.68951035300006 ],
              [-75.24260336499992, 10.68951035300006 ],
              [-75.24345491699995, 10.69069694600006 ],
              [-75.24469742899993, 10.692396772000052],
              [-75.24540215399992, 10.693360877000089],
              [-75.24702562599992, 10.695612091000044],
              [-75.24704852799994, 10.695643844000074],
              [-75.24705665999994, 10.695655125000087],
              [-75.24707776999992, 10.695700355000042],
              [-75.2470875169999 , 10.695721241000058],
              [-75.24708711599993, 10.695722273000058],
              [-75.24708684399991, 10.695722111000066],
              [-75.2470772449999 , 10.695716357000038],
              [-75.24707285099993, 10.69571372200005 ],
              [-75.2470496489999 , 10.69569980700004 ],
              [-75.24644444299992, 10.695336876000056],
              [-75.24485080199992, 10.694875143000047],
              [-75.2432670739999 , 10.695137844000044],
              [-75.24181530599992, 10.696123344000057],
              [-75.24073308099992, 10.697345427000073],
              [-75.23892329699993, 10.69893952600006 ],
              [-75.23662351299993, 10.700419393000061],
              [-75.23567277599994, 10.700845979000064],
              [-75.23514952399995, 10.701080757000057],
              [-75.23468171399992, 10.701290659000051],
              [-75.2328554039999 , 10.701677319000055],
              [-75.23029656999995, 10.702073745000064],
              [-75.22750980799992, 10.70211089500009 ],
              [-75.22748861699995, 10.702111178000052],
              [-75.22740950399992, 10.70210355000006 ],
              [-75.22638819799994, 10.702005068000062],
              [-75.22441839899994, 10.700823668000055],
              [-75.2230442469999 , 10.698547427000051],
              [-75.22203964899995, 10.69650779400007 ],
              [-75.2214078909999 , 10.694946255000048]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08433",
      "properties": {"id": "08433", "iso": "MAL", "name": "MALAMBO"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.8219958649999 , 10.891264387000092],
              [-74.82191996099994, 10.891245411000057],
              [-74.82184526899994, 10.891247343000089],
              [-74.8218371559999 , 10.891247553000085],
              [-74.82177471999995, 10.891255555000043],
              [-74.82129042299994, 10.891317622000088],
              [-74.82116876499992, 10.89132750300007 ],
              [-74.82096603499991, 10.891315130000066],
              [-74.82024172699994, 10.891249456000082],
              [-74.8199933109999 , 10.891217095000059],
              [-74.8198000079999 , 10.89116774200005 ],
              [-74.81890654999995, 10.890951448000067],
              [-74.81857253499993, 10.890859700000078],
              [-74.81846017299995, 10.89075377800009 ],
              [-74.8184213099999 , 10.890717142000085],
              [-74.81840826899992, 10.890704849000088],
              [-74.81838467999995, 10.890682611000045],
              [-74.8183690429999 , 10.890667871000062],
              [-74.81846252399993, 10.890581097000052],
              [-74.81835687599994, 10.890482112000086],
              [-74.81822497399992, 10.890384483000048],
              [-74.81832609499992, 10.890291107000053],
              [-74.81841152299995, 10.890215065000064],
              [-74.81861139799992, 10.890047551000066],
              [-74.81872501499993, 10.889945615000045],
              [-74.81881507899993, 10.889864809000073],
              [-74.8188197149999 , 10.889860105000082],
              [-74.81894261899993, 10.889735366000082],
              [-74.81908157999993, 10.889569755000082],
              [-74.81921863699995, 10.889402241000084],
              [-74.8193469119999 , 10.889278457000046],
              [-74.81957027599992, 10.889062915000068],
              [-74.81963087399993, 10.888993926000069],
              [-74.81972494099995, 10.88888683500005 ],
              [-74.81976842499995, 10.888838264000071],
              [-74.81982159699993, 10.88880924600005 ],
              [-74.8199181199999 , 10.888670030000071],
              [-74.82000228899994, 10.888460544000054],
              [-74.82008612099992, 10.88823393000007 ],
              [-74.82025466899995, 10.887778282000056],
              [-74.82041117999995, 10.887466980000056],
              [-74.8205074839999 , 10.887269421000042],
              [-74.82057374599992, 10.88715565700005 ],
              [-74.82073064399992, 10.886999806000063],
              [-74.82063376299993, 10.886964167000087],
              [-74.82062151199995, 10.886904403000074],
              [-74.82063329899995, 10.886778811000056],
              [-74.82068118699993, 10.886575398000048],
              [-74.82077718499994, 10.886258257000065],
              [-74.8208915159999 , 10.886012822000055],
              [-74.8210841479999 , 10.885629674000086],
              [-74.82139715799991, 10.885001080000052],
              [-74.82173782899991, 10.884308882000084],
              [-74.82181848599993, 10.884145004000061],
              [-74.82216865799995, 10.883451687000047],
              [-74.82247463099992, 10.882845879000058],
              [-74.82249911299994, 10.88279642000009 ],
              [-74.82261236499994, 10.882567622000067],
              [-74.82266724699991, 10.882456752000053],
              [-74.82279971999992, 10.882211274000042],
              [-74.82290279999995, 10.882051920000038],
              [-74.82290818699994, 10.882043593000049],
              [-74.82296219699992, 10.881870052000068],
              [-74.82305861799995, 10.881720336000058],
              [-74.8232093869999 , 10.881534608000038],
              [-74.82321333199991, 10.881527420000054],
              [-74.82324552299991, 10.881468747000042],
              [-74.82332392199993, 10.88137288300004 ],
              [-74.82340219499991, 10.881223211000076],
              [-74.82345032199993, 10.881115460000046],
              [-74.82348149699993, 10.881061231000047],
              [-74.82359487899993, 10.880863976000057],
              [-74.82366112199992, 10.880744228000083],
              [-74.82390829199994, 10.88040280000007 ],
              [-74.82401740099994, 10.880265110000039],
              [-74.8241314149999 , 10.88012122300006 ],
              [-74.82436671699992, 10.879869517000088],
              [-74.8245415959999 , 10.879647853000051],
              [-74.82468025399993, 10.879457400000092],
              [-74.82460949499995, 10.879457571000046],
              [-74.82483608099994, 10.879109132000053],
              [-74.82485925599991, 10.879039585000044],
              [-74.82486752999995, 10.87901475700005 ],
              [-74.82477620999992, 10.87900029900004 ],
              [-74.82283329899991, 10.87829686300006 ],
              [-74.82094257799992, 10.877615897000055],
              [-74.8208802339999 , 10.877593441000045],
              [-74.8199189419999 , 10.877239176000046],
              [-74.81963430199994, 10.877209737000044],
              [-74.81876514399994, 10.877121440000053],
              [-74.81664561799994, 10.87690559500004 ],
              [-74.8139110539999 , 10.87662090300006 ],
              [-74.81209938199993, 10.87883787800007 ],
              [-74.81159283799991, 10.879489213000056],
              [-74.8116918039999 , 10.879488976000061],
              [-74.81169316099994, 10.879488972000047],
              [-74.81170135499991, 10.879488952000088],
              [-74.81173612899994, 10.879488869000056],
              [-74.81173437299992, 10.87949085300005 ],
              [-74.81172081099993, 10.879506183000046],
              [-74.81135607199991, 10.879918481000061],
              [-74.81119922799991, 10.880098240000052],
              [-74.81112601299992, 10.880105727000057],
              [-74.81060047699992, 10.880159479000042],
              [-74.80963422299993, 10.88025680000004 ],
              [-74.80963214699995, 10.880214266000053],
              [-74.80963112199993, 10.880193276000057],
              [-74.80963009699991, 10.88017228800004 ],
              [-74.80936663499995, 10.880196380000086],
              [-74.80886678799993, 10.880236387000082],
              [-74.8087444169999 , 10.88022060500009 ],
              [-74.80868927099993, 10.880249444000071],
              [-74.80865833799993, 10.88026990700007 ],
              [-74.8082577479999 , 10.880292441000051],
              [-74.80797485199992, 10.88032062700006 ],
              [-74.80752038399993, 10.880600169000047],
              [-74.80725864399994, 10.881035610000083],
              [-74.80675895699994, 10.881982635000043],
              [-74.80650673399992, 10.882532290000086],
              [-74.80646509299993, 10.882658402000061],
              [-74.80643773499992, 10.882850289000089],
              [-74.80643299899992, 10.882883511000045],
              [-74.80637308799993, 10.883077889000049],
              [-74.8062689859999 , 10.883442244000037],
              [-74.8060552359999 , 10.884161024000036],
              [-74.80588913399993, 10.884764458000063],
              [-74.80579170199991, 10.885151480000047],
              [-74.80573459499993, 10.885401325000089],
              [-74.80563267799994, 10.885992927000075],
              [-74.80547069499994, 10.885925808000081],
              [-74.80546794199995, 10.88592466700004 ],
              [-74.8051679209999 , 10.885604795000063],
              [-74.80482147099991, 10.885229792000075],
              [-74.80446931099993, 10.884911896000062],
              [-74.80384303699992, 10.88435605400008 ],
              [-74.8036833139999 , 10.884218386000043],
              [-74.80361101599993, 10.884183367000048],
              [-74.8035767909999 , 10.884161650000067],
              [-74.80351380699994, 10.884087402000091],
              [-74.80321326799992, 10.883843396000088],
              [-74.80286771999994, 10.883560635000038],
              [-74.80228981399995, 10.88306489300004 ],
              [-74.80226971199994, 10.883047649000048],
              [-74.80218470499995, 10.882981887000085],
              [-74.80209225599992, 10.882910369000058],
              [-74.80165538699993, 10.882490632000042],
              [-74.80164625899994, 10.882461913000043],
              [-74.80162035599994, 10.882380420000061],
              [-74.80159201299995, 10.882357070000069],
              [-74.80158654999991, 10.88235256900009 ],
              [-74.80151575999992, 10.88229424800005 ],
              [-74.80148150899993, 10.882265325000049],
              [-74.80140554299993, 10.882172296000078],
              [-74.8013550739999 , 10.882101416000069],
              [-74.8012997429999 , 10.882062289000089],
              [-74.80117408099994, 10.881952588000047],
              [-74.80092007399992, 10.881670622000058],
              [-74.80079664399994, 10.881524702000092],
              [-74.80077968599994, 10.881504654000082],
              [-74.80075866499993, 10.881469482000057],
              [-74.80074702799993, 10.881450010000037],
              [-74.80073443899994, 10.881449316000044],
              [-74.80073437499993, 10.881449312000086],
              [-74.8006839009999 , 10.881446530000062],
              [-74.80063239299994, 10.881424768000045],
              [-74.80046862099994, 10.881213778000074],
              [-74.80043680099993, 10.881172783000068],
              [-74.80031544699995, 10.881029476000037],
              [-74.80020004499994, 10.880893196000045],
              [-74.80016286699993, 10.880850143000089],
              [-74.80000278699993, 10.880664768000088],
              [-74.79997518499994, 10.880636214000049],
              [-74.79981366399994, 10.880459804000054],
              [-74.79976864399993, 10.880412191000062],
              [-74.79964718899993, 10.880283740000039],
              [-74.79948942399994, 10.880137336000075],
              [-74.79931907499991, 10.879986148000057],
              [-74.79919034599993, 10.879873234000058],
              [-74.79906355199995, 10.879760321000049],
              [-74.79907090699993, 10.87975250900007 ],
              [-74.79908470299995, 10.879737856000077],
              [-74.79919462399994, 10.87955          ],
              [-74.79919902499995, 10.879528123000057],
              [-74.79920423899995, 10.87951881500004 ],
              [-74.79942501799991, 10.879124670000067],
              [-74.79939635499994, 10.87911091300009 ],
              [-74.79938448599995, 10.879104012000084],
              [-74.79923264799993, 10.879015734000063],
              [-74.79925392199993, 10.878975521000086],
              [-74.79938873999993, 10.878720681000061],
              [-74.79956957899992, 10.878823474000058],
              [-74.79958184499992, 10.878798995000068],
              [-74.79961513699993, 10.87873757400007 ],
              [-74.79968802999991, 10.878603091000059],
              [-74.79980936099992, 10.878379308000092],
              [-74.79982057699993, 10.878358638000066],
              [-74.79990571199994, 10.878201600000068],
              [-74.7999318869999 , 10.87814062800004 ],
              [-74.7999545269999 , 10.878087893000043],
              [-74.79997904599992, 10.878030779000085],
              [-74.79996915799995, 10.878027788000054],
              [-74.79995867799994, 10.87802461800004 ],
              [-74.79989658599993, 10.878005834000078],
              [-74.79978152699994, 10.877972034000038],
              [-74.79979370299992, 10.877939623000088],
              [-74.79985319199994, 10.877781305000042],
              [-74.79986582099991, 10.87774771900007 ],
              [-74.79991471599993, 10.877617691000069],
              [-74.79997456699994, 10.877458403000048],
              [-74.80000222399991, 10.877380591000076],
              [-74.80008038999995, 10.877188662000037],
              [-74.80009888699993, 10.877102894000075],
              [-74.80013126699993, 10.876952754000058],
              [-74.8001379289999 , 10.876921862000074],
              [-74.80026303799991, 10.876657669000053],
              [-74.80029691999994, 10.876586543000087],
              [-74.8004183139999 , 10.876331707000077],
              [-74.80044035499992, 10.876285438000082],
              [-74.79935786099992, 10.876026615000058],
              [-74.79884174999995, 10.876936945000068],
              [-74.79842092899992, 10.877324686000065],
              [-74.79754827799991, 10.877894311000091],
              [-74.7974289199999 , 10.877972137000086],
              [-74.79722357599991, 10.878106032000062],
              [-74.7968924839999 , 10.877780340000072],
              [-74.79674266099994, 10.877597129000037],
              [-74.79674257099992, 10.877597021000042],
              [-74.79662534199991, 10.877453668000044],
              [-74.79655914499995, 10.877493512000058],
              [-74.79606371299991, 10.878554472000076],
              [-74.79597319399994, 10.878946451000047],
              [-74.79588268099991, 10.879343456000072],
              [-74.79587258599992, 10.87952669300006 ],
              [-74.79575835199995, 10.879820640000048],
              [-74.79569228499992, 10.880018119000056],
              [-74.7956565149999 , 10.880239442000061],
              [-74.79563872099993, 10.880382987000075],
              [-74.7956571659999 , 10.880508509000038],
              [-74.79565136899993, 10.880610173000036],
              [-74.79564563299994, 10.880741731000057],
              [-74.79562771199994, 10.88083744000005 ],
              [-74.79554964999994, 10.881076801000063],
              [-74.79534488699994, 10.881459968000058],
              [-74.79436386499992, 10.881043717000068],
              [-74.79259566799993, 10.88032437000004 ],
              [-74.7923601629999 , 10.880230005000044],
              [-74.79229895799995, 10.880205483000054],
              [-74.79215037499995, 10.880540611000072],
              [-74.79213426399991, 10.880532986000048],
              [-74.79213317199992, 10.880532469000059],
              [-74.7919817639999 , 10.880460808000066],
              [-74.79197962899991, 10.880459797000071],
              [-74.79189459999992, 10.880396598000061],
              [-74.7914753579999 , 10.880176991000042],
              [-74.79129793299995, 10.88008405100004 ],
              [-74.79128841499994, 10.880036462000078],
              [-74.79130982999993, 10.879986493000047],
              [-74.79139073199991, 10.879893695000078],
              [-74.7916167809999 , 10.879643851000083],
              [-74.79168340599995, 10.879536775000076],
              [-74.79170482099994, 10.879474909000066],
              [-74.7917071999999 , 10.87940352600009 ],
              [-74.7916929239999 , 10.879358317000083],
              [-74.79165723099993, 10.879291691000049],
              [-74.79155015599991, 10.879184615000042],
              [-74.79125748199993, 10.878946669000072],
              [-74.7909505319999 , 10.878694446000054],
              [-74.7907006879999 , 10.878492192000067],
              [-74.7907839689999 , 10.878373219000082],
              [-74.79080342499992, 10.878351508000037],
              [-74.79081953799994, 10.878333527000052],
              [-74.79094815199994, 10.87819          ],
              [-74.79114564699995, 10.877921122000089],
              [-74.79131220999994, 10.877709349000042],
              [-74.79142880299992, 10.877578479000078],
              [-74.79151624899993, 10.877457722000088],
              [-74.79162332399994, 10.87731197900007 ],
              [-74.79144783899994, 10.877240596000092],
              [-74.79129912299993, 10.877207878000092],
              [-74.79125153299992, 10.877225725000073],
              [-74.79113553499991, 10.877303056000073],
              [-74.7910284589999 , 10.877356595000037],
              [-74.79095409999991, 10.87737741400008 ],
              [-74.79077645099994, 10.877585282000041],
              [-74.79068400299991, 10.877693457000078],
              [-74.79042867499993, 10.877992218000088],
              [-74.79041063199992, 10.878013330000044],
              [-74.7903770819999 , 10.87805258700007 ],
              [-74.79021944199991, 10.877978229000064],
              [-74.78977435099995, 10.877610960000084],
              [-74.78924981199992, 10.877178135000065],
              [-74.78931800399994, 10.87685451100009 ],
              [-74.78934484099995, 10.87672714900009 ],
              [-74.78942529699992, 10.876345323000066],
              [-74.7893777079999 , 10.87630368300006 ],
              [-74.78957976899994, 10.876021669000068],
              [-74.78977435099995, 10.875750094000068],
              [-74.78979113899993, 10.87572666300008 ],
              [-74.78977435099995, 10.875720539000042],
              [-74.7897446209999 , 10.875709695000069],
              [-74.78945994799994, 10.87560585700004 ],
              [-74.7893955859999 , 10.875584329000048],
              [-74.78903671799992, 10.87546429300005 ],
              [-74.78876990399993, 10.87537504800008 ],
              [-74.78796564599992, 10.875125205000074],
              [-74.78796545299991, 10.875125497000056],
              [-74.78778004899993, 10.875405982000075],
              [-74.78769830699991, 10.875527808000072],
              [-74.78766937599994, 10.87557092700007 ],
              [-74.78753258499995, 10.875774798000066],
              [-74.7874272219999 , 10.87588777500008 ],
              [-74.78741887399991, 10.875928122000062],
              [-74.78741580099995, 10.875942978000069],
              [-74.7873758259999 , 10.876194249000037],
              [-74.78734727199992, 10.876475978000087],
              [-74.78732119199992, 10.876692766000076],
              [-74.78731681499994, 10.876729152000053],
              [-74.78729206899993, 10.876898570000037],
              [-74.7872671959999 , 10.876969304000056],
              [-74.78726424599995, 10.876977694000061],
              [-74.78717023999991, 10.877245019000043],
              [-74.78707422799994, 10.877508204000037],
              [-74.78700843699994, 10.877688552000052],
              [-74.78683906399993, 10.87759481200004 ],
              [-74.78670576899992, 10.877521038000054],
              [-74.78626664799992, 10.87745407400007 ],
              [-74.78605665299995, 10.877422051000053],
              [-74.78601679699995, 10.877435972000058],
              [-74.78593390099991, 10.877474593000045],
              [-74.78589116099994, 10.877494507000051],
              [-74.78588316999992, 10.877497186000085],
              [-74.78563988999991, 10.877578739000057],
              [-74.78563349599995, 10.877580235000039],
              [-74.7854628579999 , 10.87762014100008 ],
              [-74.78536434799992, 10.87763584600009 ],
              [-74.78531866299994, 10.87762728000007 ],
              [-74.7852715489999 , 10.87764155700006 ],
              [-74.78524156799995, 10.877677248000055],
              [-74.78524156799995, 10.877721506000057],
              [-74.78524156799995, 10.877801456000043],
              [-74.78524727899992, 10.87791995300006 ],
              [-74.78522300799995, 10.878004187000045],
              [-74.78518446099991, 10.878034168000056],
              [-74.78507351899992, 10.87809969600005 ],
              [-74.78494032799995, 10.878178364000064],
              [-74.78487829699992, 10.878229978000093],
              [-74.78476765999994, 10.878322035000053],
              [-74.78475990399994, 10.878328489000069],
              [-74.78473830399992, 10.878342363000058],
              [-74.78467457099993, 10.878383298000074],
              [-74.78455559799994, 10.878386273000046],
              [-74.78443959899994, 10.878341657000078],
              [-74.78429385699991, 10.878246478000051],
              [-74.78413919199994, 10.878047199000036],
              [-74.7838358109999 , 10.87766945900006 ],
              [-74.78354135299992, 10.877345257000059],
              [-74.78315766399993, 10.876890186000082],
              [-74.78307854699995, 10.876789881000093],
              [-74.78297789099992, 10.876662269000064],
              [-74.7829145099999 , 10.876581913000052],
              [-74.78211367599994, 10.875566610000078],
              [-74.78186680699991, 10.87547143200004 ],
              [-74.7817091679999 , 10.87539707500008 ],
              [-74.7816689469999 , 10.875348810000048],
              [-74.78159019499992, 10.875254306000045],
              [-74.78155093299995, 10.875187331000063],
              [-74.78153963099993, 10.87516805100006 ],
              [-74.78149501599995, 10.87503718000005 ],
              [-74.78149204199991, 10.874918207000064],
              [-74.78148311899992, 10.874781389000077],
              [-74.7814682469999 , 10.874629697000046],
              [-74.78145634999993, 10.874570212000037],
              [-74.78143850399994, 10.874475033000067],
              [-74.78128086499993, 10.874457188000065],
              [-74.78048969399993, 10.874460162000048],
              [-74.78028743999994, 10.874501801000065],
              [-74.78006733899991, 10.874537494000037],
              [-74.77979072699992, 10.87462077500004 ],
              [-74.77963903699992, 10.874692158000073],
              [-74.77960152399993, 10.87470049500007 ],
              [-74.77958549899995, 10.874704056000041],
              [-74.77961226799994, 10.874823029000083],
              [-74.77950816599991, 10.87488251600007 ],
              [-74.77942785899995, 10.874989592000077],
              [-74.77947842299994, 10.875629072000038],
              [-74.77954881699992, 10.876468944000067],
              [-74.77956467799993, 10.876658188000079],
              [-74.77954970799993, 10.876661340000055],
              [-74.7784344339999 , 10.876896135000038],
              [-74.77833919199992, 10.876915028000042],
              [-74.77821016999991, 10.876940621000074],
              [-74.77768359499993, 10.87704507400008 ],
              [-74.77745274699993, 10.87709086700005 ],
              [-74.77697998899993, 10.877184644000067],
              [-74.77664686399993, 10.87725602800009 ],
              [-74.77665578699992, 10.877339310000082],
              [-74.77587056499993, 10.877488025000048],
              [-74.7758330449999 , 10.877621643000055],
              [-74.77579642199993, 10.877752065000038],
              [-74.77564476399994, 10.878292152000085],
              [-74.77558347999991, 10.878510400000039],
              [-74.77557908099993, 10.878526065000074],
              [-74.7754273619999 , 10.87909922700004 ],
              [-74.77541846799994, 10.879132828000081],
              [-74.77575009599991, 10.87918499500006 ],
              [-74.77588096699992, 10.879204327000082],
              [-74.77716587599991, 10.879353043000037],
              [-74.77877201199993, 10.879564220000077],
              [-74.77880109299991, 10.87956629200005 ],
              [-74.7787928539999 , 10.879589552000084],
              [-74.77879276399995, 10.87958980600007 ],
              [-74.7786935069999 , 10.879870064000045],
              [-74.77868256699992, 10.87990095300006 ],
              [-74.7786509199999 , 10.879909026000064],
              [-74.77859652699993, 10.880063908000068],
              [-74.77816227499994, 10.881253639000079],
              [-74.77790053399991, 10.88192881000009 ],
              [-74.77771612599992, 10.882437419000041],
              [-74.777499        , 10.883011464000049],
              [-74.77748530299993, 10.883053610000047],
              [-74.77746916099994, 10.88319792100009 ],
              [-74.77746695499991, 10.88321254200008 ],
              [-74.77746256699993, 10.883241618000056],
              [-74.7774542869999 , 10.883296485000074],
              [-74.77734471999992, 10.883217957000056],
              [-74.77719481399993, 10.882975250000072],
              [-74.7771091539999 , 10.882818206000081],
              [-74.77709344899995, 10.882736829000066],
              [-74.77677793299995, 10.882594061000077],
              [-74.77645384999994, 10.882481274000043],
              [-74.77587706899993, 10.88245272100005 ],
              [-74.77559018999995, 10.882432726000047],
              [-74.77542123299992, 10.88242095000004 ],
              [-74.77540593499992, 10.882419884000058],
              [-74.77524460799992, 10.882382765000045],
              [-74.77512182799995, 10.882389903000046],
              [-74.77499979699991, 10.882404891000078],
              [-74.77495907299993, 10.882409892000055],
              [-74.77488461799993, 10.882398378000062],
              [-74.77468210299992, 10.882367061000082],
              [-74.77465354999993, 10.882342791000042],
              [-74.7746521219999 , 10.882154337000088],
              [-74.77435373799995, 10.88200157500006 ],
              [-74.77378266699992, 10.881815978000077],
              [-74.7737154319999 , 10.88179935800008 ],
              [-74.77365560399994, 10.881784569000047],
              [-74.77361998399994, 10.881758452000042],
              [-74.7736070819999 , 10.881748992000041],
              [-74.7736221589999 , 10.88172626700009 ],
              [-74.77365120399992, 10.881682497000043],
              [-74.77366923499994, 10.881655325000054],
              [-74.77368242099993, 10.88163545100008 ],
              [-74.77371158599993, 10.881591496000055],
              [-74.7737421569999 , 10.88154542600006 ],
              [-74.77377158399992, 10.881501075000074],
              [-74.77380231299992, 10.881454767000037],
              [-74.77383959799994, 10.88141319600004 ],
              [-74.77386886999994, 10.881380566000075],
              [-74.77387674699992, 10.881371784000066],
              [-74.7739145459999 , 10.881329639000057],
              [-74.7739434689999 , 10.88129740000005 ],
              [-74.7738205039999 , 10.881261719000065],
              [-74.77377751999995, 10.881249248000074],
              [-74.7737650169999 , 10.881245618000037],
              [-74.77375735599992, 10.88124339400008 ],
              [-74.77374300499991, 10.881237242000054],
              [-74.77371089799993, 10.881223478000038],
              [-74.77365668399995, 10.881214605000082],
              [-74.7735993469999 , 10.881209112000079],
              [-74.77354538799995, 10.88120394100008 ],
              [-74.77348723099993, 10.881198372000085],
              [-74.77343224499992, 10.881193103000044],
              [-74.77337743699991, 10.88118785100005 ],
              [-74.77332255199991, 10.881189319000043],
              [-74.77330041899995, 10.881189913000071],
              [-74.77329183599994, 10.881190142000037],
              [-74.77325775899993, 10.881191053000066],
              [-74.77324212699995, 10.881195474000037],
              [-74.77322649399991, 10.881199896000055],
              [-74.77317381599994, 10.88119448100008 ],
              [-74.7731271319999 , 10.881196007000085],
              [-74.77310738199992, 10.881196652000085],
              [-74.77288079199991, 10.881204058000037],
              [-74.77281516499994, 10.881202674000065],
              [-74.77278290499993, 10.881201994000037],
              [-74.77274771399993, 10.881201254000075],
              [-74.77260740699995, 10.881198300000051],
              [-74.77245729499992, 10.881186827000079],
              [-74.77231320499993, 10.881175814000073],
              [-74.77225866399993, 10.881172338000056],
              [-74.77217129499991, 10.88116677100004 ],
              [-74.7721007959999 , 10.88116227800009 ],
              [-74.77202690799993, 10.881157570000084],
              [-74.77194582899995, 10.881137299000045],
              [-74.77186225899993, 10.881116404000068],
              [-74.7717798189999 , 10.881095795000078],
              [-74.771697        , 10.881075089000092],
              [-74.77161426899994, 10.88104700100007 ],
              [-74.77154083599993, 10.881018159000064],
              [-74.77142968999993, 10.880974507000076],
              [-74.77131266799995, 10.880975379000063],
              [-74.77131107399993, 10.88097539100005 ],
              [-74.77117644099991, 10.880987253000058],
              [-74.7710843019999 , 10.880995368000072],
              [-74.77100081799995, 10.881002722000062],
              [-74.77091855499992, 10.881009967000068],
              [-74.7708421129999 , 10.881016702000068],
              [-74.77083489899991, 10.88101733600007 ],
              [-74.77075203999993, 10.881024636000063],
              [-74.77066150699994, 10.881032057000084],
              [-74.77057348699992, 10.881039268000052],
              [-74.7705550519999 , 10.881039663000081],
              [-74.7704836069999 , 10.88104119500008 ],
              [-74.77042271399995, 10.881042497000067],
              [-74.77039472799993, 10.881034745000079],
              [-74.77030900699992, 10.881011006000051],
              [-74.77022766899995, 10.880988483000067],
              [-74.77013758699991, 10.880963535000092],
              [-74.77005508399992, 10.880940359000078],
              [-74.76997512399993, 10.880917900000043],
              [-74.76989457199994, 10.880895279000072],
              [-74.7698131969999 , 10.88087242000006 ],
              [-74.76972941499992, 10.880848884000045],
              [-74.76960863099993, 10.880838740000058],
              [-74.76957993999991, 10.88082649000006 ],
              [-74.76949915599994, 10.880791996000085],
              [-74.76948575999995, 10.880786276000038],
              [-74.76947597799995, 10.880884356000081],
              [-74.76933962999993, 10.881915843000058],
              [-74.76931229999991, 10.882403191000037],
              [-74.76930611999995, 10.883004160000041],
              [-74.76932717499994, 10.883554306000065],
              [-74.76933891099992, 10.883887850000065],
              [-74.76934017199994, 10.883923701000072],
              [-74.76935002499994, 10.884203717000048],
              [-74.76936081199995, 10.884677320000037],
              [-74.76936804799993, 10.884735395000064],
              [-74.7693837619999 , 10.884861502000092],
              [-74.76945381799993, 10.885439166000083],
              [-74.76950839599994, 10.885744044000091],
              [-74.76950848999991, 10.885744569000053],
              [-74.76951430999992, 10.885777077000057],
              [-74.76952010899993, 10.885817263000092],
              [-74.76953145699991, 10.885892614000056],
              [-74.76953183199993, 10.885895101000074],
              [-74.76954302099995, 10.885969402000057],
              [-74.76955179499993, 10.88602766200006 ],
              [-74.7695544149999 , 10.886041352000063],
              [-74.76955474099992, 10.886047224000038],
              [-74.76956454899994, 10.88611235500008 ],
              [-74.7695681969999 , 10.886131143000057],
              [-74.76956826399993, 10.886131486000068],
              [-74.76961773099993, 10.886386241000082],
              [-74.76971925899994, 10.886913680000077],
              [-74.76980060199992, 10.88735887100006 ],
              [-74.76980360499994, 10.88737530800006 ],
              [-74.76983770099991, 10.887561915000049],
              [-74.76987013899992, 10.887741675000086],
              [-74.7698752849999 , 10.887770195000087],
              [-74.76988604399992, 10.887829821000082],
              [-74.76989129299994, 10.887880626000083],
              [-74.76989290699993, 10.887896253000065],
              [-74.7699126089999 , 10.888086957000041],
              [-74.76991255899992, 10.888318949000052],
              [-74.7699125549999 , 10.888337746000047],
              [-74.76991252899995, 10.888460097000063],
              [-74.76988948399992, 10.888702872000067],
              [-74.76985065199995, 10.889231477000067],
              [-74.76983735199991, 10.889383310000085],
              [-74.76983529299991, 10.889406812000061],
              [-74.76983406599993, 10.88942081600004 ],
              [-74.76981067999992, 10.889687798000068],
              [-74.76980852499992, 10.889712400000064],
              [-74.76980791999995, 10.889719304000039],
              [-74.76980455799992, 10.889757691000057],
              [-74.7697912079999 , 10.889931102000048],
              [-74.76976903399992, 10.889929493000068],
              [-74.76966062299994, 10.889921626000046],
              [-74.76957994299994, 10.889915771000062],
              [-74.76957945099991, 10.88991576400008 ],
              [-74.76957241899993, 10.889915665000046],
              [-74.76937375799992, 10.889912859000049],
              [-74.76911674599995, 10.88990993200008 ],
              [-74.76909837699992, 10.889911507000079],
              [-74.76891636399995, 10.889927111000077],
              [-74.7687261399999 , 10.889952905000087],
              [-74.76840232899991, 10.889997325000081],
              [-74.7680872279999 , 10.890043182000056],
              [-74.76782582399994, 10.890085058000068],
              [-74.76780987599994, 10.890087613000048],
              [-74.76778903799993, 10.890080657000055],
              [-74.76778520499994, 10.890079377000063],
              [-74.76773994299992, 10.89006426700007 ],
              [-74.76757757099995, 10.89001006300009 ],
              [-74.76746141699994, 10.889972725000064],
              [-74.76739752799995, 10.88996983900006 ],
              [-74.7673249259999 , 10.889976999000055],
              [-74.7670954909999 , 10.890034356000058],
              [-74.76684834699995, 10.89011800500009 ],
              [-74.76683700899991, 10.890121843000088],
              [-74.76652625399993, 10.890212190000057],
              [-74.76619225899992, 10.890316882000093],
              [-74.76593522699994, 10.890405803000078],
              [-74.76583503299992, 10.89043878800004 ],
              [-74.76561576099994, 10.89049327500004 ],
              [-74.76542844399995, 10.89052767700008 ],
              [-74.7652629019999 , 10.89056495400007 ],
              [-74.76513220899994, 10.890586451000047],
              [-74.76497539199994, 10.890593592000073],
              [-74.76477791199994, 10.890606464000086],
              [-74.76465013199993, 10.89061504700004 ],
              [-74.76455722799994, 10.89049734200006 ],
              [-74.76451257999992, 10.890446128000065],
              [-74.76449430899993, 10.89042517200005 ],
              [-74.76447569999993, 10.89042517200005 ],
              [-74.76445666599994, 10.890411847000053],
              [-74.76443762899993, 10.890392811000083],
              [-74.76440146099992, 10.890347125000062],
              [-74.76437481199991, 10.89031857200007 ],
              [-74.76436719799995, 10.890299536000043],
              [-74.7643386439999 , 10.890257658000053],
              [-74.76431580199994, 10.89024052600007 ],
              [-74.7643005729999 , 10.890223393000042],
              [-74.76429105499994, 10.890204358000062],
              [-74.76428153699993, 10.89017580400008 ],
              [-74.76427963399993, 10.890141540000059],
              [-74.76426440499995, 10.890120601000092],
              [-74.76424346599993, 10.890111083000079],
              [-74.76423394799991, 10.890088240000068],
              [-74.76424329299994, 10.890063515000065],
              [-74.76426250199995, 10.890034702000037],
              [-74.76428034799994, 10.890009717000055],
              [-74.76430889999995, 10.889949042000069],
              [-74.76430889999995, 10.889913350000086],
              [-74.76430889999995, 10.889834827000072],
              [-74.76429462399994, 10.889784859000088],
              [-74.76428034799994, 10.889763444000039],
              [-74.7642553629999 , 10.889717044000065],
              [-74.76425893099992, 10.889663505000044],
              [-74.76425893099992, 10.88962424500005 ],
              [-74.76421253299992, 10.889542154000083],
              [-74.76420539499992, 10.889431509000076],
              [-74.76419111799993, 10.889402955000037],
              [-74.76416526899993, 10.889390031000062],
              [-74.76410545699991, 10.889360125000053],
              [-74.76404834999994, 10.889292310000087],
              [-74.76402434899995, 10.889271309000037],
              [-74.76401979699995, 10.889267326000038],
              [-74.76400589799994, 10.889251884000089],
              [-74.76399555099994, 10.889240387000086],
              [-74.76398767299992, 10.889231634000055],
              [-74.7639876739999 , 10.889220503000047],
              [-74.76398690299993, 10.88921855700005 ],
              [-74.76397313899992, 10.889185731000055],
              [-74.76397011899991, 10.88917852700007 ],
              [-74.76394432199993, 10.889117001000045],
              [-74.76391396799994, 10.889069236000068],
              [-74.76387138399991, 10.888967681000054],
              [-74.76384137199994, 10.888875186000064],
              [-74.76382873199992, 10.88883622800006 ],
              [-74.76376794599992, 10.88871080000007 ],
              [-74.76368899699992, 10.888579434000064],
              [-74.76359804399993, 10.88848994500006 ],
              [-74.76349174299992, 10.888424414000042],
              [-74.763029        , 10.888329785000053],
              [-74.76261182199994, 10.888230480000061],
              [-74.76258107699994, 10.888223161000042],
              [-74.76216325599995, 10.88805668000009 ],
              [-74.7616667609999 , 10.887872432000052],
              [-74.7613276479999 , 10.887729691000061],
              [-74.76110355799995, 10.887622566000061],
              [-74.76063711799992, 10.887354541000093],
              [-74.75998296099993, 10.88700920000008 ],
              [-74.75963764799991, 10.88680069500009 ],
              [-74.7591773389999 , 10.88656853200007 ],
              [-74.75868657799992, 10.886246740000047],
              [-74.75829723999993, 10.886032012000044],
              [-74.7582686749999 , 10.88601625800004 ],
              [-74.75818987499991, 10.885972799000058],
              [-74.7577719869999 , 10.885770433000062],
              [-74.7573905029999 , 10.885615820000055],
              [-74.75705757899993, 10.88553285200004 ],
              [-74.75673072199993, 10.885455849000039],
              [-74.75649460799991, 10.88537864400007 ],
              [-74.75625848499993, 10.885295461000055],
              [-74.7559134359999 , 10.885200556000086],
              [-74.75565922599992, 10.885147309000047],
              [-74.75499350899992, 10.885035182000081],
              [-74.75456383499994, 10.88497036800004 ],
              [-74.75415238199992, 10.884935403000043],
              [-74.75388603499994, 10.884864243000038],
              [-74.75335973099993, 10.884871391000047],
              [-74.75284564599991, 10.884932329000037],
              [-74.75239208499994, 10.885005085000046],
              [-74.75217437899994, 10.88504144600006 ],
              [-74.75175116799994, 10.885155994000058],
              [-74.75154562499995, 10.885222223000085],
              [-74.7513885109999 , 10.885300305000044],
              [-74.75120109199992, 10.885354533000054],
              [-74.75100148599995, 10.885366936000082],
              [-74.75089795899993, 10.885352122000086],
              [-74.7507957279999 , 10.885337494000055],
              [-74.75063842299994, 10.885331861000054],
              [-74.7503298499999 , 10.88531460200005 ],
              [-74.75003933399995, 10.88525545400006 ],
              [-74.74990617399993, 10.885225851000087],
              [-74.74948269999993, 10.885226784000054],
              [-74.74927096099992, 10.88522725300004 ],
              [-74.74899872899994, 10.88522785400005 ],
              [-74.74875673999992, 10.885228391000055],
              [-74.74819422299993, 10.88527148500009 ],
              [-74.74768008399991, 10.88530849600005 ],
              [-74.74713552899993, 10.88527381700004 ],
              [-74.7462702869999 , 10.885209949000057],
              [-74.74525461399992, 10.885147420000067],
              [-74.74514489299992, 10.885140665000051],
              [-74.74486043999991, 10.885087474000045],
              [-74.74502315899991, 10.884812061000048],
              [-74.7450658759999 , 10.884737794000046],
              [-74.74514368399991, 10.884602519000055],
              [-74.74525218399992, 10.884428875000083],
              [-74.74544522299993, 10.88418329700005 ],
              [-74.7455475829999 , 10.883967811000048],
              [-74.74567414299992, 10.883752276000052],
              [-74.74587301499992, 10.883411013000057],
              [-74.74557066499995, 10.88347146700005 ],
              [-74.74497543699994, 10.883596690000047],
              [-74.74442177599991, 10.883713168000043],
              [-74.7443198599999 , 10.884125967000045],
              [-74.74388417799992, 10.884079090000057],
              [-74.74344244799994, 10.88403222100004 ],
              [-74.74332141199994, 10.88401454700005 ],
              [-74.74249858699994, 10.88398046900005 ],
              [-74.74182102899994, 10.883981952000056],
              [-74.74181459199991, 10.883808564000049],
              [-74.74181203199993, 10.88379853500004 ],
              [-74.74177187099991, 10.883641228000045],
              [-74.74172900399992, 10.883408126000063],
              [-74.74165593899994, 10.883199001000037],
              [-74.74157080399993, 10.883001869000054],
              [-74.74149775099994, 10.882798729000058],
              [-74.7414247879999 , 10.882631462000063],
              [-74.7413456889999 , 10.882428330000039],
              [-74.7412484489999 , 10.882231224000066],
              [-74.7411452369999 , 10.882064024000044],
              [-74.74107019399992, 10.88190424700008 ],
              [-74.74103582299995, 10.881831065000085],
              [-74.74087203399995, 10.881628118000037],
              [-74.74077730399995, 10.881460459000039],
              [-74.74075055999992, 10.881413124000062],
              [-74.7405987059999 , 10.881138400000054],
              [-74.7404529829999 , 10.880899543000055],
              [-74.74031947799995, 10.880714468000065],
              [-74.74019805499995, 10.880523392000043],
              [-74.74008284299993, 10.880398074000084],
              [-74.74003461899991, 10.880322616000058],
              [-74.73994928199994, 10.880189084000051],
              [-74.73984593499995, 10.87996208900006 ],
              [-74.73974870299992, 10.87976498100005 ],
              [-74.73954236799995, 10.87947243800005 ],
              [-74.73936618499994, 10.87913797400006 ],
              [-74.7391413279999 , 10.878678041000057],
              [-74.7390499949999 , 10.878415141000062],
              [-74.73897691299993, 10.87819406500006 ],
              [-74.73888553499995, 10.877907248000042],
              [-74.73883056799991, 10.87767417300006 ],
              [-74.7387774959999 , 10.877506439000058],
              [-74.73810402799995, 10.876001124000084],
              [-74.7381039839999 , 10.876000306000037],
              [-74.73809208599994, 10.875792724000064],
              [-74.73806360299994, 10.875335821000078],
              [-74.7380308189999 , 10.874859185000048],
              [-74.73799344299994, 10.874358425000082],
              [-74.73797384999995, 10.874113141000066],
              [-74.73795085699993, 10.873825295000074],
              [-74.73785852399993, 10.872751204000053],
              [-74.73765130499993, 10.870466079000039],
              [-74.73758894899993, 10.869758649000062],
              [-74.73756489099992, 10.869466678000038],
              [-74.73753350099992, 10.869084881000049],
              [-74.73751342999992, 10.868840757000044],
              [-74.73748983599995, 10.868521945000055],
              [-74.73745243399992, 10.868014105000043],
              [-74.73743904399993, 10.867811055000061],
              [-74.73742104799993, 10.867535928000052],
              [-74.73740898699992, 10.86733171800006 ],
              [-74.73739387299992, 10.867072753000059],
              [-74.73738349299992, 10.86687177400006 ],
              [-74.73737100199992, 10.866625722000038],
              [-74.73736233799991, 10.866427499000054],
              [-74.73735231099994, 10.866192396000088],
              [-74.73734542599993, 10.865997210000046],
              [-74.73733771099995, 10.86577049500005 ],
              [-74.73733263899993, 10.86557838400006 ],
              [-74.73732718499991, 10.865359334000061],
              [-74.7373239609999 , 10.865170026000044],
              [-74.73732070099993, 10.864957688000061],
              [-74.73731934999995, 10.864771207000047],
              [-74.73731827299991, 10.864569545000052],
              [-74.73731925499993, 10.864331032000052],
              [-74.73732086899992, 10.864069440000037],
              [-74.73732100199993, 10.864058603000046],
              [-74.7373287069999 , 10.86365937100004 ],
              [-74.73732972399995, 10.863608318000047],
              [-74.7373439019999 , 10.863179162000051],
              [-74.73736066299995, 10.862815007000052],
              [-74.73736300799993, 10.862769023000055],
              [-74.73738406899992, 10.862417049000044],
              [-74.73738762699992, 10.862363152000057],
              [-74.73741296999992, 10.862021298000059],
              [-74.73741747299994, 10.861966109000036],
              [-74.73744742799994, 10.861629209000057],
              [-74.73745663299991, 10.86153500100005 ],
              [-74.7374654809999 , 10.861446752000063],
              [-74.73747724599991, 10.86133576800006 ],
              [-74.7374865509999 , 10.86124966300008 ],
              [-74.73749955399995, 10.861135983000054],
              [-74.73750898399993, 10.861054617000093],
              [-74.7375241009999 , 10.860931644000061],
              [-74.73753290199994, 10.86086061900005 ],
              [-74.7375580289999 , 10.860669735000045],
              [-74.73755835899993, 10.860667288000059],
              [-74.73758495999994, 10.860477889000038],
              [-74.73759431399992, 10.860414929000058],
              [-74.73761324599991, 10.860288180000055],
              [-74.73762519799993, 10.860212064000052],
              [-74.73764297799994, 10.860099845000093],
              [-74.73765634199992, 10.860019143000045],
              [-74.73767419599994, 10.859912635000057],
              [-74.73768859199993, 10.859830053000053],
              [-74.73770688499991, 10.859726715000079],
              [-74.73772215399993, 10.859643308000045],
              [-74.73774106199994, 10.859541923000052],
              [-74.73775709299991, 10.859458417000042],
              [-74.7377767559999 , 10.859358135000036],
              [-74.73779346999993, 10.859275019000052],
              [-74.73781395999993, 10.859175483000058],
              [-74.73783127999991, 10.859093078000058],
              [-74.7378527489999 , 10.858993565000048],
              [-74.73787054899992, 10.858912445000044],
              [-74.73789317399991, 10.858812227000044],
              [-74.73791129199992, 10.858733047000044],
              [-74.73793532999991, 10.858631141000046],
              [-74.7379535149999 , 10.858554833000085],
              [-74.73797538899993, 10.858465979000073],
              [-74.73797960699994, 10.858448846000044],
              [-74.7379972199999 , 10.85837779700006 ],
              [-74.73802721899995, 10.85826088500005 ],
              [-74.73804239899994, 10.858201955000084],
              [-74.73808910899993, 10.858027099000083],
              [-74.73818583199994, 10.85768581800005 ],
              [-74.73829394799992, 10.857330355000045],
              [-74.73841458899994, 10.85695694900005 ],
              [-74.73855016699991, 10.856558119000056],
              [-74.73867179799993, 10.856213579000041],
              [-74.73881086099993, 10.855830161000085],
              [-74.73927034099995, 10.854593621000049],
              [-74.73943164899993, 10.85415413700008 ],
              [-74.73958117299992, 10.853736003000051],
              [-74.73971215099994, 10.853356352000048],
              [-74.73983039399991, 10.852998152000055],
              [-74.73993688699994, 10.85265820300009 ],
              [-74.74003305399992, 10.852331952000043],
              [-74.74011964699991, 10.852016807000041],
              [-74.74022026799992, 10.851628076000054],
              [-74.74025219899994, 10.851500301000044],
              [-74.74031767499991, 10.851236381000092],
              [-74.74035441899991, 10.851083556000049],
              [-74.74041190899993, 10.85084147100008 ],
              [-74.7404494189999 , 10.850679137000043],
              [-74.74050307599992, 10.850442778000058],
              [-74.74053974499992, 10.850277491000043],
              [-74.7405913799999 , 10.850039177000042],
              [-74.74062619599994, 10.849875507000093],
              [-74.7406772679999 , 10.849628126000084],
              [-74.740709        , 10.84947234000009 ],
              [-74.74076299199993, 10.849197401000083],
              [-74.74078822099995, 10.849067855000044],
              [-74.74086377799995, 10.848662563000062],
              [-74.74086697599995, 10.848644548000038],
              [-74.74093620799994, 10.84825242700009 ],
              [-74.74095912999991, 10.84811637900009 ],
              [-74.74100507999992, 10.847840942000062],
              [-74.74103079199995, 10.847680165000043],
              [-74.7410706519999 , 10.84742658500005 ],
              [-74.74109641599995, 10.847256434000087],
              [-74.74113296599995, 10.847008868000046],
              [-74.74115772499994, 10.846835759000044],
              [-74.74119219599993, 10.84658636100005 ],
              [-74.74121519099992, 10.846415601000047],
              [-74.74124867199993, 10.846155808000049],
              [-74.74126904899992, 10.845994575000077],
              [-74.7413036399999 , 10.84570541000005 ],
              [-74.74131935899993, 10.845572403000062],
              [-74.7413658779999 , 10.845151344000044],
              [-74.7413694249999 , 10.845116665000091],
              [-74.74140930699991, 10.844723211000087],
              [-74.74142277399994, 10.844579845000055],
              [-74.74144903599995, 10.844295499000054],
              [-74.74146345199995, 10.84412786100006 ],
              [-74.7414853439999 , 10.843865377000043],
              [-74.74149913999992, 10.843688721000092],
              [-74.74151826299993, 10.843432151000059],
              [-74.74153083999994, 10.843253039000047],
              [-74.74154781699991, 10.842994806000092],
              [-74.74155884399994, 10.84281816400005 ],
              [-74.7415741399999 , 10.84254971100006 ],
              [-74.74158325199994, 10.842383186000063],
              [-74.74159774999993, 10.842082858000083],
              [-74.74160410499991, 10.841947705000052],
              [-74.74162139399994, 10.841511707000052],
              [-74.74162306399995, 10.841458999000054],
              [-74.7416350229999 , 10.841074064000054],
              [-74.7416386559999 , 10.840923369000052],
              [-74.74164515299992, 10.840634980000061],
              [-74.74164802699994, 10.840461301000062],
              [-74.74165177599991, 10.840193811000063],
              [-74.74165341699995, 10.840012104000039],
              [-74.7416548779999 , 10.83975040100006 ],
              [-74.74165515999994, 10.839566451000053],
              [-74.74165441299994, 10.839304385000048],
              [-74.74165331899991, 10.839120778000051],
              [-74.74165027599992, 10.838850198000046],
              [-74.7416479339999 , 10.838676186000043],
              [-74.74164209299994, 10.838378428000055],
              [-74.7416390219999 , 10.83823159900004 ],
              [-74.74162659299992, 10.837786924000056],
              [-74.74162208799993, 10.83769598400005 ],
              [-74.74161435799994, 10.837603353000077],
              [-74.74160338899992, 10.837508868000043],
              [-74.74158915699991, 10.837412320000055],
              [-74.74157164499991, 10.837313570000049],
              [-74.7415507849999 , 10.837212202000046],
              [-74.74152650099995, 10.83710786000006 ],
              [-74.7414986739999 , 10.837000021000051],
              [-74.74146927399994, 10.836895347000052],
              [-74.74143639999994, 10.836786240000038],
              [-74.74139974799994, 10.836671697000043],
              [-74.7413587179999 , 10.836549829000091],
              [-74.74127167299991, 10.836305336000066],
              [-74.74109423399995, 10.835827800000061],
              [-74.74102224899991, 10.83562954200005 ],
              [-74.7409508579999 , 10.835422472000062],
              [-74.74089167799991, 10.835236611000084],
              [-74.74085971499994, 10.835127588000091],
              [-74.74083123699995, 10.83502276900009 ],
              [-74.74080604099993, 10.83492141000005 ],
              [-74.7407840109999 , 10.83482304000006 ],
              [-74.74076502699995, 10.834727117000057],
              [-74.74074906499993, 10.834633479000047],
              [-74.74073608099991, 10.834541872000045],
              [-74.74072613399994, 10.834452880000072],
              [-74.7407260459999 , 10.834452093000039],
              [-74.74080286799995, 10.83445271700009 ],
              [-74.74141220199994, 10.834451392000062],
              [-74.74323854199992, 10.834447423000086],
              [-74.74946820899993, 10.834433789000059],
              [-74.75254973599993, 10.834426997000037],
              [-74.7529277239999 , 10.834426163000046],
              [-74.75296087999993, 10.834406393000052],
              [-74.75313267899992, 10.834321001000092],
              [-74.75330446499993, 10.834235622000051],
              [-74.75347625899991, 10.834150229000045],
              [-74.75364808099994, 10.83408184600006 ],
              [-74.75381987499992, 10.833996454000044],
              [-74.7540089009999 , 10.83392802800006 ],
              [-74.75418068499994, 10.833842641000047],
              [-74.75435251699992, 10.833774253000058],
              [-74.75452430199994, 10.83368887000006 ],
              [-74.75467885699993, 10.833586515000093],
              [-74.75483340999995, 10.83348416800004 ],
              [-74.75507390999991, 10.833364626000048],
              [-74.75524566199994, 10.83326223000006 ],
              [-74.75531407099993, 10.833092068000042],
              [-74.7554341089999 , 10.832938787000046],
              [-74.75557138499994, 10.832802469000057],
              [-74.75569134699992, 10.83261518800009 ],
              [-74.7558113799999 , 10.83246190500006 ],
              [-74.75594861499991, 10.832308588000046],
              [-74.75593099299994, 10.832121611000048],
              [-74.75591337799995, 10.83193463300006 ],
              [-74.75587855399993, 10.831747699000061],
              [-74.75584373599992, 10.831560749000062],
              [-74.75584319299992, 10.831322736000061],
              [-74.75582549399991, 10.831101751000062],
              [-74.75582506899991, 10.830914737000057],
              [-74.75579025299993, 10.830727799000044],
              [-74.75575542799993, 10.830540860000042],
              [-74.75575500199994, 10.830353840000043],
              [-74.75568582599993, 10.830183981000062],
              [-74.75558225599991, 10.830014191000089],
              [-74.7554615169999 , 10.829861451000056],
              [-74.75534078899994, 10.829708703000051],
              [-74.75518577299994, 10.829607041000088],
              [-74.75516854199992, 10.829590077000091],
              [-74.75503091999991, 10.829573376000042],
              [-74.75485878299992, 10.829505753000092],
              [-74.75472085199993, 10.829353040000058],
              [-74.75461728099992, 10.829183261000082],
              [-74.75454806699992, 10.828996399000062],
              [-74.75453044499994, 10.828809412000055],
              [-74.75451282099993, 10.82862244100005 ],
              [-74.75439208999995, 10.828469686000062],
              [-74.75427135799993, 10.828316950000044],
              [-74.75411630899993, 10.828198283000063],
              [-74.7539612569999 , 10.828079608000053],
              [-74.75380624899992, 10.827977949000058],
              [-74.75359972399991, 10.82791039600005 ],
              [-74.75346183099992, 10.827774686000055],
              [-74.75330674299994, 10.827639021000039],
              [-74.7531516919999 , 10.827520355000047],
              [-74.7530309629999 , 10.827367603000084],
              [-74.75299603499991, 10.827129658000047],
              [-74.7529612109999 , 10.826942715000087],
              [-74.75297798899993, 10.826755665000064],
              [-74.75297756599991, 10.82656865000007 ],
              [-74.75302873599992, 10.826381524000055],
              [-74.75316581599992, 10.826160196000046],
              [-74.75326849899994, 10.825938947000054],
              [-74.75331967199992, 10.825751819000061],
              [-74.75338808299995, 10.825581657000043],
              [-74.75347367899991, 10.825411450000047],
              [-74.75354208399995, 10.82524128600005 ],
              [-74.75362769299994, 10.82507107400005 ],
              [-74.7537305379999 , 10.82491783200004 ],
              [-74.75385057299991, 10.824764561000052],
              [-74.7539878419999 , 10.824628244000053],
              [-74.75414231999991, 10.824491889000058],
              [-74.7542795889999 , 10.824355574000037],
              [-74.75438242699994, 10.82420232800007 ],
              [-74.75446803099993, 10.824032129000045],
              [-74.75455362699995, 10.823861922000049],
              [-74.75460480499993, 10.823674794000055],
              [-74.75465596999993, 10.823487661000058],
              [-74.75465554899995, 10.82330064000007 ],
              [-74.75462072399995, 10.823113702000057],
              [-74.75460352999994, 10.823113740000053],
              [-74.7546200619999 , 10.823090746000048],
              [-74.75468924599994, 10.822994541000071],
              [-74.76033605399994, 10.824136495000062],
              [-74.7633452149999 , 10.82474503900005 ],
              [-74.76335355799995, 10.824746023000046],
              [-74.76377089199991, 10.824795348000066],
              [-74.76414662699995, 10.824863801000049],
              [-74.76507189799992, 10.825006606000045],
              [-74.7657394069999 , 10.825112729000068],
              [-74.76579808699995, 10.82512205800009 ],
              [-74.76642757999991, 10.825222139000061],
              [-74.76728603499993, 10.825357866000047],
              [-74.76740428099993, 10.825376561000041],
              [-74.76786467499994, 10.825455766000061],
              [-74.7678304989999 , 10.825306497000042],
              [-74.7677118229999 , 10.824877239000045],
              [-74.76766962199991, 10.824741904000064],
              [-74.7678283969999 , 10.82465241500006 ],
              [-74.76795140999991, 10.82458308200006 ],
              [-74.76861698899995, 10.82435193200007 ],
              [-74.76887136099992, 10.824263590000044],
              [-74.76953423899994, 10.824057269000093],
              [-74.77005149199994, 10.823880718000055],
              [-74.77122743899992, 10.82349593300006 ],
              [-74.7717308519999 , 10.823330737000049],
              [-74.77246050699995, 10.82307361100004 ],
              [-74.77297646699992, 10.822901124000055],
              [-74.77367626199992, 10.822656908000056],
              [-74.77480907299991, 10.822270141000047],
              [-74.77551017099995, 10.822020383000051],
              [-74.77589999299994, 10.821902184000066],
              [-74.77680324799991, 10.821586677000084],
              [-74.7778211509999 , 10.821229992000042],
              [-74.77790963199993, 10.821192548000056],
              [-74.7788804409999 , 10.820860111000059],
              [-74.77976438699994, 10.820561599000087],
              [-74.78049416199991, 10.820315416000085],
              [-74.7809667759999 , 10.820144880000043],
              [-74.7818156649999 , 10.819863086000055],
              [-74.78229958599991, 10.819692531000044],
              [-74.7831572309999 , 10.819392047000065],
              [-74.78404719499991, 10.81908581500005 ],
              [-74.78500296699991, 10.818742203000056],
              [-74.78551705299992, 10.818579014000079],
              [-74.78646559799995, 10.818250640000088],
              [-74.7867502329999 , 10.818145129000072],
              [-74.78676475899994, 10.818139745000053],
              [-74.78759816299993, 10.81785416200006 ],
              [-74.7885892509999 , 10.817516216000058],
              [-74.7889099219999 , 10.817398929000092],
              [-74.78954658299995, 10.817166068000063],
              [-74.78958238699994, 10.817152973000077],
              [-74.78964300199993, 10.817130804000044],
              [-74.7896985399999 , 10.817110486000047],
              [-74.78996395199994, 10.817023432000042],
              [-74.78997073799991, 10.817021207000039],
              [-74.79040860399994, 10.816877589000057],
              [-74.79081108999992, 10.816725724000037],
              [-74.79094489399995, 10.816680685000051],
              [-74.7917560759999 , 10.816401401000064],
              [-74.79203425399993, 10.816293143000053],
              [-74.79222972199995, 10.816237064000063],
              [-74.79302051799993, 10.81594870400005 ],
              [-74.79332369899993, 10.815846036000039],
              [-74.79390310899993, 10.81564213900009 ],
              [-74.79435590199995, 10.815502493000054],
              [-74.79474222599993, 10.815354294000088],
              [-74.79522986099994, 10.815198339000062],
              [-74.79525429499995, 10.815190525000048],
              [-74.7957272989999 , 10.815007583000067],
              [-74.79630403899995, 10.814817012000049],
              [-74.79687403999992, 10.814631147000057],
              [-74.79719203499991, 10.814518467000084],
              [-74.79775866999995, 10.814316717000054],
              [-74.79827070199991, 10.81413961800007 ],
              [-74.79882981199995, 10.813943044000041],
              [-74.79937001799993, 10.813775169000053],
              [-74.79937422899991, 10.813773868000055],
              [-74.79974072899995, 10.81364241600005 ],
              [-74.80022137399993, 10.813459583000053],
              [-74.8006687589999 , 10.81331728400005 ],
              [-74.80102032999991, 10.813198575000058],
              [-74.80165667599994, 10.81298040900009 ],
              [-74.80262874599993, 10.812663735000058],
              [-74.80320674199993, 10.812444175000053],
              [-74.80344888899992, 10.812427077000052],
              [-74.80352974699991, 10.812397309000062],
              [-74.80353526499994, 10.81239527200006 ],
              [-74.80393751999992, 10.812338630000056],
              [-74.80471090899994, 10.812249544000053],
              [-74.80492216599993, 10.812235790000045],
              [-74.80531038599992, 10.812239469000076],
              [-74.80534508599993, 10.812239798000064],
              [-74.80534655899993, 10.81224018100005 ],
              [-74.80613669799993, 10.812244623000083],
              [-74.8077919879999 , 10.812280782000073],
              [-74.80779211899994, 10.812284542000043],
              [-74.80779212999994, 10.812284859000044],
              [-74.8077956489999 , 10.81228457900005 ],
              [-74.80782955299992, 10.812284868000063],
              [-74.80784933799993, 10.812285041000052],
              [-74.80793126499992, 10.812285752000037],
              [-74.8082083729999 , 10.812288159000047],
              [-74.80825632299991, 10.81228857700006 ],
              [-74.80838973699991, 10.812289734000046],
              [-74.82235565799994, 10.812410513000088],
              [-74.82245633999992, 10.812411376000057],
              [-74.82262817399993, 10.812412857000083],
              [-74.82337542599993, 10.812419299000055],
              [-74.8233758049999 , 10.81241930100009 ],
              [-74.8286512439999 , 10.81246475000006 ],
              [-74.83037626999993, 10.812479581000048],
              [-74.83063964099995, 10.812481845000093],
              [-74.83065232399991, 10.81248196000007 ],
              [-74.83071091699992, 10.812482467000052],
              [-74.8311155099999 , 10.812485938000066],
              [-74.8314442379999 , 10.812488761000054],
              [-74.83164259299991, 10.812490471000046],
              [-74.83189443099991, 10.812492636000059],
              [-74.83189540799992, 10.812492642000052],
              [-74.83258581299992, 10.812499529000092],
              [-74.83264567399993, 10.812500123000063],
              [-74.83489889299995, 10.81252255000004 ],
              [-74.83523879499995, 10.81252593000005 ],
              [-74.83592117899991, 10.81253272300006 ],
              [-74.83644675699992, 10.812537950000092],
              [-74.837473        , 10.812548156000048],
              [-74.83753866399991, 10.812548809000077],
              [-74.83791534799991, 10.812552555000082],
              [-74.83807738799993, 10.812554164000062],
              [-74.83812973599993, 10.812555003000057],
              [-74.83812892999993, 10.812559061000059],
              [-74.83812668299993, 10.812570368000081],
              [-74.8393315159999 , 10.812584142000048],
              [-74.83936079999995, 10.812585518000049],
              [-74.83937990699991, 10.812586415000055],
              [-74.8394970999999 , 10.812587633000078],
              [-74.83951820899995, 10.812587852000092],
              [-74.84000379299994, 10.81259408200009 ],
              [-74.84040897999995, 10.812599281000075],
              [-74.84046227999994, 10.81259996500006 ],
              [-74.84073812299994, 10.812603504000037],
              [-74.84161585699991, 10.812601982000047],
              [-74.84196609999992, 10.812601374000053],
              [-74.84211806199994, 10.812601111000049],
              [-74.8427961189999 , 10.812599935000037],
              [-74.84291791099992, 10.812602076000076],
              [-74.84358507299993, 10.812613805000069],
              [-74.8435846989999 , 10.812608860000068],
              [-74.84378259699992, 10.812610824000046],
              [-74.84415951699992, 10.812614564000057],
              [-74.84420772499993, 10.812615042000061],
              [-74.84450372599991, 10.812617978000048],
              [-74.84478577199991, 10.812620777000063],
              [-74.8461754299999 , 10.812634565000053],
              [-74.84737085899991, 10.812646416000064],
              [-74.84819848699993, 10.812654616000088],
              [-74.84819962199992, 10.812654627000086],
              [-74.84931816499994, 10.812665708000054],
              [-74.85389767999993, 10.81271103000006 ],
              [-74.85640914499993, 10.812735854000039],
              [-74.85748953599995, 10.812746531000073],
              [-74.85960114199992, 10.81276738400004 ],
              [-74.86263175899995, 10.81279728900006 ],
              [-74.86901085199992, 10.812860127000079],
              [-74.86918530399993, 10.812861845000043],
              [-74.87181114899994, 10.812887665000062],
              [-74.87358137099994, 10.812905070000056],
              [-74.8749688179999 , 10.812918702000047],
              [-74.87496949399991, 10.812968916000045],
              [-74.87496991299992, 10.813000074000058],
              [-74.8782409769999 , 10.813032175000046],
              [-74.87866413099994, 10.813036327000077],
              [-74.87939013399995, 10.813043450000066],
              [-74.8807424229999 , 10.813111348000064],
              [-74.88152125199991, 10.813118054000086],
              [-74.88233915199993, 10.813125097000068],
              [-74.88230902399994, 10.81316703400006 ],
              [-74.8822978899999 , 10.813178521000054],
              [-74.88228305099994, 10.813193927000043],
              [-74.88226825999993, 10.813209381000092],
              [-74.88225352799992, 10.813224881000053],
              [-74.88223884599995, 10.813240437000047],
              [-74.8822242199999 , 10.813256036000041],
              [-74.88220964499993, 10.813271688000043],
              [-74.8821951239999 , 10.81328738600007 ],
              [-74.88218065699994, 10.813303136000059],
              [-74.88216624699993, 10.81331893500004 ],
              [-74.88215189199991, 10.813334780000048],
              [-74.88213758799992, 10.813350678000063],
              [-74.8821233349999 , 10.813366618000089],
              [-74.88210914599995, 10.813382606000062],
              [-74.88209500699992, 10.813398646000053],
              [-74.88208092499991, 10.813414733000059],
              [-74.88206689499992, 10.813430864000054],
              [-74.8820529269999 , 10.813447044000043],
              [-74.88203901199995, 10.813463270000057],
              [-74.88202515199993, 10.813479543000085],
              [-74.88201134799993, 10.813495863000071],
              [-74.88199759899993, 10.813512227000047],
              [-74.8819839109999 , 10.813528637000047],
              [-74.88197027399991, 10.813545100000056],
              [-74.88195669599992, 10.813561600000071],
              [-74.88194317499995, 10.813578151000058],
              [-74.88192971199993, 10.81359474900006 ],
              [-74.88191630199992, 10.813611386000048],
              [-74.88190295499993, 10.81362807100004 ],
              [-74.8818896649999 , 10.813644802000056],
              [-74.88187642899993, 10.813661578000051],
              [-74.8818632519999 , 10.813678397000047],
              [-74.88185013399993, 10.813695257000063],
              [-74.88183707299993, 10.813712163000048],
              [-74.88182406999994, 10.813729114000068],
              [-74.88181112399991, 10.813746111000057],
              [-74.8817982409999 , 10.813763146000042],
              [-74.88178541299993, 10.813780226000063],
              [-74.88177264699993, 10.813797350000073],
              [-74.88175993899995, 10.813814518000072],
              [-74.88174728999991, 10.81383173100005 ],
              [-74.88173469499992, 10.81384898400006 ],
              [-74.88172216499993, 10.813866275000066],
              [-74.88170969599992, 10.81388361200004 ],
              [-74.8816972809999 , 10.813900988000057],
              [-74.88168493199993, 10.813918409000053],
              [-74.88167263999992, 10.813935872000059],
              [-74.88166040899995, 10.81395337300006 ],
              [-74.88164823799991, 10.813970917000063],
              [-74.88163612699992, 10.81398850100004 ],
              [-74.88162407699991, 10.81400612400006 ],
              [-74.8816120859999 , 10.814023792000057],
              [-74.8816001589999 , 10.814041497000062],
              [-74.88158829199995, 10.814059246000056],
              [-74.88157648099991, 10.814077028000042],
              [-74.88156473799995, 10.814094856000054],
              [-74.88155305499993, 10.814112717000057],
              [-74.88154143199995, 10.814130619000082],
              [-74.88152987099994, 10.81414856400005 ],
              [-74.8815183719999 , 10.81416654900005 ],
              [-74.88150693599994, 10.81418456700004 ],
              [-74.8814955599999 , 10.814202625000064],
              [-74.88148424799994, 10.814220721000083],
              [-74.88147299699995, 10.814238860000046],
              [-74.88146520099991, 10.814251497000043],
              [-74.88144026299994, 10.81429475100009 ],
              [-74.8814046249999 , 10.814356559000089],
              [-74.88140459599992, 10.814356605000057],
              [-74.88137911699994, 10.814400741000043],
              [-74.88134341799991, 10.814462560000038],
              [-74.8813179039999 , 10.814506691000076],
              [-74.88128216899992, 10.814568490000056],
              [-74.88125663399995, 10.814612603000057],
              [-74.8812208569999 , 10.81467438900006 ],
              [-74.88120837999992, 10.814695913000037],
              [-74.88119529699992, 10.814718482000046],
              [-74.88115948299992, 10.814780246000055],
              [-74.88113389599994, 10.814824327000053],
              [-74.8810980419999 , 10.814886071000046],
              [-74.88107243199994, 10.81493013100004 ],
              [-74.88103653899992, 10.814991857000052],
              [-74.88101089999992, 10.815035900000055],
              [-74.88097497299992, 10.815097607000041],
              [-74.88094931199993, 10.815141639000046],
              [-74.88091334299992, 10.815203324000038],
              [-74.88088765599991, 10.815247339000052],
              [-74.88085165299992, 10.815309003000039],
              [-74.88082593899992, 10.815352997000048],
              [-74.88078989599995, 10.815414643000054],
              [-74.88076415699993, 10.81545862300004 ],
              [-74.88072808299995, 10.815520241000058],
              [-74.88072805299993, 10.815520290000052],
              [-74.88070230999995, 10.815564214000062],
              [-74.88066619699993, 10.815625812000064],
              [-74.88066616799995, 10.815625862000047],
              [-74.88064040299992, 10.815669770000056],
              [-74.8806042519999 , 10.815731343000039],
              [-74.8806042189999 , 10.815731406000054],
              [-74.88057843199994, 10.815775288000054],
              [-74.88054224699994, 10.815836838000052],
              [-74.88054220499993, 10.815836913000055],
              [-74.8805163959999 , 10.815880768000056],
              [-74.88048017699992, 10.81594229700005 ],
              [-74.88048012899992, 10.815942377000056],
              [-74.88047786299995, 10.815946231000055],
              [-74.8804651399999 , 10.815968216000044],
              [-74.88044375599992, 10.81600505700004 ],
              [-74.8804223059999 , 10.816041860000041],
              [-74.88040079699994, 10.81607863000005 ],
              [-74.88037922299992, 10.816115363000051],
              [-74.88035758599995, 10.816152059000046],
              [-74.88033588299993, 10.816188716000056],
              [-74.88031411799994, 10.816225337000049],
              [-74.8802922939999 , 10.816261925000049],
              [-74.88027040299994, 10.816298475000053],
              [-74.8802484499999 , 10.81633498900004 ],
              [-74.8802264329999 , 10.816371462000063],
              [-74.88020435599992, 10.816407900000058],
              [-74.88018221099992, 10.816444300000057],
              [-74.88016001199992, 10.816480665000086],
              [-74.88013774399991, 10.81651699300005 ],
              [-74.8801154169999 , 10.816553279000061],
              [-74.88009302199993, 10.816589529000055],
              [-74.88007057099992, 10.816625742000042],
              [-74.88004805399993, 10.81666191700009 ],
              [-74.88002547499991, 10.816698057000053],
              [-74.8800028359999 , 10.816734155000063],
              [-74.87998013299995, 10.816770218000045],
              [-74.87995736699992, 10.816806237000037],
              [-74.87993453699994, 10.816842223000037],
              [-74.87991165299991, 10.816878169000063],
              [-74.87988870099991, 10.816914077000092],
              [-74.87986568799994, 10.81694994500009 ],
              [-74.87984261299994, 10.816985774000045],
              [-74.87981947799994, 10.817021565000061],
              [-74.87979627999994, 10.81705732100005 ],
              [-74.8797730199999 , 10.81709303200006 ],
              [-74.87974969999993, 10.817128707000052],
              [-74.87972631899993, 10.817164338000055],
              [-74.87970287499991, 10.81719993900009 ],
              [-74.8796793699999 , 10.817235488000051],
              [-74.87965580399992, 10.817271007000045],
              [-74.87963217599992, 10.817306482000049],
              [-74.8796084889999 , 10.817341920000047],
              [-74.87960463799993, 10.817347667000092],
              [-74.87960181299991, 10.817351875000043],
              [-74.87957985299994, 10.81738450100005 ],
              [-74.87955782999995, 10.81741708900006 ],
              [-74.87953575299991, 10.81744963300008 ],
              [-74.8795136089999 , 10.817482139000049],
              [-74.87949140899991, 10.817514602000074],
              [-74.87946914899993, 10.817547026000057],
              [-74.87944682099993, 10.817579411000054],
              [-74.8794244419999 , 10.817611752000062],
              [-74.87940199899992, 10.817644054000084],
              [-74.87937949199994, 10.817676314000039],
              [-74.87935692699995, 10.817708537000044],
              [-74.87933430699991, 10.817740713000092],
              [-74.87931161999995, 10.81777284900005 ],
              [-74.8792888779999 , 10.817804945000091],
              [-74.87926607399993, 10.817837001000044],
              [-74.8792432059999 , 10.817869010000038],
              [-74.8792202859999 , 10.817900980000047],
              [-74.87919730199991, 10.81793291200006 ],
              [-74.87917426099995, 10.817964797000059],
              [-74.87915115499993, 10.817996644000061],
              [-74.87912799299994, 10.81802844300006 ],
              [-74.87910477499992, 10.818060204000062],
              [-74.87908149299994, 10.818091924000043],
              [-74.87905815499994, 10.818123598000057],
              [-74.87903475599995, 10.818155231000048],
              [-74.87901129799991, 10.818186819000061],
              [-74.87898778199991, 10.818218366000053],
              [-74.87896420499993, 10.818249870000045],
              [-74.87894056899995, 10.818281330000048],
              [-74.87891687799993, 10.81831274800004 ],
              [-74.8788931279999 , 10.818344121000052],
              [-74.87886931699995, 10.818375452000055],
              [-74.87884544899993, 10.818406740000057],
              [-74.87882151799994, 10.818437986000049],
              [-74.87879753699991, 10.818469183000047],
              [-74.87877349199994, 10.81850034100006 ],
              [-74.8787493879999 , 10.818531453000048],
              [-74.87872522999993, 10.818562521000047],
              [-74.87870101399994, 10.818593545000056],
              [-74.87867673699992, 10.818624528000043],
              [-74.8786524009999 , 10.818655460000059],
              [-74.87862801299991, 10.818686352000043],
              [-74.87860356399995, 10.818717198000058],
              [-74.87857905699991, 10.818747998000049],
              [-74.87855449199992, 10.818778759000054],
              [-74.87852987399992, 10.818809473000044],
              [-74.87850519299991, 10.818840137000052],
              [-74.87848045899995, 10.818870759000049],
              [-74.87845566699991, 10.818901337000057],
              [-74.8784475779999 , 10.81891128600006 ],
              [-74.8784253039999 , 10.818939607000061],
              [-74.8784020409999 , 10.818969302000085],
              [-74.8783788369999 , 10.818999034000058],
              [-74.87835568999992, 10.819028819000039],
              [-74.8783326009999 , 10.819058638000058],
              [-74.87830956999994, 10.81908850800005 ],
              [-74.87828659799993, 10.819118421000042],
              [-74.87826368499992, 10.819148379000069],
              [-74.87824082999992, 10.819178377000071],
              [-74.87821803199995, 10.819208421000042],
              [-74.87819529199993, 10.81923850600009 ],
              [-74.87817261499993, 10.81926863600006 ],
              [-74.87814999299991, 10.819298808000042],
              [-74.87812743099994, 10.819329025000059],
              [-74.8781049239999 , 10.81935928200005 ],
              [-74.87808247999993, 10.819389586000057],
              [-74.87806009999991, 10.819419929000048],
              [-74.87803776999993, 10.819450317000076],
              [-74.87801550299992, 10.819480747000057],
              [-74.8779932999999 , 10.819511220000038],
              [-74.87797114499995, 10.81954173400004 ],
              [-74.87794906099992, 10.819572287000085],
              [-74.87792703199995, 10.819602884000062],
              [-74.87790506099992, 10.819633525000086],
              [-74.8778831489999 , 10.819664207000073],
              [-74.87786130299992, 10.81969493300005 ],
              [-74.87783951099993, 10.81972569700008 ],
              [-74.8778177829999 , 10.81975649900005 ],
              [-74.87779611199994, 10.819787352000048],
              [-74.87777450099992, 10.819818239000085],
              [-74.87775295199992, 10.819849171000044],
              [-74.8777314649999 , 10.819880141000056],
              [-74.87771003199992, 10.819911149000063],
              [-74.8776886679999 , 10.81994220200005 ],
              [-74.8776673559999 , 10.819973295000068],
              [-74.87765211399994, 10.819995620000043],
              [-74.87765182599992, 10.819996113000059],
              [-74.87763934899994, 10.820017302000053],
              [-74.87762693899992, 10.82003852400004 ],
              [-74.8776145889999 , 10.820059787000048],
              [-74.87760230499993, 10.820081082000058],
              [-74.87759008499995, 10.82010241200004 ],
              [-74.87757792299993, 10.820123782000053],
              [-74.87756583599992, 10.820145187000037],
              [-74.87755380599992, 10.820166622000045],
              [-74.87754183699991, 10.820188095000049],
              [-74.87752993799995, 10.82020960500006 ],
              [-74.8775181019999 , 10.820231143000058],
              [-74.87750632899991, 10.820252724000056],
              [-74.87749461899995, 10.820274332000054],
              [-74.87748297699994, 10.820295981000072],
              [-74.8774713979999 , 10.820317660000057],
              [-74.87745988299991, 10.820339371000046],
              [-74.8774484299999 , 10.82036111800005 ],
              [-74.87743704899992, 10.820382897000059],
              [-74.87742572999991, 10.820404710000048],
              [-74.87741447399992, 10.820426556000086],
              [-74.87740328599995, 10.82044843500006 ],
              [-74.87739216499995, 10.820470344000057],
              [-74.8773811069999 , 10.82049229100005 ],
              [-74.87737011399992, 10.820514266000089],
              [-74.87735918599992, 10.820536274000062],
              [-74.87734832399991, 10.82055831100007 ],
              [-74.87733752999992, 10.820580383000049],
              [-74.87732680099992, 10.820602485000052],
              [-74.87731613699992, 10.820624619000057],
              [-74.87730553999995, 10.82064678200004 ],
              [-74.87729501199993, 10.820668979000061],
              [-74.87728454599994, 10.82069120500006 ],
              [-74.87727414699992, 10.820713463000061],
              [-74.87726381199991, 10.82073574900005 ],
              [-74.87725354799994, 10.820758065000064],
              [-74.8772433499999 , 10.820780410000054],
              [-74.87723321999994, 10.820802786000058],
              [-74.87722315499991, 10.820825192000086],
              [-74.8772131579999 , 10.820847627000092],
              [-74.87720322399991, 10.820870095000089],
              [-74.87719336399994, 10.820892582000056],
              [-74.87718356599993, 10.820915109000055],
              [-74.87717383699993, 10.820937657000059],
              [-74.8771641749999 , 10.82096023300005 ],
              [-74.87715457999991, 10.820982841000045],
              [-74.87714505399993, 10.821005477000085],
              [-74.87713559299993, 10.821028137000042],
              [-74.87712619799993, 10.821050830000047],
              [-74.87711687699993, 10.821073544000058],
              [-74.87710761999995, 10.821096290000071],
              [-74.87709843399995, 10.821119061000047],
              [-74.87708931199995, 10.821141857000043],
              [-74.8770802599999 , 10.821164681000084],
              [-74.87707127699991, 10.821187534000046],
              [-74.8770623609999 , 10.82121040900006 ],
              [-74.87705351199992, 10.821233309000093],
              [-74.8770447359999 , 10.821256239000093],
              [-74.87703602399995, 10.821279194000056],
              [-74.8770273799999 , 10.821302174000039],
              [-74.87701880899994, 10.821325175000084],
              [-74.8770103029999 , 10.82134820400006 ],
              [-74.87700186899991, 10.821371259000045],
              [-74.87699350299994, 10.821394335000036],
              [-74.87699056899993, 10.821402483000043],
              [-74.87698784699995, 10.821408374000043],
              [-74.87697923899992, 10.821426891000044],
              [-74.87697056799993, 10.821445380000057],
              [-74.87696182699995, 10.821463833000053],
              [-74.87695302299994, 10.82148225900005 ],
              [-74.87694415099992, 10.821500659000037],
              [-74.87693521299991, 10.82151901800006 ],
              [-74.87692620999991, 10.821537350000085],
              [-74.87691713999993, 10.82155564900006 ],
              [-74.87690800399992, 10.821573919000059],
              [-74.87689880399995, 10.821592157000055],
              [-74.87688953499992, 10.82161035900009 ],
              [-74.8768802049999 , 10.821628531000044],
              [-74.87687080699993, 10.821646671000053],
              [-74.87686134799992, 10.821664774000055],
              [-74.87685182199994, 10.821682845000055],
              [-74.87684222999991, 10.821700885000041],
              [-74.87683257299994, 10.821718889000067],
              [-74.87682285299991, 10.821736860000044],
              [-74.87681306499991, 10.82175479500006 ],
              [-74.87680321699992, 10.821772696000039],
              [-74.87679330199995, 10.821790563000093],
              [-74.8767833249999 , 10.821808395000062],
              [-74.87677328299992, 10.82182619300005 ],
              [-74.87676317499995, 10.821843953000041],
              [-74.87675300399991, 10.821861681000087],
              [-74.87674276999991, 10.821879368000054],
              [-74.87673247299995, 10.821897026000045],
              [-74.87672211299991, 10.821914640000045],
              [-74.87671169099991, 10.82193222300009 ],
              [-74.87670120199994, 10.82194977100005 ],
              [-74.87669065199992, 10.821967277000056],
              [-74.87668004199992, 10.821984746000055],
              [-74.87666936799991, 10.822002178000048],
              [-74.87665862999995, 10.82201957600006 ],
              [-74.87664782599995, 10.82203693200006 ],
              [-74.87663696399994, 10.82205425300009 ],
              [-74.87662604099995, 10.822071538000046],
              [-74.87661505599993, 10.822088776000044],
              [-74.87660400599992, 10.82210598000006 ],
              [-74.8765928919999 , 10.82212314800006 ],
              [-74.8765817229999 , 10.822140273000059],
              [-74.8765704889999 , 10.822157361000052],
              [-74.87655919299993, 10.82217441000006 ],
              [-74.87654783999994, 10.822191418000045],
              [-74.8765364219999 , 10.822208381000053],
              [-74.87652494399993, 10.822225313000047],
              [-74.8765134059999 , 10.822242197000037],
              [-74.87650180299994, 10.822259042000042],
              [-74.8764901479999 , 10.822275847000071],
              [-74.8764784249999 , 10.822292613000059],
              [-74.87646664699992, 10.822309337000092],
              [-74.87645480799995, 10.822326020000048],
              [-74.87644290599991, 10.82234266000006 ],
              [-74.87643094599991, 10.822359258000063],
              [-74.87641892799991, 10.822375814000054],
              [-74.87640684999991, 10.822392327000045],
              [-74.87639471199992, 10.822408798000083],
              [-74.87638251399994, 10.822425227000053],
              [-74.87637025899994, 10.822441612000091],
              [-74.87635794199991, 10.82245795600005 ],
              [-74.87634556899991, 10.822474252000063],
              [-74.87633313399994, 10.822490512000059],
              [-74.87632064399992, 10.82250672300006 ],
              [-74.8763080999999 , 10.822522891000062],
              [-74.87629548899992, 10.82253901200005 ],
              [-74.87628282899993, 10.822555095000041],
              [-74.87627010299991, 10.82257112600007 ],
              [-74.8762573269999 , 10.822587119000048],
              [-74.87624448699995, 10.822603063000088],
              [-74.87623159599991, 10.822618962000092],
              [-74.87621864499994, 10.822634816000061],
              [-74.87620762499995, 10.822648216000061],
              [-74.87620587599991, 10.822650554000063],
              [-74.8761917889999 , 10.822669319000056],
              [-74.8761776529999 , 10.822688035000056],
              [-74.87616345099991, 10.822706709000045],
              [-74.87614919299995, 10.822725343000059],
              [-74.87613487499993, 10.822743932000037],
              [-74.8761204999999 , 10.822762473000068],
              [-74.87610606999993, 10.822780976000047],
              [-74.87609157499992, 10.822799428000053],
              [-74.87607702899993, 10.822817839000038],
              [-74.87606242399994, 10.82283620600009 ],
              [-74.87604775999995, 10.82285452800005 ],
              [-74.87603303899994, 10.822872803000052],
              [-74.8760182609999 , 10.82289103700009 ],
              [-74.87600342499991, 10.822909225000046],
              [-74.87598853399993, 10.82292736700009 ],
              [-74.87597358199992, 10.82294545700006 ],
              [-74.87595857899993, 10.822963507000054],
              [-74.87594351699994, 10.82298151000009 ],
              [-74.87592839699994, 10.822999466000056],
              [-74.87591322299994, 10.823017378000088],
              [-74.8758979939999 , 10.823035244000039],
              [-74.87588270899994, 10.823053060000063],
              [-74.8758673669999 , 10.823070832000042],
              [-74.87585196899994, 10.823088554000037],
              [-74.87583651499995, 10.823106232000043],
              [-74.87582100799995, 10.823123856000052],
              [-74.87580544199994, 10.823141436000071],
              [-74.87578982499991, 10.82315897300009 ],
              [-74.87577415299995, 10.823176457000045],
              [-74.87575842399991, 10.823193895000088],
              [-74.87574264099993, 10.823211282000045],
              [-74.87572680199992, 10.823228627000049],
              [-74.8757109149999 , 10.823245917000065],
              [-74.87569496899994, 10.823263161000057],
              [-74.87567896899992, 10.82328035200004 ],
              [-74.87566291599995, 10.82329750200006 ],
              [-74.87564680699995, 10.823314597000092],
              [-74.8756306489999 , 10.823331645000053],
              [-74.87561443599992, 10.823348640000063],
              [-74.87559816999993, 10.823365589000048],
              [-74.8755818489999 , 10.82338248700006 ],
              [-74.87556547699995, 10.823399334000044],
              [-74.87554904699994, 10.823416131000045],
              [-74.87553257099995, 10.823432879000052],
              [-74.87551603999992, 10.823449573000062],
              [-74.87549946099995, 10.823466221000047],
              [-74.87548282599994, 10.823482819000048],
              [-74.87546613599994, 10.823499360000085],
              [-74.87544940099991, 10.82351585300006 ],
              [-74.87543579699991, 10.823529183000062],
              [-74.87542828699992, 10.823535058000061],
              [-74.87541262299993, 10.823547373000054],
              [-74.87539700199994, 10.823559746000058],
              [-74.8753814289999 , 10.823572178000063],
              [-74.87536590099995, 10.823584665000055],
              [-74.87535042099995, 10.823597205000056],
              [-74.87533498599993, 10.823609805000046],
              [-74.87531959699993, 10.823622456000066],
              [-74.87530425599994, 10.823635170000045],
              [-74.87528896299995, 10.82364793000005 ],
              [-74.87527371599992, 10.823660755000049],
              [-74.87525851799995, 10.823673628000051],
              [-74.87524336599995, 10.823686561000045],
              [-74.87522826299994, 10.823699542000043],
              [-74.8752132059999 , 10.823712581000052],
              [-74.8751981989999 , 10.823725674000059],
              [-74.87518324099995, 10.823738821000063],
              [-74.87516832599994, 10.823752024000044],
              [-74.87515347099992, 10.823765279000042],
              [-74.87513865599993, 10.823778588000039],
              [-74.87512389699992, 10.82379195100009 ],
              [-74.87510918499993, 10.82380537000006 ],
              [-74.87509452099994, 10.823818836000044],
              [-74.87507990799992, 10.82383235900005 ],
              [-74.87506534499994, 10.82384593200004 ],
              [-74.87505083299993, 10.823859562000052],
              [-74.8750363659999 , 10.823873241000058],
              [-74.87502195899992, 10.82388697500005 ],
              [-74.87500759699992, 10.823900756000057],
              [-74.87499328899992, 10.82391459300004 ],
              [-74.8749790299999 , 10.823928478000084],
              [-74.87496481999995, 10.823942418000058],
              [-74.87495066799994, 10.823956409000061],
              [-74.87493656499993, 10.823970450000047],
              [-74.87492251499992, 10.823984538000047],
              [-74.87490851399991, 10.82399868500005 ],
              [-74.87489456899993, 10.82401287600004 ],
              [-74.87488067499993, 10.82402712000004 ],
              [-74.87486683399993, 10.824041412000042],
              [-74.87485304599994, 10.824055758000043],
              [-74.87483930899992, 10.824070151000058],
              [-74.87482562799994, 10.824084595000045],
              [-74.874812        , 10.824099085000057],
              [-74.87479842699992, 10.824113626000042],
              [-74.8747849049999 , 10.824128219000045],
              [-74.87477143799993, 10.824142857000083],
              [-74.87475802599994, 10.824157544000059],
              [-74.87474466899994, 10.824172283000053],
              [-74.87473136499995, 10.824187066000093],
              [-74.87471811699993, 10.824201899000059],
              [-74.87470492299991, 10.82421677800005 ],
              [-74.87469178599991, 10.824231705000045],
              [-74.87467869999995, 10.824246684000059],
              [-74.8746656749999 , 10.824261703000047],
              [-74.8746549409999 , 10.824274161000062],
              [-74.87465269499995, 10.824276781000037],
              [-74.87463977999994, 10.824291900000048],
              [-74.87462691799993, 10.824307063000049],
              [-74.87461410799995, 10.824322275000043],
              [-74.87460136099992, 10.824337528000058],
              [-74.87458867499993, 10.824352831000056],
              [-74.87457603999991, 10.824368177000053],
              [-74.87456345999993, 10.824383571000055],
              [-74.87456107799994, 10.824386501000049],
              [-74.87456012399991, 10.824387775000048],
              [-74.87454864699993, 10.824403195000059],
              [-74.87453722899994, 10.82441865900006 ],
              [-74.87452586899991, 10.824434162000045],
              [-74.87451456999992, 10.824449707000042],
              [-74.87450332899994, 10.824465298000064],
              [-74.87449214299994, 10.82448093000005 ],
              [-74.87448102399992, 10.824496603000057],
              [-74.87446996699992, 10.824512317000085],
              [-74.8744589659999 , 10.824528075000046],
              [-74.87444802599992, 10.824543866000056],
              [-74.87443714099993, 10.82455970600006 ],
              [-74.87442632499994, 10.824575584000058],
              [-74.87441556499994, 10.824591501000043],
              [-74.8744048659999 , 10.824607459000049],
              [-74.87439423399991, 10.824623461000044],
              [-74.87438365699995, 10.824639492000074],
              [-74.87437314499994, 10.824655570000061],
              [-74.87436269199992, 10.82467169000006 ],
              [-74.87435230099993, 10.824687844000039],
              [-74.87434197099992, 10.82470403900004 ],
              [-74.87433170499992, 10.824720270000057],
              [-74.87432150099994, 10.82473654100005 ],
              [-74.87431135799994, 10.824752849000049],
              [-74.87430127499994, 10.824769199000059],
              [-74.87429126199993, 10.82478558300005 ],
              [-74.87428130699993, 10.824802003000059],
              [-74.8742714139999 , 10.824818462000053],
              [-74.87426158699992, 10.824834955000085],
              [-74.8742518169999 , 10.824851487000046],
              [-74.87424211899992, 10.82486805700006 ],
              [-74.87423248099992, 10.824884662000045],
              [-74.8742229049999 , 10.824901302000058],
              [-74.87421339199994, 10.824917975000062],
              [-74.87420394499992, 10.824934688000042],
              [-74.8741945619999 , 10.824951436000049],
              [-74.87418524199995, 10.824968217000048],
              [-74.8741759859999 , 10.824985033000075],
              [-74.87416679599994, 10.825001886000052],
              [-74.87415766599992, 10.825018771000089],
              [-74.87414860699994, 10.82503569000005 ],
              [-74.87413960999993, 10.82505264200006 ],
              [-74.87413067699993, 10.825069627000062],
              [-74.87412181099995, 10.825086645000056],
              [-74.87411300799994, 10.825103700000057],
              [-74.87410427099991, 10.82512078800005 ],
              [-74.87409559799994, 10.82513790300004 ],
              [-74.87408699499991, 10.82515505400005 ],
              [-74.8740784549999 , 10.82517223900004 ],
              [-74.87406997999994, 10.82518944900005 ],
              [-74.87406726799992, 10.825194990000057],
              [-74.87406579999993, 10.825197910000043],
              [-74.8740593409999 , 10.825210646000073],
              [-74.87405281799994, 10.82522335100009 ],
              [-74.87404622999992, 10.825236019000045],
              [-74.8740395769999 , 10.825248655000053],
              [-74.87403285799991, 10.825261260000048],
              [-74.87402607099995, 10.825273827000046],
              [-74.87401922899994, 10.825286362000043],
              [-74.87401231599995, 10.825298864000047],
              [-74.87400533699991, 10.825311325000087],
              [-74.87399829899994, 10.825323755000056],
              [-74.87399119799994, 10.825336151000045],
              [-74.8739840309999 , 10.825348507000058],
              [-74.87397679999992, 10.825360832000058],
              [-74.87396950599992, 10.825373118000073],
              [-74.87396214599994, 10.825385365000045],
              [-74.87395472799994, 10.825397576000057],
              [-74.87394724699993, 10.825409752000041],
              [-74.87393970099993, 10.82542188800005 ],
              [-74.87393209499993, 10.825433988000043],
              [-74.87392442599992, 10.82544604900005 ],
              [-74.87391669699991, 10.82545807200006 ],
              [-74.87390890299991, 10.82547005300006 ],
              [-74.8739010509999 , 10.825481997000054],
              [-74.8738931339999 , 10.825493900000083],
              [-74.87388515799995, 10.82550576500006 ],
              [-74.87387711899993, 10.82551759000006 ],
              [-74.87386902299994, 10.825529372000062],
              [-74.87386086499993, 10.825541113000043],
              [-74.87385264199992, 10.825552818000062],
              [-74.87384436699995, 10.825564477000057],
              [-74.8738360289999 , 10.825576095000088],
              [-74.8738276329999 , 10.825587669000072],
              [-74.87381917399995, 10.82559920500006 ],
              [-74.87381065799991, 10.825610698000048],
              [-74.87380208199994, 10.825622147000047],
              [-74.87379344899995, 10.825633548000042],
              [-74.87378475499992, 10.825644910000051],
              [-74.8737760059999 , 10.82565623000005 ],
              [-74.87376719499991, 10.82566750300009 ],
              [-74.87375832599992, 10.82567873700009 ],
              [-74.87374940299992, 10.825689916000044],
              [-74.87374042399995, 10.82570105700006 ],
              [-74.87373138599992, 10.825712151000062],
              [-74.87372228899994, 10.825723200000084],
              [-74.87371313799991, 10.82573420500006 ],
              [-74.87370392899993, 10.825745160000054],
              [-74.87369466399991, 10.825756072000047],
              [-74.87368534199993, 10.825766934000058],
              [-74.87367596699994, 10.825777754000057],
              [-74.87366653299995, 10.825788527000043],
              [-74.87365704599995, 10.825799246000088],
              [-74.8736475039999 , 10.82580992000004 ],
              [-74.87363790599994, 10.825820546000045],
              [-74.87362825699995, 10.825831125000093],
              [-74.87361855099994, 10.825841654000044],
              [-74.87360879099992, 10.825852134000058],
              [-74.87359897899995, 10.825862567000058],
              [-74.87358911299992, 10.825872952000054],
              [-74.87357918799995, 10.825883284000042],
              [-74.87356921799994, 10.825893567000037],
              [-74.8735610949999 , 10.825901864000059],
              [-74.87356033099991, 10.825902735000057],
              [-74.87355118499994, 10.82591321700005 ],
              [-74.8735420989999 , 10.825923748000037],
              [-74.87353306799992, 10.825934326000038],
              [-74.87352409499994, 10.825944951000054],
              [-74.87351517499991, 10.825955621000048],
              [-74.8735063119999 , 10.825966339000047],
              [-74.87349750699991, 10.825977101000092],
              [-74.87348875899994, 10.825987909000048],
              [-74.87348006799994, 10.82599876100005 ],
              [-74.8734714329999 , 10.826009658000089],
              [-74.87346285799993, 10.82602059900006 ],
              [-74.8734543409999 , 10.826031585000067],
              [-74.8734458799999 , 10.826042615000063],
              [-74.87343747699992, 10.826053690000037],
              [-74.8734291369999 , 10.826064805000044],
              [-74.87342085199992, 10.826075968000055],
              [-74.87341262899992, 10.82608717000005 ],
              [-74.8734044659999 , 10.826098414000057],
              [-74.8733963599999 , 10.826109703000043],
              [-74.87338831499994, 10.82612103200006 ],
              [-74.87338032999992, 10.826132401000052],
              [-74.87337240499994, 10.826143812000055],
              [-74.8733645399999 , 10.826155262000043],
              [-74.87335673599995, 10.826166758000056],
              [-74.87334899299992, 10.826178287000062],
              [-74.87334131099993, 10.826189861000046],
              [-74.87333369099991, 10.826201476000051],
              [-74.8733261289999 , 10.826213129000053],
              [-74.87331863599991, 10.82622481900006 ],
              [-74.87331119899994, 10.826236549000043],
              [-74.8733038279999 , 10.826248314000054],
              [-74.8732965129999 , 10.82626011900004 ],
              [-74.87328926899994, 10.826271964000057],
              [-74.87328208399992, 10.826283844000045],
              [-74.87327496099994, 10.82629576000005 ],
              [-74.87326789899993, 10.826307715000041],
              [-74.87326090699992, 10.82631970500006 ],
              [-74.87325397199993, 10.826331731000039],
              [-74.87324710699994, 10.82634379600006 ],
              [-74.87324030299993, 10.826355892000038],
              [-74.87323356099995, 10.826368023000043],
              [-74.87322688499995, 10.826380192000045],
              [-74.8732202729999 , 10.826392393000049],
              [-74.87321372399992, 10.826404628000091],
              [-74.87320724199992, 10.826416897000058],
              [-74.87320082699995, 10.826429202000043],
              [-74.87319447599992, 10.82644153700005 ],
              [-74.8731881889999 , 10.82645390500005 ],
              [-74.87318196399991, 10.826466306000043],
              [-74.87317581399992, 10.826478738000048],
              [-74.87316972399992, 10.826491202000057],
              [-74.87316369799993, 10.826503699000057],
              [-74.87315774399991, 10.826516224000045],
              [-74.87315185299991, 10.82652878500005 ],
              [-74.87314602799995, 10.826541373000055],
              [-74.87314026999991, 10.826553988000057],
              [-74.87313457899995, 10.826566634000073],
              [-74.87312895499991, 10.826579311000046],
              [-74.87312339499994, 10.826592017000053],
              [-74.87312141199993, 10.826596608000045],
              [-74.87311674299991, 10.82660485200006 ],
              [-74.87310659299993, 10.826622881000048],
              [-74.87309650899994, 10.826640947000044],
              [-74.8730864879999 , 10.826659043000063],
              [-74.87307652899995, 10.826677174000054],
              [-74.87306663899994, 10.826695341000061],
              [-74.87305681099991, 10.82671354200005 ],
              [-74.87304704799993, 10.826731781000092],
              [-74.87303734999995, 10.826750050000044],
              [-74.8730277169999 , 10.826768352000045],
              [-74.8730181489999 , 10.826786687000038],
              [-74.87300864299993, 10.82680505500008 ],
              [-74.87299920499993, 10.82682346100006 ],
              [-74.87298983399995, 10.82684189300005 ],
              [-74.87298052699992, 10.826860361000058],
              [-74.87297128299991, 10.826878862000058],
              [-74.87296210999995, 10.826897393000081],
              [-74.87295299899995, 10.82691595600005 ],
              [-74.8729439569999 , 10.826934548000054],
              [-74.87293497899992, 10.826953178000053],
              [-74.87292606699992, 10.826971832000083],
              [-74.87291722099991, 10.826990519000049],
              [-74.87290844199993, 10.827009240000052],
              [-74.87289972999992, 10.827027988000054],
              [-74.87289108399995, 10.827046764000045],
              [-74.87288250099994, 10.827065575000063],
              [-74.8728739899999 , 10.827084411000044],
              [-74.87286554499991, 10.827103279000085],
              [-74.8728571659999 , 10.827122176000046],
              [-74.87284885499992, 10.827141101000052],
              [-74.8728406109999 , 10.827160053000057],
              [-74.87283243299993, 10.827179039000043],
              [-74.87282432299992, 10.827198051000039],
              [-74.87281628099993, 10.827217088000054],
              [-74.87280830599991, 10.827236154000047],
              [-74.8728003999999 , 10.827255248000085],
              [-74.87279256099993, 10.827274371000044],
              [-74.87278478799993, 10.827293521000058],
              [-74.8727770879999 , 10.827312695000046],
              [-74.87276945199994, 10.827331894000054],
              [-74.87276188499993, 10.827351127000043],
              [-74.87275438699993, 10.827370381000037],
              [-74.8727469559999 , 10.827389660000051],
              [-74.87273959499993, 10.827408967000054],
              [-74.87273229999994, 10.82742829700004 ],
              [-74.87272507299991, 10.82744765700005 ],
              [-74.87271792099995, 10.827467033000062],
              [-74.87271083399992, 10.827486444000044],
              [-74.87270381499991, 10.827505874000053],
              [-74.8726968659999 , 10.82752533100006 ],
              [-74.8726899859999 , 10.827544808000084],
              [-74.87268317599995, 10.82756431200005 ],
              [-74.8726764349999 , 10.827583836000088],
              [-74.87266975799992, 10.827603389000046],
              [-74.8726631589999 , 10.827622958000063],
              [-74.87265662699991, 10.82764255300009 ],
              [-74.8726501619999 , 10.827662170000053],
              [-74.87264376799993, 10.827681811000048],
              [-74.87263744199993, 10.827701473000047],
              [-74.8726311879999 , 10.827721161000056],
              [-74.87262500399993, 10.827740867000045],
              [-74.87262108499993, 10.827753482000048],
              [-74.87261887699992, 10.827760632000093],
              [-74.87261282999992, 10.827780378000057],
              [-74.87260685799993, 10.82780014900004 ],
              [-74.87260095099992, 10.827819937000072],
              [-74.87259511899993, 10.827839746000052],
              [-74.87258935499995, 10.827859575000048],
              [-74.87258366099991, 10.82787942400006 ],
              [-74.87257804099994, 10.82789929200004 ],
              [-74.8725724869999 , 10.827919181000084],
              [-74.87256700699993, 10.827939087000061],
              [-74.87256159499992, 10.827959013000054],
              [-74.8725562539999 , 10.827978960000053],
              [-74.8725509869999 , 10.827998922000063],
              [-74.87254578499994, 10.828018908000047],
              [-74.87254065899992, 10.828038905000085],
              [-74.87253560099992, 10.828058921000093],
              [-74.87253061799993, 10.828078958000049],
              [-74.8725257019999 , 10.828099009000084],
              [-74.87252085999995, 10.828119077000053],
              [-74.87251608699995, 10.82813916300006 ],
              [-74.87251138799991, 10.828159266000057],
              [-74.87250675699994, 10.82817938100004 ],
              [-74.87250220099992, 10.828199517000087],
              [-74.87249771199993, 10.828219666000052],
              [-74.87249329899993, 10.828239832000065],
              [-74.8724889579999 , 10.82826001300009 ],
              [-74.87248468599995, 10.828280205000056],
              [-74.87248048499993, 10.828300415000058],
              [-74.8724763539999 , 10.828320638000037],
              [-74.87247281999993, 10.828338273000043],
              [-74.8724714569999 , 10.828343089000043],
              [-74.87246615599992, 10.828361653000059],
              [-74.87246079099992, 10.828380198000048],
              [-74.8724553479999 , 10.828398724000067],
              [-74.87244984199992, 10.82841723200005 ],
              [-74.87244426199993, 10.828435716000058],
              [-74.87243861199994, 10.828454178000072],
              [-74.8724328909999 , 10.82847262100006 ],
              [-74.87242710099991, 10.828491042000053],
              [-74.8724212429999 , 10.82850944200004 ],
              [-74.8724153149999 , 10.828527825000037],
              [-74.8724093159999 , 10.828546180000046],
              [-74.87240324599992, 10.82856451300006 ],
              [-74.8723971039999 , 10.828582822000044],
              [-74.8723909009999 , 10.828601114000037],
              [-74.87238462599993, 10.828619376000063],
              [-74.87237827799993, 10.828637617000084],
              [-74.8723718629999 , 10.828655836000053],
              [-74.87236537899992, 10.828674031000048],
              [-74.87235882199991, 10.828692200000091],
              [-74.87235220299993, 10.828710345000047],
              [-74.87234551299991, 10.82872846400005 ],
              [-74.8723387519999 , 10.82874656100006 ],
              [-74.87233192499991, 10.828764632000059],
              [-74.87232502699993, 10.82878267700005 ],
              [-74.8723180579999 , 10.828800699000055],
              [-74.87231102699991, 10.828818691000038],
              [-74.87230392499993, 10.828836659000046],
              [-74.87229675399993, 10.828854600000057],
              [-74.87228951799995, 10.828872515000057],
              [-74.87228220999992, 10.82889040100008 ],
              [-74.87227483899994, 10.828908261000038],
              [-74.87226739499994, 10.828926095000043],
              [-74.87225988199992, 10.828943896000055],
              [-74.8722523109999 , 10.828961674000084],
              [-74.87224466599992, 10.828979422000089],
              [-74.87223695699993, 10.828997143000038],
              [-74.87222917799994, 10.829014837000045],
              [-74.8722213289999 , 10.82903249900005 ],
              [-74.87221341799994, 10.829050128000063],
              [-74.87220543599994, 10.829067734000091],
              [-74.8721973939999 , 10.82908530800006 ],
              [-74.87218927999993, 10.829102852000062],
              [-74.87218110099991, 10.829120365000051],
              [-74.8721728559999 , 10.829137848000073],
              [-74.87216454399993, 10.82915530200006 ],
              [-74.87215616499992, 10.829172723000056],
              [-74.87214772099992, 10.829190113000038],
              [-74.87213921199992, 10.829207474000043],
              [-74.87213063399992, 10.829224803000045],
              [-74.87212199299995, 10.829242096000087],
              [-74.87211328599994, 10.829259358000058],
              [-74.87210451499993, 10.829276591000053],
              [-74.87209567399992, 10.829293788000086],
              [-74.87208677199993, 10.82931095300006 ],
              [-74.87207780099993, 10.829328084000053],
              [-74.87206876999994, 10.829345188000048],
              [-74.87205966999994, 10.829362248000052],
              [-74.87205051099994, 10.829379280000069],
              [-74.87204128199994, 10.829396279000036],
              [-74.8720319919999 , 10.829413241000054],
              [-74.87202263599994, 10.829430172000059],
              [-74.87201321099991, 10.829447066000057],
              [-74.87200373199994, 10.829463922000059],
              [-74.8719941839999 , 10.829480747000048],
              [-74.87198456999994, 10.829497533000051],
              [-74.87197489599993, 10.829514286000062],
              [-74.87196515699992, 10.829531003000056],
              [-74.8719553549999 , 10.829547683000044],
              [-74.87194548799994, 10.829564323000056],
              [-74.87193556099993, 10.829580933000045],
              [-74.87192556699995, 10.82959750000009 ],
              [-74.8719155149999 , 10.829614033000041],
              [-74.87190539799991, 10.829630529000042],
              [-74.8718952179999 , 10.829646987000046],
              [-74.87188497599993, 10.829663404000087],
              [-74.87187467099994, 10.829679786000042],
              [-74.87186430499992, 10.829696130000059],
              [-74.87185387799991, 10.829712433000054],
              [-74.87184338599991, 10.82972870000009 ],
              [-74.8718328349999 , 10.829744925000057],
              [-74.8718222199999 , 10.829761115000053],
              [-74.87181154799993, 10.82977726200005 ],
              [-74.87180080799993, 10.829793370000061],
              [-74.87179001299995, 10.82980943800004 ],
              [-74.87177915699993, 10.829825466000045],
              [-74.87176823499993, 10.829841456000054],
              [-74.87175725999992, 10.829857404000052],
              [-74.87174622099991, 10.82987330800006 ],
              [-74.87173511899994, 10.829889175000062],
              [-74.87172395899995, 10.829905001000043],
              [-74.87171274199994, 10.829920781000055],
              [-74.87170146099993, 10.82993652500005 ],
              [-74.8716901219999 , 10.829952220000052],
              [-74.87167871999992, 10.829967877000058],
              [-74.87166726099991, 10.829983492000054],
              [-74.87165574799991, 10.829999064000049],
              [-74.87164862799995, 10.830008631000055],
              [-74.87136533799992, 10.830380084000069],
              [-74.87112424199995, 10.830697611000062],
              [-74.87063024199995, 10.831440327000053],
              [-74.87029697999992, 10.831973427000037],
              [-74.87021466399995, 10.83210510300006 ],
              [-74.87003412699994, 10.832452371000045],
              [-74.86991955499991, 10.83258420900006 ],
              [-74.86976291399992, 10.832817812000087],
              [-74.86968469499993, 10.832973473000038],
              [-74.86946831699993, 10.833488257000056],
              [-74.86917377799995, 10.834144334000086],
              [-74.86917242899995, 10.834147348000045],
              [-74.8691159519999 , 10.834450914000058],
              [-74.8690611369999 , 10.83452827900004 ],
              [-74.86898744399991, 10.834621470000059],
              [-74.86892565499994, 10.834698746000072],
              [-74.86883305799995, 10.83481004500004 ],
              [-74.8687451429999 , 10.834919125000056],
              [-74.86867882099995, 10.83498942500006 ],
              [-74.86861730699991, 10.83505060300007 ],
              [-74.86854164699992, 10.835123071000055],
              [-74.86842580099994, 10.835234030000038],
              [-74.8683737419999 , 10.835286151000048],
              [-74.86830757999991, 10.83534725900006 ],
              [-74.86824149799992, 10.83540376700006 ],
              [-74.86816351899995, 10.835476200000073],
              [-74.86811406099991, 10.835512262000066],
              [-74.86806921899995, 10.83555068600009 ],
              [-74.86799379599995, 10.835609358000056],
              [-74.86793011399993, 10.835661308000056],
              [-74.86786872099992, 10.835715590000063],
              [-74.86779546899993, 10.835783494000054],
              [-74.8677105509999 , 10.83585350800007 ],
              [-74.86761857399995, 10.835928041000045],
              [-74.86752195299994, 10.836002496000049],
              [-74.86741362799995, 10.83608137400006 ],
              [-74.86732177099992, 10.83614900300006 ],
              [-74.8672487199999 , 10.836205408000069],
              [-74.86718497999993, 10.83606791200009 ],
              [-74.86663118299992, 10.836821745000066],
              [-74.8679265159999 , 10.83790322100009 ],
              [-74.8680000839999 , 10.837952594000058],
              [-74.86807821899993, 10.83800664000006 ],
              [-74.868138        , 10.838046610000049],
              [-74.86821850599995, 10.838098388000049],
              [-74.86830365799995, 10.838150229000064],
              [-74.86837498299991, 10.838194972000053],
              [-74.86847407999994, 10.838247025000044],
              [-74.86854768699993, 10.838294105000045],
              [-74.86863523599993, 10.838341379000042],
              [-74.86876677499993, 10.838400810000053],
              [-74.86890997899991, 10.83845811000009 ],
              [-74.86905337099995, 10.838503914000057],
              [-74.8691594089999 , 10.838558375000048],
              [-74.86920995799994, 10.838593607000064],
              [-74.8692650829999 , 10.838633517000062],
              [-74.86931560799991, 10.838671052000052],
              [-74.86937073399992, 10.838710956000057],
              [-74.86941652299993, 10.838753017000045],
              [-74.86946226999993, 10.838797382000052],
              [-74.8695011989999 , 10.838832454000055],
              [-74.86956070299993, 10.838888517000044],
              [-74.8696132689999 , 10.838942185000064],
              [-74.86966351299992, 10.838995813000054],
              [-74.86971138299992, 10.839051708000056],
              [-74.86976150999993, 10.839112231000058],
              [-74.86981804899995, 10.839205039000092],
              [-74.86991064599994, 10.839365071000088],
              [-74.86996262899993, 10.839453217000084],
              [-74.87001219899992, 10.83954592400005 ],
              [-74.87005045399991, 10.839620078000053],
              [-74.87009990599995, 10.839719682000066],
              [-74.87014467199992, 10.83982151300006 ],
              [-74.8702031059999 , 10.839939653000044],
              [-74.87024795199994, 10.84003688200005 ],
              [-74.87028798499995, 10.840143258000069],
              [-74.87032363999992, 10.840233462000072],
              [-74.87035654699991, 10.840348921000043],
              [-74.8703824399999 , 10.840466574000061],
              [-74.8704014249999 , 10.84057953000007 ],
              [-74.87041852599992, 10.840667160000066],
              [-74.87043338099994, 10.840750158000048],
              [-74.87044582999994, 10.840837719000092],
              [-74.87046577099994, 10.840895501000091],
              [-74.87048566399994, 10.84095558100006 ],
              [-74.87050784999991, 10.841017987000043],
              [-74.8705256259999 , 10.841066538000064],
              [-74.87054823499994, 10.841103665000048],
              [-74.87059356099991, 10.841173316000038],
              [-74.87063225199995, 10.841222174000052],
              [-74.87065735399995, 10.841250142000092],
              [-74.8707239069999 , 10.841301712000075],
              [-74.87073576799992, 10.841423761000044],
              [-74.8707457349999 , 10.841520485000046],
              [-74.87076244699995, 10.841631103000054],
              [-74.87078852499991, 10.84173725800008 ],
              [-74.87081926699994, 10.841843494000045],
              [-74.87085191599994, 10.841972737000049],
              [-74.87088711599995, 10.842090531000053],
              [-74.87093612899992, 10.842215421000049],
              [-74.87097624199993, 10.842317191000063],
              [-74.87103011399995, 10.842430662000083],
              [-74.8710817299999 , 10.842539499000054],
              [-74.8711610979999 , 10.842657938000059],
              [-74.87131417799992, 10.842952229000048],
              [-74.87136336299994, 10.84306792600006 ],
              [-74.8714099789999 , 10.843197387000089],
              [-74.87144997299993, 10.843306047000056],
              [-74.87148318599992, 10.843403117000037],
              [-74.87151857499993, 10.84350941200006 ],
              [-74.87153799899994, 10.843597074000058],
              [-74.87156400899994, 10.843707829000039],
              [-74.87158063099992, 10.843823050000083],
              [-74.87159931399992, 10.843954393000047],
              [-74.87160699599991, 10.844048789000055],
              [-74.87161755699992, 10.84424669200007 ],
              [-74.8715880659999 , 10.845423573000062],
              [-74.87158639799992, 10.845452729000044],
              [-74.87151838699992, 10.84663895600005 ],
              [-74.87148347099992, 10.846776403000092],
              [-74.87144922499994, 10.846874780000064],
              [-74.87142424399991, 10.846975581000038],
              [-74.87139453099991, 10.84708091400006 ],
              [-74.87137947299993, 10.847145082000054],
              [-74.87135677799995, 10.84724822600009 ],
              [-74.8713504879999 , 10.847275739000054],
              [-74.87133162999993, 10.847358231000044],
              [-74.87132488399993, 10.84741878400007 ],
              [-74.87132063199994, 10.847456944000044],
              [-74.87132060699992, 10.84750477700004 ],
              [-74.8713205599999 , 10.847597201000042],
              [-74.87132087599991, 10.847605538000039],
              [-74.8713253599999 , 10.847723744000064],
              [-74.87134144299995, 10.847871145000056],
              [-74.87135369399994, 10.847970205000081],
              [-74.8713686239999 , 10.84804861300006 ],
              [-74.87128129299992, 10.848530202000063],
              [-74.87125120999991, 10.848703984000053],
              [-74.87124516199992, 10.848738920000073],
              [-74.87124586899995, 10.848833218000038],
              [-74.87124456999993, 10.848909074000062],
              [-74.8712457979999 , 10.84894451200006 ],
              [-74.87124768099994, 10.84899878300007 ],
              [-74.87125307199994, 10.849090855000043],
              [-74.87126549299995, 10.849180710000041],
              [-74.8712848319999 , 10.84927297300004 ],
              [-74.87129202199992, 10.849299230000042],
              [-74.87131328299995, 10.84937687100006 ],
              [-74.87135375399993, 10.849457950000044],
              [-74.87139899699991, 10.849532201000045],
              [-74.87141932799994, 10.84956698700006 ],
              [-74.87145140899992, 10.84959505200004 ],
              [-74.8714904549999 , 10.849623227000052],
              [-74.87155728899995, 10.84965870600007 ],
              [-74.87158479399994, 10.849682100000052],
              [-74.8716372049999 , 10.849744961000056],
              [-74.87169860799992, 10.849826346000043],
              [-74.8717732739999 , 10.849947020000059],
              [-74.8718176079999 , 10.850074146000054],
              [-74.8718567009999 , 10.850235682000061],
              [-74.8718909019999 , 10.850410942000053],
              [-74.8719103599999 , 10.850496313000065],
              [-74.87194749399993, 10.850637136000046],
              [-74.87196888099993, 10.850745518000053],
              [-74.87197654999994, 10.850785066000071],
              [-74.8719881099999 , 10.850844676000065],
              [-74.87200947499991, 10.850955355000053],
              [-74.87202381199995, 10.851068249000036],
              [-74.87203029599993, 10.851147438000055],
              [-74.87203342499993, 10.851185666000049],
              [-74.87205123599995, 10.851367577000076],
              [-74.87205280899991, 10.851546961000054],
              [-74.8720550999999 , 10.851684971000054],
              [-74.87204307699994, 10.851843447000078],
              [-74.8720311379999 , 10.851997332000053],
              [-74.8720148569999 , 10.852132758000039],
              [-74.87200315899992, 10.85227285700006 ],
              [-74.87198415699993, 10.85243123500004 ],
              [-74.87195354099993, 10.852589442000067],
              [-74.87193221699994, 10.85274779100007 ],
              [-74.87192184799994, 10.852789054000084],
              [-74.87189479099993, 10.852896715000043],
              [-74.87187218899993, 10.85302875800005 ],
              [-74.87182400799992, 10.853104314000063],
              [-74.87175408999991, 10.853213960000062],
              [-74.87175077699993, 10.85321915600008 ],
              [-74.87171683299994, 10.853272386000071],
              [-74.87171115699994, 10.853343392000056],
              [-74.8716933149999 , 10.853457047000063],
              [-74.8716938259999 , 10.853648381000085],
              [-74.87166402699995, 10.85381588100006 ],
              [-74.87166431299994, 10.853923508000037],
              [-74.8716103189999 , 10.854091064000045],
              [-74.8715705919999 , 10.854187136000064],
              [-74.87156823999993, 10.854192823000062],
              [-74.87147790799992, 10.854342541000051],
              [-74.87136337599992, 10.854492320000077],
              [-74.87132734199992, 10.854588084000056],
              [-74.87127918699991, 10.85467790100006 ],
              [-74.87126739299993, 10.85479153600005 ],
              [-74.87124969199994, 10.854958999000075],
              [-74.87124999399992, 10.855072606000078],
              [-74.87125035999992, 10.855210129000056],
              [-74.87121441699992, 10.855341767000084],
              [-74.87117256699992, 10.855527231000053],
              [-74.8711245099999 , 10.855652925000072],
              [-74.87105186399992, 10.85577517900009 ],
              [-74.8710462219999 , 10.855784673000073],
              [-74.87094985199991, 10.855940385000054],
              [-74.87089704399995, 10.85599869400005 ],
              [-74.87078701199994, 10.856120186000055],
              [-74.87063031199995, 10.856335844000057],
              [-74.87054605199995, 10.85649750600004 ],
              [-74.87047388099995, 10.85665315700004 ],
              [-74.87033539499993, 10.856892686000037],
              [-74.8702814259999 , 10.857072206000055],
              [-74.87023918499995, 10.857194723000077],
              [-74.87021539699992, 10.857263714000055],
              [-74.87016134499993, 10.857407357000056],
              [-74.87011326899994, 10.85752707100005 ],
              [-74.87004712999993, 10.857676720000086],
              [-74.86999901999991, 10.85778447000007 ],
              [-74.8699509889999 , 10.857922123000037],
              [-74.8699029839999 , 10.858065750000037],
              [-74.86980069399993, 10.85826931400004 ],
              [-74.8696982269999 , 10.858407103000047],
              [-74.86961383999994, 10.858520927000086],
              [-74.86953538499995, 10.85858690200007 ],
              [-74.86943266199995, 10.858629023000049],
              [-74.8692997579999 , 10.85869514300009 ],
              [-74.86916676499993, 10.858725383000092],
              [-74.86904599099995, 10.858803427000055],
              [-74.86886482099993, 10.858917507000058],
              [-74.86873804899994, 10.85901350100005 ],
              [-74.86859312599995, 10.859109541000066],
              [-74.86843011499991, 10.859229551000055],
              [-74.86827316199992, 10.859349544000054],
              [-74.86812832399994, 10.85947548200005 ],
              [-74.8679775089999 , 10.859631333000038],
              [-74.86791120999993, 10.859721196000066],
              [-74.86774238999993, 10.859930906000045],
              [-74.86765211599993, 10.860104541000055],
              [-74.86759800999994, 10.860230247000061],
              [-74.86752581199994, 10.86037992200005 ],
              [-74.86745954799994, 10.860481741000058],
              [-74.8673811939999 , 10.86058956900007 ],
              [-74.86723641499992, 10.860739422000051],
              [-74.86716398199991, 10.860799403000044],
              [-74.86698886999994, 10.860913463000088],
              [-74.86684993299991, 10.86098557400004 ],
              [-74.8667652609999 , 10.860991777000038],
              [-74.86655355499994, 10.860998299000073],
              [-74.86640892899993, 10.860992273000079],
              [-74.86614822999991, 10.860981407000054],
              [-74.86559780399995, 10.860994787000038],
              [-74.86550046999992, 10.860991984000066],
              [-74.86535370099995, 10.860987758000078],
              [-74.86483556799993, 10.860972840000045],
              [-74.86408547999991, 10.860968791000062],
              [-74.86283334599995, 10.860977999000056],
              [-74.86240992199993, 10.860979085000054],
              [-74.86230791799994, 10.861296252000045],
              [-74.86219387199992, 10.861631382000041],
              [-74.86208591399992, 10.861984438000093],
              [-74.86197156899993, 10.862205969000058],
              [-74.86189938399991, 10.862361615000054],
              [-74.86182717799994, 10.862505302000045],
              [-74.86173068799991, 10.86261915700004 ],
              [-74.86164016099991, 10.862697121000053],
              [-74.86150137999994, 10.862829021000039],
              [-74.86141291799993, 10.862880586000074],
              [-74.86132622299993, 10.862931120000042],
              [-74.86120539599995, 10.862991225000087],
              [-74.86105264799994, 10.863047591000054],
              [-74.86104223199993, 10.863051435000045],
              [-74.8609152009999 , 10.863051763000044],
              [-74.86077605699995, 10.863046139000062],
              [-74.86055216499994, 10.863016814000048],
              [-74.8604493119999 , 10.863011104000066],
              [-74.86036343899991, 10.862992471000041],
              [-74.8603403699999 , 10.86298746500006 ],
              [-74.86018309399992, 10.862987868000062],
              [-74.86009082699991, 10.862988104000067],
              [-74.86006816399993, 10.862988165000047],
              [-74.85977175799991, 10.862988923000046],
              [-74.85960849999992, 10.86301325900007 ],
              [-74.85943923399992, 10.863055546000055],
              [-74.85920350599991, 10.863127905000056],
              [-74.8590524519999 , 10.863194062000048],
              [-74.8587262129999 , 10.863350358000048],
              [-74.85844230099991, 10.863500564000049],
              [-74.8580497719999 , 10.863752702000056],
              [-74.8577597929999 , 10.863896949000036],
              [-74.85751817199991, 10.864029108000068],
              [-74.85697431699992, 10.864245757000049],
              [-74.85657553599992, 10.864420173000042],
              [-74.85630968499993, 10.864540438000063],
              [-74.8561464849999 , 10.864588685000058],
              [-74.85593733999991, 10.86465987300005 ],
              [-74.85591709199991, 10.864629949000062],
              [-74.85589074599994, 10.864591012000062],
              [-74.85557594999995, 10.864681791000066],
              [-74.8550560569999 , 10.864808684000081],
              [-74.85479611599993, 10.864875118000043],
              [-74.8546207569999 , 10.864899482000055],
              [-74.85431840899992, 10.864942105000068],
              [-74.8541067239999 , 10.864954605000037],
              [-74.85392529399991, 10.86497300600007 ],
              [-74.8537439179999 , 10.86500933900004 ],
              [-74.85350223599994, 10.865117586000054],
              [-74.8533088659999 , 10.865195809000056],
              [-74.8530671179999 , 10.865280133000056],
              [-74.8527648399999 , 10.86534667400008 ],
              [-74.85248058999991, 10.865371309000068],
              [-74.85228288199994, 10.86542070300004 ],
              [-74.85219041699992, 10.865443804000051],
              [-74.85188213599992, 10.865528294000057],
              [-74.85142889399992, 10.865702844000054],
              [-74.8511690659999 , 10.865811131000044],
              [-74.85076428899993, 10.866009475000055],
              [-74.85041395499991, 10.866207681000049],
              [-74.85014813599992, 10.866339896000056],
              [-74.84984603399994, 10.866478185000062],
              [-74.84925384699994, 10.86672483700005 ],
              [-74.84915477499993, 10.866754488000083],
              [-74.84915390099991, 10.866754750000041],
              [-74.8491254189999 , 10.86676327400005 ],
              [-74.84908522699993, 10.866796266000051],
              [-74.84904834499991, 10.866811732000087],
              [-74.84891747499995, 10.866820060000066],
              [-74.84882110599995, 10.866856942000084],
              [-74.84877139699995, 10.866869229000088],
              [-74.84877070399995, 10.866869400000041],
              [-74.84875219499992, 10.866873976000079],
              [-74.84873450799995, 10.866878348000057],
              [-74.84873375199993, 10.866878535000069],
              [-74.84871521999992, 10.866883116000054],
              [-74.84867923199994, 10.86688674800007 ],
              [-74.8484197329999 , 10.866932172000077],
              [-74.84836068099992, 10.866944982000064],
              [-74.8483187089999 , 10.86695473800006 ],
              [-74.84830654399991, 10.866957565000064],
              [-74.84813414199994, 10.866976935000082],
              [-74.8480754759999 , 10.86698352600007 ],
              [-74.84799566599992, 10.86698462000004 ],
              [-74.84799013899993, 10.866984696000088],
              [-74.8479365149999 , 10.866985430000057],
              [-74.84781450299994, 10.866979798000045],
              [-74.84781278299994, 10.866979719000085],
              [-74.84763470699994, 10.866974249000066],
              [-74.8475749829999 , 10.866964256000074],
              [-74.84735473699993, 10.866914284000075],
              [-74.84724432999991, 10.866891441000064],
              [-74.84714915199993, 10.866880020000053],
              [-74.8470406479999 , 10.866872405000038],
              [-74.84690739799993, 10.866874309000082],
              [-74.84680871899991, 10.866922290000048],
              [-74.84680519199992, 10.866924005000044],
              [-74.84680269499995, 10.866925219000052],
              [-74.84680254799991, 10.86692529000004 ],
              [-74.8467979589999 , 10.866927522000083],
              [-74.84677375299992, 10.86693929200004 ],
              [-74.84676340999994, 10.866944913000054],
              [-74.84675689799991, 10.866948452000088],
              [-74.84652992299993, 10.867071807000059],
              [-74.84637002399995, 10.867168889000084],
              [-74.8463576229999 , 10.867173731000037],
              [-74.84627008599995, 10.867207912000083],
              [-74.8461130419999 , 10.867267875000039],
              [-74.84599990899994, 10.867315071000064],
              [-74.8459579009999 , 10.86733259600004 ],
              [-74.84583321699995, 10.867387800000074],
              [-74.84575707499994, 10.867409691000091],
              [-74.84575160299994, 10.867395571000088],
              [-74.84573490799994, 10.86737022200009 ],
              [-74.8457289509999 , 10.867361177000078],
              [-74.84569556299994, 10.86737596000006 ],
              [-74.84561942099992, 10.867397851000078],
              [-74.84548845099994, 10.867433684000048],
              [-74.84548842399994, 10.867433690000041],
              [-74.84534192699994, 10.86745820300007 ],
              [-74.8451302659999 , 10.86748265400007 ],
              [-74.8448884359999 , 10.867537073000051],
              [-74.84459233799993, 10.867657406000092],
              [-74.84447760199993, 10.86773542800006 ],
              [-74.84438096899993, 10.86779546200006 ],
              [-74.84421784199992, 10.867873605000057],
              [-74.84407281199992, 10.867933768000057],
              [-74.84386131299993, 10.868018001000053],
              [-74.8436981669999 , 10.868090167000048],
              [-74.84359465499995, 10.868139148000068],
              [-74.84320273499992, 10.868324603000076],
              [-74.8428825439999 , 10.868486850000068],
              [-74.84261674399994, 10.868631022000045],
              [-74.84249785799994, 10.868720804000077],
              [-74.84246581299993, 10.868745004000061],
              [-74.84226648099991, 10.868859111000063],
              [-74.84201892199991, 10.869039112000053],
              [-74.84152387199993, 10.869423032000043],
              [-74.84134275899993, 10.869566990000067],
              [-74.84121874599992, 10.869677873000057],
              [-74.84116169599992, 10.86972888200006 ],
              [-74.84096253299992, 10.869908759000054],
              [-74.8406848969999 , 10.870154608000064],
              [-74.84047982099992, 10.870363437000037],
              [-74.84020221299994, 10.870646125000064],
              [-74.84017343699992, 10.870677669000088],
              [-74.84002727899991, 10.870837893000044],
              [-74.83989451799994, 10.87096379400009 ],
              [-74.83971350099995, 10.871143630000063],
              [-74.8395807199999 , 10.871263549000048],
              [-74.83921243499992, 10.871545498000046],
              [-74.83894074199992, 10.871749470000054],
              [-74.83885378699995, 10.87180591300006 ],
              [-74.83890944199993, 10.87189126800007 ],
              [-74.83924016799995, 10.872045531000083],
              [-74.83959131199992, 10.872158264000063],
              [-74.83978506099993, 10.872223550000058],
              [-74.8401058849999 , 10.872306459000072],
              [-74.84032376399995, 10.872347767000065],
              [-74.8404689919999 , 10.87236534200008 ],
              [-74.84071707399994, 10.87238863600004 ],
              [-74.8408985989999 , 10.872406119000061],
              [-74.8410740729999 , 10.872423620000063],
              [-74.84115876299995, 10.872423406000053],
              [-74.84140097699992, 10.872518471000092],
              [-74.84158259399993, 10.872571828000048],
              [-74.84183081399993, 10.872648938000054],
              [-74.84202456999992, 10.872720206000054],
              [-74.84221234699993, 10.872815403000061],
              [-74.84238197199994, 10.872910646000037],
              [-74.84250923499991, 10.87300001500006 ],
              [-74.84264256499995, 10.873095353000053],
              [-74.84275778999995, 10.873208670000054],
              [-74.84281248199994, 10.873304201000053],
              [-74.8428370769999 , 10.873459599000057],
              [-74.84284952899992, 10.873597094000047],
              [-74.84278369299994, 10.87387231300005 ],
              [-74.84271781299992, 10.874129584000059],
              [-74.8426339209999 , 10.874440723000077],
              [-74.84258007699992, 10.874674052000046],
              [-74.84257740599992, 10.87474304300008 ],
              [-74.84256873299995, 10.874967062000053],
              [-74.84256882699992, 10.875003826000068],
              [-74.84256923799995, 10.875164379000068],
              [-74.84256972999992, 10.875355717000048],
              [-74.84260640899993, 10.875505109000073],
              [-74.84263691599995, 10.875606683000058],
              [-74.84273415199993, 10.875779838000085],
              [-74.84282509099995, 10.87585733900005 ],
              [-74.84298869899993, 10.875964559000067],
              [-74.8431824939999 , 10.876047784000093],
              [-74.84340051499993, 10.876142901000037],
              [-74.84358821199993, 10.876208202000043],
              [-74.8437394959999 , 10.876225760000068],
              [-74.84388472299992, 10.876243333000048],
              [-74.84404200699993, 10.876242939000065],
              [-74.84418118999992, 10.876260527000056],
              [-74.84430820799992, 10.87625422800005 ],
              [-74.84440506099992, 10.876277899000058],
              [-74.84455042299993, 10.87634928600005 ],
              [-74.8445990379999 , 10.876432879000049],
              [-74.84461144199992, 10.876552433000086],
              [-74.84461173299991, 10.876666037000064],
              [-74.84461668499995, 10.876702833000081],
              [-74.84463023899991, 10.876803514000073],
              [-74.84466070099995, 10.876887147000048],
              [-74.84470715299994, 10.876978392000069],
              [-74.84472758299995, 10.87701852300006 ],
              [-74.84486111599995, 10.877191589000063],
              [-74.84502475499994, 10.877310764000072],
              [-74.84510352199993, 10.877358400000048],
              [-74.84504365799995, 10.877603701000055],
              [-74.8449538129999 , 10.877950730000066],
              [-74.8448276069999 , 10.878273928000056],
              [-74.84467706899994, 10.878543376000039],
              [-74.8445325319999 , 10.87879487300006 ],
              [-74.84432752499993, 10.879058482000062],
              [-74.8441828519999 , 10.879256160000068],
              [-74.84406737599994, 10.879375293000066],
              [-74.84403438899994, 10.87940932400005 ],
              [-74.84402598299994, 10.879417998000065],
              [-74.84356128399992, 10.879849676000049],
              [-74.84322938299994, 10.880167415000074],
              [-74.84292160299992, 10.88045519600007 ],
              [-74.8426499759999 , 10.880689071000063],
              [-74.84254139599994, 10.880808930000057],
              [-74.8424328879999 , 10.880958685000053],
              [-74.84224609199993, 10.881246159000057],
              [-74.84199906799995, 10.881635436000067],
              [-74.84187267099992, 10.881886887000064],
              [-74.8417523629999 , 10.88215027700005 ],
              [-74.84161401699993, 10.882461546000059],
              [-74.84151778299992, 10.882677047000072],
              [-74.84133696899994, 10.882940593000058],
              [-74.84123703599994, 10.883023329000082],
              [-74.84114787099992, 10.882956859000046],
              [-74.8408902189999 , 10.883294487000057],
              [-74.84068474799994, 10.883378715000049],
              [-74.84055786699992, 10.883438824000052],
              [-74.84039469599992, 10.883505007000053],
              [-74.84021956799995, 10.883625031000065],
              [-74.84005644399991, 10.883709151000062],
              [-74.8398813039999 , 10.883823200000052],
              [-74.83973638999993, 10.883931190000055],
              [-74.83951313999995, 10.884158958000057],
              [-74.83932616999994, 10.884380663000059],
              [-74.83916355499991, 10.88466210200005 ],
              [-74.83901914699993, 10.884967402000086],
              [-74.83891089699995, 10.885218806000069],
              [-74.83882068999992, 10.885428311000055],
              [-74.83882019499993, 10.885428338000054],
              [-74.83818558299993, 10.885465770000053],
              [-74.83747190399993, 10.885533326000086],
              [-74.83705465899993, 10.885600141000054],
              [-74.83662527899992, 10.885655024000073],
              [-74.83627450999995, 10.885697751000066],
              [-74.83604473499992, 10.88574017700006 ],
              [-74.8348780899999 , 10.886101845000042],
              [-74.83404391699992, 10.886367005000068],
              [-74.83328234099992, 10.886631986000054],
              [-74.83288324199992, 10.88669875100004 ],
              [-74.83248413699994, 10.886765515000093],
              [-74.8321640069999 , 10.88681218000005 ],
              [-74.83208230299994, 10.88682409100005 ],
              [-74.83206687099994, 10.886826344000042],
              [-74.83189172599992, 10.886845497000081],
              [-74.83184309599994, 10.88685081400007 ],
              [-74.83169801299994, 10.886893025000063],
              [-74.83157717499995, 10.886953125000048],
              [-74.83133556099995, 10.88710319900008 ],
              [-74.83109986199992, 10.88719347500006 ],
              [-74.83078562999992, 10.887331777000043],
              [-74.83012993199992, 10.88761454300004 ],
              [-74.83006046499992, 10.887644497000053],
              [-74.82949857199992, 10.887926912000069],
              [-74.82857399199992, 10.888329811000062],
              [-74.82798782899994, 10.888588368000057],
              [-74.82762538499992, 10.88879853800006 ],
              [-74.8274925799999 , 10.888912473000062],
              [-74.82734790099994, 10.889116124000054],
              [-74.82722730299992, 10.889271884000038],
              [-74.82717307799993, 10.88936171000006 ],
              [-74.82709464499993, 10.889445614000067],
              [-74.82684704799993, 10.889619622000055],
              [-74.82658729299993, 10.889769748000049],
              [-74.82638187299995, 10.88987787800005 ],
              [-74.82616434099992, 10.889980063000053],
              [-74.82590455699994, 10.890118224000048],
              [-74.8256144259999 , 10.89022058900008 ],
              [-74.82554510299991, 10.890243952000048],
              [-74.82520339799993, 10.890359124000042],
              [-74.82481658099994, 10.890503576000071],
              [-74.8243515069999 , 10.890680201000066],
              [-74.82391106599994, 10.89089049200004 ],
              [-74.82347886499991, 10.890982480000048],
              [-74.82328022599995, 10.891030457000056],
              [-74.82325489399994, 10.891036575000044],
              [-74.82323394299993, 10.891045571000063],
              [-74.82322373999995, 10.89104995200006 ],
              [-74.82319735199991, 10.89106881500004 ],
              [-74.82279162499992, 10.891483925000045],
              [-74.82261626899992, 10.891685545000087],
              [-74.82261610899991, 10.891685729000073],
              [-74.82261252799992, 10.891689846000077],
              [-74.82257026799994, 10.891738435000093],
              [-74.82256913499992, 10.891737400000068],
              [-74.82256470399994, 10.89173334800006 ],
              [-74.8225616169999 , 10.89173052700005 ],
              [-74.82255736299993, 10.891726639000069],
              [-74.82215505199991, 10.891358911000054],
              [-74.82206915299992, 10.891295380000088],
              [-74.82200276599991, 10.89126611200004 ],
              [-74.8219958649999 , 10.891264387000092]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08436",
      "properties": {"id": "08436", "iso": "MAN", "name": "MANATÍ"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.92492815399993, 10.53439135800005 ],
              [-74.92493031899994, 10.534361775000093],
              [-74.92493365099995, 10.534323545000063],
              [-74.92508343999992, 10.53434661700004 ],
              [-74.92509337099995, 10.53434814700006 ],
              [-74.92510471199995, 10.534349894000059],
              [-74.9251111499999 , 10.534350886000084],
              [-74.9252306489999 , 10.534369292000065],
              [-74.92533530399993, 10.534385411000073],
              [-74.92534910499995, 10.534337981000078],
              [-74.92535967799995, 10.534269917000074],
              [-74.92536095299994, 10.534261707000041],
              [-74.92537789999994, 10.534152610000092],
              [-74.9253871759999 , 10.534092896000061],
              [-74.92542519499995, 10.534096202000057],
              [-74.92555135899994, 10.534107173000052],
              [-74.92553708199995, 10.533926334000057],
              [-74.92532768899991, 10.533902539000053],
              [-74.92532293099993, 10.533721700000058],
              [-74.92510401999994, 10.533671732000073],
              [-74.92506975599991, 10.533659200000045],
              [-74.9250535459999 , 10.533737879000057],
              [-74.9250273589999 , 10.53386498000009 ],
              [-74.92502625299994, 10.53387034800005 ],
              [-74.92500779799991, 10.533959922000065],
              [-74.92493762399994, 10.533956867000086],
              [-74.9248984319999 , 10.533951713000079],
              [-74.92468653999993, 10.53392384600005 ],
              [-74.92468659199994, 10.534012455000038],
              [-74.92465192299994, 10.53401034500007 ],
              [-74.92452529399992, 10.53400264000004 ],
              [-74.924453        , 10.533998242000052],
              [-74.9243797119999 , 10.533993782000039],
              [-74.92421835899995, 10.533983964000072],
              [-74.92421452699995, 10.533983731000092],
              [-74.92409735899992, 10.533976602000052],
              [-74.92404944299994, 10.53397368700007 ],
              [-74.92401422699993, 10.533971544000053],
              [-74.92383624299993, 10.533952667000051],
              [-74.92378281899994, 10.533947         ],
              [-74.9237001379999 , 10.533938231000093],
              [-74.92364966699995, 10.533921742000075],
              [-74.92338024399993, 10.53383372400009 ],
              [-74.92310289399995, 10.53374311500005 ],
              [-74.92307427299994, 10.53382337800008 ],
              [-74.92303233899992, 10.53394097800009 ],
              [-74.92295840799994, 10.533926093000048],
              [-74.92274870699993, 10.533883871000057],
              [-74.92273926399992, 10.533953443000087],
              [-74.92273483199995, 10.53398610000005 ],
              [-74.92273179299991, 10.534005955000055],
              [-74.92272875399993, 10.53402581000006 ],
              [-74.92272554099992, 10.534062885000083],
              [-74.9226134779999 , 10.534036068000091],
              [-74.92260776099994, 10.534073426000077],
              [-74.92260740099994, 10.534075778000044],
              [-74.92260271799995, 10.534129818000054],
              [-74.92255680099993, 10.534122609000065],
              [-74.92229043999993, 10.534086892000062],
              [-74.92217106999993, 10.534065113000054],
              [-74.92200353499993, 10.534034546000044],
              [-74.92186789899995, 10.533999086000051],
              [-74.9216992339999 , 10.533914752000044],
              [-74.92149776699995, 10.533730135000042],
              [-74.92130456699994, 10.53355874400006 ],
              [-74.92105849599994, 10.533254290000059],
              [-74.9207736539999 , 10.532996878000063],
              [-74.92053153599994, 10.532757667000055],
              [-74.9203251429999 , 10.532536118000053],
              [-74.92002327599994, 10.532180048000043],
              [-74.91980386299991, 10.531932591000043],
              [-74.91963448599995, 10.531746596000062],
              [-74.9193833569999 , 10.531553073000055],
              [-74.9191590719999 , 10.53144254600005 ],
              [-74.91899199199992, 10.531397190000064],
              [-74.91897021999995, 10.531391274000043],
              [-74.91878461999994, 10.531387915000039],
              [-74.91856148199992, 10.53143969100006 ],
              [-74.91822958599994, 10.531544773000064],
              [-74.91791996099994, 10.531662987000061],
              [-74.9176575649999 , 10.531694237000067],
              [-74.91731552699991, 10.531683859000054],
              [-74.91701379799991, 10.531597537000039],
              [-74.91665703299992, 10.531485602000089],
              [-74.91630794699995, 10.531385742000055],
              [-74.91615076799991, 10.53127591000009 ],
              [-74.91610178699995, 10.531248899000047],
              [-74.91601089699992, 10.531198776000053],
              [-74.9160407359999 , 10.531095191000077],
              [-74.91606732899993, 10.53098417600006 ],
              [-74.91609607099991, 10.53086421100005 ],
              [-74.91614263199995, 10.53066984800006 ],
              [-74.91622137999991, 10.530290181000055],
              [-74.91636034599992, 10.52997907300005 ],
              [-74.9165341659999 , 10.529734259000065],
              [-74.91680291999995, 10.529571537000038],
              [-74.91708104999992, 10.529443179000054],
              [-74.9176580489999 , 10.52928931300005 ],
              [-74.91794310099993, 10.529160929000057],
              [-74.91808214199995, 10.529039589000092],
              [-74.91820030499991, 10.528899937000062],
              [-74.91827676699995, 10.528673524000055],
              [-74.91831605999994, 10.52827334500006 ],
              [-74.9183669869999 , 10.527818332000038],
              [-74.9184062999999 , 10.527370136000059],
              [-74.91851977699991, 10.526821307000091],
              [-74.91861699099991, 10.526279302000091],
              [-74.91867026999995, 10.525849424000057],
              [-74.91876285299992, 10.525334839000038],
              [-74.91880450899993, 10.524907253000038],
              [-74.91894353899994, 10.524545858000067],
              [-74.91930264299992, 10.52401954000004 ],
              [-74.91957834699991, 10.523557358000062],
              [-74.91955632199995, 10.523536962000037],
              [-74.91954840199992, 10.523529627000073],
              [-74.91919121399991, 10.523198858000057],
              [-74.91912785699992, 10.523149213000067],
              [-74.91877175099995, 10.522870168000054],
              [-74.91831097399995, 10.522554013000047],
              [-74.91828270399992, 10.52253461500004 ],
              [-74.91790979699994, 10.522325392000084],
              [-74.91780456399994, 10.522266349000063],
              [-74.91780289699994, 10.52226541300007 ],
              [-74.91717255599991, 10.52206730100005 ],
              [-74.91641239799992, 10.521969891000083],
              [-74.91564066399991, 10.521920539000064],
              [-74.91498717799993, 10.521843538000041],
              [-74.91418297099995, 10.521476462000066],
              [-74.91384251199992, 10.52134715200009 ],
              [-74.9132466229999 , 10.521120825000082],
              [-74.91266027599994, 10.520900189000088],
              [-74.91249574299991, 10.520838276000063],
              [-74.91183522599994, 10.52058756200006 ],
              [-74.91120947399992, 10.520313934000058],
              [-74.91100409599994, 10.520218318000047],
              [-74.91070538999992, 10.520079250000038],
              [-74.91060689599993, 10.520033395000041],
              [-74.91028938199992, 10.51992406000005 ],
              [-74.9103615809999 , 10.519582293000042],
              [-74.9104236849999 , 10.519288317000075],
              [-74.91067169299993, 10.519452633000071],
              [-74.91072261099993, 10.519255887000043],
              [-74.91095673699994, 10.51937913900008 ],
              [-74.91085277099995, 10.519204332000072],
              [-74.91089411299993, 10.519063025000037],
              [-74.91105855199993, 10.51850096600009 ],
              [-74.91109091299995, 10.518064295000045],
              [-74.91103986599995, 10.517607075000058],
              [-74.91102595299992, 10.517184082000085],
              [-74.91102524299993, 10.517123557000048],
              [-74.91102466199993, 10.517073956000047],
              [-74.91102262499993, 10.516900294000038],
              [-74.91101890199991, 10.516582830000061],
              [-74.91097785799991, 10.516190605000077],
              [-74.91094234999991, 10.515851247000057],
              [-74.91088622299992, 10.515296552000052],
              [-74.91087503799992, 10.515186016000087],
              [-74.91082399499993, 10.51475854000006 ],
              [-74.91081005299992, 10.514509280000084],
              [-74.91081928499995, 10.514280642000074],
              [-74.91084707499994, 10.514051967000057],
              [-74.91089337199992, 10.513889629000062],
              [-74.9109698549999 , 10.513750088000052],
              [-74.91107640299992, 10.51351442300006 ],
              [-74.91117439499993, 10.513405118000037],
              [-74.9112015529999 , 10.513374826000074],
              [-74.91127798299993, 10.513216991000093],
              [-74.9113498079999 , 10.512990529000092],
              [-74.9114331429999 , 10.512748074000058],
              [-74.91152812699994, 10.51251017900006 ],
              [-74.91164172599991, 10.512267715000064],
              [-74.91164393699995, 10.512055068000052],
              [-74.91166041399993, 10.511977849000061],
              [-74.91166042799995, 10.51197778900007 ],
              [-74.91177635699995, 10.511514384000066],
              [-74.91183793899995, 10.511268223000059],
              [-74.91188254899993, 10.511069324000061],
              [-74.9119983619999 , 10.510698818000037],
              [-74.91212110799995, 10.51032828700005 ],
              [-74.9122299679999 , 10.509973754000043],
              [-74.9122422339999 , 10.509930351000037],
              [-74.91229719699993, 10.50973586200007 ],
              [-74.91240832799991, 10.509507109000083],
              [-74.91241965699993, 10.50949096700009 ],
              [-74.9125913769999 , 10.509246307000069],
              [-74.91276055799995, 10.509001436000062],
              [-74.91287403899992, 10.508690327000068],
              [-74.9129759299999 , 10.50847529400005 ],
              [-74.91319141199995, 10.508173237000051],
              [-74.91337673899994, 10.507802608000077],
              [-74.91353195399995, 10.507514348000086],
              [-74.91368725099994, 10.50728328300005 ],
              [-74.91382155399992, 10.507047613000054],
              [-74.9139606409999 , 10.506862282000043],
              [-74.91404040499992, 10.506753436000054],
              [-74.91417401399991, 10.506571110000039],
              [-74.91428265099995, 10.506422862000079],
              [-74.91441703099991, 10.506164314000046],
              [-74.91447719699994, 10.505825944000037],
              [-74.91447712999991, 10.505457788000058],
              [-74.9145003079999 , 10.505059911000046],
              [-74.9145701889999 , 10.50466281200005 ],
              [-74.91459286799994, 10.504533954000067],
              [-74.91460473699993, 10.504491100000052],
              [-74.9147341339999 , 10.504023896000092],
              [-74.91481286499993, 10.503529970000045],
              [-74.91487076199991, 10.50309089500007 ],
              [-74.91490540799992, 10.502578724000045],
              [-74.91483818799992, 10.50197522600007 ],
              [-74.91485429499994, 10.50146761700006 ],
              [-74.91485489599995, 10.50145199900004 ],
              [-74.9148627969999 , 10.501247191000061],
              [-74.91487043299992, 10.501049211000065],
              [-74.91480316099995, 10.500557712000045],
              [-74.91478458299991, 10.500077605000058],
              [-74.91480771099992, 10.499682057000086],
              [-74.91490932699992, 10.499315828000078],
              [-74.9149397249999 , 10.499206275000063],
              [-74.91497589599993, 10.499167500000055],
              [-74.91548418199994, 10.498622621000038],
              [-74.9159730149999 , 10.497922448000054],
              [-74.91632286099991, 10.497384718000092],
              [-74.91654991899992, 10.497039622000045],
              [-74.9165522479999 , 10.497036086000037],
              [-74.91680937099994, 10.496645899000043],
              [-74.91701782099994, 10.496329893000052],
              [-74.91707868299994, 10.49620739900007 ],
              [-74.9172611109999 , 10.49584025200005 ],
              [-74.91742558099992, 10.495574839000085],
              [-74.91761095399994, 10.495304856000075],
              [-74.9177730639999 , 10.495009715000037],
              [-74.9179329019999 , 10.494691697000064],
              [-74.91809278599993, 10.494394313000043],
              [-74.9182317179999 , 10.494051136000053],
              [-74.9184448879999 , 10.493611939000061],
              [-74.9185490779999 , 10.493353463000062],
              [-74.9185954479999 , 10.493088152000041],
              [-74.91863473899991, 10.492733738000084],
              [-74.91861613899994, 10.49245708500007 ],
              [-74.91847009899993, 10.492098292000037],
              [-74.91832175799993, 10.491867615000046],
              [-74.91815495799995, 10.491723780000086],
              [-74.91811565499995, 10.491708767000091],
              [-74.9179482369999 , 10.491642959000046],
              [-74.91777484999994, 10.491543573000058],
              [-74.91755004999993, 10.491484420000063],
              [-74.91725345899994, 10.49129042900006 ],
              [-74.91704488499994, 10.49105744700006 ],
              [-74.91683857099991, 10.490815337000072],
              [-74.9166323259999 , 10.490490943000054],
              [-74.91654188599995, 10.490296699000055],
              [-74.9165232979999 , 10.490125198000044],
              [-74.91654184099991, 10.48983256400004 ],
              [-74.91658814599992, 10.489411818000065],
              [-74.91659960299995, 10.48900486000008 ],
              [-74.91659093899995, 10.488937491000058],
              [-74.91652658999993, 10.488437142000066],
              [-74.91652080299991, 10.488392144000045],
              [-74.91639692199993, 10.487970439000037],
              [-74.91639324999994, 10.487957940000058],
              [-74.91623329799995, 10.487413941000057],
              [-74.91615460699995, 10.486864607000086],
              [-74.91614979799994, 10.48683103400009 ],
              [-74.91610198699993, 10.486615382000082],
              [-74.91604540999992, 10.486360190000084],
              [-74.91604061499993, 10.48619786800009 ],
              [-74.91603777499995, 10.48610167000004 ],
              [-74.91603247599994, 10.485922194000068],
              [-74.9160315009999 , 10.485889170000064],
              [-74.91602772499994, 10.485839547000069],
              [-74.91600089899993, 10.485487015000047],
              [-74.91599427699992, 10.485399999000037],
              [-74.9159822279999 , 10.485265433000052],
              [-74.91596418499995, 10.485063922000052],
              [-74.91596636799994, 10.484606635000091],
              [-74.91604511299994, 10.484357338000052],
              [-74.9161447749999 , 10.484110243000089],
              [-74.91625708399994, 10.48381256500005 ],
              [-74.91628370399991, 10.483742009000082],
              [-74.91632074699993, 10.483481315000063],
              [-74.91627032199995, 10.48322620600004 ],
              [-74.9162674239999 , 10.483211543000039],
              [-74.91626741699991, 10.483176828000069],
              [-74.91626736099994, 10.482898327000044],
              [-74.91623484599995, 10.482619457000055],
              [-74.91622726999992, 10.482363142000054],
              [-74.91622726799994, 10.48236307600007 ],
              [-74.91621874799995, 10.482178503000057],
              [-74.91618376399992, 10.481805528000052],
              [-74.91614432099993, 10.481469534000041],
              [-74.9160724649999 , 10.481151795000073],
              [-74.91604845899991, 10.480951695000044],
              [-74.91603763699993, 10.480861490000052],
              [-74.91603756299992, 10.480853419000084],
              [-74.91603419499995, 10.48048686900006 ],
              [-74.9160337369999 , 10.480437216000041],
              [-74.91602562399993, 10.480417170000067],
              [-74.91600281599995, 10.480360793000045],
              [-74.91596802899994, 10.48003157100004 ],
              [-74.91596488999994, 10.47992236400006 ],
              [-74.9159647649999 , 10.479918016000056],
              [-74.91596246499995, 10.479838034000068],
              [-74.91596144999994, 10.479802732000053],
              [-74.91595850199991, 10.479700181000055],
              [-74.91594943099994, 10.479384580000044],
              [-74.91587978299992, 10.47872162300007 ],
              [-74.91572910299993, 10.478017562000048],
              [-74.91555513599991, 10.477123804000087],
              [-74.91540443499991, 10.476488387000074],
              [-74.91521901699991, 10.475816420000058],
              [-74.91398357999992, 10.475598257000058],
              [-74.9141599429999 , 10.474932840000065],
              [-74.91428966699993, 10.474429667000038],
              [-74.9144355329999 , 10.473885346000088],
              [-74.91463243099992, 10.473260954000068],
              [-74.9147553219999 , 10.47293277700004 ],
              [-74.9147806229999 , 10.472865210000066],
              [-74.91478888899991, 10.472624716000041],
              [-74.91477265899994, 10.472266473000047],
              [-74.91473718799995, 10.471946888000048],
              [-74.91473592299991, 10.47170286100004 ],
              [-74.91473495799994, 10.471516866000059],
              [-74.91470719999995, 10.47130648700005 ],
              [-74.91475358299994, 10.470963281000081],
              [-74.91496666199993, 10.470471040000064],
              [-74.91517518799992, 10.469820981000055],
              [-74.91541514799991, 10.468884389000038],
              [-74.91545782399993, 10.468717816000037],
              [-74.91543700199992, 10.46850740800005 ],
              [-74.9155922619999 , 10.468083979000085],
              [-74.91568782299993, 10.467345936000072],
              [-74.91581180299994, 10.466916170000047],
              [-74.91583140299991, 10.466828466000038],
              [-74.91588940799994, 10.466508488000045],
              [-74.91590928799991, 10.466274093000038],
              [-74.91597139899994, 10.465891566000039],
              [-74.9160307009999 , 10.465647158000081],
              [-74.91607306599991, 10.46545195400006 ],
              [-74.91612869099993, 10.465255825000042],
              [-74.91617516999992, 10.464991256000076],
              [-74.91622788899991, 10.46477184400004 ],
              [-74.91626741399995, 10.464516250000088],
              [-74.91630048799993, 10.46424313600005 ],
              [-74.9163522419999 , 10.464067538000052],
              [-74.91638655799994, 10.463980098000093],
              [-74.9164482619999 , 10.463876403000086],
              [-74.9165089089999 , 10.463732889000084],
              [-74.91656772999994, 10.46364599900005 ],
              [-74.91665450499994, 10.46348938500006 ],
              [-74.91671259099991, 10.463344040000038],
              [-74.9167763289999 , 10.463239283000064],
              [-74.91686924699991, 10.46308977800004 ],
              [-74.91690365099993, 10.463034424000057],
              [-74.91713562399991, 10.462661174000061],
              [-74.9172076989999 , 10.462525084000049],
              [-74.9173050519999 , 10.462374990000058],
              [-74.9173422639999 , 10.462279589000048],
              [-74.91734268399995, 10.462278509000043],
              [-74.91739453999992, 10.46228915000006 ],
              [-74.9175230649999 , 10.462312645000054],
              [-74.91779744799993, 10.462362802000087],
              [-74.91782058099994, 10.462366333000091],
              [-74.91788167099992, 10.462375654000084],
              [-74.91793213999995, 10.462383355000043],
              [-74.91815178299993, 10.462416874000041],
              [-74.91881168099991, 10.462509115000046],
              [-74.91892749399994, 10.462538540000082],
              [-74.91938821999992, 10.462674744000083],
              [-74.9198629569999 , 10.462767412000062],
              [-74.9203028899999 , 10.462917374000085],
              [-74.92129627199995, 10.46321465300008 ],
              [-74.92139579799993, 10.463262416000077],
              [-74.92179406799994, 10.463494756000046],
              [-74.92228957599991, 10.463747528000056],
              [-74.9227197539999 , 10.463985557000058],
              [-74.92291935299994, 10.464095998000062],
              [-74.92303034699995, 10.464131420000058],
              [-74.92350287499994, 10.46428221800005 ],
              [-74.92421380299993, 10.464641903000086],
              [-74.92470699299992, 10.46485117800006 ],
              [-74.92530201699992, 10.465174515000058],
              [-74.92589165999993, 10.465478137000048],
              [-74.92667283899993, 10.465880388000073],
              [-74.92722788799995, 10.466005132000078],
              [-74.92753887499993, 10.466075026000055],
              [-74.92914121199993, 10.466460055000084],
              [-74.92942224799992, 10.466611794000073],
              [-74.92942376499991, 10.466612613000052],
              [-74.92942371999993, 10.466611825000086],
              [-74.92939826999992, 10.466166701000077],
              [-74.9293496969999 , 10.465654467000093],
              [-74.92935439299993, 10.465155793000065],
              [-74.92931041799994, 10.46457497700004 ],
              [-74.92925854899994, 10.464201109000044],
              [-74.92921783899993, 10.46391413200007 ],
              [-74.9292225609999 , 10.463452104000055],
              [-74.92925271999991, 10.462871017000055],
              [-74.92926667299992, 10.462418146000061],
              [-74.92927593299993, 10.462022429000058],
              [-74.92932465399991, 10.461422998000046],
              [-74.92938487099991, 10.460979119000058],
              [-74.9295516709999 , 10.460532688000058],
              [-74.92959566199994, 10.46028560600007 ],
              [-74.9296558929999 , 10.459823364000044],
              [-74.9296837359999 , 10.459619755000062],
              [-74.92977409699995, 10.459450296000057],
              [-74.9298643809999 , 10.459225900000092],
              [-74.92987827999991, 10.459013148000054],
              [-74.92988296199991, 10.458743284000093],
              [-74.92992234899992, 10.458459559000062],
              [-74.92992232899991, 10.458297136000056],
              [-74.92993630299992, 10.457954019000056],
              [-74.93002665099993, 10.457516895000083],
              [-74.93007992199995, 10.457198882000057],
              [-74.93016793399994, 10.456537625000067],
              [-74.93017725899995, 10.456242546000055],
              [-74.93013098999995, 10.455842386000086],
              [-74.93011254099991, 10.455410078000057],
              [-74.9301079409999 , 10.454879436000056],
              [-74.93018207499995, 10.454126758000086],
              [-74.93021258399995, 10.453642872000046],
              [-74.93024005299992, 10.453207172000077],
              [-74.93032582799992, 10.452669434000086],
              [-74.93035827799991, 10.452118146000089],
              [-74.9303745389999 , 10.451804682000045],
              [-74.93051349399991, 10.451143351000042],
              [-74.93076359899993, 10.45120680000008 ],
              [-74.9307365599999 , 10.449074266000082],
              [-74.93073417499994, 10.448886168000058],
              [-74.93073013499992, 10.448567636000064],
              [-74.93072775199994, 10.44837962300005 ],
              [-74.93072689299993, 10.448311784000055],
              [-74.93073230399995, 10.448292129000038],
              [-74.93074532999992, 10.448244807000037],
              [-74.93074402299993, 10.448234820000039],
              [-74.93071053999995, 10.447979040000064],
              [-74.93070255999993, 10.44791807200005 ],
              [-74.93065631099995, 10.447564753000051],
              [-74.93050394099993, 10.446400709000045],
              [-74.93043802099993, 10.445897117000072],
              [-74.93034275499991, 10.44516931600009 ],
              [-74.9303403429999 , 10.445161469000084],
              [-74.9303195729999 , 10.44509387700009 ],
              [-74.9303195999999 , 10.445050441000092],
              [-74.93031960699994, 10.444977211000037],
              [-74.9303497059999 , 10.444920001000071],
              [-74.93038903399992, 10.444874200000072],
              [-74.93084248299994, 10.444323667000049],
              [-74.9311268699999 , 10.443978389000051],
              [-74.93158395899991, 10.443423437000092],
              [-74.93203887499993, 10.442893471000048],
              [-74.93206792199993, 10.442859629000054],
              [-74.9321952919999 , 10.44271062100006 ],
              [-74.93227408799993, 10.442616695000083],
              [-74.9323412139999 , 10.442534174000059],
              [-74.93238289999994, 10.442463153000062],
              [-74.93238979699993, 10.442425035000042],
              [-74.93239449499993, 10.442399068000043],
              [-74.93239447099995, 10.44237658500009 ],
              [-74.9323944539999 , 10.442360221000058],
              [-74.93240380999993, 10.44228786900004 ],
              [-74.93240835699993, 10.442252694000047],
              [-74.93229029399993, 10.442282682000041],
              [-74.93212124599995, 10.442326563000051],
              [-74.9320101269999 , 10.442363447000048],
              [-74.93172000699991, 10.442435214000056],
              [-74.93171138199995, 10.442437348000055],
              [-74.93170891099993, 10.442437354000049],
              [-74.9315308059999 , 10.442437762000054],
              [-74.93116036599992, 10.44246150400005 ],
              [-74.9308732259999 , 10.442471314000045],
              [-74.93066481099993, 10.442416933000061],
              [-74.93063482199994, 10.442398938000053],
              [-74.93056990899993, 10.442359985000053],
              [-74.93054180299993, 10.442328511000085],
              [-74.93046799799993, 10.442245855000067],
              [-74.93034066099995, 10.44214781900007 ],
              [-74.93024568499993, 10.442133295000076],
              [-74.93022260299995, 10.442129765000061],
              [-74.92993782899993, 10.442041263000078],
              [-74.92967576699994, 10.44196076500009 ],
              [-74.92959050899992, 10.441934575000062],
              [-74.92954403599992, 10.441910935000067],
              [-74.92927254299991, 10.441772841000045],
              [-74.92912014399991, 10.441680069000085],
              [-74.92892225899993, 10.441534691000072],
              [-74.92874131199994, 10.44145020500008 ],
              [-74.92863603199993, 10.441387834000068],
              [-74.92857511499994, 10.441362233000063],
              [-74.9283925599999 , 10.441296031000093],
              [-74.92824663899995, 10.44125035500008 ],
              [-74.92816185499993, 10.441240070000049],
              [-74.92810265599991, 10.441229577000058],
              [-74.92796773999993, 10.44124293200008 ],
              [-74.92783812399995, 10.44125811300006 ],
              [-74.92772104199992, 10.441309346000082],
              [-74.92761682199995, 10.441358918000049],
              [-74.92747954899994, 10.441456619000064],
              [-74.92731768399995, 10.441585367000073],
              [-74.92725283899995, 10.441676791000077],
              [-74.9271794899999 , 10.441769389000058],
              [-74.92709076199992, 10.441863605000037],
              [-74.92702427199993, 10.441934226000058],
              [-74.92694890199994, 10.442014276000066],
              [-74.92690242299994, 10.442070069000067],
              [-74.92682948499993, 10.44215762400006 ],
              [-74.92678789399992, 10.442122960000063],
              [-74.9266949279999 , 10.441833617000043],
              [-74.9266869349999 , 10.441808750000064],
              [-74.92658915699991, 10.441648386000054],
              [-74.92656949699995, 10.441616147000047],
              [-74.92650869699992, 10.441516424000042],
              [-74.92641841299991, 10.441322250000042],
              [-74.9262008199999 , 10.44098652100007 ],
              [-74.92604802299991, 10.44084046000006 ],
              [-74.92573547699993, 10.440482115000066],
              [-74.92544837699995, 10.440244885000084],
              [-74.92516593699992, 10.439911596000059],
              [-74.9254967679999 , 10.439323237000053],
              [-74.92559831999995, 10.439142640000057],
              [-74.9255983509999 , 10.439142583000091],
              [-74.92559837099992, 10.439142548000063],
              [-74.92564717199991, 10.439055761000077],
              [-74.92574023399993, 10.438890257000082],
              [-74.92619878399995, 10.43807477100006 ],
              [-74.92633484999993, 10.437854590000086],
              [-74.92635165299993, 10.43782740000006 ],
              [-74.9264188489999 , 10.437641978000045],
              [-74.9264072659999 , 10.43752992800006 ],
              [-74.92631007799991, 10.437262529000066],
              [-74.92625918399995, 10.436940135000043],
              [-74.92626148999994, 10.436558127000069],
              [-74.9262945669999 , 10.436227621000057],
              [-74.92629628599991, 10.436210431000063],
              [-74.92610669999993, 10.43629113500009 ],
              [-74.92572896799993, 10.436451926000075],
              [-74.92526385999992, 10.436578628000063],
              [-74.92513624599991, 10.436613393000073],
              [-74.92437284499994, 10.436466554000049],
              [-74.92432583899995, 10.43645751200006 ],
              [-74.92406665599992, 10.436411198000087],
              [-74.9239457569999 , 10.436389594000048],
              [-74.9238581539999 , 10.436373940000067],
              [-74.92364336599991, 10.43629609900006 ],
              [-74.9234066699999 , 10.43621031500004 ],
              [-74.92296646599993, 10.436071320000053],
              [-74.92293202399992, 10.436060444000077],
              [-74.92290461599993, 10.436049256000047],
              [-74.92284870399993, 10.436026432000062],
              [-74.92247088699992, 10.435872201000052],
              [-74.92180673099995, 10.435601082000062],
              [-74.92149004599992, 10.435400856000058],
              [-74.92141930499992, 10.43535613000006 ],
              [-74.92126329999991, 10.435257495000087],
              [-74.92110566499991, 10.435157829000048],
              [-74.92095247799995, 10.435060975000056],
              [-74.92067120099995, 10.43447682100009 ],
              [-74.92039692599991, 10.43390720800005 ],
              [-74.92037728199995, 10.433870984000066],
              [-74.92006241099995, 10.43329034900006 ],
              [-74.92002650699993, 10.433224142000086],
              [-74.91925797099992, 10.43163871300004 ],
              [-74.91904486099992, 10.431199083000081],
              [-74.91894390999994, 10.430990833000067],
              [-74.91803717699992, 10.42912029300004 ],
              [-74.91768633399994, 10.42839652400005 ],
              [-74.91769043799991, 10.428262078000046],
              [-74.91769690799993, 10.42805022400006 ],
              [-74.9177135939999 , 10.427503774000058],
              [-74.91773252699994, 10.427481453000041],
              [-74.91793656199991, 10.427240898000036],
              [-74.91791477099991, 10.426967226000045],
              [-74.91791244899991, 10.426820692000092],
              [-74.91790186399993, 10.426733844000069],
              [-74.91790058999993, 10.426656026000046],
              [-74.91790900799992, 10.426605436000045],
              [-74.91790814599995, 10.426550496000061],
              [-74.9179210389999 , 10.426488457000062],
              [-74.9179495919999 , 10.426389434000043],
              [-74.91794873799995, 10.426336751000065],
              [-74.91796389899991, 10.42627004600007 ],
              [-74.91797730299993, 10.426242330000036],
              [-74.91797713999995, 10.42623321900004 ],
              [-74.91799439599993, 10.426150405000044],
              [-74.91801624599992, 10.42612212000006 ],
              [-74.9180374739999 , 10.426094643000056],
              [-74.91800502199993, 10.426092352000069],
              [-74.91813774099995, 10.42574144200006 ],
              [-74.91822637599995, 10.425439044000086],
              [-74.91823498399992, 10.425408436000055],
              [-74.91827353999992, 10.425271330000044],
              [-74.9182918599999 , 10.42521514200007 ],
              [-74.91831191699993, 10.42516663400005 ],
              [-74.91834031299993, 10.425060770000073],
              [-74.91837385999992, 10.424986879000073],
              [-74.91841143999994, 10.424873928000068],
              [-74.91844631799995, 10.424740428000064],
              [-74.9185034599999 , 10.424551646000054],
              [-74.91853245499993, 10.424484686000085],
              [-74.91860104699992, 10.424286407000068],
              [-74.9186003559999 , 10.424240652000037],
              [-74.91863192499994, 10.424045363000062],
              [-74.91865881299992, 10.423987594000039],
              [-74.91872539699995, 10.423810011000057],
              [-74.91875227699995, 10.423752315000058],
              [-74.9187685149999 , 10.42361003700006 ],
              [-74.91880829999991, 10.423494823000055],
              [-74.91888896299992, 10.423326100000054],
              [-74.91893101799991, 10.423208471000066],
              [-74.9189799049999 , 10.423081643000046],
              [-74.9190335909999 , 10.422963849000041],
              [-74.91907572999992, 10.422850879000066],
              [-74.91911270999992, 10.42270127200004 ],
              [-74.91912156399991, 10.422675964000064],
              [-74.91915510199993, 10.422602004000055],
              [-74.9192297539999 , 10.422348768000063],
              [-74.91927331099993, 10.422178453000072],
              [-74.91931754699993, 10.422047053000085],
              [-74.91935303399993, 10.421952532000091],
              [-74.91939518099991, 10.421839566000074],
              [-74.9194134359999 , 10.421805895000091],
              [-74.91944912699995, 10.421740058000069],
              [-74.91949388599994, 10.421643044000064],
              [-74.9195248069999 , 10.421548615000063],
              [-74.91953567899992, 10.421507130000066],
              [-74.91955986099993, 10.42142648500004 ],
              [-74.91958589699993, 10.421318430000042],
              [-74.91960762399992, 10.42122867900008 ],
              [-74.91963103499995, 10.42110001900005 ],
              [-74.91964175999993, 10.421026414000039],
              [-74.91965813799993, 10.420914         ],
              [-74.91967278299995, 10.420815231000063],
              [-74.91968574999993, 10.420757777000063],
              [-74.91972221499992, 10.420576181000058],
              [-74.91975507899991, 10.42046104800005 ],
              [-74.91978001399991, 10.420394344000044],
              [-74.91978609999995, 10.420378062000054],
              [-74.91981492399992, 10.420297333000065],
              [-74.91986178299993, 10.420188868000082],
              [-74.91988630799995, 10.420128841000064],
              [-74.91991452099995, 10.420009209000057],
              [-74.9199380089999 , 10.419885132000047],
              [-74.91995223499993, 10.419763483000054],
              [-74.91998264499995, 10.419639250000046],
              [-74.9200063959999 , 10.419531136000046],
              [-74.9200164959999 , 10.419439365000073],
              [-74.9200137549999 , 10.419269952000093],
              [-74.92002297499994, 10.419120916000054],
              [-74.9200217689999 , 10.41904308900007 ],
              [-74.9200408879999 , 10.418937391000043],
              [-74.92006253099993, 10.418843049000088],
              [-74.92006160099993, 10.418785851000052],
              [-74.92005977699995, 10.41866904300008 ],
              [-74.92004600599995, 10.41853419000006 ],
              [-74.92004487699995, 10.41846096100005 ],
              [-74.92002898499993, 10.418335290000073],
              [-74.92002726299995, 10.418225410000048],
              [-74.91998616299992, 10.41811852300009 ],
              [-74.91996148399994, 10.418053967000048],
              [-74.91994268699995, 10.41800479200009 ],
              [-74.91991593299991, 10.417922873000066],
              [-74.91988461499994, 10.417843299000083],
              [-74.91987237399991, 10.417802289000065],
              [-74.91984149699994, 10.417752517000054],
              [-74.91979432499994, 10.417696146000083],
              [-74.91975846299994, 10.417625832000056],
              [-74.91973247699991, 10.41759193100006 ],
              [-74.91970158899994, 10.417539826000052],
              [-74.91965713199994, 10.417510915000037],
              [-74.91963673499993, 10.417538722000074],
              [-74.91960057099993, 10.41759206900008 ],
              [-74.91958008199992, 10.417613097000071],
              [-74.91950984599993, 10.417706013000043],
              [-74.91948717699995, 10.417738488000055],
              [-74.91940758699991, 10.417826972000057],
              [-74.91937983999992, 10.417827470000077],
              [-74.91931776099995, 10.417851579000057],
              [-74.91924187399991, 10.41788278000007 ],
              [-74.91918417699992, 10.417892966000068],
              [-74.91911257499993, 10.417903461000037],
              [-74.91904552599993, 10.417904695000061],
              [-74.91896490499994, 10.417929135000065],
              [-74.91887262999995, 10.417946828000083],
              [-74.9187665369999 , 10.417969428000049],
              [-74.91872487899991, 10.417970169000057],
              [-74.9186326169999 , 10.417987998000058],
              [-74.91858429099995, 10.418004864000068],
              [-74.91853832899994, 10.418024052000078],
              [-74.91833945699995, 10.418036937000068],
              [-74.91825156899995, 10.418038589000048],
              [-74.91814066699993, 10.418049817000053],
              [-74.91805287699992, 10.418060585000092],
              [-74.9180116359999 , 10.418086535000043],
              [-74.9179405459999 , 10.418126835000066],
              [-74.91789573499995, 10.418145507000077],
              [-74.9178288789999 , 10.418180334000056],
              [-74.9175985089999 , 10.418300344000045],
              [-74.91753207399995, 10.418345084000066],
              [-74.91745870299991, 10.418387646000042],
              [-74.91742016799992, 10.418438740000056],
              [-74.91735659699992, 10.418515539000055],
              [-74.91724199499993, 10.418584054000064],
              [-74.91715299499992, 10.418667312000082],
              [-74.91703220799991, 10.418780309000056],
              [-74.91643368799993, 10.419267772000069],
              [-74.91632941299991, 10.419375818000049],
              [-74.9163983819999 , 10.418816225000057],
              [-74.91640276999993, 10.418780628000093],
              [-74.9164042569999 , 10.418773219000059],
              [-74.9164282559999 , 10.418653669000037],
              [-74.91649861099995, 10.418303190000074],
              [-74.9165356769999 , 10.418118542000059],
              [-74.9166055099999 , 10.417851613000039],
              [-74.91663468799993, 10.417216716000041],
              [-74.91675173999994, 10.41657331500005 ],
              [-74.91678067799995, 10.416352897000081],
              [-74.9168922739999 , 10.41580351400006 ],
              [-74.91699000599993, 10.415114610000046],
              [-74.91699382599995, 10.414915311000073],
              [-74.91694859199993, 10.41483597000007 ],
              [-74.91692673999995, 10.414483699000073],
              [-74.9168849109999 , 10.413600531000043],
              [-74.91685308399991, 10.412907173000065],
              [-74.91684690799991, 10.412747980000063],
              [-74.91684209699991, 10.412218070000051],
              [-74.9167783769999 , 10.412214283000083],
              [-74.9167783549999 , 10.412213828000063],
              [-74.91677681599992, 10.412117557000045],
              [-74.9165896909999 , 10.411955984000087],
              [-74.91654699499992, 10.41191911900006 ],
              [-74.91673407099995, 10.41189804000004 ],
              [-74.9168063759999 , 10.411889894000069],
              [-74.9168326329999 , 10.411886937000077],
              [-74.91710635899994, 10.41174863100008 ],
              [-74.91733285799995, 10.411634187000061],
              [-74.9174068129999 , 10.411588595000069],
              [-74.91746674399991, 10.411574471000051],
              [-74.91757588399992, 10.41149735600004 ],
              [-74.91764290099991, 10.411493687000075],
              [-74.91787191999993, 10.411395302000074],
              [-74.9184180549999 , 10.411163387000045],
              [-74.91855345599993, 10.411115322000057],
              [-74.91889381199991, 10.410994508000044],
              [-74.91954276099995, 10.410954377000053],
              [-74.91996610299992, 10.41097071200005 ],
              [-74.92049179899993, 10.411007734000066],
              [-74.92095403999991, 10.41105888800007 ],
              [-74.9214303089999 , 10.411082071000067],
              [-74.92162443899991, 10.411123460000056],
              [-74.92182244799994, 10.411297533000038],
              [-74.92185390899994, 10.411192691000053],
              [-74.92195099899993, 10.410837851000053],
              [-74.92202505999995, 10.410567163000053],
              [-74.92212286099993, 10.410210713000083],
              [-74.92226235999993, 10.409620191000045],
              [-74.92240561499995, 10.409113467000054],
              [-74.92252057199994, 10.40857887900006 ],
              [-74.92263579899992, 10.408145558000058],
              [-74.92277563899995, 10.40768074500005 ],
              [-74.9228943899999 , 10.407243931000039],
              [-74.9229993379999 , 10.40692238400004 ],
              [-74.92305870099995, 10.406698738000046],
              [-74.92324857099993, 10.406474742000057],
              [-74.92343131799993, 10.406222828000068],
              [-74.92362816699995, 10.405970879000051],
              [-74.92376601299992, 10.405769036000038],
              [-74.9238670819999 , 10.405621039000039],
              [-74.92403576099991, 10.405386624000073],
              [-74.92432051499992, 10.405029683000066],
              [-74.92447158199991, 10.404805786000054],
              [-74.92469290899993, 10.404473456000062],
              [-74.92439858499995, 10.404282470000055],
              [-74.9243713489999 , 10.404264792000049],
              [-74.92371051399994, 10.403819570000053],
              [-74.92319726999995, 10.403490652000073],
              [-74.92310274699992, 10.403430075000074],
              [-74.92279372799993, 10.403218209000045],
              [-74.92259032099992, 10.403078747000052],
              [-74.92214549199991, 10.402940243000046],
              [-74.92168650699995, 10.402784322000059],
              [-74.9212805169999 , 10.40265968600005 ],
              [-74.92115076199991, 10.402623077000044],
              [-74.92104753399991, 10.402593953000064],
              [-74.9211656789999 , 10.402257727000062],
              [-74.92125755799992, 10.401996258000054],
              [-74.92144637399991, 10.401381159000039],
              [-74.92148432199991, 10.401271130000055],
              [-74.9216633659999 , 10.400752018000048],
              [-74.92179986899993, 10.400357058000054],
              [-74.92185919799994, 10.400122932000045],
              [-74.92241598699991, 10.399925901000074],
              [-74.92314895299995, 10.399658559000045],
              [-74.9236528479999 , 10.399468653000042],
              [-74.92381049199992, 10.39906664700004 ],
              [-74.92413302299991, 10.39833595600004 ],
              [-74.92423543899992, 10.398381079000046],
              [-74.92456397199993, 10.398561789000041],
              [-74.9247723339999 , 10.39865203000005 ],
              [-74.92503008199992, 10.39874562600005 ],
              [-74.9252313959999 , 10.398839376000069],
              [-74.92534688599994, 10.398895420000088],
              [-74.9255033579999 , 10.398971351000057],
              [-74.9257151509999 , 10.399026655000057],
              [-74.92617065299993, 10.399200045000043],
              [-74.92648284799992, 10.39931013100005 ],
              [-74.92653431999992, 10.399328277000052],
              [-74.92677435699994, 10.399393987000053],
              [-74.9269050179999 , 10.399449512000047],
              [-74.92704264499991, 10.399470098000052],
              [-74.92694791799994, 10.399658918000057],
              [-74.92679671899992, 10.399833921000038],
              [-74.92662082399994, 10.40000899200004 ],
              [-74.92645561299992, 10.40021895500007 ],
              [-74.9262201279999 , 10.400550631000044],
              [-74.92616747199992, 10.40062479900007 ],
              [-74.9259954979999 , 10.400943032000043],
              [-74.92630691099993, 10.401312359000087],
              [-74.92659000599991, 10.40164334700006 ],
              [-74.92685175999992, 10.401911536000057],
              [-74.92703929099991, 10.402124049000065],
              [-74.92725513199991, 10.402371404000064],
              [-74.92741088299994, 10.402570034000064],
              [-74.92754549199992, 10.40277919500005 ],
              [-74.92767313099995, 10.403019803000063],
              [-74.92774782399994, 10.403246588000059],
              [-74.9277870009999 , 10.403382675000046],
              [-74.92776614199994, 10.403494470000055],
              [-74.92773896099993, 10.403875175000053],
              [-74.9277186249999 , 10.404179036000073],
              [-74.92766294599994, 10.404458546000058],
              [-74.92761479199993, 10.404909149000048],
              [-74.92758055699994, 10.405289871000093],
              [-74.92758830699995, 10.405544771000052],
              [-74.92760630299995, 10.40567741700005 ],
              [-74.92767737499992, 10.405869290000055],
              [-74.92772743599994, 10.406117090000066],
              [-74.92779125499993, 10.406235644000049],
              [-74.9279646299999 , 10.406430736000061],
              [-74.92832181299991, 10.40676501200005 ],
              [-74.92858003799995, 10.407029718000047],
              [-74.9288169969999 , 10.407256067000048],
              [-74.92906450599992, 10.407471907000058],
              [-74.92930853599995, 10.407705221000072],
              [-74.92960559499994, 10.407980297000051],
              [-74.9298314269999 , 10.408206129000064],
              [-74.9298319689999 , 10.408206669000037],
              [-74.93004762999993, 10.408384186000092],
              [-74.93031990499992, 10.408627900000056],
              [-74.93040480999991, 10.408714971000052],
              [-74.93047212599993, 10.408823042000051],
              [-74.93048294699992, 10.408910313000092],
              [-74.93048448999991, 10.408944722000058],
              [-74.93049045999993, 10.409077911000054],
              [-74.93051551999991, 10.409214035000048],
              [-74.93056170799991, 10.40933264000006 ],
              [-74.9306325629999 , 10.40944419400006 ],
              [-74.93075276099995, 10.409541647000083],
              [-74.93094023099991, 10.409729713000047],
              [-74.9312302159999 , 10.409997821000047],
              [-74.93156639599994, 10.410388023000053],
              [-74.93196260999991, 10.410802511000043],
              [-74.93213021899993, 10.410979908000058],
              [-74.9322456299999 , 10.411102061000065],
              [-74.93263832799994, 10.411520048000057],
              [-74.93285116899995, 10.41174895000006 ],
              [-74.93286829599992, 10.41176736500006 ],
              [-74.93311489099995, 10.41164797600004 ],
              [-74.93355188399994, 10.411496640000053],
              [-74.93385859699993, 10.411432955000066],
              [-74.93426300499993, 10.41136298400005 ],
              [-74.93441215099995, 10.411337182000068],
              [-74.93496939199991, 10.411300757000049],
              [-74.9354641889999 , 10.411306227000068],
              [-74.93547736399995, 10.411306368000055],
              [-74.9359006869999 , 10.41131570400006 ],
              [-74.93623930099994, 10.411307804000046],
              [-74.93686717899993, 10.411302616000057],
              [-74.93692485199995, 10.411304943000061],
              [-74.93735402499993, 10.411322250000069],
              [-74.93825011199993, 10.411361732000046],
              [-74.93869394799992, 10.411368939000056],
              [-74.93898738799993, 10.411373702000049],
              [-74.93968227199991, 10.41136483200006 ],
              [-74.93992564099995, 10.411353701000053],
              [-74.94027837599992, 10.411349251000047],
              [-74.94046874399993, 10.411306830000058],
              [-74.94070836599991, 10.411218879000046],
              [-74.94090557599992, 10.411099615000069],
              [-74.94103932699994, 10.410994491000054],
              [-74.94121877399994, 10.410829880000051],
              [-74.94122707899993, 10.41082031700006 ],
              [-74.9413283259999 , 10.410703779000073],
              [-74.94133129199992, 10.410700374000044],
              [-74.94136796899994, 10.410638920000054],
              [-74.94140853599993, 10.410570957000061],
              [-74.94147522299994, 10.410448556000063],
              [-74.9415345299999 , 10.410210936000055],
              [-74.94155637799992, 10.410098431000051],
              [-74.9415901719999 , 10.40992443600004 ],
              [-74.94164651699992, 10.409679263000044],
              [-74.94165589699992, 10.409607839000046],
              [-74.94172207799994, 10.409467618000065],
              [-74.94180753799992, 10.40927792900004 ],
              [-74.94187357899995, 10.409088281000038],
              [-74.94191466099994, 10.408898707000048],
              [-74.94196689799992, 10.408731064000051],
              [-74.94199850899992, 10.408520130000056],
              [-74.9420791469999 , 10.408200813000064],
              [-74.94215884399995, 10.40793424800006 ],
              [-74.94223580999994, 10.40768141600006 ],
              [-74.9423543979999 , 10.407436715000074],
              [-74.9424511279999 , 10.407310145000054],
              [-74.94257011799994, 10.407210970000051],
              [-74.94277506199995, 10.407097836000048],
              [-74.94299942399994, 10.40698738900005 ],
              [-74.94317118599992, 10.406912782000063],
              [-74.94333749799995, 10.40687388400005 ],
              [-74.94349813499991, 10.406788328000061],
              [-74.94371682499991, 10.406633967000062],
              [-74.94392992899992, 10.40646588900006 ],
              [-74.94407656999994, 10.406333689000064],
              [-74.94417338699992, 10.406240068000045],
              [-74.94429221999991, 10.406083227000067],
              [-74.94439701899995, 10.405866022000055],
              [-74.94448786299995, 10.40561864600005 ],
              [-74.94453156199995, 10.405374151000046],
              [-74.94454516199994, 10.40527800700005 ],
              [-74.94458096199992, 10.405184546000044],
              [-74.9446250659999 , 10.405085579000058],
              [-74.94464694499993, 10.404975685000068],
              [-74.94467715199994, 10.404865767000047],
              [-74.94468237199993, 10.40474768000007 ],
              [-74.94470428599993, 10.404648769000062],
              [-74.94475921199995, 10.40445092400006 ],
              [-74.94483886599994, 10.404170627000042],
              [-74.94493803699993, 10.403925976000039],
              [-74.94513783199994, 10.403605067000058],
              [-74.94515894299991, 10.403571162000048],
              [-74.94542990399992, 10.40326288600005 ],
              [-74.94578372099994, 10.402828075000059],
              [-74.9460048979999 , 10.402626929000064],
              [-74.94600782099991, 10.402624267000078],
              [-74.9461654989999 , 10.402472815000067],
              [-74.94636199599995, 10.402318514000058],
              [-74.94646711799993, 10.402219377000051],
              [-74.94653622299995, 10.402134064000052],
              [-74.94661080399993, 10.402024027000039],
              [-74.9466935989999 , 10.401875529000051],
              [-74.94679581199995, 10.401726972000063],
              [-74.94686210099991, 10.401627939000036],
              [-74.94699522399992, 10.40156279200005 ],
              [-74.94701444299994, 10.401553384000067],
              [-74.9474327659999 , 10.401373761000059],
              [-74.94803944299991, 10.401105753000081],
              [-74.94855472699993, 10.400887427000043],
              [-74.94926391699994, 10.400577947000045],
              [-74.94943525799994, 10.400504572000045],
              [-74.94981243799992, 10.40034305000006 ],
              [-74.95005556699994, 10.400225935000037],
              [-74.95039349799993, 10.400071349000086],
              [-74.95018882999995, 10.399617933000059],
              [-74.94971270499991, 10.398630728000057],
              [-74.9492923489999 , 10.39774955100006 ],
              [-74.94916527499993, 10.397471351000092],
              [-74.94899839499993, 10.397105994000071],
              [-74.94853329599994, 10.396089474000064],
              [-74.94834350399992, 10.39566529700005 ],
              [-74.9484460299999 , 10.395298905000061],
              [-74.94853858899995, 10.394942344000071],
              [-74.94875702599995, 10.394100864000052],
              [-74.94894510199993, 10.393385288000047],
              [-74.94903146699994, 10.393056691000083],
              [-74.94933490299991, 10.393125419000057],
              [-74.94966046299993, 10.393172123000056],
              [-74.9498750219999 , 10.39319716500006 ],
              [-74.94998879499991, 10.39288565600009 ],
              [-74.95023843899992, 10.39223329300006 ],
              [-74.95046963699991, 10.391595624000047],
              [-74.95060981599994, 10.391470759000072],
              [-74.9511002289999 , 10.390960516000064],
              [-74.95139891999992, 10.390655826000057],
              [-74.9517300839999 , 10.390320650000092],
              [-74.9518414339999 , 10.39020795500005 ],
              [-74.95236873599993, 10.389664655000047],
              [-74.95247498499992, 10.389557040000057],
              [-74.95279280699992, 10.38923513800006 ],
              [-74.95288116899991, 10.389095775000044],
              [-74.95310957399994, 10.388783949000072],
              [-74.95315704899991, 10.388571475000049],
              [-74.95321178599994, 10.388339449000057],
              [-74.95321804899993, 10.388312906000067],
              [-74.95324971499991, 10.388178665000055],
              [-74.9532677869999 , 10.388102070000059],
              [-74.95327116199991, 10.38808776500008 ],
              [-74.9532849599999 , 10.388029276000054],
              [-74.95342029699992, 10.387498039000093],
              [-74.95354816199995, 10.386941197000056],
              [-74.9536248199999 , 10.386582197000052],
              [-74.95376486899994, 10.386413398000059],
              [-74.9539270109999 , 10.386211589000084],
              [-74.9540595659999 , 10.386006201000043],
              [-74.95411820999993, 10.385819321000042],
              [-74.95415459599991, 10.385606877000043],
              [-74.95415596399994, 10.38557283700004 ],
              [-74.9541631049999 , 10.385395043000074],
              [-74.95416841999992, 10.385262692000083],
              [-74.95430503799992, 10.385189095000044],
              [-74.95456728399995, 10.385078536000037],
              [-74.95479615999994, 10.384935127000062],
              [-74.9549548629999 , 10.384824853000055],
              [-74.9550914319999 , 10.38470553600007 ],
              [-74.95513561899992, 10.384666928000058],
              [-74.95529980499992, 10.384551867000084],
              [-74.95533492099992, 10.384527257000059],
              [-74.95559329199995, 10.384354472000041],
              [-74.95609915099993, 10.384082154000055],
              [-74.95646107599993, 10.383912745000089],
              [-74.95703723399993, 10.383654877000083],
              [-74.9572034549999 , 10.383588519000057],
              [-74.9572919119999 , 10.383485759000052],
              [-74.95757594399993, 10.38323235900009 ],
              [-74.95776781599994, 10.383081722000043],
              [-74.9579894069999 , 10.382978602000037],
              [-74.95826282399992, 10.38289730400004 ],
              [-74.95853269199995, 10.382867263000037],
              [-74.95868873899991, 10.382889998000053],
              [-74.95875466199993, 10.382899603000055],
              [-74.9588915999999 , 10.382939494000084],
              [-74.95898306899994, 10.383010245000037],
              [-74.95899536899992, 10.383019753000042],
              [-74.95905496799992, 10.383173355000054],
              [-74.9590849839999 , 10.383327041000086],
              [-74.95910795999993, 10.383605222000085],
              [-74.9591014799999 , 10.383931078000046],
              [-74.9590834739999 , 10.384103204000041],
              [-74.9591685499999 , 10.38411029200006 ],
              [-74.95936464399995, 10.384146359000056],
              [-74.9595755009999 , 10.38417140200005 ],
              [-74.95976419899995, 10.384207490000051],
              [-74.95996757199993, 10.384203270000057],
              [-74.96031129699992, 10.384136417000093],
              [-74.96045112099995, 10.384096446000058],
              [-74.96049235299995, 10.384084659000052],
              [-74.96068085699994, 10.384051189000047],
              [-74.9608668269999 , 10.383976293000046],
              [-74.96089139899993, 10.383966397000052],
              [-74.96115047599994, 10.383828441000048],
              [-74.9611609449999 , 10.383822867000049],
              [-74.96132333599991, 10.38370892000006 ],
              [-74.96165929799992, 10.383513951000054],
              [-74.9617094159999 , 10.383487357000092],
              [-74.96181541899995, 10.383431107000092],
              [-74.96203523199995, 10.383314465000069],
              [-74.9621725419999 , 10.383241601000066],
              [-74.96239783999994, 10.383142127000042],
              [-74.96251615199992, 10.383134477000056],
              [-74.96257711999993, 10.383135282000069],
              [-74.96261694899994, 10.383121901000038],
              [-74.96267850299995, 10.383095885000046],
              [-74.96293749999995, 10.382974774000047],
              [-74.96302978099993, 10.382933535000063],
              [-74.96309960699995, 10.382906113000047],
              [-74.96312510999991, 10.382898141000055],
              [-74.96314465299992, 10.382893895000052],
              [-74.96324416499994, 10.382880173000046],
              [-74.96331923699995, 10.382871840000064],
              [-74.96338707799993, 10.38286699200006 ],
              [-74.9634491509999 , 10.382865640000091],
              [-74.9635058739999 , 10.382867435000037],
              [-74.96356777999995, 10.38287218000005 ],
              [-74.96363628199992, 10.382879915000046],
              [-74.96372611699991, 10.382892287000061],
              [-74.9638138549999 , 10.382906160000061],
              [-74.96389844399994, 10.38292203800006 ],
              [-74.96397998999993, 10.382939949000047],
              [-74.96405857399992, 10.382959907000043],
              [-74.96413156299991, 10.382980562000057],
              [-74.96419560099991, 10.383000450000054],
              [-74.96425406599991, 10.38302062300005 ],
              [-74.96430800699994, 10.383041455000068],
              [-74.96435061799991, 10.383061216000044],
              [-74.96439652299995, 10.383087086000046],
              [-74.96454107599993, 10.383182779000037],
              [-74.96459383199993, 10.383214893000059],
              [-74.96464650299993, 10.383241354000063],
              [-74.9646962679999 , 10.383259689000056],
              [-74.96474841899993, 10.383273347000056],
              [-74.96480488999993, 10.383284613000058],
              [-74.9648658449999 , 10.38329353100005 ],
              [-74.96493173899995, 10.383300158000054],
              [-74.96498195799995, 10.383302445000083],
              [-74.96503453399993, 10.383301414000073],
              [-74.96508999499991, 10.383297063000043],
              [-74.96514942099992, 10.383289305000062],
              [-74.96525641499994, 10.383269403000043],
              [-74.96545708699995, 10.383223667000038],
              [-74.96553749799995, 10.383206999000038],
              [-74.9657077469999 , 10.383178141000087],
              [-74.96577211899995, 10.383165847000043],
              [-74.96584007099995, 10.383149522000053],
              [-74.96589940099994, 10.38313082400009 ],
              [-74.96594597799992, 10.383110982000062],
              [-74.96599595299995, 10.383083623000061],
              [-74.96604203999993, 10.383054267000091],
              [-74.96614731299991, 10.382982662000074],
              [-74.96619866899994, 10.382951177000052],
              [-74.96624562099993, 10.382927236000057],
              [-74.96629010299995, 10.382910009000057],
              [-74.96634475299993, 10.38289476500006 ],
              [-74.96640676599992, 10.382881940000061],
              [-74.96647489399993, 10.382871385000044],
              [-74.9665878269999 , 10.38285714300008 ],
              [-74.96661826499991, 10.382853676000082],
              [-74.96670586399995, 10.382843698000045],
              [-74.96680860099991, 10.382835459000091],
              [-74.96690294399991, 10.38283186800004 ],
              [-74.9669907629999 , 10.38283289900005 ],
              [-74.96707752299994, 10.382838527000047],
              [-74.96716897999994, 10.382848803000059],
              [-74.96726598999993, 10.382863822000047],
              [-74.9673705479999 , 10.382883887000048],
              [-74.96747171899995, 10.382907681000063],
              [-74.96757986699993, 10.382938434000039],
              [-74.96769760799992, 10.382976821000057],
              [-74.96783820499991, 10.383027154000047],
              [-74.96797123899995, 10.383079034000048],
              [-74.96811888099995, 10.383142024000051],
              [-74.96820939299994, 10.383183127000052],
              [-74.9683006169999 , 10.383226789000048],
              [-74.96839261899993, 10.383273027000087],
              [-74.96848547799993, 10.383321889000058],
              [-74.96857926599995, 10.383373410000047],
              [-74.96867412299991, 10.383427667000092],
              [-74.96886762299994, 10.383544717000063],
              [-74.96899645099995, 10.383626892000052],
              [-74.96909583399992, 10.383694429000059],
              [-74.96922281899992, 10.383787902000051],
              [-74.96933582299994, 10.383878800000048],
              [-74.96939969499994, 10.383934190000048],
              [-74.96946209499993, 10.383991197000057],
              [-74.9695228409999 , 10.384049657000048],
              [-74.9695817839999 , 10.384109417000047],
              [-74.96963478499993, 10.384166452000045],
              [-74.96969068399994, 10.384230296000055],
              [-74.96982791099992, 10.384397853000053],
              [-74.9699514909999 , 10.384556247000091],
              [-74.9700007159999 , 10.384623202000057],
              [-74.9700448339999 , 10.384686377000037],
              [-74.97013381099993, 10.384823313000084],
              [-74.97017302799992, 10.384888414000045],
              [-74.97020912499994, 10.38495191000004 ],
              [-74.97023627999994, 10.385004059000039],
              [-74.97029730699995, 10.385131562000083],
              [-74.9703266109999 , 10.385188664000054],
              [-74.9703598559999 , 10.385246348000067],
              [-74.97043698399995, 10.385371637000048],
              [-74.9704674589999 , 10.385425354000063],
              [-74.97051785799994, 10.385524019000059],
              [-74.97056487999993, 10.385625179000044],
              [-74.97060835399992, 10.385728466000046],
              [-74.97064811099995, 10.385833483000056],
              [-74.97067363799994, 10.385903710000036],
              [-74.97068579099994, 10.385945544000037],
              [-74.97069484899993, 10.38599730900006 ],
              [-74.97070232999994, 10.386075522000056],
              [-74.97070881299993, 10.386167937000039],
              [-74.97070839199995, 10.386235829000043],
              [-74.9707020749999 , 10.386304598000038],
              [-74.9706914969999 , 10.386360288000049],
              [-74.97068172199994, 10.386392537000063],
              [-74.97066216799993, 10.386445752000043],
              [-74.97064406399994, 10.386491100000057],
              [-74.97062119599991, 10.38654063000007 ],
              [-74.97054142799993, 10.386693664000063],
              [-74.97051577899992, 10.38674555700004 ],
              [-74.97049496999995, 10.386792706000051],
              [-74.9704784729999 , 10.386836578000043],
              [-74.97044673499994, 10.386939123000047],
              [-74.97040488899995, 10.387078343000042],
              [-74.97038555299991, 10.387152216000061],
              [-74.97037213599992, 10.387223532000064],
              [-74.97036641099993, 10.387288194000064],
              [-74.97036777599993, 10.387363855000046],
              [-74.97037675299993, 10.387458929000047],
              [-74.97038465499992, 10.387517173000049],
              [-74.9703943749999 , 10.387573165000049],
              [-74.97040590899991, 10.387626905000047],
              [-74.97041926499992, 10.387678365000056],
              [-74.97043570999995, 10.387727902000051],
              [-74.97047743399992, 10.387832113000059],
              [-74.9704937539999 , 10.387878193000063],
              [-74.97050823899991, 10.387930578000066],
              [-74.97053560099994, 10.388048128000037],
              [-74.9705498159999 , 10.388097453000057],
              [-74.97057731699994, 10.388169442000049],
              [-74.9706402889999 , 10.38830768300005 ],
              [-74.97066447299994, 10.388366017000067],
              [-74.97071078199991, 10.388485309000089],
              [-74.97072256699994, 10.388522648000048],
              [-74.9707307239999 , 10.388556655000059],
              [-74.97073921499992, 10.388618414000064],
              [-74.97074429699995, 10.38870840900006 ],
              [-74.97074564299993, 10.38876994900005 ],
              [-74.97074469099994, 10.388847184000042],
              [-74.97074115899994, 10.388909695000052],
              [-74.97073313099992, 10.388979211000049],
              [-74.97069626599995, 10.389196648000052],
              [-74.9706857079999 , 10.389269006000063],
              [-74.97067941699993, 10.38933277700005 ],
              [-74.97067713899992, 10.389391069000055],
              [-74.97067378899993, 10.389508337000052],
              [-74.97067671699995, 10.389542457000061],
              [-74.97068319699991, 10.389572188000045],
              [-74.97069629299995, 10.389609828000061],
              [-74.97071110899992, 10.389639806000048],
              [-74.97072549399991, 10.38966150500005 ],
              [-74.97074079299995, 10.389679972000067],
              [-74.97076120199995, 10.389698252000073],
              [-74.97078659599993, 10.389714831000049],
              [-74.9708171069999 , 10.38972982200005 ],
              [-74.97085370099995, 10.38974369400006 ],
              [-74.9709001999999 , 10.389755413000046],
              [-74.97095414599994, 10.389761744000054],
              [-74.97099963399995, 10.38976155000006 ],
              [-74.97106267099991, 10.389755274000038],
              [-74.9711633739999 , 10.389737646000071],
              [-74.97121486099991, 10.389735246000043],
              [-74.97130355099995, 10.389737257000093],
              [-74.97142183799991, 10.389741266000044],
              [-74.97154829899995, 10.389750402000061],
              [-74.97159139599995, 10.389756651000084],
              [-74.97162950899991, 10.38976504100009 ],
              [-74.97167438799994, 10.389779304000058],
              [-74.97172274199994, 10.389799355000036],
              [-74.97177469799993, 10.38982492200006 ],
              [-74.97185203099991, 10.389866747000042],
              [-74.97190643399995, 10.389896555000064],
              [-74.97193924499993, 10.389917633000039],
              [-74.97197475399992, 10.38994497400006 ],
              [-74.9720007219999 , 10.389969506000057],
              [-74.97202369299993, 10.389998365000054],
              [-74.9720443949999 , 10.390032941000072],
              [-74.9720629869999 , 10.390073466000047],
              [-74.9720800039999 , 10.390121073000046],
              [-74.97209319799992, 10.390161214000045],
              [-74.9720954519999 , 10.390173652000044],
              [-74.97209562799992, 10.390184460000057],
              [-74.97209084799994, 10.390204666000045],
              [-74.97208000499995, 10.390226214000052],
              [-74.97206403199993, 10.390247171000055],
              [-74.97204536699991, 10.390264285000057],
              [-74.97203530399992, 10.390270317000045],
              [-74.9720227119999 , 10.39027522300006 ],
              [-74.97196221099995, 10.390290537000055],
              [-74.97192508699993, 10.390307558000075],
              [-74.97185053999993, 10.39034973300005 ],
              [-74.97181759099993, 10.390366094000058],
              [-74.9717691919999 , 10.390386148000061],
              [-74.97172037299993, 10.390403984000045],
              [-74.97167145299994, 10.390419484000063],
              [-74.97162270799993, 10.390432568000051],
              [-74.97158632899993, 10.390439593000053],
              [-74.9715015139999 , 10.390451165000059],
              [-74.97146262099994, 10.390459847000045],
              [-74.97138592899995, 10.39048447500005 ],
              [-74.97132641799993, 10.39050960700007 ],
              [-74.97130065099992, 10.390523391000045],
              [-74.9712775239999 , 10.39053804300005 ],
              [-74.97125689799992, 10.390553640000064],
              [-74.97123870299993, 10.390570247000085],
              [-74.9712207629999 , 10.39058969100006 ],
              [-74.97121145199992, 10.39060131900004 ],
              [-74.97120388999991, 10.390610764000087],
              [-74.97118847699994, 10.390632972000049],
              [-74.97117488099991, 10.39065580600004 ],
              [-74.97116249299995, 10.390679542000044],
              [-74.97115528699993, 10.390697332000059],
              [-74.97114875699992, 10.390724551000062],
              [-74.97114663699995, 10.390752735000092],
              [-74.9711489199999 , 10.390781878000041],
              [-74.97115561099992, 10.390811987000063],
              [-74.97118509799992, 10.390882571000077],
              [-74.97134304199994, 10.391260665000061],
              [-74.97141023299992, 10.391421510000043],
              [-74.97147431099995, 10.391497099000048],
              [-74.97154124799994, 10.39156994800004 ],
              [-74.97159573499994, 10.39162514800006 ],
              [-74.97165197899994, 10.391678658000046],
              [-74.9717099799999 , 10.39173048300006 ],
              [-74.97176974099995, 10.391780616000062],
              [-74.97170465599993, 10.39192199200005 ],
              [-74.97167471099993, 10.391987046000054],
              [-74.97165750599993, 10.392012487000045],
              [-74.97160663199992, 10.392087727000046],
              [-74.97093517899992, 10.393080725000061],
              [-74.9702077309999 , 10.393961791000038],
              [-74.97045316499992, 10.394049236000058],
              [-74.9707540789999 , 10.394207645000051],
              [-74.97107118299994, 10.394341011000051],
              [-74.97131194799994, 10.394444426000064],
              [-74.97163115399991, 10.394583462000071],
              [-74.97170778299994, 10.394616838000047],
              [-74.97197638799992, 10.39470209600006 ],
              [-74.9722959209999 , 10.394807985000057],
              [-74.97259240199992, 10.394888711000078],
              [-74.97291268899994, 10.39492101400009 ],
              [-74.97291671699992, 10.39492142000006 ],
              [-74.97344285499992, 10.394886043000042],
              [-74.98008276299993, 10.394846450000045],
              [-74.98180584399995, 10.394836155000064],
              [-74.98323988399994, 10.394827578000047],
              [-74.98842240899995, 10.394796531000054],
              [-74.99166708599995, 10.394777052000052],
              [-74.99239024999991, 10.394772705000037],
              [-74.99270895699993, 10.394769010000061],
              [-74.99278795599992, 10.39477376800005 ],
              [-74.99292329999992, 10.394781914000077],
              [-74.99335422099995, 10.394807850000063],
              [-74.9933949629999 , 10.394810302000053],
              [-74.99341721499991, 10.394778073000055],
              [-74.99360283899995, 10.394509223000057],
              [-74.99362308099995, 10.39446674900006 ],
              [-74.99366240899991, 10.394371804000059],
              [-74.99369486299992, 10.39427429500006 ],
              [-74.99372027599992, 10.39417472200006 ],
              [-74.99373852499991, 10.394073586000047],
              [-74.99374950999993, 10.393971405000059],
              [-74.99375317499994, 10.393868704000056],
              [-74.99374950999993, 10.393766003000053],
              [-74.99373852499991, 10.393663823000054],
              [-74.99372027599992, 10.393562687000042],
              [-74.99369486299992, 10.393463111000074],
              [-74.99368516699991, 10.393431459000055],
              [-74.9934555399999 , 10.39306821200006 ],
              [-74.99331309899992, 10.39289926300006 ],
              [-74.99304917499995, 10.392586221000045],
              [-74.99391142699994, 10.39272767600005 ],
              [-74.99460715399994, 10.392735970000047],
              [-74.99526119299992, 10.39269298000005 ],
              [-74.99574901199992, 10.392619626000055],
              [-74.99634021199995, 10.392391772000053],
              [-74.99695202499993, 10.39211246700006 ],
              [-74.99914994999995, 10.390954815000043],
              [-75.00236207499995, 10.389202818000058],
              [-75.0038082609999 , 10.388418021000064],
              [-75.00399811599993, 10.388317322000091],
              [-75.0053258609999 , 10.38761308700009 ],
              [-75.00511114699992, 10.387330088000056],
              [-75.00489255199994, 10.387050370000054],
              [-75.00467008499993, 10.386773919000063],
              [-75.00444373999994, 10.38650074900005 ],
              [-75.00436692599993, 10.386411828000064],
              [-75.00428430099993, 10.38632092000006 ],
              [-75.00419506499992, 10.386227142000052],
              [-75.00409764099993, 10.386128822000046],
              [-75.00390449299994, 10.385942538000052],
              [-75.00353579199992, 10.385599206000052],
              [-75.00338920999991, 10.38546036300005 ],
              [-75.00324106099993, 10.385314752000056],
              [-75.00311199499993, 10.385180974000093],
              [-75.00303963999994, 10.385102060000065],
              [-75.00297182199995, 10.385024827000052],
              [-75.00290808099993, 10.384948747000067],
              [-75.00284812199993, 10.384873472000038],
              [-75.0027916279999 , 10.384798602000046],
              [-75.00273851799994, 10.384723997000037],
              [-75.00268863299993, 10.384649465000052],
              [-75.00264188699992, 10.384574848000057],
              [-75.0026083219999 , 10.384523586000057],
              [-75.00252343499994, 10.384404302000064],
              [-75.00248751799995, 10.384346833000052],
              [-75.0024699729999 , 10.384312797000064],
              [-75.00245676999992, 10.384280746000059],
              [-75.00244767099991, 10.38425006600005 ],
              [-75.00244257199995, 10.38422042700006 ],
              [-75.00244121599991, 10.384186355000054],
              [-75.00244418599993, 10.38415001900006 ],
              [-75.00245150399991, 10.384111136000058],
              [-75.00246327199994, 10.384069139000076],
              [-75.0024777289999 , 10.384028313000044],
              [-75.00249650399991, 10.383982667000055],
              [-75.00256951499995, 10.38382484500005 ],
              [-75.0025880309999 , 10.383785842000066],
              [-75.00260924299994, 10.383745992000058],
              [-75.00263337299992, 10.383704874000045],
              [-75.00266088799992, 10.383661694000068],
              [-75.00271532999994, 10.383583239000075],
              [-75.00285294999992, 10.38339553500009 ],
              [-75.00288847199994, 10.383343615000058],
              [-75.00291877899991, 10.383295909000083],
              [-75.00296606599994, 10.383213281000053],
              [-75.0030146009999 , 10.383118633000038],
              [-75.00305953499992, 10.383023575000038],
              [-75.0030654759999 , 10.383010503000037],
              [-75.0031709239999 , 10.382778519000055],
              [-75.00323606699993, 10.382647235000093],
              [-75.00327342999992, 10.382579798000052],
              [-75.00331078999994, 10.382518556000036],
              [-75.00334885599995, 10.382462358000055],
              [-75.00338801899994, 10.382410629000049],
              [-75.00342332699995, 10.38237054800004 ],
              [-75.0034642949999 , 10.382331330000056],
              [-75.00351131399992, 10.382292581000058],
              [-75.00356525299992, 10.382253602000048],
              [-75.00361604499994, 10.38222047700009 ],
              [-75.00367466099993, 10.382185100000072],
              [-75.00388014899994, 10.382069819000037],
              [-75.0039565109999 , 10.38202494300009 ],
              [-75.00402983899994, 10.38197727000005 ],
              [-75.0040907529999 , 10.381931555000051],
              [-75.00412247399993, 10.381904234000046],
              [-75.00415090899992, 10.381876713000054],
              [-75.00417623499993, 10.381848811000054],
              [-75.00419856999991, 10.381820404000052],
              [-75.00421802299991, 10.381791339000074],
              [-75.00423463099992, 10.381761569000048],
              [-75.00424842699994, 10.381731001000048],
              [-75.00425945199993, 10.381699579000042],
              [-75.00427250899992, 10.381653037000092],
              [-75.00428344999995, 10.381606734000059],
              [-75.00429214699994, 10.381561219000048],
              [-75.00429853899993, 10.38151683700005 ],
              [-75.00430257399995, 10.381473978000088],
              [-75.00430425499991, 10.381432722000056],
              [-75.00430357599993, 10.38139323200005 ],
              [-75.00430053799994, 10.381355618000043],
              [-75.00429049099995, 10.38130117500009 ],
              [-75.00425791899994, 10.381178606000049],
              [-75.00424213399992, 10.381099172000063],
              [-75.00422881299994, 10.381015750000074],
              [-75.00421781499995, 10.380927495000037],
              [-75.00420893499995, 10.380832764000047],
              [-75.0041981299999 , 10.380652861000044],
              [-75.00418676299995, 10.38032416100009 ],
              [-75.0041808869999 , 10.380195938000043],
              [-75.00417176699995, 10.380065861000048],
              [-75.00415958199994, 10.37994937700006 ],
              [-75.00415139699993, 10.379896547000044],
              [-75.0041396279999 , 10.379840801000057],
              [-75.00412417899992, 10.379781671000046],
              [-75.00410482899991, 10.379718298000057],
              [-75.00408268599995, 10.37965358300005 ],
              [-75.00405589899992, 10.379581520000045],
              [-75.00394367299992, 10.379301464000037],
              [-75.00389961999991, 10.37918393700005 ],
              [-75.00387841899993, 10.37912070100009 ],
              [-75.00386108299995, 10.379062374000057],
              [-75.00384724899993, 10.379007781000041],
              [-75.00383677399992, 10.37895633100004 ],
              [-75.00382820199991, 10.37889500600005 ],
              [-75.00382479299992, 10.378837432000068],
              [-75.00382652799993, 10.378783189000046],
              [-75.0038334109999 , 10.378731997000045],
              [-75.00384550699994, 10.378683566000063],
              [-75.00386283699993, 10.378637814000058],
              [-75.00388545599992, 10.37859461100004 ],
              [-75.00391341599993, 10.378553859000078],
              [-75.00396466199993, 10.37849301500006 ],
              [-75.00401831999994, 10.37843894200006 ],
              [-75.0040437749999 , 10.378417592000062],
              [-75.00407109199995, 10.378398615000037],
              [-75.00410032899993, 10.378381966000063],
              [-75.00413157299994, 10.378367600000047],
              [-75.00416487399991, 10.378355483000064],
              [-75.00420040799992, 10.378345547000038],
              [-75.00423834399993, 10.378337746000057],
              [-75.0042789439999 , 10.378332022000052],
              [-75.00435190899992, 10.37832701700006 ],
              [-75.00443602299993, 10.37832723300005 ],
              [-75.00452013099994, 10.378331409000054],
              [-75.00463228199993, 10.378339333000042],
              [-75.00473892199994, 10.378346867000062],
              [-75.00480146099994, 10.37834934600005 ],
              [-75.00485701899993, 10.378349687000082],
              [-75.00491720399992, 10.378347310000038],
              [-75.00497226099992, 10.378341848000048],
              [-75.00502316599994, 10.378333204000057],
              [-75.00507040199994, 10.378321288000052],
              [-75.0051454469999 , 10.37829780900006 ],
              [-75.00522019499994, 10.378272228000071],
              [-75.00529439999991, 10.378244640000048],
              [-75.00536779099991, 10.378215129000068],
              [-75.0054401299999 , 10.378183806000038],
              [-75.00551117799995, 10.378150769000058],
              [-75.00558070999995, 10.378116120000072],
              [-75.0056485259999 , 10.378079969000055],
              [-75.00571012699993, 10.378043468000044],
              [-75.0057758769999 , 10.377999487000068],
              [-75.0058446669999 , 10.37794927400006 ],
              [-75.00604001199991, 10.377800680000064],
              [-75.00610270599992, 10.377756894000072],
              [-75.00616049599995, 10.377720249000049],
              [-75.00633327299994, 10.377618579000057],
              [-75.00646152099995, 10.37754923600005 ],
              [-75.00652176199992, 10.377519721000056],
              [-75.0065792879999 , 10.377493813000058],
              [-75.00663477099994, 10.37747121700005 ],
              [-75.00668857499994, 10.377451785000062],
              [-75.00675011399994, 10.377433962000055],
              [-75.00681544199995, 10.377420739000058],
              [-75.00688511099992, 10.377411989000052],
              [-75.00696015599993, 10.37740758600006 ],
              [-75.00703696599993, 10.37740732800006 ],
              [-75.00710891299991, 10.377410248000047],
              [-75.00712284699995, 10.377410814000086],
              [-75.0072125879999 , 10.37741731500006 ],
              [-75.0074632589999 , 10.377439224000057],
              [-75.00753986799992, 10.377443757000037],
              [-75.00760817499992, 10.377445685000055],
              [-75.00768598699995, 10.377444557000047],
              [-75.00775778499991, 10.377439478000042],
              [-75.00782496699992, 10.37743034500005 ],
              [-75.00788823399995, 10.37741705500008 ],
              [-75.00800372499992, 10.377384456000073],
              [-75.0081281759999 , 10.37734217600007 ],
              [-75.0082645139999 , 10.377289383000061],
              [-75.00843490599993, 10.37721749700006 ],
              [-75.00850933299995, 10.377182699000059],
              [-75.00859048099994, 10.37713961500009 ],
              [-75.00866709099995, 10.377095429000065],
              [-75.0088504549999 , 10.376985693000051],
              [-75.00894572599992, 10.376932484000065],
              [-75.0090403609999 , 10.376886116000037],
              [-75.0090849629999 , 10.376867188000062],
              [-75.0091284209999 , 10.376850800000057],
              [-75.00924401799995, 10.376811579000048],
              [-75.00934794799991, 10.376779660000068],
              [-75.00939056899995, 10.376768207000055],
              [-75.00944495999994, 10.376753593000046],
              [-75.0095364359999 , 10.376733021000064],
              [-75.0096241729999 , 10.376717606000057],
              [-75.0097078789999 , 10.37670745400004 ],
              [-75.00978796999993, 10.376702521000084],
              [-75.00986467799993, 10.37670279300005 ],
              [-75.00991565399994, 10.376706115000047],
              [-75.00996892499995, 10.37671228000005 ],
              [-75.01002497199994, 10.376721338000038],
              [-75.01008467999992, 10.376733441000056],
              [-75.01020770399992, 10.376764267000055],
              [-75.01042282599991, 10.37682620600009 ],
              [-75.01051088999992, 10.376853896000057],
              [-75.01060771399995, 10.376889707000089],
              [-75.01069870099991, 10.376926929000092],
              [-75.01090940499995, 10.37701701800006 ],
              [-75.01101197799994, 10.37705784700006 ],
              [-75.01110556099991, 10.377090637000038],
              [-75.01119194899991, 10.377115721000052],
              [-75.01124804999995, 10.377129015000037],
              [-75.01130701299991, 10.377140785000051],
              [-75.01136939199995, 10.377151138000045],
              [-75.01143621699993, 10.377160250000088],
              [-75.01157144699994, 10.37717411300008 ],
              [-75.01183794299993, 10.377194276000068],
              [-75.01194847999994, 10.377204264000056],
              [-75.01206315899992, 10.377218288000051],
              [-75.01216506499992, 10.377235520000056],
              [-75.01226709199995, 10.377257262000057],
              [-75.01238003199995, 10.37728463700006 ],
              [-75.01275749899992, 10.377386533000049],
              [-75.01290054999993, 10.377422766000052],
              [-75.01313019199995, 10.37747573300004 ],
              [-75.01337023799994, 10.377527573000066],
              [-75.01362286799991, 10.377578766000056],
              [-75.01389211799994, 10.377630127000089],
              [-75.01415931299994, 10.37767845600007 ],
              [-75.01445686699992, 10.37772993100009 ],
              [-75.01539340499994, 10.377884951000055],
              [-75.0155829979999 , 10.37791412200005 ],
              [-75.01598298399995, 10.377969177000068],
              [-75.01615579399993, 10.377994778000073],
              [-75.01633735299993, 10.378025815000058],
              [-75.01649896399994, 10.378058851000048],
              [-75.01671881199991, 10.378110208000066],
              [-75.01691305799994, 10.378160268000045],
              [-75.01709150199991, 10.378211556000053],
              [-75.01725732599994, 10.378265027000054],
              [-75.0174698109999 , 10.37833725300004 ],
              [-75.01759418199993, 10.37838344000005 ],
              [-75.01765281399992, 10.378407610000068],
              [-75.01770703099993, 10.378431902000045],
              [-75.01775768999994, 10.378456701000061],
              [-75.01780523799994, 10.378482233000057],
              [-75.01790722699991, 10.378543422000064],
              [-75.01800601299993, 10.37861035900005 ],
              [-75.01810159399992, 10.378683040000055],
              [-75.01819397199995, 10.378761474000044],
              [-75.01850296999993, 10.378819111000041],
              [-75.0188742869999 , 10.378840782000054],
              [-75.01923233199994, 10.378820210000072],
              [-75.01947531299993, 10.378773953000064],
              [-75.01970849399993, 10.378747246000046],
              [-75.01999093799992, 10.378713883000046],
              [-75.02018788899994, 10.378658009000048],
              [-75.0204340109999 , 10.37856295000006 ],
              [-75.0209031199999 , 10.378330620000042],
              [-75.02118517899993, 10.378170404000059],
              [-75.02138522399991, 10.378049462000092],
              [-75.0216277689999 , 10.37786009100006 ],
              [-75.02183442499995, 10.377752135000037],
              [-75.02208036999991, 10.377601784000092],
              [-75.0222575059999 , 10.377510186000052],
              [-75.02247236499994, 10.377407876000063],
              [-75.02254620399992, 10.377372715000092],
              [-75.02269708499995, 10.377290952000067],
              [-75.02279135099991, 10.377237896000054],
              [-75.02290043099993, 10.377176502000054],
              [-75.02310391399993, 10.377107594000051],
              [-75.02312001899992, 10.377000206000048],
              [-75.0232250709999 , 10.375887472000045],
              [-75.02322987999992, 10.375826085000085],
              [-75.02324359799991, 10.375651205000054],
              [-75.02327252499992, 10.375282324000068],
              [-75.02329134799993, 10.37501367100009 ],
              [-75.02330227099992, 10.374896383000078],
              [-75.0233035839999 , 10.374882281000055],
              [-75.0233429889999 , 10.374459254000044],
              [-75.0233658379999 , 10.374000902000091],
              [-75.02337353099995, 10.373890363000044],
              [-75.02338906299991, 10.373667313000055],
              [-75.0233442249999 , 10.373665305000088],
              [-75.02318788799994, 10.373658309000064],
              [-75.0230278969999 , 10.37364438800006 ],
              [-75.02287755599991, 10.373616041000048],
              [-75.02263510099993, 10.373580768000068],
              [-75.02250115599992, 10.37356223100005 ],
              [-75.02249933099995, 10.373561975000086],
              [-75.02215511199995, 10.373534199000062],
              [-75.02200480699992, 10.373517849000052],
              [-75.0218761829999 , 10.373458245000052],
              [-75.02172078299992, 10.373360327000057],
              [-75.02145603499991, 10.373164357000064],
              [-75.0213005789999 , 10.373047246000056],
              [-75.02104045099992, 10.372776885000064],
              [-75.02090906199993, 10.372604513000056],
              [-75.0207921999999 , 10.372427301000073],
              [-75.02073094499991, 10.372204341000042],
              [-75.02069637299991, 10.371990896000057],
              [-75.02067117699994, 10.371671844000048],
              [-75.02062424899992, 10.371379258000047],
              [-75.02061404299991, 10.371208927000055],
              [-75.0206404619999 , 10.371129668000037],
              [-75.02074201099992, 10.371052588000055],
              [-75.02089651099993, 10.370985628000085],
              [-75.0209259579999 , 10.370972865000056],
              [-75.0210298529999 , 10.37086938200008 ],
              [-75.0210787609999 , 10.370813503000079],
              [-75.0211940829999 , 10.370681742000045],
              [-75.02128824099992, 10.370563893000053],
              [-75.02141369199995, 10.370376369000041],
              [-75.0215464229999 , 10.370188822000046],
              [-75.02175184499993, 10.370001057000081],
              [-75.0217524059999 , 10.370000608000055],
              [-75.02190173999992, 10.369880647000059],
              [-75.02204452699993, 10.36981544200006 ],
              [-75.02221173299995, 10.369812546000048],
              [-75.02233287099995, 10.36980019400005 ],
              [-75.02246594099995, 10.369727814000044],
              [-75.02268131599993, 10.369624         ],
              [-75.02306853099992, 10.369473073000051],
              [-75.0230782939999 , 10.369469267000056],
              [-75.02339797499991, 10.369398735000061],
              [-75.02369588199991, 10.369340266000052],
              [-75.0240786789999 , 10.369305542000063],
              [-75.02442271299992, 10.369273330000055],
              [-75.02473289399995, 10.369267611000055],
              [-75.02503590299995, 10.369293105000054],
              [-75.02536804199991, 10.369335309000064],
              [-75.02563961999994, 10.369384891000038],
              [-75.02590637999992, 10.369446482000058],
              [-75.02612140899993, 10.369511585000055],
              [-75.02617320899992, 10.36952726900006 ],
              [-75.02633020699994, 10.369589285000075],
              [-75.02637216699992, 10.369605858000057],
              [-75.02641826099995, 10.36959627400006 ],
              [-75.02651263699994, 10.369576651000045],
              [-75.02680852299994, 10.36956153500006 ],
              [-75.02701184999995, 10.369570366000062],
              [-75.0272591669999 , 10.369612819000054],
              [-75.02739294499992, 10.369626611000058],
              [-75.02739492299992, 10.369626814000071],
              [-75.02739695899993, 10.369626849000042],
              [-75.02800081799995, 10.369637006000062],
              [-75.02843719299995, 10.36968849300007 ],
              [-75.02879349299991, 10.369706628000074],
              [-75.02910378899992, 10.369736899000088],
              [-75.02934277999992, 10.369767958000068],
              [-75.02942865499995, 10.369779118000054],
              [-75.02957657699994, 10.36980986900005 ],
              [-75.0297584779999 , 10.369854917000055],
              [-75.02995250799995, 10.369891367000037],
              [-75.03009794999991, 10.369918687000052],
              [-75.03043739999993, 10.369972861000065],
              [-75.03069199499993, 10.370017690000054],
              [-75.03088840999993, 10.370055492000063],
              [-75.03119618199992, 10.370052176000058],
              [-75.03139158499994, 10.370030671000052],
              [-75.03146509299995, 10.370022581000057],
              [-75.03152651499994, 10.370017889000053],
              [-75.0317819469999 , 10.370009225000047],
              [-75.03195216899991, 10.370011524000063],
              [-75.03216779799993, 10.369991689000074],
              [-75.03250692199992, 10.369940285000041],
              [-75.0326595279999 , 10.369918234000068],
              [-75.03277822799993, 10.369901083000059],
              [-75.03280311099991, 10.369831691000059],
              [-75.03283337199991, 10.369745969000064],
              [-75.03286404599993, 10.369659076000062],
              [-75.03292036499994, 10.369499562000044],
              [-75.03298401299992, 10.36941586100005 ],
              [-75.03300217299994, 10.369391983000071],
              [-75.03303111499991, 10.369361816000037],
              [-75.03319496299991, 10.369191028000046],
              [-75.0332514129999 , 10.369112248000079],
              [-75.03343861899992, 10.368850999000074],
              [-75.0342543289999 , 10.369498609000061],
              [-75.03608297099993, 10.370505635000086],
              [-75.03718908199994, 10.371094999000093],
              [-75.03900375099994, 10.372061880000047],
              [-75.0413227109999 , 10.373297436000087],
              [-75.04355759799995, 10.374556891000054],
              [-75.04557584499992, 10.376007852000043],
              [-75.04596171299994, 10.376285259000042],
              [-75.04797678299991, 10.377937511000084],
              [-75.05002195399993, 10.379469050000068],
              [-75.05017213699995, 10.379592443000092],
              [-75.05298357799995, 10.381902336000053],
              [-75.0544718349999 , 10.383695036000063],
              [-75.05565757999995, 10.38512333500006 ],
              [-75.0573186389999 , 10.387559489000068],
              [-75.05828396299995, 10.388975259000063],
              [-75.06005407799995, 10.391571274000057],
              [-75.06006484699992, 10.391587068000092],
              [-75.06066845599992, 10.392472309000084],
              [-75.0607249869999 , 10.392555216000062],
              [-75.06420023099992, 10.397616165000045],
              [-75.0676554129999 , 10.402504820000047],
              [-75.07026605899995, 10.40584977800006 ],
              [-75.0705760379999 , 10.40613836700004 ],
              [-75.07217339899995, 10.407625505000055],
              [-75.07259307099991, 10.407988966000062],
              [-75.07289199599995, 10.408247853000091],
              [-75.07294206099994, 10.408291210000073],
              [-75.07292334099992, 10.40831939800006 ],
              [-75.07290779599992, 10.408342807000054],
              [-75.07353854099995, 10.40880779300005 ],
              [-75.07353857299995, 10.408807817000081],
              [-75.07357191999995, 10.40883240100004 ],
              [-75.07397032699993, 10.409126106000087],
              [-75.07404435899991, 10.409180683000045],
              [-75.07406656899991, 10.40919705500005 ],
              [-75.0741925829999 , 10.409289953000041],
              [-75.07425109699994, 10.409333089000086],
              [-75.07427899299995, 10.409353654000085],
              [-75.0741874929999 , 10.409605196000086],
              [-75.07418497399993, 10.409612120000077],
              [-75.07416237599995, 10.409659694000084],
              [-75.07414751599993, 10.409690979000061],
              [-75.07413589099991, 10.409715454000093],
              [-75.07413479599995, 10.409717760000092],
              [-75.0741189709999 , 10.409751074000042],
              [-75.0741171599999 , 10.409754887000076],
              [-75.07405917999995, 10.40990627800005 ],
              [-75.07404894199993, 10.409933011000078],
              [-75.07403784999991, 10.409961973000065],
              [-75.07403064399995, 10.409980790000077],
              [-75.07393954699995, 10.410218652000083],
              [-75.07392442299994, 10.410258143000078],
              [-75.07390045699992, 10.41032795700005 ],
              [-75.07389957099991, 10.41033053700005 ],
              [-75.07388066999994, 10.410385598000062],
              [-75.07387944299995, 10.410389172000066],
              [-75.07382048799991, 10.410357887000089],
              [-75.07381059099993, 10.410360232000073],
              [-75.07378445699993, 10.410366423000085],
              [-75.0737509569999 , 10.410374360000048],
              [-75.07374695399994, 10.410375308000084],
              [-75.07373123999992, 10.410379031000048],
              [-75.07363340699993, 10.410402206000072],
              [-75.07361314499991, 10.410419066000088],
              [-75.07359207299993, 10.410436600000082],
              [-75.0735594329999 , 10.410462866000046],
              [-75.07354505799992, 10.410474434000037],
              [-75.07352932599991, 10.410487094000075],
              [-75.07350137599991, 10.41051000200008 ],
              [-75.07350020899992, 10.410510958000089],
              [-75.07347228299994, 10.410533847000067],
              [-75.07345136599992, 10.410576861000038],
              [-75.07344376099991, 10.410616133000076],
              [-75.07342474599994, 10.410649796000087],
              [-75.0733962249999 , 10.410666627000069],
              [-75.07337688099994, 10.41067825400006 ],
              [-75.07336199899993, 10.410687199000051],
              [-75.0733277729999 , 10.41070777100009 ],
              [-75.07327643499991, 10.410718992000056],
              [-75.07323079899993, 10.410726472000079],
              [-75.07315093999995, 10.410748913000077],
              [-75.0730900929999 , 10.410763874000054],
              [-75.07305776899994, 10.410776965000082],
              [-75.07302519299992, 10.410796467000068],
              [-75.07292723299992, 10.410860839000065],
              [-75.07286382299992, 10.410892914000044],
              [-75.07278658299992, 10.410920420000082],
              [-75.0727535389999 , 10.410932187000071],
              [-75.07263945299991, 10.410962109000081],
              [-75.07251395799994, 10.411003252000057],
              [-75.07251320599994, 10.411000293000086],
              [-75.07250635199995, 10.410973330000047],
              [-75.07244930899992, 10.410784446000036],
              [-75.07244311999995, 10.410751082000047],
              [-75.07244075699992, 10.410738343000048],
              [-75.07217930799993, 10.410823084000072],
              [-75.07191786099992, 10.41091074600007 ],
              [-75.07173068899993, 10.410963344000038],
              [-75.07171673999994, 10.410894631000076],
              [-75.07168000799993, 10.410912053000061],
              [-75.07136444899993, 10.41102341800007 ],
              [-75.07119109699994, 10.41105155300005 ],
              [-75.07118381999993, 10.411054197000055],
              [-75.07112786699992, 10.411074538000037],
              [-75.07088483099994, 10.41116289100006 ],
              [-75.07051152399993, 10.411279833000037],
              [-75.07047950499992, 10.411289861000057],
              [-75.0701731449999 , 10.411373602000083],
              [-75.07006506499994, 10.411409897000055],
              [-75.06995920799994, 10.411445443000048],
              [-75.07002600999994, 10.41151042100006 ],
              [-75.06990360899994, 10.41155483700004 ],
              [-75.06934743799991, 10.411730162000083],
              [-75.06906697499994, 10.41180496700008 ],
              [-75.06881978799993, 10.41184938200007 ],
              [-75.06860111999993, 10.411863409000091],
              [-75.06810674699994, 10.411879772000077],
              [-75.06797127199991, 10.411884446000045],
              [-75.06781915699992, 10.411898474000054],
              [-75.06761712999992, 10.41191483600005 ],
              [-75.06710611799991, 10.411933539000074],
              [-75.0667923769999 , 10.411956915000076],
              [-75.0667349439999 , 10.411962411000047],
              [-75.06635267299993, 10.411998993000054],
              [-75.06597238599994, 10.412027046000048],
              [-75.06554693699991, 10.412069121000059],
              [-75.0651809119999 , 10.412066785000093],
              [-75.06498839199992, 10.412043408000045],
              [-75.06492561399995, 10.412038854000059],
              [-75.0648883419999 , 10.412040048000051],
              [-75.06484740199994, 10.412040858000069],
              [-75.06476349299993, 10.412042515000053],
              [-75.06451979999991, 10.41204732400007 ],
              [-75.06450226299995, 10.412082318000046],
              [-75.06447892699993, 10.412128886000062],
              [-75.06443983199995, 10.412206895000054],
              [-75.06442662499995, 10.412233253000068],
              [-75.06438437899993, 10.412317562000055],
              [-75.06428283199995, 10.412526366000066],
              [-75.0642537299999 , 10.412605431000088],
              [-75.06420651299993, 10.412733711000044],
              [-75.0641630689999 , 10.412851739000075],
              [-75.06414815699992, 10.412940964000086],
              [-75.0641205149999 , 10.413106355000082],
              [-75.06413535899992, 10.413211195000088],
              [-75.06414487099994, 10.413278382000044],
              [-75.06415219199994, 10.413330078000058],
              [-75.06415279299995, 10.413382915000057],
              [-75.06415648399991, 10.41370719200006 ],
              [-75.06413537499992, 10.413888155000052],
              [-75.06408015799991, 10.41405695800006 ],
              [-75.06405040499993, 10.414100206000057],
              [-75.06400616899992, 10.414164505000088],
              [-75.06391354499993, 10.41425677300009 ],
              [-75.06382699599993, 10.414312229000075],
              [-75.06365373799991, 10.414371022000068],
              [-75.06342165799992, 10.414439194000067],
              [-75.06314304799992, 10.414482981000049],
              [-75.0628799129999 , 10.414523655000039],
              [-75.06256726799995, 10.41457981700006 ],
              [-75.06221746799991, 10.414639152000063],
              [-75.06193881899992, 10.414670672000057],
              [-75.06157542599993, 10.414719597000044],
              [-75.06154253999995, 10.414724024000066],
              [-75.06122672999993, 10.41475872600006 ],
              [-75.06102244499994, 10.414799216000063],
              [-75.06096514899991, 10.414821614000061],
              [-75.06093198499991, 10.414834580000047],
              [-75.06088014199992, 10.41485484900005 ],
              [-75.06078426499994, 10.41489806800007 ],
              [-75.06059892799993, 10.41505501000006 ],
              [-75.06050327299994, 10.415168752000056],
              [-75.06043244499995, 10.415297745000089],
              [-75.06041346099994, 10.415454453000052],
              [-75.06040829299991, 10.415497114000061],
              [-75.06033581899993, 10.415735950000055],
              [-75.06033472299993, 10.415739560000077],
              [-75.06026891399995, 10.415895091000039],
              [-75.06024249299992, 10.415957534000086],
              [-75.06014401999994, 10.416160202000071],
              [-75.06010804899995, 10.416199550000044],
              [-75.05998660599994, 10.416332388000058],
              [-75.05985400499992, 10.416516762000072],
              [-75.05961282899995, 10.41677068000007 ],
              [-75.05947430299994, 10.416916523000054],
              [-75.05924901399993, 10.417174767000063],
              [-75.05905763399994, 10.417380782000066],
              [-75.05880747799995, 10.41761150900004 ],
              [-75.05862307899991, 10.417767257000037],
              [-75.05852328999993, 10.41785154200005 ],
              [-75.05834110299992, 10.418026869000073],
              [-75.05820520899994, 10.418149929000037],
              [-75.05808501199994, 10.418340399000044],
              [-75.05799298499994, 10.418622756000048],
              [-75.05798763899992, 10.418892588000062],
              [-75.05797572499995, 10.419042865000051],
              [-75.0578835579999 , 10.419282302000056],
              [-75.05779773899991, 10.419570776000057],
              [-75.05773035399994, 10.41981013800006 ],
              [-75.05772407799992, 10.419830638000064],
              [-75.05767210699992, 10.420000415000061],
              [-75.05763587599995, 10.420297936000054],
              [-75.0575547119999 , 10.420602278000047],
              [-75.05755322399995, 10.420607861000065],
              [-75.05750811799993, 10.420798009000066],
              [-75.05747680399992, 10.420930033000047],
              [-75.05735455799993, 10.42145471100008 ],
              [-75.05727509199994, 10.421792220000043],
              [-75.05719257399994, 10.42214507400007 ],
              [-75.0571069369999 , 10.422491802000081],
              [-75.0570519019999 , 10.422718861000078],
              [-75.05699043099992, 10.422869289000062],
              [-75.05689281199994, 10.423233979000088],
              [-75.05688940399995, 10.423246726000059],
              [-75.05681894599991, 10.423495293000087],
              [-75.05677929599995, 10.423656637000079],
              [-75.05676389999991, 10.42371928600005 ],
              [-75.05670897699991, 10.42398313600006 ],
              [-75.0566724489999 , 10.424185611000041],
              [-75.05662065999991, 10.424461714000074],
              [-75.05651950999993, 10.42479929600006 ],
              [-75.05646441199991, 10.425007960000073],
              [-75.05635086599995, 10.425342510000064],
              [-75.05628341599993, 10.425563473000068],
              [-75.05620998899991, 10.425851912000041],
              [-75.05614577999995, 10.426118856000073],
              [-75.05609365799995, 10.426287653000088],
              [-75.0560754469999 , 10.42640728300006 ],
              [-75.05605103699992, 10.42652386900005 ],
              [-75.05596491299991, 10.426717298000085],
              [-75.05591987399993, 10.42684164800005 ],
              [-75.05589428099995, 10.426841728000056],
              [-75.0557466919999 , 10.427361407000092],
              [-75.05574494199993, 10.427367570000058],
              [-75.0556804929999 , 10.427610445000084],
              [-75.0555641439999 , 10.428048905000082],
              [-75.0554828729999 , 10.428355174000046],
              [-75.05545006999995, 10.428475984000045],
              [-75.05535553999994, 10.428824131000056],
              [-75.05533882299994, 10.42888570000008 ],
              [-75.05533254499994, 10.42890882000006 ],
              [-75.0552886879999 , 10.42907034600006 ],
              [-75.05505556299994, 10.430005773000062],
              [-75.05500256399995, 10.430218431000071],
              [-75.05485227299994, 10.430836252000063],
              [-75.05472539099992, 10.431314163000081],
              [-75.05469211199994, 10.431439512000054],
              [-75.05451747799992, 10.432125462000045],
              [-75.05447252999994, 10.432309196000062],
              [-75.05445733399995, 10.432371311000054],
              [-75.05441442199992, 10.43254672200004 ],
              [-75.05440696099993, 10.432577221000088],
              [-75.0543990459999 , 10.432609570000068],
              [-75.05439120699992, 10.432641616000069],
              [-75.05438656799993, 10.43266058000006 ],
              [-75.05437398299995, 10.432709731000045],
              [-75.05435478399994, 10.432784713000046],
              [-75.05433945999994, 10.432844569000054],
              [-75.05431987899993, 10.432921041000043],
              [-75.0543010049999 , 10.432994761000089],
              [-75.05429439399995, 10.433020580000061],
              [-75.05428576699995, 10.433057217000055],
              [-75.05427323899994, 10.433110421000038],
              [-75.0542598209999 , 10.433167405000063],
              [-75.05424731099993, 10.433220533000053],
              [-75.0542325369999 , 10.433283275000065],
              [-75.0542210829999 , 10.433331918000079],
              [-75.05420810499993, 10.433387035000067],
              [-75.05419152399992, 10.433457451000038],
              [-75.05417570999992, 10.433524612000042],
              [-75.05416839299994, 10.43355568800007 ],
              [-75.05416520699993, 10.433568767000054],
              [-75.05416301399993, 10.433577770000056],
              [-75.05415700799995, 10.433602424000071],
              [-75.05411289099993, 10.433783507000044],
              [-75.0540379869999 , 10.434090961000038],
              [-75.05400899499995, 10.434209962000068],
              [-75.05395506299993, 10.434431333000077],
              [-75.0538505689999 , 10.434899638000047],
              [-75.05382915699994, 10.434995603000061],
              [-75.05378538899993, 10.435153791000062],
              [-75.05369319899995, 10.435486985000068],
              [-75.0535611279999 , 10.436032873000045],
              [-75.05348958699994, 10.436328574000072],
              [-75.05346350799994, 10.436438500000065],
              [-75.0533441849999 , 10.436941515000058],
              [-75.0531840189999 , 10.437544779000063],
              [-75.05308900799992, 10.437911537000048],
              [-75.05298993399992, 10.438293975000079],
              [-75.05284539299993, 10.438841350000075],
              [-75.05277153999992, 10.439121028000045],
              [-75.0527467519999 , 10.439230559000066],
              [-75.05262622399994, 10.439763131000063],
              [-75.05244682699993, 10.440497705000041],
              [-75.0524310429999 , 10.440557930000068],
              [-75.05229639299995, 10.441071772000043],
              [-75.05226506799994, 10.441193485000042],
              [-75.0521144299999 , 10.441778777000081],
              [-75.0521023149999 , 10.441825832000063],
              [-75.05197129599992, 10.442326921000074],
              [-75.05180145399993, 10.442978826000058],
              [-75.05176514899995, 10.44311712700005 ],
              [-75.05158306999994, 10.443810739000071],
              [-75.05148255599994, 10.444192182000052],
              [-75.05144717799993, 10.444326425000042],
              [-75.05140125799994, 10.444506569000055],
              [-75.05129461799993, 10.444924872000058],
              [-75.05128223699995, 10.44497345600007 ],
              [-75.05126105499994, 10.44505584600006 ],
              [-75.0510396439999 , 10.445917241000075],
              [-75.05085037299995, 10.446637259000056],
              [-75.05063208999991, 10.44750319600007 ],
              [-75.0504864479999 , 10.448043221000091],
              [-75.0503475669999 , 10.448544127000048],
              [-75.05034076399994, 10.44856866200007 ],
              [-75.05014666699992, 10.449317862000044],
              [-75.05008669999995, 10.449560898000072],
              [-75.05008321999992, 10.449575         ],
              [-75.05005260799993, 10.44969906700004 ],
              [-75.0500254339999 , 10.449809200000061],
              [-75.05001627099995, 10.449841629000048],
              [-75.04987971899993, 10.450324920000071],
              [-75.04969447799994, 10.451068110000051],
              [-75.04968570999995, 10.451103285000045],
              [-75.04947690999995, 10.451862249000044],
              [-75.04932641399995, 10.452421730000083],
              [-75.04923810799994, 10.452732720000085],
              [-75.04917583399993, 10.452952046000064],
              [-75.04917309899992, 10.452961083000048],
              [-75.04896681899993, 10.453642957000056],
              [-75.04881142799991, 10.454207316000065],
              [-75.04878845299993, 10.454305729000055],
              [-75.0487175529999 , 10.454609375000075],
              [-75.04868431999995, 10.454751699000042],
              [-75.04866604199992, 10.454829980000056],
              [-75.0485156709999 , 10.455428348000055],
              [-75.04849543199992, 10.455514893000043],
              [-75.04827335499994, 10.45646449800006 ],
              [-75.04826930799993, 10.456480731000056],
              [-75.04821455299992, 10.45670040200008 ],
              [-75.04816385299995, 10.456903811000075],
              [-75.04810844499991, 10.457126104000054],
              [-75.04792273399994, 10.457902594000075],
              [-75.04786410399993, 10.458147735000068],
              [-75.04784666599994, 10.458220643000061],
              [-75.0478181389999 , 10.458326343000067],
              [-75.04763790099992, 10.45899419400007 ],
              [-75.04758759299995, 10.459178680000036],
              [-75.04757686099992, 10.459218045000057],
              [-75.04754360099992, 10.459340014000077],
              [-75.04745597499993, 10.459661363000066],
              [-75.04744405899993, 10.459705064000048],
              [-75.0473951269999 , 10.459884510000052],
              [-75.04712336299991, 10.460930469000061],
              [-75.04700233299991, 10.46135329100008 ],
              [-75.04697815799994, 10.461437747000048],
              [-75.0469294959999 , 10.461607752000077],
              [-75.04692323699993, 10.461629619000064],
              [-75.04691446399994, 10.461660265000091],
              [-75.04681841299993, 10.46202850800006 ],
              [-75.04678212899995, 10.462167617000091],
              [-75.0467681909999 , 10.462221043000056],
              [-75.04663783699993, 10.462720826000066],
              [-75.04656774499995, 10.462986510000064],
              [-75.04637087799995, 10.46373273900008 ],
              [-75.04634042499993, 10.463857721000068],
              [-75.04629404799994, 10.464048053000056],
              [-75.04615751599994, 10.464608387000055],
              [-75.04608413999995, 10.464882784000054],
              [-75.04595847399992, 10.465352736000057],
              [-75.04590331599991, 10.465603098000088],
              [-75.04583077299992, 10.465932359000078],
              [-75.0458277269999 , 10.465946183000085],
              [-75.04593451799991, 10.465976654000087],
              [-75.0458998499999 , 10.466098181000064],
              [-75.0458922489999 , 10.466132835000053],
              [-75.04582610499995, 10.466434395000078],
              [-75.04582497699994, 10.466439535000063],
              [-75.04581414099994, 10.466711315000055],
              [-75.04582636499993, 10.46688473100005 ],
              [-75.04599663499994, 10.467161736000037],
              [-75.0462372579999 , 10.467519475000074],
              [-75.04648361699992, 10.467842501000064],
              [-75.04686443199995, 10.468199806000086],
              [-75.04712212899994, 10.468412946000058],
              [-75.04735657299995, 10.468660846000091],
              [-75.04739219899994, 10.46884575200005 ],
              [-75.04733452599993, 10.469082985000057],
              [-75.04716592999995, 10.469343682000044],
              [-75.04688662399991, 10.46969144700006 ],
              [-75.04656525799993, 10.470131174000073],
              [-75.04640383299994, 10.470352054000045],
              [-75.0459861519999 , 10.470893296000042],
              [-75.04574638899993, 10.47120398800007 ],
              [-75.04517892899992, 10.472071405000065],
              [-75.04485379399995, 10.47249436900006 ],
              [-75.04456754899991, 10.472866741000075],
              [-75.04398825999994, 10.473548597000047],
              [-75.04337492899992, 10.474304731000075],
              [-75.04330376199994, 10.474392468000076],
              [-75.0432681389999 , 10.474443118000067],
              [-75.04314028099992, 10.47462491300007 ],
              [-75.0431063609999 , 10.474673146000043],
              [-75.04281101899994, 10.47509307900009 ],
              [-75.04248576099991, 10.475468965000061],
              [-75.04230350099994, 10.475679591000073],
              [-75.04197322699991, 10.47608960000008 ],
              [-75.04178793799991, 10.476370771000063],
              [-75.04170824099992, 10.476491705000058],
              [-75.04164459799995, 10.476588284000059],
              [-75.04145192699991, 10.476880657000038],
              [-75.04114124099993, 10.477299370000082],
              [-75.0410451919999 , 10.477428816000042],
              [-75.04054188299995, 10.477825083000084],
              [-75.04015352599993, 10.478102104000072],
              [-75.03934818199991, 10.478722812000058],
              [-75.03910352899993, 10.478900273000079],
              [-75.0388543649999 , 10.47908100300009 ],
              [-75.03878715699994, 10.479142934000038],
              [-75.0385333349999 , 10.479376837000075],
              [-75.0383635039999 , 10.479462247000072],
              [-75.0380185969999 , 10.479635706000067],
              [-75.03797200299994, 10.479659137000056],
              [-75.03786597599992, 10.479722692000053],
              [-75.03782368299994, 10.479748045000065],
              [-75.03750321099994, 10.479940140000053],
              [-75.03731011999992, 10.480055880000066],
              [-75.03704645399995, 10.48025642500005 ],
              [-75.03695324599994, 10.480352445000051],
              [-75.0367686379999 , 10.480542619000062],
              [-75.03673354099993, 10.480567867000047],
              [-75.03657519399991, 10.480681785000058],
              [-75.03657488099992, 10.480580433000057],
              [-75.0365746409999 , 10.48050324400009 ],
              [-75.03644300199994, 10.480580253000085],
              [-75.03642777699991, 10.480592081000054],
              [-75.03624658599995, 10.480732849000049],
              [-75.03615241099993, 10.480829379000056],
              [-75.03613952599994, 10.48080976500006 ],
              [-75.03583408399993, 10.481043721000049],
              [-75.0354563869999 , 10.48124948900005 ],
              [-75.03489813599992, 10.481308648000038],
              [-75.03399579699993, 10.481220054000062],
              [-75.03357612499991, 10.481186323000088],
              [-75.03343631299992, 10.481190803000061],
              [-75.03263906899991, 10.481216363000044],
              [-75.03160431899994, 10.48131436500006 ],
              [-75.03143928299994, 10.481320916000072],
              [-75.03089298799995, 10.48134261100006 ],
              [-75.03010667699994, 10.481441346000054],
              [-75.02963127499993, 10.481561168000042],
              [-75.02874365699995, 10.481853458000046],
              [-75.0278763199999 , 10.48218103000005 ],
              [-75.0271803249999 , 10.482406837000042],
              [-75.02657572899994, 10.482480830000043],
              [-75.0261110109999 , 10.48251676600006 ],
              [-75.02557015299993, 10.482606050000072],
              [-75.02543564199993, 10.482637657000055],
              [-75.02528767699994, 10.482735742000045],
              [-75.02521089999993, 10.482832471000052],
              [-75.02516384099994, 10.482929340000055],
              [-75.0250940869999 , 10.483181914000056],
              [-75.02505916799993, 10.483377180000048],
              [-75.02489929899991, 10.483662982000055],
              [-75.02478029499991, 10.483738005000077],
              [-75.02470508099992, 10.48378542000006 ],
              [-75.02434881099992, 10.48407078200006 ],
              [-75.0238890899999 , 10.484413595000092],
              [-75.0234143209999 , 10.484569782000051],
              [-75.02268138499994, 10.48473967800004 ],
              [-75.02256070499993, 10.484786520000057],
              [-75.02282074699991, 10.485001389000047],
              [-75.02307454299995, 10.485177021000084],
              [-75.02329382999994, 10.48532877200006 ],
              [-75.0233796249999 , 10.486142266000058],
              [-75.0233827749999 , 10.486172146000058],
              [-75.0256028309999 , 10.507220566000058],
              [-75.02625186299991, 10.513373269000056],
              [-75.02696701299993, 10.520152416000087],
              [-75.02708116499991, 10.520754281000052],
              [-75.01452507999994, 10.52113014300005 ],
              [-75.01235487999992, 10.52119510700004 ],
              [-75.01197452799994, 10.52120649300008 ],
              [-75.01128968099994, 10.521226994000074],
              [-75.01120746099991, 10.521229455000082],
              [-75.01076402399991, 10.521242728000061],
              [-75.01052211199993, 10.52128450300006 ],
              [-75.01048206299993, 10.521286307000082],
              [-75.01036094499995, 10.521291764000068],
              [-75.0103118909999 , 10.52129397400006 ],
              [-75.0101482199999 , 10.521413072000087],
              [-75.01011300999994, 10.521378435000088],
              [-75.00999794899991, 10.521292074000087],
              [-75.00995825199993, 10.521260002000076],
              [-75.00993278199991, 10.521239422000065],
              [-75.0099131529999 , 10.521223562000046],
              [-75.00988240899994, 10.52120052400005 ],
              [-75.00984956799994, 10.521175915000072],
              [-75.00978596399995, 10.521122289000061],
              [-75.00973734799993, 10.52102975300005 ],
              [-75.00968876899992, 10.520949174000066],
              [-75.00968765099992, 10.520948460000056],
              [-75.0096282319999 , 10.520910487000037],
              [-75.00959926799993, 10.520893232000049],
              [-75.00952835699991, 10.520850987000074],
              [-75.00937117199993, 10.520821553000076],
              [-75.00913395299995, 10.520721064000043],
              [-75.0089975169999 , 10.520744078000064],
              [-75.00899442699995, 10.520728468000073],
              [-75.00895815399991, 10.520701042000042],
              [-75.00889619199995, 10.520667522000053],
              [-75.00889243199993, 10.520665488000077],
              [-75.00881731599992, 10.520624851000036],
              [-75.0088130069999 , 10.520622520000074],
              [-75.0087984839999 , 10.520601163000038],
              [-75.00873210499992, 10.520503546000043],
              [-75.00858695799991, 10.520291774000043],
              [-75.00854097099995, 10.520227928000054],
              [-75.0083616849999 , 10.519979016000093],
              [-75.00834187399994, 10.519951512000091],
              [-75.0083139109999 , 10.51991733500006 ],
              [-75.00826087199994, 10.519852509000089],
              [-75.00823260599992, 10.519817962000047],
              [-75.00821140799991, 10.51979205300006 ],
              [-75.00820504899991, 10.51979629400006 ],
              [-75.00819526399994, 10.519812449000085],
              [-75.00815468899992, 10.519775049000089],
              [-75.0081087339999 , 10.519732690000069],
              [-75.00810201599995, 10.519726497000079],
              [-75.00807858799993, 10.519704903000047],
              [-75.0080518449999 , 10.519680253000047],
              [-75.00804416199992, 10.519673171000079],
              [-75.0080522579999 , 10.519667606000041],
              [-75.0080611969999 , 10.519661462000045],
              [-75.00807104299992, 10.519654695000042],
              [-75.00807926699991, 10.519649042000083],
              [-75.00810205599993, 10.519633379000084],
              [-75.00813772099991, 10.519608866000056],
              [-75.0081875539999 , 10.519574616000057],
              [-75.00837037399992, 10.51944896100008 ],
              [-75.00845385899993, 10.519387377000044],
              [-75.0084647779999 , 10.519379323000067],
              [-75.00852304499995, 10.519336341000042],
              [-75.00860095299993, 10.51927887100004 ],
              [-75.00858693899994, 10.519264730000089],
              [-75.00857767499991, 10.519255729000065],
              [-75.00855638799993, 10.519235046000063],
              [-75.00840498699995, 10.519087944000091],
              [-75.00834107599991, 10.519144707000066],
              [-75.00830671499995, 10.519175225000083],
              [-75.00811564499992, 10.519344926000088],
              [-75.00796267299995, 10.519476698000062],
              [-75.00794094299994, 10.519492429000081],
              [-75.00793494999994, 10.519496767000078],
              [-75.00791870299992, 10.519481007000081],
              [-75.00788628399994, 10.519449559000066],
              [-75.00786207499993, 10.519418747000088],
              [-75.00784995999993, 10.51940559400009 ],
              [-75.0077082439999 , 10.519251731000054],
              [-75.00769551199994, 10.519237908000036],
              [-75.00760659499991, 10.519138530000077],
              [-75.00757415999993, 10.519102278000048],
              [-75.00751943199992, 10.519026136000036],
              [-75.00749801699993, 10.518916681000064],
              [-75.00746232499995, 10.518852435000042],
              [-75.00739094099993, 10.51876915400004 ],
              [-75.0073052809999 , 10.518702529000052],
              [-75.00721248199994, 10.518643042000065],
              [-75.00713781799993, 10.518600377000041],
              [-75.00711254399994, 10.51858593500009 ],
              [-75.00700304799994, 10.518534682000052],
              [-75.00700070999994, 10.518533587000093],
              [-75.00690791099993, 10.518500275000065],
              [-75.00681511199991, 10.518471721000083],
              [-75.0066842409999 , 10.518409855000073],
              [-75.00665441199993, 10.51840046400008 ],
              [-75.0065557499999 , 10.518369404000055],
              [-75.00648436699993, 10.51837416300009 ],
              [-75.00642725999995, 10.518376543000045],
              [-75.0063439779999 , 10.518357507000076],
              [-75.00620596999994, 10.518300400000044],
              [-75.0060869969999 , 10.518271847000051],
              [-75.00593262899991, 10.518244050000078],
              [-75.00584557999991, 10.518229303000055],
              [-75.00584397499995, 10.518236672000057],
              [-75.0057986459999 , 10.518382958000075],
              [-75.00558436799992, 10.518294362000063],
              [-75.0054932349999 , 10.518252758000074],
              [-75.00545669399992, 10.51823607600005 ],
              [-75.00544918599991, 10.518232649000083],
              [-75.00544181299995, 10.51822928300004 ],
              [-75.00538420399994, 10.518207367000059],
              [-75.00535497699991, 10.518198067000071],
              [-75.0052795069999 , 10.518174054000042],
              [-75.00519622599995, 10.518119327000079],
              [-75.00507963299992, 10.518036045000088],
              [-75.00495114199992, 10.517952764000086],
              [-75.00488678499994, 10.517795104000072],
              [-75.0048482009999 , 10.51776404800006 ],
              [-75.00473154499991, 10.51764929300009 ],
              [-75.00471648999991, 10.517629196000087],
              [-75.00471342499992, 10.517625105000093],
              [-75.00466867099993, 10.517565365000053],
              [-75.00452062599993, 10.517424134000066],
              [-75.00440404199992, 10.517331513000045],
              [-75.00427411599992, 10.517265487000088],
              [-75.0040861679999 , 10.517243905000043],
              [-75.00393401899993, 10.517226639000057],
              [-75.00377296699992, 10.517222682000067],
              [-75.00358062399994, 10.517227673000093],
              [-75.00341068999995, 10.51724587700005 ],
              [-75.00319157399991, 10.517273075000048],
              [-75.00301720499994, 10.517304572000057],
              [-75.00286504399992, 10.517282883000064],
              [-75.00284394099992, 10.517280335000066],
              [-75.00268604299993, 10.517261270000063],
              [-75.00258766899992, 10.51727483600007 ],
              [-75.00247596699995, 10.517319433000068],
              [-75.00230610999995, 10.517364198000053],
              [-75.0022331699999 , 10.517397108000068],
              [-75.00211847899993, 10.517448855000055],
              [-75.0018954279999 , 10.517547739000065],
              [-75.0018772329999 , 10.517555805000086],
              [-75.00176097799994, 10.517573852000055],
              [-75.0017296389999 , 10.51757394200007 ],
              [-75.0015909899999 , 10.51757434700005 ],
              [-75.00147728799993, 10.517570927000065],
              [-75.00145677599994, 10.517570310000053],
              [-75.00127332799991, 10.51755756700004 ],
              [-75.00107201199995, 10.517553724000038],
              [-75.00083031799994, 10.51751015900004 ],
              [-75.00067811799994, 10.51747518700006 ],
              [-75.0006036549999 , 10.517492753000056],
              [-75.0006021239999 , 10.517493115000093],
              [-75.00051716599995, 10.517506642000058],
              [-75.00043672299995, 10.517533438000044],
              [-75.00040989899992, 10.51753794700005 ],
              [-75.00030261799992, 10.51756481600006 ],
              [-75.00025789599994, 10.517569374000061],
              [-75.00019520399991, 10.517547421000074],
              [-75.00011         , 10.51747683800005 ],
              [-75.00002600099992, 10.51738842800006 ],
              [-74.99996639199992, 10.517322315000058],
              [-74.99989917199991, 10.517282669000053],
              [-74.99981038199991, 10.517317215000048],
              [-74.9997159749999 , 10.51735403300006 ],
              [-74.9996310329999 , 10.517371987000047],
              [-74.9994339399999 , 10.517284023000059],
              [-74.99914354499992, 10.517138853000063],
              [-74.9990037629999 , 10.517037363000043],
              [-74.99884705099993, 10.516989121000051],
              [-74.99875747699991, 10.516953967000063],
              [-74.99855768599991, 10.516841108000051],
              [-74.99855553799995, 10.51684057600005 ],
              [-74.99853424699995, 10.51691995100009 ],
              [-74.99853422599995, 10.516920008000056],
              [-74.99853359499991, 10.516921765000063],
              [-74.99852826199992, 10.516936612000052],
              [-74.9984873219999 , 10.517050575000042],
              [-74.99848717799995, 10.517050977000054],
              [-74.9983767189999 , 10.517269640000052],
              [-74.9983529989999 , 10.517359767000073],
              [-74.99834017799992, 10.517408482000064],
              [-74.99833954899992, 10.517410317000042],
              [-74.9982241379999 , 10.517746754000086],
              [-74.99808917999991, 10.518140173000063],
              [-74.99806953699994, 10.518197439000062],
              [-74.99792558199994, 10.51861708000007 ],
              [-74.99755883299991, 10.519329798000058],
              [-74.99729305799991, 10.519846286000075],
              [-74.99687121599993, 10.520697762000054],
              [-74.99653608299991, 10.521374080000044],
              [-74.99649157899995, 10.52164377300005 ],
              [-74.99645201699991, 10.521883055000046],
              [-74.99623625599992, 10.523188038000058],
              [-74.99620325099994, 10.52316439100008 ],
              [-74.99578545499992, 10.522865025000044],
              [-74.99530885599995, 10.52251029200005 ],
              [-74.99486468799995, 10.522257212000056],
              [-74.99447072399994, 10.521966604000056],
              [-74.9940558479999 , 10.521660566000037],
              [-74.99370541399992, 10.521402068000043],
              [-74.99279083999994, 10.520711558000073],
              [-74.99257495299992, 10.52046712400005 ],
              [-74.9924490969999 , 10.520324628000083],
              [-74.99237594899995, 10.520156862000078],
              [-74.99233201399994, 10.520056099000044],
              [-74.99095359599994, 10.519518195000046],
              [-74.99046885199994, 10.51961250000005 ],
              [-74.98945225099993, 10.519739309000045],
              [-74.98832553599993, 10.519665153000062],
              [-74.98707451899992, 10.520128850000049],
              [-74.98639135199994, 10.520382067000071],
              [-74.98647432099995, 10.52065624000005 ],
              [-74.9864740559999 , 10.520659180000052],
              [-74.98641494299994, 10.521313795000083],
              [-74.98641464999992, 10.521317035000038],
              [-74.98410443599994, 10.521521882000059],
              [-74.98410439699995, 10.521521910000047],
              [-74.98381389999992, 10.521728172000053],
              [-74.98366945399994, 10.521830733000058],
              [-74.98353115899994, 10.52196697800008 ],
              [-74.98323402499994, 10.522259709000082],
              [-74.98323399899994, 10.522259734000045],
              [-74.98285410599993, 10.52263399700007 ],
              [-74.98284448099992, 10.522643479000067],
              [-74.98284367899993, 10.52264425800007 ],
              [-74.98284365699993, 10.522644278000087],
              [-74.9822663349999 , 10.523203666000086],
              [-74.98226585299994, 10.523204132000046],
              [-74.98226576399992, 10.523204164000049],
              [-74.98168707399992, 10.523415698000065],
              [-74.98113947799993, 10.523523233000049],
              [-74.98042834899991, 10.52350922700009 ],
              [-74.97975524299994, 10.523373950000064],
              [-74.97975519899995, 10.523373996000089],
              [-74.97975457399991, 10.523374639000053],
              [-74.97657128399993, 10.526647815000047],
              [-74.97509091599994, 10.528169952000042],
              [-74.97385031199991, 10.52944554800007 ],
              [-74.97380457199995, 10.52963773600004 ],
              [-74.97377887899995, 10.529745676000061],
              [-74.9733337959999 , 10.531615823000038],
              [-74.97333177099995, 10.531624329000067],
              [-74.97333168199992, 10.531624363000049],
              [-74.97184956699994, 10.532196051000085],
              [-74.97152581299991, 10.532320929000036],
              [-74.97152508599993, 10.532321208000042],
              [-74.97152499799995, 10.532321240000044],
              [-74.97060514299994, 10.532644289000075],
              [-74.96930703299995, 10.53283377300005 ],
              [-74.96869215799995, 10.532823100000087],
              [-74.96854031499993, 10.532820464000054],
              [-74.96822550099995, 10.532706272000041],
              [-74.96795072099991, 10.532766741000046],
              [-74.96733051599995, 10.53284292300009 ],
              [-74.96707029299995, 10.532874886000059],
              [-74.96701012299991, 10.532882277000056],
              [-74.9665325709999 , 10.532853165000063],
              [-74.96619622199995, 10.532817503000047],
              [-74.96592768999994, 10.532766377000087],
              [-74.96566227299991, 10.532736639000063],
              [-74.96532289699991, 10.532725391000042],
              [-74.96515619999991, 10.532742813000084],
              [-74.96496511099991, 10.532762783000067],
              [-74.96490482299993, 10.532769084000051],
              [-74.96485606699991, 10.532770147000065],
              [-74.9647444069999 , 10.532772583000053],
              [-74.96442970499993, 10.532755147000046],
              [-74.96418269499992, 10.532700945000045],
              [-74.96417166899994, 10.532696870000052],
              [-74.96399433199991, 10.53263133300004 ],
              [-74.9637412699999 , 10.532469131000084],
              [-74.96363888099995, 10.532403504000058],
              [-74.96347624499992, 10.53225677200004 ],
              [-74.96337404999991, 10.532122824000055],
              [-74.9633093349999 , 10.53200165600009 ],
              [-74.96324063999992, 10.531873039000061],
              [-74.96321903699993, 10.531867016000092],
              [-74.96320269699993, 10.531868134000092],
              [-74.96266073699991, 10.53190519900005 ],
              [-74.96240870999992, 10.531954236000047],
              [-74.96235859599994, 10.531963985000061],
              [-74.96159703399991, 10.532118659000048],
              [-74.96127101099995, 10.532268585000054],
              [-74.96125421999994, 10.532276306000085],
              [-74.96124109499993, 10.532282342000087],
              [-74.96110002899991, 10.532410204000087],
              [-74.9610999809999 , 10.532410247000087],
              [-74.96075492599994, 10.532723003000058],
              [-74.96052840599992, 10.532928250000055],
              [-74.96024823999994, 10.533182104000048],
              [-74.96020957199994, 10.533217143000059],
              [-74.95976208299993, 10.533595276000085],
              [-74.95973866399993, 10.53361506400006 ],
              [-74.95959504199993, 10.534153952000054],
              [-74.95945152299993, 10.534663830000056],
              [-74.95928940199991, 10.53522158900006 ],
              [-74.95923057699991, 10.53533208500005 ],
              [-74.95914585299994, 10.535491221000086],
              [-74.95913752599995, 10.53550686200009 ],
              [-74.95913682299994, 10.535508183000047],
              [-74.95913451599995, 10.53551251600004 ],
              [-74.95913178199993, 10.535517652000067],
              [-74.95912739699992, 10.535525888000052],
              [-74.95912674599992, 10.53552710300005 ],
              [-74.95910234899992, 10.535579520000056],
              [-74.95909510399991, 10.535595084000079],
              [-74.95909413099992, 10.535597175000078],
              [-74.95909040799995, 10.535605173000079],
              [-74.95908486299993, 10.53561708300009 ],
              [-74.95907738499994, 10.535633147000055],
              [-74.95906221899992, 10.535665725000058],
              [-74.95898881099993, 10.535823419000053],
              [-74.9588806939999 , 10.53596692700006 ],
              [-74.95894340399991, 10.536028532000046],
              [-74.95896493399994, 10.536049683000044],
              [-74.95913833999992, 10.536220023000055],
              [-74.95925307999994, 10.536382117000073],
              [-74.9592685849999 , 10.536404023000046],
              [-74.95929283399994, 10.536438278000048],
              [-74.95940940799994, 10.53660295700007 ],
              [-74.95942856999994, 10.536630023000043],
              [-74.95960067099992, 10.53690266700005 ],
              [-74.95974939199994, 10.53713827200005 ],
              [-74.9598942209999 , 10.537540364000051],
              [-74.95992304999993, 10.53789492900006 ],
              [-74.95991022299995, 10.538273355000058],
              [-74.95985839399992, 10.538701745000083],
              [-74.95985271099994, 10.538792732000047],
              [-74.95983788399991, 10.539030100000048],
              [-74.95982706299992, 10.539203343000054],
              [-74.95980760799995, 10.539408992000062],
              [-74.95979317699994, 10.539561588000083],
              [-74.95970210399992, 10.539807307000046],
              [-74.95966132299992, 10.539863080000089],
              [-74.95957343999993, 10.53998326900006 ],
              [-74.95954030799993, 10.540021911000053],
              [-74.95951974499991, 10.540045894000059],
              [-74.9593222659999 , 10.540276210000059],
              [-74.95925201299991, 10.540373344000045],
              [-74.95915467999993, 10.540507920000039],
              [-74.95909560899992, 10.54058959400004 ],
              [-74.9589981339999 , 10.540793790000066],
              [-74.95895722099993, 10.54087949500007 ],
              [-74.95894406799994, 10.540943835000064],
              [-74.95890538899994, 10.541133035000087],
              [-74.95889130299992, 10.541201940000064],
              [-74.95890997799995, 10.541446500000063],
              [-74.95891742299995, 10.541543994000051],
              [-74.95901968499993, 10.541890584000043],
              [-74.95905792499991, 10.541966230000071],
              [-74.9591361869999 , 10.542121045000044],
              [-74.95916724499995, 10.54218248400008 ],
              [-74.9591878459999 , 10.54222323600004 ],
              [-74.95925166099994, 10.542314729000054],
              [-74.95925379899995, 10.542317794000041],
              [-74.95925593699991, 10.542320860000075],
              [-74.95928292899993, 10.542359556000065],
              [-74.9592893809999 , 10.54236880700006 ],
              [-74.95935313099994, 10.542460202000086],
              [-74.95939234399992, 10.542516419000037],
              [-74.95939261399991, 10.542516807000084],
              [-74.95940782799994, 10.542538618000037],
              [-74.95941395799991, 10.54254740500005 ],
              [-74.95941769299992, 10.542552760000092],
              [-74.9594391519999 , 10.542583523000076],
              [-74.95947002799994, 10.542627788000061],
              [-74.95979054299994, 10.543005263000055],
              [-74.95979543999994, 10.54301177800005 ],
              [-74.95981115799992, 10.543032699000037],
              [-74.95984874399994, 10.54308272600008 ],
              [-74.95985458899992, 10.543090505000066],
              [-74.95985460799994, 10.543090531000075],
              [-74.95987017199991, 10.54311124600008 ],
              [-74.95987441499994, 10.543116893000047],
              [-74.95987864999995, 10.543122529000073],
              [-74.9598849109999 , 10.543130862000055],
              [-74.95992049299991, 10.543178221000062],
              [-74.96003530899992, 10.543331038000076],
              [-74.9600803159999 , 10.543390940000052],
              [-74.96010183699991, 10.543470789000082],
              [-74.96011903999994, 10.54353461900007 ],
              [-74.96012681099995, 10.543563454000036],
              [-74.96014631499992, 10.543635822000056],
              [-74.96014750799992, 10.543640246000052],
              [-74.96016406199993, 10.54370166600006 ],
              [-74.96016583799991, 10.543708256000059],
              [-74.96017538299992, 10.543743673000051],
              [-74.96017581599995, 10.543745279000063],
              [-74.96017884199995, 10.543756510000037],
              [-74.96015416199992, 10.543750183000043],
              [-74.96007089499994, 10.54372883600007 ],
              [-74.96003261599992, 10.543719022000062],
              [-74.9598338909999 , 10.543668075000085],
              [-74.95982338699991, 10.543665382000086],
              [-74.9597434289999 , 10.54364488400006 ],
              [-74.95972239999992, 10.543639493000057],
              [-74.95964093999993, 10.543618609000077],
              [-74.9595867239999 , 10.543604709000078],
              [-74.9595604129999 , 10.543597964000071],
              [-74.95948000399994, 10.543577349000088],
              [-74.95945390799994, 10.543570659000068],
              [-74.95941878299993, 10.54356165300004 ],
              [-74.95938248599992, 10.543552347000059],
              [-74.95935983599992, 10.54354654000008 ],
              [-74.95931940499992, 10.543536175000042],
              [-74.95931172699994, 10.54353420700005 ],
              [-74.95925732499995, 10.543520261000083],
              [-74.95922210899994, 10.54351123300006 ],
              [-74.95916502199992, 10.543496597000058],
              [-74.95913585199992, 10.543489117000092],
              [-74.9591222119999 , 10.54348562000007 ],
              [-74.95902122199993, 10.543459729000062],
              [-74.95896893299994, 10.543446323000069],
              [-74.95891622099992, 10.54343281100006 ],
              [-74.95884573599994, 10.543414743000085],
              [-74.9588213529999 , 10.543408492000083],
              [-74.95882015699993, 10.54340818500009 ],
              [-74.95881721699993, 10.543407431000048],
              [-74.95879609199994, 10.543402016000073],
              [-74.95872851899992, 10.543384694000054],
              [-74.95870640999993, 10.543379026000082],
              [-74.95869799299993, 10.543376868000053],
              [-74.95860894499992, 10.543354041000043],
              [-74.95856061799992, 10.543341652000038],
              [-74.95855530999995, 10.54334029100005 ],
              [-74.95851856399992, 10.54333087000009 ],
              [-74.95841536599994, 10.54330441400009 ],
              [-74.95829548599994, 10.543273680000084],
              [-74.95829216099992, 10.543272828000056],
              [-74.95827301399993, 10.543267919000073],
              [-74.95823039599992, 10.543256994000046],
              [-74.95814098799991, 10.543234073000065],
              [-74.95810955199994, 10.543226013000037],
              [-74.95805801699993, 10.543212801000038],
              [-74.95800744299993, 10.543199835000053],
              [-74.95798780599995, 10.543194801000084],
              [-74.95785241599992, 10.543160095000076],
              [-74.95776456199991, 10.543137573000081],
              [-74.9577432019999 , 10.543132097000068],
              [-74.95772990699993, 10.543128688000081],
              [-74.95768712099994, 10.543117719000065],
              [-74.95767487299992, 10.543114579000076],
              [-74.95764605099993, 10.543107190000057],
              [-74.95757567999993, 10.543089149000082],
              [-74.95754337699992, 10.543080867000072],
              [-74.9574906169999 , 10.54306734100004 ],
              [-74.95742960399991, 10.543051699000046],
              [-74.95730962899995, 10.543020941000066],
              [-74.95727925199992, 10.54301315300006 ],
              [-74.95718370399993, 10.54298865700008 ],
              [-74.95715405999994, 10.542981057000077],
              [-74.95714906199993, 10.542979776000038],
              [-74.95707113199995, 10.542959796000048],
              [-74.95704075799995, 10.54295200900009 ],
              [-74.9570338609999 , 10.542950241000085],
              [-74.9569477199999 , 10.542928157000063],
              [-74.95682814399993, 10.542897502000073],
              [-74.9568098979999 , 10.542892824000091],
              [-74.95673890499995, 10.542874623000046],
              [-74.9567161029999 , 10.54286877700008 ],
              [-74.95670127099993, 10.542864975000043],
              [-74.95668641099991, 10.542861164000044],
              [-74.95667587299994, 10.542858462000083],
              [-74.95659026899995, 10.54283651600008 ],
              [-74.95653971899992, 10.542823556000087],
              [-74.95651839199991, 10.542818087000057],
              [-74.9564778909999 , 10.54280770400004 ],
              [-74.95641085999995, 10.54279052000004 ],
              [-74.9563566089999 , 10.542776611000079],
              [-74.95624827799992, 10.54274883800008 ],
              [-74.95614423599994, 10.542722164000054],
              [-74.9561124299999 , 10.542714010000054],
              [-74.9560554979999 , 10.542699414000083],
              [-74.95601816499993, 10.542689843000062],
              [-74.95599527699994, 10.542683975000045],
              [-74.9559439329999 , 10.54267081200004 ],
              [-74.95589945099994, 10.542659407000087],
              [-74.95588997899995, 10.542656979000071],
              [-74.95584490599992, 10.542645423000067],
              [-74.95578479599993, 10.542630012000075],
              [-74.95576628999993, 10.542625268000052],
              [-74.9556694449999 , 10.542600439000068],
              [-74.95566046999994, 10.542598138000074],
              [-74.95562016699995, 10.54258780500004 ],
              [-74.95551238299993, 10.542560172000037],
              [-74.95539717299994, 10.542530635000048],
              [-74.95535424699995, 10.542519630000072],
              [-74.95527224099993, 10.542498605000048],
              [-74.95525552999993, 10.54249432100005 ],
              [-74.95524897099995, 10.542492639000045],
              [-74.95523108999993, 10.542488055000092],
              [-74.95516183099994, 10.542470298000069],
              [-74.9551589319999 , 10.542469554000093],
              [-74.95498610099992, 10.54242524500006 ],
              [-74.9549756529999 , 10.542422566000084],
              [-74.95496981799994, 10.542421070000046],
              [-74.95496113699994, 10.542418844000053],
              [-74.9548914319999 , 10.542400973000042],
              [-74.95473357599991, 10.542360502000065],
              [-74.95470499099991, 10.542353174000084],
              [-74.95463750999994, 10.54233587400006 ],
              [-74.95461483399993, 10.54233006000004 ],
              [-74.95459968099993, 10.542326175000085],
              [-74.95454177999994, 10.542311331000064],
              [-74.95454090999993, 10.542311107000046],
              [-74.95452715599993, 10.542307581000046],
              [-74.95447524499991, 10.54229427200005 ],
              [-74.95437144899995, 10.54226766100004 ],
              [-74.95436707399995, 10.542266539000082],
              [-74.9543538129999 , 10.542263139000056],
              [-74.9542770779999 , 10.542243466000059],
              [-74.95421910499994, 10.54222860300007 ],
              [-74.95419611299991, 10.542222708000054],
              [-74.95409293699993, 10.542196255000079],
              [-74.95400474599995, 10.542173644000059],
              [-74.95396438999995, 10.542163297000059],
              [-74.95391975199993, 10.542151853000064],
              [-74.95383012299993, 10.54212887400007 ],
              [-74.9537662379999 , 10.542112495000083],
              [-74.95375733799995, 10.542110213000058],
              [-74.95369512499991, 10.542094263000081],
              [-74.9536014169999 , 10.542070238000065],
              [-74.95359721299991, 10.54206916000004 ],
              [-74.95356123099992, 10.542059935000054],
              [-74.95354581799995, 10.542055983000068],
              [-74.95339955599991, 10.54201848300005 ],
              [-74.95336546599992, 10.542009743000051],
              [-74.95334608299993, 10.542004774000077],
              [-74.95332727199991, 10.541999951000037],
              [-74.95320467099992, 10.54196851800009 ],
              [-74.95312725799994, 10.541948670000068],
              [-74.9530887759999 , 10.54193880400004 ],
              [-74.95308703299992, 10.541938357000049],
              [-74.95297010299993, 10.541908378000073],
              [-74.95289581399993, 10.54188933100005 ],
              [-74.9528697639999 , 10.541882652000083],
              [-74.95285967599995, 10.541880065000043],
              [-74.95282705999995, 10.541871703000083],
              [-74.95272804199993, 10.54184631600009 ],
              [-74.95271262799992, 10.541842364000047],
              [-74.95268578299994, 10.541835481000078],
              [-74.95259969099993, 10.541813408000053],
              [-74.95258391099992, 10.541809362000038],
              [-74.95243431099993, 10.54177100700008 ],
              [-74.95237522499991, 10.541755858000045],
              [-74.95228156699994, 10.541731845000072],
              [-74.95227190299994, 10.541729367000073],
              [-74.9522649619999 , 10.541727587000082],
              [-74.95212361199992, 10.54169134700004 ],
              [-74.95206174599991, 10.541675485000042],
              [-74.95201313199993, 10.54166302100009 ],
              [-74.95200921999992, 10.541662018000068],
              [-74.95195782199994, 10.54164884000005 ],
              [-74.95167667099992, 10.541576755000051],
              [-74.95159514199992, 10.541555852000045],
              [-74.95157333499992, 10.541550261000054],
              [-74.95157323399991, 10.541550235000045],
              [-74.95155159599994, 10.541544687000055],
              [-74.9515472139999 , 10.541543564000051],
              [-74.95146161299994, 10.541521616000068],
              [-74.95136049399991, 10.54149569000009 ],
              [-74.95122580399993, 10.541461156000082],
              [-74.95118120099994, 10.54144972000006 ],
              [-74.95115592199994, 10.541443238000056],
              [-74.9509550269999 , 10.541391729000054],
              [-74.9509524209999 , 10.54139106100007 ],
              [-74.95085669299993, 10.541366517000085],
              [-74.95078868599995, 10.541349081000078],
              [-74.95076432899992, 10.54134283500008 ],
              [-74.95075626199991, 10.541340767000065],
              [-74.95068722699995, 10.541323067000064],
              [-74.95057465999992, 10.541294205000042],
              [-74.95042805799994, 10.541256617000045],
              [-74.95036987499992, 10.541241699000068],
              [-74.95030015999993, 10.541223825000088],
              [-74.9502894439999 , 10.541221077000046],
              [-74.95028337999992, 10.541219523000052],
              [-74.95021506899991, 10.541202008000084],
              [-74.95021183799992, 10.541201180000087],
              [-74.9501182109999 , 10.54117717400004 ],
              [-74.95009367099993, 10.541170882000074],
              [-74.94985385399991, 10.541109393000056],
              [-74.9498002659999 , 10.54109565300007 ],
              [-74.94976793699993, 10.541087364000077],
              [-74.94974179299993, 10.54108066100008 ],
              [-74.94973505199994, 10.541078933000051],
              [-74.94972730399991, 10.54107694600009 ],
              [-74.94972304399994, 10.541075854000042],
              [-74.94971803999994, 10.541074571000081],
              [-74.9496958499999 , 10.541068882000047],
              [-74.9496500809999 , 10.54105714700006 ],
              [-74.9495792479999 , 10.541038985000057],
              [-74.9494745849999 , 10.54101214900004 ],
              [-74.94946036099992, 10.541008502000068],
              [-74.94936995099994, 10.540985320000061],
              [-74.94933367099992, 10.540976018000038],
              [-74.94932819699994, 10.540974615000039],
              [-74.94932674599994, 10.54097424300005 ],
              [-74.94919782099993, 10.540941186000055],
              [-74.94919177699995, 10.540939636000076],
              [-74.94913857499995, 10.540925995000066],
              [-74.94910598099995, 10.540917638000053],
              [-74.9490886239999 , 10.540913188000047],
              [-74.9490735469999 , 10.540909322000061],
              [-74.94905027699991, 10.540903356000058],
              [-74.94901574499994, 10.540894503000061],
              [-74.9490081209999 , 10.540892548000045],
              [-74.94890732499994, 10.540866703000063],
              [-74.94889716599994, 10.540864099000089],
              [-74.94885453599994, 10.54085316800007 ],
              [-74.94879801499991, 10.540838676000078],
              [-74.94874797599994, 10.540825846000075],
              [-74.94874762799992, 10.54082575700005 ],
              [-74.94863677899991, 10.540797335000093],
              [-74.948545        , 10.540773803000093],
              [-74.94852550799993, 10.540768805000084],
              [-74.94850083999995, 10.54076247900008 ],
              [-74.94843401499992, 10.540745345000062],
              [-74.94835250699992, 10.54072444600007 ],
              [-74.94829415799995, 10.540709485000093],
              [-74.94812779499995, 10.540666829000088],
              [-74.9479948039999 , 10.540632730000084],
              [-74.94797589399991, 10.540627881000091],
              [-74.94794997399993, 10.54062123500006 ],
              [-74.94792670999993, 10.540615270000046],
              [-74.94788414499993, 10.540604356000074],
              [-74.94786027499993, 10.54059823600005 ],
              [-74.94785323899993, 10.540596432000086],
              [-74.94782902299994, 10.540590223000038],
              [-74.94772366599994, 10.540563208000037],
              [-74.94772168199995, 10.540562699000077],
              [-74.9475656649999 , 10.54052269500005 ],
              [-74.9474662799999 , 10.540497212000048],
              [-74.94743001299992, 10.54048791300005 ],
              [-74.94740429299992, 10.540481318000047],
              [-74.94728624399994, 10.540451049000069],
              [-74.94726621699994, 10.540445914000088],
              [-74.94726105399991, 10.54044459000005 ],
              [-74.94720404399993, 10.540429973000073],
              [-74.94719985899991, 10.540428900000052],
              [-74.9469754779999 , 10.540371367000091],
              [-74.94689720999992, 10.540351298000076],
              [-74.94688673099995, 10.54034861100007 ],
              [-74.9468836389999 , 10.540347818000043],
              [-74.94681884599993, 10.540331205000086],
              [-74.94679620699992, 10.54032539900004 ],
              [-74.94675149699992, 10.540313935000086],
              [-74.94674636799994, 10.540312620000066],
              [-74.9466792849999 , 10.540295419000074],
              [-74.9466515979999 , 10.540288321000048],
              [-74.94662238399991, 10.540280830000086],
              [-74.94652025999994, 10.540254645000061],
              [-74.94640313199994, 10.540224612000088],
              [-74.94637920799994, 10.540218477000053],
              [-74.94626068499991, 10.540188086000057],
              [-74.9462493549999 , 10.540185181000084],
              [-74.94615497199993, 10.540160980000053],
              [-74.94613657999992, 10.540156264000075],
              [-74.94612825799993, 10.540154130000076],
              [-74.94599779999993, 10.54012067900004 ],
              [-74.94588528499992, 10.540091829000062],
              [-74.94579121299995, 10.540067708000038],
              [-74.9457718619999 , 10.540062746000046],
              [-74.94576725099995, 10.540061564000041],
              [-74.9457162519999 , 10.540048488000082],
              [-74.94570144799991, 10.540044692000038],
              [-74.9456557609999 , 10.540032977000067],
              [-74.94560952299992, 10.540021121000052],
              [-74.94555740599992, 10.540007758000058],
              [-74.94554561799993, 10.540004735000082],
              [-74.94535827099992, 10.539956693000079],
              [-74.94533036799993, 10.539949538000087],
              [-74.9452006869999 , 10.539916284000071],
              [-74.94509118799994, 10.539888203000089],
              [-74.94506344699994, 10.53988109100004 ],
              [-74.94501312099993, 10.539868187000081],
              [-74.94493740099995, 10.539848772000084],
              [-74.94488497899994, 10.539835330000074],
              [-74.94486703699994, 10.539830730000062],
              [-74.94463846399992, 10.539772122000045],
              [-74.94456227599994, 10.539752585000087],
              [-74.94454325999993, 10.53974770800005 ],
              [-74.94453462299992, 10.539745494000044],
              [-74.94452136899992, 10.539742095000065],
              [-74.94450417099995, 10.539737684000045],
              [-74.94448591699995, 10.539733003000038],
              [-74.94447765599995, 10.53973088500004 ],
              [-74.94447418199991, 10.539729994000083],
              [-74.94416067399993, 10.539649610000083],
              [-74.94414645599994, 10.539645964000044],
              [-74.94405976599995, 10.539623736000067],
              [-74.94401134299994, 10.539611319000073],
              [-74.9439954739999 , 10.539607250000074],
              [-74.94391641899995, 10.539586980000081],
              [-74.94389407999995, 10.539581252000062],
              [-74.9438122009999 , 10.539560256000073],
              [-74.94380665699993, 10.539558834000047],
              [-74.9437769029999 , 10.539551204000077],
              [-74.94354608299994, 10.539492014000075],
              [-74.94353071799992, 10.539488074000076],
              [-74.9435272259999 , 10.539487181000084],
              [-74.94349043099993, 10.539477748000081],
              [-74.94348729799992, 10.539476944000057],
              [-74.94347334999992, 10.539473367000085],
              [-74.94341783599992, 10.539459132000047],
              [-74.9433822549999 , 10.539450008000074],
              [-74.94337831799993, 10.539448999000058],
              [-74.9432865249999 , 10.539425461000064],
              [-74.94326337599995, 10.539419524000039],
              [-74.94324095999991, 10.53941377600006 ],
              [-74.94312543599995, 10.539384153000071],
              [-74.94302599299994, 10.539358653000079],
              [-74.94296484199992, 10.539342972000043],
              [-74.94290781899991, 10.539328349000073],
              [-74.94288976099995, 10.539323717000059],
              [-74.94276733799995, 10.539292322000051],
              [-74.94270767299992, 10.539277021000089],
              [-74.94265646499991, 10.53926388800005 ],
              [-74.94264307499992, 10.539260454000043],
              [-74.94258629099994, 10.539245897000058],
              [-74.94254730199992, 10.539235897000083],
              [-74.94254344599995, 10.539234908000083],
              [-74.94246415399994, 10.539214577000052],
              [-74.9424068109999 , 10.53919987100005 ],
              [-74.94237169299993, 10.539190868000048],
              [-74.9423203209999 , 10.53917769800006 ],
              [-74.9421929639999 , 10.53914504100004 ],
              [-74.94214718499995, 10.539133303000085],
              [-74.9420007029999 , 10.53909574000005 ],
              [-74.94191779399995, 10.539074479000078],
              [-74.9418894499999 , 10.539067211000088],
              [-74.94180477899994, 10.539045498000064],
              [-74.94178951099991, 10.539041583000085],
              [-74.94157632399993, 10.538986916000056],
              [-74.9415259509999 , 10.538973999000063],
              [-74.94151561099994, 10.538971347000086],
              [-74.9415079979999 , 10.538969395000038],
              [-74.94145332599993, 10.538955376000047],
              [-74.94142887099991, 10.538949105000086],
              [-74.94122331799991, 10.538896397000087],
              [-74.94121055999994, 10.538893125000072],
              [-74.94117748899993, 10.538884644000063],
              [-74.94102136799995, 10.53884460900008 ],
              [-74.94091294499992, 10.538816808000092],
              [-74.94090238099994, 10.538814099000092],
              [-74.9408699949999 , 10.53880579400004 ],
              [-74.9408385349999 , 10.538797727000087],
              [-74.94079252199992, 10.538785928000038],
              [-74.9406446239999 , 10.53874800200009 ],
              [-74.94056833899992, 10.538728439000067],
              [-74.9404462999999 , 10.538697149000086],
              [-74.94040470899995, 10.538686484000038],
              [-74.94035203099992, 10.538672976000043],
              [-74.94030034199994, 10.538659721000045],
              [-74.94026788899993, 10.53865139900006 ],
              [-74.94024530399992, 10.538645608000081],
              [-74.94019183199993, 10.538631896000084],
              [-74.94010729399992, 10.538610217000041],
              [-74.9400183599999 , 10.538587411000037],
              [-74.93996436099991, 10.538573564000046],
              [-74.93977663499993, 10.538525425000046],
              [-74.93977197999993, 10.538524231000054],
              [-74.93974322499992, 10.538516857000047],
              [-74.9396976299999 , 10.53850516500006 ],
              [-74.93967176099994, 10.538498531000073],
              [-74.93951468199992, 10.538458250000076],
              [-74.93948731699993, 10.538451233000046],
              [-74.9394082099999 , 10.538430947000052],
              [-74.93928446399991, 10.538399214000037],
              [-74.9392200449999 , 10.538382695000053],
              [-74.9391147039999 , 10.538355682000088],
              [-74.93909342699993, 10.538350226000091],
              [-74.93906841899991, 10.53834381300004 ],
              [-74.9390295259999 , 10.538333839000074],
              [-74.93900003099992, 10.538326275000088],
              [-74.93887659899991, 10.53829462200008 ],
              [-74.93879906599994, 10.538274740000077],
              [-74.93879630899994, 10.53827403300005 ],
              [-74.9387447869999 , 10.538260821000051],
              [-74.93874257899995, 10.538260255000068],
              [-74.93871728499994, 10.538253768000061],
              [-74.9386666019999 , 10.538240771000062],
              [-74.9386457569999 , 10.538235426000085],
              [-74.93855150699994, 10.538211256000068],
              [-74.93848210899995, 10.53819345900007 ],
              [-74.93843644099991, 10.538181748000056],
              [-74.93837098699993, 10.538164963000042],
              [-74.93823463699994, 10.538129998000045],
              [-74.93815278099993, 10.53810900700006 ],
              [-74.93811882199992, 10.538100298000074],
              [-74.93811856799994, 10.53810023300008 ],
              [-74.93807686799994, 10.538089539000055],
              [-74.93795347399993, 10.538057896000055],
              [-74.9379500579999 , 10.538057019000064],
              [-74.9378499959999 , 10.538031359000058],
              [-74.93782261299992, 10.538024337000081],
              [-74.93776445999993, 10.538009424000052],
              [-74.93769767099991, 10.537992297000073],
              [-74.93761362999993, 10.537970745000052],
              [-74.93758186499991, 10.537962599000082],
              [-74.93757136099993, 10.537959905000037],
              [-74.93748436699991, 10.537937596000063],
              [-74.93743296499991, 10.537924415000077],
              [-74.9374202269999 , 10.537921148000066],
              [-74.93730652099993, 10.537891989000059],
              [-74.93715562499995, 10.537853293000069],
              [-74.93714694899995, 10.537851068000066],
              [-74.93709066399992, 10.537836634000087],
              [-74.9370436079999 , 10.537824567000087],
              [-74.93695532299995, 10.537801926000043],
              [-74.93695121799993, 10.537800873000037],
              [-74.93692679999992, 10.537794611000038],
              [-74.93688499699994, 10.53778389100006 ],
              [-74.93685195199993, 10.537775417000091],
              [-74.9368131199999 , 10.53776545900007 ],
              [-74.9366309959999 , 10.537718754000082],
              [-74.93659256799992, 10.537708899000052],
              [-74.9365884849999 , 10.53770785200004 ],
              [-74.93646564999995, 10.537676351000073],
              [-74.93644243499995, 10.537670398000046],
              [-74.9364370529999 , 10.537669018000088],
              [-74.93643507599995, 10.53766851000006 ],
              [-74.93633111399993, 10.537641850000057],
              [-74.9362747429999 , 10.537627394000083],
              [-74.93610056099993, 10.53758272500005 ],
              [-74.9360066669999 , 10.537558646000036],
              [-74.93598218699992, 10.537552368000092],
              [-74.93597004599991, 10.537549254000055],
              [-74.93582710399994, 10.537512596000056],
              [-74.93578276699992, 10.537501226000074],
              [-74.93577975399995, 10.537500453000064],
              [-74.9356492199999 , 10.537466979000044],
              [-74.93563852499994, 10.537464236000062],
              [-74.9355944539999 , 10.537452934000044],
              [-74.9355474329999 , 10.537440875000073],
              [-74.93545693499993, 10.537417667000057],
              [-74.93535827499994, 10.537392365000073],
              [-74.93528765799994, 10.537374255000088],
              [-74.93526751299993, 10.537369089000038],
              [-74.93520150799992, 10.537352162000047],
              [-74.93519325599993, 10.537350046000086],
              [-74.93507051099994, 10.537318568000046],
              [-74.93501675199991, 10.537304781000046],
              [-74.9350046639999 , 10.537301681000088],
              [-74.93495959199993, 10.537290122000059],
              [-74.93495555099992, 10.537289086000044],
              [-74.93493191899995, 10.537283025000079],
              [-74.93482148499993, 10.537254704000077],
              [-74.93472259399994, 10.537229343000092],
              [-74.93466585899995, 10.53721479300009 ],
              [-74.93462977299993, 10.53720553800008 ],
              [-74.93454198699993, 10.537183025000047],
              [-74.9345369479999 , 10.537181733000068],
              [-74.93452167099991, 10.537177815000064],
              [-74.93442431999995, 10.537152848000062],
              [-74.93441047699991, 10.537149298000088],
              [-74.93436996999992, 10.537138910000067],
              [-74.93432787299992, 10.53712811400004 ],
              [-74.93428776999991, 10.537117830000057],
              [-74.9342690769999 , 10.53711303600005 ],
              [-74.9341106519999 , 10.53707240700004 ],
              [-74.93409878699993, 10.537069364000047],
              [-74.93407128299992, 10.537062310000067],
              [-74.9340498009999 , 10.537056801000062],
              [-74.93386986699994, 10.537010643000087],
              [-74.93383144599994, 10.537000790000093],
              [-74.93381196699994, 10.536995796000042],
              [-74.9337976839999 , 10.53699213300007 ],
              [-74.9337292109999 , 10.536974574000055],
              [-74.93372540299993, 10.536973597000042],
              [-74.93372185999993, 10.536972688000048],
              [-74.93371363199992, 10.53697057800008 ],
              [-74.93371171199993, 10.536970086000053],
              [-74.93360814899995, 10.536943528000052],
              [-74.93351976799994, 10.536920863000091],
              [-74.9335009319999 , 10.536916033000068],
              [-74.93349510999991, 10.536914540000055],
              [-74.93343838899995, 10.536899994000066],
              [-74.93342230399992, 10.536895869000091],
              [-74.93340113399995, 10.536890440000093],
              [-74.93336860699992, 10.536882099000081],
              [-74.93335810899993, 10.536879407000072],
              [-74.93335651399991, 10.536878998000077],
              [-74.93335108199994, 10.536877605000086],
              [-74.93334372099991, 10.536875717000044],
              [-74.93331558999995, 10.53686850300005 ],
              [-74.93329932499995, 10.53686433100006 ],
              [-74.93324565099994, 10.53685056800009 ],
              [-74.9332116299999 , 10.536841843000047],
              [-74.93320240699995, 10.536839478000047],
              [-74.93318799699995, 10.536835783000072],
              [-74.93315466899992, 10.536827236000079],
              [-74.93311685799995, 10.536817540000072],
              [-74.93308307299992, 10.536808875000077],
              [-74.93308113799992, 10.536808379000092],
              [-74.93307358099992, 10.536806441000067],
              [-74.9330535329999 , 10.536801299000047],
              [-74.93300724599993, 10.536789428000077],
              [-74.93298989399995, 10.536784976000092],
              [-74.93292448699992, 10.536768201000086],
              [-74.93291923099991, 10.536766852000085],
              [-74.93288521999995, 10.536758131000056],
              [-74.93288233099992, 10.536757390000048],
              [-74.93265673699995, 10.536699534000093],
              [-74.93260537799995, 10.536686362000069],
              [-74.93259649999993, 10.536684088000072],
              [-74.9325889509999 , 10.536682153000072],
              [-74.93257437199992, 10.53667841400005 ],
              [-74.93256154199992, 10.536675124000055],
              [-74.93252627599992, 10.536666079000042],
              [-74.93250841399993, 10.536661498000058],
              [-74.93244381199992, 10.53664493000008 ],
              [-74.93242982299995, 10.536641342000053],
              [-74.93234757099992, 10.536620248000077],
              [-74.93227779799992, 10.53660235500007 ],
              [-74.93225395999991, 10.53659624100004 ],
              [-74.9322153409999 , 10.536586337000074],
              [-74.93218396299994, 10.53657829000008 ],
              [-74.93212186099993, 10.536562363000087],
              [-74.93209389299994, 10.536555190000058],
              [-74.93207971599992, 10.536551554000084],
              [-74.9320654039999 , 10.536547884000072],
              [-74.93190053299992, 10.536505600000055],
              [-74.93187537799992, 10.536499149000065],
              [-74.93187142799991, 10.536498136000091],
              [-74.93184959799993, 10.536492537000072],
              [-74.93180452199994, 10.536480976000064],
              [-74.9317973279999 , 10.536479130000089],
              [-74.93175956699992, 10.536469447000059],
              [-74.93174840699993, 10.536466585000085],
              [-74.93157574899993, 10.536422303000052],
              [-74.93156964899993, 10.53642073900005 ],
              [-74.9315390189999 , 10.536412883000082],
              [-74.9314636279999 , 10.536393547000046],
              [-74.93136963199993, 10.536369440000044],
              [-74.93123241699993, 10.53633424800006 ],
              [-74.93119813099992, 10.536325454000064],
              [-74.9311663599999 , 10.536317306000058],
              [-74.93112156899991, 10.536305818000073],
              [-74.93110018399994, 10.536300335000078],
              [-74.93095932099993, 10.536264206000055],
              [-74.93090144699994, 10.536249362000092],
              [-74.93085741099992, 10.536238069000092],
              [-74.93083725499991, 10.536232898000037],
              [-74.93079352699993, 10.536221683000065],
              [-74.93076156099994, 10.536213485000076],
              [-74.93075126299993, 10.536210843000049],
              [-74.93059799399992, 10.536171534000061],
              [-74.93050250099992, 10.536147043000085],
              [-74.9304693279999 , 10.536138536000067],
              [-74.93045316099995, 10.53613438900004 ],
              [-74.93037698099994, 10.536114853000072],
              [-74.93037370399992, 10.536114010000063],
              [-74.9302767449999 , 10.536089143000083],
              [-74.93022914999995, 10.536076938000065],
              [-74.93018612899994, 10.536065904000054],
              [-74.93013791599992, 10.53605353900008 ],
              [-74.93012339299992, 10.53604981400008 ],
              [-74.9301186589999 , 10.536048600000072],
              [-74.92999066199991, 10.536015775000067],
              [-74.92993671599993, 10.536001938000084],
              [-74.92987775199992, 10.535986816000047],
              [-74.92980630599993, 10.535968492000052],
              [-74.92980227299995, 10.535967458000073],
              [-74.92976656499991, 10.535958300000061],
              [-74.9297544989999 , 10.53595520500005 ],
              [-74.92968803799994, 10.535938158000079],
              [-74.92968715699993, 10.535937931000092],
              [-74.92967819499995, 10.535935633000065],
              [-74.92964664999994, 10.535927542000081],
              [-74.92962920399992, 10.535923068000045],
              [-74.92956612699993, 10.535906890000092],
              [-74.92955457799991, 10.535903928000039],
              [-74.9295254399999 , 10.535896455000056],
              [-74.9294888199999 , 10.535887063000075],
              [-74.92945010799991, 10.535877134000089],
              [-74.9294341879999 , 10.535873052000056],
              [-74.92942884299993, 10.53587168000007 ],
              [-74.92942312699995, 10.535870215000045],
              [-74.92933619299993, 10.535847918000059],
              [-74.92933133399993, 10.535846672000048],
              [-74.92932444299993, 10.53584490500009 ],
              [-74.92931453099993, 10.53584236200004 ],
              [-74.9292863359999 , 10.535835131000056],
              [-74.92925719399994, 10.535827657000084],
              [-74.92923785199991, 10.535822696000082],
              [-74.92921221299991, 10.535816121000039],
              [-74.9291585279999 , 10.535802353000065],
              [-74.92915038899991, 10.53580026600008 ],
              [-74.9291216439999 , 10.535792892000075],
              [-74.9290853089999 , 10.535783575000039],
              [-74.9290725749999 , 10.535780309000074],
              [-74.9290712479999 , 10.535779969000089],
              [-74.9289903689999 , 10.535759226000039],
              [-74.92897894799995, 10.535756297000091],
              [-74.92890008899991, 10.535736072000077],
              [-74.92889361599993, 10.535734412000068],
              [-74.92888654099994, 10.535732597000049],
              [-74.92886843999992, 10.535727954000038],
              [-74.92883797799993, 10.53572014200006 ],
              [-74.92881709999995, 10.535714787000074],
              [-74.92880557399991, 10.535711830000082],
              [-74.92877740599994, 10.535704606000081],
              [-74.92874843099992, 10.535697174000063],
              [-74.9287250779999 , 10.535691185000076],
              [-74.92870554299992, 10.535686173000045],
              [-74.92867520099992, 10.535678390000044],
              [-74.92866925399994, 10.535676864000038],
              [-74.92866508499992, 10.535675795000088],
              [-74.92866296699992, 10.535675253000079],
              [-74.92862555699992, 10.535665658000084],
              [-74.92860859199993, 10.535661308000044],
              [-74.92858997699994, 10.535656535000044],
              [-74.92852557999993, 10.535640019000084],
              [-74.92850634899992, 10.53563508500008 ],
              [-74.92850216799991, 10.53563401200006 ],
              [-74.92844062899991, 10.535618229000079],
              [-74.92843641199994, 10.535617147000039],
              [-74.92843044199992, 10.53561561500004 ],
              [-74.92842109199995, 10.535613218000037],
              [-74.92840930199992, 10.535610194000071],
              [-74.92835099899992, 10.535595240000077],
              [-74.92833382499992, 10.53559083500005 ],
              [-74.92832285399993, 10.53558802100008 ],
              [-74.92829855699995, 10.535581789000048],
              [-74.92827267899992, 10.535575152000092],
              [-74.92825888199991, 10.535571613000059],
              [-74.9282219129999 , 10.535562131000063],
              [-74.9282041969999 , 10.535557587000085],
              [-74.92818722099992, 10.535553233000087],
              [-74.92816838499994, 10.535548402000074],
              [-74.92816411899992, 10.535547308000048],
              [-74.92811562999992, 10.535534873000074],
              [-74.92809543399994, 10.535529693000058],
              [-74.92807607999993, 10.535524729000088],
              [-74.92805982099992, 10.535520559000076],
              [-74.92801822899992, 10.535509889000082],
              [-74.92800488799992, 10.535506467000062],
              [-74.9280026159999 , 10.535505884000088],
              [-74.92798861799992, 10.535502294000082],
              [-74.92798258599993, 10.535500747000071],
              [-74.9279721609999 , 10.535498076000067],
              [-74.92794934199992, 10.535492226000088],
              [-74.92793059699994, 10.53548741800006 ],
              [-74.92789496899991, 10.535478280000063],
              [-74.92785694499992, 10.535468527000091],
              [-74.9278237609999 , 10.53546001500007 ],
              [-74.92778973899993, 10.535451287000058],
              [-74.92777493299991, 10.535447490000081],
              [-74.92777244199993, 10.535446851000074],
              [-74.92775429599993, 10.535442197000066],
              [-74.92773959699991, 10.535438427000088],
              [-74.92770474499991, 10.53542948800009 ],
              [-74.92769906399991, 10.535428030000048],
              [-74.92769255899992, 10.535426362000067],
              [-74.92761361399994, 10.535406114000068],
              [-74.92760812999995, 10.535404708000044],
              [-74.9275218919999 , 10.53538259000004 ],
              [-74.92752054999994, 10.53538224600004 ],
              [-74.92750291999994, 10.535377724000057],
              [-74.9275002999999 , 10.535377052000058],
              [-74.92743482999992, 10.535360260000061],
              [-74.92738172799994, 10.535346641000046],
              [-74.9273710679999 , 10.535343907000083],
              [-74.92734560199995, 10.535337377000076],
              [-74.92732956599991, 10.535333265000077],
              [-74.92730343999995, 10.535326564000059],
              [-74.92730215299991, 10.535326233000092],
              [-74.92721698699995, 10.535304390000078],
              [-74.92720475999994, 10.535301253000057],
              [-74.92716257299992, 10.535290434000046],
              [-74.92712445799992, 10.53528065800009 ],
              [-74.92709912099991, 10.53527415900004 ],
              [-74.9270927579999 , 10.535272527000075],
              [-74.92705044799993, 10.535261675000072],
              [-74.92700008399993, 10.535248756000044],
              [-74.92699038199993, 10.535246268000037],
              [-74.92698513999994, 10.53524492200006 ],
              [-74.9269436269999 , 10.53523427500005 ],
              [-74.92690619299992, 10.535224674000062],
              [-74.92690561199993, 10.535224524000057],
              [-74.92688672199995, 10.535219679000079],
              [-74.92688655899991, 10.535219637000068],
              [-74.9268773309999 , 10.53521727000009 ],
              [-74.92686840399995, 10.535214980000092],
              [-74.92682105299991, 10.535202836000053],
              [-74.92680959199993, 10.535199897000041],
              [-74.92676906199995, 10.535189502000037],
              [-74.92672701299995, 10.535178718000054],
              [-74.92672533299992, 10.535178287000065],
              [-74.92669819899993, 10.535171328000047],
              [-74.92667545099994, 10.535165493000079],
              [-74.92667424899992, 10.53516518500004 ],
              [-74.92667238399991, 10.535164707000092],
              [-74.92665054299994, 10.535159105000048],
              [-74.92664285199993, 10.535157132000052],
              [-74.92664051899993, 10.535156534000066],
              [-74.92663026299994, 10.535153905000072],
              [-74.92658727899993, 10.53514288000008 ],
              [-74.92658436199991, 10.53514213200009 ],
              [-74.92657493099995, 10.535139713000092],
              [-74.92656533099995, 10.535137251000037],
              [-74.9265280969999 , 10.535127701000079],
              [-74.92651625499991, 10.53512466400008 ],
              [-74.92651611299993, 10.535124628000062],
              [-74.9264896549999 , 10.535117841000044],
              [-74.92648384599994, 10.535116351000056],
              [-74.92648008799995, 10.535115387000076],
              [-74.9264379249999 , 10.535104574000059],
              [-74.92639829099994, 10.535094409000067],
              [-74.92639177799992, 10.53509273800006 ],
              [-74.92638133499992, 10.535090059000083],
              [-74.92638062299994, 10.535089876000086],
              [-74.92637436299992, 10.535088270000074],
              [-74.92632937299993, 10.53507673200005 ],
              [-74.92630692599994, 10.535070975000053],
              [-74.92627107699991, 10.535061780000092],
              [-74.92625134699995, 10.535056720000057],
              [-74.92623845199995, 10.535053413000071],
              [-74.92623541199993, 10.535052632000088],
              [-74.9262035989999 , 10.535044473000085],
              [-74.92620356399993, 10.535044463000077],
              [-74.92616603599993, 10.535034839000048],
              [-74.92612751999991, 10.53502496100009 ],
              [-74.92612030099991, 10.535023109000065],
              [-74.92610573999991, 10.535019374000058],
              [-74.92609574699992, 10.535016811000048],
              [-74.9260711149999 , 10.535010494000062],
              [-74.92604802699992, 10.535004573000037],
              [-74.92603269199992, 10.535000639000089],
              [-74.92600102299991, 10.534992516000045],
              [-74.92599612799995, 10.534991261000073],
              [-74.92599407199992, 10.534990733000086],
              [-74.92596150799994, 10.534982381000077],
              [-74.92596006899993, 10.534982013000047],
              [-74.92594701999991, 10.534978666000086],
              [-74.92592072999992, 10.534971924000047],
              [-74.92591293699991, 10.534969924000052],
              [-74.92586115299991, 10.534956643000044],
              [-74.9258499419999 , 10.534953768000037],
              [-74.92584543299995, 10.534952611000051],
              [-74.92584378799995, 10.53495218900008 ],
              [-74.92584364299995, 10.534952152000074],
              [-74.92582394199991, 10.534947099000078],
              [-74.92582241699995, 10.534946708000064],
              [-74.9258215129999 , 10.534946476000073],
              [-74.92581698899994, 10.534945316000062],
              [-74.92577645099993, 10.53493491900008 ],
              [-74.92577287599994, 10.534934001000067],
              [-74.92575786299994, 10.534930151000083],
              [-74.92573450899994, 10.534924161000049],
              [-74.92572524199994, 10.534921784000062],
              [-74.92570152099995, 10.534915700000056],
              [-74.9256890979999 , 10.534912514000041],
              [-74.92568328199991, 10.534911022000074],
              [-74.92568164199992, 10.534910601000092],
              [-74.92563591899994, 10.534898875000067],
              [-74.92561835399994, 10.534894370000075],
              [-74.92560646499993, 10.534891321000089],
              [-74.92559622099992, 10.534888694000074],
              [-74.92559359499995, 10.53488802000004 ],
              [-74.92555761999995, 10.534878793000075],
              [-74.92553829899992, 10.534873838000067],
              [-74.92553378799994, 10.534872681000081],
              [-74.92553263499991, 10.534872385000085],
              [-74.92549462099993, 10.534862635000081],
              [-74.9254688179999 , 10.534856017000038],
              [-74.92546529299995, 10.534855113000049],
              [-74.92545120099993, 10.53485149900007 ],
              [-74.9254451079999 , 10.534849936000057],
              [-74.92543757899995, 10.534848005000072],
              [-74.9254009679999 , 10.53483861400008 ],
              [-74.92539477499992, 10.534837026000048],
              [-74.92536244599995, 10.534828734000087],
              [-74.92533749299992, 10.534822334000069],
              [-74.9253323719999 , 10.534821021000084],
              [-74.9253304309999 , 10.534820523000064],
              [-74.9253126349999 , 10.53481595900007 ],
              [-74.92529212499994, 10.534810698000058],
              [-74.92528983999995, 10.53481011200006 ],
              [-74.92526789599992, 10.534804484000063],
              [-74.92524350199994, 10.534798228000056],
              [-74.92523107599993, 10.534795041000052],
              [-74.92519984599994, 10.534787031000064],
              [-74.92518454599991, 10.534783106000077],
              [-74.9251796289999 , 10.534781845000055],
              [-74.92517783999995, 10.534781386000077],
              [-74.92517552899994, 10.534780794000085],
              [-74.92515001099991, 10.534774249000066],
              [-74.92514748499991, 10.53477360100004 ],
              [-74.92510786499992, 10.534763439000074],
              [-74.92510405199994, 10.53476246100007 ],
              [-74.9250915259999 , 10.534759248000057],
              [-74.92508582499994, 10.534757786000057],
              [-74.92507554199995, 10.534755148000045],
              [-74.92507248999993, 10.534754366000072],
              [-74.9250696009999 , 10.534753625000064],
              [-74.92506447399995, 10.534752310000044],
              [-74.92502400899991, 10.534741931000042],
              [-74.92501847699992, 10.534740512000042],
              [-74.92500105999994, 10.534736045000045],
              [-74.92498567199993, 10.534732098000063],
              [-74.92497389199991, 10.534729077000065],
              [-74.92497216899994, 10.534728635000079],
              [-74.92493845099995, 10.534719987000074],
              [-74.92491543199992, 10.53471408300004 ],
              [-74.92490474099992, 10.534711341000047],
              [-74.92490725699992, 10.534676950000062],
              [-74.92491466099995, 10.534575768000082],
              [-74.92491726899993, 10.534540134000054],
              [-74.92491801299991, 10.534529960000043],
              [-74.92492815399993, 10.53439135800005 ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08520",
      "properties": {"id": "08520", "iso": "PAL", "name": "PALMAR DE VARELA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.73872969899992, 10.750285917000042],
              [-74.73811468999992, 10.750158282000086],
              [-74.7380699009999 , 10.75016059300009 ],
              [-74.7377071869999 , 10.75017930000007 ],
              [-74.7376192939999 , 10.75012842700005 ],
              [-74.73736032999994, 10.749978537000061],
              [-74.7359933919999 , 10.749215736000053],
              [-74.73569909499992, 10.749134563000041],
              [-74.73534092599994, 10.749035768000056],
              [-74.73517377799993, 10.749000207000051],
              [-74.73487202799993, 10.748936007000054],
              [-74.73446435499994, 10.748876419000055],
              [-74.73413069499992, 10.748859763000041],
              [-74.73407714299992, 10.748857089000069],
              [-74.73382917999993, 10.74878410100007 ],
              [-74.73366933199992, 10.748737050000045],
              [-74.73356570699991, 10.748726195000074],
              [-74.73291520099991, 10.748658041000056],
              [-74.73289238899991, 10.748664816000087],
              [-74.7307973099999 , 10.749287154000058],
              [-74.72838586799992, 10.749375980000082],
              [-74.72838530799993, 10.749376002000076],
              [-74.72774647799991, 10.749399531000051],
              [-74.72774643899993, 10.74938072000009 ],
              [-74.72774642099995, 10.749372318000042],
              [-74.72774539999995, 10.748895281000046],
              [-74.72763637799994, 10.748047435000046],
              [-74.72764574199994, 10.744072072000051],
              [-74.72762239699995, 10.743003525000063],
              [-74.7276041149999 , 10.742166656000052],
              [-74.7269252879999 , 10.740104772000052],
              [-74.72666856799992, 10.738738861000058],
              [-74.72621543199995, 10.73783349100006 ],
              [-74.7259859369999 , 10.736007396000048],
              [-74.72596758299994, 10.734013534000042],
              [-74.7259340949999 , 10.730374376000043],
              [-74.72595330299993, 10.728253232000043],
              [-74.72595769299994, 10.72776906100006 ],
              [-74.72595777799995, 10.727758663000088],
              [-74.72595783999992, 10.727594513000042],
              [-74.72595896599995, 10.724591343000043],
              [-74.72592577199993, 10.71893390200006 ],
              [-74.72592606499995, 10.714102531000037],
              [-74.72593030399992, 10.71084849400006 ],
              [-74.72592815599995, 10.709838251000065],
              [-74.7259239789999 , 10.707873925000058],
              [-74.72592140799992, 10.706665505000046],
              [-74.72542862999995, 10.70477023300009 ],
              [-74.72523567699994, 10.70242815100005 ],
              [-74.72520918499993, 10.698802930000056],
              [-74.72520836799993, 10.698417673000051],
              [-74.72519289299993, 10.691124818000048],
              [-74.72518757599994, 10.688615020000043],
              [-74.7247113489999 , 10.68564142300005 ],
              [-74.72434929899993, 10.684342531000084],
              [-74.72404842799995, 10.683263124000064],
              [-74.72377902499994, 10.68030768500006 ],
              [-74.72286992399995, 10.677056102000051],
              [-74.7228416769999 , 10.672575688000052],
              [-74.72325290899994, 10.669046585000046],
              [-74.72330321999993, 10.668614812000044],
              [-74.72358790699991, 10.667239996000092],
              [-74.7236389649999 , 10.666993424000054],
              [-74.72418312099995, 10.666733686000043],
              [-74.72508200599992, 10.666304627000045],
              [-74.7264673809999 , 10.665673095000045],
              [-74.72727526299991, 10.665185635000057],
              [-74.7282276489999 , 10.664726450000046],
              [-74.7290067539999 , 10.664296197000056],
              [-74.72952603699991, 10.663952211000037],
              [-74.7300453769999 , 10.663636796000048],
              [-74.73068015599995, 10.663263991000065],
              [-74.73137289399995, 10.662976788000037],
              [-74.73255625899992, 10.662459957000067],
              [-74.73368129199991, 10.661686063000047],
              [-74.73466236199994, 10.661141071000088],
              [-74.73549898499994, 10.660596381000062],
              [-74.73633553799993, 10.660023111000044],
              [-74.73694122799992, 10.659564628000055],
              [-74.73737382399992, 10.659220813000047],
              [-74.73797969299994, 10.658848053000042],
              [-74.73847012999994, 10.658532688000037],
              [-74.73922006999993, 10.657988173000092],
              [-74.74005648899993, 10.657357742000045],
              [-74.74054685199991, 10.657013801000062],
              [-74.7407531639999 , 10.656874685000048],
              [-74.7413545679999 , 10.65646915700006 ],
              [-74.74190246099994, 10.65601078900005 ],
              [-74.74250812799994, 10.655552297000042],
              [-74.74296954499994, 10.655179837000048],
              [-74.74306553899993, 10.655102127000077],
              [-74.74314248099995, 10.655039841000075],
              [-74.74374817099994, 10.654549519000057],
              [-74.74426698099995, 10.654005483000049],
              [-74.74484338399992, 10.653375595000057],
              [-74.74521756599995, 10.652746138000055],
              [-74.74567847199995, 10.652145073000042],
              [-74.74628350299992, 10.651400820000049],
              [-74.74641704999993, 10.651145416000077],
              [-74.74721113099991, 10.650970487000052],
              [-74.74733458899993, 10.650957739000091],
              [-74.74752866999995, 10.65090364200006 ],
              [-74.74768374599995, 10.650835793000056],
              [-74.7478083869999 , 10.650764332000051],
              [-74.74780948699993, 10.65076373100004 ],
              [-74.74796574499993, 10.650678309000057],
              [-74.74810103999994, 10.650600415000042],
              [-74.74825136399994, 10.650517601000047],
              [-74.74839053999995, 10.650431426000068],
              [-74.74846597099992, 10.650387038000076],
              [-74.74851775099995, 10.650356568000063],
              [-74.74866291799992, 10.650264733000085],
              [-74.74882492099994, 10.65017285500005 ],
              [-74.74897246499995, 10.650124505000065],
              [-74.7492507579999 , 10.650054978000071],
              [-74.74943036699995, 10.65004046100006 ],
              [-74.74962769699994, 10.650037209000061],
              [-74.74983934099993, 10.650046351000071],
              [-74.75014943299993, 10.650151905000087],
              [-74.75032325699993, 10.65023457600006 ],
              [-74.7505785489999 , 10.650348715000064],
              [-74.75071466499992, 10.65043429700006 ],
              [-74.75085423299993, 10.65052101300006 ],
              [-74.7509932019999 , 10.650600935000057],
              [-74.75118466899994, 10.650716640000041],
              [-74.7514165469999 , 10.650771778000092],
              [-74.75152445299994, 10.65076376400009 ],
              [-74.75157330699994, 10.650760134000052],
              [-74.75169493199991, 10.650757231000057],
              [-74.7517817669999 , 10.650755158000038],
              [-74.7519210719999 , 10.650725480000062],
              [-74.75214545299991, 10.65068404100009 ],
              [-74.75244828099994, 10.650601736000056],
              [-74.75265382799995, 10.650564563000046],
              [-74.75276522899992, 10.650524034000057],
              [-74.75287413099994, 10.65048441500005 ],
              [-74.75319860199994, 10.650378064000051],
              [-74.75337375599992, 10.650361265000072],
              [-74.75341559899994, 10.650357252000049],
              [-74.7536186179999 , 10.650339859000042],
              [-74.75385070699991, 10.650361670000052],
              [-74.75405636499994, 10.650442870000063],
              [-74.75420421799993, 10.650528983000072],
              [-74.75436111699992, 10.650712264000049],
              [-74.7545496859999 , 10.650869488000069],
              [-74.75475585899994, 10.650992773000041],
              [-74.7548891479999 , 10.651086836000047],
              [-74.75504997599995, 10.651289036000037],
              [-74.75498656899993, 10.651545678000048],
              [-74.75486648599991, 10.651680685000088],
              [-74.75471606299993, 10.651776219000055],
              [-74.75449159199991, 10.651905523000039],
              [-74.75426118299993, 10.652000386000054],
              [-74.75404240799992, 10.652056232000064],
              [-74.75390345299991, 10.652183938000064],
              [-74.7539312319999 , 10.652407332000053],
              [-74.7540937519999 , 10.652544836000061],
              [-74.75429099399992, 10.652563333000046],
              [-74.75452008699995, 10.65258543300007 ],
              [-74.7547172219999 , 10.652620034000051],
              [-74.75494860999993, 10.65264890800006 ],
              [-74.75501031699991, 10.652657313000077],
              [-74.75512416799995, 10.652672821000067],
              [-74.75535052499993, 10.652751421000062],
              [-74.75549674099994, 10.652814385000056],
              [-74.75563898099995, 10.652820288000044],
              [-74.75581845799991, 10.652680338000039],
              [-74.75602392899992, 10.652484119000064],
              [-74.75620914399991, 10.652363655000045],
              [-74.75633643499992, 10.652200101000062],
              [-74.75639174899993, 10.652025732000084],
              [-74.7564066839999 , 10.65197865500005 ],
              [-74.7564261309999 , 10.651818541000068],
              [-74.7565359749999 , 10.651649926000061],
              [-74.75669358699992, 10.65150212900005 ],
              [-74.75688365299993, 10.651569499000061],
              [-74.75703449799994, 10.651718333000076],
              [-74.75716782599994, 10.651832727000055],
              [-74.75731584999994, 10.65199625400004 ],
              [-74.75744922299992, 10.652127607000068],
              [-74.75757573299995, 10.652250494000043],
              [-74.75775114699991, 10.652407745000062],
              [-74.75803266999992, 10.652502039000069],
              [-74.75825558299994, 10.652570493000042],
              [-74.75849608399994, 10.652588037000044],
              [-74.75867032699995, 10.652598953000052],
              [-74.75881197699994, 10.652598657000055],
              [-74.75906974799994, 10.652547235000043],
              [-74.75926092799995, 10.652346803000057],
              [-74.75933196599993, 10.652185627000051],
              [-74.75938968299994, 10.652054547000091],
              [-74.75943157199993, 10.651959413000043],
              [-74.75959386999995, 10.651812720000066],
              [-74.75978196799991, 10.651764282000045],
              [-74.75986473099994, 10.651757560000078],
              [-74.75999640499992, 10.651746866000053],
              [-74.76020487699992, 10.651743576000058],
              [-74.76047760499995, 10.651734503000057],
              [-74.7607269149999 , 10.651732257000049],
              [-74.76088341099995, 10.651731917000063],
              [-74.76108332199993, 10.651731476000066],
              [-74.76130643599993, 10.651759809000055],
              [-74.7614917919999 , 10.65176561000004 ],
              [-74.76166599499993, 10.65175957300005 ],
              [-74.76184549599992, 10.651700707000089],
              [-74.7620249979999 , 10.65163900400006 ],
              [-74.76218873499994, 10.651554184000076],
              [-74.76234646099994, 10.651466536000044],
              [-74.76255948699992, 10.651389236000057],
              [-74.76273782099992, 10.65138375500004 ],
              [-74.76288288899991, 10.651380604000053],
              [-74.7631206559999 , 10.651129792000063],
              [-74.76331571599991, 10.65094431600005 ],
              [-74.76347773499992, 10.650729550000051],
              [-74.76355701099993, 10.650550280000061],
              [-74.76368259599991, 10.650329657000043],
              [-74.76382398299995, 10.650147422000089],
              [-74.76396005999993, 10.650081010000065],
              [-74.76407423699993, 10.650060740000072],
              [-74.76410508399994, 10.65005526400006 ],
              [-74.76421370699995, 10.650080865000064],
              [-74.76424995399992, 10.650089407000053],
              [-74.76439630899995, 10.650148977000072],
              [-74.76453979699994, 10.650200638000058],
              [-74.76477228999994, 10.650182888000074],
              [-74.76493071599992, 10.64999073100006 ],
              [-74.76492129699994, 10.649810511000055],
              [-74.76491809499993, 10.649650052000084],
              [-74.76489191799993, 10.64944051200007 ],
              [-74.7649275089999 , 10.649203140000054],
              [-74.76499311499992, 10.649118802000089],
              [-74.76507060199992, 10.649019193000072],
              [-74.76521984399994, 10.64901721700005 ],
              [-74.76527493299994, 10.64901648700004 ],
              [-74.76543853399994, 10.649061888000062],
              [-74.76557522199994, 10.649138984000047],
              [-74.76571132499993, 10.649216095000043],
              [-74.76596215299992, 10.64937599500007 ],
              [-74.76602664199993, 10.649499538000043],
              [-74.7660711449999 , 10.649584798000092],
              [-74.76604270999991, 10.649765646000048],
              [-74.76598508699993, 10.649920597000062],
              [-74.7659737319999 , 10.65008107400007 ],
              [-74.76603800499993, 10.650209180000047],
              [-74.76609841499993, 10.650345021000078],
              [-74.76610201199992, 10.650353108000047],
              [-74.76621206399994, 10.65052293900004 ],
              [-74.76641804699995, 10.650681802000065],
              [-74.76662538799991, 10.650742645000037],
              [-74.76675286099993, 10.650757522000049],
              [-74.76685576999995, 10.650769530000048],
              [-74.7670414609999 , 10.65079793600006 ],
              [-74.76720502199993, 10.650822155000071],
              [-74.76735457799992, 10.650843007000049],
              [-74.76752694299995, 10.650973147000059],
              [-74.76771387699995, 10.651108889000056],
              [-74.76772185599992, 10.651112861000058],
              [-74.76798391499995, 10.651243314000055],
              [-74.7681810279999 , 10.651325930000041],
              [-74.76838672299994, 10.651359943000045],
              [-74.7685489399999 , 10.651362404000054],
              [-74.7686937229999 , 10.651353609000068],
              [-74.7689039089999 , 10.651287611000043],
              [-74.76898789099994, 10.651259654000057],
              [-74.76911066699995, 10.651218781000068],
              [-74.76929375299994, 10.651156530000037],
              [-74.76931056899991, 10.651150813000072],
              [-74.76955105699994, 10.651104524000061],
              [-74.7697740189999 , 10.65106531400005 ],
              [-74.76994174399994, 10.650976523000054],
              [-74.77009804199992, 10.650761197000065],
              [-74.77013106699991, 10.650717365000048],
              [-74.77019313699992, 10.650634983000089],
              [-74.7702914219999 , 10.650525730000084],
              [-74.77041573799994, 10.650440139000068],
              [-74.77060426899993, 10.650462318000052],
              [-74.77078408499995, 10.650581498000065],
              [-74.77084408799993, 10.650616521000074],
              [-74.77093774799994, 10.650671187000057],
              [-74.77104551999992, 10.650712772000077],
              [-74.77112626199994, 10.650743929000043],
              [-74.77136982999991, 10.650791982000044],
              [-74.77156161799991, 10.650870157000043],
              [-74.77159012499993, 10.650881777000052],
              [-74.77160214499992, 10.651054461000058],
              [-74.77156200699994, 10.651279982000062],
              [-74.7715189239999 , 10.65148657800006 ],
              [-74.77149003999995, 10.651658685000086],
              [-74.7715455039999 , 10.651868739000065],
              [-74.77166327599991, 10.65195641400004 ],
              [-74.77177779999994, 10.652041673000042],
              [-74.77185986899991, 10.652028117000043],
              [-74.77201531599991, 10.652002443000072],
              [-74.77215265899991, 10.651865118000046],
              [-74.77225816499993, 10.651728719000062],
              [-74.7723883139999 , 10.65157079700009 ],
              [-74.77248342399992, 10.651453059000062],
              [-74.77259904999994, 10.651309293000054],
              [-74.7727667819999 , 10.651103255000066],
              [-74.77299115499994, 10.65093213700004 ],
              [-74.77312493599993, 10.650809199000037],
              [-74.7731427729999 , 10.650792808000062],
              [-74.77324705899991, 10.65068974400009 ],
              [-74.77336253699991, 10.650606433000064],
              [-74.77350467699995, 10.650572210000064],
              [-74.77370458899992, 10.650571770000056],
              [-74.77375607899995, 10.650591235000093],
              [-74.77386951699992, 10.650634120000063],
              [-74.7740842259999 , 10.65073985400005 ],
              [-74.77434814599991, 10.650876548000042],
              [-74.77451444299993, 10.65100103800006 ],
              [-74.77467483999993, 10.651182893000055],
              [-74.77480867199995, 10.651439381000046],
              [-74.7749266209999 , 10.65169278500008 ],
              [-74.77509507299993, 10.651984524000056],
              [-74.77528659399991, 10.652127032000067],
              [-74.77544042999995, 10.652210294000042],
              [-74.7756550439999 , 10.652266882000049],
              [-74.77587223199993, 10.652324023000062],
              [-74.77601990699992, 10.652335558000061],
              [-74.77624602999992, 10.652311876000056],
              [-74.77648355399992, 10.652271236000047],
              [-74.77667850599994, 10.652235440000084],
              [-74.77676701699994, 10.652219187000071],
              [-74.77708870399994, 10.652138800000046],
              [-74.77727107099992, 10.652086409000049],
              [-74.77747965099991, 10.65200570400009 ],
              [-74.77772142299995, 10.651892444000055],
              [-74.77804520299992, 10.651669680000055],
              [-74.7782609059999 , 10.651544409000053],
              [-74.7783409839999 , 10.651515456000084],
              [-74.77843173899993, 10.651482643000065],
              [-74.7785823239999 , 10.651514496000061],
              [-74.77874781199995, 10.651632784000071],
              [-74.77880992099995, 10.65183735000005 ],
              [-74.77881411599992, 10.652009098000065],
              [-74.77876201399994, 10.652222883000093],
              [-74.77870302899993, 10.652465114000051],
              [-74.77866434799995, 10.65265560000006 ],
              [-74.77862844299995, 10.652817836000054],
              [-74.77864421599992, 10.652968457000043],
              [-74.77870586899991, 10.653138281000054],
              [-74.7788469859999 , 10.653399835000073],
              [-74.77898365499993, 10.653533432000074],
              [-74.77911592299995, 10.653670993000048],
              [-74.77922655099991, 10.653840242000058],
              [-74.77945864799995, 10.654175313000053],
              [-74.77952895999994, 10.654444099000045],
              [-74.77961597899991, 10.654655769000044],
              [-74.77975788899994, 10.654711260000056],
              [-74.77986524399995, 10.654753238000069],
              [-74.77987497799995, 10.654781655000079],
              [-74.77996415699994, 10.655042010000045],
              [-74.78009244099991, 10.655056086000059],
              [-74.78009308299994, 10.655056156000057],
              [-74.78019611099995, 10.655067461000044],
              [-74.78027164199995, 10.65525092100006 ],
              [-74.7802662869999 , 10.655405172000087],
              [-74.78024332699994, 10.655600708000065],
              [-74.7801887359999 , 10.655952821000085],
              [-74.78016283699992, 10.656116161000057],
              [-74.78016316999992, 10.65625966600004 ],
              [-74.78025061799991, 10.65653405900008 ],
              [-74.78034910299993, 10.65669457100006 ],
              [-74.78037263399995, 10.656699572000036],
              [-74.78058982499994, 10.65674572800009 ],
              [-74.78084136799993, 10.656595997000068],
              [-74.78106710799995, 10.656401125000059],
              [-74.78117763099993, 10.65623022300008 ],
              [-74.7811785259999 , 10.656228838000061],
              [-74.78117898499994, 10.656222605000039],
              [-74.78119417499994, 10.656016088000058],
              [-74.7812200269999 , 10.655838619000065],
              [-74.78124768799995, 10.655836038000075],
              [-74.78148386399994, 10.655814014000043],
              [-74.7815588709999 , 10.655646324000088],
              [-74.78155545999994, 10.655402817000038],
              [-74.78144822799993, 10.655225661000088],
              [-74.78140536399991, 10.655154993000053],
              [-74.7813493239999 , 10.655062601000054],
              [-74.7813719159999 , 10.654829765000045],
              [-74.78144166099992, 10.654612649000057],
              [-74.78149063799992, 10.654423270000052],
              [-74.78151047199992, 10.654233957000088],
              [-74.78146940899995, 10.654015963000063],
              [-74.78142707299992, 10.653798256000073],
              [-74.78150112799995, 10.653531134000048],
              [-74.78158209399993, 10.653406653000047],
              [-74.78172788299992, 10.653363136000053],
              [-74.78178330899993, 10.653346591000059],
              [-74.78197600199991, 10.65337863700006 ],
              [-74.7821441079999 , 10.653513572000065],
              [-74.7823790889999 , 10.653676032000078],
              [-74.78259371099995, 10.653738832000045],
              [-74.78277416399993, 10.653730752000058],
              [-74.78279332099993, 10.653729894000037],
              [-74.78297581299995, 10.65372976000009 ],
              [-74.78303068999992, 10.653871546000062],
              [-74.78307205599992, 10.653978424000059],
              [-74.78308374499994, 10.654210621000061],
              [-74.78319136399995, 10.654436923000048],
              [-74.78330763299994, 10.654573385000049],
              [-74.78401518999993, 10.655009077000045],
              [-74.78427913099995, 10.655085342000064],
              [-74.78456618199993, 10.655168285000059],
              [-74.78484690499994, 10.655159169000058],
              [-74.78494547799994, 10.655097052000087],
              [-74.7850151589999 , 10.655053141000053],
              [-74.78516828599993, 10.654955610000059],
              [-74.7853245629999 , 10.654856941000048],
              [-74.78547514199994, 10.654765069000064],
              [-74.78565483699992, 10.654667485000061],
              [-74.78596209099993, 10.654649834000054],
              [-74.7861649219999 , 10.65467761800005 ],
              [-74.78623455999991, 10.654838207000068],
              [-74.78623785699995, 10.655090465000058],
              [-74.78626453699991, 10.655385895000052],
              [-74.78641548899992, 10.655577641000093],
              [-74.78667053899994, 10.655708139000069],
              [-74.78691884999995, 10.655729445000077],
              [-74.78693991499995, 10.655731252000066],
              [-74.78728508599994, 10.65568978400006 ],
              [-74.78763224799991, 10.655632491000063],
              [-74.7882295359999 , 10.655562197000052],
              [-74.78853065399994, 10.655606702000057],
              [-74.78863220099993, 10.655689948000088],
              [-74.78866399099991, 10.65571600900006 ],
              [-74.78876895899992, 10.655904475000057],
              [-74.78883281599991, 10.656099811000047],
              [-74.78888517399992, 10.656260144000044],
              [-74.78897228299991, 10.65638537600006 ],
              [-74.78918116699992, 10.656556651000074],
              [-74.78931445599994, 10.656642214000044],
              [-74.78955242199993, 10.656790840000042],
              [-74.78974616199991, 10.65688971600008 ],
              [-74.7899006909999 , 10.656968581000058],
              [-74.7901034599999 , 10.657094104000066],
              [-74.79013165999993, 10.65712568300006 ],
              [-74.79027212699992, 10.657282985000052],
              [-74.79038777699992, 10.657396855000059],
              [-74.79061439499992, 10.657586165000055],
              [-74.79079386899991, 10.657630962000042],
              [-74.79105214099991, 10.657673866000039],
              [-74.79126344499991, 10.657659249000062],
              [-74.79145479599993, 10.657658810000044],
              [-74.79156863899993, 10.657673868000074],
              [-74.79162277099994, 10.65768102700008 ],
              [-74.79179085299995, 10.657743917000062],
              [-74.7919533509999 , 10.657863323000072],
              [-74.79195954199992, 10.657868853000082],
              [-74.79214510199995, 10.65803463900005 ],
              [-74.79230166199994, 10.658178343000088],
              [-74.79235700899994, 10.65839462200006 ],
              [-74.79228485699991, 10.658562024000048],
              [-74.79212271999995, 10.658717196000055],
              [-74.79208851999994, 10.65887150900005 ],
              [-74.7922969839999 , 10.65898007900006 ],
              [-74.79246487899991, 10.658968395000045],
              [-74.79255872199991, 10.658958736000045],
              [-74.79263333999995, 10.658951055000045],
              [-74.79296926199993, 10.658978526000055],
              [-74.79311162999994, 10.65903582900006 ],
              [-74.79316925599994, 10.659061620000045],
              [-74.79326485999991, 10.659104407000086],
              [-74.79342727099993, 10.65918425700005 ],
              [-74.7936707959999 , 10.659269570000049],
              [-74.7938004319999 , 10.659259930000076],
              [-74.79382756699994, 10.659257912000044],
              [-74.79401281999992, 10.659216797000056],
              [-74.7941577869999 , 10.659171270000058],
              [-74.7944357909999 , 10.659096053000042],
              [-74.79494575699994, 10.659055324000065],
              [-74.79517764799994, 10.659110157000043],
              [-74.79524527299992, 10.65920236900007 ],
              [-74.79531704699991, 10.65930023900006 ],
              [-74.79543948799994, 10.65963273500006 ],
              [-74.79551794799994, 10.659777191000046],
              [-74.7956888569999 , 10.659775664000051],
              [-74.7958332799999 , 10.659734636000053],
              [-74.7958594399999 , 10.65956340300005 ],
              [-74.79586222599994, 10.659552098000063],
              [-74.7959055529999 , 10.65937628200004 ],
              [-74.79580064699991, 10.65909911700004 ],
              [-74.79567834199992, 10.658824241000048],
              [-74.79577821599992, 10.658604807000074],
              [-74.79600527299993, 10.658554557000059],
              [-74.79624986599993, 10.658595282000078],
              [-74.79661440399991, 10.65865597900006 ],
              [-74.79685204999993, 10.658672373000059],
              [-74.79703709699993, 10.658660650000058],
              [-74.79724037699992, 10.658637570000053],
              [-74.79741422899991, 10.658572184000093],
              [-74.79757594199992, 10.658511365000038],
              [-74.79791787699992, 10.658424697000044],
              [-74.79813802299992, 10.658286330000067],
              [-74.79828462499995, 10.658109909000075],
              [-74.79830548299992, 10.658084809000059],
              [-74.79849655299995, 10.65772955500006 ],
              [-74.7986323259999 , 10.65753432300005 ],
              [-74.79880884499994, 10.657424864000063],
              [-74.79898877599993, 10.657431214000042],
              [-74.7991624039999 , 10.657424045000084],
              [-74.79916566199995, 10.657423506000043],
              [-74.79933597299993, 10.657395389000044],
              [-74.79954052199992, 10.657300277000047],
              [-74.7996718469999 , 10.657159573000058],
              [-74.79990375099993, 10.65692400100005 ],
              [-74.80025081999992, 10.656590983000058],
              [-74.80039561699994, 10.656593473000044],
              [-74.80043947699994, 10.656615173000091],
              [-74.80062178399993, 10.656705374000069],
              [-74.80081902299992, 10.656779497000059],
              [-74.80116743099995, 10.657254401000046],
              [-74.8013239359999 , 10.657494716000087],
              [-74.8015039519999 , 10.657651930000043],
              [-74.80155403299995, 10.657641372000057],
              [-74.80171261299995, 10.657607940000048],
              [-74.80177042399993, 10.657579272000078],
              [-74.80189803399992, 10.657515989000046],
              [-74.80315451299992, 10.656746923000071],
              [-74.80331081499992, 10.656660683000041],
              [-74.80353700099994, 10.656592656000043],
              [-74.8036932359999 , 10.656545666000056],
              [-74.8047938599999 , 10.656514018000053],
              [-74.80480582699994, 10.656513674000053],
              [-74.80500867099994, 10.65654823500006 ],
              [-74.80510575799991, 10.656653696000092],
              [-74.8051249259999 , 10.656674518000045],
              [-74.80515099199994, 10.656825875000038],
              [-74.80503829299994, 10.656988856000055],
              [-74.80478425799993, 10.65740555900004 ],
              [-74.80492938999993, 10.657545059000086],
              [-74.80513380999992, 10.657526445000087],
              [-74.80515237499992, 10.657524756000043],
              [-74.80581529599993, 10.657314719000055],
              [-74.80620337199991, 10.657170307000058],
              [-74.80638190799993, 10.657167253000068],
              [-74.80658605299993, 10.65716376100005 ],
              [-74.8067272749999 , 10.657172462000062],
              [-74.80673567999992, 10.657168984000066],
              [-74.80687551799991, 10.657111101000055],
              [-74.80707264199992, 10.657140015000039],
              [-74.80729322199994, 10.657184697000048],
              [-74.80749042399992, 10.657241867000039],
              [-74.80756220699993, 10.65726651600005 ],
              [-74.8076407659999 , 10.657293492000065],
              [-74.80782660999995, 10.65737893100004 ],
              [-74.80804683799994, 10.657392543000071],
              [-74.80808999899995, 10.657388659000048],
              [-74.80827269399992, 10.657372222000049],
              [-74.8093617319999 , 10.657048746000044],
              [-74.80967543699995, 10.657012459000043],
              [-74.80986022499991, 10.656991084000083],
              [-74.81004025199991, 10.657030206000059],
              [-74.81022028599995, 10.657076110000048],
              [-74.81036546399991, 10.657115311000041],
              [-74.81066419299992, 10.657124494000072],
              [-74.81070705799993, 10.65712581300005 ],
              [-74.81091552099991, 10.657119668000064],
              [-74.81116506899991, 10.65709647600005 ],
              [-74.81128436799992, 10.657129406000081],
              [-74.81138227199995, 10.657156429000054],
              [-74.81148435799992, 10.657330754000043],
              [-74.8115190449999 , 10.657502432000058],
              [-74.81151381199993, 10.657703585000093],
              [-74.81156702399994, 10.658448107000083],
              [-74.81159070199993, 10.658791570000062],
              [-74.81168375099992, 10.659003778000056],
              [-74.81180001999991, 10.659141354000042],
              [-74.81186218999994, 10.659177899000042],
              [-74.81203169499992, 10.659277541000051],
              [-74.81205338799992, 10.65929798400009 ],
              [-74.81216565199992, 10.659403778000069],
              [-74.81229333899995, 10.659530029000052],
              [-74.81235234499991, 10.659656773000052],
              [-74.81238634999994, 10.65972981300007 ],
              [-74.81245597799995, 10.659942087000047],
              [-74.81247285599994, 10.659990483000058],
              [-74.81252586799991, 10.660142491000045],
              [-74.81271732199991, 10.660188934000075],
              [-74.81276163299992, 10.660162601000081],
              [-74.81300080699992, 10.660020467000038],
              [-74.8132209389999 , 10.659762871000055],
              [-74.81334222999993, 10.659607212000083],
              [-74.81374166499995, 10.659446370000069],
              [-74.81403150699992, 10.659313479000048],
              [-74.81420514199993, 10.659313076000046],
              [-74.81422355199993, 10.659315722000088],
              [-74.81436742299991, 10.659336407000069],
              [-74.81455311399992, 10.659358567000083],
              [-74.81477059399992, 10.659417936000068],
              [-74.81535914499995, 10.659631243000092],
              [-74.81531275599991, 10.65981552900007 ],
              [-74.8154004939999 , 10.660192735000066],
              [-74.81569134699993, 10.660714098000085],
              [-74.81620192499992, 10.661388598000087],
              [-74.81648676399993, 10.661783410000055],
              [-74.81676651299995, 10.661943376000067],
              [-74.81681736999991, 10.661972456000058],
              [-74.81699998999994, 10.662020053000049],
              [-74.81720000899992, 10.661945565000053],
              [-74.81726025899991, 10.661704734000068],
              [-74.81716380799992, 10.661326583000061],
              [-74.81715229399992, 10.661230595000063],
              [-74.8171410569999 , 10.661136919000057],
              [-74.81712032699994, 10.66096409700009 ],
              [-74.81722912299995, 10.660956661000057],
              [-74.81737205199994, 10.661038680000047],
              [-74.81740346599992, 10.661056706000068],
              [-74.81748359099993, 10.661192847000052],
              [-74.81752064199992, 10.661515656000063],
              [-74.81752114499994, 10.66172374200005 ],
              [-74.8175215089999 , 10.661874425000065],
              [-74.81752926599995, 10.66208249400006 ],
              [-74.81757144399995, 10.662245240000061],
              [-74.81761521899995, 10.662397685000087],
              [-74.81759775499995, 10.662612417000048],
              [-74.81750271999994, 10.662875929000052],
              [-74.8174107239999 , 10.663329267000051],
              [-74.81734209099994, 10.663551589000065],
              [-74.8174388079999 , 10.663675651000062],
              [-74.81823508499991, 10.66445308200008 ],
              [-74.81832592899991, 10.664541778000057],
              [-74.81835853699994, 10.664575214000081],
              [-74.81850046599993, 10.664720749000082],
              [-74.81865339299992, 10.664866455000038],
              [-74.81881515099991, 10.664912394000055],
              [-74.81897170199994, 10.664928966000048],
              [-74.81919471799995, 10.664909799000043],
              [-74.81925932099995, 10.66491342800009 ],
              [-74.81972513599993, 10.66493960300005 ],
              [-74.8199109599999 , 10.665013731000045],
              [-74.8199727999999 , 10.66505455500004 ],
              [-74.82013694299991, 10.665162915000053],
              [-74.82026489799995, 10.665397078000069],
              [-74.82030604999994, 10.665642184000092],
              [-74.82030030099992, 10.665860277000093],
              [-74.8202829729999 , 10.666015130000062],
              [-74.8201908559999 , 10.666187103000084],
              [-74.82004904199994, 10.666355808000048],
              [-74.81949099099995, 10.667059978000054],
              [-74.81935766299995, 10.667197025000064],
              [-74.81932032899994, 10.667346269000063],
              [-74.81938178299993, 10.667483983000068],
              [-74.81960802399993, 10.66773541300006 ],
              [-74.8198397619999 , 10.667900970000062],
              [-74.81997823899991, 10.667948503000048],
              [-74.82007186899995, 10.667980642000089],
              [-74.82050695899994, 10.668145709000044],
              [-74.82139480899991, 10.668928913000059],
              [-74.8214519309999 , 10.668983593000064],
              [-74.82220726299994, 10.669706638000036],
              [-74.8223914109999 , 10.669817506000072],
              [-74.82241663299993, 10.669832691000067],
              [-74.82260182999994, 10.669883094000056],
              [-74.82286258199991, 10.669882476000055],
              [-74.82309449299993, 10.669767789000048],
              [-74.82315812699994, 10.669629779000047],
              [-74.82341754499993, 10.668735352000056],
              [-74.82339934499993, 10.668535387000077],
              [-74.8233238599999 , 10.668383032000065],
              [-74.82320756399992, 10.668129061000059],
              [-74.82318981899994, 10.667882769000073],
              [-74.8232179389999 , 10.667469132000065],
              [-74.82329043999994, 10.667337879000058],
              [-74.82344983099995, 10.667343153000047],
              [-74.82349444199991, 10.667391517000056],
              [-74.82356006999993, 10.667462666000063],
              [-74.8238618069999 , 10.667637648000039],
              [-74.82402998999993, 10.667619736000063],
              [-74.82416914799995, 10.667648220000046],
              [-74.82426920199993, 10.667799676000072],
              [-74.8243610099999 , 10.667971481000052],
              [-74.8243958189999 , 10.668189483000049],
              [-74.82469959399992, 10.669540193000046],
              [-74.82478075599994, 10.669793110000057],
              [-74.82496096799991, 10.669903980000072],
              [-74.82516272899994, 10.669917182000063],
              [-74.82516920599994, 10.669917607000059],
              [-74.82536643999993, 10.669871930000056],
              [-74.82556884799993, 10.66972228800006 ],
              [-74.82578616899991, 10.669483910000054],
              [-74.82596858099993, 10.669446737000044],
              [-74.82615997599993, 10.669463221000058],
              [-74.82627547899995, 10.66947749500008 ],
              [-74.8263393869999 , 10.669485393000059],
              [-74.82695405099992, 10.669735889000037],
              [-74.82716295099993, 10.669787369000062],
              [-74.8273367509999 , 10.669729878000055],
              [-74.82753663299991, 10.669714704000057],
              [-74.8276846949999 , 10.669763510000053],
              [-74.82781796599994, 10.66983775600005 ],
              [-74.82807902499991, 10.670077813000091],
              [-74.82841584899995, 10.670461188000047],
              [-74.82850775299994, 10.67059658100004 ],
              [-74.82852075499994, 10.67061573500007 ],
              [-74.82857692099992, 10.67071450700007 ],
              [-74.82864267699995, 10.670830143000046],
              [-74.82890365499992, 10.671152675000087],
              [-74.82906624999993, 10.671301437000068],
              [-74.82929864499994, 10.671496360000049],
              [-74.82954233099991, 10.67163927300004 ],
              [-74.82987858699994, 10.671793262000051],
              [-74.83033663899994, 10.672003453000059],
              [-74.83056893399993, 10.672157690000063],
              [-74.83076642799995, 10.67232897200006 ],
              [-74.83099839199991, 10.672581516000037],
              [-74.83127138999993, 10.673021549000055],
              [-74.83145736599994, 10.673153296000066],
              [-74.83159632399992, 10.673221889000047],
              [-74.83188344699994, 10.67325227100008 ],
              [-74.8320367359999 , 10.67322082000004 ],
              [-74.83217595399992, 10.673160022000047],
              [-74.8323989619999 , 10.673022203000073],
              [-74.83250021999993, 10.672858099000052],
              [-74.83258659499995, 10.672674835000066],
              [-74.83290805699994, 10.67239608500006 ],
              [-74.83324653999995, 10.672177742000088],
              [-74.83345506499995, 10.672082880000062],
              [-74.8334627719999 , 10.672079115000088],
              [-74.8337734189999 , 10.67192729900006 ],
              [-74.8338470889999 , 10.67191515400009 ],
              [-74.83399952799994, 10.67189002200007 ],
              [-74.8342777869999 , 10.671923248000041],
              [-74.83454433799994, 10.671954799000048],
              [-74.83475896399995, 10.671893819000047],
              [-74.83479228899995, 10.67188064000004 ],
              [-74.8349846449999 , 10.67180456300008 ],
              [-74.83512367999992, 10.671666378000054],
              [-74.8351379689999 , 10.671650170000078],
              [-74.83539822299991, 10.671354956000073],
              [-74.83581822199994, 10.67119008900005 ],
              [-74.83600951799991, 10.671167021000088],
              [-74.83617182499995, 10.671201662000044],
              [-74.8364096009999 , 10.67126322100006 ],
              [-74.8370362199999 , 10.67172045500007 ],
              [-74.83735564299991, 10.671995373000073],
              [-74.83744860099995, 10.672165784000072],
              [-74.83755875199995, 10.672359869000047],
              [-74.83764066099991, 10.672674924000091],
              [-74.83760021599994, 10.67295073200006 ],
              [-74.83748488099991, 10.673197347000041],
              [-74.83719619899995, 10.673793552000063],
              [-74.83688378399995, 10.674264376000053],
              [-74.83685985999995, 10.674292363000063],
              [-74.83662920299992, 10.674562187000049],
              [-74.83653129499993, 10.674700273000042],
              [-74.8365156019999 , 10.674806214000057],
              [-74.83649646799995, 10.67493538900004 ],
              [-74.83652611699995, 10.675141544000041],
              [-74.83667984099992, 10.67528410600005 ],
              [-74.83688864499993, 10.675292631000048],
              [-74.8370943569999 , 10.675323769000045],
              [-74.83730870599993, 10.675380872000062],
              [-74.83754071099992, 10.675417020000054],
              [-74.83776713499992, 10.675505176000058],
              [-74.83790623899995, 10.675625739000054],
              [-74.8378661289999 , 10.67580324700009 ],
              [-74.8378316429999 , 10.675946829000054],
              [-74.83778515499995, 10.676084801000059],
              [-74.83774743399994, 10.676177027000051],
              [-74.83771020799992, 10.67626804300005 ],
              [-74.83759514299993, 10.676623126000038],
              [-74.83751413199991, 10.67689226300007 ],
              [-74.83751416599995, 10.676905970000064],
              [-74.83751460699995, 10.67708209500006 ],
              [-74.83758412999993, 10.677242374000059],
              [-74.8377120859999 , 10.677471444000048],
              [-74.8377820799999 , 10.677596704000052],
              [-74.8377705929999 , 10.677797296000051],
              [-74.8377390519999 , 10.677975343000071],
              [-74.83778904499991, 10.67820686500005 ],
              [-74.83794849499992, 10.678353363000042],
              [-74.83814522599994, 10.67833254900006 ],
              [-74.8383687349999 , 10.678283410000063],
              [-74.83862354299993, 10.678299735000053],
              [-74.83880311599995, 10.678385173000038],
              [-74.8389252309999 , 10.678563408000059],
              [-74.83895472499995, 10.678705708000052],
              [-74.83894026199994, 10.678861116000064],
              [-74.83879265999991, 10.678998767000053],
              [-74.8385785559999 , 10.679038841000079],
              [-74.8382710969999 , 10.67898309900005 ],
              [-74.83817512199994, 10.678984708000087],
              [-74.83787697199995, 10.678989711000042],
              [-74.83748870199992, 10.679052819000049],
              [-74.83728614099994, 10.679144834000056],
              [-74.83707775799991, 10.679415404000054],
              [-74.83698427599995, 10.679670446000046],
              [-74.83696541499995, 10.67972190200004 ],
              [-74.83705839999993, 10.679899077000073],
              [-74.83727590899991, 10.680073692000065],
              [-74.83743562799992, 10.680213423000055],
              [-74.83746489299995, 10.680379450000089],
              [-74.83742157099994, 10.68053210100004 ],
              [-74.83732518699992, 10.680918863000045],
              [-74.83722024899993, 10.681339955000055],
              [-74.83725508599991, 10.681568685000059],
              [-74.83738875499995, 10.681797737000068],
              [-74.83742969999992, 10.681952445000093],
              [-74.83744160899994, 10.682147902000054],
              [-74.83747986199995, 10.682365318000052],
              [-74.83752140399992, 10.682479330000092],
              [-74.83757591499995, 10.682628931000067],
              [-74.83774395799992, 10.682670890000054],
              [-74.83794655499992, 10.682593         ],
              [-74.83823068299995, 10.682461226000044],
              [-74.8383812049999 , 10.682345592000047],
              [-74.83850828999994, 10.682225507000055],
              [-74.83865892299991, 10.682156211000063],
              [-74.83884749999993, 10.68218739200006 ],
              [-74.8389723279999 , 10.682310244000064],
              [-74.83905329799995, 10.682476153000039],
              [-74.8390992009999 , 10.682545476000087],
              [-74.83921614399992, 10.68272208600007 ],
              [-74.83921973899993, 10.682890826000062],
              [-74.83922261999993, 10.683026031000054],
              [-74.8392402899999 , 10.683238421000055],
              [-74.83929283299994, 10.68346088800007 ],
              [-74.83929317099995, 10.683595523000065],
              [-74.83929326399993, 10.683632645000046],
              [-74.83929277099992, 10.683635026000047],
              [-74.83925884099995, 10.683798841000055],
              [-74.83925952399994, 10.684298281000054],
              [-74.83925970899992, 10.684493860000089],
              [-74.83925987099991, 10.684664376000057],
              [-74.83937923299993, 10.68488159900005 ],
              [-74.83955008099991, 10.684976005000067],
              [-74.83955027199994, 10.684976111000083],
              [-74.83968357299995, 10.68505882900007 ],
              [-74.83987556999995, 10.685310340000058],
              [-74.83996850099993, 10.685464912000043],
              [-74.84005022999992, 10.685711039000068],
              [-74.8399751309999 , 10.685941179000054],
              [-74.83984775899995, 10.686061835000089],
              [-74.8396738159999 , 10.686065085000052],
              [-74.8394940259999 , 10.68600790000005 ],
              [-74.83926502999992, 10.68592540800006 ],
              [-74.83903309399994, 10.685802815000045],
              [-74.83845552299994, 10.685325126000066],
              [-74.83822334999991, 10.685228520000067],
              [-74.83803816299991, 10.685182644000065],
              [-74.8378062829999 , 10.685197905000052],
              [-74.83780555499993, 10.685198270000058],
              [-74.83758891799994, 10.685306916000059],
              [-74.83739780699995, 10.685407951000059],
              [-74.83719085599995, 10.685511571000063],
              [-74.83711127499993, 10.685729563000052],
              [-74.83717230999991, 10.685911309000062],
              [-74.8371757349999 , 10.685921507000046],
              [-74.83737883599991, 10.686044164000066],
              [-74.83757300899993, 10.68613804000006 ],
              [-74.83770913699993, 10.686211203000084],
              [-74.83733633299994, 10.686411085000088],
              [-74.8373675979999 , 10.68645043500004 ],
              [-74.8373677969999 , 10.686450686000057],
              [-74.83729709099993, 10.686734067000089],
              [-74.8367324859999 , 10.688997013000062],
              [-74.8367192099999 , 10.689007934000074],
              [-74.8364553959999 , 10.689224955000043],
              [-74.83635869599993, 10.689304501000038],
              [-74.8359551229999 , 10.689636490000055],
              [-74.83552448299992, 10.690099597000085],
              [-74.83513805899992, 10.690515152000046],
              [-74.8337929029999 , 10.68965501300005 ],
              [-74.83229646899991, 10.692793436000045],
              [-74.8315840429999 , 10.692542780000053],
              [-74.83108646399995, 10.69227832000007 ],
              [-74.83084461999994, 10.692239052000048],
              [-74.83071375399993, 10.69223074200005 ],
              [-74.83064313299991, 10.692226258000062],
              [-74.83020442299994, 10.694047092000062],
              [-74.83019995099994, 10.694046411000045],
              [-74.82840406999992, 10.693772486000057],
              [-74.82829324599993, 10.694700554000065],
              [-74.82818089999995, 10.695641396000042],
              [-74.82818087399994, 10.695641615000056],
              [-74.82818035799994, 10.695645934000083],
              [-74.8284480129999 , 10.695692542000074],
              [-74.82855656999993, 10.69571144400004 ],
              [-74.82855697699995, 10.695714044000056],
              [-74.82860888899995, 10.696042416000068],
              [-74.82861114499991, 10.696056672000054],
              [-74.82853641299994, 10.696207967000078],
              [-74.82853639199993, 10.696208012000056],
              [-74.82783602899991, 10.697625937000055],
              [-74.8271325739999 , 10.697507724000047],
              [-74.82705673499993, 10.697494977000076],
              [-74.8270434929999 , 10.697490649000088],
              [-74.82592765999993, 10.697125765000067],
              [-74.82592597299993, 10.697133048000069],
              [-74.82565364499993, 10.698309056000085],
              [-74.8256536369999 , 10.698309092000045],
              [-74.82563593999993, 10.698385516000087],
              [-74.82563528199995, 10.698388357000056],
              [-74.82563488999995, 10.698388390000048],
              [-74.82561405099995, 10.698390121000045],
              [-74.82561400499992, 10.69839012600005 ],
              [-74.82481017499993, 10.69845696200008 ],
              [-74.82431934699991, 10.698497772000053],
              [-74.82431860299994, 10.698503918000085],
              [-74.82429397899995, 10.698707398000067],
              [-74.82410871399992, 10.700238359000082],
              [-74.82409535699992, 10.700349584000037],
              [-74.82409036699994, 10.700391141000068],
              [-74.82405606399993, 10.700676826000063],
              [-74.82376374799992, 10.701890607000053],
              [-74.8237103749999 , 10.702112226000054],
              [-74.82104969799991, 10.701547404000053],
              [-74.81695102099991, 10.700471580000055],
              [-74.81693725899993, 10.700467968000055],
              [-74.81693727499993, 10.700467918000072],
              [-74.81769723399992, 10.698168189000057],
              [-74.81765089099991, 10.698158893000084],
              [-74.81690605199992, 10.698009453000054],
              [-74.81690601399993, 10.698009445000082],
              [-74.81468730899991, 10.697564293000084],
              [-74.81467124399995, 10.69764165500004 ],
              [-74.8143402419999 , 10.69923563000009 ],
              [-74.8143402149999 , 10.699235762000058],
              [-74.81428902199991, 10.69948228900006 ],
              [-74.81419608499994, 10.699929845000042],
              [-74.81415232899991, 10.700121265000064],
              [-74.8140897589999 , 10.70039500200005 ],
              [-74.81375694299993, 10.701617834000047],
              [-74.81372567499994, 10.701605801000085],
              [-74.8127621399999 , 10.701234970000087],
              [-74.81230718799992, 10.70194004700005 ],
              [-74.81218714599993, 10.702285689000064],
              [-74.81228231299991, 10.702763656000059],
              [-74.81229185099994, 10.702994706000084],
              [-74.81229656999994, 10.703108984000039],
              [-74.8122776379999 , 10.703220250000072],
              [-74.81220386899992, 10.703653809000059],
              [-74.81205863999992, 10.704107585000088],
              [-74.81200391199991, 10.704278584000065],
              [-74.81197142199994, 10.704340142000092],
              [-74.81172314199995, 10.704810571000053],
              [-74.81167068099995, 10.70487190700004 ],
              [-74.81149545699992, 10.705076770000062],
              [-74.81141062499995, 10.705161067000063],
              [-74.81120955199992, 10.705360871000039],
              [-74.81105328699994, 10.705516149000061],
              [-74.81068552399995, 10.705820716000062],
              [-74.81065116199994, 10.705849173000047],
              [-74.8104975519999 , 10.705941905000088],
              [-74.81043762299993, 10.705978083000048],
              [-74.8104350189999 , 10.705979655000078],
              [-74.81027563799995, 10.706075870000063],
              [-74.81021536299994, 10.706091708000088],
              [-74.81002774399991, 10.706141008000088],
              [-74.81002060999992, 10.706142882000051],
              [-74.8099914469999 , 10.706142593000038],
              [-74.80678365499995, 10.70611062000006 ],
              [-74.8067527469999 , 10.70615158000004 ],
              [-74.80675078299993, 10.706154181000045],
              [-74.80670332299991, 10.70621707500004 ],
              [-74.80621983999993, 10.70623148900006 ],
              [-74.80621929999995, 10.70623584100008 ],
              [-74.80619348599993, 10.706444078000061],
              [-74.80619213599994, 10.706444198000042],
              [-74.80491509099994, 10.706556552000052],
              [-74.80491505499992, 10.706556554000088],
              [-74.8046425249999 , 10.706580530000053],
              [-74.8045381739999 , 10.70656530000008 ],
              [-74.80356766699992, 10.706423637000057],
              [-74.80269411599994, 10.706199857000058],
              [-74.80175319099993, 10.705883251000046],
              [-74.80169652799992, 10.705867049000062],
              [-74.80129439899991, 10.70575206600006 ],
              [-74.8007854999999 , 10.705606551000074],
              [-74.80077811199993, 10.705636523000067],
              [-74.80034649699991, 10.707387508000068],
              [-74.79994756299993, 10.709075392000045],
              [-74.7998887999999 , 10.70944008400005 ],
              [-74.79980395299992, 10.709966670000085],
              [-74.79977774999992, 10.710129290000054],
              [-74.7997740159999 , 10.710152461000064],
              [-74.7992962539999 , 10.710153568000067],
              [-74.79927258599992, 10.710153622000064],
              [-74.79912578999995, 10.711037428000054],
              [-74.7990842449999 , 10.711287551000055],
              [-74.7987319209999 , 10.711172311000041],
              [-74.79829566899991, 10.711029619000044],
              [-74.7982897839999 , 10.711049523000042],
              [-74.79775083499993, 10.712872807000053],
              [-74.79773285499994, 10.712999366000076],
              [-74.79772734799991, 10.713038160000053],
              [-74.79774995199995, 10.713163138000084],
              [-74.79779992599993, 10.71343943900007 ],
              [-74.79780040199995, 10.713641075000055],
              [-74.79780199399994, 10.714313168000047],
              [-74.79780351399995, 10.714955787000065],
              [-74.7978036689999 , 10.715021600000057],
              [-74.7977805129999 , 10.715114120000067],
              [-74.79763808599995, 10.715683185000046],
              [-74.7973527769999 , 10.71620336300009 ],
              [-74.79695319299992, 10.716153311000085],
              [-74.7967872129999 , 10.716132520000087],
              [-74.79661233299993, 10.716110614000058],
              [-74.79634671799994, 10.716129558000091],
              [-74.79562662299992, 10.716180912000084],
              [-74.79558574099991, 10.716183828000055],
              [-74.7946670579999 , 10.720752117000075],
              [-74.79457365499991, 10.721216570000081],
              [-74.79412240599993, 10.72346045100005 ],
              [-74.79252462999995, 10.726185914000041],
              [-74.7925245589999 , 10.726186039000083],
              [-74.7920916459999 , 10.726924489000055],
              [-74.79169647799995, 10.727598554000053],
              [-74.7916714079999 , 10.727679795000086],
              [-74.79166801899993, 10.727690776000088],
              [-74.79163583899992, 10.72779505300008 ],
              [-74.79160277799991, 10.727902198000038],
              [-74.79160167999993, 10.727905761000045],
              [-74.79158459799993, 10.727903298000058],
              [-74.79070208599995, 10.727776069000072],
              [-74.79068122399991, 10.727773063000086],
              [-74.7906811979999 , 10.727773059000071],
              [-74.7903117699999 , 10.727719798000066],
              [-74.7902651739999 , 10.728215811000041],
              [-74.79009962399994, 10.728901008000037],
              [-74.78960213599993, 10.730602390000058],
              [-74.78946085299992, 10.731452833000048],
              [-74.78934361099994, 10.732374064000055],
              [-74.78915329499995, 10.732681489000072],
              [-74.78800674999991, 10.73254242300004 ],
              [-74.7880055839999 , 10.73204274200009 ],
              [-74.78800558499995, 10.732042687000046],
              [-74.78800473299992, 10.731678037000052],
              [-74.78800465599994, 10.731645077000053],
              [-74.78482793199993, 10.731321713000057],
              [-74.78482886099994, 10.731334189000052],
              [-74.7848525789999 , 10.731652259000043],
              [-74.78484601999992, 10.73167804600007 ],
              [-74.78447396599995, 10.73314083300005 ],
              [-74.78323140899994, 10.732789438000054],
              [-74.78208448399994, 10.732485048000058],
              [-74.78170230399991, 10.732438684000044],
              [-74.78092403399995, 10.732333088000075],
              [-74.78084475199995, 10.73232233200008 ],
              [-74.78067515999993, 10.732299320000038],
              [-74.78003041599993, 10.73230078000006 ],
              [-74.77984010499995, 10.732332588000077],
              [-74.77954866499994, 10.732381296000085],
              [-74.77945752599993, 10.732396528000038],
              [-74.7794551369999 , 10.732396613000049],
              [-74.77874119799992, 10.73242175900009 ],
              [-74.77821579399995, 10.732399327000053],
              [-74.77812293699992, 10.733556647000057],
              [-74.7782878029999 , 10.734316696000064],
              [-74.77829189099992, 10.734335548000047],
              [-74.77786686499991, 10.73491741500004 ],
              [-74.77786684799992, 10.73491744100005 ],
              [-74.77722067799994, 10.735802058000047],
              [-74.77667318899995, 10.73564998900008 ],
              [-74.77581794299994, 10.735412437000036],
              [-74.77579268499994, 10.735405423000088],
              [-74.77578697299992, 10.735403834000067],
              [-74.77276270199991, 10.73586619200006 ],
              [-74.77108428199995, 10.73612279300005 ],
              [-74.77107714499994, 10.736140314000068],
              [-74.7708859199999 , 10.736609791000092],
              [-74.77072807699994, 10.736997325000061],
              [-74.77067821499992, 10.73699636400005 ],
              [-74.76962954899994, 10.73697615900005 ],
              [-74.76950619799993, 10.736973722000073],
              [-74.7694492299999 , 10.73697259700009 ],
              [-74.7675056299999 , 10.736934208000037],
              [-74.76748025199993, 10.73693370500007 ],
              [-74.76662623499993, 10.73946545800004 ],
              [-74.7665077069999 , 10.739816836000045],
              [-74.7663500079999 , 10.74031077400008 ],
              [-74.76626171799995, 10.74058731100007 ],
              [-74.76617573899995, 10.740856615000041],
              [-74.76616953099995, 10.740868396000053],
              [-74.7659254859999 , 10.741331638000077],
              [-74.76591417899994, 10.741353098000047],
              [-74.76570054099994, 10.741920322000055],
              [-74.76567365199992, 10.741964864000067],
              [-74.76527229899995, 10.742629707000049],
              [-74.76526826599991, 10.74276251300006 ],
              [-74.7652644659999 , 10.742887697000072],
              [-74.76526446499992, 10.742887727000038],
              [-74.76525007499993, 10.743361805000063],
              [-74.76482097399992, 10.743693362000045],
              [-74.76317454799994, 10.742959779000046],
              [-74.7625562209999 , 10.742775177000055],
              [-74.76252793099991, 10.74276691600005 ],
              [-74.76233299599994, 10.742709995000041],
              [-74.76218837099992, 10.742667764000089],
              [-74.76203369299992, 10.742622598000082],
              [-74.7620318619999 , 10.74261856000004 ],
              [-74.76199430899993, 10.742535710000084],
              [-74.7619744889999 , 10.742491984000083],
              [-74.76192483499995, 10.742477258000065],
              [-74.76187341199994, 10.742462008000075],
              [-74.76166207799992, 10.742399334000083],
              [-74.7615241179999 , 10.742358420000073],
              [-74.7614616329999 , 10.74233988900005 ],
              [-74.76145494899993, 10.742337629000076],
              [-74.76135562899992, 10.742304011000044],
              [-74.76123796099995, 10.742264182000042],
              [-74.76100727099993, 10.742186096000069],
              [-74.76097005799994, 10.742173500000092],
              [-74.76095123599993, 10.742167129000052],
              [-74.76094839299992, 10.74216616700005 ],
              [-74.7609422569999 , 10.742164090000074],
              [-74.76094097799995, 10.742163657000049],
              [-74.7609137419999 , 10.742154438000057],
              [-74.76090761799992, 10.742152365000038],
              [-74.76078728199991, 10.742111633000093],
              [-74.76058166199994, 10.74204242500008 ],
              [-74.76055125499994, 10.742032190000089],
              [-74.7605454699999 , 10.742030143000079],
              [-74.76051915499994, 10.742020834000073],
              [-74.7604933049999 , 10.742074910000042],
              [-74.76047880399994, 10.742102353000064],
              [-74.7604661389999 , 10.742126322000047],
              [-74.76044849199991, 10.742159718000039],
              [-74.7603938229999 , 10.742263181000055],
              [-74.76035961399992, 10.742324667000048],
              [-74.7603201579999 , 10.74239444400007 ],
              [-74.76031994399995, 10.74239483100007 ],
              [-74.76029468899992, 10.742431707000037],
              [-74.76029117899992, 10.742436833000056],
              [-74.7602560869999 , 10.742488073000061],
              [-74.7602554309999 , 10.742489031000048],
              [-74.7602527279999 , 10.742492978000087],
              [-74.76025092099991, 10.742495483000084],
              [-74.76020981699992, 10.742552470000078],
              [-74.76020776399992, 10.74255531600005 ],
              [-74.7601648079999 , 10.742614871000058],
              [-74.76012570499995, 10.742745007000053],
              [-74.76007348799993, 10.742888482000069],
              [-74.76006030099995, 10.742904803000044],
              [-74.7600558979999 , 10.742910252000058],
              [-74.7600547749999 , 10.742911642000081],
              [-74.76005416399994, 10.742912339000043],
              [-74.76004847299993, 10.742918831000054],
              [-74.76002447499991, 10.742946209000081],
              [-74.76001760799994, 10.742954043000054],
              [-74.76001679199993, 10.742954974000043],
              [-74.76001374299994, 10.742963215000088],
              [-74.76001279199994, 10.74296578700006 ],
              [-74.75998336499993, 10.743045330000086],
              [-74.75986450599993, 10.743366620000074],
              [-74.75984746899991, 10.743408645000045],
              [-74.75982591899992, 10.74346180200007 ],
              [-74.75982514899994, 10.743463702000042],
              [-74.75982318699994, 10.743468541000084],
              [-74.75982312499991, 10.743468695000047],
              [-74.75981362599993, 10.743492126000092],
              [-74.75980397599994, 10.74355988700006 ],
              [-74.7597825599999 , 10.743572974000074],
              [-74.75976132399995, 10.74358512300006 ],
              [-74.7597449299999 , 10.743607417000078],
              [-74.75974395399993, 10.743608744000085],
              [-74.75973531299991, 10.743620495000073],
              [-74.75972959199993, 10.743628275000049],
              [-74.75972786599993, 10.743630622000069],
              [-74.75969302899995, 10.743727669000066],
              [-74.75968883799993, 10.743739343000072],
              [-74.75964600799995, 10.743800733000057],
              [-74.75960966199995, 10.743828525000083],
              [-74.7595955939999 , 10.743917177000071],
              [-74.75955791599995, 10.744050274000074],
              [-74.75955643399993, 10.744055510000067],
              [-74.75953790199992, 10.744135163000067],
              [-74.75952595299992, 10.744186524000042],
              [-74.7595074379999 , 10.744329002000086],
              [-74.7594884639999 , 10.744462074000069],
              [-74.7594668079999 , 10.744588486000055],
              [-74.75945626599992, 10.744650025000055],
              [-74.75942270799993, 10.74480617900008 ],
              [-74.7593973299999 , 10.74492427000007 ],
              [-74.75939248299994, 10.744923897000092],
              [-74.7593922339999 , 10.744923877000076],
              [-74.7593805219999 , 10.744954475000043],
              [-74.75935406299993, 10.745023598000046],
              [-74.7593442729999 , 10.745076594000068],
              [-74.75933136399993, 10.745146480000074],
              [-74.75931228299993, 10.745182471000078],
              [-74.75929649799991, 10.745212245000062],
              [-74.75926440099994, 10.745272785000054],
              [-74.75920121899992, 10.745395175000056],
              [-74.75918440299995, 10.74542804300006 ],
              [-74.75915930999992, 10.745477091000055],
              [-74.7591279959999 , 10.74555358400005 ],
              [-74.75912250199991, 10.745567004000065],
              [-74.7591158699999 , 10.745577327000092],
              [-74.75908919299991, 10.74561884600007 ],
              [-74.75907010299994, 10.745648559000074],
              [-74.75902727199991, 10.74573230100009 ],
              [-74.75901986799994, 10.74574677600009 ],
              [-74.75901867899995, 10.745749101000058],
              [-74.75901677699994, 10.745752821000053],
              [-74.75899668699992, 10.745792101000063],
              [-74.75899056499992, 10.745804072000055],
              [-74.75898678599992, 10.74581235100004 ],
              [-74.75896994699991, 10.74584923100008 ],
              [-74.75891672299991, 10.745965802000057],
              [-74.75891049199993, 10.74597944900006 ],
              [-74.75888722099995, 10.746030416000053],
              [-74.75888581299995, 10.746030386000086],
              [-74.75886799299991, 10.746065127000065],
              [-74.75885397799993, 10.746092450000049],
              [-74.75869151199993, 10.746343824000064],
              [-74.75858946999995, 10.746478207000052],
              [-74.7585862059999 , 10.746482506000063],
              [-74.75858322599993, 10.746486430000061],
              [-74.75856924699991, 10.746518696000066],
              [-74.75855983299994, 10.746540426000081],
              [-74.75854838399994, 10.746566854000037],
              [-74.7585141049999 , 10.74664597900005 ],
              [-74.75850181999994, 10.746666989000062],
              [-74.75848190199991, 10.746701055000074],
              [-74.75845799599995, 10.746741942000085],
              [-74.75845761799991, 10.74674311800004 ],
              [-74.75843779799993, 10.746804800000064],
              [-74.75843070199994, 10.74682688200005 ],
              [-74.75839530099995, 10.746922604000076],
              [-74.75839506199992, 10.746923311000046],
              [-74.75836984499995, 10.746917803000088],
              [-74.75828769399993, 10.746899860000042],
              [-74.7582767639999 , 10.746897474000093],
              [-74.75827679899993, 10.746897090000061],
              [-74.75821057999991, 10.74688502500004 ],
              [-74.75818819999995, 10.747028378000039],
              [-74.75818700899993, 10.74703393100009 ],
              [-74.7581506219999 , 10.747203597000066],
              [-74.75810055399995, 10.747437055000091],
              [-74.75802449299994, 10.747791709000069],
              [-74.7580219919999 , 10.747801166000045],
              [-74.75800183399991, 10.74787740100004 ],
              [-74.75799109399992, 10.747938701000066],
              [-74.75798163899992, 10.747992673000056],
              [-74.75797940099994, 10.748005448000072],
              [-74.7579664029999 , 10.748079636000057],
              [-74.75796518299995, 10.748084720000065],
              [-74.75788994899995, 10.748332533000053],
              [-74.75786640299992, 10.74841008900006 ],
              [-74.75781572599993, 10.74857701500008 ],
              [-74.75780497599993, 10.74861242500009 ],
              [-74.75780385599995, 10.748616113000082],
              [-74.75779330299991, 10.74864841100009 ],
              [-74.75779153899992, 10.748653811000054],
              [-74.75766988799995, 10.748647102000064],
              [-74.75742063899992, 10.748570959000062],
              [-74.75726327599995, 10.748507310000036],
              [-74.7572141409999 , 10.748487436000062],
              [-74.75709619999992, 10.748439732000065],
              [-74.75689296099995, 10.748350834000064],
              [-74.75680731099993, 10.748313370000062],
              [-74.75646516699993, 10.74816371500009 ],
              [-74.75627195499993, 10.748032368000054],
              [-74.7561663859999 , 10.747972406000088],
              [-74.75601469199995, 10.747899982000092],
              [-74.75591594799994, 10.747852838000085],
              [-74.7557802849999 , 10.747783237000078],
              [-74.75565184699991, 10.74771734300009 ],
              [-74.7556128729999 , 10.747697348000088],
              [-74.75557389999994, 10.747677353000086],
              [-74.7552068679999 , 10.747510196000064],
              [-74.75509705899992, 10.74750012600009 ],
              [-74.75510084199993, 10.747455159000083],
              [-74.7551046239999 , 10.747410193000064],
              [-74.75511283499992, 10.747312579000038],
              [-74.75511283899993, 10.747312526000087],
              [-74.7551141699999 , 10.747296705000053],
              [-74.7551212809999 , 10.74721216200004 ],
              [-74.75513142599993, 10.747091548000071],
              [-74.7551392439999 , 10.746998602000076],
              [-74.7551703019999 , 10.74663773800006 ],
              [-74.75506644699993, 10.74662468400004 ],
              [-74.75502900599992, 10.746627679000085],
              [-74.75500760999995, 10.746629391000056],
              [-74.7549779869999 , 10.74663176100006 ],
              [-74.75494747399995, 10.746634202000052],
              [-74.75493221199991, 10.746641994000072],
              [-74.75475478099992, 10.746732575000067],
              [-74.75469858199995, 10.746761265000089],
              [-74.75450557099992, 10.746876641000085],
              [-74.75444302799991, 10.74691402700006 ],
              [-74.75437235499993, 10.746959677000063],
              [-74.75414464399995, 10.74710676300009 ],
              [-74.75394756499992, 10.747231880000072],
              [-74.75380057399991, 10.747325198000055],
              [-74.75351932099994, 10.747495091000076],
              [-74.75337940899993, 10.74757646900008 ],
              [-74.75330286199994, 10.747607463000065],
              [-74.75330059599992, 10.747608381000077],
              [-74.75318479499992, 10.747655269000063],
              [-74.75316278799994, 10.747664180000072],
              [-74.75314669799991, 10.747670695000068],
              [-74.75301820699991, 10.747732085000052],
              [-74.75286253699994, 10.74778368300008 ],
              [-74.7527640799999 , 10.747816318000048],
              [-74.75273655599995, 10.747827623000092],
              [-74.75272623099994, 10.74783186400009 ],
              [-74.75271379599991, 10.747836971000083],
              [-74.7526549609999 , 10.74786113500005 ],
              [-74.75263327399995, 10.747870043000091],
              [-74.75263323899992, 10.747870057000057],
              [-74.75252423099994, 10.747914828000091],
              [-74.75232435599992, 10.747976218000076],
              [-74.75224332699992, 10.748001783000063],
              [-74.75209713099991, 10.748047909000093],
              [-74.75206741099993, 10.748057286000062],
              [-74.75205738099993, 10.748060451000072],
              [-74.75205582199993, 10.748051571000076],
              [-74.7520527129999 , 10.748051547000046],
              [-74.75205104899993, 10.74805153300008 ],
              [-74.7520084489999 , 10.74805118800009 ],
              [-74.75198159099995, 10.748050970000065],
              [-74.7519738549999 , 10.74805090700005 ],
              [-74.75196120499993, 10.748050804000059],
              [-74.75192005899993, 10.748050471000056],
              [-74.75191270899995, 10.748050411000065],
              [-74.75189822599992, 10.748050294000052],
              [-74.75188232699992, 10.748050165000052],
              [-74.75186868599991, 10.748050054000089],
              [-74.75180661699994, 10.748049550000076],
              [-74.75177944599994, 10.748049329000082],
              [-74.75177767999992, 10.74804931500006 ],
              [-74.75168830599995, 10.748048589000064],
              [-74.75162124299993, 10.748048045000075],
              [-74.75151795399995, 10.748045904000037],
              [-74.75129813399991, 10.748041349000061],
              [-74.75116200699995, 10.748038528000052],
              [-74.75101731599995, 10.748036532000071],
              [-74.75073866699995, 10.74803268900007 ],
              [-74.75047196299994, 10.748029010000039],
              [-74.75045268799994, 10.748028693000037],
              [-74.75034006699991, 10.748026844000037],
              [-74.7500921809999 , 10.74802277300006 ],
              [-74.74974741699992, 10.74801711200007 ],
              [-74.74921084899995, 10.74799450800009 ],
              [-74.74917932799991, 10.747992632000091],
              [-74.74909375899995, 10.747987538000075],
              [-74.74907031999993, 10.747986143000048],
              [-74.74901097399993, 10.747982610000065],
              [-74.74885392899995, 10.74794453900006 ],
              [-74.7488131369999 , 10.74792956300007 ],
              [-74.74874971199995, 10.747874425000077],
              [-74.74866483799991, 10.747844851000082],
              [-74.74857596999993, 10.747813884000038],
              [-74.74846568499993, 10.747761531000037],
              [-74.74778722199994, 10.747439456000052],
              [-74.74728605199994, 10.747307186000057],
              [-74.74665028499993, 10.74713939500009 ],
              [-74.74637358099994, 10.747066367000059],
              [-74.74585104499994, 10.746832508000068],
              [-74.74558722099994, 10.746714435000058],
              [-74.74537236399993, 10.746740403000047],
              [-74.74520054499993, 10.746791772000051],
              [-74.74506314899992, 10.746860078000054],
              [-74.74497742699992, 10.746970772000054],
              [-74.74484010099991, 10.74707307500006 ],
              [-74.74462547099995, 10.747201051000047],
              [-74.74447089999995, 10.747239612000044],
              [-74.74441926399993, 10.747252494000065],
              [-74.74410137599995, 10.747338183000068],
              [-74.7438349549999 , 10.747372761000065],
              [-74.74366326699993, 10.747483638000062],
              [-74.74354620799994, 10.747608203000084],
              [-74.74354319399993, 10.747611411000037],
              [-74.7433886039999 , 10.747679745000084],
              [-74.7432242349999 , 10.747789821000083],
              [-74.74303439099992, 10.747953230000064],
              [-74.74291914999992, 10.748052425000083],
              [-74.7426932589999 , 10.748381566000091],
              [-74.74261446399993, 10.748496377000038],
              [-74.7425245039999 , 10.748558247000062],
              [-74.7423499539999 , 10.748678294000058],
              [-74.74214904799993, 10.748862478000092],
              [-74.74208558099991, 10.748920662000046],
              [-74.7418539649999 , 10.749143019000087],
              [-74.74147553899991, 10.749506318000044],
              [-74.74143716099991, 10.749524781000048],
              [-74.74084450499993, 10.74980991600006 ],
              [-74.74045755499992, 10.749911489000056],
              [-74.73990771799993, 10.750073863000068],
              [-74.73935071499994, 10.750265120000051],
              [-74.7393172159999 , 10.750276622000058],
              [-74.73929663299992, 10.750277450000056],
              [-74.73885094399992, 10.75029535200008 ],
              [-74.7387874499999 , 10.750297901000067],
              [-74.73875549399992, 10.750291270000048],
              [-74.73872969899992, 10.750285917000042]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08549",
      "properties": {"id": "08549", "iso": "PIO", "name": "PIOJÓ"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-75.12834372999993, 10.807521713000085],
              [-75.12727556999994, 10.807513450000044],
              [-75.12717980999992, 10.807536904000074],
              [-75.12710006299994, 10.807574774000045],
              [-75.12703654699993, 10.807604939000043],
              [-75.12694028499993, 10.807685248000041],
              [-75.12691508299991, 10.807706274000054],
              [-75.12684654599991, 10.807794726000054],
              [-75.1267778589999 , 10.807941286000073],
              [-75.12675148099993, 10.807997574000069],
              [-75.12659429699994, 10.807965187000093],
              [-75.12586327299994, 10.807814561000043],
              [-75.1256555949999 , 10.807906930000058],
              [-75.12490696899994, 10.808239903000072],
              [-75.1248943459999 , 10.808216471000037],
              [-75.12468025899994, 10.807819012000039],
              [-75.12453916499993, 10.807469019000052],
              [-75.12441527699991, 10.807161704000066],
              [-75.12425282799995, 10.806843658000048],
              [-75.12414894599993, 10.806666857000039],
              [-75.12407180799994, 10.80653557100004 ],
              [-75.12385936399994, 10.80621969300006 ],
              [-75.12359333199993, 10.80587016600009 ],
              [-75.12339736399991, 10.805664497000066],
              [-75.12305158799995, 10.805361046000087],
              [-75.1229312079999 , 10.805197750000048],
              [-75.12262344099992, 10.804701944000044],
              [-75.12236387299993, 10.804166923000082],
              [-75.12228521399993, 10.804004792000057],
              [-75.12223210999991, 10.803823897000086],
              [-75.12215677899991, 10.803283901000043],
              [-75.12213869399994, 10.802972486000044],
              [-75.12213469499994, 10.802441611000063],
              [-75.12210459199991, 10.801998690000062],
              [-75.1218829039999 , 10.801399230000072],
              [-75.12084976999995, 10.79860556400007 ],
              [-75.12059636099991, 10.797966228000064],
              [-75.12054347499992, 10.797886125000048],
              [-75.12045621699991, 10.79778411600006 ],
              [-75.12035501899993, 10.797695574000045],
              [-75.12024200299993, 10.797622343000057],
              [-75.12011950899995, 10.797565938000048],
              [-75.11896196899994, 10.797207564000075],
              [-75.11869759899992, 10.79712571500005 ],
              [-75.11796533699993, 10.796944777000078],
              [-75.11771091399993, 10.79688191200006 ],
              [-75.11736610499992, 10.796738656000059],
              [-75.11670153599994, 10.796462554000072],
              [-75.1161472739999 , 10.796232279000037],
              [-75.11613298999993, 10.796226344000047],
              [-75.1163672429999 , 10.795779740000057],
              [-75.11644715699993, 10.795508338000047],
              [-75.11654181099993, 10.79505589200005 ],
              [-75.11687312499993, 10.794499520000045],
              [-75.11719147699995, 10.79405673000008 ],
              [-75.11725146799995, 10.793973290000054],
              [-75.11709980499995, 10.793177650000075],
              [-75.11672035399994, 10.79141299400004 ],
              [-75.11666883099991, 10.791173383000057],
              [-75.11651863599991, 10.790570905000038],
              [-75.11647515999994, 10.790396509000061],
              [-75.11644853899992, 10.790391795000062],
              [-75.11644676999992, 10.790391501000045],
              [-75.11607215299995, 10.790329056000076],
              [-75.11606220499993, 10.790327397000055],
              [-75.11326027399991, 10.789860298000065],
              [-75.11180536099994, 10.789423990000046],
              [-75.11169137299993, 10.789365735000047],
              [-75.11133350199992, 10.789119980000066],
              [-75.11095953499995, 10.78889889800007 ],
              [-75.11059945699992, 10.78874174300006 ],
              [-75.11023057999995, 10.788606007000055],
              [-75.10988504099993, 10.78850058800009 ],
              [-75.10958487599993, 10.788427941000066],
              [-75.1091746319999 , 10.788374308000073],
              [-75.10876134199992, 10.788353670000049],
              [-75.10852757499993, 10.788359383000056],
              [-75.10841979399993, 10.788350550000075],
              [-75.10617774499991, 10.788166813000089],
              [-75.10611003199995, 10.787563765000073],
              [-75.10582905399991, 10.787565418000042],
              [-75.10552686299991, 10.787502866000068],
              [-75.10523405599992, 10.787467832000061],
              [-75.10502015199995, 10.78740711000006 ],
              [-75.10492476399992, 10.78737551000006 ],
              [-75.10486826599993, 10.787649204000047],
              [-75.10478117199995, 10.787870242000054],
              [-75.1047314939999 , 10.787954399000057],
              [-75.1046914129999 , 10.788022297000055],
              [-75.1045982089999 , 10.788098004000062],
              [-75.1045976119999 , 10.788098490000039],
              [-75.10450180999993, 10.788176307000072],
              [-75.10446940599991, 10.788231711000037],
              [-75.10445990999995, 10.78824758400009 ],
              [-75.10414509899994, 10.788248341000042],
              [-75.10393362699995, 10.788248851000048],
              [-75.09998691399994, 10.788258359000054],
              [-75.09959119899992, 10.788259309000068],
              [-75.09919712899995, 10.788260256000058],
              [-75.09867685499995, 10.788038332000042],
              [-75.09836022899992, 10.787858096000036],
              [-75.0975569769999 , 10.787277093000057],
              [-75.0972499639999 , 10.787025723000056],
              [-75.09706276999992, 10.786849006000068],
              [-75.09692838099994, 10.786750580000046],
              [-75.09635798599993, 10.786503450000055],
              [-75.09550708299992, 10.786205446000054],
              [-75.09493297999995, 10.785995669000044],
              [-75.09447769699995, 10.785765240000046],
              [-75.09409017599995, 10.785543620000055],
              [-75.09377457999994, 10.785392011000056],
              [-75.09350332499992, 10.78530879400006 ],
              [-75.09294322099993, 10.785073632000092],
              [-75.09259958199993, 10.784884471000055],
              [-75.09224281799993, 10.784720779000054],
              [-75.09180578099995, 10.784561305000068],
              [-75.09159290899993, 10.784534345000054],
              [-75.09141890299992, 10.784548207000057],
              [-75.09124937899992, 10.784589392000044],
              [-75.09108869799991, 10.784656848000054],
              [-75.09094099299995, 10.784748835000073],
              [-75.09087872799995, 10.784815300000048],
              [-75.09074343899994, 10.785019229000056],
              [-75.09051025199994, 10.785435895000091],
              [-75.08944955399994, 10.784771449000061],
              [-75.08876914399991, 10.784293688000048],
              [-75.08839628699991, 10.784071400000073],
              [-75.08826494099992, 10.783976042000063],
              [-75.0880560629999 , 10.783772860000056],
              [-75.0878657099999 , 10.78354218000004 ],
              [-75.08652698499992, 10.781414665000057],
              [-75.08550009099991, 10.779851931000053],
              [-75.0854137419999 , 10.779748525000059],
              [-75.0849543999999 , 10.779426450000074],
              [-75.08377542599993, 10.778657484000064],
              [-75.08337364099992, 10.778391850000048],
              [-75.08293934099993, 10.778087004000042],
              [-75.08279016799992, 10.77794807500004 ],
              [-75.08266016999994, 10.77779143400005 ],
              [-75.08232977299991, 10.777260691000038],
              [-75.08204674199993, 10.776858839000056],
              [-75.08169386299994, 10.776570512000092],
              [-75.08132922999994, 10.776296855000055],
              [-75.08086811699991, 10.775982730000067],
              [-75.08070241699994, 10.77589258100005 ],
              [-75.08055934599992, 10.775857754000072],
              [-75.08041201099991, 10.775856013000066],
              [-75.08001601099994, 10.775962580000055],
              [-75.07943273199993, 10.77618506500005 ],
              [-75.07883464199995, 10.77648911700004 ],
              [-75.07858715899994, 10.776579343000037],
              [-75.07833335599992, 10.776650362000055],
              [-75.07805415999991, 10.776704958000039],
              [-75.07777132599995, 10.776736308000068],
              [-75.07748683199992, 10.776744201000042],
              [-75.07720266599995, 10.776728569000056],
              [-75.07707720299993, 10.776509358000055],
              [-75.07697283899995, 10.776279585000054],
              [-75.07687520399992, 10.775993498000048],
              [-75.0768270069999 , 10.775786864000054],
              [-75.07680510099993, 10.775604907000059],
              [-75.07679789999992, 10.775083022000047],
              [-75.07676285199994, 10.77459168100006 ],
              [-75.0767499509999 , 10.77409928500009 ],
              [-75.07675038999992, 10.773952812000061],
              [-75.0770165749999 , 10.772888074000036],
              [-75.07708595199995, 10.772706088000064],
              [-75.0771871959999 , 10.772539342000073],
              [-75.0771915979999 , 10.772532091000073],
              [-75.07708970599992, 10.772575303000053],
              [-75.07669792299993, 10.772684986000058],
              [-75.07622342899992, 10.772743048000052],
              [-75.07603163899995, 10.772746705000088],
              [-75.0758807659999 , 10.77272767900007 ],
              [-75.07567364699992, 10.772698025000068],
              [-75.0751762619999 , 10.772697338000057],
              [-75.07469811899995, 10.77267699400005 ],
              [-75.0737820409999 , 10.772716972000069],
              [-75.07364094199994, 10.772718263000058],
              [-75.0735363309999 , 10.772719219000066],
              [-75.07312823299992, 10.772787923000067],
              [-75.07254509599994, 10.77285426900005 ],
              [-75.07247373599995, 10.772862388000078],
              [-75.0721756669999 , 10.77289630000007 ],
              [-75.0720771739999 , 10.772773610000058],
              [-75.07205695699992, 10.772467899000048],
              [-75.0720340769999 , 10.772382015000062],
              [-75.07196354699994, 10.772273488000053],
              [-75.07183916299994, 10.77212393700006 ],
              [-75.07160924699991, 10.771907881000061],
              [-75.07127304999995, 10.771660994000058],
              [-75.07045411799993, 10.771007096000062],
              [-75.0702932669999 , 10.770837303000064],
              [-75.07001234699993, 10.770495483000047],
              [-75.06972295299994, 10.770189689000063],
              [-75.06952040599992, 10.770033641000055],
              [-75.06927368299995, 10.769898238000053],
              [-75.06858869799993, 10.76963726300005 ],
              [-75.0678438779999 , 10.769297768000058],
              [-75.06722523499991, 10.76906123200007 ],
              [-75.06700145099995, 10.769160979000048],
              [-75.06676152299991, 10.769224084000086],
              [-75.06634860799994, 10.769292721000056],
              [-75.0661741749999 , 10.76928752300006 ],
              [-75.0657755869999 , 10.769162398000049],
              [-75.06539155699994, 10.768992899000068],
              [-75.06523446499995, 10.768890275000047],
              [-75.06512064799995, 10.768843501000049],
              [-75.06463816599995, 10.768769115000055],
              [-75.0642479139999 , 10.768662943000038],
              [-75.06385049199991, 10.768587121000053],
              [-75.06349021599993, 10.768482131000042],
              [-75.06342183999993, 10.768462206000038],
              [-75.06300032299993, 10.768315449000056],
              [-75.06320789199992, 10.767370318000076],
              [-75.06327845399994, 10.767049025000063],
              [-75.06326782299993, 10.76697198900007 ],
              [-75.06309887999993, 10.767040232000056],
              [-75.06193702999991, 10.76750953900006 ],
              [-75.06123078399992, 10.76782042800005 ],
              [-75.06096529899992, 10.767822245000048],
              [-75.06070098499993, 10.767797553000037],
              [-75.06005287799991, 10.767650624000055],
              [-75.05943721599994, 10.767685449000055],
              [-75.05875204699993, 10.767732983000087],
              [-75.05698323299993, 10.767855695000037],
              [-75.05626070599993, 10.768217661000051],
              [-75.0561746429999 , 10.768276813000057],
              [-75.05612063099994, 10.768382298000063],
              [-75.0560897869999 , 10.76849652900006 ],
              [-75.0559611889999 , 10.76969441500006 ],
              [-75.05572668199994, 10.770838476000051],
              [-75.05566029599993, 10.77148763200006 ],
              [-75.0556252479999 , 10.771563513000046],
              [-75.05555793499991, 10.771613801000058],
              [-75.0551983869999 , 10.771687495000037],
              [-75.05507359799992, 10.771697590000088],
              [-75.05494383699994, 10.771684021000056],
              [-75.05448077199992, 10.771569156000055],
              [-75.05426496799993, 10.771534831000054],
              [-75.05402656899992, 10.77153964900009 ],
              [-75.0538009789999 , 10.771602783000048],
              [-75.05365102299993, 10.771595059000049],
              [-75.05332409999994, 10.771510096000043],
              [-75.05298090299993, 10.77135710400006 ],
              [-75.05287399399992, 10.771351976000062],
              [-75.0527726389999 , 10.771385978000069],
              [-75.05276268599994, 10.771141404000048],
              [-75.05257992499992, 10.771292366000068],
              [-75.05242907599995, 10.771395812000037],
              [-75.05189185899991, 10.771714901000053],
              [-75.05154667399995, 10.771947269000066],
              [-75.05106153399993, 10.772337435000054],
              [-75.04994745199991, 10.77317867100004 ],
              [-75.04957838099995, 10.773393217000091],
              [-75.04924798899992, 10.773519605000047],
              [-75.04901662999993, 10.773716080000042],
              [-75.0488163089999 , 10.773956387000055],
              [-75.04874373799993, 10.773992390000046],
              [-75.0484794809999 , 10.773986548000039],
              [-75.04725874799993, 10.773856890000047],
              [-75.04711460199991, 10.773826807000091],
              [-75.04691143399992, 10.773733274000051],
              [-75.04618605099995, 10.773214966000069],
              [-75.0460354789999 , 10.773123231000056],
              [-75.04587431599992, 10.773051185000043],
              [-75.04576893199993, 10.773017107000044],
              [-75.04567212299992, 10.773023224000042],
              [-75.04540097299991, 10.773145219000071],
              [-75.04510599399993, 10.773303941000052],
              [-75.0446538139999 , 10.773628891000044],
              [-75.04454657499991, 10.773574058000065],
              [-75.04443023699991, 10.773542152000061],
              [-75.0439285139999 , 10.773478443000045],
              [-75.04342397799991, 10.77344256200007 ],
              [-75.04297047699993, 10.77335654300009 ],
              [-75.04250068999994, 10.773246571000072],
              [-75.0419657839999 , 10.77318774300005 ],
              [-75.0417694219999 , 10.773183238000058],
              [-75.04163766499994, 10.773217265000085],
              [-75.04153585499995, 10.773264591000043],
              [-75.04124944999995, 10.773438404000046],
              [-75.04107915999992, 10.77351397600006 ],
              [-75.04106548399994, 10.77351569700005 ],
              [-75.04099200399992, 10.773592491000045],
              [-75.04091746799992, 10.773522764000063],
              [-75.04087952299994, 10.773520485000063],
              [-75.04077986599992, 10.773450239000056],
              [-75.0407446829999 , 10.77338764500007 ],
              [-75.04073619499991, 10.773353179000082],
              [-75.04071775799991, 10.773278322000067],
              [-75.04071260499995, 10.773176903000092],
              [-75.04072610999992, 10.773076227000047],
              [-75.04078375499995, 10.772927461000052],
              [-75.0409344059999 , 10.772707109000066],
              [-75.04079323399992, 10.772545471000058],
              [-75.04070008399992, 10.772438820000048],
              [-75.04008846499994, 10.772059524000042],
              [-75.04006219299993, 10.772043231000055],
              [-75.03930074399995, 10.771427155000083],
              [-75.03888654499991, 10.771130262000042],
              [-75.03837342399993, 10.770814776000066],
              [-75.0384240219999 , 10.77075622600006 ],
              [-75.03848274299992, 10.770707855000069],
              [-75.03854544799992, 10.77069070300007 ],
              [-75.0385559259999 , 10.770688432000043],
              [-75.03868119299995, 10.770661276000055],
              [-75.03879950499993, 10.770644893000053],
              [-75.0394493789999 , 10.770656143000053],
              [-75.0395258019999 , 10.770644244000039],
              [-75.03971883399993, 10.77061419100005 ],
              [-75.03989572799992, 10.770552904000056],
              [-75.0400382229999 , 10.770489085000065],
              [-75.04021090799995, 10.770445162000044],
              [-75.04040445499993, 10.770416050000051],
              [-75.04058515799994, 10.770399673000043],
              [-75.04067404499995, 10.770397122000077],
              [-75.0407635649999 , 10.770394551000038],
              [-75.0409178459999 , 10.770374656000058],
              [-75.0410219609999 , 10.770322688000078],
              [-75.0410291359999 , 10.770319106000045],
              [-75.04111919899992, 10.770243160000064],
              [-75.04125078699991, 10.770051187000092],
              [-75.04125544099992, 10.77004439600006 ],
              [-75.04132662399991, 10.769947570000056],
              [-75.04139633499994, 10.769833935000065],
              [-75.04147607899995, 10.769770233000088],
              [-75.0415408419999 , 10.769730477000053],
              [-75.04161416699992, 10.769723838000061],
              [-75.04166834199992, 10.769758472000092],
              [-75.04176421999995, 10.769749794000063],
              [-75.04180690499993, 10.769717688000071],
              [-75.04188224599994, 10.769603561000054],
              [-75.0419696589999 , 10.769522334000044],
              [-75.04208096799994, 10.769478553000056],
              [-75.04253021099993, 10.769268625000052],
              [-75.0426645629999 , 10.769177292000052],
              [-75.04282002499991, 10.769067076000056],
              [-75.04298286799991, 10.768910958000049],
              [-75.04306442299992, 10.768801791000044],
              [-75.04314847899991, 10.768743235000045],
              [-75.04320341599993, 10.768698534000066],
              [-75.04325529699992, 10.768575016000057],
              [-75.04326393799994, 10.76847802900005 ],
              [-75.04332983899991, 10.768319293000047],
              [-75.0433680829999 , 10.768214157000045],
              [-75.0433867399999 , 10.768180948000065],
              [-75.04345837299991, 10.768053463000058],
              [-75.04360892599993, 10.767887186000053],
              [-75.04372168399993, 10.767679074000057],
              [-75.04380028999992, 10.767536264000057],
              [-75.04386906799994, 10.767411306000042],
              [-75.04395476199994, 10.76733041500006 ],
              [-75.04421040499994, 10.767093332000059],
              [-75.04430987099994, 10.767013878000057],
              [-75.04436985599995, 10.766965970000058],
              [-75.04428077999995, 10.766754499000058],
              [-75.04417395799993, 10.766487837000057],
              [-75.04414658499991, 10.766303936000043],
              [-75.04416347099993, 10.766252803000043],
              [-75.04422174699994, 10.766076364000071],
              [-75.04449010299993, 10.765789209000047],
              [-75.04460110299993, 10.765596938000044],
              [-75.04474957399992, 10.76542452800004 ],
              [-75.04487323699993, 10.765250363000064],
              [-75.0449388429999 , 10.765096950000043],
              [-75.04495797099992, 10.765064253000048],
              [-75.04498153899993, 10.765023967000047],
              [-75.04502761899994, 10.764940158000059],
              [-75.04507592199991, 10.764873562000048],
              [-75.04511905899994, 10.764813076000053],
              [-75.04515569499995, 10.764718765000055],
              [-75.04514173299992, 10.764623760000063],
              [-75.04509287999991, 10.764520549000054],
              [-75.04501956999991, 10.764430855000057],
              [-75.04498728399994, 10.764354464000064],
              [-75.04496595799992, 10.76424030000004 ],
              [-75.0449762689999 , 10.764047902000073],
              [-75.04500948599991, 10.763906250000048],
              [-75.04505409599994, 10.76380548800006 ],
              [-75.04509704399993, 10.76372653900006 ],
              [-75.0451026099999 , 10.763716306000049],
              [-75.0451179609999 , 10.763679830000058],
              [-75.04518019099993, 10.763601194000046],
              [-75.04521892999992, 10.763547334000066],
              [-75.04526071299995, 10.763480206000054],
              [-75.04530286799991, 10.76340059000006 ],
              [-75.04534027699992, 10.763330373000088],
              [-75.0453814729999 , 10.763247418000049],
              [-75.04538146099992, 10.763166523000052],
              [-75.04537108699992, 10.763082157000042],
              [-75.0453611609999 , 10.762993124000047],
              [-75.04538201699995, 10.762890681000044],
              [-75.04542808199994, 10.762798884000063],
              [-75.04548180499995, 10.762671814000043],
              [-75.04552730799992, 10.762558781000052],
              [-75.0455291749999 , 10.762482248000083],
              [-75.04551356199994, 10.76231043200005 ],
              [-75.04553311199993, 10.762092297000038],
              [-75.04555161399992, 10.761985251000056],
              [-75.04554271299992, 10.761923061000061],
              [-75.04551560499993, 10.761840580000069],
              [-75.04545455099992, 10.761745659000042],
              [-75.0454151589999 , 10.761645257000055],
              [-75.04539419399993, 10.76149515800006 ],
              [-75.04537554899991, 10.76133154200005 ],
              [-75.04538088599992, 10.76123251200005 ],
              [-75.04537570399992, 10.761158286000068],
              [-75.04536427099993, 10.761041787000067],
              [-75.04568238399992, 10.76081008500006 ],
              [-75.04597192699993, 10.760681816000044],
              [-75.04618415999994, 10.76058387200004 ],
              [-75.04648978799992, 10.760379134000061],
              [-75.04660883799994, 10.760248084000068],
              [-75.04660672299991, 10.760240704000068],
              [-75.04656283999992, 10.760087886000065],
              [-75.04643794299994, 10.759754150000049],
              [-75.04624807199991, 10.759556601000043],
              [-75.04616044699992, 10.75945276400006 ],
              [-75.0461126969999 , 10.759348785000043],
              [-75.04608603999992, 10.759199599000056],
              [-75.04611638999995, 10.759046431000058],
              [-75.04615603999991, 10.758908415000064],
              [-75.04620619699995, 10.75877304100004 ],
              [-75.04625218599995, 10.75865495000005 ],
              [-75.04630504699992, 10.758550351000054],
              [-75.04635481699995, 10.758457762000091],
              [-75.04640052699995, 10.758351442000048],
              [-75.04639612099993, 10.758262602000059],
              [-75.04638757999993, 10.758164549000071],
              [-75.04632060299991, 10.758080115000041],
              [-75.04625632099993, 10.75799994600004 ],
              [-75.04623625399995, 10.757911896000053],
              [-75.04621962199991, 10.757798086000037],
              [-75.04619420199992, 10.757544930000051],
              [-75.04615444199993, 10.757316801000059],
              [-75.04611583199994, 10.757297607000055],
              [-75.04602609599993, 10.757311136000055],
              [-75.04601184099994, 10.75732187500006 ],
              [-75.045889        , 10.75741438700004 ],
              [-75.04579682199994, 10.757464852000055],
              [-75.0457124269999 , 10.757467827000085],
              [-75.04554591999994, 10.757486912000047],
              [-75.04537591099995, 10.757531737000079],
              [-75.04522697399995, 10.757565033000049],
              [-75.04510348399992, 10.757543189000046],
              [-75.04500023899993, 10.757532491000063],
              [-75.04492137799991, 10.757515626000043],
              [-75.04483990999995, 10.757472200000052],
              [-75.04485107799991, 10.757462342000053],
              [-75.04482984799995, 10.757466412000042],
              [-75.04482236299992, 10.757462306000093],
              [-75.0447808859999 , 10.757439546000057],
              [-75.04470447499995, 10.757397626000056],
              [-75.04449647899992, 10.757392541000058],
              [-75.0442936529999 , 10.757372047000047],
              [-75.04409438099992, 10.757424681000089],
              [-75.04385049099994, 10.757582046000039],
              [-75.04360345399994, 10.757781292000061],
              [-75.04346751899993, 10.757890929000041],
              [-75.0432588669999 , 10.757967534000045],
              [-75.0430793079999 , 10.75798167000005 ],
              [-75.04289871799995, 10.757971023000039],
              [-75.04248297599992, 10.757803353000043],
              [-75.04202747099993, 10.757550428000059],
              [-75.04178936099993, 10.757320901000071],
              [-75.04174104399993, 10.757274327000061],
              [-75.04142700299991, 10.757082991000061],
              [-75.04125103699994, 10.757032228000071],
              [-75.04103664899992, 10.757051037000053],
              [-75.04062578299994, 10.757210049000037],
              [-75.04049347499995, 10.757259984000086],
              [-75.04007220199992, 10.757418983000036],
              [-75.03956927799993, 10.757564297000044],
              [-75.03939597399994, 10.757565717000091],
              [-75.03900913399991, 10.757568887000048],
              [-75.03833357399992, 10.757496377000052],
              [-75.03818315699993, 10.75747276900006 ],
              [-75.03785295999995, 10.757515347000037],
              [-75.03749984799992, 10.757440047000046],
              [-75.0374576129999 , 10.757433345000038],
              [-75.03745450199995, 10.757432221000045],
              [-75.03691624999993, 10.757237746000044],
              [-75.03677298799994, 10.757172364000041],
              [-75.03672554499991, 10.75715071400009 ],
              [-75.03664283299992, 10.757112967000069],
              [-75.0365374619999 , 10.75706487900004 ],
              [-75.03639934599994, 10.756954798000038],
              [-75.03624418499993, 10.756831133000048],
              [-75.03583516499992, 10.75649859300006 ],
              [-75.0355508749999 , 10.75633745500005 ],
              [-75.03529872999991, 10.756250525000041],
              [-75.03498053899995, 10.75620338300007 ],
              [-75.03491429399992, 10.756193569000061],
              [-75.03451190399994, 10.75614678100004 ],
              [-75.03415901999995, 10.756105748000039],
              [-75.0340235239999 , 10.756091401000049],
              [-75.03358659399993, 10.756045123000092],
              [-75.03343915799991, 10.756023739000057],
              [-75.03316950999994, 10.755987538000056],
              [-75.0331087429999 , 10.755979378000063],
              [-75.03288678099995, 10.755989274000058],
              [-75.03283596199992, 10.75599980800007 ],
              [-75.0326696379999 , 10.756034287000091],
              [-75.03252913499995, 10.756070339000075],
              [-75.0324083619999 , 10.756101327000067],
              [-75.03208098499994, 10.756185329000061],
              [-75.03141791699994, 10.756361792000064],
              [-75.0313827139999 , 10.756318578000048],
              [-75.03131577199991, 10.75623640100008 ],
              [-75.03125317799993, 10.756159560000071],
              [-75.03116065199993, 10.75582815200005 ],
              [-75.03111360799994, 10.75541368000006 ],
              [-75.03108237899994, 10.755180085000063],
              [-75.03101305599995, 10.754954145000056],
              [-75.03092105099995, 10.754788578000046],
              [-75.03082928499992, 10.754698399000063],
              [-75.0307296339999 , 10.754525319000038],
              [-75.03059939099995, 10.754322179000042],
              [-75.03046891799994, 10.754043658000057],
              [-75.03042248799994, 10.753825182000071],
              [-75.03037565999995, 10.753478556000061],
              [-75.03035927799993, 10.753116762000047],
              [-75.03033512899992, 10.752709756000058],
              [-75.03028437099994, 10.752326706000076],
              [-75.0302835839999 , 10.75207542600009 ],
              [-75.03022376599995, 10.751850154000067],
              [-75.03021158099995, 10.751804262000064],
              [-75.03015015199992, 10.751663732000054],
              [-75.03006839199992, 10.75152326500006 ],
              [-75.03007399299992, 10.75128327300007 ],
              [-75.0301267399999 , 10.75108711100006 ],
              [-75.03021751499995, 10.750860676000059],
              [-75.03033108699992, 10.750611559000049],
              [-75.03041449499995, 10.750468071000057],
              [-75.03068764099993, 10.750052617000051],
              [-75.0309536339999 , 10.749787955000045],
              [-75.0312047569999 , 10.749643951000053],
              [-75.03142505799991, 10.749394506000044],
              [-75.03162238999994, 10.749114976000044],
              [-75.03177381899991, 10.748782817000063],
              [-75.0317801839999 , 10.748247985000091],
              [-75.03183309199994, 10.747832368000047],
              [-75.0320754409999 , 10.747322360000055],
              [-75.03210162099992, 10.746893004000071],
              [-75.03204747599995, 10.746644398000058],
              [-75.03189387099991, 10.746283023000046],
              [-75.03159547099995, 10.745937170000047],
              [-75.03148722899994, 10.74545504200006 ],
              [-75.03144754999994, 10.744957624000051],
              [-75.03153029299995, 10.744603058000052],
              [-75.03167423799994, 10.744316154000046],
              [-75.03175710099993, 10.74399928300005 ],
              [-75.0317563669999 , 10.74376559500007 ],
              [-75.03175487399994, 10.743290633000072],
              [-75.03175669399991, 10.743290626000089],
              [-75.03269052999991, 10.743282075000081],
              [-75.0328132809999 , 10.743280952000077],
              [-75.0330179149999 , 10.743279078000057],
              [-75.03367907199993, 10.743273023000086],
              [-75.03368347299994, 10.743272982000065],
              [-75.03363496799994, 10.742843685000082],
              [-75.03360285999992, 10.74255951300006 ],
              [-75.0335531799999 , 10.742119811000066],
              [-75.0335247239999 , 10.742044900000053],
              [-75.03333761399995, 10.741552327000079],
              [-75.03332053299994, 10.74150736300004 ],
              [-75.03320159799995, 10.741194260000043],
              [-75.0331509209999 , 10.741202503000068],
              [-75.03252638799995, 10.741304083000045],
              [-75.03188261899993, 10.74136395100004 ],
              [-75.03180745499992, 10.74137094100007 ],
              [-75.03174850599993, 10.74139262400007 ],
              [-75.03139388599993, 10.741523055000073],
              [-75.0312301429999 , 10.74158435000004 ],
              [-75.0308714869999 , 10.741718606000063],
              [-75.03054481299995, 10.741784254000038],
              [-75.03038955499994, 10.739217327000063],
              [-75.030292        , 10.737604413000042],
              [-75.03024825599994, 10.736988429000064],
              [-75.03006402599993, 10.734394226000063],
              [-75.0299477829999 , 10.732067227000073],
              [-75.02975882599992, 10.729570077000062],
              [-75.02969359299993, 10.728309627000044],
              [-75.02958153399993, 10.727752373000044],
              [-75.02961916899994, 10.727601519000075],
              [-75.02962430799994, 10.727580921000083],
              [-75.02962995699994, 10.727558277000071],
              [-75.02969035199993, 10.727527114000054],
              [-75.03100058199993, 10.726851028000056],
              [-75.03127028199992, 10.726862321000056],
              [-75.03158863099992, 10.726752249000072],
              [-75.03200473499993, 10.72654490800005 ],
              [-75.03228593099993, 10.72631373300004 ],
              [-75.03303229599993, 10.725887189000048],
              [-75.03338737799993, 10.725764877000074],
              [-75.03398676699993, 10.725375150000048],
              [-75.03497880299994, 10.725117544000057],
              [-75.03540664399992, 10.724984792000043],
              [-75.03568903799993, 10.724897169000087],
              [-75.03637472299994, 10.724664748000066],
              [-75.0365607949999 , 10.724554927000042],
              [-75.03670505499991, 10.724469784000064],
              [-75.03701055599993, 10.72424329200004 ],
              [-75.03735751399995, 10.72398606300004 ],
              [-75.03786322999991, 10.723486476000062],
              [-75.0387542279999 , 10.722760945000061],
              [-75.03944607099993, 10.722054850000063],
              [-75.0404686999999 , 10.72042543200007 ],
              [-75.04167324999992, 10.718546995000054],
              [-75.04182606799992, 10.718037440000046],
              [-75.04248687499995, 10.715834052000048],
              [-75.04158165399991, 10.712225886000056],
              [-75.04144558099995, 10.711683497000081],
              [-75.04139972899992, 10.711500732000047],
              [-75.04143284299994, 10.71120312800008 ],
              [-75.041443        , 10.711111841000047],
              [-75.04148075899991, 10.710772475000056],
              [-75.04150105699995, 10.710590059000083],
              [-75.04165815899995, 10.70917813300008 ],
              [-75.04177959599991, 10.708086737000087],
              [-75.04179946399995, 10.70790818100005 ],
              [-75.04222322699991, 10.707345729000053],
              [-75.04282289999992, 10.706549798000083],
              [-75.04348048899993, 10.705057028000056],
              [-75.04453896899992, 10.703466456000058],
              [-75.04473063699993, 10.703178440000045],
              [-75.04543866299991, 10.701890495000043],
              [-75.04555297099995, 10.701682560000052],
              [-75.04654400299995, 10.70114981200004 ],
              [-75.04660542099992, 10.701116796000065],
              [-75.04765784599994, 10.70010966500007 ],
              [-75.04871191899991, 10.69962453100004 ],
              [-75.04905932899993, 10.699174946000085],
              [-75.04911626199993, 10.69910126700006 ],
              [-75.0495843949999 , 10.698940897000057],
              [-75.05053573499993, 10.698614991000056],
              [-75.0507625219999 , 10.69807439200008 ],
              [-75.05077854599995, 10.69803619600009 ],
              [-75.05096972699994, 10.697580471000038],
              [-75.05126102799994, 10.696886087000053],
              [-75.05245495999992, 10.694441029000075],
              [-75.05275347799994, 10.693829692000065],
              [-75.05352217299992, 10.692984047000039],
              [-75.05360208799993, 10.692582254000058],
              [-75.05381843299995, 10.692366925000044],
              [-75.05392585299995, 10.692260008000062],
              [-75.05459923699993, 10.693021027000043],
              [-75.05474079599992, 10.693181010000046],
              [-75.05547490099991, 10.694334541000046],
              [-75.05555689999994, 10.694463391000056],
              [-75.05567402999992, 10.694943187000092],
              [-75.05576259899993, 10.695305991000055],
              [-75.05590385799991, 10.695514120000041],
              [-75.0563336969999 , 10.696147440000061],
              [-75.05657845499991, 10.696508065000046],
              [-75.0566726049999 , 10.696477807000065],
              [-75.05771408899994, 10.696143108000058],
              [-75.05893117699992, 10.695858202000068],
              [-75.05913520799993, 10.696178794000048],
              [-75.05998728399993, 10.696015498000065],
              [-75.06079902099992, 10.695932632000051],
              [-75.06124561699994, 10.695931224000049],
              [-75.06241989799992, 10.694963819000066],
              [-75.06232038499991, 10.694886627000074],
              [-75.06164656599992, 10.694363942000052],
              [-75.06079138699994, 10.693563552000057],
              [-75.06009887599993, 10.69284295400007 ],
              [-75.05981416499992, 10.692683235000061],
              [-75.05688251899994, 10.690042256000083],
              [-75.0565956239999 , 10.689199913000039],
              [-75.0563462419999 , 10.687393751000059],
              [-75.05561242999994, 10.684372048000057],
              [-75.05560569999994, 10.684344335000048],
              [-75.06311495999995, 10.684638366000058],
              [-75.06332036599991, 10.684645681000063],
              [-75.06332236199995, 10.684645754000087],
              [-75.06420256399991, 10.684677110000052],
              [-75.0642022049999 , 10.684685989000059],
              [-75.06562303699991, 10.684741599000063],
              [-75.06677777699991, 10.684816561000048],
              [-75.06747857899995, 10.684832921000066],
              [-75.06804509799991, 10.684846150000055],
              [-75.06984776699994, 10.684949733000053],
              [-75.07143317599991, 10.684994844000073],
              [-75.07213707799991, 10.685017716000061],
              [-75.0726464359999 , 10.685034263000091],
              [-75.07438982999992, 10.685098789000051],
              [-75.07589044399992, 10.685142375000055],
              [-75.0766861219999 , 10.685180929000069],
              [-75.07673954599994, 10.685162558000059],
              [-75.07679201899992, 10.685165503000064],
              [-75.07835265499995, 10.685253122000063],
              [-75.0794406359999 , 10.68530069700006 ],
              [-75.0805754509999 , 10.685324526000045],
              [-75.08133009099993, 10.68536804100006 ],
              [-75.08145615599994, 10.685375311000087],
              [-75.0819909679999 , 10.685406149000073],
              [-75.08270925799991, 10.685441035000053],
              [-75.08354553499993, 10.685505036000052],
              [-75.08408711299995, 10.685523018000083],
              [-75.08472982299992, 10.685533020000037],
              [-75.0852879279999 , 10.685555496000063],
              [-75.08580587499995, 10.68556439100007 ],
              [-75.08635688999993, 10.685584604000042],
              [-75.08693836299994, 10.685590546000071],
              [-75.0874045299999 , 10.68559994800006 ],
              [-75.08789912399993, 10.685627701000044],
              [-75.08820058099991, 10.685643370000037],
              [-75.0884687379999 , 10.685624437000058],
              [-75.08865255799992, 10.685645922000049],
              [-75.08887862199992, 10.685655349000058],
              [-75.08915885399995, 10.68566657100007 ],
              [-75.08937764699994, 10.68565510700006 ],
              [-75.08955664599995, 10.685664993000046],
              [-75.08985087799994, 10.685664435000092],
              [-75.09004860899995, 10.685664821000046],
              [-75.09026773399995, 10.685685961000047],
              [-75.09045849799992, 10.685695733000045],
              [-75.09062341899994, 10.68571041100006 ],
              [-75.09093452499991, 10.68574927800006 ],
              [-75.09126417499994, 10.68576001300005 ],
              [-75.09149017699991, 10.685762448000048],
              [-75.09173504799992, 10.685769358000073],
              [-75.09192807199992, 10.685769791000041],
              [-75.0921871189999 , 10.685781218000045],
              [-75.0924580699999 , 10.685806502000048],
              [-75.0926933639999 , 10.68579720300005 ],
              [-75.09289594699993, 10.685811517000047],
              [-75.09311737899992, 10.685827969000059],
              [-75.09335034499992, 10.685821021000038],
              [-75.09351540699993, 10.685849674000053],
              [-75.09368479599993, 10.685841025000059],
              [-75.09392480299994, 10.685831678000056],
              [-75.09417888099995, 10.685817537000048],
              [-75.0944473159999 , 10.68582654100004 ],
              [-75.0945934529999 , 10.685846062000053],
              [-75.09529183699993, 10.685900573000083],
              [-75.0954269689999 , 10.68591112100006 ],
              [-75.0964456129999 , 10.685990624000056],
              [-75.0972104139999 , 10.686050309000052],
              [-75.09811603999992, 10.686120987000038],
              [-75.0989967989999 , 10.686408069000038],
              [-75.09963738199991, 10.68661686200005 ],
              [-75.10049637899994, 10.686896843000056],
              [-75.1015085379999 , 10.687953737000043],
              [-75.10164104599994, 10.688092100000063],
              [-75.10185610699995, 10.688316665000059],
              [-75.1021505679999 , 10.688665895000042],
              [-75.10215275799993, 10.689319245000092],
              [-75.10215378399994, 10.689624682000044],
              [-75.10234520999995, 10.690280573000052],
              [-75.10268327799992, 10.690912083000057],
              [-75.10316947599995, 10.691389765000054],
              [-75.1129600399999 , 10.691807719000053],
              [-75.11337124799991, 10.691743751000047],
              [-75.1330189439999 , 10.688687318000063],
              [-75.14306763799993, 10.687124125000082],
              [-75.14365237899995, 10.687033161000045],
              [-75.14534803899994, 10.68676938100009 ],
              [-75.15338957099993, 10.685518425000055],
              [-75.15463574599994, 10.685324569000045],
              [-75.15468408699991, 10.685317049000048],
              [-75.15472869299992, 10.685310110000046],
              [-75.1547283619999 , 10.685306049000076],
              [-75.1548199799999 , 10.68529876100007 ],
              [-75.15494286799992, 10.685228076000044],
              [-75.15502995199995, 10.685177985000053],
              [-75.15534255999995, 10.685479709000049],
              [-75.15557109699995, 10.686033835000046],
              [-75.1559605679999 , 10.68633128600004 ],
              [-75.1565656439999 , 10.68657464000006 ],
              [-75.15696500299993, 10.686615949000043],
              [-75.15772912999995, 10.687045745000091],
              [-75.15777583199991, 10.68707201300009 ],
              [-75.15779374399995, 10.687093122000078],
              [-75.15783000399995, 10.687135857000044],
              [-75.15783666599992, 10.687124595000057],
              [-75.15818275299995, 10.686539407000055],
              [-75.15837673499993, 10.686474707000059],
              [-75.15889628799994, 10.68694245000006 ],
              [-75.1590577899999 , 10.686845850000054],
              [-75.15940316399991, 10.686876667000092],
              [-75.15966188399995, 10.686811742000089],
              [-75.1600486769999 , 10.68635153200006 ],
              [-75.16010190599991, 10.686148592000052],
              [-75.16067487799995, 10.686466747000054],
              [-75.16106465799993, 10.686849564000056],
              [-75.16124845599995, 10.686955640000065],
              [-75.16139950899992, 10.68695511900006 ],
              [-75.16147514499994, 10.686986870000055],
              [-75.16162642699993, 10.687050372000044],
              [-75.16175597599994, 10.687071265000043],
              [-75.16180999799991, 10.687092420000056],
              [-75.16188575199993, 10.687156185000049],
              [-75.16199391099991, 10.687230510000063],
              [-75.16214537999991, 10.687347370000055],
              [-75.16223187899993, 10.687400427000057],
              [-75.16231850299994, 10.687485498000058],
              [-75.16241602199995, 10.68760254600005 ],
              [-75.1625458769999 , 10.687708806000046],
              [-75.16267569099995, 10.687804396000047],
              [-75.16276203899992, 10.687814769000056],
              [-75.16289159399992, 10.687835662000055],
              [-75.16294831899995, 10.68784269200006 ],
              [-75.16298532799993, 10.687869547000048],
              [-75.16305025999992, 10.687916664000056],
              [-75.16318946199993, 10.687920384000051],
              [-75.1633192409999 , 10.68792649900007 ],
              [-75.16341871499992, 10.687984155000038],
              [-75.16369652199995, 10.688248268000052],
              [-75.16380558599991, 10.688329195000051],
              [-75.16396409399994, 10.688395782000043],
              [-75.1641462039999 , 10.68846454000004 ],
              [-75.16431175399993, 10.688526412000044],
              [-75.1645033449999 , 10.688602109000044],
              [-75.16460029899991, 10.688636449000057],
              [-75.16474489099994, 10.688738130000047],
              [-75.16482598899995, 10.688868249000052],
              [-75.16492595499994, 10.688998234000053],
              [-75.1650661189999 , 10.689141947000053],
              [-75.16516570199991, 10.68921593500005 ],
              [-75.1652719789999 , 10.689233879000085],
              [-75.16534017599992, 10.689203079000038],
              [-75.16537752999994, 10.68914682400009 ],
              [-75.1653889079999 , 10.689086079000049],
              [-75.1653383499999 , 10.688937086000067],
              [-75.16526927199993, 10.688839557000051],
              [-75.16527127699993, 10.688788207000073],
              [-75.16528710099993, 10.68868776700009 ],
              [-75.16534093599995, 10.688626730000067],
              [-75.16544923399994, 10.688595658000054],
              [-75.16562237099993, 10.688732148000042],
              [-75.16581205799991, 10.688873189000049],
              [-75.16590189899995, 10.688902909000092],
              [-75.16610233599994, 10.688892208000084],
              [-75.16632109799991, 10.68880204900006 ],
              [-75.16642475899994, 10.688782672000059],
              [-75.1665238459999 , 10.688784333000058],
              [-75.1666374049999 , 10.688832559000048],
              [-75.16673952499991, 10.688932196000053],
              [-75.1667612629999 , 10.689006716000051],
              [-75.16676174699995, 10.689076712000087],
              [-75.1667456209999 , 10.689132825000058],
              [-75.16675316099992, 10.689200439000047],
              [-75.16681256299995, 10.689263036000057],
              [-75.16690919699994, 10.689250712000046],
              [-75.16698912499993, 10.689210495000054],
              [-75.16711133399991, 10.689144328000054],
              [-75.16718654399995, 10.689104151000038],
              [-75.16729259599992, 10.689089422000052],
              [-75.16738237599992, 10.689109808000069],
              [-75.16755315699993, 10.689246315000048],
              [-75.16761979799992, 10.689332193000041],
              [-75.16763924499992, 10.689416064000056],
              [-75.16758306299994, 10.689479447000053],
              [-75.16750560999992, 10.689535976000059],
              [-75.16745207899993, 10.689641344000052],
              [-75.16747625799991, 10.689727511000058],
              [-75.16760196699994, 10.68982698900004 ],
              [-75.16770867699995, 10.68990793100005 ],
              [-75.16785046399991, 10.689944294000043],
              [-75.16800868199994, 10.689968885000042],
              [-75.16820942299995, 10.690002510000056],
              [-75.1684243329999 , 10.690038377000064],
              [-75.16851117699991, 10.689974784000071],
              [-75.1686215769999 , 10.689906360000066],
              [-75.16872294199993, 10.68989633700005 ],
              [-75.16886739299991, 10.689977016000057],
              [-75.16891781099991, 10.690105005000078],
              [-75.16899871599992, 10.69020712300005 ],
              [-75.16911015999995, 10.690290363000088],
              [-75.1692757909999 , 10.69036389900009 ],
              [-75.16945956899991, 10.690332310000088],
              [-75.16961716499992, 10.690265899000053],
              [-75.16983854799992, 10.690213358000051],
              [-75.16992119199995, 10.69021046100005 ],
              [-75.17010315999994, 10.690225569000063],
              [-75.17023555899993, 10.69024568900005 ],
              [-75.17041259599995, 10.690230461000056],
              [-75.1705373019999 , 10.690161859000057],
              [-75.17069439099993, 10.689992578000044],
              [-75.1707979869999 , 10.68994514700006 ],
              [-75.17098670399992, 10.689911149000068],
              [-75.17127234599991, 10.68989050500005 ],
              [-75.1714612689999 , 10.68988687600006 ],
              [-75.1716903109999 , 10.689878303000057],
              [-75.17185537299991, 10.689839795000069],
              [-75.17219974299991, 10.689772022000056],
              [-75.17239319599992, 10.689740330000063],
              [-75.17262641399992, 10.689652293000051],
              [-75.1727887369999 , 10.68955773500005 ],
              [-75.17297668399993, 10.689411605000089],
              [-75.17307052799993, 10.68931985100005 ],
              [-75.1731995589999 , 10.68919281300009 ],
              [-75.17335701099995, 10.689077263000058],
              [-75.17349328699993, 10.68897353500006 ],
              [-75.17362488499992, 10.688876849000053],
              [-75.1737354249999 , 10.688808344000051],
              [-75.17389075499995, 10.688727848000042],
              [-75.17403192699993, 10.688649790000056],
              [-75.17418987699995, 10.688606656000047],
              [-75.1743290579999 , 10.688582340000039],
              [-75.1743305299999 , 10.688582195000038],
              [-75.1744610579999 , 10.688555335000046],
              [-75.17479727299991, 10.688515768000059],
              [-75.17503013399994, 10.688514951000059],
              [-75.17522418099992, 10.688514268000063],
              [-75.17541823199991, 10.688513586000056],
              [-75.17558544199994, 10.688244301000054],
              [-75.17576540399995, 10.68792379100006 ],
              [-75.17594591499994, 10.68775682100005 ],
              [-75.1761520259999 , 10.687512989000084],
              [-75.17642337399991, 10.687422470000058],
              [-75.1766301749999 , 10.687370563000059],
              [-75.17687569499992, 10.687292927000044],
              [-75.17705666799992, 10.68725390800006 ],
              [-75.17725105499994, 10.687111776000052],
              [-75.17741046599991, 10.68712254400009 ],
              [-75.17748280399991, 10.68713404400006 ],
              [-75.17778151999994, 10.68725051000007 ],
              [-75.17792177599995, 10.687283960000059],
              [-75.17806471699993, 10.687306057000058],
              [-75.17826166399993, 10.687309283000047],
              [-75.17834721999992, 10.687323516000049],
              [-75.17852691799993, 10.68738518300006 ],
              [-75.17876082399994, 10.687504559000047],
              [-75.17882620699993, 10.687517497000044],
              [-75.1789114419999 , 10.687512594000054],
              [-75.17903148699992, 10.68749531800006 ],
              [-75.17908159599995, 10.687482337000063],
              [-75.17916265199995, 10.687450244000047],
              [-75.1793833719999 , 10.687345033000042],
              [-75.17958949199993, 10.68718791300006 ],
              [-75.17964755499992, 10.687157975000048],
              [-75.17969603499995, 10.68715228700006 ],
              [-75.17974695799995, 10.687155158000053],
              [-75.1799011999999 , 10.687181701000043],
              [-75.18006733899995, 10.687240681000048],
              [-75.18018100199993, 10.687287751000042],
              [-75.18025517899991, 10.687335180000048],
              [-75.18033657899991, 10.687402271000053],
              [-75.18037005699995, 10.687439854000047],
              [-75.18038494299992, 10.687468993000039],
              [-75.18039593399993, 10.687518909000062],
              [-75.18041416599993, 10.687713750000057],
              [-75.18043032599991, 10.687761977000036],
              [-75.18045709699993, 10.687804791000076],
              [-75.18052236699992, 10.687885203000064],
              [-75.18057061799993, 10.687934064000046],
              [-75.18062912999994, 10.687972313000046],
              [-75.1808086289999 , 10.68806064000006 ],
              [-75.18085594999991, 10.688105822000068],
              [-75.18090356899995, 10.688171383000054],
              [-75.1809211719999 , 10.688195619000055],
              [-75.18101868999992, 10.688217744000042],
              [-75.18106424599995, 10.688215372000059],
              [-75.1811005209999 , 10.68818890700004 ],
              [-75.1811746759999 , 10.68809202400007 ],
              [-75.18123325999994, 10.688026053000044],
              [-75.18128392599994, 10.687977983000053],
              [-75.18132567899994, 10.687948633000076],
              [-75.18137136699994, 10.687930667000046],
              [-75.1814367959999 , 10.687915583000063],
              [-75.18169940599995, 10.68789249200006 ],
              [-75.18174935099995, 10.68788244700005 ],
              [-75.18177918399994, 10.687869196000065],
              [-75.1818438439999 , 10.68781696700006 ],
              [-75.18208082599995, 10.687579589000052],
              [-75.18212241799995, 10.687545967000062],
              [-75.1821689379999 , 10.687519137000038],
              [-75.18223520899994, 10.687491513000055],
              [-75.18228687499993, 10.687479119000045],
              [-75.1824822289999 , 10.68747174300006 ],
              [-75.18255155499992, 10.687460585000053],
              [-75.18275259099994, 10.687394295000047],
              [-75.18282680499993, 10.687356778000037],
              [-75.18284783299993, 10.687334298000053],
              [-75.18286869699995, 10.68729144100007 ],
              [-75.18292077399991, 10.687111179000055],
              [-75.18294528899992, 10.687047395000093],
              [-75.18300526799993, 10.686942195000086],
              [-75.18307201699992, 10.686841921000052],
              [-75.18310625499993, 10.68680547300005 ],
              [-75.18313472499995, 10.68678613500009 ],
              [-75.18331425199995, 10.68670701600007 ],
              [-75.18336998899991, 10.686669709000057],
              [-75.18340386599994, 10.686613875000091],
              [-75.18347430999995, 10.686416932000043],
              [-75.18350578799993, 10.686356896000063],
              [-75.18355538199995, 10.68630739100007 ],
              [-75.18367162599992, 10.686220477000063],
              [-75.18374637099993, 10.686170812000057],
              [-75.18379361899991, 10.686150338000061],
              [-75.18384410599992, 10.68614517900005 ],
              [-75.18389677099992, 10.686148210000056],
              [-75.18410933899992, 10.686178424000047],
              [-75.18417624499995, 10.686201569000048],
              [-75.18432200199993, 10.686267132000069],
              [-75.1843717029999 , 10.68628099600005 ],
              [-75.18442403299991, 10.686282469000048],
              [-75.18456711399995, 10.686266532000047],
              [-75.18463811899994, 10.686266241000055],
              [-75.18477837799992, 10.686285145000056],
              [-75.18502804399992, 10.686330360000056],
              [-75.18509955199994, 10.68633630000005 ],
              [-75.18535637399992, 10.686294531000044],
              [-75.1857292919999 , 10.68630154400006 ],
              [-75.18596353599992, 10.686283367000044],
              [-75.18614751899992, 10.68625673300005 ],
              [-75.18662164599994, 10.686170480000044],
              [-75.1873311359999 , 10.686001031000046],
              [-75.18843630299995, 10.686007130000064],
              [-75.18867695799992, 10.686015793000081],
              [-75.18880636199992, 10.686020453000083],
              [-75.18922055099995, 10.686035362000041],
              [-75.18956585199993, 10.686111692000054],
              [-75.18975466499995, 10.686281639000072],
              [-75.1898155099999 , 10.686346365000077],
              [-75.18994358899994, 10.686482614000056],
              [-75.19049220199992, 10.686403102000043],
              [-75.19133776399991, 10.68612895800004 ],
              [-75.1916644609999 , 10.685967347000087],
              [-75.19169966899995, 10.685949931000039],
              [-75.19217658299993, 10.68582682400006 ],
              [-75.19283090499994, 10.685730056000068],
              [-75.19338995099992, 10.685687594000058],
              [-75.19369000699993, 10.685686519000058],
              [-75.19471209199992, 10.685512088000053],
              [-75.19473955599994, 10.685507400000063],
              [-75.19513519399993, 10.685402602000067],
              [-75.19584266899994, 10.685058292000065],
              [-75.19640038199992, 10.684651605000056],
              [-75.19691687699992, 10.684164124000063],
              [-75.19732430999994, 10.683690524000042],
              [-75.19754119499993, 10.683325524000054],
              [-75.1977574949999 , 10.682914415000084],
              [-75.19782562799992, 10.682784917000049],
              [-75.19815117899992, 10.682298119000052],
              [-75.19850390099992, 10.681784249000088],
              [-75.19882989699994, 10.68141885500006 ],
              [-75.19918257099994, 10.68089148900009 ],
              [-75.19949393199994, 10.68025635400005 ],
              [-75.19983315999991, 10.679782999000054],
              [-75.20008473999991, 10.67951925500006 ],
              [-75.20010112199992, 10.679502076000063],
              [-75.20032134999991, 10.679056312000057],
              [-75.20032700899992, 10.679046923000044],
              [-75.20071461299995, 10.678403882000055],
              [-75.20072723499993, 10.678362494000055],
              [-75.2008113139999 , 10.678255172000092],
              [-75.20091634399995, 10.678121107000038],
              [-75.20174828799992, 10.676850356000045],
              [-75.20306668499995, 10.675021422000043],
              [-75.20487990299995, 10.673669007000058],
              [-75.20622274999994, 10.673651260000042],
              [-75.20655259799992, 10.673732466000047],
              [-75.20855037899992, 10.674224302000084],
              [-75.20961191799995, 10.675339377000057],
              [-75.2099128349999 , 10.675655469000048],
              [-75.21140227799992, 10.677447241000039],
              [-75.21242469599991, 10.680815161000055],
              [-75.21292519799994, 10.682170986000074],
              [-75.21331196999995, 10.68321872200005 ],
              [-75.21456223699994, 10.685375933000046],
              [-75.21630084299994, 10.687526644000059],
              [-75.21829020899992, 10.690157076000048],
              [-75.21954054699995, 10.692314243000055],
              [-75.2214078909999 , 10.694946255000048],
              [-75.22203964899995, 10.69650779400007 ],
              [-75.2230442469999 , 10.698547427000051],
              [-75.22441839899994, 10.700823668000055],
              [-75.22638819799994, 10.702005068000062],
              [-75.22740950399992, 10.70210355000006 ],
              [-75.22748861699995, 10.702111178000052],
              [-75.22750980799992, 10.70211089500009 ],
              [-75.23029656999995, 10.702073745000064],
              [-75.2328554039999 , 10.701677319000055],
              [-75.23468171399992, 10.701290659000051],
              [-75.23514952399995, 10.701080757000057],
              [-75.23567277599994, 10.700845979000064],
              [-75.23662351299993, 10.700419393000061],
              [-75.23892329699993, 10.69893952600006 ],
              [-75.24073308099992, 10.697345427000073],
              [-75.24181530599992, 10.696123344000057],
              [-75.2432670739999 , 10.695137844000044],
              [-75.24485080199992, 10.694875143000047],
              [-75.24644444299992, 10.695336876000056],
              [-75.2470496489999 , 10.69569980700004 ],
              [-75.24707285099993, 10.69571372200005 ],
              [-75.2470772449999 , 10.695716357000038],
              [-75.24708684399991, 10.695722111000066],
              [-75.24708711599993, 10.695722273000058],
              [-75.2471216909999 , 10.695743010000058],
              [-75.2474293379999 , 10.695927500000039],
              [-75.24814081999995, 10.697204947000046],
              [-75.24814346399995, 10.697233923000056],
              [-75.24814092199995, 10.697304531000043],
              [-75.24812408699995, 10.697501543000044],
              [-75.24807894899993, 10.698029722000058],
              [-75.24804646399991, 10.698169212000039],
              [-75.24784299499993, 10.698896749000085],
              [-75.24782917999994, 10.69893432300006 ],
              [-75.24757021599993, 10.699638629000049],
              [-75.24754326999994, 10.699711910000076],
              [-75.24713714899991, 10.700854450000065],
              [-75.24700073499992, 10.70123821900006 ],
              [-75.24689533499992, 10.701511417000063],
              [-75.24689041699992, 10.701524163000045],
              [-75.24687284799995, 10.701591393000058],
              [-75.2468790879999 , 10.70165819500005 ],
              [-75.24693361399994, 10.701807858000052],
              [-75.24698314599993, 10.701900511000076],
              [-75.24707845899991, 10.702001976000076],
              [-75.24724423899994, 10.702157141000043],
              [-75.2473182569999 , 10.702199937000046],
              [-75.24750931799991, 10.702281697000046],
              [-75.2476700389999 , 10.702339796000047],
              [-75.24797820399993, 10.702451193000059],
              [-75.2480755759999 , 10.702493189000052],
              [-75.24814818999994, 10.702543494000054],
              [-75.24836934799993, 10.702723650000053],
              [-75.24851645199993, 10.702858737000042],
              [-75.24865504499991, 10.702986008000039],
              [-75.2487037169999 , 10.703036496000038],
              [-75.2487469429999 , 10.703092058000038],
              [-75.2490374859999 , 10.703514017000089],
              [-75.24928584499992, 10.703965381000046],
              [-75.24913044899995, 10.704237241000044],
              [-75.24799101799994, 10.705501187000038],
              [-75.24799179999991, 10.70570321300005 ],
              [-75.24799254599992, 10.705898871000045],
              [-75.24792760399993, 10.706160002000047],
              [-75.24792834899995, 10.706355666000093],
              [-75.24786316199993, 10.706551570000045],
              [-75.24773226699995, 10.706812944000092],
              [-75.24760137099992, 10.707074326000054],
              [-75.24747022799994, 10.707270483000059],
              [-75.24740503699991, 10.707466389000047],
              [-75.24733983599992, 10.707662300000038],
              [-75.24714274799993, 10.707858698000052],
              [-75.24701160399991, 10.708054855000057],
              [-75.24699441599995, 10.70812395200005 ],
              [-75.24697007399993, 10.708125308000092],
              [-75.24696139299994, 10.70821389300005 ],
              [-75.24694055099991, 10.708265622000056],
              [-75.2469105099999 , 10.708311412000057],
              [-75.24674366199991, 10.708481534000043],
              [-75.24673824999991, 10.708488920000093],
              [-75.24659662099992, 10.708682210000063],
              [-75.24656286999993, 10.708748513000046],
              [-75.2465546549999 , 10.708791742000074],
              [-75.24653681699994, 10.708885624000061],
              [-75.2465319879999 , 10.70898485300006 ],
              [-75.24652420399991, 10.709144774000038],
              [-75.24650786799992, 10.709190313000079],
              [-75.24649357899995, 10.709230155000057],
              [-75.24640049599992, 10.709378331000039],
              [-75.24637221699993, 10.70945245200005 ],
              [-75.2463615019999 , 10.70952746800009 ],
              [-75.2463437259999 , 10.709801673000072],
              [-75.24632109999993, 10.709882269000047],
              [-75.246254        , 10.710026260000063],
              [-75.24623595799994, 10.710083091000058],
              [-75.24621194699995, 10.710290257000054],
              [-75.24618988799995, 10.710480587000063],
              [-75.24615450399995, 10.71056184300005 ],
              [-75.24613271699991, 10.710598265000044],
              [-75.24605688799994, 10.710725036000042],
              [-75.24595776599995, 10.710871780000048],
              [-75.2459300079999 , 10.710948396000049],
              [-75.2459140759999 , 10.711087346000056],
              [-75.24591906199993, 10.711364879000087],
              [-75.24591947599993, 10.711387916000092],
              [-75.24592028099994, 10.71139160100006 ],
              [-75.24593932199991, 10.711478802000045],
              [-75.24600857699994, 10.711656926000046],
              [-75.24601882099995, 10.711716024000054],
              [-75.24601903399991, 10.711794700000041],
              [-75.2460080749999 , 10.711852308000061],
              [-75.24598577499995, 10.711903136000046],
              [-75.24596442799992, 10.711932273000059],
              [-75.24590554799994, 10.711985493000043],
              [-75.24588184499993, 10.712006918000043],
              [-75.24585946899992, 10.712036240000089],
              [-75.24584672199995, 10.712069479000093],
              [-75.2458386319999 , 10.712123519000045],
              [-75.24583862999992, 10.712219351000044],
              [-75.2458386269999 , 10.712473762000059],
              [-75.24585275599992, 10.712600944000087],
              [-75.24588149699991, 10.712685627000042],
              [-75.2459084219999 , 10.712734879000038],
              [-75.2459724979999 , 10.712852080000062],
              [-75.24603095299994, 10.713057824000089],
              [-75.24606942399993, 10.713120436000054],
              [-75.24615753299992, 10.713212224000074],
              [-75.24624376999992, 10.713281897000059],
              [-75.24637483399994, 10.713370845000043],
              [-75.24642528099992, 10.713419428000066],
              [-75.24646219099992, 10.713491997000062],
              [-75.24646685599993, 10.713524513000038],
              [-75.24646194999991, 10.713574409000046],
              [-75.2464512379999 , 10.713604363000059],
              [-75.24644448399994, 10.71362324900008 ],
              [-75.24640667599994, 10.713687113000049],
              [-75.24626849899994, 10.713850999000044],
              [-75.24617976899992, 10.71391518200005 ],
              [-75.2460273929999 , 10.713970798000048],
              [-75.24598379499992, 10.71401405100005 ],
              [-75.2459586789999 , 10.714076059000092],
              [-75.24594096599992, 10.714183477000063],
              [-75.24592439499992, 10.714239723000048],
              [-75.24588779599992, 10.714300447000085],
              [-75.24578491099993, 10.714423927000041],
              [-75.24574720999993, 10.714489852000042],
              [-75.2456858619999 , 10.71466001500005 ],
              [-75.2456259839999 , 10.714877693000062],
              [-75.24557570699994, 10.714976386000046],
              [-75.2455215459999 , 10.71503496300005 ],
              [-75.24551189099992, 10.715045401000054],
              [-75.2454455049999 , 10.715097379000042],
              [-75.24525766099993, 10.715170355000055],
              [-75.24521961799991, 10.71520542400009 ],
              [-75.24517570199993, 10.715267822000044],
              [-75.24513163299991, 10.715361273000042],
              [-75.24508929599995, 10.715482706000046],
              [-75.24505692199995, 10.715603616000067],
              [-75.24500817299992, 10.71583839100009 ],
              [-75.24495760699995, 10.715977170000087],
              [-75.2449409059999 , 10.716007510000054],
              [-75.24493038999992, 10.71602660700006 ],
              [-75.24480237999995, 10.716259107000042],
              [-75.2446300169999 , 10.71649343200005 ],
              [-75.24462185099992, 10.716509502000065],
              [-75.24458839899995, 10.716575316000046],
              [-75.24457506899995, 10.71663079800004 ],
              [-75.2445716659999 , 10.716690197000048],
              [-75.2445907309999 , 10.71686743400005 ],
              [-75.24462564799995, 10.71702487500005 ],
              [-75.24467938599992, 10.717165107000085],
              [-75.2447612819999 , 10.717302607000079],
              [-75.24486101799994, 10.717422662000047],
              [-75.24496389099994, 10.717511990000048],
              [-75.2450797699999 , 10.717586525000058],
              [-75.24517867499992, 10.717626564000057],
              [-75.24540657199992, 10.71769170500005 ],
              [-75.24548865699995, 10.717704815000047],
              [-75.24557406199995, 10.717705315000046],
              [-75.24576351799993, 10.717681693000088],
              [-75.24587185099995, 10.717658565000079],
              [-75.24598715799993, 10.717608570000039],
              [-75.24619811399992, 10.71747210600006 ],
              [-75.24626617199993, 10.71744361900005 ],
              [-75.24635275499992, 10.717421968000053],
              [-75.2466342909999 , 10.717370187000085],
              [-75.24674648299992, 10.717358726000043],
              [-75.24685876099994, 10.71736838600009 ],
              [-75.24691966699993, 10.717389670000045],
              [-75.24695486999991, 10.71740197400004 ],
              [-75.24705832799992, 10.717460862000053],
              [-75.24715378799993, 10.717530794000083],
              [-75.24722043299994, 10.717600857000093],
              [-75.24727480699994, 10.71768290600005 ],
              [-75.24732745599994, 10.717811424000047],
              [-75.24740183499995, 10.71816480800004 ],
              [-75.24745129299993, 10.71832608200009 ],
              [-75.24751702299994, 10.71845144200006 ],
              [-75.24756555599993, 10.718511455000055],
              [-75.24762117499995, 10.718564621000041],
              [-75.24773675699993, 10.718638871000053],
              [-75.24785926699991, 10.718679097000063],
              [-75.24797399399995, 10.718693494000092],
              [-75.2483079779999 , 10.71870737100005 ],
              [-75.24845930999993, 10.718738257000041],
              [-75.24853850499994, 10.718768431000058],
              [-75.24862786499995, 10.718815670000083],
              [-75.24869103499992, 10.718861297000046],
              [-75.24870009499995, 10.718870011000092],
              [-75.24874582299992, 10.71891398300005 ],
              [-75.24880081899994, 10.718989201000056],
              [-75.24883756899993, 10.719074353000053],
              [-75.2488582819999 , 10.719215784000085],
              [-75.24893487499992, 10.719477149000056],
              [-75.24907673099995, 10.71976962900004 ],
              [-75.24909055799992, 10.719798138000044],
              [-75.24914461899994, 10.71993274600004 ],
              [-75.24916626299995, 10.720015171000057],
              [-75.24918120599995, 10.720114889000058],
              [-75.24917346299992, 10.720304528000042],
              [-75.24916060499993, 10.720386851000058],
              [-75.24914038899993, 10.720457711000051],
              [-75.24911421099995, 10.720512067000072],
              [-75.24908070599992, 10.720559299000058],
              [-75.2487078659999 , 10.720953217000044],
              [-75.24836535799994, 10.721213412000054],
              [-75.24827770899992, 10.721290151000062],
              [-75.24825734199993, 10.72131500900008 ],
              [-75.2482542429999 , 10.721318791000044],
              [-75.24819131699991, 10.721395611000048],
              [-75.24811720899993, 10.721540921000042],
              [-75.24809285499992, 10.721619407000048],
              [-75.2480799029999 , 10.72169526500005 ],
              [-75.24807607899993, 10.721796509000058],
              [-75.2480839669999 , 10.72188484500009 ],
              [-75.24811905599995, 10.722011928000086],
              [-75.2482136559999 , 10.722198301000049],
              [-75.24823584899991, 10.722258689000057],
              [-75.24825111599995, 10.722371115000044],
              [-75.24825466399994, 10.72260381700005 ],
              [-75.24826702599995, 10.722665513000038],
              [-75.24828840299995, 10.722720526000046],
              [-75.24833082499993, 10.722782467000059],
              [-75.2483734569999 , 10.722818486000051],
              [-75.24842696799993, 10.722845200000052],
              [-75.2484855639999 , 10.72286121400009 ],
              [-75.24860314799992, 10.72287381800004 ],
              [-75.24903051199993, 10.722886191000043],
              [-75.24923503399992, 10.722912997000037],
              [-75.2493683429999 , 10.722930465000047],
              [-75.2495155169999 , 10.72296516700004 ],
              [-75.24952656099993, 10.722965119000037],
              [-75.24513939999991, 10.730393789000061],
              [-75.2449048769999 , 10.730790900000045],
              [-75.24478608899994, 10.73103236500009 ],
              [-75.24439134699992, 10.731834775000038],
              [-75.24435703499995, 10.731992769000044],
              [-75.2444027649999 , 10.732209654000087],
              [-75.2443337389999 , 10.732422039000085],
              [-75.2443095349999 , 10.732614523000052],
              [-75.24427812199991, 10.732864334000055],
              [-75.24427474499993, 10.732891193000057],
              [-75.24420563299992, 10.733049914000048],
              [-75.24415803099993, 10.733159233000038],
              [-75.24415865199995, 10.73315860200006 ],
              [-75.24425202999993, 10.733248012000047],
              [-75.24433521199995, 10.73335622500008 ],
              [-75.24437177899995, 10.733429306000062],
              [-75.24443209299994, 10.733593068000062],
              [-75.24450969199995, 10.733725362000087],
              [-75.24452133999995, 10.73377487600004 ],
              [-75.24451422099992, 10.733825537000087],
              [-75.24448898399993, 10.733867923000048],
              [-75.24439551099994, 10.733979004000048],
              [-75.24435252099994, 10.734052661000078],
              [-75.24432634099992, 10.734097514000041],
              [-75.24429543199994, 10.734194425000055],
              [-75.24428985799995, 10.734309033000045],
              [-75.24426906399992, 10.734352663000038],
              [-75.24424363399993, 10.734375580000062],
              [-75.2441976309999 , 10.734395420000055],
              [-75.24406230799991, 10.734415049000063],
              [-75.24401261799994, 10.734429798000065],
              [-75.24396665799992, 10.734459464000054],
              [-75.24393361499995, 10.734498596000037],
              [-75.2439009869999 , 10.734581948000084],
              [-75.24387661399993, 10.734798540000043],
              [-75.24382267999994, 10.734938861000046],
              [-75.24379003199994, 10.735061857000062],
              [-75.2437476419999 , 10.735134848000087],
              [-75.24362354099992, 10.735279915000092],
              [-75.24360328599994, 10.735330655000041],
              [-75.24359623399994, 10.735389861000044],
              [-75.2436045199999 , 10.73547104100004 ],
              [-75.24362614599994, 10.735551215000044],
              [-75.24365736499993, 10.73562826500006 ],
              [-75.24369651399991, 10.735699083000043],
              [-75.24378100799993, 10.735807793000049],
              [-75.2439752169999 , 10.73600188200004 ],
              [-75.2440522309999 , 10.73609048500009 ],
              [-75.24409551799994, 10.73615609300009 ],
              [-75.24416734599993, 10.736298145000092],
              [-75.24422365599992, 10.73636397200005 ],
              [-75.24423935399994, 10.73638232500008 ],
              [-75.2442482749999 , 10.736420637000037],
              [-75.24423795199993, 10.73644184300008 ],
              [-75.24414920499993, 10.736509228000045],
              [-75.24409249899992, 10.736575196000047],
              [-75.2440382609999 , 10.73666391100005 ],
              [-75.24402931299994, 10.736681640000086],
              [-75.24395154899992, 10.736835735000057],
              [-75.24381039499991, 10.737025955000092],
              [-75.24381009599995, 10.737026356000058],
              [-75.24376152799994, 10.737112704000083],
              [-75.24376066699995, 10.737115269000071],
              [-75.24370686899994, 10.737275646000057],
              [-75.2436652049999 , 10.737481684000045],
              [-75.24360804399993, 10.737701405000053],
              [-75.24356506399994, 10.737933166000062],
              [-75.24351537099994, 10.738063862000047],
              [-75.2433402819999 , 10.738323671000046],
              [-75.24324188899993, 10.738438811000037],
              [-75.24313913399993, 10.738559056000042],
              [-75.2431152769999 , 10.738591141000086],
              [-75.24302215999995, 10.738716369000088],
              [-75.24292674899993, 10.738866879000057],
              [-75.24287692799993, 10.738976559000037],
              [-75.24285542099994, 10.739055007000047],
              [-75.2428473999999 , 10.739114897000093],
              [-75.24285555699993, 10.739231673000063],
              [-75.24285611999994, 10.739239739000084],
              [-75.24289804999995, 10.739409314000056],
              [-75.24300562099995, 10.739714716000037],
              [-75.24302376999992, 10.739793637000048],
              [-75.24303152899995, 10.739870846000088],
              [-75.24302762999992, 10.739980092000053],
              [-75.24300608199991, 10.740110042000083],
              [-75.24297451099994, 10.74023130900008 ],
              [-75.24294963699992, 10.740285762000042],
              [-75.24294036299995, 10.740306064000038],
              [-75.24291635199995, 10.74034080000007 ],
              [-75.24290714799992, 10.740354116000049],
              [-75.24285245499993, 10.740408039000044],
              [-75.24282813699995, 10.74042419300008 ],
              [-75.2426372139999 , 10.740551010000047],
              [-75.24255192999993, 10.740628546000039],
              [-75.24248309199993, 10.740723022000054],
              [-75.24245180299994, 10.740779944000053],
              [-75.24234770999993, 10.740969308000047],
              [-75.24228200699991, 10.741066978000049],
              [-75.24220413499995, 10.741151990000048],
              [-75.24201476399992, 10.741317589000062],
              [-75.24188356999991, 10.741458874000045],
              [-75.24171157899991, 10.741665228000045],
              [-75.24155355399995, 10.741892201000041],
              [-75.24147688199992, 10.741978325000048],
              [-75.24140001899991, 10.742037310000057],
              [-75.24131257199991, 10.742086596000092],
              [-75.24090898799994, 10.742578095000056],
              [-75.24090822099993, 10.742579188000093],
              [-75.24064121999993, 10.74295926800005 ],
              [-75.24056296399993, 10.743070665000062],
              [-75.2401609069999 , 10.74384503500005 ],
              [-75.24016542699991, 10.744306775000041],
              [-75.24016561899992, 10.74432634100009 ],
              [-75.2401674759999 , 10.744515989000092],
              [-75.24034324899992, 10.74503797400007 ],
              [-75.2404258759999 , 10.74528334300004 ],
              [-75.2404289449999 , 10.745333974000062],
              [-75.24043273299992, 10.74539646800008 ],
              [-75.24054303799994, 10.747216493000053],
              [-75.23832966799995, 10.748904629000037],
              [-75.23749370099995, 10.74987486100008 ],
              [-75.23673030499992, 10.75081227000004 ],
              [-75.23623416599992, 10.75141313000006 ],
              [-75.23506962999994, 10.752678985000045],
              [-75.23419388199994, 10.753392696000049],
              [-75.23356733499992, 10.753764445000058],
              [-75.23322187399992, 10.754122819000088],
              [-75.23267050899995, 10.754490101000044],
              [-75.23201503799993, 10.754869065000037],
              [-75.23128992099993, 10.75548223900006 ],
              [-75.23066925599994, 10.756102611000074],
              [-75.2303596289999 , 10.756450723000057],
              [-75.2303301789999 , 10.756605634000039],
              [-75.23016708599994, 10.75746351500004 ],
              [-75.23015399299993, 10.757537048000074],
              [-75.22996139799994, 10.758618722000051],
              [-75.2299603649999 , 10.758624523000037],
              [-75.23002566199995, 10.758766695000077],
              [-75.23016839699994, 10.75907747000008 ],
              [-75.23031522399992, 10.759397155000045],
              [-75.22990849399991, 10.759245900000053],
              [-75.22970586299994, 10.759099141000092],
              [-75.22960630499995, 10.759027035000088],
              [-75.22934067499995, 10.759060885000054],
              [-75.22897958599992, 10.75878874700004 ],
              [-75.22831656799991, 10.759618983000053],
              [-75.22813390699991, 10.759782051000059],
              [-75.22814489199993, 10.75981018400006 ],
              [-75.22817537299994, 10.760648661000062],
              [-75.22822535199992, 10.76202352100006 ],
              [-75.22819855599994, 10.762257891000047],
              [-75.22795391499994, 10.762574891000042],
              [-75.22787090399993, 10.762864343000047],
              [-75.22790634699993, 10.763125307000053],
              [-75.22803455699994, 10.763364976000048],
              [-75.2281883309999 , 10.763779947000046],
              [-75.2282421729999 , 10.764627697000037],
              [-75.22833958199993, 10.764839510000058],
              [-75.22834161599991, 10.76492498400006 ],
              [-75.22832781699992, 10.764996351000093],
              [-75.22832519299993, 10.764995919000057],
              [-75.22828951499991, 10.765060254000048],
              [-75.22825678599992, 10.765119263000088],
              [-75.22825450899995, 10.76512431000009 ],
              [-75.22815352999993, 10.765348077000056],
              [-75.2280404899999 , 10.765574745000038],
              [-75.22793071599995, 10.765839638000045],
              [-75.22731177299994, 10.766296340000054],
              [-75.22695928599995, 10.766449433000048],
              [-75.2267961629999 , 10.76661193800004 ],
              [-75.2267906699999 , 10.766712511000037],
              [-75.22691515699995, 10.767146487000048],
              [-75.22698876699991, 10.767608069000062],
              [-75.22693907999991, 10.76784451900005 ],
              [-75.22678201299993, 10.768045933000053],
              [-75.2265541129999 , 10.768179703000044],
              [-75.22641856799993, 10.768187276000049],
              [-75.22625816399994, 10.768405954000059],
              [-75.2261689199999 , 10.768593938000038],
              [-75.22594136599992, 10.768927760000054],
              [-75.22570295699995, 10.769246679000048],
              [-75.22549905599993, 10.769556932000057],
              [-75.2252862599999 , 10.76979891700006 ],
              [-75.22512489999991, 10.769942523000054],
              [-75.22495893599995, 10.770009304000041],
              [-75.22485132399993, 10.770095083000058],
              [-75.22478918399992, 10.77021698100009 ],
              [-75.22458746399991, 10.770533627000077],
              [-75.2244498089999 , 10.770666473000063],
              [-75.22421030099991, 10.770695106000062],
              [-75.22409816899994, 10.770727540000053],
              [-75.22403378099995, 10.77082596300005 ],
              [-75.2240622999999 , 10.77094752100004 ],
              [-75.22411021099992, 10.771058337000056],
              [-75.2241344169999 , 10.771179911000047],
              [-75.22407001599993, 10.771274067000093],
              [-75.22397511399993, 10.771295764000058],
              [-75.2238603529999 , 10.771204405000049],
              [-75.22374330899993, 10.771078904000092],
              [-75.22362014999993, 10.771049478000066],
              [-75.22346104899992, 10.77121868800009 ],
              [-75.2234226089999 , 10.771329823000087],
              [-75.22341222999995, 10.77143871900006 ],
              [-75.2234004359999 , 10.771562428000038],
              [-75.22336586499995, 10.771706963000042],
              [-75.22312873899995, 10.771695331000046],
              [-75.22292541399992, 10.771518668000056],
              [-75.22257388799994, 10.771446283000046],
              [-75.22227959399993, 10.771533842000053],
              [-75.22200142999992, 10.77165965100005 ],
              [-75.22155716399993, 10.772168997000051],
              [-75.22140838599995, 10.772405762000062],
              [-75.22134295899991, 10.772610912000061],
              [-75.2212555669999 , 10.77288493900005 ],
              [-75.22119814599995, 10.77306498200005 ],
              [-75.2210637849999 , 10.773380355000086],
              [-75.22099546099992, 10.773484130000043],
              [-75.22096556199995, 10.773569620000046],
              [-75.22099405999995, 10.77368477500005 ],
              [-75.22105072599993, 10.773827574000052],
              [-75.22108591599994, 10.774000340000043],
              [-75.22108964599994, 10.774416546000054],
              [-75.22107519699995, 10.774591628000053],
              [-75.22106201699995, 10.774751310000056],
              [-75.22104902799992, 10.77490868700005 ],
              [-75.22101877199992, 10.775084897000056],
              [-75.22095049099994, 10.775296465000054],
              [-75.22089906799994, 10.775399107000055],
              [-75.22077027499995, 10.775593821000086],
              [-75.22064776299993, 10.775735146000045],
              [-75.2205060419999 , 10.775936313000045],
              [-75.22038354499995, 10.776081907000048],
              [-75.22031064399994, 10.77621451500005 ],
              [-75.22028091699991, 10.776344828000049],
              [-75.21988959399994, 10.777202575000047],
              [-75.22030091799991, 10.777425462000053],
              [-75.22097984399994, 10.777716956000063],
              [-75.22186173799992, 10.778342061000046],
              [-75.22242366499995, 10.778740369000047],
              [-75.22272585299993, 10.779167847000053],
              [-75.22314448999992, 10.779661374000057],
              [-75.22351438099992, 10.779921524000088],
              [-75.2238042919999 , 10.78008778900005 ],
              [-75.2241456509999 , 10.780193221000047],
              [-75.22436713399992, 10.78029187900006 ],
              [-75.22465670799994, 10.780339492000053],
              [-75.22503251899991, 10.780395678000048],
              [-75.22592803299995, 10.780652848000045],
              [-75.2262892519999 , 10.780720645000088],
              [-75.2266452959999 , 10.780809723000061],
              [-75.22753318499991, 10.780952857000045],
              [-75.22788410699991, 10.78106082700009 ],
              [-75.2281342199999 , 10.781169463000083],
              [-75.22950881399993, 10.782035060000055],
              [-75.22966189599993, 10.782203751000054],
              [-75.22984107799994, 10.782389486000056],
              [-75.2300178619999 , 10.782575190000045],
              [-75.23036362999994, 10.783157284000083],
              [-75.23027883499992, 10.783821128000056],
              [-75.23021538699993, 10.784237836000045],
              [-75.2299476579999 , 10.785182212000052],
              [-75.2294163169999 , 10.78609502200004 ],
              [-75.22897331199994, 10.786562032000063],
              [-75.22868738299991, 10.786854245000086],
              [-75.22852055099992, 10.78726940200005 ],
              [-75.22851768899994, 10.787605665000058],
              [-75.22851349199993, 10.788098838000053],
              [-75.22850721399993, 10.78814542300006 ],
              [-75.22834160399992, 10.788194332000046],
              [-75.22805406499992, 10.788330061000067],
              [-75.22663328399994, 10.789000721000093],
              [-75.2263111179999 , 10.789153507000037],
              [-75.2251934969999 , 10.789683505000085],
              [-75.22415476899994, 10.790211715000055],
              [-75.22354813899994, 10.790872983000042],
              [-75.2232287569999 , 10.791299450000054],
              [-75.22265690699993, 10.791892487000041],
              [-75.22196708299992, 10.792644893000045],
              [-75.22193660699992, 10.792662754000048],
              [-75.2216814809999 , 10.792812273000038],
              [-75.22173104899991, 10.793169229000057],
              [-75.22177225899992, 10.793465997000055],
              [-75.22184308099992, 10.793976007000083],
              [-75.22184978899992, 10.794024317000037],
              [-75.22185118499993, 10.794034368000041],
              [-75.22185928899995, 10.794092729000056],
              [-75.22186829399993, 10.794157574000053],
              [-75.22187639799995, 10.794215935000068],
              [-75.22188562799994, 10.794282400000043],
              [-75.22192007199993, 10.79453043600006 ],
              [-75.22198258299994, 10.794980604000045],
              [-75.22206991599995, 10.79560951700006 ],
              [-75.2222080649999 , 10.79660434300007 ],
              [-75.22207292399992, 10.796673824000038],
              [-75.22202869199992, 10.796696403000055],
              [-75.22187235799993, 10.796774933000052],
              [-75.2212320029999 , 10.796909294000045],
              [-75.22110290299992, 10.796936384000048],
              [-75.22109735899994, 10.796937548000074],
              [-75.2210726639999 , 10.796942730000069],
              [-75.22093858699992, 10.796970865000048],
              [-75.22086373299993, 10.797039217000076],
              [-75.22078890499995, 10.797107546000063],
              [-75.22077113199992, 10.797123775000045],
              [-75.22076464399993, 10.797129699000038],
              [-75.22062811399991, 10.797254371000065],
              [-75.22056590199992, 10.797311179000076],
              [-75.22048351399991, 10.797386411000048],
              [-75.22047102199991, 10.797397817000046],
              [-75.21982145099992, 10.79799257500008 ],
              [-75.21937532699991, 10.798303789000045],
              [-75.21887311499995, 10.79875667500005 ],
              [-75.21836717399992, 10.799377031000063],
              [-75.21797852999993, 10.79999696100009 ],
              [-75.21755049199993, 10.800539723000043],
              [-75.21708334999994, 10.80108262400006 ],
              [-75.21705269199992, 10.801139972000044],
              [-75.21677275699994, 10.801663610000048],
              [-75.2167357639999 , 10.801733734000038],
              [-75.21638454099991, 10.80239950500004 ],
              [-75.21627000599995, 10.803134388000046],
              [-75.21627248499993, 10.803791523000086],
              [-75.21637889999994, 10.804056167000056],
              [-75.21650545499995, 10.804370883000047],
              [-75.21650958099991, 10.804381151000086],
              [-75.21650961199992, 10.804381229000057],
              [-75.21651284399991, 10.804388997000046],
              [-75.21652224499991, 10.804411590000086],
              [-75.21661068399993, 10.804624153000077],
              [-75.2166385079999 , 10.804691027000047],
              [-75.21662244799995, 10.804697357000066],
              [-75.2166056499999 , 10.804703977000088],
              [-75.21611198699992, 10.804750180000042],
              [-75.21555696399992, 10.804671412000062],
              [-75.21500443799994, 10.804709872000046],
              [-75.21433119699992, 10.804756736000058],
              [-75.21361261299995, 10.804685405000043],
              [-75.21303471899995, 10.80474412500007 ],
              [-75.21260976299993, 10.80462990600006 ],
              [-75.21217534199991, 10.80451313900005 ],
              [-75.21156752599995, 10.804507060000049],
              [-75.21126211399991, 10.804412927000044],
              [-75.21086725899994, 10.804291224000053],
              [-75.2106780499999 , 10.80429670600006 ],
              [-75.21017751699992, 10.804311208000058],
              [-75.2099066099999 , 10.80424829900005 ],
              [-75.20961993899994, 10.804181730000039],
              [-75.20944897899994, 10.804142027000069],
              [-75.20929847999992, 10.804137541000046],
              [-75.20897578199992, 10.804127913000059],
              [-75.2087544819999 , 10.804121309000038],
              [-75.20852103899995, 10.804078083000093],
              [-75.2082483399999 , 10.804027588000054],
              [-75.20805682399993, 10.804028287000051],
              [-75.2077679919999 , 10.80402934500006 ],
              [-75.20752739099993, 10.804001069000037],
              [-75.20730263399992, 10.804008064000072],
              [-75.20710091699993, 10.804014343000063],
              [-75.2067984059999 , 10.804023757000039],
              [-75.20638433899995, 10.80403664000005 ],
              [-75.20601176299994, 10.80404823400005 ],
              [-75.20577626699992, 10.804055567000091],
              [-75.20548438399993, 10.80406464400005 ],
              [-75.20508037999991, 10.804077216000053],
              [-75.2047664939999 , 10.804178229000058],
              [-75.20448046799993, 10.804170661000057],
              [-75.2043288989999 , 10.80421311300006 ],
              [-75.20403570899992, 10.804295233000062],
              [-75.2038807909999 , 10.804436673000055],
              [-75.20374656399991, 10.804559224000059],
              [-75.20369690699994, 10.804832591000093],
              [-75.2035910059999 , 10.80498145100006 ],
              [-75.20340947999995, 10.805236612000044],
              [-75.20314375999993, 10.805438031000051],
              [-75.20284544799995, 10.805664154000056],
              [-75.20261745199991, 10.805761743000062],
              [-75.20230368499995, 10.80589604100004 ],
              [-75.20212464899993, 10.806040946000053],
              [-75.20176926599993, 10.806126387000063],
              [-75.2015134639999 , 10.806276408000087],
              [-75.20122824899994, 10.80644367800005 ],
              [-75.20103786099992, 10.806555333000063],
              [-75.20070131799991, 10.806600942000046],
              [-75.20039803399993, 10.80653546700006 ],
              [-75.20012804899994, 10.806370002000051],
              [-75.19996675699991, 10.806119754000065],
              [-75.1999523689999 , 10.805965029000049],
              [-75.19991976699993, 10.80561444600005 ],
              [-75.19987695999993, 10.805154125000058],
              [-75.19983749999994, 10.804729829000053],
              [-75.19976484099993, 10.804415389000042],
              [-75.19976060199991, 10.804162958000063],
              [-75.19975629699991, 10.803906778000055],
              [-75.19975125899992, 10.803606635000051],
              [-75.1997459719999 , 10.803291769000055],
              [-75.19971150299995, 10.803101197000046],
              [-75.19966364999993, 10.802836657000057],
              [-75.19966698299993, 10.802659198000072],
              [-75.19949066099991, 10.802655963000063],
              [-75.19914474099994, 10.802649620000068],
              [-75.19886488199995, 10.802644488000055],
              [-75.19865648199993, 10.802640666000059],
              [-75.19848500499995, 10.80263751700005 ],
              [-75.1982008839999 , 10.802632309000046],
              [-75.19793484599995, 10.802584186000047],
              [-75.19777417899991, 10.802585993000037],
              [-75.1975626709999 , 10.802588378000053],
              [-75.19722849199991, 10.802592140000058],
              [-75.1969264089999 , 10.802553068000066],
              [-75.1966727009999 , 10.80256294700007 ],
              [-75.1964960709999 , 10.802569824000045],
              [-75.19631932499993, 10.802576704000046],
              [-75.1960713329999 , 10.802511354000046],
              [-75.19584332099993, 10.802507279000054],
              [-75.19566615399992, 10.80250410900004 ],
              [-75.19541160499995, 10.802499561000047],
              [-75.19514744799994, 10.80249483600005 ],
              [-75.19492079199995, 10.802490789000046],
              [-75.19467773899993, 10.802486439000063],
              [-75.19443765499994, 10.80248215000006 ],
              [-75.19421663799994, 10.802478195000049],
              [-75.19392620799994, 10.802473004000092],
              [-75.19372463099995, 10.802436666000062],
              [-75.19357874099995, 10.802441847000068],
              [-75.19334530099991, 10.802450136000061],
              [-75.1931496119999 , 10.802457082000046],
              [-75.19297244799992, 10.802398897000046],
              [-75.19283071599995, 10.802391790000058],
              [-75.19282633599994, 10.80247726500005 ],
              [-75.19281448399994, 10.802708370000062],
              [-75.19279816399995, 10.803026793000072],
              [-75.19278249399991, 10.803332442000055],
              [-75.19277064199991, 10.803563564000058],
              [-75.19275611599994, 10.803846949000047],
              [-75.19273903699991, 10.80418009500005 ],
              [-75.19272420899995, 10.80446925800004 ],
              [-75.19243073699994, 10.804454553000085],
              [-75.19183840199992, 10.804424869000059],
              [-75.19141881599995, 10.804195175000075],
              [-75.19100229599991, 10.803967162000049],
              [-75.19060563999994, 10.803938868000046],
              [-75.19048055199994, 10.80387427800008 ],
              [-75.19027205599991, 10.80377744000009 ],
              [-75.18990119799992, 10.803618282000059],
              [-75.18972035099995, 10.803581223000037],
              [-75.18955816999994, 10.803528067000059],
              [-75.18874211399992, 10.803273906000072],
              [-75.18845708899994, 10.803183774000047],
              [-75.18820444699992, 10.80308455700009 ],
              [-75.18762253999995, 10.802908917000082],
              [-75.18700128099994, 10.802714774000037],
              [-75.18628269499993, 10.802509048000047],
              [-75.18594878699992, 10.802411953000046],
              [-75.18548516599992, 10.802275576000056],
              [-75.18518839099994, 10.802217541000061],
              [-75.1849288059999 , 10.80213210900007 ],
              [-75.18475026599992, 10.80206058500005 ],
              [-75.18454864499995, 10.802007360000061],
              [-75.18420774999993, 10.801914882000062],
              [-75.18402924899993, 10.801891516000069],
              [-75.18392949899993, 10.80187987100004 ],
              [-75.18373941699991, 10.801817493000044],
              [-75.1836026599999 , 10.801803440000072],
              [-75.18351666599995, 10.80180325300006 ],
              [-75.18339375999994, 10.801789289000055],
              [-75.18328948099992, 10.801789031000055],
              [-75.18313634499992, 10.801788731000045],
              [-75.18303197999995, 10.801765596000052],
              [-75.1828996989999 , 10.801765358000068],
              [-75.18276511199991, 10.801760481000088],
              [-75.1826028349999 , 10.801760142000091],
              [-75.18246594699991, 10.80175068200009 ],
              [-75.18212954099994, 10.80175237800006 ],
              [-75.18185566099993, 10.80177941900007 ],
              [-75.18170023499994, 10.801779066000051],
              [-75.18147979399993, 10.801810853000063],
              [-75.18131729099991, 10.801828880000073],
              [-75.18109916499992, 10.801830719000066],
              [-75.18081846399991, 10.801869232000058],
              [-75.18055846699991, 10.801905471000055],
              [-75.18028695299995, 10.801957772000037],
              [-75.18004774499991, 10.802012451000053],
              [-75.17971587799991, 10.802082977000055],
              [-75.17943028199994, 10.802151302000084],
              [-75.17919111099991, 10.802217472000052],
              [-75.17889860899993, 10.802313364000042],
              [-75.17865710299992, 10.802404755000055],
              [-75.17835059799995, 10.802495960000044],
              [-75.1781461459999 , 10.802566814000045],
              [-75.17786521599993, 10.802658066000049],
              [-75.17752855599991, 10.80278603000005 ],
              [-75.17714311799995, 10.80289784300004 ],
              [-75.17668107499992, 10.803034727000068],
              [-75.17632114299994, 10.803167220000091],
              [-75.17615608099993, 10.80326560900005 ],
              [-75.1760608429999 , 10.803315947000044],
              [-75.17600276199994, 10.80333422800004 ],
              [-75.1759191029999 , 10.803386856000088],
              [-75.17581459199994, 10.803441823000071],
              [-75.1756494949999 , 10.803567826000062],
              [-75.17551012399991, 10.803659417000063],
              [-75.17471438799993, 10.803172330000052],
              [-75.17466600099993, 10.80321198200005 ],
              [-75.17442865599992, 10.80339123500005 ],
              [-75.17356374799994, 10.803655983000056],
              [-75.17316335299995, 10.803788196000085],
              [-75.17263845699995, 10.803961523000055],
              [-75.17232069199991, 10.804044944000054],
              [-75.17193096199992, 10.804106947000037],
              [-75.1715467649999 , 10.80416807000006 ],
              [-75.1707071109999 , 10.80430165200005 ],
              [-75.16993010699991, 10.804356802000086],
              [-75.16993002099991, 10.80435680800008 ],
              [-75.16909140399991, 10.804416330000038],
              [-75.16908773699993, 10.804455139000083],
              [-75.1690222169999 , 10.805148399000075],
              [-75.1688854169999 , 10.806595883000057],
              [-75.16764575399992, 10.806555321000076],
              [-75.1676456699999 , 10.80655531900004 ],
              [-75.16387868699991, 10.806432050000069],
              [-75.16250597099992, 10.806231834000073],
              [-75.16208869099995, 10.806154269000046],
              [-75.1602145089999 , 10.806148285000063],
              [-75.15884829999993, 10.806143922000047],
              [-75.15763560999994, 10.806140045000063],
              [-75.15665194399992, 10.80610120700004 ],
              [-75.15665188199995, 10.806101205000061],
              [-75.15604471299991, 10.806077233000053],
              [-75.15575793499994, 10.806066309000073],
              [-75.1556915189999 , 10.806063779000056],
              [-75.15534291999995, 10.806062655000062],
              [-75.15533254999991, 10.805821412000057],
              [-75.15525854799995, 10.805513627000039],
              [-75.15523755399994, 10.805322817000047],
              [-75.15523863699991, 10.80454555600005 ],
              [-75.15523896499991, 10.804310157000089],
              [-75.15270239999995, 10.804310505000046],
              [-75.15201385699993, 10.80431059600005 ],
              [-75.15159143199992, 10.804967929000043],
              [-75.15158703799995, 10.804976513000042],
              [-75.15156651399991, 10.805008156000042],
              [-75.1511121769999 , 10.805901834000053],
              [-75.15067774499994, 10.80668115900005 ],
              [-75.1501746369999 , 10.807573085000058],
              [-75.15016212599994, 10.807595265000089],
              [-75.14974410199994, 10.808336353000072],
              [-75.14892918399994, 10.809358681000049],
              [-75.1474963419999 , 10.811217500000055],
              [-75.14734850799994, 10.811380107000048],
              [-75.14718185499993, 10.811523831000045],
              [-75.1469857749999 , 10.811654137000062],
              [-75.14677430199993, 10.811758295000061],
              [-75.14655098899993, 10.811834557000054],
              [-75.14641663999993, 10.81186189300007 ],
              [-75.14631957699993, 10.81188164200006 ],
              [-75.14504292299995, 10.811916989000053],
              [-75.14379261499994, 10.811927556000057],
              [-75.14219862999994, 10.811930629000074],
              [-75.14141514499994, 10.811801119000052],
              [-75.14012103199991, 10.811577201000091],
              [-75.1377444499999 , 10.811044606000053],
              [-75.13633148699995, 10.810705103000089],
              [-75.13495058899991, 10.810422716000062],
              [-75.13475610899991, 10.810359677000065],
              [-75.13458557399991, 10.810243659000037],
              [-75.13218481599995, 10.80783886100005 ],
              [-75.13196382699994, 10.80770020600005 ],
              [-75.13174650699995, 10.807619289000058],
              [-75.13151752699991, 10.807581089000053],
              [-75.13026804799995, 10.807570221000049],
              [-75.12856828299994, 10.80752659600006 ],
              [-75.12839414699994, 10.80752280900009 ],
              [-75.12834372999993, 10.807521713000085]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08558",
      "properties": {"id": "08558", "iso": "POL", "name": "POLONUEVO"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.84378259699992, 10.812610824000046],
              [-74.8435846989999 , 10.812608860000068],
              [-74.84358507299993, 10.812613805000069],
              [-74.84291791099992, 10.812602076000076],
              [-74.8427961189999 , 10.812599935000037],
              [-74.84211806199994, 10.812601111000049],
              [-74.84196609999992, 10.812601374000053],
              [-74.84161585699991, 10.812601982000047],
              [-74.84073812299994, 10.812603504000037],
              [-74.84046227999994, 10.81259996500006 ],
              [-74.84040897999995, 10.812599281000075],
              [-74.84000379299994, 10.81259408200009 ],
              [-74.83951820899995, 10.812587852000092],
              [-74.8394970999999 , 10.812587633000078],
              [-74.83937990699991, 10.812586415000055],
              [-74.83936079999995, 10.812585518000049],
              [-74.8393315159999 , 10.812584142000048],
              [-74.83812668299993, 10.812570368000081],
              [-74.83812892999993, 10.812559061000059],
              [-74.83812973599993, 10.812555003000057],
              [-74.83807738799993, 10.812554164000062],
              [-74.83791534799991, 10.812552555000082],
              [-74.83753866399991, 10.812548809000077],
              [-74.837473        , 10.812548156000048],
              [-74.83644675699992, 10.812537950000092],
              [-74.83592117899991, 10.81253272300006 ],
              [-74.83523879499995, 10.81252593000005 ],
              [-74.83489889299995, 10.81252255000004 ],
              [-74.83264567399993, 10.812500123000063],
              [-74.83258581299992, 10.812499529000092],
              [-74.83189540799992, 10.812492642000052],
              [-74.83189443099991, 10.812492636000059],
              [-74.83164259299991, 10.812490471000046],
              [-74.8314442379999 , 10.812488761000054],
              [-74.8311155099999 , 10.812485938000066],
              [-74.83071091699992, 10.812482467000052],
              [-74.83065232399991, 10.81248196000007 ],
              [-74.83063964099995, 10.812481845000093],
              [-74.83037626999993, 10.812479581000048],
              [-74.8286512439999 , 10.81246475000006 ],
              [-74.8233758049999 , 10.81241930100009 ],
              [-74.82337542599993, 10.812419299000055],
              [-74.82262817399993, 10.812412857000083],
              [-74.82245633999992, 10.812411376000057],
              [-74.82235565799994, 10.812410513000088],
              [-74.80838973699991, 10.812289734000046],
              [-74.80825632299991, 10.81228857700006 ],
              [-74.8082083729999 , 10.812288159000047],
              [-74.80793126499992, 10.812285752000037],
              [-74.80784933799993, 10.812285041000052],
              [-74.80782955299992, 10.812284868000063],
              [-74.8077956489999 , 10.81228457900005 ],
              [-74.80779212999994, 10.812284859000044],
              [-74.80779211899994, 10.812284542000043],
              [-74.8077919879999 , 10.812280782000073],
              [-74.80779099299991, 10.812251883000044],
              [-74.8077829639999 , 10.81201903100009 ],
              [-74.80778012899992, 10.811936828000057],
              [-74.80777890299993, 10.811901272000057],
              [-74.80781906399994, 10.810738981000043],
              [-74.80885590299994, 10.810629188000064],
              [-74.80885946699993, 10.810480064000046],
              [-74.80886400799994, 10.810290143000088],
              [-74.8089056039999 , 10.808549931000073],
              [-74.80897989699992, 10.807981190000078],
              [-74.80907390199991, 10.807261537000045],
              [-74.80947105899992, 10.807443965000061],
              [-74.80976527999991, 10.807579111000052],
              [-74.8107551999999 , 10.808033815000044],
              [-74.81082613799992, 10.807975507000037],
              [-74.81124545099993, 10.807595623000054],
              [-74.8114632029999 , 10.807294372000058],
              [-74.81159545499992, 10.80708099800006 ],
              [-74.81179186199995, 10.806799990000059],
              [-74.81194071399995, 10.806570857000054],
              [-74.81208401799995, 10.806385310000053],
              [-74.8122951329999 , 10.806180453000081],
              [-74.81246291999992, 10.806048267000051],
              [-74.81267295599991, 10.805896260000054],
              [-74.81287786899992, 10.805767125000045],
              [-74.81305536499991, 10.80561445300009 ],
              [-74.81318089199993, 10.805502087000093],
              [-74.8133513269999 , 10.805353869000044],
              [-74.81349036899991, 10.805212379000068],
              [-74.81354840499995, 10.805153321000091],
              [-74.81359883899995, 10.805084621000049],
              [-74.81368473399993, 10.804967617000045],
              [-74.8138189149999 , 10.804739495000092],
              [-74.81383168299993, 10.804717788000062],
              [-74.81393870199992, 10.804487807000044],
              [-74.81404822899992, 10.804248684000072],
              [-74.81413409399994, 10.80402977500006 ],
              [-74.81420052199991, 10.80385184000005 ],
              [-74.8142238829999 , 10.803775602000087],
              [-74.81426281899991, 10.803648534000047],
              [-74.81429600199994, 10.803504406000059],
              [-74.8143234339999 , 10.803300376000038],
              [-74.8143256059999 , 10.803194690000055],
              [-74.81431729299993, 10.80303361600005 ],
              [-74.81431621199994, 10.803021280000053],
              [-74.81430034099992, 10.802840179000043],
              [-74.81443102399993, 10.802223501000071],
              [-74.81445951799992, 10.80208904300008 ],
              [-74.81446767099993, 10.802050577000045],
              [-74.81510531399994, 10.802132578000055],
              [-74.81512118199993, 10.801917508000088],
              [-74.81513159399992, 10.801776377000067],
              [-74.81514464299994, 10.801599498000087],
              [-74.8151452589999 , 10.80159115400005 ],
              [-74.81518009699994, 10.801118969000072],
              [-74.81521136099991, 10.800695216000065],
              [-74.81523916999993, 10.800318289000074],
              [-74.81524135199993, 10.80028871400009 ],
              [-74.81524212799991, 10.800278192000064],
              [-74.81524617299993, 10.80022336400009 ],
              [-74.81525026499992, 10.800167897000051],
              [-74.8153342629999 , 10.800177717000054],
              [-74.81567932599995, 10.800218057000052],
              [-74.81623577099992, 10.799385836000056],
              [-74.81663831299994, 10.799368787000049],
              [-74.81701051399995, 10.797847895000075],
              [-74.81702447099991, 10.797790862000056],
              [-74.81709001699994, 10.797523023000053],
              [-74.81693746299993, 10.795456559000058],
              [-74.81678577799994, 10.793401930000073],
              [-74.81672631899994, 10.79259653300005 ],
              [-74.81672614599995, 10.79259419400006 ],
              [-74.81670375699991, 10.792466639000054],
              [-74.8159748189999 , 10.792632138000045],
              [-74.81494128399993, 10.79286680000007 ],
              [-74.81360929399995, 10.793169206000073],
              [-74.8129869309999 , 10.793310503000043],
              [-74.81047098999994, 10.793881704000057],
              [-74.81012088499995, 10.793961189000072],
              [-74.80887305699991, 10.794244468000045],
              [-74.80903544899991, 10.79340912300006 ],
              [-74.80904395399995, 10.793247748000056],
              [-74.8090484789999 , 10.793161904000044],
              [-74.80909379299993, 10.792302207000091],
              [-74.80917211699995, 10.790816238000048],
              [-74.80917644899995, 10.790734082000085],
              [-74.80755418699994, 10.790374449000069],
              [-74.80757365699992, 10.789102101000083],
              [-74.80765664699993, 10.789101904000063],
              [-74.80765624599991, 10.78903123300006 ],
              [-74.8076566979999 , 10.788926824000043],
              [-74.80765937799993, 10.788698682000074],
              [-74.80767156999991, 10.788483364000058],
              [-74.8076792089999 , 10.78821756700006 ],
              [-74.80771213799994, 10.787971863000053],
              [-74.80772895099994, 10.787783317000049],
              [-74.8077632369999 , 10.78752029900005 ],
              [-74.80786468899993, 10.787240090000068],
              [-74.80796001399995, 10.786860629000046],
              [-74.8079960579999 , 10.786696517000053],
              [-74.80808884899994, 10.786125794000043],
              [-74.8081557559999 , 10.78562181500007 ],
              [-74.80819654699991, 10.785124704000054],
              [-74.80827878299993, 10.78464286600007 ],
              [-74.80833174499992, 10.784299503000057],
              [-74.80833955599991, 10.78400748200005 ],
              [-74.80837743099994, 10.783754912000063],
              [-74.80838780399995, 10.78340423800006 ],
              [-74.80840229199993, 10.78315448300009 ],
              [-74.80843064999993, 10.782883948000062],
              [-74.80845999299993, 10.78265910400006 ],
              [-74.80849478999994, 10.78257620900007 ],
              [-74.8084950299999 , 10.78249095600006 ],
              [-74.80850178499992, 10.782367415000067],
              [-74.80850456999991, 10.782316483000045],
              [-74.80853774499991, 10.782044230000054],
              [-74.80855514699994, 10.781835216000047],
              [-74.80855047199992, 10.781739968000068],
              [-74.80853724399992, 10.78150398200006 ],
              [-74.8084883219999 , 10.779843649000043],
              [-74.80865706799995, 10.77980621100005 ],
              [-74.80888608399994, 10.779755080000086],
              [-74.80921172599994, 10.77963257500005 ],
              [-74.80943451099995, 10.779554531000088],
              [-74.80964537499995, 10.77946423700007 ],
              [-74.80982216299992, 10.779353964000052],
              [-74.80999782099991, 10.779250475000083],
              [-74.81021147799993, 10.779108232000056],
              [-74.81033889899993, 10.779011104000062],
              [-74.81048033399992, 10.77895862400004 ],
              [-74.8106423669999 , 10.778901147000056],
              [-74.81078705699991, 10.778841054000054],
              [-74.81098994899992, 10.778721039000061],
              [-74.81129864299993, 10.778478842000084],
              [-74.81141236299993, 10.778364646000057],
              [-74.8115058919999 , 10.778249138000092],
              [-74.81159595899993, 10.778138484000067],
              [-74.81164982699994, 10.778086768000037],
              [-74.81165772899993, 10.778079183000045],
              [-74.81167344499994, 10.778064096000037],
              [-74.8116894929999 , 10.778048689000059],
              [-74.81177500299992, 10.777932908000082],
              [-74.8117899799999 , 10.777912628000081],
              [-74.81195654499993, 10.77768708700006 ],
              [-74.81197051399994, 10.777668174000041],
              [-74.8119446739999 , 10.777499429000045],
              [-74.81197256199994, 10.777021683000044],
              [-74.81206172399993, 10.776346243000091],
              [-74.8120929609999 , 10.775749965000045],
              [-74.81217648199993, 10.775738988000057],
              [-74.81241968599994, 10.775670166000054],
              [-74.8127754759999 , 10.775595864000081],
              [-74.81283720699992, 10.775582972000052],
              [-74.81332008099992, 10.775484840000047],
              [-74.81335022999991, 10.775478003000046],
              [-74.8137521029999 , 10.775386833000084],
              [-74.81407488999992, 10.775317087000076],
              [-74.81407491699991, 10.775317080000093],
              [-74.81420591999995, 10.775288773000057],
              [-74.81508818499992, 10.775117851000061],
              [-74.81538098499993, 10.77505472200005 ],
              [-74.81550932599993, 10.775027051000052],
              [-74.81584332299991, 10.77495082300004 ],
              [-74.81620834599994, 10.774900872000046],
              [-74.81621733899993, 10.774899643000083],
              [-74.81622846999994, 10.774899960000084],
              [-74.81628907999993, 10.774901688000057],
              [-74.81656607399992, 10.774909578000063],
              [-74.81686443299992, 10.77492587900008 ],
              [-74.8168644889999 , 10.774925883000037],
              [-74.81694390199993, 10.774930220000044],
              [-74.8171258079999 , 10.774940749000052],
              [-74.81724180399993, 10.774947463000046],
              [-74.81767914199992, 10.774960139000086],
              [-74.8176791919999 , 10.774960141000065],
              [-74.81769952499991, 10.774960728000053],
              [-74.81770024399992, 10.774961026000085],
              [-74.81801950599993, 10.775092847000053],
              [-74.81803303499993, 10.775098114000059],
              [-74.8184412729999 , 10.775257044000057],
              [-74.81905573799992, 10.775492607000047],
              [-74.81945934399994, 10.77565684800004 ],
              [-74.8194821809999 , 10.775615966000089],
              [-74.81965824999992, 10.77530080200006 ],
              [-74.81991870399992, 10.774861994000048],
              [-74.82046484499995, 10.773908898000059],
              [-74.82066373499993, 10.773549259000049],
              [-74.82111578099995, 10.772736463000058],
              [-74.82126503599994, 10.77273450000007 ],
              [-74.82135564099991, 10.772733308000056],
              [-74.82150591699991, 10.772731330000056],
              [-74.82180608599992, 10.772687503000043],
              [-74.82190408799994, 10.772603554000057],
              [-74.8219462429999 , 10.77256744400006 ],
              [-74.82241996099992, 10.772197559000062],
              [-74.82261810799992, 10.77205396100004 ],
              [-74.82265201799993, 10.772029387000089],
              [-74.82268023699993, 10.77196383900008 ],
              [-74.82273869799991, 10.771828051000057],
              [-74.8228926779999 , 10.771502443000088],
              [-74.82300850299993, 10.771257527000046],
              [-74.82322545699992, 10.770859530000052],
              [-74.82335550999994, 10.770571885000038],
              [-74.82349522699991, 10.77027464300005 ],
              [-74.82357212199992, 10.770035018000044],
              [-74.82367788499994, 10.769718700000055],
              [-74.82376907499992, 10.769383261000087],
              [-74.82386004799991, 10.768961624000042],
              [-74.82393160799995, 10.768525669000041],
              [-74.82396881999995, 10.767891083000052],
              [-74.82398272599994, 10.767653985000038],
              [-74.82399149899993, 10.76747556500004 ],
              [-74.82401499499991, 10.766997840000045],
              [-74.82403787099992, 10.766456651000055],
              [-74.82396031099995, 10.766428105000045],
              [-74.82352426699993, 10.766362120000053],
              [-74.82353868399991, 10.766317790000073],
              [-74.8235412329999 , 10.766309946000092],
              [-74.8235819439999 , 10.766184793000093],
              [-74.82363965699994, 10.76602183500006 ],
              [-74.82369741399992, 10.765878030000067],
              [-74.82371237899991, 10.765851543000053],
              [-74.82362777499992, 10.76577349300004 ],
              [-74.82369697999991, 10.765701105000062],
              [-74.82379605499995, 10.765525598000067],
              [-74.82381333099994, 10.765494999000055],
              [-74.82398947199994, 10.765176484000051],
              [-74.8240532769999 , 10.76506110300005 ],
              [-74.82415111299991, 10.764854221000064],
              [-74.82424653999993, 10.764682453000091],
              [-74.82432930699991, 10.76453347000006 ],
              [-74.82435293999993, 10.764462782000066],
              [-74.82443752299992, 10.764280951000046],
              [-74.82452863499992, 10.763984748000041],
              [-74.82465002599992, 10.763557301000048],
              [-74.82477155299995, 10.763183670000046],
              [-74.82488969499991, 10.762816773000054],
              [-74.8249740899999 , 10.76255758700006 ],
              [-74.82507207299994, 10.762291636000043],
              [-74.82507691399991, 10.76228111100005 ],
              [-74.82516341999991, 10.762092972000062],
              [-74.82517369699991, 10.762059805000092],
              [-74.8252207829999 , 10.761907850000057],
              [-74.82527130999995, 10.761709285000052],
              [-74.82535901599994, 10.761413093000044],
              [-74.82544518499992, 10.761169124000048],
              [-74.8255076339999 , 10.760992306000048],
              [-74.82562915299991, 10.760615309000059],
              [-74.82576788099993, 10.760322358000053],
              [-74.82579467599993, 10.76025117100005 ],
              [-74.82585912399992, 10.76007996900006 ],
              [-74.82593697899995, 10.759928425000055],
              [-74.82602834999994, 10.759739851000063],
              [-74.8261059749999 , 10.759494136000058],
              [-74.82617594599992, 10.75936335700004 ],
              [-74.82632487399991, 10.759067013000049],
              [-74.82637082199994, 10.758991622000053],
              [-74.82641520699991, 10.75891880200004 ],
              [-74.82652346299994, 10.758680857000058],
              [-74.82666325199995, 10.75835763300006 ],
              [-74.82683027699994, 10.758043307000037],
              [-74.82705149399993, 10.757639160000053],
              [-74.82721851899993, 10.757324838000045],
              [-74.82735847599992, 10.757068876000062],
              [-74.82739536999992, 10.757003467000061],
              [-74.82746232799991, 10.756884759000059],
              [-74.82758791599991, 10.756695466000053],
              [-74.82760239499993, 10.756673644000045],
              [-74.82780120399991, 10.756377178000037],
              [-74.82787356999995, 10.756296281000061],
              [-74.82805895299992, 10.756071605000045],
              [-74.8281450799999 , 10.755980117000092],
              [-74.82832130299994, 10.755792923000058],
              [-74.82832139499993, 10.755792649000057],
              [-74.82838575799991, 10.755603214000075],
              [-74.82841584899995, 10.755514651000055],
              [-74.8284968449999 , 10.755258835000063],
              [-74.82875396599991, 10.754498235000085],
              [-74.82877146699991, 10.754446461000043],
              [-74.82893798099991, 10.753930337000043],
              [-74.82920807799991, 10.753122460000043],
              [-74.82927216099995, 10.75292691900006 ],
              [-74.82943312499992, 10.752435773000059],
              [-74.82959960499994, 10.751906194000071],
              [-74.82986537599993, 10.751183535000052],
              [-74.82989497299991, 10.751107902000058],
              [-74.83007266899995, 10.75065384900006 ],
              [-74.83047369099995, 10.749612459000048],
              [-74.8306896869999 , 10.749078299000075],
              [-74.83087484299995, 10.748620395000046],
              [-74.83097849599994, 10.748372553000081],
              [-74.83103600999993, 10.748235033000071],
              [-74.83134274199995, 10.74745985900006 ],
              [-74.83151261099994, 10.74704070100006 ],
              [-74.83174081399994, 10.746487700000046],
              [-74.83174009599992, 10.746383969000078],
              [-74.8317322659999 , 10.745253516000048],
              [-74.83172212599993, 10.743845390000047],
              [-74.83187960899994, 10.743845127000043],
              [-74.83369132199994, 10.743842068000049],
              [-74.83381160399995, 10.74349568200006 ],
              [-74.83399203999994, 10.742982763000043],
              [-74.83420591999993, 10.74238656500006 ],
              [-74.83429279599994, 10.742140093000046],
              [-74.83433059499993, 10.742064872000071],
              [-74.83434637399995, 10.742033473000049],
              [-74.83435959699995, 10.74194026400005 ],
              [-74.8343660509999 , 10.741777442000057],
              [-74.83437546099992, 10.741584188000047],
              [-74.83441478899994, 10.741134805000058],
              [-74.83445659699993, 10.74036265500007 ],
              [-74.83450748799993, 10.739716540000074],
              [-74.83453790699991, 10.739330327000062],
              [-74.8345381279999 , 10.73932751600006 ],
              [-74.8345465619999 , 10.73927548800009 ],
              [-74.83454746799993, 10.739269896000053],
              [-74.83456132499992, 10.73917952100004 ],
              [-74.83456202199994, 10.739179535000062],
              [-74.83459109499995, 10.73897796700004 ],
              [-74.83460281899994, 10.73890381700005 ],
              [-74.83460773099995, 10.738872744000048],
              [-74.83464741099993, 10.738621758000079],
              [-74.83468364899994, 10.73831218500004 ],
              [-74.83476670899995, 10.737886027000059],
              [-74.83488029099993, 10.737552967000056],
              [-74.83495379199991, 10.737339810000037],
              [-74.83501054499993, 10.73715996900006 ],
              [-74.83503390399994, 10.737080047000063],
              [-74.8350404169999 , 10.736993509000058],
              [-74.83504284999992, 10.73697009700004 ],
              [-74.83504698899992, 10.736930262000044],
              [-74.83507286999992, 10.73685608900007 ],
              [-74.83507371799993, 10.736853659000076],
              [-74.83515379599993, 10.73658058600006 ],
              [-74.8352170739999 , 10.736317536000058],
              [-74.83522685399993, 10.736191054000074],
              [-74.8352138219999 , 10.736157481000077],
              [-74.8351997549999 , 10.736121240000045],
              [-74.83516592599995, 10.736051438000061],
              [-74.83511193599992, 10.735991669000043],
              [-74.83508155599992, 10.735955139000055],
              [-74.83508815399995, 10.73590187800005 ],
              [-74.83510821099992, 10.735848584000053],
              [-74.83513160899992, 10.735785299000042],
              [-74.83517496199994, 10.735681554000053],
              [-74.8353557289999 , 10.735248976000037],
              [-74.83550596399994, 10.734772733000057],
              [-74.8356327269999 , 10.734329829000046],
              [-74.83573593499995, 10.733886977000054],
              [-74.8358021439999 , 10.733450876000063],
              [-74.83583834899991, 10.73312799100006 ],
              [-74.83592419099995, 10.73312512800004 ],
              [-74.83648440199994, 10.73310643800005 ],
              [-74.83680736799994, 10.733072364000066],
              [-74.83684727399992, 10.732882582000059],
              [-74.83692024399994, 10.732459776000042],
              [-74.83701318199991, 10.731950396000059],
              [-74.83702196799993, 10.731909460000054],
              [-74.83710963999994, 10.731500907000054],
              [-74.83717623799993, 10.731097262000048],
              [-74.8371762509999 , 10.731097187000046],
              [-74.83720576499991, 10.730918311000039],
              [-74.83726227699992, 10.73064196300004 ],
              [-74.83726418999993, 10.730641465000076],
              [-74.83733962499991, 10.730621805000055],
              [-74.83744050899992, 10.730594936000045],
              [-74.83756490099995, 10.730548043000056],
              [-74.83785404799994, 10.730447497000057],
              [-74.83803898999992, 10.730393796000044],
              [-74.83816346499992, 10.730380179000065],
              [-74.8382240389999 , 10.73038002900006 ],
              [-74.83830487599994, 10.73040977900007 ],
              [-74.83838576599993, 10.730459498000073],
              [-74.83852064399991, 10.730568984000058],
              [-74.83888091299991, 10.730900018000057],
              [-74.83897936599993, 10.73099047900007 ],
              [-74.83901797099992, 10.73101896500009 ],
              [-74.83910073299995, 10.731080030000044],
              [-74.83914431999995, 10.731016694000061],
              [-74.83920120699992, 10.730890100000067],
              [-74.83921439399995, 10.730780248000087],
              [-74.83923084899993, 10.730633787000045],
              [-74.83924017899994, 10.730584210000075],
              [-74.8392640539999 , 10.73045733300006 ],
              [-74.8393171319999 , 10.730154373000062],
              [-74.83938387099994, 10.72993124900006 ],
              [-74.83950780099991, 10.729701326000054],
              [-74.83953562399995, 10.72965982900007 ],
              [-74.83970224399991, 10.729411326000047],
              [-74.83982962899995, 10.72921800100005 ],
              [-74.8399696969999 , 10.729064667000046],
              [-74.84006118099995, 10.728964519000044],
              [-74.84010362499993, 10.728802746000042],
              [-74.84015116899991, 10.72862153300008 ],
              [-74.8401784969999 , 10.728502108000043],
              [-74.84020966299994, 10.728365916000087],
              [-74.84021258699994, 10.728353137000056],
              [-74.8402144129999 , 10.728345171000058],
              [-74.84040740699993, 10.727482801000065],
              [-74.84042592599991, 10.727394453000045],
              [-74.84053375799994, 10.726880162000043],
              [-74.8406400849999 , 10.726344126000072],
              [-74.84066997699995, 10.726187645000039],
              [-74.84058234199995, 10.726131291000058],
              [-74.84036323299995, 10.72598208100004 ],
              [-74.84006630399995, 10.725796495000054],
              [-74.84006627199994, 10.725796475000038],
              [-74.84000260499994, 10.725756682000053],
              [-74.83957455699993, 10.725484863000077],
              [-74.83940600999995, 10.725368803000038],
              [-74.83940744099993, 10.725366074000078],
              [-74.83948638599992, 10.72521553000007 ],
              [-74.8396771429999 , 10.72479908400004 ],
              [-74.83985762799995, 10.724312782000084],
              [-74.83988115999995, 10.724244978000058],
              [-74.84016163699994, 10.723436826000068],
              [-74.84043151299994, 10.722716564000052],
              [-74.84045243599991, 10.722660733000055],
              [-74.8405461559999 , 10.722464167000055],
              [-74.84069674199992, 10.722131015000059],
              [-74.8408473419999 , 10.721804519000045],
              [-74.8410280789999 , 10.721418053000093],
              [-74.84105005799995, 10.721372871000085],
              [-74.84118206999995, 10.721101531000045],
              [-74.84124205499995, 10.720935054000051],
              [-74.84135248899992, 10.720628564000037],
              [-74.84141263799995, 10.720462026000064],
              [-74.84139269299993, 10.720446217000074],
              [-74.84129125399994, 10.720365823000066],
              [-74.8411900609999 , 10.720269568000049],
              [-74.8410720679999 , 10.720183336000048],
              [-74.84075846099995, 10.719921216000046],
              [-74.84066733599991, 10.719814951000046],
              [-74.8405727359999 , 10.71966543600007 ],
              [-74.8405219469999 , 10.71953910600007 ],
              [-74.8404913839999 , 10.719429366000043],
              [-74.84047435899993, 10.719349541000042],
              [-74.84047391299993, 10.719173169000044],
              [-74.84048026399995, 10.719023403000051],
              [-74.8404869339999 , 10.718968295000082],
              [-74.84051652399995, 10.718723810000085],
              [-74.84054903799995, 10.718274480000048],
              [-74.84055413099992, 10.718239889000074],
              [-74.8406019709999 , 10.717914948000043],
              [-74.8406046309999 , 10.717904831000055],
              [-74.84066707199992, 10.717667794000079],
              [-74.8406686319999 , 10.717661874000044],
              [-74.84078871299994, 10.717242278000072],
              [-74.84090530799995, 10.716776099000072],
              [-74.84095217299995, 10.71667947800006 ],
              [-74.84100492899995, 10.716250063000075],
              [-74.84109065899992, 10.715554343000065],
              [-74.84110303099993, 10.715472119000083],
              [-74.84118332199995, 10.714938474000064],
              [-74.8411911789999 , 10.714881364000064],
              [-74.8412758419999 , 10.714266032000069],
              [-74.84133132799991, 10.713587031000088],
              [-74.84138657199992, 10.712959325000043],
              [-74.84139020799995, 10.712918         ],
              [-74.84144614799993, 10.712418694000064],
              [-74.84149856999994, 10.711856174000047],
              [-74.84152802099993, 10.711526646000038],
              [-74.84157076299994, 10.711130533000073],
              [-74.84160683599993, 10.710757734000083],
              [-74.84162633599993, 10.710484808000047],
              [-74.84167373899993, 10.710484694000058],
              [-74.84168952399995, 10.710484653000037],
              [-74.84191571399992, 10.710484094000037],
              [-74.84197963999992, 10.71048060600009 ],
              [-74.84198601899993, 10.710476904000075],
              [-74.84201995899991, 10.710457212000051],
              [-74.84207030499994, 10.710407172000089],
              [-74.84211386899995, 10.710337180000067],
              [-74.84213265799991, 10.710262063000073],
              [-74.8421338579999 , 10.710257266000042],
              [-74.84214652399993, 10.709944422000092],
              [-74.84213907899994, 10.709661579000056],
              [-74.84214546599992, 10.709525124000038],
              [-74.84216520299992, 10.709345375000055],
              [-74.84218845399994, 10.70922551600006 ],
              [-74.84220886199995, 10.709170304000054],
              [-74.84223523899993, 10.709098943000072],
              [-74.84228200399991, 10.708962391000057],
              [-74.84230200899992, 10.708889129000056],
              [-74.84231185199991, 10.708789272000047],
              [-74.84230780299993, 10.70851973100008 ],
              [-74.84230298499995, 10.70840062700006 ],
              [-74.84229703499994, 10.70825353500004 ],
              [-74.8422896269999 , 10.70798400600006 ],
              [-74.8422756029999 , 10.707761076000054],
              [-74.84229141799995, 10.707462816000088],
              [-74.84229166199992, 10.707458208000048],
              [-74.84229825499995, 10.707392008000056],
              [-74.8423067679999 , 10.707392165000044],
              [-74.84238962499995, 10.707398073000093],
              [-74.84240692499992, 10.70739930600007 ],
              [-74.8425424319999 , 10.707408971000064],
              [-74.84289439899993, 10.707522875000052],
              [-74.8433096359999 , 10.707626186000084],
              [-74.84379170099993, 10.707729337000046],
              [-74.84414366699991, 10.707843240000045],
              [-74.84463646699993, 10.708022873000061],
              [-74.84481947599994, 10.708074593000049],
              [-74.84500595899993, 10.70811238300007 ],
              [-74.84516072899993, 10.70812591300006 ],
              [-74.84527682099991, 10.708139538000069],
              [-74.84535419899993, 10.70814282300006 ],
              [-74.84544573699992, 10.708184335000055],
              [-74.8460018529999 , 10.708367285000065],
              [-74.84614479699991, 10.708416320000083],
              [-74.84614483399992, 10.70841633200007 ],
              [-74.84646278199995, 10.70852539000009 ],
              [-74.84650519599995, 10.708539939000048],
              [-74.84677270999993, 10.70863317900006 ],
              [-74.84685718299994, 10.708660791000057],
              [-74.84695919299992, 10.708670975000075],
              [-74.84729682199992, 10.708677088000059],
              [-74.8476097329999 , 10.708645007000086],
              [-74.84767993699995, 10.708634585000084],
              [-74.84782417699995, 10.70861317400005 ],
              [-74.8479155789999 , 10.708599031000062],
              [-74.84800698399994, 10.70858836900004 ],
              [-74.84806873399992, 10.708581673000083],
              [-74.84810543099991, 10.708577693000052],
              [-74.848225        , 10.708577392000052],
              [-74.84854145599991, 10.708555735000061],
              [-74.84879806499993, 10.70850988300009 ],
              [-74.8488849659999 , 10.70848723000006 ],
              [-74.84904056199991, 10.708446671000047],
              [-74.84923505199993, 10.708381184000075],
              [-74.84931112099991, 10.708355570000037],
              [-74.8493912589999 , 10.708307327000057],
              [-74.84939462199992, 10.708305300000063],
              [-74.8494140279999 , 10.708275883000056],
              [-74.84941529399993, 10.708273965000046],
              [-74.84945923499993, 10.70828446300004 ],
              [-74.84959858699995, 10.708317760000057],
              [-74.84961777899991, 10.70831695000004 ],
              [-74.84980307199993, 10.708309143000065],
              [-74.84992998999991, 10.708304913000063],
              [-74.85017907999992, 10.708310384000072],
              [-74.85030839099994, 10.708292223000058],
              [-74.85034918199995, 10.708276243000057],
              [-74.85047311499994, 10.708227694000072],
              [-74.85059655899994, 10.708152238000082],
              [-74.85074158399993, 10.708063589000062],
              [-74.85089921799994, 10.70801066200005 ],
              [-74.8509881789999 , 10.707978584000045],
              [-74.85106016299994, 10.707952627000054],
              [-74.85113226099992, 10.70795679300005 ],
              [-74.85116245499995, 10.707951777000062],
              [-74.85117370099994, 10.707949908000046],
              [-74.85120528499993, 10.707944662000045],
              [-74.85129687999995, 10.707929446000037],
              [-74.85137914699993, 10.70792971800006 ],
              [-74.85144965699993, 10.70792995100004 ],
              [-74.85152255899993, 10.70791857000006 ],
              [-74.85162133199992, 10.707902629000046],
              [-74.85177406699995, 10.707914755000047],
              [-74.85189380899993, 10.707947682000054],
              [-74.85201592999994, 10.707971326000063],
              [-74.85219934699995, 10.70793939400005 ],
              [-74.8523077019999 , 10.707865385000048],
              [-74.85264616499995, 10.70785023700006 ],
              [-74.85271835299994, 10.707872593000047],
              [-74.85274476399991, 10.707880773000056],
              [-74.85285753499994, 10.707890439000039],
              [-74.85297731599991, 10.70791175000005 ],
              [-74.8531347679999 , 10.707924379000076],
              [-74.85314381899991, 10.707925106000062],
              [-74.85320370099993, 10.707915443000047],
              [-74.85324065799995, 10.707909477000044],
              [-74.85325668199994, 10.70790689100005 ],
              [-74.85341670899993, 10.707840024000063],
              [-74.85359790799993, 10.707768584000064],
              [-74.85380786199994, 10.70770923300006 ],
              [-74.85386607399994, 10.707692780000059],
              [-74.8540260339999 , 10.707646830000044],
              [-74.85416015099992, 10.707598469000061],
              [-74.85432464399992, 10.70760133500005 ],
              [-74.85456636399994, 10.707699730000058],
              [-74.85471429699993, 10.707735076000063],
              [-74.85487860599994, 10.707791391000058],
              [-74.85514843499993, 10.707917765000047],
              [-74.85536668799995, 10.70800678900008 ],
              [-74.85553798599994, 10.708081715000048],
              [-74.8556236359999 , 10.708143126000039],
              [-74.8556552039999 , 10.708165760000043],
              [-74.85586405599992, 10.708254749000048],
              [-74.85598620999991, 10.708269094000059],
              [-74.85609670699995, 10.708255518000044],
              [-74.85624254099992, 10.708216488000062],
              [-74.85636048799995, 10.708086739000066],
              [-74.85643583199993, 10.70804515900005 ],
              [-74.85652775099993, 10.707966450000072],
              [-74.85666844899993, 10.707835677000048],
              [-74.85674699899994, 10.70776266900009 ],
              [-74.8568975419999 , 10.707721332000062],
              [-74.85696823299992, 10.707665793000047],
              [-74.85705330799993, 10.707526642000062],
              [-74.85712178999995, 10.707429263000051],
              [-74.85729812799991, 10.707404280000048],
              [-74.85742492799994, 10.70743490700005 ],
              [-74.85760330799991, 10.707500562000064],
              [-74.85764538099994, 10.707523924000043],
              [-74.85776750299993, 10.707591730000047],
              [-74.85787062599991, 10.707673404000047],
              [-74.85791740999991, 10.707743300000061],
              [-74.85803236599992, 10.707813417000068],
              [-74.8581239689999 , 10.707839291000084],
              [-74.85823358699992, 10.708097696000038],
              [-74.85826573299994, 10.708323302000053],
              [-74.85823405499991, 10.708650986000066],
              [-74.85818405099991, 10.708836803000054],
              [-74.85802540499992, 10.709168721000083],
              [-74.85799644199994, 10.709389478000048],
              [-74.85801215999993, 10.709605727000053],
              [-74.85791054099991, 10.709761153000045],
              [-74.8578022609999 , 10.709800321000046],
              [-74.85762775899991, 10.70995550300006 ],
              [-74.85759186799993, 10.710139041000048],
              [-74.85762653099994, 10.710315837000053],
              [-74.85774122299995, 10.710464995000052],
              [-74.85781361599993, 10.71060704200005 ],
              [-74.85781088999994, 10.710718621000069],
              [-74.8577822769999 , 10.710834767000051],
              [-74.85776344399994, 10.710875835000081],
              [-74.85768753899993, 10.711041357000056],
              [-74.85764479399995, 10.711166753000043],
              [-74.85762801699991, 10.711243455000044],
              [-74.85759702999991, 10.711385121000092],
              [-74.85759850099993, 10.711643172000038],
              [-74.85761881399992, 10.711891988000048],
              [-74.85771398499992, 10.712250311000048],
              [-74.85776979299993, 10.712431823000088],
              [-74.85785569599994, 10.712750594000056],
              [-74.85791602299992, 10.712985593000042],
              [-74.85800079099994, 10.71309109200007 ],
              [-74.85802839299993, 10.71312544500006 ],
              [-74.85806802199994, 10.713132654000049],
              [-74.85819756399991, 10.713156224000045],
              [-74.85838108299993, 10.713114974000064],
              [-74.85852495599994, 10.712980615000049],
              [-74.85860291099993, 10.712871607000068],
              [-74.85869997899994, 10.71267199600004 ],
              [-74.85872596999991, 10.712634885000057],
              [-74.85883924899991, 10.712507394000056],
              [-74.85890527599992, 10.712449494000055],
              [-74.85901841399993, 10.712363847000063],
              [-74.85921385899991, 10.712273822000043],
              [-74.85929629399993, 10.712229921000073],
              [-74.85952934199992, 10.712142348000043],
              [-74.85965861599993, 10.712152070000059],
              [-74.85977096399995, 10.712158973000044],
              [-74.8598015849999 , 10.712160853000057],
              [-74.85981844899993, 10.712161889000072],
              [-74.85987491499992, 10.71215045300005 ],
              [-74.86003732099994, 10.71209519000007 ],
              [-74.86035039899991, 10.711979976000066],
              [-74.86054092599994, 10.711952704000055],
              [-74.86076178099995, 10.711992947000056],
              [-74.86085012899991, 10.71205642800004 ],
              [-74.86085958099994, 10.712069159000066],
              [-74.86093399499993, 10.71216637200007 ],
              [-74.86099420699992, 10.712239264000061],
              [-74.86108746799994, 10.71235033000005 ],
              [-74.86123722099995, 10.712491344000057],
              [-74.86139172199995, 10.71264394800005 ],
              [-74.86151062199991, 10.71271310700007 ],
              [-74.8615894749999 , 10.712754570000072],
              [-74.86171992599992, 10.712783044000048],
              [-74.86180319599993, 10.712764117000063],
              [-74.86187824299992, 10.71274406300006 ],
              [-74.86194728099991, 10.712695006000047],
              [-74.86202915799993, 10.71262732200006 ],
              [-74.86211918399994, 10.712545671000044],
              [-74.8622056509999 , 10.712455907000049],
              [-74.86228164199991, 10.712385923000056],
              [-74.86233782999994, 10.712349692000089],
              [-74.86241863899994, 10.712306393000063],
              [-74.86250302499991, 10.712274688000036],
              [-74.86252757599993, 10.71227170800006 ],
              [-74.8625922029999 , 10.71226386300009 ],
              [-74.86273549099991, 10.712281831000041],
              [-74.86281536299992, 10.712294262000057],
              [-74.8629294189999 , 10.712339048000047],
              [-74.86300943299995, 10.712382817000048],
              [-74.86309069999993, 10.712443987000086],
              [-74.8631909849999 , 10.712557311000069],
              [-74.86325728999992, 10.712682384000061],
              [-74.86328926699991, 10.71274492300006 ],
              [-74.86335335699994, 10.71289902600006 ],
              [-74.86341587099992, 10.712966080000058],
              [-74.86345469499992, 10.712985647000039],
              [-74.86350167799992, 10.712993573000062],
              [-74.86367755599991, 10.712954528000068],
              [-74.86370081699994, 10.712908003000052],
              [-74.86370884099995, 10.712866184000063],
              [-74.8637625739999 , 10.712810243000092],
              [-74.86385745899992, 10.712770380000052],
              [-74.86392308699993, 10.712748049000083],
              [-74.8639840429999 , 10.71273154000005 ],
              [-74.86404380599993, 10.712710395000045],
              [-74.86410587299991, 10.712679956000045],
              [-74.8641469559999 , 10.712680944000056],
              [-74.86418000399993, 10.712721427000076],
              [-74.8642085539999 , 10.712803717000043],
              [-74.8642032599999 , 10.712929088000067],
              [-74.86420012099995, 10.713011510000058],
              [-74.86418301399993, 10.71312068900005 ],
              [-74.86417412399993, 10.713220138000054],
              [-74.86417408799991, 10.713220547000049],
              [-74.86418506199993, 10.71330987500005 ],
              [-74.8642275709999 , 10.713365404000058],
              [-74.86427355399991, 10.713410478000071],
              [-74.86434528199993, 10.713439193000056],
              [-74.86444283899993, 10.713470116000053],
              [-74.86452515099995, 10.713502270000049],
              [-74.8645850929999 , 10.713520584000037],
              [-74.86470605499994, 10.713535160000049],
              [-74.86480821899994, 10.713546334000057],
              [-74.8648880959999 , 10.713561089000052],
              [-74.86497523399993, 10.71362221600009 ],
              [-74.8649920659999 , 10.71371033400004 ],
              [-74.8649525969999 , 10.713803336000069],
              [-74.86488939199995, 10.713838415000055],
              [-74.86486228199993, 10.713861958000052],
              [-74.86476316499994, 10.713948027000072],
              [-74.86475937599994, 10.714145312000085],
              [-74.86481877299991, 10.714302874000055],
              [-74.8648268849999 , 10.71431578000005 ],
              [-74.86485659999994, 10.714363057000071],
              [-74.86497420699993, 10.714420576000066],
              [-74.8651150359999 , 10.71442462400006 ],
              [-74.86529311099991, 10.71436120900006 ],
              [-74.8654524399999 , 10.714304835000064],
              [-74.86582923599991, 10.714082776000055],
              [-74.86603767299994, 10.71400069300006 ],
              [-74.86628133499994, 10.713925417000041],
              [-74.86629935499991, 10.713924214000087],
              [-74.86650415299994, 10.713910553000062],
              [-74.86673406999995, 10.713907259000052],
              [-74.86694289999991, 10.713862204000066],
              [-74.86715891499995, 10.71378198900004 ],
              [-74.86728221899995, 10.713736203000053],
              [-74.8675613659999 , 10.713709490000042],
              [-74.86763431799994, 10.713753275000045],
              [-74.867651        , 10.713835707000044],
              [-74.86767514299993, 10.713955006000049],
              [-74.86766412599991, 10.71411054500004 ],
              [-74.86772555999994, 10.714200796000057],
              [-74.86778627199993, 10.714221206000047],
              [-74.8677937189999 , 10.714223711000045],
              [-74.86796515399993, 10.714248510000061],
              [-74.86799495299994, 10.714239894000059],
              [-74.86813623899991, 10.714199050000047],
              [-74.86821348099994, 10.714154631000042],
              [-74.86828091799993, 10.714019738000047],
              [-74.86836110299993, 10.713959721000037],
              [-74.8683744679999 , 10.713949719000084],
              [-74.86846134599995, 10.713960954000072],
              [-74.86851318299995, 10.714004827000053],
              [-74.8685115159999 , 10.714011334000077],
              [-74.8684947399999 , 10.714076849000037],
              [-74.86844613099993, 10.714223265000044],
              [-74.86826413099993, 10.71445146900004 ],
              [-74.86806305099992, 10.714612456000054],
              [-74.86801154299991, 10.71461052300009 ],
              [-74.8678963989999 , 10.714606200000048],
              [-74.86760219699994, 10.714421788000038],
              [-74.86734138499992, 10.71435327100005 ],
              [-74.86730130899991, 10.714351419000081],
              [-74.8671113499999 , 10.714342644000055],
              [-74.86697545399994, 10.71435865300009 ],
              [-74.86685837999994, 10.714410186000066],
              [-74.86670878599995, 10.714533786000061],
              [-74.86652522599991, 10.71492433800006 ],
              [-74.86645348999991, 10.715142728000046],
              [-74.8664402679999 , 10.71532839200006 ],
              [-74.86646653399993, 10.715428042000042],
              [-74.86658711299992, 10.715629379000063],
              [-74.86671409399992, 10.715691483000057],
              [-74.86685726299993, 10.715702477000093],
              [-74.86719755699994, 10.715721912000049],
              [-74.86730311299993, 10.715716827000051],
              [-74.8675517659999 , 10.715706491000049],
              [-74.86773229499994, 10.715677883000069],
              [-74.86795257999995, 10.715621269000053],
              [-74.8683394759999 , 10.715581695000083],
              [-74.86847319799995, 10.715568018000056],
              [-74.86862335299992, 10.71556737700007 ],
              [-74.86870795499993, 10.715597179000042],
              [-74.86883523199992, 10.715724244000057],
              [-74.86890365699992, 10.715807476000066],
              [-74.86901481099994, 10.715999571000054],
              [-74.86904375299991, 10.716171134000092],
              [-74.86902100099991, 10.716326676000051],
              [-74.8689297709999 , 10.716385064000065],
              [-74.86881755799993, 10.716473706000045],
              [-74.86851358699994, 10.71670004300006 ],
              [-74.86835224299995, 10.716819053000052],
              [-74.86826806399995, 10.716882050000038],
              [-74.86809984199994, 10.717035888000055],
              [-74.86797614499994, 10.717177941000045],
              [-74.86783823099995, 10.717289888000039],
              [-74.86775296799993, 10.71734788000009 ],
              [-74.86757393099992, 10.717469657000038],
              [-74.86743605899994, 10.71759088500005 ],
              [-74.86729085699994, 10.717649505000054],
              [-74.86719952499993, 10.717687013000045],
              [-74.86713475099992, 10.717886814000053],
              [-74.86706563099995, 10.71816319700008 ],
              [-74.86706628999991, 10.71830704100006 ],
              [-74.86707409499991, 10.718474051000044],
              [-74.8672676409999 , 10.718726117000074],
              [-74.86734539799994, 10.718797711000093],
              [-74.86749584199993, 10.718859715000065],
              [-74.86764136099993, 10.718870697000057],
              [-74.86776798099993, 10.718853921000061],
              [-74.86798595499994, 10.718804273000046],
              [-74.86820129499995, 10.71869199400004 ],
              [-74.86862445299994, 10.718388621000088],
              [-74.86878087999992, 10.718225583000049],
              [-74.86883611899992, 10.717993386000046],
              [-74.86886337899995, 10.717798422000044],
              [-74.86887893799991, 10.717610465000064],
              [-74.86890905799993, 10.71745799200005 ],
              [-74.86892021299991, 10.717401521000056],
              [-74.86905584499993, 10.717308161000062],
              [-74.86919635599992, 10.717256534000057],
              [-74.86938388399994, 10.717225579000058],
              [-74.86959001399993, 10.717159756000058],
              [-74.86979407699994, 10.717154249000089],
              [-74.8699443669999 , 10.71718840400007 ],
              [-74.8700079269999 , 10.717236845000059],
              [-74.8700506269999 , 10.717341046000058],
              [-74.87005605099995, 10.717501079000044],
              [-74.86999604599993, 10.717717058000062],
              [-74.8698725459999 , 10.717898516000048],
              [-74.86974640299991, 10.71801503200004 ],
              [-74.86960462699994, 10.718122210000047],
              [-74.86959211099992, 10.718131671000037],
              [-74.86951075999991, 10.718296710000061],
              [-74.86951560499995, 10.718373746000054],
              [-74.8695183449999 , 10.718417301000045],
              [-74.86960108799991, 10.718556123000042],
              [-74.8696135369999 , 10.718713807000086],
              [-74.86955807499993, 10.718897293000055],
              [-74.86949724399994, 10.718932345000042],
              [-74.86932922499994, 10.719125587000065],
              [-74.86932030799994, 10.719147606000092],
              [-74.8692294789999 , 10.719371894000062],
              [-74.86922537599992, 10.719501810000054],
              [-74.86929391899992, 10.71961286000004 ],
              [-74.86932578499994, 10.719658339000091],
              [-74.8693765619999 , 10.719730809000055],
              [-74.86946174199994, 10.719890502000055],
              [-74.86957399599993, 10.719998280000084],
              [-74.86957719599991, 10.720001352000054],
              [-74.86966409599995, 10.720024181000042],
              [-74.86974829099995, 10.719968154000071],
              [-74.86975028099994, 10.719964793000088],
              [-74.86981579999991, 10.719854205000047],
              [-74.8698645209999 , 10.719735696000043],
              [-74.87000419799995, 10.719500822000043],
              [-74.87010712699993, 10.719437751000044],
              [-74.87019863499995, 10.719442         ],
              [-74.8702857049999 , 10.719501944000058],
              [-74.87031976199995, 10.719768555000087],
              [-74.87029474199994, 10.719940314000041],
              [-74.87021835299993, 10.720163325000044],
              [-74.8701811979999 , 10.720246985000074],
              [-74.87008162599994, 10.72053040500009 ],
              [-74.87002603499991, 10.720686059000059],
              [-74.86993323399992, 10.720910330000038],
              [-74.86991930599993, 10.720943988000045],
              [-74.86990341599994, 10.721060040000054],
              [-74.8699606159999 , 10.721256963000087],
              [-74.87001040699994, 10.721355955000092],
              [-74.87003163499992, 10.721398159000046],
              [-74.8701741989999 , 10.721542834000047],
              [-74.87029795599994, 10.72166842100006 ],
              [-74.87053576399995, 10.721859940000058],
              [-74.87069796499992, 10.72193347700005 ],
              [-74.87073743199994, 10.721958802000074],
              [-74.87090265599994, 10.722064826000064],
              [-74.87106012299995, 10.722129108000047],
              [-74.87119177599993, 10.722191175000091],
              [-74.87126016499991, 10.722269753000091],
              [-74.8713193939999 , 10.722397081000054],
              [-74.87138362299993, 10.722591654000041],
              [-74.87141470299991, 10.722719102000042],
              [-74.87150208999992, 10.722848629000055],
              [-74.87162201699994, 10.72291074800006 ],
              [-74.87173486099994, 10.722963618000051],
              [-74.87186180099991, 10.723021069000083],
              [-74.87189881799992, 10.723019909000072],
              [-74.87203302299991, 10.723015702000055],
              [-74.87222525499993, 10.72298704800005 ],
              [-74.87240089499994, 10.722921348000057],
              [-74.87253888299995, 10.722830295000051],
              [-74.87266502599994, 10.72271377900006 ],
              [-74.87276994199993, 10.722571834000064],
              [-74.87278364199994, 10.722490588000085],
              [-74.87277339499991, 10.722462613000062],
              [-74.87274801199993, 10.722393314000044],
              [-74.87275430499994, 10.722230917000047],
              [-74.87275617299991, 10.722126524000089],
              [-74.87272252299994, 10.721950375000063],
              [-74.87269520899991, 10.721907699000042],
              [-74.87261147399994, 10.721776875000046],
              [-74.8725995339999 , 10.721766609000042],
              [-74.87247723199994, 10.721661466000057],
              [-74.87225155499993, 10.721558045000052],
              [-74.87214114199992, 10.72152371900006 ],
              [-74.87199769699993, 10.721447786000056],
              [-74.87189863099991, 10.72132990700004 ],
              [-74.87191662299995, 10.72116049500005 ],
              [-74.87194444399995, 10.721088467000072],
              [-74.87208927899991, 10.721031813000081],
              [-74.87227668399993, 10.721005518000084],
              [-74.87252064899991, 10.721046201000092],
              [-74.87255348299993, 10.721060651000073],
              [-74.87278135899993, 10.721160978000057],
              [-74.87296959499992, 10.72131778000005 ],
              [-74.8731580619999 , 10.721525574000054],
              [-74.87318721199995, 10.721750274000044],
              [-74.87332893599995, 10.72198839500004 ],
              [-74.8734175699999 , 10.722407531000044],
              [-74.87337885999995, 10.722658013000057],
              [-74.87333786799991, 10.722922409000091],
              [-74.87330143499992, 10.723158974000057],
              [-74.87328788799994, 10.723272601000076],
              [-74.87334260199992, 10.723448519000044],
              [-74.87341937899993, 10.723837572000093],
              [-74.8734840159999 , 10.724133968000046],
              [-74.87348347599993, 10.724406829000088],
              [-74.87348330499992, 10.724493221000046],
              [-74.87364819699991, 10.724670985000046],
              [-74.87375460399994, 10.724697709000054],
              [-74.87378430899992, 10.72470517100004 ],
              [-74.87399077299995, 10.724746009000057],
              [-74.87425592699992, 10.72480745900009 ],
              [-74.87449759499992, 10.724857419000045],
              [-74.87464338199993, 10.724956460000044],
              [-74.87469321799995, 10.725090673000068],
              [-74.87463768899994, 10.725250836000043],
              [-74.8746059259999 , 10.725482746000068],
              [-74.87463728499995, 10.72567730500009 ],
              [-74.87467895499992, 10.725720137000053],
              [-74.87473384299994, 10.72577655400005 ],
              [-74.8748585159999 , 10.725873369000055],
              [-74.87498999699994, 10.72591916400006 ],
              [-74.87504679499995, 10.725917810000055],
              [-74.87522671999994, 10.725913515000059],
              [-74.87536951499993, 10.725871187000052],
              [-74.87551225199991, 10.725814953000054],
              [-74.8755839929999 , 10.725771044000055],
              [-74.87572964599991, 10.725681909000059],
              [-74.8758601589999 , 10.725516794000043],
              [-74.87586298499991, 10.725487185000077],
              [-74.87588029699992, 10.725305795000054],
              [-74.87593068599995, 10.725045992000048],
              [-74.87606333499991, 10.72483451000005 ],
              [-74.87616625099992, 10.724785397000062],
              [-74.87630416799993, 10.724701370000048],
              [-74.8764560759999 , 10.72460105600004 ],
              [-74.87661267199991, 10.724500724000052],
              [-74.87674159399995, 10.72450017500006 ],
              [-74.8768755509999 , 10.72457608700006 ],
              [-74.87691600099993, 10.724708024000051],
              [-74.87693072999991, 10.724853977000066],
              [-74.8769217489999 , 10.724909301000082],
              [-74.87688968899994, 10.725106788000062],
              [-74.87688819199991, 10.72511090100005 ],
              [-74.8767831909999 , 10.72539928000009 ],
              [-74.87668551199994, 10.725568893000059],
              [-74.87653849599991, 10.725713219000056],
              [-74.87644302299992, 10.72585269600006 ],
              [-74.87639461899994, 10.726033685000061],
              [-74.87640929899993, 10.726168051000059],
              [-74.87644494299991, 10.726274515000057],
              [-74.87650391599993, 10.726355384000044],
              [-74.87668732899994, 10.72648207800006 ],
              [-74.87686347099992, 10.726555491000056],
              [-74.87704664499995, 10.726628878000042],
              [-74.87719443499992, 10.726653743000043],
              [-74.87737983599993, 10.726701621000075],
              [-74.87747632199995, 10.726784646000056],
              [-74.8776436789999 , 10.726987892000068],
              [-74.87789588299995, 10.72716633400006 ],
              [-74.87789730499992, 10.727167340000051],
              [-74.87789765899993, 10.72716758900009 ],
              [-74.87819115099995, 10.727272949000053],
              [-74.87853679299991, 10.727505565000058],
              [-74.87902614299992, 10.727895167000042],
              [-74.87937390299993, 10.728178395000043],
              [-74.87955930899994, 10.728279451000049],
              [-74.87977745899991, 10.728373422000061],
              [-74.87994839299995, 10.728379635000067],
              [-74.88000466499994, 10.728327823000086],
              [-74.88009994499993, 10.728240098000072],
              [-74.8801055209999 , 10.728232350000042],
              [-74.88021860899994, 10.728075241000056],
              [-74.88042754999992, 10.727680834000068],
              [-74.8805948669999 , 10.727402341000072],
              [-74.88073483599993, 10.727281007000045],
              [-74.8807907659999 , 10.727232524000044],
              [-74.8809717019999 , 10.727146732000051],
              [-74.88101737999995, 10.72712507500006 ],
              [-74.88123703999992, 10.727033856000048],
              [-74.88138147899991, 10.726942306000069],
              [-74.88138411799991, 10.726940633000083],
              [-74.88138732399995, 10.726940442000057],
              [-74.8816790269999 , 10.726923164000084],
              [-74.88192972399992, 10.72696607000006 ],
              [-74.88207280299991, 10.727025642000058],
              [-74.88214248999992, 10.727084994000052],
              [-74.88227021199992, 10.727193770000042],
              [-74.88242075099993, 10.72735053100007 ],
              [-74.88243356599992, 10.72738164000009 ],
              [-74.88250832699993, 10.727563113000087],
              [-74.8825510289999 , 10.727687926000044],
              [-74.88263812799994, 10.727794034000055],
              [-74.88264574699991, 10.727796350000062],
              [-74.88271314799994, 10.72781685800004 ],
              [-74.88287587699995, 10.727809164000064],
              [-74.88292849099992, 10.727806676000057],
              [-74.88309236999993, 10.727805971000066],
              [-74.88316153799991, 10.727845856000044],
              [-74.8831646509999 , 10.727847651000047],
              [-74.8832637189999 , 10.727904772000045],
              [-74.88339519099992, 10.727987535000068],
              [-74.88350333499994, 10.728086609000059],
              [-74.88352953599991, 10.728125395000063],
              [-74.88360467899992, 10.728236632000062],
              [-74.88360455299994, 10.728309387000081],
              [-74.88360402799992, 10.728613943000084],
              [-74.88355394699994, 10.728931285000044],
              [-74.8834765819999 , 10.729429291000088],
              [-74.88343295099992, 10.729619289000084],
              [-74.88337715599994, 10.729707491000056],
              [-74.88329077299994, 10.729761102000055],
              [-74.8830669379999 , 10.729965762000063],
              [-74.88305619699992, 10.730181082000058],
              [-74.8831317449999 , 10.730321959000037],
              [-74.88322347799993, 10.73041647100007 ],
              [-74.88335734899994, 10.730510799000058],
              [-74.88360335699991, 10.730551408000053],
              [-74.8837202279999 , 10.730509243000085],
              [-74.88393027399991, 10.730362507000052],
              [-74.8841379019999 , 10.730197267000051],
              [-74.88416360199994, 10.73015779700006 ],
              [-74.8843494919999 , 10.72987229100005 ],
              [-74.88442361699992, 10.729696047000061],
              [-74.88451172999993, 10.72950585700005 ],
              [-74.88457891599995, 10.72934816500009 ],
              [-74.88467199699994, 10.729222769000046],
              [-74.8847746589999 , 10.729143622000038],
              [-74.88482951499992, 10.729104613000061],
              [-74.88497769299994, 10.72899923500006 ],
              [-74.88517380899992, 10.72887802200006 ],
              [-74.88540508199992, 10.728765920000058],
              [-74.88555444699995, 10.728661112000054],
              [-74.88571272999991, 10.728456731000051],
              [-74.88580371699993, 10.728350836000061],
              [-74.8858384959999 , 10.728310357000055],
              [-74.8860127559999 , 10.728015631000062],
              [-74.88631564999991, 10.727690258000052],
              [-74.88635425899992, 10.72765725000005 ],
              [-74.8863809529999 , 10.727634427000055],
              [-74.88653748599995, 10.727561992000062],
              [-74.8866687339999 , 10.72759383500005 ],
              [-74.88678611299991, 10.72766508500007 ],
              [-74.88689671899994, 10.727791921000062],
              [-74.88701456199993, 10.727967337000052],
              [-74.88716085399994, 10.728256399000088],
              [-74.88724133499994, 10.728415425000037],
              [-74.88742756499994, 10.728699340000048],
              [-74.88766740299991, 10.728929784000059],
              [-74.88780842299991, 10.729051858000048],
              [-74.88787447899995, 10.729099493000092],
              [-74.88792753699994, 10.729137757000046],
              [-74.88797753699993, 10.729173811000067],
              [-74.8881163719999 , 10.729330619000052],
              [-74.88813267899991, 10.729348641000058],
              [-74.88819594799992, 10.729418561000045],
              [-74.88822695399995, 10.729452826000056],
              [-74.88838696899995, 10.729632687000048],
              [-74.88848342499995, 10.729736435000063],
              [-74.88859676999994, 10.729778491000047],
              [-74.8886499269999 , 10.729798215000073],
              [-74.88893143799993, 10.729924315000062],
              [-74.88899499899992, 10.730002744000046],
              [-74.88903384699995, 10.730126662000089],
              [-74.88903775599994, 10.730139130000055],
              [-74.88917194499993, 10.730302898000048],
              [-74.88926825499993, 10.73037424000006 ],
              [-74.8894399699999 , 10.730554052000059],
              [-74.88955514799994, 10.73065540500005 ],
              [-74.8896725379999 , 10.73072896900004 ],
              [-74.88974072199994, 10.730793490000053],
              [-74.88983016799995, 10.730899583000053],
              [-74.88987278899992, 10.731005881000044],
              [-74.8899976109999 , 10.731169689000069],
              [-74.89015959799991, 10.731268521000061],
              [-74.89018269799993, 10.731286886000078],
              [-74.8902681969999 , 10.731354856000053],
              [-74.89024990399992, 10.73137736600006 ],
              [-74.89011680499993, 10.731495195000036],
              [-74.89006367299993, 10.731542231000049],
              [-74.88984027099991, 10.731755776000057],
              [-74.88966668899991, 10.731960068000092],
              [-74.88963335999995, 10.731999292000069],
              [-74.8894819269999 , 10.732185239000046],
              [-74.88942900099994, 10.732262927000079],
              [-74.88932367899992, 10.732417527000052],
              [-74.88925165099994, 10.732549856000048],
              [-74.88925872999994, 10.732617742000059],
              [-74.88928593299994, 10.732878595000045],
              [-74.88940382999994, 10.733049478000055],
              [-74.8895451809999 , 10.733224889000041],
              [-74.88967943799992, 10.73338644000006 ],
              [-74.88978621899992, 10.733689385000048],
              [-74.88981321799992, 10.73396256600006 ],
              [-74.88981639999992, 10.734147845000052],
              [-74.88981675799994, 10.734150989000057],
              [-74.88984809599992, 10.734423322000055],
              [-74.88988874499995, 10.734606116000066],
              [-74.89004850899994, 10.73470965100006 ],
              [-74.89033003499992, 10.734801080000068],
              [-74.89071487599995, 10.734945330000073],
              [-74.89106456799993, 10.735087419000081],
              [-74.89133936299993, 10.73513596500004 ],
              [-74.89137169599991, 10.735141677000058],
              [-74.89155471999993, 10.735208054000054],
              [-74.89167933699991, 10.735309423000047],
              [-74.89171044499994, 10.735455200000047],
              [-74.89163354699991, 10.735663898000041],
              [-74.89161630599995, 10.735710674000075],
              [-74.89161555999993, 10.735712697000054],
              [-74.89144009099994, 10.736280864000037],
              [-74.89139675199993, 10.736540440000056],
              [-74.89134889399992, 10.73683709100004 ],
              [-74.8913708739999 , 10.737036165000063],
              [-74.89154825399993, 10.737410581000063],
              [-74.89164022799991, 10.737546824000049],
              [-74.89174674799995, 10.737791856000058],
              [-74.89189079099992, 10.738045989000057],
              [-74.89205331699992, 10.738244457000064],
              [-74.89219134599995, 10.73833501200005 ],
              [-74.89228827199992, 10.738398608000068],
              [-74.8924080779999 , 10.738472201000093],
              [-74.8925535219999 , 10.738517892000061],
              [-74.89268010399991, 10.738535873000046],
              [-74.89285579299991, 10.738535108000065],
              [-74.89311782899995, 10.738462180000056],
              [-74.89325344799994, 10.738406011000052],
              [-74.8934453359999 , 10.738361178000048],
              [-74.89356490999995, 10.738383818000045],
              [-74.89377854999992, 10.73848711100004 ],
              [-74.89400158199993, 10.738594995000085],
              [-74.8941447169999 , 10.738647640000067],
              [-74.89425479399995, 10.738642536000043],
              [-74.89438783799994, 10.738535420000062],
              [-74.89449913899995, 10.73828250300005 ],
              [-74.89459005799995, 10.738184837000063],
              [-74.89467032499994, 10.73816411900009 ],
              [-74.89468130999995, 10.738161283000068],
              [-74.8947844349999 , 10.738172417000044],
              [-74.8949207629999 , 10.738273725000056],
              [-74.89501523699994, 10.738444696000045],
              [-74.89502595399995, 10.738546075000045],
              [-74.8950341659999 , 10.738623748000066],
              [-74.8950372619999 , 10.738653036000073],
              [-74.89499362999993, 10.738847766000049],
              [-74.89480727699993, 10.739080168000044],
              [-74.89454108599995, 10.739271230000043],
              [-74.89427028199992, 10.73947852200007 ],
              [-74.89398511099995, 10.739616401000092],
              [-74.89367509999994, 10.739763691000064],
              [-74.89365551299994, 10.739772996000056],
              [-74.89363236699995, 10.739789494000092],
              [-74.89360556999992, 10.739808595000056],
              [-74.89311611199992, 10.740157464000049],
              [-74.89263202599994, 10.740707660000055],
              [-74.89244150299993, 10.74096965800004 ],
              [-74.89239411299991, 10.74105635400008 ],
              [-74.8922768679999 , 10.741270838000048],
              [-74.89219627199992, 10.741543912000054],
              [-74.89219746899994, 10.741620025000088],
              [-74.89219954299995, 10.741751913000087],
              [-74.8922263259999 , 10.741989855000043],
              [-74.89229464099992, 10.742105123000044],
              [-74.89241879199994, 10.742160055000056],
              [-74.89265054999993, 10.74223070100004 ],
              [-74.89280989699995, 10.74231321800005 ],
              [-74.89297375799993, 10.742361045000052],
              [-74.8930790849999 , 10.742388323000057],
              [-74.89322162299993, 10.74237383700006 ],
              [-74.89334538499992, 10.74234094600007 ],
              [-74.89345752499992, 10.74232427800007 ],
              [-74.89371501599993, 10.742406384000049],
              [-74.89385084399993, 10.742449696000051],
              [-74.89400524899992, 10.742474449000042],
              [-74.89411976199995, 10.742464708000057],
              [-74.89426204299991, 10.74244157000004 ],
              [-74.89435670499995, 10.74253937800006 ],
              [-74.89456391599992, 10.74291119500009 ],
              [-74.89456413999994, 10.742911597000045],
              [-74.89443515399995, 10.74334239600006 ],
              [-74.89520460199992, 10.743679709000048],
              [-74.89520448699994, 10.743679891000056],
              [-74.89520169999992, 10.743786359000069],
              [-74.89505927399995, 10.743931319000069],
              [-74.89493707899993, 10.744012341000087],
              [-74.89477062199995, 10.74407330300005 ],
              [-74.89464312299992, 10.744186165000087],
              [-74.8947151989999 , 10.744252884000048],
              [-74.89475580699991, 10.744286106000061],
              [-74.8947649779999 , 10.744293621000054],
              [-74.89486157399995, 10.74437264100004 ],
              [-74.89508339799994, 10.744549592000055],
              [-74.89521243399992, 10.744660201000045],
              [-74.8952762099999 , 10.744730178000054],
              [-74.8953676239999 , 10.744828259000087],
              [-74.89538899599995, 10.744847151000045],
              [-74.89543352899994, 10.744893542000057],
              [-74.89555131199995, 10.745029011000042],
              [-74.89561318299991, 10.745095269000046],
              [-74.89566711499992, 10.745151135000071],
              [-74.89570940499993, 10.745201592000058],
              [-74.89581760399994, 10.745350384000062],
              [-74.89591103299995, 10.745467632000043],
              [-74.89594342999993, 10.745528057000058],
              [-74.89599162499991, 10.745626207000043],
              [-74.8960305519999 , 10.74573035700007 ],
              [-74.89604740599992, 10.745846975000063],
              [-74.89606671799993, 10.745987446000072],
              [-74.89605979499993, 10.746105296000053],
              [-74.8960594699999 , 10.746231523000063],
              [-74.89603186799991, 10.746397221000052],
              [-74.8960198449999 , 10.746517794000056],
              [-74.89600266899993, 10.746639260000052],
              [-74.89600230499991, 10.746784650000052],
              [-74.89604391199993, 10.747011950000058],
              [-74.89605273599994, 10.747224888000062],
              [-74.8960876729999 , 10.74738577000005 ],
              [-74.89610286499993, 10.747551311000052],
              [-74.89611815299992, 10.747808089000046],
              [-74.89611305499994, 10.748004202000061],
              [-74.89611737999991, 10.748173103000056],
              [-74.89612616099993, 10.74834654600005 ],
              [-74.89612603199993, 10.74840025800006 ],
              [-74.89610458699991, 10.748488787000042],
              [-74.89610906799993, 10.74866385200005 ],
              [-74.89610398999992, 10.74886863000006 ],
              [-74.89608493299994, 10.74913458900005 ],
              [-74.89605658199991, 10.749450060000072],
              [-74.89600115799993, 10.749796054000058],
              [-74.89591554899994, 10.750110893000056],
              [-74.8958665209999 , 10.75029562900005 ],
              [-74.89586083699993, 10.750363715000049],
              [-74.89585924199991, 10.750382780000052],
              [-74.89585830399994, 10.750394175000054],
              [-74.89584917699995, 10.750486762000037],
              [-74.89584883799995, 10.750489909000066],
              [-74.89584858599994, 10.750492511000061],
              [-74.89584865299992, 10.750494006000054],
              [-74.89585141999993, 10.750545440000053],
              [-74.89585236499994, 10.750563345000046],
              [-74.89585808399994, 10.750669783000092],
              [-74.8958978519999 , 10.750916609000058],
              [-74.89590933599993, 10.75098114800005 ],
              [-74.8959564139999 , 10.751267233000078],
              [-74.89597147199993, 10.75162797900009 ],
              [-74.89597307399993, 10.751886415000058],
              [-74.89593280299994, 10.752334928000039],
              [-74.89589485099992, 10.752774332000058],
              [-74.89585580999994, 10.75298852800006 ],
              [-74.89583087099993, 10.753125356000055],
              [-74.8958283959999 , 10.753182026000047],
              [-74.89581907799993, 10.75339549000006 ],
              [-74.89579019799993, 10.75345459500005 ],
              [-74.89578982699993, 10.753608086000042],
              [-74.89581310999995, 10.753701256000056],
              [-74.89586104599994, 10.753801463000059],
              [-74.89590941199992, 10.753941771000086],
              [-74.89591759099994, 10.75396989300009 ],
              [-74.89591796999991, 10.753971197000055],
              [-74.8959182989999 , 10.753972330000067],
              [-74.89594840699993, 10.75407585000005 ],
              [-74.8959721789999 , 10.754252302000054],
              [-74.89598067499992, 10.754387726000061],
              [-74.8959756029999 , 10.75459039000009 ],
              [-74.89597061599994, 10.754753376000053],
              [-74.89595658199994, 10.754928005000068],
              [-74.89594294199992, 10.755029427000068],
              [-74.89588250799994, 10.755369159000054],
              [-74.8958437039999 , 10.755525456000044],
              [-74.89579564399992, 10.75569351300004 ],
              [-74.89559988799994, 10.756246927000063],
              [-74.89551763399993, 10.75640009600005 ],
              [-74.89542329599993, 10.756525967000073],
              [-74.89534193899993, 10.756638447000057],
              [-74.89526587099994, 10.756743614000072],
              [-74.89520570299993, 10.756805154000062],
              [-74.89511521599991, 10.756947474000071],
              [-74.89507131699992, 10.75704810500008 ],
              [-74.89505654699991, 10.757081959000061],
              [-74.89502887599991, 10.757143430000042],
              [-74.89500921099994, 10.75718712400004 ],
              [-74.89500481899995, 10.757204072000093],
              [-74.89496573299994, 10.757354923000037],
              [-74.89495093699992, 10.757400522000069],
              [-74.89492478099993, 10.757468190000054],
              [-74.89488678299995, 10.757596659000058],
              [-74.89488409499995, 10.75760574800006 ],
              [-74.89484919299991, 10.757723739000085],
              [-74.89481998299993, 10.757931673000087],
              [-74.89479449399994, 10.758091747000037],
              [-74.89476262099993, 10.758242887000051],
              [-74.8947596289999 , 10.758775417000038],
              [-74.89476580799993, 10.759144979000041],
              [-74.89475152299991, 10.759273011000062],
              [-74.8947356619999 , 10.759415163000085],
              [-74.89473367599993, 10.759442162000084],
              [-74.89470882199993, 10.759780123000041],
              [-74.89476768599991, 10.759753747000047],
              [-74.89480897099992, 10.760131990000048],
              [-74.89481521699992, 10.760361451000051],
              [-74.89481465199992, 10.760581564000063],
              [-74.89477714499992, 10.761011316000065],
              [-74.89476328799992, 10.761077330000091],
              [-74.89470102799993, 10.761373956000057],
              [-74.89466915999992, 10.76149864200005 ],
              [-74.89463309599995, 10.761616378000042],
              [-74.89462770299991, 10.761622651000039],
              [-74.89460736099994, 10.761646316000054],
              [-74.89456476599992, 10.76178720200005 ],
              [-74.89451536699994, 10.76193438200005 ],
              [-74.89438640299994, 10.762318619000041],
              [-74.89411976199995, 10.76289876900006 ],
              [-74.89383353699992, 10.763354022000044],
              [-74.89376180499994, 10.763497303000065],
              [-74.89370590099992, 10.763604887000042],
              [-74.89363148599995, 10.76375108500008 ],
              [-74.89353402099994, 10.763931255000045],
              [-74.89344396199994, 10.764096601000062],
              [-74.89337203299993, 10.764251586000057],
              [-74.89327713499995, 10.764415372000087],
              [-74.89320804899995, 10.76455111100006 ],
              [-74.89312898599991, 10.764711031000047],
              [-74.8930659429999 , 10.764829553000084],
              [-74.89306273099993, 10.764960202000054],
              [-74.89308084399994, 10.765261205000058],
              [-74.89309849099993, 10.765493425000045],
              [-74.89309164199994, 10.765656585000045],
              [-74.89304394699991, 10.76585870200006 ],
              [-74.89300667099991, 10.766042807000076],
              [-74.89298386799993, 10.766123371000049],
              [-74.89297487699992, 10.766155136000066],
              [-74.89296959399991, 10.766173853000055],
              [-74.8929688959999 , 10.766176309000059],
              [-74.8929677239999 , 10.766180432000056],
              [-74.89296399299991, 10.766193612000052],
              [-74.89295934799992, 10.766210031000071],
              [-74.89294834099991, 10.766250567000043],
              [-74.89294363899995, 10.766250368000044],
              [-74.89290254699995, 10.766248644000086],
              [-74.89289485799992, 10.766248321000091],
              [-74.8928650819999 , 10.766247070000077],
              [-74.89283283399993, 10.766238343000055],
              [-74.8926676719999 , 10.76619363900005 ],
              [-74.8925236149999 , 10.766174836000062],
              [-74.89233087999992, 10.76610991800004 ],
              [-74.8921357129999 , 10.76608636900005 ],
              [-74.89178026999991, 10.766025582000054],
              [-74.89090445399995, 10.76587593100004 ],
              [-74.8904048089999 , 10.765858384000069],
              [-74.88905443599992, 10.765877412000066],
              [-74.88826423299992, 10.76587509300009 ],
              [-74.88697943199992, 10.765698967000048],
              [-74.88648524199994, 10.765366569000037],
              [-74.88569175399994, 10.764833339000063],
              [-74.88547824299991, 10.76470861200005 ],
              [-74.88428406199995, 10.764548817000048],
              [-74.8840204679999 , 10.764524639000058],
              [-74.88317106699992, 10.764446717000055],
              [-74.8833893289999 , 10.76542869900004 ],
              [-74.88365348399992, 10.766632185000049],
              [-74.8836810809999 , 10.766757945000052],
              [-74.8837567089999 , 10.76717645900004 ],
              [-74.8837494409999 , 10.767289038000058],
              [-74.88383525099994, 10.767360537000059],
              [-74.88379866699995, 10.76803151200005 ],
              [-74.88379510899995, 10.768514135000089],
              [-74.88375705599992, 10.76885416500005 ],
              [-74.8836958839999 , 10.769143557000064],
              [-74.88366517199995, 10.76933652200006 ],
              [-74.88361120699994, 10.769538608000062],
              [-74.88351262399993, 10.769912932000068],
              [-74.88345819099993, 10.770291976000067],
              [-74.88343936799993, 10.770383854000045],
              [-74.88343691799992, 10.770429815000057],
              [-74.88343677699993, 10.770484964000048],
              [-74.88343900199993, 10.770524046000048],
              [-74.88345050499993, 10.770572340000058],
              [-74.88345509699991, 10.77059074500005 ],
              [-74.88346894299991, 10.770629848000056],
              [-74.88350364599995, 10.770694301000049],
              [-74.8837723229999 , 10.771051318000048],
              [-74.88434249599993, 10.771666628000048],
              [-74.88478292999991, 10.77212757500007 ],
              [-74.8849056439999 , 10.772307194000064],
              [-74.88491478999993, 10.772364681000056],
              [-74.88490760199994, 10.77244739300005 ],
              [-74.88489269599995, 10.772491250000087],
              [-74.88488418999992, 10.772516275000044],
              [-74.88483031599992, 10.77267699400005 ],
              [-74.88475749799994, 10.772973250000064],
              [-74.88473855399991, 10.773113394000063],
              [-74.88472673699994, 10.773189195000043],
              [-74.88470795199993, 10.773264978000043],
              [-74.88469372999992, 10.77337525200005 ],
              [-74.8846865349999 , 10.773457965000091],
              [-74.88467691999995, 10.773582049000083],
              [-74.88466031399992, 10.773715290000041],
              [-74.88466010299993, 10.77380032800005 ],
              [-74.8846318759999 , 10.773931248000054],
              [-74.88460607799993, 10.774018501000057],
              [-74.88457803199992, 10.77408276500006 ],
              [-74.88452665199992, 10.77417685100005 ],
              [-74.88447063299992, 10.77426631500009 ],
              [-74.88443332399993, 10.774316763000058],
              [-74.88437273599993, 10.77438094100006 ],
              [-74.8843004979999 , 10.774454269000046],
              [-74.8842445869999 , 10.774506961000043],
              [-74.88416307299991, 10.774568773000055],
              [-74.88410951899994, 10.774605389000044],
              [-74.88404426699992, 10.774669557000038],
              [-74.88402328099994, 10.774701657000037],
              [-74.88397661699992, 10.774772767000059],
              [-74.8839228839999 , 10.774880624000048],
              [-74.88390645999993, 10.774940341000047],
              [-74.88387323799992, 10.775204535000057],
              [-74.88387542799995, 10.775259694000056],
              [-74.88389623699993, 10.775298830000054],
              [-74.88391481499991, 10.775305777000085],
              [-74.88395656299991, 10.775340380000046],
              [-74.88304789199992, 10.777116546000059],
              [-74.8827808449999 , 10.777019220000057],
              [-74.88248002599994, 10.777635331000056],
              [-74.8820671119999 , 10.778481033000048],
              [-74.88143731999992, 10.779770935000045],
              [-74.88121718699995, 10.780221788000063],
              [-74.88075272899994, 10.781173052000042],
              [-74.88071659799994, 10.781243964000055],
              [-74.88069854499992, 10.781279398000038],
              [-74.8806952939999 , 10.781283416000065],
              [-74.88054928299994, 10.781463905000066],
              [-74.88054291599991, 10.781471774000067],
              [-74.8808944939999 , 10.781679548000056],
              [-74.88090320899994, 10.78168469700006 ],
              [-74.88138708299994, 10.781970649000073],
              [-74.88213015199995, 10.782409776000065],
              [-74.8827446379999 , 10.782772915000066],
              [-74.8828260709999 , 10.782800016000067],
              [-74.8830449479999 , 10.782906537000088],
              [-74.8841527749999 , 10.782978997000043],
              [-74.88459611099995, 10.78316161400005 ],
              [-74.88530490799991, 10.783453583000039],
              [-74.88536334199995, 10.78346883200004 ],
              [-74.88547844599992, 10.783505413000057],
              [-74.88554351099992, 10.78350798200006 ],
              [-74.88590377999992, 10.783608067000046],
              [-74.88610482899992, 10.783663918000059],
              [-74.88612666699993, 10.783669982000049],
              [-74.88616964199991, 10.783681926000042],
              [-74.88616971699992, 10.783681947000048],
              [-74.8861697769999 , 10.783681947000048],
              [-74.88625729799992, 10.783686721000038],
              [-74.88625738899992, 10.783686721000038],
              [-74.88669975399995, 10.783710833000043],
              [-74.88668925099995, 10.783791188000066],
              [-74.88668430199994, 10.78382904600005 ],
              [-74.88668308099994, 10.783838432000039],
              [-74.88669430999994, 10.783987766000052],
              [-74.88668154899995, 10.78408464000006 ],
              [-74.88667571799994, 10.784113059000049],
              [-74.88664395299992, 10.784221995000053],
              [-74.8866198209999 , 10.784301507000066],
              [-74.88655502599994, 10.78444464000006 ],
              [-74.8865016229999 , 10.784598406000043],
              [-74.88640542299993, 10.784875404000047],
              [-74.88638216499993, 10.784960383000055],
              [-74.88637005299995, 10.785004636000053],
              [-74.88635003899992, 10.78506548200005 ],
              [-74.8863173009999 , 10.785120620000043],
              [-74.88629637299994, 10.785184735000087],
              [-74.88626727699994, 10.785261569000056],
              [-74.88623130199994, 10.785344673000054],
              [-74.88621776399992, 10.78536464800004 ],
              [-74.88616155499994, 10.785415490000048],
              [-74.88607967899992, 10.78552039400006 ],
              [-74.8860062469999 , 10.78561908000006 ],
              [-74.88594082999992, 10.785733359000062],
              [-74.88591820799991, 10.78577269200008 ],
              [-74.88590882299991, 10.785789008000052],
              [-74.88585051399991, 10.785913483000058],
              [-74.88582791999994, 10.785999490000052],
              [-74.88580189299995, 10.786085244000049],
              [-74.8857817199999 , 10.786169032000089],
              [-74.88566529999991, 10.786944634000065],
              [-74.88562431999992, 10.787230045000058],
              [-74.88562174299994, 10.787359390000063],
              [-74.8855919099999 , 10.787481575000072],
              [-74.88558391399994, 10.787547109000059],
              [-74.88554186599993, 10.787949285000082],
              [-74.88553880299992, 10.78797858200005 ],
              [-74.88551151699994, 10.788044997000043],
              [-74.8854811299999 , 10.788090155000077],
              [-74.88541119299992, 10.788194090000047],
              [-74.8853948439999 , 10.78822391500006 ],
              [-74.88536215599993, 10.788283574000047],
              [-74.88517191699992, 10.788142823000044],
              [-74.88511825699993, 10.788220804000048],
              [-74.88503887099995, 10.788363055000048],
              [-74.88502474199993, 10.788434264000045],
              [-74.88498489799991, 10.788562846000048],
              [-74.88497530599994, 10.78867772700005 ],
              [-74.88496663299992, 10.788743323000062],
              [-74.88495160499991, 10.78885692600005 ],
              [-74.88494890099992, 10.788908441000046],
              [-74.88477175199995, 10.789236410000058],
              [-74.88467041099995, 10.78954830500004 ],
              [-74.88464605599995, 10.789623255000038],
              [-74.88428618299992, 10.790730877000044],
              [-74.88427040399995, 10.79072463500006 ],
              [-74.88380553699994, 10.790540753000073],
              [-74.88328518599991, 10.790582615000062],
              [-74.88293819099994, 10.79061052500009 ],
              [-74.88281965599992, 10.790620059000048],
              [-74.88281079399991, 10.79064285700008 ],
              [-74.8816516999999 , 10.793624475000058],
              [-74.88165168499995, 10.79362451500009 ],
              [-74.88157589799994, 10.793819466000059],
              [-74.8790216619999 , 10.792040919000044],
              [-74.87768236599993, 10.792927956000085],
              [-74.87767341299991, 10.792949284000088],
              [-74.87690478399992, 10.794780350000053],
              [-74.87690465599991, 10.794780653000089],
              [-74.87665931799995, 10.79536511100008 ],
              [-74.87666080999992, 10.79536618600008 ],
              [-74.87673432899993, 10.795419141000082],
              [-74.87686545999992, 10.795513595000045],
              [-74.87714592499992, 10.795588233000046],
              [-74.88042254999993, 10.797760654000058],
              [-74.8807525929999 , 10.797979474000044],
              [-74.88496186899994, 10.797994083000049],
              [-74.88508612499993, 10.797994524000046],
              [-74.88513802099993, 10.797994707000043],
              [-74.8851193129999 , 10.798153906000039],
              [-74.88436254399994, 10.804593655000076],
              [-74.88436195699995, 10.804598662000046],
              [-74.8842828519999 , 10.804616764000059],
              [-74.88411298599993, 10.804673638000054],
              [-74.88409219099992, 10.80467741700005 ],
              [-74.8840848939999 , 10.804638158000046],
              [-74.8840292669999 , 10.804648519000068],
              [-74.88353852099993, 10.804739934000054],
              [-74.8831894519999 , 10.804804957000044],
              [-74.88316203699992, 10.80481332100004 ],
              [-74.88290752199993, 10.804890969000041],
              [-74.88263032999993, 10.80497553300006 ],
              [-74.88247283299995, 10.805023580000068],
              [-74.88212308999994, 10.805168352000067],
              [-74.88191661399992, 10.805266285000073],
              [-74.8816708029999 , 10.805349796000087],
              [-74.88149761099993, 10.805443640000078],
              [-74.88148522699993, 10.805450351000047],
              [-74.88139324699995, 10.805492608000066],
              [-74.88138844799994, 10.805494813000053],
              [-74.88133040299994, 10.805521491000093],
              [-74.8811012569999 , 10.80562679400009 ],
              [-74.88107727199991, 10.805637816000058],
              [-74.8807752539999 , 10.805728901000066],
              [-74.88045355799994, 10.805758326000046],
              [-74.88016714499992, 10.805765312000062],
              [-74.87996471699995, 10.805770254000038],
              [-74.87972125199991, 10.805843379000066],
              [-74.87941353999992, 10.80600869500006 ],
              [-74.87917742999991, 10.806069360000038],
              [-74.87892053099995, 10.806120502000056],
              [-74.87871739799994, 10.806160941000087],
              [-74.87844438999991, 10.806198083000083],
              [-74.87818590199993, 10.806205508000062],
              [-74.8780865459999 , 10.806214258000068],
              [-74.87792015699995, 10.806228911000062],
              [-74.87775645099993, 10.80627462600006 ],
              [-74.87763331399992, 10.806399150000061],
              [-74.8774826309999 , 10.806638225000086],
              [-74.87737077499992, 10.806783628000062],
              [-74.87728033099995, 10.806901197000059],
              [-74.87721575099994, 10.806941560000041],
              [-74.87719330499993, 10.80695558900004 ],
              [-74.87710421399993, 10.80701126200006 ],
              [-74.87701369199993, 10.80711602000008 ],
              [-74.87693272699994, 10.807222868000053],
              [-74.8768208489999 , 10.807370516000049],
              [-74.87671779899995, 10.807545953000044],
              [-74.87658712799993, 10.807840523000039],
              [-74.87661062199993, 10.80784894900006 ],
              [-74.87732816699992, 10.80810630700006 ],
              [-74.8802148499999 , 10.809141650000072],
              [-74.88021766499992, 10.80921928400005 ],
              [-74.88024432499992, 10.809955737000053],
              [-74.88024500499995, 10.809974520000083],
              [-74.8802310719999 , 10.809988476000058],
              [-74.87996619299992, 10.81025380400007 ],
              [-74.87972628299991, 10.810494113000061],
              [-74.87972129299993, 10.810499112000059],
              [-74.87950088199995, 10.810719888000051],
              [-74.87920084199993, 10.811020425000038],
              [-74.87922564099995, 10.81104819300009 ],
              [-74.87939702699992, 10.811240096000063],
              [-74.8796965709999 , 10.811575494000067],
              [-74.87959622599993, 10.812003914000059],
              [-74.87958784999995, 10.812132475000055],
              [-74.87957736899995, 10.812293347000093],
              [-74.87953984299992, 10.812446120000061],
              [-74.87947417499993, 10.812713461000044],
              [-74.87942489399995, 10.812914094000064],
              [-74.87939013399995, 10.813043450000066],
              [-74.87866413099994, 10.813036327000077],
              [-74.8782409769999 , 10.813032175000046],
              [-74.87496991299992, 10.813000074000058],
              [-74.87496949399991, 10.812968916000045],
              [-74.8749688179999 , 10.812918702000047],
              [-74.87358137099994, 10.812905070000056],
              [-74.87181114899994, 10.812887665000062],
              [-74.86918530399993, 10.812861845000043],
              [-74.86901085199992, 10.812860127000079],
              [-74.86263175899995, 10.81279728900006 ],
              [-74.85960114199992, 10.81276738400004 ],
              [-74.85748953599995, 10.812746531000073],
              [-74.85640914499993, 10.812735854000039],
              [-74.85389767999993, 10.81271103000006 ],
              [-74.84931816499994, 10.812665708000054],
              [-74.84819962199992, 10.812654627000086],
              [-74.84819848699993, 10.812654616000088],
              [-74.84737085899991, 10.812646416000064],
              [-74.8461754299999 , 10.812634565000053],
              [-74.84478577199991, 10.812620777000063],
              [-74.84450372599991, 10.812617978000048],
              [-74.84420772499993, 10.812615042000061],
              [-74.84415951699992, 10.812614564000057],
              [-74.84378259699992, 10.812610824000046]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08560",
      "properties": {"id": "08560", "iso": "PON", "name": "PONEDERA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.87252064899991, 10.721046201000092],
              [-74.87227668399993, 10.721005518000084],
              [-74.87208927899991, 10.721031813000081],
              [-74.87194444399995, 10.721088467000072],
              [-74.87191662299995, 10.72116049500005 ],
              [-74.87189863099991, 10.72132990700004 ],
              [-74.87199769699993, 10.721447786000056],
              [-74.87214114199992, 10.72152371900006 ],
              [-74.87225155499993, 10.721558045000052],
              [-74.87247723199994, 10.721661466000057],
              [-74.8725995339999 , 10.721766609000042],
              [-74.87261147399994, 10.721776875000046],
              [-74.87269520899991, 10.721907699000042],
              [-74.87272252299994, 10.721950375000063],
              [-74.87275617299991, 10.722126524000089],
              [-74.87275430499994, 10.722230917000047],
              [-74.87274801199993, 10.722393314000044],
              [-74.87277339499991, 10.722462613000062],
              [-74.87278364199994, 10.722490588000085],
              [-74.87276994199993, 10.722571834000064],
              [-74.87266502599994, 10.72271377900006 ],
              [-74.87253888299995, 10.722830295000051],
              [-74.87240089499994, 10.722921348000057],
              [-74.87222525499993, 10.72298704800005 ],
              [-74.87203302299991, 10.723015702000055],
              [-74.87189881799992, 10.723019909000072],
              [-74.87186180099991, 10.723021069000083],
              [-74.87173486099994, 10.722963618000051],
              [-74.87162201699994, 10.72291074800006 ],
              [-74.87150208999992, 10.722848629000055],
              [-74.87141470299991, 10.722719102000042],
              [-74.87138362299993, 10.722591654000041],
              [-74.8713193939999 , 10.722397081000054],
              [-74.87126016499991, 10.722269753000091],
              [-74.87119177599993, 10.722191175000091],
              [-74.87106012299995, 10.722129108000047],
              [-74.87090265599994, 10.722064826000064],
              [-74.87073743199994, 10.721958802000074],
              [-74.87069796499992, 10.72193347700005 ],
              [-74.87053576399995, 10.721859940000058],
              [-74.87029795599994, 10.72166842100006 ],
              [-74.8701741989999 , 10.721542834000047],
              [-74.87003163499992, 10.721398159000046],
              [-74.87001040699994, 10.721355955000092],
              [-74.8699606159999 , 10.721256963000087],
              [-74.86990341599994, 10.721060040000054],
              [-74.86991930599993, 10.720943988000045],
              [-74.86993323399992, 10.720910330000038],
              [-74.87002603499991, 10.720686059000059],
              [-74.87008162599994, 10.72053040500009 ],
              [-74.8701811979999 , 10.720246985000074],
              [-74.87021835299993, 10.720163325000044],
              [-74.87029474199994, 10.719940314000041],
              [-74.87031976199995, 10.719768555000087],
              [-74.8702857049999 , 10.719501944000058],
              [-74.87019863499995, 10.719442         ],
              [-74.87010712699993, 10.719437751000044],
              [-74.87000419799995, 10.719500822000043],
              [-74.8698645209999 , 10.719735696000043],
              [-74.86981579999991, 10.719854205000047],
              [-74.86975028099994, 10.719964793000088],
              [-74.86974829099995, 10.719968154000071],
              [-74.86966409599995, 10.720024181000042],
              [-74.86957719599991, 10.720001352000054],
              [-74.86957399599993, 10.719998280000084],
              [-74.86946174199994, 10.719890502000055],
              [-74.8693765619999 , 10.719730809000055],
              [-74.86932578499994, 10.719658339000091],
              [-74.86929391899992, 10.71961286000004 ],
              [-74.86922537599992, 10.719501810000054],
              [-74.8692294789999 , 10.719371894000062],
              [-74.86932030799994, 10.719147606000092],
              [-74.86932922499994, 10.719125587000065],
              [-74.86949724399994, 10.718932345000042],
              [-74.86955807499993, 10.718897293000055],
              [-74.8696135369999 , 10.718713807000086],
              [-74.86960108799991, 10.718556123000042],
              [-74.8695183449999 , 10.718417301000045],
              [-74.86951560499995, 10.718373746000054],
              [-74.86951075999991, 10.718296710000061],
              [-74.86959211099992, 10.718131671000037],
              [-74.86960462699994, 10.718122210000047],
              [-74.86974640299991, 10.71801503200004 ],
              [-74.8698725459999 , 10.717898516000048],
              [-74.86999604599993, 10.717717058000062],
              [-74.87005605099995, 10.717501079000044],
              [-74.8700506269999 , 10.717341046000058],
              [-74.8700079269999 , 10.717236845000059],
              [-74.8699443669999 , 10.71718840400007 ],
              [-74.86979407699994, 10.717154249000089],
              [-74.86959001399993, 10.717159756000058],
              [-74.86938388399994, 10.717225579000058],
              [-74.86919635599992, 10.717256534000057],
              [-74.86905584499993, 10.717308161000062],
              [-74.86892021299991, 10.717401521000056],
              [-74.86890905799993, 10.71745799200005 ],
              [-74.86887893799991, 10.717610465000064],
              [-74.86886337899995, 10.717798422000044],
              [-74.86883611899992, 10.717993386000046],
              [-74.86878087999992, 10.718225583000049],
              [-74.86862445299994, 10.718388621000088],
              [-74.86820129499995, 10.71869199400004 ],
              [-74.86798595499994, 10.718804273000046],
              [-74.86776798099993, 10.718853921000061],
              [-74.86764136099993, 10.718870697000057],
              [-74.86749584199993, 10.718859715000065],
              [-74.86734539799994, 10.718797711000093],
              [-74.8672676409999 , 10.718726117000074],
              [-74.86707409499991, 10.718474051000044],
              [-74.86706628999991, 10.71830704100006 ],
              [-74.86706563099995, 10.71816319700008 ],
              [-74.86713475099992, 10.717886814000053],
              [-74.86719952499993, 10.717687013000045],
              [-74.86729085699994, 10.717649505000054],
              [-74.86743605899994, 10.71759088500005 ],
              [-74.86757393099992, 10.717469657000038],
              [-74.86775296799993, 10.71734788000009 ],
              [-74.86783823099995, 10.717289888000039],
              [-74.86797614499994, 10.717177941000045],
              [-74.86809984199994, 10.717035888000055],
              [-74.86826806399995, 10.716882050000038],
              [-74.86835224299995, 10.716819053000052],
              [-74.86851358699994, 10.71670004300006 ],
              [-74.86881755799993, 10.716473706000045],
              [-74.8689297709999 , 10.716385064000065],
              [-74.86902100099991, 10.716326676000051],
              [-74.86904375299991, 10.716171134000092],
              [-74.86901481099994, 10.715999571000054],
              [-74.86890365699992, 10.715807476000066],
              [-74.86883523199992, 10.715724244000057],
              [-74.86870795499993, 10.715597179000042],
              [-74.86862335299992, 10.71556737700007 ],
              [-74.86847319799995, 10.715568018000056],
              [-74.8683394759999 , 10.715581695000083],
              [-74.86795257999995, 10.715621269000053],
              [-74.86773229499994, 10.715677883000069],
              [-74.8675517659999 , 10.715706491000049],
              [-74.86730311299993, 10.715716827000051],
              [-74.86719755699994, 10.715721912000049],
              [-74.86685726299993, 10.715702477000093],
              [-74.86671409399992, 10.715691483000057],
              [-74.86658711299992, 10.715629379000063],
              [-74.86646653399993, 10.715428042000042],
              [-74.8664402679999 , 10.71532839200006 ],
              [-74.86645348999991, 10.715142728000046],
              [-74.86652522599991, 10.71492433800006 ],
              [-74.86670878599995, 10.714533786000061],
              [-74.86685837999994, 10.714410186000066],
              [-74.86697545399994, 10.71435865300009 ],
              [-74.8671113499999 , 10.714342644000055],
              [-74.86730130899991, 10.714351419000081],
              [-74.86734138499992, 10.71435327100005 ],
              [-74.86760219699994, 10.714421788000038],
              [-74.8678963989999 , 10.714606200000048],
              [-74.86801154299991, 10.71461052300009 ],
              [-74.86806305099992, 10.714612456000054],
              [-74.86826413099993, 10.71445146900004 ],
              [-74.86844613099993, 10.714223265000044],
              [-74.8684947399999 , 10.714076849000037],
              [-74.8685115159999 , 10.714011334000077],
              [-74.86851318299995, 10.714004827000053],
              [-74.86846134599995, 10.713960954000072],
              [-74.8683744679999 , 10.713949719000084],
              [-74.86836110299993, 10.713959721000037],
              [-74.86828091799993, 10.714019738000047],
              [-74.86821348099994, 10.714154631000042],
              [-74.86813623899991, 10.714199050000047],
              [-74.86799495299994, 10.714239894000059],
              [-74.86796515399993, 10.714248510000061],
              [-74.8677937189999 , 10.714223711000045],
              [-74.86778627199993, 10.714221206000047],
              [-74.86772555999994, 10.714200796000057],
              [-74.86766412599991, 10.71411054500004 ],
              [-74.86767514299993, 10.713955006000049],
              [-74.867651        , 10.713835707000044],
              [-74.86763431799994, 10.713753275000045],
              [-74.8675613659999 , 10.713709490000042],
              [-74.86728221899995, 10.713736203000053],
              [-74.86715891499995, 10.71378198900004 ],
              [-74.86694289999991, 10.713862204000066],
              [-74.86673406999995, 10.713907259000052],
              [-74.86650415299994, 10.713910553000062],
              [-74.86629935499991, 10.713924214000087],
              [-74.86628133499994, 10.713925417000041],
              [-74.86603767299994, 10.71400069300006 ],
              [-74.86582923599991, 10.714082776000055],
              [-74.8654524399999 , 10.714304835000064],
              [-74.86529311099991, 10.71436120900006 ],
              [-74.8651150359999 , 10.71442462400006 ],
              [-74.86497420699993, 10.714420576000066],
              [-74.86485659999994, 10.714363057000071],
              [-74.8648268849999 , 10.71431578000005 ],
              [-74.86481877299991, 10.714302874000055],
              [-74.86475937599994, 10.714145312000085],
              [-74.86476316499994, 10.713948027000072],
              [-74.86486228199993, 10.713861958000052],
              [-74.86488939199995, 10.713838415000055],
              [-74.8649525969999 , 10.713803336000069],
              [-74.8649920659999 , 10.71371033400004 ],
              [-74.86497523399993, 10.71362221600009 ],
              [-74.8648880959999 , 10.713561089000052],
              [-74.86480821899994, 10.713546334000057],
              [-74.86470605499994, 10.713535160000049],
              [-74.8645850929999 , 10.713520584000037],
              [-74.86452515099995, 10.713502270000049],
              [-74.86444283899993, 10.713470116000053],
              [-74.86434528199993, 10.713439193000056],
              [-74.86427355399991, 10.713410478000071],
              [-74.8642275709999 , 10.713365404000058],
              [-74.86418506199993, 10.71330987500005 ],
              [-74.86417408799991, 10.713220547000049],
              [-74.86417412399993, 10.713220138000054],
              [-74.86418301399993, 10.71312068900005 ],
              [-74.86420012099995, 10.713011510000058],
              [-74.8642032599999 , 10.712929088000067],
              [-74.8642085539999 , 10.712803717000043],
              [-74.86418000399993, 10.712721427000076],
              [-74.8641469559999 , 10.712680944000056],
              [-74.86410587299991, 10.712679956000045],
              [-74.86404380599993, 10.712710395000045],
              [-74.8639840429999 , 10.71273154000005 ],
              [-74.86392308699993, 10.712748049000083],
              [-74.86385745899992, 10.712770380000052],
              [-74.8637625739999 , 10.712810243000092],
              [-74.86370884099995, 10.712866184000063],
              [-74.86370081699994, 10.712908003000052],
              [-74.86367755599991, 10.712954528000068],
              [-74.86350167799992, 10.712993573000062],
              [-74.86345469499992, 10.712985647000039],
              [-74.86341587099992, 10.712966080000058],
              [-74.86335335699994, 10.71289902600006 ],
              [-74.86328926699991, 10.71274492300006 ],
              [-74.86325728999992, 10.712682384000061],
              [-74.8631909849999 , 10.712557311000069],
              [-74.86309069999993, 10.712443987000086],
              [-74.86300943299995, 10.712382817000048],
              [-74.8629294189999 , 10.712339048000047],
              [-74.86281536299992, 10.712294262000057],
              [-74.86273549099991, 10.712281831000041],
              [-74.8625922029999 , 10.71226386300009 ],
              [-74.86252757599993, 10.71227170800006 ],
              [-74.86250302499991, 10.712274688000036],
              [-74.86241863899994, 10.712306393000063],
              [-74.86233782999994, 10.712349692000089],
              [-74.86228164199991, 10.712385923000056],
              [-74.8622056509999 , 10.712455907000049],
              [-74.86211918399994, 10.712545671000044],
              [-74.86202915799993, 10.71262732200006 ],
              [-74.86194728099991, 10.712695006000047],
              [-74.86187824299992, 10.71274406300006 ],
              [-74.86180319599993, 10.712764117000063],
              [-74.86171992599992, 10.712783044000048],
              [-74.8615894749999 , 10.712754570000072],
              [-74.86151062199991, 10.71271310700007 ],
              [-74.86139172199995, 10.71264394800005 ],
              [-74.86123722099995, 10.712491344000057],
              [-74.86108746799994, 10.71235033000005 ],
              [-74.86099420699992, 10.712239264000061],
              [-74.86093399499993, 10.71216637200007 ],
              [-74.86085958099994, 10.712069159000066],
              [-74.86085012899991, 10.71205642800004 ],
              [-74.86076178099995, 10.711992947000056],
              [-74.86054092599994, 10.711952704000055],
              [-74.86035039899991, 10.711979976000066],
              [-74.86003732099994, 10.71209519000007 ],
              [-74.85987491499992, 10.71215045300005 ],
              [-74.85981844899993, 10.712161889000072],
              [-74.8598015849999 , 10.712160853000057],
              [-74.85977096399995, 10.712158973000044],
              [-74.85965861599993, 10.712152070000059],
              [-74.85952934199992, 10.712142348000043],
              [-74.85929629399993, 10.712229921000073],
              [-74.85921385899991, 10.712273822000043],
              [-74.85901841399993, 10.712363847000063],
              [-74.85890527599992, 10.712449494000055],
              [-74.85883924899991, 10.712507394000056],
              [-74.85872596999991, 10.712634885000057],
              [-74.85869997899994, 10.71267199600004 ],
              [-74.85860291099993, 10.712871607000068],
              [-74.85852495599994, 10.712980615000049],
              [-74.85838108299993, 10.713114974000064],
              [-74.85819756399991, 10.713156224000045],
              [-74.85806802199994, 10.713132654000049],
              [-74.85802839299993, 10.71312544500006 ],
              [-74.85800079099994, 10.71309109200007 ],
              [-74.85791602299992, 10.712985593000042],
              [-74.85785569599994, 10.712750594000056],
              [-74.85776979299993, 10.712431823000088],
              [-74.85771398499992, 10.712250311000048],
              [-74.85761881399992, 10.711891988000048],
              [-74.85759850099993, 10.711643172000038],
              [-74.85759702999991, 10.711385121000092],
              [-74.85762801699991, 10.711243455000044],
              [-74.85764479399995, 10.711166753000043],
              [-74.85768753899993, 10.711041357000056],
              [-74.85776344399994, 10.710875835000081],
              [-74.8577822769999 , 10.710834767000051],
              [-74.85781088999994, 10.710718621000069],
              [-74.85781361599993, 10.71060704200005 ],
              [-74.85774122299995, 10.710464995000052],
              [-74.85762653099994, 10.710315837000053],
              [-74.85759186799993, 10.710139041000048],
              [-74.85762775899991, 10.70995550300006 ],
              [-74.8578022609999 , 10.709800321000046],
              [-74.85791054099991, 10.709761153000045],
              [-74.85801215999993, 10.709605727000053],
              [-74.85799644199994, 10.709389478000048],
              [-74.85802540499992, 10.709168721000083],
              [-74.85818405099991, 10.708836803000054],
              [-74.85823405499991, 10.708650986000066],
              [-74.85826573299994, 10.708323302000053],
              [-74.85823358699992, 10.708097696000038],
              [-74.8581239689999 , 10.707839291000084],
              [-74.85803236599992, 10.707813417000068],
              [-74.85791740999991, 10.707743300000061],
              [-74.85787062599991, 10.707673404000047],
              [-74.85776750299993, 10.707591730000047],
              [-74.85764538099994, 10.707523924000043],
              [-74.85760330799991, 10.707500562000064],
              [-74.85742492799994, 10.70743490700005 ],
              [-74.85729812799991, 10.707404280000048],
              [-74.85712178999995, 10.707429263000051],
              [-74.85705330799993, 10.707526642000062],
              [-74.85696823299992, 10.707665793000047],
              [-74.8568975419999 , 10.707721332000062],
              [-74.85674699899994, 10.70776266900009 ],
              [-74.85666844899993, 10.707835677000048],
              [-74.85652775099993, 10.707966450000072],
              [-74.85643583199993, 10.70804515900005 ],
              [-74.85636048799995, 10.708086739000066],
              [-74.85624254099992, 10.708216488000062],
              [-74.85609670699995, 10.708255518000044],
              [-74.85598620999991, 10.708269094000059],
              [-74.85586405599992, 10.708254749000048],
              [-74.8556552039999 , 10.708165760000043],
              [-74.8556236359999 , 10.708143126000039],
              [-74.85553798599994, 10.708081715000048],
              [-74.85536668799995, 10.70800678900008 ],
              [-74.85514843499993, 10.707917765000047],
              [-74.85487860599994, 10.707791391000058],
              [-74.85471429699993, 10.707735076000063],
              [-74.85456636399994, 10.707699730000058],
              [-74.85432464399992, 10.70760133500005 ],
              [-74.85416015099992, 10.707598469000061],
              [-74.8540260339999 , 10.707646830000044],
              [-74.85386607399994, 10.707692780000059],
              [-74.85380786199994, 10.70770923300006 ],
              [-74.85359790799993, 10.707768584000064],
              [-74.85341670899993, 10.707840024000063],
              [-74.85325668199994, 10.70790689100005 ],
              [-74.85324065799995, 10.707909477000044],
              [-74.85320370099993, 10.707915443000047],
              [-74.85314381899991, 10.707925106000062],
              [-74.8531347679999 , 10.707924379000076],
              [-74.85297731599991, 10.70791175000005 ],
              [-74.85285753499994, 10.707890439000039],
              [-74.85274476399991, 10.707880773000056],
              [-74.85271835299994, 10.707872593000047],
              [-74.85264616499995, 10.70785023700006 ],
              [-74.8523077019999 , 10.707865385000048],
              [-74.85219934699995, 10.70793939400005 ],
              [-74.85201592999994, 10.707971326000063],
              [-74.85189380899993, 10.707947682000054],
              [-74.85177406699995, 10.707914755000047],
              [-74.85162133199992, 10.707902629000046],
              [-74.85152255899993, 10.70791857000006 ],
              [-74.85144965699993, 10.70792995100004 ],
              [-74.85137914699993, 10.70792971800006 ],
              [-74.85129687999995, 10.707929446000037],
              [-74.85120528499993, 10.707944662000045],
              [-74.85117370099994, 10.707949908000046],
              [-74.85116245499995, 10.707951777000062],
              [-74.85113226099992, 10.70795679300005 ],
              [-74.85106016299994, 10.707952627000054],
              [-74.8509881789999 , 10.707978584000045],
              [-74.85089921799994, 10.70801066200005 ],
              [-74.85074158399993, 10.708063589000062],
              [-74.85059655899994, 10.708152238000082],
              [-74.85047311499994, 10.708227694000072],
              [-74.85034918199995, 10.708276243000057],
              [-74.85030839099994, 10.708292223000058],
              [-74.85017907999992, 10.708310384000072],
              [-74.84992998999991, 10.708304913000063],
              [-74.84980307199993, 10.708309143000065],
              [-74.84961777899991, 10.70831695000004 ],
              [-74.84959858699995, 10.708317760000057],
              [-74.84945923499993, 10.70828446300004 ],
              [-74.84941529399993, 10.708273965000046],
              [-74.84923993199993, 10.708232685000041],
              [-74.84885159099991, 10.708061332000057],
              [-74.84846809099992, 10.70777075400008 ],
              [-74.8483059859999 , 10.707598287000053],
              [-74.84818945099994, 10.707375974000058],
              [-74.84815988799994, 10.707209935000037],
              [-74.84813661099992, 10.707042759000046],
              [-74.84813613599994, 10.706854060000069],
              [-74.84815933899995, 10.706659089000084],
              [-74.8482630499999 , 10.70644131000006 ],
              [-74.84836111899995, 10.706142754000041],
              [-74.8484072299999 , 10.705851106000068],
              [-74.84839510799992, 10.705582783000068],
              [-74.8484379059999 , 10.705226168000081],
              [-74.84850402799992, 10.70505989700007 ],
              [-74.84859111499992, 10.70493312800005 ],
              [-74.84870078999995, 10.70470404300005 ],
              [-74.84880755499995, 10.704457434000062],
              [-74.84885679999991, 10.704279342000063],
              [-74.84889691099994, 10.70410184700006 ],
              [-74.8489082019999 , 10.703827230000059],
              [-74.84890277599993, 10.703632546000051],
              [-74.84888812499992, 10.703106925000043],
              [-74.84886878499992, 10.702803891000087],
              [-74.84886633299993, 10.702765462000059],
              [-74.84864606499991, 10.70255902200006 ],
              [-74.8484054139999 , 10.702569966000056],
              [-74.84834962399992, 10.702615638000054],
              [-74.84822789399993, 10.70271529300004 ],
              [-74.84818317899993, 10.702794372000085],
              [-74.84804983499993, 10.70303019700009 ],
              [-74.8479516239999 , 10.70320388500005 ],
              [-74.8478573619999 , 10.703370591000066],
              [-74.84771737199992, 10.70359622400008 ],
              [-74.84756080199992, 10.703751846000046],
              [-74.84728878599992, 10.703773216000059],
              [-74.84713027499993, 10.703578984000046],
              [-74.84710990299993, 10.703380393000089],
              [-74.84705729199993, 10.703266682000049],
              [-74.84693120299994, 10.703060012000037],
              [-74.84674720299995, 10.702983927000048],
              [-74.84673217599993, 10.702977711000074],
              [-74.84653332899995, 10.702967854000065],
              [-74.84637618599993, 10.702895797000053],
              [-74.8463706039999 , 10.702868550000062],
              [-74.84634439899992, 10.70274063900007 ],
              [-74.84642738499991, 10.702450661000057],
              [-74.84669921799991, 10.702356851000047],
              [-74.84699176599992, 10.70218019300006 ],
              [-74.84739918699995, 10.701910113000054],
              [-74.84745176799993, 10.70174783400006 ],
              [-74.84734420299992, 10.70154748400006 ],
              [-74.84715765499993, 10.701569190000043],
              [-74.84686457899994, 10.701538863000053],
              [-74.84648821199994, 10.70167433000006 ],
              [-74.84604152599991, 10.701751314000091],
              [-74.84572432199991, 10.701752097000053],
              [-74.84556698199992, 10.70152136200005 ],
              [-74.84547567899995, 10.701252706000048],
              [-74.84547209899995, 10.701242172000093],
              [-74.8455130989999 , 10.700900553000054],
              [-74.8457006669999 , 10.700589617000048],
              [-74.84583594799994, 10.700289160000068],
              [-74.84607555299993, 10.699864258000048],
              [-74.84607793299995, 10.699827570000082],
              [-74.84608562599993, 10.699708998000062],
              [-74.84601209099992, 10.699595339000041],
              [-74.84589045599995, 10.699531566000076],
              [-74.8458549209999 , 10.69951293400004 ],
              [-74.84569677499991, 10.699604087000068],
              [-74.84567907799993, 10.699598305000052],
              [-74.84553121099992, 10.699782808000066],
              [-74.84550012599993, 10.699794359000066],
              [-74.8453327009999 , 10.699856560000057],
              [-74.84525920199991, 10.699804175000054],
              [-74.84515411899991, 10.699628498000038],
              [-74.84494452599995, 10.699504827000055],
              [-74.84485457099993, 10.699518742000066],
              [-74.8448085409999 , 10.699525860000051],
              [-74.84465166299992, 10.699557292000065],
              [-74.84450091299993, 10.69960119700005 ],
              [-74.8444980349999 , 10.699593502000084],
              [-74.84444209599991, 10.699443966000047],
              [-74.84450435799994, 10.699236833000043],
              [-74.84480745299993, 10.699091200000055],
              [-74.84514204099992, 10.698986885000068],
              [-74.84554928699993, 10.698877756000059],
              [-74.84570954899993, 10.69871846600006 ],
              [-74.84581939899994, 10.69860927700006 ],
              [-74.8459880609999 , 10.69836385700006 ],
              [-74.84598077599992, 10.69825968200007 ],
              [-74.84597720699992, 10.698208649000037],
              [-74.84565455899991, 10.698022170000058],
              [-74.84560863799993, 10.697995627000068],
              [-74.84539325599991, 10.697946467000065],
              [-74.84536961899994, 10.69794107000007 ],
              [-74.84533913099995, 10.697915355000077],
              [-74.8451494059999 , 10.697755328000085],
              [-74.8448038489999 , 10.697658415000092],
              [-74.8444276539999 , 10.69786059200004 ],
              [-74.84426022999992, 10.69786100500005 ],
              [-74.84412404299991, 10.697799245000056],
              [-74.84402401699992, 10.697603066000056],
              [-74.84398764499991, 10.697414508000065],
              [-74.84399848299995, 10.697336417000088],
              [-74.84403926199991, 10.697042624000062],
              [-74.84405652399994, 10.696587467000086],
              [-74.84408906399995, 10.695729487000051],
              [-74.8441120519999 , 10.695446382000057],
              [-74.84424487699994, 10.695222322000063],
              [-74.84444449999995, 10.694987353000045],
              [-74.84452841199993, 10.69485267400006 ],
              [-74.84455710999993, 10.694677458000058],
              [-74.8445390739999 , 10.694436826000072],
              [-74.84438759199992, 10.693832675000067],
              [-74.8440041799999 , 10.693340956000043],
              [-74.84388152299994, 10.693321521000087],
              [-74.84380417199992, 10.69330926400005 ],
              [-74.84369192799994, 10.693372336000039],
              [-74.84360423099992, 10.693421613000055],
              [-74.84340734099993, 10.693611365000038],
              [-74.84332664599992, 10.693777669000042],
              [-74.84326871399992, 10.693910019000043],
              [-74.8431936689999 , 10.694052580000061],
              [-74.84310713199994, 10.694168052000066],
              [-74.84294518599995, 10.694282577000081],
              [-74.8427481089999 , 10.694397759000083],
              [-74.84246967099995, 10.694415960000072],
              [-74.84245731699991, 10.694412012000043],
              [-74.84223812699992, 10.694341948000044],
              [-74.84218079099992, 10.694310232000078],
              [-74.84208839699994, 10.694259122000062],
              [-74.84172156799991, 10.69405620300006 ],
              [-74.8416229369999 , 10.693907305000039],
              [-74.84154629299991, 10.693820432000052],
              [-74.8414050959999 , 10.693660378000061],
              [-74.84125701499994, 10.693553397000073],
              [-74.84106575299995, 10.693479295000088],
              [-74.84087738999995, 10.693424966000066],
              [-74.8408276859999 , 10.693250783000053],
              [-74.8409782249999 , 10.693089666000049],
              [-74.84137203799992, 10.692950843000062],
              [-74.84198607199994, 10.692702999000062],
              [-74.84206966099993, 10.692551381000044],
              [-74.8419738099999 , 10.692370827000047],
              [-74.84177044099994, 10.692141936000041],
              [-74.84164275299992, 10.69191061400005 ],
              [-74.84146829199994, 10.691707646000054],
              [-74.84140135599995, 10.691672784000048],
              [-74.84124807799992, 10.69159294900004 ],
              [-74.84107430899991, 10.691548169000043],
              [-74.84083915699995, 10.69151428300006 ],
              [-74.84077120799992, 10.69146352000007 ],
              [-74.84064771299995, 10.691371253000057],
              [-74.8406098079999 , 10.691179815000055],
              [-74.84073137699994, 10.691019063000056],
              [-74.8408933529999 , 10.690918090000082],
              [-74.84106104699993, 10.690817687000049],
              [-74.84127574699994, 10.69078494100006 ],
              [-74.84152778399994, 10.690776416000062],
              [-74.84175644499993, 10.690673031000074],
              [-74.84180394599991, 10.690572258000088],
              [-74.84182576799992, 10.690525960000059],
              [-74.84182247099994, 10.690348568000047],
              [-74.84176149099994, 10.69018260200005 ],
              [-74.84165718999992, 10.690045007000037],
              [-74.8413086889999 , 10.689805186000058],
              [-74.84129291599993, 10.689799314000084],
              [-74.84112629499992, 10.689737272000059],
              [-74.84083894799994, 10.689731768000058],
              [-74.84061909699994, 10.689761691000058],
              [-74.84046810899991, 10.68979740800006 ],
              [-74.84030053599992, 10.689837049000062],
              [-74.84005119999995, 10.689957441000047],
              [-74.83992406099992, 10.690054926000073],
              [-74.83973589699991, 10.690193256000043],
              [-74.83969502399992, 10.69018900900005 ],
              [-74.83957647599993, 10.690176695000048],
              [-74.83944557299992, 10.69002729500005 ],
              [-74.83943068899993, 10.689671957000087],
              [-74.83940735099992, 10.68947765900009 ],
              [-74.83939458999993, 10.68947066600009 ],
              [-74.8391979889999 , 10.689362919000075],
              [-74.83891438199993, 10.68948395600006 ],
              [-74.83867555599994, 10.68968850500005 ],
              [-74.8384858739999 , 10.689679925000064],
              [-74.83846623499994, 10.689648036000051],
              [-74.8383872149999 , 10.689519718000042],
              [-74.83831683799991, 10.68901819100006 ],
              [-74.83830464199991, 10.688821045000054],
              [-74.83825203599991, 10.688778841000044],
              [-74.83814808299991, 10.688695436000046],
              [-74.83807522099994, 10.688569624000081],
              [-74.83808953999994, 10.688357711000037],
              [-74.83818478799992, 10.68818234300005 ],
              [-74.83848312799995, 10.688018893000049],
              [-74.83862822099991, 10.68797666000006 ],
              [-74.83877282599991, 10.68793456800006 ],
              [-74.83896976299991, 10.687879833000068],
              [-74.83920172299992, 10.687896221000074],
              [-74.83943954599994, 10.687970215000064],
              [-74.83959590699993, 10.687906550000037],
              [-74.83969432599991, 10.687745851000045],
              [-74.8396823249999 , 10.687517625000055],
              [-74.8395887289999 , 10.687322375000065],
              [-74.83941457299994, 10.687122799000065],
              [-74.83924064999991, 10.687019276000058],
              [-74.83906113799992, 10.68696321300007 ],
              [-74.83879142099994, 10.686923208000053],
              [-74.83860027299994, 10.68700954700006 ],
              [-74.83842639599993, 10.68703822200007 ],
              [-74.83823516599995, 10.686973158000058],
              [-74.83809869399994, 10.686770093000064],
              [-74.83804006999992, 10.686626730000057],
              [-74.83798800199992, 10.686478277000049],
              [-74.83788139599994, 10.686342679000063],
              [-74.83779065099992, 10.686255018000054],
              [-74.83774762699994, 10.68623189100009 ],
              [-74.83770913699993, 10.686211203000084],
              [-74.83757300899993, 10.68613804000006 ],
              [-74.83737883599991, 10.686044164000066],
              [-74.8371757349999 , 10.685921507000046],
              [-74.83717230999991, 10.685911309000062],
              [-74.83711127499993, 10.685729563000052],
              [-74.83719085599995, 10.685511571000063],
              [-74.83739780699995, 10.685407951000059],
              [-74.83758891799994, 10.685306916000059],
              [-74.83780555499993, 10.685198270000058],
              [-74.8378062829999 , 10.685197905000052],
              [-74.83803816299991, 10.685182644000065],
              [-74.83822334999991, 10.685228520000067],
              [-74.83845552299994, 10.685325126000066],
              [-74.83903309399994, 10.685802815000045],
              [-74.83926502999992, 10.68592540800006 ],
              [-74.8394940259999 , 10.68600790000005 ],
              [-74.8396738159999 , 10.686065085000052],
              [-74.83984775899995, 10.686061835000089],
              [-74.8399751309999 , 10.685941179000054],
              [-74.84005022999992, 10.685711039000068],
              [-74.83996850099993, 10.685464912000043],
              [-74.83987556999995, 10.685310340000058],
              [-74.83968357299995, 10.68505882900007 ],
              [-74.83955027199994, 10.684976111000083],
              [-74.83955008099991, 10.684976005000067],
              [-74.83937923299993, 10.68488159900005 ],
              [-74.83925987099991, 10.684664376000057],
              [-74.83925970899992, 10.684493860000089],
              [-74.83925952399994, 10.684298281000054],
              [-74.83925884099995, 10.683798841000055],
              [-74.83929277099992, 10.683635026000047],
              [-74.83929326399993, 10.683632645000046],
              [-74.83929317099995, 10.683595523000065],
              [-74.83929283299994, 10.68346088800007 ],
              [-74.8392402899999 , 10.683238421000055],
              [-74.83922261999993, 10.683026031000054],
              [-74.83921973899993, 10.682890826000062],
              [-74.83921614399992, 10.68272208600007 ],
              [-74.8390992009999 , 10.682545476000087],
              [-74.83905329799995, 10.682476153000039],
              [-74.8389723279999 , 10.682310244000064],
              [-74.83884749999993, 10.68218739200006 ],
              [-74.83865892299991, 10.682156211000063],
              [-74.83850828999994, 10.682225507000055],
              [-74.8383812049999 , 10.682345592000047],
              [-74.83823068299995, 10.682461226000044],
              [-74.83794655499992, 10.682593         ],
              [-74.83774395799992, 10.682670890000054],
              [-74.83757591499995, 10.682628931000067],
              [-74.83752140399992, 10.682479330000092],
              [-74.83747986199995, 10.682365318000052],
              [-74.83744160899994, 10.682147902000054],
              [-74.83742969999992, 10.681952445000093],
              [-74.83738875499995, 10.681797737000068],
              [-74.83725508599991, 10.681568685000059],
              [-74.83722024899993, 10.681339955000055],
              [-74.83732518699992, 10.680918863000045],
              [-74.83742157099994, 10.68053210100004 ],
              [-74.83746489299995, 10.680379450000089],
              [-74.83743562799992, 10.680213423000055],
              [-74.83727590899991, 10.680073692000065],
              [-74.83705839999993, 10.679899077000073],
              [-74.83696541499995, 10.67972190200004 ],
              [-74.83698427599995, 10.679670446000046],
              [-74.83707775799991, 10.679415404000054],
              [-74.83728614099994, 10.679144834000056],
              [-74.83748870199992, 10.679052819000049],
              [-74.83787697199995, 10.678989711000042],
              [-74.83817512199994, 10.678984708000087],
              [-74.8382710969999 , 10.67898309900005 ],
              [-74.8385785559999 , 10.679038841000079],
              [-74.83879265999991, 10.678998767000053],
              [-74.83894026199994, 10.678861116000064],
              [-74.83895472499995, 10.678705708000052],
              [-74.8389252309999 , 10.678563408000059],
              [-74.83880311599995, 10.678385173000038],
              [-74.83862354299993, 10.678299735000053],
              [-74.8383687349999 , 10.678283410000063],
              [-74.83814522599994, 10.67833254900006 ],
              [-74.83794849499992, 10.678353363000042],
              [-74.83778904499991, 10.67820686500005 ],
              [-74.8377390519999 , 10.677975343000071],
              [-74.8377705929999 , 10.677797296000051],
              [-74.8377820799999 , 10.677596704000052],
              [-74.8377120859999 , 10.677471444000048],
              [-74.83758412999993, 10.677242374000059],
              [-74.83751460699995, 10.67708209500006 ],
              [-74.83751416599995, 10.676905970000064],
              [-74.83751413199991, 10.67689226300007 ],
              [-74.83759514299993, 10.676623126000038],
              [-74.83771020799992, 10.67626804300005 ],
              [-74.83774743399994, 10.676177027000051],
              [-74.83778515499995, 10.676084801000059],
              [-74.8378316429999 , 10.675946829000054],
              [-74.8378661289999 , 10.67580324700009 ],
              [-74.83790623899995, 10.675625739000054],
              [-74.83776713499992, 10.675505176000058],
              [-74.83754071099992, 10.675417020000054],
              [-74.83730870599993, 10.675380872000062],
              [-74.8370943569999 , 10.675323769000045],
              [-74.83688864499993, 10.675292631000048],
              [-74.83667984099992, 10.67528410600005 ],
              [-74.83652611699995, 10.675141544000041],
              [-74.83649646799995, 10.67493538900004 ],
              [-74.8365156019999 , 10.674806214000057],
              [-74.83653129499993, 10.674700273000042],
              [-74.83662920299992, 10.674562187000049],
              [-74.83685985999995, 10.674292363000063],
              [-74.83688378399995, 10.674264376000053],
              [-74.83719619899995, 10.673793552000063],
              [-74.83748488099991, 10.673197347000041],
              [-74.83760021599994, 10.67295073200006 ],
              [-74.83764066099991, 10.672674924000091],
              [-74.83755875199995, 10.672359869000047],
              [-74.83744860099995, 10.672165784000072],
              [-74.83735564299991, 10.671995373000073],
              [-74.8370362199999 , 10.67172045500007 ],
              [-74.8364096009999 , 10.67126322100006 ],
              [-74.83617182499995, 10.671201662000044],
              [-74.83600951799991, 10.671167021000088],
              [-74.83581822199994, 10.67119008900005 ],
              [-74.83539822299991, 10.671354956000073],
              [-74.8351379689999 , 10.671650170000078],
              [-74.83512367999992, 10.671666378000054],
              [-74.8349846449999 , 10.67180456300008 ],
              [-74.83479228899995, 10.67188064000004 ],
              [-74.83475896399995, 10.671893819000047],
              [-74.83454433799994, 10.671954799000048],
              [-74.8342777869999 , 10.671923248000041],
              [-74.83399952799994, 10.67189002200007 ],
              [-74.8338470889999 , 10.67191515400009 ],
              [-74.8337734189999 , 10.67192729900006 ],
              [-74.8334627719999 , 10.672079115000088],
              [-74.83345506499995, 10.672082880000062],
              [-74.83324653999995, 10.672177742000088],
              [-74.83290805699994, 10.67239608500006 ],
              [-74.83258659499995, 10.672674835000066],
              [-74.83250021999993, 10.672858099000052],
              [-74.8323989619999 , 10.673022203000073],
              [-74.83217595399992, 10.673160022000047],
              [-74.8320367359999 , 10.67322082000004 ],
              [-74.83188344699994, 10.67325227100008 ],
              [-74.83159632399992, 10.673221889000047],
              [-74.83145736599994, 10.673153296000066],
              [-74.83127138999993, 10.673021549000055],
              [-74.83099839199991, 10.672581516000037],
              [-74.83076642799995, 10.67232897200006 ],
              [-74.83056893399993, 10.672157690000063],
              [-74.83033663899994, 10.672003453000059],
              [-74.82987858699994, 10.671793262000051],
              [-74.82954233099991, 10.67163927300004 ],
              [-74.82929864499994, 10.671496360000049],
              [-74.82906624999993, 10.671301437000068],
              [-74.82890365499992, 10.671152675000087],
              [-74.82864267699995, 10.670830143000046],
              [-74.82857692099992, 10.67071450700007 ],
              [-74.82852075499994, 10.67061573500007 ],
              [-74.82850775299994, 10.67059658100004 ],
              [-74.82841584899995, 10.670461188000047],
              [-74.82807902499991, 10.670077813000091],
              [-74.82781796599994, 10.66983775600005 ],
              [-74.8276846949999 , 10.669763510000053],
              [-74.82753663299991, 10.669714704000057],
              [-74.8273367509999 , 10.669729878000055],
              [-74.82716295099993, 10.669787369000062],
              [-74.82695405099992, 10.669735889000037],
              [-74.8263393869999 , 10.669485393000059],
              [-74.82627547899995, 10.66947749500008 ],
              [-74.82615997599993, 10.669463221000058],
              [-74.82596858099993, 10.669446737000044],
              [-74.82578616899991, 10.669483910000054],
              [-74.82556884799993, 10.66972228800006 ],
              [-74.82536643999993, 10.669871930000056],
              [-74.82516920599994, 10.669917607000059],
              [-74.82516272899994, 10.669917182000063],
              [-74.82496096799991, 10.669903980000072],
              [-74.82478075599994, 10.669793110000057],
              [-74.82469959399992, 10.669540193000046],
              [-74.8243958189999 , 10.668189483000049],
              [-74.8243610099999 , 10.667971481000052],
              [-74.82426920199993, 10.667799676000072],
              [-74.82416914799995, 10.667648220000046],
              [-74.82402998999993, 10.667619736000063],
              [-74.8238618069999 , 10.667637648000039],
              [-74.82356006999993, 10.667462666000063],
              [-74.82349444199991, 10.667391517000056],
              [-74.82344983099995, 10.667343153000047],
              [-74.82329043999994, 10.667337879000058],
              [-74.8232179389999 , 10.667469132000065],
              [-74.82318981899994, 10.667882769000073],
              [-74.82320756399992, 10.668129061000059],
              [-74.8233238599999 , 10.668383032000065],
              [-74.82339934499993, 10.668535387000077],
              [-74.82341754499993, 10.668735352000056],
              [-74.82315812699994, 10.669629779000047],
              [-74.82309449299993, 10.669767789000048],
              [-74.82286258199991, 10.669882476000055],
              [-74.82260182999994, 10.669883094000056],
              [-74.82241663299993, 10.669832691000067],
              [-74.8223914109999 , 10.669817506000072],
              [-74.82220726299994, 10.669706638000036],
              [-74.8214519309999 , 10.668983593000064],
              [-74.82139480899991, 10.668928913000059],
              [-74.82050695899994, 10.668145709000044],
              [-74.82007186899995, 10.667980642000089],
              [-74.81997823899991, 10.667948503000048],
              [-74.8198397619999 , 10.667900970000062],
              [-74.81960802399993, 10.66773541300006 ],
              [-74.81938178299993, 10.667483983000068],
              [-74.81932032899994, 10.667346269000063],
              [-74.81935766299995, 10.667197025000064],
              [-74.81949099099995, 10.667059978000054],
              [-74.82004904199994, 10.666355808000048],
              [-74.8201908559999 , 10.666187103000084],
              [-74.8202829729999 , 10.666015130000062],
              [-74.82030030099992, 10.665860277000093],
              [-74.82030604999994, 10.665642184000092],
              [-74.82026489799995, 10.665397078000069],
              [-74.82013694299991, 10.665162915000053],
              [-74.8199727999999 , 10.66505455500004 ],
              [-74.8199109599999 , 10.665013731000045],
              [-74.81972513599993, 10.66493960300005 ],
              [-74.81925932099995, 10.66491342800009 ],
              [-74.81919471799995, 10.664909799000043],
              [-74.81897170199994, 10.664928966000048],
              [-74.81881515099991, 10.664912394000055],
              [-74.81865339299992, 10.664866455000038],
              [-74.81850046599993, 10.664720749000082],
              [-74.81835853699994, 10.664575214000081],
              [-74.81832592899991, 10.664541778000057],
              [-74.81823508499991, 10.66445308200008 ],
              [-74.8174388079999 , 10.663675651000062],
              [-74.81734209099994, 10.663551589000065],
              [-74.8174107239999 , 10.663329267000051],
              [-74.81750271999994, 10.662875929000052],
              [-74.81759775499995, 10.662612417000048],
              [-74.81761521899995, 10.662397685000087],
              [-74.81757144399995, 10.662245240000061],
              [-74.81752926599995, 10.66208249400006 ],
              [-74.8175215089999 , 10.661874425000065],
              [-74.81752114499994, 10.66172374200005 ],
              [-74.81752064199992, 10.661515656000063],
              [-74.81748359099993, 10.661192847000052],
              [-74.81740346599992, 10.661056706000068],
              [-74.81737205199994, 10.661038680000047],
              [-74.81722912299995, 10.660956661000057],
              [-74.81712032699994, 10.66096409700009 ],
              [-74.8171410569999 , 10.661136919000057],
              [-74.81715229399992, 10.661230595000063],
              [-74.81716380799992, 10.661326583000061],
              [-74.81726025899991, 10.661704734000068],
              [-74.81720000899992, 10.661945565000053],
              [-74.81699998999994, 10.662020053000049],
              [-74.81681736999991, 10.661972456000058],
              [-74.81676651299995, 10.661943376000067],
              [-74.81648676399993, 10.661783410000055],
              [-74.81620192499992, 10.661388598000087],
              [-74.81569134699993, 10.660714098000085],
              [-74.8154004939999 , 10.660192735000066],
              [-74.81531275599991, 10.65981552900007 ],
              [-74.81535914499995, 10.659631243000092],
              [-74.81477059399992, 10.659417936000068],
              [-74.81455311399992, 10.659358567000083],
              [-74.81436742299991, 10.659336407000069],
              [-74.81422355199993, 10.659315722000088],
              [-74.81420514199993, 10.659313076000046],
              [-74.81403150699992, 10.659313479000048],
              [-74.81374166499995, 10.659446370000069],
              [-74.81334222999993, 10.659607212000083],
              [-74.8132209389999 , 10.659762871000055],
              [-74.81300080699992, 10.660020467000038],
              [-74.81276163299992, 10.660162601000081],
              [-74.81271732199991, 10.660188934000075],
              [-74.81252586799991, 10.660142491000045],
              [-74.81247285599994, 10.659990483000058],
              [-74.81245597799995, 10.659942087000047],
              [-74.81238634999994, 10.65972981300007 ],
              [-74.81235234499991, 10.659656773000052],
              [-74.81229333899995, 10.659530029000052],
              [-74.81216565199992, 10.659403778000069],
              [-74.81205338799992, 10.65929798400009 ],
              [-74.81203169499992, 10.659277541000051],
              [-74.81186218999994, 10.659177899000042],
              [-74.81180001999991, 10.659141354000042],
              [-74.81168375099992, 10.659003778000056],
              [-74.81159070199993, 10.658791570000062],
              [-74.81156702399994, 10.658448107000083],
              [-74.81151381199993, 10.657703585000093],
              [-74.8115190449999 , 10.657502432000058],
              [-74.81148435799992, 10.657330754000043],
              [-74.81138227199995, 10.657156429000054],
              [-74.81128436799992, 10.657129406000081],
              [-74.81116506899991, 10.65709647600005 ],
              [-74.81091552099991, 10.657119668000064],
              [-74.81070705799993, 10.65712581300005 ],
              [-74.81066419299992, 10.657124494000072],
              [-74.81036546399991, 10.657115311000041],
              [-74.81022028599995, 10.657076110000048],
              [-74.81004025199991, 10.657030206000059],
              [-74.80986022499991, 10.656991084000083],
              [-74.80967543699995, 10.657012459000043],
              [-74.8093617319999 , 10.657048746000044],
              [-74.80827269399992, 10.657372222000049],
              [-74.80808999899995, 10.657388659000048],
              [-74.80804683799994, 10.657392543000071],
              [-74.80782660999995, 10.65737893100004 ],
              [-74.8076407659999 , 10.657293492000065],
              [-74.80756220699993, 10.65726651600005 ],
              [-74.80749042399992, 10.657241867000039],
              [-74.80729322199994, 10.657184697000048],
              [-74.80707264199992, 10.657140015000039],
              [-74.80687551799991, 10.657111101000055],
              [-74.80673567999992, 10.657168984000066],
              [-74.8067272749999 , 10.657172462000062],
              [-74.80658605299993, 10.65716376100005 ],
              [-74.80638190799993, 10.657167253000068],
              [-74.80620337199991, 10.657170307000058],
              [-74.80581529599993, 10.657314719000055],
              [-74.80515237499992, 10.657524756000043],
              [-74.80513380999992, 10.657526445000087],
              [-74.80492938999993, 10.657545059000086],
              [-74.80478425799993, 10.65740555900004 ],
              [-74.80503829299994, 10.656988856000055],
              [-74.80515099199994, 10.656825875000038],
              [-74.8051249259999 , 10.656674518000045],
              [-74.80510575799991, 10.656653696000092],
              [-74.80500867099994, 10.65654823500006 ],
              [-74.80480582699994, 10.656513674000053],
              [-74.8047938599999 , 10.656514018000053],
              [-74.8036932359999 , 10.656545666000056],
              [-74.80353700099994, 10.656592656000043],
              [-74.80331081499992, 10.656660683000041],
              [-74.80315451299992, 10.656746923000071],
              [-74.80189803399992, 10.657515989000046],
              [-74.80177042399993, 10.657579272000078],
              [-74.80171261299995, 10.657607940000048],
              [-74.80155403299995, 10.657641372000057],
              [-74.8015039519999 , 10.657651930000043],
              [-74.8013239359999 , 10.657494716000087],
              [-74.80116743099995, 10.657254401000046],
              [-74.80081902299992, 10.656779497000059],
              [-74.80062178399993, 10.656705374000069],
              [-74.80043947699994, 10.656615173000091],
              [-74.80039561699994, 10.656593473000044],
              [-74.80025081999992, 10.656590983000058],
              [-74.79990375099993, 10.65692400100005 ],
              [-74.7996718469999 , 10.657159573000058],
              [-74.79954052199992, 10.657300277000047],
              [-74.79933597299993, 10.657395389000044],
              [-74.79916566199995, 10.657423506000043],
              [-74.7991624039999 , 10.657424045000084],
              [-74.79898877599993, 10.657431214000042],
              [-74.79880884499994, 10.657424864000063],
              [-74.7986323259999 , 10.65753432300005 ],
              [-74.79849655299995, 10.65772955500006 ],
              [-74.79830548299992, 10.658084809000059],
              [-74.79828462499995, 10.658109909000075],
              [-74.79813802299992, 10.658286330000067],
              [-74.79791787699992, 10.658424697000044],
              [-74.79757594199992, 10.658511365000038],
              [-74.79741422899991, 10.658572184000093],
              [-74.79724037699992, 10.658637570000053],
              [-74.79703709699993, 10.658660650000058],
              [-74.79685204999993, 10.658672373000059],
              [-74.79661440399991, 10.65865597900006 ],
              [-74.79624986599993, 10.658595282000078],
              [-74.79600527299993, 10.658554557000059],
              [-74.79577821599992, 10.658604807000074],
              [-74.79567834199992, 10.658824241000048],
              [-74.79580064699991, 10.65909911700004 ],
              [-74.7959055529999 , 10.65937628200004 ],
              [-74.79586222599994, 10.659552098000063],
              [-74.7958594399999 , 10.65956340300005 ],
              [-74.7958332799999 , 10.659734636000053],
              [-74.7956888569999 , 10.659775664000051],
              [-74.79551794799994, 10.659777191000046],
              [-74.79543948799994, 10.65963273500006 ],
              [-74.79531704699991, 10.65930023900006 ],
              [-74.79524527299992, 10.65920236900007 ],
              [-74.79517764799994, 10.659110157000043],
              [-74.79494575699994, 10.659055324000065],
              [-74.7944357909999 , 10.659096053000042],
              [-74.7941577869999 , 10.659171270000058],
              [-74.79401281999992, 10.659216797000056],
              [-74.79382756699994, 10.659257912000044],
              [-74.7938004319999 , 10.659259930000076],
              [-74.7936707959999 , 10.659269570000049],
              [-74.79342727099993, 10.65918425700005 ],
              [-74.79326485999991, 10.659104407000086],
              [-74.79316925599994, 10.659061620000045],
              [-74.79311162999994, 10.65903582900006 ],
              [-74.79296926199993, 10.658978526000055],
              [-74.79263333999995, 10.658951055000045],
              [-74.79255872199991, 10.658958736000045],
              [-74.79246487899991, 10.658968395000045],
              [-74.7922969839999 , 10.65898007900006 ],
              [-74.79208851999994, 10.65887150900005 ],
              [-74.79212271999995, 10.658717196000055],
              [-74.79228485699991, 10.658562024000048],
              [-74.79235700899994, 10.65839462200006 ],
              [-74.79230166199994, 10.658178343000088],
              [-74.79214510199995, 10.65803463900005 ],
              [-74.79195954199992, 10.657868853000082],
              [-74.7919533509999 , 10.657863323000072],
              [-74.79179085299995, 10.657743917000062],
              [-74.79162277099994, 10.65768102700008 ],
              [-74.79156863899993, 10.657673868000074],
              [-74.79145479599993, 10.657658810000044],
              [-74.79126344499991, 10.657659249000062],
              [-74.79105214099991, 10.657673866000039],
              [-74.79079386899991, 10.657630962000042],
              [-74.79061439499992, 10.657586165000055],
              [-74.79038777699992, 10.657396855000059],
              [-74.79027212699992, 10.657282985000052],
              [-74.79013165999993, 10.65712568300006 ],
              [-74.7901034599999 , 10.657094104000066],
              [-74.7899006909999 , 10.656968581000058],
              [-74.78974616199991, 10.65688971600008 ],
              [-74.78955242199993, 10.656790840000042],
              [-74.78931445599994, 10.656642214000044],
              [-74.78918116699992, 10.656556651000074],
              [-74.78897228299991, 10.65638537600006 ],
              [-74.78888517399992, 10.656260144000044],
              [-74.78883281599991, 10.656099811000047],
              [-74.78876895899992, 10.655904475000057],
              [-74.78866399099991, 10.65571600900006 ],
              [-74.78863220099993, 10.655689948000088],
              [-74.78853065399994, 10.655606702000057],
              [-74.7882295359999 , 10.655562197000052],
              [-74.78763224799991, 10.655632491000063],
              [-74.78728508599994, 10.65568978400006 ],
              [-74.78693991499995, 10.655731252000066],
              [-74.78691884999995, 10.655729445000077],
              [-74.78667053899994, 10.655708139000069],
              [-74.78641548899992, 10.655577641000093],
              [-74.78626453699991, 10.655385895000052],
              [-74.78623785699995, 10.655090465000058],
              [-74.78623455999991, 10.654838207000068],
              [-74.7861649219999 , 10.65467761800005 ],
              [-74.78596209099993, 10.654649834000054],
              [-74.78565483699992, 10.654667485000061],
              [-74.78547514199994, 10.654765069000064],
              [-74.7853245629999 , 10.654856941000048],
              [-74.78516828599993, 10.654955610000059],
              [-74.7850151589999 , 10.655053141000053],
              [-74.78494547799994, 10.655097052000087],
              [-74.78484690499994, 10.655159169000058],
              [-74.78456618199993, 10.655168285000059],
              [-74.78427913099995, 10.655085342000064],
              [-74.78401518999993, 10.655009077000045],
              [-74.78330763299994, 10.654573385000049],
              [-74.78319136399995, 10.654436923000048],
              [-74.78308374499994, 10.654210621000061],
              [-74.78307205599992, 10.653978424000059],
              [-74.78303068999992, 10.653871546000062],
              [-74.78297581299995, 10.65372976000009 ],
              [-74.78279332099993, 10.653729894000037],
              [-74.78277416399993, 10.653730752000058],
              [-74.78259371099995, 10.653738832000045],
              [-74.7823790889999 , 10.653676032000078],
              [-74.7821441079999 , 10.653513572000065],
              [-74.78197600199991, 10.65337863700006 ],
              [-74.78178330899993, 10.653346591000059],
              [-74.78172788299992, 10.653363136000053],
              [-74.78158209399993, 10.653406653000047],
              [-74.78150112799995, 10.653531134000048],
              [-74.78142707299992, 10.653798256000073],
              [-74.78146940899995, 10.654015963000063],
              [-74.78151047199992, 10.654233957000088],
              [-74.78149063799992, 10.654423270000052],
              [-74.78144166099992, 10.654612649000057],
              [-74.7813719159999 , 10.654829765000045],
              [-74.7813493239999 , 10.655062601000054],
              [-74.78140536399991, 10.655154993000053],
              [-74.78144822799993, 10.655225661000088],
              [-74.78155545999994, 10.655402817000038],
              [-74.7815588709999 , 10.655646324000088],
              [-74.78148386399994, 10.655814014000043],
              [-74.78124768799995, 10.655836038000075],
              [-74.7812200269999 , 10.655838619000065],
              [-74.78119417499994, 10.656016088000058],
              [-74.78117898499994, 10.656222605000039],
              [-74.7811785259999 , 10.656228838000061],
              [-74.78117763099993, 10.65623022300008 ],
              [-74.78106710799995, 10.656401125000059],
              [-74.78084136799993, 10.656595997000068],
              [-74.78058982499994, 10.65674572800009 ],
              [-74.78037263399995, 10.656699572000036],
              [-74.78034910299993, 10.65669457100006 ],
              [-74.78025061799991, 10.65653405900008 ],
              [-74.78016316999992, 10.65625966600004 ],
              [-74.78016283699992, 10.656116161000057],
              [-74.7801887359999 , 10.655952821000085],
              [-74.78024332699994, 10.655600708000065],
              [-74.7802662869999 , 10.655405172000087],
              [-74.78027164199995, 10.65525092100006 ],
              [-74.78019611099995, 10.655067461000044],
              [-74.78009308299994, 10.655056156000057],
              [-74.78009244099991, 10.655056086000059],
              [-74.77996415699994, 10.655042010000045],
              [-74.77987497799995, 10.654781655000079],
              [-74.77986524399995, 10.654753238000069],
              [-74.77975788899994, 10.654711260000056],
              [-74.77961597899991, 10.654655769000044],
              [-74.77952895999994, 10.654444099000045],
              [-74.77945864799995, 10.654175313000053],
              [-74.77922655099991, 10.653840242000058],
              [-74.77911592299995, 10.653670993000048],
              [-74.77898365499993, 10.653533432000074],
              [-74.7788469859999 , 10.653399835000073],
              [-74.77870586899991, 10.653138281000054],
              [-74.77864421599992, 10.652968457000043],
              [-74.77862844299995, 10.652817836000054],
              [-74.77866434799995, 10.65265560000006 ],
              [-74.77870302899993, 10.652465114000051],
              [-74.77876201399994, 10.652222883000093],
              [-74.77881411599992, 10.652009098000065],
              [-74.77880992099995, 10.65183735000005 ],
              [-74.77874781199995, 10.651632784000071],
              [-74.7785823239999 , 10.651514496000061],
              [-74.77843173899993, 10.651482643000065],
              [-74.7783409839999 , 10.651515456000084],
              [-74.7782609059999 , 10.651544409000053],
              [-74.77804520299992, 10.651669680000055],
              [-74.77772142299995, 10.651892444000055],
              [-74.77747965099991, 10.65200570400009 ],
              [-74.77727107099992, 10.652086409000049],
              [-74.77708870399994, 10.652138800000046],
              [-74.77676701699994, 10.652219187000071],
              [-74.77667850599994, 10.652235440000084],
              [-74.77648355399992, 10.652271236000047],
              [-74.77624602999992, 10.652311876000056],
              [-74.77601990699992, 10.652335558000061],
              [-74.77587223199993, 10.652324023000062],
              [-74.7756550439999 , 10.652266882000049],
              [-74.77544042999995, 10.652210294000042],
              [-74.77528659399991, 10.652127032000067],
              [-74.77509507299993, 10.651984524000056],
              [-74.7749266209999 , 10.65169278500008 ],
              [-74.77480867199995, 10.651439381000046],
              [-74.77467483999993, 10.651182893000055],
              [-74.77451444299993, 10.65100103800006 ],
              [-74.77434814599991, 10.650876548000042],
              [-74.7740842259999 , 10.65073985400005 ],
              [-74.77386951699992, 10.650634120000063],
              [-74.77375607899995, 10.650591235000093],
              [-74.77370458899992, 10.650571770000056],
              [-74.77350467699995, 10.650572210000064],
              [-74.77336253699991, 10.650606433000064],
              [-74.77324705899991, 10.65068974400009 ],
              [-74.7731427729999 , 10.650792808000062],
              [-74.77312493599993, 10.650809199000037],
              [-74.77299115499994, 10.65093213700004 ],
              [-74.7727667819999 , 10.651103255000066],
              [-74.77259904999994, 10.651309293000054],
              [-74.77248342399992, 10.651453059000062],
              [-74.7723883139999 , 10.65157079700009 ],
              [-74.77225816499993, 10.651728719000062],
              [-74.77215265899991, 10.651865118000046],
              [-74.77201531599991, 10.652002443000072],
              [-74.77185986899991, 10.652028117000043],
              [-74.77177779999994, 10.652041673000042],
              [-74.77166327599991, 10.65195641400004 ],
              [-74.7715455039999 , 10.651868739000065],
              [-74.77149003999995, 10.651658685000086],
              [-74.7715189239999 , 10.65148657800006 ],
              [-74.77156200699994, 10.651279982000062],
              [-74.77160214499992, 10.651054461000058],
              [-74.77159012499993, 10.650881777000052],
              [-74.77156161799991, 10.650870157000043],
              [-74.77136982999991, 10.650791982000044],
              [-74.77112626199994, 10.650743929000043],
              [-74.77104551999992, 10.650712772000077],
              [-74.77093774799994, 10.650671187000057],
              [-74.77084408799993, 10.650616521000074],
              [-74.77078408499995, 10.650581498000065],
              [-74.77060426899993, 10.650462318000052],
              [-74.77041573799994, 10.650440139000068],
              [-74.7702914219999 , 10.650525730000084],
              [-74.77019313699992, 10.650634983000089],
              [-74.77013106699991, 10.650717365000048],
              [-74.77009804199992, 10.650761197000065],
              [-74.76994174399994, 10.650976523000054],
              [-74.7697740189999 , 10.65106531400005 ],
              [-74.76955105699994, 10.651104524000061],
              [-74.76931056899991, 10.651150813000072],
              [-74.76929375299994, 10.651156530000037],
              [-74.76911066699995, 10.651218781000068],
              [-74.76898789099994, 10.651259654000057],
              [-74.7689039089999 , 10.651287611000043],
              [-74.7686937229999 , 10.651353609000068],
              [-74.7685489399999 , 10.651362404000054],
              [-74.76838672299994, 10.651359943000045],
              [-74.7681810279999 , 10.651325930000041],
              [-74.76798391499995, 10.651243314000055],
              [-74.76772185599992, 10.651112861000058],
              [-74.76771387699995, 10.651108889000056],
              [-74.76752694299995, 10.650973147000059],
              [-74.76735457799992, 10.650843007000049],
              [-74.76720502199993, 10.650822155000071],
              [-74.7670414609999 , 10.65079793600006 ],
              [-74.76685576999995, 10.650769530000048],
              [-74.76675286099993, 10.650757522000049],
              [-74.76662538799991, 10.650742645000037],
              [-74.76641804699995, 10.650681802000065],
              [-74.76621206399994, 10.65052293900004 ],
              [-74.76610201199992, 10.650353108000047],
              [-74.76609841499993, 10.650345021000078],
              [-74.76603800499993, 10.650209180000047],
              [-74.7659737319999 , 10.65008107400007 ],
              [-74.76598508699993, 10.649920597000062],
              [-74.76604270999991, 10.649765646000048],
              [-74.7660711449999 , 10.649584798000092],
              [-74.76602664199993, 10.649499538000043],
              [-74.76596215299992, 10.64937599500007 ],
              [-74.76571132499993, 10.649216095000043],
              [-74.76557522199994, 10.649138984000047],
              [-74.76543853399994, 10.649061888000062],
              [-74.76527493299994, 10.64901648700004 ],
              [-74.76521984399994, 10.64901721700005 ],
              [-74.76507060199992, 10.649019193000072],
              [-74.76499311499992, 10.649118802000089],
              [-74.7649275089999 , 10.649203140000054],
              [-74.76489191799993, 10.64944051200007 ],
              [-74.76491809499993, 10.649650052000084],
              [-74.76492129699994, 10.649810511000055],
              [-74.76493071599992, 10.64999073100006 ],
              [-74.76477228999994, 10.650182888000074],
              [-74.76453979699994, 10.650200638000058],
              [-74.76439630899995, 10.650148977000072],
              [-74.76424995399992, 10.650089407000053],
              [-74.76421370699995, 10.650080865000064],
              [-74.76410508399994, 10.65005526400006 ],
              [-74.76407423699993, 10.650060740000072],
              [-74.76396005999993, 10.650081010000065],
              [-74.76382398299995, 10.650147422000089],
              [-74.76368259599991, 10.650329657000043],
              [-74.76355701099993, 10.650550280000061],
              [-74.76347773499992, 10.650729550000051],
              [-74.76331571599991, 10.65094431600005 ],
              [-74.7631206559999 , 10.651129792000063],
              [-74.76288288899991, 10.651380604000053],
              [-74.76273782099992, 10.65138375500004 ],
              [-74.76255948699992, 10.651389236000057],
              [-74.76234646099994, 10.651466536000044],
              [-74.76218873499994, 10.651554184000076],
              [-74.7620249979999 , 10.65163900400006 ],
              [-74.76184549599992, 10.651700707000089],
              [-74.76166599499993, 10.65175957300005 ],
              [-74.7614917919999 , 10.65176561000004 ],
              [-74.76130643599993, 10.651759809000055],
              [-74.76108332199993, 10.651731476000066],
              [-74.76088341099995, 10.651731917000063],
              [-74.7607269149999 , 10.651732257000049],
              [-74.76047760499995, 10.651734503000057],
              [-74.76020487699992, 10.651743576000058],
              [-74.75999640499992, 10.651746866000053],
              [-74.75986473099994, 10.651757560000078],
              [-74.75978196799991, 10.651764282000045],
              [-74.75959386999995, 10.651812720000066],
              [-74.75943157199993, 10.651959413000043],
              [-74.75938968299994, 10.652054547000091],
              [-74.75933196599993, 10.652185627000051],
              [-74.75926092799995, 10.652346803000057],
              [-74.75906974799994, 10.652547235000043],
              [-74.75881197699994, 10.652598657000055],
              [-74.75867032699995, 10.652598953000052],
              [-74.75849608399994, 10.652588037000044],
              [-74.75825558299994, 10.652570493000042],
              [-74.75803266999992, 10.652502039000069],
              [-74.75775114699991, 10.652407745000062],
              [-74.75757573299995, 10.652250494000043],
              [-74.75744922299992, 10.652127607000068],
              [-74.75731584999994, 10.65199625400004 ],
              [-74.75716782599994, 10.651832727000055],
              [-74.75703449799994, 10.651718333000076],
              [-74.75688365299993, 10.651569499000061],
              [-74.75669358699992, 10.65150212900005 ],
              [-74.7565359749999 , 10.651649926000061],
              [-74.7564261309999 , 10.651818541000068],
              [-74.7564066839999 , 10.65197865500005 ],
              [-74.75639174899993, 10.652025732000084],
              [-74.75633643499992, 10.652200101000062],
              [-74.75620914399991, 10.652363655000045],
              [-74.75602392899992, 10.652484119000064],
              [-74.75581845799991, 10.652680338000039],
              [-74.75563898099995, 10.652820288000044],
              [-74.75549674099994, 10.652814385000056],
              [-74.75535052499993, 10.652751421000062],
              [-74.75512416799995, 10.652672821000067],
              [-74.75501031699991, 10.652657313000077],
              [-74.75494860999993, 10.65264890800006 ],
              [-74.7547172219999 , 10.652620034000051],
              [-74.75452008699995, 10.65258543300007 ],
              [-74.75429099399992, 10.652563333000046],
              [-74.7540937519999 , 10.652544836000061],
              [-74.7539312319999 , 10.652407332000053],
              [-74.75390345299991, 10.652183938000064],
              [-74.75404240799992, 10.652056232000064],
              [-74.75426118299993, 10.652000386000054],
              [-74.75449159199991, 10.651905523000039],
              [-74.75471606299993, 10.651776219000055],
              [-74.75486648599991, 10.651680685000088],
              [-74.75498656899993, 10.651545678000048],
              [-74.75504997599995, 10.651289036000037],
              [-74.7548891479999 , 10.651086836000047],
              [-74.75475585899994, 10.650992773000041],
              [-74.7545496859999 , 10.650869488000069],
              [-74.75436111699992, 10.650712264000049],
              [-74.75420421799993, 10.650528983000072],
              [-74.75405636499994, 10.650442870000063],
              [-74.75385070699991, 10.650361670000052],
              [-74.7536186179999 , 10.650339859000042],
              [-74.75341559899994, 10.650357252000049],
              [-74.75337375599992, 10.650361265000072],
              [-74.75319860199994, 10.650378064000051],
              [-74.75287413099994, 10.65048441500005 ],
              [-74.75276522899992, 10.650524034000057],
              [-74.75265382799995, 10.650564563000046],
              [-74.75244828099994, 10.650601736000056],
              [-74.75214545299991, 10.65068404100009 ],
              [-74.7519210719999 , 10.650725480000062],
              [-74.7517817669999 , 10.650755158000038],
              [-74.75169493199991, 10.650757231000057],
              [-74.75157330699994, 10.650760134000052],
              [-74.75152445299994, 10.65076376400009 ],
              [-74.7514165469999 , 10.650771778000092],
              [-74.75118466899994, 10.650716640000041],
              [-74.7509932019999 , 10.650600935000057],
              [-74.75085423299993, 10.65052101300006 ],
              [-74.75071466499992, 10.65043429700006 ],
              [-74.7505785489999 , 10.650348715000064],
              [-74.75032325699993, 10.65023457600006 ],
              [-74.75014943299993, 10.650151905000087],
              [-74.74983934099993, 10.650046351000071],
              [-74.74962769699994, 10.650037209000061],
              [-74.74943036699995, 10.65004046100006 ],
              [-74.7492507579999 , 10.650054978000071],
              [-74.74897246499995, 10.650124505000065],
              [-74.74882492099994, 10.65017285500005 ],
              [-74.74866291799992, 10.650264733000085],
              [-74.74851775099995, 10.650356568000063],
              [-74.74846597099992, 10.650387038000076],
              [-74.74839053999995, 10.650431426000068],
              [-74.74825136399994, 10.650517601000047],
              [-74.74810103999994, 10.650600415000042],
              [-74.74796574499993, 10.650678309000057],
              [-74.74780948699993, 10.65076373100004 ],
              [-74.7478083869999 , 10.650764332000051],
              [-74.74768374599995, 10.650835793000056],
              [-74.74752866999995, 10.65090364200006 ],
              [-74.74733458899993, 10.650957739000091],
              [-74.74721113099991, 10.650970487000052],
              [-74.74641704999993, 10.651145416000077],
              [-74.74643843199993, 10.65110455100006 ],
              [-74.74653031799994, 10.650928838000084],
              [-74.74665749499991, 10.650685636000048],
              [-74.74694462899993, 10.649884912000061],
              [-74.74705867999995, 10.649198856000055],
              [-74.7470572499999 , 10.648541625000064],
              [-74.7470558139999 , 10.647884395000062],
              [-74.74705381899992, 10.646969986000045],
              [-74.74702336799993, 10.64625566300009 ],
              [-74.74679058299995, 10.645484624000062],
              [-74.74658712599995, 10.644913552000048],
              [-74.74652691199992, 10.644765569000072],
              [-74.74649373799991, 10.644684040000072],
              [-74.7464128659999 , 10.644485288000055],
              [-74.74609373299995, 10.643857309000055],
              [-74.74565911199994, 10.643258151000055],
              [-74.74534016899992, 10.642715898000063],
              [-74.74490567599992, 10.642173888000059],
              [-74.74449994999992, 10.641574667000043],
              [-74.74412335799991, 10.641089686000043],
              [-74.74363078699992, 10.640404920000037],
              [-74.74313827799995, 10.639748731000054],
              [-74.74279064099994, 10.639292261000037],
              [-74.74232714599992, 10.63869316000006 ],
              [-74.74192161499991, 10.638179659000059],
              [-74.74154478699995, 10.637580372000059],
              [-74.7410521029999 , 10.636838453000053],
              [-74.74064595599992, 10.636039198000049],
              [-74.74038455699991, 10.63538251600005 ],
              [-74.74015284399991, 10.63509724700009 ],
              [-74.73980484999993, 10.63446932000005 ],
              [-74.73945734799992, 10.63406999700004 ],
              [-74.73899417599995, 10.633613766000053],
              [-74.73838681699993, 10.633272138000052],
              [-74.73780853499994, 10.633016178000048],
              [-74.7371439609999 , 10.632931849000045],
              [-74.73659511599993, 10.632933         ],
              [-74.73613311499992, 10.633019699000045],
              [-74.73558432999994, 10.633049423000045],
              [-74.73515103199992, 10.63305033100005 ],
              [-74.73454428999992, 10.632994451000059],
              [-74.73396619199991, 10.632824207000056],
              [-74.73359017699994, 10.63259639000006 ],
              [-74.73324293199994, 10.632311358000038],
              [-74.7328666109999 , 10.631940665000059],
              [-74.73237475199994, 10.631570207000038],
              [-74.73198399799992, 10.63126016700005 ],
              [-74.73144155799991, 10.630602474000057],
              [-74.73083004299991, 10.630026274000045],
              [-74.7305236659999 , 10.62976894400009 ],
              [-74.73021942199995, 10.629513406000058],
              [-74.72964274999993, 10.62872092400005 ],
              [-74.72919279399991, 10.627853797000057],
              [-74.7285682349999 , 10.627012361000084],
              [-74.7280620329999 , 10.62604220500009 ],
              [-74.72776921499991, 10.62559658300006 ],
              [-74.72742310699994, 10.625046071000043],
              [-74.72710336999995, 10.624416763000056],
              [-74.7266507459999 , 10.623682729000052],
              [-74.72635721199993, 10.62289587400005 ],
              [-74.72611685999993, 10.622161401000085],
              [-74.7255839739999 , 10.621112547000052],
              [-74.72556729099995, 10.62107971000006 ],
              [-74.72521095699994, 10.620378351000056],
              [-74.72503565599993, 10.619733345000043],
              [-74.7249371499999 , 10.617755488000057],
              [-74.72450839699991, 10.615749795000056],
              [-74.7244114099999 , 10.61449004900004 ],
              [-74.72422037899992, 10.613370492000058],
              [-74.72421773199994, 10.612110552000047],
              [-74.72421321899992, 10.60996398900005 ],
              [-74.72421057499992, 10.60870404800005 ],
              [-74.72439523199995, 10.606790419000049],
              [-74.72462793599993, 10.605296674000044],
              [-74.72500175099992, 10.60361597700006 ],
              [-74.72614811599993, 10.60123449100007 ],
              [-74.72656503099995, 10.601191164000056],
              [-74.72707192799993, 10.601138485000092],
              [-74.72787239799993, 10.600483526000062],
              [-74.72848449199995, 10.599968950000061],
              [-74.72942616199992, 10.59917370700009 ],
              [-74.72964236899992, 10.598905177000063],
              [-74.72999068699994, 10.598472566000055],
              [-74.7305548139999 , 10.597584768000047],
              [-74.73144821599993, 10.59627630500006 ],
              [-74.73234160999993, 10.594967840000038],
              [-74.7330934979999 , 10.593659668000043],
              [-74.73375114399994, 10.592398355000057],
              [-74.73403244999992, 10.59160447000005 ],
              [-74.73431316499995, 10.59053059900009 ],
              [-74.73454630999993, 10.589270169000088],
              [-74.73481915399992, 10.587648063000074],
              [-74.73578248799993, 10.584348445000046],
              [-74.73598756399991, 10.584061021000082],
              [-74.73636032499991, 10.583538574000045],
              [-74.73726914799994, 10.583291412000051],
              [-74.73783422699995, 10.582870249000052],
              [-74.73820984799994, 10.582076165000046],
              [-74.73839630899994, 10.581049151000059],
              [-74.7384412749999 , 10.58002244000005 ],
              [-74.73830197199993, 10.578791503000048],
              [-74.73806453899994, 10.575923450000062],
              [-74.7380123559999 , 10.57529298500009 ],
              [-74.73796274999995, 10.57469375100004 ],
              [-74.73752882199994, 10.569452008000042],
              [-74.73744018999992, 10.568381349000049],
              [-74.73653787299992, 10.563255282000057],
              [-74.73625010599994, 10.561940070000048],
              [-74.73594981199994, 10.560315512000045],
              [-74.7357835009999 , 10.55655639400004 ],
              [-74.73577816999995, 10.554050098000062],
              [-74.73587522299994, 10.55183459500006 ],
              [-74.73592914799991, 10.550603607000085],
              [-74.7362417619999 , 10.54872322500006 ],
              [-74.73765782799995, 10.544647517000044],
              [-74.73891923999992, 10.54229521700006 ],
              [-74.74113790599995, 10.540531241000053],
              [-74.74128974299992, 10.540410522000059],
              [-74.74445104199992, 10.53821085900006 ],
              [-74.74872072199992, 10.536165461000053],
              [-74.75251647899995, 10.534590952000087],
              [-74.7572619739999 , 10.533014363000063],
              [-74.76060417699995, 10.53129823900008 ],
              [-74.76216363599991, 10.53049750300005 ],
              [-74.7626983799999 , 10.530240028000037],
              [-74.76509711099993, 10.529085034000047],
              [-74.76518557399993, 10.52904243900008 ],
              [-74.7662014199999 , 10.528553306000049],
              [-74.7670655629999 , 10.52813721800004 ],
              [-74.77085656699995, 10.524526144000049],
              [-74.77496546799995, 10.521540904000062],
              [-74.77753488199994, 10.519437412000059],
              [-74.7776514549999 , 10.519341978000057],
              [-74.78065327299993, 10.516829041000051],
              [-74.7823908819999 , 10.51525875100009 ],
              [-74.7850746229999 , 10.512119913000049],
              [-74.78681111199995, 10.510079673000064],
              [-74.78870406899995, 10.50725586100009 ],
              [-74.7910714759999 , 10.504274330000044],
              [-74.7929654369999 , 10.501920423000058],
              [-74.79443526299991, 10.499927339000067],
              [-74.79470110099993, 10.499566862000052],
              [-74.79483772099991, 10.499378751000052],
              [-74.79527723399991, 10.49965831600008 ],
              [-74.79648978799992, 10.49838690300004 ],
              [-74.79723791099991, 10.497602461000042],
              [-74.79830452499993, 10.496291051000071],
              [-74.79839908999992, 10.496174784000061],
              [-74.7988341969999 , 10.495332942000061],
              [-74.79904533699994, 10.49492443400004 ],
              [-74.79987382899992, 10.493321483000045],
              [-74.80015037099992, 10.49278643100007 ],
              [-74.80030356299994, 10.492523649000077],
              [-74.80067640999994, 10.491884066000068],
              [-74.8012075449999 , 10.490972955000075],
              [-74.8015053119999 , 10.49046216700009 ],
              [-74.80234895899991, 10.489014975000089],
              [-74.8025916979999 , 10.488598580000087],
              [-74.80198048999995, 10.488176771000042],
              [-74.80242864999991, 10.487487821000059],
              [-74.80265680499991, 10.487204225000085],
              [-74.80432243199994, 10.48513385700005 ],
              [-74.80653309099995, 10.482935797000039],
              [-74.8082069699999 , 10.484792953000067],
              [-74.80921048499994, 10.486242382000057],
              [-74.81068235799995, 10.488383604000092],
              [-74.81238824099995, 10.490788227000053],
              [-74.81355785999995, 10.491973303000066],
              [-74.81459351199993, 10.49289473600004 ],
              [-74.8153285809999 , 10.493585904000042],
              [-74.81586268799992, 10.493881612000052],
              [-74.81723668399991, 10.494102952000048],
              [-74.81912300499994, 10.494098429000076],
              [-74.82020523299991, 10.494095834000063],
              [-74.82028377699993, 10.494095645000073],
              [-74.82043362699994, 10.49409528600006 ],
              [-74.8201356319999 , 10.494638173000055],
              [-74.82001768299995, 10.494808824000074],
              [-74.81999741999994, 10.494837042000086],
              [-74.8198943189999 , 10.494980613000052],
              [-74.81982006899995, 10.49508400700006 ],
              [-74.81977806399993, 10.495142499000053],
              [-74.8196622609999 , 10.49510928300009 ],
              [-74.81965191099994, 10.495106314000054],
              [-74.81960779099995, 10.495194815000048],
              [-74.8196064739999 , 10.495197457000074],
              [-74.81951667599992, 10.49537758300005 ],
              [-74.81936673799993, 10.495678344000055],
              [-74.81932163399995, 10.49578538500009 ],
              [-74.81930112899994, 10.495834049000052],
              [-74.81926102599994, 10.49592922100004 ],
              [-74.81921009899992, 10.496050077000064],
              [-74.8192597019999 , 10.496072014000049],
              [-74.81926708899994, 10.49607528100006 ],
              [-74.81926977699993, 10.496076386000084],
              [-74.81929509899993, 10.496086801000047],
              [-74.81937213399993, 10.496118485000068],
              [-74.82010807299991, 10.496421166000061],
              [-74.82045200499994, 10.496562621000066],
              [-74.82019109899994, 10.497367484000051],
              [-74.82018353899991, 10.49736426000004 ],
              [-74.81928973799995, 10.496983041000078],
              [-74.81921042699992, 10.49694921400004 ],
              [-74.81904328999991, 10.49687792800006 ],
              [-74.81902683399994, 10.496870909000052],
              [-74.81900328699993, 10.496837929000037],
              [-74.81899748399991, 10.496829801000047],
              [-74.81897261299991, 10.496794966000039],
              [-74.8189653359999 , 10.496792608000078],
              [-74.81896485499993, 10.49679245200008 ],
              [-74.81890563699994, 10.496773264000069],
              [-74.81890548199993, 10.496773636000057],
              [-74.81890012899993, 10.496786460000067],
              [-74.81887309899992, 10.496851217000085],
              [-74.81885485899994, 10.496894917000077],
              [-74.8188496329999 , 10.496907437000061],
              [-74.81882898299995, 10.49695691100004 ],
              [-74.81880048399995, 10.497025191000091],
              [-74.81863120699995, 10.49743075200007 ],
              [-74.81857818599991, 10.497540582000056],
              [-74.81834375299991, 10.498026191000065],
              [-74.81834092699995, 10.498032046000048],
              [-74.81824428099992, 10.49823224000005 ],
              [-74.81780457299993, 10.49925176000005 ],
              [-74.8177999689999 , 10.499264984000092],
              [-74.81776695099995, 10.499359814000059],
              [-74.81776559999992, 10.499363695000056],
              [-74.81774500699993, 10.499422844000037],
              [-74.8176806919999 , 10.49964239600007 ],
              [-74.8176163899999 , 10.499831915000073],
              [-74.81761408699992, 10.499838701000044],
              [-74.81759027099991, 10.49990889500009 ],
              [-74.81755695999993, 10.500070698000059],
              [-74.81755706899992, 10.500073998000062],
              [-74.81755933799991, 10.500142082000082],
              [-74.8175807529999 , 10.500315783000076],
              [-74.81761819099995, 10.500449142000093],
              [-74.8176826799999 , 10.50067886000005 ],
              [-74.81770355899994, 10.50075036000004 ],
              [-74.81784022499994, 10.50104540500007 ],
              [-74.8180808759999 , 10.50129663100006 ],
              [-74.81819364799992, 10.501406750000058],
              [-74.8183740739999 , 10.50158293000004 ],
              [-74.81851167099995, 10.501717289000055],
              [-74.81890618599994, 10.502106772000047],
              [-74.81974556599994, 10.50288720900005 ],
              [-74.81982793799995, 10.502963795000085],
              [-74.82044163199993, 10.503519459000074],
              [-74.82071635299991, 10.503768200000081],
              [-74.8213784479999 , 10.504389760000038],
              [-74.82144629099992, 10.504453449000039],
              [-74.82146154599991, 10.504467770000076],
              [-74.8223898299999 , 10.505339222000089],
              [-74.82245173899992, 10.505401427000038],
              [-74.8224606309999 , 10.505410361000088],
              [-74.82274859899991, 10.505699706000087],
              [-74.82282549399991, 10.505763813000044],
              [-74.82284924699991, 10.505783615000041],
              [-74.82289443899992, 10.505821292000064],
              [-74.82296644099995, 10.505881320000071],
              [-74.82297298199995, 10.505886773000043],
              [-74.8229944119999 , 10.505834932000084],
              [-74.82299608699992, 10.505830881000065],
              [-74.82299767399991, 10.505827043000068],
              [-74.82300413799993, 10.505806292000045],
              [-74.82301215199993, 10.50578056200004 ],
              [-74.8231805719999 , 10.505239902000085],
              [-74.8232645569999 , 10.505280622000043],
              [-74.82329043699991, 10.505293170000073],
              [-74.82329080499994, 10.505293349000056],
              [-74.82340056899994, 10.50534656700006 ],
              [-74.82342352799992, 10.505359190000092],
              [-74.82345093499993, 10.505374259000064],
              [-74.82345666799995, 10.50537741100004 ],
              [-74.82345822999991, 10.505378270000051],
              [-74.82352171499991, 10.505391099000065],
              [-74.8235225059999 , 10.505391259000078],
              [-74.8235645389999 , 10.505399753000063],
              [-74.82356627399992, 10.505400104000046],
              [-74.82357257299992, 10.505401377000055],
              [-74.8236187309999 , 10.505426254000042],
              [-74.8236275189999 , 10.505430990000093],
              [-74.82365751599991, 10.505447157000049],
              [-74.82368440999994, 10.505461652000065],
              [-74.82370321299993, 10.505471786000044],
              [-74.8237818959999 , 10.505514194000057],
              [-74.82379335899992, 10.505520372000092],
              [-74.82390469199993, 10.505580377000058],
              [-74.82389915499994, 10.50560439700007 ],
              [-74.82387805099995, 10.505695955000078],
              [-74.82381850799993, 10.505954257000042],
              [-74.8237992729999 , 10.50603770400005 ],
              [-74.82379651299993, 10.506049676000089],
              [-74.82377372999991, 10.506148511000049],
              [-74.82377236799994, 10.506154420000087],
              [-74.82376173299991, 10.506200555000078],
              [-74.82370637799994, 10.506440694000048],
              [-74.82370012799993, 10.506467805000057],
              [-74.8236916919999 , 10.506504401000086],
              [-74.8236346999999 , 10.506632204000084],
              [-74.82363175599994, 10.506639651000057],
              [-74.82361688899994, 10.506677259000071],
              [-74.82361495899994, 10.506682140000066],
              [-74.82359114099995, 10.506742392000092],
              [-74.82358373199992, 10.506761133000055],
              [-74.82344602799992, 10.507109469000056],
              [-74.8233457629999 , 10.507363097000052],
              [-74.8232686209999 , 10.507571100000064],
              [-74.82325436499991, 10.507609537000064],
              [-74.8232463459999 , 10.507635107000056],
              [-74.82309652499993, 10.508112813000082],
              [-74.8229322279999 , 10.508083043000056],
              [-74.82289575399994, 10.508076434000088],
              [-74.82289309099991, 10.50807595100008 ],
              [-74.82288767799992, 10.508074970000052],
              [-74.82287254399995, 10.50807222800006 ],
              [-74.8228724999999 , 10.508072220000088],
              [-74.82287171699994, 10.508072078000055],
              [-74.82284607999992, 10.508067434000054],
              [-74.82283916099993, 10.50809526100005 ],
              [-74.82280999499994, 10.508208256000046],
              [-74.82279569799994, 10.508263644000067],
              [-74.82268822599991, 10.508680023000068],
              [-74.82266380199991, 10.508774646000063],
              [-74.8226317299999 , 10.508898903000045],
              [-74.82248884599994, 10.509302285000047],
              [-74.82278981499991, 10.50953627000007 ],
              [-74.82299962799993, 10.50969938500009 ],
              [-74.82312720099992, 10.50980756100006 ],
              [-74.82330674699995, 10.509922394000057],
              [-74.8235504239999 , 10.510116175000064],
              [-74.8238233319999 , 10.51035735000005 ],
              [-74.82406708999991, 10.510586151000041],
              [-74.82435161299992, 10.51090639000006 ],
              [-74.82465327599994, 10.511232233000044],
              [-74.82476941999994, 10.511334789000045],
              [-74.82505392999991, 10.511655594000047],
              [-74.82521016499993, 10.511809464000066],
              [-74.8255350689999 , 10.512067462000061],
              [-74.82586629899993, 10.512341258000049],
              [-74.82618552399992, 10.512610554000048],
              [-74.82647543699994, 10.512798573000055],
              [-74.82670753499991, 10.51292458100005 ],
              [-74.82684706099991, 10.513015774000053],
              [-74.82697464499995, 10.513130735000061],
              [-74.8272182899999 , 10.513307548000057],
              [-74.82735158199995, 10.513415716000054],
              [-74.82746204799992, 10.513530711000044],
              [-74.82763314099992, 10.513690763000056],
              [-74.8278337079999 , 10.513999899000055],
              [-74.8279379569999 , 10.51413750200004 ],
              [-74.82818205699994, 10.514503036000065],
              [-74.8283098839999 , 10.514731521000044],
              [-74.82843206699994, 10.514949914000056],
              [-74.82848785699991, 10.51503028600007 ],
              [-74.82863487899994, 10.515242088000036],
              [-74.82897775499993, 10.515608511000039],
              [-74.8291458679999 , 10.515716588000089],
              [-74.82940658599995, 10.515870774000064],
              [-74.82953406599995, 10.515939398000057],
              [-74.82966958799994, 10.516105314000072],
              [-74.82975512999991, 10.516182196000045],
              [-74.82986587599993, 10.516258070000049],
              [-74.83004228999994, 10.51634931700005 ],
              [-74.83023628099994, 10.516438682000057],
              [-74.83043151599992, 10.51651077400004 ],
              [-74.83057941599992, 10.51663807600005 ],
              [-74.83068595799995, 10.516734047000057],
              [-74.83077517399994, 10.516829015000042],
              [-74.83079486499992, 10.516963427000064],
              [-74.83081692499991, 10.517147237000074],
              [-74.83089427699991, 10.517319805000056],
              [-74.83091556999995, 10.517367311000044],
              [-74.83097028799995, 10.51748937700006 ],
              [-74.83111976499993, 10.517637189000084],
              [-74.83114774499995, 10.517777928000044],
              [-74.83112284399994, 10.51792718300004 ],
              [-74.83112076399993, 10.518124140000054],
              [-74.83115292499991, 10.51828832800004 ],
              [-74.83132866499994, 10.518518938000057],
              [-74.8314924039999 , 10.518630020000046],
              [-74.8316567469999 , 10.51881654500005 ],
              [-74.83171896499994, 10.51897907600005 ],
              [-74.83180484499991, 10.519226550000042],
              [-74.83188726499992, 10.51947388900004 ],
              [-74.83197957899995, 10.519746972000064],
              [-74.83204143899991, 10.519997955000065],
              [-74.83220232999992, 10.52036137400006 ],
              [-74.83229764899994, 10.520675294000057],
              [-74.8324193439999 , 10.520975572000054],
              [-74.83248675399994, 10.521099511000045],
              [-74.83255559899993, 10.521226070000068],
              [-74.83266772099995, 10.521432204000064],
              [-74.83284736199994, 10.521695480000062],
              [-74.83300751699994, 10.522030169000061],
              [-74.8333858499999 , 10.52236948500007 ],
              [-74.83369515999993, 10.52262409900004 ],
              [-74.83395716099994, 10.523099463000051],
              [-74.8341307199999 , 10.523580527000092],
              [-74.83434663299994, 10.523788373000059],
              [-74.83460816599995, 10.523970030000044],
              [-74.83482139099993, 10.524218484000073],
              [-74.83498845499992, 10.524586194000051],
              [-74.8349662679999 , 10.524863779000043],
              [-74.83495483099995, 10.524997527000039],
              [-74.8349385379999 , 10.525188054000068],
              [-74.83496473799994, 10.525567454000054],
              [-74.83505377799992, 10.525840379000044],
              [-74.83523732299994, 10.526064518000055],
              [-74.83553155299995, 10.526292728000044],
              [-74.83573298599993, 10.526378050000062],
              [-74.83591931099994, 10.52647115800005 ],
              [-74.83609485499994, 10.52660187400005 ],
              [-74.83617801799994, 10.526708876000043],
              [-74.83631264999991, 10.526790049000056],
              [-74.83643997899992, 10.526900313000056],
              [-74.83650961299992, 10.527126169000042],
              [-74.8364731819999 , 10.527272181000058],
              [-74.83644106399993, 10.527482452000072],
              [-74.83643252099995, 10.527697527000043],
              [-74.83641344699993, 10.527888261000044],
              [-74.8364433559999 , 10.528213848000064],
              [-74.83662258399994, 10.528233399000044],
              [-74.83665874799993, 10.528283085000055],
              [-74.83693752199991, 10.52851829900004 ],
              [-74.83716397799992, 10.528722551000044],
              [-74.83723347699993, 10.528894139000045],
              [-74.83728020299992, 10.529083872000058],
              [-74.83734188299991, 10.52938245200005 ],
              [-74.8373615189999 , 10.529477516000043],
              [-74.83737959199993, 10.52956498900005 ],
              [-74.83758339299993, 10.530017618000045],
              [-74.83780398099992, 10.53033234700007 ],
              [-74.83799209099993, 10.530447252000045],
              [-74.83828810299991, 10.530548700000054],
              [-74.83854022499992, 10.530679727000063],
              [-74.83862756799994, 10.530908895000039],
              [-74.83868002999992, 10.53110990500005 ],
              [-74.8387445109999 , 10.53132783600006 ],
              [-74.8387506009999 , 10.531482622000055],
              [-74.83876244699991, 10.531654357000093],
              [-74.83873337099993, 10.531900764000056],
              [-74.83877465799992, 10.532204617000048],
              [-74.83884446299993, 10.532495987000061],
              [-74.8388682719999 , 10.532656381000038],
              [-74.8389965739999 , 10.533284347000063],
              [-74.83906680599995, 10.533515808000061],
              [-74.8391712099999 , 10.533716698000092],
              [-74.83923817999994, 10.533899589000043],
              [-74.83931683999992, 10.534192063000091],
              [-74.83959596999995, 10.534626420000052],
              [-74.83971789799995, 10.534752681000043],
              [-74.83994109899993, 10.53496401700005 ],
              [-74.84007226599994, 10.53536540500005 ],
              [-74.84003311799995, 10.535460791000048],
              [-74.8399218699999 , 10.535731871000053],
              [-74.83975452199991, 10.53593907700008 ],
              [-74.83970820499991, 10.536139182000056],
              [-74.8397146609999 , 10.536443133000091],
              [-74.83967174999992, 10.536683187000051],
              [-74.8396316059999 , 10.536907757000051],
              [-74.8396034349999 , 10.53702041400004 ],
              [-74.83801569099995, 10.537562123000043],
              [-74.83798527599993, 10.537572211000054],
              [-74.83794670599991, 10.537616609000054],
              [-74.83795259199991, 10.537751760000049],
              [-74.83804029899994, 10.537906809000049],
              [-74.83807030799994, 10.538059773000043],
              [-74.83808144299991, 10.538130916000057],
              [-74.8380955049999 , 10.53823846000006 ],
              [-74.8379777049999 , 10.538268990000063],
              [-74.8377970439999 , 10.538307209000038],
              [-74.83771591699991, 10.538366461000066],
              [-74.83774694999994, 10.538435832000062],
              [-74.83778157899991, 10.53854059400004 ],
              [-74.83775968499992, 10.538579976000051],
              [-74.83771211399994, 10.538665526000045],
              [-74.83771079099995, 10.538667900000064],
              [-74.83760253499992, 10.538778171000047],
              [-74.83750885299992, 10.538854050000054],
              [-74.83738335999993, 10.538910488000056],
              [-74.8372788879999 , 10.538918465000052],
              [-74.83714657199994, 10.538860043000057],
              [-74.8369929619999 , 10.53884729500004 ],
              [-74.83676408799994, 10.538894218000053],
              [-74.83661386099993, 10.53901631900004 ],
              [-74.83654412599992, 10.539216991000046],
              [-74.83654134499994, 10.539483459000053],
              [-74.83655921299993, 10.539572504000091],
              [-74.83657795599993, 10.53968189200009 ],
              [-74.83657849999992, 10.539685066000061],
              [-74.83660647399995, 10.53980111800007 ],
              [-74.83655848399991, 10.539897689000043],
              [-74.83645454299995, 10.539896953000039],
              [-74.83634327099992, 10.539872306000063],
              [-74.83624816999992, 10.539924648000067],
              [-74.83612388099993, 10.540051559000062],
              [-74.83601945799995, 10.540147500000046],
              [-74.83594352999995, 10.54030679400006 ],
              [-74.83594905599995, 10.540582585000038],
              [-74.83597046499995, 10.540676948000055],
              [-74.83598817299992, 10.540755001000036],
              [-74.8361023619999 , 10.540870617000053],
              [-74.83617164999993, 10.540949589000093],
              [-74.83623543599992, 10.541022290000058],
              [-74.83633415799994, 10.541101746000038],
              [-74.83639194499995, 10.541178339000055],
              [-74.83645919299994, 10.541260787000056],
              [-74.83672337299993, 10.541785146000052],
              [-74.83678543499991, 10.542193087000044],
              [-74.83679721699991, 10.54273250600005 ],
              [-74.83674844599994, 10.543379352000045],
              [-74.8363525659999 , 10.544426231000045],
              [-74.83616752599994, 10.544889045000048],
              [-74.83603474599994, 10.545187307000049],
              [-74.83586054599994, 10.54557860400007 ],
              [-74.8355868399999 , 10.546123927000053],
              [-74.83550066899994, 10.546797821000041],
              [-74.83557359199995, 10.547311574000048],
              [-74.8358840379999 , 10.54791571900006 ],
              [-74.83635198799993, 10.548201478000067],
              [-74.83655838899995, 10.548401162000062],
              [-74.83674231299995, 10.548469288000092],
              [-74.83683070699993, 10.548568216000092],
              [-74.8369995729999 , 10.548750960000064],
              [-74.83707550699995, 10.548679477000064],
              [-74.83706248599992, 10.548517823000054],
              [-74.83709280999994, 10.548373412000046],
              [-74.83728954999992, 10.54835894200005 ],
              [-74.83751474199994, 10.54845148700008 ],
              [-74.8377057049999 , 10.548433750000072],
              [-74.83801558399995, 10.54832084800006 ],
              [-74.83805140899995, 10.54831716700005 ],
              [-74.83805118899994, 10.548454573000072],
              [-74.8379893579999 , 10.548582421000049],
              [-74.8380577719999 , 10.548668411000051],
              [-74.83821567199993, 10.548566816000061],
              [-74.83827786099994, 10.548567639000055],
              [-74.83832264199992, 10.548652190000041],
              [-74.83841292599993, 10.548631317000059],
              [-74.8386170039999 , 10.548691974000064],
              [-74.83881853399993, 10.548780087000068],
              [-74.83902381399992, 10.548900563000075],
              [-74.83941596799991, 10.548970904000043],
              [-74.83959662599995, 10.548970068000074],
              [-74.83987681299993, 10.548896351000053],
              [-74.84011225599994, 10.548916347000045],
              [-74.84050223899993, 10.549017982000066],
              [-74.84066319399994, 10.549140196000053],
              [-74.84092043299995, 10.549319153000056],
              [-74.84134818399991, 10.549742605000063],
              [-74.8414871889999 , 10.549966170000062],
              [-74.84158570099993, 10.550351401000057],
              [-74.8415875789999 , 10.550634555000045],
              [-74.8415899929999 , 10.55070429300008 ],
              [-74.84159668599995, 10.550897689000067],
              [-74.8416535149999 , 10.551020694000044],
              [-74.84183623799993, 10.551116148000062],
              [-74.84185485299992, 10.551118942000073],
              [-74.84191362299993, 10.551127759000053],
              [-74.84191866099991, 10.551173737000056],
              [-74.84193057399995, 10.551373712000043],
              [-74.84193378099991, 10.551394845000061],
              [-74.84196787799993, 10.551619266000046],
              [-74.84197759199992, 10.551683212000057],
              [-74.84202993699995, 10.551837889000069],
              [-74.84212878599993, 10.55209641600004 ],
              [-74.84226851199992, 10.552371230000062],
              [-74.84250641199992, 10.552634491000049],
              [-74.8426492449999 , 10.553012114000069],
              [-74.8427644599999 , 10.553194147000056],
              [-74.84285545299991, 10.553395239000054],
              [-74.84290777099994, 10.55353296900006 ],
              [-74.84307966299991, 10.55388509700009 ],
              [-74.84317542399992, 10.55416284000006 ],
              [-74.84329188999993, 10.554386286000067],
              [-74.84348926799993, 10.554540606000046],
              [-74.84370352299993, 10.554650541000058],
              [-74.84396494399994, 10.554785783000057],
              [-74.8441210169999 , 10.554865624000058],
              [-74.8442548299999 , 10.554945541000052],
              [-74.8443705869999 , 10.55511135100005 ],
              [-74.84445823599992, 10.555226390000087],
              [-74.8445479959999 , 10.55538606500005 ],
              [-74.84467286099994, 10.55565525500009 ],
              [-74.8447486149999 , 10.555809882000062],
              [-74.84483020999994, 10.556016467000063],
              [-74.84491747299995, 10.55620495100004 ],
              [-74.84501349899995, 10.556357269000046],
              [-74.84519026399994, 10.556605429000058],
              [-74.84532990699995, 10.556731642000045],
              [-74.84550396499992, 10.556921053000053],
              [-74.84562087199993, 10.557090824000056],
              [-74.84599513699993, 10.557421557000055],
              [-74.8462154899999 , 10.557521011000063],
              [-74.84640665499995, 10.557586652000055],
              [-74.84665330099995, 10.557692265000071],
              [-74.84681875699994, 10.55775174200005 ],
              [-74.84717253199995, 10.557881378000047],
              [-74.8474806179999 , 10.558010008000053],
              [-74.84776544699992, 10.558199149000075],
              [-74.84835201999994, 10.55871974900009 ],
              [-74.84846817199991, 10.558816644000046],
              [-74.8486021459999 , 10.558954164000056],
              [-74.84876413899991, 10.559114228000055],
              [-74.84901418599992, 10.559320399000057],
              [-74.8493267899999 , 10.559428106000041],
              [-74.84959923999992, 10.559462457000052],
              [-74.84992977299993, 10.559433399000056],
              [-74.85012108899991, 10.55944422400006 ],
              [-74.8502833519999 , 10.559484506000047],
              [-74.85058690199992, 10.55962274600006 ],
              [-74.85080265599993, 10.559818264000057],
              [-74.85095346599991, 10.559955184000046],
              [-74.85114204299992, 10.560124213000051],
              [-74.85133945699994, 10.56029546700006 ],
              [-74.85157417799991, 10.56042427400007 ],
              [-74.85174536099993, 10.560489961000087],
              [-74.85197729499993, 10.560534572000051],
              [-74.85232822199993, 10.560663102000092],
              [-74.85255734599991, 10.56084050000004 ],
              [-74.85271950299995, 10.560951971000065],
              [-74.8529576279999 , 10.561180767000053],
              [-74.85314947199993, 10.56140401500005 ],
              [-74.85324797099992, 10.561517902000048],
              [-74.85347458199993, 10.561821867000049],
              [-74.8537646119999 , 10.562152798000056],
              [-74.8538985319999 , 10.562267716000065],
              [-74.85400844899993, 10.562381579000089],
              [-74.8547976289999 , 10.562964665000038],
              [-74.85494133899994, 10.563119400000062],
              [-74.85507467899993, 10.563233190000062],
              [-74.8552603249999 , 10.563376233000042],
              [-74.85537079599993, 10.563482167000075],
              [-74.85553901799994, 10.563619609000057],
              [-74.85569876499994, 10.56379097200005 ],
              [-74.85587001999994, 10.563936598000055],
              [-74.85603847199991, 10.564114428000039],
              [-74.8561950749999 , 10.564277323000056],
              [-74.85633340699991, 10.56456313700005 ],
              [-74.85633618299994, 10.56457643400006 ],
              [-74.85635060899995, 10.564645537000047],
              [-74.85636714899994, 10.564724770000055],
              [-74.85613034999994, 10.56532003600006 ],
              [-74.85550129199993, 10.566903353000043],
              [-74.85486109699991, 10.568412418000037],
              [-74.85470093099991, 10.568785656000045],
              [-74.85425772199994, 10.569818478000059],
              [-74.85375388199992, 10.571002357000054],
              [-74.8533490669999 , 10.571994111000038],
              [-74.85291402199994, 10.573037560000046],
              [-74.85279073799995, 10.573340461000043],
              [-74.85260848799993, 10.573788225000044],
              [-74.85240195199992, 10.57429565700005 ],
              [-74.8517699489999 , 10.57582055000006 ],
              [-74.85098473199992, 10.57766930300005 ],
              [-74.85040772399992, 10.579075188000047],
              [-74.8499906049999 , 10.580087586000047],
              [-74.84987068799995, 10.580378630000041],
              [-74.84911239899992, 10.582173427000043],
              [-74.84887559499992, 10.582767652000086],
              [-74.84864199599991, 10.583353826000064],
              [-74.8480496279999 , 10.58474966700004 ],
              [-74.84760666499994, 10.58584088300006 ],
              [-74.84738547699993, 10.586381851000056],
              [-74.84728157399991, 10.586635964000038],
              [-74.8472198689999 , 10.586786885000038],
              [-74.84716428699994, 10.586922824000055],
              [-74.84697613599991, 10.587424216000045],
              [-74.8468777679999 , 10.587686351000059],
              [-74.84673250799995, 10.588048861000061],
              [-74.84664088499994, 10.588238327000056],
              [-74.84662262699993, 10.58827608300004 ],
              [-74.84661352599994, 10.588319337000087],
              [-74.84657807899993, 10.588487807000092],
              [-74.84658480399992, 10.588875030000054],
              [-74.84670028399995, 10.58960316100007 ],
              [-74.84683658599994, 10.590404026000044],
              [-74.84698729199994, 10.59132856900004 ],
              [-74.84708339199994, 10.591918113000077],
              [-74.8470877609999 , 10.591944916000045],
              [-74.84713257899995, 10.592217453000046],
              [-74.84715618999991, 10.592361030000063],
              [-74.84716622299993, 10.592422038000052],
              [-74.8472938729999 , 10.59243540400007 ],
              [-74.84731222099992, 10.592555093000044],
              [-74.8473698219999 , 10.592930831000047],
              [-74.84744223199993, 10.593382198000086],
              [-74.84732740999993, 10.593395526000052],
              [-74.84736095999995, 10.593613960000084],
              [-74.84740164899995, 10.593870228000071],
              [-74.84739774199994, 10.59387819200009 ],
              [-74.84738308899995, 10.593908062000082],
              [-74.84733929799995, 10.594023350000043],
              [-74.84731313299994, 10.594092232000037],
              [-74.84725364699995, 10.594242614000052],
              [-74.84716917599991, 10.594435350000083],
              [-74.84707399699994, 10.59464474300006 ],
              [-74.8469493849999 , 10.594923640000047],
              [-74.84694922999995, 10.594924098000092],
              [-74.8469440209999 , 10.594939529000044],
              [-74.8465871009999 , 10.594823888000064],
              [-74.8462601629999 , 10.59471538400004 ],
              [-74.84602031399993, 10.594637338000041],
              [-74.84593369799995, 10.59461286000004 ],
              [-74.8459108589999 , 10.594606405000093],
              [-74.84591104999993, 10.59462966600006 ],
              [-74.84591747499991, 10.59482027100006 ],
              [-74.84592413699994, 10.594891407000091],
              [-74.84593779099993, 10.595032295000067],
              [-74.84595031099991, 10.595161476000044],
              [-74.84599076899991, 10.595345787000042],
              [-74.84599314099995, 10.59535659100004 ],
              [-74.84600724099994, 10.595403442000077],
              [-74.84602148399995, 10.595450765000066],
              [-74.84581016099992, 10.595713273000058],
              [-74.84561028599995, 10.595978582000043],
              [-74.8454764359999 , 10.596141879000072],
              [-74.84543182599992, 10.596196303000056],
              [-74.84542459199992, 10.596204532000058],
              [-74.84530214599994, 10.59634383000008 ],
              [-74.84541749699991, 10.596427604000041],
              [-74.84551510799992, 10.596498495000048],
              [-74.84551752899995, 10.596499881000057],
              [-74.84554243899993, 10.596514138000089],
              [-74.84534176499994, 10.596714457000076],
              [-74.84513567099992, 10.59692412700008 ],
              [-74.8448382389999 , 10.59723107800005 ],
              [-74.84460029199994, 10.597465454000087],
              [-74.84427906499991, 10.597784302000036],
              [-74.84398996099992, 10.598084114000073],
              [-74.84360448799993, 10.598476725000069],
              [-74.8432939679999 , 10.598795573000075],
              [-74.8430607809999 , 10.599032330000057],
              [-74.84275026199992, 10.59934165900006 ],
              [-74.84250993599994, 10.599578416000043],
              [-74.84228031799995, 10.599813982000057],
              [-74.84200311099994, 10.600085241000045],
              [-74.84171638599992, 10.600371966000068],
              [-74.84149390599993, 10.600590877000059],
              [-74.84143203999992, 10.600653932000057],
              [-74.84138920999993, 10.600699142000053],
              [-74.84133686199993, 10.600769336000042],
              [-74.84127856499993, 10.600854997000056],
              [-74.8412143189999 , 10.600972780000063],
              [-74.84114888399995, 10.601135773000067],
              [-74.8410589739999 , 10.601285845000064],
              [-74.84119618299991, 10.601780481000048],
              [-74.84172323399991, 10.603680482000073],
              [-74.84541072199994, 10.616973762000043],
              [-74.84666561799992, 10.62149762000007 ],
              [-74.8480540349999 , 10.626502819000052],
              [-74.84968653599992, 10.632387932000086],
              [-74.84985474299992, 10.63299431200005 ],
              [-74.85248882299993, 10.642490088000045],
              [-74.85249917099992, 10.64252739300008 ],
              [-74.85522062599995, 10.652338154000063],
              [-74.85551048299993, 10.653383079000037],
              [-74.85551665699995, 10.653405336000048],
              [-74.85577802599994, 10.654347873000063],
              [-74.86062844999992, 10.671839201000068],
              [-74.86238264299993, 10.67816507300006 ],
              [-74.86276525699992, 10.679544834000069],
              [-74.8633525489999 , 10.681662693000078],
              [-74.86478618199993, 10.686832579000054],
              [-74.86558210599992, 10.689702795000073],
              [-74.86565638999991, 10.689970673000062],
              [-74.86574096899994, 10.69027568000007 ],
              [-74.86736442199992, 10.69613008500005 ],
              [-74.8678750329999 , 10.69797142200008 ],
              [-74.86807398299993, 10.698688863000086],
              [-74.87537657599995, 10.725023069000088],
              [-74.87555476599994, 10.725665648000074],
              [-74.8755839929999 , 10.725771044000055],
              [-74.87551225199991, 10.725814953000054],
              [-74.87536951499993, 10.725871187000052],
              [-74.87522671999994, 10.725913515000059],
              [-74.87504679499995, 10.725917810000055],
              [-74.87498999699994, 10.72591916400006 ],
              [-74.8748585159999 , 10.725873369000055],
              [-74.87473384299994, 10.72577655400005 ],
              [-74.87467895499992, 10.725720137000053],
              [-74.87463728499995, 10.72567730500009 ],
              [-74.8746059259999 , 10.725482746000068],
              [-74.87463768899994, 10.725250836000043],
              [-74.87469321799995, 10.725090673000068],
              [-74.87464338199993, 10.724956460000044],
              [-74.87449759499992, 10.724857419000045],
              [-74.87425592699992, 10.72480745900009 ],
              [-74.87399077299995, 10.724746009000057],
              [-74.87378430899992, 10.72470517100004 ],
              [-74.87375460399994, 10.724697709000054],
              [-74.87364819699991, 10.724670985000046],
              [-74.87348330499992, 10.724493221000046],
              [-74.87348347599993, 10.724406829000088],
              [-74.8734840159999 , 10.724133968000046],
              [-74.87341937899993, 10.723837572000093],
              [-74.87334260199992, 10.723448519000044],
              [-74.87328788799994, 10.723272601000076],
              [-74.87330143499992, 10.723158974000057],
              [-74.87333786799991, 10.722922409000091],
              [-74.87337885999995, 10.722658013000057],
              [-74.8734175699999 , 10.722407531000044],
              [-74.87332893599995, 10.72198839500004 ],
              [-74.87318721199995, 10.721750274000044],
              [-74.8731580619999 , 10.721525574000054],
              [-74.87296959499992, 10.72131778000005 ],
              [-74.87278135899993, 10.721160978000057],
              [-74.87255348299993, 10.721060651000073],
              [-74.87252064899991, 10.721046201000092]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08573",
      "properties": {"id": "08573", "iso": "PUC", "name": "PUERTO COLOMBIA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.87272351899992, 11.047953309000093],
              [-74.87272286299992, 11.04771285600009 ],
              [-74.87294397199992, 11.047646692000058],
              [-74.8730541999999 , 11.047493384000063],
              [-74.87316472399993, 11.047449373000063],
              [-74.87325281999995, 11.047296126000049],
              [-74.87325234499991, 11.047121247000064],
              [-74.87311933899991, 11.047034161000056],
              [-74.8728323229999 , 11.047275378000052],
              [-74.87252317699995, 11.047516649000045],
              [-74.87214633799994, 11.047277191000092],
              [-74.87243347399993, 11.047079696000083],
              [-74.8724344609999 , 11.047078199000055],
              [-74.8724390889999 , 11.047081166000055],
              [-74.87240324099992, 11.047047922000047],
              [-74.8723725779999 , 11.04699492800006 ],
              [-74.87238563799991, 11.046801045000052],
              [-74.87243421199992, 11.046756539000057],
              [-74.8726093379999 , 11.046596081000075],
              [-74.87274542499995, 11.046471394000037],
              [-74.8728869869999 , 11.046273227000086],
              [-74.8729024079999 , 11.046251639000047],
              [-74.87293079599993, 11.046207353000057],
              [-74.8730266899999 , 11.046057738000059],
              [-74.8730321939999 , 11.045877483000083],
              [-74.87303320199993, 11.045844465000073],
              [-74.87303318799991, 11.045721674000049],
              [-74.87303318499994, 11.04569586100007 ],
              [-74.87303317399994, 11.045618269000045],
              [-74.87295465599993, 11.045534260000068],
              [-74.87294253399995, 11.045530273000054],
              [-74.87286183999993, 11.045503713000073],
              [-74.87285651699995, 11.04550196100007 ],
              [-74.87285463599994, 11.045497389000047],
              [-74.87282794499993, 11.045432510000069],
              [-74.87277143699993, 11.04529516100007 ],
              [-74.87271873599991, 11.04531686100006 ],
              [-74.87269293399993, 11.045327485000087],
              [-74.87261651299991, 11.045399023000073],
              [-74.87256864799991, 11.045443830000067],
              [-74.87256305799991, 11.045464546000062],
              [-74.87254248999994, 11.045540776000053],
              [-74.87249016499993, 11.045637725000063],
              [-74.87241820999992, 11.045721751000087],
              [-74.87232008799992, 11.045818703000066],
              [-74.87230195899991, 11.045930721000047],
              [-74.87230023299992, 11.045941385000049],
              [-74.87229393899992, 11.04598027700007 ],
              [-74.87230496399991, 11.045997388000046],
              [-74.87233974199995, 11.04605136300006 ],
              [-74.8723397629999 , 11.046193544000062],
              [-74.87231770299991, 11.046220791000053],
              [-74.87228372699991, 11.046262754000054],
              [-74.87226126599995, 11.04629049600004 ],
              [-74.87223511999991, 11.04645207100009 ],
              [-74.87218790299994, 11.046532657000057],
              [-74.87216316699994, 11.046574872000065],
              [-74.87215274499994, 11.046627856000043],
              [-74.87213694499991, 11.046708178000074],
              [-74.87211739899993, 11.046807539000042],
              [-74.87210433699994, 11.046949725000047],
              [-74.87206989299995, 11.046986189000052],
              [-74.87202590399994, 11.047032756000078],
              [-74.8720127549999 , 11.047046675000047],
              [-74.87177069599994, 11.047104871000045],
              [-74.87165946999994, 11.047053184000049],
              [-74.87157613199992, 11.04697636700007 ],
              [-74.87156132299992, 11.046962717000042],
              [-74.87152136599991, 11.046892567000043],
              [-74.87144353399992, 11.046755923000092],
              [-74.87141726699991, 11.046725177000042],
              [-74.87133850499993, 11.046632987000066],
              [-74.87126686399995, 11.046549132000052],
              [-74.87126552099994, 11.046535878000043],
              [-74.8712537639999 , 11.046419877000062],
              [-74.87119915099993, 11.046403700000042],
              [-74.87113120299995, 11.04638357400006 ],
              [-74.8711229139999 , 11.046381119000046],
              [-74.87097196399992, 11.046476577000078],
              [-74.87095936799994, 11.046484542000087],
              [-74.87095843799995, 11.04647536300007 ],
              [-74.87095281799992, 11.046419915000058],
              [-74.87089421399992, 11.046404984000048],
              [-74.8708368529999 , 11.04639036900005 ],
              [-74.87076311099992, 11.046371581000074],
              [-74.87074999899994, 11.04636824000005 ],
              [-74.87058421899991, 11.046357690000093],
              [-74.87054718599995, 11.046355333000065],
              [-74.87054293599994, 11.04635769500004 ],
              [-74.87053115399993, 11.046364244000074],
              [-74.87033784399995, 11.046471693000058],
              [-74.87028510899995, 11.046471698000062],
              [-74.87015465899992, 11.046471712000084],
              [-74.87009326499992, 11.046438642000055],
              [-74.86993874399991, 11.046355409000057],
              [-74.86986023499992, 11.046310178000056],
              [-74.86988115699995, 11.046148815000038],
              [-74.86989291599991, 11.04605812400007 ],
              [-74.86990018099993, 11.046026639000047],
              [-74.86996806899992, 11.045732435000048],
              [-74.86997792099993, 11.045689734000064],
              [-74.87004235899991, 11.04560391700005 ],
              [-74.87008884499994, 11.045542009000087],
              [-74.87012837099991, 11.045489369000052],
              [-74.87019377699994, 11.045353642000066],
              [-74.8702591789999 , 11.045179139000084],
              [-74.87037200899994, 11.045037247000039],
              [-74.87040308799993, 11.044998164000049],
              [-74.8704078799999 , 11.04498869400004 ],
              [-74.87047503399992, 11.04485597300004 ],
              [-74.87038997999991, 11.044843058000083],
              [-74.8703545109999 , 11.044803649000073],
              [-74.87033763499994, 11.044784899000092],
              [-74.87034437699992, 11.044773625000062],
              [-74.87042267099991, 11.044642706000047],
              [-74.8705206699999 , 11.044497455000055],
              [-74.87052732899991, 11.04448758500007 ],
              [-74.87054108699994, 11.044444077000037],
              [-74.87056002799994, 11.04438417700004 ],
              [-74.87053384699993, 11.044319552000047],
              [-74.8704749609999 , 11.044242005000058],
              [-74.8705076679999 , 11.044196764000048],
              [-74.87056689499991, 11.044151242000055],
              [-74.87062541799992, 11.044106270000043],
              [-74.87065811199994, 11.04397054900005 ],
              [-74.87057304899992, 11.043867155000044],
              [-74.87038986499994, 11.04386717500006 ],
              [-74.87031214099994, 11.043803208000043],
              [-74.87031134999995, 11.043802557000049],
              [-74.87027839899991, 11.043793638000068],
              [-74.8702284869999 , 11.043780129000083],
              [-74.87026274999994, 11.043699950000075],
              [-74.87029130399992, 11.043550044000085],
              [-74.87031271899991, 11.043442969000068],
              [-74.87032556799994, 11.043388717000084],
              [-74.87034983799992, 11.043324471000062],
              [-74.87039124099994, 11.043263081000077],
              [-74.87044834799991, 11.043184559000053],
              [-74.87049688899992, 11.04312888000004 ],
              [-74.87057826699993, 11.043067490000055],
              [-74.87064393999992, 11.043013238000071],
              [-74.87070532999991, 11.042973263000079],
              [-74.87090234899995, 11.042867615000091],
              [-74.87122072099993, 11.04267630600009 ],
              [-74.87136777199993, 11.042586363000055],
              [-74.87145200499992, 11.04252211700009 ],
              [-74.87151482299993, 11.042466438000076],
              [-74.87159191699993, 11.042379349000043],
              [-74.87162903699993, 11.042293689000076],
              [-74.87165473499994, 11.04221373900009 ],
              [-74.87166472799993, 11.042135217000066],
              [-74.87165616199991, 11.042056694000053],
              [-74.8716376029999 , 11.041991021000058],
              [-74.87161761499993, 11.041935342000045],
              [-74.8715862059999 , 11.041871097000069],
              [-74.8715605079999 , 11.041738323000061],
              [-74.87154623099991, 11.04163243700009 ],
              [-74.87154385199995, 11.041582468000058],
              [-74.8715152979999 , 11.041539638000074],
              [-74.87144391399994, 11.041415906000054],
              [-74.87138442799994, 11.041294553000057],
              [-74.87129876699993, 11.041113714000062],
              [-74.8712583169999 , 11.040940013000068],
              [-74.87117979399994, 11.04070206700004 ],
              [-74.87112030799994, 11.040611648000038],
              [-74.87094422799993, 11.040399876000038],
              [-74.87079908099992, 11.04019048300006 ],
              [-74.87066583099994, 11.040031059000057],
              [-74.8706372769999 , 11.039962055000046],
              [-74.87061348299994, 11.039866877000065],
              [-74.87060872399991, 11.039771698000038],
              [-74.87062775899994, 11.039512337000076],
              [-74.87073007599992, 11.039034065000067],
              [-74.8707586299999 , 11.038898436000068],
              [-74.87078718299995, 11.038765186000091],
              [-74.87085618799995, 11.038579588000061],
              [-74.87095136599993, 11.038336883000056],
              [-74.87103702699994, 11.038132250000046],
              [-74.87117979399994, 11.037758674000088],
              [-74.8713035259999 , 11.037449344000038],
              [-74.8713558739999 , 11.037332751000065],
              [-74.87140742199995, 11.03722965500009 ],
              [-74.87142963799994, 11.03718522400004 ],
              [-74.87146770899994, 11.037116220000087],
              [-74.8715010219999 , 11.03699486700009 ],
              [-74.87158906199994, 11.036794992000068],
              [-74.87161523599991, 11.036714091000078],
              [-74.87165092799995, 11.036590359000058],
              [-74.8717127939999 , 11.036407140000051],
              [-74.8717413469999 , 11.036342895000075],
              [-74.87177465999991, 11.036240578000047],
              [-74.87185080199993, 11.036102569000093],
              [-74.87190403799991, 11.03595868800005 ],
              [-74.8719388419999 , 11.035864623000066],
              [-74.87202450299992, 11.035652851000066],
              [-74.87209112799991, 11.035502945000076],
              [-74.87222437799994, 11.035195995000038],
              [-74.87233859199995, 11.034965187000068],
              [-74.87239807799995, 11.034796245000052],
              [-74.87247184199992, 11.034584473000052],
              [-74.8725360869999 , 11.034365563000051],
              [-74.87260509099991, 11.034218036000084],
              [-74.87272023399993, 11.034030647000066],
              [-74.87272644399991, 11.034020541000075],
              [-74.87275067199994, 11.034004377000088],
              [-74.87275165299991, 11.03400391000008 ],
              [-74.87280327499991, 11.03397932300004 ],
              [-74.87294252099991, 11.033913002000077],
              [-74.87294487499992, 11.033911881000051],
              [-74.8729465099999 , 11.03390423400009 ],
              [-74.87296942299992, 11.03379706000004 ],
              [-74.87298660999994, 11.033716670000047],
              [-74.87299028699994, 11.03369947200008 ],
              [-74.87315684899994, 11.032893430000058],
              [-74.8734126409999 , 11.031668007000064],
              [-74.8734184349999 , 11.031581092000067],
              [-74.87341972799993, 11.031561706000048],
              [-74.8734215639999 , 11.03153416200007 ],
              [-74.87328727399995, 11.031428026000071],
              [-74.8732564899999 , 11.03140369600004 ],
              [-74.87310892199991, 11.03131253500004 ],
              [-74.87304039299994, 11.03129397500004 ],
              [-74.8730221539999 , 11.031290555000055],
              [-74.87299470799991, 11.031285409000077],
              [-74.8729504499999 , 11.031288264000068],
              [-74.87290949999993, 11.031301218000067],
              [-74.87290168899995, 11.03129090200008 ],
              [-74.87226444299995, 11.030449307000083],
              [-74.87221160799993, 11.030379529000072],
              [-74.87196351799992, 11.03003726500009 ],
              [-74.87169163499993, 11.029662175000055],
              [-74.87173247599992, 11.029624487000092],
              [-74.87178635299995, 11.029581026000074],
              [-74.8718607109999 , 11.02950472300006 ],
              [-74.87193907799991, 11.029390112000044],
              [-74.8720170819999 , 11.029253527000037],
              [-74.87204945999991, 11.029196833000071],
              [-74.8720495199999 , 11.029196679000052],
              [-74.87207269599992, 11.029137511000044],
              [-74.87207503099995, 11.02913155300007 ],
              [-74.87215581599992, 11.028925449000042],
              [-74.87224623599991, 11.028751748000047],
              [-74.87223978999992, 11.028741349000086],
              [-74.87223559299991, 11.02873457800007 ],
              [-74.87222390399995, 11.028709720000052],
              [-74.87220630399992, 11.02866735400005 ],
              [-74.87215900799993, 11.02857828000009 ],
              [-74.87212692599991, 11.028510648000065],
              [-74.87210180999995, 11.028457702000082],
              [-74.87206979899992, 11.028398910000078],
              [-74.87203426899993, 11.028347718000077],
              [-74.87199325699993, 11.028288626000062],
              [-74.87194357499993, 11.028230199000063],
              [-74.8719306189999 , 11.02821496200005 ],
              [-74.87172443499992, 11.027997137000057],
              [-74.87167091499992, 11.02795132500006 ],
              [-74.87166477199992, 11.02794539100006 ],
              [-74.87158180499995, 11.027865229000042],
              [-74.87149927599995, 11.027775182000084],
              [-74.87148068599993, 11.027757525000084],
              [-74.87128452099995, 11.027571203000036],
              [-74.87124192999994, 11.02753074900005 ],
              [-74.8712377139999 , 11.027508370000078],
              [-74.87123618399994, 11.02750024800008 ],
              [-74.87122955699994, 11.027465076000055],
              [-74.87111282399991, 11.027363545000071],
              [-74.87108503099995, 11.027342972000042],
              [-74.87101837999995, 11.027293636000081],
              [-74.87093354299992, 11.02724048600004 ],
              [-74.87093336799995, 11.027240377000055],
              [-74.87085726599992, 11.027211431000069],
              [-74.87076950999995, 11.027191832000085],
              [-74.87071784499994, 11.027180294000061],
              [-74.87070935499992, 11.02717823800009 ],
              [-74.87058249299992, 11.02713783900009 ],
              [-74.87054308299992, 11.027125289000082],
              [-74.87045444799992, 11.027105658000039],
              [-74.87040077199993, 11.027097685000058],
              [-74.8703653359999 , 11.027092421000077],
              [-74.87029026499994, 11.027081269000064],
              [-74.87023182999991, 11.02707034000008 ],
              [-74.87018850399994, 11.027062237000052],
              [-74.8697929569999 , 11.026952183000049],
              [-74.8695850659999 , 11.026908008000078],
              [-74.8691217029999 , 11.026800526000045],
              [-74.86903802599994, 11.026781116000052],
              [-74.8689541729999 , 11.026766064000071],
              [-74.86894807899995, 11.026764918000083],
              [-74.86863713399993, 11.026696986000047],
              [-74.86818777299993, 11.026624954000056],
              [-74.86839319499995, 11.026372221000088],
              [-74.86843152099993, 11.026325068000062],
              [-74.86974801999992, 11.024705360000041],
              [-74.87011181799994, 11.024227146000044],
              [-74.87063713299995, 11.023619541000073],
              [-74.87113822799995, 11.023002377000068],
              [-74.87143412499995, 11.022630517000039],
              [-74.8715558849999 , 11.022477498000057],
              [-74.8715861629999 , 11.022439447000068],
              [-74.8715979779999 , 11.02242459900009 ],
              [-74.87159815399991, 11.022424380000075],
              [-74.8715682799999 , 11.022406543000045],
              [-74.87154324499994, 11.022391595000045],
              [-74.87153381399992, 11.022383281000089],
              [-74.87145953899994, 11.022317798000074],
              [-74.87145304699993, 11.022312075000059],
              [-74.87144453299993, 11.022305251000091],
              [-74.8714112479999 , 11.02227857400004 ],
              [-74.87141006399992, 11.022277625000072],
              [-74.87139820399995, 11.022268120000092],
              [-74.87139469399995, 11.022265307000055],
              [-74.87138685899993, 11.022259027000075],
              [-74.87138335199995, 11.022256217000063],
              [-74.87137919499992, 11.022252885000057],
              [-74.87135024899993, 11.022228944000062],
              [-74.8713414689999 , 11.022221682000065],
              [-74.87131934199994, 11.022203381000054],
              [-74.8713139859999 , 11.022198951000064],
              [-74.87130729199993, 11.022193414000071],
              [-74.87130066799995, 11.02218793600008 ],
              [-74.87129167699993, 11.022180500000047],
              [-74.87126710399991, 11.022160176000057],
              [-74.87124952199991, 11.022145634000083],
              [-74.87124653699993, 11.022143165000045],
              [-74.87123059699991, 11.02212998200008 ],
              [-74.87123028099995, 11.022129720000066],
              [-74.87122959099992, 11.02212927700009 ],
              [-74.8712027599999 , 11.022112069000059],
              [-74.87120198299993, 11.022111571000039],
              [-74.8711999539999 , 11.022110269000052],
              [-74.87119642099992, 11.022108003000085],
              [-74.87118469899991, 11.022100485000067],
              [-74.87114126999995, 11.022071662000087],
              [-74.87112614199992, 11.022061621000091],
              [-74.87112532899994, 11.022061088000044],
              [-74.8711237999999 , 11.022060087000057],
              [-74.87112079399992, 11.022058118000075],
              [-74.87110888199993, 11.022050318000083],
              [-74.8710794719999 , 11.022031060000074],
              [-74.87108014499995, 11.022030056000062],
              [-74.87108055899995, 11.02202943900005 ],
              [-74.87107077299993, 11.022023101000059],
              [-74.87106802599993, 11.022021322000057],
              [-74.8710666579999 , 11.022020436000048],
              [-74.87106475599995, 11.02201920400006 ],
              [-74.87106275199994, 11.022017906000087],
              [-74.8710589989999 , 11.022015475000046],
              [-74.87105504999994, 11.022012918000087],
              [-74.87105374399994, 11.022012072000052],
              [-74.87105341199992, 11.022011857000052],
              [-74.87104914199995, 11.022009092000076],
              [-74.87104785999992, 11.022008262000043],
              [-74.87103907999995, 11.022002575000045],
              [-74.87103780399991, 11.022002747000045],
              [-74.87102092099991, 11.021995521000065],
              [-74.87101844999995, 11.021993962000067],
              [-74.87101658599994, 11.021992786000055],
              [-74.87099995999995, 11.021982294000054],
              [-74.87099912899993, 11.021981770000082],
              [-74.87099333999993, 11.02197766800009 ],
              [-74.87099096199995, 11.021975983000061],
              [-74.87098715799993, 11.021973288000083],
              [-74.87098678399991, 11.021973023000044],
              [-74.87097195899992, 11.021962518000066],
              [-74.87097128399995, 11.021962040000062],
              [-74.87095632599994, 11.021951224000077],
              [-74.87094708899991, 11.021944545000053],
              [-74.87094689799994, 11.021944407000092],
              [-74.87094655099992, 11.021944156000075],
              [-74.87094092599995, 11.021940088000065],
              [-74.87093964799993, 11.02193916400006 ],
              [-74.87093738499993, 11.02193752800008 ],
              [-74.8709270629999 , 11.021930687000065],
              [-74.87090653299992, 11.021917079000048],
              [-74.87089137999993, 11.021907036000073],
              [-74.87089027999991, 11.021906387000058],
              [-74.87087796499992, 11.021899119000068],
              [-74.87086714499992, 11.021892733000072],
              [-74.87084419799993, 11.02187919000005 ],
              [-74.87081119599992, 11.021859714000072],
              [-74.87080634699993, 11.021856361000061],
              [-74.87080354699992, 11.021854425000072],
              [-74.87079817599994, 11.021850403000087],
              [-74.87079364899995, 11.02184701300007 ],
              [-74.87078949699992, 11.021843904000093],
              [-74.8707813719999 , 11.021837820000087],
              [-74.87075723799995, 11.021819748000041],
              [-74.87071970699992, 11.021791050000047],
              [-74.87064284299993, 11.021733419000043],
              [-74.87063864099991, 11.021730269000045],
              [-74.87063743999994, 11.02172936800008 ],
              [-74.87063677599991, 11.02172887000006 ],
              [-74.87055650899993, 11.021671833000084],
              [-74.87052034099992, 11.021646670000052],
              [-74.87048544399994, 11.021622390000061],
              [-74.87048277899993, 11.021620536000057],
              [-74.87047631899992, 11.021618857000078],
              [-74.87047517199994, 11.02161796300004 ],
              [-74.87046612099994, 11.021610905000045],
              [-74.8704421779999 , 11.021592235000071],
              [-74.87042240599993, 11.02157691900004 ],
              [-74.87041130599994, 11.021568320000085],
              [-74.87040501899992, 11.021563943000046],
              [-74.8703516889999 , 11.021526819000087],
              [-74.87031774699994, 11.021503191000079],
              [-74.87031401599995, 11.021500593000042],
              [-74.87031082099992, 11.021498369000085],
              [-74.87030476799993, 11.021496576000061],
              [-74.87029994199992, 11.021493406000047],
              [-74.870298        , 11.02149213000007 ],
              [-74.87021164399994, 11.021435416000088],
              [-74.8701932589999 , 11.021423342000048],
              [-74.87019249899993, 11.021422843000039],
              [-74.87019012499991, 11.021421284000041],
              [-74.8701879539999 , 11.021419858000058],
              [-74.87018778099991, 11.021419428000058],
              [-74.87018650399995, 11.021418615000073],
              [-74.87017507699994, 11.021411344000057],
              [-74.87014994999993, 11.021395356000085],
              [-74.87014290699994, 11.021390875000066],
              [-74.87013731999991, 11.021387320000088],
              [-74.87012137199991, 11.021377173000076],
              [-74.87012114399994, 11.021377026000039],
              [-74.87010500799994, 11.021366759000045],
              [-74.87007857399993, 11.02134993900006 ],
              [-74.87005487899995, 11.02133486200006 ],
              [-74.8700288359999 , 11.021318290000067],
              [-74.87002381999991, 11.021315099000049],
              [-74.87002090999994, 11.021313216000067],
              [-74.87001549799993, 11.02130971300005 ],
              [-74.87001540599994, 11.021309655000039],
              [-74.87000295399992, 11.021301823000044],
              [-74.86999735099994, 11.02129829900008 ],
              [-74.86993104599992, 11.021411729000079],
              [-74.86989656799994, 11.021470711000063],
              [-74.86989572499994, 11.021472153000047],
              [-74.86983603199991, 11.021574272000066],
              [-74.86980975499995, 11.021619224000062],
              [-74.86978269999992, 11.02166550700008 ],
              [-74.86969066699993, 11.021822949000068],
              [-74.8696803119999 , 11.02184066500007 ],
              [-74.86966166899992, 11.021872558000041],
              [-74.8696607199999 , 11.021874181000044],
              [-74.86961847999993, 11.02192898900006 ],
              [-74.86959231299994, 11.021962941000083],
              [-74.86957358799992, 11.021987237000076],
              [-74.8695663009999 , 11.021996692000073],
              [-74.86953957999992, 11.022031363000053],
              [-74.86949364399993, 11.022090966000064],
              [-74.8694916959999 , 11.022091566000086],
              [-74.86948741099991, 11.022092886000053],
              [-74.86948308599995, 11.022094219000053],
              [-74.8694593699999 , 11.022125955000092],
              [-74.86945413799992, 11.022132956000064],
              [-74.86943295399993, 11.022161305000054],
              [-74.86942917999994, 11.022166355000081],
              [-74.86937367999991, 11.022240637000039],
              [-74.86936934699992, 11.022254579000048],
              [-74.86931567799991, 11.022325785000078],
              [-74.86926058199992, 11.022398872000053],
              [-74.86924692499991, 11.022411866000084],
              [-74.8692370139999 , 11.022425464000037],
              [-74.86919194899991, 11.022487267000088],
              [-74.86914787099994, 11.022547735000046],
              [-74.86913794399993, 11.022561339000049],
              [-74.86912837299991, 11.022572604000061],
              [-74.86912811499991, 11.022572908000086],
              [-74.86913601499992, 11.022578416000044],
              [-74.86914186299992, 11.022582493000073],
              [-74.86917630799991, 11.022606507000091],
              [-74.86918516199995, 11.022612680000066],
              [-74.86897449799994, 11.022905627000057],
              [-74.86897243999994, 11.022910678000073],
              [-74.86897007399995, 11.02291415600007 ],
              [-74.86896552899992, 11.022910882000076],
              [-74.86889262599993, 11.02285837100004 ],
              [-74.86888437999994, 11.022860956000045],
              [-74.86887988599995, 11.022862364000048],
              [-74.8688521919999 , 11.022884980000072],
              [-74.86881347799994, 11.022915539000053],
              [-74.86879809499993, 11.022927664000065],
              [-74.86878843799991, 11.022935276000055],
              [-74.8687553229999 , 11.022961050000049],
              [-74.86874883399992, 11.022966101000065],
              [-74.86874423499995, 11.022969680000074],
              [-74.86867560399992, 11.023022873000059],
              [-74.86867176299995, 11.023029695000048],
              [-74.8686660809999 , 11.023039788000062],
              [-74.8686565399999 , 11.023056734000079],
              [-74.86864100899993, 11.023070054000073],
              [-74.86863937099992, 11.023071432000052],
              [-74.86859158999994, 11.02311162400008 ],
              [-74.86857962599993, 11.023121238000044],
              [-74.86854028099992, 11.023154544000079],
              [-74.86851166699995, 11.023178766000058],
              [-74.86849814399994, 11.023189038000055],
              [-74.86848461099993, 11.02319931900007 ],
              [-74.86846300899992, 11.023219322000045],
              [-74.8684587379999 , 11.023223277000056],
              [-74.86841615499992, 11.023262708000061],
              [-74.86836597899992, 11.023309159000064],
              [-74.86836289899992, 11.023312011000087],
              [-74.86836168399992, 11.02331313500008 ],
              [-74.86835893499995, 11.023315680000053],
              [-74.86833988899991, 11.023330205000093],
              [-74.8683218999999 , 11.023343923000084],
              [-74.86831558099993, 11.023348742000053],
              [-74.86831300499995, 11.023351206000086],
              [-74.86831237199993, 11.023351811000055],
              [-74.86831080499991, 11.023353310000061],
              [-74.86829149499994, 11.023371779000058],
              [-74.86826376399995, 11.02339830200009 ],
              [-74.8682613869999 , 11.023400576000085],
              [-74.86824652899992, 11.023414787000092],
              [-74.86821746599992, 11.023433839000063],
              [-74.86818974199991, 11.023459630000048],
              [-74.86817815299992, 11.023470410000073],
              [-74.86817232899995, 11.023475826000038],
              [-74.86808781099995, 11.023554434000062],
              [-74.86808030799995, 11.023561414000085],
              [-74.86807138099994, 11.023569716000054],
              [-74.86792911799995, 11.023702025000091],
              [-74.86791403099994, 11.023716057000058],
              [-74.86784305399993, 11.023740418000045],
              [-74.86781936699992, 11.023770489000071],
              [-74.8678128489999 , 11.023778763000053],
              [-74.86780309399995, 11.023791147000054],
              [-74.86775778799995, 11.023848662000091],
              [-74.86775682199993, 11.02384993600009 ],
              [-74.8677512669999 , 11.023857263000082],
              [-74.86771006299995, 11.023911652000038],
              [-74.86768632099995, 11.023942988000044],
              [-74.86766376599991, 11.02397275800007 ],
              [-74.86765895799994, 11.02398313000009 ],
              [-74.86765695399993, 11.023987453000075],
              [-74.86764640899992, 11.024010202000056],
              [-74.86758471799993, 11.024143287000072],
              [-74.86752321299991, 11.024251096000057],
              [-74.86748205399994, 11.024323242000037],
              [-74.8674705489999 , 11.024349019000056],
              [-74.86746069899993, 11.024371089000056],
              [-74.86740405099994, 11.024498011000048],
              [-74.8673899449999 , 11.024529616000052],
              [-74.86731062399991, 11.024601738000058],
              [-74.86725113699993, 11.024656466000067],
              [-74.8671693419999 , 11.024692120000054],
              [-74.86715833799991, 11.024696916000039],
              [-74.86703144099994, 11.024811904000046],
              [-74.8669947859999 , 11.024869299000045],
              [-74.86695929599995, 11.024924869000074],
              [-74.86690451299995, 11.025021265000078],
              [-74.86683437499994, 11.024899884000092],
              [-74.86661921199993, 11.02506530100004 ],
              [-74.86613409499995, 11.02548995400008 ],
              [-74.86604341799995, 11.025569329000064],
              [-74.86552924099993, 11.026019421000058],
              [-74.86480203399992, 11.024956322000037],
              [-74.86474594599991, 11.024874328000067],
              [-74.86472391799992, 11.024842125000077],
              [-74.86425680499991, 11.025688807000051],
              [-74.86366936299993, 11.026759882000079],
              [-74.86360623599995, 11.026884640000048],
              [-74.86355282399995, 11.02701899300007 ],
              [-74.8633731409999 , 11.027234927000052],
              [-74.8631108909999 , 11.02744127200009 ],
              [-74.8623532819999 , 11.028103486000077],
              [-74.86199390299993, 11.02842019700006 ],
              [-74.86182392699993, 11.028578551000066],
              [-74.86158389799994, 11.02876754600004 ],
              [-74.86151469299995, 11.02879992600009 ],
              [-74.86138429899995, 11.028837045000046],
              [-74.86128972299991, 11.028860689000055],
              [-74.8612309799999 , 11.02886648100008 ],
              [-74.86117970299995, 11.028896393000082],
              [-74.86097268999993, 11.029153374000089],
              [-74.8608418199999 , 11.029234276000068],
              [-74.86071570799993, 11.029308039000057],
              [-74.8602588519999 , 11.029515053000068],
              [-74.86005795299991, 11.02942968700006 ],
              [-74.85990986599995, 11.029840628000045],
              [-74.85967453699993, 11.030493662000083],
              [-74.85962806799995, 11.030633924000085],
              [-74.85956468299992, 11.030825248000042],
              [-74.85947192799995, 11.031105220000086],
              [-74.8593749609999 , 11.031397904000073],
              [-74.85937437099994, 11.031399686000043],
              [-74.85937270499994, 11.031404716000054],
              [-74.85930289899994, 11.03139133700006 ],
              [-74.85868577199994, 11.031243248000067],
              [-74.85827341399994, 11.031144296000036],
              [-74.85798919199993, 11.031070956000065],
              [-74.85764357199992, 11.030981772000075],
              [-74.8573249019999 , 11.03090470300009 ],
              [-74.85624675499992, 11.030643958000041],
              [-74.85616736799994, 11.03062510400008 ],
              [-74.85559200799992, 11.030488456000057],
              [-74.8552178889999 , 11.030399602000045],
              [-74.85518075599992, 11.030390783000087],
              [-74.8546858279999 , 11.030267051000067],
              [-74.85449108799992, 11.030217699000048],
              [-74.85440790699994, 11.030196619000037],
              [-74.8541443869999 , 11.030133179000075],
              [-74.85389394399994, 11.030072887000074],
              [-74.85377487499994, 11.030043589000059],
              [-74.85329051199994, 11.029924409000046],
              [-74.85303162699995, 11.029878723000081],
              [-74.85281455599994, 11.029854149000073],
              [-74.85262806999992, 11.02983303700006 ],
              [-74.85189330799994, 11.029786165000075],
              [-74.85152400099992, 11.029762606000077],
              [-74.8514440639999 , 11.029759548000072],
              [-74.8514359589999 , 11.029759238000054],
              [-74.85117564699993, 11.029749281000079],
              [-74.85103553199991, 11.029753136000068],
              [-74.8510031749999 , 11.029750926000077],
              [-74.85076228499992, 11.02976065200005 ],
              [-74.85051098499991, 11.029779949000044],
              [-74.85051079099992, 11.02977997000005 ],
              [-74.8505106259999 , 11.02977999400008 ],
              [-74.85026903699992, 11.02982374600009 ],
              [-74.8500258599999 , 11.02987508700005 ],
              [-74.84968910899994, 11.029954720000092],
              [-74.84963684599995, 11.029966740000077],
              [-74.84919812999993, 11.030079366000052],
              [-74.84897333599991, 11.030137075000084],
              [-74.84791527399994, 11.030412900000044],
              [-74.84703772899991, 11.03064513600009 ],
              [-74.84641492999992, 11.030820432000041],
              [-74.84641486699991, 11.030820450000078],
              [-74.84641479099992, 11.030820471000084],
              [-74.84604925199994, 11.030921608000085],
              [-74.84533263299994, 11.031101765000074],
              [-74.8450565409999 , 11.031190011000092],
              [-74.84483174799993, 11.031240196000056],
              [-74.84478439399993, 11.03125328300007 ],
              [-74.8445049849999 , 11.031322300000056],
              [-74.84431519899994, 11.031371579000051],
              [-74.84411469699995, 11.03142364100006 ],
              [-74.84406436699993, 11.031437791000087],
              [-74.84372542299991, 11.031533082000067],
              [-74.84358657999991, 11.031572117000053],
              [-74.8435506429999 , 11.031581676000087],
              [-74.84320485099994, 11.031673658000045],
              [-74.84285887099992, 11.03177535900005 ],
              [-74.84244769999992, 11.031877199000064],
              [-74.84197076099991, 11.032003602000088],
              [-74.84171196999995, 11.032086592000041],
              [-74.84133172799994, 11.03218368000006 ],
              [-74.84131674499992, 11.032188155000085],
              [-74.8411762639999 , 11.032223293000072],
              [-74.8407992089999 , 11.032335960000069],
              [-74.8403744389999 , 11.032446438000079],
              [-74.84025959599995, 11.032487314000036],
              [-74.84008946499995, 11.032552750000093],
              [-74.83994193799992, 11.03262413300007 ],
              [-74.83991761599992, 11.03263532200009 ],
              [-74.83979983599994, 11.032697638000059],
              [-74.83956093799992, 11.032836598000074],
              [-74.83944735799992, 11.032913285000063],
              [-74.83923642799994, 11.033076231000052],
              [-74.83918970099995, 11.033118458000047],
              [-74.8390171019999 , 11.033293770000057],
              [-74.83889278499993, 11.033420042000046],
              [-74.83882683099995, 11.03349372200006 ],
              [-74.83863323499992, 11.033692511000083],
              [-74.83834412999994, 11.033994703000076],
              [-74.83829523399993, 11.034041474000048],
              [-74.83817994799995, 11.034151747000067],
              [-74.8380800999999 , 11.034234274000084],
              [-74.83806335399993, 11.034248115000082],
              [-74.8379610369999 , 11.034319499000048],
              [-74.83783373599994, 11.034390883000071],
              [-74.83770971699994, 11.034449873000085],
              [-74.83766936399991, 11.034462052000038],
              [-74.83759657399992, 11.034484022000072],
              [-74.83751339299994, 11.034509127000092],
              [-74.83744797599991, 11.034528871000077],
              [-74.83727855899991, 11.034569798000064],
              [-74.83711294799991, 11.034585978000052],
              [-74.8367170059999 , 11.03458788100005 ],
              [-74.83656156299992, 11.034587873000078],
              [-74.83642542199993, 11.034591115000069],
              [-74.83582722599994, 11.034596825000051],
              [-74.83580468499991, 11.034596408000084],
              [-74.83567359199992, 11.03459928400008 ],
              [-74.83552998499994, 11.034600040000043],
              [-74.83541803799994, 11.034607850000043],
              [-74.83527146299991, 11.03462688600007 ],
              [-74.83508300999995, 11.034678282000073],
              [-74.83500115599992, 11.03470302900007 ],
              [-74.83488967399995, 11.034747336000066],
              [-74.83414009899991, 11.035143531000074],
              [-74.83410729799994, 11.035161766000044],
              [-74.83405332099994, 11.035191774000054],
              [-74.83370814699992, 11.035373991000085],
              [-74.83338786299993, 11.035543068000038],
              [-74.8328927359999 , 11.035817587000054],
              [-74.83224585799991, 11.036179384000093],
              [-74.83224266699995, 11.036181169000088],
              [-74.83223395099992, 11.036185920000037],
              [-74.83218780499993, 11.036211070000093],
              [-74.83171462899992, 11.036468960000093],
              [-74.83168820399993, 11.036484060000078],
              [-74.83085234799995, 11.036945213000081],
              [-74.83058941799993, 11.03707251500009 ],
              [-74.8302854069999 , 11.037227926000071],
              [-74.82938597099991, 11.037720475000071],
              [-74.8289092139999 , 11.03797319000006 ],
              [-74.8285671669999 , 11.038154623000082],
              [-74.82842875299991, 11.038232665000066],
              [-74.82828757999994, 11.03831226300008 ],
              [-74.8280567889999 , 11.038448095000092],
              [-74.8280565199999 , 11.03844825300007 ],
              [-74.82781092099992, 11.03859280000006 ],
              [-74.82777318599994, 11.038609549000057],
              [-74.82758164199993, 11.03869456600006 ],
              [-74.82747996699993, 11.038739694000071],
              [-74.82737917599991, 11.038784430000078],
              [-74.82729861999991, 11.038690759000076],
              [-74.82713435399995, 11.038499752000064],
              [-74.82710913999995, 11.038452927000037],
              [-74.82698861199992, 11.038229089000083],
              [-74.82694994499991, 11.038080372000081],
              [-74.8269434739999 , 11.038063900000054],
              [-74.82693997699994, 11.038054998000064],
              [-74.82694338699991, 11.038029495000046],
              [-74.82697573099995, 11.03778759000005 ],
              [-74.82702855499991, 11.037511573000074],
              [-74.82712373299995, 11.037259350000056],
              [-74.82726163199993, 11.037001957000086],
              [-74.82770249799995, 11.036551514000053],
              [-74.8281231069999 , 11.036152901000037],
              [-74.8286608649999 , 11.035600866000038],
              [-74.82912247999991, 11.035129732000087],
              [-74.82965072099995, 11.034534867000048],
              [-74.83007902399993, 11.03405897500005 ],
              [-74.83039787099995, 11.033644949000063],
              [-74.83073575499992, 11.033209507000038],
              [-74.83111642099993, 11.03268207800005 ],
              [-74.83113074499994, 11.032662231000074],
              [-74.8315876019999 , 11.031965049000064],
              [-74.83195641799995, 11.031353528000068],
              [-74.83234664999992, 11.03065872500008 ],
              [-74.83237763599993, 11.030599315000075],
              [-74.8324176829999 , 11.030522533000067],
              [-74.83266311799991, 11.030051962000073],
              [-74.83291772099994, 11.029518963000044],
              [-74.83319611699994, 11.02884795500006 ],
              [-74.83320653399994, 11.028822441000045],
              [-74.83331333199993, 11.028560872000071],
              [-74.83364107699992, 11.027758162000055],
              [-74.83365282099993, 11.027729737000072],
              [-74.83401465199995, 11.02685396700008 ],
              [-74.83449054399995, 11.025680893000072],
              [-74.83499023099995, 11.024438814000064],
              [-74.83550337699995, 11.023202069000092],
              [-74.8355258759999 , 11.023153925000088],
              [-74.83659714299995, 11.020469177000052],
              [-74.8367940899999 , 11.019975599000077],
              [-74.8370600849999 , 11.019311473000073],
              [-74.8370679649999 , 11.01929179800004 ],
              [-74.83711446599995, 11.019175699000073],
              [-74.83718033199995, 11.019011248000083],
              [-74.83813984499994, 11.01661555000004 ],
              [-74.83834355899995, 11.016106921000073],
              [-74.83839867599994, 11.015969305000056],
              [-74.83847750599995, 11.01576932800009 ],
              [-74.83850617199994, 11.015696607000052],
              [-74.83852257699994, 11.015654991000076],
              [-74.83854671499995, 11.015593759000069],
              [-74.83854828699992, 11.015589770000076],
              [-74.83899550099994, 11.01445526100008 ],
              [-74.83921135399993, 11.013915101000066],
              [-74.83926076599994, 11.013861944000041],
              [-74.83926447499994, 11.01385795400006 ],
              [-74.83963127399994, 11.013463353000077],
              [-74.83966970999995, 11.013433661000079],
              [-74.83983560099995, 11.013312888000087],
              [-74.84020914199994, 11.013132340000084],
              [-74.84026915299995, 11.01311250100008 ],
              [-74.84123963199994, 11.012747980000086],
              [-74.84133700899991, 11.012708774000089],
              [-74.84150069499992, 11.012652770000045],
              [-74.84158490699991, 11.012623957000073],
              [-74.84163922199991, 11.012620199000082],
              [-74.84177180499995, 11.012611027000048],
              [-74.84208887699992, 11.012649655000075],
              [-74.84229739599994, 11.012695546000089],
              [-74.84245864499991, 11.01273103300008 ],
              [-74.84249505799994, 11.012737635000065],
              [-74.84258951099991, 11.012754761000053],
              [-74.84281419899992, 11.012876805000076],
              [-74.8437065089999 , 11.01336148200005 ],
              [-74.8443557299999 , 11.013705549000065],
              [-74.84491337199995, 11.01400108200005 ],
              [-74.84602124899993, 11.01459880200008 ],
              [-74.84705094399993, 11.015150904000052],
              [-74.84762595699993, 11.015459215000078],
              [-74.84854728499994, 11.015957950000086],
              [-74.84888710999991, 11.01612932800009 ],
              [-74.84898891199992, 11.016180668000061],
              [-74.84912542199993, 11.016240541000059],
              [-74.84963993299993, 11.01646620300005 ],
              [-74.84986818399994, 11.016546692000077],
              [-74.85000205699993, 11.016593900000089],
              [-74.85036328899992, 11.01672128100006 ],
              [-74.85044773099992, 11.016743671000086],
              [-74.85111710199993, 11.01692115600008 ],
              [-74.85113554999992, 11.016924535000044],
              [-74.8517509909999 , 11.017037274000074],
              [-74.85187914499994, 11.017053607000037],
              [-74.85206135399994, 11.017076830000065],
              [-74.85233348299994, 11.017111513000089],
              [-74.85258140499991, 11.017128730000081],
              [-74.85267936299994, 11.017135532000054],
              [-74.85288171099995, 11.017149584000038],
              [-74.85327432199995, 11.017158388000041],
              [-74.85354557999995, 11.017158388000041],
              [-74.85388108399991, 11.01714054200005 ],
              [-74.85418446499995, 11.017115558000057],
              [-74.85450926199991, 11.017083435000075],
              [-74.85649016299993, 11.016833592000069],
              [-74.85663456399993, 11.01681547600009 ],
              [-74.85710066899992, 11.016757001000087],
              [-74.85845321799991, 11.016587318000063],
              [-74.85939535099993, 11.016470153000057],
              [-74.8598111739999 , 11.016418441000042],
              [-74.86054833399993, 11.016326767000066],
              [-74.86214733199995, 11.016123323000045],
              [-74.86395334299993, 11.015898464000088],
              [-74.86628545199994, 11.01560293500006 ],
              [-74.86628065199994, 11.015575096000077],
              [-74.86627831399994, 11.01556153200005 ],
              [-74.86627257299995, 11.015502801000082],
              [-74.8662708629999 , 11.015485307000063],
              [-74.86625975399994, 11.015371651000066],
              [-74.86626657199992, 11.015300067000055],
              [-74.86627117599994, 11.015251726000088],
              [-74.86629116299991, 11.015208896000047],
              [-74.86636825799991, 11.01513037400008 ],
              [-74.86638340199994, 11.015117468000085],
              [-74.8666292119999 , 11.014907985000093],
              [-74.86665807599991, 11.014883386000065],
              [-74.86682796999992, 11.01476774400004 ],
              [-74.86689935299995, 11.014710637000064],
              [-74.86693325199991, 11.014677409000058],
              [-74.8670435489999 , 11.014569297000037],
              [-74.86715465799995, 11.014454715000056],
              [-74.86718060599992, 11.014427957000066],
              [-74.86731766299994, 11.014318026000069],
              [-74.8675789269999 , 11.014128145000086],
              [-74.8677352169999 , 11.014033104000077],
              [-74.86779022399992, 11.013999654000088],
              [-74.86805067899991, 11.013850059000049],
              [-74.8683864329999 , 11.013664568000081],
              [-74.86859772999992, 11.013523704000079],
              [-74.86865673999995, 11.013474211000073],
              [-74.86874811199993, 11.013373322000064],
              [-74.86881627999992, 11.013257434000082],
              [-74.86883836599992, 11.013232757000083],
              [-74.86892993899994, 11.01313043500005 ],
              [-74.86899337999995, 11.013059547000069],
              [-74.86902465499992, 11.012991447000047],
              [-74.86915283699994, 11.012712331000046],
              [-74.86916341799991, 11.012684406000062],
              [-74.86932300699993, 11.012263225000083],
              [-74.8693331309999 , 11.01223650500009 ],
              [-74.86940118199993, 11.01212068700005 ],
              [-74.8697232479999 , 11.011572555000043],
              [-74.86975185099993, 11.01152387400009 ],
              [-74.8698272339999 , 11.011464103000037],
              [-74.86983113899993, 11.011461006000047],
              [-74.86990591199992, 11.01140171700007 ],
              [-74.8700709549999 , 11.011270852000052],
              [-74.87009609599994, 11.01125091700004 ],
              [-74.87024394899993, 11.011092079000036],
              [-74.87028776999995, 11.01103250400007 ],
              [-74.87030123599993, 11.011014198000055],
              [-74.87034960599993, 11.010936322000077],
              [-74.87040901899991, 11.010840667000082],
              [-74.8704518909999 , 11.010771643000055],
              [-74.87055177499991, 11.010599315000093],
              [-74.87069958399991, 11.010344304000057],
              [-74.87071394799995, 11.010319520000053],
              [-74.87094711399993, 11.009891756000059],
              [-74.8709668969999 , 11.009855462000075],
              [-74.87119594699993, 11.009508064000045],
              [-74.87126830799991, 11.009409216000051],
              [-74.87137696399992, 11.00930429400006 ],
              [-74.87144332199995, 11.009223400000053],
              [-74.87150368199991, 11.009163449000084],
              [-74.87162431399992, 11.009010661000048],
              [-74.8716437459999 , 11.009034926000083],
              [-74.87164632999992, 11.009036523000077],
              [-74.87164831199993, 11.009037749000072],
              [-74.8735593959999 , 11.01021979300009 ],
              [-74.87371086399992, 11.010332933000086],
              [-74.87435795599993, 11.010816280000085],
              [-74.87435803299991, 11.010816337000051],
              [-74.87459639399992, 11.010994382000092],
              [-74.87459427899995, 11.01099660400007 ],
              [-74.87461627899995, 11.011013392000052],
              [-74.87486739699995, 11.01074707400005 ],
              [-74.8748909219999 , 11.010722692000058],
              [-74.87502594699993, 11.010582747000058],
              [-74.87671497399992, 11.008832186000063],
              [-74.8784164619999 , 11.007068640000057],
              [-74.87934553899993, 11.006105675000072],
              [-74.87953143099992, 11.005913003000046],
              [-74.87963545399992, 11.005805186000089],
              [-74.87986246999992, 11.00556989000006 ],
              [-74.88333232299993, 11.00197348100005 ],
              [-74.87656071899994, 10.997027101000072],
              [-74.87630637799992, 10.99684131500004 ],
              [-74.8763932729999 , 10.996781866000049],
              [-74.8765432049999 , 10.996638044000065],
              [-74.87667547699994, 10.996372217000044],
              [-74.87675043299993, 10.99622405100007 ],
              [-74.87675480799993, 10.99600182000006 ],
              [-74.87669745799991, 10.99587110300007 ],
              [-74.87665774699991, 10.995744741000067],
              [-74.8766004009999 , 10.995666314000061],
              [-74.87652541199992, 10.995574815000055],
              [-74.87643278399992, 10.995500753000044],
              [-74.87623871999995, 10.995435414000042],
              [-74.87604024699993, 10.995400582000059],
              [-74.87587265799993, 10.995418036000046],
              [-74.87577563499991, 10.995457268000052],
              [-74.87575433099994, 10.995488034000061],
              [-74.87571831299994, 10.995540067000093],
              [-74.87566539599993, 10.99559236400006 ],
              [-74.87560365299993, 10.995609802000047],
              [-74.87553308299994, 10.995574951000037],
              [-74.8754316369999 , 10.995522676000064],
              [-74.87532136999994, 10.995461687000045],
              [-74.87522433299995, 10.995422481000048],
              [-74.87519345299995, 10.995339693000062],
              [-74.87529046899994, 10.995252528000037],
              [-74.87537426199992, 10.99523073000006 ],
              [-74.87543159499995, 10.995217650000086],
              [-74.87543343999994, 10.99521244300007 ],
              [-74.87546245599992, 10.995130495000069],
              [-74.8754536269999 , 10.995069491000038],
              [-74.87541103799992, 10.995039447000067],
              [-74.87539187599992, 10.995025923000071],
              [-74.87535217099992, 10.994943138000053],
              [-74.8754271329999 , 10.994842905000041],
              [-74.87554619499991, 10.994716521000043],
              [-74.87579756299994, 10.994568332000085],
              [-74.87588624499995, 10.994552389000091],
              [-74.87594309699995, 10.994542170000045],
              [-74.87609745299994, 10.994472427000062],
              [-74.87623416499991, 10.994415761000084],
              [-74.87626943899994, 10.99435475100006 ],
              [-74.87634440799991, 10.994302453000046],
              [-74.8764326029999 , 10.994245787000068],
              [-74.87652522199994, 10.99423706400006 ],
              [-74.87659579299992, 10.994271911000055],
              [-74.87662225799994, 10.994293695000067],
              [-74.87670604999994, 10.994267540000067],
              [-74.8768030569999 , 10.994132444000059],
              [-74.87688684499994, 10.99406706800005 ],
              [-74.87696622099992, 10.994001697000044],
              [-74.87700590799994, 10.993958115000055],
              [-74.87701471499992, 10.993866604000061],
              [-74.8769882439999 , 10.993801250000047],
              [-74.87690002699992, 10.99372718300009 ],
              [-74.87687355399993, 10.993648749000045],
              [-74.87683825699992, 10.993548535000059],
              [-74.87678533299993, 10.993557255000042],
              [-74.87671475799993, 10.993478830000072],
              [-74.8766441869999 , 10.993435265000073],
              [-74.87655156999995, 10.99343527700006 ],
              [-74.8764698679999 , 10.993452947000037],
              [-74.87641044399993, 10.99346580300005 ],
              [-74.87632664599994, 10.993452741000056],
              [-74.87623843699993, 10.993430963000037],
              [-74.87621637299992, 10.99334381600005 ],
              [-74.87626046899993, 10.99329152100006 ],
              [-74.87626046799994, 10.99328834000005 ],
              [-74.87626045999991, 10.99323051400006 ],
              [-74.87620752499993, 10.993152087000055],
              [-74.87617483799994, 10.993123841000056],
              [-74.87613694899994, 10.993091090000064],
              [-74.87606195799992, 10.99298652300007 ],
              [-74.87602226099995, 10.992929881000066],
              [-74.87595608899994, 10.992820952000045],
              [-74.87593403199992, 10.99276866300005 ],
              [-74.87592078599994, 10.99265973000007 ],
              [-74.87592436299991, 10.992650888000071],
              [-74.87584434799993, 10.992482667000047],
              [-74.87587915299991, 10.99205627300006 ],
              [-74.87595372599992, 10.991788242000041],
              [-74.8759815969999 , 10.991772499000092],
              [-74.87603204099992, 10.99171711100007 ],
              [-74.87609929099995, 10.991622952000057],
              [-74.87613291499991, 10.991562027000043],
              [-74.8762113709999 , 10.991440173000058],
              [-74.87627861899995, 10.991323861000069],
              [-74.8763346529999 , 10.991202016000045],
              [-74.87641870899995, 10.991019240000071],
              [-74.87646913499992, 10.990875239000047],
              [-74.87651395199993, 10.990686933000063],
              [-74.87647469099994, 10.990515254000059],
              [-74.87641861599991, 10.99036019500005 ],
              [-74.87635887099992, 10.990305397000043],
              [-74.87634013099995, 10.99028820600006 ],
              [-74.87628406899995, 10.99022175500005 ],
              [-74.87620558999993, 10.990188536000062],
              [-74.87603182599992, 10.99021625100005 ],
              [-74.87590852699992, 10.990310417000046],
              [-74.87574599099992, 10.99044335800005 ],
              [-74.87563949799994, 10.990498754000043],
              [-74.87547695199993, 10.990543082000045],
              [-74.87530879899992, 10.990592951000053],
              [-74.87512944399992, 10.990698200000054],
              [-74.87498371499993, 10.990759138000044],
              [-74.87486600199992, 10.990764693000074],
              [-74.87477631599995, 10.99074255100004 ],
              [-74.87466420299995, 10.99068718600006 ],
              [-74.87463616799994, 10.990626268000085],
              [-74.87454646299994, 10.990476749000038],
              [-74.87447358799994, 10.99043245100006 ],
              [-74.87446706899993, 10.990426373000048],
              [-74.87437267999991, 10.990338314000041],
              [-74.87433903699991, 10.990249709000068],
              [-74.8743390269999 , 10.990177710000069],
              [-74.87439506099992, 10.990039249000063],
              [-74.87448472599993, 10.98992293300006 ],
              [-74.87450876699995, 10.989849015000061],
              [-74.87453515699991, 10.98976785700006 ],
              [-74.87459119199991, 10.98966262500005 ],
              [-74.87468646499991, 10.989513079000062],
              [-74.8747593189999 , 10.989407842000048],
              [-74.87484338899992, 10.98934137200007 ],
              [-74.87489942899992, 10.989274906000048],
              [-74.8749330469999 , 10.989158600000053],
              [-74.87496667099992, 10.989092137000057],
              [-74.87500590099995, 10.989042285000039],
              [-74.87504512699991, 10.988986899000054],
              [-74.87505632099993, 10.988842902000044],
              [-74.87504509999991, 10.98878198400007 ],
              [-74.87499463699993, 10.988654612000062],
              [-74.8749006139999 , 10.98855059400006 ],
              [-74.8748544849999 , 10.988499559000047],
              [-74.87471433499991, 10.988366661000043],
              [-74.87447890599992, 10.988305771000057],
              [-74.87437799799994, 10.988206097000045],
              [-74.87428730699992, 10.98818619800005 ],
              [-74.87417620299993, 10.988161818000037],
              [-74.87399683299992, 10.988145230000043],
              [-74.87390152899991, 10.988051090000056],
              [-74.87382864599994, 10.987923719000037],
              [-74.87384543899992, 10.98775203200006 ],
              [-74.87387905399993, 10.98763572900009 ],
              [-74.87392948399992, 10.987502802000051],
              [-74.87412004299995, 10.987358782000058],
              [-74.8741329579999 , 10.987356961000046],
              [-74.87435545799991, 10.98732552100006 ],
              [-74.87445635599994, 10.98736427600005 ],
              [-74.8748431109999 , 10.98731438100009 ],
              [-74.87513458399991, 10.987308805000055],
              [-74.87534196999991, 10.987253395000039],
              [-74.87549890999992, 10.987203531000091],
              [-74.87564463399991, 10.987114898000073],
              [-74.8758071769999 , 10.987053957000057],
              [-74.87594168699991, 10.986970864000057],
              [-74.87600894499991, 10.98690993200006 ],
              [-74.8760930059999 , 10.986788079000064],
              [-74.8761322279999 , 10.986682848000044],
              [-74.87616133199992, 10.986555764000059],
              [-74.87620576199993, 10.986531219000085],
              [-74.87647459999994, 10.986228542000049],
              [-74.87661793799992, 10.98605053600005 ],
              [-74.87672532599993, 10.98587262500007 ],
              [-74.8767606529999 , 10.985641617000056],
              [-74.87659847399993, 10.985499945000072],
              [-74.87654434899991, 10.985429040000042],
              [-74.87647215099992, 10.98532265600005 ],
              [-74.87654377099994, 10.985215887000038],
              [-74.87659721699993, 10.985038122000049],
              [-74.87684871199991, 10.984966403000044],
              [-74.87711827499993, 10.984930164000048],
              [-74.87722462199991, 10.984811315000059],
              [-74.8772317669999 , 10.98481601800006 ],
              [-74.87732416799992, 10.984857504000047],
              [-74.8774165609999 , 10.984840890000044],
              [-74.87741654599995, 10.984741300000053],
              [-74.87740813499994, 10.984658309000054],
              [-74.87738291799991, 10.984550423000087],
              [-74.87736611299994, 10.984492332000059],
              [-74.87745010599991, 10.98447572300006 ],
              [-74.87751730399992, 10.984484012000053],
              [-74.87755088199992, 10.984367818000067],
              [-74.87755086899995, 10.984276525000041],
              [-74.87755086199991, 10.984210130000065],
              [-74.87760965299992, 10.984168626000042],
              [-74.8776936459999 , 10.984152015000063],
              [-74.87766001999995, 10.983986038000069],
              [-74.87764321199995, 10.983903049000048],
              [-74.87771879199994, 10.983795150000049],
              [-74.87786998199994, 10.983786827000074],
              [-74.8779623769999 , 10.983786812000062],
              [-74.87810515099994, 10.983662304000063],
              [-74.87819754199995, 10.983637394000084],
              [-74.87834871599995, 10.983521181000071],
              [-74.87847469899992, 10.983454770000037],
              [-74.8784998829999 , 10.98338007600006 ],
              [-74.87842428099992, 10.983338588000038],
              [-74.87842427999993, 10.983305391000044],
              [-74.87843265799995, 10.98316430400007 ],
              [-74.87851664099992, 10.983081299000048],
              [-74.87859222399993, 10.982998297000051],
              [-74.87872660599993, 10.982956779000062],
              [-74.87890298899993, 10.98288206300009 ],
              [-74.87900378299992, 10.982882050000057],
              [-74.87912136799991, 10.982832233000067],
              [-74.87930614999993, 10.982782412000063],
              [-74.87945733799995, 10.982765792000066],
              [-74.87955812899992, 10.982749177000073],
              [-74.8796589339999 , 10.982815555000059],
              [-74.87977652599994, 10.98283213700006 ],
              [-74.87991091099991, 10.982765722000067],
              [-74.8799444839999 , 10.982599736000054],
              [-74.87985208399994, 10.982566552000037],
              [-74.87981007399992, 10.982483564000063],
              [-74.87986045399992, 10.982383970000058],
              [-74.87982683699994, 10.982267782000065],
              [-74.8797596419999 , 10.98225119600005 ],
              [-74.87965883999993, 10.982201416000066],
              [-74.8796840249999 , 10.982110122000051],
              [-74.87974279999992, 10.98198562500005 ],
              [-74.87974278699994, 10.981886035000059],
              [-74.87979317599991, 10.981836230000056],
              [-74.87986876699995, 10.981827920000057],
              [-74.8800367369999 , 10.981678510000052],
              [-74.88013751499994, 10.981595502000062],
              [-74.88017951899991, 10.98162039500005 ],
              [-74.88032229299995, 10.981512480000049],
              [-74.88041469299992, 10.981553964000057],
              [-74.8804734979999 , 10.981636946000037],
              [-74.8805575049999 , 10.981686728000057],
              [-74.88075908199994, 10.981620303000057],
              [-74.88081787399994, 10.98159540100005 ],
              [-74.88096064899992, 10.98149578500005 ],
              [-74.88108662399992, 10.981412772000056],
              [-74.88119580699993, 10.981321466000054],
              [-74.88128819199994, 10.981271656000047],
              [-74.88138059499994, 10.981321440000045],
              [-74.88148140599992, 10.981437610000057],
              [-74.88157380399991, 10.981454195000083],
              [-74.88175019199991, 10.981454167000038],
              [-74.88185096099994, 10.98129646600006 ],
              [-74.88191815999994, 10.981329651000067],
              [-74.88197695999992, 10.981371142000057],
              [-74.88205255699995, 10.981354531000079],
              [-74.8821449379999 , 10.98127982200009 ],
              [-74.88221577199994, 10.981186473000037],
              [-74.8822348519999 , 10.981186424000043],
              [-74.88225410199993, 10.981105523000053],
              [-74.88228768999994, 10.981030825000062],
              [-74.88234647199994, 10.980947823000065],
              [-74.88241366699992, 10.980939515000046],
              [-74.88247247099991, 10.980989298000054],
              [-74.88259006299995, 10.980989282000053],
              [-74.8826908559999 , 10.980989266000051],
              [-74.88280844199994, 10.980947749000052],
              [-74.88294281599991, 10.980848137000066],
              [-74.88301000999991, 10.980839828000057],
              [-74.88311080199992, 10.980823213000065],
              [-74.8831115129999 , 10.980822506000038],
              [-74.88321157799993, 10.980723605000037],
              [-74.88320316399995, 10.980632315000037],
              [-74.88310323399992, 10.980590760000041],
              [-74.88304356399993, 10.98056594700006 ],
              [-74.8828923559999 , 10.980482978000055],
              [-74.88280834599993, 10.980358505000083],
              [-74.88282512099994, 10.980242314000066],
              [-74.88284190599995, 10.980159339000068],
              [-74.88276370199992, 10.980110186000047],
              [-74.88271285699994, 10.980064148000054],
              [-74.88264506099995, 10.979988812000045],
              [-74.88260691999994, 10.979905101000043],
              [-74.88250047999992, 10.979832629000043],
              [-74.88244517999993, 10.979800609000051],
              [-74.88238415599994, 10.97974786800006 ],
              [-74.88235218499995, 10.979691246000073],
              [-74.8823288449999 , 10.979649908000056],
              [-74.88227353499991, 10.979568906000054],
              [-74.88224301999992, 10.979510506000054],
              [-74.88220296399993, 10.97943327300004 ],
              [-74.88216481799992, 10.979371103000062],
              [-74.88214002899991, 10.97933531600006 ],
              [-74.88214001499995, 10.97926749000004 ],
              [-74.88219338999994, 10.97918270200006 ],
              [-74.8822612269999 , 10.979124249000051],
              [-74.88234209599995, 10.979054568000038],
              [-74.8823756299999 , 10.979016774000058],
              [-74.88243422799991, 10.97905535800004 ],
              [-74.88245836599992, 10.979062807000048],
              [-74.88249672899991, 10.979074646000072],
              [-74.88257875799991, 10.979074633000039],
              [-74.88264124799991, 10.979024450000054],
              [-74.88267248699992, 10.978966550000052],
              [-74.88272325899993, 10.978916370000093],
              [-74.88279747599995, 10.978920216000063],
              [-74.88293420199994, 10.978993527000057],
              [-74.88300921699994, 10.97900648700005 ],
              [-74.88309045199992, 10.979020518000084],
              [-74.88311389299992, 10.97904753000006 ],
              [-74.88320373399995, 10.979051379000055],
              [-74.88332091899991, 10.979055216000063],
              [-74.88343419199992, 10.979032041000039],
              [-74.88353965499994, 10.979032025000038],
              [-74.88369199599992, 10.979028142000061],
              [-74.88377012099994, 10.979047428000058],
              [-74.8838130819999 , 10.979001107000045],
              [-74.88384821799991, 10.97891233100006 ],
              [-74.88384758599994, 10.97889856200004 ],
              [-74.88384429899992, 10.978827421000062],
              [-74.88382760499991, 10.978807630000063],
              [-74.88378569799994, 10.978757959000063],
              [-74.88369584799995, 10.978715517000069],
              [-74.88359429099995, 10.97871553300007 ],
              [-74.88348100399992, 10.978680818000043],
              [-74.88337943999994, 10.97862679800005 ],
              [-74.88330130199995, 10.978534185000058],
              [-74.88321535599994, 10.978460863000066],
              [-74.88308254099991, 10.978422292000062],
              [-74.88298097299992, 10.978368272000068],
              [-74.88292627899995, 10.97831424900005 ],
              [-74.8828364119999 , 10.978156023000054],
              [-74.88279342999994, 10.978078838000044],
              [-74.88278559999992, 10.977951476000044],
              [-74.88275043499993, 10.97789358700004 ],
              [-74.88266058599993, 10.977843429000075],
              [-74.8824808899999 , 10.977746971000045],
              [-74.88239884899991, 10.97766978900006 ],
              [-74.88230899599995, 10.977604193000047],
              [-74.88221914199994, 10.97753087500007 ],
              [-74.88219569599994, 10.977461408000067],
              [-74.88219958499991, 10.977345620000051],
              [-74.88219956799992, 10.977237553000066],
              [-74.88218782699994, 10.977094751000038],
              [-74.88219171599991, 10.976994403000049],
              [-74.88219170299993, 10.976913351000064],
              [-74.88221512399991, 10.976813001000039],
              [-74.88224245299995, 10.976731948000065],
              [-74.8822631299999 , 10.97671508700006 ],
              [-74.88229725299993, 10.976578657000061],
              [-74.88230490099994, 10.97642703200006 ],
              [-74.88230097699994, 10.976311249000048],
              [-74.88230095299991, 10.976160725000057],
              [-74.88230093599992, 10.976056519000053],
              [-74.88236731399991, 10.975896336000062],
              [-74.8823673039999 , 10.975834582000061],
              [-74.88237119999991, 10.975776693000057],
              [-74.8823868149999 , 10.975718794000045],
              [-74.88241494399995, 10.975677093000058],
              [-74.88241805299992, 10.975672477000046],
              [-74.88245711299993, 10.975649312000087],
              [-74.88244465699995, 10.975554006000038],
              [-74.88243324099994, 10.97546667100005 ],
              [-74.88243323699993, 10.97543121800004 ],
              [-74.8824350239999 , 10.975390448000041],
              [-74.88244398799992, 10.975349677000054],
              [-74.88246012699994, 10.975303585000063],
              [-74.8824744659999 , 10.975245083000061],
              [-74.88249240199991, 10.975197217000073],
              [-74.88252647099995, 10.975101488000064],
              [-74.8825515719999 , 10.975018171000045],
              [-74.88256233299995, 10.974973853000051],
              [-74.88259999699994, 10.974910030000046],
              [-74.88263945799991, 10.974862164000058],
              [-74.88268250899995, 10.974821384000052],
              [-74.88273273199991, 10.974780604000046],
              [-74.88277040399993, 10.974754010000083],
              [-74.88286905899992, 10.974651178000045],
              [-74.88293542499991, 10.974585579000063],
              [-74.88296232999994, 10.974544804000061],
              [-74.88299758199992, 10.974569230000043],
              [-74.88302309599993, 10.974586913000053],
              [-74.88300252099992, 10.974440723000043],
              [-74.88299841499992, 10.974411553000039],
              [-74.88309643299993, 10.974478913000041],
              [-74.88315802599993, 10.974521252000045],
              [-74.88491913799993, 10.977075231000072],
              [-74.8859234379999 , 10.978062240000042],
              [-74.88602251499992, 10.97815961200007 ],
              [-74.8868322699999 , 10.978955424000048],
              [-74.88714550399993, 10.979919145000054],
              [-74.8882568489999 , 10.980661525000073],
              [-74.89203500699995, 10.983018984000068],
              [-74.89385429499993, 10.983014109000067],
              [-74.89419096799992, 10.98287999900009 ],
              [-74.8947540229999 , 10.982655710000074],
              [-74.89584889699995, 10.982219575000045],
              [-74.89592633899991, 10.982006212000044],
              [-74.89624530899994, 10.981127411000045],
              [-74.8964715649999 , 10.980504047000068],
              [-74.89659755799994, 10.98015692100006 ],
              [-74.89727108799991, 10.978975022000043],
              [-74.89727773499993, 10.978963358000044],
              [-74.89734826099993, 10.978839601000061],
              [-74.8973597939999 , 10.978824156000087],
              [-74.89761438099993, 10.978483167000093],
              [-74.89836506799992, 10.977477718000046],
              [-74.89891070099992, 10.977132564000044],
              [-74.90018104199993, 10.97632897200009 ],
              [-74.90079736699994, 10.975939098000083],
              [-74.9008012779999 , 10.975936624000042],
              [-74.90319320899994, 10.974439475000054],
              [-74.90443341499991, 10.973646934000044],
              [-74.90531596499994, 10.971890803000065],
              [-74.90531572299994, 10.971804654000039],
              [-74.90531523299995, 10.97162774900005 ],
              [-74.90746466099995, 10.97217315800009 ],
              [-74.9075863889999 , 10.972204045000069],
              [-74.90760120699991, 10.97220780500004 ],
              [-74.90873425299992, 10.972495301000038],
              [-74.9093081339999 , 10.971441499000036],
              [-74.9087747019999 , 10.971092205000048],
              [-74.90828576099995, 10.97078663600007 ],
              [-74.90757558599995, 10.970700889000057],
              [-74.9068645509999 , 10.970308235000061],
              [-74.90633039199992, 10.969695883000043],
              [-74.90630837299994, 10.969672699000057],
              [-74.9057073699999 , 10.96903992600005 ],
              [-74.9050218399999 , 10.967854057000068],
              [-74.90477107499993, 10.967420269000058],
              [-74.90472888099993, 10.967364183000086],
              [-74.90419863999995, 10.966659381000056],
              [-74.90401397699992, 10.966413926000087],
              [-74.9021916339999 , 10.965278936000061],
              [-74.90090187099992, 10.964186338000047],
              [-74.90035652299991, 10.963726995000059],
              [-74.89981303399992, 10.96326921900004 ],
              [-74.89977524999995, 10.963237394000089],
              [-74.89927375199994, 10.96281498400009 ],
              [-74.8990339739999 , 10.96261301900006 ],
              [-74.89906362099993, 10.962564987000064],
              [-74.8991146969999 , 10.96248223300006 ],
              [-74.89937276299992, 10.962064117000068],
              [-74.89970027899994, 10.961533479000082],
              [-74.89975094399995, 10.961451392000072],
              [-74.89988892999992, 10.961227829000052],
              [-74.89990004499992, 10.961209820000079],
              [-74.89999430199993, 10.961057106000055],
              [-74.89999620299994, 10.961054025000067],
              [-74.90008877899993, 10.960904034000066],
              [-74.90009210499994, 10.960898645000043],
              [-74.90014743899991, 10.960808993000057],
              [-74.90017002199994, 10.960772404000068],
              [-74.90022662699994, 10.96068069200004 ],
              [-74.90026786899995, 10.960678057000052],
              [-74.90050955599992, 10.960662607000074],
              [-74.90093902799993, 10.960635154000045],
              [-74.90108452999993, 10.960625853000067],
              [-74.9012888119999 , 10.960612795000088],
              [-74.90147794899991, 10.960600704000058],
              [-74.90147807099993, 10.960475054000085],
              [-74.90154699599992, 10.960456366000074],
              [-74.9016218079999 , 10.960448604000078],
              [-74.90163176699991, 10.960447571000088],
              [-74.90167741999994, 10.960442834000048],
              [-74.90168173299992, 10.960442386000068],
              [-74.90173801899994, 10.960432922000052],
              [-74.9019122599999 , 10.960403625000083],
              [-74.90194907699993, 10.960397435000061],
              [-74.90205911599992, 10.960378933000072],
              [-74.9020850519999 , 10.960374572000092],
              [-74.90213043099993, 10.960379110000076],
              [-74.90229335299995, 10.960395402000074],
              [-74.90233489499991, 10.960399556000084],
              [-74.9024080499999 , 10.960396508000088],
              [-74.90257225399995, 10.960389666000083],
              [-74.90267753699993, 10.960385279000093],
              [-74.90283026799995, 10.96036891500006 ],
              [-74.90287741199995, 10.960363864000044],
              [-74.90295803599992, 10.960347740000088],
              [-74.90299961499994, 10.96033942400004 ],
              [-74.90309156399991, 10.96032103400006 ],
              [-74.90384109399992, 10.960253219000037],
              [-74.90435148799992, 10.960285342000077],
              [-74.90465437799992, 10.960286777000078],
              [-74.90481514599992, 10.960287539000092],
              [-74.90510458799992, 10.960288911000077],
              [-74.90532145599991, 10.960282273000075],
              [-74.9054126879999 , 10.960279480000054],
              [-74.90545436799994, 10.960278204000076],
              [-74.90550043099995, 10.960278204000076],
              [-74.90561141299992, 10.960278204000076],
              [-74.90566048799991, 10.960301636000054],
              [-74.90572644399992, 10.960333129000048],
              [-74.9059320579999 , 10.960431305000043],
              [-74.90600759299991, 10.96046737100005 ],
              [-74.90637957399991, 10.960616163000054],
              [-74.90650727999991, 10.960667245000081],
              [-74.90689989099991, 10.960877828000037],
              [-74.90711761199992, 10.960938504000069],
              [-74.90713512099995, 10.960935740000082],
              [-74.90725324099992, 10.960917089000077],
              [-74.90737816299992, 10.960838567000053],
              [-74.90745311599994, 10.960706507000054],
              [-74.90745755699993, 10.960613231000082],
              [-74.90746025399994, 10.960556601000064],
              [-74.90744597699995, 10.960456663000059],
              [-74.90742811099994, 10.96042723700009 ],
              [-74.9073650759999 , 10.960323413000083],
              [-74.90728037299994, 10.96020750300005 ],
              [-74.90718423699991, 10.960075949000043],
              [-74.90718302199991, 10.96007405000006 ],
              [-74.9071523849999 , 10.960026179000067],
              [-74.90713512099995, 10.959999205000088],
              [-74.90703195099991, 10.959838003000073],
              [-74.90702415099992, 10.959813186000076],
              [-74.90692725499991, 10.959504879000065],
              [-74.90689965099995, 10.959336745000087],
              [-74.90687490699992, 10.959186031000058],
              [-74.90687490699992, 10.959046854000064],
              [-74.90687490699992, 10.958964295000044],
              [-74.90687490699992, 10.95896236200008 ],
              [-74.90689014399993, 10.958848084000067],
              [-74.90690345999991, 10.958748210000067],
              [-74.90703195099991, 10.95855309400008 ],
              [-74.90713512099995, 10.958460979000051],
              [-74.90716520099994, 10.958434121000039],
              [-74.90738734699994, 10.958275446000073],
              [-74.90743170099995, 10.958243764000088],
              [-74.90759350399992, 10.958110515000044],
              [-74.90784282899995, 10.957960920000062],
              [-74.90790283399991, 10.95792491700007 ],
              [-74.90799801199995, 10.957877327000062],
              [-74.90817885099995, 10.95784877400007 ],
              [-74.90830734199994, 10.95784877400007 ],
              [-74.90836685099993, 10.957860878000076],
              [-74.90852588799993, 10.957893224000088],
              [-74.90858811899994, 10.957905881000045],
              [-74.90868647099995, 10.95792917500006 ],
              [-74.90967315299991, 10.958162863000041],
              [-74.9098120139999 , 10.958176493000053],
              [-74.90982792499995, 10.958178055000076],
              [-74.91003404899993, 10.958198288000062],
              [-74.9103780559999 , 10.958232055000053],
              [-74.91038035399993, 10.95823228100005 ],
              [-74.91044885699995, 10.958239005000053],
              [-74.91071132999991, 10.958288949000064],
              [-74.91096358399994, 10.958336949000056],
              [-74.91145417999991, 10.958430302000068],
              [-74.91162431099991, 10.958462675000078],
              [-74.9116909359999 , 10.958457916000043],
              [-74.9116965469999 , 10.958453708000093],
              [-74.91176707799991, 10.958400809000068],
              [-74.9118120409999 , 10.958332532000043],
              [-74.9118452699999 , 10.958282073000078],
              [-74.9121525509999 , 10.957815461000052],
              [-74.9122060819999 , 10.957736279000073],
              [-74.91224945099992, 10.957672130000049],
              [-74.91238097899992, 10.957477578000066],
              [-74.91250946999992, 10.957401435000065],
              [-74.91258104899993, 10.957401435000065],
              [-74.9127471349999 , 10.957401435000065],
              [-74.91307028799991, 10.957401435000065],
              [-74.9131512429999 , 10.957401435000065],
              [-74.9132239079999 , 10.957401435000065],
              [-74.91328427699995, 10.957401435000065],
              [-74.91337083499991, 10.957401435000065],
              [-74.9139752179999 , 10.957387158000074],
              [-74.91404546099994, 10.957387158000074],
              [-74.9142306729999 , 10.957387158000074],
              [-74.91476519899993, 10.957387158000074],
              [-74.91486513699994, 10.95734432800009 ],
              [-74.91493176199992, 10.957258668000065],
              [-74.91497935099994, 10.95712541800009 ],
              [-74.91496435399995, 10.956566275000057],
              [-74.91520923999991, 10.956348371000047],
              [-74.9156598749999 , 10.956099376000054],
              [-74.91586156699992, 10.955987930000049],
              [-74.91616848899992, 10.955627580000055],
              [-74.91653306199993, 10.955570596000086],
              [-74.91670606399992, 10.955373234000092],
              [-74.9167324739999 , 10.955343105000054],
              [-74.91678243799993, 10.955286106000074],
              [-74.91695066699992, 10.955188258000078],
              [-74.91743475399994, 10.95490669700007 ],
              [-74.91770331999993, 10.954641165000055],
              [-74.91797181099992, 10.954091206000044],
              [-74.91795191699993, 10.953895210000042],
              [-74.91791408199992, 10.953522379000049],
              [-74.91783720599994, 10.953086284000051],
              [-74.91783709799995, 10.952695402000074],
              [-74.91783706399991, 10.952574325000057],
              [-74.9180434889999 , 10.952195251000092],
              [-74.91810556799993, 10.952081251000038],
              [-74.91816071199992, 10.951917623000043],
              [-74.91835480899994, 10.951341685000045],
              [-74.91856571399995, 10.950734857000043],
              [-74.91866887899994, 10.950454264000086],
              [-74.91871910299994, 10.950317659000063],
              [-74.91904514999993, 10.94976768400005 ],
              [-74.9191403669999 , 10.94927767900009 ],
              [-74.91910500299991, 10.948817724000037],
              [-74.9189729229999 , 10.94861387900005 ],
              [-74.91895335099991, 10.948335469000085],
              [-74.91895251199992, 10.948038443000087],
              [-74.9188764239999 , 10.947704495000039],
              [-74.91883806299995, 10.947426135000057],
              [-74.91885601299992, 10.947129055000062],
              [-74.91876050899992, 10.946572389000039],
              [-74.91870362899994, 10.946386906000043],
              [-74.9185148759999 , 10.946071829000061],
              [-74.91840147999994, 10.94583080600006 ],
              [-74.9184008439999 , 10.945605219000072],
              [-74.91840074699991, 10.945570907000047],
              [-74.91841916599992, 10.945440906000044],
              [-74.9183999309999 , 10.945286751000083],
              [-74.9184574709999 , 10.945339618000048],
              [-74.91994092399995, 10.946966067000062],
              [-74.92002400399991, 10.947270668000044],
              [-74.92000035699994, 10.947493287000043],
              [-74.91991741899994, 10.947704199000043],
              [-74.91988190199993, 10.947874093000053],
              [-74.91986416999993, 10.948061565000046],
              [-74.91988792899991, 10.948219724000069],
              [-74.91994133999992, 10.948413027000072],
              [-74.92004217699991, 10.94861217500005 ],
              [-74.92019641999991, 10.94899877100005 ],
              [-74.92032104799995, 10.94947324900005 ],
              [-74.9203922559999 , 10.949707548000049],
              [-74.9204100739999 , 10.949836425000058],
              [-74.92042788399993, 10.949906720000058],
              [-74.92053461699993, 10.94999456000005 ],
              [-74.9208607409999 , 10.95024051200005 ],
              [-74.92100899899992, 10.950410355000088],
              [-74.92110982199995, 10.95054506200006 ],
              [-74.92116915399993, 10.950732507000055],
              [-74.92122847499991, 10.950843791000068],
              [-74.92133520699991, 10.950931633000039],
              [-74.92156052699994, 10.951078023000036],
              [-74.92176804099995, 10.951165840000044],
              [-74.92195775099992, 10.951189216000046],
              [-74.92210596899992, 10.951218463000089],
              [-74.92227790899994, 10.951288709000039],
              [-74.92245581999993, 10.951505413000064],
              [-74.92260406699995, 10.951622532000044],
              [-74.92276414299994, 10.951675206000061],
              [-74.92301314999992, 10.951733718000071],
              [-74.9232088089999 , 10.951815674000045],
              [-74.92343412499991, 10.951950346000046],
              [-74.92360608799993, 10.952096750000067],
              [-74.92381364199991, 10.952319301000045],
              [-74.9239322549999 , 10.952459859000044],
              [-74.92411609299995, 10.952658983000049],
              [-74.92418132599994, 10.95274683200006 ],
              [-74.92491585899995, 10.950836864000053],
              [-74.92543756899994, 10.95090114800007 ],
              [-74.9258170139999 , 10.951018198000043],
              [-74.92622017899993, 10.951158668000062],
              [-74.92645139299992, 10.951193749000083],
              [-74.92673002499993, 10.951187807000053],
              [-74.92709163599994, 10.951140835000047],
              [-74.92742359699992, 10.951082149000058],
              [-74.92785042499992, 10.951052731000061],
              [-74.92833654999993, 10.95107015700006 ],
              [-74.92879898699994, 10.951163742000062],
              [-74.92915471799995, 10.951274935000072],
              [-74.9295401089999 , 10.951432990000058],
              [-74.92985436999993, 10.951620349000052],
              [-74.9300678699999 , 10.951883898000062],
              [-74.93026948599993, 10.952042006000056],
              [-74.93048294699992, 10.952182531000062],
              [-74.93066673399994, 10.952211766000062],
              [-74.93081496999991, 10.952299592000088],
              [-74.93093949999991, 10.952416712000058],
              [-74.93112922899991, 10.952492809000091],
              [-74.93076838799993, 10.952882162000037],
              [-74.9305535009999 , 10.95302690200009 ],
              [-74.9304761649999 , 10.953078992000087],
              [-74.93035942199992, 10.953157626000063],
              [-74.9301638469999 , 10.95334514700005 ],
              [-74.92994461299992, 10.953690849000054],
              [-74.92979648799991, 10.953960370000061],
              [-74.92974749699994, 10.95403063000009 ],
              [-74.92928985099991, 10.954686934000051],
              [-74.9291565229999 , 10.955031087000066],
              [-74.92896603199995, 10.955522794000046],
              [-74.92874493699992, 10.956249272000036],
              [-74.92847652699993, 10.957342874000062],
              [-74.9284711009999 , 10.957392076000076],
              [-74.92831669899994, 10.95879227000006 ],
              [-74.9284176029999 , 10.959863192000057],
              [-74.9288289449999 , 10.96081599100006 ],
              [-74.92909785599994, 10.961300177000055],
              [-74.92935886799995, 10.961799989000042],
              [-74.92941155499994, 10.961976790000051],
              [-74.92943800099994, 10.962065533000043],
              [-74.92939859099994, 10.962424844000054],
              [-74.92927489999994, 10.962663919000079],
              [-74.92926010799994, 10.962692511000057],
              [-74.92922479499993, 10.962760768000066],
              [-74.92886919499995, 10.96312017200006 ],
              [-74.92803946299995, 10.963940568000055],
              [-74.92770098299991, 10.964271333000056],
              [-74.92811915999994, 10.964600995000069],
              [-74.92839325499995, 10.964809199000058],
              [-74.92856195899992, 10.965027853000038],
              [-74.92868854999995, 10.965392318000056],
              [-74.92877300799995, 10.96584011300007 ],
              [-74.9287520609999 , 10.966246286000057],
              [-74.92848876999994, 10.966892063000046],
              [-74.9281411369999 , 10.967444134000061],
              [-74.9279785569999 , 10.967374850000056],
              [-74.9273645639999 , 10.967113192000056],
              [-74.92717995099991, 10.96703451600007 ],
              [-74.92717742299993, 10.96703343900009 ],
              [-74.92667591599991, 10.966819714000053],
              [-74.92614887099995, 10.96663241400006 ],
              [-74.92580106699995, 10.966653346000044],
              [-74.92532678099991, 10.966674319000049],
              [-74.92489463999993, 10.966622374000053],
              [-74.92455738199993, 10.966664129000037],
              [-74.92419906299995, 10.966768382000055],
              [-74.92391651799994, 10.967038420000051],
              [-74.92388295899991, 10.967070493000051],
              [-74.92347204999993, 10.967528851000054],
              [-74.92309271399995, 10.96785181100006 ],
              [-74.92263957799992, 10.968091474000062],
              [-74.9222391049999 , 10.96823739000007 ],
              [-74.92205612799995, 10.968377450000048],
              [-74.92178425499992, 10.968680001000052],
              [-74.92144721699992, 10.969182684000089],
              [-74.92143461499995, 10.969201478000059],
              [-74.92123221299994, 10.969744389000084],
              [-74.92110693699993, 10.970020465000061],
              [-74.92095571899995, 10.970353714000055],
              [-74.9205132269999 , 10.97097870400006 ],
              [-74.9200944289999 , 10.971533390000047],
              [-74.91949119899994, 10.972217519000083],
              [-74.9195864969999 , 10.972235717000046],
              [-74.92081155199992, 10.97254402100009 ],
              [-74.92147923099992, 10.972785907000059],
              [-74.92178551599994, 10.972937115000093],
              [-74.92204895499992, 10.973173064000036],
              [-74.92232469599992, 10.973590571000045],
              [-74.92262127399994, 10.973926041000084],
              [-74.92266167899993, 10.97397174200006 ],
              [-74.9230108509999 , 10.974177403000056],
              [-74.92336613999993, 10.974328598000056],
              [-74.9236846579999 , 10.974401130000047],
              [-74.92416241399991, 10.974461509000037],
              [-74.92428209299993, 10.974468568000077],
              [-74.92446866499995, 10.974479573000053],
              [-74.92465896499994, 10.974468456000068],
              [-74.92478102599995, 10.97446132600004 ],
              [-74.92489507899995, 10.974458841000057],
              [-74.92506276599994, 10.974455191000061],
              [-74.9252200169999 , 10.974498961000052],
              [-74.92523475099995, 10.974503061000064],
              [-74.92524368799991, 10.97450555000006 ],
              [-74.92530165999995, 10.97452168600006 ],
              [-74.92540804999993, 10.974626225000065],
              [-74.92545559299992, 10.97467294200004 ],
              [-74.92545923099993, 10.974677254000085],
              [-74.9255704759999 , 10.974809073000074],
              [-74.92573127299994, 10.974872571000049],
              [-74.9262266369999 , 10.974922349000053],
              [-74.92739022399991, 10.974959845000058],
              [-74.92767319699993, 10.974968963000038],
              [-74.92771500099991, 10.97497031100005 ],
              [-74.92809474299992, 10.974964145000058],
              [-74.92845609099993, 10.974909568000044],
              [-74.92875329799995, 10.97478581100006 ],
              [-74.92892152099995, 10.974715761000084],
              [-74.92921546999992, 10.974570422000056],
              [-74.92941145799995, 10.974546155000041],
              [-74.9296013419999 , 10.974594510000088],
              [-74.9301475449999 , 10.97480414000006 ],
              [-74.93111372899995, 10.975321904000054],
              [-74.9312437559999 , 10.975421589000064],
              [-74.93135493199992, 10.975506823000046],
              [-74.93138666199991, 10.975531148000073],
              [-74.93154492799994, 10.975737036000055],
              [-74.93161847599993, 10.975890328000048],
              [-74.93164306799991, 10.97616467000006 ],
              [-74.93163497899991, 10.97640675200006 ],
              [-74.93152076799993, 10.976777970000057],
              [-74.9313342339999 , 10.977099271000043],
              [-74.93126774699994, 10.977213791000054],
              [-74.93224826799991, 10.977749173000063],
              [-74.93234592999994, 10.977802499000063],
              [-74.93286800999994, 10.977185473000077],
              [-74.93313418299994, 10.976870895000047],
              [-74.93395493499992, 10.976489911000044],
              [-74.93509386599993, 10.975752928000077],
              [-74.93591454099993, 10.975156750000053],
              [-74.93693625299994, 10.974585328000046],
              [-74.93745549499994, 10.97432857800004 ],
              [-74.93779891699995, 10.97431190900005 ],
              [-74.93814238399995, 10.97442766000006 ],
              [-74.93851100299992, 10.974617897000087],
              [-74.93889976999992, 10.974773465000055],
              [-74.93938038699991, 10.974857102000044],
              [-74.93976202199991, 10.974824382000065],
              [-74.94016247299993, 10.974717165000072],
              [-74.94016915899994, 10.97471476600009 ],
              [-74.94059118199993, 10.974563383000088],
              [-74.94088793799995, 10.974353780000058],
              [-74.94095638899995, 10.974288806000061],
              [-74.94124119399993, 10.974018458000046],
              [-74.94149554099994, 10.973790249000047],
              [-74.94174523999993, 10.973748260000093],
              [-74.9418960189999 , 10.97375751900006 ],
              [-74.94201894999992, 10.973838436000051],
              [-74.94205153799993, 10.97385988600007 ],
              [-74.94208417599992, 10.973892112000044],
              [-74.9422306479999 , 10.97403673100007 ],
              [-74.9423235399999 , 10.974118052000051],
              [-74.9426878189999 , 10.974436943000057],
              [-74.9434153059999 , 10.974814847000061],
              [-74.9439638849999 , 10.974991294000063],
              [-74.94517884999993, 10.975259837000067],
              [-74.94545332399991, 10.975336842000047],
              [-74.94545526299993, 10.975330105000069],
              [-74.94694776599994, 10.975809272000049],
              [-74.94689426199994, 10.97561803700006 ],
              [-74.94688710499992, 10.975578658000074],
              [-74.94688537899992, 10.975569165000081],
              [-74.9468045509999 , 10.97512458600005 ],
              [-74.94671483899992, 10.974612511000089],
              [-74.94670667499992, 10.97442221600005 ],
              [-74.94669107099992, 10.974058513000045],
              [-74.94667718399995, 10.97382177600008 ],
              [-74.94666721099992, 10.973651793000045],
              [-74.94666501099994, 10.973614270000041],
              [-74.9466389079999 , 10.973169325000072],
              [-74.9466137039999 , 10.972797856000057],
              [-74.9466390899999 , 10.97258306000009 ],
              [-74.94664131899992, 10.97256419100006 ],
              [-74.9466435839999 , 10.972551855000063],
              [-74.94666681599995, 10.97242533900004 ],
              [-74.9471475709999 , 10.971471851000047],
              [-74.94727113899995, 10.971363936000046],
              [-74.94757784199993, 10.971122858000058],
              [-74.94757876299991, 10.971122629000092],
              [-74.94799634499992, 10.971018707000042],
              [-74.94800860099991, 10.971015659000045],
              [-74.94825037899994, 10.970955489000062],
              [-74.94844187299992, 10.970966813000075],
              [-74.94863987399992, 10.97096674100004 ],
              [-74.94867570199995, 10.97096814400004 ],
              [-74.9487914849999 , 10.970972678000066],
              [-74.94879955899995, 10.970972995000068],
              [-74.94899116699992, 10.970966609000072],
              [-74.94913143899993, 10.970925486000056],
              [-74.9491635999999 , 10.970916058000057],
              [-74.94932326199995, 10.970884440000077],
              [-74.94951489999994, 10.970947481000053],
              [-74.94971291599995, 10.970985272000064],
              [-74.94981062199992, 10.970981907000066],
              [-74.94989813799992, 10.970978892000062],
              [-74.95009611799992, 10.970922012000074],
              [-74.95025920099994, 10.970929861000059],
              [-74.95044123799994, 10.971057961000042],
              [-74.95078205099992, 10.97129779700009 ],
              [-74.95139164599993, 10.971862434000059],
              [-74.95168076599992, 10.972234769000067],
              [-74.9519447589999 , 10.972594695000055],
              [-74.9521207489999 , 10.972818092000068],
              [-74.95219813099993, 10.97287237200004 ],
              [-74.95235954099991, 10.972985596000058],
              [-74.95271768299995, 10.973134433000041],
              [-74.95285962499992, 10.973241055000074],
              [-74.95300675199991, 10.973351579000052],
              [-74.95357804399993, 10.973730809000074],
              [-74.9540861559999 , 10.973947812000063],
              [-74.95447294199994, 10.974112994000052],
              [-74.9545089049999 , 10.974128352000037],
              [-74.9546499619999 , 10.974203638000063],
              [-74.95519293699994, 10.97449344100005 ],
              [-74.9556915519999 , 10.974759566000046],
              [-74.9557086989999 , 10.97477294500004 ],
              [-74.9558704239999 , 10.974899089000075],
              [-74.95598761299993, 10.974990494000053],
              [-74.9561887879999 , 10.975194457000043],
              [-74.95631634299991, 10.975323784000068],
              [-74.95636353799995, 10.975371634000055],
              [-74.95612719299993, 10.975963621000062],
              [-74.95573985899995, 10.976635250000072],
              [-74.95567029499995, 10.976807276000045],
              [-74.95561009799991, 10.976956156000085],
              [-74.95558899299994, 10.977008349000073],
              [-74.95553373799993, 10.977291880000053],
              [-74.95552971199993, 10.977560220000044],
              [-74.95557669099992, 10.97786022300005 ],
              [-74.95561914599995, 10.97799056000008 ],
              [-74.95569928399993, 10.978083638000044],
              [-74.95581711599993, 10.978176706000056],
              [-74.95584600399991, 10.97821790200004 ],
              [-74.95590197999991, 10.978297715000053],
              [-74.95593499499995, 10.978376841000056],
              [-74.95600096499993, 10.978400099000055],
              [-74.95606800599995, 10.978394048000041],
              [-74.95615644499992, 10.978386065000052],
              [-74.95638733099992, 10.978409251000073],
              [-74.95649572799994, 10.978483697000058],
              [-74.95658529799994, 10.978600050000068],
              [-74.95663719499993, 10.978762970000048],
              [-74.95693723999995, 10.979146169000046],
              [-74.9582007649999 , 10.98075986300006 ],
              [-74.95865246299991, 10.980735132000063],
              [-74.95906526099992, 10.980712532000041],
              [-74.95915905999993, 10.98070739700006 ],
              [-74.96028245999992, 10.980645889000073],
              [-74.96060560099994, 10.980628196000055],
              [-74.96148405499991, 10.980580101000044],
              [-74.96194681299994, 10.980714077000073],
              [-74.9622086469999 , 10.980789883000057],
              [-74.9626516639999 , 10.980570782000086],
              [-74.96397265199994, 10.980230034000044],
              [-74.96511603199991, 10.979935098000055],
              [-74.96526061299994, 10.980252393000058],
              [-74.9652774349999 , 10.980266106000045],
              [-74.9652715979999 , 10.980246088000058],
              [-74.9654438529999 , 10.980147215000045],
              [-74.96598388199993, 10.979947305000053],
              [-74.96601611999995, 10.979955835000055],
              [-74.96613454499993, 10.979987178000044],
              [-74.96634765499994, 10.980005238000047],
              [-74.96652034399995, 10.979997899000068],
              [-74.96674445399992, 10.979946978000044],
              [-74.96694577699992, 10.979882711000073],
              [-74.96702165499994, 10.979858489000037],
              [-74.96708610799993, 10.979837917000054],
              [-74.96752694499992, 10.979677988000049],
              [-74.96791269599993, 10.979576165000083],
              [-74.96831683599993, 10.979517901000065],
              [-74.9687797819999 , 10.979503176000037],
              [-74.96922437599994, 10.97952476100005 ],
              [-74.96952935599995, 10.979560929000058],
              [-74.96977184999992, 10.979549929000086],
              [-74.96995553999994, 10.979502653000054],
              [-74.97016493099994, 10.979408170000056],
              [-74.97045145699991, 10.979266453000037],
              [-74.97066084799991, 10.979179232000092],
              [-74.97089230799992, 10.979146453000055],
              [-74.97103561499995, 10.979168169000047],
              [-74.97124139999994, 10.979226166000046],
              [-74.97139940799991, 10.979266033000044],
              [-74.97156474899992, 10.979269584000065],
              [-74.97179987899995, 10.979222284000059],
              [-74.97231052699993, 10.979062314000089],
              [-74.97255667999991, 10.97901863800007 ],
              [-74.97310409999994, 10.97892763100009 ],
              [-74.97335758199995, 10.97884764400004 ],
              [-74.9735596129999 , 10.978742270000055],
              [-74.97369920199992, 10.978665966000051],
              [-74.97393432399991, 10.97860051400005 ],
              [-74.97424659199993, 10.97852049900007 ],
              [-74.97450741699993, 10.97842236200006 ],
              [-74.97468739999994, 10.978313364000087],
              [-74.97479512399991, 10.978192301000092],
              [-74.9751427519999 , 10.977771012000062],
              [-74.97553447699994, 10.977369066000051],
              [-74.97587726299992, 10.977073631000053],
              [-74.9761809069999 , 10.976883095000062],
              [-74.97779852499991, 10.976115832000062],
              [-74.97780800399994, 10.975967971000046],
              [-74.9778689979999 , 10.97570373600007 ],
              [-74.97794532599994, 10.975560276000067],
              [-74.97807443999994, 10.975435936000054],
              [-74.97814192499993, 10.97547846200007 ],
              [-74.97814203299993, 10.975513420000084],
              [-74.97814246799993, 10.975656407000088],
              [-74.97814264399994, 10.97571578700007 ],
              [-74.97812273699992, 10.975904722000053],
              [-74.9780618289999 , 10.976080288000048],
              [-74.97803332199993, 10.976137049000044],
              [-74.97803590299992, 10.976418920000071],
              [-74.97811069499994, 10.976621986000055],
              [-74.9783984359999 , 10.976975175000064],
              [-74.97865236399991, 10.977401827000051],
              [-74.97872649099992, 10.977437575000067],
              [-74.97873590199993, 10.977442118000056],
              [-74.97914213099995, 10.977638022000065],
              [-74.97946595899992, 10.978114866000055],
              [-74.97960598799995, 10.978728768000053],
              [-74.97990650999992, 10.979114664000065],
              [-74.98036731899992, 10.979204310000057],
              [-74.98060402599992, 10.979258599000048],
              [-74.9807590559999 , 10.979294156000037],
              [-74.9811108689999 , 10.979639692000092],
              [-74.9812902669999 , 10.979815890000054],
              [-74.98037916499993, 10.980083611000055],
              [-74.9780068419999 , 10.980713276000074],
              [-74.97693608499992, 10.981046401000071],
              [-74.97596050599992, 10.981343834000086],
              [-74.9744376509999 , 10.981617472000039],
              [-74.97313489599992, 10.981492550000041],
              [-74.97233777699995, 10.981522293000069],
              [-74.9714454789999 , 10.981789982000066],
              [-74.9709041509999 , 10.982117158000051],
              [-74.97028549099991, 10.982604948000073],
              [-74.96970847199992, 10.98294997000005 ],
              [-74.96901842799991, 10.983164121000073],
              [-74.96844735799993, 10.98331878600004 ],
              [-74.96763239199993, 10.983586476000085],
              [-74.96689475999995, 10.98415159800004 ],
              [-74.96652594299991, 10.984562055000083],
              [-74.96611548599992, 10.984871384000087],
              [-74.96549087799991, 10.985156920000065],
              [-74.96495549899993, 10.985269944000038],
              [-74.96448741599994, 10.985413706000088],
              [-74.96432517199992, 10.985511794000047],
              [-74.96428375499994, 10.98553924500004 ],
              [-74.96426825399993, 10.985552729000062],
              [-74.96419220199994, 10.985580477000042],
              [-74.9641246249999 , 10.985595706000083],
              [-74.96407798799993, 10.985589043000061],
              [-74.96405228999993, 10.985595706000083],
              [-74.96401728199993, 10.985616182000058],
              [-74.96371134099991, 10.985740639000085],
              [-74.9635228869999 , 10.985852949000048],
              [-74.9631098129999 , 10.986149906000037],
              [-74.96279382099993, 10.98639546700008 ],
              [-74.96242596799993, 10.986690255000042],
              [-74.96202647399991, 10.987113055000066],
              [-74.96198750699995, 10.987138772000037],
              [-74.96193262399993, 10.987160761000041],
              [-74.9618947909999 , 10.987172182000052],
              [-74.96179603799993, 10.98721575500008 ],
              [-74.96174940299994, 10.987240368000073],
              [-74.96167359799995, 10.987287449000064],
              [-74.96138582399993, 10.98750911400009 ],
              [-74.96130016399991, 10.987577642000076],
              [-74.96127160999993, 10.98762761100005 ],
              [-74.9612376899999 , 10.987729625000043],
              [-74.96119843499991, 10.987781442000085],
              [-74.96119761999995, 10.987782257000049],
              [-74.96118906599992, 10.987790810000092],
              [-74.96099989899994, 10.987905024000042],
              [-74.96096420799995, 10.987933577000092],
              [-74.96087854799993, 10.98802994600004 ],
              [-74.9608357159999 , 10.988140590000057],
              [-74.96083400399993, 10.988219361000063],
              [-74.96083214799995, 10.988304773000038],
              [-74.96077909199994, 10.988371093000069],
              [-74.96076076299994, 10.988394003000053],
              [-74.96065011899992, 10.988411849000045],
              [-74.96060764299995, 10.988417708000043],
              [-74.96049710099993, 10.988524933000065],
              [-74.96041746799995, 10.98864794700006 ],
              [-74.96039316399992, 10.988711727000066],
              [-74.96038479099991, 10.988746223000078],
              [-74.96038158199991, 10.988868508000053],
              [-74.96037200699993, 10.988886354000044],
              [-74.96036555699993, 10.988897029000043],
              [-74.96035910799992, 10.988907703000052],
              [-74.96041073399994, 10.988931266000066],
              [-74.96034095199991, 10.989089513000067],
              [-74.9603814859999 , 10.989138259000072],
              [-74.96062436799991, 10.989309735000063],
              [-74.96072236799995, 10.989372059000061],
              [-74.9607306719999 , 10.98938750800005 ],
              [-74.96073245699995, 10.989398810000068],
              [-74.9607217489999 , 10.989421415000038],
              [-74.96071172699993, 10.989431613000079],
              [-74.96070372999992, 10.989435383000057],
              [-74.96069833399991, 10.98943792700004 ],
              [-74.9606799789999 , 10.989437486000043],
              [-74.96066702099995, 10.989434502000051],
              [-74.96058949199994, 10.989387333000082],
              [-74.9602030659999 , 10.989133818000084],
              [-74.96019119499994, 10.989145006000058],
              [-74.9601416779999 , 10.98918347600005 ],
              [-74.95931720199991, 10.98982400500006 ],
              [-74.95910954999994, 10.989986462000047],
              [-74.95901506099995, 10.989994528000068],
              [-74.95885682699992, 10.990121829000088],
              [-74.95868754199995, 10.990324334000093],
              [-74.95862868799992, 10.99039020300006 ],
              [-74.95857458299992, 10.990450757000076],
              [-74.9585196729999 , 10.990483934000054],
              [-74.9584823319999 , 10.990497448000042],
              [-74.95845721199993, 10.99050653900008 ],
              [-74.95841187499991, 10.990536832000089],
              [-74.95828784999992, 10.990666893000082],
              [-74.95823169899995, 10.990745183000058],
              [-74.95795472499992, 10.991131363000079],
              [-74.95789429999991, 10.991261509000083],
              [-74.95778149999995, 10.991433079000046],
              [-74.95770726099994, 10.991521595000052],
              [-74.95761389799992, 10.991667976000087],
              [-74.9575736669999 , 10.991731052000091],
              [-74.95750595899995, 10.991938409000056],
              [-74.95748811199991, 10.99218230300005 ],
              [-74.95744052399994, 10.99240835200004 ],
              [-74.9573810359999 , 10.992551120000087],
              [-74.95732105799993, 10.992671076000079],
              [-74.95731486299991, 10.992683465000084],
              [-74.95724421799991, 10.99282475800004 ],
              [-74.95713878599992, 10.992982906000066],
              [-74.95712881399993, 10.992991320000044],
              [-74.95662436899994, 10.993286373000046],
              [-74.9561683259999 , 10.993438387000083],
              [-74.95610868099993, 10.993436969000072],
              [-74.9560715959999 , 10.993436088000067],
              [-74.95604903599991, 10.99343555200005 ],
              [-74.95599528799994, 10.993536473000063],
              [-74.95595290599994, 10.99361605300004 ],
              [-74.95596522299991, 10.993620024000052],
              [-74.95600760199994, 10.99362687300004 ],
              [-74.95602110099992, 10.993629055000042],
              [-74.95611744599995, 10.993644626000048],
              [-74.95627306299991, 10.993661758000087],
              [-74.95647579299992, 10.99366604100004 ],
              [-74.95669565499992, 10.993648909000058],
              [-74.95696834099994, 10.993564676000062],
              [-74.95724388299993, 10.993427619000045],
              [-74.9573464369999 , 10.993413343000043],
              [-74.95742971899995, 10.993419291000066],
              [-74.9574951539999 , 10.993437137000058],
              [-74.95751002499992, 10.993517444000076],
              [-74.95745648699994, 10.993591802000083],
              [-74.95738212899994, 10.993675083000085],
              [-74.95734346299992, 10.993767287000082],
              [-74.95736130899991, 10.99381487700009 ],
              [-74.95740592399994, 10.993862466000053],
              [-74.9574535129999 , 10.99389220900008 ],
              [-74.95750110199992, 10.993901132000076],
              [-74.95752489699993, 10.993927901000063],
              [-74.95750407699995, 10.993972516000042],
              [-74.95746838499991, 10.994026054000074],
              [-74.95742971899995, 10.994142053000076],
              [-74.95742674399992, 10.994329435000054],
              [-74.95795224799991, 10.994569761000037],
              [-74.95797937399993, 10.994605453000077],
              [-74.9579736629999 , 10.994626868000068],
              [-74.95794225399993, 10.99463400600007 ],
              [-74.95735833499992, 10.994375597000044],
              [-74.95730467499993, 10.99435387200009 ],
              [-74.9571124709999 , 10.994276057000093],
              [-74.95700569899992, 10.994232829000055],
              [-74.95693145899992, 10.994247106000046],
              [-74.95685864799992, 10.994279942000048],
              [-74.95677726999992, 10.99432991100008 ],
              [-74.95674871699993, 10.994368458000054],
              [-74.9567287289999 , 10.994431276000057],
              [-74.95671159699992, 10.994465540000078],
              [-74.95666305599991, 10.994512654000062],
              [-74.95656169099993, 10.994562622000046],
              [-74.9564346279999 , 10.994666843000061],
              [-74.95632755199995, 10.99479390600004 ],
              [-74.95625474099995, 10.994839592000062],
              [-74.95617907399992, 10.994902409000076],
              [-74.95613767099991, 10.994959516000051],
              [-74.95609055799991, 10.995006630000091],
              [-74.95603345099994, 10.995069448000038],
              [-74.95601346299992, 10.995113705000051],
              [-74.95601917399995, 10.995152253000072],
              [-74.95600346999993, 10.995196511000074],
              [-74.9559791989999 , 10.995235058000048],
              [-74.9559392239999 , 10.99528502700008 ],
              [-74.9559063879999 , 10.995346417000064],
              [-74.95587483799994, 10.99542550800004 ],
              [-74.95577932399993, 10.995563424000068],
              [-74.95573506599993, 10.995654795000064],
              [-74.95569594999995, 10.995742231000065],
              [-74.95568652499992, 10.995763298000043],
              [-74.95566082699992, 10.995860380000067],
              [-74.95564654999993, 10.995954607000044],
              [-74.95562513499993, 10.996051689000069],
              [-74.95558373299991, 10.996127356000045],
              [-74.95556660099993, 10.996195884000088],
              [-74.95553757099992, 10.996290111000064],
              [-74.9555085419999 , 10.996384338000041],
              [-74.9554776089999 , 10.996629422000069],
              [-74.95545619399991, 10.996807882000041],
              [-74.95544271699993, 10.996854731000042],
              [-74.95540622499993, 10.996981582000046],
              [-74.9553491179999 , 10.997138627000083],
              [-74.95523728299992, 10.997390850000045],
              [-74.95519921199991, 10.99749078700006 ],
              [-74.95525501199995, 10.997516035000046],
              [-74.9558250099999 , 10.997773943000084],
              [-74.95584404599992, 10.997802496000077],
              [-74.95583452799991, 10.997828671000093],
              [-74.95581120899993, 10.997824387000037],
              [-74.95534578699994, 10.997621657000082],
              [-74.95532151599991, 10.997700180000038],
              [-74.95527154699994, 10.997775846000081],
              [-74.95518017599994, 10.99792289700008 ],
              [-74.95510593699993, 10.998019979000048],
              [-74.95502455899992, 10.998165602000086],
              [-74.95494175399995, 10.998299804000055],
              [-74.95487750899991, 10.99840402400008 ],
              [-74.95480897999994, 10.99851538300004 ],
              [-74.95476472199994, 10.998599616000092],
              [-74.95472760299992, 10.99866957200004 ],
              [-74.95472760299992, 10.99872382400008 ],
              [-74.9547333129999 , 10.998776648000046],
              [-74.9547590119999 , 10.99882376100004 ],
              [-74.95481183599992, 10.998895145000063],
              [-74.95485609399992, 10.998945114000037],
              [-74.95489749599994, 10.99899651000004 ],
              [-74.9549203389999 , 10.99903648500009 ],
              [-74.95490748999993, 10.999057900000082],
              [-74.95485609399992, 10.999007931000051],
              [-74.9548289679999 , 10.999037913000052],
              [-74.95477471599992, 10.999097875000075],
              [-74.95471332599993, 10.999224938000054],
              [-74.9546747789999 , 10.99931631000004 ],
              [-74.95464622499992, 10.999407681000037],
              [-74.9546190989999 , 10.999511901000062],
              [-74.95459625599995, 10.999620405000087],
              [-74.9546005389999 , 10.999718914000084],
              [-74.95461338899992, 10.99980172000005 ],
              [-74.95461338899992, 10.999868820000074],
              [-74.9545934009999 , 11.000021582000045],
              [-74.95458055199992, 11.000117236000051],
              [-74.95457341399992, 11.000225740000076],
              [-74.95455485399992, 11.000304262000043],
              [-74.95450060199994, 11.00043418000007 ],
              [-74.95447905299994, 11.000507806000087],
              [-74.95444920599994, 11.000609785000051],
              [-74.95442065199995, 11.000751125000079],
              [-74.95441494099992, 11.000826791000065],
              [-74.95458292899991, 11.000864400000069],
              [-74.95498886699994, 11.000955282000064],
              [-74.95499743399995, 11.000975270000083],
              [-74.95499457799991, 11.000996685000075],
              [-74.95497459099994, 11.001006679000056],
              [-74.95458057699994, 11.000934936000078],
              [-74.95448061499991, 11.00091673500009 ],
              [-74.95445491599992, 11.001018100000067],
              [-74.9544249349999 , 11.001099478000071],
              [-74.95441208599993, 11.001150874000075],
              [-74.95439896899995, 11.001218334000043],
              [-74.9543920989999 , 11.001253667000071],
              [-74.95437211099994, 11.001306491000037],
              [-74.95433356399991, 11.001352176000069],
              [-74.95429358899992, 11.001405         ],
              [-74.95426503499993, 11.001449258000036],
              [-74.95424647599992, 11.00153920200006 ],
              [-74.95420935599992, 11.001641995000057],
              [-74.95419079599992, 11.00170481200007 ],
              [-74.9541636699999 , 11.001872327000058],
              [-74.95413987599994, 11.002019853000093],
              [-74.95410894299994, 11.002234005000048],
              [-74.95406135399992, 11.002461838000045],
              [-74.9540316099999 , 11.002670041000044],
              [-74.95401673899994, 11.002794963000042],
              [-74.95398996999995, 11.00295557600009 ],
              [-74.9539873519999 , 11.003054205000069],
              [-74.95396593699991, 11.003164136000066],
              [-74.95396736499993, 11.00324265800009 ],
              [-74.95397593099995, 11.003316897000047],
              [-74.95397735899991, 11.00337828700009 ],
              [-74.95399591799992, 11.00339542000006 ],
              [-74.9544399259999 , 11.003502495000077],
              [-74.95445134699992, 11.00352248300004 ],
              [-74.95444849199993, 11.00354247000007 ],
              [-74.95443135999994, 11.00355389200007 ],
              [-74.95394452199992, 11.003466803000038],
              [-74.95390597499994, 11.003579590000072],
              [-74.95383173599993, 11.003732351000053],
              [-74.95380318199994, 11.003799452000067],
              [-74.95377462899995, 11.00386655300008 ],
              [-74.95367754599994, 11.004114969000057],
              [-74.95364756499993, 11.004194918000053],
              [-74.9536318609999 , 11.00429342800004 ],
              [-74.95360187999995, 11.004359101000091],
              [-74.95357189799995, 11.004413353000075],
              [-74.95352906799991, 11.00447759800005 ],
              [-74.95352763999995, 11.004514718000053],
              [-74.95350908099994, 11.004556121000064],
              [-74.95348338199994, 11.004586102000076],
              [-74.95346910599994, 11.004611800000077],
              [-74.95346767799992, 11.004653203000089],
              [-74.95346339499991, 11.004686039000092],
              [-74.95343626899995, 11.004726014000084],
              [-74.95341199899991, 11.004755995000039],
              [-74.95339486699993, 11.00479454300006 ],
              [-74.95338629999992, 11.004825951000043],
              [-74.95332995599995, 11.004899941000076],
              [-74.9533147269999 , 11.004941819000067],
              [-74.9532785589999 , 11.00499321600006 ],
              [-74.95324619899992, 11.005031287000065],
              [-74.95317957399993, 11.005109333000064],
              [-74.95315863499991, 11.005185476000065],
              [-74.95314530999991, 11.005286365000075],
              [-74.95313459499994, 11.005339691000074],
              [-74.95310914099991, 11.005466372000058],
              [-74.9530889159999 , 11.00549789900009 ],
              [-74.95305798299995, 11.005568093000079],
              [-74.9530401369999 , 11.005613303000075],
              [-74.95302348099995, 11.005681118000041],
              [-74.95300325599993, 11.00573227600006 ],
              [-74.95296042599995, 11.005836973000044],
              [-74.95293544099991, 11.005903597000042],
              [-74.95290926699994, 11.005955946000086],
              [-74.95288904199992, 11.006010673000048],
              [-74.95289261099992, 11.006057073000079],
              [-74.9528970909999 , 11.006078246000072],
              [-74.95290541499992, 11.006117588000052],
              [-74.95292599899994, 11.006147059000057],
              [-74.9529594739999 , 11.00621768600007 ],
              [-74.95297327399993, 11.006246240000053],
              [-74.9529952709999 , 11.006327917000078],
              [-74.95302953499993, 11.006385024000053],
              [-74.95307331799995, 11.006421192000062],
              [-74.95407269099991, 11.006626777000065],
              [-74.95409553399992, 11.006655331000047],
              [-74.95409172699993, 11.006685788000084],
              [-74.95407078799991, 11.006699113000082],
              [-74.95398131999991, 11.006689595000068],
              [-74.9529457779999 , 11.006474492000052],
              [-74.9528924789999 , 11.006470685000068],
              [-74.95284714299993, 11.00648463400006 ],
              [-74.95284242699995, 11.00649530700008 ],
              [-74.95281344299991, 11.006560904000082],
              [-74.95281689799992, 11.00661619500005 ],
              [-74.95280998399994, 11.006668995000041],
              [-74.9527993499999 , 11.006750201000045],
              [-74.95278418099991, 11.006866039000045],
              [-74.9527276689999 , 11.007059370000093],
              [-74.95265033599992, 11.007231881000052],
              [-74.95264438799995, 11.00732705900009 ],
              [-74.95267107099994, 11.007408680000083],
              [-74.9526949509999 , 11.007481724000058],
              [-74.95269792499994, 11.007495109000047],
              [-74.95270089999991, 11.007508493000046],
              [-74.95268578699995, 11.007523605000074],
              [-74.95268007899995, 11.007529313000077],
              [-74.95266818199991, 11.007527826000057],
              [-74.9526638339999 , 11.007527283000059],
              [-74.95265628499993, 11.007526339000037],
              [-74.9526388239999 , 11.007504513000072],
              [-74.95263248999993, 11.007496596000067],
              [-74.95261156499993, 11.007458668000083],
              [-74.95260992199991, 11.007459818000086],
              [-74.9525977799999 , 11.007431488000066],
              [-74.95257065399994, 11.007391513000073],
              [-74.95253856799991, 11.007360572000039],
              [-74.95253067899995, 11.007352965000052],
              [-74.95250312199994, 11.007346536000057],
              [-74.95249832499991, 11.007343338000055],
              [-74.9524451069999 , 11.007330033000073],
              [-74.9523954629999 , 11.007344035000074],
              [-74.95236893299995, 11.007351518000064],
              [-74.95232910899995, 11.007362751000073],
              [-74.95232025399991, 11.007370201000072],
              [-74.95231129799993, 11.007377735000091],
              [-74.95214172599992, 11.007520390000082],
              [-74.9520941369999 , 11.007582851000052],
              [-74.95209058499995, 11.007583412000088],
              [-74.95206675799994, 11.007596319000072],
              [-74.95204293099994, 11.007609225000067],
              [-74.95202572799991, 11.007618543000092],
              [-74.95201297999995, 11.007636754000089],
              [-74.95200490699995, 11.007648286000062],
              [-74.95197516399992, 11.007701824000037],
              [-74.95190675499992, 11.007758336000052],
              [-74.95177588399991, 11.007844592000083],
              [-74.9516777309999 , 11.007913001000077],
              [-74.95145465699994, 11.008055769000066],
              [-74.9514417389999 , 11.008068687000048],
              [-74.95138029899994, 11.008130127000072],
              [-74.95129701799993, 11.008195562000083],
              [-74.95121671099992, 11.008278843000085],
              [-74.95118768499992, 11.008291124000039],
              [-74.95117416999994, 11.00829684200005 ],
              [-74.95115332099994, 11.008321317000082],
              [-74.95113097699993, 11.008347546000039],
              [-74.9511185579999 , 11.008362124000087],
              [-74.9510897369999 , 11.008395749000044],
              [-74.95107337399992, 11.008414839000068],
              [-74.9510114819999 , 11.008487046000084],
              [-74.95088061199993, 11.008656583000061],
              [-74.95076163899995, 11.008748787000059],
              [-74.95075082799991, 11.00875710300005 ],
              [-74.95068430599991, 11.008808273000056],
              [-74.95058317899992, 11.008891555000048],
              [-74.95052735599995, 11.008923873000072],
              [-74.9505266669999 , 11.008924272000058],
              [-74.95052663099995, 11.008924326000056],
              [-74.95043148899993, 11.009067040000048],
              [-74.9503363099999 , 11.009209808000037],
              [-74.95033051599995, 11.009254234000082],
              [-74.95033333599991, 11.009254422000083],
              [-74.95033333599991, 11.009322832000066],
              [-74.95032441299992, 11.009355550000066],
              [-74.95028277199992, 11.009426933000043],
              [-74.95023220899992, 11.009477497000091],
              [-74.95023220899992, 11.00952211200007 ],
              [-74.95024707999994, 11.009572675000072],
              [-74.95023220899992, 11.009608367000055],
              [-74.95018759399994, 11.009650008000051],
              [-74.9501429789999 , 11.009706520000066],
              [-74.95008944099993, 11.009733289000053],
              [-74.95004780099993, 11.009751135000045],
              [-74.95001805699991, 11.009774930000049],
              [-74.94995857099991, 11.009810622000089],
              [-74.9499436989999 , 11.009849288000055],
              [-74.94992585299991, 11.009867134000046],
              [-74.94983959799993, 11.009846313000082],
              [-74.9497771369999 , 11.009849288000055],
              [-74.94962544599991, 11.009843339000042],
              [-74.94953325999995, 11.009814057000085],
              [-74.94950295799993, 11.009804431000077],
              [-74.94941530999995, 11.009776590000058],
              [-74.94938046899995, 11.009765523000056],
              [-74.94935630299995, 11.009764791000066],
              [-74.94933599199993, 11.009764175000043],
              [-74.94930861499995, 11.009763346000057],
              [-74.94929826999993, 11.00976303200008 ],
              [-74.94926578199994, 11.009754070000042],
              [-74.94924403399995, 11.009748071000047],
              [-74.94921201499994, 11.009739238000066],
              [-74.94916809699993, 11.009720695000055],
              [-74.94914341399993, 11.009710273000053],
              [-74.94909511399993, 11.009689880000053],
              [-74.9490916609999 , 11.009688422000067],
              [-74.94894432599995, 11.009626213000047],
              [-74.9487585729999 , 11.009623521000037],
              [-74.94861597199991, 11.009659172000056],
              [-74.94846963499992, 11.00970200200004 ],
              [-74.94840895899995, 11.00970200200004 ],
              [-74.94833757499993, 11.009723417000089],
              [-74.94827689899995, 11.009773386000063],
              [-74.9481412689999 , 11.009823354000048],
              [-74.94791997999994, 11.00988760000007 ],
              [-74.94781647299993, 11.009912584000062],
              [-74.94769155099993, 11.009919723000053],
              [-74.94752022999995, 11.009976830000085],
              [-74.94740244699994, 11.01003036800006 ],
              [-74.94732927799993, 11.010069629000043],
              [-74.9472561099999 , 11.010108890000083],
              [-74.94701340499995, 11.010155289000068],
              [-74.94691195599995, 11.010197400000038],
              [-74.94663507099995, 11.010312334000048],
              [-74.9465956759999 , 11.010347977000038],
              [-74.94641021199993, 11.01051577800007 ],
              [-74.94633525799992, 11.010697806000053],
              [-74.9462317519999 , 11.010844143000043],
              [-74.9459997539999 , 11.011090417000048],
              [-74.94586979699994, 11.011268402000042],
              [-74.9458234729999 , 11.011383109000064],
              [-74.94575208899994, 11.011472339000079],
              [-74.9457118659999 , 11.011501466000084],
              [-74.94564501299993, 11.011624030000064],
              [-74.94543383599995, 11.011963103000085],
              [-74.94534460599994, 11.012096948000078],
              [-74.94526429899992, 11.012230792000082],
              [-74.9452167099999 , 11.012317048000057],
              [-74.94515247599992, 11.01244970600004 ],
              [-74.9451038869999 , 11.012582339000062],
              [-74.94509432399991, 11.01259239300009 ],
              [-74.94509615099992, 11.01259787400005 ],
              [-74.94504261299994, 11.012708519000057],
              [-74.94498550599991, 11.01276919500009 ],
              [-74.9448784299999 , 11.012904824000088],
              [-74.94478563099995, 11.01307257600007 ],
              [-74.94470353999992, 11.013168944000086],
              [-74.94467141699994, 11.01321534400006 ],
              [-74.94465000199995, 11.013297435000084],
              [-74.94458932599991, 11.01336881900005 ],
              [-74.94455006499993, 11.013418788000081],
              [-74.94451794199995, 11.013475895000056],
              [-74.94445924099995, 11.013546335000058],
              [-74.94446083499992, 11.013547279000079],
              [-74.94441800499993, 11.013654354000039],
              [-74.94432877499992, 11.013782845000037],
              [-74.9442395449999 , 11.013947028000075],
              [-74.94415388399995, 11.014075519000073],
              [-74.94408249999992, 11.014296809000086],
              [-74.94406822399992, 11.014421731000084],
              [-74.94401111699995, 11.01481791100008 ],
              [-74.94395043999992, 11.014928556000086],
              [-74.94385407199991, 11.015028493000045],
              [-74.94380410399992, 11.01512129200006 ],
              [-74.94369345899992, 11.015360428000065],
              [-74.9435113159999 , 11.015596538000068],
              [-74.94350072199995, 11.015610271000071],
              [-74.94348185899992, 11.015623370000071],
              [-74.9433722309999 , 11.015699501000086],
              [-74.94336774099992, 11.01570048700006 ],
              [-74.94321508799993, 11.015848515000073],
              [-74.94292479399991, 11.016253023000047],
              [-74.94257368299992, 11.016740488000039],
              [-74.94244116899995, 11.016924464000056],
              [-74.94243462499992, 11.016933549000044],
              [-74.94243155799995, 11.016958850000037],
              [-74.94241558899995, 11.01709059400008 ],
              [-74.94244253399995, 11.017227767000065],
              [-74.94246155899992, 11.017324623000093],
              [-74.94255195499994, 11.017392420000078],
              [-74.94255463699994, 11.017399700000055],
              [-74.9426015329999 , 11.017416662000073],
              [-74.94265393199993, 11.017435615000068],
              [-74.94271044399994, 11.017483205000076],
              [-74.94271044399994, 11.017533170000092],
              [-74.94271044399994, 11.017566486000078],
              [-74.94270449499993, 11.017608126000084],
              [-74.94265987999995, 11.01764679300004 ],
              [-74.94259919399991, 11.017717593000043],
              [-74.94255280399994, 11.017771714000048],
              [-74.9424963809999 , 11.017859268000052],
              [-74.9424665489999 , 11.017905559000042],
              [-74.94245465199992, 11.01798586600006 ],
              [-74.94258063199993, 11.018058934000067],
              [-74.94260336799994, 11.018072121000046],
              [-74.94265690599991, 11.018167300000073],
              [-74.94262418799991, 11.018283298000085],
              [-74.94257957299993, 11.018390374000091],
              [-74.94246708999992, 11.018474737000076],
              [-74.9424665489999 , 11.018476630000066],
              [-74.94227024299994, 11.018536116000064],
              [-74.94185383799993, 11.018616423000083],
              [-74.94166431299993, 11.018694760000074],
              [-74.94163076299992, 11.01870862700008 ],
              [-74.94141363799991, 11.018714576000093],
              [-74.94123517799994, 11.018711601000064],
              [-74.94101507799991, 11.018661038000062],
              [-74.9409407199999 , 11.018669961000057],
              [-74.9408574389999 , 11.018681858000036],
              [-74.94069682499992, 11.01864319200007 ],
              [-74.94062246699991, 11.018619397000066],
              [-74.94051836499995, 11.018589654000039],
              [-74.9403577519999 , 11.018553962000055],
              [-74.94025067599995, 11.01851232100006 ],
              [-74.94018967599993, 11.018509484000049],
              [-74.94012277999991, 11.018506373000037],
              [-74.9398491419999 , 11.018539090000047],
              [-74.93972719499993, 11.018559911000068],
              [-74.93962309299991, 11.018592628000079],
              [-74.93957773099993, 11.018596752000064],
              [-74.93949222299995, 11.018604526000047],
              [-74.93935540399991, 11.018631295000091],
              [-74.9392572509999 , 11.01867293500004 ],
              [-74.93906094499994, 11.018666986000085],
              [-74.93894494699992, 11.018669961000057],
              [-74.9388497679999 , 11.018678884000053],
              [-74.93875161599993, 11.018714576000093],
              [-74.93869212899995, 11.01874134500008 ],
              [-74.93868769399995, 11.018737575000046],
              [-74.93864453999993, 11.018750268000076],
              [-74.93851366899992, 11.018768114000068],
              [-74.93840956799994, 11.018765139000038],
              [-74.93834252699992, 11.018757956000059],
              [-74.93813289199994, 11.018814132000045],
              [-74.93745760099995, 11.019093957000052],
              [-74.93733902899993, 11.019145545000072],
              [-74.93703910499994, 11.019258954000065],
              [-74.93683562799993, 11.01933589500004 ],
              [-74.93682148399995, 11.019341243000042],
              [-74.93679090899991, 11.01935280400005 ],
              [-74.93677185799993, 11.019360008000092],
              [-74.93664477999994, 11.019386791000045],
              [-74.93640573099992, 11.019447188000072],
              [-74.93631178099992, 11.019470925000064],
              [-74.93629361599994, 11.019475514000078],
              [-74.93628451099994, 11.01946711000005 ],
              [-74.93624643899994, 11.019445695000059],
              [-74.93623664099994, 11.019446875000085],
              [-74.9361476919999 , 11.019457592000037],
              [-74.93605859999991, 11.019463532000088],
              [-74.93606789599994, 11.019489959000055],
              [-74.93618196099993, 11.019481812000038],
              [-74.93618808499991, 11.019511096000087],
              [-74.936203        , 11.019515694000063],
              [-74.93624154699995, 11.019534253000074],
              [-74.93625725099992, 11.019567090000066],
              [-74.93626813499992, 11.019643275000078],
              [-74.9362672449999 , 11.019648467000081],
              [-74.93625725099992, 11.019704147000084],
              [-74.93619474199994, 11.019821353000054],
              [-74.93615246899992, 11.019838120000088],
              [-74.93610033599992, 11.019858973000055],
              [-74.93604322899995, 11.019878009000081],
              [-74.93603180699995, 11.019879913000068],
              [-74.93602997399995, 11.019745838000063],
              [-74.93601702599994, 11.01969482900006 ],
              [-74.93597469999992, 11.019691459000057],
              [-74.93596983299994, 11.019673612000076],
              [-74.93567190399995, 11.01974935700008 ],
              [-74.9356814219999 , 11.019783621000045],
              [-74.93548535399992, 11.019808367000053],
              [-74.93549342199992, 11.019889049000085],
              [-74.93510667299995, 11.019959862000064],
              [-74.93508213399991, 11.019966559000068],
              [-74.93510485399992, 11.020064932000082],
              [-74.93508530399993, 11.020070916000066],
              [-74.93496793399993, 11.020108922000077],
              [-74.93466121199992, 11.020211202000041],
              [-74.93463759799994, 11.02022668300009 ],
              [-74.93450807699992, 11.020263943000089],
              [-74.93433551299995, 11.02029983400007 ],
              [-74.93430589999991, 11.02029520700006 ],
              [-74.9343000099999 , 11.020240517000047],
              [-74.93422309699992, 11.020244937000086],
              [-74.9342196099999 , 11.020242225000061],
              [-74.93416926099991, 11.020278827000084],
              [-74.93408810599993, 11.020324606000088],
              [-74.93372439099994, 11.020370210000067],
              [-74.93368849099994, 11.020382533000088],
              [-74.93370964199994, 11.020587904000081],
              [-74.93366992099993, 11.020600451000064],
              [-74.93320676299993, 11.020741873000077],
              [-74.9329573949999 , 11.020814209000036],
              [-74.93296881099991, 11.02086367700008 ],
              [-74.9329300309999 , 11.020888169000045],
              [-74.9328848209999 , 11.020897687000058],
              [-74.93286102699994, 11.020888169000045],
              [-74.93280021399994, 11.020898006000039],
              [-74.93266115199992, 11.02098810700005 ],
              [-74.93236133999994, 11.021118977000071],
              [-74.93226378199995, 11.02116656600009 ],
              [-74.93222571099994, 11.02117370500008 ],
              [-74.93215670599994, 11.021233191000078],
              [-74.93210911699992, 11.02127364200004 ],
              [-74.93194493499993, 11.021359303000054],
              [-74.93167843499992, 11.021483035000074],
              [-74.93154171599991, 11.02153620300004 ],
              [-74.93152284899992, 11.021485890000065],
              [-74.93140720699995, 11.021525865000058],
              [-74.93142749599991, 11.02158055700005 ],
              [-74.93144004399994, 11.021614381000063],
              [-74.93133296799994, 11.021650073000046],
              [-74.93131843799995, 11.021619075000046],
              [-74.93129013799995, 11.02155870100006 ],
              [-74.93119295699995, 11.021591459000092],
              [-74.93116914299992, 11.021601819000068],
              [-74.93112354499993, 11.021496293000041],
              [-74.93108092799991, 11.021400166000092],
              [-74.93107360099992, 11.021383638000088],
              [-74.93105368099992, 11.021393942000088],
              [-74.93071140099994, 11.02156709500008 ],
              [-74.9307049379999 , 11.02157036500006 ],
              [-74.9305942929999 , 11.02162633800009 ],
              [-74.93002322299992, 11.021905925000056],
              [-74.93009163199991, 11.02210520400007 ],
              [-74.92952948399994, 11.022417509000093],
              [-74.92933317899991, 11.022468072000038],
              [-74.92887215799993, 11.022774428000048],
              [-74.92891159299995, 11.022830763000059],
              [-74.92882902999992, 11.022872045000042],
              [-74.9288260269999 , 11.022873744000037],
              [-74.92852207999994, 11.023045746000037],
              [-74.92846728899991, 11.02307475200007 ],
              [-74.9284411779999 , 11.023088576000077],
              [-74.92844385899991, 11.023090580000087],
              [-74.92844066799995, 11.023095392000073],
              [-74.92843475599994, 11.023104308000086],
              [-74.92842074499993, 11.023125440000058],
              [-74.92838718299993, 11.023176058000047],
              [-74.92827164099992, 11.023350317000052],
              [-74.92815861699995, 11.023513905000073],
              [-74.92809875299992, 11.023607026000093],
              [-74.92807830999993, 11.02363882600008 ],
              [-74.92800990099994, 11.023734005000051],
              [-74.9279514239999 , 11.02381790600009 ],
              [-74.9279414909999 , 11.023832158000062],
              [-74.92783441599994, 11.023927336000042],
              [-74.92726310699993, 11.024318170000072],
              [-74.92722146599993, 11.024249760000089],
              [-74.92651952499995, 11.024695909000059],
              [-74.92599009499992, 11.02505282800007 ],
              [-74.92524353899995, 11.025490054000045],
              [-74.92508191899992, 11.025589795000087],
              [-74.9251188479999 , 11.025653312000088],
              [-74.9250780079999 , 11.025698527000088],
              [-74.92503803299991, 11.025791802000072],
              [-74.9250114219999 , 11.025848654000072],
              [-74.92498582399992, 11.025871596000059],
              [-74.92488661999994, 11.025924306000093],
              [-74.9247914419999 , 11.025858871000082],
              [-74.92370872499993, 11.02670194600006 ],
              [-74.92364927499995, 11.026721948000045],
              [-74.92350613999992, 11.026926049000053],
              [-74.92322655299995, 11.027116406000061],
              [-74.92289045399991, 11.027455479000082],
              [-74.92267630299995, 11.027717220000056],
              [-74.92259599599993, 11.027824296000063],
              [-74.92249189499995, 11.02789567900004 ],
              [-74.92240563899992, 11.027934346000052],
              [-74.92231343499992, 11.027984909000054],
              [-74.92223907699992, 11.028077113000052],
              [-74.92220041099995, 11.028133625000066],
              [-74.92216769299995, 11.028243676000045],
              [-74.92216142299992, 11.028292941000075],
              [-74.92177210799991, 11.028761208000049],
              [-74.92168585199994, 11.02878202900007 ],
              [-74.92163528899994, 11.028787977000093],
              [-74.92154605899992, 11.028963463000082],
              [-74.9214984699999 , 11.029106230000082],
              [-74.92142411099991, 11.029338228000086],
              [-74.92135213299991, 11.02950479000009 ],
              [-74.92153480499991, 11.029564657000037],
              [-74.92151155699992, 11.029616625000074],
              [-74.92141399899992, 11.02979270500009 ],
              [-74.92135927099991, 11.02994261100008 ],
              [-74.92131644099993, 11.030259079000075],
              [-74.92130389599993, 11.03036122900005 ],
              [-74.92128768599991, 11.030409860000077],
              [-74.92122867499995, 11.03053359200004 ],
              [-74.92114301399994, 11.030611639000085],
              [-74.92108209999992, 11.03064590300005 ],
              [-74.92101014199994, 11.030683202000091],
              [-74.92089792999991, 11.03083935300009 ],
              [-74.92076111099993, 11.031083248000073],
              [-74.92074180999992, 11.031141817000048],
              [-74.92068007299991, 11.031329157000073],
              [-74.9206748549999 , 11.031344988000058],
              [-74.92057967699992, 11.031684062000068],
              [-74.92042798599994, 11.032165903000077],
              [-74.92039824299991, 11.03227892700005 ],
              [-74.92037742299993, 11.03235625900004 ],
              [-74.92037147399992, 11.032475232000081],
              [-74.92037147399992, 11.032591231000083],
              [-74.92035660199991, 11.032686410000053],
              [-74.92034767899992, 11.032745896000051],
              [-74.92028819299992, 11.032971945000043],
              [-74.92026142399993, 11.033206917000086],
              [-74.92023120499994, 11.033421544000078],
              [-74.92020645899993, 11.033549083000082],
              [-74.92019694099992, 11.033627129000081],
              [-74.9201893259999 , 11.033720404000064],
              [-74.92018361599992, 11.033825101000048],
              [-74.9201626759999 , 11.033922183000072],
              [-74.92015886899992, 11.034004036000056],
              [-74.92016838699993, 11.034095407000052],
              [-74.92016838699993, 11.034167743000069],
              [-74.9201493509999 , 11.034278150000091],
              [-74.92014554399992, 11.034358100000077],
              [-74.92014364099992, 11.034443761000091],
              [-74.92014831599994, 11.034582685000089],
              [-74.92003811099994, 11.034892309000043],
              [-74.9200262139999 , 11.03503210200006 ],
              [-74.92002584399995, 11.035079833000054],
              [-74.92004496299995, 11.03507983600008 ],
              [-74.92007975199994, 11.035090332000038],
              [-74.92010479599992, 11.035071549000065],
              [-74.92015238499994, 11.035066790000087],
              [-74.92016722199992, 11.035069572000054],
              [-74.92022852799994, 11.035081067000078],
              [-74.9202344169999 , 11.03525815100005 ],
              [-74.92026415999993, 11.03580542700007 ],
              [-74.92032067199995, 11.03602850100009 ],
              [-74.92036244499991, 11.036156605000087],
              [-74.92036528699992, 11.036165320000066],
              [-74.9204188249999 , 11.036290242000064],
              [-74.92049020899992, 11.036400292000053],
              [-74.92057348999992, 11.036513317000072],
              [-74.9206508229999 , 11.036620392000088],
              [-74.9206613209999 , 11.036640514000055],
              [-74.92069246299991, 11.036861313000088],
              [-74.9207192319999 , 11.036995157000092],
              [-74.92078466699991, 11.037105208000071],
              [-74.92082932299991, 11.037171129000058],
              [-74.92087985499995, 11.037230139000087],
              [-74.9210027549999 , 11.037373660000071],
              [-74.92112802699995, 11.037519951000093],
              [-74.92122623599994, 11.037596871000062],
              [-74.92148321799993, 11.037969970000063],
              [-74.92156096699995, 11.03814324700005 ],
              [-74.92159665799994, 11.038271143000088],
              [-74.9219468579999 , 11.038477262000072],
              [-74.92195549099995, 11.038543860000061],
              [-74.9219794739999 , 11.038665490000085],
              [-74.9220099499999 , 11.038798456000052],
              [-74.92203197999993, 11.038891276000072],
              [-74.92204012499991, 11.038925593000045],
              [-74.92206590099994, 11.039082249000046],
              [-74.92207479699994, 11.039140301000089],
              [-74.92207490999994, 11.039257406000047],
              [-74.92208879599991, 11.039297612000041],
              [-74.9221543349999 , 11.03942277100009 ],
              [-74.92224752599992, 11.03951124300005 ],
              [-74.92230476599991, 11.039590014000055],
              [-74.92230686199991, 11.039593677000084],
              [-74.92236319999995, 11.039692140000056],
              [-74.92240306799994, 11.039777743000059],
              [-74.92244352799992, 11.03987501100005 ],
              [-74.92245741499994, 11.039915226000062],
              [-74.92246719799994, 11.039990795000051],
              [-74.92248498899994, 11.040106901000058],
              [-74.92248502699994, 11.040118280000058],
              [-74.9224853959999 , 11.040229840000052],
              [-74.92247812099993, 11.040318073000037],
              [-74.92245855499993, 11.040512397000043],
              [-74.92243016899994, 11.040716529000065],
              [-74.92242847699993, 11.040728693000062],
              [-74.9224107039999 , 11.040726857000038],
              [-74.92241148099993, 11.040786645000082],
              [-74.92241224399993, 11.040845425000043],
              [-74.92241224399993, 11.040845453000088],
              [-74.92241252699995, 11.04086723800009 ],
              [-74.92241367899993, 11.040955881000059],
              [-74.92240975599992, 11.040981830000078],
              [-74.92237501199992, 11.041211674000067],
              [-74.92234813699991, 11.041324935000091],
              [-74.9223419459999 , 11.041351028000065],
              [-74.92234193399992, 11.041351078000048],
              [-74.9223333729999 , 11.041387158000077],
              [-74.9223213059999 , 11.041495754000039],
              [-74.9223213009999 , 11.04149579600005 ],
              [-74.92231899199993, 11.041516576000049],
              [-74.9223158179999 , 11.041545149000058],
              [-74.9223095779999 , 11.041601309000043],
              [-74.92230215099994, 11.041613346000076],
              [-74.92222332099993, 11.04174110300005 ],
              [-74.92221230799993, 11.04182921100005 ],
              [-74.92221142499994, 11.041836282000077],
              [-74.9222084509999 , 11.041964178000057],
              [-74.92220804899995, 11.041974433000064],
              [-74.9222064789999 , 11.042014475000087],
              [-74.92220250199995, 11.042115868000053],
              [-74.92219926899992, 11.042583111000056],
              [-74.92219687399995, 11.042929253000068],
              [-74.92219655299994, 11.04297544800005 ],
              [-74.92218867299994, 11.042992164000054],
              [-74.92218012099994, 11.043010304000063],
              [-74.92209839999992, 11.04318365100005 ],
              [-74.92209957799992, 11.043287323000072],
              [-74.92210434899994, 11.043707132000065],
              [-74.92202106799994, 11.043772569000055],
              [-74.92194076099992, 11.044016463000048],
              [-74.92195408099991, 11.044160941000086],
              [-74.92195850299993, 11.044208894000064],
              [-74.92197942799993, 11.044435843000088],
              [-74.92197687499993, 11.044449799000063],
              [-74.92193481299995, 11.04467973700008 ],
              [-74.92177419899991, 11.044968248000089],
              [-74.92165656399993, 11.045116629000063],
              [-74.92151245799994, 11.045298397000067],
              [-74.9214706169999 , 11.045336890000044],
              [-74.92146803599991, 11.045339265000052],
              [-74.92144164699994, 11.045363543000065],
              [-74.9214245199999 , 11.045379300000093],
              [-74.92128938399992, 11.045503625000038],
              [-74.92114155799993, 11.045584511000072],
              [-74.92113748199995, 11.04558674100008 ],
              [-74.92113554999992, 11.045587798000042],
              [-74.92113331899992, 11.04558901900009 ],
              [-74.92113174399992, 11.045589881000069],
              [-74.92099451099995, 11.04562419000007 ],
              [-74.92099003299995, 11.04562530800007 ],
              [-74.92096518199992, 11.045631522000065],
              [-74.9208581069999 , 11.045649368000056],
              [-74.92082129799991, 11.045655794000083],
              [-74.92077241699991, 11.04566432900009 ],
              [-74.92067072399993, 11.045682085000067],
              [-74.92030190799994, 11.045693982000046],
              [-74.9201139729999 , 11.045684718000075],
              [-74.92008173599993, 11.045683129000054],
              [-74.92002459499992, 11.045680312000059],
              [-74.91987955299993, 11.04567316300006 ],
              [-74.91959699199992, 11.045601779000037],
              [-74.91931740599995, 11.045580959000063],
              [-74.91926122099994, 11.045582505000084],
              [-74.91899320399995, 11.045589881000069],
              [-74.91885935899995, 11.04554526700008 ],
              [-74.91871361599993, 11.045533370000044],
              [-74.91860951599995, 11.04554229200005 ],
              [-74.91851136299994, 11.045586908000075],
              [-74.91848470499991, 11.045586908000075],
              [-74.91846144599992, 11.045586908000075],
              [-74.91841321099992, 11.045586908000075],
              [-74.91829423599995, 11.045563113000071],
              [-74.91829076099992, 11.045562564000079],
              [-74.91825045599995, 11.045556200000078],
              [-74.91818121299991, 11.04554526700008 ],
              [-74.91799970699992, 11.045519599000045],
              [-74.91788675499993, 11.045503625000038],
              [-74.91787501699991, 11.045503275000044],
              [-74.91771378899995, 11.045498462000069],
              [-74.91768747499992, 11.04549767800006 ],
              [-74.9175566049999 , 11.045521471000086],
              [-74.91746737499994, 11.045521471000086],
              [-74.91740580899994, 11.045486291000088],
              [-74.91740491399992, 11.045485779000046],
              [-74.91733055599991, 11.045450089000042],
              [-74.91727701799994, 11.04538167800007 ],
              [-74.9172234799999 , 11.045310294000046],
              [-74.91713722399993, 11.04523891000008 ],
              [-74.91707351999992, 11.045209789000069],
              [-74.91706410499995, 11.045196279000038],
              [-74.9170479249999 , 11.045184619000054],
              [-74.91699152899992, 11.045171303000075],
              [-74.9169108669999 , 11.045152258000087],
              [-74.91689243099995, 11.045151008000062],
              [-74.91686855499995, 11.045149389000073],
              [-74.9168553749999 , 11.045148496000081],
              [-74.91679855699994, 11.04514464500005 ],
              [-74.91677065499994, 11.045144127000071],
              [-74.91676740499992, 11.04514406700008 ],
              [-74.91669576499993, 11.045142741000063],
              [-74.91661757199995, 11.045135813000059],
              [-74.9165768169999 , 11.045132202000048],
              [-74.91654538299991, 11.045129417000055],
              [-74.91642545699995, 11.045108477000042],
              [-74.9162389089999 , 11.04502662300007 ],
              [-74.91616447699994, 11.044987032000051],
              [-74.91614943999991, 11.04497903500004 ],
              [-74.9160466479999 , 11.044950481000058],
              [-74.91596479499992, 11.044940962000055],
              [-74.91574410699991, 11.04480303400004 ],
              [-74.91573229199992, 11.044802017000052],
              [-74.91562078899995, 11.044813961000045],
              [-74.91518308199994, 11.04479691000006 ],
              [-74.91487199499994, 11.044831446000046],
              [-74.91446974099995, 11.044818299000042],
              [-74.91405283699993, 11.044753287000049],
              [-74.91362340499995, 11.044788423000057],
              [-74.91351859399992, 11.044817548000083],
              [-74.91331886099994, 11.044840147000059],
              [-74.91307196699995, 11.044865324000057],
              [-74.91294229099992, 11.04487240900005 ],
              [-74.91268077599995, 11.044845705000057],
              [-74.91256814799993, 11.04484013800004 ],
              [-74.91228390999993, 11.044832227000086],
              [-74.91217063899995, 11.044814986000063],
              [-74.91203967599995, 11.044798712000045],
              [-74.91148528399992, 11.044717693000052],
              [-74.91129869699995, 11.044657589000053],
              [-74.91117465199994, 11.044617134000077],
              [-74.91116022099993, 11.04461242800005 ],
              [-74.91084875199994, 11.044541541000058],
              [-74.9106810429999 , 11.044503820000045],
              [-74.91062078399995, 11.044483673000059],
              [-74.91049996599992, 11.044437534000053],
              [-74.90994513099992, 11.044245109000087],
              [-74.90981928699995, 11.044216799000083],
              [-74.90964619399995, 11.044191052000087],
              [-74.90946126799992, 11.044165943000053],
              [-74.90934160699993, 11.044143147000057],
              [-74.9092816129999 , 11.044128822000062],
              [-74.90882741999991, 11.044053868000049],
              [-74.90864862199993, 11.04403426500005 ],
              [-74.90848754399991, 11.044013699000061],
              [-74.90832146199995, 11.04401099000006 ],
              [-74.90812581799992, 11.04400987300005 ],
              [-74.90794196699994, 11.044008125000062],
              [-74.90785330299991, 11.044012937000048],
              [-74.90769929499993, 11.044015419000061],
              [-74.90757513999995, 11.044022150000046],
              [-74.90717367699995, 11.04405564600006 ],
              [-74.90698973499991, 11.044053889000054],
              [-74.9068288069999 , 11.044039163000036],
              [-74.90665134799991, 11.044048789000044],
              [-74.90655700799994, 11.044059770000047],
              [-74.9064330889999 , 11.044072347000053],
              [-74.90632535399993, 11.044054733000053],
              [-74.90621669099994, 11.044019569000056],
              [-74.90615598799991, 11.043993538000052],
              [-74.9060887359999 , 11.043956138000055],
              [-74.90575553899993, 11.043827566000061],
              [-74.90554374899995, 11.043751058000055],
              [-74.90538737899993, 11.043712600000049],
              [-74.90526120899995, 11.04368422500005 ],
              [-74.90511073199991, 11.043645444000049],
              [-74.90496647099991, 11.043612173000042],
              [-74.90482189199992, 11.04357305800005 ],
              [-74.90472591399993, 11.043554778000043],
              [-74.90462400799993, 11.043536824000057],
              [-74.90442331199995, 11.043447927000045],
              [-74.90429584799995, 11.043396132000055],
              [-74.90420514499993, 11.04336582700006 ],
              [-74.90409666399995, 11.043336485000054],
              [-74.9039822549999 , 11.043307454000058],
              [-74.90382636299995, 11.043280655000046],
              [-74.90356287299994, 11.04324205000006 ],
              [-74.90350861399992, 11.043227373000093],
              [-74.90340039799992, 11.043203866000056],
              [-74.90326878399992, 11.043187484000043],
              [-74.90306540799992, 11.043163227000036],
              [-74.90267637799991, 11.04311375900005 ],
              [-74.90251506599992, 11.043098967000049],
              [-74.90235434499994, 11.04309588700005 ],
              [-74.90217019999994, 11.043099931000086],
              [-74.9019626189999 , 11.043111108000062],
              [-74.90183713699992, 11.043100251000055],
              [-74.9016517089999 , 11.043080887000087],
              [-74.90154399699992, 11.043069066000044],
              [-74.90123269399993, 11.043032977000053],
              [-74.90111672199993, 11.043051485000092],
              [-74.9010149419999 , 11.043030328000043],
              [-74.9007001569999 , 11.043042136000054],
              [-74.90012865299991, 11.043038997000053],
              [-74.89964043599991, 11.043037043000083],
              [-74.89896458899995, 11.04309266100006 ],
              [-74.89846013099992, 11.043163235000065],
              [-74.89840290299992, 11.043171241000039],
              [-74.89819445899991, 11.043171208000047],
              [-74.8979293129999 , 11.043227247000061],
              [-74.89765940799992, 11.04330710000005 ],
              [-74.89742421199992, 11.043367354000054],
              [-74.89713908599992, 11.043383384000037],
              [-74.8968355099999 , 11.043388682000057],
              [-74.89599058699991, 11.043412660000058],
              [-74.8951019939999 , 11.043286143000046],
              [-74.8943112849999 , 11.04321290300004 ],
              [-74.89366731099994, 11.043213783000056],
              [-74.89357905899993, 11.043235752000044],
              [-74.89299591399993, 11.043380916000046],
              [-74.8924947289999 , 11.043490857000052],
              [-74.89234696299991, 11.043523269000048],
              [-74.89155267899991, 11.043626739000047],
              [-74.88998934099993, 11.043988255000045],
              [-74.8896894579999 , 11.044011522000062],
              [-74.88924525499993, 11.04395101700004 ],
              [-74.88919887999992, 11.04394773200005 ],
              [-74.88911510399993, 11.043938681000043],
              [-74.88865248699994, 11.043888698000046],
              [-74.8883749119999 , 11.043979530000058],
              [-74.88784019799994, 11.044078350000063],
              [-74.88753430299994, 11.044196295000063],
              [-74.8874628019999 , 11.044217421000042],
              [-74.88738280599995, 11.044235018000052],
              [-74.8872599579999 , 11.044200166000053],
              [-74.88680362999992, 11.044179529000075],
              [-74.88665853599991, 11.044169277000037],
              [-74.88655988099993, 11.04416229900005 ],
              [-74.88639338099995, 11.044205783000052],
              [-74.88628762999991, 11.044200610000075],
              [-74.88625967199994, 11.044199242000047],
              [-74.88595861999994, 11.044207063000044],
              [-74.88584953799995, 11.044222618000049],
              [-74.88570292799994, 11.04423261100004 ],
              [-74.8855252109999 , 11.044228783000051],
              [-74.88536073099993, 11.044243349000055],
              [-74.8851294179999 , 11.044293912000057],
              [-74.88487010099993, 11.04432797600009 ],
              [-74.88456241599994, 11.044344064000086],
              [-74.8843777109999 , 11.044395034000047],
              [-74.88423823799991, 11.044425924000052],
              [-74.8841261739999 , 11.044441300000074],
              [-74.88390781499993, 11.044472452000036],
              [-74.88375627499994, 11.044508309000037],
              [-74.88360805299993, 11.04453860700005 ],
              [-74.88338838399994, 11.044587138000054],
              [-74.88329349799994, 11.044606634000047],
              [-74.88307940499993, 11.044658476000052],
              [-74.88295813199994, 11.044679040000062],
              [-74.88266278399993, 11.044763016000047],
              [-74.88247061999994, 11.044833893000089],
              [-74.8822977879999 , 11.044879945000048],
              [-74.88218767799992, 11.04490711200009 ],
              [-74.88198364199991, 11.044980088000045],
              [-74.88180034299995, 11.045048706000046],
              [-74.88159251699994, 11.045133085000089],
              [-74.88142686099991, 11.045200046000048],
              [-74.8811963089999 , 11.045314870000084],
              [-74.8810337029999 , 11.045419929000047],
              [-74.88087609699994, 11.045496212000046],
              [-74.8806666989999 , 11.04560090700005 ],
              [-74.8805155149999 , 11.045668922000061],
              [-74.88035635999995, 11.045806301000084],
              [-74.88019452999993, 11.045899810000037],
              [-74.88010237299994, 11.045960348000051],
              [-74.87993511399992, 11.046047645000044],
              [-74.87972874899992, 11.046149696000043],
              [-74.87953108699992, 11.046252375000051],
              [-74.87943370699992, 11.046303814000055],
              [-74.87920064999992, 11.046409853000057],
              [-74.87901480699992, 11.046510475000048],
              [-74.87881770599995, 11.046604484000056],
              [-74.87852465299994, 11.046729680000055],
              [-74.87832248899991, 11.046852491000038],
              [-74.8782106569999 , 11.046940850000055],
              [-74.8780573919999 , 11.047035049000044],
              [-74.87790602699994, 11.047103177000054],
              [-74.87774903099995, 11.047167978000061],
              [-74.87753353699992, 11.047231620000048],
              [-74.87729092899991, 11.04730502600006 ],
              [-74.8770780879999 , 11.04737179600005 ],
              [-74.8769339559999 , 11.047420032000048],
              [-74.87667253099994, 11.047509652000087],
              [-74.87639670099992, 11.047595371000057],
              [-74.87621981799992, 11.047650085000043],
              [-74.87606396199993, 11.047697536000044],
              [-74.87580454399995, 11.047758181000063],
              [-74.87557028999993, 11.04783515400004 ],
              [-74.87534369499991, 11.047927271000049],
              [-74.8752213489999 , 11.047956662000047],
              [-74.87495249099993, 11.047984591000045],
              [-74.87479716699994, 11.048023352000087],
              [-74.87456136799995, 11.048079857000062],
              [-74.87428908299995, 11.048113403000059],
              [-74.87402800799993, 11.048153566000053],
              [-74.87394289399992, 11.048179896000079],
              [-74.87385093299991, 11.048208343000056],
              [-74.87370238799991, 11.048273863000077],
              [-74.87374772299995, 11.04815254600004 ],
              [-74.87363916699991, 11.047935118000055],
              [-74.87327714499992, 11.048104858000045],
              [-74.87303396899995, 11.04819294300006 ],
              [-74.87283499199992, 11.048259047000045],
              [-74.87265808199993, 11.04830323300007 ],
              [-74.87243679699992, 11.048303820000058],
              [-74.87254690599991, 11.048106792000056],
              [-74.87272351899992, 11.047953309000093]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08606",
      "properties": {"id": "08606", "iso": "REP", "name": "REPELÓN"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-75.05708717899995, 10.593421177000039],
              [-75.0560530539999 , 10.592277420000073],
              [-75.05560991699991, 10.591787303000046],
              [-75.05286481599995, 10.590684682000074],
              [-75.05126481199994, 10.590042008000069],
              [-75.04512898299993, 10.584112168000047],
              [-75.04182063299993, 10.57946676000006 ],
              [-75.04105337899995, 10.578673622000053],
              [-75.0397750329999 , 10.577352147000056],
              [-75.03683039799995, 10.57192963500006 ],
              [-75.03581391699993, 10.569653562000042],
              [-75.03434715199995, 10.564759265000077],
              [-75.03404912499991, 10.563764809000077],
              [-75.03399549399995, 10.563585854000053],
              [-75.03386439899992, 10.562759529000061],
              [-75.03372510499992, 10.562506670000062],
              [-75.03304961599991, 10.561180082000078],
              [-75.03237034299991, 10.559846064000055],
              [-75.03202008799991, 10.55780511800009 ],
              [-75.03195930399994, 10.557450914000071],
              [-75.03171004399991, 10.555998441000042],
              [-75.03110425299991, 10.553891847000045],
              [-75.03101573799995, 10.553584035000085],
              [-75.03053024699994, 10.551895742000056],
              [-75.03014230699995, 10.549820436000061],
              [-75.02978854499992, 10.548236966000047],
              [-75.02934654099994, 10.546509841000045],
              [-75.02879381799994, 10.543394246000048],
              [-75.02868671499994, 10.542790521000086],
              [-75.02789447799995, 10.538173352000058],
              [-75.02685038599992, 10.535995028000059],
              [-75.0270959849999 , 10.53150306200007 ],
              [-75.02734275499995, 10.527396052000086],
              [-75.02720326999992, 10.52418844400006 ],
              [-75.02717980499995, 10.523750617000076],
              [-75.02707874899994, 10.521864984000047],
              [-75.02707153099993, 10.521730286000093],
              [-75.02706574899992, 10.521622439000055],
              [-75.02708951499994, 10.520838454000057],
              [-75.02708116499991, 10.520754281000052],
              [-75.02696701299993, 10.520152416000087],
              [-75.02625186299991, 10.513373269000056],
              [-75.0256028309999 , 10.507220566000058],
              [-75.0233827749999 , 10.486172146000058],
              [-75.0233796249999 , 10.486142266000058],
              [-75.02329382999994, 10.48532877200006 ],
              [-75.02307454299995, 10.485177021000084],
              [-75.02282074699991, 10.485001389000047],
              [-75.02256070499993, 10.484786520000057],
              [-75.02268138499994, 10.48473967800004 ],
              [-75.0234143209999 , 10.484569782000051],
              [-75.0238890899999 , 10.484413595000092],
              [-75.02434881099992, 10.48407078200006 ],
              [-75.02470508099992, 10.48378542000006 ],
              [-75.02478029499991, 10.483738005000077],
              [-75.02489929899991, 10.483662982000055],
              [-75.02505916799993, 10.483377180000048],
              [-75.0250940869999 , 10.483181914000056],
              [-75.02516384099994, 10.482929340000055],
              [-75.02521089999993, 10.482832471000052],
              [-75.02528767699994, 10.482735742000045],
              [-75.02543564199993, 10.482637657000055],
              [-75.02557015299993, 10.482606050000072],
              [-75.0261110109999 , 10.48251676600006 ],
              [-75.02657572899994, 10.482480830000043],
              [-75.0271803249999 , 10.482406837000042],
              [-75.0278763199999 , 10.48218103000005 ],
              [-75.02874365699995, 10.481853458000046],
              [-75.02963127499993, 10.481561168000042],
              [-75.03010667699994, 10.481441346000054],
              [-75.03089298799995, 10.48134261100006 ],
              [-75.03143928299994, 10.481320916000072],
              [-75.03160431899994, 10.48131436500006 ],
              [-75.03263906899991, 10.481216363000044],
              [-75.03343631299992, 10.481190803000061],
              [-75.03357612499991, 10.481186323000088],
              [-75.03399579699993, 10.481220054000062],
              [-75.03489813599992, 10.481308648000038],
              [-75.0354563869999 , 10.48124948900005 ],
              [-75.03583408399993, 10.481043721000049],
              [-75.03613952599994, 10.48080976500006 ],
              [-75.03615241099993, 10.480829379000056],
              [-75.03624658599995, 10.480732849000049],
              [-75.03642777699991, 10.480592081000054],
              [-75.03644300199994, 10.480580253000085],
              [-75.0365746409999 , 10.48050324400009 ],
              [-75.03657488099992, 10.480580433000057],
              [-75.03657519399991, 10.480681785000058],
              [-75.03673354099993, 10.480567867000047],
              [-75.0367686379999 , 10.480542619000062],
              [-75.03695324599994, 10.480352445000051],
              [-75.03704645399995, 10.48025642500005 ],
              [-75.03731011999992, 10.480055880000066],
              [-75.03750321099994, 10.479940140000053],
              [-75.03782368299994, 10.479748045000065],
              [-75.03786597599992, 10.479722692000053],
              [-75.03797200299994, 10.479659137000056],
              [-75.0380185969999 , 10.479635706000067],
              [-75.0383635039999 , 10.479462247000072],
              [-75.0385333349999 , 10.479376837000075],
              [-75.03878715699994, 10.479142934000038],
              [-75.0388543649999 , 10.47908100300009 ],
              [-75.03910352899993, 10.478900273000079],
              [-75.03934818199991, 10.478722812000058],
              [-75.04015352599993, 10.478102104000072],
              [-75.04054188299995, 10.477825083000084],
              [-75.0410451919999 , 10.477428816000042],
              [-75.04114124099993, 10.477299370000082],
              [-75.04145192699991, 10.476880657000038],
              [-75.04164459799995, 10.476588284000059],
              [-75.04170824099992, 10.476491705000058],
              [-75.04178793799991, 10.476370771000063],
              [-75.04197322699991, 10.47608960000008 ],
              [-75.04230350099994, 10.475679591000073],
              [-75.04248576099991, 10.475468965000061],
              [-75.04281101899994, 10.47509307900009 ],
              [-75.0431063609999 , 10.474673146000043],
              [-75.04314028099992, 10.47462491300007 ],
              [-75.0432681389999 , 10.474443118000067],
              [-75.04330376199994, 10.474392468000076],
              [-75.04337492899992, 10.474304731000075],
              [-75.04398825999994, 10.473548597000047],
              [-75.04456754899991, 10.472866741000075],
              [-75.04485379399995, 10.47249436900006 ],
              [-75.04517892899992, 10.472071405000065],
              [-75.04574638899993, 10.47120398800007 ],
              [-75.0459861519999 , 10.470893296000042],
              [-75.04640383299994, 10.470352054000045],
              [-75.04656525799993, 10.470131174000073],
              [-75.04688662399991, 10.46969144700006 ],
              [-75.04716592999995, 10.469343682000044],
              [-75.04733452599993, 10.469082985000057],
              [-75.04739219899994, 10.46884575200005 ],
              [-75.04735657299995, 10.468660846000091],
              [-75.04712212899994, 10.468412946000058],
              [-75.04686443199995, 10.468199806000086],
              [-75.04648361699992, 10.467842501000064],
              [-75.0462372579999 , 10.467519475000074],
              [-75.04599663499994, 10.467161736000037],
              [-75.04582636499993, 10.46688473100005 ],
              [-75.04581414099994, 10.466711315000055],
              [-75.04582497699994, 10.466439535000063],
              [-75.04582610499995, 10.466434395000078],
              [-75.0458922489999 , 10.466132835000053],
              [-75.0458998499999 , 10.466098181000064],
              [-75.04593451799991, 10.465976654000087],
              [-75.0458277269999 , 10.465946183000085],
              [-75.04583077299992, 10.465932359000078],
              [-75.04590331599991, 10.465603098000088],
              [-75.04595847399992, 10.465352736000057],
              [-75.04608413999995, 10.464882784000054],
              [-75.04615751599994, 10.464608387000055],
              [-75.04629404799994, 10.464048053000056],
              [-75.04634042499993, 10.463857721000068],
              [-75.04637087799995, 10.46373273900008 ],
              [-75.04656774499995, 10.462986510000064],
              [-75.04663783699993, 10.462720826000066],
              [-75.0467681909999 , 10.462221043000056],
              [-75.04678212899995, 10.462167617000091],
              [-75.04681841299993, 10.46202850800006 ],
              [-75.04691446399994, 10.461660265000091],
              [-75.04692323699993, 10.461629619000064],
              [-75.0469294959999 , 10.461607752000077],
              [-75.04697815799994, 10.461437747000048],
              [-75.04700233299991, 10.46135329100008 ],
              [-75.04712336299991, 10.460930469000061],
              [-75.0473951269999 , 10.459884510000052],
              [-75.04744405899993, 10.459705064000048],
              [-75.04745597499993, 10.459661363000066],
              [-75.04754360099992, 10.459340014000077],
              [-75.04757686099992, 10.459218045000057],
              [-75.04758759299995, 10.459178680000036],
              [-75.04763790099992, 10.45899419400007 ],
              [-75.0478181389999 , 10.458326343000067],
              [-75.04784666599994, 10.458220643000061],
              [-75.04786410399993, 10.458147735000068],
              [-75.04792273399994, 10.457902594000075],
              [-75.04810844499991, 10.457126104000054],
              [-75.04816385299995, 10.456903811000075],
              [-75.04821455299992, 10.45670040200008 ],
              [-75.04826930799993, 10.456480731000056],
              [-75.04827335499994, 10.45646449800006 ],
              [-75.04849543199992, 10.455514893000043],
              [-75.0485156709999 , 10.455428348000055],
              [-75.04866604199992, 10.454829980000056],
              [-75.04868431999995, 10.454751699000042],
              [-75.0487175529999 , 10.454609375000075],
              [-75.04878845299993, 10.454305729000055],
              [-75.04881142799991, 10.454207316000065],
              [-75.04896681899993, 10.453642957000056],
              [-75.04917309899992, 10.452961083000048],
              [-75.04917583399993, 10.452952046000064],
              [-75.04923810799994, 10.452732720000085],
              [-75.04932641399995, 10.452421730000083],
              [-75.04947690999995, 10.451862249000044],
              [-75.04968570999995, 10.451103285000045],
              [-75.04969447799994, 10.451068110000051],
              [-75.04987971899993, 10.450324920000071],
              [-75.05001627099995, 10.449841629000048],
              [-75.0500254339999 , 10.449809200000061],
              [-75.05005260799993, 10.44969906700004 ],
              [-75.05008321999992, 10.449575         ],
              [-75.05008669999995, 10.449560898000072],
              [-75.05014666699992, 10.449317862000044],
              [-75.05034076399994, 10.44856866200007 ],
              [-75.0503475669999 , 10.448544127000048],
              [-75.0504864479999 , 10.448043221000091],
              [-75.05063208999991, 10.44750319600007 ],
              [-75.05085037299995, 10.446637259000056],
              [-75.0510396439999 , 10.445917241000075],
              [-75.05126105499994, 10.44505584600006 ],
              [-75.05128223699995, 10.44497345600007 ],
              [-75.05129461799993, 10.444924872000058],
              [-75.05140125799994, 10.444506569000055],
              [-75.05144717799993, 10.444326425000042],
              [-75.05148255599994, 10.444192182000052],
              [-75.05158306999994, 10.443810739000071],
              [-75.05176514899995, 10.44311712700005 ],
              [-75.05180145399993, 10.442978826000058],
              [-75.05197129599992, 10.442326921000074],
              [-75.0521023149999 , 10.441825832000063],
              [-75.0521144299999 , 10.441778777000081],
              [-75.05226506799994, 10.441193485000042],
              [-75.05229639299995, 10.441071772000043],
              [-75.0524310429999 , 10.440557930000068],
              [-75.05244682699993, 10.440497705000041],
              [-75.05262622399994, 10.439763131000063],
              [-75.0527467519999 , 10.439230559000066],
              [-75.05277153999992, 10.439121028000045],
              [-75.05284539299993, 10.438841350000075],
              [-75.05298993399992, 10.438293975000079],
              [-75.05308900799992, 10.437911537000048],
              [-75.0531840189999 , 10.437544779000063],
              [-75.0533441849999 , 10.436941515000058],
              [-75.05346350799994, 10.436438500000065],
              [-75.05348958699994, 10.436328574000072],
              [-75.0535611279999 , 10.436032873000045],
              [-75.05369319899995, 10.435486985000068],
              [-75.05378538899993, 10.435153791000062],
              [-75.05382915699994, 10.434995603000061],
              [-75.0538505689999 , 10.434899638000047],
              [-75.05395506299993, 10.434431333000077],
              [-75.05400899499995, 10.434209962000068],
              [-75.0540379869999 , 10.434090961000038],
              [-75.05411289099993, 10.433783507000044],
              [-75.05415700799995, 10.433602424000071],
              [-75.05416301399993, 10.433577770000056],
              [-75.05416520699993, 10.433568767000054],
              [-75.05416839299994, 10.43355568800007 ],
              [-75.05417570999992, 10.433524612000042],
              [-75.05419152399992, 10.433457451000038],
              [-75.05420810499993, 10.433387035000067],
              [-75.0542210829999 , 10.433331918000079],
              [-75.0542325369999 , 10.433283275000065],
              [-75.05424731099993, 10.433220533000053],
              [-75.0542598209999 , 10.433167405000063],
              [-75.05427323899994, 10.433110421000038],
              [-75.05428576699995, 10.433057217000055],
              [-75.05429439399995, 10.433020580000061],
              [-75.0543010049999 , 10.432994761000089],
              [-75.05431987899993, 10.432921041000043],
              [-75.05433945999994, 10.432844569000054],
              [-75.05435478399994, 10.432784713000046],
              [-75.05437398299995, 10.432709731000045],
              [-75.05438656799993, 10.43266058000006 ],
              [-75.05439120699992, 10.432641616000069],
              [-75.0543990459999 , 10.432609570000068],
              [-75.05440696099993, 10.432577221000088],
              [-75.05441442199992, 10.43254672200004 ],
              [-75.05445733399995, 10.432371311000054],
              [-75.05447252999994, 10.432309196000062],
              [-75.05451747799992, 10.432125462000045],
              [-75.05469211199994, 10.431439512000054],
              [-75.05472539099992, 10.431314163000081],
              [-75.05485227299994, 10.430836252000063],
              [-75.05500256399995, 10.430218431000071],
              [-75.05505556299994, 10.430005773000062],
              [-75.0552886879999 , 10.42907034600006 ],
              [-75.05533254499994, 10.42890882000006 ],
              [-75.05533882299994, 10.42888570000008 ],
              [-75.05535553999994, 10.428824131000056],
              [-75.05545006999995, 10.428475984000045],
              [-75.0554828729999 , 10.428355174000046],
              [-75.0555641439999 , 10.428048905000082],
              [-75.0556804929999 , 10.427610445000084],
              [-75.05574494199993, 10.427367570000058],
              [-75.0557466919999 , 10.427361407000092],
              [-75.05589428099995, 10.426841728000056],
              [-75.05591987399993, 10.42684164800005 ],
              [-75.05596491299991, 10.426717298000085],
              [-75.05605103699992, 10.42652386900005 ],
              [-75.0560754469999 , 10.42640728300006 ],
              [-75.05609365799995, 10.426287653000088],
              [-75.05614577999995, 10.426118856000073],
              [-75.05620998899991, 10.425851912000041],
              [-75.05628341599993, 10.425563473000068],
              [-75.05635086599995, 10.425342510000064],
              [-75.05646441199991, 10.425007960000073],
              [-75.05651950999993, 10.42479929600006 ],
              [-75.05662065999991, 10.424461714000074],
              [-75.0566724489999 , 10.424185611000041],
              [-75.05670897699991, 10.42398313600006 ],
              [-75.05676389999991, 10.42371928600005 ],
              [-75.05677929599995, 10.423656637000079],
              [-75.05681894599991, 10.423495293000087],
              [-75.05688940399995, 10.423246726000059],
              [-75.05689281199994, 10.423233979000088],
              [-75.05699043099992, 10.422869289000062],
              [-75.0570519019999 , 10.422718861000078],
              [-75.0571069369999 , 10.422491802000081],
              [-75.05719257399994, 10.42214507400007 ],
              [-75.05727509199994, 10.421792220000043],
              [-75.05735455799993, 10.42145471100008 ],
              [-75.05747680399992, 10.420930033000047],
              [-75.05750811799993, 10.420798009000066],
              [-75.05755322399995, 10.420607861000065],
              [-75.0575547119999 , 10.420602278000047],
              [-75.05763587599995, 10.420297936000054],
              [-75.05767210699992, 10.420000415000061],
              [-75.05772407799992, 10.419830638000064],
              [-75.05773035399994, 10.41981013800006 ],
              [-75.05779773899991, 10.419570776000057],
              [-75.0578835579999 , 10.419282302000056],
              [-75.05797572499995, 10.419042865000051],
              [-75.05798763899992, 10.418892588000062],
              [-75.05799298499994, 10.418622756000048],
              [-75.05808501199994, 10.418340399000044],
              [-75.05820520899994, 10.418149929000037],
              [-75.05834110299992, 10.418026869000073],
              [-75.05852328999993, 10.41785154200005 ],
              [-75.05862307899991, 10.417767257000037],
              [-75.05880747799995, 10.41761150900004 ],
              [-75.05905763399994, 10.417380782000066],
              [-75.05924901399993, 10.417174767000063],
              [-75.05947430299994, 10.416916523000054],
              [-75.05961282899995, 10.41677068000007 ],
              [-75.05985400499992, 10.416516762000072],
              [-75.05998660599994, 10.416332388000058],
              [-75.06010804899995, 10.416199550000044],
              [-75.06014401999994, 10.416160202000071],
              [-75.06024249299992, 10.415957534000086],
              [-75.06026891399995, 10.415895091000039],
              [-75.06033472299993, 10.415739560000077],
              [-75.06033581899993, 10.415735950000055],
              [-75.06040829299991, 10.415497114000061],
              [-75.06041346099994, 10.415454453000052],
              [-75.06043244499995, 10.415297745000089],
              [-75.06050327299994, 10.415168752000056],
              [-75.06059892799993, 10.41505501000006 ],
              [-75.06078426499994, 10.41489806800007 ],
              [-75.06088014199992, 10.41485484900005 ],
              [-75.06093198499991, 10.414834580000047],
              [-75.06096514899991, 10.414821614000061],
              [-75.06102244499994, 10.414799216000063],
              [-75.06122672999993, 10.41475872600006 ],
              [-75.06154253999995, 10.414724024000066],
              [-75.06157542599993, 10.414719597000044],
              [-75.06193881899992, 10.414670672000057],
              [-75.06221746799991, 10.414639152000063],
              [-75.06256726799995, 10.41457981700006 ],
              [-75.0628799129999 , 10.414523655000039],
              [-75.06314304799992, 10.414482981000049],
              [-75.06342165799992, 10.414439194000067],
              [-75.06365373799991, 10.414371022000068],
              [-75.06382699599993, 10.414312229000075],
              [-75.06391354499993, 10.41425677300009 ],
              [-75.06400616899992, 10.414164505000088],
              [-75.06405040499993, 10.414100206000057],
              [-75.06408015799991, 10.41405695800006 ],
              [-75.06413537499992, 10.413888155000052],
              [-75.06415648399991, 10.41370719200006 ],
              [-75.06415279299995, 10.413382915000057],
              [-75.06415219199994, 10.413330078000058],
              [-75.06414487099994, 10.413278382000044],
              [-75.06413535899992, 10.413211195000088],
              [-75.0641205149999 , 10.413106355000082],
              [-75.06414815699992, 10.412940964000086],
              [-75.0641630689999 , 10.412851739000075],
              [-75.06420651299993, 10.412733711000044],
              [-75.0642537299999 , 10.412605431000088],
              [-75.06428283199995, 10.412526366000066],
              [-75.06438437899993, 10.412317562000055],
              [-75.06442662499995, 10.412233253000068],
              [-75.06443983199995, 10.412206895000054],
              [-75.06447892699993, 10.412128886000062],
              [-75.06450226299995, 10.412082318000046],
              [-75.06451979999991, 10.41204732400007 ],
              [-75.06476349299993, 10.412042515000053],
              [-75.06484740199994, 10.412040858000069],
              [-75.0648883419999 , 10.412040048000051],
              [-75.06492561399995, 10.412038854000059],
              [-75.06498839199992, 10.412043408000045],
              [-75.0651809119999 , 10.412066785000093],
              [-75.06554693699991, 10.412069121000059],
              [-75.06597238599994, 10.412027046000048],
              [-75.06635267299993, 10.411998993000054],
              [-75.0667349439999 , 10.411962411000047],
              [-75.0667923769999 , 10.411956915000076],
              [-75.06710611799991, 10.411933539000074],
              [-75.06761712999992, 10.41191483600005 ],
              [-75.06781915699992, 10.411898474000054],
              [-75.06797127199991, 10.411884446000045],
              [-75.06810674699994, 10.411879772000077],
              [-75.06860111999993, 10.411863409000091],
              [-75.06881978799993, 10.41184938200007 ],
              [-75.06906697499994, 10.41180496700008 ],
              [-75.06934743799991, 10.411730162000083],
              [-75.06990360899994, 10.41155483700004 ],
              [-75.07002600999994, 10.41151042100006 ],
              [-75.06995920799994, 10.411445443000048],
              [-75.07006506499994, 10.411409897000055],
              [-75.0701731449999 , 10.411373602000083],
              [-75.07047950499992, 10.411289861000057],
              [-75.07051152399993, 10.411279833000037],
              [-75.07088483099994, 10.41116289100006 ],
              [-75.07112786699992, 10.411074538000037],
              [-75.07118381999993, 10.411054197000055],
              [-75.07119109699994, 10.41105155300005 ],
              [-75.07136444899993, 10.41102341800007 ],
              [-75.07168000799993, 10.410912053000061],
              [-75.07171673999994, 10.410894631000076],
              [-75.07173068899993, 10.410963344000038],
              [-75.07191786099992, 10.41091074600007 ],
              [-75.07217930799993, 10.410823084000072],
              [-75.07244075699992, 10.410738343000048],
              [-75.07244311999995, 10.410751082000047],
              [-75.07244930899992, 10.410784446000036],
              [-75.07250635199995, 10.410973330000047],
              [-75.07251320599994, 10.411000293000086],
              [-75.07251395799994, 10.411003252000057],
              [-75.07263945299991, 10.410962109000081],
              [-75.0727535389999 , 10.410932187000071],
              [-75.07278658299992, 10.410920420000082],
              [-75.07286382299992, 10.410892914000044],
              [-75.07292723299992, 10.410860839000065],
              [-75.07302519299992, 10.410796467000068],
              [-75.07305776899994, 10.410776965000082],
              [-75.0730900929999 , 10.410763874000054],
              [-75.07315093999995, 10.410748913000077],
              [-75.07323079899993, 10.410726472000079],
              [-75.07327643499991, 10.410718992000056],
              [-75.0733277729999 , 10.41070777100009 ],
              [-75.07336199899993, 10.410687199000051],
              [-75.07337688099994, 10.41067825400006 ],
              [-75.0733962249999 , 10.410666627000069],
              [-75.07342474599994, 10.410649796000087],
              [-75.07344376099991, 10.410616133000076],
              [-75.07345136599992, 10.410576861000038],
              [-75.07347228299994, 10.410533847000067],
              [-75.07350020899992, 10.410510958000089],
              [-75.07350137599991, 10.41051000200008 ],
              [-75.07352932599991, 10.410487094000075],
              [-75.07354505799992, 10.410474434000037],
              [-75.0735594329999 , 10.410462866000046],
              [-75.07359207299993, 10.410436600000082],
              [-75.07361314499991, 10.410419066000088],
              [-75.07363340699993, 10.410402206000072],
              [-75.07373123999992, 10.410379031000048],
              [-75.07374695399994, 10.410375308000084],
              [-75.0737509569999 , 10.410374360000048],
              [-75.07378445699993, 10.410366423000085],
              [-75.07381059099993, 10.410360232000073],
              [-75.07382048799991, 10.410357887000089],
              [-75.07387944299995, 10.410389172000066],
              [-75.07388066999994, 10.410385598000062],
              [-75.07389957099991, 10.41033053700005 ],
              [-75.07390045699992, 10.41032795700005 ],
              [-75.07392442299994, 10.410258143000078],
              [-75.07393954699995, 10.410218652000083],
              [-75.07403064399995, 10.409980790000077],
              [-75.07403784999991, 10.409961973000065],
              [-75.07404894199993, 10.409933011000078],
              [-75.07405917999995, 10.40990627800005 ],
              [-75.0741171599999 , 10.409754887000076],
              [-75.0741189709999 , 10.409751074000042],
              [-75.07413479599995, 10.409717760000092],
              [-75.07413589099991, 10.409715454000093],
              [-75.07414751599993, 10.409690979000061],
              [-75.07416237599995, 10.409659694000084],
              [-75.07418497399993, 10.409612120000077],
              [-75.0741874929999 , 10.409605196000086],
              [-75.07427899299995, 10.409353654000085],
              [-75.07432476699995, 10.409374175000039],
              [-75.07443517199994, 10.40942167500009 ],
              [-75.07481380999991, 10.409584576000043],
              [-75.07483635099993, 10.409594274000085],
              [-75.07536078599992, 10.409802978000073],
              [-75.07541931999992, 10.409826272000089],
              [-75.07542745099994, 10.409829229000081],
              [-75.07569361499992, 10.409926016000043],
              [-75.07583889599994, 10.40997884400008 ],
              [-75.07587736599993, 10.409992833000047],
              [-75.07590447099994, 10.410003385000039],
              [-75.07590659399995, 10.409997786000076],
              [-75.07595143999993, 10.410016305000056],
              [-75.07597809599991, 10.410027313000057],
              [-75.07695666299992, 10.410431422000045],
              [-75.07963600599993, 10.411254968000037],
              [-75.08235110399994, 10.41196648500005 ],
              [-75.08485682999992, 10.411970451000059],
              [-75.08939778399991, 10.410533813000086],
              [-75.09112505399992, 10.40993779900009 ],
              [-75.09176886599994, 10.409693711000045],
              [-75.0947743239999 , 10.408554258000038],
              [-75.09610979799993, 10.408066576000067],
              [-75.09812235299995, 10.407331641000042],
              [-75.10142611399993, 10.406169919000092],
              [-75.10227830999992, 10.405930289000082],
              [-75.10472644799995, 10.404888380000045],
              [-75.10715172199991, 10.40394570300009 ],
              [-75.1099827999999 , 10.403335595000044],
              [-75.1140959889999 , 10.402877129000046],
              [-75.11486485599994, 10.40279522500009 ],
              [-75.11775969399991, 10.402486851000049],
              [-75.1194226529999 , 10.402214341000047],
              [-75.1203627509999 , 10.401759318000074],
              [-75.12238584699992, 10.400780103000045],
              [-75.12431555699993, 10.399715054000069],
              [-75.12481587699995, 10.399438917000055],
              [-75.12592803099994, 10.398944553000092],
              [-75.12668037599991, 10.398610129000076],
              [-75.12695118699992, 10.398489751000056],
              [-75.12892611799992, 10.397478465000063],
              [-75.1292539179999 , 10.39731061100008 ],
              [-75.12925439299994, 10.397311012000046],
              [-75.13002887599993, 10.397587499000053],
              [-75.13002892499992, 10.397587516000044],
              [-75.12990348199992, 10.397651569000061],
              [-75.12952422099994, 10.397854087000042],
              [-75.12925910699994, 10.397953505000089],
              [-75.12897190099994, 10.398071335000054],
              [-75.1285116329999 , 10.398270170000046],
              [-75.12808450099993, 10.398461639000061],
              [-75.12759109899991, 10.398675204000085],
              [-75.12727811699995, 10.39879671500006 ],
              [-75.12695040699992, 10.39889613300005 ],
              [-75.12899399399993, 10.400740886000051],
              [-75.13006318399994, 10.401708516000042],
              [-75.1303895229999 , 10.402003858000057],
              [-75.13124746099993, 10.402780791000055],
              [-75.1314916739999 , 10.40299777000007 ],
              [-75.1318993509999 , 10.403359987000044],
              [-75.13216985999992, 10.403607553000086],
              [-75.1323447389999 , 10.403767604000052],
              [-75.13235127399992, 10.403773585000067],
              [-75.13338552399995, 10.404720122000072],
              [-75.13338678399992, 10.404721275000043],
              [-75.13342497999992, 10.405020886000045],
              [-75.13342450199991, 10.405023449000055],
              [-75.13339782999992, 10.40516681400004 ],
              [-75.13336347099994, 10.405351500000052],
              [-75.13330945899992, 10.405641813000045],
              [-75.1331367489999 , 10.405939398000044],
              [-75.13303101499991, 10.406062425000073],
              [-75.13302225299992, 10.406072620000089],
              [-75.13295386699991, 10.406152192000093],
              [-75.13279395899991, 10.406256727000084],
              [-75.13252032999992, 10.40643560500007 ],
              [-75.13248087799991, 10.406461396000054],
              [-75.13240037299994, 10.406501522000042],
              [-75.13231528099993, 10.406543935000059],
              [-75.13217906799991, 10.406611828000052],
              [-75.13649366599992, 10.40885250000008 ],
              [-75.13650169699991, 10.408856670000091],
              [-75.13655183599991, 10.40892223700007 ],
              [-75.13676165299995, 10.409196615000042],
              [-75.1367731659999 , 10.409211671000037],
              [-75.1366148319999 , 10.409509447000062],
              [-75.13656657999991, 10.409600195000053],
              [-75.13683719699992, 10.40989694700005 ],
              [-75.13696764199995, 10.410039992000065],
              [-75.13719216099992, 10.410184746000084],
              [-75.13720357099993, 10.410192103000043],
              [-75.13732384999992, 10.410269655000093],
              [-75.1376337989999 , 10.410361467000087],
              [-75.13776079699994, 10.41039908600004 ],
              [-75.1377646059999 , 10.410400215000038],
              [-75.13792039399993, 10.410491882000088],
              [-75.13805105699993, 10.410568764000061],
              [-75.1380460819999 , 10.410662839000054],
              [-75.1380423299999 , 10.410733795000056],
              [-75.13813335399993, 10.410758677000047],
              [-75.13818141799993, 10.410771816000079],
              [-75.13845838099991, 10.410847531000059],
              [-75.13860350699991, 10.410799294000071],
              [-75.13863149799994, 10.410789991000058],
              [-75.13884473799993, 10.410719112000038],
              [-75.13895636099994, 10.410682010000073],
              [-75.1389632879999 , 10.410679708000089],
              [-75.13898242499994, 10.41067163300005 ],
              [-75.13902344599995, 10.410654326000042],
              [-75.1392206079999 , 10.410571140000059],
              [-75.13933599699993, 10.410525342000085],
              [-75.13942397399995, 10.410490425000091],
              [-75.13947216699995, 10.410515459000067],
              [-75.13966769599995, 10.410617032000061],
              [-75.14019391099993, 10.411883913000054],
              [-75.14026392799991, 10.412054850000061],
              [-75.14030806699992, 10.41216260400006 ],
              [-75.14030887799993, 10.412164587000063],
              [-75.1401329819999 , 10.412365029000057],
              [-75.1400320269999 , 10.412480070000072],
              [-75.13999687799992, 10.412520124000082],
              [-75.13993994399993, 10.412708494000071],
              [-75.1399409949999 , 10.412708965000093],
              [-75.1400107099999 , 10.412740227000086],
              [-75.14020352499995, 10.412826695000092],
              [-75.14059272999992, 10.41300123800005 ],
              [-75.1407462439999 , 10.413070081000058],
              [-75.14076703599994, 10.413069823000058],
              [-75.14078563499993, 10.413069593000046],
              [-75.14080961299993, 10.413076253000042],
              [-75.1408720089999 , 10.41309358400008 ],
              [-75.14090133899992, 10.41310173100004 ],
              [-75.14097255799993, 10.413121515000057],
              [-75.14101553899991, 10.413127774000088],
              [-75.14111035699995, 10.413141582000037],
              [-75.14119174899992, 10.413150004000045],
              [-75.14120715699994, 10.41315159700008 ],
              [-75.14123764499993, 10.413154750000047],
              [-75.14125090199991, 10.41315862600004 ],
              [-75.1412511179999 , 10.413158689000056],
              [-75.1412575199999 , 10.41315639600009 ],
              [-75.14134605299995, 10.413124682000046],
              [-75.14139985299994, 10.413105407000046],
              [-75.14144479399994, 10.413089306000074],
              [-75.14159628399995, 10.41301876500006 ],
              [-75.14167146999995, 10.412983754000038],
              [-75.14177097899994, 10.41293741600009 ],
              [-75.1417865969999 , 10.41293014300004 ],
              [-75.1418061359999 , 10.412921045000076],
              [-75.14202666299991, 10.41281833100004 ],
              [-75.14202670299994, 10.412818363000042],
              [-75.14203692199993, 10.41282663700008 ],
              [-75.14225922499992, 10.413006636000091],
              [-75.14225950999992, 10.413006866000046],
              [-75.14229571099992, 10.413064322000082],
              [-75.1423484419999 , 10.413148015000047],
              [-75.14232946999994, 10.413434721000044],
              [-75.1423282209999 , 10.413627588000054],
              [-75.14233939199994, 10.41383150200005 ],
              [-75.14234863999991, 10.41400046800004 ],
              [-75.14240566799992, 10.414394879000042],
              [-75.14246268999995, 10.414789290000044],
              [-75.14257892599994, 10.415186538000057],
              [-75.14278029399992, 10.415850290000037],
              [-75.14300498699993, 10.41651478700004 ],
              [-75.14300629099995, 10.416754240000046],
              [-75.14301508299991, 10.416822271000058],
              [-75.14303704399993, 10.416992188000052],
              [-75.14314002599991, 10.417255673000057],
              [-75.14326262799995, 10.417440270000043],
              [-75.14345844399992, 10.417650884000068],
              [-75.14352500599995, 10.417722475000062],
              [-75.14352536599995, 10.417722836000053],
              [-75.14379135399992, 10.417971665000039],
              [-75.14379172699995, 10.417972014000043],
              [-75.14411145499992, 10.41821453600005 ],
              [-75.14425536499994, 10.418362630000047],
              [-75.1442557279999 , 10.418362991000038],
              [-75.14450500399994, 10.418704645000048],
              [-75.14480357199994, 10.419107731000054],
              [-75.14497059999991, 10.41928710700006 ],
              [-75.14497095599995, 10.419287473000054],
              [-75.1451510469999 , 10.419381787000077],
              [-75.14523113099995, 10.419423728000083],
              [-75.14533277299995, 10.419456121000053],
              [-75.14561476099993, 10.419545987000049],
              [-75.14609880899991, 10.419682385000044],
              [-75.14647925499992, 10.419831944000066],
              [-75.14651509399994, 10.419846033000056],
              [-75.14681560299994, 10.41998182900005 ],
              [-75.14698306899993, 10.42012320300006 ],
              [-75.14708136199994, 10.42020818800006 ],
              [-75.14711239399992, 10.420235018000085],
              [-75.14748762499994, 10.420414161000053],
              [-75.1482187869999 , 10.420744221000064],
              [-75.14843708899991, 10.42086891300005 ],
              [-75.14851051599993, 10.42093101100005 ],
              [-75.14856538799995, 10.42096957700005 ],
              [-75.14858799399991, 10.420984607000037],
              [-75.14864309199993, 10.42102260200005 ],
              [-75.1487393029999 , 10.421051783000053],
              [-75.14898825599994, 10.42109985400009 ],
              [-75.1490042289999 , 10.421108845000049],
              [-75.14926894799993, 10.421257879000052],
              [-75.14965289299994, 10.421599738000054],
              [-75.15011781699991, 10.421979707000048],
              [-75.1505003229999 , 10.422298179000052],
              [-75.1507282039999 , 10.422419481000077],
              [-75.15115599399991, 10.422516401000053],
              [-75.15176956199991, 10.422594436000054],
              [-75.15210444799993, 10.42269372000004 ],
              [-75.15240603099994, 10.422880940000084],
              [-75.1526889239999 , 10.423110759000053],
              [-75.15268921799992, 10.423110996000048],
              [-75.15281864299993, 10.423325675000058],
              [-75.15294245399991, 10.423892379000051],
              [-75.15300228199993, 10.424578752000059],
              [-75.15301015199992, 10.424730460000092],
              [-75.15302208599991, 10.424960436000049],
              [-75.15302650299992, 10.42504554900006 ],
              [-75.15306721299993, 10.425291153000046],
              [-75.15314845599994, 10.425472248000062],
              [-75.15325343399991, 10.425591151000049],
              [-75.15333954899995, 10.425687716000084],
              [-75.1533725779999 , 10.425860663000037],
              [-75.1533915629999 , 10.426026123000042],
              [-75.15339515799991, 10.42605744900004 ],
              [-75.15335909999993, 10.426190378000058],
              [-75.15327956199991, 10.42633322800009 ],
              [-75.15318101899993, 10.426491646000045],
              [-75.15310135699991, 10.426672354000061],
              [-75.15302785499995, 10.426871125000048],
              [-75.1529839719999 , 10.427074119000054],
              [-75.15295837399992, 10.427262590000055],
              [-75.15295131699992, 10.427467543000091],
              [-75.15288890099993, 10.427666472000055],
              [-75.1527431259999 , 10.428005644000052],
              [-75.15270882599992, 10.428142132000062],
              [-75.15270184099995, 10.428267325000093],
              [-75.15274365099992, 10.428399815000091],
              [-75.15276362999992, 10.428562501000044],
              [-75.15279669799992, 10.428643044000069],
              [-75.15293602899993, 10.428766049000046],
              [-75.15293636099995, 10.428766341000085],
              [-75.15298077299991, 10.42885295800005 ],
              [-75.1530120889999 , 10.429021643000056],
              [-75.15304707599995, 10.429124436000052],
              [-75.15313698199992, 10.42920341200005 ],
              [-75.1531372849999 , 10.429203681000047],
              [-75.15317489299991, 10.429260680000084],
              [-75.15316607199992, 10.429313454000066],
              [-75.15312181199994, 10.429362261000051],
              [-75.15305341099992, 10.429438049000055],
              [-75.15303319899994, 10.429497225000091],
              [-75.15303369399993, 10.429625869000063],
              [-75.1530401309999 , 10.429901886000039],
              [-75.15302016599992, 10.43024386400009 ],
              [-75.15301206399994, 10.430442562000053],
              [-75.15301504799993, 10.430490213000041],
              [-75.15261772899993, 10.431053665000093],
              [-75.15233459299992, 10.431594085000086],
              [-75.15249930899995, 10.431890664000036],
              [-75.15256093599993, 10.432001626000044],
              [-75.15256117399991, 10.432002044000058],
              [-75.15264611899994, 10.43195969900006 ],
              [-75.15268689699991, 10.431942832000061],
              [-75.15269937099993, 10.43193767300005 ],
              [-75.15271622199992, 10.431946723000067],
              [-75.15281885999991, 10.432001845000059],
              [-75.15285023699994, 10.432026831000087],
              [-75.15293802199994, 10.432096738000041],
              [-75.15293810999992, 10.432096813000044],
              [-75.15302038499993, 10.432182906000037],
              [-75.15302074199991, 10.432183270000053],
              [-75.15311422799994, 10.432306657000083],
              [-75.1531425739999 , 10.432379534000063],
              [-75.15318013599995, 10.432487363000064],
              [-75.15322569299991, 10.432704318000049],
              [-75.15322226799992, 10.43283679600006 ],
              [-75.15319965099991, 10.433045587000038],
              [-75.15321749599991, 10.433247231000053],
              [-75.1531993509999 , 10.43339633000005 ],
              [-75.15322342999991, 10.433640180000054],
              [-75.15320690599992, 10.433836549000091],
              [-75.15318016299994, 10.433988706000036],
              [-75.1531351509999 , 10.434075689000053],
              [-75.15312209199993, 10.434157564000088],
              [-75.15311381099991, 10.434326182000063],
              [-75.15311867799994, 10.43443730100006 ],
              [-75.15312122499995, 10.434495489000085],
              [-75.15313369599994, 10.434598468000047],
              [-75.15318547299995, 10.434688531000063],
              [-75.15326155699995, 10.43479472200005 ],
              [-75.15337770499991, 10.43492462100005 ],
              [-75.15337794099992, 10.434924885000044],
              [-75.15352440999993, 10.435033197000052],
              [-75.15363707799992, 10.435094030000073],
              [-75.15373952099992, 10.435146664000058],
              [-75.1539174529999 , 10.435257751000051],
              [-75.15403614399992, 10.435304427000062],
              [-75.15420740899992, 10.435312131000046],
              [-75.1542139199999 , 10.435312986000042],
              [-75.1543802029999 , 10.43533483400006 ],
              [-75.1545753819999 , 10.435370976000058],
              [-75.15478759299992, 10.435426674000041],
              [-75.15494258399991, 10.43547654400004 ],
              [-75.15510630299991, 10.435500027000046],
              [-75.15519128499994, 10.435559180000041],
              [-75.15519157199992, 10.43555937900004 ],
              [-75.1552576229999 , 10.435695200000055],
              [-75.15537145499991, 10.435914267000044],
              [-75.15552629599995, 10.436173134000057],
              [-75.15554362699993, 10.43619553700006 ],
              [-75.15567240899992, 10.43636200900005 ],
              [-75.1556725449999 , 10.436362185000064],
              [-75.15582808499994, 10.436486286000047],
              [-75.15591433399993, 10.436563026000044],
              [-75.15603626399991, 10.436649488000057],
              [-75.15604729099994, 10.436652646000084],
              [-75.15617676699992, 10.436689717000093],
              [-75.15637201699991, 10.436767665000048],
              [-75.15643944099992, 10.436822151000058],
              [-75.15655066699992, 10.43690569000006 ],
              [-75.15662489199991, 10.43697586400009 ],
              [-75.15662526099993, 10.436976217000051],
              [-75.15675673699991, 10.437165641000092],
              [-75.15681558999995, 10.43726969700009 ],
              [-75.1568798749999 , 10.437460059000045],
              [-75.15695860499994, 10.437627525000039],
              [-75.1569742929999 , 10.437660898000047],
              [-75.15707210799991, 10.437778710000089],
              [-75.15726256799991, 10.438023617000056],
              [-75.1573601259999 , 10.438163289000045],
              [-75.15741990499993, 10.438248875000056],
              [-75.1574239119999 , 10.438254611000048],
              [-75.15753845099994, 10.43844873800009 ],
              [-75.15757184799992, 10.438546085000041],
              [-75.15762081899993, 10.438623367000048],
              [-75.1576710889999 , 10.438739463000047],
              [-75.1577318269999 , 10.438999503000048],
              [-75.15773764199992, 10.439158960000043],
              [-75.15775244699995, 10.439320949000091],
              [-75.15777489599992, 10.439470117000042],
              [-75.15780043299992, 10.439614118000065],
              [-75.15778226099991, 10.439755371000047],
              [-75.15772683799992, 10.439897465000058],
              [-75.15767790099994, 10.44003485400009 ],
              [-75.15762591799995, 10.440049242000043],
              [-75.15757343699994, 10.440099235000048],
              [-75.15753465099993, 10.440246594000087],
              [-75.15753352099995, 10.440256279000039],
              [-75.15752039799992, 10.44036873400006 ],
              [-75.15749067299993, 10.440445552000085],
              [-75.15744428499994, 10.440556857000047],
              [-75.1574390639999 , 10.440608521000058],
              [-75.15745428799994, 10.440671689000055],
              [-75.1574835159999 , 10.44079841200005 ],
              [-75.1575006569999 , 10.440896383000052],
              [-75.15749232699994, 10.440953516000093],
              [-75.15745077899993, 10.441047380000043],
              [-75.15746504299995, 10.441093185000057],
              [-75.15750205199993, 10.441131580000047],
              [-75.15750241199993, 10.441131940000048],
              [-75.15751782199993, 10.44123368600009 ],
              [-75.1575243069999 , 10.441324803000043],
              [-75.15752431899995, 10.441324915000052],
              [-75.15757905099991, 10.441362591000086],
              [-75.1575793049999 , 10.441362767000044],
              [-75.1576026969999 , 10.441401811000048],
              [-75.1576429829999 , 10.441469053000048],
              [-75.1577262269999 , 10.441592832000083],
              [-75.15777772899992, 10.441677175000052],
              [-75.15777792199992, 10.441677492000053],
              [-75.15783748899992, 10.441691805000062],
              [-75.15786876399994, 10.441723094000054],
              [-75.15786912699991, 10.441723455000044],
              [-75.15793964599993, 10.441852285000039],
              [-75.15791005099993, 10.441883346000054],
              [-75.15785816099992, 10.44188005300009 ],
              [-75.15785779499993, 10.441879692000043],
              [-75.15780215399991, 10.441824694000047],
              [-75.15778594099993, 10.441831456000045],
              [-75.15772053199993, 10.44185873500004 ],
              [-75.15772595199991, 10.441880677000086],
              [-75.15775230499992, 10.441931778000082],
              [-75.15778389499991, 10.441970939000043],
              [-75.15782198099993, 10.442021411000042],
              [-75.1578629679999 , 10.442124245000059],
              [-75.15790981099991, 10.442226765000044],
              [-75.15796455599991, 10.442330738000067],
              [-75.15797554399995, 10.442351607000091],
              [-75.1580080949999 , 10.442408209000064],
              [-75.15807125599991, 10.442486531000043],
              [-75.1581376339999 , 10.442623002000062],
              [-75.15818415699994, 10.44271970200009 ],
              [-75.15822481899994, 10.442816716000038],
              [-75.15829641399995, 10.442941243000064],
              [-75.1583524209999 , 10.443002075000038],
              [-75.15835274999995, 10.443002418000049],
              [-75.15844950799993, 10.443049762000044],
              [-75.15850914599991, 10.44306408600005 ],
              [-75.15858048399991, 10.443095380000045],
              [-75.1586557359999 , 10.443178887000045],
              [-75.15870032899994, 10.443240672000059],
              [-75.1587338359999 , 10.44331472500005 ],
              [-75.15876637699995, 10.443371330000048],
              [-75.15880382099994, 10.443427751000058],
              [-75.15884776999991, 10.443477906000055],
              [-75.15886901599993, 10.443499498000051],
              [-75.15889104299993, 10.443521884000063],
              [-75.15889140099995, 10.443522245000054],
              [-75.15894676599993, 10.443565955000054],
              [-75.15899593699993, 10.443604167000046],
              [-75.15906953599995, 10.443658569000092],
              [-75.15916788699991, 10.443734988000074],
              [-75.15928970099992, 10.443810143000064],
              [-75.15936852099992, 10.443852604000085],
              [-75.15947048899994, 10.443887991000054],
              [-75.15948799199992, 10.443892403000063],
              [-75.1595659489999 , 10.443912057000091],
              [-75.1596623609999 , 10.443953573000044],
              [-75.15976593099992, 10.44401804100005 ],
              [-75.15983140999992, 10.444032037000056],
              [-75.15993762599993, 10.444038031000048],
              [-75.15999073599994, 10.444041032000086],
              [-75.16002624799995, 10.444044961000088],
              [-75.16027498299991, 10.444113439000091],
              [-75.16031081199992, 10.444123189000038],
              [-75.16035836899994, 10.444132310000043],
              [-75.16039933399992, 10.444147667000038],
              [-75.16045233199992, 10.444168215000047],
              [-75.16052356799992, 10.44419943400004 ],
              [-75.1605720739999 , 10.44422600200005 ],
              [-75.16064531699993, 10.444274566000047],
              [-75.16075488399991, 10.444342173000052],
              [-75.16100833599995, 10.444459248000044],
              [-75.16113562699991, 10.444528246000061],
              [-75.16120236599994, 10.444565496000052],
              [-75.16133423299993, 10.444610923000084],
              [-75.16144800399991, 10.44462814800005 ],
              [-75.1615385099999 , 10.444669951000037],
              [-75.16160231799995, 10.444711685000073],
              [-75.16160241199992, 10.444711746000053],
              [-75.16177946099992, 10.444906549000052],
              [-75.16177962599994, 10.44490673200005 ],
              [-75.16197553199993, 10.445080052000037],
              [-75.16222989199991, 10.445214513000053],
              [-75.16248451799993, 10.44542629600005 ],
              [-75.16263918999994, 10.445524616000057],
              [-75.1626412679999 , 10.44552593700007 ],
              [-75.1628172689999 , 10.445637815000055],
              [-75.16318914599992, 10.445868535000045],
              [-75.16352202999991, 10.446118716000058],
              [-75.16391329999993, 10.446310703000051],
              [-75.1642461209999 , 10.446541551000053],
              [-75.16461810599992, 10.44690722900009 ],
              [-75.16461846999994, 10.446907588000045],
              [-75.16504886999991, 10.447118770000088],
              [-75.16540094999993, 10.447272226000052],
              [-75.16545498599993, 10.447292813000047],
              [-75.16575297199995, 10.447406344000058],
              [-75.16606608899991, 10.447694904000059],
              [-75.16606645899992, 10.447695256000088],
              [-75.16620064299991, 10.447827164000046],
              [-75.16659522499992, 10.448215054000059],
              [-75.16659559099992, 10.448215410000046],
              [-75.16681190599991, 10.448639970000045],
              [-75.16681311799994, 10.448987940000052],
              [-75.16679466399995, 10.449297313000045],
              [-75.16681142499993, 10.449671551000051],
              [-75.1668352449999 , 10.449741474000064],
              [-75.16683539299993, 10.449741910000057],
              [-75.16685141599993, 10.449788952000063],
              [-75.16692094199993, 10.449886545000084],
              [-75.16692102299993, 10.449886660000061],
              [-75.16707982999992, 10.450023247000047],
              [-75.16714910899992, 10.450049806000038],
              [-75.1673077019999 , 10.45011061200006 ],
              [-75.16739712999993, 10.450208263000093],
              [-75.1675066279999 , 10.450393991000055],
              [-75.16757647199995, 10.450560264000046],
              [-75.16764281199994, 10.450695081000049],
              [-75.16775901499994, 10.45093126200004 ],
              [-75.16785552699992, 10.451127408000048],
              [-75.16805473499994, 10.451498927000046],
              [-75.16816406299995, 10.45163567800006 ],
              [-75.1682831679999 , 10.451791743000058],
              [-75.16828335399993, 10.45179199100005 ],
              [-75.16851146199991, 10.451938128000052],
              [-75.16907659499992, 10.452249628000061],
              [-75.16984011799991, 10.452697577000038],
              [-75.17067304599993, 10.453184460000045],
              [-75.17121821799992, 10.453447052000058],
              [-75.17165420599991, 10.453612067000051],
              [-75.17230823299991, 10.453874281000083],
              [-75.17270439499993, 10.453970868000056],
              [-75.1730212519999 , 10.454028549000043],
              [-75.1732351739999 , 10.454146335000075],
              [-75.17350306299994, 10.45433070200005 ],
              [-75.17350839199992, 10.454331009000043],
              [-75.17385578499994, 10.45464716500004 ],
              [-75.1740582619999 , 10.454827101000092],
              [-75.17423389999993, 10.454939073000048],
              [-75.17442769799993, 10.45502461700005 ],
              [-75.17480523299992, 10.455109650000054],
              [-75.17514205099991, 10.455179525000062],
              [-75.17523006499994, 10.455192971000088],
              [-75.17542694399992, 10.45522304700006 ],
              [-75.1755616019999 , 10.455243618000054],
              [-75.17588134699992, 10.455226070000037],
              [-75.17617827299995, 10.455215319000047],
              [-75.17629700499992, 10.455211020000093],
              [-75.17672932099993, 10.455513893000045],
              [-75.17697723899994, 10.455663744000049],
              [-75.17733882599993, 10.455915927000092],
              [-75.17748331199994, 10.456156389000057],
              [-75.17762801399994, 10.456472120000058],
              [-75.1777210539999 , 10.45661145300005 ],
              [-75.17789432099994, 10.456765616000041],
              [-75.17812010299991, 10.456908690000091],
              [-75.17858265299992, 10.45717152900005 ],
              [-75.17914481299994, 10.457455993000053],
              [-75.17946475999992, 10.457652172000053],
              [-75.17969056699991, 10.457803452000064],
              [-75.18021815899993, 10.458086644000048],
              [-75.1809060569999 , 10.458342009000091],
              [-75.18127714199994, 10.458529833000057],
              [-75.18147675199992, 10.45870855600009 ],
              [-75.18185654299992, 10.459035949000054],
              [-75.18212707299995, 10.459352696000053],
              [-75.18230334399993, 10.459586223000088],
              [-75.18250769399992, 10.45996610900005 ],
              [-75.18272187999992, 10.460400710000044],
              [-75.18289421799994, 10.460706781000056],
              [-75.1830039589999 , 10.460883015000093],
              [-75.18323807299993, 10.461039744000061],
              [-75.18380867999991, 10.461370703000057],
              [-75.18428922699991, 10.461637590000066],
              [-75.18465906999995, 10.461871947000077],
              [-75.18477832199994, 10.461990675000038],
              [-75.18497250299993, 10.462202257000058],
              [-75.1852193069999 , 10.462439689000064],
              [-75.18535095099992, 10.462540592000039],
              [-75.18589086799994, 10.462778573000037],
              [-75.18634780699995, 10.46300857600005 ],
              [-75.18658999699994, 10.463088638000045],
              [-75.18677541399995, 10.463138754000056],
              [-75.1869761129999 , 10.46321209100006 ],
              [-75.18712155999992, 10.463304745000073],
              [-75.18735733599993, 10.46355589400008 ],
              [-75.1876972359999 , 10.463949077000052],
              [-75.18801236199994, 10.464382022000052],
              [-75.18819572599995, 10.464674372000047],
              [-75.18833057799992, 10.464925808000089],
              [-75.1884225259999 , 10.465162304000046],
              [-75.18851320699991, 10.465439863000086],
              [-75.1885965649999 , 10.465576483000063],
              [-75.18871471499995, 10.46578827400009 ],
              [-75.18885757099991, 10.465939780000042],
              [-75.18904180099992, 10.466055587000085],
              [-75.18925638099995, 10.466148042000043],
              [-75.18941698999993, 10.46622422900009 ],
              [-75.18946968599994, 10.466277457000047],
              [-75.18956137399994, 10.466424997000047],
              [-75.18964367799992, 10.466673841000045],
              [-75.18970531199994, 10.466944642000044],
              [-75.18982394099993, 10.467320653000058],
              [-75.18990051999992, 10.467502454000055],
              [-75.19006269899995, 10.467641589000038],
              [-75.19027044599994, 10.467761435000057],
              [-75.19038959099993, 10.467843213000037],
              [-75.19041313299994, 10.467892926000047],
              [-75.1904161569999 , 10.467892530000086],
              [-75.19068373899995, 10.467857456000047],
              [-75.1908632979999 , 10.467863890000046],
              [-75.19103169599992, 10.46789257000006 ],
              [-75.19124812099994, 10.468037741000046],
              [-75.1914898739999 , 10.468211995000047],
              [-75.1916976139999 , 10.468264164000061],
              [-75.19195852399992, 10.46827176000005 ],
              [-75.19231765599994, 10.468290181000043],
              [-75.19278056499991, 10.468302753000046],
              [-75.19307504099993, 10.468275539000047],
              [-75.19337644399991, 10.468219151000085],
              [-75.19351382899993, 10.468192381000051],
              [-75.19363449299993, 10.468205927000042],
              [-75.19373136299993, 10.468234807000044],
              [-75.1939900719999 , 10.468452050000053],
              [-75.1943851119999 , 10.468766094000046],
              [-75.19463795399992, 10.468893111000057],
              [-75.19498474499994, 10.469017550000046],
              [-75.19499880699993, 10.469022597000048],
              [-75.19528244599991, 10.469130083000039],
              [-75.19545943399993, 10.469217051000044],
              [-75.19565783599995, 10.469437240000047],
              [-75.1958334439999 , 10.469532540000046],
              [-75.1961873919999 , 10.46969953200005 ],
              [-75.19637707099992, 10.469811451000055],
              [-75.19653351199992, 10.469909847000054],
              [-75.19656396499994, 10.469900651000046],
              [-75.19670909899992, 10.469856254000092],
              [-75.19671327299994, 10.469855615000085],
              [-75.19689234399993, 10.469828394000047],
              [-75.19697529599995, 10.469815784000048],
              [-75.19707233199995, 10.469844593000062],
              [-75.19723351999994, 10.469991448000087],
              [-75.19731690799995, 10.47006743500009 ],
              [-75.19737039199993, 10.470106934000057],
              [-75.19748403199992, 10.470190857000091],
              [-75.19761016799993, 10.470162653000045],
              [-75.19762372699995, 10.47015962100005 ],
              [-75.1977016749999 , 10.470142191000093],
              [-75.19774362699991, 10.470109655000044],
              [-75.19783718299993, 10.470037097000045],
              [-75.19795823999993, 10.470010281000043],
              [-75.1981045199999 , 10.470031675000087],
              [-75.19832349399991, 10.470139095000093],
              [-75.19854978799992, 10.470267895000063],
              [-75.19860840099994, 10.470309764000092],
              [-75.19879061699993, 10.470439904000045],
              [-75.19888693999991, 10.470508694000046],
              [-75.19917760299995, 10.470711038000047],
              [-75.19920820999994, 10.470712114000037],
              [-75.19926155099995, 10.470713989000046],
              [-75.19928417599994, 10.47071326300005 ],
              [-75.19946970299992, 10.470707312000059],
              [-75.19970720399994, 10.470680103000063],
              [-75.19982995299995, 10.470651890000056],
              [-75.20005916999992, 10.470599204000052],
              [-75.20014572899993, 10.470579300000054],
              [-75.20020876199993, 10.470582144000048],
              [-75.20022940799993, 10.470586490000073],
              [-75.20031709199992, 10.470604973000093],
              [-75.20033588199993, 10.470611105000046],
              [-75.2003358739999 , 10.47061122200006 ],
              [-75.2003404969999 , 10.470611992000045],
              [-75.20043847499994, 10.470628343000044],
              [-75.20050445399994, 10.470640077000041],
              [-75.20083643099991, 10.470660894000048],
              [-75.20097500399993, 10.470678508000049],
              [-75.20108620299993, 10.470714329000089],
              [-75.2011896219999 , 10.470768282000051],
              [-75.2013572969999 , 10.470882798000048],
              [-75.20140565299994, 10.470885213000088],
              [-75.2016079519999 , 10.470818540000039],
              [-75.20176468299991, 10.470798586000058],
              [-75.20197998399993, 10.470713764000038],
              [-75.20212755499995, 10.470689961000062],
              [-75.20220987599993, 10.470689673000038],
              [-75.2023249209999 , 10.470706076000056],
              [-75.20260610499992, 10.470773629000064],
              [-75.20277469299992, 10.470779501000038],
              [-75.20309181999994, 10.470713231000047],
              [-75.20319760099994, 10.470697337000047],
              [-75.20333778499992, 10.470707511000057],
              [-75.20347815499991, 10.470770064000078],
              [-75.20358915199995, 10.47084048000005 ],
              [-75.20372297199992, 10.470989385000053],
              [-75.2038251109999 , 10.471049161000053],
              [-75.2040330289999 , 10.471092077000037],
              [-75.20426147499995, 10.471119396000063],
              [-75.2043839669999 , 10.47111605300006 ],
              [-75.20460828799992, 10.471087131000047],
              [-75.20482663599995, 10.471033009000053],
              [-75.20497359399991, 10.471019879000039],
              [-75.20513998099995, 10.471021203000078],
              [-75.20514216399994, 10.471021220000068],
              [-75.20526861999991, 10.471030473000042],
              [-75.20551967499995, 10.471078085000045],
              [-75.20577190699993, 10.471178071000054],
              [-75.20594081299993, 10.471272528000043],
              [-75.20608820799993, 10.471380646000057],
              [-75.20622809799994, 10.471531169000059],
              [-75.20640586199994, 10.471867761000055],
              [-75.2065062659999 , 10.472223015000054],
              [-75.20659425399992, 10.472707798000044],
              [-75.2066404009999 , 10.473069923000082],
              [-75.20667991199991, 10.473426981000046],
              [-75.20670617199994, 10.47357383800005 ],
              [-75.20673820999991, 10.473694487000046],
              [-75.20676192799993, 10.473748237000052],
              [-75.2070264649999 , 10.473930627000072],
              [-75.2071979449999 , 10.47399112700009 ],
              [-75.20729585899994, 10.474034429000085],
              [-75.20754190299994, 10.47418487400006 ],
              [-75.20762485299991, 10.47422924700004 ],
              [-75.2079174939999 , 10.474385785000038],
              [-75.20818471399991, 10.47449759500006 ],
              [-75.20839501099994, 10.474562757000058],
              [-75.20880708999994, 10.474647427000093],
              [-75.20932566999994, 10.474753981000049],
              [-75.20945136499995, 10.474756443000047],
              [-75.20961545699993, 10.474738657000046],
              [-75.20993099399993, 10.474660890000052],
              [-75.21058090999992, 10.474500713000054],
              [-75.21108618499994, 10.47435914600004 ],
              [-75.21166103299993, 10.47417322800004 ],
              [-75.21213667299992, 10.474040609000042],
              [-75.21262342599994, 10.47398811100004 ],
              [-75.21302624299994, 10.473944660000086],
              [-75.2134823529999 , 10.474002505000044],
              [-75.21381728699993, 10.474089700000093],
              [-75.21435966099995, 10.47421980200005 ],
              [-75.21448549599995, 10.474260818000062],
              [-75.21490115799992, 10.474412654000048],
              [-75.21530157299992, 10.474618010000086],
              [-75.21568436799993, 10.474827249000043],
              [-75.21591209299993, 10.474991758000044],
              [-75.21608816799994, 10.475162760000046],
              [-75.21629129299993, 10.475424709000038],
              [-75.2165604779999 , 10.475771846000043],
              [-75.21676139599992, 10.476036031000092],
              [-75.21683290999994, 10.476140705000091],
              [-75.21687536699994, 10.47620284900006 ],
              [-75.21701927499993, 10.476413489000038],
              [-75.21722267899992, 10.476711209000086],
              [-75.21735738899991, 10.476916422000045],
              [-75.2174553989999 , 10.477035559000058],
              [-75.21767109699994, 10.477228482000044],
              [-75.21789087399992, 10.477328635000049],
              [-75.21821691999992, 10.477395668000042],
              [-75.21854198699992, 10.477495987000054],
              [-75.21898420299993, 10.477672269000038],
              [-75.21940872699992, 10.477834977000043],
              [-75.21972558699991, 10.477951691000044],
              [-75.21987138899993, 10.478024281000046],
              [-75.22001672599993, 10.47812033300005 ],
              [-75.2201615969999 , 10.47823984900009 ],
              [-75.22034246999993, 10.47840506500006 ],
              [-75.22053573999995, 10.47864389700004 ],
              [-75.22061265199994, 10.478758258000084],
              [-75.2207033649999 , 10.478955928000062],
              [-75.22081751999991, 10.479204672000037],
              [-75.22100928399993, 10.47952752100008 ],
              [-75.22112910399994, 10.47972923900005 ],
              [-75.22119028999992, 10.479840326000044],
              [-75.22123928399992, 10.479838862000065],
              [-75.2212793839999 , 10.47983619300004 ],
              [-75.22140933999992, 10.479823104000047],
              [-75.22155745899994, 10.479809446000047],
              [-75.22157409199991, 10.47980791000009 ],
              [-75.22172258299992, 10.479797234000046],
              [-75.22184563099995, 10.479777200000058],
              [-75.22198493099995, 10.479752652000059],
              [-75.2221590499999 , 10.47972369300004 ],
              [-75.2221890319999 , 10.479720893000092],
              [-75.22229828099995, 10.479710663000048],
              [-75.22242826899992, 10.479692963000048],
              [-75.22253739399991, 10.479672855000047],
              [-75.22264187999991, 10.479652719000057],
              [-75.22299007299995, 10.479601710000054],
              [-75.22358678599994, 10.479555228000038],
              [-75.2235896329999 , 10.479555007000044],
              [-75.22436204599995, 10.478975694000042],
              [-75.2260395319999 , 10.47776417700004 ],
              [-75.22678508099995, 10.476739045000045],
              [-75.22694588799993, 10.476457630000084],
              [-75.22715785499992, 10.476086690000045],
              [-75.22749231499995, 10.476086690000045],
              [-75.22751380999995, 10.476086690000045],
              [-75.22799659399993, 10.476086690000045],
              [-75.22851996899993, 10.476086690000045],
              [-75.22896586199994, 10.476086690000045],
              [-75.22897306899995, 10.476086690000045],
              [-75.22897321799991, 10.476086690000045],
              [-75.22898137899995, 10.47616636400005 ],
              [-75.22898617399994, 10.47619982000009 ],
              [-75.2290237709999 , 10.476409225000054],
              [-75.22902640199993, 10.477118414000074],
              [-75.22920304699994, 10.477750306000075],
              [-75.22933254399993, 10.47790642800004 ],
              [-75.2296310239999 , 10.478266276000056],
              [-75.23036843699992, 10.478666110000063],
              [-75.23112471899992, 10.478931699000043],
              [-75.23219100499995, 10.479234495000071],
              [-75.2335681539999 , 10.479284716000052],
              [-75.2337457029999 , 10.47951375200006 ],
              [-75.23384256799994, 10.480685202000075],
              [-75.23401879399995, 10.481202083000085],
              [-75.23403959299992, 10.48158535600004 ],
              [-75.23411891399991, 10.482083426000088],
              [-75.2341009829999 , 10.482466843000054],
              [-75.23394805299995, 10.48300409300009 ],
              [-75.23375696199992, 10.483694825000043],
              [-75.23358516699994, 10.48436631800007 ],
              [-75.23356715799991, 10.484730572000046],
              [-75.23366541999991, 10.485113561000048],
              [-75.23384107899994, 10.48547710300005 ],
              [-75.2340749089999 , 10.485859600000083],
              [-75.23430852799993, 10.486184594000065],
              [-75.23446524999991, 10.486663210000074],
              [-75.23468014199995, 10.487160782000046],
              [-75.23472737999992, 10.487303137000083],
              [-75.2349151919999 , 10.487869120000084],
              [-75.23500121299992, 10.488204382000049],
              [-75.23505276599991, 10.488405312000054],
              [-75.23520963499993, 10.488922259000049],
              [-75.23542453399995, 10.489419828000052],
              [-75.2355382369999 , 10.489652901000056],
              [-75.2356392179999 , 10.489859896000041],
              [-75.23575336699992, 10.489879402000042],
              [-75.23579160699995, 10.48990307300005 ],
              [-75.2361243819999 , 10.49009837500006 ],
              [-75.23616591899992, 10.49013315600007 ],
              [-75.23622355899994, 10.49018874400008 ],
              [-75.23626358099995, 10.490233186000069],
              [-75.23626732699995, 10.490237359000048],
              [-75.23632026199994, 10.490304431000084],
              [-75.23642142499995, 10.490450082000052],
              [-75.23653145999992, 10.490660266000077],
              [-75.23657726899995, 10.490754941000091],
              [-75.23662543599994, 10.490842722000082],
              [-75.23668730399993, 10.490965115000051],
              [-75.23672017699994, 10.491034422000041],
              [-75.23673768399993, 10.491071336000061],
              [-75.23681098199995, 10.491221429000063],
              [-75.23685905499991, 10.491325334000067],
              [-75.23690030099993, 10.491406160000054],
              [-75.23695077899993, 10.491496256000062],
              [-75.23699891799993, 10.49158864900005 ],
              [-75.2370563639999 , 10.491676473000041],
              [-75.23711161199992, 10.491743575000044],
              [-75.23715763199993, 10.491803705000052],
              [-75.23722447699993, 10.491870861000052],
              [-75.23730748299994, 10.491951924000091],
              [-75.23743219799991, 10.492037831000061],
              [-75.23760077799994, 10.492156217000058],
              [-75.23766762999992, 10.492223376000084],
              [-75.23771137499995, 10.492276588000038],
              [-75.23775973799991, 10.492332127000054],
              [-75.23784245099995, 10.492459243000042],
              [-75.2379571749999 , 10.492662548000055],
              [-75.23803516899994, 10.492803457000036],
              [-75.23808780699994, 10.492918898000084],
              [-75.23816563899993, 10.49308744800004 ],
              [-75.23822512299995, 10.493221346000041],
              [-75.23825935799994, 10.493311349000066],
              [-75.2382958739999 , 10.493408273000057],
              [-75.2383186049999 , 10.493484396000042],
              [-75.2383321659999 , 10.493542052000066],
              [-75.23834113099991, 10.493592762000048],
              [-75.23832913799993, 10.493659483000044],
              [-75.23831466999991, 10.493749213000058],
              [-75.23830142399993, 10.493833989000052],
              [-75.2382977769999 , 10.49385735900006 ],
              [-75.23828810799995, 10.49392178700009 ],
              [-75.23828773899993, 10.493981664000046],
              [-75.23830105899992, 10.494078458000047],
              [-75.2383215719999 , 10.494138443000054],
              [-75.2383513119999 , 10.494205393000072],
              [-75.23838818399992, 10.494244748000085],
              [-75.2384528099999 , 10.494295777000048],
              [-75.2386698439999 , 10.49445589000004 ],
              [-75.23877843899993, 10.494523273000084],
              [-75.23887548099992, 10.494583694000085],
              [-75.23895864699995, 10.494637125000054],
              [-75.23905799399995, 10.494699859000093],
              [-75.23913656699995, 10.494746356000064],
              [-75.23923364299992, 10.494802166000056],
              [-75.23934466499992, 10.494851145000041],
              [-75.23945344399993, 10.494888591000063],
              [-75.23956692899992, 10.494914558000062],
              [-75.23971283399993, 10.494949916000053],
              [-75.23984948099991, 10.494980614000042],
              [-75.23997215799994, 10.495020449000037],
              [-75.24008552099991, 10.495067135000056],
              [-75.24020577199991, 10.495125379000058],
              [-75.2403121019999 , 10.495183545000089],
              [-75.2403952759999 , 10.49523697300009 ],
              [-75.2404506229999 , 10.495287947000065],
              [-75.24051056199994, 10.49534815900006 ],
              [-75.24057046299993, 10.495412973000043],
              [-75.24063033399995, 10.495484694000083],
              [-75.24068550999993, 10.495563301000061],
              [-75.24070510199994, 10.495607066000048],
              [-75.24071754399995, 10.495634869000071],
              [-75.2407610329999 , 10.495729536000056],
              [-75.24079761899992, 10.495814944000074],
              [-75.2408409169999 , 10.495941843000082],
              [-75.24088182499992, 10.496080249000045],
              [-75.24089271499992, 10.496195452000052],
              [-75.24090349999994, 10.49632908700005 ],
              [-75.24092090199991, 10.49651801500005 ],
              [-75.2409780559999 , 10.497034192000058],
              [-75.24097674099994, 10.497250658000041],
              [-75.24097790799993, 10.497439503000066],
              [-75.24097920699995, 10.497607629000072],
              [-75.2409666019999 , 10.497771060000048],
              [-75.24095397399992, 10.497941407000042],
              [-75.24094152499993, 10.498081819000049],
              [-75.24093621199995, 10.498192328000073],
              [-75.24092144299993, 10.498332728000037],
              [-75.24091587599992, 10.498484680000047],
              [-75.24095069499992, 10.498860251000053],
              [-75.24099366899992, 10.499040118000039],
              [-75.24104596899991, 10.499210830000038],
              [-75.24110056899991, 10.499386155000082],
              [-75.24111401699992, 10.499462220000055],
              [-75.2411204629999 , 10.49954516300005 ],
              [-75.24112690299995, 10.499630412000045],
              [-75.2411331749999 , 10.499743283000043],
              [-75.24113499399994, 10.49982619800005 ],
              [-75.24114370599995, 10.499918365000042],
              [-75.24115932199993, 10.500019784000074],
              [-75.24119127599994, 10.500105165000093],
              [-75.2412278509999 , 10.500192878000064],
              [-75.24125981899994, 10.500275964000082],
              [-75.2413010269999 , 10.500363704000051],
              [-75.24137452999992, 10.500481562000061],
              [-75.2414366289999 , 10.500567120000085],
              [-75.24149410299992, 10.500650336000092],
              [-75.24154018399992, 10.500701262000064],
              [-75.24157015199995, 10.50073137000004 ],
              [-75.24163022499994, 10.500768550000089],
              [-75.24169960499995, 10.500801169000056],
              [-75.24188689199991, 10.50089663600005 ],
              [-75.24193768799995, 10.500933760000066],
              [-75.24199307399994, 10.500980131000063],
              [-75.2420438179999 , 10.501026466000042],
              [-75.24208067299992, 10.501068126000064],
              [-75.24212508499994, 10.50111578800005 ],
              [-75.24213677699993, 10.501131137000073],
              [-75.24214094199993, 10.501136589000055],
              [-75.24214270499994, 10.501139316000092],
              [-75.24214358499995, 10.501142346000051],
              [-75.24214411499992, 10.501283554000054],
              [-75.24214862899993, 10.501716181000063],
              [-75.24214852299991, 10.501718060000087],
              [-75.24214578099992, 10.501726398000073],
              [-75.24203796099994, 10.502054280000038],
              [-75.2420301599999 , 10.502234119000093],
              [-75.2420112289999 , 10.50267397600004 ],
              [-75.24196745799992, 10.503159499000049],
              [-75.24184964199992, 10.503569584000047],
              [-75.24171849299995, 10.50393525700008 ],
              [-75.24164051999992, 10.504160421000051],
              [-75.24156152299992, 10.504388566000046],
              [-75.2413785249999 , 10.504847861000087],
              [-75.24126975399992, 10.505041061000043],
              [-75.24114832999993, 10.505219847000092],
              [-75.24111847199993, 10.505252613000039],
              [-75.24099164799992, 10.505391789000043],
              [-75.24084911599994, 10.505500557000062],
              [-75.2408374229999 , 10.505507844000078],
              [-75.2405845049999 , 10.505665466000039],
              [-75.24051259899994, 10.505717915000048],
              [-75.24035812199992, 10.505917109000052],
              [-75.24021787399994, 10.50614117300006 ],
              [-75.24013759799993, 10.506238373000087],
              [-75.24005695199992, 10.506383346000064],
              [-75.23987588999995, 10.506654878000063],
              [-75.23971551599993, 10.506831379000062],
              [-75.23963931499992, 10.506941329000085],
              [-75.23952520599994, 10.507211747000042],
              [-75.23934604199991, 10.50756681100006 ],
              [-75.23919271999995, 10.507828643000039],
              [-75.23910836199991, 10.507983094000053],
              [-75.23890231299993, 10.508228467000038],
              [-75.23866935599995, 10.508457072000056],
              [-75.23860629799992, 10.508510932000092],
              [-75.2385653099999 , 10.508563283000058],
              [-75.2385353489999 , 10.50861487800006 ],
              [-75.23845201799992, 10.508896292000088],
              [-75.2383192819999 , 10.509268777000045],
              [-75.23817756999995, 10.509778907000054],
              [-75.2380221059999 , 10.51012877100004 ],
              [-75.2378234329999 , 10.510470291000047],
              [-75.23777230699994, 10.510534109000048],
              [-75.23770572399991, 10.510615433000055],
              [-75.23744543799995, 10.510775768000087],
              [-75.23721656999993, 10.510918876000062],
              [-75.23700335399991, 10.51098505400006 ],
              [-75.23695849599994, 10.51099227800006 ],
              [-75.23681127799995, 10.511015989000043],
              [-75.2365902919999 , 10.511114797000062],
              [-75.2365040869999 , 10.511195833000045],
              [-75.23645325299992, 10.511265202000061],
              [-75.23631262899994, 10.511363982000091],
              [-75.2363136379999 , 10.511365390000037],
              [-75.23628583899995, 10.51164336000005 ],
              [-75.2362044329999 , 10.512457425000036],
              [-75.2362361509999 , 10.512448437000046],
              [-75.23628249699993, 10.512435295000046],
              [-75.2363545309999 , 10.512412666000046],
              [-75.23642410299993, 10.512415359000045],
              [-75.23649591899994, 10.512429576000045],
              [-75.23656310299992, 10.512441467000087],
              [-75.2366371999999 , 10.512462610000057],
              [-75.23673208499991, 10.512497673000041],
              [-75.2369680079999 , 10.512604932000045],
              [-75.23706747099993, 10.512649234000037],
              [-75.2371692989999 , 10.512686655000039],
              [-75.23722640199992, 10.51270359700004 ],
              [-75.2372939739999 , 10.51278177100005 ],
              [-75.23737919899992, 10.512878968000052],
              [-75.2374667819999 , 10.512969268000063],
              [-75.23754056799993, 10.51304106400005 ],
              [-75.2376081729999 , 10.513097176000088],
              [-75.23764672099992, 10.51312917100006 ],
              [-75.23771821399993, 10.513196350000044],
              [-75.2377750409999 , 10.513251240000045],
              [-75.23777816699993, 10.513254257000085],
              [-75.2378218689999 , 10.51331437500005 ],
              [-75.23788623299993, 10.513409153000055],
              [-75.23793907299995, 10.51349236100009 ],
              [-75.23801263599995, 10.513643719000072],
              [-75.2381062959999 , 10.513836423000043],
              [-75.23816131399991, 10.513942661000044],
              [-75.23821858199994, 10.51406042800005 ],
              [-75.23844029899993, 10.514595937000081],
              [-75.23865965599992, 10.51514368700009 ],
              [-75.23866295499994, 10.515151914000057],
              [-75.23868247599995, 10.515200655000058],
              [-75.23868308099992, 10.515202344000045],
              [-75.23891971699993, 10.515853693000054],
              [-75.23917426499992, 10.516613127000085],
              [-75.23948194899992, 10.517149325000048],
              [-75.23973865499994, 10.517638180000063],
              [-75.2407382849999 , 10.51897776900006 ],
              [-75.24121944199993, 10.519547727000088],
              [-75.24117614099993, 10.519557324000061],
              [-75.24111138299992, 10.519772956000054],
              [-75.24079083999993, 10.520840304000046],
              [-75.24075053699994, 10.520996405000062],
              [-75.24069828199993, 10.521216817000038],
              [-75.24018209099995, 10.523633499000084],
              [-75.24001157999993, 10.524431784000058],
              [-75.23990080599992, 10.524950391000061],
              [-75.23901447599991, 10.529099894000069],
              [-75.23890909399995, 10.52927172200009 ],
              [-75.23880870399995, 10.529379210000059],
              [-75.23871997999993, 10.529475269000045],
              [-75.23864295299995, 10.529552995000074],
              [-75.23855434299992, 10.52962836200004 ],
              [-75.23846810099991, 10.529694547000076],
              [-75.23835859199994, 10.529772086000037],
              [-75.23824674399992, 10.529851921000045],
              [-75.2380906809999 , 10.529952197000057],
              [-75.23790200299993, 10.530075267000086],
              [-75.23770871399995, 10.53019142100004 ],
              [-75.2375085029999 , 10.530300642000043],
              [-75.23702201399993, 10.530553069000064],
              [-75.23724264999993, 10.530956655000068],
              [-75.2370950909999 , 10.531201817000067],
              [-75.2369575539999 , 10.531400222000059],
              [-75.23691728999995, 10.531458304000068],
              [-75.23674192999994, 10.531691806000083],
              [-75.23660645199993, 10.531868687000042],
              [-75.23657361199992, 10.531911562000062],
              [-75.2364263899999 , 10.53209464400004 ],
              [-75.23623243299994, 10.532332643000075],
              [-75.23606432999992, 10.532513314000084],
              [-75.2358611869999 , 10.532735168000045],
              [-75.23566760099993, 10.532906497000056],
              [-75.23551601399993, 10.533036678000087],
              [-75.23539481599994, 10.533127945000047],
              [-75.2352643829999 , 10.533209972000066],
              [-75.23517456999991, 10.533259718000068],
              [-75.23512469299993, 10.533287344000087],
              [-75.23499901899993, 10.533348708000062],
              [-75.23487103799994, 10.533405455000093],
              [-75.23472680499992, 10.533466708000049],
              [-75.23461050499992, 10.533509725000044],
              [-75.23441994899991, 10.533550026000057],
              [-75.23466810699995, 10.534048390000066],
              [-75.23514092399995, 10.534997933000056],
              [-75.2352717739999 , 10.535263065000038],
              [-75.23539567099994, 10.535530467000058],
              [-75.23547362099993, 10.535710240000071],
              [-75.23553102999995, 10.535823214000061],
              [-75.23560214999992, 10.535977650000063],
              [-75.2358114729999 , 10.536328301000083],
              [-75.23581042299992, 10.53632910600004 ],
              [-75.2358337309999 , 10.536374524000053],
              [-75.23570012699992, 10.536413680000067],
              [-75.23565438799994, 10.53642708500007 ],
              [-75.23516635199991, 10.536570119000089],
              [-75.23473763299995, 10.53681961400008 ],
              [-75.2346767109999 , 10.536850667000067],
              [-75.23440170799995, 10.536990849000063],
              [-75.2339803829999 , 10.537304064000068],
              [-75.23364517999994, 10.537666543000057],
              [-75.23345860499995, 10.537990771000068],
              [-75.23331681499991, 10.538373789000048],
              [-75.23324006399992, 10.538912408000044],
              [-75.2332269069999 , 10.539133906000075],
              [-75.23322659699994, 10.539139127000055],
              [-75.23306287699995, 10.539401807000047],
              [-75.2328633539999 , 10.539664625000057],
              [-75.2325650599999 , 10.540324468000051],
              [-75.2324378049999 , 10.540764104000061],
              [-75.23236039299991, 10.54112563800004 ],
              [-75.23224601299995, 10.541470534000041],
              [-75.23208218499991, 10.541704888000083],
              [-75.23169649699992, 10.541975467000043],
              [-75.23152795499993, 10.542004918000089],
              [-75.23111945599993, 10.542076302000055],
              [-75.23111682899992, 10.54207676100009 ],
              [-75.2307795399999 , 10.54218263000007 ],
              [-75.23073424799992, 10.542196845000092],
              [-75.23070067599991, 10.542207382000072],
              [-75.23060321499992, 10.542237972000066],
              [-75.23052315199993, 10.54226310100006 ],
              [-75.22990567399995, 10.542521624000074],
              [-75.22962700999994, 10.542685561000042],
              [-75.22899950999994, 10.54340327600005 ],
              [-75.22860768899994, 10.543950128000063],
              [-75.22829472699993, 10.544517941000038],
              [-75.22816046199995, 10.54500009800006 ],
              [-75.22808231099992, 10.545436710000047],
              [-75.22806479199994, 10.546499278000056],
              [-75.22808035399993, 10.546648688000062],
              [-75.2281687759999 , 10.547497647000057],
              [-75.22815650099994, 10.54759066300005 ],
              [-75.22814897699993, 10.547647633000054],
              [-75.22810610399995, 10.547972465000043],
              [-75.22801522499992, 10.54864838800006 ],
              [-75.22783796899995, 10.549123621000092],
              [-75.22745916999992, 10.549623645000054],
              [-75.2268740159999 , 10.550178287000051],
              [-75.22653162899991, 10.550540788000092],
              [-75.2262041379999 , 10.551059068000086],
              [-75.22601639999993, 10.551268307000043],
              [-75.22592612599993, 10.551368916000058],
              [-75.22583805999994, 10.551467065000054],
              [-75.22578446699993, 10.551526799000044],
              [-75.22566213499994, 10.551663136000059],
              [-75.22521675699994, 10.55198669400005 ],
              [-75.22511274899995, 10.55205753100006 ],
              [-75.22449529399995, 10.55247808100006 ],
              [-75.2244682839999 , 10.55249122400005 ],
              [-75.22444995899991, 10.552500146000057],
              [-75.22423896899994, 10.552602837000052],
              [-75.22422096899993, 10.552611599000045],
              [-75.2239234239999 , 10.55275641500009 ],
              [-75.2233723729999 , 10.552857593000056],
              [-75.22327990699995, 10.552853115000062],
              [-75.22326324799991, 10.552852310000048],
              [-75.22325412599992, 10.552851868000062],
              [-75.22320577199991, 10.552849527000092],
              [-75.22296410399991, 10.55283782400005 ],
              [-75.22329161899995, 10.553372082000067],
              [-75.2234395179999 , 10.553613342000062],
              [-75.22348499599991, 10.553780792000055],
              [-75.22349912699991, 10.55383282200006 ],
              [-75.22350384599991, 10.553850196000042],
              [-75.22356425399994, 10.554072621000046],
              [-75.2236601809999 , 10.554425823000088],
              [-75.2237678329999 , 10.554721837000045],
              [-75.22377471499993, 10.554740762000051],
              [-75.22384120499993, 10.554923595000048],
              [-75.22414385499991, 10.555327718000058],
              [-75.22416035899994, 10.555349757000045],
              [-75.22432513599995, 10.555569781000088],
              [-75.2246969009999 , 10.55616653900006 ],
              [-75.2247257869999 , 10.556404568000062],
              [-75.22464863599993, 10.556737134000059],
              [-75.22448392899992, 10.557086568000045],
              [-75.22447804799992, 10.557083858000055],
              [-75.22454389399991, 10.55716734300006 ],
              [-75.22454336399994, 10.557346377000044],
              [-75.22436591099995, 10.557780021000042],
              [-75.2243273819999 , 10.55814269800004 ],
              [-75.22437485799992, 10.558577179000054],
              [-75.22452590099994, 10.55900356300009 ],
              [-75.2247190839999 , 10.559335616000055],
              [-75.2249772589999 , 10.559662575000061],
              [-75.2253004449999 , 10.559984462000045],
              [-75.22568862299994, 10.560301251000055],
              [-75.22228891099991, 10.560250107000059],
              [-75.21616622999994, 10.561428421000073],
              [-75.21053081499991, 10.56251295900006 ],
              [-75.21002815299994, 10.562609696000038],
              [-75.2020516849999 , 10.564144772000077],
              [-75.18367815999994, 10.567680765000091],
              [-75.1805544469999 , 10.568281925000065],
              [-75.17981896799995, 10.568423468000049],
              [-75.1794591229999 , 10.568492721000041],
              [-75.17921611499992, 10.568539488000056],
              [-75.1788134599999 , 10.56887421500005 ],
              [-75.1782456819999 , 10.569257140000047],
              [-75.1777958959999 , 10.56956090600005 ],
              [-75.17772799799991, 10.569606761000045],
              [-75.1770418609999 , 10.569658840000045],
              [-75.17637305399995, 10.569876486000055],
              [-75.1754367609999 , 10.570194437000055],
              [-75.1740292959999 , 10.569818387000055],
              [-75.17391223999994, 10.569851916000061],
              [-75.17304878199991, 10.569995720000065],
              [-75.17234357699994, 10.570153345000051],
              [-75.17132484399991, 10.570350851000057],
              [-75.17077825699994, 10.571012257000064],
              [-75.16999545199991, 10.571402917000057],
              [-75.16940883099994, 10.571831688000088],
              [-75.16862780299994, 10.572726671000055],
              [-75.16870909999994, 10.573541079000051],
              [-75.1687104689999 , 10.573929021000083],
              [-75.16719048699991, 10.576533525000059],
              [-75.16633485599993, 10.578515014000061],
              [-75.16602473499995, 10.579524752000054],
              [-75.16516512399994, 10.580381204000048],
              [-75.16367759899993, 10.581084636000071],
              [-75.16257876499992, 10.580816857000059],
              [-75.16147952099993, 10.580432686000051],
              [-75.16081172299994, 10.58008582900004 ],
              [-75.1592828069999 , 10.580168668000056],
              [-75.15838124899994, 10.580249347000063],
              [-75.15681135599993, 10.579827980000061],
              [-75.15641803499994, 10.579480172000046],
              [-75.1558689599999 , 10.579443256000047],
              [-75.1550834059999 , 10.57905799200006 ],
              [-75.15429879699991, 10.57894428800006 ],
              [-75.15367157899993, 10.57898522000005 ],
              [-75.15288494499993, 10.578289596000047],
              [-75.15158845699995, 10.577556911000045],
              [-75.15080493199991, 10.577753552000047],
              [-75.14986389899991, 10.577756750000049],
              [-75.14888284599994, 10.577527316000044],
              [-75.14778470199991, 10.577453452000043],
              [-75.14719507599995, 10.577028708000057],
              [-75.14590048199994, 10.576839120000045],
              [-75.14438645699994, 10.576844245000075],
              [-75.14430093199991, 10.576844534000088],
              [-75.14421446199992, 10.576844828000048],
              [-75.14423477199995, 10.576957701000083],
              [-75.14424823699994, 10.577032530000054],
              [-75.1442092659999 , 10.577132362000043],
              [-75.1440888919999 , 10.577601217000051],
              [-75.14407366299992, 10.577885428000059],
              [-75.14408659199995, 10.578453181000043],
              [-75.14409877799994, 10.578947281000069],
              [-75.14410461699993, 10.579213472000049],
              [-75.14401719599994, 10.579462455000055],
              [-75.14391589299993, 10.579798456000049],
              [-75.14378041099991, 10.58005449500007 ],
              [-75.14358107699991, 10.580200236000053],
              [-75.14354098099994, 10.580229551000059],
              [-75.14347605099994, 10.580322556000056],
              [-75.1433556849999 , 10.580494968000039],
              [-75.14327823299993, 10.580743927000071],
              [-75.14330163199992, 10.58099558300006 ],
              [-75.14331261599995, 10.581199707000053],
              [-75.14321672099993, 10.581599494000045],
              [-75.14309673699995, 10.581976133000069],
              [-75.14298460099991, 10.582181666000054],
              [-75.14281967399995, 10.58237686800004 ],
              [-75.14264062199993, 10.582546579000052],
              [-75.14247578299995, 10.582783538000058],
              [-75.1423156919999 , 10.58304948600005 ],
              [-75.1421217969999 , 10.583407716000067],
              [-75.14187823299994, 10.583847819000084],
              [-75.14157250399995, 10.584279929000047],
              [-75.14119002099994, 10.584738298000048],
              [-75.14074080199993, 10.585246091000045],
              [-75.14065639499995, 10.585339540000064],
              [-75.14043126899992, 10.58524400400006 ],
              [-75.14024170699992, 10.585163561000059],
              [-75.14010705299995, 10.585104328000057],
              [-75.13988624299992, 10.585001761000058],
              [-75.13970802099993, 10.584910524000065],
              [-75.13958393999991, 10.584819112000048],
              [-75.13935903799995, 10.584651505000068],
              [-75.13918061699991, 10.584502875000055],
              [-75.13902931799993, 10.584369458000083],
              [-75.13888181999994, 10.584216896000044],
              [-75.13875766399991, 10.584102519000055],
              [-75.13864515599994, 10.584003409000047],
              [-75.13850557399991, 10.583900567000057],
              [-75.13844537799991, 10.583859719000088],
              [-75.13829235599991, 10.583755881000059],
              [-75.13812958699992, 10.583660766000037],
              [-75.13802112299993, 10.583611388000065],
              [-75.1379241329999 , 10.58352370700004 ],
              [-75.1378774719999 , 10.583451160000038],
              [-75.13784238199992, 10.58337092000005 ],
              [-75.13777623999994, 10.583256350000056],
              [-75.13770238799992, 10.583149455000068],
              [-75.13770000699992, 10.583145782000088],
              [-75.13762072999992, 10.583023456000092],
              [-75.1375081079999 , 10.582889908000084],
              [-75.13742677699992, 10.58279666900006 ],
              [-75.13736832299992, 10.582729665000045],
              [-75.13721705199993, 10.582603902000074],
              [-75.13704638299993, 10.582459072000063],
              [-75.1369976389999 , 10.582414369000048],
              [-75.1369299829999 , 10.582352321000087],
              [-75.1367825719999 , 10.582222714000068],
              [-75.13665072099991, 10.582119846000069],
              [-75.1364918729999 , 10.582040025000083],
              [-75.13637559799992, 10.58196771300004 ],
              [-75.13628635599991, 10.581883830000038],
              [-75.1361932179999 , 10.581792308000047],
              [-75.13604194999994, 10.58166654200005 ],
              [-75.1358867859999 , 10.581533137000065],
              [-75.13567751799991, 10.581411392000064],
              [-75.13548377399991, 10.581304904000092],
              [-75.13524749999993, 10.58120238400005 ],
              [-75.13511577299994, 10.581122318000041],
              [-75.13510416499992, 10.581115264000061],
              [-75.13508472099994, 10.581103444000064],
              [-75.13493350399995, 10.58099298600007 ],
              [-75.13480549899992, 10.580882448000068],
              [-75.1347162799999 , 10.580806215000052],
              [-75.13457278599992, 10.58069190200007 ],
              [-75.13442555599994, 10.580615868000052],
              [-75.13430553699993, 10.580578010000067],
              [-75.13418166999992, 10.580547814000056],
              [-75.13398052999992, 10.580540837000058],
              [-75.13383960499993, 10.580556813000044],
              [-75.13377176699993, 10.580564501000083],
              [-75.13363647099993, 10.58058408900007 ],
              [-75.13360427999993, 10.580588572000067],
              [-75.13343931799994, 10.580611541000053],
              [-75.1332421429999 , 10.580631338000046],
              [-75.13311455799993, 10.58064324600008 ],
              [-75.13305206199993, 10.580636326000047],
              [-75.13291336799995, 10.580620965000037],
              [-75.1326810509999 , 10.58054521400004 ],
              [-75.1323405249999 , 10.581617795000057],
              [-75.13215671299992, 10.58215030200006 ],
              [-75.13193858199992, 10.582824512000059],
              [-75.1318366289999 , 10.583120626000039],
              [-75.13170115999992, 10.583514087000083],
              [-75.13152884199991, 10.58401594500009 ],
              [-75.13137544199992, 10.584395287000063],
              [-75.13127927599993, 10.584625003000042],
              [-75.13119898199994, 10.584816800000056],
              [-75.13102643699995, 10.585253608000073],
              [-75.13079246199993, 10.58582072300004 ],
              [-75.13046764899991, 10.585840948000055],
              [-75.1303871419999 , 10.586047854000071],
              [-75.13027586299995, 10.586304606000056],
              [-75.13014129599992, 10.58653847700009 ],
              [-75.13001448299991, 10.586776150000048],
              [-75.12989141299994, 10.586979369000062],
              [-75.12977213699992, 10.587159621000069],
              [-75.12971071399994, 10.587293756000065],
              [-75.12966894599992, 10.587519660000055],
              [-75.12966963899993, 10.58772246600006 ],
              [-75.12967040599995, 10.587944401000073],
              [-75.12968287399991, 10.588196913000047],
              [-75.12970694499995, 10.588449383000068],
              [-75.12971132199993, 10.58859860800004 ],
              [-75.12971134499992, 10.58860465400005 ],
              [-75.12971181099994, 10.588740185000063],
              [-75.12970845699994, 10.588889435000056],
              [-75.1296509789999 , 10.589046513000085],
              [-75.12956273399993, 10.589253444000065],
              [-75.12947836599994, 10.589464188000079],
              [-75.12940559399993, 10.58967106600005 ],
              [-75.12930940899992, 10.589820625000073],
              [-75.12922094399994, 10.589962505000074],
              [-75.12912856199995, 10.59009291700005 ],
              [-75.1290091429999 , 10.59023107300004 ],
              [-75.12888194199991, 10.590357777000065],
              [-75.12877413599995, 10.590499722000061],
              [-75.1286585659999 , 10.590634040000054],
              [-75.1285236459999 , 10.590764594000063],
              [-75.12841178699995, 10.59085297900009 ],
              [-75.12828831999991, 10.59094140500008 ],
              [-75.12814167999994, 10.591037558000039],
              [-75.12797571099992, 10.591137606000075],
              [-75.12783292099994, 10.59122992400006 ],
              [-75.12766694299995, 10.591326145000039],
              [-75.12753191299993, 10.59142608500008 ],
              [-75.1274200819999 , 10.59152212500004 ],
              [-75.12731986499995, 10.591621950000047],
              [-75.12721587099992, 10.59174857700009 ],
              [-75.12712358599993, 10.591905775000043],
              [-75.12700806299995, 10.59205539800007 ],
              [-75.12691971299995, 10.592231713000047],
              [-75.12679661499993, 10.592427281000084],
              [-75.12667359699992, 10.592645805000075],
              [-75.12658522899994, 10.592818298000054],
              [-75.12650458299993, 10.592986934000066],
              [-75.12645091999991, 10.593128695000075],
              [-75.12640497899991, 10.593266611000047],
              [-75.12632037299994, 10.593408473000068],
              [-75.12623186199994, 10.593538873000057],
              [-75.12615894399994, 10.593703658000038],
              [-75.12610525399992, 10.593837768000071],
              [-75.12605541699992, 10.59396803900006 ],
              [-75.12599789699993, 10.594113640000046],
              [-75.12596517399993, 10.59414795400005 ],
              [-75.12592467799993, 10.59419041700005 ],
              [-75.12568929299994, 10.59435191600005 ],
              [-75.12558896999991, 10.594440750000047],
              [-75.12551577599993, 10.594505561000062],
              [-75.12525371599992, 10.594781950000083],
              [-75.1251574289999 , 10.59490089600007 ],
              [-75.12506498199991, 10.595012176000068],
              [-75.1249570359999 , 10.595115853000038],
              [-75.12487606399992, 10.59518883000004 ],
              [-75.12475657599992, 10.59530785000004 ],
              [-75.12462168699994, 10.59544988500005 ],
              [-75.12450604999992, 10.595565068000042],
              [-75.12441738199993, 10.595649549000086],
              [-75.12431339599993, 10.595779999000058],
              [-75.12423243699993, 10.595856801000082],
              [-75.12414296899993, 10.595945914000083],
              [-75.1238458979999 , 10.596094969000092],
              [-75.12383609799991, 10.596099885000058],
              [-75.1240149919999 , 10.598814726000057],
              [-75.12395253999995, 10.59990318000007 ],
              [-75.12393979199993, 10.600125361000039],
              [-75.12392988399995, 10.60013207500009 ],
              [-75.12380262699992, 10.600243469000077],
              [-75.1236910099999 , 10.600404557000047],
              [-75.1236371469999 , 10.600488922000068],
              [-75.1235524999999 , 10.60061931100006 ],
              [-75.1234792379999 , 10.600684602000058],
              [-75.12337112199992, 10.600738536000051],
              [-75.12320889499995, 10.600804133000054],
              [-75.12308536499995, 10.600873422000063],
              [-75.12294646599992, 10.60097337500008 ],
              [-75.1228112629999 , 10.601023574000067],
              [-75.12258338799995, 10.601119999000048],
              [-75.12240573499992, 10.601197121000041],
              [-75.12219326899992, 10.601274363000073],
              [-75.12204254199992, 10.601309307000065],
              [-75.12182595799993, 10.601313854000068],
              [-75.12159803199995, 10.601394970000058],
              [-75.12149771999992, 10.601468012000055],
              [-75.12129318399991, 10.601602626000044],
              [-75.12110023899993, 10.60173337200007 ],
              [-75.1209072929999 , 10.601864115000069],
              [-75.12070282299993, 10.602017858000067],
              [-75.12033478599994, 10.602258567000092],
              [-75.12022701099994, 10.602451443000064],
              [-75.12011811299993, 10.602580973000045],
              [-75.12004197599992, 10.602581125000086],
              [-75.11988726599992, 10.602581434000058],
              [-75.11934815199993, 10.602619597000057],
              [-75.11931112099995, 10.602623764000043],
              [-75.11903089599991, 10.60265529700007 ],
              [-75.11860029999991, 10.602782150000053],
              [-75.11819416499992, 10.602949969000065],
              [-75.11811879699991, 10.602981113000055],
              [-75.11749270999991, 10.60327723800009 ],
              [-75.11711742699993, 10.603446722000058],
              [-75.11696364799991, 10.60351617300006 ],
              [-75.1166406289999 , 10.603661277000072],
              [-75.11653652199993, 10.60370804300004 ],
              [-75.1165252099999 , 10.603713127000049],
              [-75.11640005499993, 10.603775511000038],
              [-75.11632786199993, 10.603756363000059],
              [-75.11634619599994, 10.603745730000071],
              [-75.11629572099991, 10.603690751000045],
              [-75.11602397599995, 10.603394756000057],
              [-75.11570180599995, 10.603059089000055],
              [-75.11527872499994, 10.602620437000041],
              [-75.11500699399994, 10.602330521000056],
              [-75.11470027099995, 10.601987144000077],
              [-75.11440528899993, 10.601685824000072],
              [-75.11423448799991, 10.601502715000038],
              [-75.11403259699995, 10.601277616000061],
              [-75.11371037799995, 10.600926640000068],
              [-75.11353556499995, 10.600701452000067],
              [-75.11332211599995, 10.600487872000087],
              [-75.11314732999995, 10.600270334000072],
              [-75.11298427499992, 10.600091028000065],
              [-75.11275132899993, 10.599831591000054],
              [-75.1126075979999 , 10.599648392000063],
              [-75.11248671899995, 10.59952959800006 ],
              [-75.11248669899993, 10.599529577000055],
              [-75.11242520499991, 10.599469143000078],
              [-75.11225823599995, 10.599274541000057],
              [-75.1120636519999 , 10.599086047000071],
              [-75.11198659999991, 10.599011405000056],
              [-75.1116489289999 , 10.598664308000082],
              [-75.11140446799993, 10.598431693000066],
              [-75.11119097999995, 10.59820663000005 ],
              [-75.1110357469999 , 10.59805025500009 ],
              [-75.11079905299994, 10.597825268000065],
              [-75.11059337499995, 10.597623139000063],
              [-75.11033725399994, 10.597371432000045],
              [-75.11005799199995, 10.597138932000064],
              [-75.10984848599992, 10.596948293000082],
              [-75.10962740899993, 10.596765347000087],
              [-75.10931717199992, 10.596525295000049],
              [-75.1089720089999 , 10.59624709500008 ],
              [-75.10867736699993, 10.596041427000046],
              [-75.10848339899991, 10.595869870000058],
              [-75.10824309999992, 10.595721427000058],
              [-75.10801887299993, 10.595547345000057],
              [-75.1079289889999 , 10.595477561000052],
              [-75.10763041799993, 10.595252774000073],
              [-75.10734358499991, 10.595070045000057],
              [-75.10704879499991, 10.594818459000066],
              [-75.10670383599995, 10.594601480000051],
              [-75.10648667599992, 10.594433823000088],
              [-75.10622306699992, 10.594254843000044],
              [-75.10595564499994, 10.594091177000053],
              [-75.10567270099995, 10.593912257000056],
              [-75.10545098099993, 10.59375226900005 ],
              [-75.1054506609999 , 10.593656605000092],
              [-75.10516873299991, 10.593565694000063],
              [-75.10461496999994, 10.593364701000041],
              [-75.10422005099991, 10.593243545000064],
              [-75.1039898969999 , 10.593208898000057],
              [-75.10387178199994, 10.59319111700006 ],
              [-75.10345005199991, 10.593146578000074],
              [-75.1029744139999 , 10.59317492100007 ],
              [-75.10296720699995, 10.593175526000039],
              [-75.10264187799993, 10.59320279700006 ],
              [-75.10223991099991, 10.59328447100006 ],
              [-75.10190360999991, 10.593342971000084],
              [-75.10158664799991, 10.593401406000055],
              [-75.10151906099992, 10.593417325000075],
              [-75.10127360899992, 10.593475136000052],
              [-75.1009828629999 , 10.593538238000065],
              [-75.10093349599993, 10.593548953000038],
              [-75.1007950579999 , 10.593581870000037],
              [-75.10063977699991, 10.59361879100004 ],
              [-75.10033831499993, 10.593684827000061],
              [-75.10007940799994, 10.593754552000064],
              [-75.09989382299995, 10.593774292000091],
              [-75.09964639099991, 10.593805711000073],
              [-75.09938344799991, 10.593825701000071],
              [-75.09909724699992, 10.593830461000039],
              [-75.09881867599995, 10.593800760000079],
              [-75.09856320499995, 10.593740367000066],
              [-75.09851216699991, 10.593726120000042],
              [-75.09848989199992, 10.593719902000089],
              [-75.09829220799992, 10.593664721000039],
              [-75.09808308599992, 10.593585045000054],
              [-75.09789338999991, 10.593532089000064],
              [-75.09763787799994, 10.593460218000075],
              [-75.09737850099992, 10.593388357000038],
              [-75.09713070899994, 10.59331263100006 ],
              [-75.09689460299995, 10.593259827000054],
              [-75.09649969799995, 10.593142488000069],
              [-75.09622103599992, 10.59308599600007 ],
              [-75.0959231949999 , 10.593006510000066],
              [-75.09556792699993, 10.593285880000053],
              [-75.09548342199992, 10.593352332000052],
              [-75.0951786359999 , 10.593582919000085],
              [-75.09492015899991, 10.593782741000041],
              [-75.09458049599994, 10.593994308000049],
              [-75.09427178899995, 10.594209599000067],
              [-75.0940905679999 , 10.594378558000074],
              [-75.09383609299994, 10.594620458000065],
              [-75.09360086499993, 10.594831685000088],
              [-75.09331550199994, 10.595088991000068],
              [-75.09295677999995, 10.595384803000059],
              [-75.0926211609999 , 10.595649926000078],
              [-75.09233970299994, 10.595918700000084],
              [-75.09205811599992, 10.596149209000089],
              [-75.09175739499995, 10.596441004000042],
              [-75.0914990039999 , 10.596667609000065],
              [-75.09111332899994, 10.597001772000056],
              [-75.0908973409999 , 10.597186147000059],
              [-75.09064288099995, 10.59743187500004 ],
              [-75.09028023099995, 10.597712388000048],
              [-75.08997943499992, 10.597981226000059],
              [-75.08982395899994, 10.598118022000051],
              [-75.0895914859999 , 10.598322563000067],
              [-75.08932465899994, 10.598592908000057],
              [-75.08924219199992, 10.598907109000038],
              [-75.0892354959999 , 10.598932623000053],
              [-75.0882919949999 , 10.600293367000063],
              [-75.08708819999993, 10.602119421000054],
              [-75.0860099919999 , 10.603703901000074],
              [-75.08344944999993, 10.607540421000067],
              [-75.0832959629999 , 10.607540913000037],
              [-75.0808100829999 , 10.60663252900008 ],
              [-75.0792099759999 , 10.606012405000058],
              [-75.07804379399994, 10.605560593000064],
              [-75.0772663759999 , 10.605268314000057],
              [-75.0766426749999 , 10.60504700100006 ],
              [-75.0756661879999 , 10.604621375000079],
              [-75.0735147449999 , 10.603815401000077],
              [-75.0719923719999 , 10.603238530000056],
              [-75.07171994799995, 10.603135302000055],
              [-75.07134654299995, 10.602993808000065],
              [-75.0705225879999 , 10.602681586000074],
              [-75.06940141399991, 10.602167749000046],
              [-75.06916812299994, 10.602060830000084],
              [-75.06772511299994, 10.601399498000092],
              [-75.06772409099995, 10.601399014000037],
              [-75.0672544279999 , 10.601176749000047],
              [-75.06511115399991, 10.599699993000058],
              [-75.06381185499993, 10.598451658000045],
              [-75.06232684499992, 10.597024868000062],
              [-75.06015192299992, 10.594935173000067],
              [-75.06012400399993, 10.594921691000081],
              [-75.0589183909999 , 10.594339458000093],
              [-75.05875483499995, 10.594260470000052],
              [-75.0572889099999 , 10.593644295000047],
              [-75.05708717899995, 10.593421177000039]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08634",
      "properties": {"id": "08634", "iso": "SAB", "name": "SABANAGRANDE"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.73940137999995, 10.825355892000061],
              [-74.7390880609999 , 10.82102788900005 ],
              [-74.73859298299993, 10.817277510000054],
              [-74.73847017199995, 10.816347174000043],
              [-74.73832453099993, 10.815128387000073],
              [-74.73806787899991, 10.812178367000058],
              [-74.73788356099993, 10.809616886000072],
              [-74.73771836299994, 10.807620746000055],
              [-74.7367969259999 , 10.803029010000046],
              [-74.73623669799991, 10.800167972000054],
              [-74.73492497399991, 10.796866825000052],
              [-74.7343358789999 , 10.795312469000066],
              [-74.73407235399992, 10.794617147000054],
              [-74.7340083389999 , 10.794448241000055],
              [-74.73333489499993, 10.793175796000071],
              [-74.73143319499991, 10.789582608000046],
              [-74.73130407399992, 10.78933863900005 ],
              [-74.73128093699995, 10.789294923000057],
              [-74.73112510399994, 10.788958895000064],
              [-74.73063778699992, 10.787908074000086],
              [-74.73019078699991, 10.786944189000053],
              [-74.72914450299993, 10.784688044000063],
              [-74.7288095749999 , 10.784015976000092],
              [-74.7274678039999 , 10.781323550000081],
              [-74.72736775999994, 10.781122810000056],
              [-74.72688149999993, 10.779357016000063],
              [-74.72601810399993, 10.776708600000063],
              [-74.7259704139999 , 10.776546661000054],
              [-74.7258639929999 , 10.776185291000047],
              [-74.72555010699995, 10.77511944400004 ],
              [-74.72511400299993, 10.771728067000083],
              [-74.7251140649999 , 10.771726235000074],
              [-74.72781331499993, 10.771713677000037],
              [-74.72863965499994, 10.77163186100006 ],
              [-74.72984445799995, 10.771512569000038],
              [-74.7308130909999 , 10.771416660000057],
              [-74.73261814799991, 10.771237927000072],
              [-74.73286873099994, 10.771194756000057],
              [-74.73289115699993, 10.771190893000039],
              [-74.73465177299994, 10.770887551000044],
              [-74.7347789179999 , 10.77088670900008 ],
              [-74.7365897599999 , 10.770874688000049],
              [-74.73704706799992, 10.77087165000006 ],
              [-74.73815238399993, 10.77083715200007 ],
              [-74.7385344729999 , 10.770825227000046],
              [-74.73905153299995, 10.772197591000065],
              [-74.73891381599992, 10.773377951000043],
              [-74.73876297999993, 10.774670731000072],
              [-74.75161879299992, 10.775873059000048],
              [-74.75177453299995, 10.775886717000049],
              [-74.7540569329999 , 10.776086859000088],
              [-74.75452825799994, 10.77612818700004 ],
              [-74.75466702599994, 10.776140355000052],
              [-74.75627903399993, 10.776281701000073],
              [-74.75653929499993, 10.776304521000043],
              [-74.75673993999993, 10.776314169000045],
              [-74.75675067599991, 10.776314684000056],
              [-74.75677338799994, 10.776315774000068],
              [-74.75677343899991, 10.776316701000042],
              [-74.7567734399999 , 10.77631673600007 ],
              [-74.7567744179999 , 10.77635482800008 ],
              [-74.75677502399992, 10.776378457000078],
              [-74.7567770089999 , 10.776455899000041],
              [-74.75677700999995, 10.776455935000058],
              [-74.75695190099992, 10.776452366000058],
              [-74.75700060299994, 10.776453268000068],
              [-74.75706834699992, 10.776454522000051],
              [-74.75714463699995, 10.776455935000058],
              [-74.7572552819999 , 10.776474971000084],
              [-74.75729857999994, 10.776496378000047],
              [-74.7573671909999 , 10.776530304000062],
              [-74.7574289829999 , 10.776605840000059],
              [-74.75796198199993, 10.776603462000082],
              [-74.7580524519999 , 10.776491841000052],
              [-74.75814183599994, 10.776381563000086],
              [-74.7581451989999 , 10.776377413000091],
              [-74.7581451929999 , 10.776377380000042],
              [-74.75813092499993, 10.77632030600006 ],
              [-74.75813092599992, 10.776320271000088],
              [-74.75813270499992, 10.776305880000052],
              [-74.75813751099992, 10.776266992000046],
              [-74.7581480689999 , 10.77626775300007 ],
              [-74.7582820909999 , 10.77627741200007 ],
              [-74.75844228599993, 10.77624181300007 ],
              [-74.75846731699994, 10.776236250000068],
              [-74.7584876759999 , 10.776231726000049],
              [-74.75849295099994, 10.776231003000078],
              [-74.75853724299992, 10.776224935000073],
              [-74.75862350799991, 10.77621311900009 ],
              [-74.75863771099995, 10.776210795000054],
              [-74.75863782699992, 10.776210776000084],
              [-74.75887723799991, 10.77617159600004 ],
              [-74.75900492699992, 10.776156247000074],
              [-74.75909491599992, 10.776156766000042],
              [-74.75916475199995, 10.776159447000055],
              [-74.7592455919999 , 10.776162551000084],
              [-74.75934240299995, 10.776166268000054],
              [-74.75935547499995, 10.776166472000057],
              [-74.75954120299991, 10.776169363000065],
              [-74.75957306799995, 10.776169860000039],
              [-74.75960245099992, 10.776175737000074],
              [-74.75986224399992, 10.776227696000092],
              [-74.75988628099992, 10.776232503000074],
              [-74.7599151149999 , 10.776238269000089],
              [-74.75995861199993, 10.776241704000086],
              [-74.76004621099992, 10.776248619000057],
              [-74.76025418799992, 10.776265038000076],
              [-74.76031503899992, 10.776269095000089],
              [-74.76045580699991, 10.776278479000041],
              [-74.7605218779999 , 10.776282884000068],
              [-74.76052266799991, 10.776282945000048],
              [-74.76068405399991, 10.77629253400005 ],
              [-74.76077638599992, 10.77629802000007 ],
              [-74.76086597699992, 10.776303343000052],
              [-74.76106148999992, 10.776314958000057],
              [-74.7612087569999 , 10.776328030000059],
              [-74.76130175899993, 10.77633628500007 ],
              [-74.76137468699994, 10.77633987900009 ],
              [-74.76148555899994, 10.776345345000038],
              [-74.7615550189999 , 10.776349099000072],
              [-74.76179764599993, 10.776362215000063],
              [-74.76180052799992, 10.776362371000062],
              [-74.76203580999993, 10.776375089000055],
              [-74.76212906199993, 10.776382382000065],
              [-74.76224043199994, 10.776391094000076],
              [-74.7622651289999 , 10.77639302600005 ],
              [-74.76227695599994, 10.776393921000079],
              [-74.76230102099993, 10.77639574300008 ],
              [-74.76241701499993, 10.776404525000089],
              [-74.76246421699994, 10.77640722600006 ],
              [-74.76298823299993, 10.77643721600009 ],
              [-74.7630912589999 , 10.77644420900009 ],
              [-74.7632303709999 , 10.776451099000042],
              [-74.7633782389999 , 10.776458422000076],
              [-74.7634396819999 , 10.776461465000068],
              [-74.76368407899992, 10.776478480000037],
              [-74.76385920699994, 10.776490673000069],
              [-74.76423517399991, 10.776513519000048],
              [-74.76432782199993, 10.77651862600004 ],
              [-74.76452465999995, 10.776529476000064],
              [-74.76459807299995, 10.776533524000058],
              [-74.76462403099993, 10.776534359000038],
              [-74.76461935299994, 10.77659751400006 ],
              [-74.76461789599995, 10.776617206000083],
              [-74.7646161049999 , 10.776641399000084],
              [-74.76472912199995, 10.776650992000043],
              [-74.7648941299999 , 10.776663703000054],
              [-74.76497021999995, 10.77666604500007 ],
              [-74.7650957109999 , 10.776675924000074],
              [-74.76511526599995, 10.776677463000055],
              [-74.76528257699994, 10.776693545000057],
              [-74.76564363799991, 10.776726193000059],
              [-74.76602015899994, 10.77671848600005 ],
              [-74.76642014599992, 10.776705187000061],
              [-74.7668034859999 , 10.776654797000049],
              [-74.76716294999994, 10.776587043000063],
              [-74.76731823999995, 10.776544587000046],
              [-74.76756113899995, 10.776452393000056],
              [-74.7677118009999 , 10.776452237000058],
              [-74.76781964799994, 10.77648419500008 ],
              [-74.7679074799999 , 10.776536003000047],
              [-74.76809078199994, 10.776684206000084],
              [-74.76833176299994, 10.776847130000078],
              [-74.76860644399994, 10.776973639000062],
              [-74.76907542599992, 10.77710632700007 ],
              [-74.76952427599991, 10.77719944100005 ],
              [-74.7700446799999 , 10.777228132000062],
              [-74.77041320199993, 10.777269408000052],
              [-74.77078175699995, 10.77732801400009 ],
              [-74.77093473199994, 10.777362347000064],
              [-74.77104095899995, 10.777351792000047],
              [-74.77110149099991, 10.777334950000068],
              [-74.77114866699992, 10.777321825000058],
              [-74.7712236829999 , 10.777247360000047],
              [-74.7720608269999 , 10.777262806000067],
              [-74.77336032199992, 10.777247254000088],
              [-74.77404821899995, 10.777245659000073],
              [-74.77408564599995, 10.777173752000067],
              [-74.7741078599999 , 10.77702262300005 ],
              [-74.77421671099995, 10.776265556000055],
              [-74.77455266599992, 10.776309379000054],
              [-74.77483789299993, 10.77637558300006 ],
              [-74.77483798399993, 10.776375607000091],
              [-74.77483804999991, 10.776375620000067],
              [-74.77504696299991, 10.776428667000062],
              [-74.7750470169999 , 10.776428683000063],
              [-74.77547995999993, 10.776568242000053],
              [-74.77579853099991, 10.776686409000092],
              [-74.77611504999993, 10.776804631000061],
              [-74.7761218739999 , 10.776807179000059],
              [-74.77642148799993, 10.776865718000067],
              [-74.77686378699991, 10.77690115300004 ],
              [-74.77707483499995, 10.776897357000053],
              [-74.77734950999991, 10.776880168000048],
              [-74.77745998499995, 10.776846793000061],
              [-74.77754373899995, 10.776846601000045],
              [-74.7776308789999 , 10.776862966000067],
              [-74.77777837899993, 10.77690238200006 ],
              [-74.7778990619999 , 10.77693854100005 ],
              [-74.77823090399994, 10.777013969000052],
              [-74.77851916299994, 10.777076248000071],
              [-74.77898172599993, 10.777181192000057],
              [-74.77923648199993, 10.777243548000058],
              [-74.77922115299992, 10.777283882000063],
              [-74.77914325199993, 10.777488883000046],
              [-74.77888671099993, 10.778098957000054],
              [-74.77872669299995, 10.778437193000059],
              [-74.77868715799991, 10.778567782000039],
              [-74.7786735059999 , 10.778612873000043],
              [-74.77867199899993, 10.77861792300007 ],
              [-74.7785285139999 , 10.779158092000046],
              [-74.77841484899994, 10.779607787000089],
              [-74.77864248599991, 10.779676241000061],
              [-74.7791621369999 , 10.779837364000059],
              [-74.7797991839999 , 10.78005784000004 ],
              [-74.7799096789999 , 10.78009058300006 ],
              [-74.7804093389999 , 10.780238629000053],
              [-74.7808519049999 , 10.780386676000091],
              [-74.7810798619999 , 10.780449090000047],
              [-74.78107886899994, 10.780484711000042],
              [-74.78107367799993, 10.780671041000062],
              [-74.7810665799999 , 10.780898648000061],
              [-74.78105186999994, 10.78137001400006 ],
              [-74.7810658759999 , 10.781628355000066],
              [-74.78108963099993, 10.78175748600006 ],
              [-74.78117395899994, 10.782002413000043],
              [-74.78127842599991, 10.782263856000043],
              [-74.78149372299993, 10.782640981000043],
              [-74.78153924199995, 10.782702997000058],
              [-74.78162479999992, 10.78281955600005 ],
              [-74.7817887189999 , 10.782716490000041],
              [-74.78230040199992, 10.78233438600006 ],
              [-74.78273847299994, 10.781992198000069],
              [-74.7831631489999 , 10.78165335500006 ],
              [-74.78353435799994, 10.78137425500006 ],
              [-74.78390888799993, 10.78107859000005 ],
              [-74.78423320199994, 10.780802908000055],
              [-74.7846079219999 , 10.780590051000047],
              [-74.7847253729999 , 10.780524443000047],
              [-74.78495922799993, 10.780393806000063],
              [-74.78543436199993, 10.780144281000048],
              [-74.78570537799993, 10.779997907000052],
              [-74.78579906699991, 10.779948004000062],
              [-74.7859229529999 , 10.779917907000083],
              [-74.786067        , 10.779914261000044],
              [-74.78629815999994, 10.779913728000054],
              [-74.78652932099993, 10.779913195000063],
              [-74.78672024299993, 10.77989950500006 ],
              [-74.78688764499992, 10.77985274200006 ],
              [-74.78709848199992, 10.779759506000062],
              [-74.78760715699991, 10.779526464000071],
              [-74.78804213899991, 10.779300213000056],
              [-74.7884235919999 , 10.779103894000059],
              [-74.78874151199994, 10.778957413000057],
              [-74.7890058349999 , 10.778814364000084],
              [-74.7891832009999 , 10.778734455000063],
              [-74.7893238609999 , 10.778714252000043],
              [-74.78939422099995, 10.77871740200004 ],
              [-74.78953164699993, 10.778746900000044],
              [-74.7896255149999 , 10.778773178000051],
              [-74.78974279999994, 10.77878615800006 ],
              [-74.78999073199992, 10.778795519000084],
              [-74.79020510899994, 10.778781773000048],
              [-74.79040942099994, 10.778761424000038],
              [-74.79053331299991, 10.778734635000092],
              [-74.79078101599993, 10.778647939000052],
              [-74.79093163699991, 10.778591275000053],
              [-74.79105209199992, 10.778528064000056],
              [-74.79119593999991, 10.778441601000054],
              [-74.79142993099993, 10.778222438000057],
              [-74.79171399899991, 10.777930283000046],
              [-74.79197127999993, 10.777644819000045],
              [-74.7922520969999 , 10.777395733000048],
              [-74.79250288299994, 10.777196399000047],
              [-74.79273368299994, 10.777046806000044],
              [-74.7929712369999 , 10.776917068000046],
              [-74.79315197699992, 10.776847084000053],
              [-74.79327252199994, 10.776823617000048],
              [-74.79355729999992, 10.776829578000047],
              [-74.7938554619999 , 10.776832193000075],
              [-74.79415357899995, 10.77681162500005 ],
              [-74.79433110799994, 10.776801274000093],
              [-74.79455546299994, 10.776757687000043],
              [-74.79479647199992, 10.776674314000047],
              [-74.79514117699995, 10.776524451000057],
              [-74.7955361089999 , 10.77636453200006 ],
              [-74.79602484999992, 10.776207703000068],
              [-74.79635969899994, 10.776140671000064],
              [-74.79670797699993, 10.776083545000063],
              [-74.79706630899994, 10.776029709000056],
              [-74.79748505299995, 10.77602210300006 ],
              [-74.79757301299992, 10.77611965300008 ],
              [-74.7976697279999 , 10.77613795700006 ],
              [-74.79794457799994, 10.776237810000055],
              [-74.79817849799991, 10.776343576000045],
              [-74.79834660099993, 10.776411513000085],
              [-74.79847521399995, 10.776425024000048],
              [-74.79868536599992, 10.776365159000079],
              [-74.79888683099995, 10.776288426000065],
              [-74.79898053999995, 10.776272533000053],
              [-74.79907501499991, 10.776275140000052],
              [-74.79924050499994, 10.776316732000055],
              [-74.79946546699995, 10.776421496000069],
              [-74.79965724399995, 10.776467962000083],
              [-74.7998131029999 , 10.77646932500005 ],
              [-74.80001242599991, 10.77645319800007 ],
              [-74.8002654789999 , 10.776337717000047],
              [-74.80049364799993, 10.776189797000086],
              [-74.80071052699992, 10.776063922000048],
              [-74.80098476499995, 10.775872611000068],
              [-74.8010548179999 , 10.775831904000086],
              [-74.80116691199993, 10.775766756000053],
              [-74.80137683599992, 10.775649611000063],
              [-74.80162109499992, 10.775533732000042],
              [-74.8017955549999 , 10.775476643000047],
              [-74.80199460099993, 10.775425010000049],
              [-74.80211810499992, 10.77542008100005 ],
              [-74.80234831599995, 10.77540166700004 ],
              [-74.8026597999999 , 10.775376144000063],
              [-74.80284564399994, 10.775338961000045],
              [-74.80300712999991, 10.775293659000056],
              [-74.80324355899995, 10.775208331000044],
              [-74.803454        , 10.775102050000044],
              [-74.80361373499994, 10.774987228000043],
              [-74.80373067199992, 10.774888841000063],
              [-74.80382164799994, 10.774826444000041],
              [-74.80394782099995, 10.77471317900006 ],
              [-74.80408377999993, 10.774542150000059],
              [-74.80417574899991, 10.774365948000082],
              [-74.80421692899995, 10.774267615000042],
              [-74.80423366999992, 10.77422763900006 ],
              [-74.80424758299995, 10.774229275000039],
              [-74.80443100299993, 10.774250849000055],
              [-74.8047351429999 , 10.774273243000039],
              [-74.8048407739999 , 10.774281020000046],
              [-74.80516971899993, 10.774284135000073],
              [-74.8054296119999 , 10.774238747000084],
              [-74.8055083459999 , 10.774215200000071],
              [-74.80573843299993, 10.774407619000044],
              [-74.80607787199995, 10.77467348600004 ],
              [-74.80661151999993, 10.775097086000073],
              [-74.80678126099991, 10.77524072400007 ],
              [-74.80679655199992, 10.77525257600007 ],
              [-74.80721820199994, 10.775579246000063],
              [-74.80746922299994, 10.77576606200006 ],
              [-74.80772137799994, 10.775953725000079],
              [-74.80785990099992, 10.776058084000056],
              [-74.80786715599993, 10.776063547000092],
              [-74.80795700499993, 10.776131238000062],
              [-74.80808495499991, 10.776164032000054],
              [-74.80828812399994, 10.776216100000056],
              [-74.80876764399994, 10.77635263600007 ],
              [-74.8088577449999 , 10.776397726000084],
              [-74.8096272379999 , 10.776808522000067],
              [-74.81011198599992, 10.777068246000056],
              [-74.81027905499991, 10.77719452000008 ],
              [-74.8104941599999 , 10.777307312000062],
              [-74.81083095699995, 10.777518307000037],
              [-74.81136130699991, 10.777721430000042],
              [-74.81170792199993, 10.77787932800004 ],
              [-74.81177447899995, 10.777932491000058],
              [-74.81177500299992, 10.777932908000082],
              [-74.8116894929999 , 10.778048689000059],
              [-74.81167344499994, 10.778064096000037],
              [-74.81165772899993, 10.778079183000045],
              [-74.81164982699994, 10.778086768000037],
              [-74.81159595899993, 10.778138484000067],
              [-74.8115058919999 , 10.778249138000092],
              [-74.81141236299993, 10.778364646000057],
              [-74.81129864299993, 10.778478842000084],
              [-74.81098994899992, 10.778721039000061],
              [-74.81078705699991, 10.778841054000054],
              [-74.8106423669999 , 10.778901147000056],
              [-74.81048033399992, 10.77895862400004 ],
              [-74.81033889899993, 10.779011104000062],
              [-74.81021147799993, 10.779108232000056],
              [-74.80999782099991, 10.779250475000083],
              [-74.80982216299992, 10.779353964000052],
              [-74.80964537499995, 10.77946423700007 ],
              [-74.80943451099995, 10.779554531000088],
              [-74.80921172599994, 10.77963257500005 ],
              [-74.80888608399994, 10.779755080000086],
              [-74.80865706799995, 10.77980621100005 ],
              [-74.8084883219999 , 10.779843649000043],
              [-74.80853724399992, 10.78150398200006 ],
              [-74.80855047199992, 10.781739968000068],
              [-74.80855514699994, 10.781835216000047],
              [-74.80853774499991, 10.782044230000054],
              [-74.80850456999991, 10.782316483000045],
              [-74.80850178499992, 10.782367415000067],
              [-74.8084950299999 , 10.78249095600006 ],
              [-74.80849478999994, 10.78257620900007 ],
              [-74.80845999299993, 10.78265910400006 ],
              [-74.80843064999993, 10.782883948000062],
              [-74.80840229199993, 10.78315448300009 ],
              [-74.80838780399995, 10.78340423800006 ],
              [-74.80837743099994, 10.783754912000063],
              [-74.80833955599991, 10.78400748200005 ],
              [-74.80833174499992, 10.784299503000057],
              [-74.80827878299993, 10.78464286600007 ],
              [-74.80819654699991, 10.785124704000054],
              [-74.8081557559999 , 10.78562181500007 ],
              [-74.80808884899994, 10.786125794000043],
              [-74.8079960579999 , 10.786696517000053],
              [-74.80796001399995, 10.786860629000046],
              [-74.80786468899993, 10.787240090000068],
              [-74.8077632369999 , 10.78752029900005 ],
              [-74.80772895099994, 10.787783317000049],
              [-74.80771213799994, 10.787971863000053],
              [-74.8076792089999 , 10.78821756700006 ],
              [-74.80767156999991, 10.788483364000058],
              [-74.80765937799993, 10.788698682000074],
              [-74.8076566979999 , 10.788926824000043],
              [-74.80765624599991, 10.78903123300006 ],
              [-74.80765664699993, 10.789101904000063],
              [-74.80757365699992, 10.789102101000083],
              [-74.80755418699994, 10.790374449000069],
              [-74.80917644899995, 10.790734082000085],
              [-74.80917211699995, 10.790816238000048],
              [-74.80909379299993, 10.792302207000091],
              [-74.8090484789999 , 10.793161904000044],
              [-74.80904395399995, 10.793247748000056],
              [-74.80903544899991, 10.79340912300006 ],
              [-74.80887305699991, 10.794244468000045],
              [-74.81012088499995, 10.793961189000072],
              [-74.81047098999994, 10.793881704000057],
              [-74.8129869309999 , 10.793310503000043],
              [-74.81360929399995, 10.793169206000073],
              [-74.81494128399993, 10.79286680000007 ],
              [-74.8159748189999 , 10.792632138000045],
              [-74.81670375699991, 10.792466639000054],
              [-74.81672614599995, 10.79259419400006 ],
              [-74.81672631899994, 10.79259653300005 ],
              [-74.81678577799994, 10.793401930000073],
              [-74.81693746299993, 10.795456559000058],
              [-74.81709001699994, 10.797523023000053],
              [-74.81702447099991, 10.797790862000056],
              [-74.81701051399995, 10.797847895000075],
              [-74.81663831299994, 10.799368787000049],
              [-74.81623577099992, 10.799385836000056],
              [-74.81567932599995, 10.800218057000052],
              [-74.8153342629999 , 10.800177717000054],
              [-74.81525026499992, 10.800167897000051],
              [-74.81524617299993, 10.80022336400009 ],
              [-74.81524212799991, 10.800278192000064],
              [-74.81524135199993, 10.80028871400009 ],
              [-74.81523916999993, 10.800318289000074],
              [-74.81521136099991, 10.800695216000065],
              [-74.81518009699994, 10.801118969000072],
              [-74.8151452589999 , 10.80159115400005 ],
              [-74.81514464299994, 10.801599498000087],
              [-74.81513159399992, 10.801776377000067],
              [-74.81512118199993, 10.801917508000088],
              [-74.81510531399994, 10.802132578000055],
              [-74.81446767099993, 10.802050577000045],
              [-74.81445951799992, 10.80208904300008 ],
              [-74.81443102399993, 10.802223501000071],
              [-74.81430034099992, 10.802840179000043],
              [-74.81431621199994, 10.803021280000053],
              [-74.81431729299993, 10.80303361600005 ],
              [-74.8143256059999 , 10.803194690000055],
              [-74.8143234339999 , 10.803300376000038],
              [-74.81429600199994, 10.803504406000059],
              [-74.81426281899991, 10.803648534000047],
              [-74.8142238829999 , 10.803775602000087],
              [-74.81420052199991, 10.80385184000005 ],
              [-74.81413409399994, 10.80402977500006 ],
              [-74.81404822899992, 10.804248684000072],
              [-74.81393870199992, 10.804487807000044],
              [-74.81383168299993, 10.804717788000062],
              [-74.8138189149999 , 10.804739495000092],
              [-74.81368473399993, 10.804967617000045],
              [-74.81359883899995, 10.805084621000049],
              [-74.81354840499995, 10.805153321000091],
              [-74.81349036899991, 10.805212379000068],
              [-74.8133513269999 , 10.805353869000044],
              [-74.81318089199993, 10.805502087000093],
              [-74.81305536499991, 10.80561445300009 ],
              [-74.81287786899992, 10.805767125000045],
              [-74.81267295599991, 10.805896260000054],
              [-74.81246291999992, 10.806048267000051],
              [-74.8122951329999 , 10.806180453000081],
              [-74.81208401799995, 10.806385310000053],
              [-74.81194071399995, 10.806570857000054],
              [-74.81179186199995, 10.806799990000059],
              [-74.81159545499992, 10.80708099800006 ],
              [-74.8114632029999 , 10.807294372000058],
              [-74.81124545099993, 10.807595623000054],
              [-74.81082613799992, 10.807975507000037],
              [-74.8107551999999 , 10.808033815000044],
              [-74.80976527999991, 10.807579111000052],
              [-74.80947105899992, 10.807443965000061],
              [-74.80907390199991, 10.807261537000045],
              [-74.80897989699992, 10.807981190000078],
              [-74.8089056039999 , 10.808549931000073],
              [-74.80886400799994, 10.810290143000088],
              [-74.80885946699993, 10.810480064000046],
              [-74.80885590299994, 10.810629188000064],
              [-74.80781906399994, 10.810738981000043],
              [-74.80777890299993, 10.811901272000057],
              [-74.80778012899992, 10.811936828000057],
              [-74.8077829639999 , 10.81201903100009 ],
              [-74.80779099299991, 10.812251883000044],
              [-74.8077919879999 , 10.812280782000073],
              [-74.80613669799993, 10.812244623000083],
              [-74.80534655899993, 10.81224018100005 ],
              [-74.80534508599993, 10.812239798000064],
              [-74.80531038599992, 10.812239469000076],
              [-74.80492216599993, 10.812235790000045],
              [-74.80471090899994, 10.812249544000053],
              [-74.80393751999992, 10.812338630000056],
              [-74.80353526499994, 10.81239527200006 ],
              [-74.80352974699991, 10.812397309000062],
              [-74.80344888899992, 10.812427077000052],
              [-74.80320674199993, 10.812444175000053],
              [-74.80262874599993, 10.812663735000058],
              [-74.80165667599994, 10.81298040900009 ],
              [-74.80102032999991, 10.813198575000058],
              [-74.8006687589999 , 10.81331728400005 ],
              [-74.80022137399993, 10.813459583000053],
              [-74.79974072899995, 10.81364241600005 ],
              [-74.79937422899991, 10.813773868000055],
              [-74.79937001799993, 10.813775169000053],
              [-74.79882981199995, 10.813943044000041],
              [-74.79827070199991, 10.81413961800007 ],
              [-74.79775866999995, 10.814316717000054],
              [-74.79719203499991, 10.814518467000084],
              [-74.79687403999992, 10.814631147000057],
              [-74.79630403899995, 10.814817012000049],
              [-74.7957272989999 , 10.815007583000067],
              [-74.79525429499995, 10.815190525000048],
              [-74.79522986099994, 10.815198339000062],
              [-74.79474222599993, 10.815354294000088],
              [-74.79435590199995, 10.815502493000054],
              [-74.79390310899993, 10.81564213900009 ],
              [-74.79332369899993, 10.815846036000039],
              [-74.79302051799993, 10.81594870400005 ],
              [-74.79222972199995, 10.816237064000063],
              [-74.79203425399993, 10.816293143000053],
              [-74.7917560759999 , 10.816401401000064],
              [-74.79094489399995, 10.816680685000051],
              [-74.79081108999992, 10.816725724000037],
              [-74.79040860399994, 10.816877589000057],
              [-74.78997073799991, 10.817021207000039],
              [-74.78996395199994, 10.817023432000042],
              [-74.7896985399999 , 10.817110486000047],
              [-74.78964300199993, 10.817130804000044],
              [-74.78958238699994, 10.817152973000077],
              [-74.78954658299995, 10.817166068000063],
              [-74.7889099219999 , 10.817398929000092],
              [-74.7885892509999 , 10.817516216000058],
              [-74.78759816299993, 10.81785416200006 ],
              [-74.78676475899994, 10.818139745000053],
              [-74.7867502329999 , 10.818145129000072],
              [-74.78646559799995, 10.818250640000088],
              [-74.78551705299992, 10.818579014000079],
              [-74.78500296699991, 10.818742203000056],
              [-74.78404719499991, 10.81908581500005 ],
              [-74.7831572309999 , 10.819392047000065],
              [-74.78229958599991, 10.819692531000044],
              [-74.7818156649999 , 10.819863086000055],
              [-74.7809667759999 , 10.820144880000043],
              [-74.78049416199991, 10.820315416000085],
              [-74.77976438699994, 10.820561599000087],
              [-74.7788804409999 , 10.820860111000059],
              [-74.77790963199993, 10.821192548000056],
              [-74.7778211509999 , 10.821229992000042],
              [-74.77680324799991, 10.821586677000084],
              [-74.77589999299994, 10.821902184000066],
              [-74.77551017099995, 10.822020383000051],
              [-74.77480907299991, 10.822270141000047],
              [-74.77367626199992, 10.822656908000056],
              [-74.77297646699992, 10.822901124000055],
              [-74.77246050699995, 10.82307361100004 ],
              [-74.7717308519999 , 10.823330737000049],
              [-74.77122743899992, 10.82349593300006 ],
              [-74.77005149199994, 10.823880718000055],
              [-74.76953423899994, 10.824057269000093],
              [-74.76887136099992, 10.824263590000044],
              [-74.76861698899995, 10.82435193200007 ],
              [-74.76795140999991, 10.82458308200006 ],
              [-74.7678283969999 , 10.82465241500006 ],
              [-74.76766962199991, 10.824741904000064],
              [-74.7677118229999 , 10.824877239000045],
              [-74.7678304989999 , 10.825306497000042],
              [-74.76786467499994, 10.825455766000061],
              [-74.76740428099993, 10.825376561000041],
              [-74.76728603499993, 10.825357866000047],
              [-74.76642757999991, 10.825222139000061],
              [-74.76579808699995, 10.82512205800009 ],
              [-74.7657394069999 , 10.825112729000068],
              [-74.76507189799992, 10.825006606000045],
              [-74.76414662699995, 10.824863801000049],
              [-74.76377089199991, 10.824795348000066],
              [-74.76335355799995, 10.824746023000046],
              [-74.7633452149999 , 10.82474503900005 ],
              [-74.76033605399994, 10.824136495000062],
              [-74.75468924599994, 10.822994541000071],
              [-74.7546200619999 , 10.823090746000048],
              [-74.75460352999994, 10.823113740000053],
              [-74.75462072399995, 10.823113702000057],
              [-74.75465554899995, 10.82330064000007 ],
              [-74.75465596999993, 10.823487661000058],
              [-74.75460480499993, 10.823674794000055],
              [-74.75455362699995, 10.823861922000049],
              [-74.75446803099993, 10.824032129000045],
              [-74.75438242699994, 10.82420232800007 ],
              [-74.7542795889999 , 10.824355574000037],
              [-74.75414231999991, 10.824491889000058],
              [-74.7539878419999 , 10.824628244000053],
              [-74.75385057299991, 10.824764561000052],
              [-74.7537305379999 , 10.82491783200004 ],
              [-74.75362769299994, 10.82507107400005 ],
              [-74.75354208399995, 10.82524128600005 ],
              [-74.75347367899991, 10.825411450000047],
              [-74.75338808299995, 10.825581657000043],
              [-74.75331967199992, 10.825751819000061],
              [-74.75326849899994, 10.825938947000054],
              [-74.75316581599992, 10.826160196000046],
              [-74.75302873599992, 10.826381524000055],
              [-74.75297756599991, 10.82656865000007 ],
              [-74.75297798899993, 10.826755665000064],
              [-74.7529612109999 , 10.826942715000087],
              [-74.75299603499991, 10.827129658000047],
              [-74.7530309629999 , 10.827367603000084],
              [-74.7531516919999 , 10.827520355000047],
              [-74.75330674299994, 10.827639021000039],
              [-74.75346183099992, 10.827774686000055],
              [-74.75359972399991, 10.82791039600005 ],
              [-74.75380624899992, 10.827977949000058],
              [-74.7539612569999 , 10.828079608000053],
              [-74.75411630899993, 10.828198283000063],
              [-74.75427135799993, 10.828316950000044],
              [-74.75439208999995, 10.828469686000062],
              [-74.75451282099993, 10.82862244100005 ],
              [-74.75453044499994, 10.828809412000055],
              [-74.75454806699992, 10.828996399000062],
              [-74.75461728099992, 10.829183261000082],
              [-74.75472085199993, 10.829353040000058],
              [-74.75485878299992, 10.829505753000092],
              [-74.75503091999991, 10.829573376000042],
              [-74.75516854199992, 10.829590077000091],
              [-74.75518577299994, 10.829607041000088],
              [-74.75534078899994, 10.829708703000051],
              [-74.7554615169999 , 10.829861451000056],
              [-74.75558225599991, 10.830014191000089],
              [-74.75568582599993, 10.830183981000062],
              [-74.75575500199994, 10.830353840000043],
              [-74.75575542799993, 10.830540860000042],
              [-74.75579025299993, 10.830727799000044],
              [-74.75582506899991, 10.830914737000057],
              [-74.75582549399991, 10.831101751000062],
              [-74.75584319299992, 10.831322736000061],
              [-74.75584373599992, 10.831560749000062],
              [-74.75587855399993, 10.831747699000061],
              [-74.75591337799995, 10.83193463300006 ],
              [-74.75593099299994, 10.832121611000048],
              [-74.75594861499991, 10.832308588000046],
              [-74.7558113799999 , 10.83246190500006 ],
              [-74.75569134699992, 10.83261518800009 ],
              [-74.75557138499994, 10.832802469000057],
              [-74.7554341089999 , 10.832938787000046],
              [-74.75531407099993, 10.833092068000042],
              [-74.75524566199994, 10.83326223000006 ],
              [-74.75507390999991, 10.833364626000048],
              [-74.75483340999995, 10.83348416800004 ],
              [-74.75467885699993, 10.833586515000093],
              [-74.75452430199994, 10.83368887000006 ],
              [-74.75435251699992, 10.833774253000058],
              [-74.75418068499994, 10.833842641000047],
              [-74.7540089009999 , 10.83392802800006 ],
              [-74.75381987499992, 10.833996454000044],
              [-74.75364808099994, 10.83408184600006 ],
              [-74.75347625899991, 10.834150229000045],
              [-74.75330446499993, 10.834235622000051],
              [-74.75313267899992, 10.834321001000092],
              [-74.75296087999993, 10.834406393000052],
              [-74.7529277239999 , 10.834426163000046],
              [-74.75254973599993, 10.834426997000037],
              [-74.74946820899993, 10.834433789000059],
              [-74.74323854199992, 10.834447423000086],
              [-74.74141220199994, 10.834451392000062],
              [-74.74080286799995, 10.83445271700009 ],
              [-74.7407260459999 , 10.834452093000039],
              [-74.7402904249999 , 10.831414125000038],
              [-74.74014550499993, 10.830531035000092],
              [-74.73963941699992, 10.827952584000059],
              [-74.73940137999995, 10.825355892000061]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08638",
      "properties": {"id": "08638", "iso": "SBL", "name": "SABANALARGA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.87537657599995, 10.725023069000088],
              [-74.86807398299993, 10.698688863000086],
              [-74.8678750329999 , 10.69797142200008 ],
              [-74.86736442199992, 10.69613008500005 ],
              [-74.86574096899994, 10.69027568000007 ],
              [-74.86565638999991, 10.689970673000062],
              [-74.86558210599992, 10.689702795000073],
              [-74.86478618199993, 10.686832579000054],
              [-74.8633525489999 , 10.681662693000078],
              [-74.86276525699992, 10.679544834000069],
              [-74.86238264299993, 10.67816507300006 ],
              [-74.86062844999992, 10.671839201000068],
              [-74.85577802599994, 10.654347873000063],
              [-74.85551665699995, 10.653405336000048],
              [-74.85551048299993, 10.653383079000037],
              [-74.85522062599995, 10.652338154000063],
              [-74.85249917099992, 10.64252739300008 ],
              [-74.85248882299993, 10.642490088000045],
              [-74.84985474299992, 10.63299431200005 ],
              [-74.84968653599992, 10.632387932000086],
              [-74.8480540349999 , 10.626502819000052],
              [-74.84666561799992, 10.62149762000007 ],
              [-74.84541072199994, 10.616973762000043],
              [-74.84172323399991, 10.603680482000073],
              [-74.84119618299991, 10.601780481000048],
              [-74.8410589739999 , 10.601285845000064],
              [-74.84114888399995, 10.601135773000067],
              [-74.8412143189999 , 10.600972780000063],
              [-74.84127856499993, 10.600854997000056],
              [-74.84133686199993, 10.600769336000042],
              [-74.84138920999993, 10.600699142000053],
              [-74.84143203999992, 10.600653932000057],
              [-74.84149390599993, 10.600590877000059],
              [-74.84171638599992, 10.600371966000068],
              [-74.84200311099994, 10.600085241000045],
              [-74.84228031799995, 10.599813982000057],
              [-74.84250993599994, 10.599578416000043],
              [-74.84275026199992, 10.59934165900006 ],
              [-74.8430607809999 , 10.599032330000057],
              [-74.8432939679999 , 10.598795573000075],
              [-74.84360448799993, 10.598476725000069],
              [-74.84398996099992, 10.598084114000073],
              [-74.84427906499991, 10.597784302000036],
              [-74.84460029199994, 10.597465454000087],
              [-74.8448382389999 , 10.59723107800005 ],
              [-74.84513567099992, 10.59692412700008 ],
              [-74.84534176499994, 10.596714457000076],
              [-74.84554243899993, 10.596514138000089],
              [-74.84551752899995, 10.596499881000057],
              [-74.84551510799992, 10.596498495000048],
              [-74.84541749699991, 10.596427604000041],
              [-74.84530214599994, 10.59634383000008 ],
              [-74.84542459199992, 10.596204532000058],
              [-74.84543182599992, 10.596196303000056],
              [-74.8454764359999 , 10.596141879000072],
              [-74.84561028599995, 10.595978582000043],
              [-74.84581016099992, 10.595713273000058],
              [-74.84602148399995, 10.595450765000066],
              [-74.84600724099994, 10.595403442000077],
              [-74.84599314099995, 10.59535659100004 ],
              [-74.84599076899991, 10.595345787000042],
              [-74.84595031099991, 10.595161476000044],
              [-74.84593779099993, 10.595032295000067],
              [-74.84592413699994, 10.594891407000091],
              [-74.84591747499991, 10.59482027100006 ],
              [-74.84591104999993, 10.59462966600006 ],
              [-74.8459108589999 , 10.594606405000093],
              [-74.84593369799995, 10.59461286000004 ],
              [-74.84602031399993, 10.594637338000041],
              [-74.8462601629999 , 10.59471538400004 ],
              [-74.8465871009999 , 10.594823888000064],
              [-74.8469440209999 , 10.594939529000044],
              [-74.84694922999995, 10.594924098000092],
              [-74.8469493849999 , 10.594923640000047],
              [-74.84707399699994, 10.59464474300006 ],
              [-74.84716917599991, 10.594435350000083],
              [-74.84725364699995, 10.594242614000052],
              [-74.84731313299994, 10.594092232000037],
              [-74.84733929799995, 10.594023350000043],
              [-74.84738308899995, 10.593908062000082],
              [-74.84739774199994, 10.59387819200009 ],
              [-74.84740164899995, 10.593870228000071],
              [-74.84736095999995, 10.593613960000084],
              [-74.84732740999993, 10.593395526000052],
              [-74.84744223199993, 10.593382198000086],
              [-74.8473698219999 , 10.592930831000047],
              [-74.84731222099992, 10.592555093000044],
              [-74.8472938729999 , 10.59243540400007 ],
              [-74.84716622299993, 10.592422038000052],
              [-74.84715618999991, 10.592361030000063],
              [-74.84713257899995, 10.592217453000046],
              [-74.8470877609999 , 10.591944916000045],
              [-74.84708339199994, 10.591918113000077],
              [-74.84698729199994, 10.59132856900004 ],
              [-74.84683658599994, 10.590404026000044],
              [-74.84670028399995, 10.58960316100007 ],
              [-74.84658480399992, 10.588875030000054],
              [-74.84657807899993, 10.588487807000092],
              [-74.84661352599994, 10.588319337000087],
              [-74.84662262699993, 10.58827608300004 ],
              [-74.84664088499994, 10.588238327000056],
              [-74.84673250799995, 10.588048861000061],
              [-74.8468777679999 , 10.587686351000059],
              [-74.84697613599991, 10.587424216000045],
              [-74.84716428699994, 10.586922824000055],
              [-74.8472198689999 , 10.586786885000038],
              [-74.84728157399991, 10.586635964000038],
              [-74.84738547699993, 10.586381851000056],
              [-74.84760666499994, 10.58584088300006 ],
              [-74.8480496279999 , 10.58474966700004 ],
              [-74.84864199599991, 10.583353826000064],
              [-74.84887559499992, 10.582767652000086],
              [-74.84911239899992, 10.582173427000043],
              [-74.84987068799995, 10.580378630000041],
              [-74.8499906049999 , 10.580087586000047],
              [-74.85040772399992, 10.579075188000047],
              [-74.85098473199992, 10.57766930300005 ],
              [-74.8517699489999 , 10.57582055000006 ],
              [-74.85240195199992, 10.57429565700005 ],
              [-74.85260848799993, 10.573788225000044],
              [-74.85279073799995, 10.573340461000043],
              [-74.85291402199994, 10.573037560000046],
              [-74.8533490669999 , 10.571994111000038],
              [-74.85375388199992, 10.571002357000054],
              [-74.85425772199994, 10.569818478000059],
              [-74.85470093099991, 10.568785656000045],
              [-74.85486109699991, 10.568412418000037],
              [-74.85550129199993, 10.566903353000043],
              [-74.85613034999994, 10.56532003600006 ],
              [-74.85636714899994, 10.564724770000055],
              [-74.85636723899995, 10.564724556000044],
              [-74.85636828999992, 10.564721907000092],
              [-74.85638943499993, 10.564668754000081],
              [-74.8564904449999 , 10.564414845000044],
              [-74.85658884299994, 10.564167490000045],
              [-74.85703748599991, 10.563097454000058],
              [-74.85704953099992, 10.563068718000068],
              [-74.85706958499992, 10.56302088700005 ],
              [-74.85708600999993, 10.562981713000056],
              [-74.85735516099993, 10.56237980800006 ],
              [-74.85766547299994, 10.56164558200004 ],
              [-74.8579496829999 , 10.561023145000092],
              [-74.85798164099992, 10.56095315500005 ],
              [-74.85805971199994, 10.560782171000085],
              [-74.85810048199994, 10.560673572000042],
              [-74.85869873199994, 10.56139317800006 ],
              [-74.85931455499991, 10.562160383000048],
              [-74.85997898699992, 10.56285037300006 ],
              [-74.8606892979999 , 10.563532732000056],
              [-74.86102954499995, 10.563802340000052],
              [-74.86226660999995, 10.564551577000088],
              [-74.86279882799994, 10.564868559000047],
              [-74.86361433299993, 10.565354271000047],
              [-74.8639517599999 , 10.565560282000092],
              [-74.86433386099992, 10.56579356800006 ],
              [-74.86481387099991, 10.566081560000043],
              [-74.86502877599992, 10.565884725000046],
              [-74.86582557899993, 10.565107482000087],
              [-74.86612312699992, 10.56475712200006 ],
              [-74.8668460689999 , 10.563900175000072],
              [-74.86706971799993, 10.563635070000089],
              [-74.86763574499992, 10.562962221000078],
              [-74.86765709399992, 10.562962169000059],
              [-74.86784394099993, 10.562747362000039],
              [-74.86835548699992, 10.562241750000055],
              [-74.86838497799994, 10.562212602000045],
              [-74.8687544629999 , 10.561816018000059],
              [-74.86874002999991, 10.561796176000087],
              [-74.86855369199992, 10.56153999500009 ],
              [-74.86834906199994, 10.561213686000087],
              [-74.86808403499992, 10.560736642000052],
              [-74.86772345799994, 10.560066332000076],
              [-74.8675919609999 , 10.559821879000083],
              [-74.86743417899993, 10.55955263900006 ],
              [-74.86702696099991, 10.559108189000085],
              [-74.86692129799991, 10.559004451000078],
              [-74.86651774799992, 10.558608253000045],
              [-74.86612727699992, 10.55820906100007 ],
              [-74.86591148999992, 10.557988453000064],
              [-74.86541213899994, 10.557445725000036],
              [-74.86531403899994, 10.557334601000036],
              [-74.86531361599992, 10.557170477000057],
              [-74.86535104599994, 10.556739568000069],
              [-74.86537167299991, 10.556692618000056],
              [-74.86549035099995, 10.556736279000063],
              [-74.86567744299992, 10.556879423000055],
              [-74.86592083999994, 10.557013622000056],
              [-74.86605437199995, 10.557074838000062],
              [-74.8663923819999 , 10.557117948000041],
              [-74.86679243899994, 10.557084706000069],
              [-74.8674735429999 , 10.557083757000044],
              [-74.86794780999992, 10.557094281000047],
              [-74.8680960719999 , 10.557117356000049],
              [-74.8685141489999 , 10.55717784300009 ],
              [-74.86951262799994, 10.557383749000053],
              [-74.87008189799991, 10.557452646000058],
              [-74.87088832599994, 10.55754145900005 ],
              [-74.87156961999995, 10.557605126000055],
              [-74.87203513899993, 10.557674287000054],
              [-74.8725629349999 , 10.557749152000042],
              [-74.87346097499994, 10.557729284000061],
              [-74.87363196599995, 10.557684799000072],
              [-74.87380372099994, 10.557625749000067],
              [-74.87388443499992, 10.557662961000062],
              [-74.87422059699992, 10.557817937000038],
              [-74.87438536499991, 10.557893901000057],
              [-74.8748392299999 , 10.558039289000078],
              [-74.87577266399995, 10.558293190000086],
              [-74.87634212499995, 10.558438272000046],
              [-74.8771042379999 , 10.558577014000036],
              [-74.87742619799991, 10.558599632000039],
              [-74.8776945059999 , 10.558594213000049],
              [-74.87846165199994, 10.558469871000057],
              [-74.87854029199991, 10.55845611300009 ],
              [-74.87965643799993, 10.55828000400004 ],
              [-74.88022899299995, 10.558184986000072],
              [-74.88032009099993, 10.55816986800005 ],
              [-74.88132024099991, 10.558037695000053],
              [-74.88164728999993, 10.55800045300009 ],
              [-74.88190736299993, 10.557970838000074],
              [-74.88224177399991, 10.55793275700006 ],
              [-74.88236159599995, 10.557925243000057],
              [-74.88244983099992, 10.557919712000057],
              [-74.8825276899999 , 10.557914827000047],
              [-74.88254683299994, 10.557913628000051],
              [-74.88252620899993, 10.557740566000064],
              [-74.88250459199992, 10.55755558900006 ],
              [-74.88235636399992, 10.55715299700006 ],
              [-74.88223438499995, 10.55693296800007 ],
              [-74.88211518199995, 10.556738070000051],
              [-74.88201403399995, 10.55660427500004 ],
              [-74.88192801899993, 10.556490497000084],
              [-74.88191349499994, 10.556471284000054],
              [-74.88177682899993, 10.556205815000055],
              [-74.88169693599991, 10.55600572800006 ],
              [-74.8816555539999 , 10.555841108000038],
              [-74.8815951599999 , 10.555726119000042],
              [-74.8815076699999 , 10.555539111000087],
              [-74.88149381499994, 10.555509498000049],
              [-74.88201373499993, 10.555464168000071],
              [-74.8826598089999 , 10.555436138000061],
              [-74.8828364229999 , 10.55543275000008 ],
              [-74.88296037399994, 10.555430373000092],
              [-74.88301250399991, 10.555429373000038],
              [-74.88352076199993, 10.555327379000062],
              [-74.88408944299994, 10.55517351800006 ],
              [-74.88479734899994, 10.55498999200006 ],
              [-74.8849925689999 , 10.554968811000037],
              [-74.88499263099993, 10.554968804000055],
              [-74.88553392299991, 10.554907873000047],
              [-74.88596099099993, 10.554851879000068],
              [-74.88638757699994, 10.554768718000048],
              [-74.88683417599992, 10.554688342000077],
              [-74.8869474899999 , 10.554667949000077],
              [-74.8870498249999 , 10.554649532000042],
              [-74.88705179199991, 10.554649190000077],
              [-74.88705183199994, 10.554649184000084],
              [-74.88719495399994, 10.554634155000088],
              [-74.88725778799994, 10.55462755700006 ],
              [-74.88752894899994, 10.554604900000072],
              [-74.8877187189999 , 10.554574394000042],
              [-74.88790392699991, 10.554546231000074],
              [-74.88810874199993, 10.554531022000049],
              [-74.88814034099994, 10.55452867200006 ],
              [-74.88840737599992, 10.554538079000054],
              [-74.8886950669999 , 10.554533450000065],
              [-74.88901318899991, 10.554560886000047],
              [-74.88922161199991, 10.55459724800005 ],
              [-74.88933000299994, 10.554616980000048],
              [-74.88948621199995, 10.554662481000037],
              [-74.88974592299991, 10.554788788000053],
              [-74.88989943599995, 10.55481141100006 ],
              [-74.89007399299993, 10.554815329000064],
              [-74.89013626399992, 10.554816728000048],
              [-74.89040827299993, 10.554846676000068],
              [-74.89079110799992, 10.55484053500004 ],
              [-74.89108099099991, 10.554828969000084],
              [-74.89149672199994, 10.554849714000056],
              [-74.89190806699992, 10.55488424300006 ],
              [-74.89231326999993, 10.554971464000062],
              [-74.89264202699991, 10.55492266400006 ],
              [-74.89314224599991, 10.554857378000065],
              [-74.8932857229999 , 10.55483673200007 ],
              [-74.89370404399995, 10.554731622000077],
              [-74.89406088899995, 10.554696092000086],
              [-74.89445365899991, 10.554591319000053],
              [-74.89482027799994, 10.554448063000052],
              [-74.89510364799992, 10.554319993000092],
              [-74.89535692399994, 10.554203722000068],
              [-74.89553866299991, 10.554106923000063],
              [-74.89586833999994, 10.553975756000057],
              [-74.8958073259999 , 10.553791380000064],
              [-74.89570329499992, 10.553534519000038],
              [-74.89555035399991, 10.553269232000048],
              [-74.89543946499992, 10.552877429000091],
              [-74.89537364499995, 10.55268402200005 ],
              [-74.89524725699994, 10.552338234000047],
              [-74.89504799499991, 10.552076043000056],
              [-74.89490029099994, 10.551847253000062],
              [-74.89464081699992, 10.551590589000057],
              [-74.89441179699992, 10.551354028000048],
              [-74.89414725799992, 10.551074558000039],
              [-74.89401389199992, 10.550868477000051],
              [-74.89393307799992, 10.550755412000058],
              [-74.8938645689999 , 10.550678678000054],
              [-74.89380020899995, 10.550576727000077],
              [-74.8937736979999 , 10.550482740000064],
              [-74.89369155899993, 10.550186817000053],
              [-74.89366409999991, 10.550064079000038],
              [-74.89361778099993, 10.549929809000048],
              [-74.89355398499993, 10.549720300000047],
              [-74.89350791699991, 10.549599790000059],
              [-74.89346398999993, 10.549506738000048],
              [-74.89343822299992, 10.54945215500004 ],
              [-74.89332189399994, 10.549296170000048],
              [-74.89316802799993, 10.549111052000057],
              [-74.89304412599995, 10.548920834000057],
              [-74.89290889699993, 10.548739968000064],
              [-74.8928420009999 , 10.548624316000087],
              [-74.8927302269999 , 10.548461332000045],
              [-74.89267731899992, 10.548347768000042],
              [-74.8925861219999 , 10.548161625000091],
              [-74.89245469799994, 10.547937176000062],
              [-74.89234768099993, 10.54780598700006 ],
              [-74.89232207899994, 10.54777460300005 ],
              [-74.89226143099995, 10.547615432000043],
              [-74.89215471199992, 10.547475245000044],
              [-74.89205955999995, 10.54733494900006 ],
              [-74.89202109299993, 10.54725312000005 ],
              [-74.8919845499999 , 10.547146163000093],
              [-74.89193350699992, 10.547007444000087],
              [-74.89189570999991, 10.54682957700004 ],
              [-74.89187892199993, 10.546721063000064],
              [-74.89186581199993, 10.546636336000063],
              [-74.89185932999993, 10.54659442400009 ],
              [-74.89186693699992, 10.546436164000056],
              [-74.89186700199991, 10.546434889000068],
              [-74.89186806899994, 10.546412676000045],
              [-74.89195988299991, 10.546425637000084],
              [-74.8919598249999 , 10.546426876000055],
              [-74.89204294299992, 10.546451535000074],
              [-74.89470586899995, 10.547241553000049],
              [-74.89509919599993, 10.54735572800007 ],
              [-74.89509938899994, 10.547355783000057],
              [-74.89547529099991, 10.547460388000047],
              [-74.89593209899994, 10.547578902000055],
              [-74.89663654299994, 10.547796275000053],
              [-74.89715829999994, 10.547916035000071],
              [-74.89767634099991, 10.548088416000041],
              [-74.89792812499991, 10.548159860000055],
              [-74.89819844599992, 10.548228720000054],
              [-74.8984989619999 , 10.548297071000093],
              [-74.89876944899993, 10.54835984500005 ],
              [-74.89934694499993, 10.548493867000047],
              [-74.89971268099993, 10.548716776000049],
              [-74.8998017639999 , 10.548772553000049],
              [-74.89994169699992, 10.548818299000061],
              [-74.90009578899992, 10.54887072200006 ],
              [-74.90030985299995, 10.54890617600006 ],
              [-74.9004773659999 , 10.54893320900004 ],
              [-74.90061003099993, 10.548956237000084],
              [-74.90074042299995, 10.548981531000038],
              [-74.9008352269999 , 10.548961721000069],
              [-74.90088792499995, 10.548919700000056],
              [-74.90095631799994, 10.548845370000038],
              [-74.90098600699991, 10.54881739600006 ],
              [-74.9010131579999 , 10.54877805600006 ],
              [-74.90102132699991, 10.548709251000048],
              [-74.90102957899995, 10.54864510300007 ],
              [-74.90104693799992, 10.548571547000051],
              [-74.90104583699991, 10.54850755800004 ],
              [-74.90101578899993, 10.54842536600006 ],
              [-74.90100251299992, 10.548365323000041],
              [-74.90097807299992, 10.548254729000064],
              [-74.90094859499993, 10.548121370000047],
              [-74.90093692699992, 10.54778983600005 ],
              [-74.90088594199995, 10.547458392000067],
              [-74.9008859029999 , 10.547182104000058],
              [-74.9009306129999 , 10.547177465000061],
              [-74.90103909999993, 10.547193409000045],
              [-74.90113469999994, 10.547215768000058],
              [-74.90123281299992, 10.547296689000063],
              [-74.90132050099993, 10.547426950000045],
              [-74.90137933799991, 10.54750566100006 ],
              [-74.90141561599995, 10.547482359000071],
              [-74.90144239599994, 10.547417909000046],
              [-74.90145319299995, 10.547298412000089],
              [-74.9014980849999 , 10.54721128500006 ],
              [-74.90159171199991, 10.547158250000052],
              [-74.90169006499991, 10.547116798000047],
              [-74.90176447899995, 10.54710790200005 ],
              [-74.9018704049999 , 10.54704903000004 ],
              [-74.9019844789999 , 10.546970999000052],
              [-74.90206945099993, 10.546963808000044],
              [-74.90209190799993, 10.546971564000046],
              [-74.90217270399995, 10.546999481000057],
              [-74.90223687299994, 10.54709964600005 ],
              [-74.90223970199992, 10.547113412000044],
              [-74.90234984799991, 10.547095916000046],
              [-74.90243202099992, 10.547095114000058],
              [-74.90256480099993, 10.547062457000038],
              [-74.90273652199994, 10.546989324000037],
              [-74.9029063669999 , 10.547077087000048],
              [-74.90304882599992, 10.547168960000079],
              [-74.90319691199994, 10.547189317000061],
              [-74.90331635399991, 10.547179180000057],
              [-74.9034258659999 , 10.547159846000056],
              [-74.90354766299993, 10.54715778700006 ],
              [-74.90369374699992, 10.547292330000062],
              [-74.90378282199993, 10.547469124000088],
              [-74.90393881799992, 10.54760709900006 ],
              [-74.90411485599992, 10.547618723000085],
              [-74.90432538099992, 10.547664276000091],
              [-74.9044178609999 , 10.54766746900009 ],
              [-74.90449557499994, 10.547670150000044],
              [-74.90459910899995, 10.547598356000037],
              [-74.9047615739999 , 10.547550048000062],
              [-74.90490135899995, 10.547519669000053],
              [-74.90503579199992, 10.547451250000051],
              [-74.90513866899994, 10.547352355000044],
              [-74.9052453139999 , 10.547142146000056],
              [-74.90531889599993, 10.546990275000041],
              [-74.90541068999994, 10.546887380000044],
              [-74.90553587699992, 10.546846663000053],
              [-74.90572877799991, 10.546844407000037],
              [-74.90599514599995, 10.54685519800006 ],
              [-74.90614439699993, 10.546873236000067],
              [-74.90614916699991, 10.546874990000049],
              [-74.9062844099999 , 10.546924756000067],
              [-74.9064275479999 , 10.547052959000041],
              [-74.90648622099991, 10.54718947400005 ],
              [-74.90647228499995, 10.54741499000005 ],
              [-74.90655585399992, 10.547497222000061],
              [-74.90667180499992, 10.547487680000074],
              [-74.9067783239999 , 10.547438946000057],
              [-74.90689400499991, 10.547335402000044],
              [-74.9069740889999 , 10.54721292000005 ],
              [-74.90719201299993, 10.546993177000047],
              [-74.9073329279999 , 10.546947231000047],
              [-74.90744797999992, 10.54682753800006 ],
              [-74.9075298809999 , 10.546732732000066],
              [-74.90766913499994, 10.546718510000062],
              [-74.90770454199992, 10.546666512000058],
              [-74.90781442699995, 10.546575093000058],
              [-74.9079292959999 , 10.54659495900006 ],
              [-74.90804343399992, 10.546563499000058],
              [-74.90811193399992, 10.546468737000055],
              [-74.90811297499994, 10.546424330000093],
              [-74.90815826799991, 10.546365961000049],
              [-74.90824209499993, 10.546332283000083],
              [-74.90834356999994, 10.546357374000081],
              [-74.90847486699994, 10.546416410000063],
              [-74.90861014199993, 10.546437369000046],
              [-74.9087315729999 , 10.546512582000048],
              [-74.90878517899995, 10.54660423200005 ],
              [-74.90887738799995, 10.546761882000055],
              [-74.9089029669999 , 10.54694519800006 ],
              [-74.90892677999994, 10.547121011000058],
              [-74.90896196999995, 10.547206351000057],
              [-74.9089966109999 , 10.547290369000052],
              [-74.90914953099991, 10.547372977000066],
              [-74.90930449099994, 10.547350065000046],
              [-74.90945867699992, 10.547258517000046],
              [-74.90955750599994, 10.54717000900007 ],
              [-74.90965743099991, 10.547057865000056],
              [-74.90979259799991, 10.547043073000054],
              [-74.90991470099993, 10.547150568000063],
              [-74.9100123739999 , 10.547270831000048],
              [-74.91013098899992, 10.547378913000045],
              [-74.91025406599994, 10.547417205000045],
              [-74.91038862399995, 10.547393184000043],
              [-74.91051187599993, 10.54728904700005 ],
              [-74.91061367899994, 10.547223024000061],
              [-74.91063529399992, 10.54722954500005 ],
              [-74.91072159499993, 10.547255591000066],
              [-74.91082176799995, 10.54743985400006 ],
              [-74.91083910899994, 10.547593194000058],
              [-74.91089244999995, 10.54770205200009 ],
              [-74.9110060349999 , 10.547886847000086],
              [-74.91105093399995, 10.547934883000039],
              [-74.91125331399991, 10.548151433000044],
              [-74.9114219679999 , 10.54823342900005 ],
              [-74.91155901399992, 10.548264759000062],
              [-74.91167563399995, 10.548282896000046],
              [-74.91183993899995, 10.548269749000042],
              [-74.9119715619999 , 10.54823824500005 ],
              [-74.91210324199994, 10.548229226000046],
              [-74.9122577359999 , 10.548244946000068],
              [-74.91238183399992, 10.548231332000057],
              [-74.91248181899994, 10.54821335400004 ],
              [-74.91280426899993, 10.547966004000045],
              [-74.91295931799993, 10.547804946000042],
              [-74.9130040949999 , 10.547667914000044],
              [-74.91304285799993, 10.547615108000059],
              [-74.91312199399994, 10.547557847000064],
              [-74.91319010199993, 10.547531021000054],
              [-74.9132710049999 , 10.547480997000036],
              [-74.91330728699995, 10.547478287000047],
              [-74.91346520299993, 10.547548500000062],
              [-74.91361782199994, 10.547609159000046],
              [-74.91377728299994, 10.547605537000038],
              [-74.91405503399994, 10.547531219000064],
              [-74.91424790999992, 10.547519679000061],
              [-74.91440478299995, 10.547535455000059],
              [-74.91449081499991, 10.547539847000053],
              [-74.91477339399995, 10.547649627000055],
              [-74.91489631999991, 10.547689214000059],
              [-74.91499500099991, 10.547735818000092],
              [-74.91510989999995, 10.54772594700006 ],
              [-74.91522327599995, 10.547693221000088],
              [-74.9153371459999 , 10.54763532600009 ],
              [-74.91546773699991, 10.547590109000055],
              [-74.91571716699991, 10.547612915000059],
              [-74.9157380179999 , 10.547614818000056],
              [-74.91588452899992, 10.547615561000043],
              [-74.91601352499993, 10.54762938500005 ],
              [-74.91606682299994, 10.547656437000057],
              [-74.91621338999994, 10.547675121000054],
              [-74.91630694499992, 10.54766733300005 ],
              [-74.91644786499995, 10.54765855100004 ],
              [-74.91680122699995, 10.54755427400005 ],
              [-74.91689966199993, 10.54751533800004 ],
              [-74.91702478399992, 10.547431325000048],
              [-74.91708071399995, 10.547415016000059],
              [-74.91714723199993, 10.547433867000052],
              [-74.91720600399992, 10.54759236600006 ],
              [-74.91732181399993, 10.54771955700005 ],
              [-74.91742804899991, 10.547760224000058],
              [-74.91753067099995, 10.547759364000058],
              [-74.91761850499995, 10.54768353500009 ],
              [-74.91771433199995, 10.547549986000092],
              [-74.91774793299993, 10.54751830300006 ],
              [-74.91783645499993, 10.547434830000043],
              [-74.9178924119999 , 10.54742649700006 ],
              [-74.91797399399991, 10.547295680000047],
              [-74.9179972419999 , 10.547238395000079],
              [-74.91806163399991, 10.547079731000053],
              [-74.91812276499991, 10.546950091000042],
              [-74.91804324199995, 10.546846544000061],
              [-74.91790965399991, 10.54663166000006 ],
              [-74.91780628799995, 10.54637802700006 ],
              [-74.91780633399992, 10.546367433000057],
              [-74.91786813199991, 10.545909811000058],
              [-74.91790487499992, 10.545777581000038],
              [-74.91811627499993, 10.545586012000058],
              [-74.91844351899994, 10.545343168000045],
              [-74.9187403709999 , 10.545085394000068],
              [-74.91899246699995, 10.544887214000084],
              [-74.91920675499995, 10.54473071800004 ],
              [-74.91944898399993, 10.544552785000064],
              [-74.91969355499992, 10.544376563000071],
              [-74.91986601299993, 10.544284860000062],
              [-74.92007869699995, 10.544180908000044],
              [-74.92017709999993, 10.544106146000047],
              [-74.92031870799991, 10.543839570000046],
              [-74.92052637899991, 10.543411135000042],
              [-74.92062905499995, 10.543200681000087],
              [-74.92064046699994, 10.543164535000074],
              [-74.92072793999995, 10.542887452000059],
              [-74.92079308299992, 10.542610120000063],
              [-74.92092731799994, 10.542213659000083],
              [-74.9210745069999 , 10.541857569000058],
              [-74.92123566299995, 10.541487577000055],
              [-74.92132157599991, 10.541331442000057],
              [-74.92141178599991, 10.54125036000005 ],
              [-74.92165672999994, 10.541205214000058],
              [-74.9220001079999 , 10.541101471000047],
              [-74.92216845299993, 10.540999956000064],
              [-74.92227144399993, 10.540897459000064],
              [-74.92234975499991, 10.540737300000046],
              [-74.92236722199993, 10.540519578000044],
              [-74.92237644199992, 10.540275898000061],
              [-74.92241199099993, 10.540055812000048],
              [-74.92249547799992, 10.539871383000047],
              [-74.92255390499992, 10.539771041000051],
              [-74.92253991599995, 10.539759699000058],
              [-74.92249727199993, 10.539725134000037],
              [-74.9226028029999 , 10.539597592000064],
              [-74.9226887129999 , 10.53953726900005 ],
              [-74.92281954799995, 10.53945264500004 ],
              [-74.92290073399994, 10.53940330100005 ],
              [-74.92302618799994, 10.539323488000036],
              [-74.92316601399995, 10.539182031000053],
              [-74.92324128799993, 10.539054003000047],
              [-74.92327575599995, 10.538900179000052],
              [-74.92324328399991, 10.538738480000063],
              [-74.92326906199992, 10.538596191000067],
              [-74.9233476839999 , 10.538215967000042],
              [-74.92333765299992, 10.53796611000007 ],
              [-74.92327437299991, 10.537731077000046],
              [-74.92315650799992, 10.537510671000064],
              [-74.92304774999991, 10.537307679000037],
              [-74.9227866629999 , 10.536958928000047],
              [-74.92268849499993, 10.536765527000057],
              [-74.92256393699995, 10.53668127900005 ],
              [-74.92240054599995, 10.536604819000047],
              [-74.9222551599999 , 10.536453511000047],
              [-74.92211991199991, 10.536274930000047],
              [-74.92209813999995, 10.536216119000073],
              [-74.92208922999993, 10.536155592000057],
              [-74.92208470999992, 10.53609937400006 ],
              [-74.92212468699995, 10.53598835400004 ],
              [-74.92212945199992, 10.535977087000049],
              [-74.92224691599995, 10.53573659600005 ],
              [-74.92235924999994, 10.535536727000078],
              [-74.92244553199993, 10.535439031000067],
              [-74.92258463299993, 10.535281529000088],
              [-74.92285232499995, 10.535173369000063],
              [-74.9229414049999 , 10.535082128000056],
              [-74.92294306799994, 10.535072582000055],
              [-74.92294290299992, 10.535072553000077],
              [-74.92295386299992, 10.535002513000052],
              [-74.92292443399992, 10.534997091000037],
              [-74.92280650799995, 10.534980745000041],
              [-74.92268410799994, 10.534963779000066],
              [-74.9226883039999 , 10.534822158000054],
              [-74.92269196699993, 10.534698548000051],
              [-74.92269199499992, 10.534697597000047],
              [-74.9226936629999 , 10.534641308000062],
              [-74.92270022999992, 10.534419675000038],
              [-74.92270026199992, 10.53441858900004 ],
              [-74.92270314399991, 10.534321324000075],
              [-74.92272554099992, 10.534062885000083],
              [-74.92272875399993, 10.53402581000006 ],
              [-74.92273179299991, 10.534005955000055],
              [-74.92273483199995, 10.53398610000005 ],
              [-74.92273926399992, 10.533953443000087],
              [-74.92274870699993, 10.533883871000057],
              [-74.92295840799994, 10.533926093000048],
              [-74.92303233899992, 10.53394097800009 ],
              [-74.92307427299994, 10.53382337800008 ],
              [-74.92310289399995, 10.53374311500005 ],
              [-74.92338024399993, 10.53383372400009 ],
              [-74.92364966699995, 10.533921742000075],
              [-74.9237001379999 , 10.533938231000093],
              [-74.92378281899994, 10.533947         ],
              [-74.92383624299993, 10.533952667000051],
              [-74.92401422699993, 10.533971544000053],
              [-74.92404944299994, 10.53397368700007 ],
              [-74.92409735899992, 10.533976602000052],
              [-74.92421452699995, 10.533983731000092],
              [-74.92421835899995, 10.533983964000072],
              [-74.9243797119999 , 10.533993782000039],
              [-74.924453        , 10.533998242000052],
              [-74.92452529399992, 10.53400264000004 ],
              [-74.92465192299994, 10.53401034500007 ],
              [-74.92468659199994, 10.534012455000038],
              [-74.92468653999993, 10.53392384600005 ],
              [-74.9248984319999 , 10.533951713000079],
              [-74.92493762399994, 10.533956867000086],
              [-74.92500779799991, 10.533959922000065],
              [-74.92502625299994, 10.53387034800005 ],
              [-74.9250273589999 , 10.53386498000009 ],
              [-74.9250535459999 , 10.533737879000057],
              [-74.92506975599991, 10.533659200000045],
              [-74.92510401999994, 10.533671732000073],
              [-74.92532293099993, 10.533721700000058],
              [-74.92532768899991, 10.533902539000053],
              [-74.92553708199995, 10.533926334000057],
              [-74.92555135899994, 10.534107173000052],
              [-74.92542519499995, 10.534096202000057],
              [-74.9253871759999 , 10.534092896000061],
              [-74.92537789999994, 10.534152610000092],
              [-74.92536095299994, 10.534261707000041],
              [-74.92535967799995, 10.534269917000074],
              [-74.92534910499995, 10.534337981000078],
              [-74.92533530399993, 10.534385411000073],
              [-74.9252306489999 , 10.534369292000065],
              [-74.9251111499999 , 10.534350886000084],
              [-74.92510471199995, 10.534349894000059],
              [-74.92509337099995, 10.53434814700006 ],
              [-74.92508343999992, 10.53434661700004 ],
              [-74.92493365099995, 10.534323545000063],
              [-74.92493031899994, 10.534361775000093],
              [-74.92492815399993, 10.53439135800005 ],
              [-74.92491801299991, 10.534529960000043],
              [-74.92491726899993, 10.534540134000054],
              [-74.92491466099995, 10.534575768000082],
              [-74.92490725699992, 10.534676950000062],
              [-74.92490474099992, 10.534711341000047],
              [-74.92491543199992, 10.53471408300004 ],
              [-74.92493845099995, 10.534719987000074],
              [-74.92497216899994, 10.534728635000079],
              [-74.92497389199991, 10.534729077000065],
              [-74.92498567199993, 10.534732098000063],
              [-74.92500105999994, 10.534736045000045],
              [-74.92501847699992, 10.534740512000042],
              [-74.92502400899991, 10.534741931000042],
              [-74.92506447399995, 10.534752310000044],
              [-74.9250696009999 , 10.534753625000064],
              [-74.92507248999993, 10.534754366000072],
              [-74.92507554199995, 10.534755148000045],
              [-74.92508582499994, 10.534757786000057],
              [-74.9250915259999 , 10.534759248000057],
              [-74.92510405199994, 10.53476246100007 ],
              [-74.92510786499992, 10.534763439000074],
              [-74.92514748499991, 10.53477360100004 ],
              [-74.92515001099991, 10.534774249000066],
              [-74.92517552899994, 10.534780794000085],
              [-74.92517783999995, 10.534781386000077],
              [-74.9251796289999 , 10.534781845000055],
              [-74.92518454599991, 10.534783106000077],
              [-74.92519984599994, 10.534787031000064],
              [-74.92523107599993, 10.534795041000052],
              [-74.92524350199994, 10.534798228000056],
              [-74.92526789599992, 10.534804484000063],
              [-74.92528983999995, 10.53481011200006 ],
              [-74.92529212499994, 10.534810698000058],
              [-74.9253126349999 , 10.53481595900007 ],
              [-74.9253304309999 , 10.534820523000064],
              [-74.9253323719999 , 10.534821021000084],
              [-74.92533749299992, 10.534822334000069],
              [-74.92536244599995, 10.534828734000087],
              [-74.92539477499992, 10.534837026000048],
              [-74.9254009679999 , 10.53483861400008 ],
              [-74.92543757899995, 10.534848005000072],
              [-74.9254451079999 , 10.534849936000057],
              [-74.92545120099993, 10.53485149900007 ],
              [-74.92546529299995, 10.534855113000049],
              [-74.9254688179999 , 10.534856017000038],
              [-74.92549462099993, 10.534862635000081],
              [-74.92553263499991, 10.534872385000085],
              [-74.92553378799994, 10.534872681000081],
              [-74.92553829899992, 10.534873838000067],
              [-74.92555761999995, 10.534878793000075],
              [-74.92559359499995, 10.53488802000004 ],
              [-74.92559622099992, 10.534888694000074],
              [-74.92560646499993, 10.534891321000089],
              [-74.92561835399994, 10.534894370000075],
              [-74.92563591899994, 10.534898875000067],
              [-74.92568164199992, 10.534910601000092],
              [-74.92568328199991, 10.534911022000074],
              [-74.9256890979999 , 10.534912514000041],
              [-74.92570152099995, 10.534915700000056],
              [-74.92572524199994, 10.534921784000062],
              [-74.92573450899994, 10.534924161000049],
              [-74.92575786299994, 10.534930151000083],
              [-74.92577287599994, 10.534934001000067],
              [-74.92577645099993, 10.53493491900008 ],
              [-74.92581698899994, 10.534945316000062],
              [-74.9258215129999 , 10.534946476000073],
              [-74.92582241699995, 10.534946708000064],
              [-74.92582394199991, 10.534947099000078],
              [-74.92584364299995, 10.534952152000074],
              [-74.92584378799995, 10.53495218900008 ],
              [-74.92584543299995, 10.534952611000051],
              [-74.9258499419999 , 10.534953768000037],
              [-74.92586115299991, 10.534956643000044],
              [-74.92591293699991, 10.534969924000052],
              [-74.92592072999992, 10.534971924000047],
              [-74.92594701999991, 10.534978666000086],
              [-74.92596006899993, 10.534982013000047],
              [-74.92596150799994, 10.534982381000077],
              [-74.92599407199992, 10.534990733000086],
              [-74.92599612799995, 10.534991261000073],
              [-74.92600102299991, 10.534992516000045],
              [-74.92603269199992, 10.535000639000089],
              [-74.92604802699992, 10.535004573000037],
              [-74.9260711149999 , 10.535010494000062],
              [-74.92609574699992, 10.535016811000048],
              [-74.92610573999991, 10.535019374000058],
              [-74.92612030099991, 10.535023109000065],
              [-74.92612751999991, 10.53502496100009 ],
              [-74.92616603599993, 10.535034839000048],
              [-74.92620356399993, 10.535044463000077],
              [-74.9262035989999 , 10.535044473000085],
              [-74.92623541199993, 10.535052632000088],
              [-74.92623845199995, 10.535053413000071],
              [-74.92625134699995, 10.535056720000057],
              [-74.92627107699991, 10.535061780000092],
              [-74.92630692599994, 10.535070975000053],
              [-74.92632937299993, 10.53507673200005 ],
              [-74.92637436299992, 10.535088270000074],
              [-74.92638062299994, 10.535089876000086],
              [-74.92638133499992, 10.535090059000083],
              [-74.92639177799992, 10.53509273800006 ],
              [-74.92639829099994, 10.535094409000067],
              [-74.9264379249999 , 10.535104574000059],
              [-74.92648008799995, 10.535115387000076],
              [-74.92648384599994, 10.535116351000056],
              [-74.9264896549999 , 10.535117841000044],
              [-74.92651611299993, 10.535124628000062],
              [-74.92651625499991, 10.53512466400008 ],
              [-74.9265280969999 , 10.535127701000079],
              [-74.92656533099995, 10.535137251000037],
              [-74.92657493099995, 10.535139713000092],
              [-74.92658436199991, 10.53514213200009 ],
              [-74.92658727899993, 10.53514288000008 ],
              [-74.92663026299994, 10.535153905000072],
              [-74.92664051899993, 10.535156534000066],
              [-74.92664285199993, 10.535157132000052],
              [-74.92665054299994, 10.535159105000048],
              [-74.92667238399991, 10.535164707000092],
              [-74.92667424899992, 10.53516518500004 ],
              [-74.92667545099994, 10.535165493000079],
              [-74.92669819899993, 10.535171328000047],
              [-74.92672533299992, 10.535178287000065],
              [-74.92672701299995, 10.535178718000054],
              [-74.92676906199995, 10.535189502000037],
              [-74.92680959199993, 10.535199897000041],
              [-74.92682105299991, 10.535202836000053],
              [-74.92686840399995, 10.535214980000092],
              [-74.9268773309999 , 10.53521727000009 ],
              [-74.92688655899991, 10.535219637000068],
              [-74.92688672199995, 10.535219679000079],
              [-74.92690561199993, 10.535224524000057],
              [-74.92690619299992, 10.535224674000062],
              [-74.9269436269999 , 10.53523427500005 ],
              [-74.92698513999994, 10.53524492200006 ],
              [-74.92699038199993, 10.535246268000037],
              [-74.92700008399993, 10.535248756000044],
              [-74.92705044799993, 10.535261675000072],
              [-74.9270927579999 , 10.535272527000075],
              [-74.92709912099991, 10.53527415900004 ],
              [-74.92712445799992, 10.53528065800009 ],
              [-74.92716257299992, 10.535290434000046],
              [-74.92720475999994, 10.535301253000057],
              [-74.92721698699995, 10.535304390000078],
              [-74.92730215299991, 10.535326233000092],
              [-74.92730343999995, 10.535326564000059],
              [-74.92732956599991, 10.535333265000077],
              [-74.92734560199995, 10.535337377000076],
              [-74.9273710679999 , 10.535343907000083],
              [-74.92738172799994, 10.535346641000046],
              [-74.92743482999992, 10.535360260000061],
              [-74.9275002999999 , 10.535377052000058],
              [-74.92750291999994, 10.535377724000057],
              [-74.92752054999994, 10.53538224600004 ],
              [-74.9275218919999 , 10.53538259000004 ],
              [-74.92760812999995, 10.535404708000044],
              [-74.92761361399994, 10.535406114000068],
              [-74.92769255899992, 10.535426362000067],
              [-74.92769906399991, 10.535428030000048],
              [-74.92770474499991, 10.53542948800009 ],
              [-74.92773959699991, 10.535438427000088],
              [-74.92775429599993, 10.535442197000066],
              [-74.92777244199993, 10.535446851000074],
              [-74.92777493299991, 10.535447490000081],
              [-74.92778973899993, 10.535451287000058],
              [-74.9278237609999 , 10.53546001500007 ],
              [-74.92785694499992, 10.535468527000091],
              [-74.92789496899991, 10.535478280000063],
              [-74.92793059699994, 10.53548741800006 ],
              [-74.92794934199992, 10.535492226000088],
              [-74.9279721609999 , 10.535498076000067],
              [-74.92798258599993, 10.535500747000071],
              [-74.92798861799992, 10.535502294000082],
              [-74.9280026159999 , 10.535505884000088],
              [-74.92800488799992, 10.535506467000062],
              [-74.92801822899992, 10.535509889000082],
              [-74.92805982099992, 10.535520559000076],
              [-74.92807607999993, 10.535524729000088],
              [-74.92809543399994, 10.535529693000058],
              [-74.92811562999992, 10.535534873000074],
              [-74.92816411899992, 10.535547308000048],
              [-74.92816838499994, 10.535548402000074],
              [-74.92818722099992, 10.535553233000087],
              [-74.9282041969999 , 10.535557587000085],
              [-74.9282219129999 , 10.535562131000063],
              [-74.92825888199991, 10.535571613000059],
              [-74.92827267899992, 10.535575152000092],
              [-74.92829855699995, 10.535581789000048],
              [-74.92832285399993, 10.53558802100008 ],
              [-74.92833382499992, 10.53559083500005 ],
              [-74.92835099899992, 10.535595240000077],
              [-74.92840930199992, 10.535610194000071],
              [-74.92842109199995, 10.535613218000037],
              [-74.92843044199992, 10.53561561500004 ],
              [-74.92843641199994, 10.535617147000039],
              [-74.92844062899991, 10.535618229000079],
              [-74.92850216799991, 10.53563401200006 ],
              [-74.92850634899992, 10.53563508500008 ],
              [-74.92852557999993, 10.535640019000084],
              [-74.92858997699994, 10.535656535000044],
              [-74.92860859199993, 10.535661308000044],
              [-74.92862555699992, 10.535665658000084],
              [-74.92866296699992, 10.535675253000079],
              [-74.92866508499992, 10.535675795000088],
              [-74.92866925399994, 10.535676864000038],
              [-74.92867520099992, 10.535678390000044],
              [-74.92870554299992, 10.535686173000045],
              [-74.9287250779999 , 10.535691185000076],
              [-74.92874843099992, 10.535697174000063],
              [-74.92877740599994, 10.535704606000081],
              [-74.92880557399991, 10.535711830000082],
              [-74.92881709999995, 10.535714787000074],
              [-74.92883797799993, 10.53572014200006 ],
              [-74.92886843999992, 10.535727954000038],
              [-74.92888654099994, 10.535732597000049],
              [-74.92889361599993, 10.535734412000068],
              [-74.92890008899991, 10.535736072000077],
              [-74.92897894799995, 10.535756297000091],
              [-74.9289903689999 , 10.535759226000039],
              [-74.9290712479999 , 10.535779969000089],
              [-74.9290725749999 , 10.535780309000074],
              [-74.9290853089999 , 10.535783575000039],
              [-74.9291216439999 , 10.535792892000075],
              [-74.92915038899991, 10.53580026600008 ],
              [-74.9291585279999 , 10.535802353000065],
              [-74.92921221299991, 10.535816121000039],
              [-74.92923785199991, 10.535822696000082],
              [-74.92925719399994, 10.535827657000084],
              [-74.9292863359999 , 10.535835131000056],
              [-74.92931453099993, 10.53584236200004 ],
              [-74.92932444299993, 10.53584490500009 ],
              [-74.92933133399993, 10.535846672000048],
              [-74.92933619299993, 10.535847918000059],
              [-74.92942312699995, 10.535870215000045],
              [-74.92942884299993, 10.53587168000007 ],
              [-74.9294341879999 , 10.535873052000056],
              [-74.92945010799991, 10.535877134000089],
              [-74.9294888199999 , 10.535887063000075],
              [-74.9295254399999 , 10.535896455000056],
              [-74.92955457799991, 10.535903928000039],
              [-74.92956612699993, 10.535906890000092],
              [-74.92962920399992, 10.535923068000045],
              [-74.92964664999994, 10.535927542000081],
              [-74.92967819499995, 10.535935633000065],
              [-74.92968715699993, 10.535937931000092],
              [-74.92968803799994, 10.535938158000079],
              [-74.9297544989999 , 10.53595520500005 ],
              [-74.92976656499991, 10.535958300000061],
              [-74.92980227299995, 10.535967458000073],
              [-74.92980630599993, 10.535968492000052],
              [-74.92987775199992, 10.535986816000047],
              [-74.92993671599993, 10.536001938000084],
              [-74.92999066199991, 10.536015775000067],
              [-74.9301186589999 , 10.536048600000072],
              [-74.93012339299992, 10.53604981400008 ],
              [-74.93013791599992, 10.53605353900008 ],
              [-74.93018612899994, 10.536065904000054],
              [-74.93022914999995, 10.536076938000065],
              [-74.9302767449999 , 10.536089143000083],
              [-74.93037370399992, 10.536114010000063],
              [-74.93037698099994, 10.536114853000072],
              [-74.93045316099995, 10.53613438900004 ],
              [-74.9304693279999 , 10.536138536000067],
              [-74.93050250099992, 10.536147043000085],
              [-74.93059799399992, 10.536171534000061],
              [-74.93075126299993, 10.536210843000049],
              [-74.93076156099994, 10.536213485000076],
              [-74.93079352699993, 10.536221683000065],
              [-74.93083725499991, 10.536232898000037],
              [-74.93085741099992, 10.536238069000092],
              [-74.93090144699994, 10.536249362000092],
              [-74.93095932099993, 10.536264206000055],
              [-74.93110018399994, 10.536300335000078],
              [-74.93112156899991, 10.536305818000073],
              [-74.9311663599999 , 10.536317306000058],
              [-74.93119813099992, 10.536325454000064],
              [-74.93123241699993, 10.53633424800006 ],
              [-74.93136963199993, 10.536369440000044],
              [-74.9314636279999 , 10.536393547000046],
              [-74.9315390189999 , 10.536412883000082],
              [-74.93156964899993, 10.53642073900005 ],
              [-74.93157574899993, 10.536422303000052],
              [-74.93174840699993, 10.536466585000085],
              [-74.93175956699992, 10.536469447000059],
              [-74.9317973279999 , 10.536479130000089],
              [-74.93180452199994, 10.536480976000064],
              [-74.93184959799993, 10.536492537000072],
              [-74.93187142799991, 10.536498136000091],
              [-74.93187537799992, 10.536499149000065],
              [-74.93190053299992, 10.536505600000055],
              [-74.9320654039999 , 10.536547884000072],
              [-74.93207971599992, 10.536551554000084],
              [-74.93209389299994, 10.536555190000058],
              [-74.93212186099993, 10.536562363000087],
              [-74.93218396299994, 10.53657829000008 ],
              [-74.9322153409999 , 10.536586337000074],
              [-74.93225395999991, 10.53659624100004 ],
              [-74.93227779799992, 10.53660235500007 ],
              [-74.93234757099992, 10.536620248000077],
              [-74.93242982299995, 10.536641342000053],
              [-74.93244381199992, 10.53664493000008 ],
              [-74.93250841399993, 10.536661498000058],
              [-74.93252627599992, 10.536666079000042],
              [-74.93256154199992, 10.536675124000055],
              [-74.93257437199992, 10.53667841400005 ],
              [-74.9325889509999 , 10.536682153000072],
              [-74.93259649999993, 10.536684088000072],
              [-74.93260537799995, 10.536686362000069],
              [-74.93265673699995, 10.536699534000093],
              [-74.93288233099992, 10.536757390000048],
              [-74.93288521999995, 10.536758131000056],
              [-74.93291923099991, 10.536766852000085],
              [-74.93292448699992, 10.536768201000086],
              [-74.93298989399995, 10.536784976000092],
              [-74.93300724599993, 10.536789428000077],
              [-74.9330535329999 , 10.536801299000047],
              [-74.93307358099992, 10.536806441000067],
              [-74.93308113799992, 10.536808379000092],
              [-74.93308307299992, 10.536808875000077],
              [-74.93311685799995, 10.536817540000072],
              [-74.93315466899992, 10.536827236000079],
              [-74.93318799699995, 10.536835783000072],
              [-74.93320240699995, 10.536839478000047],
              [-74.9332116299999 , 10.536841843000047],
              [-74.93324565099994, 10.53685056800009 ],
              [-74.93329932499995, 10.53686433100006 ],
              [-74.93331558999995, 10.53686850300005 ],
              [-74.93334372099991, 10.536875717000044],
              [-74.93335108199994, 10.536877605000086],
              [-74.93335651399991, 10.536878998000077],
              [-74.93335810899993, 10.536879407000072],
              [-74.93336860699992, 10.536882099000081],
              [-74.93340113399995, 10.536890440000093],
              [-74.93342230399992, 10.536895869000091],
              [-74.93343838899995, 10.536899994000066],
              [-74.93349510999991, 10.536914540000055],
              [-74.9335009319999 , 10.536916033000068],
              [-74.93351976799994, 10.536920863000091],
              [-74.93360814899995, 10.536943528000052],
              [-74.93371171199993, 10.536970086000053],
              [-74.93371363199992, 10.53697057800008 ],
              [-74.93372185999993, 10.536972688000048],
              [-74.93372540299993, 10.536973597000042],
              [-74.9337292109999 , 10.536974574000055],
              [-74.9337976839999 , 10.53699213300007 ],
              [-74.93381196699994, 10.536995796000042],
              [-74.93383144599994, 10.537000790000093],
              [-74.93386986699994, 10.537010643000087],
              [-74.9340498009999 , 10.537056801000062],
              [-74.93407128299992, 10.537062310000067],
              [-74.93409878699993, 10.537069364000047],
              [-74.9341106519999 , 10.53707240700004 ],
              [-74.9342690769999 , 10.53711303600005 ],
              [-74.93428776999991, 10.537117830000057],
              [-74.93432787299992, 10.53712811400004 ],
              [-74.93436996999992, 10.537138910000067],
              [-74.93441047699991, 10.537149298000088],
              [-74.93442431999995, 10.537152848000062],
              [-74.93452167099991, 10.537177815000064],
              [-74.9345369479999 , 10.537181733000068],
              [-74.93454198699993, 10.537183025000047],
              [-74.93462977299993, 10.53720553800008 ],
              [-74.93466585899995, 10.53721479300009 ],
              [-74.93472259399994, 10.537229343000092],
              [-74.93482148499993, 10.537254704000077],
              [-74.93493191899995, 10.537283025000079],
              [-74.93495555099992, 10.537289086000044],
              [-74.93495959199993, 10.537290122000059],
              [-74.9350046639999 , 10.537301681000088],
              [-74.93501675199991, 10.537304781000046],
              [-74.93507051099994, 10.537318568000046],
              [-74.93519325599993, 10.537350046000086],
              [-74.93520150799992, 10.537352162000047],
              [-74.93526751299993, 10.537369089000038],
              [-74.93528765799994, 10.537374255000088],
              [-74.93535827499994, 10.537392365000073],
              [-74.93545693499993, 10.537417667000057],
              [-74.9355474329999 , 10.537440875000073],
              [-74.9355944539999 , 10.537452934000044],
              [-74.93563852499994, 10.537464236000062],
              [-74.9356492199999 , 10.537466979000044],
              [-74.93577975399995, 10.537500453000064],
              [-74.93578276699992, 10.537501226000074],
              [-74.93582710399994, 10.537512596000056],
              [-74.93597004599991, 10.537549254000055],
              [-74.93598218699992, 10.537552368000092],
              [-74.9360066669999 , 10.537558646000036],
              [-74.93610056099993, 10.53758272500005 ],
              [-74.9362747429999 , 10.537627394000083],
              [-74.93633111399993, 10.537641850000057],
              [-74.93643507599995, 10.53766851000006 ],
              [-74.9364370529999 , 10.537669018000088],
              [-74.93644243499995, 10.537670398000046],
              [-74.93646564999995, 10.537676351000073],
              [-74.9365884849999 , 10.53770785200004 ],
              [-74.93659256799992, 10.537708899000052],
              [-74.9366309959999 , 10.537718754000082],
              [-74.9368131199999 , 10.53776545900007 ],
              [-74.93685195199993, 10.537775417000091],
              [-74.93688499699994, 10.53778389100006 ],
              [-74.93692679999992, 10.537794611000038],
              [-74.93695121799993, 10.537800873000037],
              [-74.93695532299995, 10.537801926000043],
              [-74.9370436079999 , 10.537824567000087],
              [-74.93709066399992, 10.537836634000087],
              [-74.93714694899995, 10.537851068000066],
              [-74.93715562499995, 10.537853293000069],
              [-74.93730652099993, 10.537891989000059],
              [-74.9374202269999 , 10.537921148000066],
              [-74.93743296499991, 10.537924415000077],
              [-74.93748436699991, 10.537937596000063],
              [-74.93757136099993, 10.537959905000037],
              [-74.93758186499991, 10.537962599000082],
              [-74.93761362999993, 10.537970745000052],
              [-74.93769767099991, 10.537992297000073],
              [-74.93776445999993, 10.538009424000052],
              [-74.93782261299992, 10.538024337000081],
              [-74.9378499959999 , 10.538031359000058],
              [-74.9379500579999 , 10.538057019000064],
              [-74.93795347399993, 10.538057896000055],
              [-74.93807686799994, 10.538089539000055],
              [-74.93811856799994, 10.53810023300008 ],
              [-74.93811882199992, 10.538100298000074],
              [-74.93815278099993, 10.53810900700006 ],
              [-74.93823463699994, 10.538129998000045],
              [-74.93837098699993, 10.538164963000042],
              [-74.93843644099991, 10.538181748000056],
              [-74.93848210899995, 10.53819345900007 ],
              [-74.93855150699994, 10.538211256000068],
              [-74.9386457569999 , 10.538235426000085],
              [-74.9386666019999 , 10.538240771000062],
              [-74.93871728499994, 10.538253768000061],
              [-74.93874257899995, 10.538260255000068],
              [-74.9387447869999 , 10.538260821000051],
              [-74.93879630899994, 10.53827403300005 ],
              [-74.93879906599994, 10.538274740000077],
              [-74.93887659899991, 10.53829462200008 ],
              [-74.93900003099992, 10.538326275000088],
              [-74.9390295259999 , 10.538333839000074],
              [-74.93906841899991, 10.53834381300004 ],
              [-74.93909342699993, 10.538350226000091],
              [-74.9391147039999 , 10.538355682000088],
              [-74.9392200449999 , 10.538382695000053],
              [-74.93928446399991, 10.538399214000037],
              [-74.9394082099999 , 10.538430947000052],
              [-74.93948731699993, 10.538451233000046],
              [-74.93951468199992, 10.538458250000076],
              [-74.93967176099994, 10.538498531000073],
              [-74.9396976299999 , 10.53850516500006 ],
              [-74.93974322499992, 10.538516857000047],
              [-74.93977197999993, 10.538524231000054],
              [-74.93977663499993, 10.538525425000046],
              [-74.93996436099991, 10.538573564000046],
              [-74.9400183599999 , 10.538587411000037],
              [-74.94010729399992, 10.538610217000041],
              [-74.94019183199993, 10.538631896000084],
              [-74.94024530399992, 10.538645608000081],
              [-74.94026788899993, 10.53865139900006 ],
              [-74.94030034199994, 10.538659721000045],
              [-74.94035203099992, 10.538672976000043],
              [-74.94040470899995, 10.538686484000038],
              [-74.9404462999999 , 10.538697149000086],
              [-74.94056833899992, 10.538728439000067],
              [-74.9406446239999 , 10.53874800200009 ],
              [-74.94079252199992, 10.538785928000038],
              [-74.9408385349999 , 10.538797727000087],
              [-74.9408699949999 , 10.53880579400004 ],
              [-74.94090238099994, 10.538814099000092],
              [-74.94091294499992, 10.538816808000092],
              [-74.94102136799995, 10.53884460900008 ],
              [-74.94117748899993, 10.538884644000063],
              [-74.94121055999994, 10.538893125000072],
              [-74.94122331799991, 10.538896397000087],
              [-74.94142887099991, 10.538949105000086],
              [-74.94145332599993, 10.538955376000047],
              [-74.9415079979999 , 10.538969395000038],
              [-74.94151561099994, 10.538971347000086],
              [-74.9415259509999 , 10.538973999000063],
              [-74.94157632399993, 10.538986916000056],
              [-74.94178951099991, 10.539041583000085],
              [-74.94180477899994, 10.539045498000064],
              [-74.9418894499999 , 10.539067211000088],
              [-74.94191779399995, 10.539074479000078],
              [-74.9420007029999 , 10.53909574000005 ],
              [-74.94214718499995, 10.539133303000085],
              [-74.9421929639999 , 10.53914504100004 ],
              [-74.9423203209999 , 10.53917769800006 ],
              [-74.94237169299993, 10.539190868000048],
              [-74.9424068109999 , 10.53919987100005 ],
              [-74.94246415399994, 10.539214577000052],
              [-74.94254344599995, 10.539234908000083],
              [-74.94254730199992, 10.539235897000083],
              [-74.94258629099994, 10.539245897000058],
              [-74.94264307499992, 10.539260454000043],
              [-74.94265646499991, 10.53926388800005 ],
              [-74.94270767299992, 10.539277021000089],
              [-74.94276733799995, 10.539292322000051],
              [-74.94288976099995, 10.539323717000059],
              [-74.94290781899991, 10.539328349000073],
              [-74.94296484199992, 10.539342972000043],
              [-74.94302599299994, 10.539358653000079],
              [-74.94312543599995, 10.539384153000071],
              [-74.94324095999991, 10.53941377600006 ],
              [-74.94326337599995, 10.539419524000039],
              [-74.9432865249999 , 10.539425461000064],
              [-74.94337831799993, 10.539448999000058],
              [-74.9433822549999 , 10.539450008000074],
              [-74.94341783599992, 10.539459132000047],
              [-74.94347334999992, 10.539473367000085],
              [-74.94348729799992, 10.539476944000057],
              [-74.94349043099993, 10.539477748000081],
              [-74.9435272259999 , 10.539487181000084],
              [-74.94353071799992, 10.539488074000076],
              [-74.94354608299994, 10.539492014000075],
              [-74.9437769029999 , 10.539551204000077],
              [-74.94380665699993, 10.539558834000047],
              [-74.9438122009999 , 10.539560256000073],
              [-74.94389407999995, 10.539581252000062],
              [-74.94391641899995, 10.539586980000081],
              [-74.9439954739999 , 10.539607250000074],
              [-74.94401134299994, 10.539611319000073],
              [-74.94405976599995, 10.539623736000067],
              [-74.94414645599994, 10.539645964000044],
              [-74.94416067399993, 10.539649610000083],
              [-74.94447418199991, 10.539729994000083],
              [-74.94447765599995, 10.53973088500004 ],
              [-74.94448591699995, 10.539733003000038],
              [-74.94450417099995, 10.539737684000045],
              [-74.94452136899992, 10.539742095000065],
              [-74.94453462299992, 10.539745494000044],
              [-74.94454325999993, 10.53974770800005 ],
              [-74.94456227599994, 10.539752585000087],
              [-74.94463846399992, 10.539772122000045],
              [-74.94486703699994, 10.539830730000062],
              [-74.94488497899994, 10.539835330000074],
              [-74.94493740099995, 10.539848772000084],
              [-74.94501312099993, 10.539868187000081],
              [-74.94506344699994, 10.53988109100004 ],
              [-74.94509118799994, 10.539888203000089],
              [-74.9452006869999 , 10.539916284000071],
              [-74.94533036799993, 10.539949538000087],
              [-74.94535827099992, 10.539956693000079],
              [-74.94554561799993, 10.540004735000082],
              [-74.94555740599992, 10.540007758000058],
              [-74.94560952299992, 10.540021121000052],
              [-74.9456557609999 , 10.540032977000067],
              [-74.94570144799991, 10.540044692000038],
              [-74.9457162519999 , 10.540048488000082],
              [-74.94576725099995, 10.540061564000041],
              [-74.9457718619999 , 10.540062746000046],
              [-74.94579121299995, 10.540067708000038],
              [-74.94588528499992, 10.540091829000062],
              [-74.94599779999993, 10.54012067900004 ],
              [-74.94612825799993, 10.540154130000076],
              [-74.94613657999992, 10.540156264000075],
              [-74.94615497199993, 10.540160980000053],
              [-74.9462493549999 , 10.540185181000084],
              [-74.94626068499991, 10.540188086000057],
              [-74.94637920799994, 10.540218477000053],
              [-74.94640313199994, 10.540224612000088],
              [-74.94652025999994, 10.540254645000061],
              [-74.94662238399991, 10.540280830000086],
              [-74.9466515979999 , 10.540288321000048],
              [-74.9466792849999 , 10.540295419000074],
              [-74.94674636799994, 10.540312620000066],
              [-74.94675149699992, 10.540313935000086],
              [-74.94679620699992, 10.54032539900004 ],
              [-74.94681884599993, 10.540331205000086],
              [-74.9468836389999 , 10.540347818000043],
              [-74.94688673099995, 10.54034861100007 ],
              [-74.94689720999992, 10.540351298000076],
              [-74.9469754779999 , 10.540371367000091],
              [-74.94719985899991, 10.540428900000052],
              [-74.94720404399993, 10.540429973000073],
              [-74.94726105399991, 10.54044459000005 ],
              [-74.94726621699994, 10.540445914000088],
              [-74.94728624399994, 10.540451049000069],
              [-74.94740429299992, 10.540481318000047],
              [-74.94743001299992, 10.54048791300005 ],
              [-74.9474662799999 , 10.540497212000048],
              [-74.9475656649999 , 10.54052269500005 ],
              [-74.94772168199995, 10.540562699000077],
              [-74.94772366599994, 10.540563208000037],
              [-74.94782902299994, 10.540590223000038],
              [-74.94785323899993, 10.540596432000086],
              [-74.94786027499993, 10.54059823600005 ],
              [-74.94788414499993, 10.540604356000074],
              [-74.94792670999993, 10.540615270000046],
              [-74.94794997399993, 10.54062123500006 ],
              [-74.94797589399991, 10.540627881000091],
              [-74.9479948039999 , 10.540632730000084],
              [-74.94812779499995, 10.540666829000088],
              [-74.94829415799995, 10.540709485000093],
              [-74.94835250699992, 10.54072444600007 ],
              [-74.94843401499992, 10.540745345000062],
              [-74.94850083999995, 10.54076247900008 ],
              [-74.94852550799993, 10.540768805000084],
              [-74.948545        , 10.540773803000093],
              [-74.94863677899991, 10.540797335000093],
              [-74.94874762799992, 10.54082575700005 ],
              [-74.94874797599994, 10.540825846000075],
              [-74.94879801499991, 10.540838676000078],
              [-74.94885453599994, 10.54085316800007 ],
              [-74.94889716599994, 10.540864099000089],
              [-74.94890732499994, 10.540866703000063],
              [-74.9490081209999 , 10.540892548000045],
              [-74.94901574499994, 10.540894503000061],
              [-74.94905027699991, 10.540903356000058],
              [-74.9490735469999 , 10.540909322000061],
              [-74.9490886239999 , 10.540913188000047],
              [-74.94910598099995, 10.540917638000053],
              [-74.94913857499995, 10.540925995000066],
              [-74.94919177699995, 10.540939636000076],
              [-74.94919782099993, 10.540941186000055],
              [-74.94932674599994, 10.54097424300005 ],
              [-74.94932819699994, 10.540974615000039],
              [-74.94933367099992, 10.540976018000038],
              [-74.94936995099994, 10.540985320000061],
              [-74.94946036099992, 10.541008502000068],
              [-74.9494745849999 , 10.54101214900004 ],
              [-74.9495792479999 , 10.541038985000057],
              [-74.9496500809999 , 10.54105714700006 ],
              [-74.9496958499999 , 10.541068882000047],
              [-74.94971803999994, 10.541074571000081],
              [-74.94972304399994, 10.541075854000042],
              [-74.94972730399991, 10.54107694600009 ],
              [-74.94973505199994, 10.541078933000051],
              [-74.94974179299993, 10.54108066100008 ],
              [-74.94976793699993, 10.541087364000077],
              [-74.9498002659999 , 10.54109565300007 ],
              [-74.94985385399991, 10.541109393000056],
              [-74.95009367099993, 10.541170882000074],
              [-74.9501182109999 , 10.54117717400004 ],
              [-74.95021183799992, 10.541201180000087],
              [-74.95021506899991, 10.541202008000084],
              [-74.95028337999992, 10.541219523000052],
              [-74.9502894439999 , 10.541221077000046],
              [-74.95030015999993, 10.541223825000088],
              [-74.95036987499992, 10.541241699000068],
              [-74.95042805799994, 10.541256617000045],
              [-74.95057465999992, 10.541294205000042],
              [-74.95068722699995, 10.541323067000064],
              [-74.95075626199991, 10.541340767000065],
              [-74.95076432899992, 10.54134283500008 ],
              [-74.95078868599995, 10.541349081000078],
              [-74.95085669299993, 10.541366517000085],
              [-74.9509524209999 , 10.54139106100007 ],
              [-74.9509550269999 , 10.541391729000054],
              [-74.95115592199994, 10.541443238000056],
              [-74.95118120099994, 10.54144972000006 ],
              [-74.95122580399993, 10.541461156000082],
              [-74.95136049399991, 10.54149569000009 ],
              [-74.95146161299994, 10.541521616000068],
              [-74.9515472139999 , 10.541543564000051],
              [-74.95155159599994, 10.541544687000055],
              [-74.95157323399991, 10.541550235000045],
              [-74.95157333499992, 10.541550261000054],
              [-74.95159514199992, 10.541555852000045],
              [-74.95167667099992, 10.541576755000051],
              [-74.95195782199994, 10.54164884000005 ],
              [-74.95200921999992, 10.541662018000068],
              [-74.95201313199993, 10.54166302100009 ],
              [-74.95206174599991, 10.541675485000042],
              [-74.95212361199992, 10.54169134700004 ],
              [-74.9522649619999 , 10.541727587000082],
              [-74.95227190299994, 10.541729367000073],
              [-74.95228156699994, 10.541731845000072],
              [-74.95237522499991, 10.541755858000045],
              [-74.95243431099993, 10.54177100700008 ],
              [-74.95258391099992, 10.541809362000038],
              [-74.95259969099993, 10.541813408000053],
              [-74.95268578299994, 10.541835481000078],
              [-74.95271262799992, 10.541842364000047],
              [-74.95272804199993, 10.54184631600009 ],
              [-74.95282705999995, 10.541871703000083],
              [-74.95285967599995, 10.541880065000043],
              [-74.9528697639999 , 10.541882652000083],
              [-74.95289581399993, 10.54188933100005 ],
              [-74.95297010299993, 10.541908378000073],
              [-74.95308703299992, 10.541938357000049],
              [-74.9530887759999 , 10.54193880400004 ],
              [-74.95312725799994, 10.541948670000068],
              [-74.95320467099992, 10.54196851800009 ],
              [-74.95332727199991, 10.541999951000037],
              [-74.95334608299993, 10.542004774000077],
              [-74.95336546599992, 10.542009743000051],
              [-74.95339955599991, 10.54201848300005 ],
              [-74.95354581799995, 10.542055983000068],
              [-74.95356123099992, 10.542059935000054],
              [-74.95359721299991, 10.54206916000004 ],
              [-74.9536014169999 , 10.542070238000065],
              [-74.95369512499991, 10.542094263000081],
              [-74.95375733799995, 10.542110213000058],
              [-74.9537662379999 , 10.542112495000083],
              [-74.95383012299993, 10.54212887400007 ],
              [-74.95391975199993, 10.542151853000064],
              [-74.95396438999995, 10.542163297000059],
              [-74.95400474599995, 10.542173644000059],
              [-74.95409293699993, 10.542196255000079],
              [-74.95419611299991, 10.542222708000054],
              [-74.95421910499994, 10.54222860300007 ],
              [-74.9542770779999 , 10.542243466000059],
              [-74.9543538129999 , 10.542263139000056],
              [-74.95436707399995, 10.542266539000082],
              [-74.95437144899995, 10.54226766100004 ],
              [-74.95447524499991, 10.54229427200005 ],
              [-74.95452715599993, 10.542307581000046],
              [-74.95454090999993, 10.542311107000046],
              [-74.95454177999994, 10.542311331000064],
              [-74.95459968099993, 10.542326175000085],
              [-74.95461483399993, 10.54233006000004 ],
              [-74.95463750999994, 10.54233587400006 ],
              [-74.95470499099991, 10.542353174000084],
              [-74.95473357599991, 10.542360502000065],
              [-74.9548914319999 , 10.542400973000042],
              [-74.95496113699994, 10.542418844000053],
              [-74.95496981799994, 10.542421070000046],
              [-74.9549756529999 , 10.542422566000084],
              [-74.95498610099992, 10.54242524500006 ],
              [-74.9551589319999 , 10.542469554000093],
              [-74.95516183099994, 10.542470298000069],
              [-74.95523108999993, 10.542488055000092],
              [-74.95524897099995, 10.542492639000045],
              [-74.95525552999993, 10.54249432100005 ],
              [-74.95527224099993, 10.542498605000048],
              [-74.95535424699995, 10.542519630000072],
              [-74.95539717299994, 10.542530635000048],
              [-74.95551238299993, 10.542560172000037],
              [-74.95562016699995, 10.54258780500004 ],
              [-74.95566046999994, 10.542598138000074],
              [-74.9556694449999 , 10.542600439000068],
              [-74.95576628999993, 10.542625268000052],
              [-74.95578479599993, 10.542630012000075],
              [-74.95584490599992, 10.542645423000067],
              [-74.95588997899995, 10.542656979000071],
              [-74.95589945099994, 10.542659407000087],
              [-74.9559439329999 , 10.54267081200004 ],
              [-74.95599527699994, 10.542683975000045],
              [-74.95601816499993, 10.542689843000062],
              [-74.9560554979999 , 10.542699414000083],
              [-74.9561124299999 , 10.542714010000054],
              [-74.95614423599994, 10.542722164000054],
              [-74.95624827799992, 10.54274883800008 ],
              [-74.9563566089999 , 10.542776611000079],
              [-74.95641085999995, 10.54279052000004 ],
              [-74.9564778909999 , 10.54280770400004 ],
              [-74.95651839199991, 10.542818087000057],
              [-74.95653971899992, 10.542823556000087],
              [-74.95659026899995, 10.54283651600008 ],
              [-74.95667587299994, 10.542858462000083],
              [-74.95668641099991, 10.542861164000044],
              [-74.95670127099993, 10.542864975000043],
              [-74.9567161029999 , 10.54286877700008 ],
              [-74.95673890499995, 10.542874623000046],
              [-74.9568098979999 , 10.542892824000091],
              [-74.95682814399993, 10.542897502000073],
              [-74.9569477199999 , 10.542928157000063],
              [-74.9570338609999 , 10.542950241000085],
              [-74.95704075799995, 10.54295200900009 ],
              [-74.95707113199995, 10.542959796000048],
              [-74.95714906199993, 10.542979776000038],
              [-74.95715405999994, 10.542981057000077],
              [-74.95718370399993, 10.54298865700008 ],
              [-74.95727925199992, 10.54301315300006 ],
              [-74.95730962899995, 10.543020941000066],
              [-74.95742960399991, 10.543051699000046],
              [-74.9574906169999 , 10.54306734100004 ],
              [-74.95754337699992, 10.543080867000072],
              [-74.95757567999993, 10.543089149000082],
              [-74.95764605099993, 10.543107190000057],
              [-74.95767487299992, 10.543114579000076],
              [-74.95768712099994, 10.543117719000065],
              [-74.95772990699993, 10.543128688000081],
              [-74.9577432019999 , 10.543132097000068],
              [-74.95776456199991, 10.543137573000081],
              [-74.95785241599992, 10.543160095000076],
              [-74.95798780599995, 10.543194801000084],
              [-74.95800744299993, 10.543199835000053],
              [-74.95805801699993, 10.543212801000038],
              [-74.95810955199994, 10.543226013000037],
              [-74.95814098799991, 10.543234073000065],
              [-74.95823039599992, 10.543256994000046],
              [-74.95827301399993, 10.543267919000073],
              [-74.95829216099992, 10.543272828000056],
              [-74.95829548599994, 10.543273680000084],
              [-74.95841536599994, 10.54330441400009 ],
              [-74.95851856399992, 10.54333087000009 ],
              [-74.95855530999995, 10.54334029100005 ],
              [-74.95856061799992, 10.543341652000038],
              [-74.95860894499992, 10.543354041000043],
              [-74.95869799299993, 10.543376868000053],
              [-74.95870640999993, 10.543379026000082],
              [-74.95872851899992, 10.543384694000054],
              [-74.95879609199994, 10.543402016000073],
              [-74.95881721699993, 10.543407431000048],
              [-74.95882015699993, 10.54340818500009 ],
              [-74.9588213529999 , 10.543408492000083],
              [-74.95884573599994, 10.543414743000085],
              [-74.95891622099992, 10.54343281100006 ],
              [-74.95896893299994, 10.543446323000069],
              [-74.95902122199993, 10.543459729000062],
              [-74.9591222119999 , 10.54348562000007 ],
              [-74.95913585199992, 10.543489117000092],
              [-74.95916502199992, 10.543496597000058],
              [-74.95922210899994, 10.54351123300006 ],
              [-74.95925732499995, 10.543520261000083],
              [-74.95931172699994, 10.54353420700005 ],
              [-74.95931940499992, 10.543536175000042],
              [-74.95935983599992, 10.54354654000008 ],
              [-74.95938248599992, 10.543552347000059],
              [-74.95941878299993, 10.54356165300004 ],
              [-74.95945390799994, 10.543570659000068],
              [-74.95948000399994, 10.543577349000088],
              [-74.9595604129999 , 10.543597964000071],
              [-74.9595867239999 , 10.543604709000078],
              [-74.95964093999993, 10.543618609000077],
              [-74.95972239999992, 10.543639493000057],
              [-74.9597434289999 , 10.54364488400006 ],
              [-74.95982338699991, 10.543665382000086],
              [-74.9598338909999 , 10.543668075000085],
              [-74.96003261599992, 10.543719022000062],
              [-74.96007089499994, 10.54372883600007 ],
              [-74.96015416199992, 10.543750183000043],
              [-74.96017884199995, 10.543756510000037],
              [-74.96017581599995, 10.543745279000063],
              [-74.96017538299992, 10.543743673000051],
              [-74.96016583799991, 10.543708256000059],
              [-74.96016406199993, 10.54370166600006 ],
              [-74.96014750799992, 10.543640246000052],
              [-74.96014631499992, 10.543635822000056],
              [-74.96012681099995, 10.543563454000036],
              [-74.96011903999994, 10.54353461900007 ],
              [-74.96010183699991, 10.543470789000082],
              [-74.9600803159999 , 10.543390940000052],
              [-74.96003530899992, 10.543331038000076],
              [-74.95992049299991, 10.543178221000062],
              [-74.9598849109999 , 10.543130862000055],
              [-74.95987864999995, 10.543122529000073],
              [-74.95987441499994, 10.543116893000047],
              [-74.95987017199991, 10.54311124600008 ],
              [-74.95985460799994, 10.543090531000075],
              [-74.95985458899992, 10.543090505000066],
              [-74.95984874399994, 10.54308272600008 ],
              [-74.95981115799992, 10.543032699000037],
              [-74.95979543999994, 10.54301177800005 ],
              [-74.95979054299994, 10.543005263000055],
              [-74.95947002799994, 10.542627788000061],
              [-74.9594391519999 , 10.542583523000076],
              [-74.95941769299992, 10.542552760000092],
              [-74.95941395799991, 10.54254740500005 ],
              [-74.95940782799994, 10.542538618000037],
              [-74.95939261399991, 10.542516807000084],
              [-74.95939234399992, 10.542516419000037],
              [-74.95935313099994, 10.542460202000086],
              [-74.9592893809999 , 10.54236880700006 ],
              [-74.95928292899993, 10.542359556000065],
              [-74.95925593699991, 10.542320860000075],
              [-74.95925379899995, 10.542317794000041],
              [-74.95925166099994, 10.542314729000054],
              [-74.9591878459999 , 10.54222323600004 ],
              [-74.95916724499995, 10.54218248400008 ],
              [-74.9591361869999 , 10.542121045000044],
              [-74.95905792499991, 10.541966230000071],
              [-74.95901968499993, 10.541890584000043],
              [-74.95891742299995, 10.541543994000051],
              [-74.95890997799995, 10.541446500000063],
              [-74.95889130299992, 10.541201940000064],
              [-74.95890538899994, 10.541133035000087],
              [-74.95894406799994, 10.540943835000064],
              [-74.95895722099993, 10.54087949500007 ],
              [-74.9589981339999 , 10.540793790000066],
              [-74.95909560899992, 10.54058959400004 ],
              [-74.95915467999993, 10.540507920000039],
              [-74.95925201299991, 10.540373344000045],
              [-74.9593222659999 , 10.540276210000059],
              [-74.95951974499991, 10.540045894000059],
              [-74.95954030799993, 10.540021911000053],
              [-74.95957343999993, 10.53998326900006 ],
              [-74.95966132299992, 10.539863080000089],
              [-74.95970210399992, 10.539807307000046],
              [-74.95979317699994, 10.539561588000083],
              [-74.95980760799995, 10.539408992000062],
              [-74.95982706299992, 10.539203343000054],
              [-74.95983788399991, 10.539030100000048],
              [-74.95985271099994, 10.538792732000047],
              [-74.95985839399992, 10.538701745000083],
              [-74.95991022299995, 10.538273355000058],
              [-74.95992304999993, 10.53789492900006 ],
              [-74.9598942209999 , 10.537540364000051],
              [-74.95974939199994, 10.53713827200005 ],
              [-74.95960067099992, 10.53690266700005 ],
              [-74.95942856999994, 10.536630023000043],
              [-74.95940940799994, 10.53660295700007 ],
              [-74.95929283399994, 10.536438278000048],
              [-74.9592685849999 , 10.536404023000046],
              [-74.95925307999994, 10.536382117000073],
              [-74.95913833999992, 10.536220023000055],
              [-74.95896493399994, 10.536049683000044],
              [-74.95894340399991, 10.536028532000046],
              [-74.9588806939999 , 10.53596692700006 ],
              [-74.95898881099993, 10.535823419000053],
              [-74.95906221899992, 10.535665725000058],
              [-74.95907738499994, 10.535633147000055],
              [-74.95908486299993, 10.53561708300009 ],
              [-74.95909040799995, 10.535605173000079],
              [-74.95909413099992, 10.535597175000078],
              [-74.95909510399991, 10.535595084000079],
              [-74.95910234899992, 10.535579520000056],
              [-74.95912674599992, 10.53552710300005 ],
              [-74.95912739699992, 10.535525888000052],
              [-74.95913178199993, 10.535517652000067],
              [-74.95913451599995, 10.53551251600004 ],
              [-74.95913682299994, 10.535508183000047],
              [-74.95913752599995, 10.53550686200009 ],
              [-74.95914585299994, 10.535491221000086],
              [-74.95923057699991, 10.53533208500005 ],
              [-74.95928940199991, 10.53522158900006 ],
              [-74.95945152299993, 10.534663830000056],
              [-74.95959504199993, 10.534153952000054],
              [-74.95973866399993, 10.53361506400006 ],
              [-74.95976208299993, 10.533595276000085],
              [-74.96020957199994, 10.533217143000059],
              [-74.96024823999994, 10.533182104000048],
              [-74.96052840599992, 10.532928250000055],
              [-74.96075492599994, 10.532723003000058],
              [-74.9610999809999 , 10.532410247000087],
              [-74.96110002899991, 10.532410204000087],
              [-74.96124109499993, 10.532282342000087],
              [-74.96125421999994, 10.532276306000085],
              [-74.96127101099995, 10.532268585000054],
              [-74.96159703399991, 10.532118659000048],
              [-74.96235859599994, 10.531963985000061],
              [-74.96240870999992, 10.531954236000047],
              [-74.96266073699991, 10.53190519900005 ],
              [-74.96320269699993, 10.531868134000092],
              [-74.96321903699993, 10.531867016000092],
              [-74.96324063999992, 10.531873039000061],
              [-74.9633093349999 , 10.53200165600009 ],
              [-74.96337404999991, 10.532122824000055],
              [-74.96347624499992, 10.53225677200004 ],
              [-74.96363888099995, 10.532403504000058],
              [-74.9637412699999 , 10.532469131000084],
              [-74.96399433199991, 10.53263133300004 ],
              [-74.96417166899994, 10.532696870000052],
              [-74.96418269499992, 10.532700945000045],
              [-74.96442970499993, 10.532755147000046],
              [-74.9647444069999 , 10.532772583000053],
              [-74.96485606699991, 10.532770147000065],
              [-74.96490482299993, 10.532769084000051],
              [-74.96496511099991, 10.532762783000067],
              [-74.96515619999991, 10.532742813000084],
              [-74.96532289699991, 10.532725391000042],
              [-74.96566227299991, 10.532736639000063],
              [-74.96592768999994, 10.532766377000087],
              [-74.96619622199995, 10.532817503000047],
              [-74.9665325709999 , 10.532853165000063],
              [-74.96701012299991, 10.532882277000056],
              [-74.96707029299995, 10.532874886000059],
              [-74.96733051599995, 10.53284292300009 ],
              [-74.96795072099991, 10.532766741000046],
              [-74.96822550099995, 10.532706272000041],
              [-74.96854031499993, 10.532820464000054],
              [-74.96869215799995, 10.532823100000087],
              [-74.96930703299995, 10.53283377300005 ],
              [-74.97060514299994, 10.532644289000075],
              [-74.97152499799995, 10.532321240000044],
              [-74.97152508599993, 10.532321208000042],
              [-74.97152581299991, 10.532320929000036],
              [-74.97184956699994, 10.532196051000085],
              [-74.97333168199992, 10.531624363000049],
              [-74.97333177099995, 10.531624329000067],
              [-74.9733337959999 , 10.531615823000038],
              [-74.97377887899995, 10.529745676000061],
              [-74.97380457199995, 10.52963773600004 ],
              [-74.97385031199991, 10.52944554800007 ],
              [-74.97509091599994, 10.528169952000042],
              [-74.97657128399993, 10.526647815000047],
              [-74.97975457399991, 10.523374639000053],
              [-74.97975519899995, 10.523373996000089],
              [-74.97975524299994, 10.523373950000064],
              [-74.98042834899991, 10.52350922700009 ],
              [-74.98113947799993, 10.523523233000049],
              [-74.98168707399992, 10.523415698000065],
              [-74.98226576399992, 10.523204164000049],
              [-74.98226585299994, 10.523204132000046],
              [-74.9822663349999 , 10.523203666000086],
              [-74.98284365699993, 10.522644278000087],
              [-74.98284367899993, 10.52264425800007 ],
              [-74.98284448099992, 10.522643479000067],
              [-74.98285410599993, 10.52263399700007 ],
              [-74.98323399899994, 10.522259734000045],
              [-74.98323402499994, 10.522259709000082],
              [-74.98353115899994, 10.52196697800008 ],
              [-74.98366945399994, 10.521830733000058],
              [-74.98381389999992, 10.521728172000053],
              [-74.98410439699995, 10.521521910000047],
              [-74.98410443599994, 10.521521882000059],
              [-74.98641464999992, 10.521317035000038],
              [-74.98641494299994, 10.521313795000083],
              [-74.9864740559999 , 10.520659180000052],
              [-74.98647432099995, 10.52065624000005 ],
              [-74.98639135199994, 10.520382067000071],
              [-74.98707451899992, 10.520128850000049],
              [-74.98832553599993, 10.519665153000062],
              [-74.98945225099993, 10.519739309000045],
              [-74.99046885199994, 10.51961250000005 ],
              [-74.99095359599994, 10.519518195000046],
              [-74.99233201399994, 10.520056099000044],
              [-74.99237594899995, 10.520156862000078],
              [-74.9924490969999 , 10.520324628000083],
              [-74.99257495299992, 10.52046712400005 ],
              [-74.99279083999994, 10.520711558000073],
              [-74.99370541399992, 10.521402068000043],
              [-74.9940558479999 , 10.521660566000037],
              [-74.99447072399994, 10.521966604000056],
              [-74.99486468799995, 10.522257212000056],
              [-74.99530885599995, 10.52251029200005 ],
              [-74.99578545499992, 10.522865025000044],
              [-74.99620325099994, 10.52316439100008 ],
              [-74.99623625599992, 10.523188038000058],
              [-74.99645201699991, 10.521883055000046],
              [-74.99649157899995, 10.52164377300005 ],
              [-74.99653608299991, 10.521374080000044],
              [-74.99687121599993, 10.520697762000054],
              [-74.99729305799991, 10.519846286000075],
              [-74.99755883299991, 10.519329798000058],
              [-74.99792558199994, 10.51861708000007 ],
              [-74.99806953699994, 10.518197439000062],
              [-74.99808917999991, 10.518140173000063],
              [-74.9982241379999 , 10.517746754000086],
              [-74.99833954899992, 10.517410317000042],
              [-74.99834017799992, 10.517408482000064],
              [-74.9983529989999 , 10.517359767000073],
              [-74.9983767189999 , 10.517269640000052],
              [-74.99848717799995, 10.517050977000054],
              [-74.9984873219999 , 10.517050575000042],
              [-74.99852826199992, 10.516936612000052],
              [-74.99853359499991, 10.516921765000063],
              [-74.99853422599995, 10.516920008000056],
              [-74.99853424699995, 10.51691995100009 ],
              [-74.99855553799995, 10.51684057600005 ],
              [-74.99855768599991, 10.516841108000051],
              [-74.99875747699991, 10.516953967000063],
              [-74.99884705099993, 10.516989121000051],
              [-74.9990037629999 , 10.517037363000043],
              [-74.99914354499992, 10.517138853000063],
              [-74.9994339399999 , 10.517284023000059],
              [-74.9996310329999 , 10.517371987000047],
              [-74.9997159749999 , 10.51735403300006 ],
              [-74.99981038199991, 10.517317215000048],
              [-74.99989917199991, 10.517282669000053],
              [-74.99996639199992, 10.517322315000058],
              [-75.00002600099992, 10.51738842800006 ],
              [-75.00011         , 10.51747683800005 ],
              [-75.00019520399991, 10.517547421000074],
              [-75.00025789599994, 10.517569374000061],
              [-75.00030261799992, 10.51756481600006 ],
              [-75.00040989899992, 10.51753794700005 ],
              [-75.00043672299995, 10.517533438000044],
              [-75.00051716599995, 10.517506642000058],
              [-75.0006021239999 , 10.517493115000093],
              [-75.0006036549999 , 10.517492753000056],
              [-75.00067811799994, 10.51747518700006 ],
              [-75.00083031799994, 10.51751015900004 ],
              [-75.00107201199995, 10.517553724000038],
              [-75.00127332799991, 10.51755756700004 ],
              [-75.00145677599994, 10.517570310000053],
              [-75.00147728799993, 10.517570927000065],
              [-75.0015909899999 , 10.51757434700005 ],
              [-75.0017296389999 , 10.51757394200007 ],
              [-75.00176097799994, 10.517573852000055],
              [-75.0018772329999 , 10.517555805000086],
              [-75.0018954279999 , 10.517547739000065],
              [-75.00211847899993, 10.517448855000055],
              [-75.0022331699999 , 10.517397108000068],
              [-75.00230610999995, 10.517364198000053],
              [-75.00247596699995, 10.517319433000068],
              [-75.00258766899992, 10.51727483600007 ],
              [-75.00268604299993, 10.517261270000063],
              [-75.00284394099992, 10.517280335000066],
              [-75.00286504399992, 10.517282883000064],
              [-75.00301720499994, 10.517304572000057],
              [-75.00319157399991, 10.517273075000048],
              [-75.00341068999995, 10.51724587700005 ],
              [-75.00358062399994, 10.517227673000093],
              [-75.00377296699992, 10.517222682000067],
              [-75.00393401899993, 10.517226639000057],
              [-75.0040861679999 , 10.517243905000043],
              [-75.00427411599992, 10.517265487000088],
              [-75.00440404199992, 10.517331513000045],
              [-75.00452062599993, 10.517424134000066],
              [-75.00466867099993, 10.517565365000053],
              [-75.00471342499992, 10.517625105000093],
              [-75.00471648999991, 10.517629196000087],
              [-75.00473154499991, 10.51764929300009 ],
              [-75.0048482009999 , 10.51776404800006 ],
              [-75.00488678499994, 10.517795104000072],
              [-75.00495114199992, 10.517952764000086],
              [-75.00507963299992, 10.518036045000088],
              [-75.00519622599995, 10.518119327000079],
              [-75.0052795069999 , 10.518174054000042],
              [-75.00535497699991, 10.518198067000071],
              [-75.00538420399994, 10.518207367000059],
              [-75.00544181299995, 10.51822928300004 ],
              [-75.00544918599991, 10.518232649000083],
              [-75.00545669399992, 10.51823607600005 ],
              [-75.0054932349999 , 10.518252758000074],
              [-75.00558436799992, 10.518294362000063],
              [-75.0057986459999 , 10.518382958000075],
              [-75.00584397499995, 10.518236672000057],
              [-75.00584557999991, 10.518229303000055],
              [-75.00593262899991, 10.518244050000078],
              [-75.0060869969999 , 10.518271847000051],
              [-75.00620596999994, 10.518300400000044],
              [-75.0063439779999 , 10.518357507000076],
              [-75.00642725999995, 10.518376543000045],
              [-75.00648436699993, 10.51837416300009 ],
              [-75.0065557499999 , 10.518369404000055],
              [-75.00665441199993, 10.51840046400008 ],
              [-75.0066842409999 , 10.518409855000073],
              [-75.00681511199991, 10.518471721000083],
              [-75.00690791099993, 10.518500275000065],
              [-75.00700070999994, 10.518533587000093],
              [-75.00700304799994, 10.518534682000052],
              [-75.00711254399994, 10.51858593500009 ],
              [-75.00713781799993, 10.518600377000041],
              [-75.00721248199994, 10.518643042000065],
              [-75.0073052809999 , 10.518702529000052],
              [-75.00739094099993, 10.51876915400004 ],
              [-75.00746232499995, 10.518852435000042],
              [-75.00749801699993, 10.518916681000064],
              [-75.00751943199992, 10.519026136000036],
              [-75.00757415999993, 10.519102278000048],
              [-75.00760659499991, 10.519138530000077],
              [-75.00769551199994, 10.519237908000036],
              [-75.0077082439999 , 10.519251731000054],
              [-75.00784995999993, 10.51940559400009 ],
              [-75.00786207499993, 10.519418747000088],
              [-75.00788628399994, 10.519449559000066],
              [-75.00791870299992, 10.519481007000081],
              [-75.00793494999994, 10.519496767000078],
              [-75.00794094299994, 10.519492429000081],
              [-75.00796267299995, 10.519476698000062],
              [-75.00811564499992, 10.519344926000088],
              [-75.00830671499995, 10.519175225000083],
              [-75.00834107599991, 10.519144707000066],
              [-75.00840498699995, 10.519087944000091],
              [-75.00855638799993, 10.519235046000063],
              [-75.00857767499991, 10.519255729000065],
              [-75.00858693899994, 10.519264730000089],
              [-75.00860095299993, 10.51927887100004 ],
              [-75.00852304499995, 10.519336341000042],
              [-75.0084647779999 , 10.519379323000067],
              [-75.00845385899993, 10.519387377000044],
              [-75.00837037399992, 10.51944896100008 ],
              [-75.0081875539999 , 10.519574616000057],
              [-75.00813772099991, 10.519608866000056],
              [-75.00810205599993, 10.519633379000084],
              [-75.00807926699991, 10.519649042000083],
              [-75.00807104299992, 10.519654695000042],
              [-75.0080611969999 , 10.519661462000045],
              [-75.0080522579999 , 10.519667606000041],
              [-75.00804416199992, 10.519673171000079],
              [-75.0080518449999 , 10.519680253000047],
              [-75.00807858799993, 10.519704903000047],
              [-75.00810201599995, 10.519726497000079],
              [-75.0081087339999 , 10.519732690000069],
              [-75.00815468899992, 10.519775049000089],
              [-75.00819526399994, 10.519812449000085],
              [-75.00820504899991, 10.51979629400006 ],
              [-75.00821140799991, 10.51979205300006 ],
              [-75.00823260599992, 10.519817962000047],
              [-75.00826087199994, 10.519852509000089],
              [-75.0083139109999 , 10.51991733500006 ],
              [-75.00834187399994, 10.519951512000091],
              [-75.0083616849999 , 10.519979016000093],
              [-75.00854097099995, 10.520227928000054],
              [-75.00858695799991, 10.520291774000043],
              [-75.00873210499992, 10.520503546000043],
              [-75.0087984839999 , 10.520601163000038],
              [-75.0088130069999 , 10.520622520000074],
              [-75.00881731599992, 10.520624851000036],
              [-75.00889243199993, 10.520665488000077],
              [-75.00889619199995, 10.520667522000053],
              [-75.00895815399991, 10.520701042000042],
              [-75.00899442699995, 10.520728468000073],
              [-75.0089975169999 , 10.520744078000064],
              [-75.00913395299995, 10.520721064000043],
              [-75.00937117199993, 10.520821553000076],
              [-75.00952835699991, 10.520850987000074],
              [-75.00959926799993, 10.520893232000049],
              [-75.0096282319999 , 10.520910487000037],
              [-75.00968765099992, 10.520948460000056],
              [-75.00968876899992, 10.520949174000066],
              [-75.00973734799993, 10.52102975300005 ],
              [-75.00978596399995, 10.521122289000061],
              [-75.00984956799994, 10.521175915000072],
              [-75.00988240899994, 10.52120052400005 ],
              [-75.0099131529999 , 10.521223562000046],
              [-75.00993278199991, 10.521239422000065],
              [-75.00995825199993, 10.521260002000076],
              [-75.00999794899991, 10.521292074000087],
              [-75.01011300999994, 10.521378435000088],
              [-75.0101482199999 , 10.521413072000087],
              [-75.0103118909999 , 10.52129397400006 ],
              [-75.01036094499995, 10.521291764000068],
              [-75.01048206299993, 10.521286307000082],
              [-75.01052211199993, 10.52128450300006 ],
              [-75.01076402399991, 10.521242728000061],
              [-75.01120746099991, 10.521229455000082],
              [-75.01128968099994, 10.521226994000074],
              [-75.01197452799994, 10.52120649300008 ],
              [-75.01235487999992, 10.52119510700004 ],
              [-75.01452507999994, 10.52113014300005 ],
              [-75.02708116499991, 10.520754281000052],
              [-75.02708951499994, 10.520838454000057],
              [-75.02706574899992, 10.521622439000055],
              [-75.02707153099993, 10.521730286000093],
              [-75.02707874899994, 10.521864984000047],
              [-75.02717980499995, 10.523750617000076],
              [-75.02720326999992, 10.52418844400006 ],
              [-75.02734275499995, 10.527396052000086],
              [-75.0270959849999 , 10.53150306200007 ],
              [-75.02685038599992, 10.535995028000059],
              [-75.02789447799995, 10.538173352000058],
              [-75.02868671499994, 10.542790521000086],
              [-75.02879381799994, 10.543394246000048],
              [-75.02934654099994, 10.546509841000045],
              [-75.02978854499992, 10.548236966000047],
              [-75.03014230699995, 10.549820436000061],
              [-75.03053024699994, 10.551895742000056],
              [-75.03101573799995, 10.553584035000085],
              [-75.03110425299991, 10.553891847000045],
              [-75.03171004399991, 10.555998441000042],
              [-75.03195930399994, 10.557450914000071],
              [-75.03202008799991, 10.55780511800009 ],
              [-75.03237034299991, 10.559846064000055],
              [-75.03304961599991, 10.561180082000078],
              [-75.03372510499992, 10.562506670000062],
              [-75.03386439899992, 10.562759529000061],
              [-75.03399549399995, 10.563585854000053],
              [-75.03404912499991, 10.563764809000077],
              [-75.03434715199995, 10.564759265000077],
              [-75.03581391699993, 10.569653562000042],
              [-75.03683039799995, 10.57192963500006 ],
              [-75.0397750329999 , 10.577352147000056],
              [-75.04105337899995, 10.578673622000053],
              [-75.04182063299993, 10.57946676000006 ],
              [-75.04512898299993, 10.584112168000047],
              [-75.05126481199994, 10.590042008000069],
              [-75.05286481599995, 10.590684682000074],
              [-75.05560991699991, 10.591787303000046],
              [-75.0560530539999 , 10.592277420000073],
              [-75.05708717899995, 10.593421177000039],
              [-75.0572889099999 , 10.593644295000047],
              [-75.05875483499995, 10.594260470000052],
              [-75.0589183909999 , 10.594339458000093],
              [-75.06012400399993, 10.594921691000081],
              [-75.06015192299992, 10.594935173000067],
              [-75.05956972799993, 10.597575708000079],
              [-75.05745119099993, 10.607184283000038],
              [-75.05745228899991, 10.60752913400006 ],
              [-75.0564201599999 , 10.611808525000072],
              [-75.05684416299994, 10.613600442000063],
              [-75.05680528799991, 10.614465375000066],
              [-75.05681155499991, 10.614479546000041],
              [-75.05690837999992, 10.614608934000046],
              [-75.05701772099991, 10.615083557000048],
              [-75.05682717399992, 10.615205221000053],
              [-75.05666809599995, 10.61536434900006 ],
              [-75.0564770869999 , 10.615554595000049],
              [-75.05639302799995, 10.615902935000065],
              [-75.05638394699992, 10.615958454000065],
              [-75.05637132499993, 10.615968795000072],
              [-75.05518696699994, 10.618640455000047],
              [-75.05515304099993, 10.61885277500005 ],
              [-75.05511767799993, 10.61907410200007 ],
              [-75.05484517299993, 10.620779610000056],
              [-75.05373274599992, 10.621748659000048],
              [-75.05366806599994, 10.623335183000052],
              [-75.05443650599994, 10.62381558900006 ],
              [-75.05469951099991, 10.624036751000062],
              [-75.05460415699991, 10.624144037000065],
              [-75.05448761899993, 10.624524448000045],
              [-75.05448200399991, 10.62500250100004 ],
              [-75.05441169599993, 10.625405091000061],
              [-75.05433186399995, 10.625494888000048],
              [-75.05431832999994, 10.625510116000044],
              [-75.05443577599993, 10.625892928000042],
              [-75.05443501199994, 10.625897054000063],
              [-75.05431918199992, 10.626522170000044],
              [-75.05415746699992, 10.627392212000075],
              [-75.05410151899991, 10.62748739500006 ],
              [-75.05502453699995, 10.62745361900005 ],
              [-75.05556916699993, 10.627405259000057],
              [-75.05567340899995, 10.627420894000068],
              [-75.05570187799992, 10.627416407000055],
              [-75.05568972999993, 10.627743997000039],
              [-75.05570595499995, 10.628168606000088],
              [-75.0555901319999 , 10.628586992000066],
              [-75.05557083199994, 10.629008147000093],
              [-75.05554496099995, 10.629403541000045],
              [-75.05555915699995, 10.62945972700004 ],
              [-75.05554694599994, 10.629466639000043],
              [-75.05543275899993, 10.630157781000037],
              [-75.05442737299995, 10.631221193000044],
              [-75.05444115899991, 10.631209205000062],
              [-75.0543572819999 , 10.631882276000056],
              [-75.05432344199994, 10.632112774000063],
              [-75.05432622899991, 10.63298902300005 ],
              [-75.05481734799992, 10.633953079000037],
              [-75.0550300239999 , 10.635055949000048],
              [-75.0550200479999 , 10.635074172000088],
              [-75.05506850099994, 10.635114397000052],
              [-75.0548907239999 , 10.635541585000055],
              [-75.0546908689999 , 10.635957254000061],
              [-75.05468630799993, 10.635957641000061],
              [-75.05496490999991, 10.63650453300005 ],
              [-75.0555231269999 , 10.636640731000057],
              [-75.05608287799993, 10.637259722000067],
              [-75.0562942439999 , 10.637948768000058],
              [-75.05648261099992, 10.638097582000057],
              [-75.05667763499991, 10.638174487000072],
              [-75.05681617999994, 10.638288109000086],
              [-75.05688889299995, 10.638428514000054],
              [-75.05701989599993, 10.638515828000038],
              [-75.05705223899992, 10.63867321400005 ],
              [-75.05713202499993, 10.638732023000046],
              [-75.0572625879999 , 10.638471681000055],
              [-75.05769110699993, 10.638703083000053],
              [-75.05801574299994, 10.63938315200005 ],
              [-75.05849050599994, 10.640674862000083],
              [-75.05868601399993, 10.640813612000045],
              [-75.0586493109999 , 10.640676122000059],
              [-75.05883895999995, 10.640548319000061],
              [-75.05900474799995, 10.640632293000067],
              [-75.0592415879999 , 10.640924595000058],
              [-75.05931199499992, 10.641026071000056],
              [-75.0594287589999 , 10.641340717000048],
              [-75.0594339129999 , 10.641344372000049],
              [-75.06079648499991, 10.642271202000074],
              [-75.06121615199993, 10.642683711000075],
              [-75.06158501999994, 10.643561925000085],
              [-75.06221618099994, 10.644697955000083],
              [-75.06289914199994, 10.64567863700006 ],
              [-75.06266360799992, 10.647139069000048],
              [-75.06228355199994, 10.647867685000051],
              [-75.06230013699991, 10.647985199000061],
              [-75.06238009799995, 10.64794101700005 ],
              [-75.0623960719999 , 10.647947069000054],
              [-75.06240418199991, 10.64795014200007 ],
              [-75.06245248599993, 10.647968455000068],
              [-75.06248612299993, 10.648027261000038],
              [-75.06245301299992, 10.64816157000007 ],
              [-75.06238915599994, 10.648296157000061],
              [-75.0623281039999 , 10.648342393000064],
              [-75.06227108299993, 10.648385577000056],
              [-75.06241811099994, 10.64859911800005 ],
              [-75.06249467999993, 10.648698502000059],
              [-75.06256050099995, 10.648918538000089],
              [-75.06265263099993, 10.649038265000058],
              [-75.0626869319999 , 10.649105260000056],
              [-75.06272168899994, 10.649157032000062],
              [-75.0627391079999 , 10.649187304000066],
              [-75.06272890899993, 10.649237724000045],
              [-75.06265295399993, 10.649408701000084],
              [-75.06262188699992, 10.649640138000052],
              [-75.06257247899993, 10.649937868000052],
              [-75.06256467499992, 10.650124619000053],
              [-75.0625295669999 , 10.650300495000067],
              [-75.0625251649999 , 10.650471418000052],
              [-75.06252913799995, 10.650652797000077],
              [-75.06252043199993, 10.650805030000072],
              [-75.06255396599994, 10.650918844000046],
              [-75.06258389699991, 10.651093554000056],
              [-75.06260682499993, 10.65121156300006 ],
              [-75.06260018899991, 10.651397131000067],
              [-75.0625336949999 , 10.651568608000048],
              [-75.0625043899999 , 10.651731560000087],
              [-75.06248863699994, 10.651911977000054],
              [-75.06248244499994, 10.651982900000064],
              [-75.06250931099993, 10.652050884000062],
              [-75.06266584499991, 10.652164180000057],
              [-75.0627724279999 , 10.652246328000047],
              [-75.06285606799992, 10.652342136000073],
              [-75.06282365899995, 10.65242201600006 ],
              [-75.06274708799992, 10.65245722900005 ],
              [-75.0627388389999 , 10.652526942000065],
              [-75.0627368989999 , 10.652574948000051],
              [-75.06262589499994, 10.652661384000055],
              [-75.06248877899992, 10.652737517000048],
              [-75.06236192799992, 10.652802016000066],
              [-75.06236023399993, 10.652803672000061],
              [-75.06234569599991, 10.65281787400005 ],
              [-75.06224399199994, 10.652917221000052],
              [-75.0621992849999 , 10.653041084000051],
              [-75.06223974699992, 10.653269966000039],
              [-75.06225638699993, 10.653349371000047],
              [-75.06232500699991, 10.653445068000053],
              [-75.0623888849999 , 10.653524419000064],
              [-75.06242683199991, 10.653641177000054],
              [-75.06247417199995, 10.653718212000058],
              [-75.06250260899992, 10.653788260000056],
              [-75.06251457399992, 10.65390504800007 ],
              [-75.06234229699993, 10.654003359000058],
              [-75.06217000099991, 10.654087656000058],
              [-75.06202365199994, 10.654148560000067],
              [-75.06185829399993, 10.654125394000062],
              [-75.06176384399993, 10.654141852000066],
              [-75.06168844899992, 10.654279767000048],
              [-75.06166261499993, 10.654387254000085],
              [-75.06169124899992, 10.654599799000039],
              [-75.0617102409999 , 10.654672193000067],
              [-75.06170154599994, 10.654679891000058],
              [-75.06164418599991, 10.654730670000049],
              [-75.06150717699995, 10.654709805000039],
              [-75.06126843499993, 10.654562910000038],
              [-75.06110302799993, 10.654504702000054],
              [-75.06093070399993, 10.654567971000063],
              [-75.0607655199999 , 10.654673284000069],
              [-75.06059105199995, 10.654902414000048],
              [-75.06044488499992, 10.65509647400006 ],
              [-75.06033172699995, 10.655250782000053],
              [-75.06030594499993, 10.655397984000047],
              [-75.0603250229999 , 10.65553344600005 ],
              [-75.06032987599991, 10.655629219000048],
              [-75.06024509899993, 10.655813863000049],
              [-75.06006569899995, 10.655886486000043],
              [-75.05990051399993, 10.65599179700007 ],
              [-75.05983455699993, 10.656120354000052],
              [-75.05980875599994, 10.656253538000044],
              [-75.05986798099991, 10.65638662400005 ],
              [-75.05991995399995, 10.65639824300007 ],
              [-75.06004038799995, 10.656384088000038],
              [-75.06011586799991, 10.65630924900006 ],
              [-75.06018897399991, 10.656229741000061],
              [-75.0603259589999 , 10.65622724700006 ],
              [-75.06034964999992, 10.656280948000074],
              [-75.0603899429999 , 10.65638602100006 ],
              [-75.0603027759999 , 10.656551981000064],
              [-75.0602107659999 , 10.656626839000069],
              [-75.05993458599994, 10.656741624000063],
              [-75.05986779799991, 10.65680099900004 ],
              [-75.05966094299993, 10.656984884000053],
              [-75.05948396499991, 10.657099555000059],
              [-75.0592810359999 , 10.657242284000063],
              [-75.05921274399992, 10.657391866000069],
              [-75.0589814999999 , 10.657548648000045],
              [-75.05891307299993, 10.657597782000039],
              [-75.0587076249999 , 10.657621378000044],
              [-75.05859184799993, 10.657586472000048],
              [-75.0585115909999 , 10.65761926500005 ],
              [-75.05845979999992, 10.657745472000045],
              [-75.05839145899995, 10.657862351000063],
              [-75.05829717599994, 10.65800028700005 ],
              [-75.0582408219999 , 10.658245631000057],
              [-75.05824807699992, 10.658371769000041],
              [-75.05820336199992, 10.658490957000083],
              [-75.0580995979999 , 10.658607878000055],
              [-75.05808789899993, 10.658689654000057],
              [-75.05813290799995, 10.65879005000005 ],
              [-75.05823465299994, 10.658927757000072],
              [-75.05826545199994, 10.659000138000067],
              [-75.05828223799995, 10.659187005000092],
              [-75.05833439299994, 10.659331776000045],
              [-75.05832284799993, 10.65952801900005 ],
              [-75.0583161159999 , 10.659794331000057],
              [-75.05834108099992, 10.65991600600006 ],
              [-75.05841692599995, 10.660112144000038],
              [-75.0584690899999 , 10.660263929000052],
              [-75.0585023829999 , 10.660432083000046],
              [-75.05851935899994, 10.660761444000059],
              [-75.05848420099994, 10.660962380000058],
              [-75.05844673299993, 10.661203034000039],
              [-75.05838542399994, 10.66127552000006 ],
              [-75.0582791619999 , 10.661291997000092],
              [-75.05819642199992, 10.66123602600004 ],
              [-75.05802152599995, 10.661147460000052],
              [-75.05793893499992, 10.661203618000059],
              [-75.0578729579999 , 10.66131815600005 ],
              [-75.0577409739999 , 10.661528550000071],
              [-75.05760411099993, 10.661619814000062],
              [-75.05741985299994, 10.661598997000056],
              [-75.05732068299994, 10.66161780100009 ],
              [-75.05725469599992, 10.661727671000051],
              [-75.05727630399991, 10.661986943000045],
              [-75.05728605899992, 10.66221586100005 ],
              [-75.05721310099995, 10.662409835000062],
              [-75.05693510799995, 10.662938097000051],
              [-75.05674409799991, 10.663162577000037],
              [-75.0565364599999 , 10.663316989000066],
              [-75.05640439199993, 10.663464308000073],
              [-75.05631032999992, 10.663772771000083],
              [-75.0562492649999 , 10.664029805000041],
              [-75.0561266599999 , 10.664188792000061],
              [-75.05596395599991, 10.664387541000053],
              [-75.0558083119999 , 10.664567594000061],
              [-75.0557141399999 , 10.664794293000057],
              [-75.05564600199995, 10.665060681000057],
              [-75.05563202699994, 10.66520786600006 ],
              [-75.05566054499991, 10.66533865200006 ],
              [-75.05575987199995, 10.665438983000058],
              [-75.05592052799994, 10.665564850000067],
              [-75.05604105599991, 10.66568142500006 ],
              [-75.05612387899993, 10.66584472900007 ],
              [-75.05612640499993, 10.665970776000052],
              [-75.05609376199993, 10.666274268000052],
              [-75.05618855799992, 10.666570612000044],
              [-75.05623140499995, 10.666843670000048],
              [-75.0563238599999 , 10.667151687000057],
              [-75.05636654299991, 10.667303367000045],
              [-75.0563999439999 , 10.667576437000037],
              [-75.05635543699992, 10.66783325700004 ],
              [-75.05635100099994, 10.66804568200007 ],
              [-75.0563762459999 , 10.668096629000047],
              [-75.05646645999991, 10.668498017000047],
              [-75.05660124099995, 10.668684603000088],
              [-75.05666272499991, 10.668775569000047],
              [-75.05672659699991, 10.668882873000086],
              [-75.05679524199991, 10.66903452300005 ],
              [-75.05688976899995, 10.66912078200005 ],
              [-75.05702210099992, 10.669244344000049],
              [-75.05705064099993, 10.669405376000043],
              [-75.05717829999992, 10.669561627000064],
              [-75.0572752409999 , 10.66969223700005 ],
              [-75.0573509219999 , 10.669806527000048],
              [-75.05737237699992, 10.669969901000059],
              [-75.05733943799993, 10.670046970000044],
              [-75.05727119199992, 10.670198774000085],
              [-75.05718404399994, 10.670336595000038],
              [-75.05719604399991, 10.670485974000087],
              [-75.05731174599993, 10.670523188000061],
              [-75.05742260599993, 10.670471708000036],
              [-75.05757363099991, 10.67044585900004 ],
              [-75.05769415799995, 10.67056243400009 ],
              [-75.05771320699995, 10.670688461000054],
              [-75.05772526999993, 10.67088919400004 ],
              [-75.0577726649999 , 10.67103153000005 ],
              [-75.05784599599991, 10.671152827000071],
              [-75.05798800399992, 10.671449118000055],
              [-75.05808965799991, 10.671572716000071],
              [-75.05828799699992, 10.67163084200007 ],
              [-75.05863032999991, 10.67170281600005 ],
              [-75.05883097899994, 10.671725924000043],
              [-75.05902451399993, 10.671718699000053],
              [-75.05929359299995, 10.671730063000041],
              [-75.05945371899992, 10.671709405000058],
              [-75.05957888499995, 10.671709260000057],
              [-75.05966859999995, 10.671709158000056],
              [-75.05987880599992, 10.67174394600005 ],
              [-75.06010562099993, 10.67183943300006 ],
              [-75.06041495099993, 10.67198608800004 ],
              [-75.06058025099992, 10.672123523000039],
              [-75.06080464399992, 10.67235419800005 ],
              [-75.0608575469999 , 10.67242213500009 ],
              [-75.06092986999994, 10.672515009000051],
              [-75.06117073099995, 10.672708341000089],
              [-75.06141381299994, 10.672799032000057],
              [-75.06167816399994, 10.672920025000053],
              [-75.0619000929999 , 10.673071393000043],
              [-75.06202764599993, 10.67320187200005 ],
              [-75.0620844849999 , 10.673374421000062],
              [-75.06199757399992, 10.673654443000089],
              [-75.06189176599992, 10.673915824000062],
              [-75.06191107099994, 10.67424470700007 ],
              [-75.06204349799992, 10.674496482000052],
              [-75.06210506199994, 10.674673691000066],
              [-75.0621431429999 , 10.674934908000068],
              [-75.06218341899995, 10.675065492000044],
              [-75.0622238389999 , 10.675308040000061],
              [-75.06229509199994, 10.675678850000054],
              [-75.06237779999992, 10.675793055000042],
              [-75.06246295999995, 10.675972570000056],
              [-75.06244901499991, 10.676131210000051],
              [-75.06240935299991, 10.67646482500004 ],
              [-75.06249936499995, 10.676749305000044],
              [-75.06254209799994, 10.676956861000065],
              [-75.0625389889999 , 10.677032215000054],
              [-75.06253295399995, 10.67717847700004 ],
              [-75.06247427099993, 10.67740014800006 ],
              [-75.0624369759999 , 10.677738428000055],
              [-75.0624703119999 , 10.677976318000049],
              [-75.0624777669999 , 10.678263227000059],
              [-75.0624805089999 , 10.678554809000047],
              [-75.06246204999991, 10.678867408000087],
              [-75.06252360199994, 10.679035290000058],
              [-75.06265377199992, 10.679361708000044],
              [-75.0626493279999 , 10.679571653000039],
              [-75.06257180099993, 10.679812008000056],
              [-75.06246583699993, 10.679954426000052],
              [-75.06219968399995, 10.680260315000055],
              [-75.06192410499995, 10.680575547000046],
              [-75.06178523799991, 10.680811302000052],
              [-75.06172664499991, 10.681100624000067],
              [-75.0618403979999 , 10.681499374000055],
              [-75.06191152399992, 10.681774546000042],
              [-75.06195918799995, 10.682140720000064],
              [-75.06194078899995, 10.68250230600006 ],
              [-75.06194114099992, 10.682770558000072],
              [-75.06200268499992, 10.682929109000042],
              [-75.0621727589999 , 10.683103861000063],
              [-75.06234506899995, 10.683185305000052],
              [-75.06251737299993, 10.683262083000045],
              [-75.06261415099993, 10.683310953000046],
              [-75.06268513399993, 10.683471824000037],
              [-75.06274438799994, 10.683684028000073],
              [-75.0627988629999 , 10.683851916000037],
              [-75.06290517999992, 10.68397542200006 ],
              [-75.06300433599995, 10.684035957000049],
              [-75.06313188199994, 10.684159438000052],
              [-75.06317919599991, 10.684262021000052],
              [-75.06319545799994, 10.68435773400006 ],
              [-75.06332236199995, 10.684645754000087],
              [-75.06332036599991, 10.684645681000063],
              [-75.06311495999995, 10.684638366000058],
              [-75.05560569999994, 10.684344335000048],
              [-75.05561242999994, 10.684372048000057],
              [-75.0563462419999 , 10.687393751000059],
              [-75.0565956239999 , 10.689199913000039],
              [-75.05688251899994, 10.690042256000083],
              [-75.05981416499992, 10.692683235000061],
              [-75.06009887599993, 10.69284295400007 ],
              [-75.06079138699994, 10.693563552000057],
              [-75.06164656599992, 10.694363942000052],
              [-75.06232038499991, 10.694886627000074],
              [-75.06241989799992, 10.694963819000066],
              [-75.06124561699994, 10.695931224000049],
              [-75.06079902099992, 10.695932632000051],
              [-75.05998728399993, 10.696015498000065],
              [-75.05913520799993, 10.696178794000048],
              [-75.05893117699992, 10.695858202000068],
              [-75.05771408899994, 10.696143108000058],
              [-75.0566726049999 , 10.696477807000065],
              [-75.05657845499991, 10.696508065000046],
              [-75.0563336969999 , 10.696147440000061],
              [-75.05590385799991, 10.695514120000041],
              [-75.05576259899993, 10.695305991000055],
              [-75.05567402999992, 10.694943187000092],
              [-75.05555689999994, 10.694463391000056],
              [-75.05547490099991, 10.694334541000046],
              [-75.05474079599992, 10.693181010000046],
              [-75.05459923699993, 10.693021027000043],
              [-75.05392585299995, 10.692260008000062],
              [-75.05381843299995, 10.692366925000044],
              [-75.05360208799993, 10.692582254000058],
              [-75.05352217299992, 10.692984047000039],
              [-75.05275347799994, 10.693829692000065],
              [-75.05245495999992, 10.694441029000075],
              [-75.05126102799994, 10.696886087000053],
              [-75.05096972699994, 10.697580471000038],
              [-75.05077854599995, 10.69803619600009 ],
              [-75.0507625219999 , 10.69807439200008 ],
              [-75.05053573499993, 10.698614991000056],
              [-75.0495843949999 , 10.698940897000057],
              [-75.04911626199993, 10.69910126700006 ],
              [-75.04905932899993, 10.699174946000085],
              [-75.04871191899991, 10.69962453100004 ],
              [-75.04765784599994, 10.70010966500007 ],
              [-75.04660542099992, 10.701116796000065],
              [-75.04654400299995, 10.70114981200004 ],
              [-75.04555297099995, 10.701682560000052],
              [-75.04462019499994, 10.702128934000086],
              [-75.04441279699995, 10.702228183000045],
              [-75.04409560599993, 10.702273259000037],
              [-75.04165062099992, 10.702620712000055],
              [-75.04066773199992, 10.702352706000056],
              [-75.04011208299994, 10.70201688600008 ],
              [-75.03977534699993, 10.70181337300005 ],
              [-75.0395375299999 , 10.701514760000066],
              [-75.03894990799995, 10.700776917000042],
              [-75.0385117909999 , 10.699445638000043],
              [-75.03843262599992, 10.699676171000078],
              [-75.03728962999992, 10.70300461100004 ],
              [-75.03671293799994, 10.703883671000085],
              [-75.0366477579999 , 10.70398302700005 ],
              [-75.03663506799995, 10.704028054000048],
              [-75.03640611899993, 10.704563378000046],
              [-75.03619708899993, 10.704947253000057],
              [-75.0359685329999 , 10.705275389000064],
              [-75.03551114699991, 10.70584647600009 ],
              [-75.0351257069999 , 10.70622826500005 ],
              [-75.03480384099993, 10.70666919100006 ],
              [-75.03456449899994, 10.707153603000052],
              [-75.03445621799995, 10.707500849000041],
              [-75.03432449199994, 10.707856721000041],
              [-75.03418672899994, 10.70826998800004 ],
              [-75.03393587599993, 10.708846210000047],
              [-75.03376089199992, 10.70935755000005 ],
              [-75.03364228099991, 10.709842666000043],
              [-75.03350052399992, 10.71032490400006 ],
              [-75.0333777429999 , 10.710694806000049],
              [-75.03319295799992, 10.711132235000036],
              [-75.03303363799995, 10.711381509000091],
              [-75.0328158879999 , 10.711742147000052],
              [-75.03260137299992, 10.712160423000057],
              [-75.03235525799994, 10.712732157000062],
              [-75.0322114459999 , 10.71304316600009 ],
              [-75.03217634599991, 10.713119073000087],
              [-75.03192947499991, 10.71295869000005 ],
              [-75.03157193699991, 10.712765620000084],
              [-75.03097644499991, 10.712331796000058],
              [-75.03012515799992, 10.711914633000049],
              [-75.02942893999995, 10.711517447000062],
              [-75.0289043329999 , 10.711129133000043],
              [-75.02851683799992, 10.710831709000047],
              [-75.02808187499994, 10.710385773000041],
              [-75.02782979299991, 10.710035687000072],
              [-75.0278168509999 , 10.710017721000042],
              [-75.02771256699992, 10.709870495000075],
              [-75.02758114199992, 10.709684941000091],
              [-75.02731842799994, 10.70931696100007 ],
              [-75.02693626999991, 10.708609878000061],
              [-75.02660130399994, 10.708062821000055],
              [-75.02621869199993, 10.707565220000049],
              [-75.0256570979999 , 10.706789219000086],
              [-75.0255159319999 , 10.706566069000075],
              [-75.02535079299992, 10.706305026000052],
              [-75.02513287099993, 10.706004913000072],
              [-75.02500221199995, 10.705840507000062],
              [-75.02495566399995, 10.705810213000063],
              [-75.02468220399993, 10.70563224500006 ],
              [-75.02406004199992, 10.70524608900007 ],
              [-75.0232690979999 , 10.704830410000056],
              [-75.02277446799991, 10.704621686000053],
              [-75.02275887999991, 10.704615107000052],
              [-75.02261300899994, 10.704788743000051],
              [-75.02240525799994, 10.705119702000047],
              [-75.02222515099993, 10.70536042200007 ],
              [-75.02218204899992, 10.705418028000054],
              [-75.02200647599994, 10.705381419000048],
              [-75.0215708849999 , 10.705342949000055],
              [-75.02152326099991, 10.70534464700006 ],
              [-75.02151843599995, 10.705344814000057],
              [-75.02148156099992, 10.705346138000039],
              [-75.02126644899994, 10.705353781000042],
              [-75.02117479999993, 10.705347120000056],
              [-75.02115216499993, 10.705345477000037],
              [-75.02054680899994, 10.705301481000049],
              [-75.02030222499991, 10.705283705000056],
              [-75.01965315399991, 10.705239904000052],
              [-75.01963018599992, 10.70523797800007 ],
              [-75.01937470599995, 10.705216491000044],
              [-75.01910860899994, 10.705194113000061],
              [-75.01849501899994, 10.70512356200004 ],
              [-75.0177763239999 , 10.70507802700007 ],
              [-75.01728375599993, 10.705091072000073],
              [-75.01683877099993, 10.705056952000064],
              [-75.01653200899995, 10.705067725000049],
              [-75.01611120599995, 10.705089465000071],
              [-75.01600063899991, 10.705099518000054],
              [-75.0158529059999 , 10.705112955000061],
              [-75.01552503399995, 10.705132407000065],
              [-75.01502793899994, 10.705140734000054],
              [-75.01465748499993, 10.705096967000088],
              [-75.01448015599993, 10.705037292000043],
              [-75.0142003709999 , 10.70490364200009 ],
              [-75.01386965099994, 10.704745658000093],
              [-75.01355978599992, 10.704597637000063],
              [-75.01345718999994, 10.704710186000057],
              [-75.01328567999991, 10.70488778300006 ],
              [-75.01318946599991, 10.705084398000054],
              [-75.01317326399993, 10.705117508000058],
              [-75.0130045969999 , 10.705654327000047],
              [-75.01286569399991, 10.706207996000046],
              [-75.01267557099993, 10.70695838100005 ],
              [-75.01250261599995, 10.707575673000065],
              [-75.01242643099994, 10.70777199400004 ],
              [-75.01231677899995, 10.708054505000064],
              [-75.0121691139999 , 10.708587239000053],
              [-75.01201065399994, 10.708990781000068],
              [-75.01199505999995, 10.70902882900009 ],
              [-75.01199297199992, 10.709033924000039],
              [-75.01197659899992, 10.709073856000089],
              [-75.01197416399992, 10.709079793000058],
              [-75.01185295599993, 10.70937546600004 ],
              [-75.01183782399994, 10.70941237900007 ],
              [-75.01179918199995, 10.709406053000066],
              [-75.01178157299995, 10.709403167000062],
              [-75.01161463999995, 10.70937581000004 ],
              [-75.01144004199995, 10.70934720300005 ],
              [-75.01138217999994, 10.70933965000006 ],
              [-75.01102572999991, 10.709293135000053],
              [-75.01066030699991, 10.709233362000077],
              [-75.01059848299991, 10.709223860000066],
              [-75.01019533399995, 10.709161907000066],
              [-75.00978084999991, 10.709114731000057],
              [-75.00934595199993, 10.709143014000063],
              [-75.0088140009999 , 10.70924715600006 ],
              [-75.00851030699994, 10.709322461000056],
              [-75.00814598999995, 10.709407759000044],
              [-75.00781359199993, 10.70942248700004 ],
              [-75.00738688299992, 10.709400329000061],
              [-75.00690516199995, 10.709349167000084],
              [-75.00662474399991, 10.709287936000067],
              [-75.00659566399992, 10.709281586000088],
              [-75.0064777039999 , 10.709262525000042],
              [-75.00640079199991, 10.709559893000062],
              [-75.00625035699994, 10.709919894000052],
              [-75.00614388799994, 10.710191210000062],
              [-75.00583944999994, 10.710581884000078],
              [-75.00566291599995, 10.710841164000044],
              [-75.00553610399993, 10.711027404000049],
              [-75.00553117899995, 10.711034638000058],
              [-75.00541179599992, 10.71088430900005 ],
              [-75.00516852099992, 10.71057665300009 ],
              [-75.0048545759999 , 10.710310960000072],
              [-75.00482712899992, 10.710287423000068],
              [-75.00469363499991, 10.710172860000057],
              [-75.00468646799993, 10.710166707000042],
              [-75.00462307999993, 10.710112309000067],
              [-75.00455891199994, 10.710057243000051],
              [-75.00446201799991, 10.70997387600005 ],
              [-75.00423369899994, 10.70977744600009 ],
              [-75.00391297199991, 10.709504696000067],
              [-75.00355095099991, 10.70911578600004 ],
              [-75.00337643799992, 10.708906296000066],
              [-75.0032982969999 , 10.708812493000039],
              [-75.00283318699991, 10.708271361000072],
              [-75.00277906899993, 10.708205552000038],
              [-75.00171832699994, 10.70704866400007 ],
              [-75.0011459179999 , 10.706527871000048],
              [-75.0012691629999 , 10.706330656000091],
              [-75.0013511969999 , 10.706109380000044],
              [-75.0009042399999 , 10.70577591500006 ],
              [-75.00053137799995, 10.705530314000043],
              [-75.0004791909999 , 10.705495939000059],
              [-75.00019277699994, 10.705339138000056],
              [-75.0001709789999 , 10.70532742000006 ],
              [-74.99963586099994, 10.705039749000036],
              [-74.99923376299995, 10.704866244000073],
              [-74.9989235779999 , 10.704731865000042],
              [-74.99866586899992, 10.70463563900006 ],
              [-74.99851876999992, 10.704574411000067],
              [-74.99827135399994, 10.704436998000062],
              [-74.99803559499992, 10.704297580000059],
              [-74.99763159399993, 10.704202303000045],
              [-74.99725050699993, 10.704119106000064],
              [-74.99702800599994, 10.704042425000068],
              [-74.99702444999991, 10.704041188000076],
              [-74.99691202999992, 10.704002446000061],
              [-74.99683874899995, 10.703979613000058],
              [-74.99682642399995, 10.703975780000064],
              [-74.99661476499995, 10.703909833000068],
              [-74.99657197599993, 10.703890235000074],
              [-74.99631165499994, 10.703771028000062],
              [-74.99612197999994, 10.703646568000067],
              [-74.99621683499993, 10.703589024000053],
              [-74.99627036299995, 10.703556547000062],
              [-74.99651558199992, 10.703407765000065],
              [-74.99694211099995, 10.703056974000049],
              [-74.99730830199991, 10.702704673000085],
              [-74.99753964199994, 10.70245490700006 ],
              [-74.99777584499992, 10.702196049000065],
              [-74.9977590709999 , 10.702179068000078],
              [-74.99762598399991, 10.702044310000076],
              [-74.9973725179999 , 10.701787685000056],
              [-74.99733963299991, 10.701757356000087],
              [-74.99676849799994, 10.701230629000065],
              [-74.99671794999995, 10.701184         ],
              [-74.99666178199993, 10.701132208000047],
              [-74.99657967699994, 10.701061420000087],
              [-74.99607055399991, 10.700622442000054],
              [-74.99597952899995, 10.70054932700009 ],
              [-74.99575401999994, 10.700368190000063],
              [-74.9954830069999 , 10.700246310000068],
              [-74.99521619799992, 10.70014294400005 ],
              [-74.99508839499993, 10.700125815000092],
              [-74.9948783079999 , 10.70009765900005 ],
              [-74.99484392199992, 10.700093802000083],
              [-74.99474632099992, 10.70008284700009 ],
              [-74.9943711279999 , 10.70004354100007 ],
              [-74.9941239929999 , 10.700019587000043],
              [-74.99401902099993, 10.700009412000043],
              [-74.9938158089999 , 10.700030845000072],
              [-74.9936838079999 , 10.700044769000044],
              [-74.99353713199991, 10.700055846000055],
              [-74.9933860559999 , 10.700067253000043],
              [-74.99324357899991, 10.700080640000067],
              [-74.99301834599993, 10.700101802000063],
              [-74.99268757899995, 10.700049790000037],
              [-74.99250227999994, 10.700031348000039],
              [-74.99229703999993, 10.699973252000063],
              [-74.99218752199994, 10.699894543000084],
              [-74.99207595399992, 10.699804270000072],
              [-74.99151131199994, 10.699631295000074],
              [-74.99036212699991, 10.699396961000048],
              [-74.99036189899994, 10.69939691500008 ],
              [-74.99022442899991, 10.69936888300009 ],
              [-74.98941893099993, 10.699218120000069],
              [-74.98853483099992, 10.699052644000062],
              [-74.9880794529999 , 10.698962432000087],
              [-74.98807749499991, 10.698962040000083],
              [-74.98749732999994, 10.698847109000042],
              [-74.98746525099995, 10.698840017000066],
              [-74.98746065899991, 10.698839001000067],
              [-74.98715860899995, 10.698772143000042],
              [-74.98703516099994, 10.69874481900007 ],
              [-74.9870167659999 , 10.69877335600006 ],
              [-74.98698374299994, 10.698821895000037],
              [-74.98695803099992, 10.698860840000066],
              [-74.98695095599993, 10.698871231000055],
              [-74.98695092199995, 10.698871289000067],
              [-74.98695088599993, 10.698871348000068],
              [-74.98692464399994, 10.69890993200005 ],
              [-74.9868939189999 , 10.698953909000068],
              [-74.98686448799992, 10.698996082000065],
              [-74.98683975699993, 10.699034401000063],
              [-74.98681749899993, 10.699077998000064],
              [-74.98679373199991, 10.699119892000056],
              [-74.98676498299994, 10.699173986000062],
              [-74.98674054599991, 10.69922658900009 ],
              [-74.98671526599992, 10.699268687000085],
              [-74.98669148199991, 10.699310608000076],
              [-74.98666411799991, 10.699354449000055],
              [-74.9866388129999 , 10.699392767000063],
              [-74.98661760099992, 10.69942810200007 ],
              [-74.98658828599991, 10.699483084000065],
              [-74.98655871599993, 10.69952657600004 ],
              [-74.9865325369999 , 10.699569284000063],
              [-74.9865100529999 , 10.699608915000056],
              [-74.98648219199993, 10.699658929000066],
              [-74.9864505349999 , 10.699716135000074],
              [-74.98641987399992, 10.699764668000057],
              [-74.98639725299995, 10.699804543000084],
              [-74.98636839699992, 10.699853981000047],
              [-74.98634060499995, 10.699899351000056],
              [-74.98631933899992, 10.699936836000063],
              [-74.98629589399991, 10.699984992000054],
              [-74.98627091899994, 10.700029016000087],
              [-74.9862410849999 , 10.700080700000058],
              [-74.98621322899993, 10.700132967000059],
              [-74.98618629999993, 10.70018090900004 ],
              [-74.98615520499993, 10.700232462000088],
              [-74.98613224899992, 10.70027067600006 ],
              [-74.9861018169999 , 10.700323393000076],
              [-74.98607800499991, 10.700363035000066],
              [-74.98605406999991, 10.700402870000062],
              [-74.98602312399993, 10.700451875000056],
              [-74.98599213799992, 10.70050090300009 ],
              [-74.9859576259999 , 10.700552753000068],
              [-74.98593430099993, 10.700591573000054],
              [-74.98590751599994, 10.700632141000085],
              [-74.98588051899992, 10.700673031000065],
              [-74.98584826899992, 10.700716786000044],
              [-74.98581972599993, 10.700754095000093],
              [-74.98578442099995, 10.700800245000039],
              [-74.98575278099992, 10.700840809000056],
              [-74.98572014799993, 10.700879666000048],
              [-74.98568314799991, 10.700927266000065],
              [-74.98564488199992, 10.70097415500004 ],
              [-74.98561625899993, 10.701008699000056],
              [-74.98560290699993, 10.701027212000042],
              [-74.98558853499992, 10.701047179000057],
              [-74.98554385099993, 10.70110251400007 ],
              [-74.98551493999992, 10.701141748000055],
              [-74.98548641399992, 10.70118135100006 ],
              [-74.98545057099994, 10.70122820000006 ],
              [-74.98541742999993, 10.701271529000053],
              [-74.98539146099995, 10.701310831000058],
              [-74.98536109899993, 10.701349759000038],
              [-74.98533597799991, 10.70138780700006 ],
              [-74.98529670699992, 10.701440730000058],
              [-74.98526889899995, 10.70147705900007 ],
              [-74.98523383899993, 10.701525162000053],
              [-74.98520373899993, 10.701564508000047],
              [-74.98517007799995, 10.701604108000083],
              [-74.98513141899991, 10.701659823000057],
              [-74.98509905999992, 10.701701318000062],
              [-74.98506480299994, 10.70174385900009 ],
              [-74.98504064799994, 10.701776633000065],
              [-74.98504043999992, 10.701776916000085],
              [-74.98503369799994, 10.701786063000043],
              [-74.98502049199993, 10.70180160600006 ],
              [-74.98501032999991, 10.701813556000047],
              [-74.98494038599995, 10.701755373000083],
              [-74.98495082599993, 10.701743089000047],
              [-74.9848689289999 , 10.701712504000056],
              [-74.9846823769999 , 10.701642836000076],
              [-74.98448291299991, 10.701568346000045],
              [-74.98395759399995, 10.70164290200006 ],
              [-74.9833704749999 , 10.701726226000062],
              [-74.98274526599994, 10.701605580000091],
              [-74.98242430699992, 10.701543640000068],
              [-74.98092641099993, 10.700651745000073],
              [-74.98094810599991, 10.700610127000061],
              [-74.98102307199991, 10.700470143000075],
              [-74.98116223199992, 10.700210298000059],
              [-74.98127941199994, 10.699881709000067],
              [-74.98137696799995, 10.69969076600006 ],
              [-74.98140620499993, 10.699445152000067],
              [-74.98143488099993, 10.699222554000073],
              [-74.9814537069999 , 10.699136165000084],
              [-74.98145775599994, 10.699117586000057],
              [-74.98145983899991, 10.699108024000054],
              [-74.98147108099994, 10.699056449000068],
              [-74.98152144699992, 10.698825301000056],
              [-74.98153168099992, 10.698778339000057],
              [-74.98128281599992, 10.698660693000079],
              [-74.9809968589999 , 10.69852551100007 ],
              [-74.98050166399992, 10.69824897500007 ],
              [-74.98034996299992, 10.698185607000084],
              [-74.98017501899994, 10.698112528000081],
              [-74.97977101399994, 10.698048078000056],
              [-74.97947299399993, 10.698079138000082],
              [-74.97920039699994, 10.698139781000066],
              [-74.97698138499993, 10.693608001000086],
              [-74.97682255399991, 10.693283629000064],
              [-74.97681421699991, 10.693266602000051],
              [-74.97742478899994, 10.692670112000087],
              [-74.97749746599993, 10.69259911100005 ],
              [-74.97751953399995, 10.69257089000007 ],
              [-74.9775953919999 , 10.692473882000058],
              [-74.97766164899991, 10.692389148000075],
              [-74.9777156689999 , 10.69228002300008 ],
              [-74.97774657299993, 10.692217595000045],
              [-74.97779631499992, 10.692086630000063],
              [-74.9777987409999 , 10.692080246000046],
              [-74.97709407399992, 10.69133097200006 ],
              [-74.9769829899999 , 10.691245489000039],
              [-74.97696343599995, 10.691230441000073],
              [-74.9751332379999 , 10.689822068000069],
              [-74.97411666199991, 10.68903978600008 ],
              [-74.97635646499992, 10.687009279000051],
              [-74.97635933499993, 10.687006677000056],
              [-74.97521826499991, 10.686244868000074],
              [-74.97508165399995, 10.686158714000044],
              [-74.97508161299993, 10.686158688000091],
              [-74.97463600099991, 10.68587765500007 ],
              [-74.97402369299994, 10.685494136000045],
              [-74.97400497599995, 10.685478310000065],
              [-74.97365924499991, 10.68518597700006 ],
              [-74.97321153099995, 10.684835864000092],
              [-74.97264258199993, 10.684252916000048],
              [-74.9720643089999 , 10.683674498000073],
              [-74.97143295199993, 10.682909352000081],
              [-74.97127309399991, 10.682715616000053],
              [-74.97106831299993, 10.682390435000059],
              [-74.97076167699993, 10.681903513000066],
              [-74.9705079009999 , 10.681512417000079],
              [-74.96990824699992, 10.680588281000041],
              [-74.96931627999993, 10.680969385000083],
              [-74.96869238399995, 10.681371046000038],
              [-74.96865869999993, 10.681399708000072],
              [-74.96865861599991, 10.68139977900006 ],
              [-74.96852323099995, 10.681514991000086],
              [-74.96832071499995, 10.681687329000056],
              [-74.96777878099994, 10.682101026000055],
              [-74.96754081299991, 10.682489443000065],
              [-74.9674259709999 , 10.682676891000085],
              [-74.9672636329999 , 10.68294657000007 ],
              [-74.96723354999995, 10.683524918000046],
              [-74.96726318099991, 10.684186382000064],
              [-74.96732149899992, 10.684742448000065],
              [-74.96746571699993, 10.685301465000066],
              [-74.96760532099995, 10.685855871000058],
              [-74.96760636599993, 10.68587559100007 ],
              [-74.96763565999993, 10.68642778900005 ],
              [-74.9674447669999 , 10.687376843000038],
              [-74.96725236599991, 10.68822257100004 ],
              [-74.96705860899993, 10.688944312000046],
              [-74.96690257799992, 10.689298978000068],
              [-74.96658474999992, 10.689558271000067],
              [-74.96607226899994, 10.689767867000057],
              [-74.96564756399994, 10.69003551700007 ],
              [-74.96533416999995, 10.690322396000056],
              [-74.96516967199994, 10.69057138800008 ],
              [-74.96498552499992, 10.690955693000092],
              [-74.96494279099994, 10.691028087000063],
              [-74.96470077399994, 10.691437948000043],
              [-74.96448010699993, 10.69174391200005 ],
              [-74.96435242399991, 10.691937834000043],
              [-74.96427360299992, 10.691990024000063],
              [-74.9642755559999 , 10.691985723000073],
              [-74.9642388929999 , 10.691994777000048],
              [-74.96423865799994, 10.691994842000042],
              [-74.96375272299991, 10.692114855000057],
              [-74.96259399799993, 10.692415887000038],
              [-74.96213886899994, 10.692519492000088],
              [-74.96213877999992, 10.692519511000057],
              [-74.96158908899992, 10.692644641000072],
              [-74.96119549999992, 10.692793143000074],
              [-74.96110042799995, 10.692830771000047],
              [-74.9607972039999 , 10.69295078600004 ],
              [-74.96032310399994, 10.693002241000045],
              [-74.96030327599993, 10.693002927000066],
              [-74.9598887699999 , 10.693017263000058],
              [-74.95943092899995, 10.693068834000087],
              [-74.95926593199994, 10.692949828000053],
              [-74.95802482099992, 10.692054664000068],
              [-74.95801351199992, 10.692065187000082],
              [-74.95780792699992, 10.692256489000044],
              [-74.95770669699994, 10.692350686000054],
              [-74.95736999199994, 10.692646565000075],
              [-74.95694257199995, 10.692962403000081],
              [-74.9567547549999 , 10.69309145300008 ],
              [-74.95651299699995, 10.693257565000067],
              [-74.95654869799995, 10.69367838700009 ],
              [-74.9565526849999 , 10.693725390000054],
              [-74.9565767279999 , 10.69400883500009 ],
              [-74.95634845899991, 10.694098908000058],
              [-74.9562681509999 , 10.694118463000052],
              [-74.95626664499991, 10.694118829000047],
              [-74.9562436569999 , 10.694124428000066],
              [-74.95597388699991, 10.694190149000065],
              [-74.95549975999995, 10.694246190000058],
              [-74.95506267099995, 10.694316298000047],
              [-74.95429300399991, 10.694452693000073],
              [-74.95355291899995, 10.69461414500006 ],
              [-74.95355099499994, 10.694614566000041],
              [-74.95343069499995, 10.694635865000066],
              [-74.95339960799993, 10.694641373000081],
              [-74.95307896599991, 10.694698168000059],
              [-74.95228718099992, 10.694801856000083],
              [-74.95226774299994, 10.694804401000056],
              [-74.95146572099992, 10.694919882000079],
              [-74.95044516899992, 10.695070418000057],
              [-74.9495017879999 , 10.69515036000007 ],
              [-74.9489111709999 , 10.695301653000058],
              [-74.94870138499994, 10.695355392000067],
              [-74.94813305299994, 10.695575998000038],
              [-74.94750108999995, 10.695915504000084],
              [-74.94701075099994, 10.696260688000052],
              [-74.9466116449999 , 10.696517046000054],
              [-74.94649919599993, 10.696573758000056],
              [-74.94655028299991, 10.696603476000064],
              [-74.94637590199994, 10.69667757600007 ],
              [-74.94635044199993, 10.696659663000048],
              [-74.94633940299991, 10.696654349000084],
              [-74.94624865899993, 10.696610665000037],
              [-74.94623017099991, 10.696599037000055],
              [-74.94618392299992, 10.696575708000069],
              [-74.9461399839999 , 10.696552420000046],
              [-74.94609612099993, 10.696519938000051],
              [-74.94604525499994, 10.69649200300006 ],
              [-74.94600134199993, 10.696466390000069],
              [-74.94596205699992, 10.696443143000067],
              [-74.94591118699992, 10.696415183000056],
              [-74.94586728999991, 10.696387291000065],
              [-74.94582107299993, 10.696359380000047],
              [-74.94577490699993, 10.696324582000045],
              [-74.94573335599995, 10.696292121000056],
              [-74.94568487599992, 10.696257308000042],
              [-74.9456363939999 , 10.696222491000071],
              [-74.94559719199992, 10.696187743000053],
              [-74.9455579609999 , 10.696157589000052],
              [-74.9455233939999 , 10.696122891000073],
              [-74.94548420299992, 10.69608814600008 ],
              [-74.9454520029999 , 10.696048860000076],
              [-74.9454128459999 , 10.696009540000091],
              [-74.94537825299994, 10.695979417000046],
              [-74.94533439299994, 10.69594692600009 ],
              [-74.94530215799995, 10.695912240000041],
              [-74.94526064899992, 10.69587518700007 ],
              [-74.9452260999999 , 10.695840471000054],
              [-74.94518924199991, 10.695803459000047],
              [-74.94515005599993, 10.695766417000073],
              [-74.94511786299995, 10.695727132000059],
              [-74.9450787589999 , 10.695680914000093],
              [-74.9450395909999 , 10.695641575000082],
              [-74.94500508599992, 10.69559998200009 ],
              [-74.9449728379999 , 10.69556758400006 ],
              [-74.94493824299991, 10.695537473000059],
              [-74.94490367499992, 10.695505062000052],
              [-74.94485986299992, 10.695465690000049],
              [-74.9448183639999 , 10.695428628000059],
              [-74.94477453399992, 10.695391562000054],
              [-74.94473065499994, 10.695361375000061],
              [-74.94468675899992, 10.695333476000087],
              [-74.94464985299993, 10.695303352000053],
              [-74.9446013779999 , 10.695266237000055],
              [-74.94456444799994, 10.695238399000061],
              [-74.94451127699995, 10.695208137000066],
              [-74.94446508999994, 10.695177930000057],
              [-74.9444141859999 , 10.695154580000064],
              [-74.94436329299992, 10.695128930000067],
              [-74.94431699299992, 10.69511250000005 ],
              [-74.94425675899993, 10.69509366200009 ],
              [-74.94420582399994, 10.695074901000055],
              [-74.9441525169999 , 10.695063017000052],
              [-74.9440992609999 , 10.695044230000065],
              [-74.94403206599992, 10.695025333000046],
              [-74.94398574199994, 10.695011201000057],
              [-74.9439185519999 , 10.694992313000057],
              [-74.94387222299991, 10.694978178000042],
              [-74.94382587699994, 10.694966341000054],
              [-74.94377718999993, 10.694956778000062],
              [-74.94372383199993, 10.69495177500005 ],
              [-74.94366350999991, 10.69494441200004 ],
              [-74.94359849799991, 10.694943907000038],
              [-74.94354276799993, 10.694943476000049],
              [-74.94348704299995, 10.69494304400007 ],
              [-74.94342899199995, 10.694942600000047],
              [-74.9433825189999 , 10.694946825000045],
              [-74.9433360829999 , 10.69494646700008 ],
              [-74.94328960999991, 10.69495070000005 ],
              [-74.94323615199994, 10.69495717600006 ],
              [-74.94316181699992, 10.694961190000072],
              [-74.94310841399994, 10.694960772000059],
              [-74.9430503879999 , 10.694958020000058],
              [-74.94299467199994, 10.69495530200004 ],
              [-74.94294130399993, 10.694950291000055],
              [-74.94289493999992, 10.694940746000043],
              [-74.94284852099992, 10.694938093000076],
              [-74.94279285099992, 10.694930773000067],
              [-74.94274648499993, 10.694921237000074],
              [-74.94269319599994, 10.694907042000068],
              [-74.94263530399991, 10.694888220000053],
              [-74.94258664099993, 10.694874071000072],
              [-74.94253566499992, 10.694859897000072],
              [-74.94248467599994, 10.694848025000056],
              [-74.9424290849999 , 10.694831524000051],
              [-74.94237346699992, 10.694817313000044],
              [-74.94232016399991, 10.694803127000057],
              [-74.94225301099993, 10.694779649000054],
              [-74.94221136599992, 10.69476095400006 ],
              [-74.94216506399994, 10.694744521000075],
              [-74.9421234479999 , 10.694721234000042],
              [-74.94207947299992, 10.69470252700006 ],
              [-74.94203088499995, 10.694681494000065],
              [-74.94198691499992, 10.694662778000065],
              [-74.9419382609999 , 10.694648631000064],
              [-74.94188736699994, 10.694625269000085],
              [-74.94183878199993, 10.694601938000062],
              [-74.94179483799991, 10.694580926000071],
              [-74.94174857599995, 10.694557605000057],
              [-74.94169773099992, 10.694527366000045],
              [-74.9416538349999 , 10.694499475000043],
              [-74.94160531599994, 10.694469256000048],
              [-74.94155911099995, 10.694439050000085],
              [-74.94151292599992, 10.69440654500005 ],
              [-74.94147606999991, 10.694369523000091],
              [-74.94143686899992, 10.694334775000073],
              [-74.94139768899993, 10.69429774200006 ],
              [-74.94134928199992, 10.694253738000043],
              [-74.9413124379999 , 10.694214419000048],
              [-74.94128024499992, 10.694175142000063],
              [-74.94125734799991, 10.694133633000092],
              [-74.94122515499993, 10.694094355000061],
              [-74.9411907089999 , 10.694045871000071],
              [-74.94116088999994, 10.693999720000079],
              [-74.94114267799995, 10.693953659000044],
              [-74.94111978699993, 10.69391216200006 ],
              [-74.94109922399991, 10.69387067200006 ],
              [-74.94108102599995, 10.69382231700007 ],
              [-74.94106517799992, 10.69376937900006 ],
              [-74.9410493769999 , 10.693714156000055],
              [-74.94103356399995, 10.693658932000062],
              [-74.94098966799993, 10.69363104200005 ],
              [-74.94093847699992, 10.693644419000066],
              [-74.94088721299994, 10.693666979000056],
              [-74.9408383729999 , 10.69367578300006 ],
              [-74.94078716599995, 10.693691456000067],
              [-74.94072206699991, 10.693702432000066],
              [-74.94065463699991, 10.693713383000045],
              [-74.94059886699995, 10.69371983800005 ],
              [-74.94054768799992, 10.693730920000064],
              [-74.94049417199994, 10.693744284000047],
              [-74.94043834199994, 10.693757628000071],
              [-74.94038720399993, 10.693764117000057],
              [-74.94033372699994, 10.69377288000004 ],
              [-74.94027792399993, 10.693783929000062],
              [-74.94021741699993, 10.69379953500004 ],
              [-74.94016860199991, 10.693806045000088],
              [-74.94010581499992, 10.693817032000084],
              [-74.9400500349999 , 10.693823487000088],
              [-74.9400034869999 , 10.693836906000058],
              [-74.93994537499992, 10.69384563500006 ],
              [-74.93989191599991, 10.693852112000059],
              [-74.93983845799994, 10.69385857900005 ],
              [-74.93977806599992, 10.69386041100006 ],
              [-74.93972230299994, 10.693864578000046],
              [-74.93966422799991, 10.693868709000071],
              [-74.93961081999993, 10.693868287000043],
              [-74.93955973599992, 10.693867897000075],
              [-74.93950863499992, 10.69386979400008 ],
              [-74.93945058499992, 10.69386933900006 ],
              [-74.93939255799995, 10.693866598000056],
              [-74.9393415699999 , 10.693854719000058],
              [-74.93929285199994, 10.693849746000069],
              [-74.93923487299992, 10.693840118000082],
              [-74.93917918499994, 10.693835094000065],
              [-74.93909568499993, 10.693822958000055],
              [-74.9390353579999 , 10.693817901000045],
              [-74.9389658259999 , 10.693801291000057],
              [-74.93890554599994, 10.693789335000076],
              [-74.93884986199993, 10.693784311000059],
              [-74.93878492399995, 10.693774630000064],
              [-74.93873851699993, 10.69376967900007 ],
              [-74.93868749599994, 10.693762391000064],
              [-74.9386388069999 , 10.693752824000057],
              [-74.93858785399993, 10.693736372000046],
              [-74.93853223299993, 10.693722153000067],
              [-74.9384720139999 , 10.693703320000054],
              [-74.93842801099993, 10.693689195000047],
              [-74.93837703499992, 10.69367502600005 ],
              [-74.93832839999993, 10.69365857400004 ],
              [-74.93827745199991, 10.693642111000088],
              [-74.93822185499994, 10.693625606000069],
              [-74.93817090099992, 10.69360914200007 ],
              [-74.93812690999994, 10.693592722000062],
              [-74.93807599199994, 10.693571663000057],
              [-74.93803202799995, 10.693552958000055],
              [-74.93798110499995, 10.693531893000056],
              [-74.93793250099992, 10.693513152000037],
              [-74.93788625399992, 10.693487536000077],
              [-74.93783769499993, 10.693461898000066],
              [-74.9377937459999 , 10.693440896000084],
              [-74.93775211899992, 10.693419904000052],
              [-74.93770588199993, 10.693394289000082],
              [-74.93765730099994, 10.69337095800006 ],
              [-74.93761565299991, 10.693352262000076],
              [-74.93755780599992, 10.69332655900007 ],
              [-74.9375045509999 , 10.693307785000059],
              [-74.93744899399991, 10.693286683000053],
              [-74.93740031999994, 10.693274820000056],
              [-74.93735402699991, 10.693256093000059],
              [-74.93730075999991, 10.693237313000054],
              [-74.93725446099995, 10.69322087900008 ],
              [-74.9372058479999 , 10.69320212200006 ],
              [-74.9371548819999 , 10.693187962000081],
              [-74.9370969279999 , 10.69317602700005 ],
              [-74.93704825099991, 10.693166464000058],
              [-74.9370018759999 , 10.693156919000046],
              [-74.93694390299993, 10.69314729000007 ],
              [-74.93689516199993, 10.693144615000051],
              [-74.93684643199992, 10.693139642000062],
              [-74.93678839199993, 10.69313917900007 ],
              [-74.93673266699994, 10.693138756000053],
              [-74.9366792749999 , 10.693136048000042],
              [-74.9362617399999 , 10.693373886000074],
              [-74.93589598099993, 10.693524856000067],
              [-74.93564216999994, 10.693614723000053],
              [-74.93564884599994, 10.693651514000067],
              [-74.93564253599993, 10.693860397000037],
              [-74.93560863599993, 10.694034628000054],
              [-74.9355676319999 , 10.694227167000065],
              [-74.9355312159999 , 10.694426632000045],
              [-74.9354737779999 , 10.694639707000078],
              [-74.93545559299992, 10.69488294100006 ],
              [-74.93547751799991, 10.695046123000054],
              [-74.93558448899995, 10.695317878000083],
              [-74.93574100799992, 10.695491300000072],
              [-74.93603172399992, 10.695725450000054],
              [-74.93657419499993, 10.696129169000073],
              [-74.93709313999994, 10.696571733000042],
              [-74.93738595899993, 10.69683722700006 ],
              [-74.93744817699991, 10.696893639000052],
              [-74.93776871199992, 10.697173942000063],
              [-74.9380614339999 , 10.697447141000055],
              [-74.93818108999994, 10.69753058100008 ],
              [-74.9382046099999 , 10.697546982000063],
              [-74.93821308799994, 10.69759072800008 ],
              [-74.93834869999995, 10.698290777000068],
              [-74.93834894299994, 10.698292025000057],
              [-74.93836352199992, 10.698367246000089],
              [-74.93836353599994, 10.698367322000081],
              [-74.9383635499999 , 10.698367399000063],
              [-74.93836383999991, 10.69836889000004 ],
              [-74.93848163699994, 10.699039755000058],
              [-74.93848691199992, 10.69906980400009 ],
              [-74.93858449299995, 10.69962546000005 ],
              [-74.93884160199991, 10.70108960400006 ],
              [-74.93876675399991, 10.701079812000046],
              [-74.93865821299994, 10.701065609000068],
              [-74.93847008099993, 10.701064576000078],
              [-74.9382888529999 , 10.70107736500006 ],
              [-74.93808202699995, 10.701103791000037],
              [-74.93786809099993, 10.701160051000045],
              [-74.93782038499995, 10.701178953000067],
              [-74.93759353299993, 10.701268836000054],
              [-74.93738388799994, 10.701407840000059],
              [-74.93724340499995, 10.701664408000056],
              [-74.93710755599994, 10.701925603000063],
              [-74.93699526199993, 10.702108801000065],
              [-74.93690995199995, 10.702216437000061],
              [-74.93685746599994, 10.702282659000048],
              [-74.93673276299995, 10.70241631500005 ],
              [-74.93665910899995, 10.702495257000066],
              [-74.93641424899994, 10.70271908500007 ],
              [-74.93626051599995, 10.702819334000083],
              [-74.93611150999993, 10.702901231000055],
              [-74.93601369899994, 10.702958126000055],
              [-74.93591123399995, 10.70303141100004 ],
              [-74.93589254599993, 10.70304477600007 ],
              [-74.93547094099995, 10.70416053300005 ],
              [-74.93541081599994, 10.704319650000059],
              [-74.93537731999993, 10.704411533000041],
              [-74.93531355199991, 10.704586457000062],
              [-74.93498531599994, 10.705486850000057],
              [-74.93468175899994, 10.70629991900006 ],
              [-74.9346817359999 , 10.70629998000004 ],
              [-74.93460789099993, 10.706497770000055],
              [-74.93460692999992, 10.706500350000056],
              [-74.93456817899994, 10.706606872000066],
              [-74.93416730699994, 10.707708824000065],
              [-74.93387118099992, 10.708532707000074],
              [-74.93385659299992, 10.708573295000065],
              [-74.93378403999992, 10.708775151000054],
              [-74.93374838799991, 10.708961075000047],
              [-74.93375098199994, 10.709015761000046],
              [-74.93375689299995, 10.709140343000058],
              [-74.93376319999993, 10.709212393000087],
              [-74.93376796199993, 10.709266786000057],
              [-74.93374883599995, 10.709390753000037],
              [-74.93373895399992, 10.709526262000054],
              [-74.93372682699993, 10.709643374000052],
              [-74.93370605899992, 10.709687358000053],
              [-74.93367055499994, 10.709762554000065],
              [-74.93358417199994, 10.709863175000066],
              [-74.93347927899993, 10.70994761500009 ],
              [-74.93338236099993, 10.710030945000085],
              [-74.93328809899992, 10.710111994000044],
              [-74.9332040409999 , 10.710212625000054],
              [-74.93309907499992, 10.710313147000079],
              [-74.93301267699991, 10.710418359000073],
              [-74.93294708499991, 10.710544372000072],
              [-74.93271706399992, 10.711092259000054],
              [-74.93261938099994, 10.711244780000072],
              [-74.93253229999993, 10.711380747000078],
              [-74.93250847299993, 10.711446203000037],
              [-74.9322154429999 , 10.711476685000036],
              [-74.93218166699995, 10.711515615000053],
              [-74.9321448529999 , 10.711565366000059],
              [-74.93147296899991, 10.711444757000038],
              [-74.93119567699995, 10.711385502000041],
              [-74.93038608899991, 10.711354363000055],
              [-74.9295759389999 , 10.71112308100004 ],
              [-74.92771935399992, 10.710594430000071],
              [-74.92559071599993, 10.709265938000044],
              [-74.92457866399991, 10.709201972000074],
              [-74.92355758299993, 10.709162677000052],
              [-74.92291067699995, 10.709128362000058],
              [-74.9229177929999 , 10.709214746000043],
              [-74.9229280369999 , 10.709339106000073],
              [-74.92292962199991, 10.709358350000059],
              [-74.9229339229999 , 10.709443578000048],
              [-74.92294011399991, 10.709566255000084],
              [-74.92294125199993, 10.709588809000081],
              [-74.92294280499993, 10.709672065000063],
              [-74.92294507299994, 10.70979361600007 ],
              [-74.92294555599995, 10.709819516000039],
              [-74.92294449699995, 10.709900258000062],
              [-74.92294291199994, 10.710021144000052],
              [-74.92294253099993, 10.710050244000058],
              [-74.92293902599994, 10.71012829700004 ],
              [-74.92293363199991, 10.710248419000038],
              [-74.92293217899993, 10.71028076500005 ],
              [-74.92292642199993, 10.710355737000043],
              [-74.92291723299991, 10.710475403000089],
              [-74.92291451099993, 10.710510851000038],
              [-74.92290668299995, 10.710582782000074],
              [-74.92289374599994, 10.710701661000087],
              [-74.92288954399993, 10.710740273000056],
              [-74.92287984299992, 10.710809034000079],
              [-74.92286317499992, 10.710927175000052],
              [-74.9228573019999 , 10.71096880600004 ],
              [-74.92284594299991, 10.711034230000053],
              [-74.92282556099991, 10.711151628000039],
              [-74.92281781799994, 10.711196229000052],
              [-74.92280499499992, 10.711258324000084],
              [-74.92278093699991, 10.711374823000085],
              [-74.92277112999994, 10.711422309000056],
              [-74.92275707399995, 10.711480917000074],
              [-74.9227293269999 , 10.711596615000076],
              [-74.92271728499992, 10.711646827000038],
              [-74.92268703699995, 10.711760833000085],
              [-74.91715472399994, 10.711097979000044],
              [-74.91707667199995, 10.711088626000048],
              [-74.9167409559999 , 10.710946230000047],
              [-74.9174521939999 , 10.709192949000055],
              [-74.91777883599991, 10.707949840000083],
              [-74.9178955029999 , 10.707505838000088],
              [-74.91798992799994, 10.707442675000038],
              [-74.91806181099992, 10.707394599000054],
              [-74.9181240719999 , 10.707331949000093],
              [-74.91817363699994, 10.707282088000056],
              [-74.91822345499992, 10.707199784000068],
              [-74.91827094399991, 10.707159347000072],
              [-74.91833199399991, 10.70710736600006 ],
              [-74.91868515899995, 10.706806674000063],
              [-74.91863480399991, 10.706776319000085],
              [-74.91830282399991, 10.706576192000057],
              [-74.9177583899999 , 10.706306702000063],
              [-74.91751335999993, 10.706218290000038],
              [-74.91733548299993, 10.70614689100006 ],
              [-74.91712503199994, 10.706077333000053],
              [-74.91675546599993, 10.705925150000041],
              [-74.91654538699993, 10.705872125000042],
              [-74.91651467299994, 10.705864372000065],
              [-74.91633979099993, 10.705806411000083],
              [-74.91629034999994, 10.705790024000066],
              [-74.91608327599994, 10.705710331000091],
              [-74.91593424299992, 10.705670203000068],
              [-74.91347830699993, 10.705008820000046],
              [-74.91290328699995, 10.704918116000044],
              [-74.91249185399994, 10.70489636900004 ],
              [-74.91066226599992, 10.704799661000038],
              [-74.91058866799995, 10.704902034000042],
              [-74.91050584399994, 10.70500018100006 ],
              [-74.91043313099993, 10.705058664000092],
              [-74.91035761799992, 10.70511939700009 ],
              [-74.91027056399992, 10.705189415000063],
              [-74.91021111299995, 10.705245106000064],
              [-74.91015308699991, 10.705316374000063],
              [-74.91008777499991, 10.705396581000059],
              [-74.91001980999994, 10.705480034000061],
              [-74.90996060399993, 10.705552745000091],
              [-74.90989175599992, 10.705623320000086],
              [-74.90978864899995, 10.705704293000053],
              [-74.90973690499993, 10.705744939000056],
              [-74.90966688599991, 10.705764644000055],
              [-74.9095331119999 , 10.705825512000047],
              [-74.90940577099991, 10.705902086000037],
              [-74.90939979299992, 10.705905682000036],
              [-74.90901264599995, 10.706138476000092],
              [-74.90832790799993, 10.706550213000071],
              [-74.90828585199995, 10.70657338300009 ],
              [-74.90827012999995, 10.706583211000066],
              [-74.9082227689999 , 10.706612821000078],
              [-74.90820171799993, 10.706625982000048],
              [-74.90819032299993, 10.706634346000044],
              [-74.90814379699992, 10.706668495000088],
              [-74.9081217929999 , 10.706684646000042],
              [-74.9081144459999 , 10.706690933000061],
              [-74.90806678299992, 10.706731716000093],
              [-74.9080465209999 , 10.706749054000056],
              [-74.9080429409999 , 10.706752615000084],
              [-74.9079793009999 , 10.70681589500009 ],
              [-74.90797632999994, 10.706818849000058],
              [-74.90797615699995, 10.706819047000067],
              [-74.90791761199995, 10.706886205000046],
              [-74.90791158799993, 10.706893665000052],
              [-74.90789067299994, 10.706921850000072],
              [-74.90785661099994, 10.706967751000093],
              [-74.90785269499992, 10.70697302800005 ],
              [-74.90783390299993, 10.707002784000053],
              [-74.90780447999992, 10.707049373000075],
              [-74.90779996399993, 10.707056524000052],
              [-74.9077837339999 , 10.707087091000062],
              [-74.90775858899991, 10.70713444900008 ],
              [-74.9077536879999 , 10.707143680000058],
              [-74.90774020899994, 10.707174462000069],
              [-74.90771906999993, 10.707222735000073],
              [-74.90771413099992, 10.70723401500004 ],
              [-74.90770349499991, 10.707264333000069],
              [-74.90768621799992, 10.707313583000087],
              [-74.90768150399992, 10.707327020000037],
              [-74.90767355299994, 10.707356692000076],
              [-74.9076601729999 , 10.707406621000075],
              [-74.90765600399993, 10.707422178000058],
              [-74.90765063799995, 10.707450644000062],
              [-74.90764108199994, 10.707501339000089],
              [-74.90763776199992, 10.707518952000044],
              [-74.90763475199992, 10.707546017000084],
              [-74.90762906899994, 10.707597127000042],
              [-74.90762688099994, 10.707616804000054],
              [-74.9076240839999 , 10.707670223000036],
              [-74.90758336099992, 10.707999434000044],
              [-74.90757163399991, 10.708094219000088],
              [-74.90755136599995, 10.708258087000047],
              [-74.9075424639999 , 10.708330060000037],
              [-74.90730807699993, 10.708461723000084],
              [-74.90723735599994, 10.708501449000039],
              [-74.90631558599995, 10.706984058000046],
              [-74.90599171399992, 10.706450904000064],
              [-74.90591795799992, 10.706329492000066],
              [-74.9057970099999 , 10.706130384000062],
              [-74.90560310299992, 10.705379544000039],
              [-74.9055886889999 , 10.704949671000065],
              [-74.90558266499994, 10.704976799000065],
              [-74.90557598799995, 10.705006869000044],
              [-74.90551137699993, 10.705297846000065],
              [-74.9054518669999 , 10.705565838000041],
              [-74.90541249099994, 10.705743128000051],
              [-74.90539101799993, 10.705839806000085],
              [-74.90539018599992, 10.705843554000069],
              [-74.90535626699995, 10.705996305000042],
              [-74.90531174599994, 10.70619679300006 ],
              [-74.90527304599993, 10.70637105500009 ],
              [-74.9052170409999 , 10.706778064000048],
              [-74.90514524499991, 10.70691492700007 ],
              [-74.90508338599994, 10.707032858000048],
              [-74.90502270999991, 10.707148518000054],
              [-74.9049613599999 , 10.70726546700007 ],
              [-74.90491969899995, 10.707382811000059],
              [-74.90484259099992, 10.70756325700006 ],
              [-74.90478861899993, 10.707750917000055],
              [-74.90472352799992, 10.707977233000065],
              [-74.90469748499993, 10.708243654000057],
              [-74.90462332599992, 10.708536725000044],
              [-74.9044685909999 , 10.70908601900004 ],
              [-74.90440142199992, 10.70932444500005 ],
              [-74.90436793599991, 10.709443314000055],
              [-74.90437073699991, 10.709478371000046],
              [-74.90437766499991, 10.70956497800006 ],
              [-74.90438664399994, 10.709677226000053],
              [-74.90435979099993, 10.709824363000052],
              [-74.90436127699991, 10.710187426000061],
              [-74.90441370699995, 10.710408734000055],
              [-74.90442004399995, 10.71060411700006 ],
              [-74.90444064999991, 10.71077904000009 ],
              [-74.90445603499995, 10.710844146000056],
              [-74.90446324599992, 10.710869209000066],
              [-74.90447980099992, 10.710926749000066],
              [-74.90448909399993, 10.710959049000053],
              [-74.90449774099994, 10.71098336700004 ],
              [-74.90451780299992, 10.711039783000047],
              [-74.9045291679999 , 10.711071743000048],
              [-74.9045392239999 , 10.711095318000048],
              [-74.90456272599994, 10.711150411000062],
              [-74.90457612099993, 10.711181813000053],
              [-74.9045874759999 , 10.711204468000062],
              [-74.90461437699992, 10.711258136000083],
              [-74.90462976899994, 10.711288846000059],
              [-74.90464236699995, 10.71131054400007 ],
              [-74.90467255899995, 10.711362549000057],
              [-74.90468991499995, 10.711392445000058],
              [-74.90470368099994, 10.711413125000092],
              [-74.90473703499993, 10.711463231000039],
              [-74.90475633599993, 10.711492228000054],
              [-74.90477121799995, 10.711511865000091],
              [-74.90480753599991, 10.711559786000066],
              [-74.90482878099994, 10.711587818000055],
              [-74.90484471799994, 10.711606371000073],
              [-74.90488391899993, 10.711652007000055],
              [-74.9049069859999 , 10.711678861000053],
              [-74.9049401289999 , 10.71171425600005 ],
              [-74.9049910629999 , 10.711795155000061],
              [-74.90499196399992, 10.711796784000057],
              [-74.90504473899995, 10.71189224200009 ],
              [-74.90504760899995, 10.711897433000047],
              [-74.90508527899993, 10.711975303000088],
              [-74.90509761699991, 10.712002989000041],
              [-74.9051021699999 , 10.712014360000069],
              [-74.90513952599991, 10.712107667000055],
              [-74.90514087899993, 10.712111378000088],
              [-74.90514401399992, 10.71212093400004 ],
              [-74.90517338599994, 10.712210463000076],
              [-74.90517725399991, 10.71222225300005 ],
              [-74.90518252599992, 10.712242546000084],
              [-74.90520148399992, 10.712315513000078],
              [-74.9052065859999 , 10.712335151000048],
              [-74.90521378299991, 10.71236831400006 ],
              [-74.90522226199994, 10.712883112000043],
              [-74.9052274149999 , 10.71319595600005 ],
              [-74.90522310099993, 10.713245748000077],
              [-74.90517037299992, 10.713854315000049],
              [-74.90511780199995, 10.714461153000059],
              [-74.90514937299992, 10.71462834600004 ],
              [-74.90512271199992, 10.715007103000062],
              [-74.90504256499992, 10.715235755000037],
              [-74.90498967999991, 10.71538659500004 ],
              [-74.90489631899993, 10.715530123000065],
              [-74.90483825899992, 10.715680957000075],
              [-74.9047927499999 , 10.715799182000069],
              [-74.90470905799992, 10.715925019000053],
              [-74.90463933599995, 10.716029846000083],
              [-74.9045850249999 , 10.71611149100005 ],
              [-74.90450828999991, 10.71622687200005 ],
              [-74.90441555299992, 10.716274775000045],
              [-74.90428810399993, 10.716340616000082],
              [-74.90421208899994, 10.71637989800007 ],
              [-74.90416173799991, 10.716459651000037],
              [-74.9040889019999 , 10.716511489000084],
              [-74.90399817799994, 10.716576058000044],
              [-74.90394724599992, 10.716656575000059],
              [-74.9038979469999 , 10.716734526000039],
              [-74.90384362799995, 10.716820385000062],
              [-74.90376546899995, 10.716943984000068],
              [-74.90366503699994, 10.717102780000062],
              [-74.90357851999994, 10.71723957000006 ],
              [-74.9034846749999 , 10.717387933000055],
              [-74.9034716139999 , 10.717408590000048],
              [-74.90335764199995, 10.717528368000046],
              [-74.90326066199992, 10.717630304000068],
              [-74.90315050099991, 10.717700121000064],
              [-74.90302996199995, 10.717776520000086],
              [-74.90290187799991, 10.71785770200006 ],
              [-74.90280341199991, 10.717920112000058],
              [-74.90270834799992, 10.718043213000044],
              [-74.90254400999993, 10.71830516500006 ],
              [-74.90251167699995, 10.71844487800007 ],
              [-74.90248228099995, 10.718571916000087],
              [-74.90244183199991, 10.718746688000067],
              [-74.90243797999995, 10.71878784100005 ],
              [-74.90232680999992, 10.718896441000084],
              [-74.90101611599994, 10.71770181700009 ],
              [-74.9002220509999 , 10.71697806800006 ],
              [-74.90020967399994, 10.716966788000093],
              [-74.89991346799991, 10.716692114000068],
              [-74.8989951019999 , 10.718389175000084],
              [-74.89693280599994, 10.719464362000053],
              [-74.89692095599992, 10.71947263900006 ],
              [-74.89690325499993, 10.719483259000071],
              [-74.89687253999995, 10.719501687000047],
              [-74.89686490799994, 10.719506267000042],
              [-74.89684719599995, 10.719514323000055],
              [-74.89681518599991, 10.719528883000066],
              [-74.89680534999991, 10.719533357000046],
              [-74.89678830199995, 10.719538883000041],
              [-74.89675505799994, 10.719549661000087],
              [-74.89674305699992, 10.719553550000057],
              [-74.89672719599992, 10.719556773000079],
              [-74.89669297599994, 10.719563723000078],
              [-74.89667884199991, 10.71956659500006 ],
              [-74.89666444099993, 10.719567856000083],
              [-74.89662966899994, 10.719570902000044],
              [-74.89661354299994, 10.71957231500005 ],
              [-74.89660090199993, 10.719571991000066],
              [-74.8965658649999 , 10.71957109300007 ],
              [-74.8965480089999 , 10.71957063700006 ],
              [-74.89653730999993, 10.719569144000047],
              [-74.89650229199992, 10.719564259000038],
              [-74.89648309599994, 10.719561580000061],
              [-74.89647446999993, 10.719559363000087],
              [-74.89643963499992, 10.719550405000064],
              [-74.89641965099992, 10.719545267000058],
              [-74.89641312299995, 10.719542774000047],
              [-74.89637854599994, 10.719529565000073],
              [-74.89635849899992, 10.719521908000047],
              [-74.89635399399992, 10.719519571000092],
              [-74.89631913799991, 10.719501499000046],
              [-74.8963004389999 , 10.719491805000075],
              [-74.89629785199992, 10.719490065000059],
              [-74.89625945299991, 10.719464246000086],
              [-74.89624622699995, 10.719455352000068],
              [-74.89624535199994, 10.719454608000092],
              [-74.89619719599995, 10.719413565000082],
              [-74.89619656899993, 10.719413030000055],
              [-74.8961872719999 , 10.71940306600004 ],
              [-74.8961539849999 , 10.71936738700009 ],
              [-74.8961521139999 , 10.719365381000046],
              [-74.8961188259999 , 10.719321127000057],
              [-74.89594841899992, 10.719129892000069],
              [-74.89589245499991, 10.719067086000052],
              [-74.8952662399999 , 10.718364355000062],
              [-74.89493536499992, 10.717993053000043],
              [-74.8939336119999 , 10.717235362000054],
              [-74.89318256199994, 10.716667291000078],
              [-74.8916084409999 , 10.716512650000084],
              [-74.88936319999993, 10.715375280000046],
              [-74.88936306599993, 10.715346256000089],
              [-74.88936265699994, 10.71525799300008 ],
              [-74.88823959199993, 10.714762695000047],
              [-74.88775966199995, 10.714551035000056],
              [-74.88759229399994, 10.714856659000077],
              [-74.88743346399991, 10.715058920000047],
              [-74.88726364199994, 10.715219637000075],
              [-74.88715651199993, 10.715321006000067],
              [-74.88699155499995, 10.715376517000038],
              [-74.88671697599995, 10.715544866000073],
              [-74.8865614739999 , 10.715680496000061],
              [-74.88632557699992, 10.715904607000084],
              [-74.8861441809999 , 10.71607694100004 ],
              [-74.88604093399994, 10.716175038000074],
              [-74.88600194799994, 10.71624237800006 ],
              [-74.88595659799995, 10.716473935000067],
              [-74.88597624199991, 10.716713380000044],
              [-74.88605656599992, 10.717091703000051],
              [-74.8861405369999 , 10.717487119000054],
              [-74.8861597419999 , 10.717652004000058],
              [-74.88619033499992, 10.718089360000079],
              [-74.88617994099991, 10.718316850000065],
              [-74.88616637299992, 10.718613464000043],
              [-74.88613323099992, 10.718792138000083],
              [-74.88608721499992, 10.718917563000048],
              [-74.8860867169999 , 10.718918919000089],
              [-74.88604841299991, 10.719018591000065],
              [-74.8860416409999 , 10.719010293000053],
              [-74.88601542399994, 10.71905021100008 ],
              [-74.8859443959999 , 10.719158328000049],
              [-74.88582580699995, 10.719288808000044],
              [-74.88573681799994, 10.719331167000064],
              [-74.88538635299994, 10.719443187000081],
              [-74.88521418099992, 10.719498219000059],
              [-74.88508665899991, 10.71953897700007 ],
              [-74.8849496279999 , 10.719570003000058],
              [-74.88459934699995, 10.719670527000062],
              [-74.88426695399994, 10.719817293000062],
              [-74.88400316999991, 10.719922289000067],
              [-74.88371021899991, 10.720038896000062],
              [-74.8833737029999 , 10.72015112400004 ],
              [-74.88300883699992, 10.720292813000071],
              [-74.88279386099993, 10.720363176000092],
              [-74.88262145099992, 10.720385880000038],
              [-74.88230408699991, 10.720461600000078],
              [-74.88205849799994, 10.720520193000084],
              [-74.8818945829999 , 10.720559299000058],
              [-74.88177654099991, 10.720614830000045],
              [-74.88172008599992, 10.720641391000072],
              [-74.88156329899994, 10.720715144000053],
              [-74.88142808499992, 10.720778748000043],
              [-74.88138761099992, 10.720799878000037],
              [-74.8810711239999 , 10.720965068000055],
              [-74.88083247999992, 10.72106266700007 ],
              [-74.88056636099992, 10.721132258000068],
              [-74.88027476399992, 10.721194557000047],
              [-74.88015465099994, 10.721234551000066],
              [-74.88008314299992, 10.721258367000075],
              [-74.87989450299995, 10.721278526000049],
              [-74.87971597699993, 10.721297612000058],
              [-74.87961019999995, 10.721320251000066],
              [-74.87940800299992, 10.72142524600008 ],
              [-74.87937349799995, 10.721443162000071],
              [-74.87912569799994, 10.721531427000059],
              [-74.87893395099991, 10.721599711000067],
              [-74.8787521239999 , 10.721633891000067],
              [-74.87872023199992, 10.721641982000051],
              [-74.87854671399992, 10.721685992000062],
              [-74.87834810699991, 10.721749694000039],
              [-74.87818160299992, 10.721843767000053],
              [-74.87806390899993, 10.72191027100007 ],
              [-74.87790628099992, 10.722060382000052],
              [-74.87751102099992, 10.722521233000066],
              [-74.87741101599994, 10.722676088000071],
              [-74.87727338099995, 10.722860281000067],
              [-74.8771572469999 , 10.723015675000056],
              [-74.87703813099995, 10.72319477800005 ],
              [-74.87683516499993, 10.72340741700009 ],
              [-74.87683626699993, 10.72341054800006 ],
              [-74.87671679699992, 10.723531412000057],
              [-74.87654363099995, 10.723712830000068],
              [-74.87643273099991, 10.723829024000054],
              [-74.87633919299992, 10.723901700000056],
              [-74.87624092999994, 10.723978068000065],
              [-74.87614828599993, 10.72407129800007 ],
              [-74.87603548699991, 10.724150081000062],
              [-74.87595473199991, 10.724206493000054],
              [-74.8755857829999 , 10.725619377000044],
              [-74.87558543999995, 10.725648431000081],
              [-74.8755839929999 , 10.725771044000055],
              [-74.87555476599994, 10.725665648000074],
              [-74.87537657599995, 10.725023069000088]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08675",
      "properties": {"id": "08675", "iso": "STL", "name": "SANTA LUCÍA"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.9722959209999 , 10.394807985000057],
              [-74.97197638799992, 10.39470209600006 ],
              [-74.97170778299994, 10.394616838000047],
              [-74.97163115399991, 10.394583462000071],
              [-74.97131194799994, 10.394444426000064],
              [-74.97107118299994, 10.394341011000051],
              [-74.9707540789999 , 10.394207645000051],
              [-74.97045316499992, 10.394049236000058],
              [-74.9702077309999 , 10.393961791000038],
              [-74.97093517899992, 10.393080725000061],
              [-74.97160663199992, 10.392087727000046],
              [-74.97165750599993, 10.392012487000045],
              [-74.97167471099993, 10.391987046000054],
              [-74.97170465599993, 10.39192199200005 ],
              [-74.97176974099995, 10.391780616000062],
              [-74.9717099799999 , 10.39173048300006 ],
              [-74.97165197899994, 10.391678658000046],
              [-74.97159573499994, 10.39162514800006 ],
              [-74.97154124799994, 10.39156994800004 ],
              [-74.97147431099995, 10.391497099000048],
              [-74.97141023299992, 10.391421510000043],
              [-74.97134304199994, 10.391260665000061],
              [-74.97118509799992, 10.390882571000077],
              [-74.97115561099992, 10.390811987000063],
              [-74.9711489199999 , 10.390781878000041],
              [-74.97114663699995, 10.390752735000092],
              [-74.97114875699992, 10.390724551000062],
              [-74.97115528699993, 10.390697332000059],
              [-74.97116249299995, 10.390679542000044],
              [-74.97117488099991, 10.39065580600004 ],
              [-74.97118847699994, 10.390632972000049],
              [-74.97120388999991, 10.390610764000087],
              [-74.97121145199992, 10.39060131900004 ],
              [-74.9712207629999 , 10.39058969100006 ],
              [-74.97123870299993, 10.390570247000085],
              [-74.97125689799992, 10.390553640000064],
              [-74.9712775239999 , 10.39053804300005 ],
              [-74.97130065099992, 10.390523391000045],
              [-74.97132641799993, 10.39050960700007 ],
              [-74.97138592899995, 10.39048447500005 ],
              [-74.97146262099994, 10.390459847000045],
              [-74.9715015139999 , 10.390451165000059],
              [-74.97158632899993, 10.390439593000053],
              [-74.97162270799993, 10.390432568000051],
              [-74.97167145299994, 10.390419484000063],
              [-74.97172037299993, 10.390403984000045],
              [-74.9717691919999 , 10.390386148000061],
              [-74.97181759099993, 10.390366094000058],
              [-74.97185053999993, 10.39034973300005 ],
              [-74.97192508699993, 10.390307558000075],
              [-74.97196221099995, 10.390290537000055],
              [-74.9720227119999 , 10.39027522300006 ],
              [-74.97203530399992, 10.390270317000045],
              [-74.97204536699991, 10.390264285000057],
              [-74.97206403199993, 10.390247171000055],
              [-74.97208000499995, 10.390226214000052],
              [-74.97209084799994, 10.390204666000045],
              [-74.97209562799992, 10.390184460000057],
              [-74.9720954519999 , 10.390173652000044],
              [-74.97209319799992, 10.390161214000045],
              [-74.9720800039999 , 10.390121073000046],
              [-74.9720629869999 , 10.390073466000047],
              [-74.9720443949999 , 10.390032941000072],
              [-74.97202369299993, 10.389998365000054],
              [-74.9720007219999 , 10.389969506000057],
              [-74.97197475399992, 10.38994497400006 ],
              [-74.97193924499993, 10.389917633000039],
              [-74.97190643399995, 10.389896555000064],
              [-74.97185203099991, 10.389866747000042],
              [-74.97177469799993, 10.38982492200006 ],
              [-74.97172274199994, 10.389799355000036],
              [-74.97167438799994, 10.389779304000058],
              [-74.97162950899991, 10.38976504100009 ],
              [-74.97159139599995, 10.389756651000084],
              [-74.97154829899995, 10.389750402000061],
              [-74.97142183799991, 10.389741266000044],
              [-74.97130355099995, 10.389737257000093],
              [-74.97121486099991, 10.389735246000043],
              [-74.9711633739999 , 10.389737646000071],
              [-74.97106267099991, 10.389755274000038],
              [-74.97099963399995, 10.38976155000006 ],
              [-74.97095414599994, 10.389761744000054],
              [-74.9709001999999 , 10.389755413000046],
              [-74.97085370099995, 10.38974369400006 ],
              [-74.9708171069999 , 10.38972982200005 ],
              [-74.97078659599993, 10.389714831000049],
              [-74.97076120199995, 10.389698252000073],
              [-74.97074079299995, 10.389679972000067],
              [-74.97072549399991, 10.38966150500005 ],
              [-74.97071110899992, 10.389639806000048],
              [-74.97069629299995, 10.389609828000061],
              [-74.97068319699991, 10.389572188000045],
              [-74.97067671699995, 10.389542457000061],
              [-74.97067378899993, 10.389508337000052],
              [-74.97067713899992, 10.389391069000055],
              [-74.97067941699993, 10.38933277700005 ],
              [-74.9706857079999 , 10.389269006000063],
              [-74.97069626599995, 10.389196648000052],
              [-74.97073313099992, 10.388979211000049],
              [-74.97074115899994, 10.388909695000052],
              [-74.97074469099994, 10.388847184000042],
              [-74.97074564299993, 10.38876994900005 ],
              [-74.97074429699995, 10.38870840900006 ],
              [-74.97073921499992, 10.388618414000064],
              [-74.9707307239999 , 10.388556655000059],
              [-74.97072256699994, 10.388522648000048],
              [-74.97071078199991, 10.388485309000089],
              [-74.97066447299994, 10.388366017000067],
              [-74.9706402889999 , 10.38830768300005 ],
              [-74.97057731699994, 10.388169442000049],
              [-74.9705498159999 , 10.388097453000057],
              [-74.97053560099994, 10.388048128000037],
              [-74.97050823899991, 10.387930578000066],
              [-74.9704937539999 , 10.387878193000063],
              [-74.97047743399992, 10.387832113000059],
              [-74.97043570999995, 10.387727902000051],
              [-74.97041926499992, 10.387678365000056],
              [-74.97040590899991, 10.387626905000047],
              [-74.9703943749999 , 10.387573165000049],
              [-74.97038465499992, 10.387517173000049],
              [-74.97037675299993, 10.387458929000047],
              [-74.97036777599993, 10.387363855000046],
              [-74.97036641099993, 10.387288194000064],
              [-74.97037213599992, 10.387223532000064],
              [-74.97038555299991, 10.387152216000061],
              [-74.97040488899995, 10.387078343000042],
              [-74.97044673499994, 10.386939123000047],
              [-74.9704784729999 , 10.386836578000043],
              [-74.97049496999995, 10.386792706000051],
              [-74.97051577899992, 10.38674555700004 ],
              [-74.97054142799993, 10.386693664000063],
              [-74.97062119599991, 10.38654063000007 ],
              [-74.97064406399994, 10.386491100000057],
              [-74.97066216799993, 10.386445752000043],
              [-74.97068172199994, 10.386392537000063],
              [-74.9706914969999 , 10.386360288000049],
              [-74.9707020749999 , 10.386304598000038],
              [-74.97070839199995, 10.386235829000043],
              [-74.97070881299993, 10.386167937000039],
              [-74.97070232999994, 10.386075522000056],
              [-74.97069484899993, 10.38599730900006 ],
              [-74.97068579099994, 10.385945544000037],
              [-74.97067363799994, 10.385903710000036],
              [-74.97064811099995, 10.385833483000056],
              [-74.97060835399992, 10.385728466000046],
              [-74.97056487999993, 10.385625179000044],
              [-74.97051785799994, 10.385524019000059],
              [-74.9704674589999 , 10.385425354000063],
              [-74.97043698399995, 10.385371637000048],
              [-74.9703598559999 , 10.385246348000067],
              [-74.9703266109999 , 10.385188664000054],
              [-74.97029730699995, 10.385131562000083],
              [-74.97023627999994, 10.385004059000039],
              [-74.97020912499994, 10.38495191000004 ],
              [-74.97017302799992, 10.384888414000045],
              [-74.97013381099993, 10.384823313000084],
              [-74.9700448339999 , 10.384686377000037],
              [-74.9700007159999 , 10.384623202000057],
              [-74.9699514909999 , 10.384556247000091],
              [-74.96982791099992, 10.384397853000053],
              [-74.96969068399994, 10.384230296000055],
              [-74.96963478499993, 10.384166452000045],
              [-74.9695817839999 , 10.384109417000047],
              [-74.9695228409999 , 10.384049657000048],
              [-74.96946209499993, 10.383991197000057],
              [-74.96939969499994, 10.383934190000048],
              [-74.96933582299994, 10.383878800000048],
              [-74.96922281899992, 10.383787902000051],
              [-74.96909583399992, 10.383694429000059],
              [-74.96899645099995, 10.383626892000052],
              [-74.96886762299994, 10.383544717000063],
              [-74.96867412299991, 10.383427667000092],
              [-74.96857926599995, 10.383373410000047],
              [-74.96848547799993, 10.383321889000058],
              [-74.96839261899993, 10.383273027000087],
              [-74.9683006169999 , 10.383226789000048],
              [-74.96820939299994, 10.383183127000052],
              [-74.96811888099995, 10.383142024000051],
              [-74.96797123899995, 10.383079034000048],
              [-74.96783820499991, 10.383027154000047],
              [-74.96769760799992, 10.382976821000057],
              [-74.96757986699993, 10.382938434000039],
              [-74.96747171899995, 10.382907681000063],
              [-74.9673705479999 , 10.382883887000048],
              [-74.96726598999993, 10.382863822000047],
              [-74.96716897999994, 10.382848803000059],
              [-74.96707752299994, 10.382838527000047],
              [-74.9669907629999 , 10.38283289900005 ],
              [-74.96690294399991, 10.38283186800004 ],
              [-74.96680860099991, 10.382835459000091],
              [-74.96670586399995, 10.382843698000045],
              [-74.96661826499991, 10.382853676000082],
              [-74.9665878269999 , 10.38285714300008 ],
              [-74.96647489399993, 10.382871385000044],
              [-74.96640676599992, 10.382881940000061],
              [-74.96634475299993, 10.38289476500006 ],
              [-74.96629010299995, 10.382910009000057],
              [-74.96624562099993, 10.382927236000057],
              [-74.96619866899994, 10.382951177000052],
              [-74.96614731299991, 10.382982662000074],
              [-74.96604203999993, 10.383054267000091],
              [-74.96599595299995, 10.383083623000061],
              [-74.96594597799992, 10.383110982000062],
              [-74.96589940099994, 10.38313082400009 ],
              [-74.96584007099995, 10.383149522000053],
              [-74.96577211899995, 10.383165847000043],
              [-74.9657077469999 , 10.383178141000087],
              [-74.96553749799995, 10.383206999000038],
              [-74.96545708699995, 10.383223667000038],
              [-74.96525641499994, 10.383269403000043],
              [-74.96514942099992, 10.383289305000062],
              [-74.96508999499991, 10.383297063000043],
              [-74.96503453399993, 10.383301414000073],
              [-74.96498195799995, 10.383302445000083],
              [-74.96493173899995, 10.383300158000054],
              [-74.9648658449999 , 10.38329353100005 ],
              [-74.96480488999993, 10.383284613000058],
              [-74.96474841899993, 10.383273347000056],
              [-74.9646962679999 , 10.383259689000056],
              [-74.96464650299993, 10.383241354000063],
              [-74.96459383199993, 10.383214893000059],
              [-74.96454107599993, 10.383182779000037],
              [-74.96439652299995, 10.383087086000046],
              [-74.96435061799991, 10.383061216000044],
              [-74.96430800699994, 10.383041455000068],
              [-74.96425406599991, 10.38302062300005 ],
              [-74.96419560099991, 10.383000450000054],
              [-74.96413156299991, 10.382980562000057],
              [-74.96405857399992, 10.382959907000043],
              [-74.96397998999993, 10.382939949000047],
              [-74.96389844399994, 10.38292203800006 ],
              [-74.9638138549999 , 10.382906160000061],
              [-74.96372611699991, 10.382892287000061],
              [-74.96363628199992, 10.382879915000046],
              [-74.96356777999995, 10.38287218000005 ],
              [-74.9635058739999 , 10.382867435000037],
              [-74.9634491509999 , 10.382865640000091],
              [-74.96338707799993, 10.38286699200006 ],
              [-74.96331923699995, 10.382871840000064],
              [-74.96324416499994, 10.382880173000046],
              [-74.96314465299992, 10.382893895000052],
              [-74.96312510999991, 10.382898141000055],
              [-74.96309960699995, 10.382906113000047],
              [-74.96302978099993, 10.382933535000063],
              [-74.96293749999995, 10.382974774000047],
              [-74.96267850299995, 10.383095885000046],
              [-74.96261694899994, 10.383121901000038],
              [-74.96257711999993, 10.383135282000069],
              [-74.96262857099993, 10.382542125000043],
              [-74.96264633599992, 10.382462897000039],
              [-74.9626469289999 , 10.382460249000076],
              [-74.96266104199992, 10.382397304000051],
              [-74.96278769499992, 10.381832425000084],
              [-74.96254248499991, 10.379326139000057],
              [-74.96241217699992, 10.379329920000089],
              [-74.96181392299991, 10.379373102000045],
              [-74.96181389599991, 10.37937310400008 ],
              [-74.96113880699994, 10.379421833000038],
              [-74.96050655399995, 10.379331863000061],
              [-74.96048040799991, 10.379328143000066],
              [-74.96039544899992, 10.37931605600005 ],
              [-74.96025947899994, 10.37931150500009 ],
              [-74.96011882399995, 10.379306800000052],
              [-74.95944370899991, 10.379284226000038],
              [-74.95930318499995, 10.379279527000051],
              [-74.9588468199999 , 10.379224089000047],
              [-74.95824419299993, 10.379150880000054],
              [-74.95734223099993, 10.379134406000048],
              [-74.95563229699991, 10.379049878000046],
              [-74.95554881099991, 10.379045751000092],
              [-74.95548648999994, 10.379042670000047],
              [-74.95513854799992, 10.379024874000038],
              [-74.9549693589999 , 10.379027393000058],
              [-74.9548089999999 , 10.378999969000063],
              [-74.9547454879999 , 10.378939095000078],
              [-74.95446983499994, 10.378803555000047],
              [-74.95428315899994, 10.378723944000058],
              [-74.9538054379999 , 10.37841515100007 ],
              [-74.95375831299992, 10.378389974000072],
              [-74.95346683699995, 10.378234236000083],
              [-74.95345945899993, 10.378230294000048],
              [-74.95306973099991, 10.378062086000057],
              [-74.95281043299991, 10.377930875000061],
              [-74.95271177099994, 10.37788865400006 ],
              [-74.95254675799993, 10.377818037000054],
              [-74.95242662599992, 10.377774833000046],
              [-74.9523206209999 , 10.377736704000085],
              [-74.95205012499991, 10.377630808000049],
              [-74.95154990599991, 10.377514589000043],
              [-74.95153923399994, 10.377511110000057],
              [-74.95124483299992, 10.377415228000075],
              [-74.9511934969999 , 10.377398509000045],
              [-74.95074768699993, 10.37735019300004 ],
              [-74.95029317099994, 10.377291114000059],
              [-74.95024133399994, 10.377284374000055],
              [-74.9496249199999 , 10.377144649000059],
              [-74.94928549299993, 10.377080988000046],
              [-74.94881335899993, 10.37697803900005 ],
              [-74.94799172199993, 10.376758986000084],
              [-74.94764940599993, 10.376658722000059],
              [-74.9474493159999 , 10.376590311000086],
              [-74.94720665599993, 10.376507347000086],
              [-74.94693686399995, 10.376449509000054],
              [-74.94670161199991, 10.376377910000087],
              [-74.94661564299992, 10.376351745000079],
              [-74.94594702099994, 10.37614824700006 ],
              [-74.94565770699995, 10.376173076000043],
              [-74.94549640399993, 10.376178209000045],
              [-74.94536690799993, 10.376182321000044],
              [-74.94525920099994, 10.37618574000004 ],
              [-74.9452181769999 , 10.37619767600006 ],
              [-74.94507696799991, 10.376238750000084],
              [-74.94475012399994, 10.376239001000044],
              [-74.94451426099994, 10.376204746000042],
              [-74.94430641199995, 10.376174562000074],
              [-74.94401350399994, 10.376119320000043],
              [-74.9437325099999 , 10.376086823000037],
              [-74.94351712399992, 10.376096836000045],
              [-74.94333444699993, 10.376124695000044],
              [-74.94325332099993, 10.376135455000053],
              [-74.94292729499995, 10.376178702000061],
              [-74.94253734699993, 10.376289717000077],
              [-74.9423911959999 , 10.376329321000071],
              [-74.94213577099993, 10.376398538000046],
              [-74.94178696599994, 10.37647914200005 ],
              [-74.94153332399992, 10.376560686000062],
              [-74.94114802099995, 10.376673886000049],
              [-74.9406264939999 , 10.37682109900004 ],
              [-74.94055940499993, 10.376841303000049],
              [-74.94019508299994, 10.376951011000074],
              [-74.9401887489999 , 10.376697068000055],
              [-74.94018387799991, 10.37653238900009 ],
              [-74.94017920199991, 10.376234974000056],
              [-74.94012420299993, 10.375977181000053],
              [-74.94009188899992, 10.375693887000068],
              [-74.9400206329999 , 10.375434086000041],
              [-74.94001172799994, 10.375310625000054],
              [-74.9399910379999 , 10.375024913000061],
              [-74.9399773959999 , 10.374836633000086],
              [-74.93997331399993, 10.374780284000053],
              [-74.93987467199992, 10.374550609000039],
              [-74.93978617099992, 10.374375703000055],
              [-74.93973664999993, 10.374315653000053],
              [-74.93964446899992, 10.374203873000056],
              [-74.9395478159999 , 10.37410230100005 ],
              [-74.93945162099993, 10.37388175600006 ],
              [-74.93938105099994, 10.373665347000042],
              [-74.9392655289999 , 10.373392458000069],
              [-74.93922447699993, 10.372990303000051],
              [-74.93921478899995, 10.37237485500009 ],
              [-74.93853396799994, 10.372404613000072],
              [-74.93834850999991, 10.372412721000046],
              [-74.9382537059999 , 10.372085739000056],
              [-74.93820571799995, 10.37192022000005 ],
              [-74.93818929199995, 10.371863566000059],
              [-74.93814314199994, 10.371630795000044],
              [-74.93808614099993, 10.37134332000005 ],
              [-74.93799286399991, 10.371015146000047],
              [-74.9378572949999 , 10.37079060800005 ],
              [-74.93774832199995, 10.370643446000088],
              [-74.93762850799993, 10.370391182000049],
              [-74.9373854609999 , 10.369966800000043],
              [-74.9372478279999 , 10.36961642600005 ],
              [-74.93717137199991, 10.369440502000089],
              [-74.93708674299995, 10.369245772000056],
              [-74.93693529399991, 10.368897873000037],
              [-74.93684199099994, 10.368562843000063],
              [-74.93675375099991, 10.36830892000006 ],
              [-74.93669877299993, 10.368150722000053],
              [-74.93666872699993, 10.36785824900005 ],
              [-74.93663834599994, 10.367746469000053],
              [-74.93653454399993, 10.36728357000004 ],
              [-74.93644912099995, 10.367007908000062],
              [-74.93644324999991, 10.36698787000006 ],
              [-74.93636833199992, 10.366732206000052],
              [-74.93619971699991, 10.366473795000047],
              [-74.93596943599994, 10.366127016000064],
              [-74.93581717599994, 10.36587527200004 ],
              [-74.9357048679999 , 10.365657205000048],
              [-74.9356770089999 , 10.365508875000046],
              [-74.93557442499991, 10.365322736000053],
              [-74.93550760599993, 10.36519784300009 ],
              [-74.93542925299994, 10.365082272000052],
              [-74.93529086899991, 10.364976744000046],
              [-74.93529503899993, 10.364647172000048],
              [-74.93522643699993, 10.364266027000042],
              [-74.93513971999994, 10.36394649500005 ],
              [-74.9351280809999 , 10.363903613000048],
              [-74.93512505699994, 10.363889974000074],
              [-74.93512266199991, 10.36387917400009 ],
              [-74.93509066999991, 10.363734821000037],
              [-74.93509250099993, 10.363654968000048],
              [-74.93509755399992, 10.363434939000058],
              [-74.93509254199995, 10.363114643000074],
              [-74.93509678299995, 10.362789628000087],
              [-74.93508835299991, 10.362549469000044],
              [-74.93506475099991, 10.362371349000057],
              [-74.93500844899995, 10.362179928000046],
              [-74.93493622699992, 10.362009362000038],
              [-74.9348994849999 , 10.36193880400009 ],
              [-74.93489828499992, 10.361936497000045],
              [-74.93481676699992, 10.361779947000059],
              [-74.93474977199992, 10.361710612000081],
              [-74.93471708499993, 10.361676782000075],
              [-74.93461182499993, 10.36156784800005 ],
              [-74.93443962099991, 10.361375835000047],
              [-74.93399837099992, 10.360876551000047],
              [-74.9337984249999 , 10.360689551000064],
              [-74.93371077799992, 10.360614867000038],
              [-74.93350008199991, 10.36043533500009 ],
              [-74.9334166939999 , 10.360346311000058],
              [-74.93327381199992, 10.360193773000049],
              [-74.93308760899993, 10.359997412000041],
              [-74.93299945099994, 10.359843093000052],
              [-74.93294968799995, 10.359772883000062],
              [-74.9329468489999 , 10.359770904000072],
              [-74.93282001199992, 10.35968241800009 ],
              [-74.93264684699994, 10.359530764000056],
              [-74.93261974699993, 10.359537878000083],
              [-74.9326197069999 , 10.35953788800009 ],
              [-74.93240917399993, 10.359593148000044],
              [-74.93210192499993, 10.359657104000064],
              [-74.93194374299992, 10.359769283000048],
              [-74.93179203599993, 10.359851580000054],
              [-74.93156490799993, 10.360001345000057],
              [-74.93136270499991, 10.360109582000064],
              [-74.9312842839999 , 10.360160712000038],
              [-74.93122272199992, 10.360200852000048],
              [-74.9309599739999 , 10.360315201000049],
              [-74.93086359099993, 10.360357142000055],
              [-74.93068860999995, 10.36043292700009 ],
              [-74.93054171199992, 10.360524324000039],
              [-74.9303463409999 , 10.360627841000053],
              [-74.93019709299995, 10.36072157700005 ],
              [-74.93001790599993, 10.360822584000061],
              [-74.92984792099992, 10.360921168000061],
              [-74.92958544399994, 10.361032562000048],
              [-74.92929323499993, 10.361171841000044],
              [-74.92919872899995, 10.361231789000044],
              [-74.92908410699994, 10.361304507000057],
              [-74.92906772599991, 10.361314900000082],
              [-74.92905691199991, 10.361321762000046],
              [-74.92888988499993, 10.361461471000041],
              [-74.92873109899995, 10.361537042000066],
              [-74.92851315299993, 10.361679780000088],
              [-74.9284779219999 , 10.361697032000052],
              [-74.92847456999993, 10.361698672000045],
              [-74.92836517799992, 10.36175224100009 ],
              [-74.92816102899991, 10.361877425000046],
              [-74.92809737999994, 10.361919313000044],
              [-74.92791618799993, 10.362043250000056],
              [-74.92765668099992, 10.362193483000055],
              [-74.9274268389999 , 10.362337120000063],
              [-74.92742199799994, 10.362340147000054],
              [-74.92736053199991, 10.362378553000042],
              [-74.92728966399994, 10.362412523000046],
              [-74.9271997059999 , 10.362455641000054],
              [-74.92704537899994, 10.362529615000085],
              [-74.92669053399993, 10.36266523200004 ],
              [-74.92651091099992, 10.362720093000064],
              [-74.92642298999993, 10.362746948000051],
              [-74.92613367899992, 10.362776317000055],
              [-74.92580719599994, 10.362794841000039],
              [-74.92549899099993, 10.362797044000047],
              [-74.92541462499992, 10.362788969000064],
              [-74.92514610499995, 10.362763266000059],
              [-74.92488357399992, 10.362721340000064],
              [-74.92456516599992, 10.362668799000062],
              [-74.9243539759999 , 10.362646687000051],
              [-74.9241266219999 , 10.36265977100004 ],
              [-74.92408767499995, 10.362662010000065],
              [-74.92393317699992, 10.362716903000091],
              [-74.92378331099991, 10.36276483100005 ],
              [-74.92358523299993, 10.362845547000063],
              [-74.9234679029999 , 10.362940438000066],
              [-74.92363480299991, 10.362606819000064],
              [-74.92396555699992, 10.362265608000087],
              [-74.92370889299991, 10.361442436000061],
              [-74.92362314299993, 10.361167424000087],
              [-74.92361526499991, 10.361142159000053],
              [-74.9235748719999 , 10.36101261400006 ],
              [-74.9234287669999 , 10.360544018000041],
              [-74.92341137199992, 10.36055749500008 ],
              [-74.9233960599999 , 10.360569357000088],
              [-74.9232408549999 , 10.360678910000047],
              [-74.92301187599992, 10.360858437000047],
              [-74.92298608899995, 10.360877059000074],
              [-74.92253494399995, 10.361190321000038],
              [-74.92223766099994, 10.361302229000046],
              [-74.92196462399994, 10.36133138300005 ],
              [-74.9217214329999 , 10.361344067000061],
              [-74.92143378899993, 10.36132536200006 ],
              [-74.92118752899995, 10.36128777600004 ],
              [-74.9209731389999 , 10.361215395000045],
              [-74.92071317299991, 10.361040684000045],
              [-74.9202747029999 , 10.360717539000063],
              [-74.9200657589999 , 10.360541485000056],
              [-74.91982412499993, 10.360328725000045],
              [-74.91951771599992, 10.360035202000063],
              [-74.91964010399994, 10.359776157000056],
              [-74.91970505299992, 10.359638695000058],
              [-74.91974558499993, 10.359552908000069],
              [-74.91975195399993, 10.35953943000004 ],
              [-74.91978649299995, 10.359466328000053],
              [-74.9198108889999 , 10.359414686000036],
              [-74.9200511869999 , 10.358906106000063],
              [-74.92029177899991, 10.358396903000084],
              [-74.92036315999991, 10.358363810000071],
              [-74.9205350869999 , 10.358242308000058],
              [-74.92071156899993, 10.358116167000048],
              [-74.92091044699993, 10.357937074000063],
              [-74.92108413699992, 10.357778942000039],
              [-74.92130667599992, 10.357485059000055],
              [-74.92148072499992, 10.357201051000061],
              [-74.92170562899992, 10.356911712000056],
              [-74.92205228799992, 10.356545079000057],
              [-74.92234865699993, 10.356227268000055],
              [-74.92245214399992, 10.356116091000047],
              [-74.92263583399995, 10.355918734000056],
              [-74.9229491559999 , 10.35564643400005 ],
              [-74.92298869199993, 10.355603371000086],
              [-74.92317701099995, 10.35539826300004 ],
              [-74.92341869199993, 10.355143032000058],
              [-74.92345234899994, 10.355101958000091],
              [-74.92369389399994, 10.354807161000053],
              [-74.92405937399991, 10.354465441000059],
              [-74.92434882599991, 10.354150012000048],
              [-74.92457387999991, 10.353867557000058],
              [-74.92463299799994, 10.353802904000077],
              [-74.92478807799995, 10.353633307000052],
              [-74.92493501599995, 10.353393145000041],
              [-74.92510828999991, 10.353061116000049],
              [-74.9251085699999 , 10.352990484000088],
              [-74.92510922499991, 10.352825404000043],
              [-74.92516124999992, 10.352442495000048],
              [-74.9251309679999 , 10.352138583000055],
              [-74.92516043199993, 10.351797187000045],
              [-74.92519732499994, 10.351487679000059],
              [-74.92529852699994, 10.351140693000048],
              [-74.9254974449999 , 10.35081972100005 ],
              [-74.92572161899994, 10.35047776600004 ],
              [-74.92591106399993, 10.350294208000037],
              [-74.92620582199993, 10.35001987000004 ],
              [-74.92628054799991, 10.349968929000056],
              [-74.9263688339999 , 10.349908740000046],
              [-74.92644202399993, 10.349858838000046],
              [-74.9265934039999 , 10.34975563100005 ],
              [-74.92678707199991, 10.349691500000063],
              [-74.9268206289999 , 10.349605775000043],
              [-74.92681346899991, 10.349603667000054],
              [-74.92684749999995, 10.349447161000057],
              [-74.92692378299995, 10.349096338000038],
              [-74.92714425799994, 10.349167823000073],
              [-74.92733158699991, 10.349228563000054],
              [-74.9274268389999 , 10.349259445000087],
              [-74.9281152129999 , 10.349482634000083],
              [-74.92817032199991, 10.349500502000069],
              [-74.92853807999995, 10.349619739000048],
              [-74.9286338309999 , 10.349654179000083],
              [-74.92881310199994, 10.349718659000075],
              [-74.92911549199994, 10.34978748900005 ],
              [-74.92939504899994, 10.349865851000061],
              [-74.92948795299992, 10.349891893000063],
              [-74.9297783799999 , 10.349940308000043],
              [-74.92983155299993, 10.349928052000053],
              [-74.93020387899992, 10.34987688800004 ],
              [-74.93044698999995, 10.349861864000047],
              [-74.93066470899993, 10.349849560000052],
              [-74.93105391099994, 10.349841562000051],
              [-74.9313583899999 , 10.349812425000039],
              [-74.93150521299992, 10.349798375000091],
              [-74.93176161899993, 10.349742007000089],
              [-74.93207550999995, 10.349659595000048],
              [-74.93236140599993, 10.349563914000043],
              [-74.9326969359999 , 10.349382794000064],
              [-74.93284346599995, 10.349266254000042],
              [-74.93308651099994, 10.349097912000047],
              [-74.93322589499991, 10.348972331000084],
              [-74.93338064699992, 10.348835828000063],
              [-74.93346965099994, 10.348757320000061],
              [-74.9335345639999 , 10.348700061000045],
              [-74.93364822799992, 10.348558836000052],
              [-74.9337576499999 , 10.348405409000065],
              [-74.93379338799991, 10.348355299000048],
              [-74.9338163459999 , 10.348293371000068],
              [-74.93381785899993, 10.34828929200006 ],
              [-74.93389661399993, 10.348076855000045],
              [-74.93419244299992, 10.347647603000041],
              [-74.93440588699991, 10.347291601000052],
              [-74.93440743799994, 10.34728901300008 ],
              [-74.93463221999991, 10.346914077000065],
              [-74.93488921699992, 10.346456044000092],
              [-74.93490642699993, 10.346425371000066],
              [-74.93504901699993, 10.346171233000064],
              [-74.9352707299999 , 10.345776079000075],
              [-74.93546160799991, 10.345435878000046],
              [-74.93582666599991, 10.344867673000067],
              [-74.93590340599991, 10.34474823000005 ],
              [-74.93617755699995, 10.344321518000072],
              [-74.93628148599993, 10.344159755000078],
              [-74.9363327289999 , 10.344083646000058],
              [-74.93645417199991, 10.343903270000055],
              [-74.93635886399994, 10.343721265000056],
              [-74.93599249199991, 10.343305437000083],
              [-74.93562127899992, 10.342970837000053],
              [-74.93515133999995, 10.342610611000055],
              [-74.93457056099993, 10.342198178000046],
              [-74.93445370599994, 10.342115192000051],
              [-74.93395999699993, 10.341850830000055],
              [-74.93328633099992, 10.341612198000064],
              [-74.93161618199991, 10.341020573000037],
              [-74.93108985899994, 10.340854913000044],
              [-74.93034141299995, 10.340676643000052],
              [-74.9299140299999 , 10.340574850000053],
              [-74.92834555099995, 10.340201257000047],
              [-74.92833679299991, 10.340190104000044],
              [-74.92760686499992, 10.340040170000066],
              [-74.9265870779999 , 10.339910772000053],
              [-74.9264677729999 , 10.339893318000065],
              [-74.92510382099994, 10.339693792000048],
              [-74.92398443699994, 10.339530041000046],
              [-74.92378131999993, 10.339500328000042],
              [-74.9235262489999 , 10.33946951300004 ],
              [-74.9231320639999 , 10.33942188900005 ],
              [-74.92204284399992, 10.339269727000044],
              [-74.92086173599995, 10.339104727000063],
              [-74.92053963099994, 10.339059728000052],
              [-74.91920173599993, 10.338845335000087],
              [-74.91784277699992, 10.33864269500009 ],
              [-74.91774894399992, 10.33862870300004 ],
              [-74.91765918899995, 10.338615320000088],
              [-74.91764147499993, 10.338612678000061],
              [-74.91752749099993, 10.338595683000051],
              [-74.91737685099991, 10.338573220000058],
              [-74.91732324299994, 10.338566135000065],
              [-74.91732640999993, 10.338550403000056],
              [-74.91743714799992, 10.338344331000087],
              [-74.91774491599995, 10.337670726000056],
              [-74.91811738699994, 10.336855509000088],
              [-74.91812344399995, 10.336842619000038],
              [-74.91814650099991, 10.336793531000069],
              [-74.9189057399999 , 10.335162186000048],
              [-74.9188188469999 , 10.335169631000042],
              [-74.91857786999992, 10.335190275000059],
              [-74.9182042299999 , 10.335276104000059],
              [-74.91788337899993, 10.335315617000049],
              [-74.91753273099994, 10.33538291700006 ],
              [-74.91721889599995, 10.335431524000057],
              [-74.91685843099992, 10.335430064000093],
              [-74.91705196599992, 10.33475383700005 ],
              [-74.91718540099993, 10.334374101000037],
              [-74.91731971099995, 10.334111351000047],
              [-74.91750001399993, 10.333818388000054],
              [-74.91778732599994, 10.333322572000043],
              [-74.91815421399991, 10.332658601000048],
              [-74.9184456129999 , 10.332091612000056],
              [-74.91861155799995, 10.331739158000062],
              [-74.91886451199991, 10.331275769000058],
              [-74.91903350099994, 10.331019564000087],
              [-74.9193035529999 , 10.330686825000043],
              [-74.91952499699994, 10.330336123000052],
              [-74.91896564299992, 10.329705491000084],
              [-74.91898107199995, 10.329684875000055],
              [-74.91898636299993, 10.329677807000053],
              [-74.91906332099995, 10.32957497600006 ],
              [-74.91919829299991, 10.329394644000047],
              [-74.91922805299993, 10.329354884000054],
              [-74.9192698789999 , 10.32929899900006 ],
              [-74.91941787399992, 10.329024675000085],
              [-74.91943015299995, 10.329001910000045],
              [-74.91943979699994, 10.329013943000064],
              [-74.91970525099993, 10.325433461000046],
              [-74.91970933199991, 10.325433598000075],
              [-74.92042519499995, 10.325388390000057],
              [-74.92148458799994, 10.325255230000039],
              [-74.92209122999992, 10.325135162000038],
              [-74.9224304299999 , 10.325068027000043],
              [-74.92327893099991, 10.324793231000058],
              [-74.92338200699993, 10.324759849000088],
              [-74.92343516499994, 10.324742633000085],
              [-74.92382455499995, 10.324556391000044],
              [-74.92383296799994, 10.32455251600004 ],
              [-74.92520191299991, 10.324057226000093],
              [-74.92635480099995, 10.323450627000057],
              [-74.9264439879999 , 10.323395271000038],
              [-74.92726677699994, 10.322884574000057],
              [-74.92771499299994, 10.322606366000059],
              [-74.92777051799993, 10.32257190300004 ],
              [-74.92780494899995, 10.322550531000047],
              [-74.9292680129999 , 10.321642404000045],
              [-74.9296530009999 , 10.321227752000084],
              [-74.93002186399991, 10.320830468000054],
              [-74.93112491399995, 10.319763073000047],
              [-74.93174616399995, 10.319131249000066],
              [-74.9324617339999 , 10.318423259000042],
              [-74.93310365699995, 10.317788136000047],
              [-74.93323420899992, 10.317658961000063],
              [-74.93413531699991, 10.316767698000092],
              [-74.93415647199993, 10.31675232300006 ],
              [-74.9341726259999 , 10.31674058100009 ],
              [-74.93499749999995, 10.316141041000037],
              [-74.9350686489999 , 10.316089329000079],
              [-74.93508555799991, 10.31607703800006 ],
              [-74.93501490299991, 10.315716731000066],
              [-74.9349642709999 , 10.315484079000043],
              [-74.93490123799995, 10.315118563000055],
              [-74.9348283349999 , 10.31474683600004 ],
              [-74.93477079699994, 10.314568695000048],
              [-74.93466674099994, 10.314203965000047],
              [-74.93463643399991, 10.314117647000046],
              [-74.93454655199992, 10.313861643000052],
              [-74.93444807199995, 10.313533748000054],
              [-74.93440973699995, 10.313242323000054],
              [-74.93440871799993, 10.31323457600007 ],
              [-74.93437418499991, 10.313110419000054],
              [-74.93436615099995, 10.31308153200007 ],
              [-74.93435185599992, 10.313030126000058],
              [-74.9344590899999 , 10.312953037000057],
              [-74.93446961199993, 10.312945667000065],
              [-74.93648914499994, 10.311323952000066],
              [-74.93659882799994, 10.311235872000054],
              [-74.93663383499995, 10.311207761000048],
              [-74.93667514499992, 10.31117458500006 ],
              [-74.93665915699995, 10.311154230000056],
              [-74.93682568199995, 10.311025326000049],
              [-74.9369310269999 , 10.310943781000049],
              [-74.93693783299994, 10.310938513000053],
              [-74.9371977049999 , 10.310635386000058],
              [-74.9373681379999 , 10.310262185000056],
              [-74.93750954799992, 10.309970289000091],
              [-74.93761371599993, 10.30975526800006 ],
              [-74.93784430099993, 10.309397618000048],
              [-74.9380252709999 , 10.309090036000043],
              [-74.93803816999991, 10.309068105000051],
              [-74.93806549399994, 10.30902167000005 ],
              [-74.93823700599995, 10.308730384000057],
              [-74.93858372199992, 10.308215546000042],
              [-74.9387689429999 , 10.307973852000089],
              [-74.93884325999994, 10.307891927000071],
              [-74.93896824599994, 10.307754139000053],
              [-74.93926352299991, 10.307400483000038],
              [-74.93959171199992, 10.307113125000058],
              [-74.9398051679999 , 10.306934545000047],
              [-74.9401841639999 , 10.30665590600006 ],
              [-74.94034264999993, 10.306535214000064],
              [-74.94047555299994, 10.306394072000046],
              [-74.94059907499991, 10.306239292000043],
              [-74.94077600499992, 10.306106924000062],
              [-74.94103353299994, 10.305953224000064],
              [-74.94120832599992, 10.305850776000057],
              [-74.94134338799995, 10.30569126100005 ],
              [-74.94133765099991, 10.305549047000056],
              [-74.94130027499995, 10.305494303000046],
              [-74.94118913799991, 10.305458590000057],
              [-74.9411104429999 , 10.305445484000074],
              [-74.94099249299995, 10.305421267000042],
              [-74.94090460399991, 10.305410596000058],
              [-74.94079715299995, 10.30536733100007 ],
              [-74.94072618399991, 10.305338755000037],
              [-74.94055241699994, 10.305269131000045],
              [-74.94042695699994, 10.305185319000088],
              [-74.94022466299992, 10.305012766000061],
              [-74.93996923199995, 10.304838311000083],
              [-74.93965541299991, 10.304593244000046],
              [-74.93960541899992, 10.304552116000082],
              [-74.93930205399994, 10.304302546000088],
              [-74.93927914799991, 10.304283698000063],
              [-74.93868133399991, 10.303791886000056],
              [-74.93865906099995, 10.303744417000075],
              [-74.93858792899994, 10.303592820000063],
              [-74.93819284299991, 10.302750812000056],
              [-74.93818810699992, 10.302740718000052],
              [-74.93818712199993, 10.302738025000053],
              [-74.93818613899992, 10.30273533400009 ],
              [-74.93818516399995, 10.30273264300007 ],
              [-74.93818418099994, 10.30272995200005 ],
              [-74.93818320399993, 10.30272725900005 ],
              [-74.93818222699991, 10.302724571000056],
              [-74.93818124599994, 10.302721879000046],
              [-74.93818027099991, 10.302719187000037],
              [-74.93817929599993, 10.302716488000044],
              [-74.9381783199999 , 10.302713795000045],
              [-74.93817735599993, 10.302711102000046],
              [-74.9381763799999 , 10.302708404000043],
              [-74.93817540399994, 10.302705711000044],
              [-74.93817443899991, 10.30270302200006 ],
              [-74.93817346299994, 10.302700320000042],
              [-74.9381724989999 , 10.302697631000058],
              [-74.93817152699995, 10.30269492900004 ],
              [-74.93817056699993, 10.302692239000066],
              [-74.93816960099991, 10.30268953600006 ],
              [-74.93816863599994, 10.30268684500004 ],
              [-74.93816766899994, 10.302684147000093],
              [-74.93816670099994, 10.302681446000065],
              [-74.9381657369999 , 10.302678755000045],
              [-74.9381647809999 , 10.302676054000074],
              [-74.9381638129999 , 10.302673354000092],
              [-74.93816285899993, 10.302670653000064],
              [-74.93816189899991, 10.30266795500006 ],
              [-74.93816094499994, 10.302665252000054],
              [-74.93815998799994, 10.302662564000059],
              [-74.93815903099994, 10.302659863000088],
              [-74.93815807399994, 10.30265716200006 ],
              [-74.9381571159999 , 10.302654460000042],
              [-74.93815616099994, 10.30265176000006 ],
              [-74.9381552129999 , 10.30264905100006 ],
              [-74.93815425699995, 10.302646353000057],
              [-74.9381533099999 , 10.30264365000005 ],
              [-74.93815235299991, 10.302640952000047],
              [-74.93815140399994, 10.30263824900004 ],
              [-74.93815045499991, 10.302635550000048],
              [-74.93814950599995, 10.302632839000069],
              [-74.93814856199992, 10.302630141000066],
              [-74.93814761399994, 10.302627439000048],
              [-74.93814666599991, 10.302624730000048],
              [-74.93814572899993, 10.302622032000045],
              [-74.93814478099995, 10.302619322000055],
              [-74.93814384299992, 10.302616620000038],
              [-74.93814289499994, 10.302613915000052],
              [-74.93814195599992, 10.302611212000045],
              [-74.93814101699991, 10.302608502000055],
              [-74.93814008099991, 10.302605801000084],
              [-74.93813914099991, 10.302603090000048],
              [-74.93813820199995, 10.302600381000047],
              [-74.93813738799992, 10.30259804900004 ],
              [-74.9381372599999 , 10.302597683000045],
              [-74.9381363359999 , 10.302594973000055],
              [-74.9381353949999 , 10.302592264000054],
              [-74.9381344649999 , 10.302589555000054],
              [-74.93813352799992, 10.302586852000047],
              [-74.9381325949999 , 10.302584143000047],
              [-74.9381316699999 , 10.302581434000047],
              [-74.9381307299999 , 10.302578724000057],
              [-74.93812980199993, 10.302576015000056],
              [-74.93812887999991, 10.302573306000056],
              [-74.9381279509999 , 10.302570598000045],
              [-74.93812702199995, 10.302567888000056],
              [-74.93812609399993, 10.302565179000055],
              [-74.93812517299995, 10.302562470000055],
              [-74.9381242419999 , 10.30255975700004 ],
              [-74.93812331999993, 10.302557041000057],
              [-74.9381224039999 , 10.302554333000046],
              [-74.93812148399991, 10.302551623000056],
              [-74.93812056299993, 10.302548914000056],
              [-74.93811964299994, 10.302546195000048],
              [-74.93811872299995, 10.302543484000068],
              [-74.93811779999993, 10.302540776000058],
              [-74.93811687699991, 10.30253805700005 ],
              [-74.9381159699999 , 10.30253534700006 ],
              [-74.93811504699994, 10.302532631000076],
              [-74.93811413899994, 10.302529919000051],
              [-74.93811322899995, 10.302527200000043],
              [-74.93811230799992, 10.302524490000053],
              [-74.93811139699994, 10.302521770000055],
              [-74.9381104869999 , 10.30251905700004 ],
              [-74.93810957499994, 10.30251634700005 ],
              [-74.93810867399992, 10.302513627000053],
              [-74.9381077619999 , 10.302510907000055],
              [-74.93810685099993, 10.302508199000044],
              [-74.93810594799993, 10.302505477000068],
              [-74.93810503799995, 10.302502760000039],
              [-74.93810413599994, 10.302500045000045],
              [-74.93810323299994, 10.302497325000047],
              [-74.93810232599992, 10.30249460400006 ],
              [-74.93810142899991, 10.302491887000087],
              [-74.93810052399994, 10.302489171000047],
              [-74.93809962599994, 10.302486449000071],
              [-74.9380987209999 , 10.302483731000052],
              [-74.93809782099993, 10.302481012000044],
              [-74.93809692799994, 10.302478295000071],
              [-74.93809602199991, 10.302475576000063],
              [-74.93809513299993, 10.302472857000055],
              [-74.93809424099993, 10.302470138000047],
              [-74.9380933459999 , 10.302467412000055],
              [-74.93809244199991, 10.302464693000047],
              [-74.93809155299994, 10.30246197300005 ],
              [-74.93809066599994, 10.302459255000088],
              [-74.93808977599991, 10.302456525000082],
              [-74.93808888299992, 10.302453809000042],
              [-74.938088        , 10.30245108300005 ],
              [-74.93808710699994, 10.302448361000074],
              [-74.93808622299991, 10.302445634000037],
              [-74.93808533099991, 10.302442919000043],
              [-74.93808444699994, 10.302440188000048],
              [-74.93808356999995, 10.30243746800005 ],
              [-74.93808268999993, 10.302434738000045],
              [-74.93808181499992, 10.302432020000083],
              [-74.93808093899992, 10.302429287000052],
              [-74.93808006099994, 10.302426561000061],
              [-74.9380791879999 , 10.302423839000085],
              [-74.9380783119999 , 10.302421111000058],
              [-74.93807743499991, 10.302418381000052],
              [-74.9380765599999 , 10.302415655000061],
              [-74.93807567999994, 10.302412925000056],
              [-74.93807480999993, 10.302410205000058],
              [-74.93807392999992, 10.302407481000046],
              [-74.93807305999991, 10.30240475000005 ],
              [-74.93807218199993, 10.30240202400006 ],
              [-74.93807131199992, 10.302399301000037],
              [-74.93807043299995, 10.302396570000042],
              [-74.93806956199995, 10.30239384400005 ],
              [-74.93806869199994, 10.30239111800006 ],
              [-74.93806781399991, 10.30238838200006 ],
              [-74.93806694099993, 10.302385658000048],
              [-74.93806606599992, 10.302382933000047],
              [-74.9380651969999 , 10.302380204000087],
              [-74.93806432499991, 10.302377481000065],
              [-74.93806354199995, 10.30237502700004 ],
              [-74.9380625899999 , 10.302372017000039],
              [-74.93806172299992, 10.302369291000048],
              [-74.93806086699993, 10.302366550000045],
              [-74.93806000099994, 10.302363823000064],
              [-74.9380591449999 , 10.302361096000084],
              [-74.93805828899991, 10.302358358000049],
              [-74.93805742399991, 10.302355631000069],
              [-74.93805656899991, 10.302352897000048],
              [-74.93805571199994, 10.302350165000064],
              [-74.93805485099995, 10.302347430000054],
              [-74.93805400799994, 10.302344702000084],
              [-74.9380531509999 , 10.30234196400005 ],
              [-74.93805229299994, 10.30233922900004 ],
              [-74.93805144599992, 10.302336503000049],
              [-74.93805059199991, 10.30233376700005 ],
              [-74.9380497439999 , 10.302331028000083],
              [-74.93804889599994, 10.302328291000038],
              [-74.9380480399999 , 10.302325563000068],
              [-74.9380471899999 , 10.302322828000058],
              [-74.93804634399993, 10.302320087000055],
              [-74.93804550499993, 10.302317353000092],
              [-74.93804465999995, 10.302314620000061],
              [-74.93804381399991, 10.302311878000069],
              [-74.93804297299994, 10.302309144000048],
              [-74.93804212599991, 10.302306409000039],
              [-74.93804128799991, 10.302303670000072],
              [-74.93804044099994, 10.302300931000048],
              [-74.93803960399993, 10.302298197000084],
              [-74.93803876499993, 10.30229545900005 ],
              [-74.93803792699993, 10.302292722000061],
              [-74.93803708699994, 10.302289976000054],
              [-74.93803624999993, 10.302287241000045],
              [-74.93803541799991, 10.302284504000056],
              [-74.93803458499991, 10.302281769000047],
              [-74.93803374999993, 10.30227902100006 ],
              [-74.93803291599994, 10.302276282000037],
              [-74.9380320869999 , 10.302273546000038],
              [-74.93803125499994, 10.302270803000056],
              [-74.93803042699994, 10.302268068000046],
              [-74.93802960099993, 10.302265326000054],
              [-74.93802877199994, 10.302262580000047],
              [-74.9380279419999 , 10.302259844000048],
              [-74.93802711299992, 10.302257098000041],
              [-74.93802628299994, 10.30225435500006 ],
              [-74.93802546499995, 10.302251616000092],
              [-74.93802463499992, 10.302248871000074],
              [-74.93802381599994, 10.30224613300004 ],
              [-74.93802299499993, 10.30224338700009 ],
              [-74.93802217599995, 10.302240640000036],
              [-74.93802135499993, 10.302237903000048],
              [-74.9380205359999 , 10.302235161000056],
              [-74.93801971499994, 10.30223241300007 ],
              [-74.9380188959999 , 10.302229669000042],
              [-74.93801807299991, 10.302226920000066],
              [-74.93801725999992, 10.30222417400006 ],
              [-74.9380164449999 , 10.30222143900005 ],
              [-74.9380156329999 , 10.302218693000043],
              [-74.93801481899993, 10.302215945000057],
              [-74.93801401299993, 10.30221319900005 ],
              [-74.93801320199992, 10.302210457000058],
              [-74.93801239099992, 10.302207710000062],
              [-74.93801157699994, 10.302204964000055],
              [-74.93801077099994, 10.302202218000048],
              [-74.93800995999993, 10.302199461000043],
              [-74.93800915599991, 10.302196714000047],
              [-74.93800834599995, 10.302193972000055],
              [-74.93800754499995, 10.302191225000058],
              [-74.93800673499993, 10.302188479000051],
              [-74.93800593199995, 10.302185723000036],
              [-74.93800512999991, 10.30218297600004 ],
              [-74.9380043299999 , 10.302180234000048],
              [-74.93800352599993, 10.302177482000047],
              [-74.93800272399994, 10.302174730000047],
              [-74.9380019319999 , 10.30217198400004 ],
              [-74.93800113299994, 10.30216923100005 ],
              [-74.93800032999991, 10.302166484000054],
              [-74.93799953599995, 10.302163729000085],
              [-74.93799874599995, 10.302160985000057],
              [-74.93799794299991, 10.302158228000053],
              [-74.93799714999994, 10.302155481000057],
              [-74.93799635699992, 10.302152729000056],
              [-74.93799556199991, 10.302149970000073],
              [-74.93799478199992, 10.302147225000056],
              [-74.93799398899995, 10.302144474000045],
              [-74.93799319599992, 10.302141715000062],
              [-74.93799241399995, 10.302138974000059],
              [-74.9379916179999 , 10.302136218000044],
              [-74.93799083699992, 10.302133458000071],
              [-74.93799005499994, 10.30213070600007 ],
              [-74.93798926099993, 10.302127951000045],
              [-74.93798847699992, 10.302125204000049],
              [-74.9379876939999 , 10.302122452000049],
              [-74.93798691099994, 10.30211969600009 ],
              [-74.93798613699994, 10.302116939000086],
              [-74.93798535199994, 10.30211418500005 ],
              [-74.93798456699994, 10.302111428000046],
              [-74.9379837919999 , 10.302108676000046],
              [-74.93798301899994, 10.302105918000052],
              [-74.93798223899995, 10.302103165000062],
              [-74.9379814589999 , 10.302100409000047],
              [-74.93798068699994, 10.30209764600005 ],
              [-74.93797991299994, 10.302094890000092],
              [-74.93797914099991, 10.302092136000056],
              [-74.93797836499994, 10.30208938000004 ],
              [-74.93797760099994, 10.302086626000062],
              [-74.93797682799993, 10.302083860000039],
              [-74.93797606099992, 10.30208110600006 ],
              [-74.93797528899995, 10.30207835300007 ],
              [-74.93797452199993, 10.302075586000058],
              [-74.9379737559999 , 10.302072830000043],
              [-74.93797299199991, 10.302070077000053],
              [-74.9379722189999 , 10.302067314000055],
              [-74.9379714609999 , 10.30206455800004 ],
              [-74.9379706929999 , 10.302061795000043],
              [-74.93796993099994, 10.302059037000049],
              [-74.93796916499991, 10.302056274000051],
              [-74.93796840999994, 10.302053519000083],
              [-74.9379676399999 , 10.30205075300006 ],
              [-74.93796688799995, 10.302047990000062],
              [-74.93796613299992, 10.302045238000062],
              [-74.9379653769999 , 10.30204247000006 ],
              [-74.93796461699992, 10.302039709000042],
              [-74.93796386399993, 10.302036952000037],
              [-74.9379631079999 , 10.30203418900004 ],
              [-74.93796235199994, 10.302031425000052],
              [-74.93796160599993, 10.302028662000055],
              [-74.93796084699994, 10.302025896000089],
              [-74.93796010199992, 10.302023140000074],
              [-74.93795934099995, 10.30202037500004 ],
              [-74.93795859599993, 10.30201761300009 ],
              [-74.93795784999992, 10.302014848000056],
              [-74.93795710499995, 10.302012085000058],
              [-74.9379563569999 , 10.302009321000071],
              [-74.9379556099999 , 10.302006555000048],
              [-74.9379548629999 , 10.302003791000061],
              [-74.93795411899993, 10.302001028000063],
              [-74.9379533739999 , 10.30199826200004 ],
              [-74.93795262999993, 10.301995498000053],
              [-74.9379518959999 , 10.301992726000037],
              [-74.93795115599994, 10.30198996200005 ],
              [-74.9379504179999 , 10.301987198000063],
              [-74.93794968199995, 10.30198443200004 ],
              [-74.93794894099995, 10.301981669000043],
              [-74.93794820699992, 10.301978900000051],
              [-74.9379474669999 , 10.30197613200005 ],
              [-74.9379467309999 , 10.301973369000052],
              [-74.93794599899991, 10.301970595000057],
              [-74.93794526199991, 10.30196783200006 ],
              [-74.93794453399994, 10.301965066000037],
              [-74.93794379699995, 10.301962293000088],
              [-74.93794306799992, 10.301959529000044],
              [-74.93794233799991, 10.30195675400006 ],
              [-74.93794161199992, 10.301953991000062],
              [-74.93794088299995, 10.301951218000056],
              [-74.93794015399993, 10.301948456000048],
              [-74.93793943299994, 10.301945681000063],
              [-74.93793870299993, 10.30194291500004 ],
              [-74.93793798399992, 10.30194014500006 ],
              [-74.93793725699993, 10.30193736800004 ],
              [-74.93793653799992, 10.301934606000088],
              [-74.93793581299991, 10.301931833000083],
              [-74.93793508899995, 10.301929059000088],
              [-74.93793437099993, 10.301926298000069],
              [-74.93793364899994, 10.301923521000049],
              [-74.93793293899995, 10.30192074900009 ],
              [-74.93793221999994, 10.301917975000038],
              [-74.93793150199991, 10.301915202000089],
              [-74.93793078999994, 10.301912427000048],
              [-74.93793007099993, 10.30190966300006 ],
              [-74.93792935999994, 10.301906895000059],
              [-74.93792864999995, 10.301904118000039],
              [-74.9379279339999 , 10.301901343000054],
              [-74.93792722799992, 10.301898571000038],
              [-74.93792651799993, 10.30189579800009 ],
              [-74.93792580599995, 10.301893023000048],
              [-74.93792509899993, 10.301890249000053],
              [-74.93792438499992, 10.301887470000054],
              [-74.93792368699991, 10.301884697000048],
              [-74.93792297199991, 10.301881922000064],
              [-74.93792227499995, 10.301879149000058],
              [-74.93792157199994, 10.301876376000052],
              [-74.93792086899992, 10.301873603000047],
              [-74.93792016899994, 10.301870819000044],
              [-74.93791946999994, 10.301868048000074],
              [-74.93791876799992, 10.301865277000047],
              [-74.93791806199994, 10.301862499000038],
              [-74.93791736399993, 10.30185971900005 ],
              [-74.9379166729999 , 10.301856946000044],
              [-74.93791596999995, 10.301854160000062],
              [-74.93791527999991, 10.30185139200006 ],
              [-74.93791458599992, 10.30184861400005 ],
              [-74.93791388699992, 10.301845835000051],
              [-74.93791319199994, 10.301843063000092],
              [-74.93791250099991, 10.301840277000053],
              [-74.93791180799991, 10.301837503000058],
              [-74.93791112699995, 10.301834725000049],
              [-74.93791043099992, 10.301831948000086],
              [-74.93790973699993, 10.301829166000061],
              [-74.9379090569999 , 10.301826383000048],
              [-74.93790837499995, 10.301823614000057],
              [-74.93790768299993, 10.301820827000086],
              [-74.93790699699991, 10.301818045000061],
              [-74.93790631699994, 10.301815272000056],
              [-74.93790563399995, 10.301812489000042],
              [-74.9379049499999 , 10.301809709000054],
              [-74.9379042679999 , 10.301806924000061],
              [-74.93790359299993, 10.301804153000091],
              [-74.93790290999993, 10.301801368000042],
              [-74.93790222299992, 10.301798587000064],
              [-74.9379015529999 , 10.301795806000086],
              [-74.93790087999992, 10.301793020000048],
              [-74.93790019499994, 10.30179023900007 ],
              [-74.93789952299994, 10.301787456000056],
              [-74.93789884699993, 10.301784678000047],
              [-74.9378981719999 , 10.301781893000054],
              [-74.93789750499991, 10.301779111000087],
              [-74.93789683499995, 10.301776326000038],
              [-74.93789616099991, 10.30177354600005 ],
              [-74.93789549699994, 10.301770762000046],
              [-74.93789481999994, 10.301767982000058],
              [-74.93789415599991, 10.301765199000045],
              [-74.9378934909999 , 10.301762413000063],
              [-74.93789282599994, 10.301759625000045],
              [-74.93789216199991, 10.301756839000063],
              [-74.9378914959999 , 10.301754058000085],
              [-74.93789083299993, 10.301751276000061],
              [-74.93789016699992, 10.301748484000086],
              [-74.93788951199991, 10.301745703000051],
              [-74.93788884799994, 10.301742919000048],
              [-74.93788818999991, 10.301740129000052],
              [-74.93788752299992, 10.30173734500005 ],
              [-74.93788687199992, 10.30173456500006 ],
              [-74.93788621599992, 10.30173177100005 ],
              [-74.9378855569999 , 10.301728991000061],
              [-74.93788490499992, 10.30172619700005 ],
              [-74.93788424999991, 10.301723417000062],
              [-74.9378835899999 , 10.301720623000051],
              [-74.93788294699993, 10.301717839000048],
              [-74.93788228999995, 10.301715049000052],
              [-74.93788164499995, 10.301712265000049],
              [-74.9378809879999 , 10.301709475000052],
              [-74.93788034399995, 10.301706685000056],
              [-74.93787969599992, 10.301703900000064],
              [-74.9378790479999 , 10.301701111000057],
              [-74.93787840199991, 10.30169832100006 ],
              [-74.93787775599992, 10.301695537000057],
              [-74.93787710899994, 10.301692747000061],
              [-74.93787646999994, 10.30168995200006 ],
              [-74.93787581999993, 10.301687171000083],
              [-74.93787518399995, 10.30168438000004 ],
              [-74.93787454099993, 10.301681590000044],
              [-74.9378739039999 , 10.301678795000043],
              [-74.93787326499995, 10.301676004000058],
              [-74.93787262899991, 10.30167321500005 ],
              [-74.93787199099995, 10.30167042100004 ],
              [-74.93787135399992, 10.301667631000043],
              [-74.93787071699995, 10.30166484700004 ],
              [-74.93787007999993, 10.301662057000044],
              [-74.93786944699991, 10.301659266000058],
              [-74.93786880899995, 10.301656471000058],
              [-74.93786818399991, 10.30165367300009 ],
              [-74.93786755499991, 10.301650880000068],
              [-74.93786691999992, 10.301648091000061],
              [-74.9378662869999 , 10.30164529700005 ],
              [-74.93786566099993, 10.301642506000064],
              [-74.93786503899992, 10.301639713000043],
              [-74.93786441399993, 10.301636922000057],
              [-74.93786378599992, 10.30163413300005 ],
              [-74.9378631539999 , 10.301631332000056],
              [-74.93786253799993, 10.301628537000056],
              [-74.9378133699999 , 10.301393197000039],
              [-74.93781282399993, 10.301390385000047],
              [-74.93781227499994, 10.301387575000092],
              [-74.93781173999992, 10.30138476600007 ],
              [-74.93781118999993, 10.301381957000046],
              [-74.93781064699994, 10.301379147000091],
              [-74.93781011099992, 10.301376335000043],
              [-74.9378095639999 , 10.301373528000056],
              [-74.93780902799995, 10.301370706000057],
              [-74.93780847799991, 10.301367897000091],
              [-74.93780794399993, 10.301365088000068],
              [-74.93780735099995, 10.301361970000073],
              [-74.93780675699992, 10.301358862000086],
              [-74.9378061669999 , 10.301355744000091],
              [-74.93780557199995, 10.301352627000085],
              [-74.9378049789999 , 10.301349512000058],
              [-74.93780438699991, 10.301346393000074],
              [-74.93780379399993, 10.301343279000037],
              [-74.9378032109999 , 10.301340157000084],
              [-74.93780261699993, 10.301337043000046],
              [-74.93780203499995, 10.301333925000051],
              [-74.93780145099993, 10.301330807000056],
              [-74.9378008679999 , 10.30132769000005 ],
              [-74.93780028399993, 10.301324574000091],
              [-74.93779969999991, 10.301321449000056],
              [-74.9377991259999 , 10.301318331000061],
              [-74.93779853799992, 10.301315214000056],
              [-74.93779796799993, 10.30131209600006 ],
              [-74.93779739399992, 10.301308980000044],
              [-74.9377968089999 , 10.301305851000052],
              [-74.9377962339999 , 10.30130273800006 ],
              [-74.93779566099994, 10.30129961700004 ],
              [-74.93779509299992, 10.30129650300006 ],
              [-74.93779452099994, 10.301293375000057],
              [-74.93779395399991, 10.301290257000062],
              [-74.93779338199994, 10.301287141000046],
              [-74.93779281499991, 10.301284014000089],
              [-74.9377922509999 , 10.301280896000037],
              [-74.93779168499992, 10.301277772000049],
              [-74.93779111899994, 10.301274653000064],
              [-74.93779054999993, 10.30127152600005 ],
              [-74.93778998899995, 10.301268408000055],
              [-74.93778943299992, 10.301265286000046],
              [-74.93778886699994, 10.301262166000072],
              [-74.93778831199995, 10.301259040000048],
              [-74.93778775499993, 10.301255922000053],
              [-74.93778719799991, 10.301252798000064],
              [-74.93778664199994, 10.301249670000061],
              [-74.9377860859999 , 10.301246552000066],
              [-74.93778552999993, 10.301243425000052],
              [-74.93778498199993, 10.301240301000064],
              [-74.93778442699994, 10.301237185000048],
              [-74.93778387899994, 10.301234058000091],
              [-74.93778333199992, 10.301230933000056],
              [-74.93778277599995, 10.301227803000074],
              [-74.93778222999993, 10.301224688000048],
              [-74.93778169099994, 10.301221564000059],
              [-74.93778114399993, 10.301218436000056],
              [-74.93778059599993, 10.301215309000042],
              [-74.93778005399992, 10.301212186000043],
              [-74.9377795119999 , 10.301209057000051],
              [-74.9377789699999 , 10.301205930000037],
              [-74.93777843599992, 10.30120280500006 ],
              [-74.93777789799992, 10.301199679000092],
              [-74.93777735499992, 10.30119655100009 ],
              [-74.93777682699994, 10.301193426000054],
              [-74.9377762929999 , 10.30119029900004 ],
              [-74.9377757549999 , 10.301187171000038],
              [-74.93777522699992, 10.30118404600006 ],
              [-74.93777469899993, 10.301180919000046],
              [-74.93777416899991, 10.301177796000047],
              [-74.93777363799995, 10.301174667000055],
              [-74.93777310999991, 10.301171540000041],
              [-74.93777258199992, 10.301168415000063],
              [-74.93777205299995, 10.301165282000056],
              [-74.93777153499991, 10.301162153000064],
              [-74.93777100499995, 10.30115902600005 ],
              [-74.93777048099992, 10.301155899000037],
              [-74.93776996499992, 10.30115276500004 ],
              [-74.93776944599995, 10.301149638000084],
              [-74.93776892699992, 10.301146513000049],
              [-74.93776840499993, 10.301143385000046],
              [-74.93776789599991, 10.30114025100005 ],
              [-74.93776737499991, 10.301137124000093],
              [-74.93776686599995, 10.30113398800006 ],
              [-74.93776634499994, 10.301130863000083],
              [-74.93776583499994, 10.301127738000048],
              [-74.93776532299995, 10.301124599000048],
              [-74.9377648119999 , 10.301121476000048],
              [-74.93776430299994, 10.301118338000038],
              [-74.93776380199995, 10.30111521300006 ],
              [-74.93776329099995, 10.301112076000038],
              [-74.93776278899992, 10.30110895100006 ],
              [-74.93776228799993, 10.301105815000085],
              [-74.93776177399991, 10.30110268900006 ],
              [-74.93776127399991, 10.301099553000086],
              [-74.93776077099994, 10.301096416000064],
              [-74.93776028199994, 10.301093291000086],
              [-74.9377597809999 , 10.301090156000043],
              [-74.93775927699994, 10.301087021000058],
              [-74.93775878699995, 10.301083894000044],
              [-74.93775828499992, 10.301080759000058],
              [-74.9377577919999 , 10.301077623000083],
              [-74.93775729899994, 10.301074486000061],
              [-74.93775680899995, 10.301071361000083],
              [-74.93775631599993, 10.301068225000051],
              [-74.93775583199994, 10.30106509300009 ],
              [-74.93775533999991, 10.301061957000059],
              [-74.9377548569999 , 10.301058819000048],
              [-74.93775436499993, 10.30105569500006 ],
              [-74.93775388099994, 10.301052556000059],
              [-74.9377533949999 , 10.301049422000062],
              [-74.93775291399993, 10.30104628500004 ],
              [-74.93775243099992, 10.301043151000044],
              [-74.9377519489999 , 10.301040016000059],
              [-74.93775147499991, 10.301036880000083],
              [-74.9377509919999 , 10.301033743000062],
              [-74.93775051599994, 10.301030611000044],
              [-74.93775004299994, 10.301027472000044],
              [-74.93774956699991, 10.301024338000047],
              [-74.93774909299992, 10.301021204000051],
              [-74.93774861899993, 10.301018067000086],
              [-74.93774814499994, 10.301014931000054],
              [-74.93774766999991, 10.30101178700005 ],
              [-74.93774720699992, 10.301008653000054],
              [-74.93774674199994, 10.301005516000089],
              [-74.9377462679999 , 10.301002381000046],
              [-74.93774580099995, 10.30099924700005 ],
              [-74.9377453379999 , 10.300996109000039],
              [-74.93774487399992, 10.300992965000091],
              [-74.93774440899995, 10.30098982700008 ],
              [-74.93774395299994, 10.300986692000038],
              [-74.93774348799991, 10.300983556000062],
              [-74.9377430319999 , 10.300980414000037],
              [-74.93774257099994, 10.300977279000051],
              [-74.93774211999994, 10.30097414100004 ],
              [-74.93774166399993, 10.300970997000093],
              [-74.9377412099999 , 10.30096786100006 ],
              [-74.9377407529999 , 10.300964729000043],
              [-74.93774029299993, 10.30096158200007 ],
              [-74.93773984599994, 10.300958446000038],
              [-74.9377393929999 , 10.300955300000055],
              [-74.93773894599991, 10.300952166000059],
              [-74.9377385009999 , 10.300949022000054],
              [-74.93773805399991, 10.300945887000069],
              [-74.93773760599993, 10.300942741000085],
              [-74.93773715799995, 10.300939606000043],
              [-74.93773671299994, 10.300936462000038],
              [-74.9377362749999 , 10.300933327000052],
              [-74.93773582999995, 10.300930182000059],
              [-74.93773539099993, 10.300927045000037],
              [-74.93773494999994, 10.30092390100009 ],
              [-74.93773451499993, 10.30092075500005 ],
              [-74.93773407899994, 10.300917621000053],
              [-74.93773364099991, 10.300914477000049],
              [-74.93773320199995, 10.300911332000055],
              [-74.93773277699995, 10.300908198000059],
              [-74.93773233399992, 10.300905051000086],
              [-74.93773190899992, 10.300901906000092],
              [-74.9377314809999 , 10.30089877100005 ],
              [-74.93773105399993, 10.300895627000045],
              [-74.93773062399993, 10.300892482000052],
              [-74.93773018999991, 10.300889338000047],
              [-74.93772976699995, 10.300886191000075],
              [-74.9377293469999 , 10.300883054000053],
              [-74.93772891799995, 10.300879914000063],
              [-74.93772849599992, 10.300876766000044],
              [-74.93772807599993, 10.30087362200004 ],
              [-74.93772766099994, 10.30087048100006 ],
              [-74.93772724199994, 10.300867332000053],
              [-74.93772682199995, 10.300864189000038],
              [-74.93772640299994, 10.300861043000054],
              [-74.93772599199991, 10.30085789900005 ],
              [-74.93772557199992, 10.300854765000054],
              [-74.9377251599999 , 10.30085162000006 ],
              [-74.93772475199995, 10.300848476000056],
              [-74.9377243429999 , 10.300845329000083],
              [-74.93772393299992, 10.30084218400009 ],
              [-74.93772351799993, 10.300839033000045],
              [-74.93772311299995, 10.300835887000062],
              [-74.93772270099993, 10.300832743000058],
              [-74.9377222999999 , 10.300829599000053],
              [-74.93772189899994, 10.300826451000091],
              [-74.93772148499994, 10.300823304000062],
              [-74.93772108799993, 10.300820166000051],
              [-74.93772068499993, 10.300817016000053],
              [-74.93772028599994, 10.30081387100006 ],
              [-74.93771989399994, 10.300810718000037],
              [-74.93771949199993, 10.300807574000089],
              [-74.93771909899993, 10.300804429000038],
              [-74.93771870099994, 10.300801283000055],
              [-74.93771830799994, 10.300798139000051],
              [-74.93771791499995, 10.300794986000085],
              [-74.93771752299995, 10.300791840000045],
              [-74.93771713199993, 10.300788695000051],
              [-74.93771673799995, 10.300785545000053],
              [-74.93771635799993, 10.30078240000006 ],
              [-74.93771596599993, 10.300779253000087],
              [-74.93771558299994, 10.300776101000054],
              [-74.93771518999995, 10.30077295600006 ],
              [-74.9377148069999 , 10.300769809000087],
              [-74.93771442099995, 10.300766656000064],
              [-74.93771403999995, 10.30076351300005 ],
              [-74.9377136679999 , 10.300760369000045],
              [-74.93771327999991, 10.300757212000065],
              [-74.93771291099995, 10.30075406700007 ],
              [-74.9377125279999 , 10.300750914000048],
              [-74.93771215399994, 10.300747769000054],
              [-74.93771177999992, 10.300744615000042],
              [-74.93771140599995, 10.300741469000059],
              [-74.9377110289999 , 10.300738319000061],
              [-74.93771066099993, 10.300735171000042],
              [-74.93771028399993, 10.300732017000087],
              [-74.93770991899993, 10.300728871000047],
              [-74.93770954599995, 10.300725721000049],
              [-74.93770918299992, 10.300722565000058],
              [-74.93770881799992, 10.300719421000053],
              [-74.9377084539999 , 10.300716267000041],
              [-74.93770808699992, 10.300713120000069],
              [-74.93770772099992, 10.300709966000056],
              [-74.93770736699992, 10.300706812000044],
              [-74.93770700199991, 10.300703673000044],
              [-74.93770664999994, 10.300700516000063],
              [-74.9377062829999 , 10.30069736200005 ],
              [-74.9377059279999 , 10.300694217000057],
              [-74.93770557199991, 10.300691063000045],
              [-74.9377052179999 , 10.300687908000043],
              [-74.93770485999994, 10.300684753000041],
              [-74.93770451599994, 10.300681609000037],
              [-74.93770415999995, 10.30067845800005 ],
              [-74.93770381299993, 10.300675303000048],
              [-74.93770345899992, 10.300672148000046],
              [-74.93770310999992, 10.30066899500008 ],
              [-74.93770276599992, 10.300665848000051],
              [-74.9377024179999 , 10.300662694000039],
              [-74.9377020739999 , 10.300659542000062],
              [-74.93770173599995, 10.30065638700006 ],
              [-74.93770138799994, 10.300653232000059],
              [-74.93770104899994, 10.300650079000093],
              [-74.93770070799991, 10.300646928000049],
              [-74.93770036899991, 10.300643773000047],
              [-74.93770003199995, 10.300640617000056],
              [-74.9376996929999 , 10.300637473000052],
              [-74.93769935299991, 10.30063431900004 ],
              [-74.93769902999992, 10.300631165000084],
              [-74.93769868999993, 10.30062801300005 ],
              [-74.93769836199994, 10.30062485600007 ],
              [-74.93769802399993, 10.300621703000047],
              [-74.93769769799991, 10.300618549000092],
              [-74.93769736999991, 10.300615397000058],
              [-74.93769704099992, 10.30061223100006 ],
              [-74.93769670899991, 10.300609078000093],
              [-74.93769639499993, 10.30060592600006 ],
              [-74.93769606599994, 10.300602772000047],
              [-74.93769573799995, 10.300599617000046],
              [-74.9376954199999 , 10.300596460000065],
              [-74.93769510099992, 10.300593307000042],
              [-74.9376947799999 , 10.300590157000045],
              [-74.93769445799995, 10.300587         ],
              [-74.93769414399992, 10.300583844000073],
              [-74.93769382199991, 10.300580683000078],
              [-74.93769351499992, 10.300577531000044],
              [-74.93769319599994, 10.300574376000043],
              [-74.93769288499993, 10.300571221000041],
              [-74.93769257399993, 10.300568067000086],
              [-74.93769226699993, 10.300564905000044],
              [-74.93769195699991, 10.300561748000064],
              [-74.9376916459999 , 10.300558594000051],
              [-74.93769133699993, 10.30055544000004 ],
              [-74.93769102699991, 10.300552279000044],
              [-74.93769072499992, 10.300549124000042],
              [-74.93769042699995, 10.300545971000076],
              [-74.93769011699993, 10.300542808000046],
              [-74.93768981499994, 10.300539653000044],
              [-74.93768951299995, 10.300536499000088],
              [-74.93768921199995, 10.300533337000047],
              [-74.93768892199995, 10.300530182000045],
              [-74.93768862199994, 10.300527026000054],
              [-74.93768832099994, 10.300523866000049],
              [-74.93768802799991, 10.300520711000047],
              [-74.9376877389999 , 10.300517556000045],
              [-74.93768744599993, 10.30051439400006 ],
              [-74.93768715399995, 10.300511241000038],
              [-74.9376868629999 , 10.300508076000085],
              [-74.93768657099992, 10.300504923000062],
              [-74.93768628799995, 10.300501758000053],
              [-74.9376859969999 , 10.300498605000087],
              [-74.9376857119999 , 10.300495443000045],
              [-74.93768542299995, 10.300492286000065],
              [-74.93768514099992, 10.30048912500007 ],
              [-74.93768485599992, 10.300485971000057],
              [-74.93768457199991, 10.300482807000037],
              [-74.93768430099993, 10.30047965500006 ],
              [-74.93768401599993, 10.300476489000062],
              [-74.93768373799992, 10.300473336000039],
              [-74.93768346099995, 10.300470174000054],
              [-74.9376831909999 , 10.300467019000052],
              [-74.9376829169999 , 10.300463859000047],
              [-74.9376826429999 , 10.300460703000056],
              [-74.93768237199993, 10.300457538000046],
              [-74.93768209599995, 10.300454376000062],
              [-74.93768181999991, 10.30045122100006 ],
              [-74.93768155999993, 10.300448058000086],
              [-74.93768128699992, 10.300444896000045],
              [-74.93768102099995, 10.300441740000053],
              [-74.93768075799994, 10.300438579000058],
              [-74.93768049099992, 10.30043541300006 ],
              [-74.9376802299999 , 10.300432259000047],
              [-74.93767996199995, 10.300429097000062],
              [-74.93767970999994, 10.300425933000042],
              [-74.9376794449999 , 10.30042277800004 ],
              [-74.93767918999993, 10.300419617000045],
              [-74.93767892699992, 10.300416455000061],
              [-74.9376786709999 , 10.30041330000006 ],
              [-74.93767841599993, 10.30041013400006 ],
              [-74.93767815799993, 10.300406974000055],
              [-74.93767791399995, 10.300403808000056],
              [-74.93767765599995, 10.30040064700006 ],
              [-74.93767740499993, 10.300397493000048],
              [-74.9376771549999 , 10.300394329000085],
              [-74.93767691099993, 10.300391166000054],
              [-74.93767665799993, 10.300388005000059],
              [-74.93767640899995, 10.300384841000039],
              [-74.93767616499991, 10.300381685000048],
              [-74.9376759299999 , 10.300378520000038],
              [-74.93767568299995, 10.300375358000053],
              [-74.93767543199993, 10.300372196000069],
              [-74.93767519999994, 10.30036903100006 ],
              [-74.93767496399994, 10.300365869000075],
              [-74.9376747259999 , 10.300362706000044],
              [-74.93767449099994, 10.300359551000042],
              [-74.93767425199991, 10.300356388000068],
              [-74.93767401699995, 10.300353224000048],
              [-74.93767377799992, 10.300350063000053],
              [-74.93767354699992, 10.300346897000054],
              [-74.93767331399994, 10.300343736000059],
              [-74.93767308699995, 10.30034057100005 ],
              [-74.93767285699994, 10.300337411000044],
              [-74.93767263199993, 10.300334245000045],
              [-74.9376724039999 , 10.300331085000039],
              [-74.9376721779999 , 10.300327917000061],
              [-74.93767194799995, 10.300324757000055],
              [-74.93767173099991, 10.300321592000046],
              [-74.93767149999991, 10.300318430000061],
              [-74.93767128299993, 10.300315265000052],
              [-74.93767106399991, 10.300312104000056],
              [-74.93767084699994, 10.300308941000083],
              [-74.93767062799992, 10.300305775000083],
              [-74.93767041099994, 10.300302610000074],
              [-74.93767019099994, 10.300299446000054],
              [-74.93766998099994, 10.300296286000048],
              [-74.93766976199993, 10.300293122000085],
              [-74.93766955299992, 10.300289959000054],
              [-74.93766934199994, 10.300286794000044],
              [-74.93766912599995, 10.300283623000041],
              [-74.93766892299993, 10.300280463000092],
              [-74.93766871399993, 10.300277295000058],
              [-74.93766850199995, 10.300274134000063],
              [-74.93766829499992, 10.300270968000063],
              [-74.93766809599992, 10.300267808000058],
              [-74.93766789299991, 10.300264643000048],
              [-74.93766768599994, 10.300261479000085],
              [-74.9376674849999 , 10.300258307000092],
              [-74.93766728299994, 10.300255145000051],
              [-74.93766709199991, 10.300251980000041],
              [-74.9376668889999 , 10.300248818000057],
              [-74.93766669399992, 10.300245655000083],
              [-74.93766650299995, 10.300242481000055],
              [-74.93766630199991, 10.30023932100005 ],
              [-74.93766611099994, 10.30023615400006 ],
              [-74.93766591899993, 10.300232994000055],
              [-74.93766572999994, 10.300229828000056],
              [-74.93766553299992, 10.300226656000063],
              [-74.93766535499992, 10.300223492000043],
              [-74.93766516399995, 10.300220330000059],
              [-74.93766497999991, 10.30021716500005 ],
              [-74.93766478599991, 10.300213993000057],
              [-74.93766460899991, 10.300210828000047],
              [-74.9376644269999 , 10.300207665000073],
              [-74.93766424499995, 10.300204502000042],
              [-74.93766406199995, 10.30020133000005 ],
              [-74.93766387999995, 10.300198166000087],
              [-74.93766370799995, 10.300195003000056],
              [-74.93766352599994, 10.300191831000063],
              [-74.93766334999992, 10.300188665000064],
              [-74.93766317899991, 10.300185506000048],
              [-74.93766300599992, 10.300182331000087],
              [-74.93766283299993, 10.300179169000046],
              [-74.93766265799991, 10.300176002000057],
              [-74.93766248799994, 10.300172832000044],
              [-74.9376623149999 , 10.300169671000049],
              [-74.93766215099993, 10.30016650400006 ],
              [-74.9376619869999 , 10.300163333000057],
              [-74.93766181399991, 10.300160169000037],
              [-74.93766165099993, 10.300157007000053],
              [-74.93766148599991, 10.30015383500006 ],
              [-74.93766132299993, 10.30015067000005 ],
              [-74.93766116699993, 10.300147499000047],
              [-74.93766100499994, 10.300144332000059],
              [-74.93766084099991, 10.300141171000064],
              [-74.93766068399992, 10.300137997000093],
              [-74.93766052899991, 10.300134835000051],
              [-74.93766037599994, 10.300131663000059],
              [-74.93766021699992, 10.30012849800005 ],
              [-74.93766006699991, 10.30012533300004 ],
              [-74.9376599119999 , 10.300122162000036],
              [-74.9376597669999 , 10.300118999000063],
              [-74.93765960999991, 10.30011582800006 ],
              [-74.93765946699995, 10.30011266300005 ],
              [-74.93765930899991, 10.300109491000057],
              [-74.9376591649999 , 10.300106325000058],
              [-74.93765902099994, 10.300103154000055],
              [-74.93765887299992, 10.300099991000081],
              [-74.93765873799993, 10.300096818000043],
              [-74.9376585899999 , 10.300093656000058],
              [-74.93765844899991, 10.300090482000087],
              [-74.93765831099995, 10.300087319000056],
              [-74.9376581699999 , 10.300084147000064],
              [-74.93765803299993, 10.300080982000054],
              [-74.93765789899993, 10.300077811000051],
              [-74.93765776299995, 10.300074646000041],
              [-74.9376576279999 , 10.300071474000049],
              [-74.93765749099992, 10.30006830900004 ],
              [-74.93765736499995, 10.300065138000093],
              [-74.9376572349999 , 10.300061974000073],
              [-74.93765709899992, 10.300058801000091],
              [-74.93765697399994, 10.30005563900005 ],
              [-74.93765684599992, 10.300052467000057],
              [-74.93765671799991, 10.300049302000048],
              [-74.93765659199994, 10.300046130000055],
              [-74.93765647399994, 10.300042956000084],
              [-74.93765634599993, 10.300039793000053],
              [-74.93765622699993, 10.30003662100006 ],
              [-74.93765610999992, 10.300033458000087],
              [-74.9376559829999 , 10.300030285000048],
              [-74.93765586299992, 10.30002711800006 ],
              [-74.93765575399993, 10.300023947000057],
              [-74.93765563399995, 10.300020775000064],
              [-74.93765551899992, 10.300017610000054],
              [-74.93765540999993, 10.300014441000087],
              [-74.93765529199993, 10.300011275000088],
              [-74.93765518299995, 10.30000810100006 ],
              [-74.9376550739999 , 10.300004929000067],
              [-74.93765496499992, 10.300001766000037],
              [-74.9376548539999 , 10.299998593000055],
              [-74.93765474499992, 10.29999543200006 ],
              [-74.93765463699992, 10.299992257000042],
              [-74.93765453499992, 10.299989087000085],
              [-74.93765442799992, 10.29998592000004 ],
              [-74.93765432899994, 10.299982749000037],
              [-74.93765422399991, 10.299979577000045],
              [-74.93765412699992, 10.299976412000092],
              [-74.93765402799994, 10.299973237000074],
              [-74.93765392499995, 10.29997006900004 ],
              [-74.93765383499994, 10.299966902000051],
              [-74.93765373399992, 10.299963730000059],
              [-74.9376536449999 , 10.299960560000045],
              [-74.93765354599992, 10.299957393000057],
              [-74.9376534569999 , 10.299954221000064],
              [-74.93765336699994, 10.299951050000061],
              [-74.93765327499995, 10.299947885000051],
              [-74.9376531929999 , 10.299944713000059],
              [-74.93765309899993, 10.299941542000056],
              [-74.93765301199994, 10.299938377000046],
              [-74.9376529299999 , 10.299935205000054],
              [-74.93765284499995, 10.299932033000061],
              [-74.9376527579999 , 10.299928868000052],
              [-74.93765267399993, 10.29992569600006 ],
              [-74.93765259299994, 10.299922522000088],
              [-74.93765251899993, 10.29991934800006 ],
              [-74.93765243999991, 10.29991618400004 ],
              [-74.93765235899991, 10.299913012000047],
              [-74.93765228399991, 10.299909840000055],
              [-74.93765221299992, 10.29990667900006 ],
              [-74.9376521399999 , 10.299903503000053],
              [-74.93765205799991, 10.29990033100006 ],
              [-74.9376519939999 , 10.299897160000057],
              [-74.93765192199993, 10.299893995000048],
              [-74.93765184999995, 10.299890823000055],
              [-74.93765177799992, 10.299887647000048],
              [-74.9376517149999 , 10.299884487000043],
              [-74.93765164699994, 10.29988131500005 ],
              [-74.93765158799994, 10.299878138000054],
              [-74.93765152199995, 10.29987496700005 ],
              [-74.93765145599991, 10.299871802000041],
              [-74.93765139599992, 10.299868630000049],
              [-74.93765133199992, 10.299865458000056],
              [-74.93765126699992, 10.299861819000057],
              [-74.93765121299992, 10.299859121000054],
              [-74.93765115999992, 10.299855950000051],
              [-74.93765110499993, 10.299852774000044],
              [-74.93765104999994, 10.299849602000052],
              [-74.9376509949999 , 10.299846441000057],
              [-74.93765094199995, 10.299843266000039],
              [-74.93765120799992, 10.299840131000053],
              [-74.93765147199991, 10.299836992000053],
              [-74.93765164699994, 10.299833842000055],
              [-74.93765074599992, 10.299830585000052],
              [-74.93765069799991, 10.29982741300006 ],
              [-74.93765064399992, 10.299824237000053],
              [-74.93765060999993, 10.29982106500006 ],
              [-74.93765056199993, 10.29981790000005 ],
              [-74.93765051999992, 10.299814727000069],
              [-74.93765047399995, 10.299811556000066],
              [-74.93765043799993, 10.299808383000084],
              [-74.93765039899995, 10.299805220000053],
              [-74.93765035699994, 10.29980204800006 ],
              [-74.93765031799995, 10.299798871000064],
              [-74.9376502849999 , 10.299795700000061],
              [-74.93765025899995, 10.299792529000058],
              [-74.9376682219999 , 10.299660637000045],
              [-74.93768310699994, 10.29955134100004 ],
              [-74.9377066529999 , 10.299414724000087],
              [-74.9377361839999 , 10.299243387000047],
              [-74.9378189439999 , 10.29888931000005 ],
              [-74.93794250499991, 10.29843850800006 ],
              [-74.93806058099995, 10.298169053000038],
              [-74.93804851399995, 10.298141839000039],
              [-74.9380484749999 , 10.298141745000066],
              [-74.9379128349999 , 10.297824886000058],
              [-74.9377063949999 , 10.297284638000065],
              [-74.93743365699993, 10.296570887000087],
              [-74.93724196799991, 10.296069235000061],
              [-74.93721688099993, 10.29600358500005 ],
              [-74.93717158499993, 10.295815849000064],
              [-74.9371161489999 , 10.295734246000052],
              [-74.9370243699999 , 10.29559914400005 ],
              [-74.9369164819999 , 10.29539360800004 ],
              [-74.93675725499992, 10.295110514000044],
              [-74.93650483199991, 10.29472725100004 ],
              [-74.93627054599995, 10.294300218000046],
              [-74.93614693599994, 10.294130977000066],
              [-74.93607906499994, 10.29403804900005 ],
              [-74.93606273899991, 10.293994440000063],
              [-74.93605449299992, 10.293972409000048],
              [-74.93604533599995, 10.293947930000058],
              [-74.93593474999994, 10.293652447000056],
              [-74.93587426699992, 10.293413867000083],
              [-74.9358486129999 , 10.293211951000046],
              [-74.93578820599993, 10.293051418000061],
              [-74.93572386599993, 10.292920203000051],
              [-74.93570669099995, 10.292551264000053],
              [-74.9357413159999 , 10.292381402000046],
              [-74.93581070899995, 10.292227539000066],
              [-74.93588474299992, 10.292075925000063],
              [-74.93593100399994, 10.291988685000092],
              [-74.93600740799991, 10.291841659000056],
              [-74.93603980699993, 10.29177278900005 ],
              [-74.93620530299995, 10.291592679000075],
              [-74.93620533099994, 10.291592648000062],
              [-74.93623200199994, 10.291563618000055],
              [-74.93629686199995, 10.291448787000093],
              [-74.9363153189999 , 10.291338607000057],
              [-74.9363152119999 , 10.29124908600005 ],
              [-74.93631515699991, 10.291148169000053],
              [-74.93628260999992, 10.291086215000064],
              [-74.93621300599995, 10.291008292000072],
              [-74.93615965499993, 10.29095105600004 ],
              [-74.93610119499994, 10.290931833000059],
              [-74.93621588899993, 10.29071325600006 ],
              [-74.93656075899992, 10.29043444000007 ],
              [-74.93666873099994, 10.290347146000045],
              [-74.93666969199995, 10.290346369000076],
              [-74.93671273099994, 10.290311579000047],
              [-74.9366400909999 , 10.29021408400007 ],
              [-74.93664319099992, 10.29021165100005 ],
              [-74.93669688599994, 10.290169890000072],
              [-74.93670396199991, 10.29016438800005 ],
              [-74.93708051899995, 10.289871393000055],
              [-74.93708075899991, 10.28987120700009 ],
              [-74.9370808029999 , 10.289871137000091],
              [-74.9371748989999 , 10.289720730000056],
              [-74.9373268459999 , 10.289477855000086],
              [-74.93756611599991, 10.289856248000092],
              [-74.93788497499992, 10.290360508000049],
              [-74.9379428549999 , 10.290437333000057],
              [-74.93795423099994, 10.290452432000052],
              [-74.94077418299992, 10.294195394000042],
              [-74.94090072199992, 10.294363351000072],
              [-74.94139134599993, 10.295014563000052],
              [-74.9418390379999 , 10.295608791000063],
              [-74.9418787589999 , 10.295661513000084],
              [-74.9423237609999 , 10.296253100000058],
              [-74.94239611599994, 10.29634928900009 ],
              [-74.94263150499995, 10.296662214000037],
              [-74.94276876499993, 10.296844687000089],
              [-74.94331612499991, 10.297572346000038],
              [-74.94364060299995, 10.298003704000052],
              [-74.94445453999992, 10.299085743000091],
              [-74.94468604299993, 10.299393499000075],
              [-74.94487471299993, 10.29964431600007 ],
              [-74.94503348699993, 10.299855387000036],
              [-74.94516418199993, 10.300029131000088],
              [-74.9453517529999 , 10.300278485000092],
              [-74.94561440099994, 10.300617394000085],
              [-74.94566692399991, 10.300685164000072],
              [-74.9457493299999 , 10.30079149800008 ],
              [-74.94629904499993, 10.30150081700009 ],
              [-74.94675481599995, 10.30208891600006 ],
              [-74.94715388199995, 10.302603846000068],
              [-74.9478032749999 , 10.303441786000064],
              [-74.94789154499995, 10.30355568300007 ],
              [-74.94974017499993, 10.305941050000058],
              [-74.9501448019999 , 10.306463155000074],
              [-74.95038722399994, 10.306775964000053],
              [-74.9506865159999 , 10.30716399000005 ],
              [-74.95527210699993, 10.313109116000078],
              [-74.95559665299993, 10.313529883000058],
              [-74.95601401999994, 10.314073790000066],
              [-74.95605682299993, 10.314129568000055],
              [-74.95670162399995, 10.31496985800004 ],
              [-74.95734901199995, 10.31581351400007 ],
              [-74.95738716499994, 10.315863234000062],
              [-74.9577283889999 , 10.316307903000052],
              [-74.95788060299992, 10.316112178000083],
              [-74.95929907699991, 10.318137543000091],
              [-74.95976037699995, 10.318796210000073],
              [-74.9609263129999 , 10.320195333000072],
              [-74.96228736499995, 10.322274982000067],
              [-74.96275390799991, 10.323012770000048],
              [-74.96344196799993, 10.324100867000084],
              [-74.96351516599992, 10.324216621000062],
              [-74.9641387609999 , 10.324983065000083],
              [-74.9641721719999 , 10.325024130000088],
              [-74.96440761899993, 10.325313512000037],
              [-74.96459544299995, 10.325544361000084],
              [-74.96525217299995, 10.326115432000051],
              [-74.96661165799992, 10.326933670000074],
              [-74.96661182399993, 10.32693377000004 ],
              [-74.9668927589999 , 10.327102857000057],
              [-74.96728423299993, 10.32733847500009 ],
              [-74.96724818199993, 10.327401559000066],
              [-74.96777787999991, 10.327790924000055],
              [-74.96782045099991, 10.327822218000051],
              [-74.96853132599995, 10.32834476000005 ],
              [-74.9701777169999 , 10.329554962000088],
              [-74.97034951299992, 10.329681243000039],
              [-74.97372747499992, 10.333070176000092],
              [-74.97494803799992, 10.334294706000037],
              [-74.97633090199992, 10.335861743000066],
              [-74.97798623199992, 10.337737534000041],
              [-74.97853776699992, 10.338362524000047],
              [-74.97885175699992, 10.338718330000063],
              [-74.97954842399992, 10.33950777800004 ],
              [-74.9831932909999 , 10.343352721000088],
              [-74.98449355899993, 10.344205769000041],
              [-74.98586467399991, 10.34474434200007 ],
              [-74.98587666799995, 10.344749053000044],
              [-74.9865767249999 , 10.345024035000051],
              [-74.98709214299993, 10.345226491000062],
              [-74.98977974099995, 10.347103635000053],
              [-74.9924693509999 , 10.349666117000083],
              [-75.00019109399994, 10.356926043000044],
              [-75.00258754499993, 10.35890441500004 ],
              [-75.00287987299993, 10.359145743000056],
              [-75.00425761299994, 10.359879512000077],
              [-75.00495973299991, 10.360253454000087],
              [-75.01154067899995, 10.36194781000006 ],
              [-75.0128257849999 , 10.362216557000067],
              [-75.01760125599992, 10.363215209000089],
              [-75.02158192599995, 10.363374884000052],
              [-75.0240917779999 , 10.363621330000058],
              [-75.02409186599994, 10.363621347000048],
              [-75.02555301199993, 10.363889208000046],
              [-75.02577567499992, 10.363930026000048],
              [-75.02773029799994, 10.364703217000056],
              [-75.02949388399992, 10.36581682600007 ],
              [-75.03009505599994, 10.366196432000038],
              [-75.03024546499995, 10.366315851000081],
              [-75.0308979969999 , 10.366833922000069],
              [-75.0326654619999 , 10.368237174000058],
              [-75.03343861899992, 10.368850999000074],
              [-75.0332514129999 , 10.369112248000079],
              [-75.03319496299991, 10.369191028000046],
              [-75.03303111499991, 10.369361816000037],
              [-75.03300217299994, 10.369391983000071],
              [-75.03298401299992, 10.36941586100005 ],
              [-75.03292036499994, 10.369499562000044],
              [-75.03286404599993, 10.369659076000062],
              [-75.03283337199991, 10.369745969000064],
              [-75.03280311099991, 10.369831691000059],
              [-75.03277822799993, 10.369901083000059],
              [-75.0326595279999 , 10.369918234000068],
              [-75.03250692199992, 10.369940285000041],
              [-75.03216779799993, 10.369991689000074],
              [-75.03195216899991, 10.370011524000063],
              [-75.0317819469999 , 10.370009225000047],
              [-75.03152651499994, 10.370017889000053],
              [-75.03146509299995, 10.370022581000057],
              [-75.03139158499994, 10.370030671000052],
              [-75.03119618199992, 10.370052176000058],
              [-75.03088840999993, 10.370055492000063],
              [-75.03069199499993, 10.370017690000054],
              [-75.03043739999993, 10.369972861000065],
              [-75.03009794999991, 10.369918687000052],
              [-75.02995250799995, 10.369891367000037],
              [-75.0297584779999 , 10.369854917000055],
              [-75.02957657699994, 10.36980986900005 ],
              [-75.02942865499995, 10.369779118000054],
              [-75.02934277999992, 10.369767958000068],
              [-75.02910378899992, 10.369736899000088],
              [-75.02879349299991, 10.369706628000074],
              [-75.02843719299995, 10.36968849300007 ],
              [-75.02800081799995, 10.369637006000062],
              [-75.02739695899993, 10.369626849000042],
              [-75.02739492299992, 10.369626814000071],
              [-75.02739294499992, 10.369626611000058],
              [-75.0272591669999 , 10.369612819000054],
              [-75.02701184999995, 10.369570366000062],
              [-75.02680852299994, 10.36956153500006 ],
              [-75.02651263699994, 10.369576651000045],
              [-75.02641826099995, 10.36959627400006 ],
              [-75.02637216699992, 10.369605858000057],
              [-75.02633020699994, 10.369589285000075],
              [-75.02617320899992, 10.36952726900006 ],
              [-75.02612140899993, 10.369511585000055],
              [-75.02590637999992, 10.369446482000058],
              [-75.02563961999994, 10.369384891000038],
              [-75.02536804199991, 10.369335309000064],
              [-75.02503590299995, 10.369293105000054],
              [-75.02473289399995, 10.369267611000055],
              [-75.02442271299992, 10.369273330000055],
              [-75.0240786789999 , 10.369305542000063],
              [-75.02369588199991, 10.369340266000052],
              [-75.02339797499991, 10.369398735000061],
              [-75.0230782939999 , 10.369469267000056],
              [-75.02306853099992, 10.369473073000051],
              [-75.02268131599993, 10.369624         ],
              [-75.02246594099995, 10.369727814000044],
              [-75.02233287099995, 10.36980019400005 ],
              [-75.02221173299995, 10.369812546000048],
              [-75.02204452699993, 10.36981544200006 ],
              [-75.02190173999992, 10.369880647000059],
              [-75.0217524059999 , 10.370000608000055],
              [-75.02175184499993, 10.370001057000081],
              [-75.0215464229999 , 10.370188822000046],
              [-75.02141369199995, 10.370376369000041],
              [-75.02128824099992, 10.370563893000053],
              [-75.0211940829999 , 10.370681742000045],
              [-75.0210787609999 , 10.370813503000079],
              [-75.0210298529999 , 10.37086938200008 ],
              [-75.0209259579999 , 10.370972865000056],
              [-75.02089651099993, 10.370985628000085],
              [-75.02074201099992, 10.371052588000055],
              [-75.0206404619999 , 10.371129668000037],
              [-75.02061404299991, 10.371208927000055],
              [-75.02062424899992, 10.371379258000047],
              [-75.02067117699994, 10.371671844000048],
              [-75.02069637299991, 10.371990896000057],
              [-75.02073094499991, 10.372204341000042],
              [-75.0207921999999 , 10.372427301000073],
              [-75.02090906199993, 10.372604513000056],
              [-75.02104045099992, 10.372776885000064],
              [-75.0213005789999 , 10.373047246000056],
              [-75.02145603499991, 10.373164357000064],
              [-75.02172078299992, 10.373360327000057],
              [-75.0218761829999 , 10.373458245000052],
              [-75.02200480699992, 10.373517849000052],
              [-75.02215511199995, 10.373534199000062],
              [-75.02249933099995, 10.373561975000086],
              [-75.02250115599992, 10.37356223100005 ],
              [-75.02263510099993, 10.373580768000068],
              [-75.02287755599991, 10.373616041000048],
              [-75.0230278969999 , 10.37364438800006 ],
              [-75.02318788799994, 10.373658309000064],
              [-75.0233442249999 , 10.373665305000088],
              [-75.02338906299991, 10.373667313000055],
              [-75.02337353099995, 10.373890363000044],
              [-75.0233658379999 , 10.374000902000091],
              [-75.0233429889999 , 10.374459254000044],
              [-75.0233035839999 , 10.374882281000055],
              [-75.02330227099992, 10.374896383000078],
              [-75.02329134799993, 10.37501367100009 ],
              [-75.02327252499992, 10.375282324000068],
              [-75.02324359799991, 10.375651205000054],
              [-75.02322987999992, 10.375826085000085],
              [-75.0232250709999 , 10.375887472000045],
              [-75.02312001899992, 10.377000206000048],
              [-75.02310391399993, 10.377107594000051],
              [-75.02290043099993, 10.377176502000054],
              [-75.02279135099991, 10.377237896000054],
              [-75.02269708499995, 10.377290952000067],
              [-75.02254620399992, 10.377372715000092],
              [-75.02247236499994, 10.377407876000063],
              [-75.0222575059999 , 10.377510186000052],
              [-75.02208036999991, 10.377601784000092],
              [-75.02183442499995, 10.377752135000037],
              [-75.0216277689999 , 10.37786009100006 ],
              [-75.02138522399991, 10.378049462000092],
              [-75.02118517899993, 10.378170404000059],
              [-75.0209031199999 , 10.378330620000042],
              [-75.0204340109999 , 10.37856295000006 ],
              [-75.02018788899994, 10.378658009000048],
              [-75.01999093799992, 10.378713883000046],
              [-75.01970849399993, 10.378747246000046],
              [-75.01947531299993, 10.378773953000064],
              [-75.01923233199994, 10.378820210000072],
              [-75.0188742869999 , 10.378840782000054],
              [-75.01850296999993, 10.378819111000041],
              [-75.01819397199995, 10.378761474000044],
              [-75.01810159399992, 10.378683040000055],
              [-75.01800601299993, 10.37861035900005 ],
              [-75.01790722699991, 10.378543422000064],
              [-75.01780523799994, 10.378482233000057],
              [-75.01775768999994, 10.378456701000061],
              [-75.01770703099993, 10.378431902000045],
              [-75.01765281399992, 10.378407610000068],
              [-75.01759418199993, 10.37838344000005 ],
              [-75.0174698109999 , 10.37833725300004 ],
              [-75.01725732599994, 10.378265027000054],
              [-75.01709150199991, 10.378211556000053],
              [-75.01691305799994, 10.378160268000045],
              [-75.01671881199991, 10.378110208000066],
              [-75.01649896399994, 10.378058851000048],
              [-75.01633735299993, 10.378025815000058],
              [-75.01615579399993, 10.377994778000073],
              [-75.01598298399995, 10.377969177000068],
              [-75.0155829979999 , 10.37791412200005 ],
              [-75.01539340499994, 10.377884951000055],
              [-75.01445686699992, 10.37772993100009 ],
              [-75.01415931299994, 10.37767845600007 ],
              [-75.01389211799994, 10.377630127000089],
              [-75.01362286799991, 10.377578766000056],
              [-75.01337023799994, 10.377527573000066],
              [-75.01313019199995, 10.37747573300004 ],
              [-75.01290054999993, 10.377422766000052],
              [-75.01275749899992, 10.377386533000049],
              [-75.01238003199995, 10.37728463700006 ],
              [-75.01226709199995, 10.377257262000057],
              [-75.01216506499992, 10.377235520000056],
              [-75.01206315899992, 10.377218288000051],
              [-75.01194847999994, 10.377204264000056],
              [-75.01183794299993, 10.377194276000068],
              [-75.01157144699994, 10.37717411300008 ],
              [-75.01143621699993, 10.377160250000088],
              [-75.01136939199995, 10.377151138000045],
              [-75.01130701299991, 10.377140785000051],
              [-75.01124804999995, 10.377129015000037],
              [-75.01119194899991, 10.377115721000052],
              [-75.01110556099991, 10.377090637000038],
              [-75.01101197799994, 10.37705784700006 ],
              [-75.01090940499995, 10.37701701800006 ],
              [-75.01069870099991, 10.376926929000092],
              [-75.01060771399995, 10.376889707000089],
              [-75.01051088999992, 10.376853896000057],
              [-75.01042282599991, 10.37682620600009 ],
              [-75.01020770399992, 10.376764267000055],
              [-75.01008467999992, 10.376733441000056],
              [-75.01002497199994, 10.376721338000038],
              [-75.00996892499995, 10.37671228000005 ],
              [-75.00991565399994, 10.376706115000047],
              [-75.00986467799993, 10.37670279300005 ],
              [-75.00978796999993, 10.376702521000084],
              [-75.0097078789999 , 10.37670745400004 ],
              [-75.0096241729999 , 10.376717606000057],
              [-75.0095364359999 , 10.376733021000064],
              [-75.00944495999994, 10.376753593000046],
              [-75.00939056899995, 10.376768207000055],
              [-75.00934794799991, 10.376779660000068],
              [-75.00924401799995, 10.376811579000048],
              [-75.0091284209999 , 10.376850800000057],
              [-75.0090849629999 , 10.376867188000062],
              [-75.0090403609999 , 10.376886116000037],
              [-75.00894572599992, 10.376932484000065],
              [-75.0088504549999 , 10.376985693000051],
              [-75.00866709099995, 10.377095429000065],
              [-75.00859048099994, 10.37713961500009 ],
              [-75.00850933299995, 10.377182699000059],
              [-75.00843490599993, 10.37721749700006 ],
              [-75.0082645139999 , 10.377289383000061],
              [-75.0081281759999 , 10.37734217600007 ],
              [-75.00800372499992, 10.377384456000073],
              [-75.00788823399995, 10.37741705500008 ],
              [-75.00782496699992, 10.37743034500005 ],
              [-75.00775778499991, 10.377439478000042],
              [-75.00768598699995, 10.377444557000047],
              [-75.00760817499992, 10.377445685000055],
              [-75.00753986799992, 10.377443757000037],
              [-75.0074632589999 , 10.377439224000057],
              [-75.0072125879999 , 10.37741731500006 ],
              [-75.00712284699995, 10.377410814000086],
              [-75.00710891299991, 10.377410248000047],
              [-75.00703696599993, 10.37740732800006 ],
              [-75.00696015599993, 10.37740758600006 ],
              [-75.00688511099992, 10.377411989000052],
              [-75.00681544199995, 10.377420739000058],
              [-75.00675011399994, 10.377433962000055],
              [-75.00668857499994, 10.377451785000062],
              [-75.00663477099994, 10.37747121700005 ],
              [-75.0065792879999 , 10.377493813000058],
              [-75.00652176199992, 10.377519721000056],
              [-75.00646152099995, 10.37754923600005 ],
              [-75.00633327299994, 10.377618579000057],
              [-75.00616049599995, 10.377720249000049],
              [-75.00610270599992, 10.377756894000072],
              [-75.00604001199991, 10.377800680000064],
              [-75.0058446669999 , 10.37794927400006 ],
              [-75.0057758769999 , 10.377999487000068],
              [-75.00571012699993, 10.378043468000044],
              [-75.0056485259999 , 10.378079969000055],
              [-75.00558070999995, 10.378116120000072],
              [-75.00551117799995, 10.378150769000058],
              [-75.0054401299999 , 10.378183806000038],
              [-75.00536779099991, 10.378215129000068],
              [-75.00529439999991, 10.378244640000048],
              [-75.00522019499994, 10.378272228000071],
              [-75.0051454469999 , 10.37829780900006 ],
              [-75.00507040199994, 10.378321288000052],
              [-75.00502316599994, 10.378333204000057],
              [-75.00497226099992, 10.378341848000048],
              [-75.00491720399992, 10.378347310000038],
              [-75.00485701899993, 10.378349687000082],
              [-75.00480146099994, 10.37834934600005 ],
              [-75.00473892199994, 10.378346867000062],
              [-75.00463228199993, 10.378339333000042],
              [-75.00452013099994, 10.378331409000054],
              [-75.00443602299993, 10.37832723300005 ],
              [-75.00435190899992, 10.37832701700006 ],
              [-75.0042789439999 , 10.378332022000052],
              [-75.00423834399993, 10.378337746000057],
              [-75.00420040799992, 10.378345547000038],
              [-75.00416487399991, 10.378355483000064],
              [-75.00413157299994, 10.378367600000047],
              [-75.00410032899993, 10.378381966000063],
              [-75.00407109199995, 10.378398615000037],
              [-75.0040437749999 , 10.378417592000062],
              [-75.00401831999994, 10.37843894200006 ],
              [-75.00396466199993, 10.37849301500006 ],
              [-75.00391341599993, 10.378553859000078],
              [-75.00388545599992, 10.37859461100004 ],
              [-75.00386283699993, 10.378637814000058],
              [-75.00384550699994, 10.378683566000063],
              [-75.0038334109999 , 10.378731997000045],
              [-75.00382652799993, 10.378783189000046],
              [-75.00382479299992, 10.378837432000068],
              [-75.00382820199991, 10.37889500600005 ],
              [-75.00383677399992, 10.37895633100004 ],
              [-75.00384724899993, 10.379007781000041],
              [-75.00386108299995, 10.379062374000057],
              [-75.00387841899993, 10.37912070100009 ],
              [-75.00389961999991, 10.37918393700005 ],
              [-75.00394367299992, 10.379301464000037],
              [-75.00405589899992, 10.379581520000045],
              [-75.00408268599995, 10.37965358300005 ],
              [-75.00410482899991, 10.379718298000057],
              [-75.00412417899992, 10.379781671000046],
              [-75.0041396279999 , 10.379840801000057],
              [-75.00415139699993, 10.379896547000044],
              [-75.00415958199994, 10.37994937700006 ],
              [-75.00417176699995, 10.380065861000048],
              [-75.0041808869999 , 10.380195938000043],
              [-75.00418676299995, 10.38032416100009 ],
              [-75.0041981299999 , 10.380652861000044],
              [-75.00420893499995, 10.380832764000047],
              [-75.00421781499995, 10.380927495000037],
              [-75.00422881299994, 10.381015750000074],
              [-75.00424213399992, 10.381099172000063],
              [-75.00425791899994, 10.381178606000049],
              [-75.00429049099995, 10.38130117500009 ],
              [-75.00430053799994, 10.381355618000043],
              [-75.00430357599993, 10.38139323200005 ],
              [-75.00430425499991, 10.381432722000056],
              [-75.00430257399995, 10.381473978000088],
              [-75.00429853899993, 10.38151683700005 ],
              [-75.00429214699994, 10.381561219000048],
              [-75.00428344999995, 10.381606734000059],
              [-75.00427250899992, 10.381653037000092],
              [-75.00425945199993, 10.381699579000042],
              [-75.00424842699994, 10.381731001000048],
              [-75.00423463099992, 10.381761569000048],
              [-75.00421802299991, 10.381791339000074],
              [-75.00419856999991, 10.381820404000052],
              [-75.00417623499993, 10.381848811000054],
              [-75.00415090899992, 10.381876713000054],
              [-75.00412247399993, 10.381904234000046],
              [-75.0040907529999 , 10.381931555000051],
              [-75.00402983899994, 10.38197727000005 ],
              [-75.0039565109999 , 10.38202494300009 ],
              [-75.00388014899994, 10.382069819000037],
              [-75.00367466099993, 10.382185100000072],
              [-75.00361604499994, 10.38222047700009 ],
              [-75.00356525299992, 10.382253602000048],
              [-75.00351131399992, 10.382292581000058],
              [-75.0034642949999 , 10.382331330000056],
              [-75.00342332699995, 10.38237054800004 ],
              [-75.00338801899994, 10.382410629000049],
              [-75.00334885599995, 10.382462358000055],
              [-75.00331078999994, 10.382518556000036],
              [-75.00327342999992, 10.382579798000052],
              [-75.00323606699993, 10.382647235000093],
              [-75.0031709239999 , 10.382778519000055],
              [-75.0030654759999 , 10.383010503000037],
              [-75.00305953499992, 10.383023575000038],
              [-75.0030146009999 , 10.383118633000038],
              [-75.00296606599994, 10.383213281000053],
              [-75.00291877899991, 10.383295909000083],
              [-75.00288847199994, 10.383343615000058],
              [-75.00285294999992, 10.38339553500009 ],
              [-75.00271532999994, 10.383583239000075],
              [-75.00266088799992, 10.383661694000068],
              [-75.00263337299992, 10.383704874000045],
              [-75.00260924299994, 10.383745992000058],
              [-75.0025880309999 , 10.383785842000066],
              [-75.00256951499995, 10.38382484500005 ],
              [-75.00249650399991, 10.383982667000055],
              [-75.0024777289999 , 10.384028313000044],
              [-75.00246327199994, 10.384069139000076],
              [-75.00245150399991, 10.384111136000058],
              [-75.00244418599993, 10.38415001900006 ],
              [-75.00244121599991, 10.384186355000054],
              [-75.00244257199995, 10.38422042700006 ],
              [-75.00244767099991, 10.38425006600005 ],
              [-75.00245676999992, 10.384280746000059],
              [-75.0024699729999 , 10.384312797000064],
              [-75.00248751799995, 10.384346833000052],
              [-75.00252343499994, 10.384404302000064],
              [-75.0026083219999 , 10.384523586000057],
              [-75.00264188699992, 10.384574848000057],
              [-75.00268863299993, 10.384649465000052],
              [-75.00273851799994, 10.384723997000037],
              [-75.0027916279999 , 10.384798602000046],
              [-75.00284812199993, 10.384873472000038],
              [-75.00290808099993, 10.384948747000067],
              [-75.00297182199995, 10.385024827000052],
              [-75.00303963999994, 10.385102060000065],
              [-75.00311199499993, 10.385180974000093],
              [-75.00324106099993, 10.385314752000056],
              [-75.00338920999991, 10.38546036300005 ],
              [-75.00353579199992, 10.385599206000052],
              [-75.00390449299994, 10.385942538000052],
              [-75.00409764099993, 10.386128822000046],
              [-75.00419506499992, 10.386227142000052],
              [-75.00428430099993, 10.38632092000006 ],
              [-75.00436692599993, 10.386411828000064],
              [-75.00444373999994, 10.38650074900005 ],
              [-75.00467008499993, 10.386773919000063],
              [-75.00489255199994, 10.387050370000054],
              [-75.00511114699992, 10.387330088000056],
              [-75.0053258609999 , 10.38761308700009 ],
              [-75.00399811599993, 10.388317322000091],
              [-75.0038082609999 , 10.388418021000064],
              [-75.00236207499995, 10.389202818000058],
              [-74.99914994999995, 10.390954815000043],
              [-74.99695202499993, 10.39211246700006 ],
              [-74.99634021199995, 10.392391772000053],
              [-74.99574901199992, 10.392619626000055],
              [-74.99526119299992, 10.39269298000005 ],
              [-74.99460715399994, 10.392735970000047],
              [-74.99391142699994, 10.39272767600005 ],
              [-74.99304917499995, 10.392586221000045],
              [-74.99331309899992, 10.39289926300006 ],
              [-74.9934555399999 , 10.39306821200006 ],
              [-74.99368516699991, 10.393431459000055],
              [-74.99369486299992, 10.393463111000074],
              [-74.99372027599992, 10.393562687000042],
              [-74.99373852499991, 10.393663823000054],
              [-74.99374950999993, 10.393766003000053],
              [-74.99375317499994, 10.393868704000056],
              [-74.99374950999993, 10.393971405000059],
              [-74.99373852499991, 10.394073586000047],
              [-74.99372027599992, 10.39417472200006 ],
              [-74.99369486299992, 10.39427429500006 ],
              [-74.99366240899991, 10.394371804000059],
              [-74.99362308099995, 10.39446674900006 ],
              [-74.99360283899995, 10.394509223000057],
              [-74.99341721499991, 10.394778073000055],
              [-74.9933949629999 , 10.394810302000053],
              [-74.99335422099995, 10.394807850000063],
              [-74.99292329999992, 10.394781914000077],
              [-74.99278795599992, 10.39477376800005 ],
              [-74.99270895699993, 10.394769010000061],
              [-74.99239024999991, 10.394772705000037],
              [-74.99166708599995, 10.394777052000052],
              [-74.98842240899995, 10.394796531000054],
              [-74.98323988399994, 10.394827578000047],
              [-74.98180584399995, 10.394836155000064],
              [-74.98008276299993, 10.394846450000045],
              [-74.97344285499992, 10.394886043000042],
              [-74.97291671699992, 10.39492142000006 ],
              [-74.97291268899994, 10.39492101400009 ],
              [-74.97259240199992, 10.394888711000078],
              [-74.9722959209999 , 10.394807985000057]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08685",
      "properties": {"id": "08685", "iso": "STO", "name": "SANTO TOMÁS"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.7785285139999 , 10.779158092000046],
              [-74.77867199899993, 10.77861792300007 ],
              [-74.7786735059999 , 10.778612873000043],
              [-74.77868715799991, 10.778567782000039],
              [-74.77872669299995, 10.778437193000059],
              [-74.77888671099993, 10.778098957000054],
              [-74.77914325199993, 10.777488883000046],
              [-74.77922115299992, 10.777283882000063],
              [-74.77923648199993, 10.777243548000058],
              [-74.77898172599993, 10.777181192000057],
              [-74.77851916299994, 10.777076248000071],
              [-74.77823090399994, 10.777013969000052],
              [-74.7778990619999 , 10.77693854100005 ],
              [-74.77777837899993, 10.77690238200006 ],
              [-74.7776308789999 , 10.776862966000067],
              [-74.77754373899995, 10.776846601000045],
              [-74.77745998499995, 10.776846793000061],
              [-74.77734950999991, 10.776880168000048],
              [-74.77707483499995, 10.776897357000053],
              [-74.77686378699991, 10.77690115300004 ],
              [-74.77642148799993, 10.776865718000067],
              [-74.7761218739999 , 10.776807179000059],
              [-74.77611504999993, 10.776804631000061],
              [-74.77579853099991, 10.776686409000092],
              [-74.77547995999993, 10.776568242000053],
              [-74.7750470169999 , 10.776428683000063],
              [-74.77504696299991, 10.776428667000062],
              [-74.77483804999991, 10.776375620000067],
              [-74.77483798399993, 10.776375607000091],
              [-74.77483789299993, 10.77637558300006 ],
              [-74.77455266599992, 10.776309379000054],
              [-74.77421671099995, 10.776265556000055],
              [-74.7741078599999 , 10.77702262300005 ],
              [-74.77408564599995, 10.777173752000067],
              [-74.77404821899995, 10.777245659000073],
              [-74.77336032199992, 10.777247254000088],
              [-74.7720608269999 , 10.777262806000067],
              [-74.7712236829999 , 10.777247360000047],
              [-74.77114866699992, 10.777321825000058],
              [-74.77110149099991, 10.777334950000068],
              [-74.77104095899995, 10.777351792000047],
              [-74.77093473199994, 10.777362347000064],
              [-74.77078175699995, 10.77732801400009 ],
              [-74.77041320199993, 10.777269408000052],
              [-74.7700446799999 , 10.777228132000062],
              [-74.76952427599991, 10.77719944100005 ],
              [-74.76907542599992, 10.77710632700007 ],
              [-74.76860644399994, 10.776973639000062],
              [-74.76833176299994, 10.776847130000078],
              [-74.76809078199994, 10.776684206000084],
              [-74.7679074799999 , 10.776536003000047],
              [-74.76781964799994, 10.77648419500008 ],
              [-74.7677118009999 , 10.776452237000058],
              [-74.76756113899995, 10.776452393000056],
              [-74.76731823999995, 10.776544587000046],
              [-74.76716294999994, 10.776587043000063],
              [-74.7668034859999 , 10.776654797000049],
              [-74.76642014599992, 10.776705187000061],
              [-74.76602015899994, 10.77671848600005 ],
              [-74.76564363799991, 10.776726193000059],
              [-74.76528257699994, 10.776693545000057],
              [-74.76511526599995, 10.776677463000055],
              [-74.7650957109999 , 10.776675924000074],
              [-74.76497021999995, 10.77666604500007 ],
              [-74.7648941299999 , 10.776663703000054],
              [-74.76472912199995, 10.776650992000043],
              [-74.7646161049999 , 10.776641399000084],
              [-74.76461789599995, 10.776617206000083],
              [-74.76461935299994, 10.77659751400006 ],
              [-74.76462403099993, 10.776534359000038],
              [-74.76459807299995, 10.776533524000058],
              [-74.76452465999995, 10.776529476000064],
              [-74.76432782199993, 10.77651862600004 ],
              [-74.76423517399991, 10.776513519000048],
              [-74.76385920699994, 10.776490673000069],
              [-74.76368407899992, 10.776478480000037],
              [-74.7634396819999 , 10.776461465000068],
              [-74.7633782389999 , 10.776458422000076],
              [-74.7632303709999 , 10.776451099000042],
              [-74.7630912589999 , 10.77644420900009 ],
              [-74.76298823299993, 10.77643721600009 ],
              [-74.76246421699994, 10.77640722600006 ],
              [-74.76241701499993, 10.776404525000089],
              [-74.76230102099993, 10.77639574300008 ],
              [-74.76227695599994, 10.776393921000079],
              [-74.7622651289999 , 10.77639302600005 ],
              [-74.76224043199994, 10.776391094000076],
              [-74.76212906199993, 10.776382382000065],
              [-74.76203580999993, 10.776375089000055],
              [-74.76180052799992, 10.776362371000062],
              [-74.76179764599993, 10.776362215000063],
              [-74.7615550189999 , 10.776349099000072],
              [-74.76148555899994, 10.776345345000038],
              [-74.76137468699994, 10.77633987900009 ],
              [-74.76130175899993, 10.77633628500007 ],
              [-74.7612087569999 , 10.776328030000059],
              [-74.76106148999992, 10.776314958000057],
              [-74.76086597699992, 10.776303343000052],
              [-74.76077638599992, 10.77629802000007 ],
              [-74.76068405399991, 10.77629253400005 ],
              [-74.76052266799991, 10.776282945000048],
              [-74.7605218779999 , 10.776282884000068],
              [-74.76045580699991, 10.776278479000041],
              [-74.76031503899992, 10.776269095000089],
              [-74.76025418799992, 10.776265038000076],
              [-74.76004621099992, 10.776248619000057],
              [-74.75995861199993, 10.776241704000086],
              [-74.7599151149999 , 10.776238269000089],
              [-74.75988628099992, 10.776232503000074],
              [-74.75986224399992, 10.776227696000092],
              [-74.75960245099992, 10.776175737000074],
              [-74.75957306799995, 10.776169860000039],
              [-74.75954120299991, 10.776169363000065],
              [-74.75935547499995, 10.776166472000057],
              [-74.75934240299995, 10.776166268000054],
              [-74.7592455919999 , 10.776162551000084],
              [-74.75916475199995, 10.776159447000055],
              [-74.75909491599992, 10.776156766000042],
              [-74.75900492699992, 10.776156247000074],
              [-74.75887723799991, 10.77617159600004 ],
              [-74.75863782699992, 10.776210776000084],
              [-74.75863771099995, 10.776210795000054],
              [-74.75862350799991, 10.77621311900009 ],
              [-74.75853724299992, 10.776224935000073],
              [-74.75849295099994, 10.776231003000078],
              [-74.7584876759999 , 10.776231726000049],
              [-74.75846731699994, 10.776236250000068],
              [-74.75844228599993, 10.77624181300007 ],
              [-74.7582820909999 , 10.77627741200007 ],
              [-74.7581480689999 , 10.77626775300007 ],
              [-74.75813751099992, 10.776266992000046],
              [-74.75813270499992, 10.776305880000052],
              [-74.75813092599992, 10.776320271000088],
              [-74.75813092499993, 10.77632030600006 ],
              [-74.7581451929999 , 10.776377380000042],
              [-74.7581451989999 , 10.776377413000091],
              [-74.75814183599994, 10.776381563000086],
              [-74.7580524519999 , 10.776491841000052],
              [-74.75796198199993, 10.776603462000082],
              [-74.7574289829999 , 10.776605840000059],
              [-74.7573671909999 , 10.776530304000062],
              [-74.75729857999994, 10.776496378000047],
              [-74.7572552819999 , 10.776474971000084],
              [-74.75714463699995, 10.776455935000058],
              [-74.75706834699992, 10.776454522000051],
              [-74.75700060299994, 10.776453268000068],
              [-74.75695190099992, 10.776452366000058],
              [-74.75677700999995, 10.776455935000058],
              [-74.7567770089999 , 10.776455899000041],
              [-74.75677502399992, 10.776378457000078],
              [-74.7567744179999 , 10.77635482800008 ],
              [-74.7567734399999 , 10.77631673600007 ],
              [-74.75677343899991, 10.776316701000042],
              [-74.75677338799994, 10.776315774000068],
              [-74.75675067599991, 10.776314684000056],
              [-74.75673993999993, 10.776314169000045],
              [-74.75653929499993, 10.776304521000043],
              [-74.75627903399993, 10.776281701000073],
              [-74.75466702599994, 10.776140355000052],
              [-74.75452825799994, 10.77612818700004 ],
              [-74.7540569329999 , 10.776086859000088],
              [-74.75177453299995, 10.775886717000049],
              [-74.75161879299992, 10.775873059000048],
              [-74.73876297999993, 10.774670731000072],
              [-74.73891381599992, 10.773377951000043],
              [-74.73905153299995, 10.772197591000065],
              [-74.7385344729999 , 10.770825227000046],
              [-74.73815238399993, 10.77083715200007 ],
              [-74.73704706799992, 10.77087165000006 ],
              [-74.7365897599999 , 10.770874688000049],
              [-74.7347789179999 , 10.77088670900008 ],
              [-74.73465177299994, 10.770887551000044],
              [-74.73289115699993, 10.771190893000039],
              [-74.73286873099994, 10.771194756000057],
              [-74.73261814799991, 10.771237927000072],
              [-74.7308130909999 , 10.771416660000057],
              [-74.72984445799995, 10.771512569000038],
              [-74.72863965499994, 10.77163186100006 ],
              [-74.72781331499993, 10.771713677000037],
              [-74.7251140649999 , 10.771726235000074],
              [-74.72515109499994, 10.770677865000039],
              [-74.72515120799994, 10.770674602000042],
              [-74.72515379499993, 10.770601359000068],
              [-74.7252656469999 , 10.767434385000058],
              [-74.72534403899994, 10.767116024000075],
              [-74.72548808199991, 10.766531043000043],
              [-74.7257781209999 , 10.765353151000056],
              [-74.72602738199993, 10.76434086100005 ],
              [-74.7268444849999 , 10.762077627000053],
              [-74.72743031399995, 10.76029191400005 ],
              [-74.72776026799994, 10.75583884200006 ],
              [-74.72775470499994, 10.753241635000052],
              [-74.72774647799991, 10.749399531000051],
              [-74.72838530799993, 10.749376002000076],
              [-74.72838586799992, 10.749375980000082],
              [-74.7307973099999 , 10.749287154000058],
              [-74.73289238899991, 10.748664816000087],
              [-74.73291520099991, 10.748658041000056],
              [-74.73356570699991, 10.748726195000074],
              [-74.73366933199992, 10.748737050000045],
              [-74.73382917999993, 10.74878410100007 ],
              [-74.73407714299992, 10.748857089000069],
              [-74.73413069499992, 10.748859763000041],
              [-74.73446435499994, 10.748876419000055],
              [-74.73487202799993, 10.748936007000054],
              [-74.73517377799993, 10.749000207000051],
              [-74.73534092599994, 10.749035768000056],
              [-74.73569909499992, 10.749134563000041],
              [-74.7359933919999 , 10.749215736000053],
              [-74.73736032999994, 10.749978537000061],
              [-74.7376192939999 , 10.75012842700005 ],
              [-74.7377071869999 , 10.75017930000007 ],
              [-74.7380699009999 , 10.75016059300009 ],
              [-74.73811468999992, 10.750158282000086],
              [-74.73872969899992, 10.750285917000042],
              [-74.73875549399992, 10.750291270000048],
              [-74.7387874499999 , 10.750297901000067],
              [-74.73885094399992, 10.75029535200008 ],
              [-74.73929663299992, 10.750277450000056],
              [-74.7393172159999 , 10.750276622000058],
              [-74.73935071499994, 10.750265120000051],
              [-74.73990771799993, 10.750073863000068],
              [-74.74045755499992, 10.749911489000056],
              [-74.74084450499993, 10.74980991600006 ],
              [-74.74143716099991, 10.749524781000048],
              [-74.74147553899991, 10.749506318000044],
              [-74.7418539649999 , 10.749143019000087],
              [-74.74208558099991, 10.748920662000046],
              [-74.74214904799993, 10.748862478000092],
              [-74.7423499539999 , 10.748678294000058],
              [-74.7425245039999 , 10.748558247000062],
              [-74.74261446399993, 10.748496377000038],
              [-74.7426932589999 , 10.748381566000091],
              [-74.74291914999992, 10.748052425000083],
              [-74.74303439099992, 10.747953230000064],
              [-74.7432242349999 , 10.747789821000083],
              [-74.7433886039999 , 10.747679745000084],
              [-74.74354319399993, 10.747611411000037],
              [-74.74354620799994, 10.747608203000084],
              [-74.74366326699993, 10.747483638000062],
              [-74.7438349549999 , 10.747372761000065],
              [-74.74410137599995, 10.747338183000068],
              [-74.74441926399993, 10.747252494000065],
              [-74.74447089999995, 10.747239612000044],
              [-74.74462547099995, 10.747201051000047],
              [-74.74484010099991, 10.74707307500006 ],
              [-74.74497742699992, 10.746970772000054],
              [-74.74506314899992, 10.746860078000054],
              [-74.74520054499993, 10.746791772000051],
              [-74.74537236399993, 10.746740403000047],
              [-74.74558722099994, 10.746714435000058],
              [-74.74585104499994, 10.746832508000068],
              [-74.74637358099994, 10.747066367000059],
              [-74.74665028499993, 10.74713939500009 ],
              [-74.74728605199994, 10.747307186000057],
              [-74.74778722199994, 10.747439456000052],
              [-74.74846568499993, 10.747761531000037],
              [-74.74857596999993, 10.747813884000038],
              [-74.74866483799991, 10.747844851000082],
              [-74.74874971199995, 10.747874425000077],
              [-74.7488131369999 , 10.74792956300007 ],
              [-74.74885392899995, 10.74794453900006 ],
              [-74.74901097399993, 10.747982610000065],
              [-74.74907031999993, 10.747986143000048],
              [-74.74909375899995, 10.747987538000075],
              [-74.74917932799991, 10.747992632000091],
              [-74.74921084899995, 10.74799450800009 ],
              [-74.74974741699992, 10.74801711200007 ],
              [-74.7500921809999 , 10.74802277300006 ],
              [-74.75034006699991, 10.748026844000037],
              [-74.75045268799994, 10.748028693000037],
              [-74.75047196299994, 10.748029010000039],
              [-74.75073866699995, 10.74803268900007 ],
              [-74.75101731599995, 10.748036532000071],
              [-74.75116200699995, 10.748038528000052],
              [-74.75129813399991, 10.748041349000061],
              [-74.75151795399995, 10.748045904000037],
              [-74.75162124299993, 10.748048045000075],
              [-74.75168830599995, 10.748048589000064],
              [-74.75177767999992, 10.74804931500006 ],
              [-74.75177944599994, 10.748049329000082],
              [-74.75180661699994, 10.748049550000076],
              [-74.75186868599991, 10.748050054000089],
              [-74.75188232699992, 10.748050165000052],
              [-74.75189822599992, 10.748050294000052],
              [-74.75191270899995, 10.748050411000065],
              [-74.75192005899993, 10.748050471000056],
              [-74.75196120499993, 10.748050804000059],
              [-74.7519738549999 , 10.74805090700005 ],
              [-74.75198159099995, 10.748050970000065],
              [-74.7520084489999 , 10.74805118800009 ],
              [-74.75205104899993, 10.74805153300008 ],
              [-74.7520527129999 , 10.748051547000046],
              [-74.75205582199993, 10.748051571000076],
              [-74.75205738099993, 10.748060451000072],
              [-74.75206741099993, 10.748057286000062],
              [-74.75209713099991, 10.748047909000093],
              [-74.75224332699992, 10.748001783000063],
              [-74.75232435599992, 10.747976218000076],
              [-74.75252423099994, 10.747914828000091],
              [-74.75263323899992, 10.747870057000057],
              [-74.75263327399995, 10.747870043000091],
              [-74.7526549609999 , 10.74786113500005 ],
              [-74.75271379599991, 10.747836971000083],
              [-74.75272623099994, 10.74783186400009 ],
              [-74.75273655599995, 10.747827623000092],
              [-74.7527640799999 , 10.747816318000048],
              [-74.75286253699994, 10.74778368300008 ],
              [-74.75301820699991, 10.747732085000052],
              [-74.75314669799991, 10.747670695000068],
              [-74.75316278799994, 10.747664180000072],
              [-74.75318479499992, 10.747655269000063],
              [-74.75330059599992, 10.747608381000077],
              [-74.75330286199994, 10.747607463000065],
              [-74.75337940899993, 10.74757646900008 ],
              [-74.75351932099994, 10.747495091000076],
              [-74.75380057399991, 10.747325198000055],
              [-74.75394756499992, 10.747231880000072],
              [-74.75414464399995, 10.74710676300009 ],
              [-74.75437235499993, 10.746959677000063],
              [-74.75444302799991, 10.74691402700006 ],
              [-74.75450557099992, 10.746876641000085],
              [-74.75469858199995, 10.746761265000089],
              [-74.75475478099992, 10.746732575000067],
              [-74.75493221199991, 10.746641994000072],
              [-74.75494747399995, 10.746634202000052],
              [-74.7549779869999 , 10.74663176100006 ],
              [-74.75500760999995, 10.746629391000056],
              [-74.75502900599992, 10.746627679000085],
              [-74.75506644699993, 10.74662468400004 ],
              [-74.7551703019999 , 10.74663773800006 ],
              [-74.7551392439999 , 10.746998602000076],
              [-74.75513142599993, 10.747091548000071],
              [-74.7551212809999 , 10.74721216200004 ],
              [-74.7551141699999 , 10.747296705000053],
              [-74.75511283899993, 10.747312526000087],
              [-74.75511283499992, 10.747312579000038],
              [-74.7551046239999 , 10.747410193000064],
              [-74.75510084199993, 10.747455159000083],
              [-74.75509705899992, 10.74750012600009 ],
              [-74.7552068679999 , 10.747510196000064],
              [-74.75557389999994, 10.747677353000086],
              [-74.7556128729999 , 10.747697348000088],
              [-74.75565184699991, 10.74771734300009 ],
              [-74.7557802849999 , 10.747783237000078],
              [-74.75591594799994, 10.747852838000085],
              [-74.75601469199995, 10.747899982000092],
              [-74.7561663859999 , 10.747972406000088],
              [-74.75627195499993, 10.748032368000054],
              [-74.75646516699993, 10.74816371500009 ],
              [-74.75680731099993, 10.748313370000062],
              [-74.75689296099995, 10.748350834000064],
              [-74.75709619999992, 10.748439732000065],
              [-74.7572141409999 , 10.748487436000062],
              [-74.75726327599995, 10.748507310000036],
              [-74.75742063899992, 10.748570959000062],
              [-74.75766988799995, 10.748647102000064],
              [-74.75779153899992, 10.748653811000054],
              [-74.75779330299991, 10.74864841100009 ],
              [-74.75780385599995, 10.748616113000082],
              [-74.75780497599993, 10.74861242500009 ],
              [-74.75781572599993, 10.74857701500008 ],
              [-74.75786640299992, 10.74841008900006 ],
              [-74.75788994899995, 10.748332533000053],
              [-74.75796518299995, 10.748084720000065],
              [-74.7579664029999 , 10.748079636000057],
              [-74.75797940099994, 10.748005448000072],
              [-74.75798163899992, 10.747992673000056],
              [-74.75799109399992, 10.747938701000066],
              [-74.75800183399991, 10.74787740100004 ],
              [-74.7580219919999 , 10.747801166000045],
              [-74.75802449299994, 10.747791709000069],
              [-74.75810055399995, 10.747437055000091],
              [-74.7581506219999 , 10.747203597000066],
              [-74.75818700899993, 10.74703393100009 ],
              [-74.75818819999995, 10.747028378000039],
              [-74.75821057999991, 10.74688502500004 ],
              [-74.75827679899993, 10.746897090000061],
              [-74.7582767639999 , 10.746897474000093],
              [-74.75828769399993, 10.746899860000042],
              [-74.75836984499995, 10.746917803000088],
              [-74.75839506199992, 10.746923311000046],
              [-74.75839530099995, 10.746922604000076],
              [-74.75843070199994, 10.74682688200005 ],
              [-74.75843779799993, 10.746804800000064],
              [-74.75845761799991, 10.74674311800004 ],
              [-74.75845799599995, 10.746741942000085],
              [-74.75848190199991, 10.746701055000074],
              [-74.75850181999994, 10.746666989000062],
              [-74.7585141049999 , 10.74664597900005 ],
              [-74.75854838399994, 10.746566854000037],
              [-74.75855983299994, 10.746540426000081],
              [-74.75856924699991, 10.746518696000066],
              [-74.75858322599993, 10.746486430000061],
              [-74.7585862059999 , 10.746482506000063],
              [-74.75858946999995, 10.746478207000052],
              [-74.75869151199993, 10.746343824000064],
              [-74.75885397799993, 10.746092450000049],
              [-74.75886799299991, 10.746065127000065],
              [-74.75888581299995, 10.746030386000086],
              [-74.75888722099995, 10.746030416000053],
              [-74.75891049199993, 10.74597944900006 ],
              [-74.75891672299991, 10.745965802000057],
              [-74.75896994699991, 10.74584923100008 ],
              [-74.75898678599992, 10.74581235100004 ],
              [-74.75899056499992, 10.745804072000055],
              [-74.75899668699992, 10.745792101000063],
              [-74.75901677699994, 10.745752821000053],
              [-74.75901867899995, 10.745749101000058],
              [-74.75901986799994, 10.74574677600009 ],
              [-74.75902727199991, 10.74573230100009 ],
              [-74.75907010299994, 10.745648559000074],
              [-74.75908919299991, 10.74561884600007 ],
              [-74.7591158699999 , 10.745577327000092],
              [-74.75912250199991, 10.745567004000065],
              [-74.7591279959999 , 10.74555358400005 ],
              [-74.75915930999992, 10.745477091000055],
              [-74.75918440299995, 10.74542804300006 ],
              [-74.75920121899992, 10.745395175000056],
              [-74.75926440099994, 10.745272785000054],
              [-74.75929649799991, 10.745212245000062],
              [-74.75931228299993, 10.745182471000078],
              [-74.75933136399993, 10.745146480000074],
              [-74.7593442729999 , 10.745076594000068],
              [-74.75935406299993, 10.745023598000046],
              [-74.7593805219999 , 10.744954475000043],
              [-74.7593922339999 , 10.744923877000076],
              [-74.75939248299994, 10.744923897000092],
              [-74.7593973299999 , 10.74492427000007 ],
              [-74.75942270799993, 10.74480617900008 ],
              [-74.75945626599992, 10.744650025000055],
              [-74.7594668079999 , 10.744588486000055],
              [-74.7594884639999 , 10.744462074000069],
              [-74.7595074379999 , 10.744329002000086],
              [-74.75952595299992, 10.744186524000042],
              [-74.75953790199992, 10.744135163000067],
              [-74.75955643399993, 10.744055510000067],
              [-74.75955791599995, 10.744050274000074],
              [-74.7595955939999 , 10.743917177000071],
              [-74.75960966199995, 10.743828525000083],
              [-74.75964600799995, 10.743800733000057],
              [-74.75968883799993, 10.743739343000072],
              [-74.75969302899995, 10.743727669000066],
              [-74.75972786599993, 10.743630622000069],
              [-74.75972959199993, 10.743628275000049],
              [-74.75973531299991, 10.743620495000073],
              [-74.75974395399993, 10.743608744000085],
              [-74.7597449299999 , 10.743607417000078],
              [-74.75976132399995, 10.74358512300006 ],
              [-74.7597825599999 , 10.743572974000074],
              [-74.75980397599994, 10.74355988700006 ],
              [-74.75981362599993, 10.743492126000092],
              [-74.75982312499991, 10.743468695000047],
              [-74.75982318699994, 10.743468541000084],
              [-74.75982514899994, 10.743463702000042],
              [-74.75982591899992, 10.74346180200007 ],
              [-74.75984746899991, 10.743408645000045],
              [-74.75986450599993, 10.743366620000074],
              [-74.75998336499993, 10.743045330000086],
              [-74.76001279199994, 10.74296578700006 ],
              [-74.76001374299994, 10.742963215000088],
              [-74.76001679199993, 10.742954974000043],
              [-74.76001760799994, 10.742954043000054],
              [-74.76002447499991, 10.742946209000081],
              [-74.76004847299993, 10.742918831000054],
              [-74.76005416399994, 10.742912339000043],
              [-74.7600547749999 , 10.742911642000081],
              [-74.7600558979999 , 10.742910252000058],
              [-74.76006030099995, 10.742904803000044],
              [-74.76007348799993, 10.742888482000069],
              [-74.76012570499995, 10.742745007000053],
              [-74.7601648079999 , 10.742614871000058],
              [-74.76020776399992, 10.74255531600005 ],
              [-74.76020981699992, 10.742552470000078],
              [-74.76025092099991, 10.742495483000084],
              [-74.7602527279999 , 10.742492978000087],
              [-74.7602554309999 , 10.742489031000048],
              [-74.7602560869999 , 10.742488073000061],
              [-74.76029117899992, 10.742436833000056],
              [-74.76029468899992, 10.742431707000037],
              [-74.76031994399995, 10.74239483100007 ],
              [-74.7603201579999 , 10.74239444400007 ],
              [-74.76035961399992, 10.742324667000048],
              [-74.7603938229999 , 10.742263181000055],
              [-74.76044849199991, 10.742159718000039],
              [-74.7604661389999 , 10.742126322000047],
              [-74.76047880399994, 10.742102353000064],
              [-74.7604933049999 , 10.742074910000042],
              [-74.76051915499994, 10.742020834000073],
              [-74.7605454699999 , 10.742030143000079],
              [-74.76055125499994, 10.742032190000089],
              [-74.76058166199994, 10.74204242500008 ],
              [-74.76078728199991, 10.742111633000093],
              [-74.76090761799992, 10.742152365000038],
              [-74.7609137419999 , 10.742154438000057],
              [-74.76094097799995, 10.742163657000049],
              [-74.7609422569999 , 10.742164090000074],
              [-74.76094839299992, 10.74216616700005 ],
              [-74.76095123599993, 10.742167129000052],
              [-74.76097005799994, 10.742173500000092],
              [-74.76100727099993, 10.742186096000069],
              [-74.76123796099995, 10.742264182000042],
              [-74.76135562899992, 10.742304011000044],
              [-74.76145494899993, 10.742337629000076],
              [-74.7614616329999 , 10.74233988900005 ],
              [-74.7615241179999 , 10.742358420000073],
              [-74.76166207799992, 10.742399334000083],
              [-74.76187341199994, 10.742462008000075],
              [-74.76192483499995, 10.742477258000065],
              [-74.7619744889999 , 10.742491984000083],
              [-74.76199430899993, 10.742535710000084],
              [-74.7620318619999 , 10.74261856000004 ],
              [-74.76203369299992, 10.742622598000082],
              [-74.76218837099992, 10.742667764000089],
              [-74.76233299599994, 10.742709995000041],
              [-74.76252793099991, 10.74276691600005 ],
              [-74.7625562209999 , 10.742775177000055],
              [-74.76317454799994, 10.742959779000046],
              [-74.76482097399992, 10.743693362000045],
              [-74.76525007499993, 10.743361805000063],
              [-74.76526446499992, 10.742887727000038],
              [-74.7652644659999 , 10.742887697000072],
              [-74.76526826599991, 10.74276251300006 ],
              [-74.76527229899995, 10.742629707000049],
              [-74.76567365199992, 10.741964864000067],
              [-74.76570054099994, 10.741920322000055],
              [-74.76591417899994, 10.741353098000047],
              [-74.7659254859999 , 10.741331638000077],
              [-74.76616953099995, 10.740868396000053],
              [-74.76617573899995, 10.740856615000041],
              [-74.76626171799995, 10.74058731100007 ],
              [-74.7663500079999 , 10.74031077400008 ],
              [-74.7665077069999 , 10.739816836000045],
              [-74.76662623499993, 10.73946545800004 ],
              [-74.76748025199993, 10.73693370500007 ],
              [-74.7675056299999 , 10.736934208000037],
              [-74.7694492299999 , 10.73697259700009 ],
              [-74.76950619799993, 10.736973722000073],
              [-74.76962954899994, 10.73697615900005 ],
              [-74.77067821499992, 10.73699636400005 ],
              [-74.77072807699994, 10.736997325000061],
              [-74.7708859199999 , 10.736609791000092],
              [-74.77107714499994, 10.736140314000068],
              [-74.77108428199995, 10.73612279300005 ],
              [-74.77276270199991, 10.73586619200006 ],
              [-74.77578697299992, 10.735403834000067],
              [-74.77579268499994, 10.735405423000088],
              [-74.77581794299994, 10.735412437000036],
              [-74.77667318899995, 10.73564998900008 ],
              [-74.77722067799994, 10.735802058000047],
              [-74.77786684799992, 10.73491744100005 ],
              [-74.77786686499991, 10.73491741500004 ],
              [-74.77829189099992, 10.734335548000047],
              [-74.7782878029999 , 10.734316696000064],
              [-74.77812293699992, 10.733556647000057],
              [-74.77821579399995, 10.732399327000053],
              [-74.77874119799992, 10.73242175900009 ],
              [-74.7794551369999 , 10.732396613000049],
              [-74.77945752599993, 10.732396528000038],
              [-74.77954866499994, 10.732381296000085],
              [-74.77984010499995, 10.732332588000077],
              [-74.78003041599993, 10.73230078000006 ],
              [-74.78067515999993, 10.732299320000038],
              [-74.78084475199995, 10.73232233200008 ],
              [-74.78092403399995, 10.732333088000075],
              [-74.78170230399991, 10.732438684000044],
              [-74.78208448399994, 10.732485048000058],
              [-74.78323140899994, 10.732789438000054],
              [-74.78447396599995, 10.73314083300005 ],
              [-74.78484601999992, 10.73167804600007 ],
              [-74.7848525789999 , 10.731652259000043],
              [-74.78482886099994, 10.731334189000052],
              [-74.78482793199993, 10.731321713000057],
              [-74.78800465599994, 10.731645077000053],
              [-74.78800473299992, 10.731678037000052],
              [-74.78800558499995, 10.732042687000046],
              [-74.7880055839999 , 10.73204274200009 ],
              [-74.78800674999991, 10.73254242300004 ],
              [-74.78915329499995, 10.732681489000072],
              [-74.78934361099994, 10.732374064000055],
              [-74.78946085299992, 10.731452833000048],
              [-74.78960213599993, 10.730602390000058],
              [-74.79009962399994, 10.728901008000037],
              [-74.7902651739999 , 10.728215811000041],
              [-74.7903117699999 , 10.727719798000066],
              [-74.7906811979999 , 10.727773059000071],
              [-74.79068122399991, 10.727773063000086],
              [-74.79070208599995, 10.727776069000072],
              [-74.79158459799993, 10.727903298000058],
              [-74.79160167999993, 10.727905761000045],
              [-74.79160277799991, 10.727902198000038],
              [-74.79163583899992, 10.72779505300008 ],
              [-74.79166801899993, 10.727690776000088],
              [-74.7916714079999 , 10.727679795000086],
              [-74.79169647799995, 10.727598554000053],
              [-74.7920916459999 , 10.726924489000055],
              [-74.7925245589999 , 10.726186039000083],
              [-74.79252462999995, 10.726185914000041],
              [-74.79412240599993, 10.72346045100005 ],
              [-74.79457365499991, 10.721216570000081],
              [-74.7946670579999 , 10.720752117000075],
              [-74.79558574099991, 10.716183828000055],
              [-74.79562662299992, 10.716180912000084],
              [-74.79634671799994, 10.716129558000091],
              [-74.79661233299993, 10.716110614000058],
              [-74.7967872129999 , 10.716132520000087],
              [-74.79695319299992, 10.716153311000085],
              [-74.7973527769999 , 10.71620336300009 ],
              [-74.79763808599995, 10.715683185000046],
              [-74.7977805129999 , 10.715114120000067],
              [-74.7978036689999 , 10.715021600000057],
              [-74.79780351399995, 10.714955787000065],
              [-74.79780199399994, 10.714313168000047],
              [-74.79780040199995, 10.713641075000055],
              [-74.79779992599993, 10.71343943900007 ],
              [-74.79774995199995, 10.713163138000084],
              [-74.79772734799991, 10.713038160000053],
              [-74.79773285499994, 10.712999366000076],
              [-74.79775083499993, 10.712872807000053],
              [-74.7982897839999 , 10.711049523000042],
              [-74.79829566899991, 10.711029619000044],
              [-74.7987319209999 , 10.711172311000041],
              [-74.7990842449999 , 10.711287551000055],
              [-74.79912578999995, 10.711037428000054],
              [-74.79927258599992, 10.710153622000064],
              [-74.7992962539999 , 10.710153568000067],
              [-74.7997740159999 , 10.710152461000064],
              [-74.79977774999992, 10.710129290000054],
              [-74.79980395299992, 10.709966670000085],
              [-74.7998887999999 , 10.70944008400005 ],
              [-74.79994756299993, 10.709075392000045],
              [-74.80034649699991, 10.707387508000068],
              [-74.80077811199993, 10.705636523000067],
              [-74.8007854999999 , 10.705606551000074],
              [-74.80129439899991, 10.70575206600006 ],
              [-74.80169652799992, 10.705867049000062],
              [-74.80175319099993, 10.705883251000046],
              [-74.80269411599994, 10.706199857000058],
              [-74.80356766699992, 10.706423637000057],
              [-74.8045381739999 , 10.70656530000008 ],
              [-74.8046425249999 , 10.706580530000053],
              [-74.80491505499992, 10.706556554000088],
              [-74.80491509099994, 10.706556552000052],
              [-74.80619213599994, 10.706444198000042],
              [-74.80619348599993, 10.706444078000061],
              [-74.80621929999995, 10.70623584100008 ],
              [-74.80621983999993, 10.70623148900006 ],
              [-74.80670332299991, 10.70621707500004 ],
              [-74.80675078299993, 10.706154181000045],
              [-74.8067527469999 , 10.70615158000004 ],
              [-74.80678365499995, 10.70611062000006 ],
              [-74.8099914469999 , 10.706142593000038],
              [-74.81002060999992, 10.706142882000051],
              [-74.81002774399991, 10.706141008000088],
              [-74.81021536299994, 10.706091708000088],
              [-74.81027563799995, 10.706075870000063],
              [-74.8104350189999 , 10.705979655000078],
              [-74.81043762299993, 10.705978083000048],
              [-74.8104975519999 , 10.705941905000088],
              [-74.81065116199994, 10.705849173000047],
              [-74.81068552399995, 10.705820716000062],
              [-74.81105328699994, 10.705516149000061],
              [-74.81120955199992, 10.705360871000039],
              [-74.81141062499995, 10.705161067000063],
              [-74.81149545699992, 10.705076770000062],
              [-74.81167068099995, 10.70487190700004 ],
              [-74.81172314199995, 10.704810571000053],
              [-74.81197142199994, 10.704340142000092],
              [-74.81200391199991, 10.704278584000065],
              [-74.81205863999992, 10.704107585000088],
              [-74.81220386899992, 10.703653809000059],
              [-74.8122776379999 , 10.703220250000072],
              [-74.81229656999994, 10.703108984000039],
              [-74.81229185099994, 10.702994706000084],
              [-74.81228231299991, 10.702763656000059],
              [-74.81218714599993, 10.702285689000064],
              [-74.81230718799992, 10.70194004700005 ],
              [-74.8127621399999 , 10.701234970000087],
              [-74.81372567499994, 10.701605801000085],
              [-74.81375694299993, 10.701617834000047],
              [-74.8140897589999 , 10.70039500200005 ],
              [-74.81415232899991, 10.700121265000064],
              [-74.81419608499994, 10.699929845000042],
              [-74.81428902199991, 10.69948228900006 ],
              [-74.8143402149999 , 10.699235762000058],
              [-74.8143402419999 , 10.69923563000009 ],
              [-74.81467124399995, 10.69764165500004 ],
              [-74.81468730899991, 10.697564293000084],
              [-74.81690601399993, 10.698009445000082],
              [-74.81690605199992, 10.698009453000054],
              [-74.81765089099991, 10.698158893000084],
              [-74.81769723399992, 10.698168189000057],
              [-74.81693727499993, 10.700467918000072],
              [-74.81693725899993, 10.700467968000055],
              [-74.81695102099991, 10.700471580000055],
              [-74.82104969799991, 10.701547404000053],
              [-74.8237103749999 , 10.702112226000054],
              [-74.82376374799992, 10.701890607000053],
              [-74.82405606399993, 10.700676826000063],
              [-74.82409036699994, 10.700391141000068],
              [-74.82409535699992, 10.700349584000037],
              [-74.82410871399992, 10.700238359000082],
              [-74.82429397899995, 10.698707398000067],
              [-74.82431860299994, 10.698503918000085],
              [-74.82431934699991, 10.698497772000053],
              [-74.82481017499993, 10.69845696200008 ],
              [-74.82561400499992, 10.69839012600005 ],
              [-74.82561405099995, 10.698390121000045],
              [-74.82563488999995, 10.698388390000048],
              [-74.82563528199995, 10.698388357000056],
              [-74.82563593999993, 10.698385516000087],
              [-74.8256536369999 , 10.698309092000045],
              [-74.82565364499993, 10.698309056000085],
              [-74.82592597299993, 10.697133048000069],
              [-74.82592765999993, 10.697125765000067],
              [-74.8270434929999 , 10.697490649000088],
              [-74.82705673499993, 10.697494977000076],
              [-74.8271325739999 , 10.697507724000047],
              [-74.82783602899991, 10.697625937000055],
              [-74.82853639199993, 10.696208012000056],
              [-74.82853641299994, 10.696207967000078],
              [-74.82861114499991, 10.696056672000054],
              [-74.82860888899995, 10.696042416000068],
              [-74.82855697699995, 10.695714044000056],
              [-74.82855656999993, 10.69571144400004 ],
              [-74.8284480129999 , 10.695692542000074],
              [-74.82818035799994, 10.695645934000083],
              [-74.82818087399994, 10.695641615000056],
              [-74.82818089999995, 10.695641396000042],
              [-74.82829324599993, 10.694700554000065],
              [-74.82840406999992, 10.693772486000057],
              [-74.83019995099994, 10.694046411000045],
              [-74.83020442299994, 10.694047092000062],
              [-74.83064313299991, 10.692226258000062],
              [-74.83071375399993, 10.69223074200005 ],
              [-74.83084461999994, 10.692239052000048],
              [-74.83108646399995, 10.69227832000007 ],
              [-74.8315840429999 , 10.692542780000053],
              [-74.83229646899991, 10.692793436000045],
              [-74.8337929029999 , 10.68965501300005 ],
              [-74.83513805899992, 10.690515152000046],
              [-74.83552448299992, 10.690099597000085],
              [-74.8359551229999 , 10.689636490000055],
              [-74.83635869599993, 10.689304501000038],
              [-74.8364553959999 , 10.689224955000043],
              [-74.8367192099999 , 10.689007934000074],
              [-74.8367324859999 , 10.688997013000062],
              [-74.83729709099993, 10.686734067000089],
              [-74.8373677969999 , 10.686450686000057],
              [-74.8373675979999 , 10.68645043500004 ],
              [-74.83733633299994, 10.686411085000088],
              [-74.83770913699993, 10.686211203000084],
              [-74.83774762699994, 10.68623189100009 ],
              [-74.83779065099992, 10.686255018000054],
              [-74.83788139599994, 10.686342679000063],
              [-74.83798800199992, 10.686478277000049],
              [-74.83804006999992, 10.686626730000057],
              [-74.83809869399994, 10.686770093000064],
              [-74.83823516599995, 10.686973158000058],
              [-74.83842639599993, 10.68703822200007 ],
              [-74.83860027299994, 10.68700954700006 ],
              [-74.83879142099994, 10.686923208000053],
              [-74.83906113799992, 10.68696321300007 ],
              [-74.83924064999991, 10.687019276000058],
              [-74.83941457299994, 10.687122799000065],
              [-74.8395887289999 , 10.687322375000065],
              [-74.8396823249999 , 10.687517625000055],
              [-74.83969432599991, 10.687745851000045],
              [-74.83959590699993, 10.687906550000037],
              [-74.83943954599994, 10.687970215000064],
              [-74.83920172299992, 10.687896221000074],
              [-74.83896976299991, 10.687879833000068],
              [-74.83877282599991, 10.68793456800006 ],
              [-74.83862822099991, 10.68797666000006 ],
              [-74.83848312799995, 10.688018893000049],
              [-74.83818478799992, 10.68818234300005 ],
              [-74.83808953999994, 10.688357711000037],
              [-74.83807522099994, 10.688569624000081],
              [-74.83814808299991, 10.688695436000046],
              [-74.83825203599991, 10.688778841000044],
              [-74.83830464199991, 10.688821045000054],
              [-74.83831683799991, 10.68901819100006 ],
              [-74.8383872149999 , 10.689519718000042],
              [-74.83846623499994, 10.689648036000051],
              [-74.8384858739999 , 10.689679925000064],
              [-74.83867555599994, 10.68968850500005 ],
              [-74.83891438199993, 10.68948395600006 ],
              [-74.8391979889999 , 10.689362919000075],
              [-74.83939458999993, 10.68947066600009 ],
              [-74.83940735099992, 10.68947765900009 ],
              [-74.83943068899993, 10.689671957000087],
              [-74.83944557299992, 10.69002729500005 ],
              [-74.83957647599993, 10.690176695000048],
              [-74.83969502399992, 10.69018900900005 ],
              [-74.83973589699991, 10.690193256000043],
              [-74.83992406099992, 10.690054926000073],
              [-74.84005119999995, 10.689957441000047],
              [-74.84030053599992, 10.689837049000062],
              [-74.84046810899991, 10.68979740800006 ],
              [-74.84061909699994, 10.689761691000058],
              [-74.84083894799994, 10.689731768000058],
              [-74.84112629499992, 10.689737272000059],
              [-74.84129291599993, 10.689799314000084],
              [-74.8413086889999 , 10.689805186000058],
              [-74.84165718999992, 10.690045007000037],
              [-74.84176149099994, 10.69018260200005 ],
              [-74.84182247099994, 10.690348568000047],
              [-74.84182576799992, 10.690525960000059],
              [-74.84180394599991, 10.690572258000088],
              [-74.84175644499993, 10.690673031000074],
              [-74.84152778399994, 10.690776416000062],
              [-74.84127574699994, 10.69078494100006 ],
              [-74.84106104699993, 10.690817687000049],
              [-74.8408933529999 , 10.690918090000082],
              [-74.84073137699994, 10.691019063000056],
              [-74.8406098079999 , 10.691179815000055],
              [-74.84064771299995, 10.691371253000057],
              [-74.84077120799992, 10.69146352000007 ],
              [-74.84083915699995, 10.69151428300006 ],
              [-74.84107430899991, 10.691548169000043],
              [-74.84124807799992, 10.69159294900004 ],
              [-74.84140135599995, 10.691672784000048],
              [-74.84146829199994, 10.691707646000054],
              [-74.84164275299992, 10.69191061400005 ],
              [-74.84177044099994, 10.692141936000041],
              [-74.8419738099999 , 10.692370827000047],
              [-74.84206966099993, 10.692551381000044],
              [-74.84198607199994, 10.692702999000062],
              [-74.84137203799992, 10.692950843000062],
              [-74.8409782249999 , 10.693089666000049],
              [-74.8408276859999 , 10.693250783000053],
              [-74.84087738999995, 10.693424966000066],
              [-74.84106575299995, 10.693479295000088],
              [-74.84125701499994, 10.693553397000073],
              [-74.8414050959999 , 10.693660378000061],
              [-74.84154629299991, 10.693820432000052],
              [-74.8416229369999 , 10.693907305000039],
              [-74.84172156799991, 10.69405620300006 ],
              [-74.84208839699994, 10.694259122000062],
              [-74.84218079099992, 10.694310232000078],
              [-74.84223812699992, 10.694341948000044],
              [-74.84245731699991, 10.694412012000043],
              [-74.84246967099995, 10.694415960000072],
              [-74.8427481089999 , 10.694397759000083],
              [-74.84294518599995, 10.694282577000081],
              [-74.84310713199994, 10.694168052000066],
              [-74.8431936689999 , 10.694052580000061],
              [-74.84326871399992, 10.693910019000043],
              [-74.84332664599992, 10.693777669000042],
              [-74.84340734099993, 10.693611365000038],
              [-74.84360423099992, 10.693421613000055],
              [-74.84369192799994, 10.693372336000039],
              [-74.84380417199992, 10.69330926400005 ],
              [-74.84388152299994, 10.693321521000087],
              [-74.8440041799999 , 10.693340956000043],
              [-74.84438759199992, 10.693832675000067],
              [-74.8445390739999 , 10.694436826000072],
              [-74.84455710999993, 10.694677458000058],
              [-74.84452841199993, 10.69485267400006 ],
              [-74.84444449999995, 10.694987353000045],
              [-74.84424487699994, 10.695222322000063],
              [-74.8441120519999 , 10.695446382000057],
              [-74.84408906399995, 10.695729487000051],
              [-74.84405652399994, 10.696587467000086],
              [-74.84403926199991, 10.697042624000062],
              [-74.84399848299995, 10.697336417000088],
              [-74.84398764499991, 10.697414508000065],
              [-74.84402401699992, 10.697603066000056],
              [-74.84412404299991, 10.697799245000056],
              [-74.84426022999992, 10.69786100500005 ],
              [-74.8444276539999 , 10.69786059200004 ],
              [-74.8448038489999 , 10.697658415000092],
              [-74.8451494059999 , 10.697755328000085],
              [-74.84533913099995, 10.697915355000077],
              [-74.84536961899994, 10.69794107000007 ],
              [-74.84539325599991, 10.697946467000065],
              [-74.84560863799993, 10.697995627000068],
              [-74.84565455899991, 10.698022170000058],
              [-74.84597720699992, 10.698208649000037],
              [-74.84598077599992, 10.69825968200007 ],
              [-74.8459880609999 , 10.69836385700006 ],
              [-74.84581939899994, 10.69860927700006 ],
              [-74.84570954899993, 10.69871846600006 ],
              [-74.84554928699993, 10.698877756000059],
              [-74.84514204099992, 10.698986885000068],
              [-74.84480745299993, 10.699091200000055],
              [-74.84450435799994, 10.699236833000043],
              [-74.84444209599991, 10.699443966000047],
              [-74.8444980349999 , 10.699593502000084],
              [-74.84450091299993, 10.69960119700005 ],
              [-74.84465166299992, 10.699557292000065],
              [-74.8448085409999 , 10.699525860000051],
              [-74.84485457099993, 10.699518742000066],
              [-74.84494452599995, 10.699504827000055],
              [-74.84515411899991, 10.699628498000038],
              [-74.84525920199991, 10.699804175000054],
              [-74.8453327009999 , 10.699856560000057],
              [-74.84550012599993, 10.699794359000066],
              [-74.84553121099992, 10.699782808000066],
              [-74.84567907799993, 10.699598305000052],
              [-74.84569677499991, 10.699604087000068],
              [-74.8458549209999 , 10.69951293400004 ],
              [-74.84589045599995, 10.699531566000076],
              [-74.84601209099992, 10.699595339000041],
              [-74.84608562599993, 10.699708998000062],
              [-74.84607793299995, 10.699827570000082],
              [-74.84607555299993, 10.699864258000048],
              [-74.84583594799994, 10.700289160000068],
              [-74.8457006669999 , 10.700589617000048],
              [-74.8455130989999 , 10.700900553000054],
              [-74.84547209899995, 10.701242172000093],
              [-74.84547567899995, 10.701252706000048],
              [-74.84556698199992, 10.70152136200005 ],
              [-74.84572432199991, 10.701752097000053],
              [-74.84604152599991, 10.701751314000091],
              [-74.84648821199994, 10.70167433000006 ],
              [-74.84686457899994, 10.701538863000053],
              [-74.84715765499993, 10.701569190000043],
              [-74.84734420299992, 10.70154748400006 ],
              [-74.84745176799993, 10.70174783400006 ],
              [-74.84739918699995, 10.701910113000054],
              [-74.84699176599992, 10.70218019300006 ],
              [-74.84669921799991, 10.702356851000047],
              [-74.84642738499991, 10.702450661000057],
              [-74.84634439899992, 10.70274063900007 ],
              [-74.8463706039999 , 10.702868550000062],
              [-74.84637618599993, 10.702895797000053],
              [-74.84653332899995, 10.702967854000065],
              [-74.84673217599993, 10.702977711000074],
              [-74.84674720299995, 10.702983927000048],
              [-74.84693120299994, 10.703060012000037],
              [-74.84705729199993, 10.703266682000049],
              [-74.84710990299993, 10.703380393000089],
              [-74.84713027499993, 10.703578984000046],
              [-74.84728878599992, 10.703773216000059],
              [-74.84756080199992, 10.703751846000046],
              [-74.84771737199992, 10.70359622400008 ],
              [-74.8478573619999 , 10.703370591000066],
              [-74.8479516239999 , 10.70320388500005 ],
              [-74.84804983499993, 10.70303019700009 ],
              [-74.84818317899993, 10.702794372000085],
              [-74.84822789399993, 10.70271529300004 ],
              [-74.84834962399992, 10.702615638000054],
              [-74.8484054139999 , 10.702569966000056],
              [-74.84864606499991, 10.70255902200006 ],
              [-74.84886633299993, 10.702765462000059],
              [-74.84886878499992, 10.702803891000087],
              [-74.84888812499992, 10.703106925000043],
              [-74.84890277599993, 10.703632546000051],
              [-74.8489082019999 , 10.703827230000059],
              [-74.84889691099994, 10.70410184700006 ],
              [-74.84885679999991, 10.704279342000063],
              [-74.84880755499995, 10.704457434000062],
              [-74.84870078999995, 10.70470404300005 ],
              [-74.84859111499992, 10.70493312800005 ],
              [-74.84850402799992, 10.70505989700007 ],
              [-74.8484379059999 , 10.705226168000081],
              [-74.84839510799992, 10.705582783000068],
              [-74.8484072299999 , 10.705851106000068],
              [-74.84836111899995, 10.706142754000041],
              [-74.8482630499999 , 10.70644131000006 ],
              [-74.84815933899995, 10.706659089000084],
              [-74.84813613599994, 10.706854060000069],
              [-74.84813661099992, 10.707042759000046],
              [-74.84815988799994, 10.707209935000037],
              [-74.84818945099994, 10.707375974000058],
              [-74.8483059859999 , 10.707598287000053],
              [-74.84846809099992, 10.70777075400008 ],
              [-74.84885159099991, 10.708061332000057],
              [-74.84923993199993, 10.708232685000041],
              [-74.84941529399993, 10.708273965000046],
              [-74.8494140279999 , 10.708275883000056],
              [-74.84939462199992, 10.708305300000063],
              [-74.8493912589999 , 10.708307327000057],
              [-74.84931112099991, 10.708355570000037],
              [-74.84923505199993, 10.708381184000075],
              [-74.84904056199991, 10.708446671000047],
              [-74.8488849659999 , 10.70848723000006 ],
              [-74.84879806499993, 10.70850988300009 ],
              [-74.84854145599991, 10.708555735000061],
              [-74.848225        , 10.708577392000052],
              [-74.84810543099991, 10.708577693000052],
              [-74.84806873399992, 10.708581673000083],
              [-74.84800698399994, 10.70858836900004 ],
              [-74.8479155789999 , 10.708599031000062],
              [-74.84782417699995, 10.70861317400005 ],
              [-74.84767993699995, 10.708634585000084],
              [-74.8476097329999 , 10.708645007000086],
              [-74.84729682199992, 10.708677088000059],
              [-74.84695919299992, 10.708670975000075],
              [-74.84685718299994, 10.708660791000057],
              [-74.84677270999993, 10.70863317900006 ],
              [-74.84650519599995, 10.708539939000048],
              [-74.84646278199995, 10.70852539000009 ],
              [-74.84614483399992, 10.70841633200007 ],
              [-74.84614479699991, 10.708416320000083],
              [-74.8460018529999 , 10.708367285000065],
              [-74.84544573699992, 10.708184335000055],
              [-74.84535419899993, 10.70814282300006 ],
              [-74.84527682099991, 10.708139538000069],
              [-74.84516072899993, 10.70812591300006 ],
              [-74.84500595899993, 10.70811238300007 ],
              [-74.84481947599994, 10.708074593000049],
              [-74.84463646699993, 10.708022873000061],
              [-74.84414366699991, 10.707843240000045],
              [-74.84379170099993, 10.707729337000046],
              [-74.8433096359999 , 10.707626186000084],
              [-74.84289439899993, 10.707522875000052],
              [-74.8425424319999 , 10.707408971000064],
              [-74.84240692499992, 10.70739930600007 ],
              [-74.84238962499995, 10.707398073000093],
              [-74.8423067679999 , 10.707392165000044],
              [-74.84229825499995, 10.707392008000056],
              [-74.84229166199992, 10.707458208000048],
              [-74.84229141799995, 10.707462816000088],
              [-74.8422756029999 , 10.707761076000054],
              [-74.8422896269999 , 10.70798400600006 ],
              [-74.84229703499994, 10.70825353500004 ],
              [-74.84230298499995, 10.70840062700006 ],
              [-74.84230780299993, 10.70851973100008 ],
              [-74.84231185199991, 10.708789272000047],
              [-74.84230200899992, 10.708889129000056],
              [-74.84228200399991, 10.708962391000057],
              [-74.84223523899993, 10.709098943000072],
              [-74.84220886199995, 10.709170304000054],
              [-74.84218845399994, 10.70922551600006 ],
              [-74.84216520299992, 10.709345375000055],
              [-74.84214546599992, 10.709525124000038],
              [-74.84213907899994, 10.709661579000056],
              [-74.84214652399993, 10.709944422000092],
              [-74.8421338579999 , 10.710257266000042],
              [-74.84213265799991, 10.710262063000073],
              [-74.84211386899995, 10.710337180000067],
              [-74.84207030499994, 10.710407172000089],
              [-74.84201995899991, 10.710457212000051],
              [-74.84198601899993, 10.710476904000075],
              [-74.84197963999992, 10.71048060600009 ],
              [-74.84191571399992, 10.710484094000037],
              [-74.84168952399995, 10.710484653000037],
              [-74.84167373899993, 10.710484694000058],
              [-74.84162633599993, 10.710484808000047],
              [-74.84160683599993, 10.710757734000083],
              [-74.84157076299994, 10.711130533000073],
              [-74.84152802099993, 10.711526646000038],
              [-74.84149856999994, 10.711856174000047],
              [-74.84144614799993, 10.712418694000064],
              [-74.84139020799995, 10.712918         ],
              [-74.84138657199992, 10.712959325000043],
              [-74.84133132799991, 10.713587031000088],
              [-74.8412758419999 , 10.714266032000069],
              [-74.8411911789999 , 10.714881364000064],
              [-74.84118332199995, 10.714938474000064],
              [-74.84110303099993, 10.715472119000083],
              [-74.84109065899992, 10.715554343000065],
              [-74.84100492899995, 10.716250063000075],
              [-74.84095217299995, 10.71667947800006 ],
              [-74.84090530799995, 10.716776099000072],
              [-74.84078871299994, 10.717242278000072],
              [-74.8406686319999 , 10.717661874000044],
              [-74.84066707199992, 10.717667794000079],
              [-74.8406046309999 , 10.717904831000055],
              [-74.8406019709999 , 10.717914948000043],
              [-74.84055413099992, 10.718239889000074],
              [-74.84054903799995, 10.718274480000048],
              [-74.84051652399995, 10.718723810000085],
              [-74.8404869339999 , 10.718968295000082],
              [-74.84048026399995, 10.719023403000051],
              [-74.84047391299993, 10.719173169000044],
              [-74.84047435899993, 10.719349541000042],
              [-74.8404913839999 , 10.719429366000043],
              [-74.8405219469999 , 10.71953910600007 ],
              [-74.8405727359999 , 10.71966543600007 ],
              [-74.84066733599991, 10.719814951000046],
              [-74.84075846099995, 10.719921216000046],
              [-74.8410720679999 , 10.720183336000048],
              [-74.8411900609999 , 10.720269568000049],
              [-74.84129125399994, 10.720365823000066],
              [-74.84139269299993, 10.720446217000074],
              [-74.84141263799995, 10.720462026000064],
              [-74.84135248899992, 10.720628564000037],
              [-74.84124205499995, 10.720935054000051],
              [-74.84118206999995, 10.721101531000045],
              [-74.84105005799995, 10.721372871000085],
              [-74.8410280789999 , 10.721418053000093],
              [-74.8408473419999 , 10.721804519000045],
              [-74.84069674199992, 10.722131015000059],
              [-74.8405461559999 , 10.722464167000055],
              [-74.84045243599991, 10.722660733000055],
              [-74.84043151299994, 10.722716564000052],
              [-74.84016163699994, 10.723436826000068],
              [-74.83988115999995, 10.724244978000058],
              [-74.83985762799995, 10.724312782000084],
              [-74.8396771429999 , 10.72479908400004 ],
              [-74.83948638599992, 10.72521553000007 ],
              [-74.83940744099993, 10.725366074000078],
              [-74.83940600999995, 10.725368803000038],
              [-74.83957455699993, 10.725484863000077],
              [-74.84000260499994, 10.725756682000053],
              [-74.84006627199994, 10.725796475000038],
              [-74.84006630399995, 10.725796495000054],
              [-74.84036323299995, 10.72598208100004 ],
              [-74.84058234199995, 10.726131291000058],
              [-74.84066997699995, 10.726187645000039],
              [-74.8406400849999 , 10.726344126000072],
              [-74.84053375799994, 10.726880162000043],
              [-74.84042592599991, 10.727394453000045],
              [-74.84040740699993, 10.727482801000065],
              [-74.8402144129999 , 10.728345171000058],
              [-74.84021258699994, 10.728353137000056],
              [-74.84020966299994, 10.728365916000087],
              [-74.8401784969999 , 10.728502108000043],
              [-74.84015116899991, 10.72862153300008 ],
              [-74.84010362499993, 10.728802746000042],
              [-74.84006118099995, 10.728964519000044],
              [-74.8399696969999 , 10.729064667000046],
              [-74.83982962899995, 10.72921800100005 ],
              [-74.83970224399991, 10.729411326000047],
              [-74.83953562399995, 10.72965982900007 ],
              [-74.83950780099991, 10.729701326000054],
              [-74.83938387099994, 10.72993124900006 ],
              [-74.8393171319999 , 10.730154373000062],
              [-74.8392640539999 , 10.73045733300006 ],
              [-74.83924017899994, 10.730584210000075],
              [-74.83923084899993, 10.730633787000045],
              [-74.83921439399995, 10.730780248000087],
              [-74.83920120699992, 10.730890100000067],
              [-74.83914431999995, 10.731016694000061],
              [-74.83910073299995, 10.731080030000044],
              [-74.83901797099992, 10.73101896500009 ],
              [-74.83897936599993, 10.73099047900007 ],
              [-74.83888091299991, 10.730900018000057],
              [-74.83852064399991, 10.730568984000058],
              [-74.83838576599993, 10.730459498000073],
              [-74.83830487599994, 10.73040977900007 ],
              [-74.8382240389999 , 10.73038002900006 ],
              [-74.83816346499992, 10.730380179000065],
              [-74.83803898999992, 10.730393796000044],
              [-74.83785404799994, 10.730447497000057],
              [-74.83756490099995, 10.730548043000056],
              [-74.83744050899992, 10.730594936000045],
              [-74.83733962499991, 10.730621805000055],
              [-74.83726418999993, 10.730641465000076],
              [-74.83726227699992, 10.73064196300004 ],
              [-74.83720576499991, 10.730918311000039],
              [-74.8371762509999 , 10.731097187000046],
              [-74.83717623799993, 10.731097262000048],
              [-74.83710963999994, 10.731500907000054],
              [-74.83702196799993, 10.731909460000054],
              [-74.83701318199991, 10.731950396000059],
              [-74.83692024399994, 10.732459776000042],
              [-74.83684727399992, 10.732882582000059],
              [-74.83680736799994, 10.733072364000066],
              [-74.83648440199994, 10.73310643800005 ],
              [-74.83592419099995, 10.73312512800004 ],
              [-74.83583834899991, 10.73312799100006 ],
              [-74.8358021439999 , 10.733450876000063],
              [-74.83573593499995, 10.733886977000054],
              [-74.8356327269999 , 10.734329829000046],
              [-74.83550596399994, 10.734772733000057],
              [-74.8353557289999 , 10.735248976000037],
              [-74.83517496199994, 10.735681554000053],
              [-74.83513160899992, 10.735785299000042],
              [-74.83510821099992, 10.735848584000053],
              [-74.83508815399995, 10.73590187800005 ],
              [-74.83508155599992, 10.735955139000055],
              [-74.83511193599992, 10.735991669000043],
              [-74.83516592599995, 10.736051438000061],
              [-74.8351997549999 , 10.736121240000045],
              [-74.8352138219999 , 10.736157481000077],
              [-74.83522685399993, 10.736191054000074],
              [-74.8352170739999 , 10.736317536000058],
              [-74.83515379599993, 10.73658058600006 ],
              [-74.83507371799993, 10.736853659000076],
              [-74.83507286999992, 10.73685608900007 ],
              [-74.83504698899992, 10.736930262000044],
              [-74.83504284999992, 10.73697009700004 ],
              [-74.8350404169999 , 10.736993509000058],
              [-74.83503390399994, 10.737080047000063],
              [-74.83501054499993, 10.73715996900006 ],
              [-74.83495379199991, 10.737339810000037],
              [-74.83488029099993, 10.737552967000056],
              [-74.83476670899995, 10.737886027000059],
              [-74.83468364899994, 10.73831218500004 ],
              [-74.83464741099993, 10.738621758000079],
              [-74.83460773099995, 10.738872744000048],
              [-74.83460281899994, 10.73890381700005 ],
              [-74.83459109499995, 10.73897796700004 ],
              [-74.83456202199994, 10.739179535000062],
              [-74.83456132499992, 10.73917952100004 ],
              [-74.83454746799993, 10.739269896000053],
              [-74.8345465619999 , 10.73927548800009 ],
              [-74.8345381279999 , 10.73932751600006 ],
              [-74.83453790699991, 10.739330327000062],
              [-74.83450748799993, 10.739716540000074],
              [-74.83445659699993, 10.74036265500007 ],
              [-74.83441478899994, 10.741134805000058],
              [-74.83437546099992, 10.741584188000047],
              [-74.8343660509999 , 10.741777442000057],
              [-74.83435959699995, 10.74194026400005 ],
              [-74.83434637399995, 10.742033473000049],
              [-74.83433059499993, 10.742064872000071],
              [-74.83429279599994, 10.742140093000046],
              [-74.83420591999993, 10.74238656500006 ],
              [-74.83399203999994, 10.742982763000043],
              [-74.83381160399995, 10.74349568200006 ],
              [-74.83369132199994, 10.743842068000049],
              [-74.83187960899994, 10.743845127000043],
              [-74.83172212599993, 10.743845390000047],
              [-74.8317322659999 , 10.745253516000048],
              [-74.83174009599992, 10.746383969000078],
              [-74.83174081399994, 10.746487700000046],
              [-74.83151261099994, 10.74704070100006 ],
              [-74.83134274199995, 10.74745985900006 ],
              [-74.83103600999993, 10.748235033000071],
              [-74.83097849599994, 10.748372553000081],
              [-74.83087484299995, 10.748620395000046],
              [-74.8306896869999 , 10.749078299000075],
              [-74.83047369099995, 10.749612459000048],
              [-74.83007266899995, 10.75065384900006 ],
              [-74.82989497299991, 10.751107902000058],
              [-74.82986537599993, 10.751183535000052],
              [-74.82959960499994, 10.751906194000071],
              [-74.82943312499992, 10.752435773000059],
              [-74.82927216099995, 10.75292691900006 ],
              [-74.82920807799991, 10.753122460000043],
              [-74.82893798099991, 10.753930337000043],
              [-74.82877146699991, 10.754446461000043],
              [-74.82875396599991, 10.754498235000085],
              [-74.8284968449999 , 10.755258835000063],
              [-74.82841584899995, 10.755514651000055],
              [-74.82838575799991, 10.755603214000075],
              [-74.82832139499993, 10.755792649000057],
              [-74.82832130299994, 10.755792923000058],
              [-74.8281450799999 , 10.755980117000092],
              [-74.82805895299992, 10.756071605000045],
              [-74.82787356999995, 10.756296281000061],
              [-74.82780120399991, 10.756377178000037],
              [-74.82760239499993, 10.756673644000045],
              [-74.82758791599991, 10.756695466000053],
              [-74.82746232799991, 10.756884759000059],
              [-74.82739536999992, 10.757003467000061],
              [-74.82735847599992, 10.757068876000062],
              [-74.82721851899993, 10.757324838000045],
              [-74.82705149399993, 10.757639160000053],
              [-74.82683027699994, 10.758043307000037],
              [-74.82666325199995, 10.75835763300006 ],
              [-74.82652346299994, 10.758680857000058],
              [-74.82641520699991, 10.75891880200004 ],
              [-74.82637082199994, 10.758991622000053],
              [-74.82632487399991, 10.759067013000049],
              [-74.82617594599992, 10.75936335700004 ],
              [-74.8261059749999 , 10.759494136000058],
              [-74.82602834999994, 10.759739851000063],
              [-74.82593697899995, 10.759928425000055],
              [-74.82585912399992, 10.76007996900006 ],
              [-74.82579467599993, 10.76025117100005 ],
              [-74.82576788099993, 10.760322358000053],
              [-74.82562915299991, 10.760615309000059],
              [-74.8255076339999 , 10.760992306000048],
              [-74.82544518499992, 10.761169124000048],
              [-74.82535901599994, 10.761413093000044],
              [-74.82527130999995, 10.761709285000052],
              [-74.8252207829999 , 10.761907850000057],
              [-74.82517369699991, 10.762059805000092],
              [-74.82516341999991, 10.762092972000062],
              [-74.82507691399991, 10.76228111100005 ],
              [-74.82507207299994, 10.762291636000043],
              [-74.8249740899999 , 10.76255758700006 ],
              [-74.82488969499991, 10.762816773000054],
              [-74.82477155299995, 10.763183670000046],
              [-74.82465002599992, 10.763557301000048],
              [-74.82452863499992, 10.763984748000041],
              [-74.82443752299992, 10.764280951000046],
              [-74.82435293999993, 10.764462782000066],
              [-74.82432930699991, 10.76453347000006 ],
              [-74.82424653999993, 10.764682453000091],
              [-74.82415111299991, 10.764854221000064],
              [-74.8240532769999 , 10.76506110300005 ],
              [-74.82398947199994, 10.765176484000051],
              [-74.82381333099994, 10.765494999000055],
              [-74.82379605499995, 10.765525598000067],
              [-74.82369697999991, 10.765701105000062],
              [-74.82362777499992, 10.76577349300004 ],
              [-74.82371237899991, 10.765851543000053],
              [-74.82369741399992, 10.765878030000067],
              [-74.82363965699994, 10.76602183500006 ],
              [-74.8235819439999 , 10.766184793000093],
              [-74.8235412329999 , 10.766309946000092],
              [-74.82353868399991, 10.766317790000073],
              [-74.82352426699993, 10.766362120000053],
              [-74.82396031099995, 10.766428105000045],
              [-74.82403787099992, 10.766456651000055],
              [-74.82401499499991, 10.766997840000045],
              [-74.82399149899993, 10.76747556500004 ],
              [-74.82398272599994, 10.767653985000038],
              [-74.82396881999995, 10.767891083000052],
              [-74.82393160799995, 10.768525669000041],
              [-74.82386004799991, 10.768961624000042],
              [-74.82376907499992, 10.769383261000087],
              [-74.82367788499994, 10.769718700000055],
              [-74.82357212199992, 10.770035018000044],
              [-74.82349522699991, 10.77027464300005 ],
              [-74.82335550999994, 10.770571885000038],
              [-74.82322545699992, 10.770859530000052],
              [-74.82300850299993, 10.771257527000046],
              [-74.8228926779999 , 10.771502443000088],
              [-74.82273869799991, 10.771828051000057],
              [-74.82268023699993, 10.77196383900008 ],
              [-74.82265201799993, 10.772029387000089],
              [-74.82261810799992, 10.77205396100004 ],
              [-74.82241996099992, 10.772197559000062],
              [-74.8219462429999 , 10.77256744400006 ],
              [-74.82190408799994, 10.772603554000057],
              [-74.82180608599992, 10.772687503000043],
              [-74.82150591699991, 10.772731330000056],
              [-74.82135564099991, 10.772733308000056],
              [-74.82126503599994, 10.77273450000007 ],
              [-74.82111578099995, 10.772736463000058],
              [-74.82066373499993, 10.773549259000049],
              [-74.82046484499995, 10.773908898000059],
              [-74.81991870399992, 10.774861994000048],
              [-74.81965824999992, 10.77530080200006 ],
              [-74.8194821809999 , 10.775615966000089],
              [-74.81945934399994, 10.77565684800004 ],
              [-74.81905573799992, 10.775492607000047],
              [-74.8184412729999 , 10.775257044000057],
              [-74.81803303499993, 10.775098114000059],
              [-74.81801950599993, 10.775092847000053],
              [-74.81770024399992, 10.774961026000085],
              [-74.81769952499991, 10.774960728000053],
              [-74.8176791919999 , 10.774960141000065],
              [-74.81767914199992, 10.774960139000086],
              [-74.81724180399993, 10.774947463000046],
              [-74.8171258079999 , 10.774940749000052],
              [-74.81694390199993, 10.774930220000044],
              [-74.8168644889999 , 10.774925883000037],
              [-74.81686443299992, 10.77492587900008 ],
              [-74.81656607399992, 10.774909578000063],
              [-74.81628907999993, 10.774901688000057],
              [-74.81622846999994, 10.774899960000084],
              [-74.81621733899993, 10.774899643000083],
              [-74.81620834599994, 10.774900872000046],
              [-74.81584332299991, 10.77495082300004 ],
              [-74.81550932599993, 10.775027051000052],
              [-74.81538098499993, 10.77505472200005 ],
              [-74.81508818499992, 10.775117851000061],
              [-74.81420591999995, 10.775288773000057],
              [-74.81407491699991, 10.775317080000093],
              [-74.81407488999992, 10.775317087000076],
              [-74.8137521029999 , 10.775386833000084],
              [-74.81335022999991, 10.775478003000046],
              [-74.81332008099992, 10.775484840000047],
              [-74.81283720699992, 10.775582972000052],
              [-74.8127754759999 , 10.775595864000081],
              [-74.81241968599994, 10.775670166000054],
              [-74.81217648199993, 10.775738988000057],
              [-74.8120929609999 , 10.775749965000045],
              [-74.81206172399993, 10.776346243000091],
              [-74.81197256199994, 10.777021683000044],
              [-74.8119446739999 , 10.777499429000045],
              [-74.81197051399994, 10.777668174000041],
              [-74.81195654499993, 10.77768708700006 ],
              [-74.8117899799999 , 10.777912628000081],
              [-74.81177500299992, 10.777932908000082],
              [-74.81177447899995, 10.777932491000058],
              [-74.81170792199993, 10.77787932800004 ],
              [-74.81136130699991, 10.777721430000042],
              [-74.81083095699995, 10.777518307000037],
              [-74.8104941599999 , 10.777307312000062],
              [-74.81027905499991, 10.77719452000008 ],
              [-74.81011198599992, 10.777068246000056],
              [-74.8096272379999 , 10.776808522000067],
              [-74.8088577449999 , 10.776397726000084],
              [-74.80876764399994, 10.77635263600007 ],
              [-74.80828812399994, 10.776216100000056],
              [-74.80808495499991, 10.776164032000054],
              [-74.80795700499993, 10.776131238000062],
              [-74.80786715599993, 10.776063547000092],
              [-74.80785990099992, 10.776058084000056],
              [-74.80772137799994, 10.775953725000079],
              [-74.80746922299994, 10.77576606200006 ],
              [-74.80721820199994, 10.775579246000063],
              [-74.80679655199992, 10.77525257600007 ],
              [-74.80678126099991, 10.77524072400007 ],
              [-74.80661151999993, 10.775097086000073],
              [-74.80607787199995, 10.77467348600004 ],
              [-74.80573843299993, 10.774407619000044],
              [-74.8055083459999 , 10.774215200000071],
              [-74.8054296119999 , 10.774238747000084],
              [-74.80516971899993, 10.774284135000073],
              [-74.8048407739999 , 10.774281020000046],
              [-74.8047351429999 , 10.774273243000039],
              [-74.80443100299993, 10.774250849000055],
              [-74.80424758299995, 10.774229275000039],
              [-74.80423366999992, 10.77422763900006 ],
              [-74.80421692899995, 10.774267615000042],
              [-74.80417574899991, 10.774365948000082],
              [-74.80408377999993, 10.774542150000059],
              [-74.80394782099995, 10.77471317900006 ],
              [-74.80382164799994, 10.774826444000041],
              [-74.80373067199992, 10.774888841000063],
              [-74.80361373499994, 10.774987228000043],
              [-74.803454        , 10.775102050000044],
              [-74.80324355899995, 10.775208331000044],
              [-74.80300712999991, 10.775293659000056],
              [-74.80284564399994, 10.775338961000045],
              [-74.8026597999999 , 10.775376144000063],
              [-74.80234831599995, 10.77540166700004 ],
              [-74.80211810499992, 10.77542008100005 ],
              [-74.80199460099993, 10.775425010000049],
              [-74.8017955549999 , 10.775476643000047],
              [-74.80162109499992, 10.775533732000042],
              [-74.80137683599992, 10.775649611000063],
              [-74.80116691199993, 10.775766756000053],
              [-74.8010548179999 , 10.775831904000086],
              [-74.80098476499995, 10.775872611000068],
              [-74.80071052699992, 10.776063922000048],
              [-74.80049364799993, 10.776189797000086],
              [-74.8002654789999 , 10.776337717000047],
              [-74.80001242599991, 10.77645319800007 ],
              [-74.7998131029999 , 10.77646932500005 ],
              [-74.79965724399995, 10.776467962000083],
              [-74.79946546699995, 10.776421496000069],
              [-74.79924050499994, 10.776316732000055],
              [-74.79907501499991, 10.776275140000052],
              [-74.79898053999995, 10.776272533000053],
              [-74.79888683099995, 10.776288426000065],
              [-74.79868536599992, 10.776365159000079],
              [-74.79847521399995, 10.776425024000048],
              [-74.79834660099993, 10.776411513000085],
              [-74.79817849799991, 10.776343576000045],
              [-74.79794457799994, 10.776237810000055],
              [-74.7976697279999 , 10.77613795700006 ],
              [-74.79757301299992, 10.77611965300008 ],
              [-74.79748505299995, 10.77602210300006 ],
              [-74.79706630899994, 10.776029709000056],
              [-74.79670797699993, 10.776083545000063],
              [-74.79635969899994, 10.776140671000064],
              [-74.79602484999992, 10.776207703000068],
              [-74.7955361089999 , 10.77636453200006 ],
              [-74.79514117699995, 10.776524451000057],
              [-74.79479647199992, 10.776674314000047],
              [-74.79455546299994, 10.776757687000043],
              [-74.79433110799994, 10.776801274000093],
              [-74.79415357899995, 10.77681162500005 ],
              [-74.7938554619999 , 10.776832193000075],
              [-74.79355729999992, 10.776829578000047],
              [-74.79327252199994, 10.776823617000048],
              [-74.79315197699992, 10.776847084000053],
              [-74.7929712369999 , 10.776917068000046],
              [-74.79273368299994, 10.777046806000044],
              [-74.79250288299994, 10.777196399000047],
              [-74.7922520969999 , 10.777395733000048],
              [-74.79197127999993, 10.777644819000045],
              [-74.79171399899991, 10.777930283000046],
              [-74.79142993099993, 10.778222438000057],
              [-74.79119593999991, 10.778441601000054],
              [-74.79105209199992, 10.778528064000056],
              [-74.79093163699991, 10.778591275000053],
              [-74.79078101599993, 10.778647939000052],
              [-74.79053331299991, 10.778734635000092],
              [-74.79040942099994, 10.778761424000038],
              [-74.79020510899994, 10.778781773000048],
              [-74.78999073199992, 10.778795519000084],
              [-74.78974279999994, 10.77878615800006 ],
              [-74.7896255149999 , 10.778773178000051],
              [-74.78953164699993, 10.778746900000044],
              [-74.78939422099995, 10.77871740200004 ],
              [-74.7893238609999 , 10.778714252000043],
              [-74.7891832009999 , 10.778734455000063],
              [-74.7890058349999 , 10.778814364000084],
              [-74.78874151199994, 10.778957413000057],
              [-74.7884235919999 , 10.779103894000059],
              [-74.78804213899991, 10.779300213000056],
              [-74.78760715699991, 10.779526464000071],
              [-74.78709848199992, 10.779759506000062],
              [-74.78688764499992, 10.77985274200006 ],
              [-74.78672024299993, 10.77989950500006 ],
              [-74.78652932099993, 10.779913195000063],
              [-74.78629815999994, 10.779913728000054],
              [-74.786067        , 10.779914261000044],
              [-74.7859229529999 , 10.779917907000083],
              [-74.78579906699991, 10.779948004000062],
              [-74.78570537799993, 10.779997907000052],
              [-74.78543436199993, 10.780144281000048],
              [-74.78495922799993, 10.780393806000063],
              [-74.7847253729999 , 10.780524443000047],
              [-74.7846079219999 , 10.780590051000047],
              [-74.78423320199994, 10.780802908000055],
              [-74.78390888799993, 10.78107859000005 ],
              [-74.78353435799994, 10.78137425500006 ],
              [-74.7831631489999 , 10.78165335500006 ],
              [-74.78273847299994, 10.781992198000069],
              [-74.78230040199992, 10.78233438600006 ],
              [-74.7817887189999 , 10.782716490000041],
              [-74.78162479999992, 10.78281955600005 ],
              [-74.78153924199995, 10.782702997000058],
              [-74.78149372299993, 10.782640981000043],
              [-74.78127842599991, 10.782263856000043],
              [-74.78117395899994, 10.782002413000043],
              [-74.78108963099993, 10.78175748600006 ],
              [-74.7810658759999 , 10.781628355000066],
              [-74.78105186999994, 10.78137001400006 ],
              [-74.7810665799999 , 10.780898648000061],
              [-74.78107367799993, 10.780671041000062],
              [-74.78107886899994, 10.780484711000042],
              [-74.7810798619999 , 10.780449090000047],
              [-74.7808519049999 , 10.780386676000091],
              [-74.7804093389999 , 10.780238629000053],
              [-74.7799096789999 , 10.78009058300006 ],
              [-74.7797991839999 , 10.78005784000004 ],
              [-74.7791621369999 , 10.779837364000059],
              [-74.77864248599991, 10.779676241000061],
              [-74.77841484899994, 10.779607787000089],
              [-74.7785285139999 , 10.779158092000046]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08758",
      "properties": {"id": "08758", "iso": "SOL", "name": "SOLEDAD"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.76071254599992, 10.944170115000077],
              [-74.76051326599992, 10.944137398000066],
              [-74.76030216899994, 10.944162233000043],
              [-74.76026044899993, 10.944167141000037],
              [-74.76003752299994, 10.94425712900005 ],
              [-74.76002170399994, 10.944263515000046],
              [-74.75993624699993, 10.944298011000058],
              [-74.75960140699993, 10.94441977200006 ],
              [-74.75947371699993, 10.944442275000085],
              [-74.75729559299992, 10.945368105000057],
              [-74.75725060999991, 10.944979613000044],
              [-74.75720195199995, 10.944592421000038],
              [-74.75714962699993, 10.94420659800005 ],
              [-74.75709364399995, 10.943822216000058],
              [-74.75703400999993, 10.94343933500005 ],
              [-74.7569707429999 , 10.943058017000055],
              [-74.7569038449999 , 10.942678329000046],
              [-74.75683332999995, 10.942300333000048],
              [-74.75675921099992, 10.941924099000062],
              [-74.75668149599994, 10.941549680000037],
              [-74.75420217999994, 10.936803156000053],
              [-74.75384961399993, 10.936128187000065],
              [-74.7517175079999 , 10.93478633400008 ],
              [-74.75021398899992, 10.933840086000089],
              [-74.74671234899995, 10.93111205300005 ],
              [-74.74662726899993, 10.931060931000047],
              [-74.74654449199994, 10.931008346000056],
              [-74.74648176999995, 10.93096623100007 ],
              [-74.74646396799994, 10.930954278000058],
              [-74.74638566499993, 10.930898691000039],
              [-74.74626449999994, 10.930808927000044],
              [-74.7461427479999 , 10.930716067000048],
              [-74.74602038099994, 10.930620093000073],
              [-74.74589736799993, 10.93052097900005 ],
              [-74.7457736799999 , 10.930418703000043],
              [-74.74564927299991, 10.930313225000077],
              [-74.74552409699993, 10.930204507000042],
              [-74.74539810999994, 10.930092511000055],
              [-74.74527138799994, 10.929977309000037],
              [-74.7451437229999 , 10.929858718000048],
              [-74.74488522899992, 10.929611045000058],
              [-74.74462186499994, 10.929348771000036],
              [-74.74435234399994, 10.929070616000047],
              [-74.74426089799994, 10.928972558000055],
              [-74.7442600629999 , 10.928971624000042],
              [-74.74416992099992, 10.92887083100004 ],
              [-74.74407926499993, 10.928765259000045],
              [-74.74398873599995, 10.92865561900004 ],
              [-74.74389820099992, 10.928541758000051],
              [-74.74380730899992, 10.92842324500009 ],
              [-74.74371577099993, 10.928299682000045],
              [-74.74362314799993, 10.928170502000057],
              [-74.74353151699995, 10.928038755000046],
              [-74.74343769599994, 10.92790003500005 ],
              [-74.74334083999992, 10.927753086000052],
              [-74.7432393819999 , 10.927595548000056],
              [-74.7430368169999 , 10.927272260000052],
              [-74.74264282799993, 10.926629241000057],
              [-74.74247686299992, 10.926361756000063],
              [-74.74230009899992, 10.926084485000047],
              [-74.74216766099994, 10.925884623000059],
              [-74.74213761399994, 10.925839281000037],
              [-74.74203979799995, 10.925697003000039],
              [-74.74194396999991, 10.925561888000061],
              [-74.74184944799993, 10.925432981000085],
              [-74.7417557789999 , 10.92530965900005 ],
              [-74.7416624459999 , 10.925191247000043],
              [-74.7415692809999 , 10.925077553000051],
              [-74.74147602599993, 10.924968257000046],
              [-74.74138248199995, 10.924863121000044],
              [-74.74126513999994, 10.924735689000045],
              [-74.74114505799992, 10.92460853700004 ],
              [-74.74102219899993, 10.924481625000055],
              [-74.74089651899993, 10.924354902000061],
              [-74.74076797599992, 10.924228337000045],
              [-74.74063651699993, 10.924101862000043],
              [-74.74050208299991, 10.923975425000037],
              [-74.74036460399992, 10.923848969000062],
              [-74.74022411899995, 10.923722522000048],
              [-74.7400804479999 , 10.923595913000042],
              [-74.73993349699992, 10.923469067000042],
              [-74.73978316099993, 10.923341893000043],
              [-74.73962935899993, 10.923214315000052],
              [-74.7394719269999 , 10.923086201000046],
              [-74.73931071599992, 10.922957431000043],
              [-74.73914555099992, 10.922827858000062],
              [-74.73881544199992, 10.922575393000045],
              [-74.73846797099992, 10.922317901000042],
              [-74.73810067699992, 10.922053550000044],
              [-74.73770916799992, 10.921779178000065],
              [-74.73730301599994, 10.921501117000048],
              [-74.73685627499992, 10.921201263000057],
              [-74.73636084299994, 10.920873933000053],
              [-74.73567895699995, 10.920428093000055],
              [-74.7354812609999 , 10.920301522000045],
              [-74.73527733199995, 10.920175723000057],
              [-74.7350667259999 , 10.920050416000038],
              [-74.7348488639999 , 10.91992526200005 ],
              [-74.73462337399991, 10.919800038000062],
              [-74.73438903099992, 10.919674051000072],
              [-74.73414471299992, 10.919546696000054],
              [-74.7338886899999 , 10.919417077000048],
              [-74.73367441699992, 10.919311221000044],
              [-74.73344941499994, 10.919202282000072],
              [-74.73295555199991, 10.918969545000039],
              [-74.73248060199995, 10.918751483000051],
              [-74.73133890199995, 10.91823364600009 ],
              [-74.73103496399995, 10.918093748000047],
              [-74.73076154599994, 10.917965994000042],
              [-74.730478        , 10.91783100300006 ],
              [-74.73021275799994, 10.917701847000046],
              [-74.72996151699994, 10.917576430000054],
              [-74.72972199199995, 10.917453615000056],
              [-74.72948298199992, 10.917327435000061],
              [-74.72925338099992, 10.91720235300005 ],
              [-74.72903208899993, 10.917077781000046],
              [-74.7288183519999 , 10.916953277000061],
              [-74.72861133899994, 10.916828369000086],
              [-74.72841074899992, 10.91670286100009 ],
              [-74.72821615299995, 10.91657648000006 ],
              [-74.72802721999994, 10.916449017000048],
              [-74.72783047399992, 10.91630974900005 ],
              [-74.72761486399992, 10.916149423000093],
              [-74.7274089739999 , 10.915991038000072],
              [-74.7269036859999 , 10.915596236000056],
              [-74.72663395399991, 10.915391963000047],
              [-74.72649362099992, 10.915289879000056],
              [-74.72636047999993, 10.915196471000058],
              [-74.72623220699995, 10.915110101000039],
              [-74.72614925099992, 10.915056752000055],
              [-74.72610755499994, 10.915029936000053],
              [-74.7257875219999 , 10.914831934000063],
              [-74.72550117199995, 10.91466087300006 ],
              [-74.72523315599994, 10.91450760400005 ],
              [-74.72497856199993, 10.914369347000047],
              [-74.72485374699994, 10.91430450200005 ],
              [-74.72473111399995, 10.914242823000052],
              [-74.72461039299992, 10.914184171000045],
              [-74.72449137899991, 10.914128453000046],
              [-74.72437385499995, 10.914075561000061],
              [-74.7242577049999 , 10.91402544500005 ],
              [-74.72414279499992, 10.91397805400004 ],
              [-74.72402901999993, 10.913933339000039],
              [-74.72392287299994, 10.913895095000044],
              [-74.72381004199991, 10.913859161000062],
              [-74.72368937099992, 10.913825166000038],
              [-74.72355850999992, 10.913792446000059],
              [-74.72344302499994, 10.91376635100005 ],
              [-74.72331349599995, 10.913739347000046],
              [-74.72288488099991, 10.913656850000052],
              [-74.7227288009999 , 10.913625420000074],
              [-74.7225773479999 , 10.913592032000054],
              [-74.72244232399993, 10.91355864400009 ],
              [-74.72230559699995, 10.91352009700006 ],
              [-74.72217943299995, 10.913479075000055],
              [-74.72206181899992, 10.913434919000053],
              [-74.72195168499991, 10.913387220000061],
              [-74.7218571869999 , 10.913341947000049],
              [-74.72176270199992, 10.913293981000038],
              [-74.7216682639999 , 10.91324333700004 ],
              [-74.72157391499991, 10.913190048000047],
              [-74.72147970599991, 10.91313413100005 ],
              [-74.7213856759999 , 10.913075617000061],
              [-74.72129187799993, 10.913014535000059],
              [-74.72119836299993, 10.91295092100006 ],
              [-74.7211051459999 , 10.912884782000049],
              [-74.72101232699993, 10.912816185000054],
              [-74.72091995399995, 10.91274517000005 ],
              [-74.72082808999994, 10.912671785000043],
              [-74.7207367929999 , 10.912596077000046],
              [-74.72064613499992, 10.912518094000063],
              [-74.72055617599995, 10.912437905000047],
              [-74.7204669969999 , 10.912355570000045],
              [-74.72037831099993, 10.912270809000063],
              [-74.72029055099995, 10.912184024000055],
              [-74.7202037969999 , 10.91209529200006 ],
              [-74.72011813099994, 10.91200469100005 ],
              [-74.72003362299995, 10.91191229900005 ],
              [-74.7199503729999 , 10.911818225000047],
              [-74.71986845799995, 10.911722553000061],
              [-74.71978796799993, 10.911625395000044],
              [-74.71970894999993, 10.911526800000047],
              [-74.71963153899992, 10.911426936000055],
              [-74.71955581999993, 10.911325913000042],
              [-74.71948189299991, 10.911223857000039],
              [-74.71940983799993, 10.911120891000053],
              [-74.71933975399992, 10.911017147000052],
              [-74.7192717289999 , 10.910912757000062],
              [-74.71920585499993, 10.910807865000038],
              [-74.71895889399991, 10.910414045000039],
              [-74.7189065529999 , 10.910329735000062],
              [-74.71887596099992, 10.910279453000044],
              [-74.71882494699992, 10.910195152000085],
              [-74.71879588599995, 10.91014573800004 ],
              [-74.71875557699991, 10.91007647300006 ],
              [-74.71869913499995, 10.90997612700005 ],
              [-74.71868202799993, 10.909944694000046],
              [-74.71866794699991, 10.909918582000046],
              [-74.71864860099993, 10.909881842000061],
              [-74.71863613999994, 10.90985801100004 ],
              [-74.7186163099999 , 10.90981897100005 ],
              [-74.7186061569999 , 10.909798892000083],
              [-74.71857803199993, 10.90974129600005 ],
              [-74.71857773099993, 10.909740662000047],
              [-74.71855135599992, 10.90968428800005 ],
              [-74.71854350599995, 10.90966681000009 ],
              [-74.7185265409999 , 10.909628833000056],
              [-74.71851459299995, 10.909600911000041],
              [-74.71850238899992, 10.909572170000047],
              [-74.71849308499992, 10.909549224000045],
              [-74.7184789769999 , 10.90951403400004 ],
              [-74.7184698499999 , 10.90949015700005 ],
              [-74.71845738799993, 10.909456977000048],
              [-74.71844868799991, 10.909432691000063],
              [-74.71843755499992, 10.909400824000045],
              [-74.71842938899994, 10.909376334000058],
              [-74.71841940399992, 10.90934536900005 ],
              [-74.71841192499994, 10.909321095000053],
              [-74.71840292599995, 10.909290555000041],
              [-74.71839616699992, 10.90926660200006 ],
              [-74.7183881119999 , 10.909236331000045],
              [-74.71838215399993, 10.909212977000038],
              [-74.71837496699993, 10.909182625000085],
              [-74.71836979199992, 10.909159939000062],
              [-74.7183634029999 , 10.909129007000047],
              [-74.71835913299992, 10.909107651000056],
              [-74.71835337399995, 10.909074890000056],
              [-74.7183501259999 , 10.909055927000054],
              [-74.71834288699995, 10.909005655000044],
              [-74.71834278299991, 10.909004822000043],
              [-74.71833712299991, 10.908954254000037],
              [-74.71833607399992, 10.90894131500005 ],
              [-74.71833314999992, 10.908904226000061],
              [-74.71833222799995, 10.908885835000092],
              [-74.71833083099995, 10.908854727000062],
              [-74.71833046499995, 10.908834608000063],
              [-74.71833013999992, 10.90880327900004 ],
              [-74.71833020299994, 10.908795205000047],
              [-74.7183312919999 , 10.90875139800005 ],
              [-74.71833160299991, 10.908745038000063],
              [-74.71833438399995, 10.90869945500009 ],
              [-74.71834063099993, 10.90863803700006 ],
              [-74.71834989399991, 10.908573477000061],
              [-74.7183622519999 , 10.908505167000044],
              [-74.71837792799994, 10.908431920000055],
              [-74.71841411399993, 10.908287697000048],
              [-74.71849674299995, 10.907989590000057],
              [-74.71852473699994, 10.907888411000044],
              [-74.71855473399995, 10.90778769800005 ],
              [-74.71858672999991, 10.90768743700005 ],
              [-74.71862072899995, 10.90758761300009 ],
              [-74.7186567359999 , 10.907488220000062],
              [-74.71869475599993, 10.907389245000047],
              [-74.71873479299995, 10.907290673000091],
              [-74.71877685299995, 10.90719249500006 ],
              [-74.7188209329999 , 10.907094702000052],
              [-74.71886705299994, 10.906997268000055],
              [-74.71891521399994, 10.906900189000055],
              [-74.71896542399992, 10.906803445000037],
              [-74.7190176869999 , 10.906707015000052],
              [-74.71907202299991, 10.906610887000056],
              [-74.71912843199993, 10.906515039000055],
              [-74.7191869259999 , 10.906419457000084],
              [-74.71924743499994, 10.906324256000062],
              [-74.71931005099992, 10.906229278000069],
              [-74.71937478299992, 10.90613450300009 ],
              [-74.71944165199994, 10.906039905000057],
              [-74.71951067599991, 10.905945465000059],
              [-74.71958187199994, 10.90585115500005 ],
              [-74.71973086399993, 10.90566281200006 ],
              [-74.71988875799991, 10.905474698000091],
              [-74.72005584099992, 10.905286477000061],
              [-74.7202323809999 , 10.905097840000053],
              [-74.72041874199994, 10.904908403000093],
              [-74.72057273199994, 10.904758332000085],
              [-74.72073334899994, 10.904607231000057],
              [-74.7209009149999 , 10.904454800000053],
              [-74.72107583299993, 10.904300670000055],
              [-74.7212583779999 , 10.904144589000055],
              [-74.72144941899995, 10.903985805000048],
              [-74.72164976099992, 10.90382364900006 ],
              [-74.72186062499992, 10.903657131000045],
              [-74.72204816499993, 10.903512111000055],
              [-74.72224584999992, 10.903361922000045],
              [-74.7224556079999 , 10.903205099000047],
              [-74.72268120699994, 10.903038815000059],
              [-74.72311592699992, 10.90272344500005 ],
              [-74.72423240799992, 10.90192206000006 ],
              [-74.72455190099993, 10.901688983000042],
              [-74.72483568199993, 10.901478317000056],
              [-74.72515007399994, 10.901239385000054],
              [-74.7254382449999 , 10.901013681000052],
              [-74.72570571499995, 10.900796873000047],
              [-74.7259552559999 , 10.900586703000045],
              [-74.72611058099994, 10.900455127000043],
              [-74.7262710409999 , 10.900323083000046],
              [-74.72643695599993, 10.900190291000058],
              [-74.72660875199995, 10.900056421000045],
              [-74.72678671799991, 10.899921242000062],
              [-74.72697172099993, 10.899784083000043],
              [-74.72716457799993, 10.89964433700004 ],
              [-74.72736650299993, 10.89950113100008 ],
              [-74.72772969599993, 10.899250210000048],
              [-74.72813762699991, 10.898976184000048],
              [-74.72854236199993, 10.898709711000038],
              [-74.72876918399993, 10.898561769000082],
              [-74.7295860449999 , 10.898028981000039],
              [-74.72988737899993, 10.897829609000041],
              [-74.73015671999991, 10.89764868800006 ],
              [-74.73045610699995, 10.89744349700004 ],
              [-74.73073338499995, 10.897248580000053],
              [-74.73099373999992, 10.897060270000054],
              [-74.73123981099991, 10.89687666900005 ],
              [-74.7313838039999 , 10.89676630200006 ],
              [-74.73152356199995, 10.896656878000044],
              [-74.73165936599992, 10.896548167000049],
              [-74.73179143899995, 10.896439993000058],
              [-74.73192001699994, 10.896332164000057],
              [-74.73204521799994, 10.896224574000087],
              [-74.73216719899995, 10.896117099000037],
              [-74.73228607099992, 10.896009636000088],
              [-74.73240209999994, 10.895901934000051],
              [-74.7325152219999 , 10.895794051000053],
              [-74.7326255129999 , 10.89568591200009 ],
              [-74.73273304899993, 10.89557744700005 ],
              [-74.73283790399995, 10.895468570000048],
              [-74.73294013199995, 10.895359237000037],
              [-74.73303978299992, 10.89524939100005 ],
              [-74.73313690399993, 10.895138976000055],
              [-74.7334152009999 , 10.894813181000075],
              [-74.73353857799992, 10.894665062000058],
              [-74.73365434699991, 10.894523328000048],
              [-74.73376437499991, 10.894385660000069],
              [-74.73386867599993, 10.894252012000038],
              [-74.7339678379999 , 10.894121640000037],
              [-74.7340622289999 , 10.893994046000046],
              [-74.73414234699993, 10.893882545000054],
              [-74.73422159599994, 10.893769004000092],
              [-74.73430000499991, 10.893653395000058],
              [-74.73437759799992, 10.893535683000039],
              [-74.73445439499994, 10.893415833000063],
              [-74.73453042599994, 10.893293794000044],
              [-74.73468031099992, 10.893042972000046],
              [-74.73482742399995, 10.892782942000053],
              [-74.73497216099992, 10.892513011000062],
              [-74.73511487199994, 10.892232547000049],
              [-74.7352560029999 , 10.89194064500009 ],
              [-74.73539258699992, 10.891644021000047],
              [-74.73552883999992, 10.891334047000043],
              [-74.73566555299993, 10.89100893200009 ],
              [-74.7358040069999 , 10.890665652000052],
              [-74.73594312499995, 10.89030725400005 ],
              [-74.73608849999994, 10.88991971900009 ],
              [-74.73624373199993, 10.889493621000042],
              [-74.73636731499994, 10.889146649000054],
              [-74.7364262239999 , 10.888981253000054],
              [-74.73650231999994, 10.888761198000054],
              [-74.73657696999993, 10.888535816000058],
              [-74.73665030599994, 10.88830471700004 ],
              [-74.73672248299994, 10.88806739800009 ],
              [-74.73679361899991, 10.887823508000054],
              [-74.73686399899992, 10.88757206200006 ],
              [-74.73693387599991, 10.887312163000047],
              [-74.73700360599992, 10.887042500000064],
              [-74.7370688769999 , 10.886780758000043],
              [-74.7371348819999 , 10.886507099000085],
              [-74.73720224899995, 10.886218971000062],
              [-74.73727210399994, 10.885911503000045],
              [-74.7374103969999 , 10.885281685000052],
              [-74.73765381999993, 10.884142910000037],
              [-74.73771220599991, 10.88386015900005 ],
              [-74.73772704899994, 10.883785165000063],
              [-74.7377754119999 , 10.883531039000047],
              [-74.73778195999995, 10.883496         ],
              [-74.73783206399992, 10.88320886400004 ],
              [-74.7378443419999 , 10.883134639000048],
              [-74.73788338999992, 10.882889116000058],
              [-74.73789188799992, 10.88283425800006 ],
              [-74.73793405999993, 10.882541303000039],
              [-74.73794358099991, 10.882470475000048],
              [-74.73797537399992, 10.882224474000054],
              [-74.73798383899992, 10.882156447000057],
              [-74.7380183269999 , 10.881856266000057],
              [-74.73802501099993, 10.88179264200005 ],
              [-74.73805067699993, 10.88153828500009 ],
              [-74.7380580489999 , 10.881461137000088],
              [-74.7380850209999 , 10.881152214000053],
              [-74.7380887999999 , 10.881103344000053],
              [-74.73810900899991, 10.88083061900005 ],
              [-74.7381146919999 , 10.880747377000091],
              [-74.73813431099995, 10.88042725300005 ],
              [-74.73813529099994, 10.880408         ],
              [-74.73815034299992, 10.880099734000055],
              [-74.73815403799995, 10.880013347000045],
              [-74.73816549899993, 10.879701710000063],
              [-74.73816624199992, 10.87968023700006 ],
              [-74.73817468399994, 10.879344222000043],
              [-74.73817635199993, 10.879255952000051],
              [-74.73818050299991, 10.878956969000058],
              [-74.73818106899995, 10.878906907000044],
              [-74.73818212999993, 10.878559801000051],
              [-74.73818175699995, 10.878445441000054],
              [-74.73818078799991, 10.878284287000042],
              [-74.73817948599992, 10.878157327000054],
              [-74.73817729599995, 10.877994322000063],
              [-74.73817510099991, 10.87786516500006 ],
              [-74.73817167399994, 10.877699322000069],
              [-74.73816859599992, 10.877568575000055],
              [-74.73816389099994, 10.87739814300005 ],
              [-74.73815997199995, 10.877267157000063],
              [-74.73815666199994, 10.877170558000046],
              [-74.73813589799994, 10.876638104000051],
              [-74.73813338499991, 10.876584498000057],
              [-74.73810402799995, 10.876001124000084],
              [-74.7387774959999 , 10.877506439000058],
              [-74.73883056799991, 10.87767417300006 ],
              [-74.73888553499995, 10.877907248000042],
              [-74.73897691299993, 10.87819406500006 ],
              [-74.7390499949999 , 10.878415141000062],
              [-74.7391413279999 , 10.878678041000057],
              [-74.73936618499994, 10.87913797400006 ],
              [-74.73954236799995, 10.87947243800005 ],
              [-74.73974870299992, 10.87976498100005 ],
              [-74.73984593499995, 10.87996208900006 ],
              [-74.73994928199994, 10.880189084000051],
              [-74.74003461899991, 10.880322616000058],
              [-74.74008284299993, 10.880398074000084],
              [-74.74019805499995, 10.880523392000043],
              [-74.74031947799995, 10.880714468000065],
              [-74.7404529829999 , 10.880899543000055],
              [-74.7405987059999 , 10.881138400000054],
              [-74.74075055999992, 10.881413124000062],
              [-74.74077730399995, 10.881460459000039],
              [-74.74087203399995, 10.881628118000037],
              [-74.74103582299995, 10.881831065000085],
              [-74.74107019399992, 10.88190424700008 ],
              [-74.7411452369999 , 10.882064024000044],
              [-74.7412484489999 , 10.882231224000066],
              [-74.7413456889999 , 10.882428330000039],
              [-74.7414247879999 , 10.882631462000063],
              [-74.74149775099994, 10.882798729000058],
              [-74.74157080399993, 10.883001869000054],
              [-74.74165593899994, 10.883199001000037],
              [-74.74172900399992, 10.883408126000063],
              [-74.74177187099991, 10.883641228000045],
              [-74.74181203199993, 10.88379853500004 ],
              [-74.74181459199991, 10.883808564000049],
              [-74.74182102899994, 10.883981952000056],
              [-74.74249858699994, 10.88398046900005 ],
              [-74.74332141199994, 10.88401454700005 ],
              [-74.74344244799994, 10.88403222100004 ],
              [-74.74388417799992, 10.884079090000057],
              [-74.7443198599999 , 10.884125967000045],
              [-74.74442177599991, 10.883713168000043],
              [-74.74497543699994, 10.883596690000047],
              [-74.74557066499995, 10.88347146700005 ],
              [-74.74587301499992, 10.883411013000057],
              [-74.74567414299992, 10.883752276000052],
              [-74.7455475829999 , 10.883967811000048],
              [-74.74544522299993, 10.88418329700005 ],
              [-74.74525218399992, 10.884428875000083],
              [-74.74514368399991, 10.884602519000055],
              [-74.7450658759999 , 10.884737794000046],
              [-74.74502315899991, 10.884812061000048],
              [-74.74486043999991, 10.885087474000045],
              [-74.74514489299992, 10.885140665000051],
              [-74.74525461399992, 10.885147420000067],
              [-74.7462702869999 , 10.885209949000057],
              [-74.74713552899993, 10.88527381700004 ],
              [-74.74768008399991, 10.88530849600005 ],
              [-74.74819422299993, 10.88527148500009 ],
              [-74.74875673999992, 10.885228391000055],
              [-74.74899872899994, 10.88522785400005 ],
              [-74.74927096099992, 10.88522725300004 ],
              [-74.74948269999993, 10.885226784000054],
              [-74.74990617399993, 10.885225851000087],
              [-74.75003933399995, 10.88525545400006 ],
              [-74.7503298499999 , 10.88531460200005 ],
              [-74.75063842299994, 10.885331861000054],
              [-74.7507957279999 , 10.885337494000055],
              [-74.75089795899993, 10.885352122000086],
              [-74.75100148599995, 10.885366936000082],
              [-74.75120109199992, 10.885354533000054],
              [-74.7513885109999 , 10.885300305000044],
              [-74.75154562499995, 10.885222223000085],
              [-74.75175116799994, 10.885155994000058],
              [-74.75217437899994, 10.88504144600006 ],
              [-74.75239208499994, 10.885005085000046],
              [-74.75284564599991, 10.884932329000037],
              [-74.75335973099993, 10.884871391000047],
              [-74.75388603499994, 10.884864243000038],
              [-74.75415238199992, 10.884935403000043],
              [-74.75456383499994, 10.88497036800004 ],
              [-74.75499350899992, 10.885035182000081],
              [-74.75565922599992, 10.885147309000047],
              [-74.7559134359999 , 10.885200556000086],
              [-74.75625848499993, 10.885295461000055],
              [-74.75649460799991, 10.88537864400007 ],
              [-74.75673072199993, 10.885455849000039],
              [-74.75705757899993, 10.88553285200004 ],
              [-74.7573905029999 , 10.885615820000055],
              [-74.7577719869999 , 10.885770433000062],
              [-74.75818987499991, 10.885972799000058],
              [-74.7582686749999 , 10.88601625800004 ],
              [-74.75829723999993, 10.886032012000044],
              [-74.75868657799992, 10.886246740000047],
              [-74.7591773389999 , 10.88656853200007 ],
              [-74.75963764799991, 10.88680069500009 ],
              [-74.75998296099993, 10.88700920000008 ],
              [-74.76063711799992, 10.887354541000093],
              [-74.76110355799995, 10.887622566000061],
              [-74.7613276479999 , 10.887729691000061],
              [-74.7616667609999 , 10.887872432000052],
              [-74.76216325599995, 10.88805668000009 ],
              [-74.76258107699994, 10.888223161000042],
              [-74.76261182199994, 10.888230480000061],
              [-74.763029        , 10.888329785000053],
              [-74.76349174299992, 10.888424414000042],
              [-74.76359804399993, 10.88848994500006 ],
              [-74.76368899699992, 10.888579434000064],
              [-74.76376794599992, 10.88871080000007 ],
              [-74.76382873199992, 10.88883622800006 ],
              [-74.76384137199994, 10.888875186000064],
              [-74.76387138399991, 10.888967681000054],
              [-74.76391396799994, 10.889069236000068],
              [-74.76394432199993, 10.889117001000045],
              [-74.76397011899991, 10.88917852700007 ],
              [-74.76397313899992, 10.889185731000055],
              [-74.76398690299993, 10.88921855700005 ],
              [-74.7639876739999 , 10.889220503000047],
              [-74.76398767299992, 10.889231634000055],
              [-74.76399555099994, 10.889240387000086],
              [-74.76400589799994, 10.889251884000089],
              [-74.76401979699995, 10.889267326000038],
              [-74.76402434899995, 10.889271309000037],
              [-74.76404834999994, 10.889292310000087],
              [-74.76410545699991, 10.889360125000053],
              [-74.76416526899993, 10.889390031000062],
              [-74.76419111799993, 10.889402955000037],
              [-74.76420539499992, 10.889431509000076],
              [-74.76421253299992, 10.889542154000083],
              [-74.76425893099992, 10.88962424500005 ],
              [-74.76425893099992, 10.889663505000044],
              [-74.7642553629999 , 10.889717044000065],
              [-74.76428034799994, 10.889763444000039],
              [-74.76429462399994, 10.889784859000088],
              [-74.76430889999995, 10.889834827000072],
              [-74.76430889999995, 10.889913350000086],
              [-74.76430889999995, 10.889949042000069],
              [-74.76428034799994, 10.890009717000055],
              [-74.76426250199995, 10.890034702000037],
              [-74.76424329299994, 10.890063515000065],
              [-74.76423394799991, 10.890088240000068],
              [-74.76424346599993, 10.890111083000079],
              [-74.76426440499995, 10.890120601000092],
              [-74.76427963399993, 10.890141540000059],
              [-74.76428153699993, 10.89017580400008 ],
              [-74.76429105499994, 10.890204358000062],
              [-74.7643005729999 , 10.890223393000042],
              [-74.76431580199994, 10.89024052600007 ],
              [-74.7643386439999 , 10.890257658000053],
              [-74.76436719799995, 10.890299536000043],
              [-74.76437481199991, 10.89031857200007 ],
              [-74.76440146099992, 10.890347125000062],
              [-74.76443762899993, 10.890392811000083],
              [-74.76445666599994, 10.890411847000053],
              [-74.76447569999993, 10.89042517200005 ],
              [-74.76449430899993, 10.89042517200005 ],
              [-74.76451257999992, 10.890446128000065],
              [-74.76455722799994, 10.89049734200006 ],
              [-74.76465013199993, 10.89061504700004 ],
              [-74.76477791199994, 10.890606464000086],
              [-74.76497539199994, 10.890593592000073],
              [-74.76513220899994, 10.890586451000047],
              [-74.7652629019999 , 10.89056495400007 ],
              [-74.76542844399995, 10.89052767700008 ],
              [-74.76561576099994, 10.89049327500004 ],
              [-74.76583503299992, 10.89043878800004 ],
              [-74.76593522699994, 10.890405803000078],
              [-74.76619225899992, 10.890316882000093],
              [-74.76652625399993, 10.890212190000057],
              [-74.76683700899991, 10.890121843000088],
              [-74.76684834699995, 10.89011800500009 ],
              [-74.7670954909999 , 10.890034356000058],
              [-74.7673249259999 , 10.889976999000055],
              [-74.76739752799995, 10.88996983900006 ],
              [-74.76746141699994, 10.889972725000064],
              [-74.76757757099995, 10.89001006300009 ],
              [-74.76773994299992, 10.89006426700007 ],
              [-74.76778520499994, 10.890079377000063],
              [-74.76778903799993, 10.890080657000055],
              [-74.76780987599994, 10.890087613000048],
              [-74.76782582399994, 10.890085058000068],
              [-74.7680872279999 , 10.890043182000056],
              [-74.76840232899991, 10.889997325000081],
              [-74.7687261399999 , 10.889952905000087],
              [-74.76891636399995, 10.889927111000077],
              [-74.76909837699992, 10.889911507000079],
              [-74.76911674599995, 10.88990993200008 ],
              [-74.76937375799992, 10.889912859000049],
              [-74.76957241899993, 10.889915665000046],
              [-74.76957945099991, 10.88991576400008 ],
              [-74.76957994299994, 10.889915771000062],
              [-74.76966062299994, 10.889921626000046],
              [-74.76976903399992, 10.889929493000068],
              [-74.7697912079999 , 10.889931102000048],
              [-74.76980455799992, 10.889757691000057],
              [-74.76980791999995, 10.889719304000039],
              [-74.76980852499992, 10.889712400000064],
              [-74.76981067999992, 10.889687798000068],
              [-74.76983406599993, 10.88942081600004 ],
              [-74.76983529299991, 10.889406812000061],
              [-74.76983735199991, 10.889383310000085],
              [-74.76985065199995, 10.889231477000067],
              [-74.76988948399992, 10.888702872000067],
              [-74.76991252899995, 10.888460097000063],
              [-74.7699125549999 , 10.888337746000047],
              [-74.76991255899992, 10.888318949000052],
              [-74.7699126089999 , 10.888086957000041],
              [-74.76989290699993, 10.887896253000065],
              [-74.76989129299994, 10.887880626000083],
              [-74.76988604399992, 10.887829821000082],
              [-74.7698752849999 , 10.887770195000087],
              [-74.76987013899992, 10.887741675000086],
              [-74.76983770099991, 10.887561915000049],
              [-74.76980360499994, 10.88737530800006 ],
              [-74.76980060199992, 10.88735887100006 ],
              [-74.76971925899994, 10.886913680000077],
              [-74.76961773099993, 10.886386241000082],
              [-74.76956826399993, 10.886131486000068],
              [-74.7695681969999 , 10.886131143000057],
              [-74.76956454899994, 10.88611235500008 ],
              [-74.76955474099992, 10.886047224000038],
              [-74.7695544149999 , 10.886041352000063],
              [-74.76955179499993, 10.88602766200006 ],
              [-74.76954302099995, 10.885969402000057],
              [-74.76953183199993, 10.885895101000074],
              [-74.76953145699991, 10.885892614000056],
              [-74.76952010899993, 10.885817263000092],
              [-74.76951430999992, 10.885777077000057],
              [-74.76950848999991, 10.885744569000053],
              [-74.76950839599994, 10.885744044000091],
              [-74.76945381799993, 10.885439166000083],
              [-74.7693837619999 , 10.884861502000092],
              [-74.76936804799993, 10.884735395000064],
              [-74.76936081199995, 10.884677320000037],
              [-74.76935002499994, 10.884203717000048],
              [-74.76934017199994, 10.883923701000072],
              [-74.76933891099992, 10.883887850000065],
              [-74.76932717499994, 10.883554306000065],
              [-74.76930611999995, 10.883004160000041],
              [-74.76931229999991, 10.882403191000037],
              [-74.76933962999993, 10.881915843000058],
              [-74.76947597799995, 10.880884356000081],
              [-74.76948575999995, 10.880786276000038],
              [-74.76949915599994, 10.880791996000085],
              [-74.76957993999991, 10.88082649000006 ],
              [-74.76960863099993, 10.880838740000058],
              [-74.76972941499992, 10.880848884000045],
              [-74.7698131969999 , 10.88087242000006 ],
              [-74.76989457199994, 10.880895279000072],
              [-74.76997512399993, 10.880917900000043],
              [-74.77005508399992, 10.880940359000078],
              [-74.77013758699991, 10.880963535000092],
              [-74.77022766899995, 10.880988483000067],
              [-74.77030900699992, 10.881011006000051],
              [-74.77039472799993, 10.881034745000079],
              [-74.77042271399995, 10.881042497000067],
              [-74.7704836069999 , 10.88104119500008 ],
              [-74.7705550519999 , 10.881039663000081],
              [-74.77057348699992, 10.881039268000052],
              [-74.77066150699994, 10.881032057000084],
              [-74.77075203999993, 10.881024636000063],
              [-74.77083489899991, 10.88101733600007 ],
              [-74.7708421129999 , 10.881016702000068],
              [-74.77091855499992, 10.881009967000068],
              [-74.77100081799995, 10.881002722000062],
              [-74.7710843019999 , 10.880995368000072],
              [-74.77117644099991, 10.880987253000058],
              [-74.77131107399993, 10.88097539100005 ],
              [-74.77131266799995, 10.880975379000063],
              [-74.77142968999993, 10.880974507000076],
              [-74.77154083599993, 10.881018159000064],
              [-74.77161426899994, 10.88104700100007 ],
              [-74.771697        , 10.881075089000092],
              [-74.7717798189999 , 10.881095795000078],
              [-74.77186225899993, 10.881116404000068],
              [-74.77194582899995, 10.881137299000045],
              [-74.77202690799993, 10.881157570000084],
              [-74.7721007959999 , 10.88116227800009 ],
              [-74.77217129499991, 10.88116677100004 ],
              [-74.77225866399993, 10.881172338000056],
              [-74.77231320499993, 10.881175814000073],
              [-74.77245729499992, 10.881186827000079],
              [-74.77260740699995, 10.881198300000051],
              [-74.77274771399993, 10.881201254000075],
              [-74.77278290499993, 10.881201994000037],
              [-74.77281516499994, 10.881202674000065],
              [-74.77288079199991, 10.881204058000037],
              [-74.77310738199992, 10.881196652000085],
              [-74.7731271319999 , 10.881196007000085],
              [-74.77317381599994, 10.88119448100008 ],
              [-74.77322649399991, 10.881199896000055],
              [-74.77324212699995, 10.881195474000037],
              [-74.77325775899993, 10.881191053000066],
              [-74.77329183599994, 10.881190142000037],
              [-74.77330041899995, 10.881189913000071],
              [-74.77332255199991, 10.881189319000043],
              [-74.77337743699991, 10.88118785100005 ],
              [-74.77343224499992, 10.881193103000044],
              [-74.77348723099993, 10.881198372000085],
              [-74.77354538799995, 10.88120394100008 ],
              [-74.7735993469999 , 10.881209112000079],
              [-74.77365668399995, 10.881214605000082],
              [-74.77371089799993, 10.881223478000038],
              [-74.77374300499991, 10.881237242000054],
              [-74.77375735599992, 10.88124339400008 ],
              [-74.7737650169999 , 10.881245618000037],
              [-74.77377751999995, 10.881249248000074],
              [-74.7738205039999 , 10.881261719000065],
              [-74.7739434689999 , 10.88129740000005 ],
              [-74.7739145459999 , 10.881329639000057],
              [-74.77387674699992, 10.881371784000066],
              [-74.77386886999994, 10.881380566000075],
              [-74.77383959799994, 10.88141319600004 ],
              [-74.77380231299992, 10.881454767000037],
              [-74.77377158399992, 10.881501075000074],
              [-74.7737421569999 , 10.88154542600006 ],
              [-74.77371158599993, 10.881591496000055],
              [-74.77368242099993, 10.88163545100008 ],
              [-74.77366923499994, 10.881655325000054],
              [-74.77365120399992, 10.881682497000043],
              [-74.7736221589999 , 10.88172626700009 ],
              [-74.7736070819999 , 10.881748992000041],
              [-74.77361998399994, 10.881758452000042],
              [-74.77365560399994, 10.881784569000047],
              [-74.7737154319999 , 10.88179935800008 ],
              [-74.77378266699992, 10.881815978000077],
              [-74.77435373799995, 10.88200157500006 ],
              [-74.7746521219999 , 10.882154337000088],
              [-74.77465354999993, 10.882342791000042],
              [-74.77468210299992, 10.882367061000082],
              [-74.77488461799993, 10.882398378000062],
              [-74.77495907299993, 10.882409892000055],
              [-74.77499979699991, 10.882404891000078],
              [-74.77512182799995, 10.882389903000046],
              [-74.77524460799992, 10.882382765000045],
              [-74.77540593499992, 10.882419884000058],
              [-74.77542123299992, 10.88242095000004 ],
              [-74.77559018999995, 10.882432726000047],
              [-74.77587706899993, 10.88245272100005 ],
              [-74.77645384999994, 10.882481274000043],
              [-74.77677793299995, 10.882594061000077],
              [-74.77709344899995, 10.882736829000066],
              [-74.7771091539999 , 10.882818206000081],
              [-74.77719481399993, 10.882975250000072],
              [-74.77734471999992, 10.883217957000056],
              [-74.7774542869999 , 10.883296485000074],
              [-74.77746256699993, 10.883241618000056],
              [-74.77746695499991, 10.88321254200008 ],
              [-74.77746916099994, 10.88319792100009 ],
              [-74.77748530299993, 10.883053610000047],
              [-74.777499        , 10.883011464000049],
              [-74.77771612599992, 10.882437419000041],
              [-74.77790053399991, 10.88192881000009 ],
              [-74.77816227499994, 10.881253639000079],
              [-74.77859652699993, 10.880063908000068],
              [-74.7786509199999 , 10.879909026000064],
              [-74.77868256699992, 10.87990095300006 ],
              [-74.7786935069999 , 10.879870064000045],
              [-74.77879276399995, 10.87958980600007 ],
              [-74.7787928539999 , 10.879589552000084],
              [-74.77880109299991, 10.87956629200005 ],
              [-74.77877201199993, 10.879564220000077],
              [-74.77716587599991, 10.879353043000037],
              [-74.77588096699992, 10.879204327000082],
              [-74.77575009599991, 10.87918499500006 ],
              [-74.77541846799994, 10.879132828000081],
              [-74.7754273619999 , 10.87909922700004 ],
              [-74.77557908099993, 10.878526065000074],
              [-74.77558347999991, 10.878510400000039],
              [-74.77564476399994, 10.878292152000085],
              [-74.77579642199993, 10.877752065000038],
              [-74.7758330449999 , 10.877621643000055],
              [-74.77587056499993, 10.877488025000048],
              [-74.77665578699992, 10.877339310000082],
              [-74.77664686399993, 10.87725602800009 ],
              [-74.77697998899993, 10.877184644000067],
              [-74.77745274699993, 10.87709086700005 ],
              [-74.77768359499993, 10.87704507400008 ],
              [-74.77821016999991, 10.876940621000074],
              [-74.77833919199992, 10.876915028000042],
              [-74.7784344339999 , 10.876896135000038],
              [-74.77954970799993, 10.876661340000055],
              [-74.77956467799993, 10.876658188000079],
              [-74.77954881699992, 10.876468944000067],
              [-74.77947842299994, 10.875629072000038],
              [-74.77942785899995, 10.874989592000077],
              [-74.77950816599991, 10.87488251600007 ],
              [-74.77961226799994, 10.874823029000083],
              [-74.77958549899995, 10.874704056000041],
              [-74.77960152399993, 10.87470049500007 ],
              [-74.77963903699992, 10.874692158000073],
              [-74.77979072699992, 10.87462077500004 ],
              [-74.78006733899991, 10.874537494000037],
              [-74.78028743999994, 10.874501801000065],
              [-74.78048969399993, 10.874460162000048],
              [-74.78128086499993, 10.874457188000065],
              [-74.78143850399994, 10.874475033000067],
              [-74.78145634999993, 10.874570212000037],
              [-74.7814682469999 , 10.874629697000046],
              [-74.78148311899992, 10.874781389000077],
              [-74.78149204199991, 10.874918207000064],
              [-74.78149501599995, 10.87503718000005 ],
              [-74.78153963099993, 10.87516805100006 ],
              [-74.78155093299995, 10.875187331000063],
              [-74.78159019499992, 10.875254306000045],
              [-74.7816689469999 , 10.875348810000048],
              [-74.7817091679999 , 10.87539707500008 ],
              [-74.78186680699991, 10.87547143200004 ],
              [-74.78211367599994, 10.875566610000078],
              [-74.7829145099999 , 10.876581913000052],
              [-74.78297789099992, 10.876662269000064],
              [-74.78307854699995, 10.876789881000093],
              [-74.78315766399993, 10.876890186000082],
              [-74.78354135299992, 10.877345257000059],
              [-74.7838358109999 , 10.87766945900006 ],
              [-74.78413919199994, 10.878047199000036],
              [-74.78429385699991, 10.878246478000051],
              [-74.78443959899994, 10.878341657000078],
              [-74.78455559799994, 10.878386273000046],
              [-74.78467457099993, 10.878383298000074],
              [-74.78473830399992, 10.878342363000058],
              [-74.78475990399994, 10.878328489000069],
              [-74.78476765999994, 10.878322035000053],
              [-74.78487829699992, 10.878229978000093],
              [-74.78494032799995, 10.878178364000064],
              [-74.78507351899992, 10.87809969600005 ],
              [-74.78518446099991, 10.878034168000056],
              [-74.78522300799995, 10.878004187000045],
              [-74.78524727899992, 10.87791995300006 ],
              [-74.78524156799995, 10.877801456000043],
              [-74.78524156799995, 10.877721506000057],
              [-74.78524156799995, 10.877677248000055],
              [-74.7852715489999 , 10.87764155700006 ],
              [-74.78531866299994, 10.87762728000007 ],
              [-74.78536434799992, 10.87763584600009 ],
              [-74.7854628579999 , 10.87762014100008 ],
              [-74.78563349599995, 10.877580235000039],
              [-74.78563988999991, 10.877578739000057],
              [-74.78588316999992, 10.877497186000085],
              [-74.78589116099994, 10.877494507000051],
              [-74.78593390099991, 10.877474593000045],
              [-74.78601679699995, 10.877435972000058],
              [-74.78605665299995, 10.877422051000053],
              [-74.78626664799992, 10.87745407400007 ],
              [-74.78670576899992, 10.877521038000054],
              [-74.78683906399993, 10.87759481200004 ],
              [-74.78700843699994, 10.877688552000052],
              [-74.78707422799994, 10.877508204000037],
              [-74.78717023999991, 10.877245019000043],
              [-74.78726424599995, 10.876977694000061],
              [-74.7872671959999 , 10.876969304000056],
              [-74.78729206899993, 10.876898570000037],
              [-74.78731681499994, 10.876729152000053],
              [-74.78732119199992, 10.876692766000076],
              [-74.78734727199992, 10.876475978000087],
              [-74.7873758259999 , 10.876194249000037],
              [-74.78741580099995, 10.875942978000069],
              [-74.78741887399991, 10.875928122000062],
              [-74.7874272219999 , 10.87588777500008 ],
              [-74.78753258499995, 10.875774798000066],
              [-74.78766937599994, 10.87557092700007 ],
              [-74.78769830699991, 10.875527808000072],
              [-74.78778004899993, 10.875405982000075],
              [-74.78796545299991, 10.875125497000056],
              [-74.78796564599992, 10.875125205000074],
              [-74.78876990399993, 10.87537504800008 ],
              [-74.78903671799992, 10.87546429300005 ],
              [-74.7893955859999 , 10.875584329000048],
              [-74.78945994799994, 10.87560585700004 ],
              [-74.7897446209999 , 10.875709695000069],
              [-74.78977435099995, 10.875720539000042],
              [-74.78979113899993, 10.87572666300008 ],
              [-74.78977435099995, 10.875750094000068],
              [-74.78957976899994, 10.876021669000068],
              [-74.7893777079999 , 10.87630368300006 ],
              [-74.78942529699992, 10.876345323000066],
              [-74.78934484099995, 10.87672714900009 ],
              [-74.78931800399994, 10.87685451100009 ],
              [-74.78924981199992, 10.877178135000065],
              [-74.78977435099995, 10.877610960000084],
              [-74.79021944199991, 10.877978229000064],
              [-74.7903770819999 , 10.87805258700007 ],
              [-74.79041063199992, 10.878013330000044],
              [-74.79042867499993, 10.877992218000088],
              [-74.79068400299991, 10.877693457000078],
              [-74.79077645099994, 10.877585282000041],
              [-74.79095409999991, 10.87737741400008 ],
              [-74.7910284589999 , 10.877356595000037],
              [-74.79113553499991, 10.877303056000073],
              [-74.79125153299992, 10.877225725000073],
              [-74.79129912299993, 10.877207878000092],
              [-74.79144783899994, 10.877240596000092],
              [-74.79162332399994, 10.87731197900007 ],
              [-74.79151624899993, 10.877457722000088],
              [-74.79142880299992, 10.877578479000078],
              [-74.79131220999994, 10.877709349000042],
              [-74.79114564699995, 10.877921122000089],
              [-74.79094815199994, 10.87819          ],
              [-74.79081953799994, 10.878333527000052],
              [-74.79080342499992, 10.878351508000037],
              [-74.7907839689999 , 10.878373219000082],
              [-74.7907006879999 , 10.878492192000067],
              [-74.7909505319999 , 10.878694446000054],
              [-74.79125748199993, 10.878946669000072],
              [-74.79155015599991, 10.879184615000042],
              [-74.79165723099993, 10.879291691000049],
              [-74.7916929239999 , 10.879358317000083],
              [-74.7917071999999 , 10.87940352600009 ],
              [-74.79170482099994, 10.879474909000066],
              [-74.79168340599995, 10.879536775000076],
              [-74.7916167809999 , 10.879643851000083],
              [-74.79139073199991, 10.879893695000078],
              [-74.79130982999993, 10.879986493000047],
              [-74.79128841499994, 10.880036462000078],
              [-74.79129793299995, 10.88008405100004 ],
              [-74.7914753579999 , 10.880176991000042],
              [-74.79189459999992, 10.880396598000061],
              [-74.79197962899991, 10.880459797000071],
              [-74.7919817639999 , 10.880460808000066],
              [-74.79213317199992, 10.880532469000059],
              [-74.79213426399991, 10.880532986000048],
              [-74.79215037499995, 10.880540611000072],
              [-74.79229895799995, 10.880205483000054],
              [-74.7923601629999 , 10.880230005000044],
              [-74.79259566799993, 10.88032437000004 ],
              [-74.79436386499992, 10.881043717000068],
              [-74.79534488699994, 10.881459968000058],
              [-74.79554964999994, 10.881076801000063],
              [-74.79562771199994, 10.88083744000005 ],
              [-74.79564563299994, 10.880741731000057],
              [-74.79565136899993, 10.880610173000036],
              [-74.7956571659999 , 10.880508509000038],
              [-74.79563872099993, 10.880382987000075],
              [-74.7956565149999 , 10.880239442000061],
              [-74.79569228499992, 10.880018119000056],
              [-74.79575835199995, 10.879820640000048],
              [-74.79587258599992, 10.87952669300006 ],
              [-74.79588268099991, 10.879343456000072],
              [-74.79597319399994, 10.878946451000047],
              [-74.79606371299991, 10.878554472000076],
              [-74.79655914499995, 10.877493512000058],
              [-74.79662534199991, 10.877453668000044],
              [-74.79674257099992, 10.877597021000042],
              [-74.79674266099994, 10.877597129000037],
              [-74.7968924839999 , 10.877780340000072],
              [-74.79722357599991, 10.878106032000062],
              [-74.7974289199999 , 10.877972137000086],
              [-74.79754827799991, 10.877894311000091],
              [-74.79842092899992, 10.877324686000065],
              [-74.79884174999995, 10.876936945000068],
              [-74.79935786099992, 10.876026615000058],
              [-74.80044035499992, 10.876285438000082],
              [-74.8004183139999 , 10.876331707000077],
              [-74.80029691999994, 10.876586543000087],
              [-74.80026303799991, 10.876657669000053],
              [-74.8001379289999 , 10.876921862000074],
              [-74.80013126699993, 10.876952754000058],
              [-74.80009888699993, 10.877102894000075],
              [-74.80008038999995, 10.877188662000037],
              [-74.80000222399991, 10.877380591000076],
              [-74.79997456699994, 10.877458403000048],
              [-74.79991471599993, 10.877617691000069],
              [-74.79986582099991, 10.87774771900007 ],
              [-74.79985319199994, 10.877781305000042],
              [-74.79979370299992, 10.877939623000088],
              [-74.79978152699994, 10.877972034000038],
              [-74.79989658599993, 10.878005834000078],
              [-74.79995867799994, 10.87802461800004 ],
              [-74.79996915799995, 10.878027788000054],
              [-74.79997904599992, 10.878030779000085],
              [-74.7999545269999 , 10.878087893000043],
              [-74.7999318869999 , 10.87814062800004 ],
              [-74.79990571199994, 10.878201600000068],
              [-74.79982057699993, 10.878358638000066],
              [-74.79980936099992, 10.878379308000092],
              [-74.79968802999991, 10.878603091000059],
              [-74.79961513699993, 10.87873757400007 ],
              [-74.79958184499992, 10.878798995000068],
              [-74.79956957899992, 10.878823474000058],
              [-74.79938873999993, 10.878720681000061],
              [-74.79925392199993, 10.878975521000086],
              [-74.79923264799993, 10.879015734000063],
              [-74.79938448599995, 10.879104012000084],
              [-74.79939635499994, 10.87911091300009 ],
              [-74.79942501799991, 10.879124670000067],
              [-74.79920423899995, 10.87951881500004 ],
              [-74.79919902499995, 10.879528123000057],
              [-74.79919462399994, 10.87955          ],
              [-74.79908470299995, 10.879737856000077],
              [-74.79907090699993, 10.87975250900007 ],
              [-74.79906355199995, 10.879760321000049],
              [-74.79919034599993, 10.879873234000058],
              [-74.79931907499991, 10.879986148000057],
              [-74.79948942399994, 10.880137336000075],
              [-74.79964718899993, 10.880283740000039],
              [-74.79976864399993, 10.880412191000062],
              [-74.79981366399994, 10.880459804000054],
              [-74.79997518499994, 10.880636214000049],
              [-74.80000278699993, 10.880664768000088],
              [-74.80016286699993, 10.880850143000089],
              [-74.80020004499994, 10.880893196000045],
              [-74.80031544699995, 10.881029476000037],
              [-74.80043680099993, 10.881172783000068],
              [-74.80046862099994, 10.881213778000074],
              [-74.80063239299994, 10.881424768000045],
              [-74.8006839009999 , 10.881446530000062],
              [-74.80073437499993, 10.881449312000086],
              [-74.80073443899994, 10.881449316000044],
              [-74.80074702799993, 10.881450010000037],
              [-74.80075866499993, 10.881469482000057],
              [-74.80077968599994, 10.881504654000082],
              [-74.80079664399994, 10.881524702000092],
              [-74.80092007399992, 10.881670622000058],
              [-74.80117408099994, 10.881952588000047],
              [-74.8012997429999 , 10.882062289000089],
              [-74.8013550739999 , 10.882101416000069],
              [-74.80140554299993, 10.882172296000078],
              [-74.80148150899993, 10.882265325000049],
              [-74.80151575999992, 10.88229424800005 ],
              [-74.80158654999991, 10.88235256900009 ],
              [-74.80159201299995, 10.882357070000069],
              [-74.80162035599994, 10.882380420000061],
              [-74.80164625899994, 10.882461913000043],
              [-74.80165538699993, 10.882490632000042],
              [-74.80209225599992, 10.882910369000058],
              [-74.80218470499995, 10.882981887000085],
              [-74.80226971199994, 10.883047649000048],
              [-74.80228981399995, 10.88306489300004 ],
              [-74.80286771999994, 10.883560635000038],
              [-74.80321326799992, 10.883843396000088],
              [-74.80351380699994, 10.884087402000091],
              [-74.8035767909999 , 10.884161650000067],
              [-74.80361101599993, 10.884183367000048],
              [-74.8036833139999 , 10.884218386000043],
              [-74.80384303699992, 10.88435605400008 ],
              [-74.80446931099993, 10.884911896000062],
              [-74.80482147099991, 10.885229792000075],
              [-74.8051679209999 , 10.885604795000063],
              [-74.80546794199995, 10.88592466700004 ],
              [-74.80547069499994, 10.885925808000081],
              [-74.80563267799994, 10.885992927000075],
              [-74.80573459499993, 10.885401325000089],
              [-74.80579170199991, 10.885151480000047],
              [-74.80588913399993, 10.884764458000063],
              [-74.8060552359999 , 10.884161024000036],
              [-74.8062689859999 , 10.883442244000037],
              [-74.80637308799993, 10.883077889000049],
              [-74.80643299899992, 10.882883511000045],
              [-74.80643773499992, 10.882850289000089],
              [-74.80646509299993, 10.882658402000061],
              [-74.80650673399992, 10.882532290000086],
              [-74.80675895699994, 10.881982635000043],
              [-74.80725864399994, 10.881035610000083],
              [-74.80752038399993, 10.880600169000047],
              [-74.80797485199992, 10.88032062700006 ],
              [-74.8082577479999 , 10.880292441000051],
              [-74.80865833799993, 10.88026990700007 ],
              [-74.80868927099993, 10.880249444000071],
              [-74.8087444169999 , 10.88022060500009 ],
              [-74.80886678799993, 10.880236387000082],
              [-74.80936663499995, 10.880196380000086],
              [-74.80963009699991, 10.88017228800004 ],
              [-74.80963112199993, 10.880193276000057],
              [-74.80963214699995, 10.880214266000053],
              [-74.80963422299993, 10.88025680000004 ],
              [-74.81060047699992, 10.880159479000042],
              [-74.81112601299992, 10.880105727000057],
              [-74.81119922799991, 10.880098240000052],
              [-74.81135607199991, 10.879918481000061],
              [-74.81172081099993, 10.879506183000046],
              [-74.81173437299992, 10.87949085300005 ],
              [-74.81173612899994, 10.879488869000056],
              [-74.81170135499991, 10.879488952000088],
              [-74.81169316099994, 10.879488972000047],
              [-74.8116918039999 , 10.879488976000061],
              [-74.81159283799991, 10.879489213000056],
              [-74.81209938199993, 10.87883787800007 ],
              [-74.8139110539999 , 10.87662090300006 ],
              [-74.81664561799994, 10.87690559500004 ],
              [-74.81876514399994, 10.877121440000053],
              [-74.81963430199994, 10.877209737000044],
              [-74.8199189419999 , 10.877239176000046],
              [-74.8208802339999 , 10.877593441000045],
              [-74.82094257799992, 10.877615897000055],
              [-74.82283329899991, 10.87829686300006 ],
              [-74.82477620999992, 10.87900029900004 ],
              [-74.82486752999995, 10.87901475700005 ],
              [-74.82485925599991, 10.879039585000044],
              [-74.82483608099994, 10.879109132000053],
              [-74.82460949499995, 10.879457571000046],
              [-74.82468025399993, 10.879457400000092],
              [-74.8245415959999 , 10.879647853000051],
              [-74.82436671699992, 10.879869517000088],
              [-74.8241314149999 , 10.88012122300006 ],
              [-74.82401740099994, 10.880265110000039],
              [-74.82390829199994, 10.88040280000007 ],
              [-74.82366112199992, 10.880744228000083],
              [-74.82359487899993, 10.880863976000057],
              [-74.82348149699993, 10.881061231000047],
              [-74.82345032199993, 10.881115460000046],
              [-74.82340219499991, 10.881223211000076],
              [-74.82332392199993, 10.88137288300004 ],
              [-74.82324552299991, 10.881468747000042],
              [-74.82321333199991, 10.881527420000054],
              [-74.8232093869999 , 10.881534608000038],
              [-74.82305861799995, 10.881720336000058],
              [-74.82296219699992, 10.881870052000068],
              [-74.82290818699994, 10.882043593000049],
              [-74.82290279999995, 10.882051920000038],
              [-74.82279971999992, 10.882211274000042],
              [-74.82266724699991, 10.882456752000053],
              [-74.82261236499994, 10.882567622000067],
              [-74.82249911299994, 10.88279642000009 ],
              [-74.82247463099992, 10.882845879000058],
              [-74.82216865799995, 10.883451687000047],
              [-74.82181848599993, 10.884145004000061],
              [-74.82173782899991, 10.884308882000084],
              [-74.82139715799991, 10.885001080000052],
              [-74.8210841479999 , 10.885629674000086],
              [-74.8208915159999 , 10.886012822000055],
              [-74.82077718499994, 10.886258257000065],
              [-74.82068118699993, 10.886575398000048],
              [-74.82063329899995, 10.886778811000056],
              [-74.82062151199995, 10.886904403000074],
              [-74.82063376299993, 10.886964167000087],
              [-74.82073064399992, 10.886999806000063],
              [-74.82057374599992, 10.88715565700005 ],
              [-74.8205074839999 , 10.887269421000042],
              [-74.82041117999995, 10.887466980000056],
              [-74.82025466899995, 10.887778282000056],
              [-74.82008612099992, 10.88823393000007 ],
              [-74.82000228899994, 10.888460544000054],
              [-74.8199181199999 , 10.888670030000071],
              [-74.81982159699993, 10.88880924600005 ],
              [-74.81976842499995, 10.888838264000071],
              [-74.81972494099995, 10.88888683500005 ],
              [-74.81963087399993, 10.888993926000069],
              [-74.81957027599992, 10.889062915000068],
              [-74.8193469119999 , 10.889278457000046],
              [-74.81921863699995, 10.889402241000084],
              [-74.81908157999993, 10.889569755000082],
              [-74.81894261899993, 10.889735366000082],
              [-74.8188197149999 , 10.889860105000082],
              [-74.81881507899993, 10.889864809000073],
              [-74.81872501499993, 10.889945615000045],
              [-74.81861139799992, 10.890047551000066],
              [-74.81841152299995, 10.890215065000064],
              [-74.81832609499992, 10.890291107000053],
              [-74.81822497399992, 10.890384483000048],
              [-74.81835687599994, 10.890482112000086],
              [-74.81846252399993, 10.890581097000052],
              [-74.8183690429999 , 10.890667871000062],
              [-74.81838467999995, 10.890682611000045],
              [-74.81840826899992, 10.890704849000088],
              [-74.8184213099999 , 10.890717142000085],
              [-74.81846017299995, 10.89075377800009 ],
              [-74.81857253499993, 10.890859700000078],
              [-74.81890654999995, 10.890951448000067],
              [-74.8198000079999 , 10.89116774200005 ],
              [-74.8199933109999 , 10.891217095000059],
              [-74.82024172699994, 10.891249456000082],
              [-74.82096603499991, 10.891315130000066],
              [-74.82116876499992, 10.89132750300007 ],
              [-74.82129042299994, 10.891317622000088],
              [-74.82177471999995, 10.891255555000043],
              [-74.8218371559999 , 10.891247553000085],
              [-74.82184526899994, 10.891247343000089],
              [-74.82191996099994, 10.891245411000057],
              [-74.8219958649999 , 10.891264387000092],
              [-74.82200276599991, 10.89126611200004 ],
              [-74.82206915299992, 10.891295380000088],
              [-74.82215505199991, 10.891358911000054],
              [-74.82255736299993, 10.891726639000069],
              [-74.8225616169999 , 10.89173052700005 ],
              [-74.82256470399994, 10.89173334800006 ],
              [-74.82256913499992, 10.891737400000068],
              [-74.82257026799994, 10.891738435000093],
              [-74.82261252799992, 10.891689846000077],
              [-74.82261610899991, 10.891685729000073],
              [-74.82261626899992, 10.891685545000087],
              [-74.82279162499992, 10.891483925000045],
              [-74.82319735199991, 10.89106881500004 ],
              [-74.82322373999995, 10.89104995200006 ],
              [-74.82323394299993, 10.891045571000063],
              [-74.82325489399994, 10.891036575000044],
              [-74.82328022599995, 10.891030457000056],
              [-74.82347886499991, 10.890982480000048],
              [-74.82391106599994, 10.89089049200004 ],
              [-74.8243515069999 , 10.890680201000066],
              [-74.82481658099994, 10.890503576000071],
              [-74.82520339799993, 10.890359124000042],
              [-74.82554510299991, 10.890243952000048],
              [-74.8256144259999 , 10.89022058900008 ],
              [-74.82590455699994, 10.890118224000048],
              [-74.82616434099992, 10.889980063000053],
              [-74.82638187299995, 10.88987787800005 ],
              [-74.82658729299993, 10.889769748000049],
              [-74.82684704799993, 10.889619622000055],
              [-74.82709464499993, 10.889445614000067],
              [-74.82717307799993, 10.88936171000006 ],
              [-74.82722730299992, 10.889271884000038],
              [-74.82734790099994, 10.889116124000054],
              [-74.8274925799999 , 10.888912473000062],
              [-74.82762538499992, 10.88879853800006 ],
              [-74.82798782899994, 10.888588368000057],
              [-74.82857399199992, 10.888329811000062],
              [-74.82949857199992, 10.887926912000069],
              [-74.83006046499992, 10.887644497000053],
              [-74.83012993199992, 10.88761454300004 ],
              [-74.83078562999992, 10.887331777000043],
              [-74.83109986199992, 10.88719347500006 ],
              [-74.83133556099995, 10.88710319900008 ],
              [-74.83157717499995, 10.886953125000048],
              [-74.83169801299994, 10.886893025000063],
              [-74.83184309599994, 10.88685081400007 ],
              [-74.83189172599992, 10.886845497000081],
              [-74.83206687099994, 10.886826344000042],
              [-74.83208230299994, 10.88682409100005 ],
              [-74.8321640069999 , 10.88681218000005 ],
              [-74.83248413699994, 10.886765515000093],
              [-74.83288324199992, 10.88669875100004 ],
              [-74.83328234099992, 10.886631986000054],
              [-74.83404391699992, 10.886367005000068],
              [-74.8348780899999 , 10.886101845000042],
              [-74.83604473499992, 10.88574017700006 ],
              [-74.83627450999995, 10.885697751000066],
              [-74.83662527899992, 10.885655024000073],
              [-74.83705465899993, 10.885600141000054],
              [-74.83747190399993, 10.885533326000086],
              [-74.83818558299993, 10.885465770000053],
              [-74.83882019499993, 10.885428338000054],
              [-74.83880917699992, 10.885458319000065],
              [-74.8388464869999 , 10.885460141000067],
              [-74.83911392899995, 10.885473192000063],
              [-74.83911521999994, 10.885511343000076],
              [-74.83911713999993, 10.885568111000055],
              [-74.83911786099992, 10.885596056000054],
              [-74.83911565699992, 10.885671865000063],
              [-74.83911101399991, 10.88583157000005 ],
              [-74.83906024299995, 10.886381637000056],
              [-74.83901686699994, 10.886670989000038],
              [-74.83896616899995, 10.887009171000045],
              [-74.83878206599991, 10.88770345200004 ],
              [-74.83875189199995, 10.88782087800007 ],
              [-74.83870484899995, 10.888003944000047],
              [-74.83867829699994, 10.888107268000056],
              [-74.83851666599992, 10.888594996000052],
              [-74.83841495199994, 10.888774971000089],
              [-74.83836228699994, 10.888868157000047],
              [-74.83809482799995, 10.889298559000054],
              [-74.83796681199993, 10.889530558000047],
              [-74.8379352689999 , 10.889606211000057],
              [-74.8378689189999 , 10.889765350000062],
              [-74.83770727199993, 10.890158116000066],
              [-74.8376727289999 , 10.890273986000068],
              [-74.83762477499994, 10.890434843000037],
              [-74.8375800579999 , 10.890704008000057],
              [-74.83757321699994, 10.890984280000055],
              [-74.83757329899993, 10.891016324000077],
              [-74.83757362999995, 10.89114495800004 ],
              [-74.8375367239999 , 10.891507519000072],
              [-74.83753099199993, 10.891542031000085],
              [-74.83750922999991, 10.891673050000065],
              [-74.83750692399991, 10.891686957000047],
              [-74.83734896999994, 10.892294350000043],
              [-74.8371236349999 , 10.892926430000045],
              [-74.8369277889999 , 10.893373139000062],
              [-74.83681893399995, 10.893702254000061],
              [-74.83674765599994, 10.893930373000046],
              [-74.83668408499994, 10.894214526000042],
              [-74.83667477299991, 10.894348688000036],
              [-74.83667311699992, 10.894372541000052],
              [-74.8366595899999 , 10.89443589700005 ],
              [-74.83659088299993, 10.89475764100007 ],
              [-74.83655377999992, 10.895037991000038],
              [-74.83645317299994, 10.895682556000054],
              [-74.83641639399991, 10.896089954000047],
              [-74.83637629799995, 10.896667310000055],
              [-74.83636791399994, 10.896955672000047],
              [-74.83636632999992, 10.897010115000057],
              [-74.83636456099993, 10.897070984000038],
              [-74.83636031799995, 10.89721686900009 ],
              [-74.83635891099993, 10.89726524200006 ],
              [-74.83637909799995, 10.897495130000038],
              [-74.83639008199992, 10.897620157000063],
              [-74.83648576999991, 10.89801433200006 ],
              [-74.8365180049999 , 10.89821277100009 ],
              [-74.8365203539999 , 10.898227245000044],
              [-74.83651386999992, 10.89864577700007 ],
              [-74.83651073499993, 10.898678915000062],
              [-74.83646162699995, 10.899197087000061],
              [-74.83645860699994, 10.899228858000072],
              [-74.83634270399995, 10.89984032700005 ],
              [-74.8363071409999 , 10.89997737400006 ],
              [-74.83618163199992, 10.900461033000056],
              [-74.83611956099992, 10.900673553000047],
              [-74.83607630099993, 10.90082166700006 ],
              [-74.83605505299994, 10.90084263500006 ],
              [-74.83602871399995, 10.900868630000048],
              [-74.83591365599995, 10.900982189000047],
              [-74.83565148199995, 10.901135786000054],
              [-74.83544308799992, 10.90125931600005 ],
              [-74.83516413599995, 10.90143289200006 ],
              [-74.8349993839999 , 10.901506441000038],
              [-74.83474085799992, 10.90177305800006 ],
              [-74.8346722579999 , 10.901873692000038],
              [-74.83451098899991, 10.90211026500009 ],
              [-74.83449223799994, 10.90213777200006 ],
              [-74.83441917099992, 10.902332410000042],
              [-74.83437855499994, 10.90244060300006 ],
              [-74.83434634999992, 10.902510447000054],
              [-74.83419055699994, 10.902848384000038],
              [-74.83405000399995, 10.90317454600006 ],
              [-74.83382567799993, 10.903647207000063],
              [-74.8335700579999 , 10.903975022000054],
              [-74.83350516699994, 10.904077249000068],
              [-74.83347282799991, 10.904128195000055],
              [-74.83342706899992, 10.904267608000055],
              [-74.83341603699995, 10.904301219000047],
              [-74.83331986199994, 10.904418256000042],
              [-74.83331199799994, 10.904427814000087],
              [-74.83282409999993, 10.904759477000084],
              [-74.8324241439999 , 10.905006491000051],
              [-74.83212891999995, 10.90522          ],
              [-74.83212880999992, 10.905176726000093],
              [-74.8319374969999 , 10.905313364000051],
              [-74.83148752999995, 10.905634735000092],
              [-74.83077171699995, 10.906144644000051],
              [-74.83060070699992, 10.90632897100005 ],
              [-74.83049420799995, 10.906524839000042],
              [-74.83032913699992, 10.906965931000059],
              [-74.83023850299992, 10.907134779000046],
              [-74.82994725899994, 10.907368658000053],
              [-74.82992101899993, 10.907386966000047],
              [-74.82975802399994, 10.907500634000087],
              [-74.8297563719999 , 10.907501786000068],
              [-74.82957220799995, 10.907596668000053],
              [-74.82914564299995, 10.907758150000063],
              [-74.82874305599995, 10.907900789000053],
              [-74.82843839899994, 10.908061180000061],
              [-74.82825682099991, 10.908275226000058],
              [-74.82813128799995, 10.908470310000041],
              [-74.8281019399999 , 10.908569312000054],
              [-74.8280417549999 , 10.909075342000051],
              [-74.82805734099992, 10.909755525000037],
              [-74.82811260199992, 10.910004964000052],
              [-74.82820202199991, 10.910251535000043],
              [-74.82839459099995, 10.910781672000041],
              [-74.82842254799993, 10.911033419000091],
              [-74.82839566199993, 10.911201378000044],
              [-74.82834601099995, 10.91129090000004 ],
              [-74.82827328999991, 10.911422019000042],
              [-74.82826126399993, 10.911440171000038],
              [-74.82816237999992, 10.911589433000074],
              [-74.82812115199994, 10.911629898000058],
              [-74.82788558499993, 10.91177504500007 ],
              [-74.82774757699991, 10.911879741000064],
              [-74.82765715699992, 10.91197253900009 ],
              [-74.82757387599992, 10.912098651000065],
              [-74.82743348799994, 10.912424637000072],
              [-74.82728120199994, 10.912857700000075],
              [-74.82719078299993, 10.913167030000068],
              [-74.82716291699995, 10.913256198000056],
              [-74.82711939899991, 10.913395457000092],
              [-74.82704801499995, 10.913526328000046],
              [-74.8269361799999 , 10.913642921000076],
              [-74.82688890199995, 10.913688551000064],
              [-74.82683832399994, 10.913737365000088],
              [-74.82673154699995, 10.913840417000074],
              [-74.82667494799995, 10.913916096000037],
              [-74.82653866299995, 10.914018892000058],
              [-74.82652171699993, 10.914031674000057],
              [-74.82649536199995, 10.914051553000093],
              [-74.8264708509999 , 10.914071860000092],
              [-74.82637113399994, 10.914154472000064],
              [-74.82625170999995, 10.914253411000061],
              [-74.8262317999999 , 10.91427030200009 ],
              [-74.82611366599991, 10.91437052200007 ],
              [-74.82609521799992, 10.914386173000082],
              [-74.82603473499995, 10.914437484000075],
              [-74.82596861799993, 10.91449139100007 ],
              [-74.82588303199992, 10.91456117100006 ],
              [-74.82575487199995, 10.914665661000072],
              [-74.82572296199993, 10.914699157000086],
              [-74.82556332599995, 10.914866725000081],
              [-74.82544316299993, 10.915007113000058],
              [-74.8254092549999 , 10.915041616000053],
              [-74.82537534799991, 10.915076118000059],
              [-74.82530494799994, 10.915109947000076],
              [-74.82528373899993, 10.915120138000077],
              [-74.82505768999994, 10.915164158000039],
              [-74.82502027399994, 10.915172607000045],
              [-74.82474509299993, 10.915234744000088],
              [-74.82472575499992, 10.915239111000062],
              [-74.82441999499991, 10.915346186000079],
              [-74.82432536099992, 10.915399475000072],
              [-74.8241749099999 , 10.91548419500009 ],
              [-74.82395566499991, 10.915608896000037],
              [-74.8238548729999 , 10.915666224000063],
              [-74.8236050289999 , 10.91579114600006 ],
              [-74.82352232399995, 10.915838342000086],
              [-74.8233861189999 , 10.915916067000069],
              [-74.82323985099993, 10.915983834000087],
              [-74.82312675799994, 10.916036230000088],
              [-74.82285668899993, 10.916176618000065],
              [-74.82273533599994, 10.916239674000053],
              [-74.82271516999992, 10.91625163100008 ],
              [-74.82266275799992, 10.916277746000048],
              [-74.82265979199991, 10.916284466000093],
              [-74.82262807099994, 10.91635634000005 ],
              [-74.82261314199991, 10.916390167000088],
              [-74.82261214899995, 10.916392417000054],
              [-74.82258337199994, 10.916457620000074],
              [-74.82255714399992, 10.91651704800006 ],
              [-74.82254997099994, 10.916533300000083],
              [-74.82252282199994, 10.91654887900006 ],
              [-74.82244327199993, 10.916594528000076],
              [-74.82240423499991, 10.916616929000043],
              [-74.8222343459999 , 10.916677604000085],
              [-74.82223398399992, 10.916677733000085],
              [-74.8221254799999 , 10.91673484000006 ],
              [-74.82206456599994, 10.916803368000046],
              [-74.82204172299993, 10.916892836000045],
              [-74.8220188809999 , 10.91695946100009 ],
              [-74.82200540899993, 10.916978606000043],
              [-74.8219827129999 , 10.917010857000037],
              [-74.82194535499991, 10.91705963000004 ],
              [-74.82191418399992, 10.917100325000092],
              [-74.82187808799995, 10.917157423000049],
              [-74.82180948799993, 10.917265936000092],
              [-74.82175048199991, 10.917373596000061],
              [-74.82170098499995, 10.91746390700007 ],
              [-74.8216981189999 , 10.917471118000037],
              [-74.8215884729999 , 10.917746999000087],
              [-74.82158296299991, 10.917760863000069],
              [-74.82154509999992, 10.917850678000093],
              [-74.82150110999993, 10.917955027000062],
              [-74.82143448499994, 10.917998810000086],
              [-74.8213792809999 , 10.918078759000082],
              [-74.82129742799992, 10.918303381000044],
              [-74.8212365139999 , 10.918461377000085],
              [-74.8211736959999 , 10.918583205000061],
              [-74.8211454389999 , 10.91857359800008 ],
              [-74.82093670499995, 10.918502628000056],
              [-74.82079562899992, 10.918454662000045],
              [-74.82076908399995, 10.918445637000048],
              [-74.8205822079999 , 10.918448279000074],
              [-74.82048497399995, 10.918476554000051],
              [-74.82039711399995, 10.918502104000083],
              [-74.82018126799994, 10.918564872000047],
              [-74.81999123599991, 10.918669158000057],
              [-74.81995277699991, 10.918690264000077],
              [-74.81988859499995, 10.918725486000085],
              [-74.8196244749999 , 10.918951535000076],
              [-74.81955222599993, 10.91903945200005 ],
              [-74.81952875299993, 10.91906801400006 ],
              [-74.81952572699993, 10.91907169700005 ],
              [-74.81942096799992, 10.919199898000045],
              [-74.81934681799993, 10.919290642000078],
              [-74.81928730999994, 10.919356235000066],
              [-74.81923167899993, 10.919417553000073],
              [-74.81920663299991, 10.919445160000066],
              [-74.81903418299993, 10.919639678000067],
              [-74.81892491299993, 10.919762931000037],
              [-74.8187945279999 , 10.919879169000069],
              [-74.8187657819999 , 10.919904796000083],
              [-74.81867240699995, 10.919988039000089],
              [-74.81849779999993, 10.920096055000045],
              [-74.81821092299992, 10.920246736000081],
              [-74.81815848899993, 10.92027427700009 ],
              [-74.8181144589999 , 10.92029597000004 ],
              [-74.81806056999994, 10.92032252000007 ],
              [-74.81786581499995, 10.920418472000051],
              [-74.81778572199994, 10.920455106000077],
              [-74.8173632729999 , 10.920648328000084],
              [-74.81695919499992, 10.920854163000058],
              [-74.81687186099992, 10.92089623600009 ],
              [-74.81669840599994, 10.920979798000076],
              [-74.81660839599994, 10.921060564000072],
              [-74.81634624599991, 10.921295791000091],
              [-74.81587796799994, 10.921745033000093],
              [-74.81562859999991, 10.921988690000092],
              [-74.81549344699994, 10.922041990000082],
              [-74.81513557599993, 10.922173336000071],
              [-74.81487668999995, 10.92228183900005 ],
              [-74.81477389799994, 10.922321814000043],
              [-74.81462453499995, 10.922332483000048],
              [-74.81458734799992, 10.92233513900004 ],
              [-74.81458665399992, 10.922379058000047],
              [-74.81458569299991, 10.922439939000071],
              [-74.81458396699992, 10.922549224000079],
              [-74.81458328199994, 10.922592620000046],
              [-74.8145039119999 , 10.922597069000062],
              [-74.8144896259999 , 10.922597870000061],
              [-74.8144120899999 , 10.92260221500004 ],
              [-74.81425599199991, 10.922610089000045],
              [-74.81406971199993, 10.922619485000041],
              [-74.81384969299995, 10.922630583000057],
              [-74.81382850099993, 10.922631652000064],
              [-74.81363815299994, 10.922641254000041],
              [-74.81363596799991, 10.922641364000071],
              [-74.81338034999993, 10.92265425700009 ],
              [-74.81326113999995, 10.922660270000051],
              [-74.81309508499993, 10.922670788000062],
              [-74.8127692459999 , 10.922691428000064],
              [-74.81260385699994, 10.922701904000064],
              [-74.81251759799994, 10.92270736800009 ],
              [-74.81236556499994, 10.92271097400004 ],
              [-74.81234290499992, 10.922711512000092],
              [-74.81214714499993, 10.922716155000046],
              [-74.81206919999994, 10.922728384000038],
              [-74.81173857399995, 10.922743195000066],
              [-74.81165351499993, 10.922747006000066],
              [-74.81144380299992, 10.922756400000083],
              [-74.81144133299995, 10.922721914000078],
              [-74.8114350379999 , 10.922634         ],
              [-74.81143159599992, 10.922605703000045],
              [-74.81142147599991, 10.922522491000052],
              [-74.81141790599992, 10.922493136000071],
              [-74.8108696779999 , 10.922515978000092],
              [-74.81086834399991, 10.922496893000073],
              [-74.81085375099991, 10.922288191000064],
              [-74.81085363799991, 10.922288198000047],
              [-74.81057989899995, 10.922305183000049],
              [-74.81057395099992, 10.92221928400005 ],
              [-74.8100056479999 , 10.92224598900009 ],
              [-74.80942903099992, 10.922287448000077],
              [-74.80941515199993, 10.921907322000038],
              [-74.80941366999991, 10.921866722000061],
              [-74.80941324999992, 10.921855227000037],
              [-74.80936753799995, 10.921847193000076],
              [-74.80924193699991, 10.921825118000072],
              [-74.80919215699993, 10.921816369000055],
              [-74.80914873699993, 10.921808737000049],
              [-74.80904067299991, 10.92178974400008 ],
              [-74.80893403499994, 10.921771002000071],
              [-74.80891272299993, 10.921767256000066],
              [-74.8087803219999 , 10.921743985000091],
              [-74.80876642499993, 10.921741543000053],
              [-74.80868373899995, 10.921725477000052],
              [-74.80863482599995, 10.921715973000062],
              [-74.80839903599991, 10.921670159000087],
              [-74.80804021299991, 10.921676822000052],
              [-74.80784971499992, 10.92168717900006 ],
              [-74.80748013399995, 10.92170727100006 ],
              [-74.80746247999991, 10.921708231000082],
              [-74.80719531699992, 10.921778202000041],
              [-74.8070626889999 , 10.921812938000073],
              [-74.80698278099993, 10.921833866000043],
              [-74.80686183499995, 10.921867988000088],
              [-74.80664204199991, 10.921929996000074],
              [-74.80625181099992, 10.92205277700009 ],
              [-74.80624367999991, 10.922055253000053],
              [-74.8062431489999 , 10.922055415000045],
              [-74.80612893199992, 10.922090199000081],
              [-74.80606393699992, 10.92210999300005 ],
              [-74.80604241799995, 10.92211654600004 ],
              [-74.80589584299992, 10.922138437000058],
              [-74.80571796099991, 10.922219064000046],
              [-74.80571350299994, 10.92222098600007 ],
              [-74.80551694499991, 10.922305750000078],
              [-74.80522293199994, 10.922432539000056],
              [-74.80520562499993, 10.922426985000072],
              [-74.80489075899993, 10.922325939000075],
              [-74.80470110899995, 10.92225424500009 ],
              [-74.80460936599991, 10.922200200000077],
              [-74.80457019199991, 10.92217712300004 ],
              [-74.80433240999992, 10.92203704700006 ],
              [-74.80408258799991, 10.921980592000068],
              [-74.80347331399992, 10.921842908000087],
              [-74.80315137299993, 10.921788894000088],
              [-74.80286583799995, 10.92175558200006 ],
              [-74.80278743699995, 10.92174768500007 ],
              [-74.8026169449999 , 10.921730514000046],
              [-74.80260124099993, 10.921728932000065],
              [-74.80242251599992, 10.921737951000068],
              [-74.80239375199994, 10.921739402000071],
              [-74.80196925699994, 10.921763196000086],
              [-74.80173892499994, 10.92179460500006 ],
              [-74.80173621299991, 10.92179513800005 ],
              [-74.80172606999992, 10.921797132000052],
              [-74.80156092599992, 10.921829590000073],
              [-74.8013978649999 , 10.921836276000079],
              [-74.80125351499993, 10.921842194000078],
              [-74.80123523999993, 10.921841086000086],
              [-74.8010742429999 , 10.921831329000042],
              [-74.80087660799995, 10.921819351000067],
              [-74.80062467999994, 10.921779597000068],
              [-74.80040191599994, 10.921706872000073],
              [-74.80008371999992, 10.921602991000043],
              [-74.7996678419999 , 10.921452914000042],
              [-74.79963139799992, 10.921438200000068],
              [-74.79939417999992, 10.921342421000077],
              [-74.79923150499991, 10.92129830500005 ],
              [-74.79922356999992, 10.921296152000082],
              [-74.79920310299991, 10.921290602000056],
              [-74.79920302599993, 10.92130289000005 ],
              [-74.7992025129999 , 10.921384490000037],
              [-74.79920104299993, 10.921618295000087],
              [-74.79917878399993, 10.921620783000037],
              [-74.79917828899994, 10.92162083800008 ],
              [-74.79914076199992, 10.921625033000055],
              [-74.79913018699995, 10.921627847000082],
              [-74.7991132699999 , 10.92163234800006 ],
              [-74.79900967099991, 10.921659914000088],
              [-74.79896366999992, 10.921708999000089],
              [-74.79886426899992, 10.921815064000043],
              [-74.79875835899992, 10.92189406700004 ],
              [-74.79855215799995, 10.92199170200007 ],
              [-74.7984072029999 , 10.922069195000063],
              [-74.79836894699991, 10.922114239000052],
              [-74.7982891819999 , 10.922208155000078],
              [-74.79822670499993, 10.922360673000071],
              [-74.7982244609999 , 10.922366152000052],
              [-74.7981661309999 , 10.922633056000052],
              [-74.7981616429999 , 10.922653590000039],
              [-74.79813023999992, 10.922832878000065],
              [-74.7981173899999 , 10.922906242000067],
              [-74.79811678999994, 10.922909669000092],
              [-74.79808930699994, 10.92305334000008 ],
              [-74.7980282499999 , 10.923137968000049],
              [-74.79793623199993, 10.923207700000091],
              [-74.79782675099995, 10.923263871000074],
              [-74.79760618199992, 10.923306203000038],
              [-74.79745136799994, 10.923352898000076],
              [-74.79728979399994, 10.92343040500009 ],
              [-74.79723867099995, 10.923452228000087],
              [-74.79723881599995, 10.92345485900006 ],
              [-74.79724473699991, 10.923562067000091],
              [-74.79724495799991, 10.923566066000092],
              [-74.79724631699992, 10.923590680000075],
              [-74.79724714399993, 10.923605652000049],
              [-74.79724735299993, 10.923609447000047],
              [-74.79724878899992, 10.923635453000088],
              [-74.79728842599991, 10.92363391300006 ],
              [-74.79732366299993, 10.923636185000078],
              [-74.79732590399993, 10.92363632900009 ],
              [-74.79733055499992, 10.923636629000043],
              [-74.79737432199994, 10.923639451000042],
              [-74.79753718199993, 10.923655757000063],
              [-74.79766290799995, 10.92370412300005 ],
              [-74.79780995899995, 10.923792638000066],
              [-74.79789514299995, 10.923880916000087],
              [-74.79798936999993, 10.924017973000048],
              [-74.7980426709999 , 10.92414965800009 ],
              [-74.79811111999993, 10.924318768000091],
              [-74.7981349929999 , 10.92437774800004 ],
              [-74.79789839499995, 10.924450755000066],
              [-74.7978851499999 , 10.924454842000046],
              [-74.79783660899994, 10.924830321000059],
              [-74.79783709199995, 10.924858163000067],
              [-74.7978408919999 , 10.925077309000073],
              [-74.79783940499993, 10.925098134000052],
              [-74.7978194769999 , 10.925377122000043],
              [-74.7978169299999 , 10.925504471000067],
              [-74.79781624399993, 10.925538739000046],
              [-74.79781420299992, 10.925640798000074],
              [-74.7978138979999 , 10.925656031000074],
              [-74.79781386299993, 10.925657780000051],
              [-74.79781233799991, 10.925734041000055],
              [-74.79780133299994, 10.925851528000067],
              [-74.79773827699995, 10.925927671000068],
              [-74.7977377869999 , 10.925934343000051],
              [-74.7977273969999 , 10.926075911000055],
              [-74.79770512099992, 10.926379447000045],
              [-74.79748825599995, 10.92642046800006 ],
              [-74.79712368299994, 10.92648999800008 ],
              [-74.79709701699994, 10.926495084000067],
              [-74.79701024699995, 10.926511632000086],
              [-74.79702521299993, 10.926864942000066],
              [-74.79702759199995, 10.926921106000066],
              [-74.79701236299991, 10.927056260000086],
              [-74.79694348699991, 10.927071703000081],
              [-74.7967756029999 , 10.927109344000087],
              [-74.79683318999992, 10.927445529000067],
              [-74.79681385999993, 10.927602191000062],
              [-74.79680582599991, 10.927667305000057],
              [-74.79678869399993, 10.927736785000093],
              [-74.79673333799991, 10.92790909100006 ],
              [-74.79672111699995, 10.927947130000064],
              [-74.79668019099995, 10.92803088700009 ],
              [-74.79662784299995, 10.92806991000009 ],
              [-74.79647936399994, 10.928168896000045],
              [-74.79651011499993, 10.928296094000075],
              [-74.79650201099992, 10.928299113000037],
              [-74.7964858009999 , 10.928305152000064],
              [-74.7964742129999 , 10.928309469000055],
              [-74.79646795499991, 10.928311800000074],
              [-74.79644203099991, 10.928322274000038],
              [-74.79643838899995, 10.928323745000057],
              [-74.79642220599993, 10.928330283000037],
              [-74.79637986199992, 10.928347391000045],
              [-74.79626836499995, 10.92839243800006 ],
              [-74.79615194999991, 10.928434443000071],
              [-74.79613482999991, 10.92844096500005 ],
              [-74.79613139299994, 10.928442274000076],
              [-74.7961034409999 , 10.928452922000076],
              [-74.7957921759999 , 10.92857150000009 ],
              [-74.79565507399991, 10.928672647000042],
              [-74.79564847099994, 10.928682996000077],
              [-74.79554471199992, 10.928845614000068],
              [-74.79551204899991, 10.928909174000069],
              [-74.79543906399994, 10.92905120000006 ],
              [-74.79538360099991, 10.929144611000083],
              [-74.79529013599995, 10.929302026000073],
              [-74.79525353899993, 10.929363662000071],
              [-74.79524212299992, 10.929382889000067],
              [-74.79522205699993, 10.929416685000092],
              [-74.79517067899991, 10.929520282000055],
              [-74.79516399799991, 10.92953375400009 ],
              [-74.79512747299992, 10.929603084000064],
              [-74.7951268939999 , 10.929604182000048],
              [-74.79511988399992, 10.929617489000066],
              [-74.79511287299994, 10.929630797000073],
              [-74.79510696699992, 10.929638712000042],
              [-74.79510644299995, 10.929639415000054],
              [-74.79510303399991, 10.929643983000062],
              [-74.79509194699995, 10.929658843000084],
              [-74.79508937199995, 10.929662294000082],
              [-74.79504533999994, 10.929721305000044],
              [-74.79504163399992, 10.929726272000039],
              [-74.79502122999992, 10.929753617000074],
              [-74.7949833209999 , 10.929797101000077],
              [-74.79491701699993, 10.929873156000042],
              [-74.79489178799992, 10.929902095000045],
              [-74.79487390999992, 10.929935148000084],
              [-74.79484535699993, 10.929969412000048],
              [-74.79481965799994, 10.930012242000089],
              [-74.79477777999995, 10.930170239000063],
              [-74.79476421599992, 10.930216463000079],
              [-74.79470544399993, 10.930416751000052],
              [-74.79466671999995, 10.930504340000084],
              [-74.79466546899994, 10.930507170000055],
              [-74.79465309599993, 10.930535724000038],
              [-74.79463025299992, 10.930564277000087],
              [-74.79460169999993, 10.930587120000041],
              [-74.79457531799994, 10.930601190000061],
              [-74.7945588689999 , 10.930609963000052],
              [-74.79450038699991, 10.930615811000052],
              [-74.79448272699995, 10.930617577000078],
              [-74.79447413199995, 10.930618304000063],
              [-74.79438548299993, 10.93062580600008 ],
              [-74.79435899499993, 10.930628047000084],
              [-74.79434342299993, 10.930630922000091],
              [-74.79433288699994, 10.930632867000043],
              [-74.79429712899992, 10.930639468000038],
              [-74.7942613379999 , 10.930646810000042],
              [-74.79426000899991, 10.930647083000053],
              [-74.79422193799991, 10.930653745000086],
              [-74.7942048139999 , 10.930662307000091],
              [-74.7941971919999 , 10.93066611800009 ],
              [-74.79418881399994, 10.930670569000085],
              [-74.79418558899994, 10.930672282000046],
              [-74.79416673399993, 10.930682299000068],
              [-74.79413350999994, 10.930716261000043],
              [-74.79412390399995, 10.930726081000046],
              [-74.79412262699992, 10.930727335000086],
              [-74.79407155599995, 10.93077747700005 ],
              [-74.79406666699992, 10.930782277000048],
              [-74.79406102999991, 10.930787811000073],
              [-74.79402837999993, 10.930819867000082],
              [-74.79401920799995, 10.930828873000053],
              [-74.79395427999992, 10.930871963000072],
              [-74.79395370199995, 10.930872119000071],
              [-74.79394779299992, 10.93087371200005 ],
              [-74.7939464399999 , 10.930874077000055],
              [-74.79394555399995, 10.930874316000086],
              [-74.79392083599993, 10.930880981000087],
              [-74.79389219599994, 10.93088870400004 ],
              [-74.79387881999992, 10.930892310000047],
              [-74.79387824499992, 10.930892465000056],
              [-74.7938751719999 , 10.930893294000043],
              [-74.79379004999993, 10.930916246000038],
              [-74.7936896839999 , 10.930937636000067],
              [-74.79356910799993, 10.930954862000078],
              [-74.79355643399992, 10.930956672000093],
              [-74.7934698219999 , 10.930988081000066],
              [-74.79345007199993, 10.931002936000084],
              [-74.7934498589999 , 10.93100309600004 ],
              [-74.7934418019999 , 10.931009156000073],
              [-74.79343775599995, 10.931012199000065],
              [-74.7934307459999 , 10.931016882000051],
              [-74.7934248709999 , 10.931020806000049],
              [-74.7934212369999 , 10.931023234000065],
              [-74.79341347199994, 10.931028421000065],
              [-74.79341292799995, 10.931028784000091],
              [-74.79335275199992, 10.931068983000046],
              [-74.79322330999992, 10.93115749900005 ],
              [-74.79315192599995, 10.931179390000068],
              [-74.79313842599993, 10.931178871000043],
              [-74.79305293999994, 10.931175582000037],
              [-74.79293587099994, 10.931133704000047],
              [-74.7927788259999 , 10.931064224000067],
              [-74.7926898849999 , 10.93104978000008 ],
              [-74.79266746799993, 10.931046140000092],
              [-74.7925837109999 , 10.93105089900007 ],
              [-74.79244665399995, 10.93107850000007 ],
              [-74.79236016099992, 10.931095607000088],
              [-74.79225754999993, 10.931156956000052],
              [-74.79224868199992, 10.931162258000086],
              [-74.79221780199993, 10.931191321000085],
              [-74.7922170729999 , 10.931192008000039],
              [-74.79221455099992, 10.931194382000058],
              [-74.79220014099991, 10.93120794300006 ],
              [-74.79219992399993, 10.931208094000056],
              [-74.79217288299992, 10.93122685700007 ],
              [-74.79214464499995, 10.93124645100005 ],
              [-74.79213515899994, 10.931253033000075],
              [-74.79213475199992, 10.931253316000038],
              [-74.79210686699992, 10.931272665000051],
              [-74.79198445599991, 10.931366104000062],
              [-74.79182686399992, 10.93148640000004 ],
              [-74.79182133099994, 10.931490623000059],
              [-74.79159338699992, 10.931653119000089],
              [-74.7915329409999 , 10.931696209000052],
              [-74.79149391699991, 10.931767592000085],
              [-74.79143919599994, 10.931992612000045],
              [-74.79141872399993, 10.932076793000078],
              [-74.79140778599992, 10.932121772000073],
              [-74.79138575399992, 10.932212366000044],
              [-74.79138350999995, 10.932221594000055],
              [-74.79137643799993, 10.93224921600006 ],
              [-74.79137622899992, 10.932250028000055],
              [-74.79137117599993, 10.932269764000068],
              [-74.7913680929999 , 10.93228180300008 ],
              [-74.7913579879999 , 10.932321271000092],
              [-74.79133794499995, 10.93239955200005 ],
              [-74.7912998729999 , 10.93254612600009 ],
              [-74.79126216199995, 10.932633131000046],
              [-74.79124169499994, 10.93268035400007 ],
              [-74.79121560399994, 10.932744393000064],
              [-74.79121028599991, 10.932757448000075],
              [-74.7911827019999 , 10.93280933200009 ],
              [-74.79117031099992, 10.932832639000083],
              [-74.79107703599993, 10.932989684000063],
              [-74.79106520399995, 10.933006556000066],
              [-74.79092486999991, 10.933206665000057],
              [-74.7908940829999 , 10.933259784000086],
              [-74.79083052399994, 10.933369446000086],
              [-74.79077056099993, 10.93347414200008 ],
              [-74.7907400819999 , 10.933507809000048],
              [-74.79070595999991, 10.933545500000037],
              [-74.79064062399993, 10.933596610000052],
              [-74.79063452299994, 10.933601383000052],
              [-74.79062684199994, 10.933607392000056],
              [-74.79056947599992, 10.933630451000056],
              [-74.79052975999991, 10.933646415000055],
              [-74.79052899699991, 10.93364659200006 ],
              [-74.79050650799991, 10.933651809000082],
              [-74.79048441299994, 10.933656934000055],
              [-74.7904643089999 , 10.933661597000082],
              [-74.79034511399993, 10.93368924500004 ],
              [-74.79022484299992, 10.933711494000079],
              [-74.79020353299995, 10.933715436000057],
              [-74.79019070999993, 10.93371780800004 ],
              [-74.79000134499995, 10.933752837000043],
              [-74.78999009799992, 10.93375491800009 ],
              [-74.78973121299992, 10.933819640000081],
              [-74.78969551299991, 10.933827421000046],
              [-74.78962366999991, 10.933843079000042],
              [-74.7895192979999 , 10.933865826000044],
              [-74.78943979199994, 10.933883154000057],
              [-74.78936001699992, 10.933900541000071],
              [-74.78926328099993, 10.933906069000045],
              [-74.78922676699995, 10.933908156000086],
              [-74.7891449139999 , 10.933899589000077],
              [-74.7890973879999 , 10.933882510000046],
              [-74.78908436799992, 10.933877831000075],
              [-74.78907455599995, 10.933874305000074],
              [-74.78902308499994, 10.933855807000043],
              [-74.7890229109999 , 10.93385573200004 ],
              [-74.78894010499994, 10.933819871000082],
              [-74.7889022089999 , 10.933803459000046],
              [-74.78880596099992, 10.933766763000051],
              [-74.78861762499992, 10.933694956000068],
              [-74.78850704399991, 10.933664968000073],
              [-74.7885053139999 , 10.933664499000088],
              [-74.78843678599992, 10.933664499000088],
              [-74.78831781299994, 10.93368924500004 ],
              [-74.7882072999999 , 10.933742080000059],
              [-74.78807891499991, 10.933803459000046],
              [-74.78794964399992, 10.933878701000083],
              [-74.78777148899991, 10.933982395000044],
              [-74.78773640999992, 10.934004520000087],
              [-74.78762099599993, 10.934077314000092],
              [-74.78751793299995, 10.934142317000067],
              [-74.78748024299995, 10.934166089000087],
              [-74.78745718599993, 10.934182565000071],
              [-74.7873130829999 , 10.934285540000076],
              [-74.7872421909999 , 10.934336199000086],
              [-74.78722899799993, 10.934345627000084],
              [-74.78703671099993, 10.934483033000049],
              [-74.78691966299994, 10.934561300000041],
              [-74.78687871499994, 10.934588681000037],
              [-74.7868489949999 , 10.934607649000043],
              [-74.7866285899999 , 10.934748322000075],
              [-74.78651037399993, 10.934823772000072],
              [-74.78636588699993, 10.934909990000051],
              [-74.7863428899999 , 10.934923713000046],
              [-74.78631848299995, 10.934938277000072],
              [-74.7862061589999 , 10.935005303000082],
              [-74.7860654349999 , 10.935089276000042],
              [-74.7859516769999 , 10.93514547500007 ],
              [-74.7858012179999 , 10.935224620000042],
              [-74.78564272799991, 10.93530798900008 ],
              [-74.78550412099992, 10.935380899000052],
              [-74.78534343099994, 10.935465425000075],
              [-74.78527495799995, 10.935501443000078],
              [-74.78497409899995, 10.935664840000072],
              [-74.78491939799994, 10.935694548000072],
              [-74.78483332999991, 10.935741292000046],
              [-74.78471780099994, 10.93581198600009 ],
              [-74.78469233899995, 10.935827566000057],
              [-74.78453845199994, 10.935921731000064],
              [-74.78451773099994, 10.935940413000083],
              [-74.78447335899995, 10.935980419000089],
              [-74.7843859919999 , 10.936059188000058],
              [-74.78434037399995, 10.936095969000064],
              [-74.7842900149999 , 10.936136573000056],
              [-74.78427482699993, 10.936148819000039],
              [-74.78421959399992, 10.936193352000089],
              [-74.78420420099991, 10.936205763000089],
              [-74.78398148299993, 10.936390409000069],
              [-74.7838069739999 , 10.936537268000052],
              [-74.78371117599994, 10.936617886000079],
              [-74.78363030399993, 10.936675477000051],
              [-74.7833970879999 , 10.936841555000058],
              [-74.7831578659999 , 10.937003884000092],
              [-74.78292834399991, 10.937130158000059],
              [-74.78278919599995, 10.937206711000044],
              [-74.78257235899991, 10.937326006000092],
              [-74.78253004099992, 10.937349287000075],
              [-74.78246332899994, 10.937385989000063],
              [-74.78240169299994, 10.937419899000076],
              [-74.78234268099993, 10.93744693900004 ],
              [-74.7822825479999 , 10.937474492000092],
              [-74.7821919989999 , 10.93751632100009 ],
              [-74.78219108199994, 10.937516745000039],
              [-74.78218915799994, 10.937518579000084],
              [-74.78218093599992, 10.937526414000047],
              [-74.78209516699991, 10.937608156000067],
              [-74.7820777469999 , 10.937624758000084],
              [-74.7820107249999 , 10.937404124000068],
              [-74.78200370399992, 10.93738101200006 ],
              [-74.78196980599995, 10.937269420000064],
              [-74.78196477099993, 10.937252845000046],
              [-74.78194482399994, 10.937185081000052],
              [-74.78193011799993, 10.937135119000061],
              [-74.78191234399992, 10.937074735000067],
              [-74.78188442999993, 10.936979901000086],
              [-74.78188207099993, 10.936971887000084],
              [-74.78186169299994, 10.936902658000065],
              [-74.78185528299991, 10.936880880000047],
              [-74.78183937599994, 10.936826840000037],
              [-74.7818315209999 , 10.936800153000092],
              [-74.7817907729999 , 10.936666958000046],
              [-74.78177336699991, 10.936610063000046],
              [-74.78175492199995, 10.936549771000045],
              [-74.7817533619999 , 10.936544670000046],
              [-74.78174141399995, 10.93650561700008 ],
              [-74.78173472999993, 10.936483769000063],
              [-74.78170312299994, 10.936380453000083],
              [-74.78165570199991, 10.936225444000058],
              [-74.7816391369999 , 10.93617129900008 ],
              [-74.78160169099993, 10.936048895000056],
              [-74.78160148599994, 10.936048226000082],
              [-74.7815992109999 , 10.936040666000054],
              [-74.78157980999993, 10.935976185000072],
              [-74.78155375499995, 10.93592264700004 ],
              [-74.78154695299992, 10.93591021800006 ],
              [-74.78148558299995, 10.935798082000076],
              [-74.7814726659999 , 10.935775191000062],
              [-74.7814543639999 , 10.93574275800006 ],
              [-74.78143503299992, 10.93570850000009 ],
              [-74.78142918999993, 10.93569814500006 ],
              [-74.78139171399994, 10.935638896000057],
              [-74.78136656899994, 10.935606749000044],
              [-74.7812759599999 , 10.935490911000045],
              [-74.78127534599992, 10.935490126000047],
              [-74.78127250299991, 10.935486492000052],
              [-74.78122291999995, 10.935426765000045],
              [-74.7811441579999 , 10.935331890000043],
              [-74.78107267699994, 10.935245785000063],
              [-74.7810387209999 , 10.93520488300004 ],
              [-74.78089320599992, 10.93503082300009 ],
              [-74.78088467999993, 10.93502062500005 ],
              [-74.78087203599995, 10.935005501000091],
              [-74.7808034329999 , 10.93492344100008 ],
              [-74.78072581099991, 10.934830593000072],
              [-74.78070433699992, 10.934804906000068],
              [-74.7807039299999 , 10.934804419000045],
              [-74.78064042299991, 10.93472747800007 ],
              [-74.78060405899993, 10.934683422000091],
              [-74.78046420899994, 10.934513988000049],
              [-74.78046138999991, 10.934510573000068],
              [-74.78044409299991, 10.934489617000054],
              [-74.78041125699991, 10.93443964800008 ],
              [-74.78038769999995, 10.934401815000058],
              [-74.78035945199991, 10.934337816000038],
              [-74.78035697899992, 10.934332213000062],
              [-74.78033805699994, 10.934289343000046],
              [-74.78029632899995, 10.934194801000046],
              [-74.78028948299993, 10.93417530000005 ],
              [-74.78028689599995, 10.934167931000047],
              [-74.78027798499994, 10.934142549000057],
              [-74.7802492159999 , 10.934060600000066],
              [-74.78022256499992, 10.933959235000088],
              [-74.78022232799992, 10.933958241000084],
              [-74.78022190099995, 10.933956452000075],
              [-74.78020733699992, 10.933895465000091],
              [-74.780195        , 10.93380972500006 ],
              [-74.78018830099995, 10.933763167000052],
              [-74.7801868709999 , 10.93373997000009 ],
              [-74.78017973499993, 10.933624207000037],
              [-74.7801794849999 , 10.933603951000066],
              [-74.78017905899992, 10.933569497000065],
              [-74.7801788989999 , 10.933556509000084],
              [-74.78017858899995, 10.933531418000086],
              [-74.78017783199994, 10.933470018000037],
              [-74.78018019099994, 10.933451147000085],
              [-74.78019138099995, 10.933361628000057],
              [-74.7801950349999 , 10.933332397000072],
              [-74.78021531299993, 10.933170171000086],
              [-74.78021780699993, 10.933150218000037],
              [-74.78023726299995, 10.93302731700004 ],
              [-74.78025483399995, 10.932916334000083],
              [-74.78025765899991, 10.932898489000081],
              [-74.7802610519999 , 10.932877053000084],
              [-74.78026977099995, 10.932821980000085],
              [-74.7802749139999 , 10.93278949200004 ],
              [-74.7803014449999 , 10.93261207900008 ],
              [-74.78028660899992, 10.932611670000085],
              [-74.78028333999993, 10.93261158000007 ],
              [-74.78028243099993, 10.93261155500005 ],
              [-74.7801979379999 , 10.932609224000089],
              [-74.78014483999993, 10.93261563200008 ],
              [-74.78005727599992, 10.932674643000041],
              [-74.78001444599994, 10.932737461000045],
              [-74.77996209799994, 10.932921155000088],
              [-74.77990213499993, 10.933271412000067],
              [-74.77989324899994, 10.93332090000007 ],
              [-74.7798736229999 , 10.93343019300005 ],
              [-74.77984693199994, 10.933578838000074],
              [-74.77975841599994, 10.934107078000068],
              [-74.77983931699993, 10.934274593000055],
              [-74.7799449659999 , 10.934482082000045],
              [-74.7798440759999 , 10.934740015000045],
              [-74.77966894799994, 10.93514833100005 ],
              [-74.77947668799993, 10.93558900700009 ],
              [-74.77928544299994, 10.936038512000039],
              [-74.7792539699999 , 10.936112488000049],
              [-74.77911405799995, 10.936453227000072],
              [-74.77902744499994, 10.93666547500004 ],
              [-74.77877427099992, 10.937265099000058],
              [-74.7786000939999 , 10.937680077000039],
              [-74.77861443299992, 10.93768810000006 ],
              [-74.77868004399994, 10.937724811000066],
              [-74.77867976599993, 10.937725482000076],
              [-74.77854679399991, 10.938046514000064],
              [-74.7785105129999 , 10.938133675000074],
              [-74.77842991799992, 10.938327295000079],
              [-74.77838594299993, 10.938432939000052],
              [-74.7783104689999 , 10.938608162000037],
              [-74.77830668299993, 10.938616951000085],
              [-74.77828931999994, 10.93865726200005 ],
              [-74.77822318799991, 10.938810797000087],
              [-74.77818333199991, 10.938899566000089],
              [-74.7781544579999 , 10.938963877000049],
              [-74.77810100599993, 10.939082928000062],
              [-74.7780347339999 , 10.939230534000046],
              [-74.77802306299992, 10.939257978000057],
              [-74.7779740009999 , 10.93937334800006 ],
              [-74.7778922249999 , 10.939565642000048],
              [-74.7778424739999 , 10.93968263100004 ],
              [-74.77783952999994, 10.939689675000068],
              [-74.77776252399991, 10.93987394000004 ],
              [-74.77775225599993, 10.939897577000067],
              [-74.7777041839999 , 10.940008239000065],
              [-74.77762070799992, 10.940200402000073],
              [-74.77763102099993, 10.940205973000047],
              [-74.77769826699995, 10.940242302000058],
              [-74.7775423409999 , 10.940667911000048],
              [-74.77754170999992, 10.940669632000038],
              [-74.77727353999995, 10.940790672000048],
              [-74.77721423499992, 10.940817440000046],
              [-74.7769901609999 , 10.94088984800004 ],
              [-74.77677933099994, 10.940958023000064],
              [-74.77673104199994, 10.940992557000072],
              [-74.7766818849999 , 10.94102771200005 ],
              [-74.77663409699994, 10.941087777000064],
              [-74.77657755199994, 10.94115884900009 ],
              [-74.77649189199991, 10.941288292000081],
              [-74.77644050499993, 10.941363361000072],
              [-74.77641989499995, 10.94142250200008 ],
              [-74.77638577699992, 10.941520406000052],
              [-74.7763691369999 , 10.941590723000047],
              [-74.77634889599994, 10.941676260000065],
              [-74.77634889599994, 10.941702342000042],
              [-74.77634889599994, 10.94177619800007 ],
              [-74.77634967199992, 10.941793011000073],
              [-74.77635246499995, 10.94185353000006 ],
              [-74.77639822099991, 10.941987940000047],
              [-74.7764186039999 , 10.94204781600007 ],
              [-74.77642526699992, 10.942106826000042],
              [-74.7764178999999 , 10.942241382000077],
              [-74.77641040599991, 10.94225689700005 ],
              [-74.77637577399992, 10.94232859200008 ],
              [-74.77632985999992, 10.942416273000049],
              [-74.77630273199992, 10.942446197000038],
              [-74.77625489699994, 10.942498962000059],
              [-74.77618080699995, 10.942557055000066],
              [-74.77617113999992, 10.942564635000053],
              [-74.77595948699991, 10.942664408000041],
              [-74.77593053299995, 10.94267805700008 ],
              [-74.77583325699993, 10.942754992000062],
              [-74.77575295799994, 10.94288055000004 ],
              [-74.77574718599993, 10.942889575000038],
              [-74.77574138599994, 10.942898644000081],
              [-74.77572875299995, 10.94293728200006 ],
              [-74.77559435899991, 10.943043382000042],
              [-74.7755434629999 , 10.943070478000038],
              [-74.77546206099993, 10.943113814000071],
              [-74.77544713899994, 10.943122372000062],
              [-74.77526123399991, 10.943228980000072],
              [-74.77498331299995, 10.943386976000056],
              [-74.77494878899995, 10.943399199000055],
              [-74.77483007599994, 10.94344122800004 ],
              [-74.7745804029999 , 10.94352976600004 ],
              [-74.77441711099993, 10.943598726000062],
              [-74.77435749199992, 10.943623904000049],
              [-74.77427945199992, 10.943678484000088],
              [-74.77411621399995, 10.943829013000084],
              [-74.77407143099992, 10.943862423000041],
              [-74.77401725199991, 10.943902844000093],
              [-74.77389161699995, 10.944000877000065],
              [-74.77378120999992, 10.94405798400004 ],
              [-74.77365176699993, 10.944118899000046],
              [-74.77362945899995, 10.944124933000069],
              [-74.7735338519999 , 10.944150793000063],
              [-74.7733633759999 , 10.944155066000064],
              [-74.77310520299994, 10.944143801000052],
              [-74.77310163599992, 10.944143645000054],
              [-74.77290494399995, 10.944135886000083],
              [-74.77276184899995, 10.944136982000089],
              [-74.77268951299993, 10.944147452000038],
              [-74.77254484199995, 10.944191234000073],
              [-74.77236971299993, 10.944253100000083],
              [-74.77225645099992, 10.944275943000036],
              [-74.77216412799993, 10.944270232000065],
              [-74.7720806719999 , 10.944238167000037],
              [-74.77207063799995, 10.944234312000049],
              [-74.7719775779999 , 10.944187427000088],
              [-74.77189001399995, 10.944167440000058],
              [-74.77171298199994, 10.944141741000067],
              [-74.77168613399994, 10.944138683000062],
              [-74.77160506399991, 10.94412944700008 ],
              [-74.77156259999992, 10.944124609000085],
              [-74.77144648299992, 10.94413127200005 ],
              [-74.77130656999992, 10.944148404000089],
              [-74.77103911899991, 10.944216932000074],
              [-74.7708359749999 , 10.944266251000045],
              [-74.77083589599994, 10.944266270000071],
              [-74.77083583099994, 10.944266286000072],
              [-74.77079612699993, 10.944275925000056],
              [-74.77054468099993, 10.944342626000036],
              [-74.77018251299995, 10.944438698000056],
              [-74.76985319599993, 10.944549105000078],
              [-74.76976753499991, 10.944590984000058],
              [-74.7697275189999 , 10.944626222000068],
              [-74.76970440199995, 10.94464657800006 ],
              [-74.76970376499992, 10.94464713900004 ],
              [-74.76970344499995, 10.944647703000044],
              [-74.76967616399992, 10.944695680000052],
              [-74.76966930599991, 10.944709824000086],
              [-74.76964570699994, 10.944758498000056],
              [-74.76963523699993, 10.944867001000091],
              [-74.76963904399992, 10.94495932400008 ],
              [-74.76963523699993, 10.94502880400006 ],
              [-74.7696038279999 , 10.945111610000083],
              [-74.7695580749999 , 10.945178265000038],
              [-74.76949342099994, 10.945219161000068],
              [-74.76946229599992, 10.945233500000086],
              [-74.76940871199992, 10.945258185000057],
              [-74.76932971399992, 10.945273413000052],
              [-74.76923834299993, 10.945274365000046],
              [-74.76915172999992, 10.94525628100007 ],
              [-74.76898231299992, 10.945210595000049],
              [-74.76888181999993, 10.94518577000008 ],
              [-74.76878426199994, 10.94517506200009 ],
              [-74.76868194499991, 10.945194098000059],
              [-74.76859152599991, 10.945238118000077],
              [-74.76847493199995, 10.945299984000087],
              [-74.76843756499994, 10.945311761000085],
              [-74.76838490999995, 10.94532835700005 ],
              [-74.7683211339999 , 10.945329245000039],
              [-74.76831554199993, 10.945328904000064],
              [-74.7682585309999 , 10.94532543300005 ],
              [-74.76818463799992, 10.945319019000067],
              [-74.76799991599995, 10.945298541000057],
              [-74.76778607799992, 10.945267861000048],
              [-74.7676462849999 , 10.945238639000081],
              [-74.76763317899992, 10.945235899000068],
              [-74.76757549599995, 10.945223841000086],
              [-74.76749102499991, 10.945197667000059],
              [-74.76737972899991, 10.945121975000063],
              [-74.76728758099995, 10.94499303300006 ],
              [-74.7672173869999 , 10.944890716000089],
              [-74.76710317299995, 10.944728913000063],
              [-74.76695088799994, 10.944529038000042],
              [-74.76692419799991, 10.944491030000052],
              [-74.76683769699991, 10.944367842000077],
              [-74.76675163999994, 10.94429023400005 ],
              [-74.7667486329999 , 10.944287523000071],
              [-74.76674204199992, 10.944285204000039],
              [-74.76666649499992, 10.944258625000089],
              [-74.76660130999994, 10.944255384000087],
              [-74.76658008499993, 10.944257815000071],
              [-74.76653073699993, 10.944263466000052],
              [-74.76648332399992, 10.94427681500008 ],
              [-74.76641312899994, 10.94433154300009 ],
              [-74.7663743139999 , 10.944372746000056],
              [-74.7663357969999 , 10.944413634000057],
              [-74.76627274099991, 10.944480259000045],
              [-74.76619190199995, 10.94451283300009 ],
              [-74.76611450699994, 10.94451476200004 ],
              [-74.76602170799993, 10.94449215700007 ],
              [-74.7658949449999 , 10.944440497000073],
              [-74.76585692899994, 10.944415569000057],
              [-74.7658511599999 , 10.944323413000063],
              [-74.76584255599994, 10.94424081200009 ],
              [-74.7658422369999 , 10.944237753000039],
              [-74.76583616999994, 10.944181359000083],
              [-74.7658350989999 , 10.94414566800009 ],
              [-74.76583795399995, 10.944041090000042],
              [-74.76583771199995, 10.943990214000053],
              [-74.76583759699992, 10.943966137000075],
              [-74.76583188699993, 10.943942937000088],
              [-74.76582046499993, 10.94392152200004 ],
              [-74.76580797299994, 10.94391010000004 ],
              [-74.76579548099994, 10.943898679000085],
              [-74.76576228699992, 10.943881547000046],
              [-74.7657287369999 , 10.943872981000084],
              [-74.76569582199994, 10.943865549000066],
              [-74.76567138399992, 10.943869092000057],
              [-74.76563792299993, 10.943873943000085],
              [-74.76563648399991, 10.943874152000092],
              [-74.76563584699994, 10.943752066000059],
              [-74.76563557999992, 10.943700800000045],
              [-74.76563546699992, 10.94367902600004 ],
              [-74.7656354479999 , 10.943675298000073],
              [-74.7656353779999 , 10.943661783000039],
              [-74.76563529399994, 10.943645724000078],
              [-74.76560136599994, 10.943638515000089],
              [-74.76552674999994, 10.943622662000053],
              [-74.76538485599991, 10.943586832000051],
              [-74.76536799699994, 10.943607659000065],
              [-74.76536230099993, 10.943614696000054],
              [-74.7653500749999 , 10.943629800000053],
              [-74.76527988099991, 10.943709512000055],
              [-74.76516864199994, 10.943854065000039],
              [-74.76510055199992, 10.943944161000047],
              [-74.76501219199992, 10.944061078000061],
              [-74.76492712599992, 10.944150902000047],
              [-74.76479328199991, 10.944223476000047],
              [-74.76467787799993, 10.944286532000092],
              [-74.76462552999993, 10.944353751000051],
              [-74.76461471099992, 10.944388274000062],
              [-74.76459767499995, 10.944442639000044],
              [-74.76458507899991, 10.944482837000066],
              [-74.76444703599992, 10.944582193000087],
              [-74.76433490399995, 10.944670166000037],
              [-74.76424005699994, 10.94474457800004 ],
              [-74.76418121599994, 10.944818533000046],
              [-74.7641649439999 , 10.944838984000057],
              [-74.76405729399994, 10.944974285000058],
              [-74.76396403899992, 10.945097690000068],
              [-74.76387076499992, 10.945159556000078],
              [-74.7636779899999 , 10.945216696000045],
              [-74.76363504599993, 10.945222105000084],
              [-74.76339867999991, 10.945251879000068],
              [-74.76321498499993, 10.94527757700007 ],
              [-74.76289459399993, 10.945329432000051],
              [-74.76288566799991, 10.94533087700006 ],
              [-74.76261155399993, 10.945367997000062],
              [-74.76243547399991, 10.945374659000038],
              [-74.76228128499991, 10.945345154000051],
              [-74.76212028599991, 10.945280377000074],
              [-74.76203762799992, 10.94524712000009 ],
              [-74.76180634399992, 10.94514337600009 ],
              [-74.76177022699994, 10.945110219000071],
              [-74.76169022599993, 10.945036776000052],
              [-74.7615788679999 , 10.944923513000049],
              [-74.76156817799995, 10.94491108200009 ],
              [-74.7614970599999 , 10.944828375000043],
              [-74.76146860299991, 10.94479528100004 ],
              [-74.76141032499993, 10.944727508000085],
              [-74.76130443699992, 10.944604367000068],
              [-74.76119457399994, 10.944489585000042],
              [-74.7611051579999 , 10.944396164000068],
              [-74.76107985099992, 10.944375075000039],
              [-74.76107041199992, 10.944367209000063],
              [-74.7609980819999 , 10.944306935000043],
              [-74.76086126299992, 10.944217705000085],
              [-74.76071254599992, 10.944170115000077]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08770",
      "properties": {"id": "08770", "iso": "SUA", "name": "SUAN"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.89591413499994, 10.352686722000044],
              [-74.89592050499994, 10.35234375500005 ],
              [-74.8958677299999 , 10.352072487000044],
              [-74.89578598399993, 10.351952348000054],
              [-74.89571364799991, 10.351846037000087],
              [-74.89565852699991, 10.351792580000051],
              [-74.89552779599995, 10.351665790000084],
              [-74.89535696999991, 10.35155842100005 ],
              [-74.89553892299995, 10.350353529000074],
              [-74.89557302799994, 10.35012768200005 ],
              [-74.89571892899994, 10.349161519000063],
              [-74.89575925999992, 10.348894444000052],
              [-74.89576298499992, 10.348869783000055],
              [-74.8958557059999 , 10.348255786000038],
              [-74.89587778899994, 10.348109544000067],
              [-74.89593879799992, 10.347705545000053],
              [-74.89594044199993, 10.34769465100004 ],
              [-74.89601330399995, 10.347212132000038],
              [-74.89601548499991, 10.347197700000038],
              [-74.8960245959999 , 10.347137355000086],
              [-74.89601008099993, 10.347133075000045],
              [-74.8954844619999 , 10.346978037000042],
              [-74.89468093099993, 10.346743418000074],
              [-74.8946732739999 , 10.346741183000063],
              [-74.8946099129999 , 10.346722683000053],
              [-74.89459188599994, 10.346717419000072],
              [-74.89430405799993, 10.346633377000046],
              [-74.8941933079999 , 10.346970990000045],
              [-74.89411151099995, 10.34738582500006 ],
              [-74.89403390799993, 10.347770954000055],
              [-74.8939551549999 , 10.348231517000045],
              [-74.89392872499991, 10.348627352000051],
              [-74.89393724899992, 10.34918953500005 ],
              [-74.89391660299992, 10.34951415800009 ],
              [-74.89385556299993, 10.35047390200009 ],
              [-74.89380439399991, 10.351278412000056],
              [-74.89369358999994, 10.351279569000042],
              [-74.89203594599991, 10.351296854000054],
              [-74.89161089499993, 10.35130128700007 ],
              [-74.89121802099993, 10.351305384000057],
              [-74.89023631599991, 10.351373983000087],
              [-74.88950052799993, 10.35146198600006 ],
              [-74.88892633199993, 10.351473569000063],
              [-74.88876369099995, 10.351476848000061],
              [-74.88837099699992, 10.352179483000043],
              [-74.88822122599993, 10.352542766000056],
              [-74.88811548299992, 10.35273692900006 ],
              [-74.88765295099995, 10.353586215000064],
              [-74.88563751499993, 10.35316804200005 ],
              [-74.88600438399993, 10.35235855500008 ],
              [-74.8860045209999 , 10.35235825500007 ],
              [-74.8868414449999 , 10.350511607000044],
              [-74.88672049299993, 10.350444375000052],
              [-74.88621450599993, 10.350163118000069],
              [-74.88604671199994, 10.350069846000054],
              [-74.88597893499991, 10.350032169000087],
              [-74.88524748499992, 10.349678904000086],
              [-74.88524744499995, 10.349678883000081],
              [-74.88428547199993, 10.349214282000048],
              [-74.88344741399993, 10.348782810000046],
              [-74.8829521859999 , 10.348529107000047],
              [-74.88257600899993, 10.348330935000092],
              [-74.88236053499992, 10.348221987000045],
              [-74.88186849899995, 10.347598685000037],
              [-74.8817485159999 , 10.347257149000086],
              [-74.8816564629999 , 10.346995114000038],
              [-74.88146044899992, 10.346296044000042],
              [-74.88143419999994, 10.346210668000083],
              [-74.88140772999992, 10.34612456800005 ],
              [-74.8814022169999 , 10.346106631000055],
              [-74.88138195499994, 10.346040706000053],
              [-74.88129277099995, 10.345985460000065],
              [-74.8812804069999 , 10.345979840000041],
              [-74.88120173999994, 10.345944079000049],
              [-74.87980425699993, 10.345308661000047],
              [-74.87869399699991, 10.344803839000065],
              [-74.87840766599993, 10.344673647000093],
              [-74.87828263099993, 10.344616795000093],
              [-74.87814503499993, 10.344554231000075],
              [-74.87806924299991, 10.344519769000044],
              [-74.87789067299991, 10.344523279000043],
              [-74.87788816199992, 10.34452262700006 ],
              [-74.87785498099993, 10.344523279000043],
              [-74.87773730299995, 10.344492708000075],
              [-74.8776662169999 , 10.344474239000078],
              [-74.87762987299993, 10.344464797000057],
              [-74.87755404199993, 10.344445096000072],
              [-74.87734227499993, 10.344409897000048],
              [-74.87667854199992, 10.344299567000064],
              [-74.87650815699993, 10.344271244000083],
              [-74.87643046099993, 10.34425832900007 ],
              [-74.87633922499992, 10.344243163000044],
              [-74.87627807699994, 10.344232999000042],
              [-74.8762682819999 , 10.34431307200009 ],
              [-74.87626502199993, 10.344339718000072],
              [-74.87625886799992, 10.344390022000084],
              [-74.87623174599992, 10.344383016000052],
              [-74.87619854299993, 10.344374439000092],
              [-74.87618532599993, 10.344371025000044],
              [-74.87611483199993, 10.344346030000054],
              [-74.87569368699991, 10.344196706000048],
              [-74.8748388169999 , 10.34467776400004 ],
              [-74.87462293699991, 10.344799245000047],
              [-74.87418159799995, 10.34448926400006 ],
              [-74.87397406499991, 10.344343501000083],
              [-74.87392646799992, 10.344317789000058],
              [-74.87385188299993, 10.344277499000043],
              [-74.87308475999993, 10.343863125000041],
              [-74.87305514299993, 10.34384712700006 ],
              [-74.87183997699992, 10.343190723000077],
              [-74.87119459199994, 10.342842100000041],
              [-74.87091183799993, 10.34261607700006 ],
              [-74.86841791899991, 10.34062253500008 ],
              [-74.8686871669999 , 10.340136078000057],
              [-74.87140720499991, 10.335221699000044],
              [-74.87523805199993, 10.327787888000046],
              [-74.87761601199992, 10.323331885000073],
              [-74.88019557399991, 10.31849810500006 ],
              [-74.88216389799993, 10.31555839500004 ],
              [-74.88444875899995, 10.312145934000057],
              [-74.88799235199991, 10.306617551000045],
              [-74.89153733799992, 10.301676316000055],
              [-74.89531605199994, 10.295442660000049],
              [-74.89861665799992, 10.287800943000093],
              [-74.90096248599991, 10.28091850800007 ],
              [-74.90133725699991, 10.27981896700004 ],
              [-74.90230347999994, 10.277324150000084],
              [-74.90309847299994, 10.27524130200004 ],
              [-74.90378901499992, 10.272630544000037],
              [-74.90399015299994, 10.271870079000053],
              [-74.90537767399991, 10.266813109000054],
              [-74.90633334599994, 10.262485690000062],
              [-74.90710427499994, 10.258994814000062],
              [-74.90716392399992, 10.25872471300005 ],
              [-74.90729351399995, 10.258277212000053],
              [-74.90780677599992, 10.256504877000054],
              [-74.90811956899995, 10.253877569000053],
              [-74.90812362699995, 10.253286073000083],
              [-74.9083342209999 , 10.253451580000046],
              [-74.90862484799993, 10.25367998900009 ],
              [-74.90938212099991, 10.25390776900008 ],
              [-74.9102744139999 , 10.254086228000062],
              [-74.91076964199993, 10.254176099000063],
              [-74.91073013099992, 10.254385033000062],
              [-74.91091855199994, 10.255134405000092],
              [-74.91095398899995, 10.255419503000041],
              [-74.91120934799994, 10.255786313000044],
              [-74.91135404699992, 10.25598321800004 ],
              [-74.91155775699991, 10.256260422000082],
              [-74.9120140579999 , 10.256772248000061],
              [-74.91240677399992, 10.257300360000045],
              [-74.9124434979999 , 10.257349747000092],
              [-74.9128150439999 , 10.257849387000078],
              [-74.91309197599992, 10.258221796000043],
              [-74.9130950359999 , 10.258225912000057],
              [-74.91344848799991, 10.258629067000072],
              [-74.91347357199993, 10.258657678000077],
              [-74.91380696699991, 10.259037956000043],
              [-74.9148383349999 , 10.260214358000042],
              [-74.91484377899991, 10.26022056700009 ],
              [-74.91489910199994, 10.260283669000046],
              [-74.91512629699992, 10.260542813000086],
              [-74.9159105679999 , 10.261417991000087],
              [-74.91610312699993, 10.26163286800005 ],
              [-74.9162199079999 , 10.261763185000063],
              [-74.9162468529999 , 10.261793252000075],
              [-74.91625258199991, 10.261799647000089],
              [-74.91630982599992, 10.261863775000052],
              [-74.91636169799995, 10.26192145300007 ],
              [-74.9163674049999 , 10.26192780000008 ],
              [-74.91638516899991, 10.261947551000048],
              [-74.91640421199992, 10.261968726000077],
              [-74.91643885199994, 10.262007242000038],
              [-74.91650907999991, 10.262085333000073],
              [-74.91666800199994, 10.262262043000078],
              [-74.91673312699993, 10.262334458000055],
              [-74.9169878909999 , 10.262617739000063],
              [-74.91706018199994, 10.262698122000074],
              [-74.91731099499992, 10.262977010000043],
              [-74.91742793799995, 10.263107046000073],
              [-74.91746449599992, 10.263147697000079],
              [-74.91761035799993, 10.263309887000048],
              [-74.91776064899994, 10.26347700100007 ],
              [-74.9178001109999 , 10.263520879000055],
              [-74.91780107499994, 10.263520009000047],
              [-74.9178050559999 , 10.263516424000045],
              [-74.91792975599992, 10.263694286000089],
              [-74.91793362699991, 10.26369980700008 ],
              [-74.91793644899991, 10.263703834000069],
              [-74.91797724399993, 10.263762021000048],
              [-74.91815343099995, 10.264013323000086],
              [-74.9181659809999 , 10.26403023100005 ],
              [-74.9182507829999 , 10.264144480000084],
              [-74.91852668999991, 10.264516192000087],
              [-74.91856441599992, 10.264567018000037],
              [-74.91858875199995, 10.264599805000046],
              [-74.91865536299991, 10.264679834000049],
              [-74.91871953499992, 10.264756934000047],
              [-74.91902093499993, 10.265086989000054],
              [-74.9198711539999 , 10.265946700000086],
              [-74.92037235999993, 10.266674768000087],
              [-74.92120271899995, 10.267880975000082],
              [-74.92143078899994, 10.268212277000089],
              [-74.92317313099994, 10.27074321300006 ],
              [-74.92501672799995, 10.27293452400005 ],
              [-74.92735614499992, 10.276275140000052],
              [-74.92977215699995, 10.279192379000051],
              [-74.93045610199994, 10.280307361000041],
              [-74.9321225939999 , 10.28253171700004 ],
              [-74.9331199849999 , 10.283862974000044],
              [-74.93426017899992, 10.285384814000054],
              [-74.9373268459999 , 10.289477855000086],
              [-74.9371748989999 , 10.289720730000056],
              [-74.9370808029999 , 10.289871137000091],
              [-74.93708075899991, 10.28987120700009 ],
              [-74.93708051899995, 10.289871393000055],
              [-74.93670396199991, 10.29016438800005 ],
              [-74.93669688599994, 10.290169890000072],
              [-74.93664319099992, 10.29021165100005 ],
              [-74.9366400909999 , 10.29021408400007 ],
              [-74.93671273099994, 10.290311579000047],
              [-74.93666969199995, 10.290346369000076],
              [-74.93666873099994, 10.290347146000045],
              [-74.93656075899992, 10.29043444000007 ],
              [-74.93621588899993, 10.29071325600006 ],
              [-74.93610119499994, 10.290931833000059],
              [-74.93615965499993, 10.29095105600004 ],
              [-74.93621300599995, 10.291008292000072],
              [-74.93628260999992, 10.291086215000064],
              [-74.93631515699991, 10.291148169000053],
              [-74.9363152119999 , 10.29124908600005 ],
              [-74.9363153189999 , 10.291338607000057],
              [-74.93629686199995, 10.291448787000093],
              [-74.93623200199994, 10.291563618000055],
              [-74.93620533099994, 10.291592648000062],
              [-74.93620530299995, 10.291592679000075],
              [-74.93603980699993, 10.29177278900005 ],
              [-74.93600740799991, 10.291841659000056],
              [-74.93593100399994, 10.291988685000092],
              [-74.93588474299992, 10.292075925000063],
              [-74.93581070899995, 10.292227539000066],
              [-74.9357413159999 , 10.292381402000046],
              [-74.93570669099995, 10.292551264000053],
              [-74.93572386599993, 10.292920203000051],
              [-74.93578820599993, 10.293051418000061],
              [-74.9358486129999 , 10.293211951000046],
              [-74.93587426699992, 10.293413867000083],
              [-74.93593474999994, 10.293652447000056],
              [-74.93604533599995, 10.293947930000058],
              [-74.93605449299992, 10.293972409000048],
              [-74.93606273899991, 10.293994440000063],
              [-74.93607906499994, 10.29403804900005 ],
              [-74.93614693599994, 10.294130977000066],
              [-74.93627054599995, 10.294300218000046],
              [-74.93650483199991, 10.29472725100004 ],
              [-74.93675725499992, 10.295110514000044],
              [-74.9369164819999 , 10.29539360800004 ],
              [-74.9370243699999 , 10.29559914400005 ],
              [-74.9371161489999 , 10.295734246000052],
              [-74.93717158499993, 10.295815849000064],
              [-74.93721688099993, 10.29600358500005 ],
              [-74.93724196799991, 10.296069235000061],
              [-74.93743365699993, 10.296570887000087],
              [-74.9377063949999 , 10.297284638000065],
              [-74.9379128349999 , 10.297824886000058],
              [-74.9380484749999 , 10.298141745000066],
              [-74.93804851399995, 10.298141839000039],
              [-74.93806058099995, 10.298169053000038],
              [-74.93794250499991, 10.29843850800006 ],
              [-74.9378189439999 , 10.29888931000005 ],
              [-74.9377361839999 , 10.299243387000047],
              [-74.9377066529999 , 10.299414724000087],
              [-74.93768310699994, 10.29955134100004 ],
              [-74.9376682219999 , 10.299660637000045],
              [-74.93765025899995, 10.299792529000058],
              [-74.9376502849999 , 10.299795700000061],
              [-74.93765031799995, 10.299798871000064],
              [-74.93765035699994, 10.29980204800006 ],
              [-74.93765039899995, 10.299805220000053],
              [-74.93765043799993, 10.299808383000084],
              [-74.93765047399995, 10.299811556000066],
              [-74.93765051999992, 10.299814727000069],
              [-74.93765056199993, 10.29981790000005 ],
              [-74.93765060999993, 10.29982106500006 ],
              [-74.93765064399992, 10.299824237000053],
              [-74.93765069799991, 10.29982741300006 ],
              [-74.93765074599992, 10.299830585000052],
              [-74.93765164699994, 10.299833842000055],
              [-74.93765147199991, 10.299836992000053],
              [-74.93765120799992, 10.299840131000053],
              [-74.93765094199995, 10.299843266000039],
              [-74.9376509949999 , 10.299846441000057],
              [-74.93765104999994, 10.299849602000052],
              [-74.93765110499993, 10.299852774000044],
              [-74.93765115999992, 10.299855950000051],
              [-74.93765121299992, 10.299859121000054],
              [-74.93765126699992, 10.299861819000057],
              [-74.93765133199992, 10.299865458000056],
              [-74.93765139599992, 10.299868630000049],
              [-74.93765145599991, 10.299871802000041],
              [-74.93765152199995, 10.29987496700005 ],
              [-74.93765158799994, 10.299878138000054],
              [-74.93765164699994, 10.29988131500005 ],
              [-74.9376517149999 , 10.299884487000043],
              [-74.93765177799992, 10.299887647000048],
              [-74.93765184999995, 10.299890823000055],
              [-74.93765192199993, 10.299893995000048],
              [-74.9376519939999 , 10.299897160000057],
              [-74.93765205799991, 10.29990033100006 ],
              [-74.9376521399999 , 10.299903503000053],
              [-74.93765221299992, 10.29990667900006 ],
              [-74.93765228399991, 10.299909840000055],
              [-74.93765235899991, 10.299913012000047],
              [-74.93765243999991, 10.29991618400004 ],
              [-74.93765251899993, 10.29991934800006 ],
              [-74.93765259299994, 10.299922522000088],
              [-74.93765267399993, 10.29992569600006 ],
              [-74.9376527579999 , 10.299928868000052],
              [-74.93765284499995, 10.299932033000061],
              [-74.9376529299999 , 10.299935205000054],
              [-74.93765301199994, 10.299938377000046],
              [-74.93765309899993, 10.299941542000056],
              [-74.9376531929999 , 10.299944713000059],
              [-74.93765327499995, 10.299947885000051],
              [-74.93765336699994, 10.299951050000061],
              [-74.9376534569999 , 10.299954221000064],
              [-74.93765354599992, 10.299957393000057],
              [-74.9376536449999 , 10.299960560000045],
              [-74.93765373399992, 10.299963730000059],
              [-74.93765383499994, 10.299966902000051],
              [-74.93765392499995, 10.29997006900004 ],
              [-74.93765402799994, 10.299973237000074],
              [-74.93765412699992, 10.299976412000092],
              [-74.93765422399991, 10.299979577000045],
              [-74.93765432899994, 10.299982749000037],
              [-74.93765442799992, 10.29998592000004 ],
              [-74.93765453499992, 10.299989087000085],
              [-74.93765463699992, 10.299992257000042],
              [-74.93765474499992, 10.29999543200006 ],
              [-74.9376548539999 , 10.299998593000055],
              [-74.93765496499992, 10.300001766000037],
              [-74.9376550739999 , 10.300004929000067],
              [-74.93765518299995, 10.30000810100006 ],
              [-74.93765529199993, 10.300011275000088],
              [-74.93765540999993, 10.300014441000087],
              [-74.93765551899992, 10.300017610000054],
              [-74.93765563399995, 10.300020775000064],
              [-74.93765575399993, 10.300023947000057],
              [-74.93765586299992, 10.30002711800006 ],
              [-74.9376559829999 , 10.300030285000048],
              [-74.93765610999992, 10.300033458000087],
              [-74.93765622699993, 10.30003662100006 ],
              [-74.93765634599993, 10.300039793000053],
              [-74.93765647399994, 10.300042956000084],
              [-74.93765659199994, 10.300046130000055],
              [-74.93765671799991, 10.300049302000048],
              [-74.93765684599992, 10.300052467000057],
              [-74.93765697399994, 10.30005563900005 ],
              [-74.93765709899992, 10.300058801000091],
              [-74.9376572349999 , 10.300061974000073],
              [-74.93765736499995, 10.300065138000093],
              [-74.93765749099992, 10.30006830900004 ],
              [-74.9376576279999 , 10.300071474000049],
              [-74.93765776299995, 10.300074646000041],
              [-74.93765789899993, 10.300077811000051],
              [-74.93765803299993, 10.300080982000054],
              [-74.9376581699999 , 10.300084147000064],
              [-74.93765831099995, 10.300087319000056],
              [-74.93765844899991, 10.300090482000087],
              [-74.9376585899999 , 10.300093656000058],
              [-74.93765873799993, 10.300096818000043],
              [-74.93765887299992, 10.300099991000081],
              [-74.93765902099994, 10.300103154000055],
              [-74.9376591649999 , 10.300106325000058],
              [-74.93765930899991, 10.300109491000057],
              [-74.93765946699995, 10.30011266300005 ],
              [-74.93765960999991, 10.30011582800006 ],
              [-74.9376597669999 , 10.300118999000063],
              [-74.9376599119999 , 10.300122162000036],
              [-74.93766006699991, 10.30012533300004 ],
              [-74.93766021699992, 10.30012849800005 ],
              [-74.93766037599994, 10.300131663000059],
              [-74.93766052899991, 10.300134835000051],
              [-74.93766068399992, 10.300137997000093],
              [-74.93766084099991, 10.300141171000064],
              [-74.93766100499994, 10.300144332000059],
              [-74.93766116699993, 10.300147499000047],
              [-74.93766132299993, 10.30015067000005 ],
              [-74.93766148599991, 10.30015383500006 ],
              [-74.93766165099993, 10.300157007000053],
              [-74.93766181399991, 10.300160169000037],
              [-74.9376619869999 , 10.300163333000057],
              [-74.93766215099993, 10.30016650400006 ],
              [-74.9376623149999 , 10.300169671000049],
              [-74.93766248799994, 10.300172832000044],
              [-74.93766265799991, 10.300176002000057],
              [-74.93766283299993, 10.300179169000046],
              [-74.93766300599992, 10.300182331000087],
              [-74.93766317899991, 10.300185506000048],
              [-74.93766334999992, 10.300188665000064],
              [-74.93766352599994, 10.300191831000063],
              [-74.93766370799995, 10.300195003000056],
              [-74.93766387999995, 10.300198166000087],
              [-74.93766406199995, 10.30020133000005 ],
              [-74.93766424499995, 10.300204502000042],
              [-74.9376644269999 , 10.300207665000073],
              [-74.93766460899991, 10.300210828000047],
              [-74.93766478599991, 10.300213993000057],
              [-74.93766497999991, 10.30021716500005 ],
              [-74.93766516399995, 10.300220330000059],
              [-74.93766535499992, 10.300223492000043],
              [-74.93766553299992, 10.300226656000063],
              [-74.93766572999994, 10.300229828000056],
              [-74.93766591899993, 10.300232994000055],
              [-74.93766611099994, 10.30023615400006 ],
              [-74.93766630199991, 10.30023932100005 ],
              [-74.93766650299995, 10.300242481000055],
              [-74.93766669399992, 10.300245655000083],
              [-74.9376668889999 , 10.300248818000057],
              [-74.93766709199991, 10.300251980000041],
              [-74.93766728299994, 10.300255145000051],
              [-74.9376674849999 , 10.300258307000092],
              [-74.93766768599994, 10.300261479000085],
              [-74.93766789299991, 10.300264643000048],
              [-74.93766809599992, 10.300267808000058],
              [-74.93766829499992, 10.300270968000063],
              [-74.93766850199995, 10.300274134000063],
              [-74.93766871399993, 10.300277295000058],
              [-74.93766892299993, 10.300280463000092],
              [-74.93766912599995, 10.300283623000041],
              [-74.93766934199994, 10.300286794000044],
              [-74.93766955299992, 10.300289959000054],
              [-74.93766976199993, 10.300293122000085],
              [-74.93766998099994, 10.300296286000048],
              [-74.93767019099994, 10.300299446000054],
              [-74.93767041099994, 10.300302610000074],
              [-74.93767062799992, 10.300305775000083],
              [-74.93767084699994, 10.300308941000083],
              [-74.93767106399991, 10.300312104000056],
              [-74.93767128299993, 10.300315265000052],
              [-74.93767149999991, 10.300318430000061],
              [-74.93767173099991, 10.300321592000046],
              [-74.93767194799995, 10.300324757000055],
              [-74.9376721779999 , 10.300327917000061],
              [-74.9376724039999 , 10.300331085000039],
              [-74.93767263199993, 10.300334245000045],
              [-74.93767285699994, 10.300337411000044],
              [-74.93767308699995, 10.30034057100005 ],
              [-74.93767331399994, 10.300343736000059],
              [-74.93767354699992, 10.300346897000054],
              [-74.93767377799992, 10.300350063000053],
              [-74.93767401699995, 10.300353224000048],
              [-74.93767425199991, 10.300356388000068],
              [-74.93767449099994, 10.300359551000042],
              [-74.9376747259999 , 10.300362706000044],
              [-74.93767496399994, 10.300365869000075],
              [-74.93767519999994, 10.30036903100006 ],
              [-74.93767543199993, 10.300372196000069],
              [-74.93767568299995, 10.300375358000053],
              [-74.9376759299999 , 10.300378520000038],
              [-74.93767616499991, 10.300381685000048],
              [-74.93767640899995, 10.300384841000039],
              [-74.93767665799993, 10.300388005000059],
              [-74.93767691099993, 10.300391166000054],
              [-74.9376771549999 , 10.300394329000085],
              [-74.93767740499993, 10.300397493000048],
              [-74.93767765599995, 10.30040064700006 ],
              [-74.93767791399995, 10.300403808000056],
              [-74.93767815799993, 10.300406974000055],
              [-74.93767841599993, 10.30041013400006 ],
              [-74.9376786709999 , 10.30041330000006 ],
              [-74.93767892699992, 10.300416455000061],
              [-74.93767918999993, 10.300419617000045],
              [-74.9376794449999 , 10.30042277800004 ],
              [-74.93767970999994, 10.300425933000042],
              [-74.93767996199995, 10.300429097000062],
              [-74.9376802299999 , 10.300432259000047],
              [-74.93768049099992, 10.30043541300006 ],
              [-74.93768075799994, 10.300438579000058],
              [-74.93768102099995, 10.300441740000053],
              [-74.93768128699992, 10.300444896000045],
              [-74.93768155999993, 10.300448058000086],
              [-74.93768181999991, 10.30045122100006 ],
              [-74.93768209599995, 10.300454376000062],
              [-74.93768237199993, 10.300457538000046],
              [-74.9376826429999 , 10.300460703000056],
              [-74.9376829169999 , 10.300463859000047],
              [-74.9376831909999 , 10.300467019000052],
              [-74.93768346099995, 10.300470174000054],
              [-74.93768373799992, 10.300473336000039],
              [-74.93768401599993, 10.300476489000062],
              [-74.93768430099993, 10.30047965500006 ],
              [-74.93768457199991, 10.300482807000037],
              [-74.93768485599992, 10.300485971000057],
              [-74.93768514099992, 10.30048912500007 ],
              [-74.93768542299995, 10.300492286000065],
              [-74.9376857119999 , 10.300495443000045],
              [-74.9376859969999 , 10.300498605000087],
              [-74.93768628799995, 10.300501758000053],
              [-74.93768657099992, 10.300504923000062],
              [-74.9376868629999 , 10.300508076000085],
              [-74.93768715399995, 10.300511241000038],
              [-74.93768744599993, 10.30051439400006 ],
              [-74.9376877389999 , 10.300517556000045],
              [-74.93768802799991, 10.300520711000047],
              [-74.93768832099994, 10.300523866000049],
              [-74.93768862199994, 10.300527026000054],
              [-74.93768892199995, 10.300530182000045],
              [-74.93768921199995, 10.300533337000047],
              [-74.93768951299995, 10.300536499000088],
              [-74.93768981499994, 10.300539653000044],
              [-74.93769011699993, 10.300542808000046],
              [-74.93769042699995, 10.300545971000076],
              [-74.93769072499992, 10.300549124000042],
              [-74.93769102699991, 10.300552279000044],
              [-74.93769133699993, 10.30055544000004 ],
              [-74.9376916459999 , 10.300558594000051],
              [-74.93769195699991, 10.300561748000064],
              [-74.93769226699993, 10.300564905000044],
              [-74.93769257399993, 10.300568067000086],
              [-74.93769288499993, 10.300571221000041],
              [-74.93769319599994, 10.300574376000043],
              [-74.93769351499992, 10.300577531000044],
              [-74.93769382199991, 10.300580683000078],
              [-74.93769414399992, 10.300583844000073],
              [-74.93769445799995, 10.300587         ],
              [-74.9376947799999 , 10.300590157000045],
              [-74.93769510099992, 10.300593307000042],
              [-74.9376954199999 , 10.300596460000065],
              [-74.93769573799995, 10.300599617000046],
              [-74.93769606599994, 10.300602772000047],
              [-74.93769639499993, 10.30060592600006 ],
              [-74.93769670899991, 10.300609078000093],
              [-74.93769704099992, 10.30061223100006 ],
              [-74.93769736999991, 10.300615397000058],
              [-74.93769769799991, 10.300618549000092],
              [-74.93769802399993, 10.300621703000047],
              [-74.93769836199994, 10.30062485600007 ],
              [-74.93769868999993, 10.30062801300005 ],
              [-74.93769902999992, 10.300631165000084],
              [-74.93769935299991, 10.30063431900004 ],
              [-74.9376996929999 , 10.300637473000052],
              [-74.93770003199995, 10.300640617000056],
              [-74.93770036899991, 10.300643773000047],
              [-74.93770070799991, 10.300646928000049],
              [-74.93770104899994, 10.300650079000093],
              [-74.93770138799994, 10.300653232000059],
              [-74.93770173599995, 10.30065638700006 ],
              [-74.9377020739999 , 10.300659542000062],
              [-74.9377024179999 , 10.300662694000039],
              [-74.93770276599992, 10.300665848000051],
              [-74.93770310999992, 10.30066899500008 ],
              [-74.93770345899992, 10.300672148000046],
              [-74.93770381299993, 10.300675303000048],
              [-74.93770415999995, 10.30067845800005 ],
              [-74.93770451599994, 10.300681609000037],
              [-74.93770485999994, 10.300684753000041],
              [-74.9377052179999 , 10.300687908000043],
              [-74.93770557199991, 10.300691063000045],
              [-74.9377059279999 , 10.300694217000057],
              [-74.9377062829999 , 10.30069736200005 ],
              [-74.93770664999994, 10.300700516000063],
              [-74.93770700199991, 10.300703673000044],
              [-74.93770736699992, 10.300706812000044],
              [-74.93770772099992, 10.300709966000056],
              [-74.93770808699992, 10.300713120000069],
              [-74.9377084539999 , 10.300716267000041],
              [-74.93770881799992, 10.300719421000053],
              [-74.93770918299992, 10.300722565000058],
              [-74.93770954599995, 10.300725721000049],
              [-74.93770991899993, 10.300728871000047],
              [-74.93771028399993, 10.300732017000087],
              [-74.93771066099993, 10.300735171000042],
              [-74.9377110289999 , 10.300738319000061],
              [-74.93771140599995, 10.300741469000059],
              [-74.93771177999992, 10.300744615000042],
              [-74.93771215399994, 10.300747769000054],
              [-74.9377125279999 , 10.300750914000048],
              [-74.93771291099995, 10.30075406700007 ],
              [-74.93771327999991, 10.300757212000065],
              [-74.9377136679999 , 10.300760369000045],
              [-74.93771403999995, 10.30076351300005 ],
              [-74.93771442099995, 10.300766656000064],
              [-74.9377148069999 , 10.300769809000087],
              [-74.93771518999995, 10.30077295600006 ],
              [-74.93771558299994, 10.300776101000054],
              [-74.93771596599993, 10.300779253000087],
              [-74.93771635799993, 10.30078240000006 ],
              [-74.93771673799995, 10.300785545000053],
              [-74.93771713199993, 10.300788695000051],
              [-74.93771752299995, 10.300791840000045],
              [-74.93771791499995, 10.300794986000085],
              [-74.93771830799994, 10.300798139000051],
              [-74.93771870099994, 10.300801283000055],
              [-74.93771909899993, 10.300804429000038],
              [-74.93771949199993, 10.300807574000089],
              [-74.93771989399994, 10.300810718000037],
              [-74.93772028599994, 10.30081387100006 ],
              [-74.93772068499993, 10.300817016000053],
              [-74.93772108799993, 10.300820166000051],
              [-74.93772148499994, 10.300823304000062],
              [-74.93772189899994, 10.300826451000091],
              [-74.9377222999999 , 10.300829599000053],
              [-74.93772270099993, 10.300832743000058],
              [-74.93772311299995, 10.300835887000062],
              [-74.93772351799993, 10.300839033000045],
              [-74.93772393299992, 10.30084218400009 ],
              [-74.9377243429999 , 10.300845329000083],
              [-74.93772475199995, 10.300848476000056],
              [-74.9377251599999 , 10.30085162000006 ],
              [-74.93772557199992, 10.300854765000054],
              [-74.93772599199991, 10.30085789900005 ],
              [-74.93772640299994, 10.300861043000054],
              [-74.93772682199995, 10.300864189000038],
              [-74.93772724199994, 10.300867332000053],
              [-74.93772766099994, 10.30087048100006 ],
              [-74.93772807599993, 10.30087362200004 ],
              [-74.93772849599992, 10.300876766000044],
              [-74.93772891799995, 10.300879914000063],
              [-74.9377293469999 , 10.300883054000053],
              [-74.93772976699995, 10.300886191000075],
              [-74.93773018999991, 10.300889338000047],
              [-74.93773062399993, 10.300892482000052],
              [-74.93773105399993, 10.300895627000045],
              [-74.9377314809999 , 10.30089877100005 ],
              [-74.93773190899992, 10.300901906000092],
              [-74.93773233399992, 10.300905051000086],
              [-74.93773277699995, 10.300908198000059],
              [-74.93773320199995, 10.300911332000055],
              [-74.93773364099991, 10.300914477000049],
              [-74.93773407899994, 10.300917621000053],
              [-74.93773451499993, 10.30092075500005 ],
              [-74.93773494999994, 10.30092390100009 ],
              [-74.93773539099993, 10.300927045000037],
              [-74.93773582999995, 10.300930182000059],
              [-74.9377362749999 , 10.300933327000052],
              [-74.93773671299994, 10.300936462000038],
              [-74.93773715799995, 10.300939606000043],
              [-74.93773760599993, 10.300942741000085],
              [-74.93773805399991, 10.300945887000069],
              [-74.9377385009999 , 10.300949022000054],
              [-74.93773894599991, 10.300952166000059],
              [-74.9377393929999 , 10.300955300000055],
              [-74.93773984599994, 10.300958446000038],
              [-74.93774029299993, 10.30096158200007 ],
              [-74.9377407529999 , 10.300964729000043],
              [-74.9377412099999 , 10.30096786100006 ],
              [-74.93774166399993, 10.300970997000093],
              [-74.93774211999994, 10.30097414100004 ],
              [-74.93774257099994, 10.300977279000051],
              [-74.9377430319999 , 10.300980414000037],
              [-74.93774348799991, 10.300983556000062],
              [-74.93774395299994, 10.300986692000038],
              [-74.93774440899995, 10.30098982700008 ],
              [-74.93774487399992, 10.300992965000091],
              [-74.9377453379999 , 10.300996109000039],
              [-74.93774580099995, 10.30099924700005 ],
              [-74.9377462679999 , 10.301002381000046],
              [-74.93774674199994, 10.301005516000089],
              [-74.93774720699992, 10.301008653000054],
              [-74.93774766999991, 10.30101178700005 ],
              [-74.93774814499994, 10.301014931000054],
              [-74.93774861899993, 10.301018067000086],
              [-74.93774909299992, 10.301021204000051],
              [-74.93774956699991, 10.301024338000047],
              [-74.93775004299994, 10.301027472000044],
              [-74.93775051599994, 10.301030611000044],
              [-74.9377509919999 , 10.301033743000062],
              [-74.93775147499991, 10.301036880000083],
              [-74.9377519489999 , 10.301040016000059],
              [-74.93775243099992, 10.301043151000044],
              [-74.93775291399993, 10.30104628500004 ],
              [-74.9377533949999 , 10.301049422000062],
              [-74.93775388099994, 10.301052556000059],
              [-74.93775436499993, 10.30105569500006 ],
              [-74.9377548569999 , 10.301058819000048],
              [-74.93775533999991, 10.301061957000059],
              [-74.93775583199994, 10.30106509300009 ],
              [-74.93775631599993, 10.301068225000051],
              [-74.93775680899995, 10.301071361000083],
              [-74.93775729899994, 10.301074486000061],
              [-74.9377577919999 , 10.301077623000083],
              [-74.93775828499992, 10.301080759000058],
              [-74.93775878699995, 10.301083894000044],
              [-74.93775927699994, 10.301087021000058],
              [-74.9377597809999 , 10.301090156000043],
              [-74.93776028199994, 10.301093291000086],
              [-74.93776077099994, 10.301096416000064],
              [-74.93776127399991, 10.301099553000086],
              [-74.93776177399991, 10.30110268900006 ],
              [-74.93776228799993, 10.301105815000085],
              [-74.93776278899992, 10.30110895100006 ],
              [-74.93776329099995, 10.301112076000038],
              [-74.93776380199995, 10.30111521300006 ],
              [-74.93776430299994, 10.301118338000038],
              [-74.9377648119999 , 10.301121476000048],
              [-74.93776532299995, 10.301124599000048],
              [-74.93776583499994, 10.301127738000048],
              [-74.93776634499994, 10.301130863000083],
              [-74.93776686599995, 10.30113398800006 ],
              [-74.93776737499991, 10.301137124000093],
              [-74.93776789599991, 10.30114025100005 ],
              [-74.93776840499993, 10.301143385000046],
              [-74.93776892699992, 10.301146513000049],
              [-74.93776944599995, 10.301149638000084],
              [-74.93776996499992, 10.30115276500004 ],
              [-74.93777048099992, 10.301155899000037],
              [-74.93777100499995, 10.30115902600005 ],
              [-74.93777153499991, 10.301162153000064],
              [-74.93777205299995, 10.301165282000056],
              [-74.93777258199992, 10.301168415000063],
              [-74.93777310999991, 10.301171540000041],
              [-74.93777363799995, 10.301174667000055],
              [-74.93777416899991, 10.301177796000047],
              [-74.93777469899993, 10.301180919000046],
              [-74.93777522699992, 10.30118404600006 ],
              [-74.9377757549999 , 10.301187171000038],
              [-74.9377762929999 , 10.30119029900004 ],
              [-74.93777682699994, 10.301193426000054],
              [-74.93777735499992, 10.30119655100009 ],
              [-74.93777789799992, 10.301199679000092],
              [-74.93777843599992, 10.30120280500006 ],
              [-74.9377789699999 , 10.301205930000037],
              [-74.9377795119999 , 10.301209057000051],
              [-74.93778005399992, 10.301212186000043],
              [-74.93778059599993, 10.301215309000042],
              [-74.93778114399993, 10.301218436000056],
              [-74.93778169099994, 10.301221564000059],
              [-74.93778222999993, 10.301224688000048],
              [-74.93778277599995, 10.301227803000074],
              [-74.93778333199992, 10.301230933000056],
              [-74.93778387899994, 10.301234058000091],
              [-74.93778442699994, 10.301237185000048],
              [-74.93778498199993, 10.301240301000064],
              [-74.93778552999993, 10.301243425000052],
              [-74.9377860859999 , 10.301246552000066],
              [-74.93778664199994, 10.301249670000061],
              [-74.93778719799991, 10.301252798000064],
              [-74.93778775499993, 10.301255922000053],
              [-74.93778831199995, 10.301259040000048],
              [-74.93778886699994, 10.301262166000072],
              [-74.93778943299992, 10.301265286000046],
              [-74.93778998899995, 10.301268408000055],
              [-74.93779054999993, 10.30127152600005 ],
              [-74.93779111899994, 10.301274653000064],
              [-74.93779168499992, 10.301277772000049],
              [-74.9377922509999 , 10.301280896000037],
              [-74.93779281499991, 10.301284014000089],
              [-74.93779338199994, 10.301287141000046],
              [-74.93779395399991, 10.301290257000062],
              [-74.93779452099994, 10.301293375000057],
              [-74.93779509299992, 10.30129650300006 ],
              [-74.93779566099994, 10.30129961700004 ],
              [-74.9377962339999 , 10.30130273800006 ],
              [-74.9377968089999 , 10.301305851000052],
              [-74.93779739399992, 10.301308980000044],
              [-74.93779796799993, 10.30131209600006 ],
              [-74.93779853799992, 10.301315214000056],
              [-74.9377991259999 , 10.301318331000061],
              [-74.93779969999991, 10.301321449000056],
              [-74.93780028399993, 10.301324574000091],
              [-74.9378008679999 , 10.30132769000005 ],
              [-74.93780145099993, 10.301330807000056],
              [-74.93780203499995, 10.301333925000051],
              [-74.93780261699993, 10.301337043000046],
              [-74.9378032109999 , 10.301340157000084],
              [-74.93780379399993, 10.301343279000037],
              [-74.93780438699991, 10.301346393000074],
              [-74.9378049789999 , 10.301349512000058],
              [-74.93780557199995, 10.301352627000085],
              [-74.9378061669999 , 10.301355744000091],
              [-74.93780675699992, 10.301358862000086],
              [-74.93780735099995, 10.301361970000073],
              [-74.93780794399993, 10.301365088000068],
              [-74.93780847799991, 10.301367897000091],
              [-74.93780902799995, 10.301370706000057],
              [-74.9378095639999 , 10.301373528000056],
              [-74.93781011099992, 10.301376335000043],
              [-74.93781064699994, 10.301379147000091],
              [-74.93781118999993, 10.301381957000046],
              [-74.93781173999992, 10.30138476600007 ],
              [-74.93781227499994, 10.301387575000092],
              [-74.93781282399993, 10.301390385000047],
              [-74.9378133699999 , 10.301393197000039],
              [-74.93786253799993, 10.301628537000056],
              [-74.9378631539999 , 10.301631332000056],
              [-74.93786378599992, 10.30163413300005 ],
              [-74.93786441399993, 10.301636922000057],
              [-74.93786503899992, 10.301639713000043],
              [-74.93786566099993, 10.301642506000064],
              [-74.9378662869999 , 10.30164529700005 ],
              [-74.93786691999992, 10.301648091000061],
              [-74.93786755499991, 10.301650880000068],
              [-74.93786818399991, 10.30165367300009 ],
              [-74.93786880899995, 10.301656471000058],
              [-74.93786944699991, 10.301659266000058],
              [-74.93787007999993, 10.301662057000044],
              [-74.93787071699995, 10.30166484700004 ],
              [-74.93787135399992, 10.301667631000043],
              [-74.93787199099995, 10.30167042100004 ],
              [-74.93787262899991, 10.30167321500005 ],
              [-74.93787326499995, 10.301676004000058],
              [-74.9378739039999 , 10.301678795000043],
              [-74.93787454099993, 10.301681590000044],
              [-74.93787518399995, 10.30168438000004 ],
              [-74.93787581999993, 10.301687171000083],
              [-74.93787646999994, 10.30168995200006 ],
              [-74.93787710899994, 10.301692747000061],
              [-74.93787775599992, 10.301695537000057],
              [-74.93787840199991, 10.30169832100006 ],
              [-74.9378790479999 , 10.301701111000057],
              [-74.93787969599992, 10.301703900000064],
              [-74.93788034399995, 10.301706685000056],
              [-74.9378809879999 , 10.301709475000052],
              [-74.93788164499995, 10.301712265000049],
              [-74.93788228999995, 10.301715049000052],
              [-74.93788294699993, 10.301717839000048],
              [-74.9378835899999 , 10.301720623000051],
              [-74.93788424999991, 10.301723417000062],
              [-74.93788490499992, 10.30172619700005 ],
              [-74.9378855569999 , 10.301728991000061],
              [-74.93788621599992, 10.30173177100005 ],
              [-74.93788687199992, 10.30173456500006 ],
              [-74.93788752299992, 10.30173734500005 ],
              [-74.93788818999991, 10.301740129000052],
              [-74.93788884799994, 10.301742919000048],
              [-74.93788951199991, 10.301745703000051],
              [-74.93789016699992, 10.301748484000086],
              [-74.93789083299993, 10.301751276000061],
              [-74.9378914959999 , 10.301754058000085],
              [-74.93789216199991, 10.301756839000063],
              [-74.93789282599994, 10.301759625000045],
              [-74.9378934909999 , 10.301762413000063],
              [-74.93789415599991, 10.301765199000045],
              [-74.93789481999994, 10.301767982000058],
              [-74.93789549699994, 10.301770762000046],
              [-74.93789616099991, 10.30177354600005 ],
              [-74.93789683499995, 10.301776326000038],
              [-74.93789750499991, 10.301779111000087],
              [-74.9378981719999 , 10.301781893000054],
              [-74.93789884699993, 10.301784678000047],
              [-74.93789952299994, 10.301787456000056],
              [-74.93790019499994, 10.30179023900007 ],
              [-74.93790087999992, 10.301793020000048],
              [-74.9379015529999 , 10.301795806000086],
              [-74.93790222299992, 10.301798587000064],
              [-74.93790290999993, 10.301801368000042],
              [-74.93790359299993, 10.301804153000091],
              [-74.9379042679999 , 10.301806924000061],
              [-74.9379049499999 , 10.301809709000054],
              [-74.93790563399995, 10.301812489000042],
              [-74.93790631699994, 10.301815272000056],
              [-74.93790699699991, 10.301818045000061],
              [-74.93790768299993, 10.301820827000086],
              [-74.93790837499995, 10.301823614000057],
              [-74.9379090569999 , 10.301826383000048],
              [-74.93790973699993, 10.301829166000061],
              [-74.93791043099992, 10.301831948000086],
              [-74.93791112699995, 10.301834725000049],
              [-74.93791180799991, 10.301837503000058],
              [-74.93791250099991, 10.301840277000053],
              [-74.93791319199994, 10.301843063000092],
              [-74.93791388699992, 10.301845835000051],
              [-74.93791458599992, 10.30184861400005 ],
              [-74.93791527999991, 10.30185139200006 ],
              [-74.93791596999995, 10.301854160000062],
              [-74.9379166729999 , 10.301856946000044],
              [-74.93791736399993, 10.30185971900005 ],
              [-74.93791806199994, 10.301862499000038],
              [-74.93791876799992, 10.301865277000047],
              [-74.93791946999994, 10.301868048000074],
              [-74.93792016899994, 10.301870819000044],
              [-74.93792086899992, 10.301873603000047],
              [-74.93792157199994, 10.301876376000052],
              [-74.93792227499995, 10.301879149000058],
              [-74.93792297199991, 10.301881922000064],
              [-74.93792368699991, 10.301884697000048],
              [-74.93792438499992, 10.301887470000054],
              [-74.93792509899993, 10.301890249000053],
              [-74.93792580599995, 10.301893023000048],
              [-74.93792651799993, 10.30189579800009 ],
              [-74.93792722799992, 10.301898571000038],
              [-74.9379279339999 , 10.301901343000054],
              [-74.93792864999995, 10.301904118000039],
              [-74.93792935999994, 10.301906895000059],
              [-74.93793007099993, 10.30190966300006 ],
              [-74.93793078999994, 10.301912427000048],
              [-74.93793150199991, 10.301915202000089],
              [-74.93793221999994, 10.301917975000038],
              [-74.93793293899995, 10.30192074900009 ],
              [-74.93793364899994, 10.301923521000049],
              [-74.93793437099993, 10.301926298000069],
              [-74.93793508899995, 10.301929059000088],
              [-74.93793581299991, 10.301931833000083],
              [-74.93793653799992, 10.301934606000088],
              [-74.93793725699993, 10.30193736800004 ],
              [-74.93793798399992, 10.30194014500006 ],
              [-74.93793870299993, 10.30194291500004 ],
              [-74.93793943299994, 10.301945681000063],
              [-74.93794015399993, 10.301948456000048],
              [-74.93794088299995, 10.301951218000056],
              [-74.93794161199992, 10.301953991000062],
              [-74.93794233799991, 10.30195675400006 ],
              [-74.93794306799992, 10.301959529000044],
              [-74.93794379699995, 10.301962293000088],
              [-74.93794453399994, 10.301965066000037],
              [-74.93794526199991, 10.30196783200006 ],
              [-74.93794599899991, 10.301970595000057],
              [-74.9379467309999 , 10.301973369000052],
              [-74.9379474669999 , 10.30197613200005 ],
              [-74.93794820699992, 10.301978900000051],
              [-74.93794894099995, 10.301981669000043],
              [-74.93794968199995, 10.30198443200004 ],
              [-74.9379504179999 , 10.301987198000063],
              [-74.93795115599994, 10.30198996200005 ],
              [-74.9379518959999 , 10.301992726000037],
              [-74.93795262999993, 10.301995498000053],
              [-74.9379533739999 , 10.30199826200004 ],
              [-74.93795411899993, 10.302001028000063],
              [-74.9379548629999 , 10.302003791000061],
              [-74.9379556099999 , 10.302006555000048],
              [-74.9379563569999 , 10.302009321000071],
              [-74.93795710499995, 10.302012085000058],
              [-74.93795784999992, 10.302014848000056],
              [-74.93795859599993, 10.30201761300009 ],
              [-74.93795934099995, 10.30202037500004 ],
              [-74.93796010199992, 10.302023140000074],
              [-74.93796084699994, 10.302025896000089],
              [-74.93796160599993, 10.302028662000055],
              [-74.93796235199994, 10.302031425000052],
              [-74.9379631079999 , 10.30203418900004 ],
              [-74.93796386399993, 10.302036952000037],
              [-74.93796461699992, 10.302039709000042],
              [-74.9379653769999 , 10.30204247000006 ],
              [-74.93796613299992, 10.302045238000062],
              [-74.93796688799995, 10.302047990000062],
              [-74.9379676399999 , 10.30205075300006 ],
              [-74.93796840999994, 10.302053519000083],
              [-74.93796916499991, 10.302056274000051],
              [-74.93796993099994, 10.302059037000049],
              [-74.9379706929999 , 10.302061795000043],
              [-74.9379714609999 , 10.30206455800004 ],
              [-74.9379722189999 , 10.302067314000055],
              [-74.93797299199991, 10.302070077000053],
              [-74.9379737559999 , 10.302072830000043],
              [-74.93797452199993, 10.302075586000058],
              [-74.93797528899995, 10.30207835300007 ],
              [-74.93797606099992, 10.30208110600006 ],
              [-74.93797682799993, 10.302083860000039],
              [-74.93797760099994, 10.302086626000062],
              [-74.93797836499994, 10.30208938000004 ],
              [-74.93797914099991, 10.302092136000056],
              [-74.93797991299994, 10.302094890000092],
              [-74.93798068699994, 10.30209764600005 ],
              [-74.9379814589999 , 10.302100409000047],
              [-74.93798223899995, 10.302103165000062],
              [-74.93798301899994, 10.302105918000052],
              [-74.9379837919999 , 10.302108676000046],
              [-74.93798456699994, 10.302111428000046],
              [-74.93798535199994, 10.30211418500005 ],
              [-74.93798613699994, 10.302116939000086],
              [-74.93798691099994, 10.30211969600009 ],
              [-74.9379876939999 , 10.302122452000049],
              [-74.93798847699992, 10.302125204000049],
              [-74.93798926099993, 10.302127951000045],
              [-74.93799005499994, 10.30213070600007 ],
              [-74.93799083699992, 10.302133458000071],
              [-74.9379916179999 , 10.302136218000044],
              [-74.93799241399995, 10.302138974000059],
              [-74.93799319599992, 10.302141715000062],
              [-74.93799398899995, 10.302144474000045],
              [-74.93799478199992, 10.302147225000056],
              [-74.93799556199991, 10.302149970000073],
              [-74.93799635699992, 10.302152729000056],
              [-74.93799714999994, 10.302155481000057],
              [-74.93799794299991, 10.302158228000053],
              [-74.93799874599995, 10.302160985000057],
              [-74.93799953599995, 10.302163729000085],
              [-74.93800032999991, 10.302166484000054],
              [-74.93800113299994, 10.30216923100005 ],
              [-74.9380019319999 , 10.30217198400004 ],
              [-74.93800272399994, 10.302174730000047],
              [-74.93800352599993, 10.302177482000047],
              [-74.9380043299999 , 10.302180234000048],
              [-74.93800512999991, 10.30218297600004 ],
              [-74.93800593199995, 10.302185723000036],
              [-74.93800673499993, 10.302188479000051],
              [-74.93800754499995, 10.302191225000058],
              [-74.93800834599995, 10.302193972000055],
              [-74.93800915599991, 10.302196714000047],
              [-74.93800995999993, 10.302199461000043],
              [-74.93801077099994, 10.302202218000048],
              [-74.93801157699994, 10.302204964000055],
              [-74.93801239099992, 10.302207710000062],
              [-74.93801320199992, 10.302210457000058],
              [-74.93801401299993, 10.30221319900005 ],
              [-74.93801481899993, 10.302215945000057],
              [-74.9380156329999 , 10.302218693000043],
              [-74.9380164449999 , 10.30222143900005 ],
              [-74.93801725999992, 10.30222417400006 ],
              [-74.93801807299991, 10.302226920000066],
              [-74.9380188959999 , 10.302229669000042],
              [-74.93801971499994, 10.30223241300007 ],
              [-74.9380205359999 , 10.302235161000056],
              [-74.93802135499993, 10.302237903000048],
              [-74.93802217599995, 10.302240640000036],
              [-74.93802299499993, 10.30224338700009 ],
              [-74.93802381599994, 10.30224613300004 ],
              [-74.93802463499992, 10.302248871000074],
              [-74.93802546499995, 10.302251616000092],
              [-74.93802628299994, 10.30225435500006 ],
              [-74.93802711299992, 10.302257098000041],
              [-74.9380279419999 , 10.302259844000048],
              [-74.93802877199994, 10.302262580000047],
              [-74.93802960099993, 10.302265326000054],
              [-74.93803042699994, 10.302268068000046],
              [-74.93803125499994, 10.302270803000056],
              [-74.9380320869999 , 10.302273546000038],
              [-74.93803291599994, 10.302276282000037],
              [-74.93803374999993, 10.30227902100006 ],
              [-74.93803458499991, 10.302281769000047],
              [-74.93803541799991, 10.302284504000056],
              [-74.93803624999993, 10.302287241000045],
              [-74.93803708699994, 10.302289976000054],
              [-74.93803792699993, 10.302292722000061],
              [-74.93803876499993, 10.30229545900005 ],
              [-74.93803960399993, 10.302298197000084],
              [-74.93804044099994, 10.302300931000048],
              [-74.93804128799991, 10.302303670000072],
              [-74.93804212599991, 10.302306409000039],
              [-74.93804297299994, 10.302309144000048],
              [-74.93804381399991, 10.302311878000069],
              [-74.93804465999995, 10.302314620000061],
              [-74.93804550499993, 10.302317353000092],
              [-74.93804634399993, 10.302320087000055],
              [-74.9380471899999 , 10.302322828000058],
              [-74.9380480399999 , 10.302325563000068],
              [-74.93804889599994, 10.302328291000038],
              [-74.9380497439999 , 10.302331028000083],
              [-74.93805059199991, 10.30233376700005 ],
              [-74.93805144599992, 10.302336503000049],
              [-74.93805229299994, 10.30233922900004 ],
              [-74.9380531509999 , 10.30234196400005 ],
              [-74.93805400799994, 10.302344702000084],
              [-74.93805485099995, 10.302347430000054],
              [-74.93805571199994, 10.302350165000064],
              [-74.93805656899991, 10.302352897000048],
              [-74.93805742399991, 10.302355631000069],
              [-74.93805828899991, 10.302358358000049],
              [-74.9380591449999 , 10.302361096000084],
              [-74.93806000099994, 10.302363823000064],
              [-74.93806086699993, 10.302366550000045],
              [-74.93806172299992, 10.302369291000048],
              [-74.9380625899999 , 10.302372017000039],
              [-74.93806354199995, 10.30237502700004 ],
              [-74.93806432499991, 10.302377481000065],
              [-74.9380651969999 , 10.302380204000087],
              [-74.93806606599992, 10.302382933000047],
              [-74.93806694099993, 10.302385658000048],
              [-74.93806781399991, 10.30238838200006 ],
              [-74.93806869199994, 10.30239111800006 ],
              [-74.93806956199995, 10.30239384400005 ],
              [-74.93807043299995, 10.302396570000042],
              [-74.93807131199992, 10.302399301000037],
              [-74.93807218199993, 10.30240202400006 ],
              [-74.93807305999991, 10.30240475000005 ],
              [-74.93807392999992, 10.302407481000046],
              [-74.93807480999993, 10.302410205000058],
              [-74.93807567999994, 10.302412925000056],
              [-74.9380765599999 , 10.302415655000061],
              [-74.93807743499991, 10.302418381000052],
              [-74.9380783119999 , 10.302421111000058],
              [-74.9380791879999 , 10.302423839000085],
              [-74.93808006099994, 10.302426561000061],
              [-74.93808093899992, 10.302429287000052],
              [-74.93808181499992, 10.302432020000083],
              [-74.93808268999993, 10.302434738000045],
              [-74.93808356999995, 10.30243746800005 ],
              [-74.93808444699994, 10.302440188000048],
              [-74.93808533099991, 10.302442919000043],
              [-74.93808622299991, 10.302445634000037],
              [-74.93808710699994, 10.302448361000074],
              [-74.938088        , 10.30245108300005 ],
              [-74.93808888299992, 10.302453809000042],
              [-74.93808977599991, 10.302456525000082],
              [-74.93809066599994, 10.302459255000088],
              [-74.93809155299994, 10.30246197300005 ],
              [-74.93809244199991, 10.302464693000047],
              [-74.9380933459999 , 10.302467412000055],
              [-74.93809424099993, 10.302470138000047],
              [-74.93809513299993, 10.302472857000055],
              [-74.93809602199991, 10.302475576000063],
              [-74.93809692799994, 10.302478295000071],
              [-74.93809782099993, 10.302481012000044],
              [-74.9380987209999 , 10.302483731000052],
              [-74.93809962599994, 10.302486449000071],
              [-74.93810052399994, 10.302489171000047],
              [-74.93810142899991, 10.302491887000087],
              [-74.93810232599992, 10.30249460400006 ],
              [-74.93810323299994, 10.302497325000047],
              [-74.93810413599994, 10.302500045000045],
              [-74.93810503799995, 10.302502760000039],
              [-74.93810594799993, 10.302505477000068],
              [-74.93810685099993, 10.302508199000044],
              [-74.9381077619999 , 10.302510907000055],
              [-74.93810867399992, 10.302513627000053],
              [-74.93810957499994, 10.30251634700005 ],
              [-74.9381104869999 , 10.30251905700004 ],
              [-74.93811139699994, 10.302521770000055],
              [-74.93811230799992, 10.302524490000053],
              [-74.93811322899995, 10.302527200000043],
              [-74.93811413899994, 10.302529919000051],
              [-74.93811504699994, 10.302532631000076],
              [-74.9381159699999 , 10.30253534700006 ],
              [-74.93811687699991, 10.30253805700005 ],
              [-74.93811779999993, 10.302540776000058],
              [-74.93811872299995, 10.302543484000068],
              [-74.93811964299994, 10.302546195000048],
              [-74.93812056299993, 10.302548914000056],
              [-74.93812148399991, 10.302551623000056],
              [-74.9381224039999 , 10.302554333000046],
              [-74.93812331999993, 10.302557041000057],
              [-74.9381242419999 , 10.30255975700004 ],
              [-74.93812517299995, 10.302562470000055],
              [-74.93812609399993, 10.302565179000055],
              [-74.93812702199995, 10.302567888000056],
              [-74.9381279509999 , 10.302570598000045],
              [-74.93812887999991, 10.302573306000056],
              [-74.93812980199993, 10.302576015000056],
              [-74.9381307299999 , 10.302578724000057],
              [-74.9381316699999 , 10.302581434000047],
              [-74.9381325949999 , 10.302584143000047],
              [-74.93813352799992, 10.302586852000047],
              [-74.9381344649999 , 10.302589555000054],
              [-74.9381353949999 , 10.302592264000054],
              [-74.9381363359999 , 10.302594973000055],
              [-74.9381372599999 , 10.302597683000045],
              [-74.93813738799992, 10.30259804900004 ],
              [-74.93813820199995, 10.302600381000047],
              [-74.93813914099991, 10.302603090000048],
              [-74.93814008099991, 10.302605801000084],
              [-74.93814101699991, 10.302608502000055],
              [-74.93814195599992, 10.302611212000045],
              [-74.93814289499994, 10.302613915000052],
              [-74.93814384299992, 10.302616620000038],
              [-74.93814478099995, 10.302619322000055],
              [-74.93814572899993, 10.302622032000045],
              [-74.93814666599991, 10.302624730000048],
              [-74.93814761399994, 10.302627439000048],
              [-74.93814856199992, 10.302630141000066],
              [-74.93814950599995, 10.302632839000069],
              [-74.93815045499991, 10.302635550000048],
              [-74.93815140399994, 10.30263824900004 ],
              [-74.93815235299991, 10.302640952000047],
              [-74.9381533099999 , 10.30264365000005 ],
              [-74.93815425699995, 10.302646353000057],
              [-74.9381552129999 , 10.30264905100006 ],
              [-74.93815616099994, 10.30265176000006 ],
              [-74.9381571159999 , 10.302654460000042],
              [-74.93815807399994, 10.30265716200006 ],
              [-74.93815903099994, 10.302659863000088],
              [-74.93815998799994, 10.302662564000059],
              [-74.93816094499994, 10.302665252000054],
              [-74.93816189899991, 10.30266795500006 ],
              [-74.93816285899993, 10.302670653000064],
              [-74.9381638129999 , 10.302673354000092],
              [-74.9381647809999 , 10.302676054000074],
              [-74.9381657369999 , 10.302678755000045],
              [-74.93816670099994, 10.302681446000065],
              [-74.93816766899994, 10.302684147000093],
              [-74.93816863599994, 10.30268684500004 ],
              [-74.93816960099991, 10.30268953600006 ],
              [-74.93817056699993, 10.302692239000066],
              [-74.93817152699995, 10.30269492900004 ],
              [-74.9381724989999 , 10.302697631000058],
              [-74.93817346299994, 10.302700320000042],
              [-74.93817443899991, 10.30270302200006 ],
              [-74.93817540399994, 10.302705711000044],
              [-74.9381763799999 , 10.302708404000043],
              [-74.93817735599993, 10.302711102000046],
              [-74.9381783199999 , 10.302713795000045],
              [-74.93817929599993, 10.302716488000044],
              [-74.93818027099991, 10.302719187000037],
              [-74.93818124599994, 10.302721879000046],
              [-74.93818222699991, 10.302724571000056],
              [-74.93818320399993, 10.30272725900005 ],
              [-74.93818418099994, 10.30272995200005 ],
              [-74.93818516399995, 10.30273264300007 ],
              [-74.93818613899992, 10.30273533400009 ],
              [-74.93818712199993, 10.302738025000053],
              [-74.93818810699992, 10.302740718000052],
              [-74.93819284299991, 10.302750812000056],
              [-74.93858792899994, 10.303592820000063],
              [-74.93865906099995, 10.303744417000075],
              [-74.93868133399991, 10.303791886000056],
              [-74.93927914799991, 10.304283698000063],
              [-74.93930205399994, 10.304302546000088],
              [-74.93960541899992, 10.304552116000082],
              [-74.93965541299991, 10.304593244000046],
              [-74.93996923199995, 10.304838311000083],
              [-74.94022466299992, 10.305012766000061],
              [-74.94042695699994, 10.305185319000088],
              [-74.94055241699994, 10.305269131000045],
              [-74.94072618399991, 10.305338755000037],
              [-74.94079715299995, 10.30536733100007 ],
              [-74.94090460399991, 10.305410596000058],
              [-74.94099249299995, 10.305421267000042],
              [-74.9411104429999 , 10.305445484000074],
              [-74.94118913799991, 10.305458590000057],
              [-74.94130027499995, 10.305494303000046],
              [-74.94133765099991, 10.305549047000056],
              [-74.94134338799995, 10.30569126100005 ],
              [-74.94120832599992, 10.305850776000057],
              [-74.94103353299994, 10.305953224000064],
              [-74.94077600499992, 10.306106924000062],
              [-74.94059907499991, 10.306239292000043],
              [-74.94047555299994, 10.306394072000046],
              [-74.94034264999993, 10.306535214000064],
              [-74.9401841639999 , 10.30665590600006 ],
              [-74.9398051679999 , 10.306934545000047],
              [-74.93959171199992, 10.307113125000058],
              [-74.93926352299991, 10.307400483000038],
              [-74.93896824599994, 10.307754139000053],
              [-74.93884325999994, 10.307891927000071],
              [-74.9387689429999 , 10.307973852000089],
              [-74.93858372199992, 10.308215546000042],
              [-74.93823700599995, 10.308730384000057],
              [-74.93806549399994, 10.30902167000005 ],
              [-74.93803816999991, 10.309068105000051],
              [-74.9380252709999 , 10.309090036000043],
              [-74.93784430099993, 10.309397618000048],
              [-74.93761371599993, 10.30975526800006 ],
              [-74.93750954799992, 10.309970289000091],
              [-74.9373681379999 , 10.310262185000056],
              [-74.9371977049999 , 10.310635386000058],
              [-74.93693783299994, 10.310938513000053],
              [-74.9369310269999 , 10.310943781000049],
              [-74.93682568199995, 10.311025326000049],
              [-74.93665915699995, 10.311154230000056],
              [-74.93667514499992, 10.31117458500006 ],
              [-74.93663383499995, 10.311207761000048],
              [-74.93659882799994, 10.311235872000054],
              [-74.93648914499994, 10.311323952000066],
              [-74.93446961199993, 10.312945667000065],
              [-74.9344590899999 , 10.312953037000057],
              [-74.93435185599992, 10.313030126000058],
              [-74.93436615099995, 10.31308153200007 ],
              [-74.93437418499991, 10.313110419000054],
              [-74.93440871799993, 10.31323457600007 ],
              [-74.93440973699995, 10.313242323000054],
              [-74.93444807199995, 10.313533748000054],
              [-74.93454655199992, 10.313861643000052],
              [-74.93463643399991, 10.314117647000046],
              [-74.93466674099994, 10.314203965000047],
              [-74.93477079699994, 10.314568695000048],
              [-74.9348283349999 , 10.31474683600004 ],
              [-74.93490123799995, 10.315118563000055],
              [-74.9349642709999 , 10.315484079000043],
              [-74.93501490299991, 10.315716731000066],
              [-74.93508555799991, 10.31607703800006 ],
              [-74.9350686489999 , 10.316089329000079],
              [-74.93499749999995, 10.316141041000037],
              [-74.9341726259999 , 10.31674058100009 ],
              [-74.93415647199993, 10.31675232300006 ],
              [-74.93413531699991, 10.316767698000092],
              [-74.93323420899992, 10.317658961000063],
              [-74.93310365699995, 10.317788136000047],
              [-74.9324617339999 , 10.318423259000042],
              [-74.93174616399995, 10.319131249000066],
              [-74.93112491399995, 10.319763073000047],
              [-74.93002186399991, 10.320830468000054],
              [-74.9296530009999 , 10.321227752000084],
              [-74.9292680129999 , 10.321642404000045],
              [-74.92780494899995, 10.322550531000047],
              [-74.92777051799993, 10.32257190300004 ],
              [-74.92771499299994, 10.322606366000059],
              [-74.92726677699994, 10.322884574000057],
              [-74.9264439879999 , 10.323395271000038],
              [-74.92635480099995, 10.323450627000057],
              [-74.92520191299991, 10.324057226000093],
              [-74.92383296799994, 10.32455251600004 ],
              [-74.92382455499995, 10.324556391000044],
              [-74.92343516499994, 10.324742633000085],
              [-74.92338200699993, 10.324759849000088],
              [-74.92327893099991, 10.324793231000058],
              [-74.9224304299999 , 10.325068027000043],
              [-74.92209122999992, 10.325135162000038],
              [-74.92148458799994, 10.325255230000039],
              [-74.92042519499995, 10.325388390000057],
              [-74.91970933199991, 10.325433598000075],
              [-74.91970525099993, 10.325433461000046],
              [-74.91943979699994, 10.329013943000064],
              [-74.91943015299995, 10.329001910000045],
              [-74.91941787399992, 10.329024675000085],
              [-74.9192698789999 , 10.32929899900006 ],
              [-74.91922805299993, 10.329354884000054],
              [-74.91919829299991, 10.329394644000047],
              [-74.91906332099995, 10.32957497600006 ],
              [-74.91898636299993, 10.329677807000053],
              [-74.91898107199995, 10.329684875000055],
              [-74.91896564299992, 10.329705491000084],
              [-74.91952499699994, 10.330336123000052],
              [-74.9193035529999 , 10.330686825000043],
              [-74.91903350099994, 10.331019564000087],
              [-74.91886451199991, 10.331275769000058],
              [-74.91861155799995, 10.331739158000062],
              [-74.9184456129999 , 10.332091612000056],
              [-74.91815421399991, 10.332658601000048],
              [-74.91778732599994, 10.333322572000043],
              [-74.91750001399993, 10.333818388000054],
              [-74.91731971099995, 10.334111351000047],
              [-74.91718540099993, 10.334374101000037],
              [-74.91705196599992, 10.33475383700005 ],
              [-74.91685843099992, 10.335430064000093],
              [-74.91721889599995, 10.335431524000057],
              [-74.91753273099994, 10.33538291700006 ],
              [-74.91788337899993, 10.335315617000049],
              [-74.9182042299999 , 10.335276104000059],
              [-74.91857786999992, 10.335190275000059],
              [-74.9188188469999 , 10.335169631000042],
              [-74.9189057399999 , 10.335162186000048],
              [-74.91814650099991, 10.336793531000069],
              [-74.91812344399995, 10.336842619000038],
              [-74.91811738699994, 10.336855509000088],
              [-74.91774491599995, 10.337670726000056],
              [-74.91743714799992, 10.338344331000087],
              [-74.91732640999993, 10.338550403000056],
              [-74.91732324299994, 10.338566135000065],
              [-74.91727058099991, 10.33882727300005 ],
              [-74.91724957399992, 10.339124637000054],
              [-74.91724257399994, 10.339175562000037],
              [-74.91721131199995, 10.339402980000045],
              [-74.91708209299992, 10.339664990000074],
              [-74.91703058599995, 10.339882547000059],
              [-74.91701868299992, 10.339932824000073],
              [-74.91689597399994, 10.340130241000054],
              [-74.91673462999995, 10.340209949000041],
              [-74.91655441499995, 10.340311263000046],
              [-74.91634019799994, 10.340387749000058],
              [-74.91600930099992, 10.340508012000043],
              [-74.91590119499995, 10.340566270000068],
              [-74.91569564699995, 10.340677037000091],
              [-74.91557033499993, 10.34070710800006 ],
              [-74.91542150799995, 10.340748327000085],
              [-74.9154131869999 , 10.34074718700009 ],
              [-74.91542047799993, 10.340800655000066],
              [-74.91542959499992, 10.340867506000052],
              [-74.91543398899995, 10.340971105000051],
              [-74.9154411689999 , 10.341140322000058],
              [-74.91546412199995, 10.341403932000048],
              [-74.91550010199995, 10.34153249700006 ],
              [-74.91553627199994, 10.341661762000058],
              [-74.91559029099994, 10.341854776000048],
              [-74.9157407969999 , 10.342181580000045],
              [-74.9158890029999 , 10.342499240000052],
              [-74.9160624129999 , 10.342800665000084],
              [-74.91614341099995, 10.342911970000046],
              [-74.9163544889999 , 10.34320202300006 ],
              [-74.91669962299994, 10.343598412000063],
              [-74.91701330399991, 10.343811534000054],
              [-74.91735439799993, 10.344006123000042],
              [-74.91768863199991, 10.34419618000004 ],
              [-74.91693459899994, 10.345287210000038],
              [-74.91632980499992, 10.34616230100005 ],
              [-74.91575384099991, 10.346995667000044],
              [-74.9155888219999 , 10.34723443100006 ],
              [-74.91551910099992, 10.347335313000087],
              [-74.91532825599995, 10.347611444000052],
              [-74.91532483299994, 10.34761639800007 ],
              [-74.91510022199992, 10.347941389000084],
              [-74.91492962799992, 10.347848917000078],
              [-74.91479157599991, 10.347774085000083],
              [-74.91446854799995, 10.347538092000093],
              [-74.9144410799999 , 10.347515384000076],
              [-74.91435211599992, 10.347441839000055],
              [-74.91404530699992, 10.34718820200004 ],
              [-74.91381003399994, 10.34696296900006 ],
              [-74.91336865599993, 10.347445793000077],
              [-74.9126561569999 , 10.348225195000055],
              [-74.91232136699995, 10.348591419000059],
              [-74.91130984499995, 10.347969688000092],
              [-74.9112892629999 , 10.347957038000061],
              [-74.91119444999993, 10.348035847000062],
              [-74.91112531499994, 10.348072         ],
              [-74.91070841599992, 10.348289996000062],
              [-74.91066810899991, 10.348311072000058],
              [-74.9103507069999 , 10.348469523000063],
              [-74.9098282189999 , 10.348700659000087],
              [-74.90951948899993, 10.34879711800005 ],
              [-74.90897085199992, 10.34893216100005 ],
              [-74.90848826399991, 10.34895888200009 ],
              [-74.90817851799994, 10.348940686000049],
              [-74.9079013189999 , 10.348929120000037],
              [-74.90757536799993, 10.34890411400005 ],
              [-74.90424070699993, 10.34902426900004 ],
              [-74.9045742799999 , 10.349695996000037],
              [-74.9043105209999 , 10.351104623000083],
              [-74.90423930399993, 10.351484954000057],
              [-74.9041301339999 , 10.35206799000008 ],
              [-74.90392825899994, 10.353146112000047],
              [-74.90334418299994, 10.35291212900006 ],
              [-74.90270055899992, 10.352733673000046],
              [-74.90206449499993, 10.352635422000048],
              [-74.90128798799992, 10.352591056000051],
              [-74.90048178099994, 10.352588154000046],
              [-74.89985358099995, 10.352606827000045],
              [-74.89943941899992, 10.35268386100006 ],
              [-74.8992365499999 , 10.352721594000059],
              [-74.89921772499991, 10.35272509400005 ],
              [-74.8987647379999 , 10.352845948000038],
              [-74.89834971599993, 10.352956672000062],
              [-74.89822007499993, 10.352967637000063],
              [-74.89800452899993, 10.352970631000062],
              [-74.8978214469999 , 10.352973218000045],
              [-74.89768548599994, 10.353025398000057],
              [-74.89759500299994, 10.353172954000058],
              [-74.89762093099995, 10.353350902000045],
              [-74.8977210569999 , 10.35353464800005 ],
              [-74.89780886799991, 10.353670586000078],
              [-74.8978342019999 , 10.353814236000062],
              [-74.89778955999992, 10.353926887000057],
              [-74.89764930899992, 10.35399970900005 ],
              [-74.8974963149999 , 10.354001827000047],
              [-74.89724315399991, 10.353964202000043],
              [-74.89715008399992, 10.35394242700005 ],
              [-74.89700267199993, 10.353907596000056],
              [-74.8969309169999 , 10.353877035000039],
              [-74.89670257599994, 10.353779778000046],
              [-74.89637669299992, 10.35353742600006 ],
              [-74.89612472199991, 10.353273548000061],
              [-74.8959972529999 , 10.352971299000046],
              [-74.89591413499994, 10.352686722000044]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08832",
      "properties": {"id": "08832", "iso": "TUB", "name": "TUBARÁ"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.96194681299994, 10.980714077000073],
              [-74.96148405499991, 10.980580101000044],
              [-74.96060560099994, 10.980628196000055],
              [-74.96028245999992, 10.980645889000073],
              [-74.95915905999993, 10.98070739700006 ],
              [-74.95906526099992, 10.980712532000041],
              [-74.95865246299991, 10.980735132000063],
              [-74.9582007649999 , 10.98075986300006 ],
              [-74.95693723999995, 10.979146169000046],
              [-74.95663719499993, 10.978762970000048],
              [-74.95658529799994, 10.978600050000068],
              [-74.95649572799994, 10.978483697000058],
              [-74.95638733099992, 10.978409251000073],
              [-74.95615644499992, 10.978386065000052],
              [-74.95606800599995, 10.978394048000041],
              [-74.95600096499993, 10.978400099000055],
              [-74.95593499499995, 10.978376841000056],
              [-74.95590197999991, 10.978297715000053],
              [-74.95584600399991, 10.97821790200004 ],
              [-74.95581711599993, 10.978176706000056],
              [-74.95569928399993, 10.978083638000044],
              [-74.95561914599995, 10.97799056000008 ],
              [-74.95557669099992, 10.97786022300005 ],
              [-74.95552971199993, 10.977560220000044],
              [-74.95553373799993, 10.977291880000053],
              [-74.95558899299994, 10.977008349000073],
              [-74.95561009799991, 10.976956156000085],
              [-74.95567029499995, 10.976807276000045],
              [-74.95573985899995, 10.976635250000072],
              [-74.95612719299993, 10.975963621000062],
              [-74.95636353799995, 10.975371634000055],
              [-74.95631634299991, 10.975323784000068],
              [-74.9561887879999 , 10.975194457000043],
              [-74.95598761299993, 10.974990494000053],
              [-74.9558704239999 , 10.974899089000075],
              [-74.9557086989999 , 10.97477294500004 ],
              [-74.9556915519999 , 10.974759566000046],
              [-74.95519293699994, 10.97449344100005 ],
              [-74.9546499619999 , 10.974203638000063],
              [-74.9545089049999 , 10.974128352000037],
              [-74.95447294199994, 10.974112994000052],
              [-74.9540861559999 , 10.973947812000063],
              [-74.95357804399993, 10.973730809000074],
              [-74.95300675199991, 10.973351579000052],
              [-74.95285962499992, 10.973241055000074],
              [-74.95271768299995, 10.973134433000041],
              [-74.95235954099991, 10.972985596000058],
              [-74.95219813099993, 10.97287237200004 ],
              [-74.9521207489999 , 10.972818092000068],
              [-74.9519447589999 , 10.972594695000055],
              [-74.95168076599992, 10.972234769000067],
              [-74.95139164599993, 10.971862434000059],
              [-74.95078205099992, 10.97129779700009 ],
              [-74.95044123799994, 10.971057961000042],
              [-74.95025920099994, 10.970929861000059],
              [-74.95009611799992, 10.970922012000074],
              [-74.94989813799992, 10.970978892000062],
              [-74.94981062199992, 10.970981907000066],
              [-74.94971291599995, 10.970985272000064],
              [-74.94951489999994, 10.970947481000053],
              [-74.94932326199995, 10.970884440000077],
              [-74.9491635999999 , 10.970916058000057],
              [-74.94913143899993, 10.970925486000056],
              [-74.94899116699992, 10.970966609000072],
              [-74.94879955899995, 10.970972995000068],
              [-74.9487914849999 , 10.970972678000066],
              [-74.94867570199995, 10.97096814400004 ],
              [-74.94863987399992, 10.97096674100004 ],
              [-74.94844187299992, 10.970966813000075],
              [-74.94825037899994, 10.970955489000062],
              [-74.94800860099991, 10.971015659000045],
              [-74.94799634499992, 10.971018707000042],
              [-74.94757876299991, 10.971122629000092],
              [-74.94757784199993, 10.971122858000058],
              [-74.94727113899995, 10.971363936000046],
              [-74.9471475709999 , 10.971471851000047],
              [-74.94666681599995, 10.97242533900004 ],
              [-74.9466435839999 , 10.972551855000063],
              [-74.94664131899992, 10.97256419100006 ],
              [-74.9466390899999 , 10.97258306000009 ],
              [-74.9466137039999 , 10.972797856000057],
              [-74.9466389079999 , 10.973169325000072],
              [-74.94666501099994, 10.973614270000041],
              [-74.94666721099992, 10.973651793000045],
              [-74.94667718399995, 10.97382177600008 ],
              [-74.94669107099992, 10.974058513000045],
              [-74.94670667499992, 10.97442221600005 ],
              [-74.94671483899992, 10.974612511000089],
              [-74.9468045509999 , 10.97512458600005 ],
              [-74.94688537899992, 10.975569165000081],
              [-74.94688710499992, 10.975578658000074],
              [-74.94689426199994, 10.97561803700006 ],
              [-74.94694776599994, 10.975809272000049],
              [-74.94545526299993, 10.975330105000069],
              [-74.94545332399991, 10.975336842000047],
              [-74.94517884999993, 10.975259837000067],
              [-74.9439638849999 , 10.974991294000063],
              [-74.9434153059999 , 10.974814847000061],
              [-74.9426878189999 , 10.974436943000057],
              [-74.9423235399999 , 10.974118052000051],
              [-74.9422306479999 , 10.97403673100007 ],
              [-74.94208417599992, 10.973892112000044],
              [-74.94205153799993, 10.97385988600007 ],
              [-74.94201894999992, 10.973838436000051],
              [-74.9418960189999 , 10.97375751900006 ],
              [-74.94174523999993, 10.973748260000093],
              [-74.94149554099994, 10.973790249000047],
              [-74.94124119399993, 10.974018458000046],
              [-74.94095638899995, 10.974288806000061],
              [-74.94088793799995, 10.974353780000058],
              [-74.94059118199993, 10.974563383000088],
              [-74.94016915899994, 10.97471476600009 ],
              [-74.94016247299993, 10.974717165000072],
              [-74.93976202199991, 10.974824382000065],
              [-74.93938038699991, 10.974857102000044],
              [-74.93889976999992, 10.974773465000055],
              [-74.93851100299992, 10.974617897000087],
              [-74.93814238399995, 10.97442766000006 ],
              [-74.93779891699995, 10.97431190900005 ],
              [-74.93745549499994, 10.97432857800004 ],
              [-74.93693625299994, 10.974585328000046],
              [-74.93591454099993, 10.975156750000053],
              [-74.93509386599993, 10.975752928000077],
              [-74.93395493499992, 10.976489911000044],
              [-74.93313418299994, 10.976870895000047],
              [-74.93286800999994, 10.977185473000077],
              [-74.93234592999994, 10.977802499000063],
              [-74.93224826799991, 10.977749173000063],
              [-74.93126774699994, 10.977213791000054],
              [-74.9313342339999 , 10.977099271000043],
              [-74.93152076799993, 10.976777970000057],
              [-74.93163497899991, 10.97640675200006 ],
              [-74.93164306799991, 10.97616467000006 ],
              [-74.93161847599993, 10.975890328000048],
              [-74.93154492799994, 10.975737036000055],
              [-74.93138666199991, 10.975531148000073],
              [-74.93135493199992, 10.975506823000046],
              [-74.9312437559999 , 10.975421589000064],
              [-74.93111372899995, 10.975321904000054],
              [-74.9301475449999 , 10.97480414000006 ],
              [-74.9296013419999 , 10.974594510000088],
              [-74.92941145799995, 10.974546155000041],
              [-74.92921546999992, 10.974570422000056],
              [-74.92892152099995, 10.974715761000084],
              [-74.92875329799995, 10.97478581100006 ],
              [-74.92845609099993, 10.974909568000044],
              [-74.92809474299992, 10.974964145000058],
              [-74.92771500099991, 10.97497031100005 ],
              [-74.92767319699993, 10.974968963000038],
              [-74.92739022399991, 10.974959845000058],
              [-74.9262266369999 , 10.974922349000053],
              [-74.92573127299994, 10.974872571000049],
              [-74.9255704759999 , 10.974809073000074],
              [-74.92545923099993, 10.974677254000085],
              [-74.92545559299992, 10.97467294200004 ],
              [-74.92540804999993, 10.974626225000065],
              [-74.92530165999995, 10.97452168600006 ],
              [-74.92524368799991, 10.97450555000006 ],
              [-74.92523475099995, 10.974503061000064],
              [-74.9252200169999 , 10.974498961000052],
              [-74.92506276599994, 10.974455191000061],
              [-74.92489507899995, 10.974458841000057],
              [-74.92478102599995, 10.97446132600004 ],
              [-74.92465896499994, 10.974468456000068],
              [-74.92446866499995, 10.974479573000053],
              [-74.92428209299993, 10.974468568000077],
              [-74.92416241399991, 10.974461509000037],
              [-74.9236846579999 , 10.974401130000047],
              [-74.92336613999993, 10.974328598000056],
              [-74.9230108509999 , 10.974177403000056],
              [-74.92266167899993, 10.97397174200006 ],
              [-74.92262127399994, 10.973926041000084],
              [-74.92232469599992, 10.973590571000045],
              [-74.92204895499992, 10.973173064000036],
              [-74.92178551599994, 10.972937115000093],
              [-74.92147923099992, 10.972785907000059],
              [-74.92081155199992, 10.97254402100009 ],
              [-74.9195864969999 , 10.972235717000046],
              [-74.91949119899994, 10.972217519000083],
              [-74.9200944289999 , 10.971533390000047],
              [-74.9205132269999 , 10.97097870400006 ],
              [-74.92095571899995, 10.970353714000055],
              [-74.92110693699993, 10.970020465000061],
              [-74.92123221299994, 10.969744389000084],
              [-74.92143461499995, 10.969201478000059],
              [-74.92144721699992, 10.969182684000089],
              [-74.92178425499992, 10.968680001000052],
              [-74.92205612799995, 10.968377450000048],
              [-74.9222391049999 , 10.96823739000007 ],
              [-74.92263957799992, 10.968091474000062],
              [-74.92309271399995, 10.96785181100006 ],
              [-74.92347204999993, 10.967528851000054],
              [-74.92388295899991, 10.967070493000051],
              [-74.92391651799994, 10.967038420000051],
              [-74.92419906299995, 10.966768382000055],
              [-74.92455738199993, 10.966664129000037],
              [-74.92489463999993, 10.966622374000053],
              [-74.92532678099991, 10.966674319000049],
              [-74.92580106699995, 10.966653346000044],
              [-74.92614887099995, 10.96663241400006 ],
              [-74.92667591599991, 10.966819714000053],
              [-74.92717742299993, 10.96703343900009 ],
              [-74.92717995099991, 10.96703451600007 ],
              [-74.9273645639999 , 10.967113192000056],
              [-74.9279785569999 , 10.967374850000056],
              [-74.9281411369999 , 10.967444134000061],
              [-74.92848876999994, 10.966892063000046],
              [-74.9287520609999 , 10.966246286000057],
              [-74.92877300799995, 10.96584011300007 ],
              [-74.92868854999995, 10.965392318000056],
              [-74.92856195899992, 10.965027853000038],
              [-74.92839325499995, 10.964809199000058],
              [-74.92811915999994, 10.964600995000069],
              [-74.92770098299991, 10.964271333000056],
              [-74.92803946299995, 10.963940568000055],
              [-74.92886919499995, 10.96312017200006 ],
              [-74.92922479499993, 10.962760768000066],
              [-74.92926010799994, 10.962692511000057],
              [-74.92927489999994, 10.962663919000079],
              [-74.92939859099994, 10.962424844000054],
              [-74.92943800099994, 10.962065533000043],
              [-74.92941155499994, 10.961976790000051],
              [-74.92935886799995, 10.961799989000042],
              [-74.92909785599994, 10.961300177000055],
              [-74.9288289449999 , 10.96081599100006 ],
              [-74.9284176029999 , 10.959863192000057],
              [-74.92831669899994, 10.95879227000006 ],
              [-74.9284711009999 , 10.957392076000076],
              [-74.92847652699993, 10.957342874000062],
              [-74.92874493699992, 10.956249272000036],
              [-74.92896603199995, 10.955522794000046],
              [-74.9291565229999 , 10.955031087000066],
              [-74.92928985099991, 10.954686934000051],
              [-74.92974749699994, 10.95403063000009 ],
              [-74.92979648799991, 10.953960370000061],
              [-74.92994461299992, 10.953690849000054],
              [-74.9301638469999 , 10.95334514700005 ],
              [-74.93035942199992, 10.953157626000063],
              [-74.9304761649999 , 10.953078992000087],
              [-74.9305535009999 , 10.95302690200009 ],
              [-74.93076838799993, 10.952882162000037],
              [-74.93112922899991, 10.952492809000091],
              [-74.93093949999991, 10.952416712000058],
              [-74.93081496999991, 10.952299592000088],
              [-74.93066673399994, 10.952211766000062],
              [-74.93048294699992, 10.952182531000062],
              [-74.93026948599993, 10.952042006000056],
              [-74.9300678699999 , 10.951883898000062],
              [-74.92985436999993, 10.951620349000052],
              [-74.9295401089999 , 10.951432990000058],
              [-74.92915471799995, 10.951274935000072],
              [-74.92879898699994, 10.951163742000062],
              [-74.92833654999993, 10.95107015700006 ],
              [-74.92785042499992, 10.951052731000061],
              [-74.92742359699992, 10.951082149000058],
              [-74.92709163599994, 10.951140835000047],
              [-74.92673002499993, 10.951187807000053],
              [-74.92645139299992, 10.951193749000083],
              [-74.92622017899993, 10.951158668000062],
              [-74.9258170139999 , 10.951018198000043],
              [-74.92543756899994, 10.95090114800007 ],
              [-74.92491585899995, 10.950836864000053],
              [-74.92418132599994, 10.95274683200006 ],
              [-74.92411609299995, 10.952658983000049],
              [-74.9239322549999 , 10.952459859000044],
              [-74.92381364199991, 10.952319301000045],
              [-74.92360608799993, 10.952096750000067],
              [-74.92343412499991, 10.951950346000046],
              [-74.9232088089999 , 10.951815674000045],
              [-74.92301314999992, 10.951733718000071],
              [-74.92276414299994, 10.951675206000061],
              [-74.92260406699995, 10.951622532000044],
              [-74.92245581999993, 10.951505413000064],
              [-74.92227790899994, 10.951288709000039],
              [-74.92210596899992, 10.951218463000089],
              [-74.92195775099992, 10.951189216000046],
              [-74.92176804099995, 10.951165840000044],
              [-74.92156052699994, 10.951078023000036],
              [-74.92133520699991, 10.950931633000039],
              [-74.92122847499991, 10.950843791000068],
              [-74.92116915399993, 10.950732507000055],
              [-74.92110982199995, 10.95054506200006 ],
              [-74.92100899899992, 10.950410355000088],
              [-74.9208607409999 , 10.95024051200005 ],
              [-74.92053461699993, 10.94999456000005 ],
              [-74.92042788399993, 10.949906720000058],
              [-74.9204100739999 , 10.949836425000058],
              [-74.9203922559999 , 10.949707548000049],
              [-74.92032104799995, 10.94947324900005 ],
              [-74.92019641999991, 10.94899877100005 ],
              [-74.92004217699991, 10.94861217500005 ],
              [-74.91994133999992, 10.948413027000072],
              [-74.91988792899991, 10.948219724000069],
              [-74.91986416999993, 10.948061565000046],
              [-74.91988190199993, 10.947874093000053],
              [-74.91991741899994, 10.947704199000043],
              [-74.92000035699994, 10.947493287000043],
              [-74.92002400399991, 10.947270668000044],
              [-74.91994092399995, 10.946966067000062],
              [-74.9184574709999 , 10.945339618000048],
              [-74.9183999309999 , 10.945286751000083],
              [-74.91918799399991, 10.945034545000055],
              [-74.91929158399995, 10.944984609000073],
              [-74.91964765199992, 10.944812969000054],
              [-74.92033874999993, 10.944479759000046],
              [-74.92036366299993, 10.944468477000044],
              [-74.92070430299992, 10.944314202000044],
              [-74.92110233299991, 10.944127885000057],
              [-74.92123753499993, 10.944031322000058],
              [-74.92173235999991, 10.943533394000042],
              [-74.92262598099995, 10.942552225000043],
              [-74.92278039399991, 10.942330862000063],
              [-74.92284467699994, 10.942210767000063],
              [-74.92287166999995, 10.942063937000057],
              [-74.92287556799994, 10.942001443000038],
              [-74.92288728499994, 10.941813600000046],
              [-74.92286275299995, 10.941238737000049],
              [-74.9228244279999 , 10.940341831000069],
              [-74.9228170799999 , 10.939864877000048],
              [-74.92280724299991, 10.939225685000054],
              [-74.92279614199992, 10.939053791000049],
              [-74.92275204899994, 10.938370751000036],
              [-74.92275355999993, 10.93811297700006 ],
              [-74.92276916899993, 10.937936591000039],
              [-74.92282375799994, 10.93778726000005 ],
              [-74.92293065199993, 10.937541217000046],
              [-74.9231567249999 , 10.93714155300006 ],
              [-74.92331191499994, 10.936945524000066],
              [-74.92363892599991, 10.936520894000068],
              [-74.9238022049999 , 10.936277415000063],
              [-74.92375868999994, 10.936248919000093],
              [-74.92453166899992, 10.935101848000045],
              [-74.92455352699994, 10.93488764600005 ],
              [-74.92770237799994, 10.904030293000062],
              [-74.92812045499994, 10.899933323000084],
              [-74.92812210099993, 10.899917196000047],
              [-74.92812440699993, 10.899868950000041],
              [-74.9281244199999 , 10.899868669000057],
              [-74.92812985099994, 10.899755054000082],
              [-74.92814649399992, 10.899406852000084],
              [-74.92806372799993, 10.898749662000057],
              [-74.92795478599993, 10.898285264000037],
              [-74.9279858939999 , 10.89780371900008 ],
              [-74.92806498399995, 10.897125117000087],
              [-74.92812708899993, 10.896479492000083],
              [-74.92824356799991, 10.895684765000055],
              [-74.92837040199993, 10.89531880900006 ],
              [-74.9285795699999 , 10.895131175000074],
              [-74.9290307409999 , 10.894726459000083],
              [-74.92940478799994, 10.894499108000048],
              [-74.92941591399995, 10.89448419100006 ],
              [-74.9295101539999 , 10.89425467500007 ],
              [-74.92951971899993, 10.893910781000045],
              [-74.9296340329999 , 10.89332691900006 ],
              [-74.93013920699991, 10.893012902000066],
              [-74.93077021599993, 10.892458866000084],
              [-74.9311690159999 , 10.891944659000046],
              [-74.93123244999992, 10.891496820000043],
              [-74.9312729749999 , 10.891147883000087],
              [-74.93128654499992, 10.891031060000046],
              [-74.93129560499995, 10.890953051000054],
              [-74.9314883479999 , 10.890472875000057],
              [-74.93203723499994, 10.890087633000064],
              [-74.93274733499993, 10.889510089000055],
              [-74.9328433899999 , 10.889158087000055],
              [-74.93284202099994, 10.888678450000043],
              [-74.93261245799994, 10.887591907000058],
              [-74.93218960399992, 10.886793684000054],
              [-74.93196241899994, 10.886538508000058],
              [-74.93176175299993, 10.88638280300006 ],
              [-74.93132321699994, 10.886378604000072],
              [-74.93101621199992, 10.886363205000066],
              [-74.93091389499995, 10.886326273000066],
              [-74.93080769999995, 10.886287938000066],
              [-74.93057178099991, 10.886212743000044],
              [-74.93031456099993, 10.886163475000046],
              [-74.93015508099995, 10.886175976000061],
              [-74.92999534099994, 10.886281877000044],
              [-74.92999094499993, 10.886284791000037],
              [-74.92998549499993, 10.88629564100006 ],
              [-74.92998848899992, 10.886276802000054],
              [-74.93000703599995, 10.886160132000043],
              [-74.93005575199993, 10.885943286000042],
              [-74.93008811699991, 10.885758987000088],
              [-74.93006513099994, 10.885525035000057],
              [-74.92992656099995, 10.885327019000044],
              [-74.92983962099993, 10.88508218600009 ],
              [-74.92982538299992, 10.88504209100006 ],
              [-74.92977829099993, 10.88475212800006 ],
              [-74.92983655699993, 10.884558450000043],
              [-74.92993603899993, 10.884248168000056],
              [-74.92995277299991, 10.884191340000086],
              [-74.9299981719999 , 10.88403719300004 ],
              [-74.9300478259999 , 10.883851049000043],
              [-74.9300444779999 , 10.883756633000075],
              [-74.93003893799994, 10.88360040300006 ],
              [-74.92996546299992, 10.883289688000048],
              [-74.92981945699995, 10.883003085000041],
              [-74.92956907599995, 10.88249888100006 ],
              [-74.92947509099992, 10.882217411000056],
              [-74.92939776599991, 10.88201175100005 ],
              [-74.92933197199994, 10.88176074800009 ],
              [-74.92947647599993, 10.881521175000046],
              [-74.92957252399992, 10.881257822000066],
              [-74.92964457199992, 10.881066284000042],
              [-74.92959342199993, 10.88087377000005 ],
              [-74.92955306399995, 10.880721871000048],
              [-74.9295855979999 , 10.880597169000055],
              [-74.9295700049999 , 10.88037289500005 ],
              [-74.92947226899992, 10.880038325000044],
              [-74.92947888299994, 10.879692680000062],
              [-74.9295101859999 , 10.879134556000054],
              [-74.92953505199995, 10.87898195300005 ],
              [-74.92956406099995, 10.878803916000038],
              [-74.92973402399991, 10.878482986000051],
              [-74.9298302709999 , 10.878291381000054],
              [-74.92990211499995, 10.878028094000058],
              [-74.92984060799995, 10.877746667000054],
              [-74.92983163899993, 10.87756304800007 ],
              [-74.92982906499992, 10.877510346000065],
              [-74.92982314499994, 10.877389139000059],
              [-74.9299550209999 , 10.87703993100007 ],
              [-74.93000033299995, 10.87706047000006 ],
              [-74.93046107099991, 10.877269310000088],
              [-74.9305020619999 , 10.877182451000067],
              [-74.93090890299993, 10.87632035300004 ],
              [-74.93091705499995, 10.876303078000092],
              [-74.93098106299993, 10.876167444000089],
              [-74.93098117199992, 10.876167214000077],
              [-74.93102389899991, 10.876076674000046],
              [-74.93103486399991, 10.876051811000082],
              [-74.93099847799994, 10.876037390000079],
              [-74.9309437789999 , 10.876015710000047],
              [-74.93096419199992, 10.875994159000072],
              [-74.93096687299993, 10.875991329000044],
              [-74.93097889599994, 10.875974935000045],
              [-74.93098332999995, 10.875968889000092],
              [-74.9310107689999 , 10.875931472000048],
              [-74.93101131399993, 10.87593072900006 ],
              [-74.93102842299993, 10.875907400000074],
              [-74.9311018539999 , 10.875807270000053],
              [-74.93112108699995, 10.87576320900007 ],
              [-74.93124397299994, 10.875668046000044],
              [-74.93130458199994, 10.875621110000054],
              [-74.93139060599992, 10.875556284000083],
              [-74.93160784899993, 10.875392576000081],
              [-74.93174718299991, 10.875354104000053],
              [-74.93189870499992, 10.875312267000083],
              [-74.9319018569999 , 10.875311397000075],
              [-74.93199581599993, 10.875210175000063],
              [-74.93215093299995, 10.875043069000071],
              [-74.93216524899992, 10.875027647000081],
              [-74.93227045999993, 10.874914304000072],
              [-74.93224259599992, 10.874877471000048],
              [-74.93224521099995, 10.874874654000052],
              [-74.93223151899991, 10.874856555000065],
              [-74.93223036799992, 10.874855034000063],
              [-74.93218222899992, 10.874791401000039],
              [-74.9321102589999 , 10.874696265000068],
              [-74.93208199199995, 10.874658898000064],
              [-74.93206361999995, 10.87464213000004 ],
              [-74.93193142699994, 10.874785042000042],
              [-74.93161827299991, 10.874940896000055],
              [-74.93158190299994, 10.874914746000059],
              [-74.93185263099991, 10.874698197000043],
              [-74.93197421399992, 10.87456336400004 ],
              [-74.93207887499995, 10.874447295000039],
              [-74.93238405099993, 10.874372264000044],
              [-74.93272310999993, 10.874480522000056],
              [-74.93308606499994, 10.874479511000061],
              [-74.93354587599993, 10.874502147000044],
              [-74.93398107999991, 10.874381347000053],
              [-74.93422243599991, 10.874165420000054],
              [-74.93441532799994, 10.873925713000062],
              [-74.93463179999992, 10.87347068300005 ],
              [-74.93484806799995, 10.872943906000046],
              [-74.93506509299993, 10.872680211000045],
              [-74.93511556199991, 10.872598632000063],
              [-74.93525777899993, 10.872368748000042],
              [-74.93525848299993, 10.872049765000042],
              [-74.93511068499993, 10.871699482000054],
              [-74.93494021399994, 10.871317284000042],
              [-74.93469735999992, 10.871007040000052],
              [-74.93452274299995, 10.870835414000055],
              [-74.9343091209999 , 10.870625447000066],
              [-74.93401807799995, 10.87038708600005 ],
              [-74.93363707299994, 10.870107098000062],
              [-74.93343029899995, 10.869955143000084],
              [-74.93362909199993, 10.86974241200005 ],
              [-74.93384576999995, 10.869359133000046],
              [-74.93411077199994, 10.868951802000083],
              [-74.9344491139999 , 10.868807354000069],
              [-74.93486031999993, 10.868758375000084],
              [-74.93524752999991, 10.868781211000055],
              [-74.93552248899994, 10.868957240000043],
              [-74.9358149279999 , 10.868994949000069],
              [-74.93602229899994, 10.868946468000047],
              [-74.9363848349999 , 10.868801954000048],
              [-74.93655887199992, 10.868690679000053],
              [-74.93672297099994, 10.868585753000048],
              [-74.9370125069999 , 10.868297940000048],
              [-74.9373744909999 , 10.867962090000049],
              [-74.93759178499994, 10.867794060000051],
              [-74.9375999209999 , 10.86778507300005 ],
              [-74.93780886699994, 10.867554282000071],
              [-74.93802608599992, 10.867362341000046],
              [-74.93821903999992, 10.867146544000093],
              [-74.93841213099995, 10.866978583000048],
              [-74.9388804159999 , 10.866793185000063],
              [-74.93921335999994, 10.866632787000071],
              [-74.93966848699995, 10.866329301000064],
              [-74.94005486899994, 10.866065129000049],
              [-74.94032048199995, 10.865873047000036],
              [-74.94058450799992, 10.865509358000054],
              [-74.94087655699991, 10.865412222000089],
              [-74.94169342399994, 10.865126890000056],
              [-74.9420932569999 , 10.864951297000061],
              [-74.9422272299999 , 10.864888105000091],
              [-74.94232436599992, 10.86484229000007 ],
              [-74.94234139799994, 10.864795106000088],
              [-74.94243312099991, 10.86454099100007 ],
              [-74.94242021299993, 10.864288199000043],
              [-74.94232204399992, 10.864035641000044],
              [-74.94210214499992, 10.863807507000047],
              [-74.94195921599993, 10.86357240700005 ],
              [-74.94198210799993, 10.863117918000057],
              [-74.9419568799999 , 10.862759231000041],
              [-74.94210129899994, 10.862495738000064],
              [-74.94224571599995, 10.86223224300005 ],
              [-74.94239730699991, 10.862156792000064],
              [-74.94243900999993, 10.86213603300007 ],
              [-74.94263223299993, 10.862015903000042],
              [-74.94275197399992, 10.86158505700007 ],
              [-74.9429684939999 , 10.86115394300009 ],
              [-74.94343435899992, 10.860922842000036],
              [-74.94373487699994, 10.86070937900007 ],
              [-74.94408956599995, 10.860633968000059],
              [-74.94473106299995, 10.85985745700009 ],
              [-74.94499632199995, 10.859545787000059],
              [-74.94487299699995, 10.85873295500005 ],
              [-74.94483089099992, 10.858594765000078],
              [-74.94482470699995, 10.858574472000043],
              [-74.94481582499992, 10.858510503000048],
              [-74.94481372799993, 10.858419993000041],
              [-74.94514074499995, 10.858544061000089],
              [-74.94526934299995, 10.858537991000048],
              [-74.94529786899994, 10.858543223000083],
              [-74.94541366399994, 10.858564457000057],
              [-74.94563517499995, 10.857539970000062],
              [-74.9457503459999 , 10.857007297000052],
              [-74.9464995159999 , 10.85541221200009 ],
              [-74.94665401499992, 10.855083261000061],
              [-74.9473714419999 , 10.85381057300009 ],
              [-74.94767183199991, 10.853182385000082],
              [-74.9476830779999 , 10.853158867000047],
              [-74.9477324799999 , 10.852994376000083],
              [-74.9479625589999 , 10.852228325000056],
              [-74.94880466299992, 10.85070735000005 ],
              [-74.94921034999993, 10.850055376000057],
              [-74.94977162799995, 10.849000070000045],
              [-74.95008307299992, 10.848286381000037],
              [-74.95064488399993, 10.847417018000044],
              [-74.95130236699993, 10.84710523700005 ],
              [-74.95308786499993, 10.846573306000039],
              [-74.95393308599995, 10.846137021000061],
              [-74.9544961439999 , 10.845701534000057],
              [-74.95477661399991, 10.844974444000059],
              [-74.95480731099991, 10.844894866000061],
              [-74.95493797099994, 10.844802044000062],
              [-74.95524532299993, 10.844583699000054],
              [-74.95580837699993, 10.84414821200005 ],
              [-74.95637142999993, 10.843712725000046],
              [-74.95659706099991, 10.843605722000063],
              [-74.95702889599994, 10.843400931000076],
              [-74.95732110699993, 10.84322325700009 ],
              [-74.9575922169999 , 10.843058413000051],
              [-74.95818715799993, 10.842808780000041],
              [-74.95835215199992, 10.842717550000089],
              [-74.95843231999993, 10.842673223000077],
              [-74.95875056799991, 10.84249725300009 ],
              [-74.95944440399995, 10.841416821000053],
              [-74.95946836999991, 10.841379501000063],
              [-74.95987456799992, 10.84091346200006 ],
              [-74.9601222849999 , 10.839859037000053],
              [-74.96034064899993, 10.839486514000043],
              [-74.96121782599994, 10.839267060000054],
              [-74.96179377399994, 10.839114580000057],
              [-74.96206005999994, 10.838826729000061],
              [-74.96224039299995, 10.838824063000061],
              [-74.9623891679999 , 10.838823705000038],
              [-74.96265503699993, 10.838767372000063],
              [-74.96286884199992, 10.838622064000049],
              [-74.96308707599991, 10.838445558000046],
              [-74.96321275199995, 10.838209129000063],
              [-74.96328002799993, 10.838061946000039],
              [-74.96329326599994, 10.837941626000088],
              [-74.96328626399992, 10.837841401000048],
              [-74.9632611529999 , 10.837710037000079],
              [-74.96329458199995, 10.837549573000047],
              [-74.9633440209999 , 10.837484851000056],
              [-74.96350811299993, 10.83729065500006 ],
              [-74.96357304999992, 10.837107836000087],
              [-74.9635614469999 , 10.836969755000041],
              [-74.9635381089999 , 10.83663567800005 ],
              [-74.96353296899991, 10.836372833000041],
              [-74.96354603799995, 10.836181233000048],
              [-74.96355092199991, 10.83613917200006 ],
              [-74.96357475099995, 10.835933899000054],
              [-74.9635655269999 , 10.835847048000062],
              [-74.96358110599994, 10.835782601000062],
              [-74.96359677399994, 10.835717775000091],
              [-74.96370688199994, 10.835570486000051],
              [-74.96379698099992, 10.835541311000043],
              [-74.96393206099992, 10.835469699000043],
              [-74.96407177399993, 10.835449313000083],
              [-74.96424971199991, 10.835388739000052],
              [-74.9644862259999 , 10.835314655000047],
              [-74.96481725799993, 10.835173517000044],
              [-74.9649656929999 , 10.835030593000056],
              [-74.96501999599991, 10.835004820000051],
              [-74.96516383599993, 10.834936551000055],
              [-74.96522469699994, 10.834936406000054],
              [-74.96526812899992, 10.834958190000066],
              [-74.96533077499993, 10.834989608000058],
              [-74.96540973399993, 10.835016151000048],
              [-74.96549305099995, 10.834978078000063],
              [-74.96556587999993, 10.834902236000062],
              [-74.9655852439999 , 10.834882070000049],
              [-74.9656301309999 , 10.834857190000037],
              [-74.9657023069999 , 10.834817184000087],
              [-74.96573317299993, 10.834821121000061],
              [-74.96578799199995, 10.83482811400006 ],
              [-74.96590078499992, 10.83486348300005 ],
              [-74.96600887099993, 10.834814214000062],
              [-74.9661347309999 , 10.834657978000052],
              [-74.9662562659999 , 10.834577491000061],
              [-74.96625716299991, 10.83455232600005 ],
              [-74.9662604849999 , 10.834459417000062],
              [-74.96616540899993, 10.834290353000085],
              [-74.9660500359999 , 10.834121338000045],
              [-74.96602730699993, 10.83404342600005 ],
              [-74.96596617999995, 10.83393219900006 ],
              [-74.96594295499995, 10.833885207000037],
              [-74.96593597999993, 10.833692891000055],
              [-74.96597871199992, 10.83361265800005 ],
              [-74.96602328699993, 10.83355006000005 ],
              [-74.9660746269999 , 10.833473970000057],
              [-74.9661278339999 , 10.833365808000053],
              [-74.96613549199992, 10.833291993000046],
              [-74.96612707499992, 10.833200439000052],
              [-74.96608905799991, 10.833118120000051],
              [-74.96609022699994, 10.833055337000076],
              [-74.96612702099992, 10.832977590000041],
              [-74.96616727399993, 10.83293754600004 ],
              [-74.96621821499991, 10.832909235000045],
              [-74.96627564199991, 10.83286989000004 ],
              [-74.96630744399994, 10.832826475000047],
              [-74.96631942999994, 10.832775943000058],
              [-74.96629396799995, 10.83259219200005 ],
              [-74.96630972299994, 10.832393116000048],
              [-74.96632748899992, 10.832297047000054],
              [-74.96628892299992, 10.832223242000055],
              [-74.96620264499995, 10.832172229000037],
              [-74.9661529309999 , 10.832092017000036],
              [-74.96615581799995, 10.83195788200004 ],
              [-74.96616078499994, 10.831881022000061],
              [-74.96616693699991, 10.831787384000052],
              [-74.96616696799992, 10.831699079000089],
              [-74.96616262599991, 10.831587346000049],
              [-74.96617880499991, 10.83147114600007 ],
              [-74.9662590069999 , 10.83140246000005 ],
              [-74.96625982099994, 10.831297125000049],
              [-74.96622651199993, 10.831186441000057],
              [-74.9662066489999 , 10.831094248000056],
              [-74.96614096999991, 10.830950595000047],
              [-74.96604780599995, 10.830827844000055],
              [-74.96593898599991, 10.830678375000048],
              [-74.96593377099992, 10.830632824000077],
              [-74.96591130799993, 10.830436637000048],
              [-74.9658485089999 , 10.830201278000061],
              [-74.96579218699992, 10.830043321000062],
              [-74.96575596799994, 10.829932375000055],
              [-74.96565581499993, 10.829780084000049],
              [-74.96556271399993, 10.829608217000043],
              [-74.96555359699994, 10.829436016000045],
              [-74.96555127399995, 10.829292835000047],
              [-74.96551762399992, 10.82914199000004 ],
              [-74.96548391199991, 10.829036906000056],
              [-74.96548704499992, 10.828988473000038],
              [-74.9654978989999 , 10.828820361000055],
              [-74.9655113309999 , 10.828746811000087],
              [-74.96552776599992, 10.828716365000048],
              [-74.9655508809999 , 10.828672031000053],
              [-74.96559047199992, 10.828597379000087],
              [-74.96558121899994, 10.828516702000059],
              [-74.9655021989999 , 10.82848602100006 ],
              [-74.96538546799991, 10.828501636000055],
              [-74.9652364399999 , 10.828569733000052],
              [-74.96517647899992, 10.828468952000037],
              [-74.96517279499994, 10.828391449000037],
              [-74.96521386199993, 10.828293966000047],
              [-74.96528682799993, 10.828195599000082],
              [-74.96541056799992, 10.82806708000004 ],
              [-74.96542482599995, 10.827940905000048],
              [-74.96541557299992, 10.827860187000056],
              [-74.96537872099992, 10.827803508000045],
              [-74.96529628999991, 10.827735389000054],
              [-74.96521202899993, 10.827561260000039],
              [-74.96523224599991, 10.827428059000056],
              [-74.96524467099994, 10.827325858000052],
              [-74.9652558809999 , 10.827197802000057],
              [-74.96525674799994, 10.827094794000061],
              [-74.9652691359999 , 10.82699251300005 ],
              [-74.96531036599993, 10.826892048000047],
              [-74.96537011399994, 10.826818501000048],
              [-74.9654387409999 , 10.826742679000063],
              [-74.9654843049999 , 10.826665414000047],
              [-74.96549523399995, 10.82658593800005 ],
              [-74.9654751779999 , 10.82649305700005 ],
              [-74.96546574099995, 10.82641516600006 ],
              [-74.96553522499994, 10.826329913000052],
              [-74.96553883299993, 10.82627089400006 ],
              [-74.9654811719999 , 10.826132692000044],
              [-74.9653807329999 , 10.826029770000048],
              [-74.96525349599995, 10.825983733000044],
              [-74.9651554269999 , 10.825977562000048],
              [-74.96509472899993, 10.825930776000064],
              [-74.9651170109999 , 10.825854843000059],
              [-74.96519775199994, 10.825771122000049],
              [-74.96525354099992, 10.825714497000092],
              [-74.96531240299993, 10.825655187000052],
              [-74.96536507499991, 10.825634927000067],
              [-74.96539556199991, 10.825623200000052],
              [-74.9654649769999 , 10.825624722000043],
              [-74.96548399199992, 10.82562649600004 ],
              [-74.96555997199994, 10.825633576000087],
              [-74.96566072699994, 10.825642798000047],
              [-74.9657373629999 , 10.825621856000055],
              [-74.9657951019999 , 10.825579675000085],
              [-74.96585516499994, 10.825516495000045],
              [-74.96595452699995, 10.825352071000054],
              [-74.96600619399993, 10.825269419000051],
              [-74.96607018499992, 10.825130280000053],
              [-74.96607596199993, 10.824996054000053],
              [-74.9660014449999 , 10.824805193000088],
              [-74.96595077899991, 10.82469315800006 ],
              [-74.96589507599992, 10.824569345000043],
              [-74.96578786399994, 10.82440149900009 ],
              [-74.96582430799992, 10.82418924700005 ],
              [-74.9659326929999 , 10.824035709000043],
              [-74.96604219699992, 10.823910775000058],
              [-74.96611393499995, 10.823786428000062],
              [-74.96615481499992, 10.823691629000052],
              [-74.96616256199991, 10.823586671000044],
              [-74.9662511759999 , 10.823419716000046],
              [-74.96629691099992, 10.82333354800005 ],
              [-74.96633442599995, 10.823201273000052],
              [-74.96633902999992, 10.823129941000047],
              [-74.96649480199994, 10.822861489000047],
              [-74.96661381799993, 10.822831136000048],
              [-74.96672024499992, 10.82279780400006 ],
              [-74.96677290699995, 10.822746537000057],
              [-74.96675995399994, 10.822679601000061],
              [-74.96672484599992, 10.822640100000058],
              [-74.96672834799995, 10.82258586000006 ],
              [-74.96678359499992, 10.822494612000071],
              [-74.96685346099991, 10.822444621000045],
              [-74.96692804399993, 10.822366268000053],
              [-74.96695088099995, 10.822281745000055],
              [-74.96696703099991, 10.822211118000041],
              [-74.96701129199994, 10.822153765000053],
              [-74.96702297099995, 10.822142589000066],
              [-74.96707304799992, 10.822094667000044],
              [-74.96713940399991, 10.822055702000057],
              [-74.96718907099995, 10.822004424000056],
              [-74.96726966499995, 10.821877740000048],
              [-74.96733608999995, 10.821784433000062],
              [-74.96751164599993, 10.821618223000087],
              [-74.96768888899993, 10.821488730000056],
              [-74.96779349699995, 10.821440737000046],
              [-74.96787432699995, 10.821400069000049],
              [-74.96795588099991, 10.821347984000056],
              [-74.9680356529999 , 10.821278593000045],
              [-74.96816753699994, 10.821074985000052],
              [-74.96818448199991, 10.820947102000048],
              [-74.96821198399994, 10.82088267100005 ],
              [-74.96825746099995, 10.82085378100004 ],
              [-74.96833379999993, 10.82079277300005 ],
              [-74.96834746399992, 10.820772741000042],
              [-74.96841077199991, 10.820679919000042],
              [-74.96844673499993, 10.820616305000044],
              [-74.96851042899993, 10.820571681000047],
              [-74.96862313499992, 10.820530228000052],
              [-74.96877546399992, 10.820502794000049],
              [-74.96882510199993, 10.820505998000044],
              [-74.96887925499993, 10.820509491000053],
              [-74.96911307999994, 10.820430009000063],
              [-74.96920501599993, 10.820395819000055],
              [-74.96940045899993, 10.820310835000043],
              [-74.96949311599991, 10.82024470400006 ],
              [-74.96956438799992, 10.820171931000061],
              [-74.96966636899992, 10.820161322000047],
              [-74.96976705299994, 10.820170695000058],
              [-74.96988111099995, 10.820152276000044],
              [-74.96993984699992, 10.820138880000059],
              [-74.9700651249999 , 10.820082077000052],
              [-74.9702036349999 , 10.820014170000093],
              [-74.97036347699992, 10.819971893000059],
              [-74.97036387399993, 10.819971623000072],
              [-74.97044854399991, 10.819913993000057],
              [-74.97051703999995, 10.819884028000047],
              [-74.97058620999991, 10.819888511000045],
              [-74.97064575899992, 10.819912074000058],
              [-74.97070859999991, 10.819924745000037],
              [-74.97076753099992, 10.81990849400006 ],
              [-74.97081758799993, 10.819805669000061],
              [-74.97082079499995, 10.819711290000043],
              [-74.97080180299992, 10.819647003000057],
              [-74.97080363099991, 10.819612022000058],
              [-74.97080372699992, 10.819493497000053],
              [-74.97088330399993, 10.81943214000006 ],
              [-74.97098224399991, 10.819424966000042],
              [-74.97113253599991, 10.819390076000047],
              [-74.97127641499992, 10.819326155000056],
              [-74.97138154699991, 10.81927659300004 ],
              [-74.9715378169999 , 10.819202148000045],
              [-74.9716387759999 , 10.819020901000044],
              [-74.97168152899991, 10.818844039000055],
              [-74.97168089399992, 10.818704037000089],
              [-74.9716689839999 , 10.81852322800006 ],
              [-74.9716267359999 , 10.818394461000082],
              [-74.97161523599993, 10.818359396000062],
              [-74.97154574799993, 10.818322971000043],
              [-74.97137794899993, 10.81843371900004 ],
              [-74.9712429029999 , 10.818606811000052],
              [-74.97117846799995, 10.81869073200005 ],
              [-74.97111405399994, 10.818700379000063],
              [-74.97104831799993, 10.818689930000062],
              [-74.97098922799995, 10.818623796000054],
              [-74.97092038799991, 10.818467398000053],
              [-74.97084202499991, 10.81832173500004 ],
              [-74.97075170099993, 10.818180919000042],
              [-74.97068262499994, 10.81806449000004 ],
              [-74.97065783399995, 10.817974112000059],
              [-74.97062174899992, 10.81784291300005 ],
              [-74.97065126399991, 10.817793044000041],
              [-74.97068177599994, 10.817741496000053],
              [-74.97074600099995, 10.817734692000045],
              [-74.97082881199992, 10.81782353400007 ],
              [-74.97091161399993, 10.817949533000046],
              [-74.97102584899994, 10.818080647000045],
              [-74.97118149699992, 10.818126152000048],
              [-74.97124615999991, 10.818076518000055],
              [-74.9713115269999 , 10.817795463000039],
              [-74.97126064199995, 10.81759461300004 ],
              [-74.9712519009999 , 10.817310707000047],
              [-74.9712886989999 , 10.817136135000055],
              [-74.9713770859999 , 10.817005306000055],
              [-74.9714504179999 , 10.816844813000046],
              [-74.97153195099992, 10.816653457000086],
              [-74.9716081439999 , 10.816567489000079],
              [-74.97168633099994, 10.816530253000053],
              [-74.97184864499991, 10.816527689000054],
              [-74.9719659989999 , 10.816544749000059],
              [-74.97214598999994, 10.816649230000053],
              [-74.97228257299992, 10.816753421000044],
              [-74.9723542829999 , 10.816835772000047],
              [-74.9724206329999 , 10.817000248000056],
              [-74.97256877299992, 10.817020573000093],
              [-74.97259065199995, 10.817023576000054],
              [-74.97265686599991, 10.817028355000048],
              [-74.97279192099995, 10.81704097100004 ],
              [-74.97290149899993, 10.817120344000045],
              [-74.97300550499995, 10.817270776000043],
              [-74.97306317999994, 10.817419426000072],
              [-74.97284560299994, 10.81757439100005 ],
              [-74.97253032399993, 10.817784974000062],
              [-74.97256514399993, 10.817941701000052],
              [-74.97260035999994, 10.818042590000061],
              [-74.97263826199992, 10.818037487000083],
              [-74.97285336599992, 10.818022259000088],
              [-74.9729237979999 , 10.817967055000054],
              [-74.97327616599995, 10.818169860000069],
              [-74.97336539499992, 10.817952734000073],
              [-74.97343875099995, 10.817840625000088],
              [-74.97344060299991, 10.81783779400007 ],
              [-74.97347800999995, 10.817780626000058],
              [-74.97352303399992, 10.817711814000063],
              [-74.97352600799991, 10.817542277000086],
              [-74.97348802999994, 10.817473779000068],
              [-74.97351709999992, 10.817457164000075],
              [-74.97359030899992, 10.817408540000088],
              [-74.97367451699995, 10.817482200000086],
              [-74.97371925199991, 10.817462213000056],
              [-74.97376398599994, 10.817442225000093],
              [-74.97388010399993, 10.817394636000074],
              [-74.97396195699991, 10.817358468000066],
              [-74.97406843099992, 10.817296971000076],
              [-74.97413778499993, 10.817365693000056],
              [-74.97428586299992, 10.817198032000078],
              [-74.9743730799999 , 10.817316420000054],
              [-74.9743895929999 , 10.817297162000045],
              [-74.97460194599995, 10.817428042000074],
              [-74.97461761499994, 10.817429537000066],
              [-74.97469012199991, 10.817436455000063],
              [-74.97478365899991, 10.817445380000038],
              [-74.97500982399993, 10.817466959000058],
              [-74.9750880979999 , 10.817376258000081],
              [-74.97505478699992, 10.817352464000066],
              [-74.97497031699993, 10.817285839000078],
              [-74.97493105599995, 10.817210886000055],
              [-74.9749458419999 , 10.81718834700007 ],
              [-74.97496062899995, 10.817165807000038],
              [-74.97508608699991, 10.81697457100006 ],
              [-74.9749375589999 , 10.816864119000059],
              [-74.97505605599991, 10.816754188000061],
              [-74.97494897999991, 10.816621414000053],
              [-74.97499029499994, 10.81658241000008 ],
              [-74.97515313799994, 10.81642867800008 ],
              [-74.97536764599994, 10.816533416000084],
              [-74.97549149699995, 10.816617131000044],
              [-74.97559429099994, 10.816665672000056],
              [-74.97561366699995, 10.816681044000063],
              [-74.9756330429999 , 10.81669641700006 ],
              [-74.97555096499991, 10.816814871000076],
              [-74.97567036999993, 10.816894724000065],
              [-74.97578872899993, 10.816973878000056],
              [-74.97580148799995, 10.81699302700008 ],
              [-74.97568360199995, 10.81705197000008 ],
              [-74.97547801699994, 10.81711288400004 ],
              [-74.97544184899994, 10.81720615900008 ],
              [-74.9754415999999 , 10.817212271000074],
              [-74.97543804299994, 10.817299434000063],
              [-74.97547421099995, 10.817385094000088],
              [-74.97545898099992, 10.81745552600006 ],
              [-74.97537532099994, 10.81759622800007 ],
              [-74.97537522499994, 10.817596390000062],
              [-74.97538854899994, 10.817727737000041],
              [-74.9754608849999 , 10.81781720400005 ],
              [-74.97553880499993, 10.817746839000051],
              [-74.97567027699995, 10.81789905800008 ],
              [-74.97573119099991, 10.817919997000047],
              [-74.97579971999994, 10.817860986000085],
              [-74.97593106599993, 10.817702990000043],
              [-74.97588728499994, 10.817636365000055],
              [-74.97573499899994, 10.817558319000057],
              [-74.9757331699999 , 10.817556491000062],
              [-74.97571691299993, 10.81754023500008 ],
              [-74.97570903999991, 10.817532360000087],
              [-74.97574718899995, 10.817487549000077],
              [-74.97590899199992, 10.817315277000091],
              [-74.97605461599994, 10.817271494000067],
              [-74.97619344199995, 10.81724448500006 ],
              [-74.9762992229999 , 10.817223905000048],
              [-74.97636192099992, 10.817212877000088],
              [-74.97659142099991, 10.817172508000056],
              [-74.97661492299994, 10.817214130000082],
              [-74.97667483599992, 10.817394522000086],
              [-74.9776374029999 , 10.817364784000063],
              [-74.97761887699994, 10.817143876000046],
              [-74.97761784699992, 10.817132341000047],
              [-74.97761681799994, 10.817120807000038],
              [-74.97763709999992, 10.817120488000057],
              [-74.97765738099991, 10.817120170000067],
              [-74.97781111799992, 10.817135060000055],
              [-74.97782140999993, 10.81708830000008 ],
              [-74.97783069699994, 10.817046103000052],
              [-74.97788946399993, 10.816779095000072],
              [-74.97793335299991, 10.816579688000047],
              [-74.97796550499993, 10.81638758400004 ],
              [-74.97795237399993, 10.816379228000073],
              [-74.9778398709999 , 10.81630763600009 ],
              [-74.97781131599993, 10.816370452000058],
              [-74.97769223299991, 10.816287382000041],
              [-74.97774865699995, 10.816264473000047],
              [-74.97780401599994, 10.81622273000005 ],
              [-74.97788891599993, 10.816108708000058],
              [-74.97791348999993, 10.81613903400006 ],
              [-74.97800016799994, 10.81624600300006 ],
              [-74.9782236229999 , 10.816404616000057],
              [-74.97835672399992, 10.816514303000076],
              [-74.97845527299995, 10.816658647000054],
              [-74.9785708789999 , 10.816717998000058],
              [-74.97872417899993, 10.816754809000088],
              [-74.97889562299991, 10.816781500000047],
              [-74.97904203699994, 10.816832101000045],
              [-74.97919747199995, 10.816914801000053],
              [-74.97931657399994, 10.817057871000088],
              [-74.9793797669999 , 10.817133781000052],
              [-74.9794190689999 , 10.81720150600006 ],
              [-74.97950960299994, 10.817357529000049],
              [-74.97956681399995, 10.817438822000042],
              [-74.97972307599991, 10.817547284000057],
              [-74.97988277499991, 10.817538797000054],
              [-74.98004430099991, 10.81747043900009 ],
              [-74.980141        , 10.817417401000057],
              [-74.98025850699992, 10.817357299000037],
              [-74.98030132299994, 10.817351417000054],
              [-74.98037230199992, 10.81736797800005 ],
              [-74.9804534349999 , 10.81740241500006 ],
              [-74.98048181399992, 10.81740446300006 ],
              [-74.98053105299994, 10.817408017000048],
              [-74.98062569899992, 10.817417713000054],
              [-74.9807068309999 , 10.817403165000087],
              [-74.9808687769999 , 10.817341025000076],
              [-74.98091238199993, 10.817221458000063],
              [-74.98091249299995, 10.81722007400009 ],
              [-74.98092563299991, 10.81705366600005 ],
              [-74.9809891829999 , 10.816835148000052],
              [-74.98098821999992, 10.816807285000039],
              [-74.98098126399992, 10.816605735000053],
              [-74.9810128549999 , 10.816462130000048],
              [-74.98107186999994, 10.816337665000049],
              [-74.98115008799994, 10.816263225000057],
              [-74.98117490399994, 10.816262085000062],
              [-74.9812952769999 , 10.816256571000054],
              [-74.98135231399993, 10.816303609000045],
              [-74.98138868299992, 10.816429239000058],
              [-74.98141061299992, 10.816591019000043],
              [-74.98155102099992, 10.816681265000057],
              [-74.98170832699992, 10.81670407900009 ],
              [-74.98179389599994, 10.816756032000058],
              [-74.9819422889999 , 10.816818240000089],
              [-74.98203042299991, 10.81682700500005 ],
              [-74.98213278199995, 10.816802459000087],
              [-74.98232115499991, 10.816761727000085],
              [-74.98246157299991, 10.816768529000058],
              [-74.9826012879999 , 10.816821065000056],
              [-74.9828076039999 , 10.816909894000048],
              [-74.98296560599994, 10.816924175000054],
              [-74.98312680399994, 10.816861439000093],
              [-74.98322162099993, 10.816787696000063],
              [-74.98330865399993, 10.816748201000053],
              [-74.98351149599995, 10.816671306000046],
              [-74.98365548399994, 10.81657012900007 ],
              [-74.9836683119999 , 10.816551146000052],
              [-74.98374397599991, 10.816439179000042],
              [-74.98386723099992, 10.816335955000056],
              [-74.98399482699995, 10.816259333000062],
              [-74.98410853899992, 10.816138783000042],
              [-74.98423364999991, 10.816093464000062],
              [-74.98426444499995, 10.816099291000057],
              [-74.98435621499993, 10.816116652000062],
              [-74.98439743699993, 10.816127227000038],
              [-74.98444636499994, 10.81613978100006 ],
              [-74.98456076499991, 10.81615664800006 ],
              [-74.98474001799991, 10.816158180000059],
              [-74.98486675899994, 10.816201703000047],
              [-74.98495817399993, 10.81632565600006 ],
              [-74.98502976499992, 10.816510591000053],
              [-74.98505817499995, 10.816735870000059],
              [-74.98507762299994, 10.816891818000045],
              [-74.98519976499995, 10.817023215000063],
              [-74.98531104599994, 10.817042701000048],
              [-74.98545904399992, 10.817073435000054],
              [-74.98553326299992, 10.817158928000083],
              [-74.98555580499993, 10.817312227000059],
              [-74.98548539699993, 10.817470244000049],
              [-74.98530451699992, 10.817671793000045],
              [-74.98527483499993, 10.817700098000046],
              [-74.98517618999995, 10.817794158000083],
              [-74.98512835399993, 10.817959521000091],
              [-74.98508886299993, 10.818165546000046],
              [-74.98508870199993, 10.818166714000085],
              [-74.98507520699991, 10.818264704000057],
              [-74.9850632969999 , 10.818524207000053],
              [-74.98512058299991, 10.818677102000038],
              [-74.98521622699991, 10.81878412900005 ],
              [-74.98525530599994, 10.818838443000061],
              [-74.98529178599995, 10.818852507000088],
              [-74.98540383999995, 10.818928949000053],
              [-74.9854663779999 , 10.81897923200006 ],
              [-74.98554740799995, 10.819088064000084],
              [-74.98558091899991, 10.819170317000044],
              [-74.98560639499993, 10.819286597000087],
              [-74.98563543199992, 10.819394543000044],
              [-74.98568769799994, 10.819501287000037],
              [-74.9857428439999 , 10.819608244000051],
              [-74.98586045999991, 10.819688015000054],
              [-74.9859551529999 , 10.819697685000051],
              [-74.98607047899992, 10.819843107000054],
              [-74.9860973939999 , 10.819916257000045],
              [-74.98612995299993, 10.820004747000041],
              [-74.98626226499994, 10.820191475000058],
              [-74.98638228099992, 10.820205655000052],
              [-74.98658831299991, 10.820197582000048],
              [-74.98669519799995, 10.820199539000043],
              [-74.98688202599993, 10.82024713900006 ],
              [-74.9870416839999 , 10.820312984000054],
              [-74.98727215499991, 10.820463740000037],
              [-74.9874157669999 , 10.820626404000052],
              [-74.9874285059999 , 10.820640832000038],
              [-74.98743506299991, 10.820641963000071],
              [-74.98762830499993, 10.820675217000087],
              [-74.98783963699992, 10.820564477000062],
              [-74.98794185499992, 10.820477237000091],
              [-74.98808742099993, 10.820393233000061],
              [-74.9882560019999 , 10.820310903000063],
              [-74.98833274999993, 10.82029248200007 ],
              [-74.9883380249999 , 10.82029121700009 ],
              [-74.9884211719999 , 10.820271258000048],
              [-74.98861283499991, 10.82026213000006 ],
              [-74.9887581669999 , 10.820289754000044],
              [-74.98892068599991, 10.82035581200006 ],
              [-74.98908363899994, 10.820416175000048],
              [-74.98928321999995, 10.820524956000042],
              [-74.9894623539999 , 10.82067231800005 ],
              [-74.98956882699991, 10.82075150500009 ],
              [-74.98973174799994, 10.820920576000049],
              [-74.98980539999991, 10.820950956000047],
              [-74.98985773099992, 10.82097254200005 ],
              [-74.9899273659999 , 10.820905951000043],
              [-74.98993287299993, 10.820900682000058],
              [-74.98997516899993, 10.820769240000061],
              [-74.9899813799999 , 10.82065523700004 ],
              [-74.99001210799992, 10.820451424000055],
              [-74.99006553599992, 10.820289287000037],
              [-74.99005668299992, 10.820074033000083],
              [-74.99003084799995, 10.819963212000062],
              [-74.9899647869999 , 10.819812496000054],
              [-74.9899259629999 , 10.819646557000056],
              [-74.9899814449999 , 10.819567538000058],
              [-74.99011138399993, 10.819533963000083],
              [-74.9902964929999 , 10.819570188000057],
              [-74.99073838699991, 10.819724565000058],
              [-74.99105418499994, 10.819790239000042],
              [-74.99107276099994, 10.819791819000045],
              [-74.99125253199992, 10.819807131000061],
              [-74.9913855019999 , 10.819808111000043],
              [-74.99146620199991, 10.819813679000049],
              [-74.99153302899992, 10.819809899000063],
              [-74.9916468369999 , 10.819763444000046],
              [-74.99169188699994, 10.819706560000043],
              [-74.99171212099992, 10.819561996000061],
              [-74.99170502499993, 10.819469859000037],
              [-74.99166726099992, 10.819435640000052],
              [-74.99152866799994, 10.819468611000048],
              [-74.99133715099993, 10.819512089000057],
              [-74.99119961699995, 10.819459236000057],
              [-74.99114998499994, 10.819283845000086],
              [-74.9911173019999 , 10.819149769000092],
              [-74.9910553279999 , 10.818947703000049],
              [-74.99102318399991, 10.818770690000065],
              [-74.99103250399992, 10.818696602000045],
              [-74.9910328599999 , 10.81869379200009 ],
              [-74.99110947699995, 10.818636643000048],
              [-74.99124363599992, 10.818623409000054],
              [-74.9913980739999 , 10.818646         ],
              [-74.99157249899991, 10.81863566900006 ],
              [-74.99175056599995, 10.818579776000092],
              [-74.99193556199992, 10.818581666000057],
              [-74.99208770199994, 10.818632734000062],
              [-74.99216688099995, 10.818697195000084],
              [-74.99223436299991, 10.818752136000057],
              [-74.99236466799994, 10.818858891000048],
              [-74.99257384099991, 10.818919794000067],
              [-74.9926831169999 , 10.818927669000061],
              [-74.9928194279999 , 10.81890172900006 ],
              [-74.99283201299994, 10.81889269900006 ],
              [-74.99297905499992, 10.818787197000063],
              [-74.99308269999995, 10.81857695900004 ],
              [-74.99308185299992, 10.818370637000044],
              [-74.99308175699991, 10.818227382000089],
              [-74.99308466499991, 10.818118729000048],
              [-74.9930845689999 , 10.817975473000047],
              [-74.99300138899991, 10.817865392000044],
              [-74.99286189499992, 10.81787251500009 ],
              [-74.99271389899991, 10.817913401000055],
              [-74.99258443899993, 10.817946479000057],
              [-74.9925222999999 , 10.817935177000038],
              [-74.9924156909999 , 10.817779036000047],
              [-74.99235831399994, 10.81759141300006 ],
              [-74.99237227599991, 10.817489207000051],
              [-74.99240964799992, 10.817420236000089],
              [-74.99246786299994, 10.81734416800009 ],
              [-74.99251551399993, 10.817327547000048],
              [-74.99258643999991, 10.817309738000063],
              [-74.9926591819999 , 10.817269124000063],
              [-74.9926848099999 , 10.817202167000062],
              [-74.99268580199993, 10.817153504000089],
              [-74.99256090699993, 10.81698048000004 ],
              [-74.9924421209999 , 10.816908807000061],
              [-74.9923937499999 , 10.81688093300005 ],
              [-74.99233968099992, 10.816849775000037],
              [-74.99212969399991, 10.816759561000083],
              [-74.99210062399993, 10.816747072000055],
              [-74.99209742499994, 10.816698745000053],
              [-74.99209563399995, 10.81667169800005 ],
              [-74.99212550699991, 10.81663065500004 ],
              [-74.99220215199995, 10.816541568000048],
              [-74.99231946899994, 10.81648696600007 ],
              [-74.99245269999994, 10.816450731000089],
              [-74.99259222799992, 10.816371936000053],
              [-74.99270757499994, 10.816345254000055],
              [-74.99282502999995, 10.81632508000007 ],
              [-74.9929423719999 , 10.816310009000063],
              [-74.99303794899993, 10.816273911000053],
              [-74.99321057899994, 10.81621980500006 ],
              [-74.9932936379999 , 10.816199364000056],
              [-74.99336403399991, 10.816178677000039],
              [-74.99348943699994, 10.816141826000091],
              [-74.99363493699991, 10.816050356000062],
              [-74.9936572009999 , 10.81603636200009 ],
              [-74.99368088199992, 10.815957750000052],
              [-74.99368010899991, 10.81589762100009 ],
              [-74.99367972999994, 10.815868046000048],
              [-74.99371608099995, 10.81577599600007 ],
              [-74.9937624769999 , 10.81573918500004 ],
              [-74.99391519599993, 10.815718006000054],
              [-74.99405756999994, 10.81567668300005 ],
              [-74.99419646199993, 10.815611437000086],
              [-74.99422314799995, 10.815594670000053],
              [-74.99435205999993, 10.815513673000055],
              [-74.9943788409999 , 10.81536563700007 ],
              [-74.99450526399994, 10.815274127000066],
              [-74.99466231899993, 10.81516044500006 ],
              [-74.99495936999995, 10.815256945000044],
              [-74.99497242399991, 10.81525500500004 ],
              [-74.99525939599994, 10.815222034000044],
              [-74.99539235299994, 10.815190063000045],
              [-74.99584461899991, 10.815151877000062],
              [-74.9963820079999 , 10.815102916000058],
              [-74.9965523329999 , 10.815107664000038],
              [-74.99680766599994, 10.815064808000045],
              [-74.99730778599991, 10.815010692000044],
              [-74.9977124269999 , 10.81506209400004 ],
              [-74.9980694759999 , 10.815213612000036],
              [-74.99830434499995, 10.81543915800006 ],
              [-74.99844902899991, 10.815759680000042],
              [-74.9984448909999 , 10.815894692000086],
              [-74.99843935299992, 10.816075411000043],
              [-74.99841455399991, 10.816664786000047],
              [-74.99844188999992, 10.816901476000055],
              [-74.9984537169999 , 10.816925736000087],
              [-74.99855972299991, 10.817143160000057],
              [-74.99864524799995, 10.817263919000084],
              [-74.99867226499993, 10.817395379000061],
              [-74.99864299799992, 10.81744304700004 ],
              [-74.99861401099992, 10.817490260000056],
              [-74.99846535799992, 10.817611727000042],
              [-74.99830586999991, 10.817670083000053],
              [-74.99813041999994, 10.817728489000046],
              [-74.9980187189999 , 10.817749871000046],
              [-74.99786437699993, 10.817750333000049],
              [-74.99769988899993, 10.817913932000067],
              [-74.9976317199999 , 10.818245622000063],
              [-74.9976323489999 , 10.818450821000056],
              [-74.99768066999991, 10.818587482000055],
              [-74.99769092299994, 10.818596742000068],
              [-74.99787813899991, 10.81876578300006 ],
              [-74.99805982099991, 10.819002013000045],
              [-74.9980922709999 , 10.819170284000052],
              [-74.99803971099993, 10.81938616900004 ],
              [-74.99769122299995, 10.820292215000052],
              [-74.99668303999994, 10.823020763000045],
              [-74.99626136499995, 10.824178059000076],
              [-74.9960919159999 , 10.824643116000061],
              [-74.99606856199995, 10.824925344000064],
              [-74.99602006399994, 10.825511498000083],
              [-74.99608599699991, 10.82618479000007 ],
              [-74.99615680199992, 10.826710737000042],
              [-74.99619450999995, 10.826857954000047],
              [-74.99636114899994, 10.827394138000045],
              [-74.99599458499995, 10.827616225000043],
              [-74.99589371799993, 10.827700715000049],
              [-74.99590492399994, 10.827884839000092],
              [-74.99606591799994, 10.82831581000005 ],
              [-74.9960717749999 , 10.828489425000043],
              [-74.99600376199993, 10.82887372500005 ],
              [-74.99595568399991, 10.829053040000076],
              [-74.99594587799993, 10.829089625000051],
              [-74.99601305199991, 10.829153093000059],
              [-74.99622344599993, 10.829351874000054],
              [-74.99684201899993, 10.829734117000044],
              [-74.99719400999993, 10.82996457300004 ],
              [-74.99752515799992, 10.830342417000054],
              [-74.99818078399994, 10.830656143000056],
              [-74.99896432899993, 10.831027362000043],
              [-74.99911262499995, 10.830790144000048],
              [-74.99921857299995, 10.83062671500005 ],
              [-74.99934591699991, 10.830500054000083],
              [-74.99943617399992, 10.830426120000084],
              [-74.99963310299995, 10.830425526000056],
              [-75.00013366399992, 10.830508207000037],
              [-75.00037864099994, 10.830554824000046],
              [-75.00083111099991, 10.830574506000062],
              [-75.0014698409999 , 10.830588369000054],
              [-75.00183167099993, 10.830555704000062],
              [-75.00198067699995, 10.830549996000059],
              [-75.00215641699992, 10.830581033000044],
              [-75.00281116899993, 10.830610628000045],
              [-75.0033596639999 , 10.830703680000056],
              [-75.00370075699993, 10.830849973000056],
              [-75.00475647699994, 10.831457129000057],
              [-75.00579117299992, 10.832148529000051],
              [-75.00650602799993, 10.832677784000055],
              [-75.0070932079999 , 10.833228470000051],
              [-75.00751475099992, 10.833741370000041],
              [-75.00752824799991, 10.83375778900006 ],
              [-75.00752846499995, 10.833758054000043],
              [-75.00767555899995, 10.833937021000054],
              [-75.00768681599993, 10.833945868000058],
              [-75.00780597399995, 10.834039511000071],
              [-75.00783151699994, 10.834098071000085],
              [-75.0081236769999 , 10.834767871000054],
              [-75.00811469899992, 10.83480385200005 ],
              [-75.0080036139999 , 10.835025176000045],
              [-75.00774905199995, 10.835320600000045],
              [-75.00755292199995, 10.835579016000054],
              [-75.00729827799995, 10.835848130000045],
              [-75.00711275799995, 10.836095989000057],
              [-75.00702831399991, 10.83632775600006 ],
              [-75.00701300399993, 10.836538266000048],
              [-75.00689729899995, 10.836985852000055],
              [-75.00670820399995, 10.83779671700006 ],
              [-75.0065874519999 , 10.838333767000051],
              [-75.00663728299992, 10.838954484000055],
              [-75.00675516399991, 10.839206683000043],
              [-75.00676981299995, 10.839223873000037],
              [-75.00694747699993, 10.83943234700007 ],
              [-75.00732653799992, 10.839804772000036],
              [-75.00754025799995, 10.840067202000057],
              [-75.0076793799999 , 10.840303554000059],
              [-75.00767975799994, 10.840308509000067],
              [-75.00770712099995, 10.840666522000049],
              [-75.00755621899992, 10.84177717700004 ],
              [-75.00737764099995, 10.842603155000063],
              [-75.00734107999995, 10.842772274000083],
              [-75.00726217599993, 10.84307242400007 ],
              [-75.00710930999992, 10.843551693000052],
              [-75.00710880999992, 10.843557226000087],
              [-75.00709015199993, 10.843763926000065],
              [-75.00704756399995, 10.84423589100004 ],
              [-75.00703997599993, 10.844277864000048],
              [-75.00700570299995, 10.844467527000063],
              [-75.0069955319999 , 10.844620147000057],
              [-75.00704584899995, 10.844613081000091],
              [-75.00711550199992, 10.844603300000074],
              [-75.00731110599992, 10.84457583200009 ],
              [-75.00741254699994, 10.844561586000054],
              [-75.00753124399995, 10.844557269000063],
              [-75.00760847299995, 10.84455446000004 ],
              [-75.00774580399991, 10.844549465000057],
              [-75.0081240259999 , 10.844648285000062],
              [-75.00833432899992, 10.84473829600006 ],
              [-75.00850243999992, 10.84481024300004 ],
              [-75.0088649029999 , 10.84497751300006 ],
              [-75.00917423899995, 10.845176514000059],
              [-75.00930239299993, 10.845307667000043],
              [-75.00952237399991, 10.845869984000046],
              [-75.00968310399992, 10.846206237000047],
              [-75.00975209899991, 10.846288134000076],
              [-75.00981132099992, 10.846358432000045],
              [-75.01016269999991, 10.846383672000059],
              [-75.01047138099995, 10.846372207000059],
              [-75.0106256709999 , 10.846350692000044],
              [-75.01073744399991, 10.84635034900009 ],
              [-75.01078029099995, 10.846434404000092],
              [-75.01077413399992, 10.846564123000064],
              [-75.0107706579999 , 10.846637372000089],
              [-75.01077030099992, 10.846644901000047],
              [-75.01070697299991, 10.846818725000048],
              [-75.0106175219999 , 10.84715047800006 ],
              [-75.01050793099995, 10.847850605000076],
              [-75.0105091289999 , 10.848234698000056],
              [-75.0104514009999 , 10.848497953000049],
              [-75.01042001299993, 10.848687718000065],
              [-75.0104239819999 , 10.849254182000038],
              [-75.01042487599995, 10.84938180100005 ],
              [-75.01042777599991, 10.849455637000062],
              [-75.01036452599993, 10.849655768000048],
              [-75.01030150399993, 10.84992956800005 ],
              [-75.01021159599992, 10.850113996000061],
              [-75.01016950299993, 10.850271970000051],
              [-75.01017527399995, 10.850414016000059],
              [-75.01009701999993, 10.850924631000055],
              [-75.01007635399992, 10.851124635000076],
              [-75.01010348099993, 10.85128766300005 ],
              [-75.01014332599993, 10.851423934000081],
              [-75.01015728199991, 10.851471657000047],
              [-75.01011266899991, 10.851505278000047],
              [-75.00998203799992, 10.851603727000054],
              [-75.0099130989999 , 10.851655166000057],
              [-75.00987617599992, 10.851682717000074],
              [-75.00976962099992, 10.851762222000048],
              [-75.00965827199991, 10.851899367000044],
              [-75.00960009699992, 10.852020559000039],
              [-75.00957406099991, 10.852204795000091],
              [-75.00957448899993, 10.852341594000052],
              [-75.00961321299991, 10.852369252000074],
              [-75.00986789599995, 10.852551162000054],
              [-75.01002808599992, 10.85271378400006 ],
              [-75.01024841299994, 10.85290240900008 ],
              [-75.01036906599995, 10.853005701000086],
              [-75.01054805399991, 10.853158937000046],
              [-75.01133435399993, 10.85311080100007 ],
              [-75.01223226799993, 10.853055832000052],
              [-75.0127356669999 , 10.853019270000061],
              [-75.01314744599995, 10.852927614000066],
              [-75.0135054669999 , 10.852846680000084],
              [-75.01378081899992, 10.852784433000068],
              [-75.01385237699992, 10.852827798000078],
              [-75.0146787139999 , 10.853328566000073],
              [-75.0148642069999 , 10.853440977000048],
              [-75.01583329099992, 10.854460565000068],
              [-75.01603673199992, 10.854673484000045],
              [-75.01618421799992, 10.854827840000041],
              [-75.01774836399994, 10.855696439000042],
              [-75.01792356999994, 10.855796467000062],
              [-75.01797920999991, 10.855804030000058],
              [-75.02056101499994, 10.85576960800006 ],
              [-75.02090774499993, 10.85563127800009 ],
              [-75.02139558699992, 10.855436650000058],
              [-75.02191791099995, 10.855228264000061],
              [-75.02289156199993, 10.855225271000052],
              [-75.02370726799995, 10.855401825000058],
              [-75.02405535199995, 10.855332730000043],
              [-75.02433195499992, 10.855277823000051],
              [-75.02474891799994, 10.855421009000054],
              [-75.02551891799993, 10.85546339800004 ],
              [-75.0261972269999 , 10.855147957000042],
              [-75.02653601799994, 10.854878317000043],
              [-75.02653670099994, 10.854877552000062],
              [-75.02668505699995, 10.855016251000052],
              [-75.02690499199991, 10.855221868000058],
              [-75.02711256099991, 10.855408728000043],
              [-75.02721339999994, 10.85549672600007 ],
              [-75.0275346279999 , 10.855764299000043],
              [-75.0276160169999 , 10.85585031000005 ],
              [-75.0276860589999 , 10.855945875000089],
              [-75.02778711699995, 10.856113633000064],
              [-75.02784104499995, 10.856217400000048],
              [-75.02788613899992, 10.856326418000037],
              [-75.02791958499995, 10.856444124000063],
              [-75.02794245599995, 10.856566979000092],
              [-75.02795769199992, 10.856693542000073],
              [-75.02796744699992, 10.856822692000037],
              [-75.02797335799994, 10.856953649000047],
              [-75.02797798499995, 10.857218824000086],
              [-75.0279775169999 , 10.857486236000057],
              [-75.0279638419999 , 10.858561049000059],
              [-75.0279616439999 , 10.85896370000006 ],
              [-75.02796679199992, 10.859229926000069],
              [-75.02797365499993, 10.859361413000045],
              [-75.02798515299992, 10.85949105900005 ],
              [-75.02800324999993, 10.859618001000058],
              [-75.02803055399994, 10.859741068000062],
              [-75.02807049799992, 10.85985869600006 ],
              [-75.02812465199992, 10.859967228000073],
              [-75.0282020599999 , 10.860083778000046],
              [-75.02844812599994, 10.859962081000049],
              [-75.02846184999993, 10.859703313000068],
              [-75.02891236699992, 10.859615674000054],
              [-75.02933420399995, 10.859657488000039],
              [-75.02940773099994, 10.859915997000087],
              [-75.02940859699993, 10.860189103000039],
              [-75.02939492399992, 10.860462252000048],
              [-75.02961322399995, 10.860519072000045],
              [-75.03000625199991, 10.860647234000055],
              [-75.03042809499993, 10.860689032000039],
              [-75.0306316349999 , 10.860674034000056],
              [-75.03106783899995, 10.860658303000037],
              [-75.03135893799993, 10.860743652000053],
              [-75.03156303399993, 10.860901125000055],
              [-75.03165100299992, 10.861130844000058],
              [-75.0318260979999 , 10.861317160000056],
              [-75.03194275099992, 10.861417415000062],
              [-75.0320772739999 , 10.861510470000042],
              [-75.03240821299994, 10.861459094000054],
              [-75.03258198099991, 10.861228570000037],
              [-75.03274925099993, 10.860966936000068],
              [-75.03278455299994, 10.860911719000057],
              [-75.03301699399992, 10.860839123000062],
              [-75.03306513899992, 10.860868203000052],
              [-75.03314610199993, 10.860917105000055],
              [-75.03320640299995, 10.86095352700005 ],
              [-75.03335254799993, 10.861183052000058],
              [-75.03336827899994, 10.861556732000054],
              [-75.0334129069999 , 10.861872823000056],
              [-75.03350275999992, 10.862049074000083],
              [-75.03354460199995, 10.862131143000056],
              [-75.03373437999994, 10.862360540000054],
              [-75.0339094229999 , 10.862532483000052],
              [-75.0340699699999 , 10.862718849000089],
              [-75.03425215999994, 10.862866335000092],
              [-75.03426110999993, 10.862868323000043],
              [-75.03437968499992, 10.862647714000047],
              [-75.03430218299991, 10.86237525000007 ],
              [-75.03427958499992, 10.862295804000041],
              [-75.0344569419999 , 10.862169745000074],
              [-75.03445841299992, 10.862168703000066],
              [-75.03469435599993, 10.86200100700006 ],
              [-75.03517995099992, 10.862163528000053],
              [-75.03525085399991, 10.862187258000063],
              [-75.03529133199993, 10.862200804000054],
              [-75.03543743599994, 10.862249701000053],
              [-75.03544740399991, 10.862253035000037],
              [-75.03544212899993, 10.86226128800007 ],
              [-75.03541452799993, 10.862379200000078],
              [-75.03541367699995, 10.862382842000045],
              [-75.03531884599994, 10.86250119400006 ],
              [-75.03528012399994, 10.862562275000073],
              [-75.03524523199991, 10.862617313000044],
              [-75.03522002299991, 10.862687685000083],
              [-75.03517358499994, 10.862817299000085],
              [-75.0351417629999 , 10.863066382000056],
              [-75.03512770799995, 10.863299509000058],
              [-75.03511873699995, 10.863448276000042],
              [-75.03514679199992, 10.863639719000048],
              [-75.03547312199993, 10.86411187400006 ],
              [-75.03622185599994, 10.864331371000048],
              [-75.03628732499993, 10.864322722000054],
              [-75.03634013299995, 10.864315745000056],
              [-75.03652222799991, 10.864183813000068],
              [-75.03659342799995, 10.86407932700007 ],
              [-75.03663429499994, 10.863909482000054],
              [-75.03659519299993, 10.863706654000055],
              [-75.0365812309999 , 10.863683295000044],
              [-75.03654554699995, 10.863623571000062],
              [-75.03652797799992, 10.86350135400005 ],
              [-75.03649994799991, 10.863306399000066],
              [-75.03649428099993, 10.863253866000036],
              [-75.03644929399991, 10.862836866000066],
              [-75.0364628179999 , 10.862775297000042],
              [-75.03655222099991, 10.862676357000055],
              [-75.03697229399995, 10.86244395400007 ],
              [-75.03730508699994, 10.862426472000038],
              [-75.03736814599995, 10.862423159000059],
              [-75.03756917399994, 10.862611214000083],
              [-75.0376488739999 , 10.862685769000052],
              [-75.03769366699993, 10.862727672000062],
              [-75.0377233239999 , 10.862755414000048],
              [-75.03779246399995, 10.862755202000073],
              [-75.03797156199994, 10.86317901700005 ],
              [-75.03816272799992, 10.86358652000007 ],
              [-75.03817752999993, 10.86361807500009 ],
              [-75.0382917789999 , 10.863929128000052],
              [-75.03832655799994, 10.86422411500007 ],
              [-75.03833971199992, 10.864393995000057],
              [-75.03840870099992, 10.864600134000057],
              [-75.03845079399991, 10.864728636000052],
              [-75.03851925899994, 10.864765091000038],
              [-75.03869141099995, 10.864676547000045],
              [-75.03878641099993, 10.864593372000058],
              [-75.03883257799993, 10.864495917000056],
              [-75.0388403369999 , 10.864480187000083],
              [-75.0388686849999 , 10.86442272100004 ],
              [-75.0389449729999 , 10.86432906400006 ],
              [-75.03902352299991, 10.864302973000065],
              [-75.0391262409999 , 10.864357655000049],
              [-75.0392138599999 , 10.864487031000067],
              [-75.03921960499991, 10.864504602000068],
              [-75.03926470999994, 10.864642529000037],
              [-75.03927696099993, 10.86483901400004 ],
              [-75.03929904599994, 10.865250254000046],
              [-75.03945153899991, 10.865985066000064],
              [-75.03954581099993, 10.866161224000052],
              [-75.0396594799999 , 10.866394979000063],
              [-75.0397007059999 , 10.86645519600006 ],
              [-75.03977309699991, 10.866541538000092],
              [-75.03989428599994, 10.866662775000066],
              [-75.03990260299992, 10.86667109800004 ],
              [-75.04013864299992, 10.866672467000058],
              [-75.04048343399995, 10.866717622000067],
              [-75.04060000599992, 10.866718414000047],
              [-75.04073350099992, 10.866706235000038],
              [-75.04090177799992, 10.866641540000046],
              [-75.04103026699994, 10.866603178000048],
              [-75.04111258099994, 10.866585692000058],
              [-75.04125183699995, 10.866531846000044],
              [-75.04133965699992, 10.866469748000043],
              [-75.0414677199999 , 10.86642551500006 ],
              [-75.04149058999991, 10.86642558400007 ],
              [-75.04149061599992, 10.866425585000059],
              [-75.04159028799995, 10.866425881000055],
              [-75.04168748399991, 10.86643508800006 ],
              [-75.04180730999991, 10.866515269000047],
              [-75.04190077499993, 10.866616528000066],
              [-75.04190197799994, 10.866617828000074],
              [-75.04191842799992, 10.866714011000056],
              [-75.04195820699994, 10.866802678000056],
              [-75.04201287599994, 10.866813654000055],
              [-75.0423365879999 , 10.866928176000044],
              [-75.04252763999995, 10.86696217900004 ],
              [-75.04262928799994, 10.866942289000065],
              [-75.04263455199992, 10.866941262000068],
              [-75.0427846849999 , 10.866910246000089],
              [-75.04285425899991, 10.866881842000055],
              [-75.04294512799993, 10.866857874000061],
              [-75.04308588299995, 10.866859955000052],
              [-75.04314045299992, 10.866886074000092],
              [-75.04316667499995, 10.866898624000044],
              [-75.04319994399992, 10.86693000200006 ],
              [-75.04322191099993, 10.86705831100005 ],
              [-75.04318869399992, 10.867208123000069],
              [-75.04318320399994, 10.867214841000077],
              [-75.04303026799994, 10.867401980000068],
              [-75.0428372799999 , 10.867574603000037],
              [-75.04277216899993, 10.867666392000046],
              [-75.0427294559999 , 10.867726603000051],
              [-75.04265390199993, 10.867908828000054],
              [-75.04260260199993, 10.868095290000042],
              [-75.04260463799994, 10.868236773000092],
              [-75.04262201999995, 10.868300410000074],
              [-75.04263390999995, 10.868343932000073],
              [-75.04266426299995, 10.868379337000079],
              [-75.04268751799992, 10.868406459000084],
              [-75.04277312599993, 10.868430083000078],
              [-75.04287246399991, 10.868438014000048],
              [-75.04304469199991, 10.868409780000093],
              [-75.04307238899992, 10.868421768000076],
              [-75.04310352699991, 10.868435245000057],
              [-75.04313874699994, 10.86848548200004 ],
              [-75.04318533299994, 10.868551926000066],
              [-75.04319493299994, 10.868713610000043],
              [-75.04312627399992, 10.868867736000084],
              [-75.04309049199992, 10.868948057000068],
              [-75.04304975499991, 10.869005490000063],
              [-75.04294012999992, 10.869160044000068],
              [-75.04289904199993, 10.869217972000058],
              [-75.0426977809999 , 10.869323253000061],
              [-75.0425877639999 , 10.869406431000073],
              [-75.04247455299992, 10.869492019000063],
              [-75.0423250099999 , 10.869636816000082],
              [-75.04224521799995, 10.869714075000047],
              [-75.0422195509999 , 10.86980733200005 ],
              [-75.0422657869999 , 10.869863205000058],
              [-75.04236970199992, 10.869968267000047],
              [-75.04248718799994, 10.87000590000008 ],
              [-75.04262455299994, 10.870079175000058],
              [-75.04268383899995, 10.870066803000043],
              [-75.04277178799992, 10.870048448000091],
              [-75.04293987999995, 10.870016303000057],
              [-75.04296298899993, 10.870010980000075],
              [-75.04303103099994, 10.869995308000057],
              [-75.04306843099994, 10.869995294000091],
              [-75.04311782899993, 10.869995275000065],
              [-75.04320783399993, 10.870074757000054],
              [-75.04331202199995, 10.870259580000038],
              [-75.04336329299991, 10.870378351000056],
              [-75.0433669809999 , 10.870386893000045],
              [-75.04338507599994, 10.870428812000057],
              [-75.04348861199992, 10.87070049600004 ],
              [-75.04378464299992, 10.871117541000046],
              [-75.04403329299993, 10.87138720200005 ],
              [-75.04417793099992, 10.871474882000086],
              [-75.04420447299992, 10.871485262000078],
              [-75.04422649399993, 10.871493876000045],
              [-75.04425517499993, 10.871505092000064],
              [-75.04429598099995, 10.871502320000047],
              [-75.04439822699993, 10.871495369000058],
              [-75.0445002379999 , 10.87145888100008 ],
              [-75.04455222799993, 10.871407312000088],
              [-75.04456104899992, 10.871398561000092],
              [-75.04456420499992, 10.871395432000043],
              [-75.04457902599995, 10.871370846000048],
              [-75.04463849599995, 10.871272181000052],
              [-75.04472950999991, 10.871248268000045],
              [-75.04482031399994, 10.871259824000049],
              [-75.04486823799994, 10.87129793400004 ],
              [-75.04488038799991, 10.871376894000036],
              [-75.04483132299993, 10.87147775200009 ],
              [-75.04478527599991, 10.871570164000047],
              [-75.0447795039999 , 10.871581750000075],
              [-75.04477949799991, 10.871587796000085],
              [-75.04477943499995, 10.871645276000038],
              [-75.04480488799993, 10.87170265900005 ],
              [-75.04484501899992, 10.871717666000052],
              [-75.04489362999993, 10.871722636000072],
              [-75.0449144989999 , 10.871724769000082],
              [-75.0449600309999 , 10.871718357000077],
              [-75.04503604299993, 10.87170765400009 ],
              [-75.04512066299992, 10.871639836000043],
              [-75.04523946599994, 10.871549009000091],
              [-75.0452894419999 , 10.871519197000055],
              [-75.04535688899995, 10.871478962000083],
              [-75.0454929149999 , 10.87141650500007 ],
              [-75.04573651099992, 10.871272849000093],
              [-75.04581624799994, 10.871237717000042],
              [-75.04588723699993, 10.871206438000058],
              [-75.04601203699991, 10.871192035000092],
              [-75.04615887499995, 10.871190912000088],
              [-75.04629862399992, 10.871200596000051],
              [-75.0464583779999 , 10.871273970000061],
              [-75.0464789859999 , 10.871278208000092],
              [-75.0465772579999 , 10.871298408000087],
              [-75.04684031999994, 10.871401765000087],
              [-75.0469535069999 , 10.87150644400009 ],
              [-75.04697569399991, 10.871537409000041],
              [-75.04701412799994, 10.871591050000063],
              [-75.04705598699991, 10.87176712300004 ],
              [-75.04707621499995, 10.871872221000046],
              [-75.04708699199995, 10.871890791000055],
              [-75.04714557299991, 10.871991734000062],
              [-75.04720297799992, 10.872035166000046],
              [-75.0472700329999 , 10.872086824000064],
              [-75.04746799799995, 10.872200598000063],
              [-75.04762326899993, 10.872305444000062],
              [-75.04777402999991, 10.872376614000075],
              [-75.04783375699992, 10.872411034000038],
              [-75.04793553099995, 10.872484021000048],
              [-75.0479383949999 , 10.87248622800007 ],
              [-75.04806971099993, 10.872587369000087],
              [-75.04816850299994, 10.872583648000045],
              [-75.04827302199993, 10.872576577000075],
              [-75.04834679099991, 10.872571582000091],
              [-75.04835665599995, 10.87257091500004 ],
              [-75.04841107899995, 10.872460709000052],
              [-75.04832395699992, 10.872306828000092],
              [-75.04830284499991, 10.872282373000075],
              [-75.04826121699995, 10.87223415200009 ],
              [-75.04825723699992, 10.872164411000085],
              [-75.04825314199991, 10.872092678000058],
              [-75.0482969979999 , 10.872000942000057],
              [-75.04837597599993, 10.871821028000056],
              [-75.04845201199993, 10.871756694000055],
              [-75.0485197889999 , 10.87174026200006 ],
              [-75.04881214699992, 10.871724751000045],
              [-75.04882000499993, 10.871724335000067],
              [-75.04891010999995, 10.871801073000086],
              [-75.04898300999992, 10.871926337000048],
              [-75.0490386649999 , 10.872061648000056],
              [-75.04904678899993, 10.872280102000047],
              [-75.04892795399991, 10.87255009900008 ],
              [-75.04890906199995, 10.872575750000067],
              [-75.04879599499992, 10.87272925800005 ],
              [-75.04877597399991, 10.87275455400004 ],
              [-75.04876855599991, 10.872763926000061],
              [-75.04866480499993, 10.872895004000043],
              [-75.04863724899991, 10.872964934000038],
              [-75.04864232799991, 10.873068183000044],
              [-75.04871856299991, 10.873182984000039],
              [-75.04880156799993, 10.873245465000082],
              [-75.04889100899993, 10.873313436000046],
              [-75.0489211599999 , 10.873357614000042],
              [-75.04893645599992, 10.87338002100006 ],
              [-75.04900179499992, 10.873523638000052],
              [-75.0490457439999 , 10.873628302000043],
              [-75.04906828999992, 10.873681994000037],
              [-75.04911865899993, 10.87374965500004 ],
              [-75.04914858599994, 10.873813189000089],
              [-75.0491727289999 , 10.873864445000038],
              [-75.04913727699994, 10.873972062000064],
              [-75.0490441689999 , 10.874010898000051],
              [-75.04904043199991, 10.874010800000065],
              [-75.04891770699993, 10.874007567000092],
              [-75.04873676599993, 10.873960539000052],
              [-75.04864731299995, 10.873917813000048],
              [-75.04859007999994, 10.873890476000042],
              [-75.04847743499994, 10.873871425000061],
              [-75.04836710699993, 10.87388181700004 ],
              [-75.04827528299995, 10.873922151000045],
              [-75.04823197099995, 10.87400659800005 ],
              [-75.04821017599994, 10.87415018300004 ],
              [-75.04818840599995, 10.87440923400004 ],
              [-75.04822700499994, 10.874506698000062],
              [-75.04825579899995, 10.874579401000062],
              [-75.04833695899993, 10.874618361000046],
              [-75.04844073499993, 10.874647518000074],
              [-75.04844754099992, 10.87464943100008 ],
              [-75.04845729099992, 10.87465846300006 ],
              [-75.0485220509999 , 10.87471845500005 ],
              [-75.04856274899993, 10.874728308000044],
              [-75.04859861799991, 10.874736993000056],
              [-75.04868620999991, 10.874742956000091],
              [-75.04890549299995, 10.874765403000083],
              [-75.04890690799994, 10.874765396000043],
              [-75.04896931399992, 10.87476517500005 ],
              [-75.04899862199994, 10.874765073000049],
              [-75.04907614899992, 10.874833910000063],
              [-75.0491014669999 , 10.87488849500005 ],
              [-75.04908198899994, 10.875100090000046],
              [-75.04903490399994, 10.875304663000065],
              [-75.04903263999995, 10.87531037000008 ],
              [-75.04896059399994, 10.875491990000057],
              [-75.04887302899994, 10.875678548000053],
              [-75.04887811899994, 10.875781888000063],
              [-75.04894391599993, 10.875854501000049],
              [-75.04907021399993, 10.875931965000063],
              [-75.04914542699993, 10.875932858000056],
              [-75.04920845999993, 10.875931607000041],
              [-75.0492831649999 , 10.875987560000056],
              [-75.04932459399993, 10.87605590000004 ],
              [-75.04931772099991, 10.87616005500007 ],
              [-75.0492349459999 , 10.876254470000049],
              [-75.04909097699993, 10.876373710000053],
              [-75.0490126919999 , 10.87639547300006 ],
              [-75.04887609399992, 10.876531999000065],
              [-75.04878884899995, 10.87663139600005 ],
              [-75.0487054059999 , 10.87679399700005 ],
              [-75.04869645699995, 10.876871638000068],
              [-75.04862646799995, 10.87703805700005 ],
              [-75.04859810199991, 10.877160226000058],
              [-75.04859399299994, 10.877261262000047],
              [-75.04862338499993, 10.87737411900008 ],
              [-75.04863268299994, 10.877409817000057],
              [-75.0486843729999 , 10.87751780800005 ],
              [-75.04876192799992, 10.877586742000062],
              [-75.04884547199993, 10.87765528600005 ],
              [-75.04892372299992, 10.877733074000048],
              [-75.04892809899991, 10.877737723000052],
              [-75.04902868599993, 10.877844665000055],
              [-75.04911431999994, 10.87793976000006 ],
              [-75.04917371599993, 10.87800696000005 ],
              [-75.04926766099993, 10.878169692000085],
              [-75.04934020999991, 10.87832792000006 ],
              [-75.04934509599991, 10.878466937000042],
              [-75.04928155399995, 10.878639001000067],
              [-75.04920332699993, 10.878715340000042],
              [-75.0491165869999 , 10.878798162000066],
              [-75.04906414099992, 10.878857972000048],
              [-75.0490446199999 , 10.878992681000057],
              [-75.04906029599994, 10.879077619000043],
              [-75.04911833199992, 10.879204239000046],
              [-75.04919178199992, 10.879297227000052],
              [-75.04940035499993, 10.87943090500005 ],
              [-75.0497623789999 , 10.879693417000055],
              [-75.05000275599991, 10.879776115000084],
              [-75.05025217699995, 10.879740208000044],
              [-75.0504308589999 , 10.879721256000039],
              [-75.05062372399993, 10.87973075900004 ],
              [-75.0507710569999 , 10.87977309300004 ],
              [-75.05092810999992, 10.879785214000037],
              [-75.05114402599992, 10.879781251000054],
              [-75.0512659069999 , 10.879725153000038],
              [-75.05128204099992, 10.879700372000059],
              [-75.05142194899992, 10.879490272000055],
              [-75.0516256649999 , 10.879245223000055],
              [-75.05182743699993, 10.87905341000004 ],
              [-75.05196330299992, 10.878943133000064],
              [-75.05209632099991, 10.878874375000066],
              [-75.0522508329999 , 10.878851263000058],
              [-75.05238322699995, 10.878853372000037],
              [-75.0524405999999 , 10.878896387000054],
              [-75.05242540099994, 10.879092289000084],
              [-75.05230395099994, 10.879313658000058],
              [-75.05220133599994, 10.879545441000062],
              [-75.05218596399993, 10.879818071000045],
              [-75.0521909439999 , 10.880041990000052],
              [-75.05215781899994, 10.880239170000038],
              [-75.05217163599991, 10.880421130000059],
              [-75.05227161699992, 10.880591386000049],
              [-75.05239064199992, 10.880695357000093],
              [-75.05246461399992, 10.880719489000057],
              [-75.05263006699994, 10.880760581000061],
              [-75.05273458499994, 10.880711646000066],
              [-75.05283152599992, 10.880562897000061],
              [-75.05289456499992, 10.88036346000007 ],
              [-75.05290466699995, 10.880179687000066],
              [-75.05292317399994, 10.879948072000047],
              [-75.05298710199992, 10.879760376000092],
              [-75.05307540599995, 10.879576789000055],
              [-75.0531867389999 , 10.879379707000055],
              [-75.05326290399995, 10.87927374800006 ],
              [-75.0533667489999 , 10.879136250000045],
              [-75.05345434899994, 10.878803878000042],
              [-75.05348673499992, 10.878636153000059],
              [-75.0534890159999 , 10.87854739100004 ],
              [-75.05346800799992, 10.878389476000052],
              [-75.05342226899995, 10.87818317500006 ],
              [-75.05338956799994, 10.87797011500004 ],
              [-75.05338287399991, 10.877604427000051],
              [-75.05345238299992, 10.87721246700005 ],
              [-75.0535266899999 , 10.87712134900005 ],
              [-75.05362162499995, 10.877064162000067],
              [-75.05370194999995, 10.877052338000055],
              [-75.0538050049999 , 10.877041857000052],
              [-75.05391271799994, 10.877033934000053],
              [-75.05410171999995, 10.877064444000041],
              [-75.05424925999995, 10.877222116000041],
              [-75.05438628299993, 10.87738052800006 ],
              [-75.05444886599992, 10.877491158000055],
              [-75.0545161359999 , 10.877576781000073],
              [-75.05453884299993, 10.877605688000074],
              [-75.05460850499992, 10.877650797000058],
              [-75.0546097109999 , 10.877650652000057],
              [-75.0546711529999 , 10.877643240000054],
              [-75.05474632099993, 10.87760226000006 ],
              [-75.0547980849999 , 10.877530498000056],
              [-75.05486510199995, 10.877456950000067],
              [-75.05514616399995, 10.877707164000071],
              [-75.0552669779999 , 10.878265462000059],
              [-75.05493541999994, 10.880082610000045],
              [-75.05460241499992, 10.881454992000045],
              [-75.05449366499994, 10.88260428600006 ],
              [-75.05453622499994, 10.884160798000039],
              [-75.05466552299993, 10.884912624000037],
              [-75.0547655659999 , 10.885494337000068],
              [-75.05507703399991, 10.885733671000082],
              [-75.05543977999992, 10.885930771000062],
              [-75.05548926599994, 10.885957659000042],
              [-75.05545385699992, 10.886023221000073],
              [-75.05563019099992, 10.886158717000058],
              [-75.05626213599993, 10.886308007000082],
              [-75.05641660899994, 10.886344499000074],
              [-75.05647594399994, 10.886358516000087],
              [-75.05684488499992, 10.886445672000093],
              [-75.05686859199994, 10.886451272000045],
              [-75.05687762399992, 10.886450798000055],
              [-75.05836827799993, 10.88637235400006 ],
              [-75.05937331199993, 10.885904868000068],
              [-75.05949152199992, 10.885849882000059],
              [-75.0595082989999 , 10.885830211000041],
              [-75.05959877599992, 10.885724120000077],
              [-75.06053744699994, 10.884623460000057],
              [-75.0610188469999 , 10.882768768000062],
              [-75.06102639599993, 10.882742505000067],
              [-75.06105706199992, 10.882635848000064],
              [-75.06179741699992, 10.880060688000071],
              [-75.06213148099994, 10.87902185200005 ],
              [-75.06251135699995, 10.878349206000053],
              [-75.06255422599992, 10.878372862000049],
              [-75.06263299499994, 10.878051004000042],
              [-75.0626455009999 , 10.877999903000045],
              [-75.06271304799992, 10.87772390300006 ],
              [-75.06272143099994, 10.877689647000068],
              [-75.0628331389999 , 10.877653328000065],
              [-75.06292611299995, 10.877623106000044],
              [-75.0629901659999 , 10.877627472000086],
              [-75.06306883199994, 10.877632839000057],
              [-75.06313119799995, 10.877611172000059],
              [-75.06320486099992, 10.877585581000062],
              [-75.06325655099994, 10.87754738500007 ],
              [-75.06332657899992, 10.877547161000052],
              [-75.06339070699994, 10.87754695600006 ],
              [-75.06346190599993, 10.877509027000087],
              [-75.06357970699992, 10.87749247100004 ],
              [-75.06369508499995, 10.877476253000054],
              [-75.06379076899992, 10.877481622000062],
              [-75.06387779599993, 10.877459822000048],
              [-75.0639803169999 , 10.877434140000048],
              [-75.06404446599993, 10.877440273000047],
              [-75.06407576399994, 10.877526033000038],
              [-75.06411892099993, 10.877565737000054],
              [-75.06419902899995, 10.877639438000074],
              [-75.06429041199993, 10.877723511000056],
              [-75.06436640699991, 10.87783539500009 ],
              [-75.06443729199992, 10.877939757000092],
              [-75.06453058999995, 10.878034532000072],
              [-75.06464385399994, 10.878197477000072],
              [-75.06469407999992, 10.87832347400007 ],
              [-75.0647403399999 , 10.878439524000044],
              [-75.06479206999995, 10.878569298000059],
              [-75.06478631099992, 10.878651128000058],
              [-75.06482127099991, 10.87867379100004 ],
              [-75.06486316599995, 10.878700949000063],
              [-75.06488204699991, 10.87875531900005 ],
              [-75.0649026559999 , 10.878814661000092],
              [-75.06493028599994, 10.87889423200005 ],
              [-75.06496323899995, 10.87898911700006 ],
              [-75.06500871299994, 10.879120055000044],
              [-75.06506697899994, 10.879287842000053],
              [-75.06509854599994, 10.879442686000061],
              [-75.06514790999995, 10.87952210800006 ],
              [-75.06521273099992, 10.879626381000037],
              [-75.06524804299994, 10.879743632000043],
              [-75.06528046099993, 10.87985128300005 ],
              [-75.06528572099995, 10.879980580000051],
              [-75.06531962699995, 10.880060330000049],
              [-75.0653489959999 , 10.880129404000058],
              [-75.0654218489999 , 10.88019021100007 ],
              [-75.06549358699993, 10.880250086000046],
              [-75.06555770499995, 10.880303599000058],
              [-75.06562200999991, 10.880357269000058],
              [-75.06571849499994, 10.880500958000084],
              [-75.06581523099993, 10.880610188000048],
              [-75.06594396799994, 10.880755561000058],
              [-75.06600799199992, 10.880849835000049],
              [-75.0661237999999 , 10.880959961000087],
              [-75.0662107469999 , 10.881042644000047],
              [-75.06628729999994, 10.881115437000062],
              [-75.06637914899994, 10.881202779000091],
              [-75.06654285499991, 10.88135845100004 ],
              [-75.06668793999995, 10.881496416000061],
              [-75.06681576099993, 10.881617963000053],
              [-75.06694888299995, 10.881744556000058],
              [-75.06708227199994, 10.881819323000059],
              [-75.06720389999992, 10.881887499000072],
              [-75.0673068989999 , 10.881945235000046],
              [-75.06742520699993, 10.882011552000051],
              [-75.06759634399992, 10.882107480000059],
              [-75.06778876999994, 10.882215336000058],
              [-75.06794060499993, 10.882300445000055],
              [-75.06804234599991, 10.88235747300007 ],
              [-75.06816782199991, 10.882427806000067],
              [-75.06827075699994, 10.882485503000055],
              [-75.06843384699994, 10.882576923000045],
              [-75.06856801799995, 10.88265212500005 ],
              [-75.06871894999995, 10.882736729000044],
              [-75.0688204779999 , 10.882793636000088],
              [-75.06895539699991, 10.882869261000053],
              [-75.06906792399991, 10.882960809000053],
              [-75.06915077299993, 10.883001105000062],
              [-75.06922122799995, 10.88306798800005 ],
              [-75.06924228299994, 10.88308797600007 ],
              [-75.06924792899991, 10.883093336000059],
              [-75.06931185199994, 10.883154019000074],
              [-75.06933136999993, 10.883167204000074],
              [-75.06934297499993, 10.883151057000077],
              [-75.0694005009999 , 10.88307558300005 ],
              [-75.06941733299993, 10.883053499000084],
              [-75.06947919899994, 10.88297497700006 ],
              [-75.0695142429999 , 10.882925941000053],
              [-75.06952777799995, 10.882935240000052],
              [-75.06953163899993, 10.882937893000076],
              [-75.06953445699992, 10.882940007000059],
              [-75.06955208399995, 10.882953227000087],
              [-75.06956970999994, 10.882966446000069],
              [-75.06958866299993, 10.882978323000088],
              [-75.0696199859999 , 10.88299795200004 ],
              [-75.06962369399992, 10.883000276000075],
              [-75.0696505809999 , 10.883017125000038],
              [-75.0696708129999 , 10.883029804000046],
              [-75.06971247799993, 10.883055914000067],
              [-75.06978193599991, 10.883097589000045],
              [-75.0698076569999 , 10.883113021000042],
              [-75.06986220899995, 10.883158131000073],
              [-75.06990254899995, 10.883191489000069],
              [-75.06990664199992, 10.883194874000083],
              [-75.06994825499993, 10.88323968800006 ],
              [-75.06998088099994, 10.883274824000068],
              [-75.07004560299993, 10.883345256000041],
              [-75.07007698699994, 10.88339271600006 ],
              [-75.07007807799994, 10.883394366000061],
              [-75.07012364899992, 10.883463278000079],
              [-75.070146        , 10.883498748000079],
              [-75.07016695499993, 10.883532003000084],
              [-75.07020814899994, 10.88359564600006 ],
              [-75.07031258299992, 10.883756992000087],
              [-75.07031436299991, 10.883759743000041],
              [-75.07034733799992, 10.88381068700005 ],
              [-75.07041276099994, 10.883897328000046],
              [-75.07041482699992, 10.883900064000045],
              [-75.0704353779999 , 10.88392728000008 ],
              [-75.07046045499993, 10.883961168000042],
              [-75.0705164279999 , 10.884036808000076],
              [-75.07051799399994, 10.884038925000084],
              [-75.07052341799994, 10.884046253000065],
              [-75.07054567599994, 10.884068511000066],
              [-75.0705509579999 , 10.884073793000084],
              [-75.0705948019999 , 10.884117637000088],
              [-75.0706550999999 , 10.884179565000068],
              [-75.07068284199994, 10.88420805700008 ],
              [-75.07077802099991, 10.884277061000091],
              [-75.07088216699992, 10.88433930900004 ],
              [-75.07098503399993, 10.884400793000054],
              [-75.0709868909999 , 10.884401937000064],
              [-75.0710990369999 , 10.884471028000064],
              [-75.07110678599992, 10.884475802000054],
              [-75.0712154979999 , 10.884542777000092],
              [-75.07131931099991, 10.884606733000055],
              [-75.07135416799991, 10.884628208000038],
              [-75.0714036249999 , 10.884658677000061],
              [-75.07153023799992, 10.884731203000058],
              [-75.0715566579999 , 10.884746337000081],
              [-75.0716784139999 , 10.884816081000054],
              [-75.07196240699994, 10.884978756000066],
              [-75.07209730999995, 10.885066498000072],
              [-75.0721033239999 , 10.885070409000036],
              [-75.0722502779999 , 10.885165989000086],
              [-75.0725222019999 , 10.885342850000086],
              [-75.0726940909999 , 10.885454648000064],
              [-75.07283817099994, 10.885561707000079],
              [-75.07295696799991, 10.88564997900005 ],
              [-75.0729584529999 , 10.885651083000084],
              [-75.07305975499992, 10.885726356000077],
              [-75.07320050399994, 10.885830941000052],
              [-75.07320052599994, 10.885830957000053],
              [-75.0732975009999 , 10.885903015000054],
              [-75.07346758199992, 10.886029395000037],
              [-75.07348636199993, 10.88604334900009 ],
              [-75.07350257299993, 10.886055395000085],
              [-75.07350474299994, 10.886049791000062],
              [-75.07377957099993, 10.886225871000079],
              [-75.0738273039999 , 10.886241277000067],
              [-75.07400442999995, 10.88629844500008 ],
              [-75.07408078199995, 10.88633505200005 ],
              [-75.07423392099992, 10.886470458000076],
              [-75.07427596499991, 10.886507633000065],
              [-75.07453082099994, 10.886732978000055],
              [-75.0739640139999 , 10.886768227000061],
              [-75.0738616459999 , 10.886774593000041],
              [-75.0734949639999 , 10.886797396000077],
              [-75.07330982899992, 10.88680890900008 ],
              [-75.0730826329999 , 10.886823037000056],
              [-75.07308066899992, 10.88682310300004 ],
              [-75.07275038299991, 10.886834236000084],
              [-75.07206007899993, 10.88685750500008 ],
              [-75.0720237729999 , 10.88685872900004 ],
              [-75.07161926499992, 10.886918216000083],
              [-75.07133372899995, 10.886918216000083],
              [-75.0711076799999 , 10.88698960000005 ],
              [-75.0707150689999 , 10.887013394000064],
              [-75.07053931199994, 10.887048545000084],
              [-75.07020845599993, 10.887114717000088],
              [-75.06965657199993, 10.887225094000087],
              [-75.06958482499994, 10.887239443000055],
              [-75.0694911999999 , 10.887258168000074],
              [-75.06928052299992, 10.887300304000064],
              [-75.06898956899994, 10.887358495000058],
              [-75.06878954799993, 10.887398499000085],
              [-75.06827612199993, 10.887501184000087],
              [-75.06812761399993, 10.887534671000083],
              [-75.06643712199991, 10.887915861000067],
              [-75.06584907199993, 10.88804846000005 ],
              [-75.06570630399995, 10.888167433000092],
              [-75.06561112499992, 10.888381584000058],
              [-75.06546835799992, 10.888393482000083],
              [-75.06536128199991, 10.888310200000092],
              [-75.06508913099992, 10.88832566700006 ],
              [-75.06502121299991, 10.888342132000048],
              [-75.06489282599995, 10.88837325600008 ],
              [-75.0647426939999 , 10.888384039000073],
              [-75.06461896199994, 10.88838594200007 ],
              [-75.06456185399992, 10.888391654000088],
              [-75.06451426599995, 10.888382135000086],
              [-75.06445906099992, 10.888349774000062],
              [-75.06438862899995, 10.888353581000047],
              [-75.06432581199994, 10.888368811000078],
              [-75.06427822299992, 10.88839736500006 ],
              [-75.0641659129999 , 10.88842782100005 ],
              [-75.06415244699991, 10.88843455500006 ],
              [-75.06414594699993, 10.88843780500008 ],
              [-75.06405526799995, 10.888483144000077],
              [-75.0639633269999 , 10.88850390500005 ],
              [-75.06392468299993, 10.888512631000083],
              [-75.06386227599995, 10.88852672300004 ],
              [-75.06383612899992, 10.888532626000085],
              [-75.06376353899992, 10.88854901700006 ],
              [-75.06367660099994, 10.888568648000046],
              [-75.06361368599994, 10.888582855000038],
              [-75.06361268799992, 10.888583081000093],
              [-75.06349735899994, 10.888613835000058],
              [-75.06334126899992, 10.888655459000063],
              [-75.06318438399995, 10.888697296000089],
              [-75.06310984499993, 10.888715007000087],
              [-75.06303652899993, 10.888732429000072],
              [-75.06282389699993, 10.888782955000067],
              [-75.06274216299994, 10.888798844000064],
              [-75.06268231899992, 10.888810478000039],
              [-75.0626293599999 , 10.888700575000087],
              [-75.06261573099994, 10.888696332000052],
              [-75.06260130899994, 10.88869184300006 ],
              [-75.06260053499994, 10.888691602000051],
              [-75.0625804519999 , 10.888702669000054],
              [-75.06254980499995, 10.888714806000053],
              [-75.06251720499995, 10.888735747000055],
              [-75.06250086999995, 10.888761743000089],
              [-75.0624557459999 , 10.888792556000055],
              [-75.06243281399992, 10.888830133000056],
              [-75.06241444999995, 10.888866404000055],
              [-75.06239132799993, 10.888885861000063],
              [-75.06239091099991, 10.888886212000045],
              [-75.06236888399991, 10.888876501000084],
              [-75.06235216999994, 10.888850713000068],
              [-75.06234387899991, 10.888841781000053],
              [-75.06234363699991, 10.88884151800005 ],
              [-75.06231470799992, 10.888849286000038],
              [-75.06229144399992, 10.888873014000069],
              [-75.06229094299994, 10.888873526000054],
              [-75.06226051999994, 10.888851657000089],
              [-75.06223455199995, 10.888844904000052],
              [-75.06223383799994, 10.888844720000066],
              [-75.06218332399993, 10.888863493000088],
              [-75.06214292499993, 10.888890054000058],
              [-75.06206033799992, 10.888904487000048],
              [-75.06205957499992, 10.888904621000052],
              [-75.06201370199994, 10.888891038000054],
              [-75.06198214499994, 10.888861724000037],
              [-75.06191092699993, 10.888850172000048],
              [-75.06189936999994, 10.888848296000049],
              [-75.06189872099992, 10.88884819000009 ],
              [-75.06187920899993, 10.888851832000057],
              [-75.06187900499992, 10.888851870000053],
              [-75.06182611799994, 10.888849849000053],
              [-75.06182503199994, 10.888849810000067],
              [-75.06174863099994, 10.888875004000056],
              [-75.06171594599994, 10.888897425000039],
              [-75.0616682239999 , 10.88890722900004 ],
              [-75.06166736099993, 10.888907405000054],
              [-75.06162656599992, 10.888886710000065],
              [-75.06162635399994, 10.88888660300006 ],
              [-75.06157499599993, 10.888892037000062],
              [-75.0615525689999 , 10.888919290000047],
              [-75.06156560699992, 10.888987848000056],
              [-75.06154970099993, 10.889034578000064],
              [-75.06155530699994, 10.889101369000059],
              [-75.06154711799991, 10.88917355500007 ],
              [-75.06151666999995, 10.889210859000059],
              [-75.06143641199992, 10.889229897000064],
              [-75.06143605999995, 10.889229980000039],
              [-75.06133647699994, 10.889220309000052],
              [-75.06133587499994, 10.889220249000061],
              [-75.06129207899994, 10.889224496000054],
              [-75.06119548299995, 10.889262252000037],
              [-75.06114590099992, 10.889291438000043],
              [-75.0610884489999 , 10.889327724000054],
              [-75.06104148899993, 10.88933481500004 ],
              [-75.06102184699995, 10.889337668000053],
              [-75.06099303399992, 10.88934185100004 ],
              [-75.0609096899999 , 10.889414144000057],
              [-75.06090697799993, 10.889437715000042],
              [-75.06086219699995, 10.88946117100005 ],
              [-75.06080510399994, 10.889490082000066],
              [-75.06079994299995, 10.889499969000042],
              [-75.0607717769999 , 10.889553934000048],
              [-75.06077393399994, 10.889629486000047],
              [-75.06084308999993, 10.889658748000045],
              [-75.06087504299995, 10.889680678000047],
              [-75.0608775849999 , 10.889719244000048],
              [-75.06084644899994, 10.88974027100005 ],
              [-75.06078620499994, 10.889742427000044],
              [-75.06073609299995, 10.889752367000085],
              [-75.0606932579999 , 10.889767022000058],
              [-75.06064381199991, 10.889793268000062],
              [-75.0606142279999 , 10.889842463000036],
              [-75.06061916399995, 10.889922571000056],
              [-75.0606412059999 , 10.889991486000042],
              [-75.06064029099991, 10.890038812000057],
              [-75.06060696399993, 10.890102673000058],
              [-75.06058571499995, 10.890165507000063],
              [-75.06055867599991, 10.890253293000058],
              [-75.06054965599992, 10.890341761000059],
              [-75.06054438299992, 10.890415567000048],
              [-75.06054256999994, 10.890425920000041],
              [-75.0605358869999 , 10.890464086000065],
              [-75.06049925299993, 10.890533753000057],
              [-75.0604246339999 , 10.890640474000065],
              [-75.06036721299995, 10.890704928000048],
              [-75.06028898499994, 10.890823364000084],
              [-75.06026354099993, 10.890850538000052],
              [-75.06022067499993, 10.890894822000064],
              [-75.06014387099992, 10.890985199000056],
              [-75.06002098999994, 10.891064973000084],
              [-75.05991670899994, 10.89113360600004 ],
              [-75.05976923899993, 10.891252447000056],
              [-75.05969616599992, 10.891357798000058],
              [-75.05962008199992, 10.891463042000055],
              [-75.05952025599993, 10.891591093000045],
              [-75.05938650699994, 10.89167642700005 ],
              [-75.05902785199993, 10.89186298900006 ],
              [-75.05891704999993, 10.891910689000042],
              [-75.0588432049999 , 10.891942014000051],
              [-75.05873991099992, 10.89196038600005 ],
              [-75.05862338999992, 10.89197759700005 ],
              [-75.05853969099991, 10.891989960000046],
              [-75.05839160499994, 10.892000747000054],
              [-75.05828985999995, 10.89201769400006 ],
              [-75.0582299909999 , 10.89204208800004 ],
              [-75.05822988999995, 10.89204213000005 ],
              [-75.05807809599992, 10.892036481000048],
              [-75.0579180389999 , 10.892034597000077],
              [-75.05739301999995, 10.89222575000008 ],
              [-75.05738058799994, 10.892230276000078],
              [-75.05690932099992, 10.892411153000069],
              [-75.05590655799995, 10.892854414000055],
              [-75.05554094599995, 10.893050148000043],
              [-75.05466926899993, 10.893516807000083],
              [-75.0545218019999 , 10.893595755000092],
              [-75.0541695579999 , 10.893784331000063],
              [-75.0535876049999 , 10.89409588400008 ],
              [-75.05294066499994, 10.89444658900004 ],
              [-75.0527031119999 , 10.894575366000083],
              [-75.05262168599995, 10.894619507000073],
              [-75.05244263899993, 10.894716568000092],
              [-75.05225312099992, 10.894832202000089],
              [-75.0521361719999 , 10.894920274000071],
              [-75.05200940599991, 10.89500438400006 ],
              [-75.0517972319999 , 10.895123088000048],
              [-75.05165834699994, 10.895201258000043],
              [-75.05126606499994, 10.895489500000053],
              [-75.05096730999992, 10.895692545000088],
              [-75.05077109599995, 10.89582590000009 ],
              [-75.05040132199991, 10.896130794000044],
              [-75.05010138999995, 10.896378101000039],
              [-75.0497562779999 , 10.89666991900009 ],
              [-75.04961874999992, 10.896786209000084],
              [-75.04906368999991, 10.89724835800007 ],
              [-75.04858703899993, 10.897638509000046],
              [-75.04839087999994, 10.897811208000064],
              [-75.04806222199994, 10.898100560000046],
              [-75.04750690499992, 10.898484980000092],
              [-75.04683728399993, 10.899067070000058],
              [-75.0465538059999 , 10.899331046000043],
              [-75.04615561899993, 10.899667133000037],
              [-75.0454621419999 , 10.900356920000092],
              [-75.04502705699991, 10.900965911000071],
              [-75.0450592169999 , 10.901075828000046],
              [-75.04507599499993, 10.901133171000083],
              [-75.04501630999994, 10.901384481000036],
              [-75.04491997999992, 10.901528284000051],
              [-75.04479979899992, 10.901779785000087],
              [-75.04454648799992, 10.902019748000043],
              [-75.04430122399992, 10.902341407000051],
              [-75.04428142499995, 10.902367372000072],
              [-75.04406468099995, 10.902690926000048],
              [-75.04370386499994, 10.903361723000046],
              [-75.0433309089999 , 10.904020599000091],
              [-75.04325777899993, 10.90414968500005 ],
              [-75.04306622899992, 10.904487803000052],
              [-75.04281372399993, 10.904978884000059],
              [-75.0426334679999 , 10.90536211400007 ],
              [-75.04252499799992, 10.905493996000075],
              [-75.04167161399994, 10.905668338000055],
              [-75.04147643399995, 10.90608775600009 ],
              [-75.04097969499992, 10.906973967000056],
              [-75.0408989739999 , 10.907117977000041],
              [-75.0405259389999 , 10.907927949000054],
              [-75.04022609799995, 10.908578993000049],
              [-75.0401278789999 , 10.908778962000042],
              [-75.03977617399994, 10.90949501600005 ],
              [-75.0395331329999 , 10.909989837000069],
              [-75.03912016999993, 10.910830608000083],
              [-75.03832170499993, 10.912795002000053],
              [-75.03803973099991, 10.913488720000089],
              [-75.03796557699991, 10.913655564000067],
              [-75.03753291099991, 10.914629043000048],
              [-75.03667930299991, 10.91654961900008 ],
              [-75.03635788799994, 10.91727278500008 ],
              [-75.03589109199993, 10.918371980000074],
              [-75.03549311199993, 10.919309130000045],
              [-75.0352290049999 , 10.91993104100004 ],
              [-75.03504237999994, 10.920454457000062],
              [-75.03496961899992, 10.920658525000078],
              [-75.03457959199994, 10.921752409000078],
              [-75.03436609799991, 10.922351180000078],
              [-75.03426918299994, 10.922622992000072],
              [-75.03422164099993, 10.922756329000038],
              [-75.03395995599993, 10.924168216000055],
              [-75.03390006199993, 10.924906726000074],
              [-75.03389011099995, 10.925029425000048],
              [-75.03415702899991, 10.925590962000058],
              [-75.03416692799993, 10.925640069000053],
              [-75.0341813309999 , 10.925687693000043],
              [-75.03419119499995, 10.925720309000042],
              [-75.03420403799993, 10.925769178000053],
              [-75.0342153549999 , 10.925835798000037],
              [-75.03421592099994, 10.925840157000039],
              [-75.03425461099994, 10.926137983000046],
              [-75.03426352199995, 10.926364044000081],
              [-75.03426868799994, 10.926495081000041],
              [-75.03427117199993, 10.926562425000043],
              [-75.03427976899991, 10.92663219900004 ],
              [-75.03429521499993, 10.926713162000055],
              [-75.03430723999992, 10.926788527000042],
              [-75.03430948599993, 10.926852953000036],
              [-75.03430787899993, 10.926874854000062],
              [-75.03430385099995, 10.926929768000036],
              [-75.03425370299993, 10.927042519000054],
              [-75.0342301629999 , 10.927117861000056],
              [-75.03421161199992, 10.92716941000009 ],
              [-75.03418660699992, 10.927238891000059],
              [-75.03413109099995, 10.92732959500006 ],
              [-75.03410659299993, 10.927369622000072],
              [-75.03406164799992, 10.92748927100007 ],
              [-75.03402614299995, 10.927617156000053],
              [-75.03398987399993, 10.927747793000037],
              [-75.03398280899995, 10.927808802000072],
              [-75.03398041299994, 10.92783074600004 ],
              [-75.03397222899991, 10.927905697000085],
              [-75.03395095799993, 10.928062912000087],
              [-75.03389679199995, 10.92812609200007 ],
              [-75.03388912299994, 10.928132682000069],
              [-75.03376970199992, 10.928143102000092],
              [-75.03374078699994, 10.928182655000057],
              [-75.0336792249999 , 10.928229484000042],
              [-75.03360314199995, 10.928276359000051],
              [-75.0334944459999 , 10.928341273000058],
              [-75.03342743699994, 10.928382557000077],
              [-75.03341836299995, 10.928388148000067],
              [-75.03335555399991, 10.928469447000055],
              [-75.03334413999994, 10.928476875000058],
              [-75.03331847899995, 10.928504308000072],
              [-75.03331139799991, 10.928511877000062],
              [-75.03328971899992, 10.928537156000061],
              [-75.03327141199992, 10.92856187500007 ],
              [-75.03325497399993, 10.928584071000046],
              [-75.0332382499999 , 10.928597190000062],
              [-75.03322127899992, 10.928607395000085],
              [-75.03321857199995, 10.928610548000051],
              [-75.03321538999995, 10.928607876000058],
              [-75.0332018389999 , 10.928623673000061],
              [-75.03315420399991, 10.928650859000072],
              [-75.03307549399995, 10.928682437000077],
              [-75.0328387859999 , 10.928777405000062],
              [-75.03277429099995, 10.92879951000009 ],
              [-75.0327296449999 , 10.928814812000041],
              [-75.0325958329999 , 10.928860676000056],
              [-75.03234212699994, 10.928987030000087],
              [-75.03204489399991, 10.929124281000043],
              [-75.03196354399995, 10.929169966000075],
              [-75.03191575099993, 10.92919680600005 ],
              [-75.03190357199992, 10.929203646000076],
              [-75.03182023999994, 10.92925413100005 ],
              [-75.03175879199995, 10.929336834000082],
              [-75.03171909399993, 10.929353794000065],
              [-75.03168266299991, 10.929369359000077],
              [-75.0316634799999 , 10.929388436000067],
              [-75.0316392389999 , 10.929412544000058],
              [-75.03162918499993, 10.92942481600005 ],
              [-75.03155968599992, 10.929509654000071],
              [-75.03151265499991, 10.929560025000058],
              [-75.03145483599991, 10.929642717000092],
              [-75.03137942099994, 10.92977355700009 ],
              [-75.0313637719999 , 10.929792896000038],
              [-75.03114363599991, 10.930064934000086],
              [-75.03111874099994, 10.930106565000074],
              [-75.03092487499993, 10.930430752000063],
              [-75.0309588639999 , 10.930443341000057],
              [-75.03094794099991, 10.930480791000036],
              [-75.03087857199995, 10.930584326000087],
              [-75.03084913799995, 10.930640698000047],
              [-75.03080352399991, 10.930728059000046],
              [-75.0307303379999 , 10.930848826000044],
              [-75.03072690599993, 10.930858980000039],
              [-75.03067855299992, 10.931002053000043],
              [-75.03064412499992, 10.931134179000082],
              [-75.03064307799991, 10.931139133000045],
              [-75.0306377679999 , 10.931164264000074],
              [-75.03061742299991, 10.931260541000086],
              [-75.0306163859999 , 10.931264211000041],
              [-75.03056764699994, 10.931436721000068],
              [-75.03055053399993, 10.931534353000075],
              [-75.03053325799993, 10.931572476000042],
              [-75.0305236179999 , 10.931593750000047],
              [-75.03051812899992, 10.931610197000055],
              [-75.03048526299995, 10.931708668000056],
              [-75.03045930999991, 10.931778479000059],
              [-75.03044354399992, 10.93182088900005 ],
              [-75.03044111199995, 10.931827431000045],
              [-75.03037310499991, 10.932003123000072],
              [-75.03036622099995, 10.932020909000073],
              [-75.03033749799994, 10.932120491000092],
              [-75.03033693299994, 10.932121617000064],
              [-75.0303182589999 , 10.932158817000072],
              [-75.03031309499994, 10.932177032000084],
              [-75.0303130879999 , 10.932177057000047],
              [-75.03030033999994, 10.932222021000086],
              [-75.03029089899991, 10.932255320000081],
              [-75.03028188299993, 10.932287123000037],
              [-75.03027925999993, 10.932300092000048],
              [-75.03027411199992, 10.932325554000045],
              [-75.03026417899991, 10.932374671000048],
              [-75.03025710499992, 10.932409652000047],
              [-75.03022837499992, 10.93250732100006 ],
              [-75.03022089599995, 10.932522219000077],
              [-75.03019494999995, 10.932573907000062],
              [-75.0301937449999 , 10.93257630800008 ],
              [-75.0301747339999 , 10.932653611000092],
              [-75.0301612529999 , 10.932708428000069],
              [-75.03015814399993, 10.932724951000068],
              [-75.03014019699992, 10.932820345000039],
              [-75.03013459999994, 10.932850096000038],
              [-75.03010964999993, 10.932919053000091],
              [-75.03009446199991, 10.932969665000087],
              [-75.03008666199992, 10.932995657000049],
              [-75.03005975199994, 10.933056967000084],
              [-75.03005693599994, 10.933097732000078],
              [-75.03005605099992, 10.933110552000073],
              [-75.03005806599992, 10.933135418000063],
              [-75.0300388089999 , 10.933168005000084],
              [-75.03003210299994, 10.933179967000058],
              [-75.0300060809999 , 10.93322638400008 ],
              [-75.0299926049999 , 10.933250421000082],
              [-75.02997862199993, 10.933278977000043],
              [-75.02995413399992, 10.933328987000039],
              [-75.02992360199994, 10.933468754000046],
              [-75.02992072699993, 10.933484422000049],
              [-75.02990076599991, 10.933593191000057],
              [-75.02987887799992, 10.933659872000078],
              [-75.0298624159999 , 10.93371002200007 ],
              [-75.02983235399995, 10.93376289400004 ],
              [-75.02981031399992, 10.93380165800005 ],
              [-75.02980465699994, 10.933811608000042],
              [-75.02979709599992, 10.933869031000086],
              [-75.02976454899994, 10.933983931000057],
              [-75.02973193999992, 10.934079699000051],
              [-75.02970493299995, 10.934110396000051],
              [-75.02967614199991, 10.934188931000051],
              [-75.02965268699995, 10.934271945000091],
              [-75.02965125299994, 10.93427702100007 ],
              [-75.02963214899995, 10.934357440000042],
              [-75.0295930339999 , 10.934469006000086],
              [-75.0295918569999 , 10.934472364000044],
              [-75.02955158299994, 10.934593029000041],
              [-75.02951041499995, 10.934726365000074],
              [-75.02949086399991, 10.934789685000055],
              [-75.02948190199993, 10.934818710000059],
              [-75.02944810199995, 10.934928181000089],
              [-75.02942105599993, 10.935011810000049],
              [-75.02940784099991, 10.93505267200004 ],
              [-75.02934626399991, 10.935171489000084],
              [-75.02930207499992, 10.93527877200006 ],
              [-75.02928989599991, 10.935308873000054],
              [-75.0292790659999 , 10.935335640000062],
              [-75.02925789299991, 10.93538796900009 ],
              [-75.02917325899995, 10.935562344000061],
              [-75.02910991399995, 10.935680542000057],
              [-75.0290401979999 , 10.935875008000039],
              [-75.02900943899994, 10.93596080900005 ],
              [-75.02899726299995, 10.935994772000072],
              [-75.0289625179999 , 10.93609168900008 ],
              [-75.0289046549999 , 10.936233005000076],
              [-75.02882941699994, 10.93641675300006 ],
              [-75.0287735739999 , 10.936671664000073],
              [-75.02873797199993, 10.93688388600009 ],
              [-75.02873426899993, 10.936899314000073],
              [-75.02870450599994, 10.937023325000041],
              [-75.02866103699995, 10.937204441000063],
              [-75.02865164499991, 10.937243573000046],
              [-75.02860381699992, 10.937442855000086],
              [-75.02859275499992, 10.937488945000041],
              [-75.02858494399993, 10.937541162000059],
              [-75.02857325499991, 10.937619305000055],
              [-75.0285710359999 , 10.937634139000068],
              [-75.02853777799993, 10.937856473000068],
              [-75.02851712199993, 10.938059538000061],
              [-75.02851149599991, 10.93811485200007 ],
              [-75.02848051299992, 10.938231854000037],
              [-75.02846940799992, 10.93827378800006 ],
              [-75.02845629399991, 10.938411588000065],
              [-75.02845600699993, 10.938418956000078],
              [-75.02845297499994, 10.938496740000062],
              [-75.0284473449999 , 10.93864121300004 ],
              [-75.02842868499994, 10.938861302000078],
              [-75.02842517599993, 10.938947280000093],
              [-75.0284232969999 , 10.938993338000046],
              [-75.02839092599993, 10.939163724000082],
              [-75.02838192199994, 10.939376129000038],
              [-75.02838020599995, 10.939392683000051],
              [-75.02837599899993, 10.93943326100009 ],
              [-75.02836788399992, 10.939511534000076],
              [-75.02835374599994, 10.93964790800004 ],
              [-75.02835288199992, 10.939664202000074],
              [-75.02834644099994, 10.939785689000075],
              [-75.02834097399995, 10.939892852000071],
              [-75.02833482999995, 10.939978008000082],
              [-75.02832799399994, 10.940072744000076],
              [-75.0283278839999 , 10.940083219000087],
              [-75.02832650899995, 10.940214334000075],
              [-75.0283213539999 , 10.94041907400009 ],
              [-75.02832167699995, 10.940520479000043],
              [-75.02832301299992, 10.940601860000072],
              [-75.0283234179999 , 10.940626559000066],
              [-75.02832415799992, 10.940671617000078],
              [-75.0283243209999 , 10.940681534000078],
              [-75.02832563499993, 10.940761619000057],
              [-75.02832602199993, 10.940785214000073],
              [-75.0283264389999 , 10.94081058100005 ],
              [-75.0283264819999 , 10.940813201000083],
              [-75.02832801499994, 10.940910022000082],
              [-75.02832858999994, 10.940946298000085],
              [-75.02832868399992, 10.940952242000037],
              [-75.02832879099992, 10.940959020000037],
              [-75.0283289059999 , 10.94096625800006 ],
              [-75.02832818599995, 10.940967952000051],
              [-75.0283254119999 , 10.940974476000065],
              [-75.02832523099994, 10.94097490200005 ],
              [-75.0282798479999 , 10.941081630000042],
              [-75.02825395799994, 10.94114251700006 ],
              [-75.02824046599994, 10.941161692000037],
              [-75.02820926999993, 10.94122770100006 ],
              [-75.02815276199993, 10.941347270000051],
              [-75.02810451899995, 10.941397645000052],
              [-75.02806115399994, 10.941459963000057],
              [-75.02804130099992, 10.94150140700009 ],
              [-75.02802970099992, 10.941525623000075],
              [-75.02801582299992, 10.941554593000092],
              [-75.02798379899991, 10.941741819000072],
              [-75.02747175699994, 10.942068676000076],
              [-75.02723981499992, 10.942193763000091],
              [-75.02693275099995, 10.942268249000051],
              [-75.02654682299993, 10.942381423000086],
              [-75.02650940799992, 10.942473319000044],
              [-75.02650689399991, 10.942479495000043],
              [-75.02649293299993, 10.942527806000044],
              [-75.02642598499995, 10.942657197000074],
              [-75.02638529399991, 10.94274312400006 ],
              [-75.0262254989999 , 10.942876142000046],
              [-75.0261402989999 , 10.942999435000047],
              [-75.0261194979999 , 10.94302953600004 ],
              [-75.02607164099993, 10.943201883000086],
              [-75.02606250799994, 10.943374110000093],
              [-75.02609209799994, 10.943546216000072],
              [-75.0261028139999 , 10.943871447000049],
              [-75.02608393899993, 10.944024570000067],
              [-75.0260556579999 , 10.944263823000085],
              [-75.02606622099995, 10.944541220000076],
              [-75.02610610099993, 10.944904626000039],
              [-75.0261555699999 , 10.94523930300005 ],
              [-75.02619499299993, 10.94545921100007 ],
              [-75.02625100599994, 10.945700800000054],
              [-75.02626370299993, 10.945755562000045],
              [-75.02629998099991, 10.945890070000075],
              [-75.02631268599993, 10.94593717400005 ],
              [-75.02641001199993, 10.946099504000074],
              [-75.0264297999999 , 10.946233374000087],
              [-75.02642066699991, 10.946405601000038],
              [-75.02653933499994, 10.946547691000092],
              [-75.02661917299992, 10.946659948000047],
              [-75.02675857899993, 10.946855962000086],
              [-75.02692894699993, 10.947154388000058],
              [-75.02693202599994, 10.947190498000055],
              [-75.02696665899992, 10.94759672400005 ],
              [-75.02699277099993, 10.94767586100005 ],
              [-75.02703982199995, 10.94781845600005 ],
              [-75.02704823199991, 10.947843945000045],
              [-75.02708406599993, 10.947952543000042],
              [-75.02709415999993, 10.947944992000089],
              [-75.02713496599995, 10.947990785000059],
              [-75.02716626799992, 10.94809209300007 ],
              [-75.02717576499992, 10.948190383000053],
              [-75.02717828099992, 10.94821642100004 ],
              [-75.02719808199993, 10.948354118000054],
              [-75.02725133099995, 10.94850655600004 ],
              [-75.02727819699993, 10.94858346600006 ],
              [-75.02738154699995, 10.948812742000086],
              [-75.0274321679999 , 10.94890059700009 ],
              [-75.02748151999992, 10.948975111000038],
              [-75.0274827529999 , 10.948976972000082],
              [-75.0275236999999 , 10.949066771000048],
              [-75.02752591599995, 10.949154776000057],
              [-75.02758058699993, 10.949298104000093],
              [-75.02765862699994, 10.949483453000084],
              [-75.02771706599992, 10.949594243000092],
              [-75.02775499299992, 10.949648650000086],
              [-75.02779463899992, 10.949705523000091],
              [-75.02781821199994, 10.949739340000065],
              [-75.0279075819999 , 10.949834727000052],
              [-75.02802811399994, 10.949987417000045],
              [-75.02804897499993, 10.950009095000041],
              [-75.02815543699995, 10.950119725000093],
              [-75.02822820799992, 10.950195345000054],
              [-75.02829128099995, 10.950265184000045],
              [-75.02841085699993, 10.950397587000055],
              [-75.02850590199995, 10.95045086400006 ],
              [-75.02854484599993, 10.950519622000058],
              [-75.02863488799994, 10.950589782000065],
              [-75.02866713199995, 10.950614906000055],
              [-75.0287156519999 , 10.95065954100005 ],
              [-75.0288516139999 , 10.950784616000078],
              [-75.02903508199995, 10.950941128000068],
              [-75.02905055199994, 10.950954325000055],
              [-75.02933261099992, 10.95114637200004 ],
              [-75.02958902499995, 10.951320958000053],
              [-75.02962216499992, 10.951339673000064],
              [-75.02967104099992, 10.951367274000063],
              [-75.02978512099992, 10.951431698000079],
              [-75.02993024999995, 10.951513656000088],
              [-75.03059744899991, 10.951890436000042],
              [-75.03063931199995, 10.951908255000092],
              [-75.03083343999992, 10.95195631200005 ],
              [-75.0309602559999 , 10.951980720000051],
              [-75.03097441299991, 10.95198242300006 ],
              [-75.0310743149999 , 10.951994437000053],
              [-75.03118294299992, 10.952014526000085],
              [-75.03121171499993, 10.952020165000079],
              [-75.03141245099994, 10.952059513000052],
              [-75.03149697999993, 10.952075780000087],
              [-75.03158645399992, 10.952079841000057],
              [-75.03174133699991, 10.952084127000091],
              [-75.03181263199991, 10.952083859000084],
              [-75.03192571099993, 10.95208587500008 ],
              [-75.03198425699992, 10.952074924000044],
              [-75.03203319199991, 10.952052149000053],
              [-75.03209038299991, 10.95205048300005 ],
              [-75.03241327899991, 10.952010493000046],
              [-75.0327325849999 , 10.951956877000043],
              [-75.03278563799995, 10.95193805000008 ],
              [-75.03279335099995, 10.951935313000092],
              [-75.03295016299995, 10.951879664000046],
              [-75.03305643899995, 10.95181236500008 ],
              [-75.03319197299993, 10.951811941000074],
              [-75.03330788499994, 10.95173026200007 ],
              [-75.03340225499994, 10.951631387000077],
              [-75.03357804799992, 10.951447202000054],
              [-75.03373229999994, 10.95124582200009 ],
              [-75.03375614899994, 10.951135732000068],
              [-75.03378009099993, 10.951054341000088],
              [-75.03388641299995, 10.95100139200008 ],
              [-75.03400731799991, 10.95096753000007 ],
              [-75.03408458199993, 10.95090988800007 ],
              [-75.0341761979999 , 10.950799586000073],
              [-75.03425696599993, 10.950700340000083],
              [-75.03429618799993, 10.950710575000073],
              [-75.03443257499993, 10.950746166000044],
              [-75.0346842809999 , 10.95074537700009 ],
              [-75.03488249599991, 10.950668223000037],
              [-75.03505440899994, 10.950589162000085],
              [-75.03507375999993, 10.950580263000063],
              [-75.03513383199993, 10.950552636000054],
              [-75.03532179099994, 10.95043325000006 ],
              [-75.03536087399993, 10.950408425000091],
              [-75.03549585399992, 10.950235804000044],
              [-75.03553891099995, 10.950077820000047],
              [-75.03557219499993, 10.949891168000079],
              [-75.03562513299994, 10.949795337000069],
              [-75.03571184599991, 10.949665916000072],
              [-75.03572112699993, 10.949541522000061],
              [-75.03580419699995, 10.949306565000086],
              [-75.03580747099994, 10.949297304000083],
              [-75.03592277599995, 10.949177838000082],
              [-75.03596760699992, 10.949085076000074],
              [-75.03601654199991, 10.94898382100007 ],
              [-75.03603664199994, 10.948943779000047],
              [-75.03612406799994, 10.948769612000092],
              [-75.03616410099994, 10.948684498000091],
              [-75.03626176699993, 10.948473633000049],
              [-75.03633310399994, 10.948078789000078],
              [-75.03635423999992, 10.947877825000091],
              [-75.03635662699992, 10.947835420000047],
              [-75.03636110799994, 10.947755830000062],
              [-75.0362512989999 , 10.94747635300007 ],
              [-75.03623570399992, 10.947429347000082],
              [-75.03621416199991, 10.947364416000084],
              [-75.0362067929999 , 10.947342203000062],
              [-75.03618458299991, 10.947275258000047],
              [-75.03616331699993, 10.947211158000073],
              [-75.03611191599992, 10.947031947000085],
              [-75.03607505899993, 10.94685986400009 ],
              [-75.03610391699993, 10.946802375000061],
              [-75.03634351799991, 10.946801622000066],
              [-75.0365490399999 , 10.94684026100009 ],
              [-75.03659598599995, 10.946885144000078],
              [-75.03672319099991, 10.946929588000046],
              [-75.03678279999991, 10.946931582000047],
              [-75.0369682669999 , 10.946937786000092],
              [-75.03715276999992, 10.94692524800007 ],
              [-75.03728582399992, 10.946906893000062],
              [-75.03744890899992, 10.946819683000058],
              [-75.0375363679999 , 10.946778893000044],
              [-75.03759088499993, 10.946753467000065],
              [-75.03774181099993, 10.946648358000061],
              [-75.03792592799994, 10.946516239000061],
              [-75.03802850799991, 10.946429219000038],
              [-75.03816134999994, 10.946345094000037],
              [-75.0381980489999 , 10.94632450100005 ],
              [-75.03832743999993, 10.946251896000092],
              [-75.03848439699993, 10.946140789000083],
              [-75.03852343699992, 10.946050979000063],
              [-75.03854623099994, 10.946020745000055],
              [-75.03858669699991, 10.945967073000077],
              [-75.03880703999994, 10.945810923000067],
              [-75.0389036169999 , 10.94573887000007 ],
              [-75.03902415399995, 10.945592003000058],
              [-75.03904907399993, 10.945564121000075],
              [-75.03914473099991, 10.945457094000062],
              [-75.03924990999991, 10.945238526000082],
              [-75.03930079999992, 10.945070951000048],
              [-75.0393458019999 , 10.944954217000088],
              [-75.03936972799994, 10.944932063000067],
              [-75.03943327399992, 10.944873224000048],
              [-75.03951474299993, 10.944807197000046],
              [-75.03957515199994, 10.944734328000038],
              [-75.0396228059999 , 10.94467684600005 ],
              [-75.03965637999994, 10.944636347000085],
              [-75.03976511499991, 10.944582193000087],
              [-75.03984970599993, 10.944546051000088],
              [-75.0401008689999 , 10.944566187000078],
              [-75.04028248899994, 10.944598499000051],
              [-75.04037935499991, 10.944616131000089],
              [-75.04045789799994, 10.944625160000044],
              [-75.04065778299992, 10.944648138000048],
              [-75.04086970499992, 10.94469530200007 ],
              [-75.04100301799991, 10.944757662000086],
              [-75.04102715599993, 10.944775307000043],
              [-75.04108373399993, 10.944816664000086],
              [-75.04113979699991, 10.94485764600006 ],
              [-75.04114554299991, 10.944861846000038],
              [-75.0411594549999 , 10.944873519000055],
              [-75.04131238499991, 10.945001828000045],
              [-75.04149727299995, 10.94510886800009 ],
              [-75.04180613799991, 10.94519757900008 ],
              [-75.04199981399995, 10.945229054000038],
              [-75.04227530299994, 10.945273825000072],
              [-75.04242963899992, 10.94528828500006 ],
              [-75.04255067799994, 10.94529687100004 ],
              [-75.04274121999993, 10.945281321000039],
              [-75.04294673399994, 10.945217891000084],
              [-75.04313396999993, 10.945115654000062],
              [-75.04328197399991, 10.945043437000038],
              [-75.0434926719999 , 10.944988193000086],
              [-75.04353588099991, 10.944976864000068],
              [-75.04367092099994, 10.944948945000078],
              [-75.04375527999991, 10.944947863000039],
              [-75.04385191699993, 10.944894941000086],
              [-75.04402601599992, 10.944846557000062],
              [-75.04412758599995, 10.944822319000082],
              [-75.04414044999993, 10.944816550000041],
              [-75.04422425399991, 10.94477896400008 ],
              [-75.04427261199993, 10.94476446100009 ],
              [-75.04432571599995, 10.94472124400005 ],
              [-75.04442225999992, 10.944639622000068],
              [-75.04449955099994, 10.944591545000037],
              [-75.04454772199995, 10.944519643000092],
              [-75.04461533299991, 10.944471597000074],
              [-75.04464918399992, 10.944461923000063],
              [-75.04467796299991, 10.94438051600008 ],
              [-75.0447214479999 , 10.944356462000087],
              [-75.04474065499994, 10.944308569000043],
              [-75.04477912999994, 10.944231915000046],
              [-75.04477413399991, 10.94418409800005 ],
              [-75.04479821199993, 10.944145756000069],
              [-75.0448416669999 , 10.944112135000069],
              [-75.04486563599994, 10.944040310000048],
              [-75.0448847639999 , 10.943968501000086],
              [-75.04493690599992, 10.943942675000073],
              [-75.04495968499992, 10.94387605500009 ],
              [-75.04495074499994, 10.943827728000088],
              [-75.04494224399991, 10.94378177100009 ],
              [-75.04494660299991, 10.943633476000059],
              [-75.04494627699995, 10.943533029000037],
              [-75.0449848909999 , 10.943499424000038],
              [-75.04503801099992, 10.943460989000073],
              [-75.04507670299995, 10.94345130000005 ],
              [-75.04511013599995, 10.943312480000088],
              [-75.0451292329999 , 10.943231104000063],
              [-75.04515797999994, 10.943140131000064],
              [-75.04515765399992, 10.943039683000052],
              [-75.04515726599993, 10.942920103000063],
              [-75.04515698699993, 10.942834005000066],
              [-75.04520517299994, 10.942766887000062],
              [-75.04525340599992, 10.942714118000083],
              [-75.04528717999995, 10.94268052800004 ],
              [-75.04530146799993, 10.942608734000089],
              [-75.04531086899993, 10.942522605000079],
              [-75.0453349469999 , 10.94248426300004 ],
              [-75.04534433299995, 10.942393351000078],
              [-75.04537784299993, 10.942278447000092],
              [-75.0454685979999 , 10.941905065000071],
              [-75.04550716599994, 10.941857110000058],
              [-75.0455507129999 , 10.941852189000087],
              [-75.04557482199994, 10.941823413000066],
              [-75.04559880599993, 10.941756371000054],
              [-75.04562278999992, 10.941689329000042],
              [-75.04565653299994, 10.941646173000038],
              [-75.04572907599993, 10.94162681000006 ],
              [-75.0458064899999 , 10.941616998000086],
              [-75.04584511999991, 10.941588176000039],
              [-75.04590788899992, 10.941540144000044],
              [-75.04594164699995, 10.941501771000048],
              [-75.04599464199993, 10.941425071000083],
              [-75.04599433099992, 10.941329407000069],
              [-75.0460181439999 , 10.941209750000041],
              [-75.04604212899994, 10.941142708000086],
              [-75.04605610599992, 10.940975249000076],
              [-75.04606037199994, 10.940798255000061],
              [-75.04604539999991, 10.940659588000074],
              [-75.04605480099991, 10.940573460000053],
              [-75.04611277599992, 10.940539793000085],
              [-75.04617569999994, 10.94053959300004 ],
              [-75.04618524099993, 10.940496514000074],
              [-75.04621885899991, 10.94041509200008 ],
              [-75.0462428429999 , 10.940348050000068],
              [-75.04625702299995, 10.94024277300008 ],
              [-75.0462615369999 , 10.940142310000056],
              [-75.04625633899991, 10.940032312000085],
              [-75.04628525699991, 10.939993954000045],
              [-75.04638676299993, 10.93995058300004 ],
              [-75.04644970299995, 10.93995516700005 ],
              [-75.04652215199991, 10.939907104000042],
              [-75.04661393099991, 10.939849414000037],
              [-75.04671556199992, 10.93984430800009 ],
              [-75.0468074339999 , 10.939815317000068],
              [-75.0468846309999 , 10.939738540000064],
              [-75.04696201299993, 10.939719161000085],
              [-75.04707327799991, 10.939699675000043],
              [-75.04716983499992, 10.93962283600007 ],
              [-75.04722289199992, 10.93956526900007 ],
              [-75.0473630269999 , 10.939493075000087],
              [-75.0475130289999 , 10.939478249000047],
              [-75.0476098659999 , 10.93948750800007 ],
              [-75.04769202699993, 10.939448981000055],
              [-75.0477691289999 , 10.939343505000068],
              [-75.04781241199993, 10.939257268000063],
              [-75.0478653479999 , 10.939162631000045],
              [-75.0478830319999 , 10.939019077000069],
              [-75.0479673289999 , 10.938893249000046],
              [-75.04807607899994, 10.93884507100006 ],
              [-75.04814864499991, 10.938832882000042],
              [-75.04828196699992, 10.938898228000085],
              [-75.04834887099992, 10.939005639000072],
              [-75.04838550399995, 10.939107167000088],
              [-75.0484885539999 , 10.939166630000045],
              [-75.0487548669999 , 10.939195679000079],
              [-75.04885161499993, 10.939177434000044],
              [-75.04895431499995, 10.939129275000084],
              [-75.04911127399993, 10.939021152000066],
              [-75.0492622399999 , 10.93893098600006 ],
              [-75.0493767879999 , 10.93880506100004 ],
              [-75.04950942799991, 10.938661141000068],
              [-75.04958136999994, 10.93845762400008 ],
              [-75.04965924499993, 10.938218213000084],
              [-75.04960994699991, 10.937943333000078],
              [-75.04956654199992, 10.937620601000049],
              [-75.04956513999991, 10.937190112000053],
              [-75.04959468999994, 10.936974772000042],
              [-75.04965480399994, 10.936854999000047],
              [-75.04971509299992, 10.93678903700004 ],
              [-75.04981183899991, 10.936770792000061],
              [-75.04993292299991, 10.936794323000072],
              [-75.05002365799993, 10.936788055000079],
              [-75.0501266309999 , 10.93682360200006 ],
              [-75.05021149099991, 10.93687116500007 ],
              [-75.05026863499995, 10.936982397000065],
              [-75.0503474969999 , 10.937016734000053],
              [-75.05051651199994, 10.936896501000092],
              [-75.05064167699993, 10.936826711000037],
              [-75.05069769399995, 10.936795476000043],
              [-75.0509079169999 , 10.936694358000068],
              [-75.05109642899993, 10.936614834000068],
              [-75.05163348499991, 10.936548548000076],
              [-75.05197465099991, 10.936525936000066],
              [-75.05213437899994, 10.93652542600006 ],
              [-75.05232352299993, 10.93663962100004 ],
              [-75.05257067999992, 10.936732106000079],
              [-75.05297747199995, 10.936795381000081],
              [-75.05368177399993, 10.936807482000063],
              [-75.05379782199992, 10.936771237000073],
              [-75.05393567499993, 10.936742097000092],
              [-75.05408795499994, 10.936684211000056],
              [-75.05418934099993, 10.936604963000093],
              [-75.0543485529999 , 10.93644660700005 ],
              [-75.0544354889999 , 10.936388930000078],
              [-75.05447841699993, 10.936195071000043],
              [-75.05462902799991, 10.935627774000068],
              [-75.05466500099993, 10.935527211000078],
              [-75.05473739299993, 10.935462406000056],
              [-75.05479453599992, 10.935175228000048],
              [-75.05482237899992, 10.93480922000009 ],
              [-75.05486488299994, 10.93448621500005 ],
              [-75.05489289099995, 10.934170430000052],
              [-75.05497214299993, 10.933983630000057],
              [-75.05502982599995, 10.933861473000093],
              [-75.05503225099994, 10.933838397000045],
              [-75.0550509489999 , 10.93366050800006 ],
              [-75.05510083099995, 10.933373354000082],
              [-75.05518031799994, 10.93325830200007 ],
              [-75.05531067399994, 10.933157436000045],
              [-75.05543381599995, 10.933070944000065],
              [-75.05562265399993, 10.933091864000062],
              [-75.05573900599995, 10.933148891000087],
              [-75.05584055599991, 10.933119866000084],
              [-75.0559349159999 , 10.933112389000087],
              [-75.05607283699993, 10.933104773000082],
              [-75.05623984699992, 10.933111413000063],
              [-75.05634863299991, 10.933075190000068],
              [-75.0567036569999 , 10.932851632000052],
              [-75.05691612999993, 10.932700279000073],
              [-75.05709704799995, 10.932520327000077],
              [-75.05734954699994, 10.932029235000073],
              [-75.05763799399995, 10.931430404000082],
              [-75.05777070499994, 10.93131039800005 ],
              [-75.05790357299992, 10.931238223000037],
              [-75.0581335199999 , 10.93124944300007 ],
              [-75.05836342599991, 10.931248706000076],
              [-75.0584849409999 , 10.931403771000078],
              [-75.05854071999994, 10.931567366000081],
              [-75.05865599599991, 10.931905464000067],
              [-75.05872895199991, 10.93201285200007 ],
              [-75.05880237999992, 10.932263737000085],
              [-75.05882732799995, 10.932490861000076],
              [-75.05881605299993, 10.932742018000056],
              [-75.05870789599993, 10.932969570000068],
              [-75.05862386099994, 10.933173127000089],
              [-75.05869519099991, 10.933446770000046],
              [-75.05837564699993, 10.93444474200004 ],
              [-75.05818513799994, 10.935015712000052],
              [-75.05798765399993, 10.936312232000091],
              [-75.05797259699995, 10.936411084000042],
              [-75.0577800879999 , 10.936746529000061],
              [-75.0570091049999 , 10.937801316000048],
              [-75.05615467899992, 10.939120894000041],
              [-75.05582243899994, 10.939634004000084],
              [-75.05561248799995, 10.939958253000043],
              [-75.05395466599992, 10.942332113000077],
              [-75.05363720399993, 10.942786690000048],
              [-75.05353421299992, 10.942934384000068],
              [-75.05281817899993, 10.943961203000072],
              [-75.05279233599992, 10.943986050000092],
              [-75.0520943919999 , 10.944657086000063],
              [-75.05129846499995, 10.945496694000042],
              [-75.05100936899993, 10.945904193000047],
              [-75.05074431899993, 10.946263782000074],
              [-75.0502135129999 , 10.946767715000078],
              [-75.0494627679999 , 10.947208640000042],
              [-75.0490749359999 , 10.947502194000037],
              [-75.04905969099991, 10.94751373300005 ],
              [-75.04902965199994, 10.947561570000062],
              [-75.04878941099992, 10.947944147000044],
              [-75.04864322599991, 10.948041125000088],
              [-75.04836076099991, 10.948228509000046],
              [-75.04799245299995, 10.948472840000079],
              [-75.04743712599992, 10.948881182000036],
              [-75.04707533699991, 10.949264992000053],
              [-75.04644732199995, 10.949649647000058],
              [-75.04540786599995, 10.95003560500004 ],
              [-75.04402957299993, 10.950422634000063],
              [-75.04234696299994, 10.950747191000062],
              [-75.03931234999993, 10.95172510100008 ],
              [-75.03906048399995, 10.951778493000063],
              [-75.03849192799993, 10.951899018000063],
              [-75.0379848309999 , 10.952259359000038],
              [-75.03677608899994, 10.953287647000082],
              [-75.03575666099994, 10.954752646000088],
              [-75.03527723299993, 10.955441621000091],
              [-75.03512228299991, 10.955664294000087],
              [-75.03460783799994, 10.956403583000053],
              [-75.03351871799993, 10.957893285000068],
              [-75.03278888299991, 10.958891553000058],
              [-75.03268003999995, 10.959040427000048],
              [-75.0300900599999 , 10.962755567000045],
              [-75.02592524499994, 10.965997266000045],
              [-75.0220602309999 , 10.96840091100006 ],
              [-75.01878080599994, 10.970529920000047],
              [-75.01493455299993, 10.973026834000052],
              [-75.01241430499994, 10.975317035000046],
              [-75.01222502699994, 10.975505224000074],
              [-75.00880063699992, 10.97738649200005 ],
              [-75.00470796399992, 10.978600018000066],
              [-75.00002042599994, 10.97876658000007 ],
              [-74.99478561199993, 10.978647607000084],
              [-74.99002668999992, 10.978956937000078],
              [-74.9850774109999 , 10.979337650000048],
              [-74.98215067399991, 10.979742159000068],
              [-74.9812902669999 , 10.979815890000054],
              [-74.9811108689999 , 10.979639692000092],
              [-74.9807590559999 , 10.979294156000037],
              [-74.98060402599992, 10.979258599000048],
              [-74.98036731899992, 10.979204310000057],
              [-74.97990650999992, 10.979114664000065],
              [-74.97960598799995, 10.978728768000053],
              [-74.97946595899992, 10.978114866000055],
              [-74.97914213099995, 10.977638022000065],
              [-74.97873590199993, 10.977442118000056],
              [-74.97872649099992, 10.977437575000067],
              [-74.97865236399991, 10.977401827000051],
              [-74.9783984359999 , 10.976975175000064],
              [-74.97811069499994, 10.976621986000055],
              [-74.97803590299992, 10.976418920000071],
              [-74.97803332199993, 10.976137049000044],
              [-74.9780618289999 , 10.976080288000048],
              [-74.97812273699992, 10.975904722000053],
              [-74.97814264399994, 10.97571578700007 ],
              [-74.97814246799993, 10.975656407000088],
              [-74.97814203299993, 10.975513420000084],
              [-74.97814192499993, 10.97547846200007 ],
              [-74.97807443999994, 10.975435936000054],
              [-74.97794532599994, 10.975560276000067],
              [-74.9778689979999 , 10.97570373600007 ],
              [-74.97780800399994, 10.975967971000046],
              [-74.97779852499991, 10.976115832000062],
              [-74.9761809069999 , 10.976883095000062],
              [-74.97587726299992, 10.977073631000053],
              [-74.97553447699994, 10.977369066000051],
              [-74.9751427519999 , 10.977771012000062],
              [-74.97479512399991, 10.978192301000092],
              [-74.97468739999994, 10.978313364000087],
              [-74.97450741699993, 10.97842236200006 ],
              [-74.97424659199993, 10.97852049900007 ],
              [-74.97393432399991, 10.97860051400005 ],
              [-74.97369920199992, 10.978665966000051],
              [-74.9735596129999 , 10.978742270000055],
              [-74.97335758199995, 10.97884764400004 ],
              [-74.97310409999994, 10.97892763100009 ],
              [-74.97255667999991, 10.97901863800007 ],
              [-74.97231052699993, 10.979062314000089],
              [-74.97179987899995, 10.979222284000059],
              [-74.97156474899992, 10.979269584000065],
              [-74.97139940799991, 10.979266033000044],
              [-74.97124139999994, 10.979226166000046],
              [-74.97103561499995, 10.979168169000047],
              [-74.97089230799992, 10.979146453000055],
              [-74.97066084799991, 10.979179232000092],
              [-74.97045145699991, 10.979266453000037],
              [-74.97016493099994, 10.979408170000056],
              [-74.96995553999994, 10.979502653000054],
              [-74.96977184999992, 10.979549929000086],
              [-74.96952935599995, 10.979560929000058],
              [-74.96922437599994, 10.97952476100005 ],
              [-74.9687797819999 , 10.979503176000037],
              [-74.96831683599993, 10.979517901000065],
              [-74.96791269599993, 10.979576165000083],
              [-74.96752694499992, 10.979677988000049],
              [-74.96708610799993, 10.979837917000054],
              [-74.96702165499994, 10.979858489000037],
              [-74.96694577699992, 10.979882711000073],
              [-74.96674445399992, 10.979946978000044],
              [-74.96652034399995, 10.979997899000068],
              [-74.96634765499994, 10.980005238000047],
              [-74.96613454499993, 10.979987178000044],
              [-74.96601611999995, 10.979955835000055],
              [-74.96598388199993, 10.979947305000053],
              [-74.9654438529999 , 10.980147215000045],
              [-74.9652715979999 , 10.980246088000058],
              [-74.9652774349999 , 10.980266106000045],
              [-74.96526061299994, 10.980252393000058],
              [-74.96511603199991, 10.979935098000055],
              [-74.96397265199994, 10.980230034000044],
              [-74.9626516639999 , 10.980570782000086],
              [-74.9622086469999 , 10.980789883000057],
              [-74.96194681299994, 10.980714077000073]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": "08849",
      "properties": {"id": "08849", "iso": "USI", "name": "USIACURÍ"},
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-74.9720532369999 , 10.791564538000046],
              [-74.97194762499993, 10.791504847000056],
              [-74.97180364499991, 10.79151083100004 ],
              [-74.97162962399995, 10.791509154000039],
              [-74.97145515799991, 10.791513193000071],
              [-74.97110008599992, 10.79150616600009 ],
              [-74.9710898219999 , 10.791498311000055],
              [-74.97100279999995, 10.791460118000089],
              [-74.97090422499991, 10.791383693000057],
              [-74.97077947499992, 10.79127922300006 ],
              [-74.97069598799993, 10.791195309000045],
              [-74.97068044799994, 10.791142939000054],
              [-74.97067068399991, 10.791110039000046],
              [-74.97067870399991, 10.790969802000063],
              [-74.97077664299991, 10.79090536700005 ],
              [-74.97084329699993, 10.790878555000063],
              [-74.9708901649999 , 10.790836114000058],
              [-74.97089752799991, 10.790787561000059],
              [-74.97079647399994, 10.790705151000054],
              [-74.97067452899995, 10.79070454300006 ],
              [-74.9705550189999 , 10.790718239000057],
              [-74.9703923859999 , 10.790754803000084],
              [-74.9702295319999 , 10.79075683800005 ],
              [-74.9701216869999 , 10.790661769000053],
              [-74.97011534099994, 10.790656176000084],
              [-74.97007384499994, 10.790532600000063],
              [-74.9700658029999 , 10.790508641000088],
              [-74.97006914899993, 10.790316217000054],
              [-74.97008916199991, 10.790096292000044],
              [-74.97012593199992, 10.789990623000051],
              [-74.97022762499995, 10.789698392000048],
              [-74.97029540799991, 10.789536723000083],
              [-74.9703151999999 , 10.789357024000083],
              [-74.97033587699991, 10.789240662000054],
              [-74.97038166199991, 10.789137715000038],
              [-74.9703903269999 , 10.789063587000044],
              [-74.97040322399994, 10.789009907000093],
              [-74.97040745499993, 10.788955575000045],
              [-74.97038939099991, 10.788904908000063],
              [-74.9703841029999 , 10.788890079000055],
              [-74.9703241069999 , 10.788876883000057],
              [-74.97015386099991, 10.788898475000053],
              [-74.96997051099993, 10.788864398000044],
              [-74.9698367119999 , 10.788753537000048],
              [-74.96978737199993, 10.788565640000058],
              [-74.96978174399993, 10.788420859000041],
              [-74.96975906499995, 10.788338557000088],
              [-74.96967971099991, 10.788237562000063],
              [-74.96963213499993, 10.788213790000043],
              [-74.9695263129999 , 10.788079116000063],
              [-74.96932866399993, 10.788003637000088],
              [-74.96910721099994, 10.788009807000037],
              [-74.96897117899994, 10.787932721000061],
              [-74.96878261699993, 10.787782532000051],
              [-74.9685890959999 , 10.787615206000055],
              [-74.96839722099992, 10.787495439000054],
              [-74.96834216499991, 10.787461070000063],
              [-74.96813968899994, 10.787413399000059],
              [-74.96793025799991, 10.78730470000005 ],
              [-74.96771305199991, 10.787216145000059],
              [-74.96770494499992, 10.787137160000043],
              [-74.96772318399991, 10.78705212500006 ],
              [-74.96779787199995, 10.78695638000005 ],
              [-74.9678060999999 , 10.786887854000042],
              [-74.96784081099992, 10.786815592000039],
              [-74.9678265039999 , 10.786805540000046],
              [-74.96767517899991, 10.786699243000044],
              [-74.96753416899992, 10.786644615000057],
              [-74.9673784339999 , 10.786666669000056],
              [-74.96728513799991, 10.786668201000055],
              [-74.9670811709999 , 10.786669937000056],
              [-74.96696826099992, 10.786623852000048],
              [-74.96682260099993, 10.78654933100006 ],
              [-74.96671413199994, 10.786483411000063],
              [-74.9664775789999 , 10.786378899000056],
              [-74.9662875809999 , 10.786349983000036],
              [-74.96628105199994, 10.786349740000048],
              [-74.96616134099992, 10.78634530700009 ],
              [-74.96608442199994, 10.786342457000046],
              [-74.96591732999991, 10.786318169000083],
              [-74.9656657729999 , 10.786218245000043],
              [-74.96546213399995, 10.78621423900006 ],
              [-74.9653251279999 , 10.786252795000053],
              [-74.9651469229999 , 10.78637182600005 ],
              [-74.96499852099993, 10.786369149000052],
              [-74.96489595499992, 10.786225813000044],
              [-74.96486180599993, 10.78610210000005 ],
              [-74.96474610799993, 10.786015416000055],
              [-74.96453485799992, 10.786033889000066],
              [-74.96451105499995, 10.786037885000042],
              [-74.9643801069999 , 10.786059858000044],
              [-74.96436950299994, 10.786061635000067],
              [-74.96422859699993, 10.785961485000087],
              [-74.96422679599993, 10.78592021500009 ],
              [-74.96422228099993, 10.785816818000058],
              [-74.96412971399991, 10.78569439100005 ],
              [-74.9639152439999 , 10.78560301400006 ],
              [-74.96375900999993, 10.78564405800006 ],
              [-74.96372173399993, 10.785653848000038],
              [-74.9635518739999 , 10.785701448000054],
              [-74.96335405499991, 10.785731775000045],
              [-74.96317923399994, 10.785729957000058],
              [-74.96306287999994, 10.785707463000051],
              [-74.9628832759999 , 10.78567273300007 ],
              [-74.9626588399999 , 10.78555749800006 ],
              [-74.96249708599993, 10.785461474000044],
              [-74.96237438799994, 10.785371806000057],
              [-74.96214500299993, 10.785256357000037],
              [-74.96156068399995, 10.784857030000069],
              [-74.96126328899993, 10.784733845000062],
              [-74.96092823399994, 10.784672301000057],
              [-74.96069694599993, 10.78466781800006 ],
              [-74.96053643499994, 10.784750633000044],
              [-74.95986400099991, 10.785037780000039],
              [-74.95981303899993, 10.785050074000083],
              [-74.95977127899994, 10.78506014900006 ],
              [-74.95948419299992, 10.785129398000038],
              [-74.95914733199993, 10.785157373000061],
              [-74.95878968699992, 10.785098614000049],
              [-74.95875869199995, 10.785122590000071],
              [-74.95869653599993, 10.78517067000007 ],
              [-74.95848630899991, 10.78533328900005 ],
              [-74.95825434799991, 10.785316292000061],
              [-74.95794455099991, 10.785316498000043],
              [-74.95776202499991, 10.785343663000049],
              [-74.95748449999991, 10.785566535000044],
              [-74.95722899899994, 10.78580837900006 ],
              [-74.95702147599991, 10.786030564000043],
              [-74.95677034999994, 10.786209005000046],
              [-74.95657899899993, 10.786281728000063],
              [-74.95649703099991, 10.78620962900004 ],
              [-74.95637351599993, 10.786142641000083],
              [-74.95617189699993, 10.786110454000038],
              [-74.95605677899994, 10.78607177300006 ],
              [-74.95599545699991, 10.786051168000085],
              [-74.95585196999991, 10.78593643000005 ],
              [-74.9556545339999 , 10.78575984300005 ],
              [-74.95560796399991, 10.78567539100004 ],
              [-74.95552543899993, 10.785605667000084],
              [-74.9553734349999 , 10.785530855000047],
              [-74.95525603399994, 10.78554538800006 ],
              [-74.95510594799993, 10.78569641100006 ],
              [-74.95509324199992, 10.785880675000044],
              [-74.95500868199991, 10.786094390000073],
              [-74.95488152299993, 10.78616607300006 ],
              [-74.9547761419999 , 10.786152496000057],
              [-74.95467104799991, 10.78598848100006 ],
              [-74.95458071599995, 10.785779258000048],
              [-74.95446184999992, 10.785562174000063],
              [-74.9543064369999 , 10.785429057000044],
              [-74.95424171699995, 10.785354853000058],
              [-74.95419057899994, 10.785252706000051],
              [-74.95420891299995, 10.785155658000065],
              [-74.95433027699994, 10.785083532000044],
              [-74.95438811899993, 10.78491992700009 ],
              [-74.9543683149999 , 10.784785365000062],
              [-74.95415722399991, 10.78455580700006 ],
              [-74.95406795399992, 10.784416192000037],
              [-74.95406353699991, 10.784311715000058],
              [-74.95415831399993, 10.78420292900006 ],
              [-74.95428425699993, 10.784148481000045],
              [-74.95437325699993, 10.784039256000085],
              [-74.9544145189999 , 10.783862905000092],
              [-74.95442025099993, 10.783695540000053],
              [-74.95439823499993, 10.783508801000039],
              [-74.95436371599993, 10.783425067000053],
              [-74.9543020829999 , 10.78327556700009 ],
              [-74.95414531299991, 10.783102150000047],
              [-74.95397818499993, 10.782910652000055],
              [-74.95395038999993, 10.782723521000037],
              [-74.95394415999993, 10.782561107000049],
              [-74.95379363199993, 10.782382440000049],
              [-74.9536483359999 , 10.78229671300005 ],
              [-74.95355213199991, 10.782174069000064],
              [-74.95355090099991, 10.782023619000086],
              [-74.95356164099991, 10.781868272000054],
              [-74.95355899399993, 10.781711008000059],
              [-74.95350781699995, 10.781568613000047],
              [-74.95349070499992, 10.781521001000044],
              [-74.9533306319999 , 10.781312803000048],
              [-74.9531749919999 , 10.781209036000064],
              [-74.95308820199995, 10.781202755000038],
              [-74.95278280199994, 10.781331003000048],
              [-74.95270640099994, 10.781342817000052],
              [-74.95244191799992, 10.781300552000062],
              [-74.95230290899991, 10.781209563000061],
              [-74.95224378699993, 10.781025028000045],
              [-74.9521952529999 , 10.780934281000043],
              [-74.9521923229999 , 10.780928803000052],
              [-74.9520545179999 , 10.780820566000045],
              [-74.9518295709999 , 10.780711817000054],
              [-74.95158551299994, 10.780711543000052],
              [-74.9514405729999 , 10.780706863000091],
              [-74.9513018159999 , 10.78069683800004 ],
              [-74.95117143699991, 10.780733661000056],
              [-74.95105739699994, 10.78086992100009 ],
              [-74.95093739599992, 10.780924822000088],
              [-74.95086305099994, 10.780907894000052],
              [-74.95078456099992, 10.780699944000048],
              [-74.95067198399994, 10.780564674000061],
              [-74.95062796799994, 10.780445925000038],
              [-74.95060110999992, 10.780247514000052],
              [-74.95066172399993, 10.780043875000047],
              [-74.95075600099995, 10.779940883000052],
              [-74.95087619599991, 10.779799309000055],
              [-74.95092085999994, 10.779658085000051],
              [-74.95093004099994, 10.779525857000067],
              [-74.9508800289999 , 10.779493373000037],
              [-74.9507756189999 , 10.779491645000064],
              [-74.95045995499993, 10.779688475000057],
              [-74.95021250199994, 10.77973998400006 ],
              [-74.95002230199992, 10.779765440000062],
              [-74.94981848599991, 10.77977387900006 ],
              [-74.9496752629999 , 10.779746231000047],
              [-74.9495617259999 , 10.779709173000072],
              [-74.9493464059999 , 10.779716775000054],
              [-74.94915180699991, 10.779760532000068],
              [-74.94901320499991, 10.779750553000042],
              [-74.94884574199995, 10.77973849700004 ],
              [-74.94862048099992, 10.779889776000061],
              [-74.94851782299992, 10.780113423000046],
              [-74.9484794899999 , 10.780330161000052],
              [-74.9483942459999 , 10.78038755600005 ],
              [-74.94823403999993, 10.780272013000058],
              [-74.94809303199992, 10.780048137000051],
              [-74.94799650899995, 10.780018074000054],
              [-74.94777868199992, 10.78014674700006 ],
              [-74.94764879299993, 10.780345286000056],
              [-74.94740294399992, 10.78046037100006 ],
              [-74.94725626399992, 10.780484422000086],
              [-74.94716734399992, 10.780486409000048],
              [-74.94708458199995, 10.780488258000048],
              [-74.94707786899994, 10.780488830000081],
              [-74.94682785999993, 10.780510138000068],
              [-74.946682        , 10.780522696000048],
              [-74.94651010699994, 10.78049948000006 ],
              [-74.94649817399994, 10.780497869000044],
              [-74.94637256099992, 10.780488791000039],
              [-74.9463077929999 , 10.780484110000089],
              [-74.94630082299994, 10.780500933000042],
              [-74.94621026399994, 10.78055213500005 ],
              [-74.94613956899991, 10.780650983000044],
              [-74.94604821199994, 10.780713673000037],
              [-74.94595591699994, 10.780707001000053],
              [-74.94582487899993, 10.780674417000057],
              [-74.94567052899993, 10.780478475000052],
              [-74.94558619399993, 10.78027607200005 ],
              [-74.94551151299993, 10.780184078000048],
              [-74.9454074759999 , 10.780014913000059],
              [-74.94541260199992, 10.77985940900004 ],
              [-74.94546840499993, 10.779724892000047],
              [-74.94554605099995, 10.77960925800005 ],
              [-74.94560467499991, 10.779434537000043],
              [-74.94560857599993, 10.77929627800006 ],
              [-74.94550661199992, 10.77917919500004 ],
              [-74.94544770599992, 10.779111550000039],
              [-74.94539582099992, 10.779107817000067],
              [-74.94518046799993, 10.779369359000043],
              [-74.94499907399995, 10.779477498000062],
              [-74.9449265689999 , 10.779437647000066],
              [-74.9448180409999 , 10.779250923000063],
              [-74.94471678299993, 10.778960874000063],
              [-74.94464043499994, 10.778811131000055],
              [-74.94465021299993, 10.778700787000048],
              [-74.9447163079999 , 10.778530936000038],
              [-74.94478070499991, 10.778356623000093],
              [-74.94477350699992, 10.778211807000048],
              [-74.94469107099991, 10.778148183000042],
              [-74.9445584959999 , 10.778138684000055],
              [-74.94448102799993, 10.778086960000053],
              [-74.94450356599992, 10.778013547000057],
              [-74.94459164299991, 10.777996777000055],
              [-74.94468576899993, 10.777893873000039],
              [-74.94468596899992, 10.777887288000045],
              [-74.9446901039999 , 10.777749910000068],
              [-74.94454457099994, 10.777595227000063],
              [-74.94442181799991, 10.777528744000051],
              [-74.94439339799993, 10.777520938000066],
              [-74.9442735959999 , 10.777495058000056],
              [-74.94410312799994, 10.777482871000075],
              [-74.94410072399995, 10.777482697000039],
              [-74.94393281799995, 10.777482228000054],
              [-74.9438035359999 , 10.777426824000088],
              [-74.94357674699995, 10.77735868600007 ],
              [-74.94330714099993, 10.777241354000068],
              [-74.94314082499994, 10.777137176000053],
              [-74.9429592859999 , 10.777003094000065],
              [-74.94281322299992, 10.776940760000059],
              [-74.94278327799992, 10.776938619000077],
              [-74.9427666059999 , 10.776937429000043],
              [-74.94271783599993, 10.776933945000053],
              [-74.94266927399991, 10.776930478000054],
              [-74.94243088899992, 10.777028776000066],
              [-74.94231474799994, 10.777077304000045],
              [-74.94209259799993, 10.777170125000055],
              [-74.94186241099993, 10.777234405000058],
              [-74.9417840939999 , 10.777271876000043],
              [-74.94170815099994, 10.777308211000047],
              [-74.94166428099993, 10.777437745000043],
              [-74.9416432349999 , 10.77749315400007 ],
              [-74.94162161899993, 10.777550062000046],
              [-74.94151823699991, 10.777558351000039],
              [-74.94138701299994, 10.777531640000063],
              [-74.94132888699994, 10.777371713000036],
              [-74.9411852849999 , 10.777274903000091],
              [-74.94118065999993, 10.777271976000065],
              [-74.94104129699991, 10.777183843000046],
              [-74.9409753889999 , 10.777019713000072],
              [-74.94096259899993, 10.776987863000045],
              [-74.94097680999994, 10.77678700100006 ],
              [-74.94099256999993, 10.776753935000045],
              [-74.94103795399991, 10.776658714000064],
              [-74.94106835699995, 10.776637811000057],
              [-74.94118470299992, 10.776628818000063],
              [-74.94137047999993, 10.776574977000053],
              [-74.94150328199993, 10.77649789800006 ],
              [-74.9415301379999 , 10.776426651000065],
              [-74.94152781899993, 10.77640114400009 ],
              [-74.94151060299993, 10.776211812000042],
              [-74.9414125639999 , 10.77604331300006 ],
              [-74.94105729599994, 10.775818805000085],
              [-74.9410525529999 , 10.775815808000061],
              [-74.94086448399992, 10.775612117000037],
              [-74.94082236399993, 10.775389990000065],
              [-74.94073820599993, 10.77502651900005 ],
              [-74.94061951999993, 10.774660654000058],
              [-74.94052957999992, 10.77456634400005 ],
              [-74.94049571099993, 10.774530819000063],
              [-74.94041033799994, 10.774507421000067],
              [-74.94026881899993, 10.774468636000051],
              [-74.94008519099992, 10.774530560000073],
              [-74.93991292399994, 10.774527661000093],
              [-74.93990214099995, 10.774524092000092],
              [-74.93962481499995, 10.774432309000076],
              [-74.9394903729999 , 10.77429021000006 ],
              [-74.93945478299992, 10.774155342000086],
              [-74.93940449999991, 10.773964798000065],
              [-74.93942144899995, 10.773777259000042],
              [-74.93943141799991, 10.773666950000063],
              [-74.9394330909999 , 10.773387099000047],
              [-74.93944812899991, 10.773175265000077],
              [-74.93945303499993, 10.773106161000044],
              [-74.9394150469999 , 10.772890947000064],
              [-74.93940005299993, 10.772806001000049],
              [-74.93934844099994, 10.772393243000067],
              [-74.93920833499993, 10.772098357000061],
              [-74.93900079199994, 10.771928216000049],
              [-74.93888531599993, 10.771845208000059],
              [-74.93868604999994, 10.77172175600009 ],
              [-74.9384137479999 , 10.77165080900005 ],
              [-74.93813781599994, 10.771631464000052],
              [-74.93797482799994, 10.77155969100005 ],
              [-74.93785776899995, 10.771508144000052],
              [-74.93767731499992, 10.771368948000088],
              [-74.93767709799994, 10.771368780000046],
              [-74.93742788499992, 10.771138244000042],
              [-74.93730469299993, 10.770922332000055],
              [-74.93728945499993, 10.770887136000056],
              [-74.93727002399993, 10.770842254000058],
              [-74.93724310999994, 10.770780087000048],
              [-74.9372352879999 , 10.770762021000053],
              [-74.93718100699994, 10.77070251200007 ],
              [-74.93711748599992, 10.770632873000068],
              [-74.9369102629999 , 10.770543556000064],
              [-74.93669613299994, 10.770551621000038],
              [-74.93639836099993, 10.770680470000059],
              [-74.93609122599992, 10.770941037000057],
              [-74.9358681409999 , 10.77099449800005 ],
              [-74.93567623399991, 10.770935017000056],
              [-74.93548343399993, 10.770926466000049],
              [-74.93545195599995, 10.77092507000009 ],
              [-74.93524329799993, 10.77093923700005 ],
              [-74.93498832799992, 10.770875354000054],
              [-74.93465798599993, 10.770898258000045],
              [-74.9344101339999 , 10.770905112000037],
              [-74.93436335699994, 10.770906405000062],
              [-74.93419250799991, 10.770796632000042],
              [-74.93415770599995, 10.770558316000063],
              [-74.93425728799991, 10.770375431000048],
              [-74.93433186899995, 10.770139030000053],
              [-74.93429460499993, 10.76993509500005 ],
              [-74.9340836579999 , 10.769742076000057],
              [-74.93382602999992, 10.76963784700007 ],
              [-74.93358728399994, 10.769592448000083],
              [-74.93347019399994, 10.769457783000064],
              [-74.93342477099992, 10.76928786600007 ],
              [-74.93340596599995, 10.769148561000065],
              [-74.9333703829999 , 10.76900235000005 ],
              [-74.9333413519999 , 10.768845103000046],
              [-74.93330492199993, 10.768790831000047],
              [-74.93326913399994, 10.768745004000039],
              [-74.93325744899994, 10.768730044000051],
              [-74.93318499399993, 10.768696263000038],
              [-74.93306464099993, 10.76868790900005 ],
              [-74.93296215099991, 10.768715586000042],
              [-74.93277686399995, 10.76876562700005 ],
              [-74.93253130699992, 10.768737075000047],
              [-74.9323316359999 , 10.768631234000054],
              [-74.93232594099993, 10.76855037300004 ],
              [-74.93223806899994, 10.768081092000045],
              [-74.93212070699991, 10.767871805000084],
              [-74.93191743699992, 10.767656603000091],
              [-74.9316941379999 , 10.767480257000045],
              [-74.93157724399993, 10.767265326000086],
              [-74.93146852699994, 10.767096956000046],
              [-74.93133444199992, 10.766961306000042],
              [-74.93124246199994, 10.766880287000049],
              [-74.93106013199991, 10.766775791000043],
              [-74.93092486099994, 10.766657330000044],
              [-74.93086280999995, 10.766480758000057],
              [-74.93091194999994, 10.766357803000062],
              [-74.93098606399991, 10.766272959000048],
              [-74.93112882699995, 10.766208116000087],
              [-74.93126056499995, 10.766136777000042],
              [-74.93138717599993, 10.765909         ],
              [-74.93139280299994, 10.765898876000051],
              [-74.93140758899995, 10.765773530000047],
              [-74.93141174199991, 10.765635966000048],
              [-74.93142040399994, 10.76551594700004 ],
              [-74.9314945029999 , 10.765365967000037],
              [-74.93149335099992, 10.765222306000055],
              [-74.93148646199995, 10.765078259000063],
              [-74.9313630179999 , 10.764954947000092],
              [-74.93132853199995, 10.764881034000041],
              [-74.93127245799991, 10.76476086200006 ],
              [-74.93119810399992, 10.76459497500008 ],
              [-74.93129845499993, 10.764320446000056],
              [-74.9314674769999 , 10.764131019000047],
              [-74.93168556199993, 10.763979372000051],
              [-74.93192081199993, 10.76390929200005 ],
              [-74.93219371799995, 10.763876236000044],
              [-74.9324122129999 , 10.76387965500004 ],
              [-74.93262670599995, 10.763699015000043],
              [-74.93264966899994, 10.763539772000058],
              [-74.93259328999994, 10.76328324900004 ],
              [-74.9324067739999 , 10.763075318000062],
              [-74.9322902259999 , 10.762935326000047],
              [-74.9322215649999 , 10.76276990200006 ],
              [-74.93218062699992, 10.762607519000085],
              [-74.93222530999992, 10.762386613000047],
              [-74.93230515599993, 10.762237004000042],
              [-74.93237025499991, 10.762132329000053],
              [-74.9324627869999 , 10.761966352000059],
              [-74.93247556099993, 10.761708859000066],
              [-74.93238269699992, 10.76154850000006 ],
              [-74.93229685299991, 10.761301548000063],
              [-74.93241511799994, 10.761166850000052],
              [-74.93249251799995, 10.76105147800007 ],
              [-74.9323722819999 , 10.760802201000047],
              [-74.93221027099992, 10.760653478000052],
              [-74.93208265999994, 10.760507087000065],
              [-74.93187240299994, 10.76038959400006 ],
              [-74.93162533799995, 10.760304078000047],
              [-74.93154932499993, 10.760161202000063],
              [-74.9315764239999 , 10.75994492700005 ],
              [-74.93166774799994, 10.759715750000055],
              [-74.93172966199995, 10.75941574300009 ],
              [-74.93171645199993, 10.759266110000056],
              [-74.93163795599992, 10.759157535000043],
              [-74.93153369399994, 10.75908587300006 ],
              [-74.9314899179999 , 10.759055788000069],
              [-74.93122287999995, 10.758928863000051],
              [-74.93117555299995, 10.758707641000058],
              [-74.93116547499994, 10.758448767000061],
              [-74.93095332899992, 10.758279685000048],
              [-74.9306986709999 , 10.758142207000049],
              [-74.93046484399991, 10.758115116000056],
              [-74.93022124799995, 10.758064440000055],
              [-74.93003825399995, 10.757891638000046],
              [-74.9299214749999 , 10.75775767500005 ],
              [-74.92969965999993, 10.757645395000054],
              [-74.92949622099991, 10.757597429000043],
              [-74.92924304699994, 10.757580695000058],
              [-74.92900853299994, 10.757565059000058],
              [-74.92880960399992, 10.757534599000053],
              [-74.9286780779999 , 10.757506230000047],
              [-74.92861724399995, 10.757493109000052],
              [-74.92842738399992, 10.757417390000057],
              [-74.9282730889999 , 10.757326842000055],
              [-74.92809188699994, 10.75721158500005 ],
              [-74.9279436679999 , 10.757079803000067],
              [-74.92792462299991, 10.757062873000052],
              [-74.92763652999992, 10.756917606000059],
              [-74.92743503799994, 10.756898497000066],
              [-74.9271819089999 , 10.756904588000054],
              [-74.92706315099991, 10.756879493000042],
              [-74.9268057939999 , 10.756707624000057],
              [-74.9265945969999 , 10.756504432000042],
              [-74.9263410019999 , 10.756281258000058],
              [-74.92627098899993, 10.756139077000057],
              [-74.92625746899995, 10.755851651000057],
              [-74.92622869899992, 10.755695030000084],
              [-74.92605099799994, 10.755534295000075],
              [-74.92602288699993, 10.755257013000062],
              [-74.92609703699992, 10.754989465000051],
              [-74.92614242299993, 10.754480227000045],
              [-74.92625034199995, 10.754017101000045],
              [-74.92635903799993, 10.753831671000057],
              [-74.92660836199991, 10.753722998000057],
              [-74.92677951199994, 10.753552823000064],
              [-74.92696617299993, 10.75335175300006 ],
              [-74.92719924799991, 10.753011706000052],
              [-74.92743258799993, 10.752764224000089],
              [-74.9276191589999 , 10.752532299000052],
              [-74.92755644599993, 10.752542113000061],
              [-74.92761879199992, 10.752491932000055],
              [-74.92780662299992, 10.752352758000086],
              [-74.92797124799995, 10.752264025000045],
              [-74.92819548799991, 10.75216110200006 ],
              [-74.92865468899993, 10.75204235700005 ],
              [-74.92879371499993, 10.751976936000062],
              [-74.92906086399995, 10.75184741600009 ],
              [-74.92937501199992, 10.751675762000048],
              [-74.9295246659999 , 10.75158986300005 ],
              [-74.92971311299993, 10.751470761000064],
              [-74.9298851019999 , 10.751328578000084],
              [-74.92993466799993, 10.751264897000055],
              [-74.9300097009999 , 10.751168497000037],
              [-74.93011203099991, 10.750962926000057],
              [-74.93019232299991, 10.75085262500005 ],
              [-74.9303102049999 , 10.750752013000067],
              [-74.93038819799995, 10.75067692400006 ],
              [-74.93040672699993, 10.750659085000052],
              [-74.93043764599992, 10.750636826000061],
              [-74.93073756799993, 10.750158211000041],
              [-74.93079300999995, 10.750069736000057],
              [-74.9311981649999 , 10.749483516000055],
              [-74.93136037499994, 10.749322219000078],
              [-74.9316038309999 , 10.749080135000042],
              [-74.93215639399995, 10.748310661000062],
              [-74.93274613999995, 10.74761422000006 ],
              [-74.9332994209999 , 10.747100726000042],
              [-74.93374175399993, 10.746587541000054],
              [-74.9339995919999 , 10.746221134000052],
              [-74.9344604449999 , 10.745783127000038],
              [-74.93492143899994, 10.74526779100006 ],
              [-74.93540012799991, 10.744535085000052],
              [-74.93562066899995, 10.744059074000063],
              [-74.9357314479999 , 10.74357062300004 ],
              [-74.93450317499992, 10.74282363900005 ],
              [-74.93432653299993, 10.742913333000047],
              [-74.9343007789999 , 10.742900945000088],
              [-74.93380007999991, 10.742660125000043],
              [-74.93362196499993, 10.742453222000051],
              [-74.93333736299991, 10.742224265000061],
              [-74.93301079199995, 10.741954159000045],
              [-74.93242828599995, 10.741986724000071],
              [-74.93232841299994, 10.741871850000052],
              [-74.9322370939999 , 10.741673676000062],
              [-74.93216898399993, 10.741549423000038],
              [-74.93208601299995, 10.741222011000048],
              [-74.93214276999993, 10.740782078000052],
              [-74.93214351799992, 10.740365431000043],
              [-74.93210109299991, 10.74030446100005 ],
              [-74.9314825379999 , 10.739890592000052],
              [-74.93137102599991, 10.739692976000072],
              [-74.93133370899994, 10.739562160000048],
              [-74.9313262949999 , 10.739419216000044],
              [-74.93135550399995, 10.739234285000066],
              [-74.93140060799993, 10.739072540000052],
              [-74.93139622799993, 10.738698914000054],
              [-74.93135194899992, 10.738508505000084],
              [-74.9319089569999 , 10.738415449000058],
              [-74.93236046299995, 10.737905377000061],
              [-74.93244102199992, 10.73786778200008 ],
              [-74.93262882399995, 10.737780141000087],
              [-74.93308597799995, 10.737487377000036],
              [-74.9334769969999 , 10.737236968000047],
              [-74.93380409699995, 10.73702434900008 ],
              [-74.9340205119999 , 10.736883677000037],
              [-74.93439721199991, 10.736669990000053],
              [-74.9345630059999 , 10.736562323000044],
              [-74.93461181999993, 10.736497329000088],
              [-74.93478119999992, 10.73629574000006 ],
              [-74.93488965599994, 10.736148978000074],
              [-74.93509453099995, 10.735914524000066],
              [-74.93528313199994, 10.735765186000037],
              [-74.93547974499995, 10.73539504200005 ],
              [-74.9360034689999 , 10.734645409000052],
              [-74.93631077799995, 10.734177883000086],
              [-74.9365315469999 , 10.733925828000054],
              [-74.93660365799991, 10.733814584000072],
              [-74.93662216999991, 10.733721174000038],
              [-74.93660343399995, 10.73358341700009 ],
              [-74.9363265209999 , 10.733352216000071],
              [-74.93629534799993, 10.733331043000078],
              [-74.9360886799999 , 10.733190665000052],
              [-74.93599904899992, 10.733129783000038],
              [-74.93590973699992, 10.73306911800006 ],
              [-74.9357968029999 , 10.732965332000049],
              [-74.93496246099994, 10.732198569000047],
              [-74.9348791999999 , 10.732033845000046],
              [-74.93484555299995, 10.73196727800007 ],
              [-74.93468558099994, 10.730714714000044],
              [-74.93462711799992, 10.730136917000038],
              [-74.93456870199992, 10.729528653000045],
              [-74.93456484299992, 10.729112422000071],
              [-74.93461232699991, 10.728560325000046],
              [-74.93463952499991, 10.728425300000083],
              [-74.93466426499992, 10.72830248300005 ],
              [-74.93475027999995, 10.727675839000085],
              [-74.93475413199991, 10.727647774000047],
              [-74.93475912299994, 10.727636480000058],
              [-74.9349381799999 , 10.727231291000066],
              [-74.93499801999991, 10.727095878000057],
              [-74.93511410799994, 10.726969121000081],
              [-74.93518301199992, 10.726893884000049],
              [-74.93552316699993, 10.726519618000054],
              [-74.93578387399992, 10.726289157000053],
              [-74.9361190919999 , 10.725901406000048],
              [-74.93623240599993, 10.725727927000037],
              [-74.93644086899991, 10.725393205000046],
              [-74.93653648599991, 10.725206609000054],
              [-74.93670213199994, 10.724599324000053],
              [-74.9367184919999 , 10.724446139000065],
              [-74.93675742799991, 10.72397469300006 ],
              [-74.93681153599994, 10.723704656000052],
              [-74.93681780199995, 10.723673384000051],
              [-74.9368242999999 , 10.723667376000037],
              [-74.93697396399995, 10.723528973000043],
              [-74.93714082299994, 10.723380002000056],
              [-74.93721904299991, 10.723264800000038],
              [-74.93725297799995, 10.723155788000042],
              [-74.93724735799992, 10.72299914100006 ],
              [-74.93722592899991, 10.722534151000048],
              [-74.9372141579999 , 10.722246320000067],
              [-74.93723285399994, 10.722093939000047],
              [-74.93724809799994, 10.722054668000055],
              [-74.93728150299995, 10.721968612000069],
              [-74.93736670699991, 10.721774536000055],
              [-74.9374765579999 , 10.72162884700009 ],
              [-74.93764505199994, 10.721423857000048],
              [-74.93797939899991, 10.721111954000037],
              [-74.93803237499992, 10.721062534000055],
              [-74.93832062299992, 10.720792373000052],
              [-74.93875035499991, 10.720459178000056],
              [-74.9389222559999 , 10.720139832000086],
              [-74.93906886699995, 10.719830796000053],
              [-74.93931942499995, 10.719544399000085],
              [-74.93938945899993, 10.719401516000062],
              [-74.93943636599994, 10.719275780000089],
              [-74.93945947599991, 10.719213830000058],
              [-74.93952175999993, 10.719061260000046],
              [-74.93956146399995, 10.718957482000064],
              [-74.93966073799993, 10.718793220000066],
              [-74.93972951199993, 10.71868512900005 ],
              [-74.93986861699995, 10.718554641000082],
              [-74.9399267149999 , 10.718500143000085],
              [-74.94009133199995, 10.71836029800005 ],
              [-74.94110477599992, 10.717499364000048],
              [-74.94135784799994, 10.717227510000043],
              [-74.94151244099993, 10.717029394000065],
              [-74.94171550699991, 10.716635087000043],
              [-74.94184298599993, 10.716209247000052],
              [-74.94191150899991, 10.715970275000075],
              [-74.94192865899993, 10.715815650000081],
              [-74.94195845699994, 10.715546991000053],
              [-74.9421022759999 , 10.71497419700006 ],
              [-74.94211282799995, 10.714144547000046],
              [-74.94175023499992, 10.713711596000053],
              [-74.94126844099992, 10.713381411000057],
              [-74.94089740299995, 10.713343819000045],
              [-74.9405048129999 , 10.71322768500005 ],
              [-74.94024261899995, 10.713141981000092],
              [-74.93987689099993, 10.712934443000051],
              [-74.93938910199995, 10.712658071000078],
              [-74.93933974999993, 10.712630109000088],
              [-74.93889441199991, 10.712430396000059],
              [-74.93828694099994, 10.712112988000058],
              [-74.93800973399993, 10.711953064000056],
              [-74.93796563799992, 10.711931280000044],
              [-74.9375312809999 , 10.71171670700005 ],
              [-74.93708931399993, 10.711486557000057],
              [-74.93671862199994, 10.711356056000056],
              [-74.9361511489999 , 10.71126558700007 ],
              [-74.93568024199993, 10.711239852000062],
              [-74.93521076699994, 10.71121751000004 ],
              [-74.93518903499995, 10.71121647700005 ],
              [-74.93486593499995, 10.711253499000065],
              [-74.93457187599995, 10.71128719300009 ],
              [-74.93425013099994, 10.711324059000049],
              [-74.93348392699994, 10.711385936000056],
              [-74.93281787699993, 10.711454656000058],
              [-74.93250847299993, 10.711446203000037],
              [-74.93253229999993, 10.711380747000078],
              [-74.93261938099994, 10.711244780000072],
              [-74.93271706399992, 10.711092259000054],
              [-74.93294708499991, 10.710544372000072],
              [-74.93301267699991, 10.710418359000073],
              [-74.93309907499992, 10.710313147000079],
              [-74.9332040409999 , 10.710212625000054],
              [-74.93328809899992, 10.710111994000044],
              [-74.93338236099993, 10.710030945000085],
              [-74.93347927899993, 10.70994761500009 ],
              [-74.93358417199994, 10.709863175000066],
              [-74.93367055499994, 10.709762554000065],
              [-74.93370605899992, 10.709687358000053],
              [-74.93372682699993, 10.709643374000052],
              [-74.93373895399992, 10.709526262000054],
              [-74.93374883599995, 10.709390753000037],
              [-74.93376796199993, 10.709266786000057],
              [-74.93376319999993, 10.709212393000087],
              [-74.93375689299995, 10.709140343000058],
              [-74.93375098199994, 10.709015761000046],
              [-74.93374838799991, 10.708961075000047],
              [-74.93378403999992, 10.708775151000054],
              [-74.93385659299992, 10.708573295000065],
              [-74.93387118099992, 10.708532707000074],
              [-74.93416730699994, 10.707708824000065],
              [-74.93456817899994, 10.706606872000066],
              [-74.93460692999992, 10.706500350000056],
              [-74.93460789099993, 10.706497770000055],
              [-74.9346817359999 , 10.70629998000004 ],
              [-74.93468175899994, 10.70629991900006 ],
              [-74.93498531599994, 10.705486850000057],
              [-74.93531355199991, 10.704586457000062],
              [-74.93537731999993, 10.704411533000041],
              [-74.93541081599994, 10.704319650000059],
              [-74.93547094099995, 10.70416053300005 ],
              [-74.93589254599993, 10.70304477600007 ],
              [-74.93591123399995, 10.70303141100004 ],
              [-74.93601369899994, 10.702958126000055],
              [-74.93611150999993, 10.702901231000055],
              [-74.93626051599995, 10.702819334000083],
              [-74.93641424899994, 10.70271908500007 ],
              [-74.93665910899995, 10.702495257000066],
              [-74.93673276299995, 10.70241631500005 ],
              [-74.93685746599994, 10.702282659000048],
              [-74.93690995199995, 10.702216437000061],
              [-74.93699526199993, 10.702108801000065],
              [-74.93710755599994, 10.701925603000063],
              [-74.93724340499995, 10.701664408000056],
              [-74.93738388799994, 10.701407840000059],
              [-74.93759353299993, 10.701268836000054],
              [-74.93782038499995, 10.701178953000067],
              [-74.93786809099993, 10.701160051000045],
              [-74.93808202699995, 10.701103791000037],
              [-74.9382888529999 , 10.70107736500006 ],
              [-74.93847008099993, 10.701064576000078],
              [-74.93865821299994, 10.701065609000068],
              [-74.93876675399991, 10.701079812000046],
              [-74.93884160199991, 10.70108960400006 ],
              [-74.93858449299995, 10.69962546000005 ],
              [-74.93848691199992, 10.69906980400009 ],
              [-74.93848163699994, 10.699039755000058],
              [-74.93836383999991, 10.69836889000004 ],
              [-74.9383635499999 , 10.698367399000063],
              [-74.93836353599994, 10.698367322000081],
              [-74.93836352199992, 10.698367246000089],
              [-74.93834894299994, 10.698292025000057],
              [-74.93834869999995, 10.698290777000068],
              [-74.93821308799994, 10.69759072800008 ],
              [-74.9382046099999 , 10.697546982000063],
              [-74.93818108999994, 10.69753058100008 ],
              [-74.9380614339999 , 10.697447141000055],
              [-74.93776871199992, 10.697173942000063],
              [-74.93744817699991, 10.696893639000052],
              [-74.93738595899993, 10.69683722700006 ],
              [-74.93709313999994, 10.696571733000042],
              [-74.93657419499993, 10.696129169000073],
              [-74.93603172399992, 10.695725450000054],
              [-74.93574100799992, 10.695491300000072],
              [-74.93558448899995, 10.695317878000083],
              [-74.93547751799991, 10.695046123000054],
              [-74.93545559299992, 10.69488294100006 ],
              [-74.9354737779999 , 10.694639707000078],
              [-74.9355312159999 , 10.694426632000045],
              [-74.9355676319999 , 10.694227167000065],
              [-74.93560863599993, 10.694034628000054],
              [-74.93564253599993, 10.693860397000037],
              [-74.93564884599994, 10.693651514000067],
              [-74.93564216999994, 10.693614723000053],
              [-74.93589598099993, 10.693524856000067],
              [-74.9362617399999 , 10.693373886000074],
              [-74.9366792749999 , 10.693136048000042],
              [-74.93673266699994, 10.693138756000053],
              [-74.93678839199993, 10.69313917900007 ],
              [-74.93684643199992, 10.693139642000062],
              [-74.93689516199993, 10.693144615000051],
              [-74.93694390299993, 10.69314729000007 ],
              [-74.9370018759999 , 10.693156919000046],
              [-74.93704825099991, 10.693166464000058],
              [-74.9370969279999 , 10.69317602700005 ],
              [-74.9371548819999 , 10.693187962000081],
              [-74.9372058479999 , 10.69320212200006 ],
              [-74.93725446099995, 10.69322087900008 ],
              [-74.93730075999991, 10.693237313000054],
              [-74.93735402699991, 10.693256093000059],
              [-74.93740031999994, 10.693274820000056],
              [-74.93744899399991, 10.693286683000053],
              [-74.9375045509999 , 10.693307785000059],
              [-74.93755780599992, 10.69332655900007 ],
              [-74.93761565299991, 10.693352262000076],
              [-74.93765730099994, 10.69337095800006 ],
              [-74.93770588199993, 10.693394289000082],
              [-74.93775211899992, 10.693419904000052],
              [-74.9377937459999 , 10.693440896000084],
              [-74.93783769499993, 10.693461898000066],
              [-74.93788625399992, 10.693487536000077],
              [-74.93793250099992, 10.693513152000037],
              [-74.93798110499995, 10.693531893000056],
              [-74.93803202799995, 10.693552958000055],
              [-74.93807599199994, 10.693571663000057],
              [-74.93812690999994, 10.693592722000062],
              [-74.93817090099992, 10.69360914200007 ],
              [-74.93822185499994, 10.693625606000069],
              [-74.93827745199991, 10.693642111000088],
              [-74.93832839999993, 10.69365857400004 ],
              [-74.93837703499992, 10.69367502600005 ],
              [-74.93842801099993, 10.693689195000047],
              [-74.9384720139999 , 10.693703320000054],
              [-74.93853223299993, 10.693722153000067],
              [-74.93858785399993, 10.693736372000046],
              [-74.9386388069999 , 10.693752824000057],
              [-74.93868749599994, 10.693762391000064],
              [-74.93873851699993, 10.69376967900007 ],
              [-74.93878492399995, 10.693774630000064],
              [-74.93884986199993, 10.693784311000059],
              [-74.93890554599994, 10.693789335000076],
              [-74.9389658259999 , 10.693801291000057],
              [-74.9390353579999 , 10.693817901000045],
              [-74.93909568499993, 10.693822958000055],
              [-74.93917918499994, 10.693835094000065],
              [-74.93923487299992, 10.693840118000082],
              [-74.93929285199994, 10.693849746000069],
              [-74.9393415699999 , 10.693854719000058],
              [-74.93939255799995, 10.693866598000056],
              [-74.93945058499992, 10.69386933900006 ],
              [-74.93950863499992, 10.69386979400008 ],
              [-74.93955973599992, 10.693867897000075],
              [-74.93961081999993, 10.693868287000043],
              [-74.93966422799991, 10.693868709000071],
              [-74.93972230299994, 10.693864578000046],
              [-74.93977806599992, 10.69386041100006 ],
              [-74.93983845799994, 10.69385857900005 ],
              [-74.93989191599991, 10.693852112000059],
              [-74.93994537499992, 10.69384563500006 ],
              [-74.9400034869999 , 10.693836906000058],
              [-74.9400500349999 , 10.693823487000088],
              [-74.94010581499992, 10.693817032000084],
              [-74.94016860199991, 10.693806045000088],
              [-74.94021741699993, 10.69379953500004 ],
              [-74.94027792399993, 10.693783929000062],
              [-74.94033372699994, 10.69377288000004 ],
              [-74.94038720399993, 10.693764117000057],
              [-74.94043834199994, 10.693757628000071],
              [-74.94049417199994, 10.693744284000047],
              [-74.94054768799992, 10.693730920000064],
              [-74.94059886699995, 10.69371983800005 ],
              [-74.94065463699991, 10.693713383000045],
              [-74.94072206699991, 10.693702432000066],
              [-74.94078716599995, 10.693691456000067],
              [-74.9408383729999 , 10.69367578300006 ],
              [-74.94088721299994, 10.693666979000056],
              [-74.94093847699992, 10.693644419000066],
              [-74.94098966799993, 10.69363104200005 ],
              [-74.94103356399995, 10.693658932000062],
              [-74.9410493769999 , 10.693714156000055],
              [-74.94106517799992, 10.69376937900006 ],
              [-74.94108102599995, 10.69382231700007 ],
              [-74.94109922399991, 10.69387067200006 ],
              [-74.94111978699993, 10.69391216200006 ],
              [-74.94114267799995, 10.693953659000044],
              [-74.94116088999994, 10.693999720000079],
              [-74.9411907089999 , 10.694045871000071],
              [-74.94122515499993, 10.694094355000061],
              [-74.94125734799991, 10.694133633000092],
              [-74.94128024499992, 10.694175142000063],
              [-74.9413124379999 , 10.694214419000048],
              [-74.94134928199992, 10.694253738000043],
              [-74.94139768899993, 10.69429774200006 ],
              [-74.94143686899992, 10.694334775000073],
              [-74.94147606999991, 10.694369523000091],
              [-74.94151292599992, 10.69440654500005 ],
              [-74.94155911099995, 10.694439050000085],
              [-74.94160531599994, 10.694469256000048],
              [-74.9416538349999 , 10.694499475000043],
              [-74.94169773099992, 10.694527366000045],
              [-74.94174857599995, 10.694557605000057],
              [-74.94179483799991, 10.694580926000071],
              [-74.94183878199993, 10.694601938000062],
              [-74.94188736699994, 10.694625269000085],
              [-74.9419382609999 , 10.694648631000064],
              [-74.94198691499992, 10.694662778000065],
              [-74.94203088499995, 10.694681494000065],
              [-74.94207947299992, 10.69470252700006 ],
              [-74.9421234479999 , 10.694721234000042],
              [-74.94216506399994, 10.694744521000075],
              [-74.94221136599992, 10.69476095400006 ],
              [-74.94225301099993, 10.694779649000054],
              [-74.94232016399991, 10.694803127000057],
              [-74.94237346699992, 10.694817313000044],
              [-74.9424290849999 , 10.694831524000051],
              [-74.94248467599994, 10.694848025000056],
              [-74.94253566499992, 10.694859897000072],
              [-74.94258664099993, 10.694874071000072],
              [-74.94263530399991, 10.694888220000053],
              [-74.94269319599994, 10.694907042000068],
              [-74.94274648499993, 10.694921237000074],
              [-74.94279285099992, 10.694930773000067],
              [-74.94284852099992, 10.694938093000076],
              [-74.94289493999992, 10.694940746000043],
              [-74.94294130399993, 10.694950291000055],
              [-74.94299467199994, 10.69495530200004 ],
              [-74.9430503879999 , 10.694958020000058],
              [-74.94310841399994, 10.694960772000059],
              [-74.94316181699992, 10.694961190000072],
              [-74.94323615199994, 10.69495717600006 ],
              [-74.94328960999991, 10.69495070000005 ],
              [-74.9433360829999 , 10.69494646700008 ],
              [-74.9433825189999 , 10.694946825000045],
              [-74.94342899199995, 10.694942600000047],
              [-74.94348704299995, 10.69494304400007 ],
              [-74.94354276799993, 10.694943476000049],
              [-74.94359849799991, 10.694943907000038],
              [-74.94366350999991, 10.69494441200004 ],
              [-74.94372383199993, 10.69495177500005 ],
              [-74.94377718999993, 10.694956778000062],
              [-74.94382587699994, 10.694966341000054],
              [-74.94387222299991, 10.694978178000042],
              [-74.9439185519999 , 10.694992313000057],
              [-74.94398574199994, 10.695011201000057],
              [-74.94403206599992, 10.695025333000046],
              [-74.9440992609999 , 10.695044230000065],
              [-74.9441525169999 , 10.695063017000052],
              [-74.94420582399994, 10.695074901000055],
              [-74.94425675899993, 10.69509366200009 ],
              [-74.94431699299992, 10.69511250000005 ],
              [-74.94436329299992, 10.695128930000067],
              [-74.9444141859999 , 10.695154580000064],
              [-74.94446508999994, 10.695177930000057],
              [-74.94451127699995, 10.695208137000066],
              [-74.94456444799994, 10.695238399000061],
              [-74.9446013779999 , 10.695266237000055],
              [-74.94464985299993, 10.695303352000053],
              [-74.94468675899992, 10.695333476000087],
              [-74.94473065499994, 10.695361375000061],
              [-74.94477453399992, 10.695391562000054],
              [-74.9448183639999 , 10.695428628000059],
              [-74.94485986299992, 10.695465690000049],
              [-74.94490367499992, 10.695505062000052],
              [-74.94493824299991, 10.695537473000059],
              [-74.9449728379999 , 10.69556758400006 ],
              [-74.94500508599992, 10.69559998200009 ],
              [-74.9450395909999 , 10.695641575000082],
              [-74.9450787589999 , 10.695680914000093],
              [-74.94511786299995, 10.695727132000059],
              [-74.94515005599993, 10.695766417000073],
              [-74.94518924199991, 10.695803459000047],
              [-74.9452260999999 , 10.695840471000054],
              [-74.94526064899992, 10.69587518700007 ],
              [-74.94530215799995, 10.695912240000041],
              [-74.94533439299994, 10.69594692600009 ],
              [-74.94537825299994, 10.695979417000046],
              [-74.9454128459999 , 10.696009540000091],
              [-74.9454520029999 , 10.696048860000076],
              [-74.94548420299992, 10.69608814600008 ],
              [-74.9455233939999 , 10.696122891000073],
              [-74.9455579609999 , 10.696157589000052],
              [-74.94559719199992, 10.696187743000053],
              [-74.9456363939999 , 10.696222491000071],
              [-74.94568487599992, 10.696257308000042],
              [-74.94573335599995, 10.696292121000056],
              [-74.94577490699993, 10.696324582000045],
              [-74.94582107299993, 10.696359380000047],
              [-74.94586728999991, 10.696387291000065],
              [-74.94591118699992, 10.696415183000056],
              [-74.94596205699992, 10.696443143000067],
              [-74.94600134199993, 10.696466390000069],
              [-74.94604525499994, 10.69649200300006 ],
              [-74.94609612099993, 10.696519938000051],
              [-74.9461399839999 , 10.696552420000046],
              [-74.94618392299992, 10.696575708000069],
              [-74.94623017099991, 10.696599037000055],
              [-74.94624865899993, 10.696610665000037],
              [-74.94633940299991, 10.696654349000084],
              [-74.94635044199993, 10.696659663000048],
              [-74.94637590199994, 10.69667757600007 ],
              [-74.94655028299991, 10.696603476000064],
              [-74.94649919599993, 10.696573758000056],
              [-74.9466116449999 , 10.696517046000054],
              [-74.94701075099994, 10.696260688000052],
              [-74.94750108999995, 10.695915504000084],
              [-74.94813305299994, 10.695575998000038],
              [-74.94870138499994, 10.695355392000067],
              [-74.9489111709999 , 10.695301653000058],
              [-74.9495017879999 , 10.69515036000007 ],
              [-74.95044516899992, 10.695070418000057],
              [-74.95146572099992, 10.694919882000079],
              [-74.95226774299994, 10.694804401000056],
              [-74.95228718099992, 10.694801856000083],
              [-74.95307896599991, 10.694698168000059],
              [-74.95339960799993, 10.694641373000081],
              [-74.95343069499995, 10.694635865000066],
              [-74.95355099499994, 10.694614566000041],
              [-74.95355291899995, 10.69461414500006 ],
              [-74.95429300399991, 10.694452693000073],
              [-74.95506267099995, 10.694316298000047],
              [-74.95549975999995, 10.694246190000058],
              [-74.95597388699991, 10.694190149000065],
              [-74.9562436569999 , 10.694124428000066],
              [-74.95626664499991, 10.694118829000047],
              [-74.9562681509999 , 10.694118463000052],
              [-74.95634845899991, 10.694098908000058],
              [-74.9565767279999 , 10.69400883500009 ],
              [-74.9565526849999 , 10.693725390000054],
              [-74.95654869799995, 10.69367838700009 ],
              [-74.95651299699995, 10.693257565000067],
              [-74.9567547549999 , 10.69309145300008 ],
              [-74.95694257199995, 10.692962403000081],
              [-74.95736999199994, 10.692646565000075],
              [-74.95770669699994, 10.692350686000054],
              [-74.95780792699992, 10.692256489000044],
              [-74.95801351199992, 10.692065187000082],
              [-74.95802482099992, 10.692054664000068],
              [-74.95926593199994, 10.692949828000053],
              [-74.95943092899995, 10.693068834000087],
              [-74.9598887699999 , 10.693017263000058],
              [-74.96030327599993, 10.693002927000066],
              [-74.96032310399994, 10.693002241000045],
              [-74.9607972039999 , 10.69295078600004 ],
              [-74.96110042799995, 10.692830771000047],
              [-74.96119549999992, 10.692793143000074],
              [-74.96158908899992, 10.692644641000072],
              [-74.96213877999992, 10.692519511000057],
              [-74.96213886899994, 10.692519492000088],
              [-74.96259399799993, 10.692415887000038],
              [-74.96375272299991, 10.692114855000057],
              [-74.96423865799994, 10.691994842000042],
              [-74.9642388929999 , 10.691994777000048],
              [-74.9642755559999 , 10.691985723000073],
              [-74.96427360299992, 10.691990024000063],
              [-74.96435242399991, 10.691937834000043],
              [-74.96448010699993, 10.69174391200005 ],
              [-74.96470077399994, 10.691437948000043],
              [-74.96494279099994, 10.691028087000063],
              [-74.96498552499992, 10.690955693000092],
              [-74.96516967199994, 10.69057138800008 ],
              [-74.96533416999995, 10.690322396000056],
              [-74.96564756399994, 10.69003551700007 ],
              [-74.96607226899994, 10.689767867000057],
              [-74.96658474999992, 10.689558271000067],
              [-74.96690257799992, 10.689298978000068],
              [-74.96705860899993, 10.688944312000046],
              [-74.96725236599991, 10.68822257100004 ],
              [-74.9674447669999 , 10.687376843000038],
              [-74.96763565999993, 10.68642778900005 ],
              [-74.96760636599993, 10.68587559100007 ],
              [-74.96760532099995, 10.685855871000058],
              [-74.96746571699993, 10.685301465000066],
              [-74.96732149899992, 10.684742448000065],
              [-74.96726318099991, 10.684186382000064],
              [-74.96723354999995, 10.683524918000046],
              [-74.9672636329999 , 10.68294657000007 ],
              [-74.9674259709999 , 10.682676891000085],
              [-74.96754081299991, 10.682489443000065],
              [-74.96777878099994, 10.682101026000055],
              [-74.96832071499995, 10.681687329000056],
              [-74.96852323099995, 10.681514991000086],
              [-74.96865861599991, 10.68139977900006 ],
              [-74.96865869999993, 10.681399708000072],
              [-74.96869238399995, 10.681371046000038],
              [-74.96931627999993, 10.680969385000083],
              [-74.96990824699992, 10.680588281000041],
              [-74.9705079009999 , 10.681512417000079],
              [-74.97076167699993, 10.681903513000066],
              [-74.97106831299993, 10.682390435000059],
              [-74.97127309399991, 10.682715616000053],
              [-74.97143295199993, 10.682909352000081],
              [-74.9720643089999 , 10.683674498000073],
              [-74.97264258199993, 10.684252916000048],
              [-74.97321153099995, 10.684835864000092],
              [-74.97365924499991, 10.68518597700006 ],
              [-74.97400497599995, 10.685478310000065],
              [-74.97402369299994, 10.685494136000045],
              [-74.97463600099991, 10.68587765500007 ],
              [-74.97508161299993, 10.686158688000091],
              [-74.97508165399995, 10.686158714000044],
              [-74.97521826499991, 10.686244868000074],
              [-74.97635933499993, 10.687006677000056],
              [-74.97635646499992, 10.687009279000051],
              [-74.97411666199991, 10.68903978600008 ],
              [-74.9751332379999 , 10.689822068000069],
              [-74.97696343599995, 10.691230441000073],
              [-74.9769829899999 , 10.691245489000039],
              [-74.97709407399992, 10.69133097200006 ],
              [-74.9777987409999 , 10.692080246000046],
              [-74.97779631499992, 10.692086630000063],
              [-74.97774657299993, 10.692217595000045],
              [-74.9777156689999 , 10.69228002300008 ],
              [-74.97766164899991, 10.692389148000075],
              [-74.9775953919999 , 10.692473882000058],
              [-74.97751953399995, 10.69257089000007 ],
              [-74.97749746599993, 10.69259911100005 ],
              [-74.97742478899994, 10.692670112000087],
              [-74.97681421699991, 10.693266602000051],
              [-74.97682255399991, 10.693283629000064],
              [-74.97698138499993, 10.693608001000086],
              [-74.97920039699994, 10.698139781000066],
              [-74.97947299399993, 10.698079138000082],
              [-74.97977101399994, 10.698048078000056],
              [-74.98017501899994, 10.698112528000081],
              [-74.98034996299992, 10.698185607000084],
              [-74.98050166399992, 10.69824897500007 ],
              [-74.9809968589999 , 10.69852551100007 ],
              [-74.98128281599992, 10.698660693000079],
              [-74.98153168099992, 10.698778339000057],
              [-74.98152144699992, 10.698825301000056],
              [-74.98147108099994, 10.699056449000068],
              [-74.98145983899991, 10.699108024000054],
              [-74.98145775599994, 10.699117586000057],
              [-74.9814537069999 , 10.699136165000084],
              [-74.98143488099993, 10.699222554000073],
              [-74.98140620499993, 10.699445152000067],
              [-74.98137696799995, 10.69969076600006 ],
              [-74.98127941199994, 10.699881709000067],
              [-74.98116223199992, 10.700210298000059],
              [-74.98102307199991, 10.700470143000075],
              [-74.98094810599991, 10.700610127000061],
              [-74.98092641099993, 10.700651745000073],
              [-74.98242430699992, 10.701543640000068],
              [-74.98274526599994, 10.701605580000091],
              [-74.9833704749999 , 10.701726226000062],
              [-74.98395759399995, 10.70164290200006 ],
              [-74.98448291299991, 10.701568346000045],
              [-74.9846823769999 , 10.701642836000076],
              [-74.9848689289999 , 10.701712504000056],
              [-74.98495082599993, 10.701743089000047],
              [-74.98494038599995, 10.701755373000083],
              [-74.98501032999991, 10.701813556000047],
              [-74.98502049199993, 10.70180160600006 ],
              [-74.98503369799994, 10.701786063000043],
              [-74.98504043999992, 10.701776916000085],
              [-74.98504064799994, 10.701776633000065],
              [-74.98506480299994, 10.70174385900009 ],
              [-74.98509905999992, 10.701701318000062],
              [-74.98513141899991, 10.701659823000057],
              [-74.98517007799995, 10.701604108000083],
              [-74.98520373899993, 10.701564508000047],
              [-74.98523383899993, 10.701525162000053],
              [-74.98526889899995, 10.70147705900007 ],
              [-74.98529670699992, 10.701440730000058],
              [-74.98533597799991, 10.70138780700006 ],
              [-74.98536109899993, 10.701349759000038],
              [-74.98539146099995, 10.701310831000058],
              [-74.98541742999993, 10.701271529000053],
              [-74.98545057099994, 10.70122820000006 ],
              [-74.98548641399992, 10.70118135100006 ],
              [-74.98551493999992, 10.701141748000055],
              [-74.98554385099993, 10.70110251400007 ],
              [-74.98558853499992, 10.701047179000057],
              [-74.98560290699993, 10.701027212000042],
              [-74.98561625899993, 10.701008699000056],
              [-74.98564488199992, 10.70097415500004 ],
              [-74.98568314799991, 10.700927266000065],
              [-74.98572014799993, 10.700879666000048],
              [-74.98575278099992, 10.700840809000056],
              [-74.98578442099995, 10.700800245000039],
              [-74.98581972599993, 10.700754095000093],
              [-74.98584826899992, 10.700716786000044],
              [-74.98588051899992, 10.700673031000065],
              [-74.98590751599994, 10.700632141000085],
              [-74.98593430099993, 10.700591573000054],
              [-74.9859576259999 , 10.700552753000068],
              [-74.98599213799992, 10.70050090300009 ],
              [-74.98602312399993, 10.700451875000056],
              [-74.98605406999991, 10.700402870000062],
              [-74.98607800499991, 10.700363035000066],
              [-74.9861018169999 , 10.700323393000076],
              [-74.98613224899992, 10.70027067600006 ],
              [-74.98615520499993, 10.700232462000088],
              [-74.98618629999993, 10.70018090900004 ],
              [-74.98621322899993, 10.700132967000059],
              [-74.9862410849999 , 10.700080700000058],
              [-74.98627091899994, 10.700029016000087],
              [-74.98629589399991, 10.699984992000054],
              [-74.98631933899992, 10.699936836000063],
              [-74.98634060499995, 10.699899351000056],
              [-74.98636839699992, 10.699853981000047],
              [-74.98639725299995, 10.699804543000084],
              [-74.98641987399992, 10.699764668000057],
              [-74.9864505349999 , 10.699716135000074],
              [-74.98648219199993, 10.699658929000066],
              [-74.9865100529999 , 10.699608915000056],
              [-74.9865325369999 , 10.699569284000063],
              [-74.98655871599993, 10.69952657600004 ],
              [-74.98658828599991, 10.699483084000065],
              [-74.98661760099992, 10.69942810200007 ],
              [-74.9866388129999 , 10.699392767000063],
              [-74.98666411799991, 10.699354449000055],
              [-74.98669148199991, 10.699310608000076],
              [-74.98671526599992, 10.699268687000085],
              [-74.98674054599991, 10.69922658900009 ],
              [-74.98676498299994, 10.699173986000062],
              [-74.98679373199991, 10.699119892000056],
              [-74.98681749899993, 10.699077998000064],
              [-74.98683975699993, 10.699034401000063],
              [-74.98686448799992, 10.698996082000065],
              [-74.9868939189999 , 10.698953909000068],
              [-74.98692464399994, 10.69890993200005 ],
              [-74.98695088599993, 10.698871348000068],
              [-74.98695092199995, 10.698871289000067],
              [-74.98695095599993, 10.698871231000055],
              [-74.98695803099992, 10.698860840000066],
              [-74.98698374299994, 10.698821895000037],
              [-74.9870167659999 , 10.69877335600006 ],
              [-74.98703516099994, 10.69874481900007 ],
              [-74.98715860899995, 10.698772143000042],
              [-74.98746065899991, 10.698839001000067],
              [-74.98746525099995, 10.698840017000066],
              [-74.98749732999994, 10.698847109000042],
              [-74.98807749499991, 10.698962040000083],
              [-74.9880794529999 , 10.698962432000087],
              [-74.98853483099992, 10.699052644000062],
              [-74.98941893099993, 10.699218120000069],
              [-74.99022442899991, 10.69936888300009 ],
              [-74.99036189899994, 10.69939691500008 ],
              [-74.99036212699991, 10.699396961000048],
              [-74.99151131199994, 10.699631295000074],
              [-74.99207595399992, 10.699804270000072],
              [-74.99218752199994, 10.699894543000084],
              [-74.99229703999993, 10.699973252000063],
              [-74.99250227999994, 10.700031348000039],
              [-74.99268757899995, 10.700049790000037],
              [-74.99301834599993, 10.700101802000063],
              [-74.99324357899991, 10.700080640000067],
              [-74.9933860559999 , 10.700067253000043],
              [-74.99353713199991, 10.700055846000055],
              [-74.9936838079999 , 10.700044769000044],
              [-74.9938158089999 , 10.700030845000072],
              [-74.99401902099993, 10.700009412000043],
              [-74.9941239929999 , 10.700019587000043],
              [-74.9943711279999 , 10.70004354100007 ],
              [-74.99474632099992, 10.70008284700009 ],
              [-74.99484392199992, 10.700093802000083],
              [-74.9948783079999 , 10.70009765900005 ],
              [-74.99508839499993, 10.700125815000092],
              [-74.99521619799992, 10.70014294400005 ],
              [-74.9954830069999 , 10.700246310000068],
              [-74.99575401999994, 10.700368190000063],
              [-74.99597952899995, 10.70054932700009 ],
              [-74.99607055399991, 10.700622442000054],
              [-74.99657967699994, 10.701061420000087],
              [-74.99666178199993, 10.701132208000047],
              [-74.99671794999995, 10.701184         ],
              [-74.99676849799994, 10.701230629000065],
              [-74.99733963299991, 10.701757356000087],
              [-74.9973725179999 , 10.701787685000056],
              [-74.99762598399991, 10.702044310000076],
              [-74.9977590709999 , 10.702179068000078],
              [-74.99777584499992, 10.702196049000065],
              [-74.99753964199994, 10.70245490700006 ],
              [-74.99730830199991, 10.702704673000085],
              [-74.99694211099995, 10.703056974000049],
              [-74.99651558199992, 10.703407765000065],
              [-74.99627036299995, 10.703556547000062],
              [-74.99621683499993, 10.703589024000053],
              [-74.99612197999994, 10.703646568000067],
              [-74.99631165499994, 10.703771028000062],
              [-74.99657197599993, 10.703890235000074],
              [-74.99661476499995, 10.703909833000068],
              [-74.99682642399995, 10.703975780000064],
              [-74.99683874899995, 10.703979613000058],
              [-74.99691202999992, 10.704002446000061],
              [-74.99702444999991, 10.704041188000076],
              [-74.99702800599994, 10.704042425000068],
              [-74.99725050699993, 10.704119106000064],
              [-74.99763159399993, 10.704202303000045],
              [-74.99803559499992, 10.704297580000059],
              [-74.99827135399994, 10.704436998000062],
              [-74.99851876999992, 10.704574411000067],
              [-74.99866586899992, 10.70463563900006 ],
              [-74.9989235779999 , 10.704731865000042],
              [-74.99923376299995, 10.704866244000073],
              [-74.99963586099994, 10.705039749000036],
              [-75.0001709789999 , 10.70532742000006 ],
              [-75.00019277699994, 10.705339138000056],
              [-75.0004791909999 , 10.705495939000059],
              [-75.00053137799995, 10.705530314000043],
              [-75.0009042399999 , 10.70577591500006 ],
              [-75.0013511969999 , 10.706109380000044],
              [-75.0012691629999 , 10.706330656000091],
              [-75.0011459179999 , 10.706527871000048],
              [-75.00171832699994, 10.70704866400007 ],
              [-75.00277906899993, 10.708205552000038],
              [-75.00283318699991, 10.708271361000072],
              [-75.0032982969999 , 10.708812493000039],
              [-75.00337643799992, 10.708906296000066],
              [-75.00355095099991, 10.70911578600004 ],
              [-75.00391297199991, 10.709504696000067],
              [-75.00423369899994, 10.70977744600009 ],
              [-75.00446201799991, 10.70997387600005 ],
              [-75.00455891199994, 10.710057243000051],
              [-75.00462307999993, 10.710112309000067],
              [-75.00468646799993, 10.710166707000042],
              [-75.00469363499991, 10.710172860000057],
              [-75.00482712899992, 10.710287423000068],
              [-75.0048545759999 , 10.710310960000072],
              [-75.00516852099992, 10.71057665300009 ],
              [-75.00541179599992, 10.71088430900005 ],
              [-75.00553117899995, 10.711034638000058],
              [-75.00553610399993, 10.711027404000049],
              [-75.00566291599995, 10.710841164000044],
              [-75.00583944999994, 10.710581884000078],
              [-75.00614388799994, 10.710191210000062],
              [-75.00625035699994, 10.709919894000052],
              [-75.00640079199991, 10.709559893000062],
              [-75.0064777039999 , 10.709262525000042],
              [-75.00659566399992, 10.709281586000088],
              [-75.00662474399991, 10.709287936000067],
              [-75.00690516199995, 10.709349167000084],
              [-75.00738688299992, 10.709400329000061],
              [-75.00781359199993, 10.70942248700004 ],
              [-75.00814598999995, 10.709407759000044],
              [-75.00851030699994, 10.709322461000056],
              [-75.0088140009999 , 10.70924715600006 ],
              [-75.00934595199993, 10.709143014000063],
              [-75.00978084999991, 10.709114731000057],
              [-75.01019533399995, 10.709161907000066],
              [-75.01059848299991, 10.709223860000066],
              [-75.01066030699991, 10.709233362000077],
              [-75.01102572999991, 10.709293135000053],
              [-75.01138217999994, 10.70933965000006 ],
              [-75.01144004199995, 10.70934720300005 ],
              [-75.01161463999995, 10.70937581000004 ],
              [-75.01178157299995, 10.709403167000062],
              [-75.01179918199995, 10.709406053000066],
              [-75.01183782399994, 10.70941237900007 ],
              [-75.01185295599993, 10.70937546600004 ],
              [-75.01197416399992, 10.709079793000058],
              [-75.01197659899992, 10.709073856000089],
              [-75.01199297199992, 10.709033924000039],
              [-75.01199505999995, 10.70902882900009 ],
              [-75.01201065399994, 10.708990781000068],
              [-75.0121691139999 , 10.708587239000053],
              [-75.01231677899995, 10.708054505000064],
              [-75.01242643099994, 10.70777199400004 ],
              [-75.01250261599995, 10.707575673000065],
              [-75.01267557099993, 10.70695838100005 ],
              [-75.01286569399991, 10.706207996000046],
              [-75.0130045969999 , 10.705654327000047],
              [-75.01317326399993, 10.705117508000058],
              [-75.01318946599991, 10.705084398000054],
              [-75.01328567999991, 10.70488778300006 ],
              [-75.01345718999994, 10.704710186000057],
              [-75.01355978599992, 10.704597637000063],
              [-75.01386965099994, 10.704745658000093],
              [-75.0142003709999 , 10.70490364200009 ],
              [-75.01448015599993, 10.705037292000043],
              [-75.01465748499993, 10.705096967000088],
              [-75.01502793899994, 10.705140734000054],
              [-75.01552503399995, 10.705132407000065],
              [-75.0158529059999 , 10.705112955000061],
              [-75.01600063899991, 10.705099518000054],
              [-75.01611120599995, 10.705089465000071],
              [-75.01653200899995, 10.705067725000049],
              [-75.01683877099993, 10.705056952000064],
              [-75.01728375599993, 10.705091072000073],
              [-75.0177763239999 , 10.70507802700007 ],
              [-75.01849501899994, 10.70512356200004 ],
              [-75.01910860899994, 10.705194113000061],
              [-75.01937470599995, 10.705216491000044],
              [-75.01963018599992, 10.70523797800007 ],
              [-75.01965315399991, 10.705239904000052],
              [-75.02030222499991, 10.705283705000056],
              [-75.02054680899994, 10.705301481000049],
              [-75.02115216499993, 10.705345477000037],
              [-75.02117479999993, 10.705347120000056],
              [-75.02126644899994, 10.705353781000042],
              [-75.02148156099992, 10.705346138000039],
              [-75.02151843599995, 10.705344814000057],
              [-75.02152326099991, 10.70534464700006 ],
              [-75.0215708849999 , 10.705342949000055],
              [-75.02200647599994, 10.705381419000048],
              [-75.02218204899992, 10.705418028000054],
              [-75.02222515099993, 10.70536042200007 ],
              [-75.02240525799994, 10.705119702000047],
              [-75.02261300899994, 10.704788743000051],
              [-75.02275887999991, 10.704615107000052],
              [-75.02277446799991, 10.704621686000053],
              [-75.0232690979999 , 10.704830410000056],
              [-75.02406004199992, 10.70524608900007 ],
              [-75.02468220399993, 10.70563224500006 ],
              [-75.02495566399995, 10.705810213000063],
              [-75.02500221199995, 10.705840507000062],
              [-75.02513287099993, 10.706004913000072],
              [-75.02535079299992, 10.706305026000052],
              [-75.0255159319999 , 10.706566069000075],
              [-75.0256570979999 , 10.706789219000086],
              [-75.02621869199993, 10.707565220000049],
              [-75.02660130399994, 10.708062821000055],
              [-75.02693626999991, 10.708609878000061],
              [-75.02731842799994, 10.70931696100007 ],
              [-75.02758114199992, 10.709684941000091],
              [-75.02771256699992, 10.709870495000075],
              [-75.0278168509999 , 10.710017721000042],
              [-75.02782979299991, 10.710035687000072],
              [-75.02808187499994, 10.710385773000041],
              [-75.02851683799992, 10.710831709000047],
              [-75.0289043329999 , 10.711129133000043],
              [-75.02942893999995, 10.711517447000062],
              [-75.03012515799992, 10.711914633000049],
              [-75.03097644499991, 10.712331796000058],
              [-75.03157193699991, 10.712765620000084],
              [-75.03192947499991, 10.71295869000005 ],
              [-75.03217634599991, 10.713119073000087],
              [-75.0322114459999 , 10.71304316600009 ],
              [-75.03235525799994, 10.712732157000062],
              [-75.03260137299992, 10.712160423000057],
              [-75.0328158879999 , 10.711742147000052],
              [-75.03303363799995, 10.711381509000091],
              [-75.03319295799992, 10.711132235000036],
              [-75.0333777429999 , 10.710694806000049],
              [-75.03350052399992, 10.71032490400006 ],
              [-75.03364228099991, 10.709842666000043],
              [-75.03376089199992, 10.70935755000005 ],
              [-75.03393587599993, 10.708846210000047],
              [-75.03418672899994, 10.70826998800004 ],
              [-75.03432449199994, 10.707856721000041],
              [-75.03445621799995, 10.707500849000041],
              [-75.03456449899994, 10.707153603000052],
              [-75.03480384099993, 10.70666919100006 ],
              [-75.0351257069999 , 10.70622826500005 ],
              [-75.03551114699991, 10.70584647600009 ],
              [-75.0359685329999 , 10.705275389000064],
              [-75.03619708899993, 10.704947253000057],
              [-75.03640611899993, 10.704563378000046],
              [-75.03663506799995, 10.704028054000048],
              [-75.0366477579999 , 10.70398302700005 ],
              [-75.03671293799994, 10.703883671000085],
              [-75.03728962999992, 10.70300461100004 ],
              [-75.03843262599992, 10.699676171000078],
              [-75.0385117909999 , 10.699445638000043],
              [-75.03894990799995, 10.700776917000042],
              [-75.0395375299999 , 10.701514760000066],
              [-75.03977534699993, 10.70181337300005 ],
              [-75.04011208299994, 10.70201688600008 ],
              [-75.04066773199992, 10.702352706000056],
              [-75.04165062099992, 10.702620712000055],
              [-75.04409560599993, 10.702273259000037],
              [-75.04441279699995, 10.702228183000045],
              [-75.04462019499994, 10.702128934000086],
              [-75.04555297099995, 10.701682560000052],
              [-75.04543866299991, 10.701890495000043],
              [-75.04473063699993, 10.703178440000045],
              [-75.04453896899992, 10.703466456000058],
              [-75.04348048899993, 10.705057028000056],
              [-75.04282289999992, 10.706549798000083],
              [-75.04222322699991, 10.707345729000053],
              [-75.04179946399995, 10.70790818100005 ],
              [-75.04177959599991, 10.708086737000087],
              [-75.04165815899995, 10.70917813300008 ],
              [-75.04150105699995, 10.710590059000083],
              [-75.04148075899991, 10.710772475000056],
              [-75.041443        , 10.711111841000047],
              [-75.04143284299994, 10.71120312800008 ],
              [-75.04139972899992, 10.711500732000047],
              [-75.04144558099995, 10.711683497000081],
              [-75.04158165399991, 10.712225886000056],
              [-75.04248687499995, 10.715834052000048],
              [-75.04182606799992, 10.718037440000046],
              [-75.04167324999992, 10.718546995000054],
              [-75.0404686999999 , 10.72042543200007 ],
              [-75.03944607099993, 10.722054850000063],
              [-75.0387542279999 , 10.722760945000061],
              [-75.03786322999991, 10.723486476000062],
              [-75.03735751399995, 10.72398606300004 ],
              [-75.03701055599993, 10.72424329200004 ],
              [-75.03670505499991, 10.724469784000064],
              [-75.0365607949999 , 10.724554927000042],
              [-75.03637472299994, 10.724664748000066],
              [-75.03568903799993, 10.724897169000087],
              [-75.03540664399992, 10.724984792000043],
              [-75.03497880299994, 10.725117544000057],
              [-75.03398676699993, 10.725375150000048],
              [-75.03338737799993, 10.725764877000074],
              [-75.03303229599993, 10.725887189000048],
              [-75.03228593099993, 10.72631373300004 ],
              [-75.03200473499993, 10.72654490800005 ],
              [-75.03158863099992, 10.726752249000072],
              [-75.03127028199992, 10.726862321000056],
              [-75.03100058199993, 10.726851028000056],
              [-75.02969035199993, 10.727527114000054],
              [-75.02962995699994, 10.727558277000071],
              [-75.02962430799994, 10.727580921000083],
              [-75.02961916899994, 10.727601519000075],
              [-75.02958153399993, 10.727752373000044],
              [-75.02969359299993, 10.728309627000044],
              [-75.02975882599992, 10.729570077000062],
              [-75.0299477829999 , 10.732067227000073],
              [-75.03006402599993, 10.734394226000063],
              [-75.03024825599994, 10.736988429000064],
              [-75.030292        , 10.737604413000042],
              [-75.03038955499994, 10.739217327000063],
              [-75.03054481299995, 10.741784254000038],
              [-75.0308714869999 , 10.741718606000063],
              [-75.0312301429999 , 10.74158435000004 ],
              [-75.03139388599993, 10.741523055000073],
              [-75.03174850599993, 10.74139262400007 ],
              [-75.03180745499992, 10.74137094100007 ],
              [-75.03188261899993, 10.74136395100004 ],
              [-75.03252638799995, 10.741304083000045],
              [-75.0331509209999 , 10.741202503000068],
              [-75.03320159799995, 10.741194260000043],
              [-75.03332053299994, 10.74150736300004 ],
              [-75.03333761399995, 10.741552327000079],
              [-75.0335247239999 , 10.742044900000053],
              [-75.0335531799999 , 10.742119811000066],
              [-75.03360285999992, 10.74255951300006 ],
              [-75.03363496799994, 10.742843685000082],
              [-75.03368347299994, 10.743272982000065],
              [-75.03367907199993, 10.743273023000086],
              [-75.0330179149999 , 10.743279078000057],
              [-75.0328132809999 , 10.743280952000077],
              [-75.03269052999991, 10.743282075000081],
              [-75.03175669399991, 10.743290626000089],
              [-75.03175487399994, 10.743290633000072],
              [-75.0317563669999 , 10.74376559500007 ],
              [-75.03175710099993, 10.74399928300005 ],
              [-75.03167423799994, 10.744316154000046],
              [-75.03153029299995, 10.744603058000052],
              [-75.03144754999994, 10.744957624000051],
              [-75.03148722899994, 10.74545504200006 ],
              [-75.03159547099995, 10.745937170000047],
              [-75.03189387099991, 10.746283023000046],
              [-75.03204747599995, 10.746644398000058],
              [-75.03210162099992, 10.746893004000071],
              [-75.0320754409999 , 10.747322360000055],
              [-75.03183309199994, 10.747832368000047],
              [-75.0317801839999 , 10.748247985000091],
              [-75.03177381899991, 10.748782817000063],
              [-75.03162238999994, 10.749114976000044],
              [-75.03142505799991, 10.749394506000044],
              [-75.0312047569999 , 10.749643951000053],
              [-75.0309536339999 , 10.749787955000045],
              [-75.03068764099993, 10.750052617000051],
              [-75.03041449499995, 10.750468071000057],
              [-75.03033108699992, 10.750611559000049],
              [-75.03021751499995, 10.750860676000059],
              [-75.0301267399999 , 10.75108711100006 ],
              [-75.03007399299992, 10.75128327300007 ],
              [-75.03006839199992, 10.75152326500006 ],
              [-75.03015015199992, 10.751663732000054],
              [-75.03021158099995, 10.751804262000064],
              [-75.03022376599995, 10.751850154000067],
              [-75.0302835839999 , 10.75207542600009 ],
              [-75.03028437099994, 10.752326706000076],
              [-75.03033512899992, 10.752709756000058],
              [-75.03035927799993, 10.753116762000047],
              [-75.03037565999995, 10.753478556000061],
              [-75.03042248799994, 10.753825182000071],
              [-75.03046891799994, 10.754043658000057],
              [-75.03059939099995, 10.754322179000042],
              [-75.0307296339999 , 10.754525319000038],
              [-75.03082928499992, 10.754698399000063],
              [-75.03092105099995, 10.754788578000046],
              [-75.03101305599995, 10.754954145000056],
              [-75.03108237899994, 10.755180085000063],
              [-75.03111360799994, 10.75541368000006 ],
              [-75.03116065199993, 10.75582815200005 ],
              [-75.03125317799993, 10.756159560000071],
              [-75.03131577199991, 10.75623640100008 ],
              [-75.0313827139999 , 10.756318578000048],
              [-75.03141791699994, 10.756361792000064],
              [-75.03208098499994, 10.756185329000061],
              [-75.0324083619999 , 10.756101327000067],
              [-75.03252913499995, 10.756070339000075],
              [-75.0326696379999 , 10.756034287000091],
              [-75.03283596199992, 10.75599980800007 ],
              [-75.03288678099995, 10.755989274000058],
              [-75.0331087429999 , 10.755979378000063],
              [-75.03316950999994, 10.755987538000056],
              [-75.03343915799991, 10.756023739000057],
              [-75.03358659399993, 10.756045123000092],
              [-75.0340235239999 , 10.756091401000049],
              [-75.03415901999995, 10.756105748000039],
              [-75.03451190399994, 10.75614678100004 ],
              [-75.03491429399992, 10.756193569000061],
              [-75.03498053899995, 10.75620338300007 ],
              [-75.03529872999991, 10.756250525000041],
              [-75.0355508749999 , 10.75633745500005 ],
              [-75.03583516499992, 10.75649859300006 ],
              [-75.03624418499993, 10.756831133000048],
              [-75.03639934599994, 10.756954798000038],
              [-75.0365374619999 , 10.75706487900004 ],
              [-75.03664283299992, 10.757112967000069],
              [-75.03672554499991, 10.75715071400009 ],
              [-75.03677298799994, 10.757172364000041],
              [-75.03691624999993, 10.757237746000044],
              [-75.03745450199995, 10.757432221000045],
              [-75.0374576129999 , 10.757433345000038],
              [-75.03749984799992, 10.757440047000046],
              [-75.03785295999995, 10.757515347000037],
              [-75.03818315699993, 10.75747276900006 ],
              [-75.03833357399992, 10.757496377000052],
              [-75.03900913399991, 10.757568887000048],
              [-75.03939597399994, 10.757565717000091],
              [-75.03956927799993, 10.757564297000044],
              [-75.04007220199992, 10.757418983000036],
              [-75.04049347499995, 10.757259984000086],
              [-75.04062578299994, 10.757210049000037],
              [-75.04103664899992, 10.757051037000053],
              [-75.04125103699994, 10.757032228000071],
              [-75.04142700299991, 10.757082991000061],
              [-75.04174104399993, 10.757274327000061],
              [-75.04178936099993, 10.757320901000071],
              [-75.04202747099993, 10.757550428000059],
              [-75.04248297599992, 10.757803353000043],
              [-75.04289871799995, 10.757971023000039],
              [-75.0430793079999 , 10.75798167000005 ],
              [-75.0432588669999 , 10.757967534000045],
              [-75.04346751899993, 10.757890929000041],
              [-75.04360345399994, 10.757781292000061],
              [-75.04385049099994, 10.757582046000039],
              [-75.04409438099992, 10.757424681000089],
              [-75.0442936529999 , 10.757372047000047],
              [-75.04449647899992, 10.757392541000058],
              [-75.04470447499995, 10.757397626000056],
              [-75.0447808859999 , 10.757439546000057],
              [-75.04482236299992, 10.757462306000093],
              [-75.04482984799995, 10.757466412000042],
              [-75.04485107799991, 10.757462342000053],
              [-75.04483990999995, 10.757472200000052],
              [-75.04492137799991, 10.757515626000043],
              [-75.04500023899993, 10.757532491000063],
              [-75.04510348399992, 10.757543189000046],
              [-75.04522697399995, 10.757565033000049],
              [-75.04537591099995, 10.757531737000079],
              [-75.04554591999994, 10.757486912000047],
              [-75.0457124269999 , 10.757467827000085],
              [-75.04579682199994, 10.757464852000055],
              [-75.045889        , 10.75741438700004 ],
              [-75.04601184099994, 10.75732187500006 ],
              [-75.04602609599993, 10.757311136000055],
              [-75.04611583199994, 10.757297607000055],
              [-75.04615444199993, 10.757316801000059],
              [-75.04619420199992, 10.757544930000051],
              [-75.04621962199991, 10.757798086000037],
              [-75.04623625399995, 10.757911896000053],
              [-75.04625632099993, 10.75799994600004 ],
              [-75.04632060299991, 10.758080115000041],
              [-75.04638757999993, 10.758164549000071],
              [-75.04639612099993, 10.758262602000059],
              [-75.04640052699995, 10.758351442000048],
              [-75.04635481699995, 10.758457762000091],
              [-75.04630504699992, 10.758550351000054],
              [-75.04625218599995, 10.75865495000005 ],
              [-75.04620619699995, 10.75877304100004 ],
              [-75.04615603999991, 10.758908415000064],
              [-75.04611638999995, 10.759046431000058],
              [-75.04608603999992, 10.759199599000056],
              [-75.0461126969999 , 10.759348785000043],
              [-75.04616044699992, 10.75945276400006 ],
              [-75.04624807199991, 10.759556601000043],
              [-75.04643794299994, 10.759754150000049],
              [-75.04656283999992, 10.760087886000065],
              [-75.04660672299991, 10.760240704000068],
              [-75.04660883799994, 10.760248084000068],
              [-75.04648978799992, 10.760379134000061],
              [-75.04618415999994, 10.76058387200004 ],
              [-75.04597192699993, 10.760681816000044],
              [-75.04568238399992, 10.76081008500006 ],
              [-75.04536427099993, 10.761041787000067],
              [-75.04537570399992, 10.761158286000068],
              [-75.04538088599992, 10.76123251200005 ],
              [-75.04537554899991, 10.76133154200005 ],
              [-75.04539419399993, 10.76149515800006 ],
              [-75.0454151589999 , 10.761645257000055],
              [-75.04545455099992, 10.761745659000042],
              [-75.04551560499993, 10.761840580000069],
              [-75.04554271299992, 10.761923061000061],
              [-75.04555161399992, 10.761985251000056],
              [-75.04553311199993, 10.762092297000038],
              [-75.04551356199994, 10.76231043200005 ],
              [-75.0455291749999 , 10.762482248000083],
              [-75.04552730799992, 10.762558781000052],
              [-75.04548180499995, 10.762671814000043],
              [-75.04542808199994, 10.762798884000063],
              [-75.04538201699995, 10.762890681000044],
              [-75.0453611609999 , 10.762993124000047],
              [-75.04537108699992, 10.763082157000042],
              [-75.04538146099992, 10.763166523000052],
              [-75.0453814729999 , 10.763247418000049],
              [-75.04534027699992, 10.763330373000088],
              [-75.04530286799991, 10.76340059000006 ],
              [-75.04526071299995, 10.763480206000054],
              [-75.04521892999992, 10.763547334000066],
              [-75.04518019099993, 10.763601194000046],
              [-75.0451179609999 , 10.763679830000058],
              [-75.0451026099999 , 10.763716306000049],
              [-75.04509704399993, 10.76372653900006 ],
              [-75.04505409599994, 10.76380548800006 ],
              [-75.04500948599991, 10.763906250000048],
              [-75.0449762689999 , 10.764047902000073],
              [-75.04496595799992, 10.76424030000004 ],
              [-75.04498728399994, 10.764354464000064],
              [-75.04501956999991, 10.764430855000057],
              [-75.04509287999991, 10.764520549000054],
              [-75.04514173299992, 10.764623760000063],
              [-75.04515569499995, 10.764718765000055],
              [-75.04511905899994, 10.764813076000053],
              [-75.04507592199991, 10.764873562000048],
              [-75.04502761899994, 10.764940158000059],
              [-75.04498153899993, 10.765023967000047],
              [-75.04495797099992, 10.765064253000048],
              [-75.0449388429999 , 10.765096950000043],
              [-75.04487323699993, 10.765250363000064],
              [-75.04474957399992, 10.76542452800004 ],
              [-75.04460110299993, 10.765596938000044],
              [-75.04449010299993, 10.765789209000047],
              [-75.04422174699994, 10.766076364000071],
              [-75.04416347099993, 10.766252803000043],
              [-75.04414658499991, 10.766303936000043],
              [-75.04417395799993, 10.766487837000057],
              [-75.04428077999995, 10.766754499000058],
              [-75.04436985599995, 10.766965970000058],
              [-75.04430987099994, 10.767013878000057],
              [-75.04421040499994, 10.767093332000059],
              [-75.04395476199994, 10.76733041500006 ],
              [-75.04386906799994, 10.767411306000042],
              [-75.04380028999992, 10.767536264000057],
              [-75.04372168399993, 10.767679074000057],
              [-75.04360892599993, 10.767887186000053],
              [-75.04345837299991, 10.768053463000058],
              [-75.0433867399999 , 10.768180948000065],
              [-75.0433680829999 , 10.768214157000045],
              [-75.04332983899991, 10.768319293000047],
              [-75.04326393799994, 10.76847802900005 ],
              [-75.04325529699992, 10.768575016000057],
              [-75.04320341599993, 10.768698534000066],
              [-75.04314847899991, 10.768743235000045],
              [-75.04306442299992, 10.768801791000044],
              [-75.04298286799991, 10.768910958000049],
              [-75.04282002499991, 10.769067076000056],
              [-75.0426645629999 , 10.769177292000052],
              [-75.04253021099993, 10.769268625000052],
              [-75.04208096799994, 10.769478553000056],
              [-75.0419696589999 , 10.769522334000044],
              [-75.04188224599994, 10.769603561000054],
              [-75.04180690499993, 10.769717688000071],
              [-75.04176421999995, 10.769749794000063],
              [-75.04166834199992, 10.769758472000092],
              [-75.04161416699992, 10.769723838000061],
              [-75.0415408419999 , 10.769730477000053],
              [-75.04147607899995, 10.769770233000088],
              [-75.04139633499994, 10.769833935000065],
              [-75.04132662399991, 10.769947570000056],
              [-75.04125544099992, 10.77004439600006 ],
              [-75.04125078699991, 10.770051187000092],
              [-75.04111919899992, 10.770243160000064],
              [-75.0410291359999 , 10.770319106000045],
              [-75.0410219609999 , 10.770322688000078],
              [-75.0409178459999 , 10.770374656000058],
              [-75.0407635649999 , 10.770394551000038],
              [-75.04067404499995, 10.770397122000077],
              [-75.04058515799994, 10.770399673000043],
              [-75.04040445499993, 10.770416050000051],
              [-75.04021090799995, 10.770445162000044],
              [-75.0400382229999 , 10.770489085000065],
              [-75.03989572799992, 10.770552904000056],
              [-75.03971883399993, 10.77061419100005 ],
              [-75.0395258019999 , 10.770644244000039],
              [-75.0394493789999 , 10.770656143000053],
              [-75.03879950499993, 10.770644893000053],
              [-75.03868119299995, 10.770661276000055],
              [-75.0385559259999 , 10.770688432000043],
              [-75.03854544799992, 10.77069070300007 ],
              [-75.03848274299992, 10.770707855000069],
              [-75.0384240219999 , 10.77075622600006 ],
              [-75.03837342399993, 10.770814776000066],
              [-75.0382569009999 , 10.770936002000042],
              [-75.0380856889999 , 10.771036869000056],
              [-75.03782656299995, 10.77119377200006 ],
              [-75.03763563699994, 10.771278945000063],
              [-75.03750457199993, 10.77130423400007 ],
              [-75.03750146499993, 10.771288493000043],
              [-75.03750143499991, 10.771279015000061],
              [-75.03745497299991, 10.77130493900006 ],
              [-75.03725490999994, 10.771370183000045],
              [-75.03718743999991, 10.77139948200005 ],
              [-75.03712465199993, 10.771417478000046],
              [-75.03706627499992, 10.77145607500006 ],
              [-75.03696892999994, 10.77145531900004 ],
              [-75.03677467499995, 10.77139180000006 ],
              [-75.03655976499994, 10.771300457000052],
              [-75.03638156999995, 10.771255469000039],
              [-75.03618591899993, 10.771088421000059],
              [-75.0360363559999 , 10.770951600000046],
              [-75.03593551499995, 10.770817529000055],
              [-75.03585552399994, 10.770683529000053],
              [-75.03581475699991, 10.770575184000052],
              [-75.03562652099993, 10.770362219000049],
              [-75.0352916359999 , 10.77020556900004 ],
              [-75.0349930619999 , 10.770127476000084],
              [-75.03445203299992, 10.769941739000046],
              [-75.03413287699993, 10.76983580600006 ],
              [-75.03383198799992, 10.769771495000043],
              [-75.03362358699991, 10.769728499000053],
              [-75.03333668599993, 10.769657284000061],
              [-75.03319034099991, 10.769679204000056],
              [-75.03276717999995, 10.769793226000047],
              [-75.0324388809999 , 10.769935557000053],
              [-75.03227371699995, 10.769991785000059],
              [-75.03210171699993, 10.770029604000058],
              [-75.03181881599994, 10.77002744300006 ],
              [-75.03148120599991, 10.769914504000042],
              [-75.03121795699991, 10.76978143100007 ],
              [-75.03100805799994, 10.769648750000044],
              [-75.0308166289999 , 10.769483384000068],
              [-75.0307762619999 , 10.769447158000048],
              [-75.03069968299991, 10.769378437000057],
              [-75.0306004869999 , 10.769377159000044],
              [-75.03050557299991, 10.769378148000044],
              [-75.03040318399991, 10.769375788000048],
              [-75.03028997899992, 10.769378611000093],
              [-75.03010731899991, 10.76941772500004 ],
              [-75.02994049499995, 10.769446991000052],
              [-75.02979342799995, 10.769464356000071],
              [-75.02967091599993, 10.769475398000054],
              [-75.02945498899993, 10.769512146000068],
              [-75.02928698399995, 10.76957595600004 ],
              [-75.0291169059999 , 10.769633962000057],
              [-75.02894190699993, 10.76969947200007 ],
              [-75.02879387999991, 10.769736788000046],
              [-75.02832133299995, 10.76972898300005 ],
              [-75.02797456899992, 10.769727033000038],
              [-75.02768683499994, 10.769682774000046],
              [-75.02734074599994, 10.769629524000038],
              [-75.02709530499993, 10.769571638000059],
              [-75.02684601599992, 10.769505374000062],
              [-75.02643847299993, 10.76937021000009 ],
              [-75.02600713699991, 10.769235896000055],
              [-75.02567439099994, 10.769149131000063],
              [-75.02549669899992, 10.769122880000054],
              [-75.02501066799994, 10.769041457000071],
              [-75.0244428069999 , 10.768994776000056],
              [-75.02398142999994, 10.768719327000042],
              [-75.0237383779999 , 10.768647655000052],
              [-75.02361729899991, 10.768586320000054],
              [-75.02342035499993, 10.768623783000066],
              [-75.02263454299992, 10.768750645000068],
              [-75.02228708599995, 10.768821270000046],
              [-75.02208758199993, 10.769065669000042],
              [-75.02206381099995, 10.769063620000054],
              [-75.02205711199991, 10.769139516000052],
              [-75.02208679599994, 10.76928890000005 ],
              [-75.02214850899992, 10.769374730000038],
              [-75.02219251999992, 10.76942101600008 ],
              [-75.02220862699994, 10.769437957000036],
              [-75.02234881699991, 10.769573782000066],
              [-75.02236640799993, 10.769566569000062],
              [-75.02245886599991, 10.769558251000092],
              [-75.02248124099992, 10.769597563000048],
              [-75.02252751099991, 10.769678844000055],
              [-75.02255919499993, 10.769917615000054],
              [-75.02247935499992, 10.770207206000066],
              [-75.02245582499995, 10.770474456000045],
              [-75.02245512299993, 10.770782248000046],
              [-75.02245507599991, 10.770803064000063],
              [-75.02233886399995, 10.771211275000041],
              [-75.02230092199994, 10.771270481000045],
              [-75.0221585459999 , 10.771492652000063],
              [-75.02212237299995, 10.771478624000054],
              [-75.02205106999992, 10.77126019700006 ],
              [-75.02203590399995, 10.771152014000052],
              [-75.0219177749999 , 10.771133847000044],
              [-75.0217985999999 , 10.77130605700006 ],
              [-75.0216406319999 , 10.771839298000089],
              [-75.02139544799991, 10.772172883000053],
              [-75.0211421219999 , 10.772547622000047],
              [-75.02072229399994, 10.77289730700005 ],
              [-75.02059971199992, 10.77303167100007 ],
              [-75.0201136689999 , 10.773564428000043],
              [-75.01985130199995, 10.773905705000061],
              [-75.01961659799991, 10.77409442600009 ],
              [-75.01954262899994, 10.774375245000044],
              [-75.01952767099993, 10.774432027000046],
              [-75.0196191469999 , 10.774544968000043],
              [-75.01977561099994, 10.774802114000067],
              [-75.01983062799991, 10.775033844000063],
              [-75.01992178499995, 10.775089674000071],
              [-75.02004012999993, 10.775107779000052],
              [-75.02017520899994, 10.77504927700005 ],
              [-75.02033556499993, 10.775080875000072],
              [-75.0204735289999 , 10.775258866000058],
              [-75.02064269199991, 10.775595699000064],
              [-75.02076586899994, 10.775873198000056],
              [-75.0207651579999 , 10.775893672000052],
              [-75.02075717699995, 10.77612315500005 ],
              [-75.02064192499995, 10.776221185000054],
              [-75.02054340899991, 10.776230178000048],
              [-75.0204705459999 , 10.776265719000037],
              [-75.02032826899995, 10.776850718000048],
              [-75.02032344299994, 10.77687682000004 ],
              [-75.02030437099995, 10.776980025000057],
              [-75.02019983299994, 10.777122488000089],
              [-75.02006219799995, 10.777234373000056],
              [-75.01988925899991, 10.777261765000048],
              [-75.01964788299995, 10.777289458000041],
              [-75.01963514899995, 10.777276111000049],
              [-75.01964521699995, 10.777266720000057],
              [-75.0198690329999 , 10.777144284000087],
              [-75.0200693669999 , 10.777051589000052],
              [-75.02017309399992, 10.77687762000005 ],
              [-75.02023530199995, 10.776671287000056],
              [-75.02029560099993, 10.776425776000053],
              [-75.02033444799991, 10.776242188000083],
              [-75.02036423399994, 10.776220438000053],
              [-75.02004679499993, 10.776251539000043],
              [-75.0195731419999 , 10.776303128000052],
              [-75.01907904699993, 10.776306266000063],
              [-75.01855300499994, 10.776256327000056],
              [-75.01780930399991, 10.776170320000062],
              [-75.01698790799992, 10.776203125000052],
              [-75.01630869199994, 10.776163527000051],
              [-75.01566663299991, 10.776124177000042],
              [-75.01497846899991, 10.776052328000048],
              [-75.01450299699991, 10.776048770000045],
              [-75.01433860399993, 10.776050400000088],
              [-75.01384649399995, 10.77605528500004 ],
              [-75.01329747599993, 10.77598675900009 ],
              [-75.01264311699993, 10.776014025000052],
              [-75.01222505299995, 10.776075182000056],
              [-75.01193958399995, 10.776098292000086],
              [-75.01162176499992, 10.776102784000045],
              [-75.01126229699992, 10.776102327000046],
              [-75.01101863399992, 10.776114291000056],
              [-75.01093038399995, 10.776122821000058],
              [-75.01052187499994, 10.776154218000045],
              [-75.01025478699995, 10.776188993000062],
              [-75.00995515999995, 10.776232694000043],
              [-75.00967187999993, 10.776271898000061],
              [-75.00960179299994, 10.776282618000039],
              [-75.00940010199992, 10.776313469000058],
              [-75.0091515279999 , 10.776350660000048],
              [-75.0088240529999 , 10.77639649200006 ],
              [-75.00855250399991, 10.776419631000067],
              [-75.00820230099993, 10.776414762000059],
              [-75.00788295199993, 10.776338749000047],
              [-75.00758702799993, 10.77623999900004 ],
              [-75.00721010799992, 10.776115295000068],
              [-75.00687005899994, 10.776020766000045],
              [-75.00655827799994, 10.77587588700004 ],
              [-75.00625776999993, 10.77577248600005 ],
              [-75.00589446499993, 10.775686997000037],
              [-75.00558865699992, 10.775647882000044],
              [-75.00520415799991, 10.775589787000058],
              [-75.0048933779999 , 10.77558748100006 ],
              [-75.00462870399991, 10.775615324000057],
              [-75.00454881099995, 10.775629804000062],
              [-75.00447110399995, 10.775643892000062],
              [-75.00437977699994, 10.775667930000054],
              [-75.00427765899991, 10.775704584000039],
              [-75.00420625099991, 10.77576939000005 ],
              [-75.00413977699992, 10.775809232000086],
              [-75.0038759169999 , 10.775754383000049],
              [-75.00380147299995, 10.775772261000043],
              [-75.00373037199995, 10.775771689000067],
              [-75.00371105099993, 10.775771534000057],
              [-75.00344414299991, 10.775792469000066],
              [-75.00313763999992, 10.775829194000039],
              [-75.00305891199991, 10.775835137000058],
              [-75.00295690299993, 10.778981275000092],
              [-75.00268643899994, 10.780459054000062],
              [-75.00221260099994, 10.78050406300008 ],
              [-75.00207095999991, 10.78051219100007 ],
              [-75.00180493699992, 10.780527453000047],
              [-75.00180396299993, 10.780518503000053],
              [-75.00164116499991, 10.780531260000089],
              [-75.00163412699993, 10.78053181200005 ],
              [-75.00130238499992, 10.780538530000058],
              [-75.00116318199991, 10.780537467000045],
              [-75.00107538299994, 10.780509205000044],
              [-75.00100448099994, 10.780477760000053],
              [-75.00084556099995, 10.78076948200004 ],
              [-75.00048537799995, 10.781332130000067],
              [-75.0001505159999 , 10.781906495000044],
              [-74.99983119799992, 10.782563725000045],
              [-74.99960017799992, 10.782950423000045],
              [-74.99928245099994, 10.78368574700005 ],
              [-74.99919914199995, 10.784397693000074],
              [-74.99921251699993, 10.785429761000046],
              [-74.9992093969999 , 10.785763021000037],
              [-74.99925659199994, 10.786411520000058],
              [-74.99928102799993, 10.787018492000072],
              [-74.99966487599994, 10.787138594000055],
              [-74.99986985399994, 10.787248488000046],
              [-74.99989108799991, 10.78725987100006 ],
              [-75.00018889299992, 10.787406937000071],
              [-75.0004357329999 , 10.78751434500009 ],
              [-75.00051688699995, 10.787549659000092],
              [-75.00090446799993, 10.787768620000065],
              [-75.0014032229999 , 10.788252771000089],
              [-75.00151981699992, 10.788681222000037],
              [-75.00153249099992, 10.788807735000091],
              [-75.00161624299994, 10.789028946000087],
              [-75.00171420299995, 10.789459572000055],
              [-75.00173717899992, 10.789730889000055],
              [-75.00172056399992, 10.790018084000053],
              [-75.00170328699994, 10.790369597000051],
              [-75.0016369949999 , 10.79050701500006 ],
              [-75.0016069909999 , 10.790736622000054],
              [-75.00150865699993, 10.790727364000077],
              [-75.00126168799994, 10.79070411400005 ],
              [-75.00084271599991, 10.790615893000052],
              [-75.00043525799992, 10.790532375000055],
              [-74.99997391599993, 10.790508195000086],
              [-74.99959803999991, 10.790521400000046],
              [-74.99922184499991, 10.79056907100005 ],
              [-74.99880434999994, 10.790565937000054],
              [-74.99838434599991, 10.790583368000057],
              [-74.99766486099992, 10.790626196000062],
              [-74.997349        , 10.79066972600009 ],
              [-74.9969061079999 , 10.79065262000006 ],
              [-74.99643083299992, 10.790628272000049],
              [-74.99594132099992, 10.790642940000055],
              [-74.9954765889999 , 10.79072440300007 ],
              [-74.99538237299993, 10.790729849000058],
              [-74.99523056899994, 10.790738628000042],
              [-74.99481639699991, 10.790632093000056],
              [-74.99433324699993, 10.790460586000052],
              [-74.99408426999992, 10.790302373000088],
              [-74.99394221299991, 10.790117419000069],
              [-74.9938819489999 , 10.78986646900006 ],
              [-74.99387093699994, 10.789558399000043],
              [-74.99387284699992, 10.789363053000045],
              [-74.99385315699993, 10.78899510900004 ],
              [-74.99385493199992, 10.788806691000048],
              [-74.99384192899993, 10.788710052000056],
              [-74.99326157799993, 10.786995794000063],
              [-74.99258748199992, 10.785004632000039],
              [-74.99250523899991, 10.785039279000046],
              [-74.99203557399994, 10.785237129000052],
              [-74.99154044199992, 10.785587319000058],
              [-74.99112201899993, 10.785931145000063],
              [-74.9906615829999 , 10.786300015000052],
              [-74.99018678099992, 10.78671010000005 ],
              [-74.98979247799991, 10.786957589000053],
              [-74.9894241639999 , 10.787391512000056],
              [-74.98908265599994, 10.787689973000056],
              [-74.98871645999992, 10.787903200000073],
              [-74.98822685999994, 10.788168418000055],
              [-74.98797790399993, 10.788251494000065],
              [-74.98751771799994, 10.78834911000007 ],
              [-74.98684873499991, 10.78844282700004 ],
              [-74.9865485659999 , 10.788548580000054],
              [-74.98651490499992, 10.78855984900008 ],
              [-74.98637403799995, 10.788607012000057],
              [-74.98623178499992, 10.788686380000058],
              [-74.98606277699992, 10.788896547000093],
              [-74.98579859799992, 10.789115193000043],
              [-74.9855521149999 , 10.78942587000006 ],
              [-74.98529217799995, 10.789681295000037],
              [-74.98513491799991, 10.789875463000044],
              [-74.98501441099995, 10.790109009000048],
              [-74.9849463889999 , 10.790269027000079],
              [-74.98493845399992, 10.79028769200005 ],
              [-74.98487069899994, 10.790391156000055],
              [-74.9846578399999 , 10.790254893000053],
              [-74.98373062699994, 10.790555052000059],
              [-74.98342679599995, 10.790290235000043],
              [-74.98208008299991, 10.790451110000049],
              [-74.98187945799992, 10.790524790000063],
              [-74.98099167799995, 10.790850826000053],
              [-74.98050622099993, 10.790692409000087],
              [-74.9796567919999 , 10.790455135000059],
              [-74.9787264169999 , 10.790178132000051],
              [-74.97803637999993, 10.789973423000049],
              [-74.97791552199993, 10.790401213000052],
              [-74.97779122899993, 10.790311079000048],
              [-74.97765449499991, 10.790231609000045],
              [-74.9775469679999 , 10.790197790000093],
              [-74.97743636899992, 10.790230180000037],
              [-74.9773452949999 , 10.790301958000043],
              [-74.97724528999993, 10.790396030000068],
              [-74.9772128269999 , 10.790442859000052],
              [-74.97717920199995, 10.790463569000053],
              [-74.97712086299993, 10.79045923600006 ],
              [-74.97697591699995, 10.790451131000054],
              [-74.97696251199994, 10.790413285000056],
              [-74.97693185299994, 10.79038392700005 ],
              [-74.97692325999992, 10.790375698000048],
              [-74.97689364399992, 10.790352658000074],
              [-74.97687585499995, 10.790338819000056],
              [-74.97682261599994, 10.790301513000088],
              [-74.97672878599991, 10.79024682000005 ],
              [-74.97664769399995, 10.790198863000057],
              [-74.97655270099995, 10.790128010000046],
              [-74.97646701499991, 10.79006085800006 ],
              [-74.9763886689999 , 10.79003776400009 ],
              [-74.97626678299991, 10.790030297000044],
              [-74.97613077299991, 10.79004777700004 ],
              [-74.97605781599992, 10.790020611000045],
              [-74.9759535469999 , 10.78997952900005 ],
              [-74.97589073899991, 10.789976322000086],
              [-74.97572357599995, 10.789943354000059],
              [-74.9756891479999 , 10.789932100000044],
              [-74.9756105809999 , 10.78990039200005 ],
              [-74.97550828899995, 10.789895716000046],
              [-74.97547328299993, 10.78989312400006 ],
              [-74.97538735499995, 10.78990705700005 ],
              [-74.9753031969999 , 10.789938533000054],
              [-74.97515821299993, 10.790003217000049],
              [-74.97504642299992, 10.790029981000089],
              [-74.97495096099993, 10.790075363000085],
              [-74.9749404559999 , 10.790081360000045],
              [-74.97485651199992, 10.79012928800006 ],
              [-74.97477788899994, 10.790254023000045],
              [-74.97473292399991, 10.790401562000056],
              [-74.9747028729999 , 10.79050088100007 ],
              [-74.9746309059999 , 10.790620334000039],
              [-74.97456448499992, 10.790601859000049],
              [-74.97442042799992, 10.790590941000062],
              [-74.9742346509999 , 10.79066871300006 ],
              [-74.9741827119999 , 10.790814028000057],
              [-74.97397778899995, 10.791062556000043],
              [-74.97395676999992, 10.791061958000057],
              [-74.97385631999992, 10.791059105000045],
              [-74.9737497019999 , 10.791039063000085],
              [-74.97359450599993, 10.791021571000044],
              [-74.97352418899993, 10.790981295000051],
              [-74.97353221399993, 10.790878528000064],
              [-74.97349811599992, 10.79072086900004 ],
              [-74.97329733099991, 10.790641922000077],
              [-74.97311142299992, 10.790645063000056],
              [-74.97296053299993, 10.790719804000048],
              [-74.9729742159999 , 10.790916166000045],
              [-74.97291692599993, 10.791055450000044],
              [-74.97291327399995, 10.791055671000038],
              [-74.97270704499994, 10.791068280000047],
              [-74.97247317999995, 10.791090785000051],
              [-74.9722925179999 , 10.791249452000045],
              [-74.97235767199993, 10.791530120000061],
              [-74.97257597999993, 10.791558124000062],
              [-74.97274323399995, 10.791645454000047],
              [-74.97272541399991, 10.791873858000088],
              [-74.97255588299993, 10.791964427000039],
              [-74.97241700599994, 10.791959673000065],
              [-74.97221069199992, 10.791852169000038],
              [-74.9721308689999 , 10.791685298000061],
              [-74.9720532369999 , 10.791564538000046]
            ]
          ]
        ]
      }
    }
  ]
};

export default value;