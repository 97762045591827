import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useNotification } from "contexts/NotificationContext";
import { useEffect, useMemo, useState } from "react";
import { useUpdateTrainTypeMutation } from "services/api/assistant";
import { TrainType } from "types/Train";


interface TypeUpdateProps {
    open: boolean;
    onClose: (event: React.MouseEvent) => void;
    value?: TrainType
}

export function TypeUpdate(props: TypeUpdateProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const defaultValue = useMemo(() => props.value, [props.value]);
    const [execUpdate] = useUpdateTrainTypeMutation();

    const notify = useNotification();
    const [data, setData] = useState<Partial<TrainType.Update>>({});

    useEffect(() => {
        setData(defaultValue || {});
    }, [defaultValue]);

    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        try {
            if (!data.name)
                throw new Error("El nombre es requerido!");

            const body: TrainType.Update = {
                id: data.id!!,
                name: data.name,
                description: data.description
            }

            await execUpdate(body).unwrap();
            notify.success("Tipo actualizado");
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    }

    return (
        <FormModal
            title="Editar tipo"
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={handleClose}
            onSubmit={handleSubmit}
            open={open}>
            <Box>
                <TextField
                    type="text"
                    name="name"
                    label="Nombre"
                    value={data?.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    inputProps={{ maxLength: 32 }}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    required
                />
                <TextField
                    type="text"
                    name="content"
                    label="Descripción (opcional)"
                    value={data?.description}
                    onChange={(e) => setData({ ...data, description: e.target.value })}
                    inputProps={{ maxLength: 128 }}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    minRows={3}
                    maxRows={5}
                    multiline
                    fullWidth
                />
            </Box>
        </FormModal >
    )
}