import { JSONViewer } from "components/JSONViewer/JSONViewer";
import { Markdown } from "components/Markdown/Markdown";
import { useMemo } from "react";
import { ToolMessage } from "types/Message";

interface ContentToolProps {
    message: ToolMessage;
}

export function ContentTool(props: ContentToolProps) {
    const content = useMemo(() => JSON.parse(props.message.content), [props.message]);

    if (typeof content === "object")
        return <JSONViewer src={content} />;

    return <Markdown>{content}</Markdown>;
}