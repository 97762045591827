import { TabPanel } from "components/Tabs/Tabs";
import { FollowUI } from "features/FollowUI/FollowUI";
import { Message, MessageSource } from "features/FollowUI/Message";

interface Props {
    value: any;
}



const messages: Message[] = [
    {
        Sid: "CM78a444b24ab193197630cf706b2924af",
        Source: MessageSource.SYSTEM,
        Text: "Evento creado a petición del usuario por el canal de Whatsapp, con una confiablidad del 50%",
        Profile: {
            Name: "TuRed123",
            Picture: "https://www.serviciopostal.gob.ec/chatbot/uploads/chatbot.png",
            Org: "https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED"
        }
    },
    {
        Sid: "CM78a444b24ab193197630cf706b2924af",
        Source: MessageSource.AGENT,
        Text: "Luego de validar la informacion del usuario en el sistema se procede a confirmar el evento con una confiabilidad del 80%",
        Profile: {
            Name: "ALEX",
            Picture: "https://www.serviciopostal.gob.ec/chatbot/uploads/chatbot.png",
            Org: "https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED"
        }
    },
    {
        Sid: "CM78a444b24ab193197630cf706b2924af",
        Source: MessageSource.AGENT,
        Text: "Unidad de reaccion despachada al lugar de los hechos",
        Profile: {
            Name: "Carlos Palomino",
            Picture: "https://cdn-icons-png.flaticon.com/512/1022/1022331.png",
            Org: "https://www.policia.gov.co/sites/default/files/escudo-policia.png"
        }
    },
    {
        Sid: "CM78a444b24ab193197630cf706b2924af",
        Source: MessageSource.AGENT,
        Text: "Unidad medicalizada solicitada, se reporta una persona herida.",
        Profile: {
            Name: "Juan Rios",
            Picture: "https://cdn-icons-png.flaticon.com/512/6833/6833600.png",
            Org: "https://www.policia.gov.co/sites/default/files/escudo-policia.png"
        }
    },
    {
        Sid: "CM78a444b24ab193197630cf706b2924af",
        Source: MessageSource.AGENT,
        Text: "Ambulancia despachada hacia el lugar indicado",
        Profile: {
            Name: "Luis Alvarez",
            Picture: "https://cdn-icons-png.flaticon.com/512/1734/1734080.png",
            Org: "https://as2.ftcdn.net/v2/jpg/06/72/71/65/1000_F_672716595_z4A4rrsrd1kFaYf5daOKrdBDAukOVoYN.jpg"
        }
    }
];

export function TabEmergencyFollow(props: Props) {
    return (
        <TabPanel value={props.value}>
            <FollowUI messages={messages} />
        </TabPanel>
    )
}