import { TabContext, TabList, TabPanel, TabPanelProps } from "@mui/lab";
import { Box, Paper, Tab, TabProps } from "@mui/material";
import { SyntheticEvent, useState } from "react";


interface EnhancedTabsProps {
    value?: any;
    tabProps: Array<TabProps & { value: string; }>;
    tabPanelProps: Array<TabPanelProps>;
}

export function EnhancedTabs(props: EnhancedTabsProps) {
    const [value, setValue] = useState<any>(props.value || props.tabProps[0].value || 0);

    const handleChange = (_: SyntheticEvent, newValue: any) => {
        setValue(newValue);
    };

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="fullWidth"
                    onChange={handleChange}
                    centered>
                    {
                        props.tabProps.map((tabProps) => <Tab {...tabProps} />)
                    }
                </TabList>
            </Box>
            {
                props.tabPanelProps.map((tabPanelProps) => <TabPanel {...tabPanelProps} sx={{ height: '60vh', overflow: 'auto', p: 2, ...tabPanelProps.sx, }} />)
            }
        </TabContext>
    )
}