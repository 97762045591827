import { Computer, Engineering, Person, Settings, SvgIconComponent } from "@mui/icons-material";
import { alpha, Menu, MenuItem, MenuProps, styled, Typography } from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import { CreateSystem } from "./CreateSystem";
import { CreateUser } from "./CreateUser";
import { CreateAssistant } from "./assistant/CreateAssistant";
import { CreateTool } from "./CreateTool";
import { TrainChat, TrainMessage } from "types/Train";


const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        backgroundColor: theme.palette.primary.main,
        '& .MuiSvgIcon-root': {
            color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
        },
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            /*
            '&:active, &:focus': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.getContrastText(theme.palette.secondary.main),
            },
            */
            '&:hover': {
                backgroundColor: alpha(
                    theme.palette.secondary.main,
                    .5,
                ),
                color: theme.palette.getContrastText(theme.palette.secondary.main),
            },
        },
    },
}));


interface MenuOptions {
    role: TrainMessage.Role;
    title: string;
    icon: SvgIconComponent;
}

const menuOptions: MenuOptions[] = [
    { role: "system", title: "Sistema", icon: Computer },
    { role: "user", title: "Usuario", icon: Person },
    { role: "assistant", title: "Asistente", icon: Engineering },
    { role: "tool", title: "Herramienta", icon: Settings },
]

interface MenuCreateMessageProps {
    open: boolean;
    anchorEl?: MenuProps['anchorEl'];
    onClose?: MenuProps['onClose'];
    chatId?: TrainChat['id'];
}

export function MenuCreateMessage(props: MenuCreateMessageProps) {
    const open = useMemo(() => props.open, [props.open]);
    const anchorEl = useMemo(() => props.anchorEl, [props.anchorEl]);
    const onClose = useMemo(() => props.onClose, [props.onClose]);
    const chatId = useMemo(() => props.chatId, [props.chatId]);

    const [selected, setSelected] = useState<TrainMessage.Role | undefined>(undefined);


    return (
        <Fragment>
            <StyledMenu
                anchorEl={anchorEl}
                onClose={onClose}
                open={open}>
                {
                    menuOptions.map(({ icon: Icon, role, title }) => (
                        <MenuItem
                            disableRipple
                            key={role}
                            value={role}
                            onClick={() => setSelected(role)}>
                            {<Icon />}
                            <Typography variant="inherit">{title}</Typography>
                        </MenuItem>
                    ))
                }
            </StyledMenu>

            {(!!chatId && selected === "system") &&
                <CreateSystem
                    open={selected === "system"}
                    onClose={() => setSelected(undefined)}
                    chatId={chatId}
                />}

            {(!!chatId && selected === "user") &&
                <CreateUser
                    open={selected === "user"}
                    onClose={() => setSelected(undefined)}
                    chatId={chatId}
                />}

            {(!!chatId && selected === "assistant") &&
                <CreateAssistant
                    open={selected === "assistant"}
                    onClose={() => setSelected(undefined)}
                    chatId={chatId}
                />}

            {(!!chatId && selected === "tool") &&
                <CreateTool
                    open={selected === "tool"}
                    onClose={() => setSelected(undefined)}
                    chatId={chatId}
                />}
        </Fragment>
    )
}
