import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { useNotification } from "contexts/NotificationContext";
import { useMemo, useState } from "react";
import { useCreateTrainMessageMutation } from "services/api/assistant";
import { SystemMessage } from "types/Message";


interface CreateSystemProps {
    open: boolean;
    onClose: () => void;
    chatId: number;
}

export function CreateSystem(props: CreateSystemProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const chatId = useMemo(() => props.chatId, [props.chatId]);

    const [execCreate] = useCreateTrainMessageMutation();
    const notify = useNotification();
    const [data, setData] = useState<Partial<SystemMessage>>({});


    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        try {
            if (!data.content)
                throw new Error("El contenido es requerido!");

            const message: SystemMessage = {
                role: "system",
                name: data.name,
                content: data.content
            }

            await execCreate({ ...message, chat_id: chatId });
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    }

    return (
        <FormModal
            title="Nuevo mensaje del sistema"
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={handleClose}
            onSubmit={handleSubmit}
            open={open}>
            <Box>
                <TextField
                    type="text"
                    name="name"
                    label="Nombre (opcional)"
                    value={data?.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                />
                <TextField
                    type="text"
                    name="content"
                    label="Contenido"
                    value={data?.content}
                    onChange={(e) => setData({ ...data, content: e.target.value })}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    minRows={3}
                    maxRows={5}
                    multiline
                    fullWidth
                    required
                />
            </Box>
        </FormModal >
    )
}