import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import * as locales from '@mui/material/locale';

import { type CSSObject, Global } from '@emotion/react';
import {
    createAppTheme,
    createAppStylesBaseline,
    type AnimatorGeneralProviderSettings,
    AnimatorGeneralProvider,
    Animator,
    Animated,
    aaVisibility,
    aa,
    type BleepsProviderSettings,
    BleepsProvider,
    useBleeps,
    BleepsOnAnimator,
    FrameSVGCorners,
    GridLines,
    Dots,
    MovingLines,
    Text,
    AppThemeSettingsPalette,
    createThemeColor,
    Puffs
} from '@arwes/react';


// Custom color palette generator.
const createThemePalette = (hue: number): AppThemeSettingsPalette => ({
    // Darkening colors.
    main: (i: number) => [hue, 70, i * 5, 1],
    text: (i: number) => [hue, 10, 92.5 - i * 9.44],

    // Lightening colors.
    bg: (i: number) => [hue, 10, 2 + i * 2],
    ol: (i: number) => [hue, 70 + 1, 2 + i * 2],
    deco: (i: number) => [hue, 70 + 1, 50, 0.025 + i * 0.025]
});

const theme = createAppTheme({
    settings: {
        hues: {
            primary: 180,
            secondary: 60,
            error: 0,
            warning: 50,
            success: 100,
            info: 200
        },
        colors: {
            primary: createThemePalette(180),
            secondary: createThemePalette(60),
            error: createThemePalette(0),
            warning: createThemePalette(50),
            success: createThemePalette(100),
            info: createThemePalette(200),
        }
    }
});
const stylesBaseline = createAppStylesBaseline(theme);


const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: theme.colors.primary.main(7),
            main: theme.colors.primary.main(5),
            dark: theme.colors.primary.main(2),
            contrastText: theme.colors.primary.text(2)
        },
        secondary: {
            light: theme.colors.secondary.main(7),
            main: theme.colors.secondary.main(5),
            dark: theme.colors.secondary.main(2),
            contrastText: theme.colors.secondary.text(2)
        },
        background: {
            default: theme.colors.primary.deco(2),
            paper: theme.colors.secondary.deco(2)
        },
        error: {
            light: theme.colors.error.main(7),
            main: theme.colors.error.main(5),
            dark: theme.colors.error.main(2),
            contrastText: theme.colors.error.text(2)
        },
        warning: {
            light: theme.colors.warning.main(7),
            main: theme.colors.warning.main(5),
            dark: theme.colors.warning.main(2),
            contrastText: theme.colors.warning.text(2)
        },
        success: {
            light: theme.colors.success.main(7),
            main: theme.colors.success.main(5),
            dark: theme.colors.success.main(2),
            contrastText: theme.colors.success.text(2)
        },
        info: {
            light: theme.colors.info.main(7),
            main: theme.colors.info.main(5),
            dark: theme.colors.info.main(2),
            contrastText: theme.colors.info.text(2)
        }

    },
    spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
}, locales["esES"]);


const animatorsSettings: AnimatorGeneralProviderSettings = {
    duration: {
        enter: 0.2,
        exit: 0.2,
        stagger: 0.04
    }
};

const bleepsSettings: BleepsProviderSettings = {
    master: {
        volume: 0.9
    },
    bleeps: {
        intro: {
            sources: [{ src: 'https://arwes.dev/assets/sounds/intro.mp3', type: 'audio/mpeg' }]
        },
        click: {
            sources: [{ src: 'https://arwes.dev/assets/sounds/click.mp3', type: 'audio/mpeg' }]
        }
    }
};

export function DashboardLayout() {

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Global styles={stylesBaseline as Record<string, CSSObject>} />
            <AnimatorGeneralProvider {...animatorsSettings}>
                <BleepsProvider {...bleepsSettings}>
                    <Animator active={true} combine manager='stagger'>
                        <Animator>
                            <Box position="absolute" sx={{ inset: 0, backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(180, 100%, 25%, 0.25) 0%, hsla(180, 100%, 25%, 0.12) 20%, hsla(180, 100%, 25%, 0) 100%)' }}>
                                <MovingLines distance={30} sets={20} lineColor={theme.colors.secondary.deco(1)} />
                                <GridLines distance={30} lineColor={theme.colors.primary.deco(1)} />
                            </Box>
                        </Animator>
                        <Box sx={{ display: 'flex' }}>
                            <Box component="main"
                                overflow="auto"
                                className='Scrollable'
                                sx={{ p: '1px', height: "100vh", width: "100vw" }}>
                                <Outlet />
                            </Box>
                        </Box>
                    </Animator>
                </BleepsProvider>
            </AnimatorGeneralProvider>
        </ThemeProvider>
    );
}