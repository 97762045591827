import { FormControl, InputLabel, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { ExecuteFunction } from "components/ExecuteFunction/ExecuteFunction";
import { FormModal } from "components/Modal";
import { useNotification } from "contexts/NotificationContext";
import { useEffect, useMemo, useState } from "react";
import { useLazyRetrieveTrainMessagesQuery, useUpdateTrainMessageMutation } from "services/api/assistant";
import { AssistantMessage, ToolMessage } from "types/Message";

interface UpdateToolProps {
    open: boolean;
    onClose: () => void;
    message: ToolMessage & {
        id: number;
        chat_id: number;
    };
}

export function UpdateTool(props: UpdateToolProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const message = useMemo(() => props.message, [props.message]);

    const [execUpdate] = useUpdateTrainMessageMutation();
    const [trigger, { data: messages, }] = useLazyRetrieveTrainMessagesQuery();
    const notify = useNotification();

    const [options, setOptions] = useState<AssistantMessage.Tool[]>([]);
    const option = useMemo(() => options.find(({ id }) => (id === message.tool_call_id)), [message.tool_call_id, options]);
    const [currentResult, setResult] = useState<unknown>();


    useEffect(() => {
        trigger(message.chat_id);
    }, [message.chat_id, trigger]);

    useEffect(() => {
        if (!messages) return;

        const findTool = (): AssistantMessage.Tool | undefined =>
            messages
                .filter((it) => it.role === "assistant" && (it.tool_calls || []).length > 0) // 1. Filtrar mensajes del asistente con tool_calls.
                .flatMap<AssistantMessage.Tool>((it) => it.tool_calls || []) // 2. Aplanar tool_calls en un solo arreglo.
                .find((it) => (it.id === message.tool_call_id)); // 3. Buscar herramienta con id igual a message.tool_call_id.

        const currentTool = findTool();

        if (currentTool)
            setOptions([currentTool]);
        else setOptions([]);
    }, [message.tool_call_id, messages]);


    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        try {
            if (!currentResult)
                throw new Error("El contenido es requerido!");

            const body: ToolMessage = {
                role: "tool",
                tool_call_id: message.tool_call_id,
                content: JSON.stringify(currentResult),
            }

            await execUpdate({ ...body, id: message.id, chat_id: message.chat_id });
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    }

    return (
        <FormModal
            title="Actualizar resultado herramienta."
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={handleClose}
            onSubmit={handleSubmit}
            open={open}>
            <FormControl fullWidth margin="dense">
                <InputLabel id="tool_call_id-label">ID de llamada de herramienta</InputLabel>
                <Select
                    labelId="tool_call_id-label"
                    id="tool_call_id"
                    label="ID de llamada de herramienta"
                    defaultValue={message.tool_call_id}
                    margin="dense"
                    inputProps={{ readOnly: true }}
                >
                    {
                        options.map(({ id, type, function: func }) => (
                            <MenuItem value={id}>
                                <ListItemText
                                    primary={id}
                                    secondary={
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {`${type} ${func.name}()`}
                                        </Typography>
                                    }
                                />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <ExecuteFunction
                function={option?.function.name}
                arguments={option?.function.arguments}
                onResult={setResult}
            />
        </FormModal >
    )
}