import { useState } from "react";
import { Avatar, Badge, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetToken } from "services/slices/authSlice";


export function ProfileMenu() {
    const dispatch = useDispatch();

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        dispatch(resetToken());
    }

    return (
        <Box sx={{ flexGrow: 0, mx: 2 }}>
            <Badge badgeContent={4} color="secondary">
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt="Remy Sharp" src="https://mui.com/static/images/avatar/2.jpg" />
                    </IconButton>
                </Tooltip>
            </Badge>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                <MenuItem key="profile" onClick={handleCloseUserMenu}><Typography textAlign="center">{'Perfil'}</Typography></MenuItem>
                <MenuItem key="account" onClick={handleCloseUserMenu}><Typography textAlign="center">{'Cuenta'}</Typography></MenuItem>
                <MenuItem key="logout" onClick={(handleLogout)}><Typography textAlign="center">{'Cerrar sesión'}</Typography></MenuItem>
            </Menu>
        </Box>
    );
}