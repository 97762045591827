import { DataObject } from "@mui/icons-material";
import { Avatar, FormControl, InputBaseProps, InputLabel, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, } from "@mui/material";
import { useNotification } from "contexts/NotificationContext";
import { useEffect, useState } from "react";
import { useRetrieveTrainToolFunctionsQuery } from "services/api/assistant";
import { TrainTool } from "types/Train";

interface SelectToolFunctionProps {
    readOnly?: InputBaseProps['readOnly'];
    defaultValue?: string;
    onChange?: (value?: TrainTool.Function<any>) => void;
}

export function SelectToolFunction(props: SelectToolFunctionProps) {
    const [value, setValue] = useState<string>();
    const notify = useNotification();

    const { data, isError, error } = useRetrieveTrainToolFunctionsQuery();

    useEffect(() => {
        if (isError)
            notify.http(error);
    }, [error, isError, notify]);

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue]);

    useEffect(() => {
        if (!data || !props.onChange)
            return;

        const item = data.find(({ function: func }) => (func === value));
        props.onChange(item);
    }, [data, props, value]);

    return (
        <FormControl fullWidth margin="dense" variant="outlined" sx={{ p: 0 }} required>
            <InputLabel id="function-select-label">Seleccionar función</InputLabel>
            <Select
                labelId="function-select-label"
                id="function-select"
                label="Seleccionar función"
                defaultValue={props.defaultValue}
                value={value}
                onChange={(e) => setValue(e.target.value as string)}
                inputProps={{ readOnly: props.readOnly }}
                sx={{ "& #function-select": { p: 1, minHeight: 48 } }}
            >
                {data?.map(({ function: func, name }) =>
                    <MenuItem value={func} sx={{ p: 1 }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DataObject />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={name} secondary={func} />
                        </ListItem>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}