import { ExpandLess, ExpandMore, SvgIconComponent } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { Key, ReactNode, useState } from "react";


export declare type ListItemProps = {
    key?: Key;
    label: ReactNode;
    icon?: SvgIconComponent;
    href?: string;
}


function ListItem({ icon: Icon, label, href, sx }: ListItemProps & { sx?: ListItemButtonProps['sx'] }) {
    return (
        <ListItemButton component="a" href={href} sx={sx}>
            {(Icon) ? <ListItemIcon>{<Icon />}</ListItemIcon> : null}
            <ListItemText primary={label} />
        </ListItemButton>
    )
}


export declare type SubListItemProps = Omit<ListItemProps, 'href'> & { children?: Array<ListItemProps>; };

function isSubList(item: ListItemProps | SubListItemProps): item is SubListItemProps {
    return (item as any).children && Array.isArray((item as any).children);
}

function SubListItem(props: SubListItemProps) {
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    {(props.icon) ? <props.icon /> : null}
                </ListItemIcon>
                <ListItemText primary={props.label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {props.children?.map((item) => <ListItem {...item} sx={{ pl: 8 }} />)}
                </List>
            </Collapse>
        </>
    );
}

interface ListCollapseProps {
    subheader?: string;
    children?: Array<ListItemProps | SubListItemProps>;
}

export function ListCollapse(props: ListCollapseProps) {
    return (
        <List
            sx={{ bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={props.subheader ? <ListSubheader component="div" id="nested-list-subheader">{props.subheader}</ListSubheader> : null}>
            {
                props.children?.map((item) => {
                    if (isSubList(item)) {
                        return <SubListItem {...item} />
                    }
                    return <ListItem {...item} />
                })
            }
        </List>
    )
}