import { TrainCase, TrainSubType, } from "../../../types/Train";
import { AssistantAPI } from ".";

export const injectTrainSubType = (api: AssistantAPI) => api.injectEndpoints({
    endpoints: (build) => ({
        createTrainSubType: build.mutation<TrainSubType, TrainSubType.Create>({
            query: (body) => ({
                url: "/trains/sub_types",
                method: "POST",
                data: body
            }),
            invalidatesTags: (result, err, { type_id }) => [{ type: "TrainType", id: `TrainSubType_${type_id}` }]
        }),
        retrieveTrainSubType: build.query<TrainSubType, TrainSubType['id']>({
            query: (id: TrainSubType['id']) => ({
                url: `/trains/sub_types/${id}`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([{ type: "TrainSubType", id }]),
        }),
        updateTrainSubType: build.mutation<{ affectedCount: number }, TrainSubType.Update>({
            query: (body) => {
                const { id, type_id, ...data } = body;
                return ({
                    url: `/trains/sub_types/${id}`,
                    method: "PUT",
                    data
                })
            },
            invalidatesTags: (result, error, { id, type_id }) => [
                { type: "TrainSubType", id },
                { type: "TrainType", id: `TrainSubType_${type_id}` },
            ],
        }),
        deleteTrainSubType: build.mutation<{ affectedCount: number }, TrainSubType.Delete>({
            query: ({ id }) => ({
                url: `/trains/sub_types/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, { id, type_id }) => [
                { type: "TrainSubType", id },
                { type: "TrainType", id: `TrainSubType_${type_id}` },
            ],
        }),
        retrieveTrainCases: build.query<TrainCase[], TrainCase['id']>({
            query: (id: TrainCase['id']) => ({
                url: `/trains/sub_types/${id}/cases`,
                method: "GET"
            }),
            providesTags: (result, err, id) => ([
                { type: "TrainSubType", id: `TrainCase_${id}` },
                ...(result || []).map(({ id }) => ({ type: "TrainCase", id } as const))
            ]),
        }),
    }),
    overrideExisting: "throw",
});