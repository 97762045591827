import { CreateNewFolder, ExpandLess, ExpandMore, Folder, FolderOpen, } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { TrainCase, TrainChat, TrainSubType, TrainType } from "types/Train";
import { useLazyRetrieveTrainSubTypesQuery } from "services/api/assistant";
import { Cases } from "../cases";
import { SubTypeCreate } from "./SubTypeCreate";
import { SubTypeMenu } from "./SubTypeMenu";


interface SubTypesProps {
    trainType?: TrainType;
    selected?: TrainSubType;
    onChange?: (value?: TrainSubType) => void;
    selectedCase?: TrainCase;
    onChangeCase?: (value?: TrainCase) => void;
    selectedChat?: TrainChat;
    onChangeChat?: (value?: TrainChat) => void;
}

export function SubTypes({
    trainType,
    selected, onChange,
    selectedCase, onChangeCase,
    selectedChat, onChangeChat,
}: SubTypesProps) {
    const [hovered, setHovered] = useState<number | null>(null);
    const [create, setCreate] = useState<boolean>(false);
    const [trigger, { data }] = useLazyRetrieveTrainSubTypesQuery();

    useEffect(() => {
        trigger(trainType?.id!!);
    }, [trigger, trainType]);

    return (
        <Fragment>
            <List component="div" disablePadding dense>
                <ListItemButton sx={{ pl: 8 }} onClick={() => setCreate(true)}>
                    <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 2 }}>
                        <CreateNewFolder />
                    </ListItemIcon>
                    <ListItemText primary={"Nuevo"} secondary={"Crear sub tipo"} />
                </ListItemButton>

                {data?.map((it) => (
                    <Fragment key={it.id}>
                        <ListItem
                            disablePadding
                            secondaryAction={hovered === it.id && <SubTypeMenu value={it} />}
                            onMouseEnter={() => setHovered(it.id)}
                            onMouseLeave={() => setHovered(null)}
                        >
                            <ListItemButton
                                sx={{ pl: 8, }}
                                selected={selected?.id === it.id}
                                onClick={() => onChange?.(it)}
                            >
                                <ListItemIcon sx={{ minWidth: 24, minHeight: 24, mr: 2 }}>
                                    {selected?.id === it.id ? <FolderOpen /> : <Folder />}
                                </ListItemIcon>
                                <ListItemText primary={it.name} secondary={it.description} />
                                {(hovered !== it.id) && (selected && selected.id === it.id ? <ExpandLess /> : <ExpandMore />)}
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={selected?.id === it.id} timeout="auto" unmountOnExit>
                            <Cases
                                trainSubType={selected}
                                selected={selectedCase}
                                onChange={onChangeCase}
                                selectedChat={selectedChat}
                                onChangeChat={onChangeChat}
                            />
                        </Collapse>
                    </Fragment>
                ))}
            </List>
            {(create && trainType) &&
                <SubTypeCreate
                    open={create}
                    onClose={() => setCreate(false)}
                    typeId={trainType?.id}
                />
            }
        </Fragment>
    );
}
