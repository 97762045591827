import { Box, Stack, Typography } from "@mui/material";

export const NotFoundPage = () => {
    return (
        <Box component="main" sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', minHeight: '100%' }}>
            <Stack spacing={3} sx={{ alignItems: 'center', maxWidth: 'md' }}>
                <Box>
                    <Box
                        component="img"
                        alt="Under development"
                        src="https://material-kit-react.devias.io/assets/error-404.png"
                        sx={{ display: 'inline-block', height: 'auto', maxWidth: '100%', width: '200px', mt: '100px' }}
                    />
                </Box>
                <Typography variant="h3" sx={{ textAlign: 'center' }}>
                    404: No encontrado
                </Typography>
            </Stack>
        </Box>
    );
};