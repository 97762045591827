//nanoid.ts
import { customAlphabet } from 'nanoid'


const numbers = '0123456789';
const lowercase = 'abcdefghijklmnopqrstuvwxyz';
const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const alphanumeric = numbers + lowercase + uppercase;

const nanoid = customAlphabet(alphanumeric, 18);

export const generateSID =
    (size?: number): string => nanoid(size);
