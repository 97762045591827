import { Action } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react"
import { LatLngBounds, LatLng, } from "leaflet";
import { REHYDRATE } from "redux-persist";
import { RootState } from "services/store";
import { axiosBaseQuery } from "services/utils/axiosBaseQuery";


interface Address {
    road: string
    hamlet: string
    town: string
    village: string
    city: string
    state_district: string
    state: string
    postcode: string
    country: string
    country_code: string
}

export interface Nominatim {
    place_id: number;
    licence: string;
    osm_type: string;
    osm_id: number;
    lat: string;
    lon: string;
    category: string;
    type: string;
    place_rank: number;
    importance: number;
    addresstype: string;
    name: string;
    display_name: string;
    address: Address;
    boundingbox: [string, string, string, string];
    geojson: GeoJSON.Geometry;
}


interface SearchArgs {
    query: string;
    viewbox?: LatLngBounds;
}

interface ReverseArgs {
    position: LatLng;
    zoom?: number;
}


const api = createApi({
    reducerPath: "nominatim",
    tagTypes: ["Nominatim"],
    baseQuery: axiosBaseQuery({ baseURL: "https://nominatim.openstreetmap.org" }),
    extractRehydrationInfo: (action, { reducerPath, }): any => {
        const isHydrateAction = (action: Action):
            action is Action<typeof REHYDRATE> & { key: string; payload: RootState; err: unknown; } =>
            action.type === REHYDRATE;
        if (isHydrateAction(action)) {
            // when persisting the api reducer
            if (action.key === 'key used with redux-persist') {
                return action.payload
            }

            // When persisting the root reducer
            if (action.payload)
                return action.payload[reducerPath]
        }
        return undefined
    },
    endpoints: (build) => ({
        search: build.query<Array<Nominatim>, SearchArgs>({
            query(arg) {
                const params: any = {
                    q: arg.query,
                    format: "jsonv2",
                    addressdetails: 1,
                    countrycodes: "co",
                    polygon_geojson: 1,
                };
                if (arg.viewbox) {
                    params['viewbox'] = arg.viewbox.toBBoxString();
                    params['bounded'] = 1;
                }
                return ({
                    url: "/search",
                    method: "GET",
                    params
                })
            }
        }),
        reverse: build.query<Nominatim, ReverseArgs>({
            query(arg) {
                const params: any = {
                    format: "jsonv2",
                    addressdetails: 1,
                    polygon_geojson: 1,
                    lat: arg.position.lat,
                    lon: arg.position.lng,
                };
                if (arg.zoom) {
                    params['zoom'] = ((arg.zoom < 3) ? 3 : (arg.zoom > 18) ? 18 : arg.zoom);
                }
                return ({
                    url: "/reverse",
                    method: "GET",
                    params
                })
            },
        })
    }),
});

export default api;

export const {
    useSearchQuery,
    useLazySearchQuery,
    useReverseQuery,
    useLazyReverseQuery,
} = api;