import { Box, TextField } from "@mui/material";
import { FormModal } from "components/Modal";
import { SelectLazyQuery } from "components/Select/SelectLazyQuery";
import { SelectQuery } from "components/Select/SelectQuery";
import { useNotification } from "contexts/NotificationContext";
import { useEffect, useMemo, useState } from "react";
import { useLazyRetrieveTrainCasesQuery, useLazyRetrieveTrainSubTypesQuery, useRetrieveTrainTypesQuery, useUpdateTrainChatMutation, } from "services/api/assistant";
import { TrainCase, TrainChat, TrainSubType, TrainType, } from "types/Train";


interface ChatUpdateProps {
    open: boolean;
    onClose: () => void;
    value?: TrainChat;
}

export function ChatUpdate(props: ChatUpdateProps) {
    const open = useMemo(() => props.open, [props.open]);
    const handleClose = useMemo(() => props.onClose, [props.onClose]);
    const defaultValue = useMemo(() => props.value, [props.value]);
    const [execUpdate] = useUpdateTrainChatMutation();

    const notify = useNotification();
    const [data, setData] = useState<Partial<TrainChat.Update>>({});

    useEffect(() => {
        setData(defaultValue || {});
    }, [defaultValue]);

    const [trainType, setTrainType] = useState<TrainType | null>(null);
    const [trainSubType, setTrainSubType] = useState<TrainSubType | null>(null);
    const [trainCase, setTrainCase] = useState<TrainCase | null>(null);


    const handleSubmit = async (_: { [key: string]: FormDataEntryValue; }) => {
        try {
            if (!data.name)
                throw new Error("El nombre es requerido!");

            const body: TrainChat.Update = {
                id: defaultValue?.id!!,
                case_id: defaultValue?.case_id!!,
                name: data.name,
                description: data.description
            }

            await execUpdate(body).unwrap();
            notify.success("Chat actualizado");
            return true;
        } catch (error) {
            notify.http(error);
            return false;
        }
    }

    return (
        <FormModal
            title="Nuevo chat"
            buttonPositive="Guardar"
            dialogProps={{ maxWidth: "xs", fullWidth: true }}
            onClose={handleClose}
            onSubmit={handleSubmit}
            open={open}>
            <Box>

                {!defaultValue?.case_id && <>
                    <SelectQuery<TrainType, void>
                        query={useRetrieveTrainTypesQuery}
                        queryArg={undefined}
                        valueField="id"
                        displayField="name"
                        captionField="description"
                        label="Tipo"
                        onChange={setTrainType}
                    />

                    <SelectLazyQuery<TrainSubType, number>
                        queryLazy={useLazyRetrieveTrainSubTypesQuery}
                        queryArg={trainType?.id!!}
                        valueField="id"
                        displayField="name"
                        captionField="description"
                        label="Sub-Tipo"
                        onChange={setTrainSubType}
                    />

                    <SelectLazyQuery<TrainCase, number>
                        queryLazy={useLazyRetrieveTrainCasesQuery}
                        queryArg={trainSubType?.id!!}
                        valueField="id"
                        displayField="name"
                        captionField="description"
                        label="Casos"
                        onChange={setTrainCase}
                    />
                </>}

                {defaultValue?.completion_id &&
                    <TextField
                        type="text"
                        name="completion_sid"
                        label="Completion sid"
                        value={defaultValue?.completion_id || "-"}
                        onChange={(e) => setData(prev => ({ ...prev, completion_id: Number(e.target.value).valueOf() }))}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                }
                <TextField
                    type="text"
                    name="name"
                    label="Nombre"
                    value={data?.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    inputProps={{ maxLength: 32 }}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    required
                />
                <TextField
                    type="text"
                    name="content"
                    label="Descripción (opcional)"
                    value={data?.description}
                    onChange={(e) => setData({ ...data, description: e.target.value })}
                    inputProps={{ maxLength: 128 }}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    minRows={3}
                    maxRows={5}
                    multiline
                    fullWidth
                />
            </Box>
        </FormModal >
    )
}