import { Avatar, Badge, Box, Stack } from "@mui/material";
import { deepOrange, deepPurple, red } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import { Message, MessageBodyType, MessageSource } from "./Message";
import { Bubble } from "./Bubble";

interface Props {
    position: "left" | "center" | "right";
    messages: Message[];
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    padding: 1,
    fontSize: 14,
    textAlign: 'center',
    border: `2px solid ${theme.palette.background.paper}`,
}));

export function BubbleGroup(props: Props) {
    if (props.position === "center") {
        return (
            <>
                {props.messages.map((it, index) => (<Bubble message={it} position={props.position} index={index} />))}
            </>
        );
    }

    if (props.position === "left") {
        return (
            <Stack spacing={2} direction={"row"}>
                <Avatar sx={{ bgcolor: deepPurple[500], maxHeight: 48 }} alt={props.messages[0].Profile.Name} src={props.messages[0].Profile.Picture} />
                <Stack spacing={1} direction={"column"}>
                    {
                        props.messages.map((it, index) => (<Bubble message={it} position={props.position} index={index} />))
                    }
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack spacing={2} direction={"row-reverse"}>
            <Badge
                sx={{ maxHeight: 48 }}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <SmallAvatar alt={props.messages[0].Profile.Name} src={props.messages[0].Profile.Org} />
                }>
                <Avatar sx={{ bgcolor: red[500] }} alt={props.messages[0].Profile.Name} src={props.messages[0].Profile.Picture} />
            </Badge>
            <Stack spacing={1} direction={"column"}>
                {
                    props.messages.map((it, index) => (<Bubble message={it} position={props.position} index={index} />))
                }
            </Stack>
        </Stack>
    )

}