import { Box } from '@mui/material';
import ReactMarkdown, { Options } from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface MarkdownProps {
    children?: Options['children'];
}

export function Markdown(props: MarkdownProps) {

    return (
        <Box sx={{
            fontSize: '.9rem',
            fontWeight: 400,
            '& p': {
                my: 0,
                mx: 0,
            },
            overflow: 'auto',
            height: "auto",
            width: "auto",
            maxWidth: "100%",
            maxHeight: "50vh",
            '& img': {
                m: -1,
                height: "100%",
                width: "100%",
            }
        }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {props.children}
            </ReactMarkdown>
        </Box>
    )
}