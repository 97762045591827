import { ArrowBack, Attachment, Chat, ContactEmergency, History, Report } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Chip, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { EnhancedTabs } from "components/Tabs/EnhancedTabs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InfoTab } from "./InfoTab";
import { FollowUpsTab } from "./FollowUpsTab";
import { ChatTab } from "./ChatTab";
import { Event } from "types/Event";
import { useRetrieveEventQuery } from "services/api/admin";

interface IncidentPageProps {

}

export function EventPage(props: IncidentPageProps) {
    const [eventId, setEventId] = useState<string>();
    const [event, setEvent] = useState<Event>();

    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => setEventId(params['eventId']), [params]);

    const { data } = useRetrieveEventQuery(eventId!!);
    useEffect(() => setEvent(data), [data]);

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <Box sx={{ width: '100%', mb: 2 }}>
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Grid container spacing={1} columns={14}>
                        <Grid xs={2} xsOffset={1}>
                            <AvatarGroup max={4} total={5}>
                                <Avatar alt="TuRed123" src="https://scontent.fbaq6-1.fna.fbcdn.net/v/t39.30808-6/402168850_122118181508049867_7866274107371916145_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FVAib4dyb2kAb68i4G_&_nc_ht=scontent.fbaq6-1.fna&oh=00_AfDKBRCFYY0MfEsxWKpfHqnbJdFv5lQ18ynzbqISa_0pOg&oe=6628D0ED" />
                                <Avatar alt="Policia" src="https://www.policia.gov.co/sites/default/files/escudo-policia.png" />
                                <Avatar alt="Salud" src="https://as2.ftcdn.net/v2/jpg/06/72/71/65/1000_F_672716595_z4A4rrsrd1kFaYf5daOKrdBDAukOVoYN.jpg" />
                                <Avatar alt="Bomberos" src="https://www.shutterstock.com/image-vector/vector-fire-department-emblems-design-260nw-2340843179.jpg" />
                            </AvatarGroup>
                        </Grid>
                        <Grid xs={6} xsOffset={1}>

                            <Grid container spacing={2} columns={14}>
                                <Grid xs={3}>
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="contentTitle"
                                        component="div">
                                        {`#${eventId}`}
                                    </Typography>
                                </Grid>
                                <Grid xs={7} sx={{ overflow: 'auto' }}>
                                    <Typography
                                        sx={{ height: 32 }}
                                        variant="h6"
                                        id="contentTitle"
                                        component="div"
                                        noWrap>
                                        "Hurto en motocicleta"
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Box display="flex" justifyContent="flex-end">
                                        {event?.status &&
                                            <Chip
                                                label={event?.status === "reported" ? "REPORTADO" : event?.status}
                                                color={event?.status === "reported" ? "error" : "default"}
                                            />
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={4}>
                            <Box display="flex" justifyContent="flex-end">
                                <Chip icon={<ArrowBack />} label="Atrás" onClick={() => navigate(-1)} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1, p: 2 }}>
                <Grid container spacing={2} columns={14} >
                    <Grid xs={4}>
                        <EnhancedTabs
                            tabProps={[
                                { value: 'emergencia', icon: <Report />, label: "EMERGENCIA" },
                                { value: 'contacto', icon: <ContactEmergency />, label: "CONTACTO" },
                                { value: 'historial', icon: <History />, label: "HISTORIAL" },
                            ]}
                            tabPanelProps={[
                                { value: 'emergencia', children: <InfoTab incident={event} /> },
                                { value: 'contacto', children: <span>contacto</span> },
                                { value: 'historial', children: <span>historial</span> },
                            ]}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <EnhancedTabs
                            tabProps={[
                                { value: 'seguimiento', icon: <Chat />, label: "SEGUIMIENTO" },
                                { value: 'adjuntos', icon: <Attachment />, label: "ADJUNTOS" },
                            ]}
                            tabPanelProps={[
                                { value: 'seguimiento', children: <FollowUpsTab incident={event} /> },
                                { value: 'adjuntos', children: <span>adjuntos</span> },
                            ]}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <EnhancedTabs
                            tabProps={[
                                { value: 'chat', icon: <Chat />, label: "CHAT" },
                                { value: 'adjuntos', icon: <Attachment />, label: "ADJUNTOS" },
                            ]}
                            tabPanelProps={[
                                {
                                    value: 'chat', children: <ChatTab incident={event} />
                                },
                                { value: 'adjuntos', children: <span>adjuntos</span> },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Box>

        </Paper>
    )
}